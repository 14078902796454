interface IPhoneNumberDisplay {
    phoneNumber: string;
}

export const PhoneNumberDisplay: React.FunctionComponent<IPhoneNumberDisplay> = ({
    phoneNumber,
}) => {
    // CAUTION: this component is used on NetworkErrorPage, which can be displayed for some users that
    // do not have internet. No internet = no AppProvider context. Avoid using contexts that pull from
    // AppProvider, or at least provide a reasonable default if the context values are not set
    return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
};
