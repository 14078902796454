import { GanttEntry } from "entity/schedule/Gantt/Gantt.api.types";

export const UNSUPPORTED_LINK: number = -1;
export const MED_CELLCOUNT: number = 50000;
export const HIGH_CELLCOUNT: number = 100000;
export const TITLE_TEXT_WIDTH: number = 8;
export const DEFAULT_GRID_WIDTH: number = 550;
export const MAX_EXPORT_CELL_COUNT: number = 1500000;

export type GanttActions =
    | "export"
    | "gridSettings"
    | "edit"
    | "inlineEdit"
    | "saveColumns"
    | "toggle"
    | "createLink"
    | "breakLink";

export enum GanttTaskFitValue {
    Left = 1,
    Right = 2,
    Center = 3,
}

export interface IGanttChartLocalSettings {
    showCriticalPath: boolean;
    showBaseline: boolean;
    showPhases: boolean;
    showJobs: boolean;
    zoomLevel: GanttZoomLevels;
    gridWidth: number;
    columns: GanttColumnType[];
}

export enum GanttZoomLevels {
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4,
}

export type GanttDragModes = "resize" | "progress" | "move" | "ignore";

export enum GanttColumnType {
    Id = 1,
    Title = 2,
    StartDate = 3,
    EndDate = 4,
    Workdays = 5,
    Assignees = 6,
    Pred = 7,
    Complete = 8,
    NewSchedule = 9,
    QuickEdit = 10,
    Job = 11,
}

export interface IGanttColumn {
    id: GanttColumnType;
    name: string;
    label: string;
    width: number;
    align?: string;
    template?: (item: GanttEntry) => any;
    sort?: boolean | ((a: GanttEntry, b: GanttEntry) => number);
    friendlyName?: string;
    hide?: boolean;
    tree?: boolean;
}
