import { AddressEntity } from "commonComponents/entity/address/Address/Address.api.types";

import { default as FrozenBuilderAPIResponseExample } from "./FrozenBuilder.api.json";

export class FrozenBuilderEntity {
    constructor(data: typeof FrozenBuilderAPIResponseExample) {
        this.builderName = data.builderName;
        this.isAdmin = data.updatePaymentMethodVisible;
        this.updateAccountUrl = data.updateAccountUrl;
        this.contactPhoneNumber = data.contactPhoneNumber;
        this.contactEmail = data.contactEmail;
        this.contactAddress = new AddressEntity(data.contactAddress);
        this.lastBraintreeErrorMessage = data.lastBraintreeErrorMessage;
    }

    builderName: string;
    isAdmin: boolean;
    updateAccountUrl: string;
    contactPhoneNumber: string;
    contactEmail: string;
    contactAddress: AddressEntity;
    lastBraintreeErrorMessage: string | null;
}
