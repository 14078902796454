import { createContext } from "react";

export enum ParagonStatus {
    NotLoaded = 0,
    Loaded = 1,
    Error = 2,
    Unavailable = 3,
}

export interface IParagonContext {
    /**
     * Indicates status of Paragon JS
     */
    status: ParagonStatus;
}

export const ParagonContext = createContext<IParagonContext>({
    status: ParagonStatus.NotLoaded,
});
