import classNames from "classnames";
import { PureComponent } from "react";
import { Grey8 } from "styles/antTheme/Colors";

import { isInPortal } from "utilities/portal/portal";

import {
    BTIconInfoCircleErrorOutlined,
    BTIconSyncOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { StatusDisplay, StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

export enum InvoiceStatus {
    Unreleased = 1,
    PendingReleased = 2,
    PartiallyPaid = 3,
    Paid = 4,
    Void = 5,
}

export const InvoiceStatusDescription = new Map<InvoiceStatus, string>([
    [InvoiceStatus.Unreleased, "Unreleased"],
    [InvoiceStatus.PendingReleased, isInPortal({ builder: true }) ? "Pending/Released" : "Pending"],
    [InvoiceStatus.PartiallyPaid, "Partially Paid"],
    [InvoiceStatus.Paid, "Paid"],
    [InvoiceStatus.Void, "Void"],
]);

export const InvoiceStatusType = (status: InvoiceStatus): StatusTypes => {
    switch (status) {
        case InvoiceStatus.PendingReleased:
            return "pending";
        case InvoiceStatus.PartiallyPaid:
        case InvoiceStatus.Paid:
            return "success";
        case InvoiceStatus.Void:
            return "danger";
        default:
            return "";
    }
};

interface IOwnerInvoiceStatusProps {
    status: InvoiceStatus;
    releasedByDate: moment.Moment | undefined;
    releasedByName: string | undefined;
}

export class OwnerInvoiceStatus extends PureComponent<IOwnerInvoiceStatusProps> {
    render = () => {
        const { status, releasedByDate, releasedByName } = this.props;
        return (
            <>
                {releasedByDate && (
                    <div style={{ position: "absolute", right: "150px" }}>
                        Released {releasedByName ? ` by ${releasedByName} ` : ""}
                        on <DateDisplay value={releasedByDate} />
                    </div>
                )}
                <StatusDisplay statusType={InvoiceStatusType(status)}>
                    {InvoiceStatusDescription.get(status)!}
                </StatusDisplay>
            </>
        );
    };
}

interface IOwnerInvoiceStatusTagProps {
    status: InvoiceStatus;
}

export class OwnerInvoiceStatusTag extends PureComponent<IOwnerInvoiceStatusTagProps> {
    render = () => {
        const { status } = this.props;
        return (
            <StatusTagDisplay
                statusText={InvoiceStatusDescription.get(status)!}
                statusType={InvoiceStatusType(status)}
            />
        );
    };
}

export interface IOwnerInvoicePaymentStatusProps {
    paymentStatusType: InvoiceStatus;
    hasPaymentProcessing: boolean;
    hasIssueWithPayment: boolean;
    "data-testid": string;
}

export class OwnerInvoicePaymentStatus extends PureComponent<IOwnerInvoicePaymentStatusProps> {
    render() {
        const {
            paymentStatusType,
            hasIssueWithPayment,
            hasPaymentProcessing,
            "data-testid": testId,
        } = this.props;
        let hoverText: string | null = null;
        let iconDisplay = null;
        if (hasIssueWithPayment) {
            hoverText = "Issue with Payment";
            iconDisplay = <BTIconInfoCircleErrorOutlined className="padding-left-xs" />;
        } else if (hasPaymentProcessing) {
            hoverText = "Processing";
            iconDisplay = (
                <BTIconSyncOutlined className="padding-left-xs" style={{ color: Grey8 }} />
            );
        }
        return (
            <div className={classNames("flex", "align-items-center")} data-testid={testId}>
                <BTPopover content={hoverText} disabled={!hoverText}>
                    <OwnerInvoiceStatusTag status={paymentStatusType} />
                    {iconDisplay}
                </BTPopover>
            </div>
        );
    }
}
