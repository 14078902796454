import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import { useCallback, useEffect, useRef } from "react";
import { Key } from "ts-key-enum";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";
import {
    IInputDebouncingContext,
    useDebouncedOnChange,
} from "commonComponents/utilities/Input/InputDebouncingContext";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

export interface IBTInputProps<FormValues>
    extends Omit<InputProps, "id" | "onChange" | "onBlur" | "value" | "maxLength"> {
    id: KeyOfOrString<FormValues>;
    "data-testid": string;
    readOnly?: boolean;

    /** pass formik setFieldValue */
    onChange: (field: KeyOfOrString<FormValues>, value: string) => void;

    /** pass formik setFieldTouched */
    onBlur?: (
        id: KeyOfOrString<FormValues>,
        touched: boolean,
        value: string | number | undefined
    ) => void;

    value: string | number | undefined;

    fieldRef?: React.RefObject<Input>;

    autoFocus?: boolean;

    /**
     * Sets the debounce  context and limits. Should only be set in edge cases.
     */
    debouncingContext?: IInputDebouncingContext;

    /**
     * Disables default behavior of attempting to submit the form when pressing the enter key inside of the input.
     * (eg: Line Item container inputs should not submit the form but set the line item to read only when pressing the enter key)
     * */
    disableFormSubmit?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
}

export const BTInput = track((props) => ({ element: "Input", uniqueId: props["data-testid"] }))(
    function <FormValues = undefined>({
        id,
        value,
        readOnly,
        onChange,
        onFocus,
        onBlur = () => {},
        fieldRef,
        autoFocus,
        addonBefore,
        addonAfter,
        "data-testid": testid,
        debouncingContext,
        disableFormSubmit,
        hotkey,
        ...otherProps
    }: IBTInputProps<FormValues> & ITrackingProp) {
        const [debouncedValue, debouncedChange] = useDebouncedOnChange<typeof value, FormValues>(
            value ?? "",
            onChange,
            debouncingContext
        );
        const refInternal = useRef<Input>(null);

        function onChangeWrapper(event: React.ChangeEvent<HTMLInputElement>) {
            debouncedChange(id, event.target.value);
        }

        function onBlurWrapper() {
            onBlur(id, true, debouncedValue);
            otherProps.tracking?.trackEvent({ event: "InputBlur" });
        }

        function onFocusWrapper(event: React.FocusEvent<HTMLInputElement>) {
            if (onFocus) {
                onFocus(event);
            }
            otherProps.tracking?.trackEvent({ event: "InputFocus" });
        }

        const triggerFocus = useCallback(() => {
            (fieldRef?.current || refInternal?.current)?.focus();
        }, [fieldRef]);

        useEffect(() => {
            if (autoFocus) {
                triggerFocus();
            }
        }, [autoFocus, triggerFocus]);

        if (readOnly) {
            return (
                <ValueDisplay
                    id={id as string}
                    data-testid={testid}
                    value={debouncedValue}
                    {...otherProps}
                />
            );
        }

        // Handles input fields when the user uses the Enter key to submit a form
        function handleInputDebounceOnKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
            // Note: Ignore if CTRL is pressed as that conflicts with the saveAndClose hotkey
            if (
                event.key === Key.Enter &&
                !event.ctrlKey &&
                !disableFormSubmit &&
                event.target &&
                !event.repeat
            ) {
                const target = event.target as HTMLInputElement;
                const form = target.closest("form");
                if (form) {
                    event.preventDefault();
                    onChange(id, target.value);
                    requestAnimationFrame(() => {
                        if (typeof form.requestSubmit === "function") {
                            form.requestSubmit();
                        } else {
                            form.dispatchEvent(new Event("submit", { cancelable: true }));
                        }
                    });
                }
            }
        }
        const input = (
            // eslint-disable-next-line react/forbid-elements
            <Input
                id={id as string}
                data-testid={testid}
                value={debouncedValue}
                onChange={onChangeWrapper}
                onBlur={onBlurWrapper}
                ref={fieldRef ?? refInternal}
                onKeyDown={handleInputDebounceOnKeyDown}
                addonBefore={addonBefore}
                addonAfter={addonAfter}
                onFocus={onFocusWrapper}
                {...otherProps}
            />
        );

        if (!hotkey) {
            return input;
        }

        return (
            <Hotkey {...getHotkeyProps(hotkey)} onCommand={() => triggerFocus()}>
                {input}
            </Hotkey>
        );
    }
);
