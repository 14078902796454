import classNames from "classnames";
import { Component, useContext } from "react";

import { default as ReactNumberFormat } from "@buildertrend/react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { round } from "utilities/math/math";

import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

interface ICurrencyDisplayPropsInternal extends ICurrencyDisplayProps {
    builderCurrency: CurrencyLocale;
}

/**
 * Displays a formatted currency as text, use whenever you want to show currency for display only
 */
class CurrencyDisplayInternal extends Component<ICurrencyDisplayPropsInternal> {
    static defaultProps = {
        decimalScale: 2,
    };

    render() {
        const {
            value,
            decimalScale,
            builderCurrency,
            accountingMode,
            "data-testid": dataTestId,
            suffix,
            className,
            shouldTruncate,
        } = this.props;
        const roundedValue = round(value, decimalScale);

        const displayValue = (
            <ReactNumberFormat
                value={roundedValue}
                displayType="text"
                decimalScale={decimalScale!}
                fixedDecimalScale={true}
                thousandSeparator={builderCurrency.groupSeparator}
                decimalSeparator={builderCurrency.decimalSeparator}
                thousandsGroupStyle={builderCurrency.thousandsGroupStyle}
                negationFormat={accountingMode ? "parentheses" : "minus"}
                prefix={builderCurrency.currencySymbol.trim()}
                suffix={suffix}
                dir="ltr"
                data-testid={dataTestId}
                className={classNames(className)}
            />
        );

        if (shouldTruncate) {
            return <TextTruncate>{displayValue}</TextTruncate>;
        }

        return displayValue;
    }
}

interface ICurrencyDisplayProps {
    value: number;

    /** @default 2 */
    decimalScale?: number;

    /** negative numbers are wrapped in () */
    accountingMode?: boolean;

    /** Currency will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    "data-testid"?: string;

    /** @default undefined */
    suffix?: string;

    className?: string;

    shouldTruncate?: boolean;
}

export const CurrencyDisplay: React.FunctionComponent<ICurrencyDisplayProps> = ({
    forceUSCulture,
    ...props
}) => {
    const builderInfo = useContext(BuilderInfoContext);
    const locale =
        forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo!.locale.currencyLocale;
    return <CurrencyDisplayInternal builderCurrency={locale} {...props} />;
};

export class CurrencyVariableScaleDisplay extends Component<ICurrencyDisplayProps> {
    static defaultProps = {
        decimalScale: 2,
    };

    render() {
        const { value, decimalScale } = this.props;
        const stringValue = value?.toString() ?? "";
        const decimalIndex = stringValue.indexOf(".");
        let scale = 0;
        if (decimalIndex !== -1) {
            scale = stringValue.length - (decimalIndex + 1);
        }

        if (scale < decimalScale!) {
            scale = decimalScale!;
        }
        return <CurrencyDisplay {...this.props} value={value} decimalScale={scale} />;
    }
}
