/**
 * Parses the "Content-Disposition" header to use the filename generated by the server
 * @param response The raw response generated by the server for a file download
 * @param fallbackFilename The filename to be used only when the "Content-Disposition" header fails to parse
 */
export const getFileNameFromHeader = (
    response: Response,
    fallbackFilename: string = "",
    alwaysUseFallbackFilename: boolean = false
) => {
    if (alwaysUseFallbackFilename) {
        return fallbackFilename;
    }
    const content = response.headers.get("Content-Disposition");
    const matches = content?.match(/filename=(?<file>.*)/);

    // In some cases, System.Net.Http.Headers.ContentDispositionHeaderValue.FileName (used for HttpResponseMessage) string contains quotes (known bug).
    // These quotes must be removed before setting the download attribute. Otherwise, the file will not correctly download.
    // TODO: Remove replacing the quotes once the dotnet issue is resolved: https://github.com/dotnet/runtime/issues/32765
    return matches?.groups?.file.replace(/["]+/g, "") || fallbackFilename;
};

export const triggerBrowserDownload = (responseAsBlob: Blob, fileName: string) => {
    let url = window.URL.createObjectURL(responseAsBlob);
    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", url);
    linkElement.setAttribute("download", fileName);

    // append to html page
    document.body.appendChild(linkElement);

    // force download
    linkElement.click();

    // clean up and remove the link
    linkElement.parentNode!.removeChild(linkElement);
    window.URL.revokeObjectURL(url);
};

/**
 * Downloads a file through using an external download link. Used for downloading Cross Origin Download URLs
 * (Ex: Vimeo Download URLs)
 * @param linkUrl URL to download
 */
export const generateExternalAttachmentFromLink = async (linkUrl: string, index: number) => {
    let iframeElement = document.createElement("iframe");
    iframeElement.setAttribute("src", linkUrl);
    iframeElement.setAttribute("id", `vimeoDownloadUrl-${index}`);

    // append to html page
    document.body.appendChild(iframeElement);

    // remove iframe, need to use set timeout to allow the video to start
    // downloading before removing the iframe.   Doing this as well for react so we just don't leave
    // these in the dom.
    void setTimeout(() => {
        iframeElement.parentNode!.removeChild(iframeElement);
    }, 3000);
};
