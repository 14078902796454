import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IReceiptProps } from "./Receipt";

const Receipt = lazyLoadWithRetry(() => import("./Receipt"));

export const RouteReceipt = (
    props: Omit<IReceiptProps, "id" | "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.receipts.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Receipt {...props} {...routeProps} id={parseInt(routeProps.match.params.id)} />
                </BTSuspense>
            );
        }}
    />
);
