export enum OpenedFromEntity {
    Schedules = 0,
    PurchaseOrders = 1,
    TemplateImport = 2,
    TemplateDetails = 3,
    Bills = 4,
}

export interface IJobViewingPermissionWizardFormValues {
    jobId: number;
    autoAddedSubIds?: number[];
    autoAddedInternalUserIds?: number[];
    subIds: number[];
    internalUserIds: number[];
}

export class AddUsersToJobResponse {
    constructor(data: any) {
        this.subIdsAdded = data.subIdsAdded;
        this.internalUserIdsAdded = data.internalUserIdsAdded;
    }
    subIdsAdded: number[];
    internalUserIdsAdded: number[];
}
