import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISubscriptionManagerProps } from "entity/SubscriptionManager/SubscriptionManager";

const SubscriptionManager = lazyLoadWithRetry(() => import("./SubscriptionManager"));

interface ISubscriptionManagerRouteProps
    extends Omit<
        ISubscriptionManagerProps,
        "fromSettings" | "fromFrozenBuilder" | "history" | "location" | "match"
    > {
    fromUpgrade?: boolean;
}

export const RouteSubscriptionManager = (props: ISubscriptionManagerRouteProps) => (
    <RouteRelative
        path={routes.subscriptionManager.settings}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            return (
                <BTSuspense>
                    <SubscriptionManager
                        {...props}
                        {...routeProps}
                        fromSettings={qsValues.fromSettings}
                        fromFrozenBuilder={qsValues.fromFrozenBuilder}
                        fromUpgrade={props.fromUpgrade!}
                    />
                </BTSuspense>
            );
        }}
    />
);
