interface IAppDefaultFlagsType {
    [flag: string]: any;
}
const btJScriptGlobals = window.btJScriptGlobals;
const flagsDictOrUndef: IAppDefaultFlagsType | undefined =
    btJScriptGlobals && btJScriptGlobals.appDefaultFlags;

const getFlagValue = (flag: string, defaultValue: any) => {
    const flagValue = flagsDictOrUndef ? flagsDictOrUndef[flag as string] : defaultValue;
    if (flagValue === undefined) {
        throw new Error(`App default flag ${flag} not found`);
    } else {
        return flagValue;
    }
};

/**
 * Given a string representing the key value of an App Default flag, returns the value stored with that flag if it exists
 * When App Default flag dictionary is undefined (e.g. when in Storybook), always returns true
 * @param flag key value of the App Default flag to check
 * @deprecated Use AppDefaultInfoContext instead
 * @example
 * <AppDefaultInfoContext.Consumer>
 *     {(appDefaultValues) => {
 *
 *     }}
 * </AppDefaultInfoContext.Consumer>
 */
export const getAppDefaultFlagValue = (flag: string, defaultValue: any = true): any => {
    const flagValue = getFlagValue(flag, defaultValue);
    return flagValue === undefined ? defaultValue : flagValue;
};

/**
 * Given a string representing the key value of an App Default flag, returns the value stored with that flag cast to a boolean if it exists
 * When App Default flag dictionary is undefined (e.g. when in Storybook), always returns true
 * @param flag key value of the App Default flag to check
 * @deprecated Use AppDefaultInfoContext instead
 * @example
 * <AppDefaultInfoContext.Consumer>
 *     {(appDefaultValues) => {
 *
 *     }}
 * </AppDefaultInfoContext.Consumer>
 */
export const getAppDefaultFlagValueAsBoolean = (flag: string): boolean => {
    const defaultValue = true;
    const flagValue = getFlagValue(flag, defaultValue);
    return flagValue === undefined ? defaultValue : !!flagValue;
};
