import { isArray as _isArray, isEqual as _isEqual } from "lodash-es";
import { Moment } from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { FilterType } from "types/enum";

import { isEqualIgnoreOrder } from "utilities/array/array";

import {
    DateRangeValue,
    FilterEntity,
    FilterItem,
    IFilterFormValues,
    KeywordValue,
    NumberRangeValue,
    SelectAllSerializeBehaviors,
    SelectedFilterItem,
    SelectedValueTypes,
} from "entity/filters/Filter/Filter.api.types";

export function getFilterKeyValuePairAsArray(filter: FilterItem | SelectedFilterItem) {
    const filterItem = new SelectedFilterItem(filter);

    switch (filterItem.type) {
        case FilterType.DateGroups: {
            return [filterItem.key, new DateRangeValue(filterItem.selectedValue).toString()];
        }
        case FilterType.NumberField: {
            return [filterItem.key, new NumberRangeValue(filterItem.selectedValue).toString()];
        }
        case FilterType.KeywordSearch: {
            return [filterItem.key, new KeywordValue(filterItem.selectedValue).toString()];
        }
        case FilterType.Checkbox:
        case FilterType.SingleSelect: {
            return [filterItem.key, filterItem.selectedValue];
        }
        case FilterType.BuilderSelect: {
            if (filterItem.selectedValue !== undefined) {
                const value = filterItem.selectedValue as BTSelectItem<any>;
                return [filterItem.key, JSON.stringify({ value: value.value, text: value.title })];
            }
            return [filterItem.key, undefined];
        }
        default: {
            return [filterItem.key, filterItem.selectedValue?.toString()];
        }
    }
}

/**
 * Get key value pair of the filter in string form. Intended to be used for API.
 * @param values value object for which we need the key value pair in string form
 * @param dontSerialize boolean to control whether to serialize filters independently of the request object, eventually we want this to be true for all endpoints.
 */
export function getFilterString(values?: IFilterFormValues, dontSerialize?: boolean) {
    if (!values) {
        return dontSerialize ? {} : "{}";
    }

    if (values.items.length > 0 || !values.savedFilter.value) {
        return getFilterStringFromItems(values.items, dontSerialize);
    }

    return values.savedFilter.value;
}

export function getFilterStringFromItems(values?: SelectedFilterItem[], dontSerialize?: boolean) {
    if (values === undefined) {
        return dontSerialize ? {} : "{}";
    }
    return getFilterStringFromKeyValues(
        values.map((x) => getFilterKeyValuePairAsArray(x)),
        dontSerialize ?? false
    );
}

export function getDefaultFilterStringFromItems(values?: FilterItem[], dontSerialize?: boolean) {
    if (values === undefined) {
        return dontSerialize ? {} : "{}";
    }
    return getFilterStringFromKeyValues(
        values.map((x) => getFilterKeyValuePairAsArray(x)),
        dontSerialize ?? false
    );
}

function getFilterStringFromKeyValues(keyValues: SelectedValueTypes[][], dontSerialize: boolean) {
    let result: any = {};
    keyValues.forEach((value) => {
        if (value[0] !== undefined) {
            result[value[0].toString()] = value[1];
        }
    });
    return dontSerialize ? result : JSON.stringify(result);
}

/**
 * Get the kvp pair of the filter in string form or undefined if there is no value used instead to get
 * the user's default filter
 */
export function getNullableFilterString(values?: IFilterFormValues, dontSerialize?: boolean) {
    return typeof values === "undefined" ? values : getFilterString(values, dontSerialize);
}

/**
 * Converts `filterString` into `SelectedFilterItem[]` for <Filter> component initialization in JobPicker
 * @param filterString stored filter string to be converted to object
 *
 * @warn DO NOT USE OUTSIDE OF JOB PICKER
 */
export function getSelectedFilterItems(
    filters: FilterEntity,
    filterString: string | null
): SelectedFilterItem[] | undefined {
    if (filterString === null) {
        return undefined;
    }

    try {
        const filterJson = JSON.parse(filterString);
        return filters.items.map((item) => {
            return {
                key: item.key,
                type: item.type,
                selectedValue: getSelectedValueFromJson(filterJson[item.key], item.type),
            };
        });
    } catch {
        return undefined;
    }
}

/**
 * Gets value from JSON as string, number, or number[]. Used only with Job Picker filters which are one of those types
 * @param jsonValue value from json key. Expects string or number
 * @param filterType the `FilterType` for `jsonValue`
 */
function getSelectedValueFromJson(
    jsonValue: string | number,
    type: FilterType
): SelectedValueTypes {
    if (typeof jsonValue === "number" || typeof jsonValue === "boolean") {
        return jsonValue;
    } else if (typeof jsonValue === "string") {
        const jsonStringArray = jsonValue.split(",");
        const jsonNumberArray = jsonStringArray.map((v) => parseInt(v));
        if (
            (type === FilterType.MultiSelect || type === FilterType.GroupedMultiSelect) &&
            jsonNumberArray.every((n) => !isNaN(n))
        ) {
            return jsonNumberArray;
        } else {
            // return original string value
            return jsonValue;
        }
    } else {
        throw "jsonValue could not be resolved to string, number, or number[]";
    }
}

export function getAppliedFilterCount(values: SelectedFilterItem[], entity: FilterEntity) {
    let appliedFilterCount = 0;
    for (let i = 0; i < entity.items.length; i++) {
        const entityFilter = entity.items[i];
        const filterFormValue = values[i];
        if (!filterFormValue) {
            continue;
        }

        if (_isArray(entityFilter.defaultValue) && _isArray(filterFormValue.selectedValue)) {
            if (!isEqualIgnoreOrder(entityFilter.defaultValue, filterFormValue.selectedValue)) {
                appliedFilterCount++;
            }
        } else if (_isArray(entityFilter.defaultValue)) {
            // Default is array and applied value is not. If default is empty array and selected is empty string, consider them equal. But if one of them is not empty, increment
            if (entityFilter.defaultValue.length !== 0 || filterFormValue.selectedValue !== "") {
                appliedFilterCount++;
            }
        } else {
            if (IsNotEqualFilter(entityFilter.defaultValue, filterFormValue.selectedValue)) {
                appliedFilterCount++;
            }
        }
    }

    return appliedFilterCount;
}

function IsNotEqualFilter(defaultValue: SelectedValueTypes, selectedValue: SelectedValueTypes) {
    if (typeof defaultValue === "number" && typeof selectedValue !== "number") {
        return !_isEqual(defaultValue, Number(selectedValue));
    } else {
        return !_isEqual(defaultValue, selectedValue);
    }
}

export function applySerializeBehavior(entity: FilterEntity, values: IFilterFormValues) {
    const transformedValues = { ...values };
    entity.items.forEach((fi) => {
        // treat "all options selected" as "no options selected" for filters with this serialize behavior
        if (fi.serializeBehavior === SelectAllSerializeBehaviors.IncludeNoValues) {
            const match = transformedValues.items.find((sfi) => sfi.key === fi.key);
            if (match && Array.isArray(match.selectedValue)) {
                const ids = getLeafOptionIds(entity.options[fi.options]).map((id) => Number(id));
                const distinctIds = ids.filter((id, index) => ids.indexOf(id) === index);
                const distinctLeafSelectedValues = match.selectedValue.filter(
                    (val) => typeof val === "number" && distinctIds.includes(val)
                ).length;
                const distinctLeafOptionsCount = distinctIds.length;
                // all options selected
                if (match.allSelected || distinctLeafSelectedValues >= distinctLeafOptionsCount) {
                    const newItem = new SelectedFilterItem(match);
                    newItem.selectedValue = [];
                    transformedValues.items = transformedValues.items.map((sfi) =>
                        sfi.key === fi.key ? newItem : sfi
                    );
                }
            }
        }

        // SelectAllSerializeBehaviors.IncludeAllValueIdentifier has not been implemented yet and shouldn't be unless it turns out we need it
    });

    return transformedValues;
}

function getLeafOptionIds(options: BTSelectItem[]): string[] {
    return options.reduce((accumulator, currentValue) => {
        if (currentValue.children === undefined) {
            accumulator.push(currentValue.id);
            return accumulator;
        }
        return accumulator.concat(getLeafOptionIds(currentValue.children));
    }, [] as string[]);
}

export const isWeekDateDisabled = (currentDate: Moment | null) => {
    if (currentDate !== null) {
        const dayOfWeek = currentDate.weekday(); // 0 - 6; 0 = Sunday, 6 = Saturday
        // 0 - 127; 0 = None; 1 = Sunday; Saturday = 64; All = 127; (DaysOfWeek Enum)
        if (!(window.btJScriptGlobals.getBuilderWeekStartDay & (1 << dayOfWeek))) {
            return true;
        }
    }
    return false;
};

export const isSemiMonthDateDisabled = (currentDate: Moment | null) => {
    if (currentDate !== null) {
        if (currentDate.format("D") === "1" || currentDate.format("D") === "15") {
            return false;
        }
    }
    return true;
};

export const isMonthDateDisabled = (currentDate: Moment | null) => {
    if (currentDate !== null) {
        if (currentDate.format("D") === "1") {
            return false;
        }
    }
    return true;
};

const isAllSelected = (selectedValues: number[], key: string, filterEntity: FilterEntity) => {
    if (filterEntity.options[key]) {
        const ids = filterEntity.options[key].map((fi) => Number(fi.id));

        if (ids.length === selectedValues.length) {
            return selectedValues.every((fi) => ids.includes(fi));
        }
    }
    return false;
};

export const prepareFiltersForSubmission = (
    values: IFilterFormValues,
    filterEntity: FilterEntity,
    handleDisableFilter?: (
        filterField: SelectedFilterItem,
        filterValues: SelectedFilterItem[]
    ) => boolean
) => {
    values.items = values.items.map((item) => {
        const newItem = new SelectedFilterItem(item);
        if (
            newItem.type === FilterType.MultiSelect ||
            newItem.type === FilterType.GroupedMultiSelect
        ) {
            item.allSelected = isAllSelected(
                newItem.selectedValue as number[],
                newItem.key,
                filterEntity
            );
        }

        // If the filter is disabled, reset the selected value to the default value
        if (handleDisableFilter?.(newItem, values.items)) {
            item.selectedValue = filterEntity.items.find((fi) => fi.key === item.key)?.defaultValue;
        }

        return new SelectedFilterItem(item);
    });
};
