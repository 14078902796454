import { APIHandler } from "utilities/apiHandler";

import {
    IJobFromTemplateFormValues,
    JobFromTemplateEntity,
    JobsiteUpdateResponse,
} from "entity/job/JobFromTemplate/JobFromTemplate.api.types";
import { LeadToNewJobRequest } from "entity/lead/LeadToJob/LeadToJob.api.types";
import {
    FromTemplateHandler,
    IFromTemplateHandler,
} from "entity/template/common/FromTemplate.api.handler";

export interface IJobFromTemplateHandler extends IFromTemplateHandler {
    getSetup(
        leadId?: number,
        toJobId?: number,
        sourceExternalJobId?: string
    ): Promise<JobFromTemplateEntity>;
    insertJob(
        values: IJobFromTemplateFormValues,
        convertLeadToJobRequest?: LeadToNewJobRequest
    ): Promise<JobsiteUpdateResponse>;
}

export class JobFromTemplateHandler extends FromTemplateHandler implements IJobFromTemplateHandler {
    async getSetup(leadId?: number, toJobId?: number, sourceExternalJobId?: string) {
        return await APIHandler("/api/Templates/List", {
            method: "GET",
            data: {
                leadId,
                toJobId,
                sourceExternalJobId,
            },
            responseType: JobFromTemplateEntity,
        });
    }

    async getDetails(id: number) {
        return await super.getDetails(id);
    }

    async insertJob(
        values: IJobFromTemplateFormValues,
        convertLeadToJobRequest?: LeadToNewJobRequest
    ) {
        return await APIHandler(`/api/Templates/${values.sourceTemplate}`, {
            method: "POST",
            data: { ...values, convertLeadToJobRequest },
            responseType: JobsiteUpdateResponse,
        });
    }
}
