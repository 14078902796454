import {
    Blue5,
    Blue9,
    Green5,
    Green9,
    Orange5,
    Orange9,
    Purple5,
    Purple9,
    Red5,
    Red9,
    Yellow5,
    Yellow9,
} from "styles/antTheme/Colors";

import { BTAvatarSize } from "commonComponents/btWrappers/BTAvatar/BTAvatar.types";

export function getAvatarColor(globalUserId: number) {
    const avatarColors = [Blue5, Green5, Yellow5, Red5, Orange5, Purple5];
    // round robin avatar color
    const userAvatarColor = avatarColors[globalUserId % avatarColors.length];

    return userAvatarColor;
}

export function getAvatarColorDark(globalUserId: number) {
    const avatarColors = [Blue9, Green9, Yellow9, Red9, Orange9, Purple9];
    // round robin avatar color
    const userAvatarColor = avatarColors[globalUserId % avatarColors.length];

    return userAvatarColor;
}

export function sizeNameToPixels(sizeName: BTAvatarSize): number {
    switch (sizeName) {
        case "xsmall":
            return 16;
        case "small":
            return 24;
        case "middle":
            return 32;
        case "large":
            return 64;
        case "xlarge":
            return 128;
        case "xxlarge":
            return 256;
        // note: see BTAvatar.less file for CSS changes required
    }
}

/**
 * Detects if the userId is a valid userId (not 'special' or non-number)
 * Special userIds are used for non-standard options such as "All", "Users on this Job", etc
 */
export function isValidUserId(globalUserId: number | string) {
    return !isNaN(Number(globalUserId)) && globalUserId > 0;
}

/**
 * Converts a string uuid into a mostly unique int
 * There will be rare cases of overlap, so this shouldn't be assumed to be a secure hash
 */
export function simpleHash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash += str.charCodeAt(i);
    }
    return hash;
}
