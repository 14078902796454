import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";
import { PureComponent } from "react";

import { BTIconLoadingOutlined } from "commonComponents/btWrappers/BTIcon";

interface IBTSpinProps {}

export class BTSpin extends PureComponent<IBTSpinProps & SpinProps> {
    render() {
        const { children, ...otherProps } = this.props;

        return (
            // eslint-disable-next-line react/forbid-elements
            <Spin indicator={<BTIconLoadingOutlined spin />} {...otherProps}>
                {children}
            </Spin>
        );
    }
}
