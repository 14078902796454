import { MailOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconMailOutlined: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <MailOutlined {...props} type="mail" />;
};
