import { useCallback, useEffect, useRef } from "react";

/**
 * This creates and returns a ref and ref change handler to an element that will be hooked up to
 * the ResizeObserver. The ResizeObserver will trigger the provided callback when the element is
 * resized.
 * @param callback The callback to be called every time the observedRef DOM element has been resized
 * @returns The ref change handler function to be passed into a components `ref={}` prop.
 * The change handler will register a resize observer around the new reference and
 * will also update the ref DOM reference for the observed ref passed into useResizeObserver
 * @example
 * const MyComponent = () => {
 *     const [myDivRef, myDifRefChangeHandler] = useResizeObserver<HTMLDivElement>(() => {
 *         console.log("I get called every time myDiv gets resized!");
 *     });
 *     return <div ref={myDifRefChangeHandler}>This is my div</div>;
 * }
 */
export const useResizeObserver = function <TElement extends HTMLElement>(
    callback: (entry: ResizeObserverEntry | undefined) => void
): [React.MutableRefObject<TElement | null>, (node: TElement | null) => void] {
    const observedRef = useRef<TElement | null>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);

    // Wrap callback within a ref to avoid needing to unregister/reconnect the observer unnessarally on
    // callback change
    const callbackRef = useRef(callback);

    // Make sure we cleanup observer refs on unmount
    useEffect(() => {
        return () => {
            resizeObserverRef.current?.disconnect();
        };
    }, []);

    // Make sure the ref has the correct callback wired up
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const onContainerRefChange = useCallback((node: TElement | null) => {
        if (node && node !== observedRef.current) {
            // Cleanup the old observer
            resizeObserverRef.current?.disconnect();
            resizeObserverRef.current = new ResizeObserver(([observerEntry]) =>
                callbackRef.current(observerEntry)
            );
            resizeObserverRef.current.observe(node);
        }
        observedRef.current = node;
    }, []);

    return [observedRef, onContainerRefChange];
};
