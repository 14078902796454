import { APIHandler } from "utilities/apiHandler";

import {
    IScheduleNotificationRequest,
    ScheduleNotificationResponse,
} from "./ScheduleNotificationPrompt.api.types";

export interface IScheduleNotificationPromptHandler {
    sendNotifications(data: IScheduleNotificationRequest): Promise<ScheduleNotificationResponse>;
}

export class ScheduleNotificationPromptHandler implements IScheduleNotificationPromptHandler {
    async sendNotifications(
        data: IScheduleNotificationRequest
    ): Promise<ScheduleNotificationResponse> {
        return await APIHandler(`/api/calendar/NotifyOnline`, {
            method: "PUT",
            responseType: ScheduleNotificationResponse,
            data,
        });
    }
}
