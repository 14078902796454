import { BTAdminRep } from "helpers/globalContext/btAdmin/BTAdminContext.types";

import { APIHandler } from "utilities/apiHandler";

export interface IBTAdminContextHandler {
    get(encryptedBuilderId?: string): Promise<BTAdminRep>;
}

export class BTAdminUserInfoHandler implements IBTAdminContextHandler {
    isLegacy = false;

    async get(): Promise<BTAdminRep> {
        const btadminUser = await APIHandler("/api/BTAdminAccountInfo/", {
            method: "GET",
            responseType: BTAdminRep,
        });

        return btadminUser;
    }
}
