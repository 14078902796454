// tracking CreateNewAccountingJobOptions enum server-side
export enum LinkingAction {
    Create = 1,
    Choose = 2,
    None = 3,
}

export interface IEntityExtraData {
    noneMessage: string;
    createMessage: string;
    chooseMessage: string;
}
