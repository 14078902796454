import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import { memo, useContext } from "react";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { track } from "utilities/analytics/analytics";
import { round } from "utilities/math/math";
import { getAccuracy } from "utilities/number/number";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { NumberDisplay } from "commonComponents/utilities/NumberDisplay/NumberDisplay";
import { ReadOnlyShouldTruncate } from "commonComponents/utilities/TextTruncate/TextTruncate.type";
import {
    isReadOnly,
    shouldTruncate,
} from "commonComponents/utilities/TextTruncate/TextTruncate.utility";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./BTInputNumber.less";

/** Use this to override the internal styles set in BTInputNumber with Antd's styles */
export const defaultAntStyle = {};

export interface IBTInputNumberProps<FormValues>
    extends Omit<
        InputNumberProps,
        "onChange" | "name" | "value" | "id" | "min" | "max" | "placeholder" | "onBlur" | "readOnly"
    > {
    id: KeyOfOrString<FormValues> & string;

    min: number;

    max: number;

    "data-testid": string;
    readOnly?: ReadOnlyShouldTruncate;

    value: number | null | undefined;

    /** pass formik setFieldValue */
    onChange: (field: string, value: number | null | undefined) => void;

    /** pass formik setFieldTouched */
    onBlur?: (field: string, touched: boolean) => void;

    roundToDecimalPlaces?: number;
    allowNull?: boolean;

    /**
     * The label text displayed before (on the left side of) the input field
     */
    addonBefore?: React.ReactNode;
    /**
     * The label text displayed after (on the right side of) the input field
     */
    addonAfter?: React.ReactNode;
}

const BTInputNumberInternal = track((props) => ({
    element: "Number Input",
    uniqueId: props["data-testid"],
}))(function <FormValues = undefined>({
    id,
    min,
    max,
    "data-testid": testid,
    readOnly,
    value,
    onChange,
    onBlur,
    roundToDecimalPlaces,
    allowNull = false,
    addonBefore,
    addonAfter,
    step = 1,
    ...otherProps
}: IBTInputNumberProps<FormValues>) {
    const builderInfo = useContext(BuilderInfoContext);
    const currencyLocale = builderInfo?.locale.currencyLocale ?? CurrencyLocale.default;

    function handleChangeInternal(value: number | string | null | undefined) {
        if (allowNull && value === null) {
            onChange(id, null);
            return;
        }

        const valueNotNull = value === null ? undefined : value;

        let inputAsNumber = Number(valueNotNull);
        if (isNaN(inputAsNumber)) {
            // Invalid user input. Try to default to 0 if possible
            onChange(String(id), Math.min(Math.max(0, min), max));
            return;
        }

        if (roundToDecimalPlaces !== undefined) {
            inputAsNumber = round(inputAsNumber, roundToDecimalPlaces);
        }

        // We do not want validation errors for when number input is out of range. Ant Design constrains the value between
        // the min and max range, but only on blur, meaning a validation error will occur/display until then
        const constrainedInput = Math.min(Math.max(inputAsNumber, min), max);

        onChange(String(id), constrainedInput);
    }

    function handleBlur() {
        if (onBlur) {
            onBlur(id as string, true);
        }
    }

    if (isReadOnly(readOnly)) {
        let displayValue;
        if (value !== null && value !== undefined) {
            displayValue = (
                <NumberDisplay
                    value={value}
                    decimalScale={otherProps.precision ?? Math.max(0, getAccuracy(value))}
                    shouldTruncate={shouldTruncate(readOnly)}
                    suffix={typeof addonAfter === "string" ? addonAfter : undefined}
                />
            );
        }

        return (
            <ValueDisplay
                id={id as string}
                data-testid={testid}
                tabIndex={otherProps.tabIndex}
                value={displayValue}
            />
        );
    }

    const pattern = `[0-9${currencyLocale.decimalSeparator}-]*`;

    const component = (
        <InputNumber
            name={id as string}
            data-testid={testid}
            value={value ?? undefined}
            style={{ width: "100%" }}
            decimalSeparator={currencyLocale.decimalSeparator}
            onChange={handleChangeInternal}
            inputMode="numeric"
            pattern={pattern}
            onBlur={handleBlur}
            step={step}
            {...otherProps}
        />
    );

    if (addonBefore || addonAfter) {
        return (
            <span className="BTInputNumber">
                <span className="ant-input-group-wrapper">
                    <span className="ant-input-wrapper ant-input-group">
                        {addonBefore && (
                            <span className="ant-input-group-addon">{addonBefore}</span>
                        )}
                        {component}
                        {addonAfter && <span className="ant-input-group-addon">{addonAfter}</span>}
                    </span>
                </span>
            </span>
        );
    }

    return <span className="BTInputNumber">{component}</span>;
});

export const BTInputNumber = memo(BTInputNumberInternal) as typeof BTInputNumberInternal;
