import {
    LineItemType,
    MarkupColumnTypes,
    RelatedStackItem,
} from "legacyComponents/LineItemContainer.types";

import {
    calculateBuilderCost,
    newLineItemDefaults,
    recalculateAndSetMarkupFields,
} from "utilities/lineItem/lineItemHelper";
import { add, divide, multiply, round, subtract, sumListValue } from "utilities/math/math";

import {
    ILineItemsToInvoiceFormValues,
    LineItemForInvoice,
} from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoice.api.types";
import { MarkupType } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import {
    IOwnerInvoiceLineItem,
    OwnerInvoiceLineItem,
} from "entity/ownerInvoice/OwnerInvoiceLineItemContainer/OwnerInvoiceLineItemContainer.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

export function haveLineItemsChanged(
    old: IOwnerInvoiceLineItem[],
    updated: IOwnerInvoiceLineItem[]
) {
    if (old.length !== updated.length) {
        return true;
    }
    let i = 0;
    let changes = false;
    for (const li of old) {
        const newLineItem = updated[i];
        if (
            li.id !== newLineItem.id ||
            li.ownerPrice !== newLineItem.ownerPrice ||
            li.totalWithTax !== newLineItem.totalWithTax ||
            li.taxGroupId !== newLineItem.taxGroupId ||
            li.quantity !== newLineItem.quantity ||
            li.costCodeId !== newLineItem.costCodeId ||
            li.unitCost !== newLineItem.unitCost
        ) {
            changes = true;
            break;
        }
        i++;
    }
    return changes;
}

export const convertMarkupColumnTypesToMarkupType = (markupColumnType: MarkupColumnTypes) => {
    switch (markupColumnType) {
        case MarkupColumnTypes.Percentage:
            return MarkupType.percent;
        case MarkupColumnTypes.Price:
            return MarkupType.flat;
        case MarkupColumnTypes.UnitPrice:
            return MarkupType.perUnit;
        case MarkupColumnTypes.OwnerPrice:
            return MarkupType.none;
        default:
            return MarkupType.percent;
    }
};

export const mapNewLineItem = (
    mappedLineItem: LineItemForInvoice,
    isCostPlusWorkflow: boolean,
    shouldIncludeDescriptions: boolean,
    newLineItemId: number,
    taxGroupId?: number
): OwnerInvoiceLineItem => {
    let newLineItem = new OwnerInvoiceLineItem(
        newLineItemDefaults(
            newLineItemId,
            LineItemType.OwnerInvoice,
            0,
            mappedLineItem.costCode,
            false
        )
    );
    // isCostPlusWorkflow translates to "from bills, timeclock, or accounting costs", which will need to be handled differently
    if (isCostPlusWorkflow) {
        newLineItem.unitCost = mappedLineItem.unitCost;
        newLineItem.quantity = mappedLineItem.quantity;
        newLineItem.builderCost =
            mappedLineItem.builderCost ??
            calculateBuilderCost(newLineItem.unitCost, newLineItem.quantity);
        newLineItem.markupPercent = mappedLineItem.invoicedPercent!;
        recalculateAndSetMarkupFields(newLineItem);
    } else {
        newLineItem.markupType = mappedLineItem.markupType as number as MarkupType;
        const percentage = divide(mappedLineItem.invoicedPercent!, 100);
        const unroundedQuantity = multiply(percentage, mappedLineItem.quantity).toNumber();
        newLineItem.quantity = round(unroundedQuantity, 4);

        newLineItem.unitCost = mappedLineItem.unitCost;
        newLineItem.builderCost = round(multiply(mappedLineItem.builderCost, percentage), 2);
        newLineItem.ownerPrice = mappedLineItem.invoicedAmount!;
        newLineItem.markupAmount = round(
            subtract(newLineItem.ownerPrice, newLineItem.builderCost).toNumber(),
            2
        );
        newLineItem.markupPerUnit = round(newLineItem.markupAmount / unroundedQuantity, 2);
        newLineItem.markupPercent = mappedLineItem.markupPercent;
        newLineItem.totalWithTax = newLineItem.ownerPrice;
    }

    newLineItem.costTypes = mappedLineItem.costTypes;
    newLineItem.itemTitle = mappedLineItem.title;
    newLineItem.unit = mappedLineItem.unitType;

    if (shouldIncludeDescriptions) {
        newLineItem.description = mappedLineItem.description;
        newLineItem.internalNotes = mappedLineItem.internalNotes;
    }

    newLineItem.newlyInvoicedAmount = mappedLineItem.invoicedAmount;
    if ((taxGroupId ?? NoTaxId) !== NoTaxId) {
        if (mappedLineItem.taxGroupId) {
            newLineItem.taxGroupId = taxGroupId;
        }
        newLineItem.totalWithTax = mappedLineItem.totalWithTax;
    }

    switch (mappedLineItem.lineItemType) {
        case LineItemType.ChangeOrder:
            newLineItem.relatedChangeOrderLineItemId = mappedLineItem.lineItemId;
            break;
        case LineItemType.SelectionChoice:
            newLineItem.relatedSelectionChoiceLineItemId = mappedLineItem.lineItemId;
            break;
        case LineItemType.EstimateLineItem:
            newLineItem.relatedGeneralItemId = mappedLineItem.lineItemId;
            break;
        case LineItemType.Bid:
            newLineItem.relatedBidLineItemId = mappedLineItem.lineItemId;
            break;
        case LineItemType.Bill:
            newLineItem.relatedBillLineItemId = mappedLineItem.lineItemId;
            break;
        case LineItemType.Allowance:
            newLineItem.allowanceLineItemId = mappedLineItem.lineItemId;
            newLineItem.allowanceId = Number(mappedLineItem.parent!.id);
            break;
        case LineItemType.TimeCardItem:
            newLineItem.relatedTimeCardLineItemId = mappedLineItem.lineItemId;
            newLineItem.relatedTimeCardItemId = Number(mappedLineItem.parent!.id);
            break;
        case LineItemType.OtherAccountingCost:
            newLineItem.relatedAccountingCostId = mappedLineItem.lineItemId;
            break;
    }
    return newLineItem;
};

// for invoicing bills, these markup fields do not exist so the wizard will need to recalculate the full line item based on invoice %
export function recalculateAndSetMarkupFieldsForLineItemForInvoice(
    lineItem: LineItemForInvoice,
    totalEffectiveRate?: number,
    taxMethod?: number
) {
    let ownerInvoiceLineItem = new OwnerInvoiceLineItem(lineItem);
    ownerInvoiceLineItem.markupType = MarkupType.percent;
    ownerInvoiceLineItem.markupPercent = lineItem.invoicedPercent!;

    recalculateAndSetMarkupFields(ownerInvoiceLineItem, totalEffectiveRate, taxMethod);

    lineItem.markupAmount = ownerInvoiceLineItem.markupAmount;
    lineItem.markupPerUnit = ownerInvoiceLineItem.markupPerUnit;
    lineItem.markupPercent = ownerInvoiceLineItem.markupPercent;
    lineItem.markupType = MarkupColumnTypes.Percentage;
    lineItem.ownerPrice = ownerInvoiceLineItem.ownerPrice;
    lineItem.totalWithTax = ownerInvoiceLineItem.totalWithTax;
}

export function recalculateBuilderCostMarkupAndOwnerPrice(
    relatedLineItem: IOwnerInvoiceLineItem,
    x: LineItemForInvoice
) {
    relatedLineItem.ownerPrice = add(relatedLineItem.ownerPrice, x.invoicedAmount!).toNumber();

    const percentage = divide(relatedLineItem.ownerPrice, x.ownerPrice);
    const unroundedQuantity = multiply(percentage, x.quantity).toNumber();

    relatedLineItem.quantity = round(unroundedQuantity, 4);
    relatedLineItem.unitCost = x.unitCost;
    relatedLineItem.builderCost = round(multiply(x.builderCost, percentage), 2);

    relatedLineItem.markupAmount = subtract(
        relatedLineItem.ownerPrice,
        relatedLineItem.builderCost
    ).toNumber();
    relatedLineItem.markupPercent = x.markupPercent;
    relatedLineItem.markupPerUnit = round(
        divide(relatedLineItem.markupAmount, unroundedQuantity),
        2
    );
    relatedLineItem.totalWithTax = round(
        multiply(x.totalWithTax ?? relatedLineItem.ownerPrice, percentage),
        4
    );
}

export function getExistingRelatedLineItem(
    existingLineItems: IOwnerInvoiceLineItem[],
    lineItemType: LineItemType,
    lineItemId: number
) {
    switch (lineItemType) {
        case LineItemType.ChangeOrder:
            return existingLineItems.find((x) => x.relatedChangeOrderLineItemId === lineItemId);
        case LineItemType.SelectionChoice:
            return existingLineItems.find((x) => x.relatedSelectionChoiceLineItemId === lineItemId);
        case LineItemType.EstimateLineItem:
            return existingLineItems.find((x) => x.relatedGeneralItemId === lineItemId);
        case LineItemType.Bid:
            return existingLineItems.find((x) => x.relatedBidLineItemId === lineItemId);
        case LineItemType.Bill:
            return existingLineItems.find((x) => x.relatedBillLineItemId === lineItemId);
        case LineItemType.Allowance:
            return existingLineItems.find((x) => x.allowanceLineItemId === lineItemId);
    }
    return null;
}

export function getNewInvoicedAmount(
    relatedLineItem: IOwnerInvoiceLineItem,
    x: LineItemForInvoice
) {
    if (relatedLineItem.newlyInvoicedAmount && x.invoicedAmount) {
        return add(relatedLineItem.newlyInvoicedAmount, x.invoicedAmount).toNumber();
    } else {
        return x.invoicedAmount;
    }
}

export function recalculateAddLineItemsToInvoice(
    lineItems: LineItemForInvoice[],
    updatedLineItems: IOwnerInvoiceLineItem[],
    newLineItemId: number,
    includeDescriptions: boolean,
    taxGroupId?: number
): [OwnerInvoiceLineItem[], number] {
    let newLineItems: OwnerInvoiceLineItem[] = [];
    lineItems.forEach((x) => {
        x.taxGroupId = taxGroupId;
        let relatedLineItem = getExistingRelatedLineItem(
            updatedLineItems,
            x.lineItemType,
            x.lineItemId
        );

        if (relatedLineItem) {
            // line item related to same line item already exists, update its values instead of making a new line item
            recalculateBuilderCostMarkupAndOwnerPrice(relatedLineItem, x);
            relatedLineItem.newlyInvoicedAmount = getNewInvoicedAmount(relatedLineItem, x);
            if (includeDescriptions) {
                // if the existing line item does not have a description or internal notes, update it.
                if (!relatedLineItem.description?.trim()) {
                    relatedLineItem.description = x.description;
                }
                if (!relatedLineItem.internalNotes?.trim()) {
                    relatedLineItem.internalNotes = x.internalNotes;
                }
            }
        } else {
            const newLineItem = mapNewLineItem(
                x,
                x.isCostPlusWorkflow,
                includeDescriptions,
                newLineItemId--,
                taxGroupId
            );
            newLineItems.push(newLineItem);
        }
    });

    return [newLineItems, newLineItemId];
}

export function mapRelatedEntityLineItemsToInvoiceLineItems(
    lineItemsToInvoiceFormValues: ILineItemsToInvoiceFormValues,
    existingLineItems: IOwnerInvoiceLineItem[],
    nextLineItemId: number,
    taxGroupId?: number
): [OwnerInvoiceLineItem[], number] {
    let [individualLineItems, newLineItemId] = recalculateAddLineItemsToInvoice(
        lineItemsToInvoiceFormValues.lineItems,
        existingLineItems,
        nextLineItemId,
        lineItemsToInvoiceFormValues.includeDescriptions,
        taxGroupId
    );
    let lineItemsFromRelatedEntity: OwnerInvoiceLineItem[] = individualLineItems;
    if (lineItemsToInvoiceFormValues.supportsLineItemStacking) {
        newLineItemId = nextLineItemId; // reset next line item id to what was passed in
        let stackedLineItems: OwnerInvoiceLineItem[] = [];

        if (lineItemsToInvoiceFormValues.stackLineItems) {
            // group line items by cost code
            const costCodeLineItemMap = individualLineItems.reduce(
                (map, lineItem) =>
                    map.set(lineItem.costCodeId!, [
                        ...(map.get(lineItem.costCodeId!) ?? []),
                        lineItem,
                    ]),
                new Map<number, OwnerInvoiceLineItem[]>()
            );

            costCodeLineItemMap.forEach((sharedCostCodeLineItems: OwnerInvoiceLineItem[]) => {
                const summedBuilderCost = sumListValue(sharedCostCodeLineItems, "builderCost");
                const summedOwnerPrice = sumListValue(sharedCostCodeLineItems, "ownerPrice");
                const newMarkupAmount = subtract(summedOwnerPrice, summedBuilderCost).toNumber();
                const newMarkupPercent = multiply(
                    divide(newMarkupAmount, summedBuilderCost),
                    100
                ).toNumber();

                const stackedLineItemTitle = getStackedLineItemTitle(
                    lineItemsToInvoiceFormValues.lineItems,
                    sharedCostCodeLineItems
                );

                let quantity = 0;
                let stackLinks: Record<number, RelatedStackItem[]> | undefined;
                const entityIdLineItemIds: Record<number, RelatedStackItem[]> = {};
                sharedCostCodeLineItems.forEach((li) => {
                    mapRelatedItemIdsForStack(
                        li,
                        lineItemsToInvoiceFormValues,
                        entityIdLineItemIds
                    );
                    quantity += li.quantity;
                });
                stackLinks = entityIdLineItemIds;

                // quantity should never be zero here, but want to match logic in lineItemHelper.calculateMarkupFields
                const newMarkupPerUnit = quantity
                    ? divide(newMarkupAmount, quantity).toNumber()
                    : 0;

                // add owner invoice line item with stacks for each line item grouped by cost code
                const stackedLineItem = new OwnerInvoiceLineItem({
                    ...sharedCostCodeLineItems[0], // use non-calculated values from the first line item
                    lineItemId: newLineItemId--,
                    itemTitle: stackedLineItemTitle,
                    quantity: quantity,
                    unit: "Hours",
                    unitCost: quantity === 0 ? summedBuilderCost : summedBuilderCost / quantity,
                    builderCost: summedBuilderCost,
                    ownerPrice: summedOwnerPrice,
                    markupAmount: newMarkupAmount,
                    markupPerUnit: newMarkupPerUnit,
                    markupPercent: newMarkupPercent,
                    relatedStackLinks: stackLinks,
                    relatedTimeCardItemId: null,
                    relatedTimeCardLineItemId: null,
                });
                stackedLineItem.newlyInvoicedAmount = summedOwnerPrice;
                stackedLineItems.push(stackedLineItem);
            });
        } else {
            // add owner invoice line item with stack for every line item
            individualLineItems.forEach((li) => {
                const entityIdLineItemIds: Record<number, RelatedStackItem[]> = {};
                mapRelatedItemIdsForStack(li, lineItemsToInvoiceFormValues, entityIdLineItemIds);
                const stackedLineItem = new OwnerInvoiceLineItem({
                    ...li,
                    lineItemId: newLineItemId--,
                    unit: "Hours",
                    relatedStackLinks: entityIdLineItemIds,
                    relatedTimeCardItemId: null,
                    relatedTimeCardLineItemId: null,
                });
                stackedLineItem.newlyInvoicedAmount = li.ownerPrice;
                stackedLineItems.push(stackedLineItem);
            });
        }

        lineItemsFromRelatedEntity = stackedLineItems;
    }

    return [lineItemsFromRelatedEntity, newLineItemId];
}

function getStackedLineItemTitle(
    allLineItems: LineItemForInvoice[],
    mappedItemsByCostCode: OwnerInvoiceLineItem[]
) {
    if (
        mappedItemsByCostCode.length === 1 || // if there is only one line item to the cost code, use its title
        allLineItems[0].lineItemType !== LineItemType.TimeCardItem // time clock only entity that supports stacking for now
    ) {
        return mappedItemsByCostCode[0].itemTitle;
    }

    // get the line items to invoice that share the same cost code
    const lineItemsWithCostCode = allLineItems.filter(
        (li) => li.costCode === mappedItemsByCostCode[0].costCodeId
    );
    const firstLineItem = lineItemsWithCostCode[0];
    const lastLineItem = lineItemsWithCostCode[lineItemsWithCostCode.length - 1];
    switch (firstLineItem.lineItemType) {
        case LineItemType.TimeCardItem:
            return `Labor - ${lastLineItem.dateToFilter?.format(
                "MM/DD/YY"
            )} to ${firstLineItem.dateToFilter?.format("MM/DD/YY")}`;
        default:
            return firstLineItem.title;
    }
}

function mapRelatedItemIdsForStack(
    li: OwnerInvoiceLineItem,
    formValues: ILineItemsToInvoiceFormValues,
    entityIdLineItemIds: Record<number, RelatedStackItem[]>
) {
    if (entityIdLineItemIds[li.relatedTimeCardItemId!] === undefined) {
        entityIdLineItemIds[li.relatedTimeCardItemId!] = [];
    }

    const lineItem = formValues.lineItems.find(
        (x) =>
            x.parent &&
            x.parent.id === li.relatedTimeCardItemId! &&
            x.lineItemId === li.relatedTimeCardLineItemId!
    );
    entityIdLineItemIds[li.relatedTimeCardItemId!].push(
        new RelatedStackItem(li.relatedTimeCardLineItemId!, lineItem!.parent!.title)
    );
}
