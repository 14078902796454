import { Email } from "commonComponents/entity/email/InputEmail/InputEmail.types";

export type ExternalEmailLookupFormActions = "apply" | "cancel";

const defaultMaxCount = 6;

export interface IExternalEmailLookupModalFormValues {
    emailAddress: ExternalEmailLookupFormValues;
}

export type ExternalEmailLookupFormValues = ExternalEmailLookupItem[];

/**
 * Prefer using Email as a model for email addresses
 */
export class ExternalEmailLookupItem {
    constructor(data: any) {
        this.emailAddress = data.emailAddress || "";
        this.friendlyName = data.friendlyName || "";
    }

    emailAddress: string;
    friendlyName: string;

    static asEmail(item: ExternalEmailLookupItem) {
        return new Email({
            emailAddress: item.emailAddress,
            friendlyName: item.friendlyName,
            fullEmail: `${item.friendlyName} <${item.emailAddress}>`,
        });
    }
}

export class ExternalEmailLookupEntity {
    constructor(data: any) {
        this.emailAddress =
            data && data.value.map((item: any) => new ExternalEmailLookupItem(item));
        this.maxCount = defaultMaxCount;
        if (data && data.validators) {
            const maxCountValidator = data.validators.find((x: any) => x.type === "maxCount");
            this.maxCount = maxCountValidator ? maxCountValidator.value : this.maxCount;
        }
    }

    emailAddress: ExternalEmailLookupItem[];
    maxCount: number;
}
