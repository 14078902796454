import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RefSelectProps } from "antd/lib/select";
import { RefTreeSelectProps } from "antd/lib/tree-select";
import classNames from "classnames";
import { memo, useCallback, useContext, useMemo, useRef } from "react";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { track, useTracking } from "utilities/analytics/analytics";
import { getByValue, isItemSelected } from "utilities/form/form";

import { isValidUserId } from "commonComponents/btWrappers/BTAvatar/BTAvatar.utils";
import { BTSelect, BTSelectProps } from "commonComponents/btWrappers/BTSelect/BTSelect";
import {
    useBtSelectUserCustomItemRender,
    useBtSelectUserDropdownRender,
    useBtSelectUserTagRender,
} from "commonComponents/btWrappers/BTSelectUser/btSelectUser.utilities";

import "./BTSelectUser.less";

export interface IBTSelectUserProps<FormValues, ExtraDataType>
    extends Omit<BTSelectProps<FormValues, ExtraDataType>, "fieldRef"> {
    showAssignToMe?: boolean;
}

const BTSelectUserInternal = track({
    component: "User Select",
})(function <FormValues = undefined, ExtraDataType = undefined>({
    customItemRender,
    multiple = false,
    treeData,
    value,
    readOnly,
    "data-testid": dataTestId,
    id,
    onChange,
    disabled,
    showAssignToMe = true,
    ...otherProps
}: IBTSelectUserProps<FormValues, ExtraDataType>) {
    const userInfo = useContext(UserInfoContext);

    const handleCustomItemRender = useBtSelectUserCustomItemRender(false);
    const handleTagRender = useBtSelectUserTagRender();

    const selectRef = useRef<RefSelectProps | RefTreeSelectProps>(null);

    const { trackEvent } = useTracking();

    const treeDataWithoutMeGroup = useMemo(() => {
        return treeData?.filter((node) => node.title?.toString() !== "Me");
    }, [treeData]);

    const currentUserItem = useMemo(
        () => getByValue(treeDataWithoutMeGroup, userInfo?.globalUserId),
        [treeDataWithoutMeGroup, userInfo]
    );

    const handleAssignToMeButtonClicked = useCallback(() => {
        if (currentUserItem) {
            onChange(id as string, currentUserItem?.id, currentUserItem);
            selectRef.current?.blur();
            trackEvent({
                event: "ValueChange",
                uniqueId: "assignedTo-assignToMe",
                value: currentUserItem?.id,
            });
        }
    }, [onChange, trackEvent, currentUserItem, id]);

    const handleAssignToMeChecked = useCallback(
        (e: CheckboxChangeEvent) => {
            if (currentUserItem) {
                let newValue;
                if (e.target.checked) {
                    newValue = multiple
                        ? Array.from(new Set([...value, currentUserItem.id]))
                        : currentUserItem.id;
                } else {
                    newValue = multiple
                        ? value.filter(
                              (v: { toString: () => string }) =>
                                  v.toString() !== currentUserItem.id.toString()
                          )
                        : null;
                }

                onChange(id as string, newValue, currentUserItem);
                trackEvent({
                    event: "ValueChange",
                    uniqueId: "assignedTo-assignToMe",
                    value: currentUserItem?.id,
                });
            }
        },
        [id, onChange, trackEvent, value, multiple, currentUserItem]
    );

    const isCurrentUserSelected = isItemSelected(value, currentUserItem?.value);
    const handleDropdownRender = useBtSelectUserDropdownRender({
        currentUserItem,
        handleAssignToMeButtonClicked,
        handleAssignToMeChecked,
        isCurrentUserSelected,
        multiple,
        showAssignToMe,
    });

    return (
        <BTSelect
            {...otherProps}
            id={id}
            fieldRef={selectRef}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            treeData={treeDataWithoutMeGroup}
            data-testid={dataTestId}
            customItemRender={handleCustomItemRender}
            multiple={multiple}
            value={value}
            className={classNames("BTSelectUser", otherProps.className, {
                BTSelectUserCursorPadding:
                    multiple === false && value !== undefined && isValidUserId(value),
            })}
            dropdownClassName="BTSelectUserDropdown"
            maxHeight="large"
            tagRender={handleTagRender}
            dropdownRender={handleDropdownRender}
        />
    );
});

export const BTSelectUser = memo(BTSelectUserInternal) as typeof BTSelectUserInternal;
