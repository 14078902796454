import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import classNames from "classnames";
import moment from "moment";
import { createRef, PureComponent, useContext } from "react";
import { Grey6 } from "styles/antTheme/Colors";
import { Key } from "ts-key-enum";

import { TimeLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { track } from "utilities/analytics/analytics";
import { getTimeAsMinutes } from "utilities/date/date";
import { addCloseOnScroll, removeCloseOnScroll } from "utilities/helpers";
import { isNullOrUndefined } from "utilities/object/object";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconClockCircleOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./BTTimePicker.less";

const MINUTES_IN_DAY = 24 * 60;

interface IInjectedTimePickerProps {
    builderTimeLocale: TimeLocale;
}

export interface IBTTimePickerProps<FormValues = undefined> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;

    /** pass formik setFieldValue */
    onChange: (
        field: KeyOfOrString<FormValues>,
        value: moment.Moment | undefined,
        shouldValidate?: boolean
    ) => void;
    onBlur?: (field: KeyOfOrString<FormValues>, isTouched: boolean) => void;

    value?: moment.Moment;
    readOnly?: boolean;

    className?: string;
    size?: SizeType;
    disabled?: boolean;
    style?: React.CSSProperties;

    /**
     * Minutes between each item in the timepicker dropdown (note: the user can still type in a time manually)
     * @default 30
     */
    interval?: number;

    /**
     * The time to select in the dropdown when its value is undefined (empty) and the dropdown is opened
     * @default 8:00 am
     * @example set to midnight defaultDropdownTime={moment({ hour: 0, minute: 0 })}
     */
    defaultDropdownTime?: moment.Moment;

    /** Use ONLY when static times cannot be used at Storybook story level.
     *  Snapshots for the component will be ignored in Chromatic, if included.
     */
    "data-chromatic"?: "ignore";
    /**
     * If true, the timepicker will not default to midnight when the time is empty
     * @default false
     */
    allowEmpty?: boolean;
}

interface IBTTimePickerInternalState {
    timeDropdownVisible: boolean;
    unParsedValue: string | undefined;

    /** stores the type of the last action the user performed. Used to determine what the "enter" key should perform (select the dropdown, or parse the time) */
    lastAction: "dropdown" | "input";
    activeTime: number | undefined;
    dropdownTimes: BTSelectItem<moment.Moment>[];
}

@track((props) => ({ element: "Time Picker", uniqueId: props["data-testid"] }))
class BTTimePickerInternal<FormValues> extends PureComponent<
    IBTTimePickerProps<FormValues> & IInjectedTimePickerProps,
    IBTTimePickerInternalState
> {
    state: IBTTimePickerInternalState = {
        timeDropdownVisible: false,
        unParsedValue: undefined,
        lastAction: "input",
        activeTime: undefined,
        dropdownTimes: [],
    };

    static defaultProps = {
        interval: 30,
        size: "default",
        disabled: false,
        readOnly: false,
        allowEmpty: false,
        onBlur: () => {},
        defaultDropdownTime: moment({ hour: 8, minute: 0 }),
    };

    private timeInput = createRef<Input>();
    private listRef = createRef<HTMLDivElement>();
    private scrollItemTimeout: number;

    componentDidMount() {
        this.setDropdownTimesInState();
    }

    componentDidUpdate(
        prevProps: IBTTimePickerProps<FormValues> & IInjectedTimePickerProps,
        prevState: IBTTimePickerInternalState
    ) {
        const { interval, builderTimeLocale, value, defaultDropdownTime } = this.props;
        const { activeTime, timeDropdownVisible } = this.state;
        // if any props are changing, go ahead and recalculate the dropdown times
        if (
            interval !== prevProps.interval ||
            builderTimeLocale.format !== prevProps.builderTimeLocale.format ||
            value !== prevProps.value ||
            defaultDropdownTime !== prevProps.defaultDropdownTime
        ) {
            this.setDropdownTimesInState();
        }

        if (
            activeTime !== prevState.activeTime ||
            timeDropdownVisible !== prevState.timeDropdownVisible
        ) {
            // A timeout is used to wait for the time dropdown to render
            // before attempting to scroll to the active element
            this.scrollItemTimeout = setTimeout(() => {
                this.scrollSelectedIntoViewAndStyle();
            }, 0);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.scrollItemTimeout);
    }

    private setDropdownTimesInState = () => {
        this.setState({
            dropdownTimes: this.calculateDropdownTimes(),
            activeTime: this.getSelectedMinutesToFocus(),
        });
    };

    private calculateDropdownTimes = () => {
        const { interval, builderTimeLocale, value } = this.props;

        const selectedTime = getTimeAsMinutes(value);
        const times: BTSelectItem<moment.Moment>[] = [];
        for (let minute = 0; minute < MINUTES_IN_DAY; minute += interval!) {
            const time = moment().set({
                hour: Math.floor(minute / 60),
                minute: minute % 60,
                second: 0,
                millisecond: 0,
            });

            times.push(
                new BTSelectItem<moment.Moment>({
                    id: minute,
                    name: time.format(builderTimeLocale.format),
                    extraData: time,
                    selected: minute === selectedTime,
                })
            );
        }

        return times;
    };

    private getSelectedMinutesToFocus = () => {
        const { interval, value, defaultDropdownTime } = this.props;
        const timeToSelect = value ?? defaultDropdownTime!;

        // calculates the minute to select (rounded down to the nearest interval)
        return (
            timeToSelect.get("hours") * 60 +
            Math.floor(timeToSelect.get("minutes") / interval!) * interval!
        );
    };

    private scrollSelectedIntoViewAndStyle = () => {
        const { activeTime, timeDropdownVisible } = this.state;

        if (
            !isNullOrUndefined(this.listRef.current) &&
            timeDropdownVisible &&
            !isNullOrUndefined(activeTime)
        ) {
            const activeTimeIndex = this.calculateIndexFromSelectedMinutes(activeTime);
            const dropdownList = this.listRef.current.querySelector('[role="menu"]');
            if (!isNullOrUndefined(dropdownList)) {
                const dropdownElement = dropdownList.querySelector(
                    `:nth-child(${activeTimeIndex + 1})`
                );

                if (!isNullOrUndefined(dropdownElement)) {
                    dropdownList.scrollTop = (dropdownElement as HTMLDivElement).offsetTop;
                }

                dropdownList.querySelectorAll("li").forEach((child: HTMLElement, index: number) => {
                    if (index === activeTimeIndex) {
                        child.classList.add("ant-menu-item-selected");
                    } else {
                        child.classList.remove("ant-menu-item-selected");
                    }
                });
            }
        }
    };

    private handleValueChange = (time: moment.Moment | undefined) => {
        const { onChange, value, id, allowEmpty } = this.props;
        let newTime = time;

        if (value !== undefined && !allowEmpty) {
            // existing value, we clone the existing value and update only the time (in case this value is also being used in a datepicker)
            // if time is empty default to midnight
            newTime = value.clone();
            newTime.hour(time?.hour() ?? 0);
            newTime.minute(time?.minute() ?? 0);
            newTime.second(time?.second() ?? 0);
        }

        onChange(id, newTime);

        this.setState({
            unParsedValue: undefined,
            lastAction: "input",
        });
    };

    private setDropdownVisibility = (visible: boolean) => {
        this.setState({ timeDropdownVisible: visible });
    };

    private calculateIndexFromSelectedMinutes = (selectedMinutes?: number) => {
        const { interval } = this.props;
        if (isNullOrUndefined(selectedMinutes)) {
            return -1;
        }
        return Math.floor(selectedMinutes! / interval!);
    };

    private handleChange = (field: KeyOfOrString<FormValues>, value: string) => {
        this.setState({
            unParsedValue: value,
            lastAction: "input",
        });
    };

    private handleBlur = () => {
        if (this.props.disabled) {
            return;
        }

        const { unParsedValue } = this.state;

        if (unParsedValue?.trim().length === 0) {
            // input is empty, clear value
            this.setDropdownVisibility(false);
            this.setState({ unParsedValue: undefined });
            this.handleValueChange(undefined);
            return;
        }

        const parsedTime = moment(unParsedValue, [
            "hmm a",
            "hm a", // single digit hour, single or multiple digit minutes - with am/pm
            "hmm",
            "hm", // single digit hour, single or multiple digit minutes - without am/pm
            "Hmm",
            "Hm", // dougle digit hour, single or multiple digit minutes - without am/pm
        ]);

        this.setDropdownVisibility(false);

        if (parsedTime.isValid()) {
            this.handleValueChange(parsedTime);
        } else {
            // invalid date, reset input to previous value
            this.setState({
                unParsedValue: undefined,
                lastAction: "input",
            });
        }

        this.props.onBlur!(this.props.id, true);
    };

    private handleTimeSelect = (momentTime: moment.Moment) => {
        this.handleValueChange(momentTime);
        this.setDropdownVisibility(false);
        this.setState({
            lastAction: "input",
        });
    };

    private setActiveTimeFromIndex = (activeTimeIndex: number) => {
        this.setState({ activeTime: activeTimeIndex * this.props.interval! });
    };

    private handleInputClick = () => {
        this.setDropdownVisibility(true);
    };

    private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { dropdownTimes, activeTime, timeDropdownVisible, lastAction } = this.state;

        if (event.key === Key.Enter && !event.repeat) {
            event.preventDefault();
            const inputLastAction = lastAction === "input";
            // last thing you were doing was interacting with the input, and the options are
            // visible - commit the action and close the dropdown
            if (inputLastAction) {
                this.handleBlur();
            }
            if (timeDropdownVisible) {
                if (activeTime !== undefined && !inputLastAction) {
                    // Handles setting the value based on the active time
                    // when navigating by arrow keys
                    const timeToSelect = dropdownTimes.find((t) => t.value === activeTime);
                    if (timeToSelect) {
                        this.handleValueChange(timeToSelect.extraData);
                    }
                }
                this.setDropdownVisibility(false);
            } else {
                this.setDropdownVisibility(true);
            }
            return;
        }

        if (event.key === Key.Escape) {
            event.preventDefault();
            // close the dropdown
            this.setDropdownVisibility(false);
            return;
        }

        if (event.key === Key.ArrowDown || event.key === Key.ArrowUp) {
            event.preventDefault();
            this.setState({
                lastAction: "dropdown",
            });

            const activeTimeIndex = this.calculateIndexFromSelectedMinutes(activeTime);
            if (event.key === Key.ArrowDown) {
                if (!this.state.timeDropdownVisible) {
                    this.setDropdownVisibility(true);
                } else {
                    this.setActiveTimeFromIndex((activeTimeIndex + 1) % dropdownTimes.length);
                }
            } else if (event.key === Key.ArrowUp) {
                const newActiveTime =
                    activeTimeIndex <= 0 ? dropdownTimes.length - 1 : activeTimeIndex - 1;
                this.setActiveTimeFromIndex(newActiveTime);
            }
        }
    };

    private handleVisibleChange = (open: boolean) => {
        // we need a setTimeout because the users active element (aka focused) has not changed yet (document.activeElement)
        const activeInput = document.activeElement;
        const timeInput = this.timeInput.current?.input;

        if (!timeInput) {
            return;
        }

        // detects when the users activeElement is the timepicker input - this prevents the dropdown from opening/closing when double clicking the input
        if (timeInput !== activeInput) {
            this.setDropdownVisibility(false);
        } else {
            // bind scroll event
            if (this.timeInput.current) {
                if (open) {
                    addCloseOnScroll(this.timeInput.current.input, this.handleScroll);
                } else {
                    removeCloseOnScroll(this.timeInput.current.input, this.handleScroll);
                }
            }
        }
    };

    private handleScroll = () => {
        this.setDropdownVisibility(false);
    };

    private getDropdownTimes = () => {
        const { "data-testid": testid } = this.props;
        const { dropdownTimes, activeTime } = this.state;
        let selectedKeys: string[] = [];
        if (activeTime !== undefined) {
            selectedKeys.push(activeTime.toString());
        }

        return (
            <div ref={this.listRef}>
                <BTMenu
                    selectable
                    selectedKeys={selectedKeys}
                    data-testid={`${testid}-dropdown`}
                    className="DropdownList"
                >
                    {dropdownTimes.map((t) => {
                        return (
                            <BTMenuItem
                                key={t.id}
                                data-testid={`minute-${t.id}`}
                                onClick={() => this.handleTimeSelect(t.extraData!)}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {t.title}
                            </BTMenuItem>
                        );
                    })}
                </BTMenu>
            </div>
        );
    };

    render() {
        const {
            id,
            "data-testid": testid,
            "data-chromatic": dataChromatic,
            className,
            disabled,
            value,
            builderTimeLocale,
            readOnly,
        } = this.props;

        let valueToDisplay: string = "";
        if (this.state.unParsedValue !== undefined) {
            valueToDisplay = this.state.unParsedValue;
        } else if (this.props.value !== undefined) {
            valueToDisplay = moment(value).format(builderTimeLocale.format);
        }

        if (readOnly) {
            return <ValueDisplay id={id as string} data-testid={testid} value={valueToDisplay} />;
        }

        return (
            <div
                onMouseDown={(e) => {
                    // prevents the dropdown from closing when you click the scrollbar
                    const target = e.target as HTMLUListElement;
                    const list = target.classList.contains("ant-menu");

                    if (list) {
                        e.preventDefault();
                    }
                }}
            >
                <BTDropdown
                    overlay={this.getDropdownTimes}
                    onVisibleChange={this.handleVisibleChange}
                    visible={this.state.timeDropdownVisible}
                    overlayClassName={classNames("BTTimePicker-OverflowDropdown")}
                    disabled={disabled}
                >
                    <BTInput
                        id={id}
                        className={classNames("BTTimePicker", className)}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onClick={this.handleInputClick}
                        onKeyDown={this.handleKeyDown}
                        value={valueToDisplay}
                        data-testid={testid}
                        data-chromatic={dataChromatic}
                        disabled={disabled}
                        suffix={
                            <BTButton
                                data-testid="time-picker"
                                type="link"
                                icon={
                                    <BTIconClockCircleOutlined size={14} style={{ color: Grey6 }} />
                                }
                                onClick={this.handleInputClick}
                                className="BTTimePicker-button"
                            />
                        }
                        debouncingContext={{
                            useDebouncing: false, // do not debounce as context stays local
                        }}
                        fieldRef={this.timeInput}
                        autoComplete="off"
                    />
                </BTDropdown>
            </div>
        );
    }
}

interface IWrapperOnlyProps {
    isLegacy?: boolean;
}
type WrapperProps<FormValues> = IBTTimePickerProps<FormValues> & IWrapperOnlyProps;

export function BTTimePicker<FormValues = undefined>({ ...props }: WrapperProps<FormValues>) {
    const builderInfo = useContext(BuilderInfoContext);
    const timeLocale = builderInfo?.locale.timeLocale ?? TimeLocale.default;

    return <BTTimePickerInternal<FormValues> builderTimeLocale={timeLocale} {...props} />;
}
