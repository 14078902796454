import { Typography } from "antd";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";

import { routes } from "utilities/routes";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCopy, BTIconPlusCircle } from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";

import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";

import "./NewJobPrompt.less";

export interface INewJobPromptProps {
    jobRoute: string;
    templateRoute: string;
    newName: string;
    block?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
    onRecommendedTemplatesClick: () => Promise<void>;
}

export const NewJobPrompt: React.FC<INewJobPromptProps> = ({
    jobRoute,
    templateRoute,
    newName,
    block = false,
    hotkey,
    onRecommendedTemplatesClick,
}) => {
    const history = useHistory();
    const match = useRouteMatch();

    const builderInfoContext = useRequiredContext(BuilderInfoContext);

    const [showModal, setShowModal] = useState(false);

    const handleCreateFromScratch = () => {
        setShowModal(false);
        history.push(`${match.url}${jobRoute}`);
    };
    const handleCreateFromTemplate = () => {
        setShowModal(false);
        history.push(`${match.url}${templateRoute}`);
    };

    const handleRecommendedTemplatesClick = async () => {
        await onRecommendedTemplatesClick();
    };

    const canViewRecommendedTemplates = builderInfoContext.canUseExternalTemplates;

    return (
        <>
            <BTButton
                data-testid="add-job-prompt"
                type="primary"
                hotkey={hotkey}
                className="AddNewJobCondensed"
                block={block}
                onClick={() => setShowModal(true)}
            >
                New {newName}
            </BTButton>
            <BTModal
                beforeClose={() => setShowModal(false)}
                data-testid="btModalIframeModal"
                visible={showModal}
                title="Choose How You Would Like to Start"
                width="600px"
                removeBodyPadding
                centered
            >
                <div className="NewJobPrompt padding-all-md">
                    <BTButton
                        data-testid="newJobFromScratch"
                        onClick={handleCreateFromScratch}
                        type="secondary"
                    >
                        <BTIconPlusCircle className="ButtonIcon" size={42} />
                        <br />
                        Create from Scratch
                    </BTButton>
                    <BTButton
                        data-testid="newJobFromTemplate"
                        onClick={handleCreateFromTemplate}
                        type="secondary"
                    >
                        <BTIconCopy className="ButtonIcon" size={42} />
                        <br />
                        Create from My Templates
                    </BTButton>
                </div>
                {canViewRecommendedTemplates && (
                    <BTAlert
                        type="info"
                        data-testid="recommendedTemplatesAlert"
                        message={
                            <>
                                Get your jobs off the ground faster with our&nbsp;
                                <Typography.Text strong>Recommended Templates.</Typography.Text>
                                <br />
                                <BTLink
                                    useAutoSPARouting
                                    id="browseRecommendedTemplates"
                                    to={routes.template.getListLink(
                                        TemplateListTabs.RecommendedTemplates
                                    )}
                                    onClick={handleRecommendedTemplatesClick}
                                    data-testid="browseRecommendedTemplates"
                                    isUnderline={false}
                                >
                                    <b>Browse Recommended Templates</b>
                                </BTLink>
                            </>
                        }
                    />
                )}
            </BTModal>
        </>
    );
};
