import moment from "moment";

/** print api response */
export class DiscussionPrintAPI {
    constructor(data: any) {
        this.addedByName = data.addedByName;
        this.discussionDate = moment(data.discussionDate);
        this.comments = data.comments;
    }

    addedByName: string;
    discussionDate: moment.Moment;
    comments: string;
}
