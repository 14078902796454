import { ProposalType } from "legacyComponents/LineItemContainer.types";

import {
    BulkLineItemMarkupSaveResponse,
    BulkMarkupLineItem,
} from "entity/estimate/AdjustPercentMarkup/AdjustPercentMarkup.api.types";

export type AssignToAssemblyActions = "assignToAssembly" | undefined;

export class BulkAssignToAssembly extends BulkMarkupLineItem {}

export interface IBulkAssignToAssemblySaveRequest {
    parentId: number;
    builderId: number;
    proposalId: number;
    proposalType: ProposalType;
    assemblyId: number | null;
    lineItems: BulkAssignToAssembly[];
}

export class BulkAssignToAssemblySaveResponse extends BulkLineItemMarkupSaveResponse {}
