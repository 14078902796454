import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IAllowanceProps } from "entity/allowance/Allowance/Allowance";

const Allowance = lazyLoadWithRetry(() => import("./Allowance"));

export const RouteAllowance = (
    props: Omit<IAllowanceProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.allowance.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Allowance
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
