import { useState } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { IBuilderSearchExtraData } from "commonComponents/entity/builder/BuilderSearch/BuilderSearch.api.handler";

import { BuilderFilterTypes, ClosedAccountTypes } from "handlers";

import { BuilderSearchV2 } from "../../commonComponents/entity/builderSearch/BuilderSearchV2";

export function AdminBuildersSearch() {
    const dropdownEnums = {
        OpenOnly: 1,
        ClosedOnly: 2,
        OpenOrClosed: 3,
        OpenOrDataRetrieval: 4,
        Canceled: 5,
        WebsiteOnly: 6,
    };
    const dropdownAccountStatusEnums = [
        dropdownEnums.OpenOnly,
        dropdownEnums.ClosedOnly,
        dropdownEnums.OpenOrClosed,
        dropdownEnums.OpenOrDataRetrieval,
        dropdownEnums.Canceled,
    ];
    const dropdownItems: BTSelectItem[] = [
        new BTSelectItem({
            value: dropdownEnums.OpenOnly,
            title: "Open Only",
            id: "OpenOnly",
            key: "OpenOnly",
            disabled: false,
        }),
        new BTSelectItem({
            value: dropdownEnums.ClosedOnly,
            title: "Closed Only",
            id: "ClosedOnly",
            key: "ClosedOnly",
            disabled: false,
        }),
        new BTSelectItem({
            value: dropdownEnums.OpenOrClosed,
            title: "Open or Closed",
            id: "OpenOrClosed",
            key: "OpenOrClosed",
            disabled: false,
        }),
        new BTSelectItem({
            value: dropdownEnums.OpenOrDataRetrieval,
            title: "Open or Data Retrieval",
            id: "OpenOrDataRetrieval",
            key: "OpenOrDataRetrieval",
            disabled: false,
        }),
        new BTSelectItem({
            value: dropdownEnums.Canceled,
            title: "Canceled",
            id: "Canceled",
            key: "Canceled",
            disabled: false,
        }),
        new BTSelectItem({
            value: dropdownEnums.WebsiteOnly,
            title: "Website Only",
            id: "WebsiteOnly",
            key: "WebsiteOnly",
            disabled: false,
        }),
    ];
    const [selectedItem, setSelectedItem] = useState<BTSelectItem | undefined>(dropdownItems[0]);
    const [selectedItemDAccountStatus, setSelectedItemDAccountStatus] = useState<
        ClosedAccountTypes | undefined
    >(ClosedAccountTypes.OpenOnly);
    const [selectedItemAccountType, setSelectedItemAccountType] = useState<
        BuilderFilterTypes | undefined
    >();

    const handleBuilderSelectChange = (
        _field: string,
        _value: number,
        selectedItem?: BTSelectItem<IBuilderSearchExtraData>
    ) => {
        if (selectedItem?.id) {
            // TODO - for now we just redirect to the .aspx page, eventually this page should get deleted
            const builderID = selectedItem.id;
            window.location.assign(`/btadmin/adminBuilders.aspx?builderID=${builderID}`);
        }
    };

    const onChangeDropdown = (field: string, value: number, selectedItem?: BTSelectItem) => {
        if (dropdownAccountStatusEnums.includes(selectedItem?.value)) {
            setSelectedItemAccountType(undefined);
            setSelectedItemDAccountStatus(selectedItem?.value);
        } else if (selectedItem?.value === dropdownEnums.WebsiteOnly) {
            setSelectedItemDAccountStatus(undefined);
            setSelectedItemAccountType(selectedItem?.value);
        }
        setSelectedItem(selectedItem);
    };

    return (
        <BTRow style={{ justifyContent: "center" }}>
            <BTCol span={10}>
                <BuilderSearchV2
                    data-testid="admin-builders-search-component"
                    onChange={handleBuilderSelectChange}
                    placeholder="Search for a builder"
                    accountStatus={selectedItemDAccountStatus}
                    accountType={selectedItemAccountType}
                />
            </BTCol>
            <BTCol span={2}>
                <BTSelect
                    id="Builder Search Select Type"
                    onChange={onChangeDropdown}
                    data-testid="admin-builder-search-dropdown"
                    value={selectedItem?.value}
                    onBlur={() => {}}
                    notVisiblePlaceholder="Select search type"
                    noTreeDataPlaceholder=""
                    treeData={dropdownItems}
                />
            </BTCol>
        </BTRow>
    );
}

export default AdminBuildersSearch;
