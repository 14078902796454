import { Component } from "react";

import { BdsIcon } from "@buildertrend/components";

import { externalPages } from "utilities/externalPages";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconFacebookFilled,
    BTIconFacebookLightFilled,
    BTIconInstagramFilled,
    BTIconInstagramLightFilled,
    BTIconLinkedinFilled,
    BTIconLinkedinLightFilled,
    BTIconTwitterSquareFilled,
    BTIconTwitterSquareLightFilled,
} from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import "./SocialMedia.less";

type SocialTheme = "light" | "dark" | "blueprint" | undefined;
interface ISocialMediaInternalProps {
    usePrimaryButtonType?: boolean;
    theme?: SocialTheme;
}
class SocialMediaInternal extends Component<ISocialMediaInternalProps> {
    static defaultProps = {
        usePrimaryButtonType: false,
        theme: "light" as SocialTheme,
    };

    private getFacebookIcon = (theme: SocialTheme) => {
        switch (theme) {
            case "dark":
                return <BTIconFacebookFilled />;
            case "blueprint":
                return <BdsIcon iconName="FacebookLogo" className="SocialMedia--BdsIcon" />;
            case "light":
            default:
                return <BTIconFacebookLightFilled />;
        }
    };

    private getInstagramIcon = (theme: SocialTheme) => {
        switch (theme) {
            case "dark":
                return <BTIconInstagramFilled />;
            case "blueprint":
                return <BdsIcon iconName="InstagramLogo" className="SocialMedia--BdsIcon" />;
            case "light":
            default:
                return <BTIconInstagramLightFilled />;
        }
    };

    private getTwitterIcon = (theme: SocialTheme) => {
        switch (theme) {
            case "dark":
                return <BTIconTwitterSquareFilled />;
            case "blueprint":
                return <BdsIcon iconName="XLogo" className="SocialMedia--BdsIcon" />;
            case "light":
            default:
                return <BTIconTwitterSquareLightFilled />;
        }
    };

    private getLinkedInIcon = (theme: SocialTheme) => {
        switch (theme) {
            case "dark":
                return <BTIconLinkedinFilled />;
            case "blueprint":
                return <BdsIcon iconName="LinkedinLogo" className="SocialMedia--BdsIcon" />;
            case "light":
            default:
                return <BTIconLinkedinLightFilled />;
        }
    };

    render() {
        const { theme } = this.props;
        return (
            <div className="SocialMedia">
                <BTRow justify="space-between">
                    <BTCol>
                        <a href={externalPages.Facebook} rel="noopener noreferrer" target="_blank">
                            <BTButton
                                isolated
                                type={this.props.usePrimaryButtonType! ? "primary" : "link"}
                                onClick={() => {}}
                                data-testid="facebookLink"
                                className="SocialMedia-Button"
                            >
                                {this.getFacebookIcon(theme)}
                            </BTButton>
                        </a>
                    </BTCol>
                    <BTCol>
                        <a href={externalPages.Instagram} rel="noopener noreferrer" target="_blank">
                            <BTButton
                                isolated
                                type={this.props.usePrimaryButtonType! ? "primary" : "link"}
                                onClick={() => {}}
                                data-testid="instagramLink"
                                className="SocialMedia-Button"
                            >
                                {this.getInstagramIcon(theme)}
                            </BTButton>
                        </a>
                    </BTCol>
                    <BTCol>
                        <a href={externalPages.Twitter} rel="noopener noreferrer" target="_blank">
                            <BTButton
                                isolated
                                type={this.props.usePrimaryButtonType! ? "primary" : "link"}
                                onClick={() => {}}
                                data-testid="twitterLink"
                                className="SocialMedia-Button"
                            >
                                {this.getTwitterIcon(theme)}
                            </BTButton>
                        </a>
                    </BTCol>
                    <BTCol>
                        <a href={externalPages.Linkedin} rel="noopener noreferrer" target="_blank">
                            <BTButton
                                isolated
                                type={this.props.usePrimaryButtonType! ? "primary" : "link"}
                                onClick={() => {}}
                                data-testid="linkedInLink"
                                className="SocialMedia-Button"
                            >
                                {this.getLinkedInIcon(theme)}
                            </BTButton>
                        </a>
                    </BTCol>
                </BTRow>
            </div>
        );
    }
}

export const SocialMedia = withErrorBoundary(SocialMediaInternal)("Could not load Social Media");
export default SocialMedia;
