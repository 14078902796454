import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IDailyLogProps } from "entity/dailyLog/DailyLog/DailyLog";
import { IDailyLogCreationData } from "entity/dailyLog/DailyLog/DailyLog.api.types";

const DailyLog = lazyLoadWithRetry(() => import("./DailyLog"));

interface IRouteDailyLogProps
    extends Omit<IDailyLogProps, "id" | "jobId" | "history" | "location" | "match"> {
    prefilledFormValues?: IDailyLogCreationData;
}

export const RouteDailyLog = (props: IRouteDailyLogProps) => (
    <RouteRelative
        path={routes.dailyLog.details}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsParams = queryString.parse(routeProps.location.search);
            const isOpenWithMap = qsParams.openWithMap ? qsParams.openWithMap === "true" : false;

            return (
                <BTSuspense>
                    <DailyLog
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                        isOpenWithMap={isOpenWithMap}
                    />
                </BTSuspense>
            );
        }}
    />
);
