import { Tag } from "antd";
import { Component } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { getDisabledIds } from "utilities/form/form";

import {
    BTSelectCheckAllItems,
    BTSelectInternal,
    BTSelectProps,
} from "commonComponents/btWrappers/BTSelect/BTSelect";
import ReadMore from "commonComponents/utilities/ReadMore/ReadMore";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

type BTSelectItemWithRawVal = BTSelectItem<{ rawValue?: any }>;

type OmittedBTSelectPropFields = "multiple";
export interface IBTSelectDuplicateProps<FormValues, ExtraDataType>
    extends Omit<BTSelectProps<FormValues, ExtraDataType>, OmittedBTSelectPropFields> {}

export class BTSelectDuplicate<FormValues = undefined, ExtraDataType = undefined> extends Component<
    IBTSelectDuplicateProps<FormValues, ExtraDataType>
> {
    _btSelectItems: {
        btSelectItems: BTSelectItemWithRawVal[];
        rawPropValues: Pick<IBTSelectDuplicateProps<FormValues, ExtraDataType>, "treeData">;
    };
    private get btSelectItems() {
        const { treeData } = this.props;
        if (!this._btSelectItems || this._btSelectItems.rawPropValues.treeData !== treeData) {
            this._btSelectItems = {
                btSelectItems: this.getTreeDataWithParents(treeData) ?? [],
                rawPropValues: { treeData },
            };
        }
        return this._btSelectItems.btSelectItems;
    }

    private getChildValueWithParent(parent: string, value: string | number): string {
        return `${parent}-${value}`;
    }

    private getTreeDataWithParents(treeData: BTSelectItem<ExtraDataType>[] | undefined) {
        if (treeData) {
            const treeDataWithParents = treeData.map((node) => {
                const newNode = {
                    ...node,
                    extraData: node.extraData || {},
                } as BTSelectItemWithRawVal;
                if (newNode.children) {
                    newNode.children = newNode.children.map((childNode) => {
                        const newChildNode: BTSelectItemWithRawVal = {
                            ...childNode,
                            extraData: { ...childNode.extraData, rawValue: childNode.value },
                        };
                        const newValue = this.getChildValueWithParent(
                            newNode.title,
                            newChildNode.value
                        );
                        newChildNode.value = newValue;
                        newChildNode.key = newValue;
                        return newChildNode;
                    });
                }
                return newNode;
            });
            return treeDataWithParents;
        }
        return undefined;
    }

    private getInternalProps(props: IBTSelectDuplicateProps<FormValues, ExtraDataType>) {
        const { value } = props;
        const values = value && !Array.isArray(value) ? [value] : (value as any[] | undefined);

        const internalValues: Set<string> = new Set();
        let internalSelectItems: BTSelectItem<ExtraDataType>[] = [];
        if (!values || values.length === 0) {
            internalSelectItems = this.btSelectItems as BTSelectItem<ExtraDataType>[];
        } else {
            this.btSelectItems.forEach((item) => {
                const newItem = { ...item };

                if (item.children) {
                    const newItemChildren: BTSelectItemWithRawVal[] = [];
                    item.children.forEach((c) => {
                        if (!values.includes(c.extraData?.rawValue)) {
                            newItemChildren.push({ ...c });
                        } else {
                            internalValues.add(c.title);
                        }
                    });

                    newItem.children = newItemChildren;
                    if (newItem.children && newItem.children.length > 0) {
                        internalSelectItems.push(newItem as BTSelectItem<ExtraDataType>);
                    }
                } else {
                    if (!values.includes(item.value)) {
                        internalSelectItems.push({ ...item } as BTSelectItem<ExtraDataType>);
                    } else {
                        internalValues.add(item.title);
                    }
                }
            });
        }

        return {
            treeData: internalSelectItems,
            value: Array.from(internalValues),
        };
    }

    private handleChange = (field: string, values: any[]) => {
        const disabledIds = this.props.disabledIds ?? getDisabledIds(this.props.treeData);
        const newValuesSet = new Set<string | number>();

        const valueSet = new Set(values);

        const isSelectAllInSelectedValues = valueSet.has(BTSelectCheckAllItems);
        this.btSelectItems.forEach((item) => {
            const parentIsSelected =
                isSelectAllInSelectedValues || this.isItemInValues(item, valueSet);
            if (parentIsSelected && !item.children) {
                newValuesSet.add(item.value);
            }
            item.children?.forEach((c) => {
                const isDisabled = !!disabledIds.includes(c.id);
                if ((parentIsSelected && !isDisabled) || this.isItemInValues(c, valueSet)) {
                    newValuesSet.add(c.extraData?.rawValue);
                }
            });
        });

        const wasValueSelected = Array.isArray(this.props.value) && this.props.value.length > 0;

        // unselect all options
        if (isSelectAllInSelectedValues && wasValueSelected) {
            newValuesSet.clear();
        }

        const newValues: (string | number)[] = Array.from(newValuesSet);
        this.props.onChange(field, newValues, undefined);
    };

    private isItemInValues(item: BTSelectItemWithRawVal, values: Set<any>) {
        const isSelected = values.has(item.value);
        const isPreviouslySelected = values.has(item.title);
        return isSelected || isPreviouslySelected;
    }

    render() {
        const { value, treeData, onChange, readOnly, ...restProps } = this.props;

        const internalProps = this.getInternalProps(this.props);

        if (readOnly) {
            const internalValues = internalProps.value;
            return (
                <ReadMore numberOfLines={2} lineHeight={2}>
                    <ValueDisplay
                        className="BTSelect"
                        data-testid={restProps["data-testid"]}
                        value={
                            internalValues.length > 0 ? (
                                <>
                                    {internalValues.map((v: string | number) => (
                                        <Tag key={v}>{v}</Tag>
                                    ))}
                                </>
                            ) : undefined
                        }
                    />
                </ReadMore>
            );
        }

        return (
            <BTSelectInternal
                multiple
                {...restProps}
                {...internalProps}
                treeCheckable={false}
                keepEnabledWhenEmpty={true}
                onChange={this.handleChange}
            />
        );
    }
}
