import type { default as DocumentTemplateEntityJson } from "entity/document/DocumentCreate/DocumentCreate.api.json";

export class DocumentTemplateEntity {
    constructor(data: typeof DocumentTemplateEntityJson) {
        this.templates = data.templateList.map((t: any) => new DocumentTemplate(t));
        this.canEdit = data.canEdit;
    }

    templates: DocumentTemplate[];
    canEdit: boolean;
}

export class DocumentTemplate {
    constructor(data: any) {
        this.fileType = data.fileType;
        this.title = data.title;
        this.documentId = data.documentId;
        this.documentInstanceId = data.documentInstanceId;
    }

    fileType: DocumentFileType;
    title: string;
    documentId: number;
    documentInstanceId: number;
}

export enum DocumentFileType {
    None = 0,
    Word = 1,
    Excel = 2,
    PowerPoint = 3,
}
