import { APIHandler } from "utilities/apiHandler";

import { FrozenBuilderEntity } from "entity/FrozenBuilder/FrozenBuilder.api.types";

export interface IFrozenBuilderHandler {
    get(overrideUserId?: number): Promise<FrozenBuilderEntity>;
}

export class FrozenBuilderHandler implements IFrozenBuilderHandler {
    async get(overrideUserId?: number): Promise<FrozenBuilderEntity> {
        const queryString = overrideUserId ? `?overrideUserId=${overrideUserId}` : "";
        return await APIHandler(`/api/AccountInfo/FrozenBuilder${queryString}`, {
            method: "GET",
            responseType: FrozenBuilderEntity,
        });
    }
}
