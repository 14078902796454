import moment from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { TabPermissions } from "types/enum";
import { SplitDateTime } from "types/splitDateTime";

import { ScheduleConflictResponse } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";

import { IInactiveLineItem } from "entity/costCatalog/CostCatalog.types";

export type TemplateImportFormActions = undefined | "templateChanged" | "importStarted";

export interface ITemplateImportFormValues {
    templateId: number;
    schedule: boolean;
    toDos: boolean;
    selections: boolean;
    selectionsResetApproved: boolean;
    selectionsApplyPending: boolean;
    specifications: boolean;
    documentFolders: boolean;
    documentFiles: boolean;
    photoFolders: boolean;
    videoFolders: boolean;
    videoFiles: boolean;
    surveys: boolean;
    bidPackages: boolean;
    estimates: boolean;
    estimatesRemoveExisting: boolean;
    estimatesKeepIntroClosingText: boolean;
    bills: boolean;
    linkedBills: boolean;
    purchaseOrders: boolean;
    purchaseOrdersFiles: boolean;
    ownerInvoices: boolean;
    scheduleStartDate: moment.Moment | null | undefined;
    selectionsDeadline: moment.Moment | null | undefined;
    surveyReleaseDate: moment.Moment | null | undefined;
    bidPackageDeadline: SplitDateTime | null | undefined;
    purchaseOrdersCompletion: moment.Moment | null | undefined;
    ownerInvoiceDeadline: SplitDateTime | null | undefined;
}

export class TemplateImportResponse {
    constructor(data: any) {
        this.scheduleIdsAdded = data.scheduleIdsAdded;
        this.usersToAdd = data.usersToAdd.map((item: any) => new BTSelectItem(item));
        this.subsToAdd = data.subsToAdd.map((item: any) => new BTSelectItem(item));
        this.conflictsResponse = new ScheduleConflictResponse(data.conflicts);
        this.isCalendarOffline = data.isCalendarOffline;
        this.canViewConflicts = data.canViewConflicts;
        this.canAddUsers = data.canAddUsers;
        this.canSendNotifications = data.canSendNotifications;
        this.backgroundJobId = data.backgroundJobId;
    }
    scheduleIdsAdded: number[];
    usersToAdd: BTSelectItem[];
    subsToAdd: BTSelectItem[];
    conflictsResponse: ScheduleConflictResponse;
    isCalendarOffline: boolean;
    canViewConflicts: boolean;
    canAddUsers: boolean;
    canSendNotifications: boolean;
    backgroundJobId?: number;
}

export class TemplateImportCountsResponse {
    constructor(data: any) {
        this.scheduleItems = data.scheduleItems;
        this.bidPackages = data.bidPackages;
        this.bills = data.bills;
        this.linkedBills = data.linkedBills;
        this.purchaseOrders = data.purchaseOrders;
        this.purchaseOrdersFiles = data.purchaseOrdersFiles;
        this.selections = data.selections;
        this.selectionsApprovedChoices = data.selectionsApprovedChoices;
        this.selectionsLineItemUpdates = data.selectionsLineItemUpdates;
        this.ownerInvoices = data.ownerInvoices;
        this.toDos = data.toDos;
        this.surveys = data.surveys;
        this.specifications = data.specifications;
        this.documentFolders = data.documentFolders;
        this.documentFiles = data.documentFiles;
        this.videoFolders = data.videoFolders;
        this.videoFiles = data.videoFiles;
        this.photoFolders = data.photoFolders;
        this.estimates = data.estimates;
    }

    scheduleItems: number;
    bidPackages: number;
    bills: number;
    linkedBills: number;
    purchaseOrders: number;
    purchaseOrdersFiles: number;
    selections: number;
    selectionsApprovedChoices: number;
    selectionsLineItemUpdates: number;
    ownerInvoices: number;
    toDos: number;
    surveys: number;
    documentFolders: number;
    documentFiles: number;
    specifications: number;
    videoFolders: number;
    videoFiles: number;
    photoFolders: number;
    estimates: number;
}

export interface ITemplateImportRequest {
    scheduleStartCopyDateUtc: moment.Moment | null;
    templateId: number;
    jobsiteId: number;
    callingPage: TabPermissions;
    importCopyOptions: ITemplateImportOptions;
    datesForImport: ITemplateImportDates;
}

interface ITemplateImportOptions {
    applyLIUpdates: boolean;
    importBidPackages: boolean;
    importDocumentFiles: boolean;
    importDocumentFolders: boolean;
    importEstimate: boolean;
    importOwnerPayments: boolean;
    importStandaloneBills: boolean;
    importLinkedBills: boolean;
    importPODocs: boolean;
    importPOs: boolean;
    importPhotoFolders: boolean;
    importSchedules: boolean;
    importSelections: boolean;
    importSpecifications: boolean;
    importSurveys: boolean;
    importToDos: boolean;
    importVideoFiles: boolean;
    importVideoFolders: boolean;
    resetApprovedChoices: boolean;
    resetFavoritedChoices: boolean;
    removeExistingEstimates: boolean;
    keepDestinationProposalIntroClosingText: boolean;
}

// These are all in local time, just matching key names
interface ITemplateImportDates {
    newBidPackageDeadlineUtc: moment.Moment | null;
    newPOsStartDateUtc: moment.Moment | null;
    newSelectionsStartDateUtc: moment.Moment | null;
    newOwnerPaymentDeadlineLocal: moment.Moment | null;
    newSurveyReleaseDateLocal: moment.Moment | null;
}

export interface ITemplateImportConflictsRequest {
    jobId: number;
    copyOptions: CopyOptions[];
    isExistingJob: boolean;
}

export class TemplateImportConflictsResponse {
    constructor(data: any) {
        this.inactiveCostCodes = data.inactiveCostCodes ? data.inactiveCostCodes.counts : [];
        this.multipleCostTypesCount = data.multipleCostTypesCount;
        this.hasMultipleMarkedAs = data.hasMultipleMarkedAs;
    }
    inactiveCostCodes: IInactiveLineItem[];
    multipleCostTypesCount: number;
    hasMultipleMarkedAs: boolean;
}

export enum CopyOptions {
    None = 0,
    Tasks = 1,
    Selections = Tasks << 1,
    DocumentFolders = Selections << 1,
    PhotoFolders = DocumentFolders << 1,
    PurchaseOrders = PhotoFolders << 1,
    Estimates = PurchaseOrders << 1,
    BidPackages = Estimates << 1,
    ToDos = BidPackages << 1,
    Bills = ToDos << 1,
    CustomerInvoices = Bills << 1,
    DocumentFiles = CustomerInvoices << 1,
    ResetApprovedSelections = DocumentFiles << 1,
    VideoFolders = ResetApprovedSelections << 1,
    VideoFiles = VideoFolders << 1,
    ResetFavoritedChoices = VideoFiles << 1,
    Surveys = ResetFavoritedChoices << 1,
    PhotoFiles = Surveys << 1,
    PurchaseOrderFiles = PhotoFiles << 1,
    ApplyPendingLineItemUpdates = PurchaseOrderFiles << 1,
    ScheduleItems = ApplyPendingLineItemUpdates << 1,
    ScheduleItemAttachments = ScheduleItems << 1,
    All = Tasks |
        Selections |
        DocumentFolders |
        PhotoFolders |
        PurchaseOrders |
        Estimates |
        BidPackages |
        ToDos |
        Bills |
        CustomerInvoices |
        DocumentFiles |
        ResetApprovedSelections |
        VideoFolders |
        VideoFiles |
        ResetFavoritedChoices |
        Surveys |
        PhotoFiles |
        PurchaseOrderFiles |
        ApplyPendingLineItemUpdates |
        ScheduleItems |
        ScheduleItemAttachments,
}
