export enum ScheduleListContainerTabs {
    Default = 0,
    Month = 1,
    Week = 2,
    Day = 3,
    Agenda = 4,
    List = 5,
    Gantt = 6,
    Baseline = 8,
    Workdays = 9,
}

export interface IScheduleListChildProps {
    onUpdateHeaderValues: (canAdd: boolean, isOffline: boolean) => void;
    onSavedFilterVisibleChange?: (visible: boolean) => void;
    handleTabChange: ((activeKey: string) => void) | undefined;
}
