import moment from "moment";
import "moment-recur-ts";
import { Recur } from "moment-recur-ts";

import { SplitDateTime } from "types/splitDateTime";

export function getCurrentYear(): number {
    return new Date().getFullYear();
}

export enum Days {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64,
}

/**
 * returns a list of days of the weeks to be used by
 *  multiple or single select. api/TimeClock/Setup
 *  looks for a number for single selects like startOfWeek. For
 *  multiple selects, like alwaysOtHours, we need a string to pass
 *  into fromEnumToInt function.
 * @param option: string, 'multi' for value to be a string,
 * 'single' for value to be a number.
 */
export const selectDays = (option: string) => {
    return [
        {
            id: 1,
            name: "Sunday",
            value: option === "multi" ? "Sunday" : 1,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 2,
            name: "Monday",
            value: option === "multi" ? "Monday" : 2,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 3,
            name: "Tuesday",
            value: option === "multi" ? "Tuesday" : 4,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 4,
            name: "Wednesday",
            value: option === "multi" ? "Wednesday" : 8,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 5,
            name: "Thursday",
            value: option === "multi" ? "Thursday" : 16,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 6,
            name: "Friday",
            value: option === "multi" ? "Friday" : 32,
            selected: false,
            extraData: null,
            Disabled: false,
        },
        {
            id: 7,
            name: "Saturday",
            value: option === "multi" ? "Saturday" : 64,
            selected: false,
            extraData: null,
            Disabled: false,
        },
    ];
};

export const formatDateAsApFormatWithTime = (date: moment.Moment, timeFormat: string) => {
    // "ll" is for Aug. 27, 2020
    return date.format(`ll ${timeFormat}`);
};

export enum WorkDayExceptionTypes {
    ExtraWorkday = 1,
    NonWorkDay = 2,
}

/**
 * Config object containing the moment recurrences for both workday exceptions and extra workdays
 */
export interface IWorkdayExceptionRecurrences {
    exceptionWorkdays: Recur[];
    extraWorkdays: Recur[];
}

export interface ITemporalRangeFilterConfig {
    disablePastDates?: boolean;
    disableFutureDates?: boolean;
    disableToday?: boolean;
}

/**
 * Builder pattern for creating disabled date filters to be used by the DatePicker.
 * Examples of possible date filters include testing against builder defined workday
 * exceptions or excluding dates from the past, present, or future.
 *
 * @example
 * const disabledDateBuilder = new DisabledDateBuilder().disablePast().disablePresent();
 *
 * <BTDatePicker
 *     disabledDates={disabledDateBuilder.build()}
 * />
 */
export class DisabledDateBuilder {
    private disableChecks: ((date: moment.Moment | null) => boolean)[] = [];

    private addDisableCheck = (func: (date: moment.Moment | null) => boolean) => {
        const newDate = new DisabledDateBuilder();

        newDate.disableChecks = [...this.disableChecks, func];
        return newDate;
    };

    public build = () => {
        return (date: moment.Moment | null) => {
            return this.disableChecks.some((f) => f(date));
        };
    };

    /**
     * Given a workday recurrence config object, return true if the passed date is a past, present,
     * or future workday exception or falls outside of the standard workweek (sans extra workdays)
     *
     * @param exceptionConfig - moment recurrences for workday exceptions and
     * extra workdays. This should be generated by the utility
     * function `getWorkdayExceptionRecurrences`
     */
    public disableWorkdayExceptions = (exceptionConfig: IWorkdayExceptionRecurrences) => {
        return this.addDisableCheck((value: moment.Moment | null) => {
            return (
                value !== null &&
                exceptionConfig.exceptionWorkdays.some((w) => w.matches(value)) &&
                !exceptionConfig.extraWorkdays.some((w) => w.matches(value))
            );
        });
    };

    public disablePast = () => {
        return this.addDisableCheck((value: moment.Moment | null) => {
            return value !== null && value.isBefore(moment().startOf("d"));
        });
    };

    public disableFuture = () => {
        return this.addDisableCheck((value: moment.Moment | null) => {
            return value !== null && value.isAfter(moment().endOf("d"));
        });
    };

    public disablePresent = () => {
        return this.addDisableCheck((value: moment.Moment | null) => {
            return value !== null && value.isSame(moment().startOf("d"), "day");
        });
    };
}

/**
 * Converts the builder's workday exceptions and workdays into
 * moment recurrences for workday exceptions, workdays, and extra workdays
 * @param workdayExceptions - Workday exception entities (includes extra-workdays)
 * @param workdays - Standard workdays
 */
export const getWorkdayExceptionRecurrences = (
    workdayExceptions: IWorkdayException[],
    workdays?: IWorkdays
): IWorkdayExceptionRecurrences => {
    const exceptionsAsRecurrences = workdayExceptions
        .filter((w) => w.type === WorkDayExceptionTypes.NonWorkDay)
        .map((w) => {
            let recurrence = moment(w.date).recur();
            if (w.repeatsAnnually) {
                recurrence = recurrence.every(1).year();
            } else {
                recurrence = recurrence.endDate(w.date);
            }
            return recurrence;
        });
    if (workdays) {
        // Start date has to go back to 1900, otherwise the non-workdays won't apply to the same range as the calendar has available
        exceptionsAsRecurrences.push(
            minMomentDate
                .recur()
                .every(Object.keys(workdays).filter((k) => !workdays[k]))
                .dayOfWeek()
        );
    }

    const extraWorkdaysAsRecurrences = workdayExceptions
        .filter((w) => w.type === WorkDayExceptionTypes.ExtraWorkday)
        .map((w) => {
            let recurrence = moment(w.date).recur();
            if (w.repeatsAnnually) {
                recurrence = recurrence.every(1).year();
            } else {
                recurrence = recurrence.endDate(w.date);
            }
            return recurrence;
        });

    return {
        exceptionWorkdays: exceptionsAsRecurrences,
        extraWorkdays: extraWorkdaysAsRecurrences,
    };
};

export function combineDateTime(
    splitDateTime: SplitDateTime | null | undefined
): moment.Moment | null | undefined {
    // this check is necessary so that when the time is left blank here, it shows as blank in the entity details
    if (splitDateTime?.time) {
        return splitDateTime?.date?.set({
            hour: splitDateTime?.time?.get("hour"),
            minute: splitDateTime?.time?.get("minute"),
            second: splitDateTime?.time?.get("second"),
        });
    } else {
        if (!splitDateTime?.date!) {
            return null;
        } else {
            return moment(splitDateTime?.date!).endOf("d").seconds(0);
        }
    }
}

/**
 * Compares the only the timestamp portion of @param firstDate to that of @param firstDate
 * @param firstDate result will be positive if the time of day is later than that of @param secondDate
 * @param secondDate result will be negative if the time of day is later than that of @param firstDate
 * @returns number of minutes by which @param firstDate differs from @param secondDate
 */
export const compareTimestamp = (firstDate: moment.Moment, secondDate: moment.Moment) => {
    const firstTimestampInMinutes = firstDate.minutes() + firstDate.hours() * 60;
    const secondTimestampInMinutes = secondDate.minutes() + secondDate.hours() * 60;
    return firstTimestampInMinutes - secondTimestampInMinutes;
};

export interface IWorkdayException {
    allJobsites: boolean;
    date: string;
    jobsiteIds: number[] | null | undefined;
    repeatsAnnually: boolean;
    title: string;
    type: WorkDayExceptionTypes;
}

export interface IWorkdays {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
}

export const checkForDateInsideMinMax = (date?: string) => {
    if (date) {
        const newDate = moment(date);
        if (newDate.isBefore(minMomentDate)) {
            return undefined;
        } else if (newDate.isAfter(maxMomentDate)) {
            return undefined;
        } else {
            return newDate;
        }
    }
    return undefined;
};

export const minMomentDate = moment("1900-1-1", "YYYY-M-D");
export const maxMomentDate = moment("9999-12-31", "YYYY-M-D");

export const getTimeAsMinutes = (date?: moment.Moment) => {
    if (date === undefined) {
        return undefined;
    }
    return date.hours() * 60 + date.minutes();
};
