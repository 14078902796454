import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { ReconsentDetailsResponse } from "entity/marketplace/Reconsent/Reconsent.api.types";

export interface IReconsentHandler {
    getReconsentDetails(): Promise<ReconsentDetailsResponse>;
    revokeConsent(): Promise<EmptyResponseEntity>;
}
export class ReconsentHandler implements IReconsentHandler {
    async getReconsentDetails(): Promise<ReconsentDetailsResponse> {
        return await APIHandler("/api/MarketPlaceToken/GetReconsentDetails", {
            method: "GET",
            responseType: ReconsentDetailsResponse,
        });
    }

    async revokeConsent(): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Marketplace/DisconnectBuilderAndRevokeConsent", {
            method: "DELETE",
            responseType: EmptyResponseEntity,
        });
    }
}
