import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

interface IRoutePDFSignatureDetailsProps {
    beforeClose: () => void;
}

export const PDFSignatureDetailsRoute = (props: IRoutePDFSignatureDetailsProps) => (
    <RouteRelative
        path={routes.signatureRequest.standaloneDetails}
        render={(routeProps) => {
            const signatureRequestId = parseInt(routeProps.match.params.id);
            const jobId = parseInt(routeProps.match.params.jobId);
            const documentInstanceId = parseInt(routeProps.match.params.documentInstanceId);

            return (
                <BTSuspense>
                    <BTModal
                        data-testid="btModalPDFSignatureDetails"
                        visible
                        title="Document Details"
                        iframeHeight="800px"
                        width="925px"
                        url={routesWebforms.SignatureRequest.GetDetailsUrl(
                            signatureRequestId,
                            jobId,
                            documentInstanceId,
                            false,
                            true
                        )}
                        beforeClose={() => props.beforeClose()}
                    />
                </BTSuspense>
            );
        }}
    />
);
