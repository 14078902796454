import moment from "moment";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";
import { AccountingIntegrationType } from "types/enum";

import { CompoundCalculationType } from "entity/tax/common/tax.types";
import {
    ITaxRateFormValue,
    NewTaxRateId,
    TaxAgencyEntity,
    TaxRateEntity,
    transformTaxAgencyFromFormValue,
} from "entity/tax/TaxRate/TaxRate.api.types";

import type { default as TaxGroupAPIResponseExample } from "./TaxGroup.api.json";

export type TaxGroupFormActions = undefined | "save" | "delete";

export const MinTaxRatesArrayLength = 2;
export const MaxTaxRatesArrayLength = 7;

export interface ITaxGroupFormValues {
    taxGroupName: string;
    taxRates: ITaxRateFormValue[];
    taxAgencyOptions: BTSelectItem<unknown>[];
    taxRateOptions: BTSelectItem<ITaxRateOptionsExtraData>[];
    isActive: boolean;
    isCompoundTaxRateIncluded: boolean;
    compoundCalculationType?: CompoundCalculationType;
}

export class TaxGroupEntity {
    constructor(data: typeof TaxGroupAPIResponseExample) {
        this.taxGroupId = data.taxGroupId;
        this.builderId = data.builderId;
        this.taxGroupName = data.taxGroupName;
        this.taxAgencyOptions =
            mapBTServiceDropdownToSelectItem(data.taxAgencyOptions)[0].children ?? [];
        this.taxRates = data.taxRates;
        this.taxRateOptions =
            mapBTServiceDropdownToSelectItem<ITaxRateOptionsExtraData>(data.taxRateOptions)[0]
                .children ?? [];
        this.isApplied = data.isApplied;
        this.deactivatedDate = data.deactivatedDate && moment(data.deactivatedDate);
        this.compoundCalculationType = data.compoundCalculationType;
        this.isCompoundTaxRateIncluded = data.isCompoundTaxRateIncluded;
        this.jobsUsingTaxGroup = data.taxGroupInUseJobs.map((x) => new JobUsingTaxGroup(x));
        this.accountingType = data.accountingType;
    }

    taxGroupId: number;
    builderId: number;
    taxGroupName: string;
    taxRates: TaxRateEntity[];
    taxAgencyOptions: BTSelectItem<unknown>[];
    taxRateOptions: BTSelectItem<ITaxRateOptionsExtraData>[];
    isApplied: boolean;
    deactivatedDate: moment.Moment | null;
    compoundCalculationType?: CompoundCalculationType;
    isCompoundTaxRateIncluded: boolean;
    jobsUsingTaxGroup: JobUsingTaxGroup[];
    accountingType?: AccountingIntegrationType;
}

export interface ITaxRateOptionsExtraData {
    taxRate: TaxRateEntity;
    accountingType: AccountingIntegrationType | null;
    isOriginatedFromAccounting: boolean;
}

export class JobUsingTaxGroup {
    constructor(data: (typeof TaxGroupAPIResponseExample.taxGroupInUseJobs)[0]) {
        this.jobId = data.jobId;
        this.jobName = data.jobName;
        this.isJobDefault = data.isJobDefault;
    }
    jobId: number;
    jobName: string;
    isJobDefault: boolean;
}
export class TaxGroupDeleteResponse {
    constructor(data: ITaxGroupDeleteResponse) {
        this.requestedIds = data.requestedIds;
        this.deletedIds = data.deletedIds;
    }
    requestedIds: number[];
    deletedIds: number[];
}

export interface ITaxGroupDeleteResponse {
    requestedIds: number[];
    deletedIds: number[];
}

export class TaxGroupUpdateResponse {
    constructor(data: any) {
        this.taxGroup = new TaxGroupEntity(data);
    }

    taxGroup: TaxGroupEntity;
}

export class TaxGroupCreateRequest {
    constructor(formValues: ITaxGroupFormValues) {
        this.taxGroupName = formValues.taxGroupName;
        this.taxRates = formValues.taxRates.map((tr) =>
            tranformTaxRateFormValueToEntity(tr, formValues.taxAgencyOptions)
        );
        this.isCompoundTaxRateIncluded = formValues.isCompoundTaxRateIncluded;
        this.compoundCalculationType = formValues.compoundCalculationType;
    }

    taxGroupName: string;
    taxRates: {
        taxRateId: number;
        taxRateName: string;
        taxRatePercent: number;
        taxAgency: TaxAgencyEntity;
        isCompoundRate: boolean;
    }[];
    isCompoundTaxRateIncluded: boolean;
    compoundCalculationType?: CompoundCalculationType;
}

export class TaxGroupUpdateRequest extends TaxGroupCreateRequest {
    constructor(formValues: ITaxGroupFormValues) {
        super(formValues);
        this.isActive = formValues.isActive;
    }
    isActive: boolean;
}
export class TaxGroupCreateResponse {
    constructor(data: ITaxGroupDeleteResponse) {
        this.taxGroup = data.taxGroup;
    }
    taxGroup: TaxGroupEntity;
}

export interface ITaxGroupDeleteResponse {
    taxGroup: TaxGroupEntity;
}
function tranformTaxRateFormValueToEntity(
    formValue: ITaxRateFormValue,
    taxAgencyOptions: BTSelectItem<unknown>[]
) {
    const id = formValue.taxRateId && formValue.taxRateId > 0 ? formValue.taxRateId : NewTaxRateId;
    return {
        taxRateId: id,
        taxRateName: formValue.taxRateName,
        taxRatePercent: formValue.taxRatePercent,
        taxAgency: transformTaxAgencyFromFormValue(formValue, taxAgencyOptions),
        isCompoundRate: formValue.isCompoundRate,
    };
}
