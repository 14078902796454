import { createContext } from "react";

import { UserInfo } from "helpers/AppProvider.types";

/**
 * Context containing user and session information, such as the login type
 * of the current user, organization link Ids, the global user Id (deprecated), the time remaining until session
 * expires, and more.
 */
export const UserInfoContext = createContext<UserInfo | undefined>(undefined);

/**
 * Wrapper around component for userInfo context
 */
export const withUserInfoContext =
    <P extends object>(Component: React.ComponentType<P>) =>
    (props: P) =>
        (
            <UserInfoContext.Consumer>
                {(userInfo) => <Component {...props} userInfo={userInfo} />}
            </UserInfoContext.Consumer>
        );
