import yup from "utilities/form/yup";

export const newRoleValidationSchema = (role: Role) => {
    return yup.object().shape<IRolePermissionFormValues>({
        id: yup.number(),
        name: yup
            .string()
            .transform((value: string) => value.toLowerCase().trim())
            .notOneOf(
                role.roles
                    .map((r) => r.name.toLocaleLowerCase())
                    .filter((r) => role.id === 0 || r !== role.name.toLocaleLowerCase()), // Remove the current role's name if it is not a newly created role
                "A Role by this name already exists. Please choose another." // Validation error message
            )
            .max(50)
            .required()
            .label("Role"),
        description: yup.string().max(256).label("Role Description"),
        categories: yup.object().shape({
            PermissionCategory: yup.object(),
        }),
    });
};

export const roleValidationSchema = yup.object().shape<IRolePermissionFormValues>({
    id: yup.number(),
    name: yup.string().max(50).required().label("Role"),
    description: yup.string().max(256).label("Role Description"),
    categories: yup.object().shape({
        PermissionCategory: yup.object(),
    }),
});

export type PermissionTypes = "view" | "add" | "update" | "delete" | "custom";

export const isPermission = (value: any) => {
    return value.enabled !== undefined;
};

export const isExcludedPermission = (value: Permission) => {
    return value.enabled === null;
};

export class CheckAlls {
    constructor() {
        this.permissionCheckAlls = {};
    }

    permissionCheckAlls: {
        [key: string]: PermissionCheckAll;
    };
}

export class PermissionCheckAll {
    constructor() {
        this.permissions = {};
        this.indeterminate = false;
        this.checkAll = false;
        this.checkedKeys = [];
    }

    checkedKeys: string[];
    permissions: {
        [key: string]: Permission;
    };
    indeterminate: boolean;
    checkAll: boolean;
}

export interface IRolePermissionFormValues {
    id: number;
    name: string;
    description: string;
    categories: {
        [key: string]: PermissionCategory;
    };
    isMfaEnabled?: boolean;
    isSystemDefault?: boolean;
}

export interface IHasRole {
    role: Role;
    readOnly: boolean;
}

export class Role {
    constructor(data: any) {
        const {
            id,
            name,
            description,
            isSystemDefault,
            isHidden,
            roles,
            isShowHideVisible,
            isMfaEnabled,
            ...categories
        } = data;
        this.rawData = data;
        this.id = id;
        this.name = name;
        this.description = description;
        this.isSystemDefault = isSystemDefault;
        this.isHidden = isHidden;
        this.isShowHideVisible = isShowHideVisible;
        this.roles = roles ? roles.map((role: any) => new RoleListItem(role)) : [];
        this.categories = {};
        this.key = 0;

        for (let [key, value] of Object.entries(categories)) {
            if (value) {
                this.categories[key] = new PermissionCategory(value);
            }
        }
        this.isMfaEnabled = isMfaEnabled;
    }

    /** overwrites JSON.stringify to return categories directly on the ROOT object (instead of within categories key). categories are expected on the root object on the post endpoint */
    toJSON = () => {
        const { categories, rawData, ...otherKeys } = this;

        return {
            ...otherKeys,
            ...categories,
        };
    };
    rawData: {};
    id: number;
    name: string;
    description: string;
    isSystemDefault: boolean;
    isHidden: boolean;
    isShowHideVisible: boolean;
    roles: RoleListItem[];
    categories: {
        [key: string]: PermissionCategory;
    };
    key: number;
    isMfaEnabled?: boolean;
}

export class RoleListItem {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.isHidden = data.isHidden;
    }

    id: number;
    name: string;
    isHidden: boolean;
}

export class PermissionCategory {
    constructor(category: any) {
        const { name, ...subCategories } = category;

        this.name = name;
        this.subCategories = {};
        this.permissions = {};

        for (let [key, value] of Object.entries(subCategories)) {
            if (value) {
                if (!isPermission(value)) {
                    this.subCategories[key] = new PermissionSubCategory(value);
                } else {
                    this.permissions[key] = new Permission(value);
                }
            }
        }
    }

    /** Overwrites toJSON to flatten out object for api request */
    toJSON = () => {
        const { subCategories, permissions, ...otherKeys } = this;

        return {
            ...otherKeys,
            ...subCategories,
            ...permissions,
        };
    };

    name: string;
    subCategories: {
        [key: string]: PermissionSubCategory;
    };
    permissions: {
        [key: string]: Permission;
    };
}

export class PermissionSubCategory {
    constructor(subCategory: any) {
        const { name, permissionEntryInfo, ...permissions } = subCategory;

        this.name = name;
        this.permissionEntryInfo = permissionEntryInfo;
        this.permissions = {};

        for (let [key, value] of Object.entries(permissions)) {
            if (value) {
                this.permissions[key] = new Permission(value);
            }
        }
    }

    /** Overwrites toJSON to flatten out object for api request */
    toJSON = () => {
        const { permissions, ...otherKeys } = this;

        return {
            ...otherKeys,
            ...permissions,
        };
    };

    name: string;
    permissionEntryInfo?: IPermissionEntryInfo;
    permissions: {
        [key: string]: Permission;
    };
}

export class Permission {
    constructor(permission: any) {
        this.name = permission.name;
        this.enabled = permission.enabled;
        this.readOnly = false;
        this.permissionEntryInfo = permission.permissionEntryInfo;
    }

    name: string;
    enabled: boolean; // whether the permission is checked
    readOnly: boolean;
    permissionEntryInfo?: IPermissionEntryInfo;
}

export class GetRolesByIdsResponse {
    constructor(data: any) {
        this.roles = data ? data.map((role: any) => new Role(role)) : [];
    }

    roles: Role[];
}

export interface IPermissionEntryInfo {
    featurePermissions?: string[];
    infoText: string;
}
