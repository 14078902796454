import { APIHandler } from "utilities/apiHandler";
import { EmptyStateEntity } from "utilities/list/list.types";

import { EmptyStateDismissResponse } from "commonComponents/entity/emptyState/common/EmptyState.api.types";

export interface IEmptyStateHandler {
    dismissAlert(type: EmptyStateEntity): Promise<EmptyStateDismissResponse>;
}

export class EmptyStateHandler implements IEmptyStateHandler {
    async dismissAlert(type: EmptyStateEntity) {
        return await APIHandler(`/api/EmptyOrInitialStateAlert/Dismiss?entity=${type}`, {
            method: "POST",
            responseType: EmptyStateDismissResponse,
        });
    }
}
