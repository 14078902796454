import { Menu } from "antd";
import { MenuClickEventHandler, MenuInfo } from "rc-menu/lib/interface";
import { CSSProperties, KeyboardEventHandler, MouseEventHandler } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";

export interface IBTMenuItemProps {
    className?: string | undefined;
    /**
     * @example
     * "edit" | "annotate" | "print" | "delete"
     */
    "data-testid": string;
    disabled?: boolean;
    icon?: React.ReactNode;
    onClick?: MenuClickEventHandler;
    onKeyDown?: KeyboardEventHandler<HTMLLIElement> | undefined;
    onMouseDown?: MouseEventHandler<HTMLLIElement> | undefined;
    style?: CSSProperties | undefined;
    tabIndex?: number | undefined;
    title?: React.ReactNode;
}

export const BTMenuItem: React.FC<IBTMenuItemProps & ITrackingProp> = track((props) => ({
    element: "MenuItem",
    uniqueId: props["data-testid"],
}))(({ onClick, children, tracking, ...otherProps }) => {
    const handleClick = (info: MenuInfo) => {
        onClick?.(info);
        tracking?.trackEvent({ event: "MenuItemClick" });
    };

    return (
        // eslint-disable-next-line react/forbid-elements
        <Menu.Item {...otherProps} tabIndex={0} onClick={handleClick}>
            {children}
        </Menu.Item>
    );
});
