import moment from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import yup from "utilities/form/yup";

import { ILineItemGroup } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    baseLineItemSchemaDefinition,
    markupLineItemSchemaDefinition,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.schemas";
import { IBaseLineItem } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { WorksheetLineItem } from "entity/estimate/common/estimate.common.types";

export type EstimateLineItemDetailsFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "saveAndNew"
    | "delete";

export class EstimateLineItemDetailsEntity {
    constructor(data: any) {
        this.id = data.id;

        this.title = data.title;
        this.description = data.description;
        this.approved = data.approved;
        this.scheduledStart = data.scheduledStart ? moment(data.scheduledStart) : moment();
        this.favoriteFruit = data.favoriteFruit;

        this.builderCost = data.builderCost;
        this.ownerCost = data.ownerCost;

        this.priority = data.priority.value.map((p: any) => new BTSelectItem(p));
        this.assignedUsers = data.assignedUsers.value.map((u: any) => new BTSelectItem(u));
    }

    id: number;

    title: string;
    description: string;
    approved: boolean;
    scheduledStart: moment.Moment;
    favoriteFruit: number;

    builderCost: number;
    ownerCost: number;

    priority: BTSelectItem[];
    assignedUsers: BTSelectItem[];
}

export class EstimateLineItemDetailsDeleteResponse {}

export class EstimateLineItemDetailsCreateResponse {}

const baseLineItemEstimatesSchemaDefinition = {
    ...baseLineItemSchemaDefinition,
    ...markupLineItemSchemaDefinition,
    profit: yup.number().label("Profit"),
    assemblyId: yup.number().nullable().label("Assembly"),
    taxGroupId: yup.number().label("Tax"),
};

type BaseLineItemEstimatesSchemaType = typeof baseLineItemEstimatesSchemaDefinition;
export function getLineItemEstimatesSchema(
    getExtendedSchema: (
        baseSchema: BaseLineItemEstimatesSchemaType
    ) => BaseLineItemEstimatesSchemaType = (s) => s
) {
    return yup.object<IBaseLineItem>(getExtendedSchema(baseLineItemEstimatesSchemaDefinition));
}

export const lineItemGroupSchema = yup.object().shape<ILineItemGroup<WorksheetLineItem>>({
    id: yup.number(),
    title: yup.string().required().label("Title"),
    isExpanded: yup.boolean(),
    items: yup.array(),
});
