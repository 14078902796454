import { Component, useContext } from "react";

import { default as ReactNumberFormat } from "@buildertrend/react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { round } from "utilities/math/math";

import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

interface IPercentageDisplayProps {
    value: number;

    /** @default 2 */
    decimalScale?: number;

    /** Percentage will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    "data-testid": string;

    className?: string;

    shouldTruncate?: boolean;

    /** @default true */
    fixedDecimalScale?: boolean;
}

interface IPercentageDisplayInternalProps extends IPercentageDisplayProps {
    builderCurrency: CurrencyLocale;
}

class PercentageDisplayInternal extends Component<IPercentageDisplayInternalProps> {
    static defaultProps = {
        decimalScale: 2,
        fixedDecimalScale: true,
    };
    render() {
        const {
            value,
            decimalScale,
            builderCurrency,
            "data-testid": dataTestId,
            className,
            shouldTruncate,
            fixedDecimalScale,
        } = this.props;
        const roundedValue = round(value, decimalScale);

        const displayValue = (
            <ReactNumberFormat
                className={className!}
                value={roundedValue}
                displayType="text"
                decimalScale={decimalScale}
                fixedDecimalScale={fixedDecimalScale}
                thousandSeparator={builderCurrency.groupSeparator}
                decimalSeparator={builderCurrency.decimalSeparator}
                thousandsGroupStyle={builderCurrency.thousandsGroupStyle}
                dir="ltr"
                suffix="%"
                data-testid={dataTestId}
            />
        );

        if (shouldTruncate) {
            return <TextTruncate>{displayValue}</TextTruncate>;
        }

        return displayValue;
    }
}

export const PercentageDisplay: React.FunctionComponent<IPercentageDisplayProps> = (props) => {
    const builderInfo = useContext(BuilderInfoContext);
    const builderCurrency =
        props.forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo.locale.currencyLocale;
    return <PercentageDisplayInternal builderCurrency={builderCurrency} {...props} />;
};
