import { Col, ColProps } from "antd";
import { Component } from "react";

type FlexType = number | "none" | "auto" | string;

// These props are based off AntDesign's v4 Col (https://github.com/ant-design/ant-design/blob/master/components/grid/col.tsx)
// Do not change this without consulting architecture first
interface IBTColProps extends ColProps {
    flex?: FlexType;
}

// TODO: After upgrading to v4, we can remove this parsing logic for the flex property
function parseFlex(flex: FlexType): string {
    if (typeof flex === "number") {
        return `${flex} ${flex} auto`;
    }

    if (flex === "none") {
        return "0 0 auto";
    }

    if (flex === "auto") {
        return "1 1 auto";
    }

    return flex;
}

export class BTCol extends Component<IBTColProps> {
    render() {
        const { style, flex, ...rest } = this.props;
        const mergedStyle = {
            ...style,
            flex: flex && parseFlex(flex),
        };
        // eslint-disable-next-line react/forbid-elements
        return <Col {...rest} style={mergedStyle} />;
    }
}
