export class StatusObject {
    constructor(data: any) {
        this.message = data.message || data.Message;
        this.foreColor = data.foreColor || data.ForeColor;
        this.statusIcon = data.statusIcon;
        this.imageWidth = data.imageWidth || data.ImageWidth;
        this.relatedDate = data.relatedDate || data.RelatedDate;
    }

    message: string;
    relatedDate: Date | undefined;
    foreColor: string;
    statusIcon: string;
    imageWidth: number;
}
