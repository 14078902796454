import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IMessageDetailsProps } from "./Message";

const MessageDetails = lazyLoadWithRetry(() => import("./Message"));

export const RouteMessages = (
    props: Omit<
        IMessageDetailsProps,
        "messageId" | "isGlobal" | "isSent" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.messages.details}
        render={(routeProps) => {
            const messageId = parseInt(routeProps.match.params.messageId);
            const folderId = parseInt(routeProps.match.params.folderId);
            return (
                <BTSuspense>
                    <MessageDetails
                        {...props}
                        {...routeProps}
                        messageId={messageId}
                        folderId={folderId}
                    />
                </BTSuspense>
            );
        }}
    />
);
