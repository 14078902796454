import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IShiftProps } from "entity/timeClock/Shift/Shift";
import { ShiftDetailsTab } from "entity/timeClock/Shift/Shift.api.types";

const Shift = lazyLoadWithRetry(() => import("./Shift"));

export const RouteShift = (
    props: Omit<IShiftProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.timeClock.shiftDetails}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const id = parseInt(routeValues.id);
            const jobId = parseInt(routeValues.jobId);
            const tab = ShiftDetailsTab[routeValues.tab as string] ?? ShiftDetailsTab.General;

            return (
                <BTSuspense>
                    <Shift {...props} {...routeProps} id={id} jobId={jobId} initialTab={tab} />
                </BTSuspense>
            );
        }}
    />
);
