import moment from "moment";

import {
    BTSelectItem,
    IWithServiceValidationErrors,
    ServiceValidation,
} from "types/apiResponse/apiResponse";
import { AccountingIntegrationType, JobOrigin } from "types/enum";

import { alphabetize } from "utilities/form/form";

import {
    IEntityExtraData,
    LinkingAction,
} from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { MapLinkedAccountingContactOptions } from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptionsUtils";
import { IContactSelectorFormValues } from "commonComponents/entity/contact/ContactSelector/ContactSelector.api.types";
import { IJobTypeExtraData } from "commonComponents/entity/job/JobType/JobType";
import { WorkdaysSelectorFormValues } from "commonComponents/entity/workdaysSelector/WorkdaysSelector/WorkdaysSelector.api.types";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";

export class JobFromTemplateEntity {
    constructor(data: any) {
        this.jobGroups =
            data.jobGroup && alphabetize(data.jobGroup.map((item: any) => new BTSelectItem(item)));
        this.canLinkJobToAccounting = data.canLinkJobToAccounting;
        this.accountingIntegration = data.accountingIntegration;
        this.linkingAction = data.linkingAction;
        this.linkingOptions = MapLinkedAccountingContactOptions(data);
        this.contactOptions =
            data.contactOptions && data.contactOptions.map((item: any) => new BTSelectItem(item));
        this.isFirstLastNameVisible = data.isFirstLastNameVisible;
        this.leadTitle = data.leadTitle;
        this.hasScheduleEditPerms = data.hasScheduleEditPerms;
        this.hasExistingEstimates = data.hasExistingEstimates;
        this.canImportEstimates = data.canImportEstimates;
        this.hasBidsAddPerm = data.hasBidsAddPerm;
        this.hasPOsAddPerm = data.hasPOsAddPerm;
        this.hasEstimatesAddPerm = data.hasEstimatesAddPerm;

        // templates doesn't come with an unselected option
        // we'd like for the service to send this option down, but it would currently mess up mobile because
        // it treats it as an option to use api calls on and throws errors, maybe something to bring up to standard later
        this.templates =
            data.templates && data.templates.value.map((item: any) => new BTSelectItem(item));
        if (this.templates) {
            this.templates.unshift(
                new BTSelectItem({ id: -1, name: "-- Choose a Template --", selected: true })
            );
        }

        // combine two job types properties
        let types =
            data.jobType &&
            data.jobType.map((item: any) => new BTSelectItem<IJobTypeExtraData>(item));
        let groupedTypes =
            data.groupedJobType &&
            data.groupedJobType.map((item: any) => new BTSelectItem<IJobTypeExtraData>(item));
        this.jobType = types && groupedTypes && types.concat(groupedTypes);
        this.contractTypes = data?.contractTypes.map((item: unknown) => new BTSelectItem(item));
    }

    templates: BTSelectItem[];
    jobGroups: BTSelectItem[];
    jobType: BTSelectItem<IJobTypeExtraData>[];
    contractTypes: BTSelectItem[];
    canLinkJobToAccounting: boolean;
    accountingIntegration: AccountingIntegrationType;
    linkingOptions: BTSelectItem<IEntityExtraData>[];
    linkingAction: LinkingAction;
    contactOptions: BTSelectItem[];
    isFirstLastNameVisible: boolean;
    leadTitle: string;
    hasScheduleEditPerms: boolean;
    hasExistingEstimates: boolean;
    canImportEstimates: boolean;
    hasBidsAddPerm: boolean;
    hasPOsAddPerm: boolean;
    hasEstimatesAddPerm: boolean;
}

export type JobFromTemplateFormActions = undefined | "save";

export interface IJobFromTemplateFormValues extends IContactSelectorFormValues {
    jobOrigin: JobOrigin;
    jobName: string;
    startDate: moment.Moment | undefined;
    jobGroup: number[];
    groupedProjectType: number;
    contractType: number | undefined;
    calendarOnline: boolean;
    sourceTemplate: number;
    workDays: WorkdaysSelectorFormValues;
    whatToCopy: number[];
    notifyProjectManager: boolean;
    linkingEntity: JobsiteLinkingTypes;
    linkingAction: LinkingAction;
    linkingCustomer: string | undefined;
    linkingJob: string | undefined;
    createdFromLeads: boolean;
    leadId: number;
    leadSoldDate: string | undefined;
    isDisplayNameRequired: boolean;
    subPermissions: number[];
}

export class JobsiteUpdateResponse implements Partial<IWithServiceValidationErrors> {
    constructor(data: any) {
        this.id = data.id;
        this.jobCreated = data.jobCreated;
        this.shouldConnectToAccounting = data.shouldConnectToAccounting;
        this.formMessage = data.formMessage;
        this.failedFields = data.failedFields;
        this.ownerInvoiceId = data.ownerInvoiceId;
        this.backgroundJobId = data.backgroundJobId;
    }

    id: number;
    jobCreated: boolean;
    shouldConnectToAccounting: boolean;
    formMessage?: string;
    failedFields?: ServiceValidation[];
    ownerInvoiceId?: number;
    backgroundJobId?: number;
}
