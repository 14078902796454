import { message } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { AxiosError } from "axios";
import moment from "moment";
import queryString from "query-string";
import { ChangeEvent, Component } from "react";
import { RouteComponentProps } from "react-router";

import { ExceptionResponse } from "types/apiResponse/apiResponse";
import { ActivationStatus, BTLoginTypes } from "types/enum";

import { NoAccessError, showAPIErrorMessage, showApixErrorMessage } from "utilities/apiHandler";
import { routesWebforms } from "utilities/routesWebforms";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { btConfirmAsync, btInfo } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import BTSearch from "commonComponents/btWrappers/BTSearch/BTSearch";
import { BTSpin } from "commonComponents/btWrappers/BTSpin/BTSpin";
import { BTTable, IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { UserActivationStatusTag } from "commonComponents/entity/userActivation/UserActivationStatusTag/UserActivationStatusTag";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import {
    adminUsersOrgLinkUsersGetV2,
    AdminUsersOrgLinkUsersGetV2Params,
    OrgLinkUser,
} from "handlers";

import "entity/contact/BtAdmin/BTAdminContactsTable.less";
import { ILoginHandler, LoginHandler } from "entity/login/Login/Login.api.handler";

interface IBtAdminContactListProps extends RouteComponentProps {
    loginHandler?: ILoginHandler;
}

interface IBtAdminContactListPropsInternal extends IBtAdminContactListProps {
    builderId: number;
    loginTypes: BTLoginTypes[];
}

interface IBtAdminContactListState {
    contacts: OrgLinkUser[];
    fullContactList: OrgLinkUser[];
    pagination: TablePaginationConfig;
    tableLoading: boolean;
    isSendingPasswordResetEmail: boolean;
    searchString: string;
}

class BtAdminContactListInternal extends Component<
    IBtAdminContactListPropsInternal,
    IBtAdminContactListState
> {
    private defaultPageSize = 20;
    private defaultPage = 1;

    static defaultProps = {
        loginHandler: new LoginHandler(),
        loginTypes: [BTLoginTypes.CONTACT],
    };

    state: Readonly<IBtAdminContactListState> = {
        contacts: [],
        fullContactList: [],
        pagination: {
            defaultPageSize: this.defaultPageSize,
            defaultCurrent: this.defaultPage,
            total: 0,
            pageSizeOptions: ["20", "50", "75", "100", "250"],
            showTotal: (total: number, range: number[]) =>
                `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            position: ["bottomRight"],
        },
        tableLoading: true,
        isSendingPasswordResetEmail: false,
        searchString: "",
    };

    componentDidMount = async () => {
        try {
            await this.load();
            this.setState({ tableLoading: false });
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    };

    private load = async () => {
        const { builderId, loginTypes } = this.props;

        const params: AdminUsersOrgLinkUsersGetV2Params = { builderId, loginTypes };

        const result = await adminUsersOrgLinkUsersGetV2(params).catch(
            (ex: AxiosError<ExceptionResponse>) => {
                showApixErrorMessage(ex);
            }
        );

        if (result?.data.orgLinkUsers) {
            this.setState({
                contacts: result.data.orgLinkUsers,
                fullContactList: result.data.orgLinkUsers,
            });
        }
    };

    private onPasswordResetClick = async (userName: string) => {
        const resp = await btConfirmAsync({
            title: "Reset Password?",
            content: "This will send out a reset password email to the user.",
            okText: "Reset",
        });

        if (resp === "ok") {
            this.setState({ isSendingPasswordResetEmail: true });
            await this.onPasswordConfirmClick(userName);
        }
    };

    private onPasswordConfirmClick = async (userName: string) => {
        try {
            // only usernameReset is needed on this call
            await this.props.loginHandler?.forgotPassword({
                username: "",
                emailReset: "",
                captchaResponse: "",
                password: "",
                usernameReset: userName,
            });
            void message.success("Password Reset email sent successfully!");
            this.setState({ isSendingPasswordResetEmail: false });
        } catch (ex) {
            showAPIErrorMessage(ex);
            this.setState({ isSendingPasswordResetEmail: false });
        }
    };

    private onMobileImpersonationClick = async (credentialId: number, name: string) => {
        const response = await this.props.loginHandler
            ?.impersonationCode(credentialId)
            .catch((err: Error) => {
                if (err instanceof NoAccessError) {
                    // need to prompt to login - this behavior is built in automatically in BTadmin, recreating here.
                    window.location.reload();
                } else if (err.message) {
                    void message.error(err.message);
                } else {
                    void message.error("There was a problem generating Mobile Code.");
                }
            });

        if (response) {
            void btInfo({
                content: (
                    <div style={{ textAlign: "center", fontWeight: 500 }}>
                        <div>To login as {name}, use the following credentials:</div>
                        <div>UserName: {response.userName}</div>
                        <div>AuthCode: {response.authCode}</div>
                        <div>This code expires in 1 minute or after one use.</div>
                    </div>
                ),
                width: 520,
            });
        }
    };

    private getActivationStatusForSorting(activationStatus: ActivationStatus) {
        switch (activationStatus) {
            case ActivationStatus.Active:
                return 0;
            case ActivationStatus.Pending:
                return 1;
            case ActivationStatus.Disabled:
                return 2;
            case ActivationStatus.NotSent:
                return 3;
        }
    }
    private handleSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
        let searchString: string;
        if (typeof e === "string") {
            searchString = e;
        } else {
            searchString = e.target.value;
        }
        this.setState({
            searchString,
        });
    };

    private handleSearchButtonClick = () => {
        const { fullContactList, searchString } = this.state;

        if (this.state.searchString === "") {
            this.setState({
                contacts: fullContactList,
            });
        }

        const lastNameContacts = fullContactList.filter((x) =>
            x.lastName.toLowerCase().includes(searchString.toLowerCase())
        );
        const firstNameContacts = fullContactList.filter((x) =>
            x.firstName.toLowerCase().includes(searchString.toLowerCase())
        );
        const displayNameContacts = fullContactList.filter((x) =>
            x.displayName.toLowerCase().includes(searchString.toLowerCase())
        );
        const emailContacts = fullContactList.filter((x) =>
            x.email.toLowerCase().includes(searchString.toLowerCase())
        );
        const usernameContacts = fullContactList.filter((x) =>
            x.userID.toLowerCase().includes(searchString.toLowerCase())
        );

        const filteredContacts = [
            ...new Set([
                ...firstNameContacts,
                ...lastNameContacts,
                ...emailContacts,
                ...usernameContacts,
                ...displayNameContacts,
            ]),
        ];

        this.setState({
            contacts: filteredContacts,
        });
    };

    render() {
        if (!this.state) {
            return <BTSpin size="large" />;
        }

        const { contacts, pagination, tableLoading } = this.state;

        let columnProps: IBTColumnProps<OrgLinkUser>[] = [];

        columnProps.push({
            title: "Login ID",
            key: "contactId",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return contact.userID && contact.credentialId ? (
                    <BTExternalLink
                        href={routesWebforms.BTAdmin.getAdminAutoLoginLink(
                            BTLoginTypes.CONTACT,
                            contact.id
                        )}
                        isUnderline={true}
                    >
                        {contact.id}
                    </BTExternalLink>
                ) : (
                    <>{contact.id}</>
                );
            },
            sorter: (a, b) => a.id - b.id,
        });

        columnProps.push({
            title: "Display Name",
            key: "displayName",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return <>{contact.displayName}</>;
            },
            sorter: (a, b) => a.displayName.localeCompare(b.displayName),
        });

        columnProps.push({
            title: "First Name",
            key: "firstName",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return <>{contact.firstName}</>;
            },
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        });

        columnProps.push({
            title: "Last Name",
            key: "lastName",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return <>{contact.lastName}</>;
            },
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        });

        columnProps.push({
            title: "Email",
            key: "email",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return <>{contact.email}</>;
            },
        });

        columnProps.push({
            title: "Username",
            key: "username",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return <>{contact.userID}</>;
            },
        });
        columnProps.push({
            title: "Activation Status",
            key: "activationStatus",
            align: "left",
            width: "130px",
            render: (_, contact) => {
                return (
                    <>
                        <UserActivationStatusTag
                            activationStatus={contact.activation}
                            activationSent={
                                contact.activationSent ? moment(contact.activationSent) : undefined
                            }
                            activationConfirmed={
                                contact.activationConfirmed
                                    ? moment(contact.activationConfirmed)
                                    : undefined
                            }
                        />
                    </>
                );
            },
            sorter: (a, b) =>
                this.getActivationStatusForSorting(a.activation) -
                this.getActivationStatusForSorting(b.activation),
        });
        columnProps.push({
            title: "Password Reset",
            key: "passReset",
            align: "left",
            width: "130px",
            render: (contact) => {
                return (
                    contact.userName && (
                        <BTButton
                            data-testid={`password-reset-submit-${contact.id}`}
                            data-id={contact.id}
                            type="secondary"
                            onClick={async () => {
                                await this.onPasswordResetClick(contact.userName);
                            }}
                        >
                            Reset
                        </BTButton>
                    )
                );
            },
        });
        columnProps.push({
            title: "Mobile Login (Internal Use Only)",
            key: "mobileLogin",
            align: "left",
            width: "130px",
            render: (contact) => {
                return (
                    contact.userName &&
                    contact.credentialId && (
                        <BTButton
                            data-testid={`mobile-login-submit-${contact.id}`}
                            type="secondary"
                            onClick={async () => {
                                await this.onMobileImpersonationClick(
                                    contact.credentialId,
                                    contact.displayName
                                );
                            }}
                        >
                            Generate Code
                        </BTButton>
                    )
                );
            },
        });

        return (
            <div className="BtAdminContactsTable">
                <BTTitle style={{ textAlign: "center" }} data-testid="btAdminContactsTitle">
                    Contacts
                </BTTitle>
                <FormItem label="Search by name, email, or username">
                    <BTSearch
                        id="nameSearch"
                        data-testid="nameSearch"
                        style={{ width: "25%", float: "left" }}
                        onChange={this.handleSearchChange}
                        onSearch={this.handleSearchButtonClick}
                        value={this.state.searchString}
                        placeholder="Name, Email, or Username"
                    />
                </FormItem>
                {tableLoading ? (
                    <BTSpin size="large" />
                ) : (
                    <>
                        <BTTable
                            dataSource={contacts}
                            columns={columnProps}
                            rowKey={(record) => `${record.id.toString()}`}
                            pagination={pagination}
                        />
                        {this.state.isSendingPasswordResetEmail && (
                            <BTLoading displayMode="fixed" />
                        )}
                    </>
                )}
            </div>
        );
    }
}

export const BtAdminContactsTable: React.FunctionComponent<IBtAdminContactListProps> = (props) => {
    // builderId from props in btadmin is tied to job.  we need from params in btadmin context
    // in the future if this is reused elsewhere with different builderId context can be split up and passed differently here
    // eslint-disable-next-line deprecate/member-expression
    const parsedBuilderId = queryString.parse(props.location.search?.toLowerCase()).builderid;
    const builderId = parsedBuilderId && !isNaN(+parsedBuilderId) ? +parsedBuilderId : -1;

    return (
        <BtAdminContactListInternal
            {...props}
            loginTypes={[BTLoginTypes.CONTACT]}
            builderId={builderId}
        >
            {props}
        </BtAdminContactListInternal>
    );
};

export default BtAdminContactsTable;
