import { default as ChatSearchApiExampleJson } from "entity/chat/ChatSearch/chatSearch.example.json";
import { Channel, ChannelMessage } from "entity/chat/common/chat.types";

export interface IChatSearchState {
    searchValue: string;
    results?: ChatSearchResponse;
    error?: IChatSearchError;
}

export class ChatSearchResponseItem {
    constructor(data: (typeof ChatSearchApiExampleJson.data)[0]) {
        this.channel = new Channel(data.channel);
        this.message = new ChannelMessage(data.message);
    }

    channel: Channel;
    message: ChannelMessage;
}

export class ChatSearchResponse {
    constructor(data: typeof ChatSearchApiExampleJson) {
        this.data = data.data.map((datum) => new ChatSearchResponseItem(datum));
        this.nextCursor = data.nextCursor;
        this.previousCursor = data.previousCursor;
    }

    data: ChatSearchResponseItem[];
    nextCursor: string | null;
    previousCursor: string | null;
}

export interface IChatSearchError {
    message?: string;
}
