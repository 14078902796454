export type ProposalPrintLoadingActions = "setDefaults" | undefined;

export const StartClosingTextOnNewPageText: string = "Start Closing Text on New Page";
export const NoGapBetweenIntroductionAndFirstItemText: string =
    "No Gap Between Introductory Text and First Group";
export const MoveProposalTotalsToClosingPageText: string =
    "Move Proposal Totals to Closing Text Page";
export const AllowPageBreaksInsideLineItemsText: string = "Allow Page Breaks Inside Line Items";
export const AllowPageBreaksInsideGroupDescriptionsText: string =
    "Allow Page Breaks Inside Group Descriptions";
export const ShowAllImagesText: string = "Show All Images";

export class ProposalPrintOptions {
    constructor(data?: any) {
        this.showAllImages = data?.showAllImages ?? false;
        this.noGapBetweenIntroductionAndFirstItem =
            data?.noGapBetweenIntroductionAndFirstItem ?? false;
        this.allowPageBreaksInsideGroupDescriptions =
            data?.allowPageBreaksInsideGroupDescriptions ?? false;
        this.showLineItemsControls = data?.showLineItemsControls ?? false;
        this.allowPageBreaksInsideLineItems = data?.allowPageBreaksInsideLineItems ?? false;
        this.startClosingTextOnNewPage = data?.startClosingTextOnNewPage ?? false;
        this.moveProposalTotalsToClosingPage = data?.moveProposalTotalsToClosingPage ?? false;
    }

    showAllImages: boolean;

    noGapBetweenIntroductionAndFirstItem: boolean;

    allowPageBreaksInsideGroupDescriptions: boolean;

    showLineItemsControls: boolean;
    allowPageBreaksInsideLineItems: boolean;

    startClosingTextOnNewPage: boolean;

    moveProposalTotalsToClosingPage: boolean;
}

export const ArePrintOptionsEqual = (
    options1: ProposalPrintOptions,
    options2: ProposalPrintOptions
) => {
    return !(
        options1.allowPageBreaksInsideGroupDescriptions !==
            options2.allowPageBreaksInsideGroupDescriptions ||
        options1.allowPageBreaksInsideLineItems !== options2.allowPageBreaksInsideLineItems ||
        options1.moveProposalTotalsToClosingPage !== options2.moveProposalTotalsToClosingPage ||
        options1.noGapBetweenIntroductionAndFirstItem !==
            options2.noGapBetweenIntroductionAndFirstItem ||
        options1.startClosingTextOnNewPage !== options2.startClosingTextOnNewPage ||
        options1.showAllImages !== options2.showAllImages
    );
};
