import { PropsWithChildren, useCallback, useContext } from "react";
import { Link, LinkProps, useHistory, useRouteMatch } from "react-router-dom";

import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { routes } from "utilities/routes";
import { scrollToElementById } from "utilities/scroll/scroll";

import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";

import "./BTLink.less";

/**
 * If using linkType 'scroll', make sure that your href is the same as the id of the element you want to scroll to
 */
interface IBTLinkProps extends Omit<LinkProps, "target" | "rel"> {
    shouldOpenNewTab?: boolean;
    /**
     * @default true
     */
    isUnderline?: boolean;
    "data-testid"?: string;
    linkType?: "link" | "list" | "menuList" | "scroll" | "tel" | "email";
    isDisabled?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;

    /**
     * Automatically handles links to SPA pages and adds /app in front of the rotue if needed
     */
    useAutoSPARouting?: boolean;
}

function BTLinkInternal({
    children,
    className = "",
    hotkey,
    href,
    isDisabled = false,
    isUnderline = true,
    linkType,
    onClick,
    shouldOpenNewTab = false,
    tracking,
    useAutoSPARouting,
    ...restProps
}: PropsWithChildren<IBTLinkProps & ITrackingProp>) {
    const history = useHistory();

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                onClick(event);
            }
            if (linkType === "scroll") {
                scrollToElementById(href!);
            }

            tracking?.trackEvent({ event: "LinkClick" });
        },
        [href, linkType, onClick, tracking]
    );

    if (isDisabled) {
        return <>{children}</>;
    }

    let linkListStyling = "";
    switch (linkType) {
        case "link":
            break;

        case "list":
            linkListStyling = "linkListItem";
            break;

        case "menuList":
            linkListStyling = "menuListItem";
            break;
    }
    const underlineClass = isUnderline ? "underline" : "nounderline";
    const classNameWithDefault = `${className} BTLink ${underlineClass} ${linkListStyling}`;

    let modifiedTarget: "_blank" | undefined;
    let modifiedRel: "noopener noreferrer" | undefined;

    if (shouldOpenNewTab) {
        modifiedTarget = "_blank";
        modifiedRel = "noopener noreferrer";
    }

    if (linkType === "tel") {
        const { to, ...propsWithoutTo } = restProps;
        return (
            <a className={classNameWithDefault} href={href} {...propsWithoutTo}>
                {children}
            </a>
        );
    }

    const link =
        restProps.to.toString() === "" ? (
            <a
                {...restProps}
                className={classNameWithDefault}
                target={modifiedTarget}
                onClick={handleClick}
                rel={modifiedRel}
                href={href}
            >
                {children}
            </a>
        ) : (
            <Link
                {...restProps}
                className={classNameWithDefault}
                target={modifiedTarget}
                onClick={handleClick}
                rel={modifiedRel}
                href={href}
            >
                {children}
            </Link>
        );

    if (hotkey) {
        return (
            <Hotkey
                {...getHotkeyProps(hotkey)}
                disabled={isDisabled}
                onCommand={() => {
                    if (onClick) {
                        onClick({} as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
                    }
                    if (shouldOpenNewTab) {
                        window.open(restProps.to.toString(), "_blank");
                    } else if (restProps.to) {
                        history.push(restProps.to.toString());
                    } else if (href) {
                        window.location.assign(href);
                    }
                }}
            >
                {link}
            </Hotkey>
        );
    }
    return link;
}

export const BTLink = track((props) => ({
    element: "Link",
    uniqueId: props["data-testid"],
}))(function ({ to, href, useAutoSPARouting, ...otherProps }: IBTLinkProps) {
    const spaInfoContext = useContext(SpaInfoContext);

    // temporary until all warnings are gone - just to be safe
    let toValue = to;
    if (toValue?.toString().toLowerCase().startsWith("/app")) {
        toValue = toValue.toString().replace("/app", "");
        console.warn("Do not pass /app as part of your route. Pass the part after /app");
    }

    /**
     *
     * Update 'to' field to dynamically add base route automatically
     * Remove once we are fully SPA
     */
    const updateTo = () => {
        if (!useAutoSPARouting) {
            return toValue;
        }
        const isSPA = spaInfoContext?.isSpa;
        if (isSPA) {
            return toValue.toString();
        } else {
            return "";
        }
    };

    /**
     *
     * Redirect 'to' fields to use href links when we are not in SPA
     * Remove once we are fully SPA
     */ const updateHref = () => {
        if (!useAutoSPARouting) {
            return href;
        }

        const isSPA = spaInfoContext?.isSpa;
        if (isSPA) {
            return "";
        } else {
            if (toValue.toString() === "") {
                return href;
            } else {
                if (toValue.toString().includes(".aspx")) {
                    console.warn(
                        "Do not pass webform urls through BTLink's to prop, use BTExternalLink or href instead"
                    );
                    return toValue.toString();
                } else {
                    return `${routes.appBase}${toValue.toString()}`;
                }
            }
        }
    };

    return <BTLinkInternal to={updateTo()} href={updateHref()} {...otherProps} />;
});

/**
 * Use this link whenever navigating to a route nested within your page
 * @param props Whatever props you would normally pass to BTLink
 */
export const BTLinkRelative: React.FunctionComponent<IBTLinkProps> = track((props) => ({
    element: "Link",
    uniqueId: props["data-testid"],
}))((props: IBTLinkProps & ITrackingProp) => {
    const { to, ...rest } = props;
    const match = useRouteMatch();
    return <BTLinkInternal {...rest} to={`${match.url}${to}`} />;
});
