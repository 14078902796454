import { CustomArrowProps } from "@ant-design/react-slick";
import { CarouselProps } from "antd";
import Carousel, { CarouselRef } from "antd/lib/carousel";
import classNames from "classnames";
import { Component, createRef } from "react";

import { BTIconLeftOutlined, BTIconRightOutlined } from "commonComponents/btWrappers/BTIcon";

import "./BTCarousel.less";

type OmmitedProps = "arrows" | "nextArrow" | "prevArrow";
const theme = ["dark", "light"] as const;
type Theme = (typeof theme)[number];

interface IBTCarouselItemProps {
    /**
     * Boolean for whether the item has extra content to render so that the carousel buttons can be moved
     */
    isContent?: boolean;
}

export class BTCarouselItem extends Component<IBTCarouselItemProps> {
    render() {
        return (
            <div
                className="BTCarouselItem"
                style={this.props.isContent ? { paddingBottom: "48px" } : {}}
            >
                {this.props.children}
            </div>
        );
    }
}

interface IBTCarouselProps extends Omit<CarouselProps, OmmitedProps> {
    /**
     * Class name to be passed directly to the outer carousel container
     */
    className?: string;
    /**
     * Class name to be passed directly to the carousel
     */
    carouselClassName?: string;
    /**
     * Styling for the dots that appear at the bottom of the carousel
     * @default 'dark'
     */
    dotTheme?: Theme;
    /**
     * Styling for the arrows that appear at the left and right of the carousel
     * @default 'dark'
     */
    arrowTheme?: Theme;
    "data-testid"?: string;
}

export const BTCarouselDefaultProps: Readonly<Partial<IBTCarouselProps>> = {
    className: "",
    carouselClassName: "",
    arrowTheme: "dark",
    dotTheme: "dark",
};

interface IArrowProps extends CustomArrowProps {
    arrowTheme?: string;
}

const NextArrow = ({
    arrowTheme,
    className,
    currentSlide,
    slideCount,
    ...restArrowProps
}: IArrowProps) => (
    <BTIconRightOutlined {...restArrowProps} className={classNames(arrowTheme, className)} />
);
const PrevArrow = ({
    arrowTheme,
    className,
    currentSlide,
    slideCount,
    ...restArrowProps
}: IArrowProps) => (
    <BTIconLeftOutlined {...restArrowProps} className={classNames(arrowTheme, className)} />
);

/**
 * ## Usage Notes
 *
 * When using the carousel, the children should be `BTCarouselItem` components. For now, this is
 * solving a simple bug with the library, but additional functionality may be applied in the future.
 *
 * ## Navigation
 *
 * To manually navigate within the carousel, you must first add a ref for the component.
 *
 * ```
 * private carousel = createRef<BTCarousel>();
 *   ...
 * <BTCarousel ref={this.carousel}>
 *   ...
 * </BTCarousel>
 * ```
 *
 * Now, you will be able to navigate using `this.carousel.current` and calling `goTo(...)`, `prev()`, & `next()`
 */
export class BTCarousel extends Component<IBTCarouselProps> {
    // Navigation is only exposed as a ref
    private carouselRef = createRef<CarouselRef>();

    static defaultProps = BTCarouselDefaultProps;

    /**
     * To call this function, you must add a ref on this component!
     * Function to navigate to a specific index in the carousel.
     * @param slide Index of the slide you'd like to navigate to
     * @param dontAnimate Boolean to turn off the animation when navigating
     */
    goTo = (slide: number, dontAnimate?: boolean) => {
        this.carouselRef.current!.goTo(slide, dontAnimate);
    };

    /**
     * To call this function, you must add a ref on this component!
     * Function to navigate to the next slide in the carousel.
     */
    next = () => {
        this.carouselRef.current!.next();
    };

    /**
     * To call this function, you must add a ref on this component!
     * Function to navigate to the previous slide in the carousel.
     */
    prev = () => {
        this.carouselRef.current!.prev();
    };

    render() {
        const {
            children,
            className,
            carouselClassName,
            dotTheme,
            arrowTheme,
            "data-testid": dataTestId,
            ...otherProps
        } = this.props;

        const arrowClassName = `arrow-${arrowTheme}`;
        const dotClassName = `dot-${dotTheme}`;

        return (
            <div className={`BTCarousel ${className}`} data-testid={dataTestId}>
                <Carousel
                    className={`${arrowClassName} ${dotClassName} ${carouselClassName}`}
                    ref={this.carouselRef}
                    arrows
                    nextArrow={<NextArrow arrowTheme={arrowTheme} />}
                    prevArrow={<PrevArrow arrowTheme={arrowTheme} />}
                    {...otherProps}
                >
                    {children}
                </Carousel>
            </div>
        );
    }
}
