import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IClockInClockOutProps } from "./ClockInClockOut";

const ClockInClockOut = lazyLoadWithRetry(() => import("./ClockInClockOut"));

export const RouteClockInClockOut: React.FunctionComponent<
    Omit<
        IClockInClockOutProps,
        "id" | "jobId" | "isClockIn" | "isMultiple" | "history" | "match" | "location"
    >
> = (props) => (
    <RouteRelative
        path={routes.timeClock.clockInClockOut}
        render={(routeProps) => (
            <BTSuspense>
                <ClockInClockOut
                    {...props}
                    {...routeProps}
                    id={parseInt(routeProps.match.params.id)}
                    jobId={parseInt(routeProps.match.params.jobId)}
                    isClockIn={routeProps.match.params.isClockIn === "true"}
                    isMultiple={routeProps.match.params.isMultiple === "true"}
                />
            </BTSuspense>
        )}
    />
);
