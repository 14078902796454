import { createContext, useEffect, useState } from "react";

import { BTAdminRep, BTAdminUserInfo } from "helpers/globalContext/btAdmin/BTAdminContext.types";
import {
    BTAdminUserInfoHandler,
    IBTAdminContextHandler,
} from "helpers/globalContext/btAdmin/BTAdminUserInfo.api.handler";

import { showAPIErrorMessage } from "utilities/apiHandler";

import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

/**
 * Context containing the btadmin user if they are logged in
 */
export const BTAdminUserContext = createContext<BTAdminUserInfo | undefined>(undefined);

const defaultBTAdminUserInfoHandler = new BTAdminUserInfoHandler();

export interface IBTadminUserContextProviderProps {
    handler?: IBTAdminContextHandler;
}

export function BTAdminUserInfoProvider({
    handler = defaultBTAdminUserInfoHandler,
    ...otherProps
}) {
    const [rep, setRep] = useState<BTAdminRep | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await handler.get();
                setRep(response);
            } catch (e) {
                showAPIErrorMessage(e);
            } finally {
                setIsLoading(false);
            }
        }
        setIsLoading(true);
        void fetchData();
    }, [handler]);

    let value = undefined;

    if (rep) {
        value = new BTAdminUserInfo(rep);
    }

    return (
        <BTAdminUserContext.Provider value={value}>
            {isLoading && <BTLoading />}
            {!isLoading && otherProps.children}
        </BTAdminUserContext.Provider>
    );
}
