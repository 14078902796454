import classNames from "classnames";
import { Component } from "react";

import { friendlyDefaultDisplayValue } from "utilities/helpers";
import { isNullOrWhitespace } from "utilities/string/string";

import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import {
    AddressEntity,
    AddressFieldTypes,
    getBottomAddressForMapToolTip,
    getPinTypeForMap,
} from "commonComponents/entity/address/Address/Address.api.types";
import { InfoWindowContent } from "commonComponents/entity/map/InfoWindowContent";
import { IMapPosition, Mark, PinTypes } from "commonComponents/entity/map/Map.types";
import { MapButton } from "commonComponents/entity/map/MapButton/MapButton";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import "./AddressDisplay.less";

interface IAddressDisplayProps {
    address: AddressEntity;
    className?: string;
    singleLineFormat: boolean;
    emptyState?: React.ReactNode;
    showMapIcon?: boolean;
    mapPinTooltipHeader?: string;
    mapPinTooltipBody?: string;
    mapDefaultStartPosition?: IMapPosition;
    entityName?: string;
}

class AddressDisplay extends Component<IAddressDisplayProps> {
    static defaultProps = {
        singleLineFormat: false,
        showMapIcon: true,
    };

    /* For formatting cultures with different address line structures with delimiter
     */
    private formatDelimiter = (
        leftSide: string | undefined,
        delimiter: string,
        rightSide: string | undefined
    ) => {
        if (
            leftSide &&
            rightSide &&
            !isNullOrWhitespace(leftSide) &&
            !isNullOrWhitespace(rightSide)
        ) {
            return leftSide.concat(delimiter.concat(rightSide));
        }
        return (leftSide || "").concat(rightSide || "");
    };

    private renderAddressForMapTooltip = () => {
        const { address } = this.props;
        let formattedAddress = <></>;

        const street = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.Street
        );
        if (!isNullOrWhitespace(street?.value)) {
            formattedAddress = <div>{street!.value}</div>;
        }

        const formattedBottomAddress = getBottomAddressForMapToolTip(address);

        if (formattedBottomAddress.length > 0) {
            formattedAddress = (
                <>
                    {formattedAddress}
                    <div>{formattedBottomAddress}</div>
                </>
            );
        }

        return formattedAddress;
    };

    private formatAddress = () => {
        const {
            address,
            singleLineFormat,
            mapPinTooltipBody,
            mapPinTooltipHeader,
            entityName,
            mapDefaultStartPosition,
            showMapIcon,
        } = this.props;

        const street = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.Street
        );
        const city = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.City
        );
        const suburb = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.Suburb
        );
        const state = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.State
        );
        const zip = address.fields.find(
            (field) => field.addressFieldType === AddressFieldTypes.Zip
        );
        const country =
            address.countryDropdown &&
            address.countryDropdown.find(
                (country) => parseInt(country.id) === address.countryDropdownId
            );

        const hasStreet = street && street.value;
        const hasCity = city && city.value;
        const hasState = state && state.value;
        const hasSuburb = suburb && suburb.value;
        const hasZip = zip && zip.value;
        const hasCountry = country && country.title;
        const isUK = address.globalCountry && address.globalCountry === "GB";
        const isIE = address.globalCountry && address.globalCountry === "IE";

        if (!hasStreet && !hasCity && !hasState && !hasSuburb && !hasZip) {
            return this.props.emptyState || friendlyDefaultDisplayValue;
        }

        const pinType = address.location ? getPinTypeForMap(address.location) : undefined;
        const showPinMapIcon = showMapIcon && address.location && pinType !== PinTypes.None;

        if (singleLineFormat) {
            return (
                <TextTruncate>
                    {/* TODO: this is just implementing the default country (US). Please update formatting of specific cultures */}
                    {this.formatDelimiter(
                        this.formatDelimiter(
                            this.formatDelimiter(hasStreet, ", ", hasCity),
                            ", ",
                            hasState
                        ),
                        " ",
                        hasZip
                    )}
                </TextTruncate>
            );
        } else {
            return (
                <>
                    <BTRow className="AddressTopLine AddressLine" align="middle">
                        {hasStreet ? `${street!.value}` : ""}
                        {showPinMapIcon && (
                            <span className="padding-left-xs">
                                <MapButton
                                    data-testid="addressMapButton"
                                    type="link"
                                    className="MapButton"
                                    mark={
                                        address.location?.mapPosition && showPinMapIcon
                                            ? new Mark({
                                                  id: "addressPin",
                                                  position: address.location.mapPosition,
                                                  pinType: pinType!,
                                                  animate: false,
                                                  infoWindowContent: (
                                                      <InfoWindowContent
                                                          pinType={pinType}
                                                          header={mapPinTooltipHeader}
                                                          body={mapPinTooltipBody}
                                                          footer={this.renderAddressForMapTooltip()}
                                                      />
                                                  ),
                                              })
                                            : undefined
                                    }
                                    entityName={entityName}
                                    defaultMapStartPosition={mapDefaultStartPosition}
                                    readOnly
                                />
                            </span>
                        )}
                    </BTRow>
                    {!(isUK || isIE) && (
                        <>
                            <BTRow className="AddressLine">
                                {hasSuburb ? `${suburb!.value}` : ""}
                            </BTRow>
                            <BTRow className="AddressLine">
                                {hasCity ? `${city!.value}` : ""}
                                {hasState ? `${hasCity ? ", " : ""}${state!.value}` : ""}
                                {hasZip
                                    ? `${hasState ? " " : hasCity ? ", " : ""}${zip!.value}`
                                    : ""}
                            </BTRow>
                        </>
                    )}
                    {(isUK || isIE) && (
                        <>
                            <BTRow className="AddressLine">{hasCity ? `${city!.value}` : ""}</BTRow>
                            <BTRow className="AddressLine">
                                {hasSuburb ? `${suburb!.value}` : ""}
                            </BTRow>
                            <BTRow className="AddressLine">{hasZip ? `${zip!.value}` : ""}</BTRow>
                        </>
                    )}

                    <BTRow className="AddressLine">{hasCountry ? `${country!.title}` : ""}</BTRow>
                </>
            );
        }
    };

    render() {
        return (
            <div className={classNames("AddressDisplay", this.props.className)}>
                {this.formatAddress()}
            </div>
        );
    }
}
export default AddressDisplay;
