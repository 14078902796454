import { InfoWindow } from "@react-google-maps/api";

import { IMark } from "commonComponents/entity/map/Map.types";

interface ICustomInfoWindowProps {
    mark: IMark;
    onCloseClick: () => void;
}

const CustomInfoWindow: React.FunctionComponent<ICustomInfoWindowProps> = ({
    mark,
    onCloseClick,
}) => {
    const options: google.maps.InfoWindowOptions = {
        pixelOffset: new google.maps.Size(0, -32),
    };

    return (
        <InfoWindow
            key={`${mark.id}-infoWindow`}
            position={mark.position}
            options={options}
            onCloseClick={onCloseClick}
        >
            {mark.infoWindowContent}
        </InfoWindow>
    );
};

export default CustomInfoWindow;
