import { ButtonProps } from "antd/lib/button";
import { Component } from "react";

import { BTButton, BTButtonType } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { IMapPosition, IMark, PinTypes } from "commonComponents/entity/map/Map.types";
import { MapInput } from "commonComponents/entity/map/MapInput/MapInput";
import {
    GetIconPathForPinType,
    getMapPositionFromDeviceWithFallback,
} from "commonComponents/entity/map/mapUtilities";

interface IMapButtonProps extends Omit<ButtonProps, "type"> {
    modalTitle: string;
    mark?: IMark;
    readOnly?: boolean;
    "data-testid": string;
    isOpenWithMap: boolean;
    entityName?: string;
    onMarkSet?: (newPosition: IMapPosition) => void;
    onMarkClear?: () => void;
    type?: BTButtonType;
    defaultMapStartPosition?: IMapPosition;
}

interface IMapButtonState {
    isModalVisible: boolean;
    center: IMapPosition;
}

/**
 * Opens the address in a new tab on google maps
 * @example
 * <MapButton mark={mark}>Open in maps</MapButton>
 */
export class MapButton extends Component<IMapButtonProps, IMapButtonState> {
    static defaultProps = {
        modalTitle: "Location",
        isOpenWithMap: false,
    };

    state: Readonly<IMapButtonState> = {
        isModalVisible: false,
        center: this.props.mark ? { ...this.props.mark.position } : { lat: 0, lng: 0 },
    };

    componentDidMount = () => {
        this.setState({ isModalVisible: this.props.isOpenWithMap });
    };

    private handleModalClose = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    private handleButtonClick = async () => {
        let newCenter: IMapPosition = {
            ...(this.props.mark ? this.props.mark.position : this.state.center),
        };
        if (newCenter.lat === 0 && newCenter.lng === 0) {
            newCenter = await getMapPositionFromDeviceWithFallback(
                this.props.defaultMapStartPosition
            );
        }

        this.setState({
            isModalVisible: true,
            center: newCenter,
        });
    };

    private handleMarkSet = (newPosition: IMapPosition) => {
        const { onMarkSet } = this.props;
        onMarkSet && onMarkSet(newPosition);
    };

    private handleMarkClear = () => {
        const { onMarkClear } = this.props;
        onMarkClear && onMarkClear();
    };

    private renderButtonContent = () => {
        if (this.props.children !== undefined) {
            return this.props.children;
        } else {
            return (
                <img
                    src={GetIconPathForPinType(
                        this.props.mark ? this.props.mark.pinType : PinTypes.Inactive
                    )}
                    alt="Open in Maps"
                    style={{ height: "18px", width: "16px" }}
                />
            );
        }
    };

    render() {
        const {
            mark,
            readOnly,
            modalTitle,
            isOpenWithMap,
            entityName,
            defaultMapStartPosition,
            onMarkClear,
            onMarkSet,
            ...otherProps
        } = this.props;
        const { isModalVisible, center } = this.state;

        return (
            <>
                <BTButton
                    type="secondary"
                    {...otherProps}
                    data-testid={this.props["data-testid"]}
                    onClick={this.handleButtonClick}
                >
                    {this.renderButtonContent()}
                </BTButton>
                <BTModal
                    data-testid="btModalMapButton"
                    beforeClose={this.handleModalClose}
                    visible={isModalVisible}
                    title={modalTitle}
                    setPageTitle={false}
                    width="670px"
                    removeBodyPadding
                    disableAnimation // Disables the modal animation so that the map renders properly on load
                >
                    <MapInput
                        center={center}
                        zoomLevel={14}
                        mark={mark}
                        readOnly={readOnly}
                        entityName={entityName}
                        onMarkSet={this.handleMarkSet}
                        onMarkClear={this.handleMarkClear}
                    />
                </BTModal>
            </>
        );
    }
}
