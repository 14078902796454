import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IToDoProps } from "entity/toDo/details/ToDoDetails/ToDo";
import { IToDoCreationData } from "entity/toDo/details/ToDoDetails/ToDo.types";

const ToDo = lazyLoadWithRetry(() => import("./ToDo"));

interface IRouteTodoProps
    extends Omit<IToDoProps, "id" | "jobId" | "history" | "match" | "location"> {
    prefilledFormValues?: IToDoCreationData;
    isFromMessage?: boolean;
}
export const RouteToDo = (props: IRouteTodoProps) => (
    <RouteRelative
        path={routes.toDo.details}
        render={(routeProps) => {
            const id = parseInt(routeProps.match.params.id);
            const jobId = parseInt(routeProps.match.params.jobId);
            const isFromRfi = routeProps.match.params.isFromRfi === "true";

            return (
                <BTSuspense>
                    <ToDo {...props} {...routeProps} id={id} jobId={jobId} isFromRfi={isFromRfi} />
                </BTSuspense>
            );
        }}
    />
);
