import * as BTColors from "styles/buildertrendTheme/Colors";

// greys
export const Grey0 = "#FFFFFF";
export const Grey1 = "#FFFFFF";
export const Grey2 = BTColors.Grey5;
export const Grey3 = BTColors.Grey10;
export const Grey4 = BTColors.Grey15;
export const Grey5 = BTColors.Grey25;
export const Grey6 = BTColors.Grey40;
export const Grey7 = BTColors.Grey60;
export const Grey8 = BTColors.Grey70;
export const Grey9 = BTColors.Grey90;
export const Grey10 = BTColors.Grey100;

// blues
export const Blue0 = "#fffff";
export const Blue1 = "#fffff";
export const Blue2 = BTColors.Blue5;
export const Blue3 = BTColors.Blue10;
export const Blue4 = BTColors.Blue15;
export const Blue5 = BTColors.Blue25;
export const Blue6 = BTColors.Blue40;
export const Blue7 = BTColors.Blue60;
export const Blue8 = BTColors.Blue70;
export const Blue9 = BTColors.Blue80;
export const Blue10 = BTColors.Blue90;

// reds
export const Red0 = "#ffffff";
export const Red1 = "#ffffff";
export const Red2 = BTColors.Red5;
export const Red3 = BTColors.Red10;
export const Red4 = BTColors.Red15;
export const Red5 = BTColors.Red25;
export const Red6 = BTColors.Red40;
export const Red7 = BTColors.Red60;
export const Red8 = BTColors.Red70;
export const Red9 = BTColors.Red80;
export const Red10 = BTColors.Red90;

// greens
export const Green0 = "#ffffff";
export const Green1 = "#ffffff";
export const Green2 = BTColors.Green5;
export const Green3 = BTColors.Green10;
export const Green4 = BTColors.Green15;
export const Green5 = BTColors.Green25;
export const Green6 = BTColors.Green40;
export const Green7 = BTColors.Green60;
export const Green8 = BTColors.Green70;
export const Green9 = BTColors.Green80;
export const Green10 = BTColors.Green90;

// yellows
export const Yellow0 = "#ffffff";
export const Yellow1 = "#ffffff";
export const Yellow2 = BTColors.Yellow5;
export const Yellow3 = BTColors.Yellow10;
export const Yellow4 = BTColors.Yellow15;
export const Yellow5 = BTColors.Yellow25;
export const Yellow6 = BTColors.Yellow40;
export const Yellow7 = BTColors.Yellow60;
export const Yellow8 = BTColors.Yellow70;
export const Yellow9 = BTColors.Yellow80;
export const Yellow10 = BTColors.Yellow90;

// oranges
export const Orange0 = "#ffffff";
export const Orange1 = "#ffffff";
export const Orange2 = BTColors.Orange5;
export const Orange3 = BTColors.Orange10;
export const Orange4 = BTColors.Orange15;
export const Orange5 = BTColors.Orange25;
export const Orange6 = BTColors.Orange40;
export const Orange7 = BTColors.Orange60;
export const Orange8 = BTColors.Orange70;
export const Orange9 = BTColors.Orange80;
export const Orange10 = BTColors.Orange90;

// purples
export const Purple0 = "#ffffff";
export const Purple1 = "#ffffff";
export const Purple2 = BTColors.Purple5;
export const Purple3 = BTColors.Purple10;
export const Purple4 = BTColors.Purple15;
export const Purple5 = BTColors.Purple25;
export const Purple6 = BTColors.Purple40;
export const Purple7 = BTColors.Purple60;
export const Purple8 = BTColors.Purple70;
export const Purple9 = BTColors.Purple80;
export const Purple10 = BTColors.Purple90;
