import { Input } from "antd";
import { Component } from "react";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTInput, IBTInputProps } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTInputCopy } from "commonComponents/btWrappers/BTInputCopy/BTInputCopy";

export interface IBTInputLinkProps<FormValues> extends Omit<IBTInputProps<FormValues>, "value"> {
    value: string | undefined;
}

/**
 * Converts a link into a valid URL by adding https if a URL scheme already present in the string.
 * Useful for generating links when users enter URLs without the 'https://' that is usually implied
 * implicitly.
 *
 * @example
 * convertToValidUrl("test123.com") // --> https://test123.com"
 * convertToValidUrl("https://test123.com") // --> "https://test123.com"
 * convertToValidUrl("mailto:example＠blah.com") // --> "mailto:example＠blah.com"
 */
export function convertToValidURL(linkStr: string) {
    // Regex based on https://www.ietf.org/rfc/rfc2396.txt
    const linkContainsScheme = /^[a-zA-Z]([a-zA-Z0-9+\-.])*:/g;
    return linkContainsScheme.test(linkStr) ? linkStr : "https://" + linkStr;
}

export class BTInputLink<FormValues = undefined> extends Component<IBTInputLinkProps<FormValues>> {
    render = () => {
        const {
            id,
            value,
            "data-testid": dataTestId,
            disabled,
            readOnly,
            ...otherProps
        } = this.props;
        const link = this.props.value ? this.props.value : "";

        if (readOnly) {
            return (
                <BTExternalLink data-testid={dataTestId} href={convertToValidURL(link)}>
                    {link}
                </BTExternalLink>
            );
        } else if (disabled) {
            return (
                <BTInputCopy
                    id={id}
                    data-testid={dataTestId}
                    value={value}
                    linkValue={value}
                    disabled
                    {...otherProps}
                />
            );
        } else {
            return (
                <Input.Group compact className="flex">
                    <BTInput<FormValues>
                        id={id}
                        data-testid={dataTestId}
                        value={value}
                        {...otherProps}
                    />
                    <BTExternalLink
                        data-testid={`${dataTestId}-view`}
                        href={convertToValidURL(link)}
                        disabled={link.length === 0}
                        buttonType="secondary"
                    >
                        View
                    </BTExternalLink>
                </Input.Group>
            );
        }
    };
}
