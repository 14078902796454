import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { ActivationResponse } from "commonComponents/entity/credentials/Credentials/Credentials.api.types";

import { LoginResponse } from "entity/login/Login/Login.api.types";

import {
    IFinalizeActivationRequest,
    ILinkAccountRequest,
    ILoginRequest,
    LinkAccountEntity,
} from "./LinkAccount.api.types";

export interface ILinkAccountHandler {
    linkExisting(request: ILinkAccountRequest): Promise<ActivationResponse>;
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    get(shareToken: string): Promise<LinkAccountEntity>;
    login(request: ILoginRequest): Promise<LoginResponse>;
    createNewUser: (request: ILinkAccountRequest) => Promise<EmptyResponseEntity>;
    finalizeActivation: (request: IFinalizeActivationRequest) => Promise<EmptyResponseEntity>;
}

export class LinkAccountHandler implements ILinkAccountHandler {
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    async get(shareToken: string): Promise<LinkAccountEntity> {
        return await APIHandler(`/apix/v2/Users/activation/${shareToken}`, {
            method: "GET",
            responseType: LinkAccountEntity,
        });
    }
    async linkExisting(request: ILinkAccountRequest): Promise<ActivationResponse> {
        return await APIHandler("/api/Users/LinkExisting", {
            method: "PUT",
            data: request,
            responseType: ActivationResponse,
        });
    }
    async login(request: ILoginRequest): Promise<LoginResponse> {
        return await APIHandler("/api/Login/AjaxLogin", {
            method: "POST",
            data: request,
            responseType: LoginResponse,
        });
    }

    async createNewUser(request: ILinkAccountRequest): Promise<ActivationResponse> {
        return await APIHandler("/api/Users/CreateNewUser", {
            method: "POST",
            data: request,
            responseType: ActivationResponse,
        });
    }

    async finalizeActivation(request: IFinalizeActivationRequest): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Users/FinalizeActivation", {
            method: "POST",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }
}

export const defaultLinkAccountHandler = new LinkAccountHandler();
