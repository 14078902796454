export const fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

export const Screen = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};

export const ScreenMax = {
    xs: Screen.sm - 1,
    sm: Screen.md - 1,
    md: Screen.lg - 1,
    lg: Screen.xl - 1,
    xl: Screen.xxl - 1,
};

export const ScreenMin = {
    xs: 0,
    sm: Screen.sm,
    md: Screen.md,
    lg: Screen.lg,
    xl: Screen.xl,
    xxl: Screen.xxl,
};
