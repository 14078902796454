import { Input } from "antd";
import { useCallback, useRef } from "react";
import InputMask, { InputState, MaskOptions, ReactInputMask } from "react-input-mask";

import { IBTInputProps } from "commonComponents/btWrappers/BTInput/BTInput";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

export interface IBTMaskedInputProps<FormValues>
    extends Omit<
        IBTInputProps<FormValues>,
        | "fieldRef"
        | "size"
        | "type"
        | "prefix"
        | "autoFocus"
        | "addonBefore"
        | "addonAfter"
        | "debouncingContext" // We can't use debouncing context, otherwise the input masking lags severely behind. It kills the UX
        | "disableFormSubmit"
    > {
    mask: string; // We make this null by default. If undefined, the library defaults to _, but we want to clear it.
    formatChars?: {
        [key: string]: string;
    };
    fieldRef?: React.RefObject<ReactInputMask>;
    maskChar?: string;
    parser?: (initialValue: string) => string;
    isSensitiveValue?: boolean;
    beforeMaskedValueChange?(
        newState: InputState,
        oldState: InputState,
        userInput: string,
        maskOptions: MaskOptions
    ): InputState;

    /**
     * pass through to password input visibilityToggle prop
     * defaults to "true" to show the icon in the input
     */
    visibilityToggle?: boolean;
}

export const BTMaskedInput = function <FormValues = undefined>({
    id,
    value,
    readOnly,
    onChange,
    onBlur = () => {},
    fieldRef,
    "data-testid": testid,
    mask,
    formatChars,
    maskChar,
    parser,
    disabled,
    isSensitiveValue,
    beforeMaskedValueChange,
    visibilityToggle = true,
    ...otherProps
}: IBTMaskedInputProps<FormValues>) {
    const refInternal = useRef<ReactInputMask>(null);

    const onChangeWrapper = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (parser) {
                onChange(id, parser(event.target.value));
            } else {
                onChange(id, event.target.value);
            }
        },
        [id, onChange, parser]
    );

    const onBlurWrapper = useCallback(() => {
        onBlur(id, true, value);
    }, [id, onBlur, value]);

    if (readOnly && isSensitiveValue) {
        console.warn(
            "WARNING: The case when isSensitiveValue and readOnly mode are both true is not currently supported. Awaiting a design for a read only sensitive information field"
        );
    }

    if (readOnly && !isSensitiveValue) {
        return (
            <ValueDisplay id={id as string} data-testid={testid} value={value} {...otherProps} />
        );
    }

    return (
        // eslint-disable-next-line react/forbid-elements
        <InputMask
            {...otherProps}
            mask={mask}
            formatChars={formatChars}
            id={id as string}
            data-testid={testid}
            value={value}
            onChange={onChangeWrapper}
            onBlur={onBlurWrapper}
            ref={fieldRef ?? refInternal}
            disabled={disabled}
            maskChar={maskChar ? maskChar : null} // Override the library's default (_)
            beforeMaskedValueChange={beforeMaskedValueChange}
        >
            {(inputProps: any) =>
                !isSensitiveValue ? (
                    // eslint-disable-next-line react/forbid-elements
                    <Input
                        {...inputProps}
                        value={value}
                        onChange={onChangeWrapper}
                        disabled={disabled}
                    />
                ) : (
                    <Input.Password
                        {...inputProps}
                        value={value}
                        onChange={onChangeWrapper}
                        disabled={disabled}
                        visibilityToggle={visibilityToggle}
                    />
                )
            }
        </InputMask>
    );
};
