import Icon from "@ant-design/icons";
import classnames from "classnames";
import { FunctionComponent } from "react";

import SVG from "commonComponents/btWrappers/BTIcon/images/Takeoffs.svg?react";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

export const BTIconTakeoffs: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useBaseIcon(baseProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon {...props} component={SVG} className={classnames(props.className)} />
    );
};
