import { PaymentStatus } from "types/enum";

export const getMerchantPaymentStatusDisplayString = (status: PaymentStatus) => {
    switch (status) {
        case PaymentStatus.Unpaid:
            return "Sent";
        case PaymentStatus.Expired:
            return "Expired";
        case PaymentStatus.Authorized:
            return "Processing";
        case PaymentStatus.Failed:
            return "Failed";
        case PaymentStatus.Cancelled:
            return "Cancelled";
        case PaymentStatus.Reserved:
            return "Processing";
        case PaymentStatus.ChargedBack:
            return "Charged Back";
        case PaymentStatus.Captured:
            return "Complete";
        case PaymentStatus.Refunded:
            return "Refunded";
        case PaymentStatus.ManuallyCaptured:
            return "Manually Captured";
        case PaymentStatus.RefundedAndManuallyCaptured:
            return "Refunded and Manually Captured";
        case PaymentStatus.PaymentCapturedButNotProperlyRecorded:
            return "Captured and not Recorded";
        case PaymentStatus.Void:
            return "Void";
        default:
            return "Unknown";
    }
};
