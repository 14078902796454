import { IQualtricsOptions } from "utilities/thirdPartyIntegrations/qualtrics/qualtrics.types";

let initStarted: boolean = false;

/**
 * Qualtrics wrapper instance. Be sure to call `initialize()` to init Qualtrics
 * @param context {@link IQualtricsOptions} context
 * @returns Wrapper instance for Qualtrics integration
 */
export function QualtricsIntegration(context: IQualtricsOptions) {
    return {
        name: "qualtrics",
        config: context,
        initialize: () => {
            const prepScript = document.getElementById("qualtrics-prep-script");
            const prepLoaderScript = document.getElementById("qualtrics-loader-script");
            const contentPlaceholder = document.getElementById(context.qualtricsProjectId);

            if (!!window.QSI || initStarted) {
                // Already initialized
                return;
            }
            initStarted = true;

            // No DOM
            const isBrowser: boolean = typeof document !== "undefined";
            if (!isBrowser) {
                return;
            }

            // Prep QSI objects
            if (!prepScript && !window.QSI) {
                window.QSI = {};
                window.QSI.config = {
                    externalReference: context.integrationUserProfile.userId,
                };
                window.qualtricsExtraParameters = {};
            }

            // Inject intercept script
            if (!prepLoaderScript) {
                const s = document.createElement("script");
                s.id = "qualtrics-intercept-script";
                s.type = "text/javascript";
                s.async = true;
                // See: https://buildertrend.qualtrics.com/siui/project/ZN_7QBcKGahLY8dND8/deployment
                s.innerText = `(function(){var g=function(e,h,f,g){
                                this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
                                this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
                                this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
                                this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
                                this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
                                try{(new g(100,"r","QSI_S_${context.qualtricsProjectId}","${context.qualtricsInterceptUrl}")).start()}catch(i){}})();`;

                // Insert just before the first existing script tag
                const firstScriptElement = document.getElementsByTagName("script")[0];
                firstScriptElement.parentNode!.insertBefore(s, firstScriptElement);
            }

            // Inject content placeholder element
            if (!contentPlaceholder) {
                const c = document.createElement("div");
                c.id = context.qualtricsProjectId;
                c.innerHTML = `<!--QUALTRICS: DO NOT REMOVE-CONTENTS PLACED HERE-->`;

                // Insert at the top of the body
                const firstBodyElement = document.body.firstElementChild;
                firstBodyElement?.parentNode?.insertBefore(c, firstBodyElement);
            }
        },
    };
}
