import { TabPermissions } from "types/enum";

import { APIHandler } from "utilities/apiHandler";
import { combineDateTime } from "utilities/date/date";

import {
    ITemplateImportConflictsRequest,
    ITemplateImportFormValues,
    ITemplateImportRequest,
    TemplateImportConflictsResponse,
    TemplateImportCountsResponse,
    TemplateImportResponse,
} from "./TemplateImport.api.types";

export interface ITemplateImportHandler {
    getCounts(templateId: number, jobId: number): Promise<TemplateImportCountsResponse>;
    startImport(
        jobId: number,
        tabId: TabPermissions,
        values: ITemplateImportFormValues
    ): Promise<TemplateImportResponse>;
    getImportConflicts(
        request: ITemplateImportConflictsRequest
    ): Promise<TemplateImportConflictsResponse>;
    getExternalTemplateCounts(
        templateExternalId: string,
        jobId: number
    ): Promise<TemplateImportCountsResponse>;
}

export class TemplateImportHandler implements ITemplateImportHandler {
    async getCounts(templateId: number, jobId: number): Promise<TemplateImportCountsResponse> {
        return await APIHandler(`/api/Templates/ImportCounts`, {
            method: "GET",
            data: {
                templateId,
                jobId,
                fromReact: true,
            },
            responseType: TemplateImportCountsResponse,
        });
    }

    async getExternalTemplateCounts(
        templateExternalId: string,
        jobId: number
    ): Promise<TemplateImportCountsResponse> {
        return await APIHandler(`/api/Templates/ImportExternalCounts`, {
            method: "GET",
            data: {
                templateExternalId,
                jobId,
            },
            responseType: TemplateImportCountsResponse,
        });
    }

    async startImport(
        jobId: number,
        tabId: TabPermissions,
        values: ITemplateImportFormValues
    ): Promise<TemplateImportResponse> {
        return await APIHandler(`/api/Templates/Import?fromReact=true`, {
            method: "POST",
            data: valuesToRequest(jobId, tabId, values),
            responseType: TemplateImportResponse,
        });
    }

    async getImportConflicts(request: ITemplateImportConflictsRequest) {
        return await APIHandler("/api/Templates/ImportConflicts", {
            method: "GET",
            responseType: TemplateImportConflictsResponse,
            data: {
                jobId: request.jobId,
                copyOptions: request.copyOptions,
                isExistingJob: request.isExistingJob,
            },
        });
    }
}

function valuesToRequest(
    jobId: number,
    tabId: TabPermissions,
    values: ITemplateImportFormValues
): ITemplateImportRequest {
    return {
        scheduleStartCopyDateUtc: values.scheduleStartDate || null,
        templateId: values.templateId,
        jobsiteId: jobId,
        callingPage: tabId,
        importCopyOptions: {
            applyLIUpdates: values.selectionsApplyPending,
            importBidPackages: values.bidPackages,
            importDocumentFiles: values.documentFiles,
            importDocumentFolders: values.documentFolders,
            importEstimate: values.estimates,
            importOwnerPayments: values.ownerInvoices,
            importStandaloneBills: values.bills,
            importLinkedBills: values.linkedBills,
            importPODocs: values.purchaseOrdersFiles,
            importPOs: values.purchaseOrders,
            importPhotoFolders: values.photoFolders,
            importSchedules: values.schedule,
            importSelections: values.selections,
            importSpecifications: values.specifications,
            importSurveys: values.surveys,
            importToDos: values.toDos,
            importVideoFiles: values.videoFiles,
            importVideoFolders: values.videoFolders,
            resetApprovedChoices: values.selectionsResetApproved,
            resetFavoritedChoices: false,
            removeExistingEstimates: values.estimates && values.estimatesRemoveExisting,
            keepDestinationProposalIntroClosingText:
                values.estimates && values.estimatesKeepIntroClosingText,
        },
        datesForImport: {
            // Use the old key names but send in local
            newBidPackageDeadlineUtc: combineDateTime(values.bidPackageDeadline) || null,
            newPOsStartDateUtc: values.purchaseOrdersCompletion || null,
            newSelectionsStartDateUtc: values.selectionsDeadline || null,
            newOwnerPaymentDeadlineLocal: combineDateTime(values.ownerInvoiceDeadline) || null,
            newSurveyReleaseDateLocal: values.surveyReleaseDate || null,
        },
    };
}
