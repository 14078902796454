import Meta from "antd/lib/card/Meta";
import { createRef } from "react";

import { BdsText } from "@buildertrend/components";

import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTCarousel, BTCarouselItem } from "commonComponents/btWrappers/BTCarousel/BTCarousel";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

import ConnectedToBuilderValueSrc from "images/UserActivation/OwnerValuePropositions/connected-to-builder.webp";
import ConnectedToProjectValueSrc from "images/UserActivation/OwnerValuePropositions/connected-to-project.webp";
import ConnectedWhereverValueSrc from "images/UserActivation/OwnerValuePropositions/connected-wherever.webp";

import "./ValuePropositions.less";

export interface IValuePropositionsProps {}

const valuePropositionsConfig: IValuePropositionsItemProps[] = [
    {
        id: "ProjectValue",
        title: "Stay connected to your project",
        description:
            "From progress updates to key decisions, keep an eye on your build from start to finish",
        image: ConnectedToProjectValueSrc,
    },
    {
        id: "BuilderValue",
        title: "Stay connected to your builder",
        description: "Keep all your project conversations in one place for quick reference",
        image: ConnectedToBuilderValueSrc,
    },
    {
        id: "WhereverValue",
        title: "Stay connected wherever you are",
        description:
            "Monitor your project's progress at home or on the go using the Buildertrend app",
        image: ConnectedWhereverValueSrc,
    },
];

interface IValuePropositionsItemProps {
    id: string;
    title: string;
    description: string;
    image: string;
}

const ValuePropCarouselItem: React.FC<IValuePropositionsItemProps> = (
    props: IValuePropositionsItemProps
) => {
    return (
        <BTCarouselItem isContent={true}>
            <BTCard
                id={props.id}
                cover={<img className="OwnerImage" src={props.image} alt={props.title} />}
                bordered={false}
            >
                <Meta
                    className="padding-vertical-lg"
                    title={
                        <BTRow justify="center">
                            <BdsText
                                testid={`${props.id}Title`}
                                size="heavy-sm"
                                style={{ textAlign: "center" }}
                                text={props.title}
                            />
                        </BTRow>
                    }
                    description={
                        <BTRow justify="center" className="padding-vertical-sm">
                            <BdsText
                                testid={`${props.id}Description`}
                                size="distinct-sm"
                                style={{ textAlign: "center" }}
                                text={props.description}
                            />
                        </BTRow>
                    }
                />
            </BTCard>
        </BTCarouselItem>
    );
};

export const ValuePropositions: React.FC<IValuePropositionsProps> = () => {
    const carousel = createRef<BTCarousel>();

    const valuePropositionItems = valuePropositionsConfig.map(({ id, ...rest }) => (
        <ValuePropCarouselItem key={id} id={id} {...rest} />
    ));

    return (
        <BTRow
            justify="center"
            align="middle"
            style={{ maxWidth: "100%" }}
            responsiveMode="viewport"
        >
            <BTCol>
                <BTCarousel
                    ref={carousel}
                    dotTheme="light"
                    arrowTheme="light"
                    autoplay={true}
                    autoplaySpeed={5000}
                >
                    {valuePropositionItems}
                </BTCarousel>
            </BTCol>
        </BTRow>
    );
};
