import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import classNames from "classnames";
import { createRef, PureComponent, useContext } from "react";

import { DateLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { track } from "utilities/analytics/analytics";
import { addCloseOnScroll, getClosestModal, removeCloseOnScroll } from "utilities/helpers";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./BTRangePicker.less";

type OnChangeValue = Parameters<Required<RangePickerProps>["onChange"]>[0];

interface IBTRangePickerProps<FormValues>
    extends Omit<RangePickerProps, "onChange" | "id" | "allowClear" | "placeholder"> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;
    readOnly?: boolean;

    /** pass formik setFieldValue */
    onChange: (field: KeyOfOrString<FormValues>, value: OnChangeValue) => void;
}

interface IBTRangePickerInternalProps {
    builderDateLocale: DateLocale;
}

interface IBTRangePickerInternalState {
    open: boolean;
}

@track((props) => ({ element: "Range Picker", uniqueId: props["data-testid"] }))
export class BTRangePickerInternal<FormValues = undefined> extends PureComponent<
    IBTRangePickerProps<FormValues> & IBTRangePickerInternalProps,
    IBTRangePickerInternalState
> {
    state: IBTRangePickerInternalState = {
        open: false,
    };

    private onValueChange = (dates: OnChangeValue) => {
        const { onChange, id } = this.props;

        onChange(id, dates);
    };

    rangePickerRef = createRef<HTMLDivElement>();

    private handleOpenChange = (open: boolean) => {
        this.setState({
            open: open,
        });

        // bind scroll event
        if (this.rangePickerRef.current) {
            if (open) {
                addCloseOnScroll(this.rangePickerRef.current, this.handleScroll);
            } else {
                removeCloseOnScroll(this.rangePickerRef.current, this.handleScroll);
            }
        }
    };

    private handleScroll = () => {
        // close on scroll
        this.handleOpenChange(false);
    };

    render() {
        const {
            id,
            value,
            onChange,
            readOnly,
            "data-testid": testid,
            builderDateLocale,
            className,
            dropdownClassName,
            picker,
            ...otherProps
        } = this.props;
        if (readOnly) {
            const renderedStart = value && value[0] && <DateDisplay value={value[0]} />;
            const renderedEnd = value && value[1] && <DateDisplay value={value[1]} />;
            return (
                <ValueDisplay
                    id={id as string}
                    data-testid={testid}
                    value={
                        renderedStart || renderedEnd ? (
                            <>
                                {renderedStart} ~ {renderedEnd}
                            </>
                        ) : undefined
                    }
                />
            );
        }

        return (
            <div ref={this.rangePickerRef}>
                {/* eslint-disable-next-line react/forbid-elements*/}
                <DatePicker.RangePicker
                    {...otherProps}
                    value={value}
                    data-testid={testid}
                    onChange={this.onValueChange}
                    format={builderDateLocale.dateInputFormats}
                    getPopupContainer={getClosestModal}
                    allowClear={false}
                    placeholder={["", ""]}
                    className={classNames("BTRangePicker", className)}
                    open={this.state.open}
                    onOpenChange={this.handleOpenChange}
                    dropdownClassName={classNames("RangePickerDropdown", dropdownClassName)}
                />
            </div>
        );
    }
}

export function BTRangePicker<FormValues = undefined>(props: IBTRangePickerProps<FormValues>) {
    const builderInfo = useContext(BuilderInfoContext);
    return (
        <BTRangePickerInternal<FormValues>
            builderDateLocale={builderInfo?.locale.dateLocale ?? DateLocale.default}
            {...props}
        />
    );
}
