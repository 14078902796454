import { arrayIsEqualDeep } from "utilities/array/array";

import {
    FilterEntity,
    getSelectedFilterItemsFromSavedFilter,
    SelectedFilterItem,
} from "entity/filters/Filter/Filter.api.types";
import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";

export interface IFilterReducerState {
    appliedFilter: SelectedFilterItem[] | undefined;
    showHasUnsavedChanges: boolean;
    appliedFiltersFromSavedFilter: boolean;
    selectedFilterId: number | undefined;
}

export const defaultFilterState: IFilterReducerState = {
    appliedFilter: undefined,
    showHasUnsavedChanges: false,
    appliedFiltersFromSavedFilter: false,
    selectedFilterId: undefined,
};

export class ClearFilterState {
    public readonly type = "clearFilterStateAction";
}

export class SetUnsavedAppliedFilter {
    constructor(filters: SelectedFilterItem[] = [], isChanged: boolean) {
        this.data = filters;
        this.isChanged = isChanged;
    }

    public data: SelectedFilterItem[];
    public isChanged: boolean;
    public readonly type = "setUnsavedAppliedFilterAction";
}

export class UpdateUnsavedChanges {
    constructor(
        newFilters: SelectedFilterItem[],
        filterEntity: FilterEntity,
        savedFilter: SavedFilterItem
    ) {
        this.newFilters = newFilters;
        this.filterEntity = filterEntity;
        this.savedFilter = savedFilter;
    }

    public newFilters: SelectedFilterItem[];
    public filterEntity: FilterEntity;
    public savedFilter: SavedFilterItem;
    public readonly type = "updateUnsavedChangesAction";
}

export type FilterActions = SetUnsavedAppliedFilter | UpdateUnsavedChanges | ClearFilterState;

export function FilterReducer(
    state: IFilterReducerState,
    action: FilterActions
): IFilterReducerState {
    switch (action.type) {
        case "setUnsavedAppliedFilterAction":
            return {
                ...state,
                showHasUnsavedChanges: action.isChanged,
                appliedFiltersFromSavedFilter: action.isChanged,
                appliedFilter: action.data !== undefined ? [...action.data] : undefined,
            };
        case "updateUnsavedChangesAction":
            const appliedFiltersNotFromSavedFilter = !arrayIsEqualDeep(
                action.newFilters.map((v) => v.selectedValue),
                getSelectedFilterItemsFromSavedFilter(action.filterEntity, action.savedFilter).map(
                    (v) => v.selectedValue
                )
            );

            return {
                ...state,
                selectedFilterId: action.savedFilter.id,
                showHasUnsavedChanges: appliedFiltersNotFromSavedFilter,
                appliedFiltersFromSavedFilter: !appliedFiltersNotFromSavedFilter,
            };

        case "clearFilterStateAction":
            return {
                ...defaultFilterState,
            };
        default:
            return state;
    }
}
