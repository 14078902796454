import { BTLoginTypes } from "types/enum";

import { getGlobalValue } from "utilities/globalValueUtils";

export interface IIsInPortalProps {
    /** @default false */
    builder?: boolean;

    /** @default false */
    subs?: boolean;

    /** @default false */
    owner?: boolean;

    /** @default false */
    btadmin?: boolean;
}

export interface IPortalType {
    isBuilder: boolean;
    isSubs: boolean;
    isOwner: boolean;
    isBtadmin: boolean;
}

/**
 * @see BTLoginTypes
 * This matches the enum server side BTLoginPortalType - do not change names
 */
export enum PortalType {
    ALL = 0,
    BUILDER = 1,
    OWNER = 2,
    SUBS = 3,
    LEAD = 4,
    BTADMIN = 5,
}

/**
 * @returns true when the current user is on one of the passed portals
 * @example if (isInPortal({ subs: true })) { alert("hello sub!"); }
 * @example if (isInPortal({ builder: true, owner: true })) { alert("hello builder or owner!"); }
 */
export function isInPortal(portals: IIsInPortalProps): boolean {
    const currentPortalType = getCurrentPortalType();
    if (portals.owner && currentPortalType === PortalType.OWNER) {
        return true;
    }

    if (portals.subs && currentPortalType === PortalType.SUBS) {
        return true;
    }

    if (portals.builder && currentPortalType === PortalType.BUILDER) {
        return true;
    }

    if (portals.btadmin && currentPortalType === PortalType.BTADMIN) {
        return true;
    }

    return false;
}

/**
 * @deprecated use UserInfoContext instead
 */
export function getCurrentPortalType(): PortalType {
    // The condition that a path including /app/link should use the fallback for non-SPA pages will need to be removed in the future
    // This check is currently in place to address an issue where the job picker is displaying for owners when they navigate to the app via an entity link in a new tab
    // More details outlined in User Story: 173751
    if (
        window.location.pathname.toLowerCase().includes("/app/") &&
        !window.location.pathname.toLowerCase().includes("/app/link")
    ) {
        if (window.location.pathname.toLowerCase().includes("/app/subs/")) {
            return PortalType.SUBS;
        }
        if (
            window.location.pathname.toLowerCase().includes("/app/owner/") ||
            window.location.pathname.toLowerCase().includes("/owner/ownerinvoice")
        ) {
            return PortalType.OWNER;
        }
        if (window.location.pathname.toLowerCase().includes("/app/admin/")) {
            return PortalType.BTADMIN;
        }
        if (window.location.pathname.toLowerCase().includes("/app/share/")) {
            return PortalType.LEAD;
        }
        return PortalType.BUILDER;
    }
    // fallback for non-SPA pages
    return getPortalForLoginType(getGlobalValue("loginTypeInt"));
}

export function getPortalForLoginType(loginType: BTLoginTypes | null): PortalType {
    switch (loginType) {
        case BTLoginTypes.BUILDER:
            return PortalType.BUILDER;
        case BTLoginTypes.SUBS:
            return PortalType.SUBS;
        case BTLoginTypes.OWNER:
        case BTLoginTypes.CONTACT:
            return PortalType.OWNER;
        case BTLoginTypes.LEAD:
            return PortalType.LEAD;
        case BTLoginTypes.BTADMIN:
            return PortalType.BTADMIN;
        default:
            // should not occur in practice
            return PortalType.ALL;
    }
}

export function getLoginTypeFromPortal(portal: PortalType): BTLoginTypes {
    switch (portal) {
        case PortalType.BUILDER:
            return BTLoginTypes.BUILDER;
        case PortalType.SUBS:
            return BTLoginTypes.SUBS;
        case PortalType.OWNER:
            return BTLoginTypes.OWNER;
        case PortalType.LEAD:
            return BTLoginTypes.LEAD;
        case PortalType.BTADMIN:
            return BTLoginTypes.BTADMIN;
        default:
            // should not occur in practice
            return BTLoginTypes.ALL;
    }
}

/**
 * @summary Returns the loginType based on the current portal
 * @deprecated use UserInfoContext instead
 */
export function getLoginTypeFromCurrentPortal(): BTLoginTypes {
    return getLoginTypeFromPortal(getCurrentPortalType());
}
