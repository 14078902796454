import chmln from "@chamaeleonidae/chmln";

import { EnvironmentInfo } from "helpers/AppProvider.types";

import { AccountingIntegrationType, BTLoginTypes } from "types/enum";

import {
    IChameleonIdentityAttributes,
    IChameleonOptions,
} from "utilities/thirdPartyIntegrations/chameleon/chameleon.types";

let initStarted: boolean = false;

export function ChameleonIntegration(context: IChameleonOptions) {
    /** Identifies the current user/builder with Chameleon */
    const identify = () => {
        const {
            userInfo,
            builderInfo,
            integrationUserProfile,
            integrationBuilderProfile,
            environmentInfo,
        } = context;

        const chamAttrs: IChameleonIdentityAttributes = {
            email: userInfo.email ?? "",
            name: integrationUserProfile.fullName,
            isAdmin: integrationUserProfile.isAdmin,
            userType: BTLoginTypes[userInfo.loginType],
            company: {
                uid: `${builderInfo.builderId}${getEnvironmentSuffix(environmentInfo)}`,
                name: integrationBuilderProfile.builderName,
                isConnectedToAccounting: integrationBuilderProfile.isConnectedToAccounting,
                accountingIntegrationType:
                    AccountingIntegrationType[integrationBuilderProfile.accountingIntegrationType],
            },
        };

        /* chameleon.io user identification and data */
        chmln.identify(`${userInfo.globalUserId}${getEnvironmentSuffix(environmentInfo)}`, {
            ...chamAttrs,
        });
    };

    return {
        name: "chameleon",
        config: context,
        /**
         * Initializes Chameleon and identifies the current user/builder
         */
        initialize: () => {
            if (initStarted) {
                // Already initialized
                return;
            }
            initStarted = true;

            chmln.init(context.chameleonToken, {
                fastUrl: "https://fast.chameleon.io/",
            });

            identify();
        },
    };
}

/**
 * Gets the environment abbreviation to append to Chameleon user/builder IDs
 * @param environmentInfo
 * @returns
 */
const getEnvironmentSuffix = (environmentInfo: EnvironmentInfo) => {
    if (environmentInfo.name === "Production") {
        return "";
    }

    if (environmentInfo.name.includes("Development")) {
        return "_Dev";
    }

    if (environmentInfo.name.includes("Ephemeral")) {
        return "_Eph";
    }

    return `_${environmentInfo.name}`;
};
