import { Component, Fragment } from "react";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTForm } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { JobPermissionResponse } from "entity/job/Job.api.types";

interface IPermissionWizardInternalProps {
    jobPermissions: JobPermissionResponse[];
    onSubmit: () => void;
    onCancel: () => void;
    onChange: (id: number, checked: boolean, index: number) => void;
    actionBeingPerformed?: string;
    modalConfig?: IModalConfiguration;
    fromBills?: boolean;
}

class PermissionWizardInternal extends Component<IPermissionWizardInternalProps> {
    isAnyPermissionSelected() {
        const { jobPermissions } = this.props;
        for (let j of jobPermissions) {
            for (let permission of j.permissions) {
                if (permission.selected) return true;
                if (permission.nestedOptions) {
                    for (let nestedOption of permission.nestedOptions) {
                        if (nestedOption.selected) return true;
                    }
                }
            }
        }
        return false;
    }

    render() {
        const {
            jobPermissions,
            onChange,
            onSubmit,
            onCancel,
            actionBeingPerformed,
            modalConfig,
            fromBills,
        } = this.props;

        const isButtonDisabled = fromBills ? !this.isAnyPermissionSelected() : false;

        const fields = jobPermissions.map((j, index) =>
            j.permissions.map((permission) => {
                if (permission.nestedOptions) {
                    const nestedFields = permission.nestedOptions.map((nestedOption) => {
                        return (
                            <BTRow key={nestedOption.id}>
                                <BTCol offset={1}>
                                    <BTCheckbox
                                        id={nestedOption.id.toString()}
                                        data-testid={nestedOption.id.toString()}
                                        checked={nestedOption.selected}
                                        key={nestedOption.id}
                                        onChange={(e) =>
                                            onChange(nestedOption.id, e.target.checked, index)
                                        }
                                    >
                                        {nestedOption.formattedName}
                                    </BTCheckbox>
                                </BTCol>
                            </BTRow>
                        );
                    });
                    return (
                        <Fragment key={permission.id}>
                            <BTRow>
                                <BTCheckbox
                                    id={permission.id.toString()}
                                    data-testid={permission.id.toString()}
                                    checked={permission.selected}
                                    key={permission.id}
                                    onChange={(e) =>
                                        onChange(permission.id, e.target.checked, index)
                                    }
                                >
                                    {permission.formattedName}
                                </BTCheckbox>
                            </BTRow>
                            {nestedFields}
                        </Fragment>
                    );
                } else {
                    return (
                        <BTRow key={permission.id}>
                            <BTCheckbox
                                id={permission.id.toString()}
                                data-testid={permission.id.toString()}
                                checked={permission.selected}
                                key={permission.id}
                                onChange={(e) => onChange(permission.id, e.target.checked, index)}
                            >
                                {permission.formattedName}
                            </BTCheckbox>
                        </BTRow>
                    );
                }
            })
        );

        return (
            <BTModalLayout
                title="Permission Wizard"
                footerContent={
                    <>
                        <BTButton
                            type="primary"
                            data-testid="permisisonWizardUpdate"
                            loadingAction="save"
                            actionBeingPerformed={actionBeingPerformed}
                            onClick={onSubmit}
                            disabled={isButtonDisabled}
                        >
                            Update Permissions
                        </BTButton>
                        <BTButton
                            type="secondary"
                            data-testid="permisisonWizardCancel"
                            loadingAction="cancel"
                            actionBeingPerformed={actionBeingPerformed}
                            onClick={onCancel}
                        >
                            Do Not Update Permissions
                        </BTButton>
                    </>
                }
                modalConfig={modalConfig}
                closable={false}
            >
                <BTForm data-testid="permission-wizard-form">
                    <BTLayoutContent>
                        {jobPermissions.map((perms, index) => (
                            <Fragment key={`section${index}`}>
                                <BTAlert
                                    message={perms.headerMessage}
                                    showIcon
                                    type="success"
                                    data-testid="headerMessage"
                                />
                                <PageSection title={perms.titleText}>
                                    <p style={{ fontWeight: 600 }}>{perms.subTitleText}</p>
                                    {fields[index]}
                                </PageSection>
                            </Fragment>
                        ))}
                    </BTLayoutContent>
                </BTForm>
            </BTModalLayout>
        );
    }
}

export default PermissionWizardInternal;
