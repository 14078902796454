import { InstagramFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { Grey1 } from "styles/antTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconInstagramLightFilled: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <InstagramFilled {...props} style={{ ...props.style, color: Grey1 }} />;
};
