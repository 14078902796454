import { MobileOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconMobileOutlined: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <MobileOutlined {...props} type="mobile" />;
};
