import { message } from "antd";
import { useMemo, useState } from "react";

import {
    FullscreenContext,
    IFullscreenContext,
} from "commonComponents/utilities/FullscreenContext/FullscreenContext";

export const FullscreenProvider: React.FunctionComponent = ({ children }) => {
    const [fullscreenElement, setFullscreenElement] = useState<HTMLElement>();

    const fullscreenContext = useMemo<IFullscreenContext>(() => {
        return {
            fullscreenElement,
            setFullscreen: async (element: HTMLElement | null | undefined) => {
                // @ts-ignore
                const supportsKeyboardLock =
                    // @ts-ignore
                    "keyboard" in navigator && "lock" in navigator.keyboard;

                if (!document.fullscreenElement && element) {
                    message.config({
                        getContainer: () => {
                            return element;
                        },
                    });
                    await element.requestFullscreen();

                    // lock the 'Escape' key - this is done so that when a modal is opened in a fullscreen mode,
                    // the user should not exit the fullscreen, but should close the modal by pressing the 'Escape' key
                    // and now for the user to exit the fullscreen, he/she should press and hold the 'Escape' key for 2 seconds
                    if (supportsKeyboardLock) {
                        // @ts-ignore
                        navigator.keyboard.lock(["Escape"]);
                    }

                    setFullscreenElement(element);
                } else {
                    await document.exitFullscreen();

                    // unlock the 'Escape' key
                    if (supportsKeyboardLock) {
                        // @ts-ignore
                        navigator.keyboard.unlock();
                    }

                    message.config({
                        getContainer: undefined,
                    });
                    setFullscreenElement(undefined);
                }
            },
            isInFullscreen: () => {
                return !!document.fullscreenElement;
            },
        };
    }, [fullscreenElement]);

    return (
        <FullscreenContext.Provider value={fullscreenContext}>
            {children}
        </FullscreenContext.Provider>
    );
};
