import { AccountingIntegrationType } from "types/enum";

export function getAccountingLabelText(
    integrationType: AccountingIntegrationType | null | undefined
) {
    let label = "";
    switch (integrationType) {
        case AccountingIntegrationType.QuickBooksDesktop:
        case AccountingIntegrationType.QuickBooksOnline:
            label = "QB";
            break;
        case AccountingIntegrationType.Xero:
            label = "Xero";
            break;
    }
    return label;
}
