import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ComponentProps, useCallback, useContext } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { isValidUserId } from "commonComponents/btWrappers/BTAvatar/BTAvatar.utils";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconCloseOutlined, BTIconPlus } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTSelectCustomItemContext } from "commonComponents/btWrappers/BTSelect/BTSelectCustomItemContext";
import { creatableSelectOption } from "commonComponents/btWrappers/BTSelectCreatable/BTSelectCreatable.utilities";
import { BTUser } from "commonComponents/btWrappers/BTUser/BTUser";
import { ArchivedUserTagDisplay } from "commonComponents/entity/internalUser/ArchivedUserTagDisplay";

interface IAssignToMeButtonProps {
    isSelected: boolean;
    onChange: () => void;
}

const AssignToMeButton: React.FunctionComponent<IAssignToMeButtonProps> = ({
    isSelected,
    onChange,
}) => {
    return (
        <div
            className="assignToMeWrapper"
            role="option"
            aria-selected={isSelected}
            data-testid="assignToMeButton"
            onClick={onChange}
        >
            Me
        </div>
    );
};

interface IAssignToMeCheckboxProps {
    isSelected: boolean;
    onChange: (event: CheckboxChangeEvent) => void;
}

const AssignToMeCheckbox: React.FunctionComponent<IAssignToMeCheckboxProps> = ({
    isSelected,
    onChange,
}) => {
    return (
        <div className="assignToMeWrapper">
            <BTCheckbox
                id="assignToMeCheckbox"
                data-testid="assignToMeCheckbox"
                checked={isSelected}
                onChange={onChange}
            >
                Me
            </BTCheckbox>
        </div>
    );
};

interface IInternalAvatarUserProps {
    item: BTSelectItem<any>;
    alwaysHideContactCard?: boolean;
}

const InternalAvatarUser: React.FunctionComponent<IInternalAvatarUserProps> = ({
    item,
    alwaysHideContactCard,
}) => {
    // force smaller is set when we are rendering a selected item
    const selectContext = useContext(BTSelectCustomItemContext);
    const isSelectValueRender = selectContext?.isSelectedValueRender ?? false;

    if (item.children || !isValidUserId(item.value)) {
        return <>{item.title}</>;
    }

    return (
        <>
            <BTUser
                displayName={item.title}
                globalUserId={item.value}
                type="full"
                contactCard={!alwaysHideContactCard && isSelectValueRender ? "hover" : "none"}
                size="small"
                className={item.className}
            />
            {!isSelectValueRender && item.extraData?.archived && <ArchivedUserTagDisplay />}
        </>
    );
};

interface ICreatableOptionProps {
    item: BTSelectItem<any>;
}

const CreatableOption: React.FunctionComponent<ICreatableOptionProps> = ({ item }) => {
    return (
        <BTRow gutter={5}>
            <BTCol>
                <BTIconPlus />
            </BTCol>
            <BTCol>{item.title}</BTCol>
        </BTRow>
    );
};

export function useBtSelectUserCustomItemRender(
    alwaysHideContactCard: boolean
): ComponentProps<typeof BTSelect>["customItemRender"] {
    return useCallback(
        (item: BTSelectItem<any>) => {
            if (item.id === creatableSelectOption) {
                return <CreatableOption item={item} />;
            }
            return <InternalAvatarUser alwaysHideContactCard={alwaysHideContactCard} item={item} />;
        },
        [alwaysHideContactCard]
    );
}

export function useBtSelectUserTagRender(): ComponentProps<typeof BTSelect>["tagRender"] {
    // https://github.com/react-component/select/blob/master/src/Selector/MultipleSelector.tsx
    // having a custom tag render adds a mouseDown event listener that toggles the dropdown
    const preventDropdownToggle = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);
    return useCallback(
        (props) => (
            <div className="UserTag">
                {props.label}
                {props.closable && !props.disabled && (
                    <BTButton
                        data-testid="remove"
                        icon={<BTIconCloseOutlined />}
                        onClick={props.onClose}
                        onMouseDown={preventDropdownToggle}
                        type="tertiary"
                        size="small"
                        className="UserTagRemove"
                    />
                )}
            </div>
        ),
        [preventDropdownToggle]
    );
}

interface IUseBtSelectUserDropdownRenderConfig<ExtraDataType> {
    showAssignToMe: boolean;
    multiple: boolean;
    isCurrentUserSelected: boolean;
    handleAssignToMeChecked: (e: CheckboxChangeEvent) => void;
    handleAssignToMeButtonClicked: () => void;
    currentUserItem: BTSelectItem<ExtraDataType> | undefined;
}
export function useBtSelectUserDropdownRender<ExtraDataType = undefined>({
    handleAssignToMeButtonClicked,
    handleAssignToMeChecked,
    multiple,
    isCurrentUserSelected,
    showAssignToMe,
    currentUserItem,
}: IUseBtSelectUserDropdownRenderConfig<ExtraDataType>): ComponentProps<
    typeof BTSelect
>["dropdownRender"] {
    return useCallback(
        (menu) => (
            <>
                {showAssignToMe && multiple && currentUserItem !== undefined && (
                    <AssignToMeCheckbox
                        isSelected={isCurrentUserSelected}
                        onChange={handleAssignToMeChecked}
                    />
                )}
                {showAssignToMe && !multiple && currentUserItem !== undefined && (
                    <AssignToMeButton
                        isSelected={isCurrentUserSelected}
                        onChange={handleAssignToMeButtonClicked}
                    />
                )}
                {menu}
            </>
        ),
        [
            handleAssignToMeChecked,
            handleAssignToMeButtonClicked,
            showAssignToMe,
            currentUserItem,
            isCurrentUserSelected,
            multiple,
        ]
    );
}
