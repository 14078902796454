import {
    BuilderInfo,
    EnvironmentInfo,
    IntegrationBuilderProfile,
    IntegrationUserProfile,
    UserInfo,
} from "helpers/AppProvider.types";

type ValueType = string | boolean | number;
interface IAttributes {
    [key: string]: ValueType;
}

/** Interface for Appcues object (`window.Appcues`) */
export interface IAppcues {
    identify: (userId: string, userProperties?: IAttributes) => void;
    group: (groupId: string, groupProperties: IAttributes) => void;
    page: () => void;
    track: (eventName: string, eventProperties?: IAttributes) => void;
    user: () => Promise<any>;
    refresh: () => void;
    clearShow: () => void;
}

/** Interface for Appcues init data */
export interface IAppcuesOptions {
    appcuesAccountId: string;
    userInfo: UserInfo;
    integrationUserProfile: IntegrationUserProfile;
    builderInfo: BuilderInfo;
    integrationBuilderProfile: IntegrationBuilderProfile;
    environmentInfo: EnvironmentInfo;
}

/**
 * Structure of 'form-submitted' event from Appcues.
 *
 * Note: Not an exhaustive list of Appcues properties. Add as needed.
 */
export interface IAppcuesFormSubmittedEvent {
    flowId: string;
    flowName: string;
    interaction: IAppcuesFormResponse;
}

/**
 * Form submission/response structure as received from Appcues.
 */
export interface IAppcuesFormResponse {
    response: IAppcuesFormField[];
}

/**
 * Form field structure as received from Appcues.
 *
 * Note: Not an exhaustive list of Appcues properties. Add as needed.
 */
export interface IAppcuesFormField {
    fieldId: string;
    fieldRequired: boolean;
    fieldType: string;
    label: string;
    value: string;
}

/** Values helpful in referencing Appcues content */
// Note: Updating Flows inside Appcues may cause some of these values to change. When making
//       updates inside Appcues, take this into consideration and update the necessary values
export class AppcuesConstants {
    /** 'Identify Your BT Champion - V2' Flow constants*/
    public static readonly Flow_IdentifyBTChampion_V2 = {
        FlowId: "0e537aa3-7159-49ba-a514-701523b7b042",
        FieldId_Name: "aa1e4882-3d20-4866-847a-1e18e292bd6f",
        FieldId_Email: "83c5038c-8413-4a02-a7df-ab1c634b748d",
        FieldId_Phone: "99de73a1-d317-40fa-9202-1bef33cc0f33",
    };

    /** 'Tax Setup For QuickBooks Online - Permalink' Flow */
    public static readonly Flow_TaxSetupForQuickBooksOnline = {
        FlowId: "87a912f5-6258-4d87-b854-93b87bc8c31f",
    };
}

export interface IAppcuesUserAttributes {
    userId: number;
    firstName: string;
    fullName: string;
    userName: string;
    isAdmin: boolean;
    isBtChampion: boolean;
    isInIframe: boolean;
    userType: string;
    userCreatedAt: number | null;
    userRole: string | null;
    hasActiveJobs: boolean;
    canAddDailyLogs: boolean;
    canAddToDos: boolean;
    canAddScheduleItems: boolean;
    canAddInternalUsers: boolean;
}

export interface IAppcuesGroupAttributes {
    builderId: number;
    builderName: string;
    isDemoAccount: boolean;
    isConnectedToAccounting: boolean;
    accountingIntegrationType: string;
    hasOnboardingContact: boolean;
    accountSetupDate: number;
    subscriptionPlan: string;
    isPaymentProcessingLinked: boolean;
    hasCustomCostCodes: boolean;
    activeInternalUserCount: number;
}
