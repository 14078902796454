import { ComponentProps } from "react";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { SubSetupTabKeys } from "entity/sub/SubSetup/SubSetup.api.types";

const SubSetup = lazyLoadWithRetry(() => import("entity/sub/SubSetup/SubSetup"));

export const RouteSubSetup = (
    props: Omit<
        ComponentProps<typeof SubSetup>,
        "id" | "openTradeAgreement" | "initialTab" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.sub.setup}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const subId = parseInt(routeValues.id);
            const initialTab = routeValues.initialTab;
            const openTradeAgreement = routeValues.openSubSetup === "true";

            return (
                <BTSuspense>
                    <SubSetup
                        {...props}
                        {...routeProps}
                        id={subId}
                        initialTab={initialTab as SubSetupTabKeys}
                        openTradeAgreement={openTradeAgreement}
                    />
                </BTSuspense>
            );
        }}
    />
);
