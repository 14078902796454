import { InstagramFilled } from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

interface IBTIconInstagramFilledProps extends IBTIconProps {
    isOnDark?: boolean;
}
export const BTIconInstagramFilled: FunctionComponent<IBTIconInstagramFilledProps> = (
    baseProps
) => {
    const { isOnDark, ...iconProps } = baseProps;
    const [props] = useAntIcon(iconProps);

    return (
        <InstagramFilled
            {...props}
            className={classNames("BTIconInstagramFilled", props.className, { isOnDark })}
        />
    );
};
