export interface ILanguageConstants {
    singular: string;
    plural: string;
}

export interface ISubsConstants extends ILanguageConstants {
    workersComp: string;
    tradeAgreementReviewModalTitle: string;
}

export interface IInternalUserConstants extends ILanguageConstants {
    fullAdminRoleName: string;
    projectManagerRoleName: string;
}

export class CommonConstants {
    public static readonly Owner: ILanguageConstants = {
        singular: "Owner",
        plural: "Owners",
    };
    public static readonly Sub: ISubsConstants = {
        singular: "Sub/Vendor",
        plural: "Subs/Vendors",
        workersComp: "Worker's Comp",
        tradeAgreementReviewModalTitle: "Trade Agreement Review and Approval",
    };
    public static readonly InternalUser: IInternalUserConstants = {
        singular: "Internal User",
        plural: "Internal Users",
        fullAdminRoleName: "Full Admin",
        projectManagerRoleName: "Project Manager",
    };
    public static readonly EmptyInteger = -999;
    public static readonly BidPackage: ILanguageConstants = {
        singular: "Bid Package",
        plural: "Bid Packages",
    };
    public static readonly Bid: ILanguageConstants = {
        singular: "Bid",
        plural: "Bids",
    };
    public static readonly Bill: ILanguageConstants = {
        singular: "Bill",
        plural: "Bills",
    };
    public static readonly Division: ILanguageConstants = {
        singular: "Division",
        plural: "Divisions",
    };
    public static readonly PurchaseOrders: ILanguageConstants = {
        singular: "PO",
        plural: "POs",
    };
    public static readonly POPayment: ILanguageConstants = {
        singular: "PO Payment",
        plural: "PO Payments",
    };
    public static readonly Selection: ILanguageConstants = {
        singular: "Selection",
        plural: "Selections",
    };
    public static readonly ToDo: ILanguageConstants = {
        singular: "To-Do",
        plural: "To-Do's",
    };
    public static readonly ScheduleStatus = {
        online: "online",
        offline: "offline",
    };
}

export class CellPhoneProvidersConstants {
    public static readonly NoProviderDomain: string = "none";
    public static readonly NoCarrierReturned: string = "No Carrier Returned";
}

// this is the percentage at which we should show how many characters remaining can be in a text input
export const ShowCharactersRemainingThreshold = 0.75;

export const BTCKEditorMaxLength = 200_000;

/** amount of time in milliseconds to delay calling onChange for inputs */
export const debounce_input_delay = 300;

/** amount of time in milliseconds to call onChange. This will be called if the user continuously types without pausing for debounce_input_delay milliseconds  */
export const max_debounce_delay = 1000;

export const BTContentRoot = "bt-content-root";

export const buttonLabels = {
    IMPORT: "Import",
};

export class BrandingConstants {
    public static readonly builderTrendBlue = "#00567b";
}

export class EmailConstants {
    public static readonly NoEmailPlaceholder: string = "--";
}
