import { InfoCircleFilled } from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import "./BTIconInfoCircleFilled.less";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export type IconType = "warning" | "error" | "info";

interface IBTIconInfoCircleFilledProps extends IBTIconProps {
    type?: IconType;
}

export const BTIconInfoCircleFilled: FunctionComponent<IBTIconInfoCircleFilledProps> = (
    baseProps
) => {
    const [props] = useAntIcon(baseProps);

    return (
        <InfoCircleFilled
            {...props}
            type="info-circle"
            style={{ ...props.style }}
            className={classNames("BTIconInfoCircleFilled", props.className, baseProps.type)}
        />
    );
};
