import { BTServiceFileUpload } from "legacyComponents/FileUploadContainer.types";
import moment from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { Countries } from "types/countries";

import { GetAddressServiceObject } from "commonComponents/entity/address/Address/Address";
import {
    AddressEntity,
    AddressFormValues,
    AddressServiceObject,
    IHasAddress,
} from "commonComponents/entity/address/Address/Address.api.types";
import { CellEmailLookupEntity } from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookup.api.types";
import {
    ICellPhoneInputFormValues,
    SmsOptInStatus,
    VerificationMessageType,
} from "commonComponents/entity/cellPhone/CellPhoneInput/CellPhoneInput.types";
import {
    ExternalEmailLookupEntity,
    ExternalEmailLookupFormValues,
} from "commonComponents/entity/externalEmailLookup/ExternalEmailLookup/ExternalEmailLookup.api.types";
import {
    INotificationPreference,
    INotificationPreferenceData,
    INotificationPreferenceRow,
} from "commonComponents/entity/notificationPreferences/NotificationPreferencesTable.api.types";
import { ICertificateFileFormValues } from "commonComponents/entity/sub/CertificateFile/CertificateFile.types";

import {
    ITradeAgreementExtraData,
    SubVendorCertFile,
} from "entity/sub/SubVendor/SubVendor.api.types";

import type { default as SubSetupAPIResponseExample } from "./SubSetup.api.json";
import type { default as SubSetupSaveAPIResponseExample } from "./SubSetupSave.api.json";

export type SubSetupFormActions = undefined | "save" | "delete" | "disconnect" | "manageAccounts";

export enum MerchantTypes {
    NeverConnected = -1,
    None = 0,
    WePay = 1,
    PaymentProcessingService = 3,
}

export enum SubSetupTabKeys {
    CompanyInfo = "1",
    TradeAgreement = "2",
    CertificatesContracts = "3",
    Notifications = "4",
    Payments = "5",
}

export interface ISubSetupFormValues extends ICellPhoneInputFormValues {
    // Login Info
    username: string;
    password: string;

    subId: number;

    // Company Info
    company: string;
    primaryContact: string;
    address: AddressFormValues;
    businessPhone: string;
    fax: string;
    email: ExternalEmailLookupFormValues;
    cellEmail: string;

    // Certificates and Contracts
    generalLiabilityCert: ICertificateFileFormValues;
    workmansCompCert: ICertificateFileFormValues;
    additionalCert1?: ICertificateFileFormValues;
    additionalCert2?: ICertificateFileFormValues;
    tradeAgreement?: ICertificateFileFormValues;

    // Notifications
    notificationPreferences: INotificationPreferenceRow[];
}

export class SubSetupEntity implements IHasAddress {
    constructor(data: typeof SubSetupAPIResponseExample) {
        this.builderId = data.builderId;
        this.userName = data.userName;
        this.subOptions = data.subOptions.map((item: any) => new BTSelectItem(item));
        this.company = data.company;
        this.primaryContact = data.primaryContact;
        this.cellPhone = data.cellPhone;
        this.businessPhone = data.businessPhone;
        this.cellEmail = data.cellEmail;
        this.fax = data.fax;
        this.cellEmailLookupLoadData = new CellEmailLookupEntity(data.cellEmailLookupLoadData);
        this.address = new AddressEntity(data.address);
        this.email = new ExternalEmailLookupEntity({
            value: data.email,
        });
        if (data.generalLiabilityCert) {
            this.generalLiabilityCert = new SubVendorCertFile(data.generalLiabilityCert);
        }
        if (data.additionalCert1) {
            this.additionalCert1 = new SubVendorCertFile(data.additionalCert1);
        }
        if (data.additionalCert2) {
            this.additionalCert2 = new SubVendorCertFile(data.additionalCert2);
        }
        if (data.workmansCompCert) {
            this.workmansCompCert = new SubVendorCertFile(data.workmansCompCert);
        }
        if (data.tradeAgreementContract) {
            this.tradeAgreementContract = new SubVendorCertFile(data.tradeAgreementContract);
        }

        this.notificationPreferences = data.notificationPreferences;
        this.smsOptInStatus = data.smsOptInStatus;
        this.countryCode = data.countryCode as Countries;
    }
    // Login info
    builderId: number;
    userName: string;

    subOptions: BTSelectItem[];

    // Company Info
    company: string;
    primaryContact: string;
    address: AddressEntity;
    businessPhone: string;
    cellPhone: string;
    fax: string;
    email: ExternalEmailLookupEntity;
    cellEmail: string;
    cellEmailLookupLoadData: CellEmailLookupEntity;

    // Certificates and Contracts
    generalLiabilityCert: SubVendorCertFile;
    workmansCompCert: SubVendorCertFile;
    additionalCert1?: SubVendorCertFile;
    additionalCert2?: SubVendorCertFile;
    additionalCertText: string | null;
    tradeAgreementContract: SubVendorCertFile<ITradeAgreementExtraData>;

    // Notifications
    notificationPreferences: INotificationPreferenceData;

    smsOptInStatus: SmsOptInStatus;
    countryCode: Countries;
}

export class SubSetupSaveRequest {
    constructor(values: ISubSetupFormValues, entity: SubSetupEntity) {
        this.subId = values.subId;
        this.companyName = values.company;
        this.primaryContact = values.primaryContact;
        this.address = GetAddressServiceObject(entity, values.address);
        this.businessPhone = values.businessPhone;
        this.cellPhone = values.phoneCell;
        this.fax = values.fax;
        this.email = values.email;
        this.cellEmail = values.cellEmail;
        this.generalLiabilityCert = new CertificateFileSaveRequest(
            values.generalLiabilityCert,
            entity.generalLiabilityCert
        );
        this.workmansCompCert = new CertificateFileSaveRequest(
            values.workmansCompCert,
            entity.workmansCompCert
        );

        if (values.additionalCert1 && entity.additionalCert1) {
            this.additionalCert1 = new CertificateFileSaveRequest(
                values.additionalCert1,
                entity.additionalCert1
            );
        }

        if (values.additionalCert2 && entity.additionalCert2) {
            this.additionalCert2 = new CertificateFileSaveRequest(
                values.additionalCert2,
                entity.additionalCert2
            );
        }
        this.notificationPreferences = values.notificationPreferences
            .filter((np) => !np.isCategoryRow && !np.isGroupRow)
            .map((row) => {
                const pref: INotificationPreference = {
                    emailEnabled: row.emailEnabled!,
                    textEnabled: row.textEnabled!,
                    pushEnabled: row.pushEnabled,
                    allUsersEnabled: row.allUsersEnabled,
                    name: row.name,
                    type: row.type!,
                };
                return pref;
            });
        this.verificationRequest = values.verificationRequest;
    }
    subId: number;
    companyName: string;
    primaryContact: string;
    address: AddressServiceObject;
    businessPhone: string;
    cellPhone: string;
    fax: string;
    email: ExternalEmailLookupFormValues;
    cellEmail: string;
    generalLiabilityCert: CertificateFileSaveRequest;
    workmansCompCert: CertificateFileSaveRequest;
    additionalCert1?: CertificateFileSaveRequest;
    additionalCert2?: CertificateFileSaveRequest;
    notificationPreferences: INotificationPreference[];
    verificationRequest: VerificationMessageType;
}

export class SubSetupSaveResponse {
    constructor(data: typeof SubSetupSaveAPIResponseExample) {
        this.smsOptInStatus = data.smsOptInStatus;
    }
    smsOptInStatus: SmsOptInStatus;
}

class CertificateFileSaveRequest {
    constructor(formValueCert: ICertificateFileFormValues, entityCert: SubVendorCertFile) {
        this.certExpires = formValueCert.certExpires;
        if (formValueCert && formValueCert.attachedFile) {
            this.file = formValueCert.attachedFile;
            this.deleteCert = false;
        } else if (entityCert.file) {
            // file exists on the server but not in form values
            this.deleteCert = true;
        }
    }

    certExpires?: moment.Moment;
    file?: BTServiceFileUpload;
    deleteCert: boolean;
}

export class UpdateSubUsernameResponse {
    constructor(data: any) {
        this.username = data.username;
    }
    username: string;
}
