import { BTSelectItem } from "types/apiResponse/apiResponse";
import { Countries } from "types/countries";

import type CellEmailLookupData from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookup.api.json";
import type CellEmailLookupLoadResponseAU from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookupLoadResponseAU.api.json";
import type CellEmailLookupLoadResponseGB from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookupLoadResponseGB.api.json";
import type CellEmailLookupLoadResponseLV from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookupLoadResponseLV.api.json";
import type CellEmailLookupLoadResponseUS from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookupLoadResponseUS.api.json";

type CellEmailLookupEntityApiData =
    | typeof CellEmailLookupLoadResponseUS
    | typeof CellEmailLookupLoadResponseAU
    | typeof CellEmailLookupLoadResponseGB
    | typeof CellEmailLookupLoadResponseLV;

export class CellEmailLookupEntity {
    constructor(data: CellEmailLookupEntityApiData) {
        // Set by the service to fill in the dropdowns and number formatting correctly
        this.serviceProviders = data.serviceProviders.map(
            (item) => new BTSelectItem<IServiceProvidersExtraData>(item)
        );
        this.countryCode = data.countryCode as Countries;
    }

    serviceProviders: BTSelectItem<IServiceProvidersExtraData>[];
    countryCode: Countries;
}

export class LookupResponse {
    constructor(data: typeof CellEmailLookupData) {
        this.domain = data.domain;
        this.domainMessage = data.domainMessage;
    }
    domain: string;
    domainMessage: string;
}

export interface IServiceProvidersExtraData {
    domain: string;
}

/**
 * This class takes in the cell email and service providers, splits it up and finds the appropriate values to be used in the form display.
 * This needs to be used because cell emails are saved as a single string instead of a phone number and service provider.
 */
export class CellEmailLookupDeconstructedValues {
    constructor(cellEmail: string, serviceProviders: BTSelectItem<IServiceProvidersExtraData>[]) {
        this.cellEmail = cellEmail;
        const splitCellEmail = cellEmail.split("@");
        this.cellNumber = splitCellEmail[0];
        this.domain = splitCellEmail[1] || "";
        if (this.domain) {
            const serviceProvider = serviceProviders.find(
                (item: BTSelectItem<IServiceProvidersExtraData>) =>
                    item.extraData!.domain === this.domain
            );
            if (serviceProvider) {
                this.providerID = serviceProvider.value;
            } else {
                this.providerID = -1;
            }
        } else {
            this.providerID = -1;
        }
    }

    cellEmail: string;
    cellNumber: string;
    domain: string;
    providerID: number;
}
