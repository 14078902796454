import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IReceiptUploadProps } from "./ReceiptUpload";

const ReceiptUpload = lazyLoadWithRetry(() => import("./ReceiptUpload"));

export const RouteUploadReceipt = (
    props: Omit<IReceiptUploadProps, "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.receipts.upload}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <ReceiptUpload {...props} {...routeProps} />
                </BTSuspense>
            );
        }}
    />
);
