import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { showAPIErrorMessage } from "utilities/apiHandler";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    IScheduleConflictHandler,
    ScheduleConflictHandler,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.handler";
import {
    IViewSwitcherInfo,
    ScheduleConflictResponse,
    ScheduleConflictViews,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import ScheduleConflictReportPresentational from "commonComponents/entity/scheduleConflicts/ScheduleConflictReport/ScheduleConflictReportPresentational";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import "./ScheduleConflictReport.less";

interface IScheduleConflictReportProps extends RouteComponentProps {
    conflictHandler?: IScheduleConflictHandler;
    startingView: ScheduleConflictViews;
    startingId?: number;
    startingData?: ScheduleConflictResponse;
    modalConfig?: IModalConfiguration;
    handleContinue?: () => void;
}

interface IScheduleConflictReportState {
    data?: ScheduleConflictResponse;
    jobsForDropdown?: IViewSwitcherInfo[];
    usersForDropdown?: IViewSwitcherInfo[];
    view: ScheduleConflictViews;
    showPastDates: boolean;
    loading?: boolean;
    id?: number;
}

class ScheduleConflictReportInternal extends Component<
    IScheduleConflictReportProps,
    IScheduleConflictReportState
> {
    static defaultProps = {
        conflictHandler: new ScheduleConflictHandler(),
    };

    state: Readonly<IScheduleConflictReportState> = {
        view: ScheduleConflictViews.All,
        showPastDates: false,
    };

    private getData = async (view: ScheduleConflictViews, showPastDates: boolean, id?: number) => {
        if (view !== ScheduleConflictViews.All && id === undefined) {
            throw "ID must be provided when viewing filtered conflicts";
        }
        switch (view) {
            case ScheduleConflictViews.All:
                return await this.props.conflictHandler!.getAllConflicts(showPastDates);
            case ScheduleConflictViews.User:
                return await this.props.conflictHandler!.getConflictsByUser(id!, showPastDates);
            case ScheduleConflictViews.Job:
                return await this.props.conflictHandler!.getConflictsByJob(id!, showPastDates);
            case ScheduleConflictViews.Schedule:
                return await this.props.conflictHandler!.getConflictsBySchedule(id!, showPastDates);
        }
    };

    private getDataWithDropdowns = async (
        view: ScheduleConflictViews,
        showPastDates: boolean,
        id?: number,
        data?: ScheduleConflictResponse
    ): Promise<IScheduleConflictReportState> => {
        const resp = data || (await this.getData(view, showPastDates, id));

        const jobsForDropdown = {};
        const usersForDropdown = {};
        resp.conflicts.forEach((item) => {
            usersForDropdown[item.globalUserId] = { name: item.name, id: item.globalUserId };
            item.conflicts.forEach(
                (c) => (jobsForDropdown[c.jobsiteID] = { name: c.jobName, id: c.jobsiteID })
            );
        });

        return {
            data: resp,
            jobsForDropdown: Object.values<IViewSwitcherInfo>(jobsForDropdown),
            usersForDropdown: Object.values<IViewSwitcherInfo>(usersForDropdown),
            view,
            showPastDates,
            loading: false,
            id,
        };
    };

    private reloadData = async (
        view: ScheduleConflictViews,
        showPastDates: boolean,
        id?: number
    ) => {
        try {
            this.setState({ loading: true });
            const newState = await this.getDataWithDropdowns(view, showPastDates, id);
            this.setState(newState);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleSetConflictThreshold = (userId: number, updatedNumberOfConflicts: number) => {
        const index = this.state.data!.conflicts.findIndex((c) => c.globalUserId === userId);
        if (index === -1) {
            return;
        }
        const newData = [...this.state.data!.conflicts];
        newData[index] = {
            ...newData[index],
            itemCount: updatedNumberOfConflicts!,
        };
        this.setState({
            data: {
                ...this.state.data!,
                conflicts: newData,
            },
        });
    };

    async componentDidMount() {
        try {
            const startingState = await this.getDataWithDropdowns(
                this.props.startingView,
                false,
                this.props.startingId,
                this.props.startingData
            );
            this.setState(startingState);
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    }

    render() {
        if (!this.state.data) {
            return <BTLoading />;
        }

        const component = (
            <ScheduleConflictReportPresentational
                data={this.state.data!}
                onContinue={this.props.handleContinue}
                onSetConflictThreshold={this.handleSetConflictThreshold}
                onShowPastDatesChanged={(newValue) =>
                    this.reloadData(this.state.view, newValue, this.state.id)
                }
                onScheduleItemChanged={() =>
                    this.reloadData(this.state.view, this.state.showPastDates, this.state.id)
                }
                onViewSwitched={(newView, newId) =>
                    this.reloadData(newView, this.state.showPastDates, newId)
                }
                modalConfig={this.props.modalConfig}
                {...this.props}
                {...this.state}
            />
        );

        if (this.props.modalConfig) {
            return (
                <BTModal
                    data-testid="btModalScheduleConflictReport"
                    visible
                    useModalLayout
                    title="Conflict Report"
                    setPageTitle={false}
                    width="800px"
                    removeBodyPadding
                    beforeClose={this.props.modalConfig.beforeClose}
                >
                    {component}
                </BTModal>
            );
        }

        return component;
    }
}

const ScheduleConflictReport = withErrorBoundary(ScheduleConflictReportInternal)(
    "Could not load conflicts"
);
export default ScheduleConflictReport;
