import Icon from "@ant-design/icons";
import { FunctionComponent } from "react";

import SVG from "commonComponents/btWrappers/BTIcon/images/Selections.svg?react";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

export const BTIconAllowances: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useBaseIcon(baseProps);

    // eslint-disable-next-line react/forbid-elements
    return <Icon {...props} component={SVG} />;
};
