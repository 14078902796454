import { FunctionComponent, useContext } from "react";
import { Redirect, useRouteMatch } from "react-router";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { routes } from "utilities/routes";

const RedirectToSetupSummary: FunctionComponent = () => {
    const match = useRouteMatch();

    const userContext = useContext(UserInfoContext);
    // If the user is trying to get to the subscription manager, we don't want to redirect them to the setup experience
    const isSubscriptionRoute = match.path === routes.subscriptionManager.settings;

    if (userContext?.shouldRedirectToSetupExperience && !isSubscriptionRoute) {
        return <Redirect to={routes.builderSetup.setup} />;
    }
    return null;
};

export default RedirectToSetupSummary;
