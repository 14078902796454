import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Options as ReactHotkeyOptions, useHotkeys as useReactHotkey } from "react-hotkeys-hook";

import { hasBdsHotkey } from "@buildertrend/components";

import { FocusContext } from "commonComponents/utilities/Focus/FocusContext";
import { AvailableTags } from "commonComponents/utilities/Hotkey/hotkey.types";

interface IUseHotkeyArgs {
    command: string;
    onCommand?: Function;
    triggerBehavior?: "press" | "up" | "down";
    disabled?: boolean;
    preventDefaultWhenDisabled?: boolean;
    supportedTags?: AvailableTags[];
}

export function useHotkey({
    command,
    onCommand,
    supportedTags,
    triggerBehavior = "press",
    preventDefaultWhenDisabled = true,
    disabled = false,
}: IUseHotkeyArgs) {
    const commandWrapper = useCallback(
        (e: KeyboardEvent) => {
            if ((!disabled || preventDefaultWhenDisabled) && !hasBdsHotkey(e)) {
                e?.preventDefault();
                e?.stopPropagation();
            }
            if (!disabled && !e.repeat) {
                onCommand?.();
            }
        },
        [disabled, preventDefaultWhenDisabled, onCommand]
    );

    const commandWrapperStaticRef = useRef(commandWrapper);
    useEffect(() => {
        commandWrapperStaticRef.current = commandWrapper;
    }, [commandWrapper]);

    const focusContext = useContext(FocusContext);
    const commandOptions = useMemo<ReactHotkeyOptions>(() => {
        const options: ReactHotkeyOptions = {
            filter: () => {
                return focusContext?.isCurrentFocus ?? false;
            },
            filterPreventDefault: false,
            enableOnTags: supportedTags ?? ["INPUT", "SELECT", "TEXTAREA"],
            enableOnContentEditable: true,
            keydown:
                triggerBehavior === "down" ? true : triggerBehavior !== "up" ? undefined : false,
            keyup: triggerBehavior === "up" ? true : triggerBehavior !== "down" ? undefined : false,
        };
        return options;
    }, [supportedTags, focusContext, triggerBehavior]);

    useReactHotkey(command, (e) => commandWrapperStaticRef.current(e), commandOptions);
}
