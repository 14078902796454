import { APIHandlerVersion, IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { MenuItemName } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import {
    GlobalSearchEntity,
    GlobalSearchFilters,
    GlobalSearchResult,
    ValidateRecentlyViewedResults,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { SearchCategory } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

export interface ISearchGetRequest {
    search: string;
    limit: number;
    categories?: SearchCategory[];
    jobIds?: number[];
    requestedPage?: MenuItemName;
}

export interface IGlobalSearchHandler {
    get(request: ISearchGetRequest): IAPIHandlerResult<GlobalSearchEntity>;
    getFilters(searchRequestedPage: MenuItemName): Promise<GlobalSearchFilters>;
    validateSearchItems(searchItems: GlobalSearchResult[]): Promise<ValidateRecentlyViewedResults>;
}

export class GlobalSearchHandler implements IGlobalSearchHandler {
    get(request: ISearchGetRequest): IAPIHandlerResult<GlobalSearchEntity> {
        const { limit, ...data } = request;
        return APIHandler(`/api/Search?limit=${limit}`, {
            method: "POST",
            data: {
                search: data.search,
                jobIds: data.jobIds ?? [],
                categories: data.categories ?? [],
                requestedPage: data.requestedPage ?? MenuItemName.None,
            },
            responseType: GlobalSearchEntity,
            version: APIHandlerVersion.cancellable,
        });
    }

    async getFilters(searchRequestedPage: MenuItemName): Promise<GlobalSearchFilters> {
        return await APIHandler(`/api/Search/filters?requestedPage=${searchRequestedPage}`, {
            method: "GET",
            responseType: GlobalSearchFilters,
        });
    }

    async validateSearchItems(
        searchItems: GlobalSearchResult[]
    ): Promise<ValidateRecentlyViewedResults> {
        return await APIHandler("/api/Search/Validate", {
            method: "POST",
            data: {
                searchItems: searchItems.map((r) => ({
                    id: r.id,
                    builderId: r.builderId,
                    jobId: r.jobId,
                    category: r.category,
                })),
            },
            responseType: ValidateRecentlyViewedResults,
        });
    }
}
