import { UAParser } from "ua-parser-js";

export function getMobileOS(): "Android" | "iOS" | "Other" {
    // Possible 'os.name's: https://github.com/faisalman/ua-parser-js/tree/1.0.38#documentation
    const os = UAParser(navigator.userAgent).os.name;
    switch (os) {
        case "Android":
            return os;
        case "iOS":
            return os;
        default:
            return "Other";
    }
}
