import { Input, message } from "antd";
import classNames from "classnames";
import { Component } from "react";

import { writeText } from "utilities/clipboard/clipboard";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTButton, BTButtonType } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTInput, IBTInputProps } from "commonComponents/btWrappers/BTInput/BTInput";
import "commonComponents/btWrappers/BTInputCopy/BTInputCopy.less";
import { convertToValidURL } from "commonComponents/btWrappers/BTInputLink/BTInputLink";

export interface IBTInputCopyProps<FormValues>
    extends Omit<IBTInputProps<FormValues>, "id" | "onChange" | "value" | "readOnly"> {
    id: KeyOfOrString<FormValues>;
    "data-testid": string;
    disabled?: boolean;
    onChange: (field: KeyOfOrString<FormValues>, value: string) => void;
    value: string | number | undefined;
    linkValue?: string; // This is for if you want your link to be different than what is displayed
    buttonType?: BTButtonType;
    buttonText?: string;
}

export class BTInputCopy<FormValues = undefined> extends Component<IBTInputCopyProps<FormValues>> {
    static defaultProps = {
        buttonText: "Copy",
    };

    private handleClick = async () => {
        const { value } = this.props;
        if (value !== undefined) {
            await writeText(value.toString());
            void message.success("Copied");
        }
    };

    render = () => {
        const {
            id,
            value,
            linkValue,
            buttonType,
            buttonText,
            disabled,
            onChange,
            "data-testid": testid,
            size,
            ...otherProps
        } = this.props;
        let inputField;
        if (disabled && linkValue) {
            const containerClassNames = classNames(
                "ant-input",
                "ant-input-disabled",
                "bt-input-copy-disabled",
                {
                    "ant-input-lg": size === "large",
                    "ant-input-sm": size === "small",
                }
            );

            // Make clickable link if linkValue is provided and input is disabled
            inputField = (
                <div className={containerClassNames}>
                    <BTExternalLink
                        href={convertToValidURL(linkValue)}
                        data-testid={testid}
                        target="_blank"
                    >
                        {value!.toString()}
                    </BTExternalLink>
                </div>
            );
        } else {
            inputField = (
                <BTInput
                    id={id}
                    data-testid={testid}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    size={size}
                    {...otherProps}
                />
            );
        }

        return (
            <Input.Group compact className="flex">
                {inputField}
                <BTButton
                    data-testid="inputButton"
                    type={buttonType || "secondary"}
                    onClick={this.handleClick}
                    size={this.props.size}
                >
                    {buttonText}
                </BTButton>
            </Input.Group>
        );
    };
}
