import { Circle, Marker } from "@react-google-maps/api";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { debounce } from "lodash-es";
import { useMemo } from "react";

import CustomInfoWindow from "commonComponents/entity/map/common/CustomInfoWindow";
import { IMark, PinTypes } from "commonComponents/entity/map/Map.types";
import { GetIconPathForPinType } from "commonComponents/entity/map/mapUtilities";

interface ICustomMarkProps {
    mark: IMark;
    showInfoWindow: boolean;
    onMouseOver: (id: string) => void;
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onCloseClick: () => void;
    clusterer?: Clusterer;
    autoClose?: boolean;
}

const CustomMark: React.FunctionComponent<ICustomMarkProps> = ({
    clusterer,
    mark,
    onCloseClick,
    onMouseOver,
    showInfoWindow,
    autoClose,
    onClick,
}) => {
    const debouncedOnClose = useMemo(() => debounce(onCloseClick, 100), [onCloseClick]);

    if (mark.radius === undefined && mark.pinType !== PinTypes.None) {
        // if the mark represents a Marker:
        const isAnimated = mark.animate;
        const size = mark.size ?? { height: 32, width: 32 };
        return (
            <>
                <Marker
                    key={mark.id + (isAnimated ? "-animated" : "")}
                    title={mark.title}
                    position={mark.position}
                    icon={{
                        url: GetIconPathForPinType(mark.pinType),
                        scaledSize: new google.maps.Size(size.width, size.height),
                    }}
                    animation={isAnimated ? google.maps.Animation.BOUNCE : undefined}
                    clusterer={clusterer}
                    onClick={onClick}
                    onMouseOver={() => {
                        if (mark.infoWindowContent !== undefined) {
                            onMouseOver(mark.id.toString());
                        }
                    }}
                    onMouseOut={() => {
                        if (autoClose) {
                            debouncedOnClose();
                        }
                    }}
                />
                {showInfoWindow && mark.infoWindowContent !== undefined && (
                    <CustomInfoWindow mark={mark} onCloseClick={onCloseClick} />
                )}
            </>
        );
    } else {
        // else if the mark represents a Circle:
        const options: google.maps.CircleOptions = {
            strokeWeight: 0,
            fillColor: mark.radiusColor,
            clickable: false,
            draggable: false,
            editable: false,
        };

        return (
            <Circle
                key={mark.id}
                center={mark.position}
                radius={mark.radius !== undefined ? mark.radius : 0}
                options={options}
            />
        );
    }
};

export default CustomMark;
