import { Result, Typography } from "antd";
import { buildertrendSupportInfo } from "layouts/errors/errorConstants";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";
import { PhoneNumberDisplay } from "commonComponents/utilities/PhoneNumberDisplay/PhoneNumberDisplay";

import "./ServerError.less";

const ServerError: React.FunctionComponent = ({ children }) => {
    return (
        <BTRow responsiveMode="viewport" justify="center" align="middle" className="ServerError">
            <BTCol span={24}>
                <BTRow responsiveMode="viewport" justify="center">
                    <BTLogo
                        layout="vertical"
                        color="dark-blue-blue"
                        size="md"
                        className="margin-top-lg"
                    />
                </BTRow>
                <Result
                    status="500"
                    title="500"
                    subTitle={
                        <BTRow responsiveMode="viewport" className="margin-top-md" justify="center">
                            <BTCol sm={16} xs={24}>
                                <Typography.Paragraph>
                                    Sorry, something went wrong and our systems are currently
                                    unavailable. Thank you for your patience as we work to restore
                                    access as quickly as possible.
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    If you have additional questions, our Support team can be
                                    reached at{" "}
                                    <Typography.Text strong>
                                        <PhoneNumberDisplay
                                            phoneNumber={buildertrendSupportInfo.phoneNumber}
                                        />{" "}
                                        extension {buildertrendSupportInfo.extension}
                                    </Typography.Text>
                                    .
                                </Typography.Paragraph>
                            </BTCol>
                        </BTRow>
                    }
                    extra={children}
                />
            </BTCol>
        </BTRow>
    );
};

export default ServerError;
