import { useCallback, useMemo, useState } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { isNullOrWhitespace } from "utilities/string/string";

import { BTSelectProps } from "commonComponents/btWrappers/BTSelect/BTSelect";

import "./BTSelectCreatable.less";

interface IBTSelectCreatableProps<ExtraDataType>
    extends Pick<BTSelectProps<unknown, ExtraDataType>, "onChange" | "treeData"> {
    onOptionCreate: (field: string, value?: string | number, search?: string) => void;
    maxLength: number;
    createableEntityName?: string;
    canCreateEntity: boolean;
}

export const creatableSelectOption: string = "-9999999999";

type SelectCreatableResult<ExtraDataType = undefined> =
    | Pick<
          BTSelectProps<unknown, ExtraDataType>,
          "onChange" | "onSearch" | "notFoundContent" | "treeData"
      >
    | Pick<BTSelectProps<unknown, ExtraDataType>, "treeData">;

export function useSelectCreatable<ExtraDataType>({
    onChange,
    treeData: originalTreeData,
    onOptionCreate,
    maxLength,
    createableEntityName,
    canCreateEntity,
}: IBTSelectCreatableProps<ExtraDataType>): SelectCreatableResult<ExtraDataType> {
    const [search, setSearch] = useState<string>();

    const maxExceeded = (search?.length ?? 0) > maxLength;

    const treeData = useMemo(() => {
        let result = originalTreeData;
        if (result && search && !maxExceeded) {
            const createOption = new BTSelectItem<ExtraDataType>({
                id: creatableSelectOption,
                title: createableEntityName
                    ? `Create ${createableEntityName} "${search}"`
                    : `Create "${search}"`,
                className: "SelectCreatableOption",
            });
            result = [...result, createOption];
        }
        return result;
    }, [maxExceeded, originalTreeData, search, createableEntityName]);

    const handleChange = useCallback(
        (field: string, value?: string | number, selectedItem?: BTSelectItem<ExtraDataType>) => {
            if (value?.toString() === creatableSelectOption) {
                onOptionCreate(field, value, search);
            } else {
                onChange(field, value, selectedItem);
            }
            setSearch(undefined);
        },
        [onChange, onOptionCreate, search]
    );

    const handleChildSearch = useCallback(
        (node: BTSelectItem<ExtraDataType>, targetTitle: string) => {
            if (node.title && node.title.toLocaleLowerCase() === targetTitle.toLocaleLowerCase()) {
                return true;
            }

            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    if (handleChildSearch(child, targetTitle)) {
                        return true;
                    }
                }
            }
            return false;
        },
        []
    );

    const handleSearch = useCallback(
        (value: string) => {
            const removeCreate =
                isNullOrWhitespace(value) ||
                originalTreeData?.find((item) => handleChildSearch(item, value));
            setSearch(removeCreate ? undefined : value);
        },
        [originalTreeData, handleChildSearch]
    );

    if (!canCreateEntity) {
        return { treeData: originalTreeData };
    }

    const notFoundContent =
        search && maxExceeded ? `${search.length - maxLength} characters over` : undefined;

    return { onChange: handleChange, onSearch: handleSearch, notFoundContent, treeData };
}
