/**
 * DO NOT USE
 * This will detect if the current instance is being run in a test
 * You should never do this, this method was added as a hack to fix a third party compatibility bug with CKEditor running in tests
 */
export function isUnitTest() {
    return (
        import.meta.env.MODE === "test" ||
        navigator.userAgent.includes("Node.js") ||
        navigator.userAgent.includes("jsdom")
    );
}

enum SentryEnvironments {
    Ephemeral = "Ephemeral",
    Arch = "arch.buildertrend.net",
    ArchiveSites = "archivesites.buildertrend.net",
    AutomatedTesting = "at.buildertrend.net",
    Staging = "staging.buildertrend.net",
    Dev = "Dev",
    Test = "test.buildertrend.net",
    Production = "buildertrend.net",
    Unknown = "Unknown",
}

/** @deprecated hack, please do not use */
export function isDevEnvironment() {
    return getCurrentSentryEnvironment() === SentryEnvironments.Dev;
}

export function getCurrentSentryEnvironment(): SentryEnvironments {
    const hostName: string = window.location.hostname;

    if (hostName.includes(".ephpr.")) {
        return SentryEnvironments.Ephemeral;
    } else if (hostName.includes("arch.")) {
        return SentryEnvironments.Arch;
    } else if (hostName.includes("archives.")) {
        return SentryEnvironments.ArchiveSites;
    } else if (hostName.includes("at.")) {
        return SentryEnvironments.AutomatedTesting;
    } else if (hostName.includes("staging.")) {
        return SentryEnvironments.Staging;
    } else if (
        hostName.includes("dev.buildertrend.net") ||
        hostName.includes("local.buildertrend.net") ||
        hostName.includes("localhost") ||
        hostName.includes("127.0.0.1")
    ) {
        return SentryEnvironments.Dev;
    } else if (hostName.includes("test.")) {
        return SentryEnvironments.Test;
    } else if (hostName === "buildertrend.net") {
        return SentryEnvironments.Production;
    }

    return SentryEnvironments.Unknown;
}

export function getCurrentEnvironment(): string {
    const hostName: string = window.location.hostname;
    let environment: string;

    if (hostName.includes("arch.")) {
        environment = "arch";
    } else if (hostName.includes("archives.")) {
        environment = "archivesites";
    } else if (hostName.includes("at.")) {
        environment = "at";
    } else if (hostName.includes("staging.")) {
        environment = "staging";
    } else if (hostName.includes("dev.buildertrend.net") || hostName.includes(".ephpr.")) {
        environment = hostName.split(".")[0];
    } else if (
        hostName.includes("localhost") ||
        hostName.includes("127.0.0.1") ||
        hostName.includes("local.buildertrend.net")
    ) {
        environment = "dev";
    } else if (hostName.includes("test.")) {
        environment = "test";
    } else if (hostName === "buildertrend.net") {
        environment = "prod";
    } else {
        environment = "unknown";
    }
    return environment;
}
