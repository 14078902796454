import { BTSelectItem } from "types/apiResponse/apiResponse";

import yup from "utilities/form/yup";

import {
    IEntityExtraData,
    LinkingAction,
} from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";

export const AccountingLinkingOptionsEntityValidator = yup.number().oneOf(
    Object.keys(JobsiteLinkingTypes)
        .map((val) => Number(val))
        .filter((val) => !isNaN(val))
);

export const AccountingLinkingOptionsActionValidator = yup.number().oneOf(
    Object.keys(LinkingAction)
        .map((val) => Number(val))
        .filter((val) => !isNaN(val))
);

export const MapLinkedAccountingContactOptions = (data: any) => {
    return (
        data.linkingOptions &&
        data.linkingOptions.map(
            (item: any) =>
                new BTSelectItem<IEntityExtraData>(item, (s) => {
                    if (s.extraData.chooseMessageOptions) {
                        s.extraData.chooseMessageOptions = s.extraData.chooseMessageOptions.map(
                            (item: any) => new BTSelectItem(item)
                        );
                    }
                })
        )
    );
};
