import { Dropdown } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import classNames from "classnames";
import { PureComponent } from "react";

import { track } from "utilities/analytics/analytics";
import { getClosestModal } from "utilities/helpers";

import "./BTDropdown.less";

export interface IBTDropdownProps extends DropDownProps {}

/**
 * This component wraps ant-designs Dropdown component.
 * Basic wrapping that just fixes some of the styling of that
 * component.
 */
@track({ component: "Dropdown Menu" })
export class BTDropdown extends PureComponent<IBTDropdownProps> {
    static defaultProps = {
        trigger: ["click"],
        getPopupContainer: getClosestModal,
    };

    render() {
        const {
            trigger,
            overlayStyle,
            overlayClassName,
            disabled,
            overlay,
            children,
            className,
            getPopupContainer,
            ...otherProps
        } = this.props;

        return (
            // eslint-disable-next-line react/forbid-elements
            <Dropdown
                overlay={overlay}
                getPopupContainer={getPopupContainer}
                overlayClassName={classNames(overlayClassName, "BTDropdownOverlay")}
                disabled={disabled}
                trigger={trigger}
                overlayStyle={overlayStyle}
                className={classNames(className, "BTDropdown")}
                // We have to use otherProps here pending https://github.com/react-component/dropdown/pull/202
                {...otherProps}
            >
                {children}
            </Dropdown>
        );
    }
}
