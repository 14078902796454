import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { AcceptDocuments } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";

import {
    AcceptTermsAndPoliciesHandler,
    IAcceptTermsAndPoliciesHandler,
} from "commonComponents/utilities/AcceptTermsAndPolicies/acceptTermsAndPolicies/AcceptTermsAndPolicies.api.handler";
import { AcceptTermsAndPoliciesPresentational } from "commonComponents/utilities/AcceptTermsAndPolicies/acceptTermsAndPolicies/AcceptTermsAndPoliciesPresentational";

interface IAcceptTermsAndPoliciesProps extends RouteComponentProps {
    closeHeaderModal: () => void;
    handler?: IAcceptTermsAndPoliciesHandler;
}

interface IAcceptTermsAndPoliciesState {
    isAlertModalVisible: boolean;
}

export class AcceptTermsAndPolicies extends Component<
    IAcceptTermsAndPoliciesProps,
    IAcceptTermsAndPoliciesState
> {
    static defaultProps = {
        handler: new AcceptTermsAndPoliciesHandler(),
    };

    state: Readonly<IAcceptTermsAndPoliciesState> = {
        isAlertModalVisible: true,
    };

    private handleAccept = async () => {
        let apiResponse;
        try {
            apiResponse = await this.props.handler!.acceptTermsAndPolicies({
                acceptDocuments: [AcceptDocuments.TermsOfUseAndPrivacyPolicy],
            });
            this.props.closeHeaderModal();
        } catch (e) {
            this.setState({ isAlertModalVisible: false });
            showAPIErrorMessage(e);
        }

        if (apiResponse) {
            this.setState({ isAlertModalVisible: false });
        }
    };

    private handleDismiss = async () => {
        this.setState({ isAlertModalVisible: false });
    };

    render() {
        return (
            this.state.isAlertModalVisible && (
                <AcceptTermsAndPoliciesPresentational
                    {...this.props}
                    onAccept={this.handleAccept}
                    onDismiss={this.handleDismiss}
                />
            )
        );
    }
}
