import { ComponentProps } from "react";
import { RouteComponentProps } from "react-router";

import { MediaType } from "types/enum";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

const Photo = lazyLoadWithRetry(() => import("entity/photo/Photo/Photo"));
const Video = lazyLoadWithRetry(() => import("entity/video/Video/Video"));
const Document = lazyLoadWithRetry(() => import("entity/document/Document/Document"));

export const RouteMediaProperties = (
    props: Omit<
        ComponentProps<typeof Video>,
        "documentInstanceId" | "jobId" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.media.mediaProperties}
        render={(routeProps) => <BTSuspense>{getComponent(props, routeProps)}</BTSuspense>}
    />
);

const getComponent = (props: any, routeProps: RouteComponentProps<any>) => {
    const mediaType = parseInt(routeProps.match.params.mediaType);
    switch (mediaType) {
        case MediaType.Document:
            return (
                <Document
                    {...props}
                    {...routeProps}
                    documentInstanceId={parseInt(routeProps.match.params.docInstanceId)}
                    folderId={parseInt(routeProps.match.params.folderId)}
                    jobId={parseInt(routeProps.match.params.jobId)}
                    mediaType={parseInt(routeProps.match.params.mediaType)}
                    templateDocumentInstanceId={parseInt(
                        routeProps.match.params.templateDocumentInstanceId
                    )}
                    templateType={parseInt(routeProps.match.params.templateType)}
                />
            );
        case MediaType.Photo:
            return (
                <Photo
                    {...props}
                    {...routeProps}
                    documentInstanceId={parseInt(routeProps.match.params.docInstanceId)}
                    jobId={parseInt(routeProps.match.params.jobId)}
                />
            );
        case MediaType.Video:
            return (
                <Video
                    {...props}
                    {...routeProps}
                    documentInstanceId={parseInt(routeProps.match.params.docInstanceId)}
                    jobId={parseInt(routeProps.match.params.jobId)}
                />
            );
        default:
            return undefined;
    }
};
