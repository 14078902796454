import moment from "moment";

import { BTLoginTypes } from "types/enum";

export class ScheduleConflictResponse {
    constructor(data: any) {
        this.count = data.count;
        this.conflicts = data.conflicts.map((item: any) => new UserWithConflicts(item));
    }
    count: number;
    conflicts: UserWithConflicts[];
}

export class UserWithConflicts {
    constructor(data: any) {
        this.name = data.name;
        this.itemCount = data.itemCount;
        this.loginType = data.loginType;
        this.globalUserId = data.globalUserId;
        this.conflicts = data.conflicts.map((item: any) => new ScheduleConflict(item));
    }
    name: string;
    itemCount: number;
    loginType: BTLoginTypes;
    globalUserId: number;
    conflicts: ScheduleConflict[];
}

export class ScheduleConflict {
    constructor(data: any) {
        this.title = data.title;
        this.jobName = data.jobName;
        this.startDate = moment(data.startDate);
        this.endDate = moment(data.endDate);
        this.scheduleId = data.scheduleId;
        this.jobsiteID = data.jobsiteID;
        this.relatedConflicts = data.relatedConflicts;
        this.color = data.color;
    }
    title: string;
    jobName: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    scheduleId: number;
    jobsiteID: number;
    relatedConflicts: number;
    color: string;
}

export enum ScheduleConflictViews {
    All,
    User,
    Job,
    Schedule,
}

export type ConflictTrackingFormActions = "update" | undefined;

export interface IConflictTrackingFormValues {
    conflictAlertThreshold: number;
    shouldAlertConflicts: boolean;
}

export class SetConflictThresholdResponse {
    constructor(data: any) {
        this.globalUserId = data.globalUserId;
        this.conflictAlertThreshold = data.conflictAlertThreshold;
    }
    globalUserId: number;
    conflictAlertThreshold: number;
}

export interface IViewSwitcherInfo {
    id: number;
    name: string;
}
