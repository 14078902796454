import moment from "moment";

import { getGlobalValue } from "utilities/globalValueUtils";
import { round } from "utilities/math/math";

import { LocationType } from "entity/timeClock/Shift/Shift.api.types";

// Takes a number or string as input. If it's a single-digit character, it returns the input character with a padded zero as output.
// Useful as the formatter prop on a BTInputNumber.
export function formatZeroPadding(value: number | string | undefined) {
    if (value !== undefined) {
        return value.toString().replace(/\b\d\b/g, "0" + value);
    } else {
        return "";
    }
}

// Remove the extra pad from a 2-digit or more number.
// For intance, if the user types "30", we don't want them to see the extra 0-padding (030) that would result from the first keystroke.
// Useful as the parse prop on a BTInputNumber.
export function removeExtraZeroPadding(value: number | string | undefined) {
    if (value !== undefined) {
        return value.toString().replace(/\d(?=[\d]{2})/g, "");
    } else {
        return "";
    }
}

// Convert minutes to hours (chops off the minutes)
export function convertMinutesToHours(value: number) {
    return (value - (value % 60)) / 60;
}

// Prevent the user from typing alpha characters in a number picker
export function removeNonNumericCharacters(value: string | undefined) {
    if (value !== undefined) {
        return value.toString().replace(/[^0-9]/g, "");
    } else {
        return "";
    }
}

// Prevent the user from seeing 60+ minutes appear in a minutes field
export function hide60Minutes(value: string | undefined) {
    if (value !== undefined) {
        return Number(value) > 59 ? 59 : value;
    } else {
        return "";
    }
}

/**
 * Will display time in hours & minute format. If the time clock setting decimal setting is on, it will also display in decimal format.
 * Example: 2 hours 1 minute (2.01 hours)
 */
export function displayTime(totalMinutes: number, displayHoursInDecimal: boolean) {
    return `${displayFormattedTime(totalMinutes)} ${
        displayHoursInDecimal ? displayTimeInDecimal(totalMinutes) : ""
    }`;
}

export function displayTimeInDecimal(minutes: number, format: boolean = true) {
    const hours = round(minutes / 60, 2);
    const amount = hours
        .toString()
        .replace(".", getGlobalValue("getBuilderNumberDecimalSeparator"));
    if (format) {
        return ` (${amount} ${minutes === 60 ? "hour" : "hours"})`;
    } else {
        return amount.toString();
    }
}

export function displayFormattedTime(totalMinutes: number) {
    const hours = convertMinutesToHours(totalMinutes);
    const minutes = totalMinutes % 60;
    const hourSuffix = hours === 1 ? "hour" : "hours";
    const minuteSuffix = minutes === 1 ? "minute" : "minutes";
    return `${hours} ${hourSuffix} ${minutes} ${minuteSuffix}`;
}

export function GenerateMarkIdForShiftLocation(locationType: LocationType, id: number) {
    const idSuffix = locationType === LocationType.clockIn ? "_ClockIn" : "_ClockOut";
    return id.toString() + idSuffix;
}

export function getLocalNow() {
    return moment(
        new Date().toLocaleString(["en-US"], {
            timeZone: getGlobalValue("builderTimeZoneName"),
        })
    );
}
