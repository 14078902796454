import { PureComponent } from "react";

import { formattedPlural } from "utilities/string/string";

export interface IBTFormattedPluralProps {
    value: number;
    zero?: string | JSX.Element;
    one?: string | JSX.Element;
    other: string | JSX.Element;
}

/**
 * @see formattedPlural for the function version
 * @example
 * <BTFormattedPlural value={array.length} one="User" other="Users" />
 * <BTFormattedPlural value={array.length} zero="No users exist" one="User" other="Users" />
 */
export class BTFormattedPlural extends PureComponent<IBTFormattedPluralProps> {
    render() {
        return formattedPlural(this.props);
    }
}
