import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IJobPriceSummaryProps } from "./JobPriceSummary";

const JobPriceSummary = lazyLoadWithRetry(() => import("./JobPriceSummary"));

export const RouteJobPriceSummary: React.FunctionComponent<
    Omit<IJobPriceSummaryProps, "jobId" | "history" | "location" | "match">
> = (props) => {
    return (
        <RouteRelative
            path={routes.job.jobPriceSummary}
            render={(routeProps) => (
                <BTSuspense>
                    <JobPriceSummary
                        {...props}
                        {...routeProps}
                        jobId={parseInt(routeProps.match.params.jobId)}
                    />
                </BTSuspense>
            )}
        />
    );
};
