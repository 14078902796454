import { Component, useCallback } from "react";

import { useTracking } from "utilities/analytics/analytics";
import { isSpecialJob } from "utilities/jobPicker/jobPicker";
import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconArrowDownOutlined,
    BTIconEnvelopeSimple,
    BTIconHouse,
    BTIconInfo,
} from "commonComponents/btWrappers/BTIcon";
import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { ColorSwatch } from "commonComponents/entity/colorPicker/ColorSwatch/ColorSwatch";
import { CurrencyDisplay } from "commonComponents/financial/CurrencyDisplay/CurrencyDisplay";
import {
    IJobPickerJobProps,
    IJobPickerProps,
} from "commonComponents/utilities/JobPicker/JobPicker";
import { JobPickerJob } from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobIdTypes,
    JobPickerDisplayModes,
    JobPickerSelectModes,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import {
    getStatusTextForJob,
    getStatusTypeForJob,
} from "commonComponents/utilities/JobPicker/JobPicker.utils";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import { JobRunningTotal } from "entity/job/Job.api.types";

class TimeClockGeneralJob extends Component {
    render() {
        return (
            <div className="CurrentJobInformation" data-testid="TimeClockGeneralJobInformation">
                <div className="JobInformationContainer">
                    <div className="JobName margin-bottom-xs">General</div>
                    <span style={{ whiteSpace: "normal" }}>
                        Clock in/out without assigning a time to a specific job.
                    </span>
                </div>
            </div>
        );
    }
}

const SelectedJobInfo: React.FC<IJobPickerJobProps> = (props) => {
    const showMessageIcon = useCallback(
        (jobId: number) => {
            return !isSpecialJob(jobId) && props.permissions.canSendMessage && !props.templatesOnly;
        },
        [props.permissions.canSendMessage, props.templatesOnly]
    );

    const { currentJob, ownerInfo, isTemplateMode } = props;
    const isAnyOwnerActive = ownerInfo.owners.some((x) => x.isActive);

    let jobOrTemplateRoute = "/JobPickerActions" + routes.job.getDetailsLink(currentJob.jobId);

    if (props.templatesOnly) {
        jobOrTemplateRoute =
            "/JobPickerActions" + routes.template.getTemplateDetailsLink(currentJob.jobId);
    }

    return (
        <div className="CurrentJobInformation" data-testid="CurrentJobInformation">
            <ShowOnPortal
                builder
                subs={props.displayMode === JobPickerDisplayModes.Calendar}
                render={() => (
                    <div className="ColorBar">
                        <ColorSwatch
                            className="JobColor"
                            size={14}
                            color={currentJob.jobInfo.color}
                        />
                    </div>
                )}
            />
            <div className="CurrentJobCard">
                <div className="flex">
                    <JobInfoContainer
                        currentJob={currentJob}
                        isTemplateMode={isTemplateMode}
                        jobRunningTotal={props.jobRunningTotal}
                    />
                    <ShowOnPortal
                        builder
                        subs
                        render={() => (
                            <div className="JobActions">
                                {props.permissions.canViewJobDetails &&
                                !isSpecialJob(currentJob.jobId) ? (
                                    <BTPopover
                                        content={`View ${
                                            props.templatesOnly ? "Template" : "Job"
                                        } Details`}
                                        placement="right"
                                    >
                                        <BTLinkRelative to={jobOrTemplateRoute}>
                                            <BTButton
                                                type="link"
                                                data-testid="JobInfoIcon"
                                                icon={
                                                    <BTIconInfo className="JobActionIcon JobActionLink" />
                                                }
                                            />
                                        </BTLinkRelative>
                                    </BTPopover>
                                ) : (
                                    <span />
                                )}
                                {showMessageIcon(currentJob.jobId) ? (
                                    <BTPopover
                                        content={
                                            isAnyOwnerActive
                                                ? "Send Message to Owner"
                                                : "No active contacts on this job. Only active contacts on this job can be messaged."
                                        }
                                        placement="right"
                                    >
                                        {isAnyOwnerActive ? (
                                            <BTLinkRelative
                                                to={
                                                    "/JobPickerActions" +
                                                    routes.messages.getComposeLink(
                                                        0,
                                                        currentJob.jobId,
                                                        {
                                                            defaultRecipientId: currentJob.jobId,
                                                        }
                                                    )
                                                }
                                            >
                                                <BTButton
                                                    type="link"
                                                    data-testid="SendMessageIcon"
                                                    icon={
                                                        <BTIconEnvelopeSimple className="JobActionIcon JobActionLink" />
                                                    }
                                                />
                                            </BTLinkRelative>
                                        ) : (
                                            <BTIconEnvelopeSimple className="JobActionIcon DisabledJobActionIcon" />
                                        )}
                                    </BTPopover>
                                ) : (
                                    <span />
                                )}
                                {props.permissions.canViewOwnerSite &&
                                !isSpecialJob(currentJob.jobId) &&
                                !props.templatesOnly ? (
                                    <BTPopover
                                        content={
                                            currentJob.canImpersonateOwner
                                                ? "View Owner Site"
                                                : "Add a homeowner contact to the job to view owner site"
                                        }
                                        placement="right"
                                    >
                                        {currentJob.canImpersonateOwner && (
                                            <>
                                                {/* we need the opener on the owner portal because the main nav has a "close" option that attempts to close the window/tab */}
                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <a
                                                    target="_blank"
                                                    rel="opener"
                                                    className="JobActionLink"
                                                    href={`/app${routes.getOwnerPortalRedirectLink(
                                                        currentJob.jobId
                                                    )}`}
                                                >
                                                    <BTButton
                                                        type="link"
                                                        data-testid="OwnerPortalIcon"
                                                        icon={
                                                            <BTIconHouse className="JobActionIcon" />
                                                        }
                                                    />
                                                </a>
                                            </>
                                        )}
                                        {!currentJob.canImpersonateOwner && (
                                            <BTIconHouse className="JobActionIcon DisabledJobActionIcon" />
                                        )}
                                    </BTPopover>
                                ) : (
                                    <span />
                                )}
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

interface IJobInfoContainerProps {
    currentJob: JobPickerJob;
    isTemplateMode: boolean;
    jobRunningTotal?: JobRunningTotal;
}

export const JobInfoContainer: React.FC<IJobInfoContainerProps> = (props) => {
    const { currentJob, isTemplateMode } = props;
    const total = props.jobRunningTotal;
    const isUK = currentJob.jobInfo.globalCountry && currentJob.jobInfo.globalCountry === "GB";

    return (
        <div className="JobInformationContainer" data-testid="JobInformationContainer">
            <div className="JobName">
                <TextTruncate>{currentJob.jobName}</TextTruncate>
            </div>
            {!isTemplateMode && currentJob.jobId > 0 && (
                <div className="margin-bottom-xs">
                    <StatusTagDisplay
                        statusType={getStatusTypeForJob(currentJob.jobInfo.status)}
                        statusText={getStatusTextForJob(currentJob.jobInfo.status)}
                    />
                </div>
            )}
            {currentJob.jobInfo.ownerName !== null && currentJob.jobInfo.ownerName !== "" && (
                <div className="OwnerName">{currentJob.jobInfo.ownerName}</div>
            )}
            {currentJob.jobInfo.street !== null && currentJob.jobInfo.street !== "" && (
                <div className="Address">{currentJob.jobInfo.street}</div>
            )}
            {isUK && (
                <>
                    {currentJob.jobInfo.city && (
                        <div className="Address">{currentJob.jobInfo.city}</div>
                    )}
                    {currentJob.jobInfo.suburb && (
                        <div className="Address">{currentJob.jobInfo.suburb}</div>
                    )}
                    {currentJob.jobInfo.zip && (
                        <div className="Address">{currentJob.jobInfo.zip}</div>
                    )}
                </>
            )}
            {!isUK && (
                <>
                    {currentJob.jobInfo.addressWithoutStreet !== null &&
                        currentJob.jobInfo.addressWithoutStreet !== "" && (
                            <div className="Address">{currentJob.jobInfo.addressWithoutStreet}</div>
                        )}
                </>
            )}
            {total &&
                Boolean(total.jobRunningTotal) &&
                total.hasJobsitePermission &&
                total.hasRunningTotalPermission && (
                    <div className="JobRunningTotal">
                        <strong>
                            {"Job running total: "}
                            <CurrencyDisplay value={total.jobRunningTotal!} />
                        </strong>
                    </div>
                )}
        </div>
    );
};

class PleaseSelectJobHeader extends Component<IJobPickerProps> {
    render() {
        return (
            <div className="CurrentJobInformation" data-testid="PleaseSelectJob">
                <div className="JobInformationContainer PickAJob text-center margin-all-zero">
                    <BTRow className="JobInformationRow" align="middle">
                        <BTCol span={24} className="AllJobs">
                            <BTIconArrowDownOutlined className="margin-right-xs" />
                            Pick a {this.props.templatesOnly ? "Template" : "Job"}
                            <BTIconArrowDownOutlined className="margin-left-xs" />
                        </BTCol>
                    </BTRow>
                </div>
            </div>
        );
    }
}

export function JobPickerJobInfoSection(props: IJobPickerProps) {
    const { selectMode, selectedJobIds, jobs } = props;
    const currentJob = jobs.find((x) => x.jobId === selectedJobIds);
    const { Track } = useTracking({
        jobId: currentJob?.jobId,
        page: "JobPicker",
    });
    if (typeof selectedJobIds === "number") {
        if (selectedJobIds === JobIdTypes.GlobalJob) {
            return <TimeClockGeneralJob />;
        }
        if (currentJob && currentJob.jobId !== JobIdTypes.AllJobs) {
            return (
                <Track>
                    <SelectedJobInfo {...props} currentJob={currentJob} />
                </Track>
            );
        }
    }
    if (
        selectMode === JobPickerSelectModes.Single ||
        selectedJobIds === null ||
        selectedJobIds === JobIdTypes.NoJobs
    ) {
        return <PleaseSelectJobHeader {...props} />;
    }
    return null;
}
