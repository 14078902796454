import { APIHandler } from "utilities/apiHandler";
import {
    UpdateOnboardingContactInfoRequest,
    UpdateOnboardingContactInfoResponse,
} from "utilities/onboarding/onboarding.api.types";

export interface IBuilderHandler {
    UpdateOnboardingContactInformation(
        requestData: UpdateOnboardingContactInfoResponse
    ): Promise<UpdateOnboardingContactInfoResponse>;
}

export class OnboardingHandler implements IBuilderHandler {
    async UpdateOnboardingContactInformation(
        requestData: UpdateOnboardingContactInfoRequest
    ): Promise<UpdateOnboardingContactInfoResponse> {
        return await APIHandler("/api/onboarding/UpdateOnboardingContactInformation", {
            method: "POST",
            data: requestData,
            responseType: UpdateOnboardingContactInfoResponse,
        });
    }
}
