import { Typography } from "antd";
import classNames from "classnames";
import { Component } from "react";

import { track } from "utilities/analytics/analytics";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";

import "./EmptyState.less";

import { EmptyStateDataType, IEmptyStateEntityProps } from "./common/EmptyState.api.types";

export interface IEmptyStateProps {
    title?: React.ReactNode;

    /**
     * Image that is displayed centered above the title & content
     */
    image?: React.ReactNode;

    style?: React.CSSProperties;
    className?: string;
    "data-testid"?: string;
}

@track((props) => ({ component: "Empty State", uniqueId: props.title }))
export class EmptyState extends Component<IEmptyStateProps> {
    render() {
        const { title, children, image, style, className, "data-testid": dataTestId } = this.props;

        return (
            <BTRow
                justify="center"
                align="middle"
                className={classNames("EmptyState EmptyStateContainer", className)}
                style={style}
                data-testid={dataTestId}
            >
                <BTCol xs={20} sm={16} md={12}>
                    {image && (
                        <BTRow align="middle">
                            <BTCol xs={8} lg={7} xl={6}>
                                {image}
                            </BTCol>
                        </BTRow>
                    )}
                    {title && (
                        <BTRow align="middle">
                            <BTCol>
                                <BTTitle level={1}>{title}</BTTitle>
                            </BTCol>
                        </BTRow>
                    )}
                    <BTRow>
                        <BTCol xs={24} className="col-content">
                            <Typography.Text className="col-content">{children}</Typography.Text>
                        </BTCol>
                    </BTRow>
                </BTCol>
            </BTRow>
        );
    }
}

export function getEmptyStateDataType(props: IEmptyStateEntityProps) {
    if (props.hasListData) {
        return null;
    }
    if (props.hasFilteredData) {
        return EmptyStateDataType.FilteredState;
    }
    if (props.hasTimeoutState!) {
        return EmptyStateDataType.TimeoutState;
    }
    return EmptyStateDataType.EmptyState;
}
