import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconSlidersHorizontal } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import {
    GlobalSearchFilters,
    IGlobalSearchFilterValues,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { GetQuickFilterCategories } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchUtils";
import { AllJobs } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import "./GlobalSearchQuickFilters.less";

interface IGlobalSearchFiltersProps {
    filters: GlobalSearchFilters | undefined;
    selectedFilters: IGlobalSearchFilterValues;
    onFilterChange: (filters: IGlobalSearchFilterValues) => void;
    onShowAllFilters: () => void;
    showSuggestedFilters: boolean;
}
export const GlobalSearchQuickFilters: React.FunctionComponent<IGlobalSearchFiltersProps> = ({
    filters,
    onFilterChange,
    selectedFilters,
    onShowAllFilters,
    showSuggestedFilters,
}) => {
    if (!filters) {
        return null;
    }

    const categories = GetQuickFilterCategories(filters, selectedFilters, showSuggestedFilters);

    const categoryButtons = categories.map((category) => (
        <BTButton
            key={category.value}
            id={`btn_${category.title}_Filter`}
            data-testid={`${category.title}_Filter`}
            className="QuickFilterButton"
            onClick={() => {
                const newCategories = [...selectedFilters.category];
                const index = newCategories.indexOf(category.value);
                if (index > -1) {
                    newCategories.splice(index, 1);
                } else {
                    newCategories.push(category.value);
                }
                onFilterChange({
                    ...selectedFilters,
                    category: newCategories,
                });
            }}
            type={selectedFilters.category.includes(category.value) ? "primary" : "secondary"}
        >
            <TextTruncate
                alwaysShowPopover
                popoverPlacement="bottomLeft"
                popoverContent={
                    <span>
                        Filter results to include <b>{category.title}</b>
                    </span>
                }
            >
                {category.title}
            </TextTruncate>
        </BTButton>
    ));

    const appliedFilterLabel = () => {
        let count = selectedFilters.category.length;
        if (selectedFilters.jobId !== AllJobs) {
            count++;
        }
        if (count === 0) {
            return "";
        }
        return ` (${count}) `;
    };

    return (
        <BTRow className="GlobalSearchQuickFilters">
            <BTCol flex="auto" className="QuickFilterCategories" span={18}>
                {categoryButtons.map((button, index) => (
                    <div key={index} className="QuickFilterButtonContainer">
                        {button}
                    </div>
                ))}
            </BTCol>
            <BTCol span={6}>
                <BTButton
                    id="btnAllFilters"
                    data-testid="btnAllFilters"
                    className="QuickFilterButton"
                    icon={<BTIconSlidersHorizontal />}
                    onClick={onShowAllFilters}
                >
                    All Filters {appliedFilterLabel()}
                </BTButton>
            </BTCol>
        </BTRow>
    );
};
