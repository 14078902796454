import { useContext, useEffect, useState } from "react";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { MapApiContext, MapApiStatus } from "./MapApiContext";

interface ILoadMapApiProps {
    /**
     * Element to display while Map API is loading
     *
     * @default <BTLoading />
     */
    loadingElement: React.ReactNode;
    /**
     * Function called to render Maps element. Will be called after Map API is loaded
     *
     * Using a function to render children prevents attempting to render map elements before maps have been loaded
     */
    render: () => JSX.Element;

    /**
     * Element to be rendered on fail instead of the default error message
     */
    renderOnFail?: JSX.Element;

    /**
     * This component does not allow direct children. Provide a function to render children to the `render` prop.
     * This will delay rendering children until after the Map API has been loaded.
     */
    children?: never;
}

function LoadMapApi({ loadingElement, render, renderOnFail }: ILoadMapApiProps) {
    const [status, setStatus] = useState<MapApiStatus | null>(null);
    const mapApiContext = useContext(MapApiContext);

    useEffect(() => {
        const fetchStatus = async () => {
            const apiStatus = await mapApiContext.status();
            setStatus(apiStatus);
        };

        void fetchStatus();
    }, [mapApiContext]);

    const getErrorRender = (status: MapApiStatus) => {
        if (renderOnFail) {
            return renderOnFail;
        }

        let errorTitle = "Error";
        let errorDesc = "An error occurred loading this map";
        if (status === MapApiStatus.Unavailable) {
            errorTitle = "Map is Unavailable";
            errorDesc = "This map is unavailable at this time. Please try again later.";
        }
        return (
            <BTAlert
                showIcon={true}
                title={errorTitle}
                message={errorDesc}
                type="error"
                style={{ margin: 5 }}
                data-testid="mapLoadingError"
            />
        );
    };

    if (status === null || status === MapApiStatus.NotLoaded) {
        return <>{loadingElement}</>;
    }

    if (status === MapApiStatus.Loaded) {
        return <>{render()}</>;
    }

    if (status === MapApiStatus.Error || status === MapApiStatus.Unavailable) {
        return <>{getErrorRender(status)}</>;
    }

    return null;
}

LoadMapApi.defaultProps = {
    loadingElement: (
        <div style={{ position: "relative" }}>
            <BTLoading displayMode="absolute" />
        </div>
    ),
};

export default LoadMapApi;
