import { message } from "antd";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    IMultiFactorAuthModalHandler,
    MultiFactorAuthModalHandler,
} from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModal.api.handler";
import {
    AuthEmailResponse,
    IMultiFactorAuthModalFormValues,
    MultiFactorAuthModalFormActions,
} from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModal.api.types";
import { MultiFactorAuthModalPresentational } from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModalPresentational";

import { handleRedirectPage } from "entity/login/Login/Login.utils";

export interface IMultiFactorAuthModalProps extends RouteComponentProps {
    credentialId: number;
    isOAuthRequest?: boolean;
    handler?: IMultiFactorAuthModalHandler;
    modalConfig?: IModalConfiguration;
}

export interface IMultiFactorAuthModalState {
    actionBeingPerformed: MultiFactorAuthModalFormActions;
    data?: AuthEmailResponse;
}

export class MultiFactorAuthModal extends Component<
    IMultiFactorAuthModalProps,
    IMultiFactorAuthModalState
> {
    static defaultProps = {
        handler: new MultiFactorAuthModalHandler(),
    };

    state: IMultiFactorAuthModalState = {
        actionBeingPerformed: undefined,
        data: undefined,
    };

    async componentDidMount() {
        try {
            await this.props.handler!.SendAuthenticationEmail(this.props.credentialId);
            const data = await this.props.handler!.GetAuthInfo(this.props.credentialId);
            this.setState({ data });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    }

    private handleSubmit = async (values: IMultiFactorAuthModalFormValues) => {
        await setLoadingAction<MultiFactorAuthModalFormActions>(this, {
            actionBeingPerformed: "submit",
            callback: async () => {
                try {
                    const response = await this.props.handler!.VerifyAuthCode(
                        this.props.credentialId,
                        values.oneTimeCode
                    );

                    handleRedirectPage({
                        loginResponse: response,
                        isOAuthRequest: this.props.isOAuthRequest,
                    });
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private handleResendEmail = async () => {
        await setLoadingAction<MultiFactorAuthModalFormActions>(this, {
            actionBeingPerformed: "resendEmail",
            callback: async () => {
                try {
                    await this.props.handler!.SendAuthenticationEmail(this.props.credentialId);
                    void message.success("New email sent");
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private handleSwitchUser = () => {
        const { modalConfig } = this.props;

        if (modalConfig) {
            modalConfig.beforeClose();
        } else {
            // eslint-disable-next-line deprecate/member-expression
            routesWebforms.closeModalAndReload();
        }
    };

    render() {
        if (!this.state || !this.state.data) {
            return <BTLoading />;
        }

        const component = (
            <MultiFactorAuthModalPresentational
                {...this.props}
                data={this.state.data}
                actionBeingPerformed={this.state.actionBeingPerformed}
                onSubmit={this.handleSubmit}
                onResendEmail={this.handleResendEmail}
                onSwitchUser={this.handleSwitchUser}
            />
        );

        if (this.props.modalConfig) {
            return (
                <BTModal
                    data-testid="btModalMultiFactorAuthModal"
                    centered
                    width="600px"
                    beforeClose={this.props.modalConfig.beforeClose}
                    alwaysOnTop
                    visible
                    removeBodyPadding
                >
                    {component}
                </BTModal>
            );
        }

        return component;
    }
}
