import { IHotkey } from "commonComponents/utilities/Hotkey/hotkey.types";

export interface IRootFocusListener {
    onFocusLayerChange?: (currentFocusLayer: number) => void;
}

export class RootFocusObserver {
    private focusLayer = 0;
    private listeners: IRootFocusListener[] = [];
    private globalHotkeysToDisplay: IHotkey[] = [];

    collectHotkeyToDisplay = (hotkey: IHotkey) => {
        this.globalHotkeysToDisplay.push(hotkey);
    };

    clearHotkeyToDisplay = () => {
        this.globalHotkeysToDisplay = [];
    };

    get currentFocusLayer() {
        return this.focusLayer;
    }

    addFocusLayer = () => {
        this.focusLayer++;
        this.listeners.forEach((listener) => {
            try {
                listener.onFocusLayerChange?.(this.focusLayer);
            } catch (e) {
                console.error(e);
            }
        });
        return this.focusLayer;
    };

    subtractFocusLayer = () => {
        this.focusLayer--;
        this.listeners.forEach((listener) => {
            try {
                listener.onFocusLayerChange?.(this.focusLayer);
            } catch (e) {
                console.error(e);
            }
        });
        return this.focusLayer;
    };

    registerListener = (ref: IRootFocusListener) => {
        this.listeners.push(ref);
    };

    unregisterListener = (ref: IRootFocusListener) => {
        this.listeners = this.listeners.filter((listener) => listener !== ref);
    };
}
