import { ReactNode } from "react";

import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import "./BTListLayoutHeader.less";

export type StickyHeaderType =
    | "Title"
    | "Tabs"
    | "Actions"
    | "TabsActions"
    | "SignUp"
    | "BdsLayout"
    | undefined;

export const stickyHeaderHeightTitle = 72;
export const stickyHeaderHeightTabs = 56;
export const stickyHeaderHeightActions = 48;
export const stickyBreadcrumbHeight = 20;

export const stickyHeaderLevelTitle = 10;
export const stickyHeaderLevelTabs = 20;
export const stickyBreadcrumb = 25;
export const stickyHeaderLevelActions = 30;
export const stickyListLevelActions = 40;

export const stickyHeaderTabsBelowActions = 20;

interface IBTListLayoutHeaderProps {
    jobName?: string;
    headerTitle: string | ReactNode;
    listActions?: ReactNode;
    optionalHeader?: React.ReactNode;
}

export const BTListLayoutHeader: React.FunctionComponent<IBTListLayoutHeaderProps> = (props) => {
    const top = useStickyHeight(stickyHeaderHeightTitle, "top", stickyHeaderLevelTitle);

    let content: React.ReactNode;
    if (props.optionalHeader) {
        content = (
            <>
                <div className="OptionalHeaderJobname">{props?.jobName}</div>
                {props.optionalHeader}
            </>
        );
    } else {
        content = (
            <>
                <div className="ListViewJobname">{props?.jobName}</div>
                <BTTitle level={1} bold className="ListViewTitle">
                    {props.headerTitle}
                </BTTitle>
            </>
        );
    }

    return (
        <div
            className="TitleHeader ListViewHeaderRow StickyLayoutHeader"
            style={{ height: stickyHeaderHeightTitle, top }}
        >
            <div className="ListViewJobAndTitle">{content}</div>
            {props.listActions}
        </div>
    );
};
