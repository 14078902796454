import { PureComponent } from "react";
import { Route, RouteComponentProps } from "react-router-dom";

import { track } from "utilities/analytics/analytics";

interface IDetailPageLayoutProps {
    component: React.ComponentType<any>;
    path: string;
    exact?: boolean;
}

export class DetailPageLayout extends PureComponent<IDetailPageLayoutProps> {
    private origNavHeight: string;
    componentDidMount() {
        this.origNavHeight = document.documentElement.style.getPropertyValue(
            "--main-navigation-height"
        );
        document.documentElement.style.setProperty("--main-navigation-height", "0px");
    }

    componentWillUnmount() {
        document.documentElement.style.setProperty("--main-navigation-height", this.origNavHeight);
    }

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <>
                <Route
                    {...rest}
                    render={(matchProps) => {
                        // Define jobId in tracking scope if defined in the route
                        if (matchProps.match.params.jobId !== undefined) {
                            return (
                                <TrackedComponent
                                    jobId={parseInt(matchProps.match.params.jobId)}
                                    component={Component}
                                    {...matchProps}
                                />
                            );
                        }
                        return <Component {...matchProps} />;
                    }}
                />
            </>
        );
    }
}

interface ITrackedComponentProps extends RouteComponentProps {
    jobId: number;
    component: React.ComponentType<any>;
}

const TrackedComponent: React.FunctionComponent<ITrackedComponentProps> = track(
    (props: ITrackedComponentProps) => ({
        jobId: props.jobId,
    })
)((props) => {
    const { component: Component, jobId, ...otherProps } = props;
    return <Component {...otherProps} />;
});
