import { BTLoginTypes } from "types/enum";

export interface ICredentialsFormValues {
    username: string | undefined;
    password: string | undefined;
}

export interface IUserActivationRequest {
    username: string;
    password: string;
    builderID?: string;
    shareToken: string;
}

export class ActivationResponse {
    constructor(data: any) {
        this.globalUserId = data.globalUserId;
        this.loginType = data.loginType;
        this.cellNumber = data.cellNumber;
        this.isMfaEnabled = data.isMfaEnabled;
        this.usernameAlreadyExists = data.usernameAlreadyExists;
        this.invalidCredentials = data.invalidCredentials;
        this.previousActivation = data.previousActivation
            ? new PreviousActivation(data.previousActivation)
            : undefined;
        this.credentialId = data.credentialId;
    }

    globalUserId: number;
    loginType: BTLoginTypes;
    cellNumber: string;
    isMfaEnabled: boolean;
    usernameAlreadyExists: boolean;
    invalidCredentials: boolean;
    previousActivation: PreviousActivation | undefined;
    credentialId: number;
}

export class PreviousActivation {
    constructor(data: any) {
        this.globalUserId = data.globalUserId;
        this.isActive = data.isActive;
        this.loginType = data.loginType;
        this.nameOfAccount = data.nameOfAccount;
    }

    globalUserId: number;
    isActive: boolean;
    loginType: BTLoginTypes;
    nameOfAccount: string;
}
