import { Component } from "react";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ITemplateProps } from "entity/template/Template/Template";

const Template = lazyLoadWithRetry(() => import("entity/template/Template/Template"));

export class RouteTemplate extends Component<
    Omit<ITemplateProps, "id" | "history" | "location" | "match">
> {
    render() {
        return (
            <RouteRelative
                path={routes.template.templateDetails}
                render={(routerProps) => {
                    const urlParams = routerProps.match.params;
                    const jobId = parseInt(urlParams.jobId);
                    return (
                        <BTSuspense>
                            <Template id={jobId} {...routerProps} {...this.props} />
                        </BTSuspense>
                    );
                }}
            />
        );
    }
}
