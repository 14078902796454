import { APIHandlerVersion, IAPIHandlerResult } from "types/apiResponse/apiResponse";

import { APIHandler } from "utilities/apiHandler";
import { PortalType } from "utilities/portal/portal";

import {
    IApiJobBuilderRequest,
    IApiJobFilterRequest,
    JobPickerLoadResponse,
    JobPickerMetadataLoadResponse,
    JobPickerSetRequest,
    JobPickerSetResponse,
    LinkedLogin,
    PutOwnerInSessionResponse,
    SetSortResponse,
    SwitchAccountResponse,
    SwitchLinkedLoginResponse,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobPickerDisplayModes,
    JobSortOptions,
} from "commonComponents/utilities/JobPicker/JobPicker.types";

export interface IJobPickerHandler {
    get: (
        builderId: string,
        selectedJobId: number,
        allowGlobalJob: boolean,
        selectMode: number,
        displayMode: JobPickerDisplayModes,
        filterString: string,
        templatesOnly: boolean,
        useJobInSession?: boolean
    ) => Promise<JobPickerLoadResponse>;

    getMetadata: (
        jobIds: number[],
        diaplayMode: JobPickerDisplayModes
    ) => Promise<JobPickerMetadataLoadResponse>;

    set: (
        selectedJobId: IApiJobBuilderRequest,
        selectedJobIds: IApiJobBuilderRequest[],
        persistFilters: boolean,
        filtersData: IApiJobFilterRequest,
        useJobSession?: boolean
    ) => IAPIHandlerResult<JobPickerSetResponse>;
    putOwnerInSession: (jobId: number) => Promise<PutOwnerInSessionResponse>;
    setSort: (sortOption: JobSortOptions) => Promise<SetSortResponse>;
    switchLinkedLogin: (
        newLogin: LinkedLogin,
        currentUserId: string
    ) => Promise<SwitchLinkedLoginResponse>;
    switchOwner: (newJobId: number) => Promise<SwitchLinkedLoginResponse>;
    switchAccount: (newUserId: number) => Promise<SwitchAccountResponse>;
}

export class JobPickerHandler implements IJobPickerHandler {
    get = async (
        builderId: string,
        selectedJobId: number,
        allowGlobalJob: boolean,
        selectMode: number,
        displayMode: JobPickerDisplayModes,
        filterString: string,
        templatesOnly: boolean,
        useJobInSession: boolean = true
    ) => {
        const response = await APIHandler("/api/jobpicker/GetJobPickerData", {
            method: "POST",
            data: {
                filters: filterString,
                displayMode: displayMode,
                jobSortChoice: 1,
                selectedJobId: selectedJobId,
                isExpanded: true,
                templatesOnly: templatesOnly,
                selectMode: selectMode,
                useJobInSession: useJobInSession,
                allowGlobalJob: allowGlobalJob,
                builderId: builderId,
                includeCounts: false,
                loadTop: undefined,
            },
            responseType: JobPickerLoadResponse,
        });
        return response;
    };

    getMetadata = async (jobIds: number[], displayMode: JobPickerDisplayModes) => {
        const response = await APIHandler("/api/jobpicker/GetJobpickerMetadata", {
            method: "POST",
            data: {
                jobIds,
                displayMode,
            },
            responseType: JobPickerMetadataLoadResponse,
        });
        return response;
    };

    set(
        selectedJobId: IApiJobBuilderRequest,
        selectedJobIds: IApiJobBuilderRequest[],
        persistFilters: boolean,
        filtersData: IApiJobFilterRequest,
        useJobSession: boolean = true
    ) {
        const request = new JobPickerSetRequest({
            selectedJobId: selectedJobId,
            selectedJobIds: selectedJobIds,
            persistFilters: persistFilters,
            filtersData: filtersData,
            useJobSession: useJobSession,
        });

        return APIHandler("/api/jobpicker/SetJobPickerData", {
            method: "POST",
            data: request,
            responseType: JobPickerSetResponse,
            version: APIHandlerVersion.cancellable,
        });
    }

    putOwnerInSession = async (jobId: number) => {
        return await APIHandler(`/api/jobpicker/setownersession?jobId=${jobId}`, {
            method: "PUT",
            data: {},
            responseType: PutOwnerInSessionResponse,
        });
    };

    setSort = async (sortOption: JobSortOptions) => {
        return await APIHandler(`/api/jobpicker/setsort?sortInt=${sortOption}`, {
            method: "PUT",
            responseType: SetSortResponse,
        });
    };

    switchLinkedLogin = async (newLogin: LinkedLogin, currentUserId: string) => {
        const data = {
            loginId: newLogin.loginId,
            linkedUserID: currentUserId,
        };
        return await APIHandler("/api/login/SwitchLinkedLogin", {
            method: "POST",
            data,
            responseType: SwitchLinkedLoginResponse,
        });
    };

    switchOwner = async (newJobId: number) => {
        return await APIHandler("/api/login/SwitchLinkedLogin", {
            method: "POST",
            data: { loginId: newJobId },
            responseType: SwitchLinkedLoginResponse,
        });
    };

    switchAccount = async (newUserId: number, portalType?: PortalType) => {
        return await APIHandler("/api/login/SwitchAccount", {
            method: "POST",
            data: { userId: newUserId },
            responseType: SwitchAccountResponse,
            headers: portalType !== undefined ? { Portaltype: String(portalType) } : undefined,
        });
    };
}

export const defaultJobPickerHandler = new JobPickerHandler();
