import { useStickyTotalSize } from "./StickyContext";
import { IStickyLayer, StickyDirection } from "./StickyContext.type";
import { StickyProvider } from "./StickyProvider";

interface IStickyCurrentLevelProviderProps {
    direction: StickyDirection;
    level: number | undefined;
}
export const StickyCurrentLevelProvider: React.FunctionComponent<
    IStickyCurrentLevelProviderProps
> = ({ children, direction, level = 0 }) => {
    const size = useStickyTotalSize(direction, level);
    const currentLayer: IStickyLayer = { size, level: 0 };

    return <StickyProvider initialTopLayer={currentLayer}>{children}</StickyProvider>;
};
