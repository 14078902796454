import { createContext, useContext } from "react";

import { AppDefaultInfo } from "helpers/AppProvider.types";

/**
 * Context containing global app default values. Will be undefined if the context consumer is not
 * within a parent
 */
export const AppDefaultInfoContext = createContext<AppDefaultInfo | undefined>(undefined);

export interface IWhenAppDefaultFlagProps {
    /**
     * The app default flag key to render the "then" prop if true, or "otherwise" if false. You can pass
     * multiple flag conditions which are AND'D together.
     * @example
     * // Single condition
     * <WhenAppDefaultFlag
     *     isEnabled="myFlag"
     *     then={<>I display when myFlag is true</>}
     *  />
     * // Multiple conditions
     * <WhenAppDefaultFlag
     *     isEnabled={{ flag1: true, flag2: false }}
     *     then={<>I display when flag1 is true and flag2 is false</>}
     * />
     */
    isEnabled: keyof AppDefaultInfo | Partial<{ [key in keyof AppDefaultInfo]: boolean }>;
    /**
     * The react element or render function which will be outputed when the flag condition matches.
     * Will not render anything if not provided.
     */
    then?: React.ReactNode | ((flags: AppDefaultInfo) => React.ReactNode);
    /**
     * The react element or render function which will be outputed when the flag condition **DOES
     * NOT** match. Will not render anything if not provided.
     */
    otherwise?: React.ReactNode | ((flags: AppDefaultInfo) => React.ReactNode);
}

/**
 * Utility that renders components depending on the flag value
 * @example
 * <WhenBuilderFlag
 *   isEnabled="myFlag"
 *   then={<>My flag is enabled</>}
 *   otherwise={<>My flag is disabled</>}
 * />
 */
export const WhenAppDefaultFlag: React.FC<IWhenAppDefaultFlagProps> = ({
    isEnabled: flags,
    then,
    otherwise,
}) => {
    const appDefaultContext = useContext(AppDefaultInfoContext);

    if (!appDefaultContext) {
        throw new Error(
            "Attempted to enter app default check but there was no appDefaultContext available."
        );
    }

    let areAllBuilderFlagsInCorrectState: any;
    if (typeof flags === "object") {
        areAllBuilderFlagsInCorrectState = Array.from(Object.entries(flags)).every(
            ([flagKey, desiredFlagValue]) => appDefaultContext[flagKey] === desiredFlagValue
        );
    } else {
        areAllBuilderFlagsInCorrectState = appDefaultContext[flags];
    }

    if (areAllBuilderFlagsInCorrectState) {
        if (then) {
            if (typeof then === "function") {
                return then(appDefaultContext);
            } else {
                return then;
            }
        }
    } else {
        if (otherwise) {
            if (typeof otherwise === "function") {
                return otherwise(appDefaultContext);
            } else {
                return otherwise;
            }
        }
    }

    return null;
};
