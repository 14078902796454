import { LineItemType, ProposalType } from "legacyComponents/LineItemContainer.types";

export class BulkMarkupLineItem {
    constructor(id: number, lineItemType: LineItemType) {
        this.id = id;
        this.lineItemType = lineItemType;
    }
    id: number;
    lineItemType: LineItemType;
}

export interface IBulkLineItemMarkupSaveRequest {
    parentId: number;
    proposalId: number;
    proposalType: ProposalType;
    percentMarkup: number;
    lineItems: BulkMarkupLineItem[];
}

export class BulkLineItemMarkupSaveResponse {
    constructor(data: any) {
        this.lineItems = data;
    }
    lineItems: BulkMarkupLineItem[];
}

export type AdjustPercentMarkupActions = "applyMarkup" | "cancelApplyMarkup" | undefined;

export enum AdjustPercentTypes {
    Markup = 0,
    Margin = 1,
}
