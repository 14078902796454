import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IInternalUserRoleDetailsProps } from "entity/internalUser/InternalUserRoleDetails/InternalUserRoleDetails";

const InternalUserRoleDetails = lazyLoadWithRetry(
    () => import("entity/internalUser/InternalUserRoleDetails/InternalUserRoleDetails")
);

export const RouteInternalUserRoleDetails = (
    props: Omit<
        IInternalUserRoleDetailsProps,
        | "roleId"
        | "fromInternalUserId"
        | "showCustomFields"
        | "currentTab"
        | "history"
        | "location"
        | "match"
    >
) => (
    <RouteRelative
        path={routes.internalUsers.internalUserRoleDetails}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const roleId = parseInt(routeValues.roleId);
            const copyFromRoleId = parseInt(routeValues.copyFromId);
            const fromInternalUserId = parseInt(routeValues.fromInternalUserId);
            const showCustomFields = routeValues.showCustomFields === "true";
            const currentTab = routeValues.currentTab;

            return (
                <BTSuspense>
                    <InternalUserRoleDetails
                        {...props}
                        {...routeProps}
                        roleId={roleId}
                        fromInternalUserId={fromInternalUserId}
                        showCustomFields={showCustomFields}
                        currentTab={currentTab}
                        copyFromId={copyFromRoleId}
                    />
                </BTSuspense>
            );
        }}
    />
);
