import { FacebookFilled } from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

interface IBTIconFacebookFilledProps extends IBTIconProps {
    isOnDark?: boolean;
}

export const BTIconFacebookFilled: FunctionComponent<IBTIconFacebookFilledProps> = (baseProps) => {
    const { isOnDark, ...iconProps } = baseProps;
    const [props] = useAntIcon(iconProps);

    return (
        <FacebookFilled
            {...props}
            className={classNames("BTIconFacebookFilled", props.className, { isOnDark })}
        />
    );
};
