import classNames from "classnames";
import { memo, useContext } from "react";

import {
    NumberFormatValues,
    default as ReactNumberFormat,
} from "@buildertrend/react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { round } from "utilities/math/math";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import {
    IInputDebouncingContext,
    useDebouncedOnChange,
} from "commonComponents/utilities/Input/InputDebouncingContext";
import { ReadOnlyShouldTruncate } from "commonComponents/utilities/TextTruncate/TextTruncate.type";
import {
    isReadOnly,
    shouldTruncate,
} from "commonComponents/utilities/TextTruncate/TextTruncate.utility";

import "./Percentage.less";

import { CustomInput } from "../Currency/Currency";
import { PercentageDisplay } from "../PercentageDisplay/PercentageDisplay";

interface IPercentageProps<FormValues> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;

    /** pass formik setFieldValue */
    onChange: (
        field: string,
        value: number | undefined,
        shouldValidate?: boolean,
        values?: NumberFormatValues
    ) => void;

    /** pass formik setFieldTouched */
    onBlur: (field: string, touched: boolean) => void;

    onFocus?: () => void;

    value: number | undefined | null;

    /** @default "default" */
    size?: "large" | "default" | "small";

    /** @default false */
    allowNegative?: boolean;

    /** @default 2 */
    decimalScale?: number;

    /** @default false */
    disabled?: boolean;

    /** @default true */
    defaultToZero?: boolean;

    /** @default none (inclusive)*/
    max?: number;

    /** @default none (inclusive)*/
    min?: number;

    style?: React.CSSProperties;

    /** @default false */
    readOnly?: ReadOnlyShouldTruncate;

    /** Percentage will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    /** @default true */
    showSuffix?: boolean;

    autoFocus?: boolean;

    className?: string;

    /**
     * Sets the debounce context and limits. Should only be set in edge cases.
     */
    debouncingContext?: IInputDebouncingContext;
    placeholder?: string;
}

const PercentageInternal = track((props) => ({
    element: "Percentage Input",
    uniqueId: props["data-testid"],
}))(function <FormValues = undefined>({
    id,
    style,
    disabled = false,
    onChange,
    onBlur,
    value,
    defaultToZero = true,
    max = 1_000_000_000,
    min = -1_000_000_000,
    allowNegative = false,
    decimalScale = 2,
    "data-testid": dataTestId,
    readOnly = false,
    showSuffix = true,
    autoFocus,
    className,
    debouncingContext,
    tracking,
    placeholder,
    ...otherProps
}: IPercentageProps<FormValues> & ITrackingProp) {
    const [debouncedValue, debouncedChange] = useDebouncedOnChange<typeof value, FormValues>(
        value,
        onChange,
        debouncingContext
    );
    const builderInfo = useContext(BuilderInfoContext);

    const builderCurrency =
        otherProps.forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo.locale.currencyLocale;

    function isAllowed(values: NumberFormatValues): boolean {
        return !(
            (max !== undefined && values.floatValue > max!) ||
            (min !== undefined && values.floatValue < min!)
        );
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, values?: NumberFormatValues) {
        if (values) {
            debouncedChange(id, values.floatValue, true, values);
        }
    }

    function handleBlur() {
        onBlur(id as string, true);
        tracking?.trackEvent({ event: "InputBlur" });
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
        event.target.select();
        tracking?.trackEvent({ event: "InputFocus" });
        otherProps.onFocus?.();
    }

    const defaultValue = defaultToZero ? 0 : "";
    const roundedValue =
        debouncedValue !== undefined && typeof debouncedValue === "number" && !isNaN(debouncedValue)
            ? round(debouncedValue, decimalScale)
            : defaultValue;
    const customInputParams = {
        suffixOverride: showSuffix ? "%" : undefined,
        style: style,
        defaultClassName: classNames("PercentageInput", className),
        "data-testid": dataTestId,
    };

    if (isReadOnly(readOnly)) {
        return (
            <PercentageDisplay
                value={roundedValue || 0}
                decimalScale={decimalScale}
                data-testid={dataTestId}
                shouldTruncate={shouldTruncate(readOnly)}
            />
        );
    }

    return (
        <ReactNumberFormat
            id={id as string}
            defaultValue={defaultValue}
            value={roundedValue}
            displayType="input"
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            isAllowed={isAllowed}
            thousandSeparator={builderCurrency.groupSeparator}
            decimalSeparator={builderCurrency.decimalSeparator}
            thousandsGroupStyle={builderCurrency.thousandsGroupStyle}
            customInput={CustomInput}
            autoFocus={autoFocus}
            placeholder={placeholder}
            {...customInputParams}
        />
    );
});

export const Percentage = memo(PercentageInternal) as typeof PercentageInternal;
