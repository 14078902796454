import { ComponentProps } from "react";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";

import { BTLoginTypes } from "types/enum";

import { externalPages } from "utilities/externalPages";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import AppStore from "commonComponents/entity/appStore/AppStore";
import SocialMedia from "commonComponents/entity/SocialMedia/SocialMedia";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";

import { UserActivationPageValuePropositions } from "entity/UserActivationPage/ValuePropositions/ValuePropositions";

import "./InvalidInvite.less";

export interface IInvalidInviteProps {
    loginType: BTLoginTypes | undefined;
}

export function InvalidInvitePage({ loginType = BTLoginTypes.BUILDER }: IInvalidInviteProps) {
    const appDefaults = useRequiredContext(AppDefaultInfoContext);
    const leftPanelBreakpoints: ComponentProps<typeof BTCol> = appDefaults.enableLoginPageRefresh
        ? {
              md: 24,
              lg: 8,
          }
        : {
              sm: 24,
              lg: 12,
              order: 2,
          };

    const rightPanelBreakpoints: ComponentProps<typeof BTCol> = appDefaults.enableLoginPageRefresh
        ? {
              md: 24,
              lg: 16,
          }
        : {
              sm: 0,
              lg: 12,
              order: 1,
          };

    return (
        <BTRow className="InvalidInvitePage" justify="center">
            <BTCol {...leftPanelBreakpoints} className="InvalidInvitePage--Form">
                <BTCol xs={18} className="InvalidInvitePage--Form--Wrapper">
                    <BTLogo layout="vertical" color="dark-blue-blue" size="sm" />
                    <div className="InvalidInvitePage--Form--Entry">
                        <BTTitle level={1} bold={true}>
                            We're sorry, this invite is no longer valid.
                        </BTTitle>
                    </div>
                    <BTRow className="InvalidInvitePage--Link--Login">
                        <p>
                            You may want to ask the person who invited you to resend the invitation.
                            If you're still having trouble, reach out to our{" "}
                            <BTButton
                                type="link"
                                data-testid="logIn"
                                isolated
                                href={externalPages.BuildertrendContactUs}
                            >
                                Support Team.
                            </BTButton>
                        </p>
                    </BTRow>
                    <div className="InvalidInvitePage--Form--Links">
                        <AppStore />
                        <div className="InvalidInvitePage--Form--Links--Social">
                            <SocialMedia theme="blueprint" />
                        </div>
                    </div>
                </BTCol>
            </BTCol>
            <BTCol {...rightPanelBreakpoints}>
                <UserActivationPageValuePropositions loginType={loginType} />
            </BTCol>
        </BTRow>
    );
}

export default InvalidInvitePage;
