import { Moment } from "moment";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";

import {
    APIError,
    NetworkError,
    ServiceUnavailableError,
    showAPIErrorMessage,
    TooManyRequestsError,
} from "utilities/apiHandler";

import type { default as SearchResponse } from "commonComponents/utilities/MainNavigation/globalSearch/JSON/GlobalSearch.api.get.json";
import type { default as FilterResponse } from "commonComponents/utilities/MainNavigation/globalSearch/JSON/GlobalSearch.api.getFilters.json";
import { SearchCategory } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

type SeachApiData = typeof SearchResponse;
type ResultApiItem = SeachApiData["results"][number];
type DebugApiData = SeachApiData["debug"];

type FilterApiData = typeof FilterResponse;

export const SearchExpirationMinutes = 15;

export interface IGlobalSearchState {
    searchValue: string;
    results?: BTSelectItem<GlobalSearchResult>[];
    recentlyViewed?: BTSelectItem<GlobalSearchResult>[];
    error?: IGlobalSearchError;
    selectedResultIndex: number;
    isSearching: boolean;
    lastKeywordChange?: Moment;
    performedAtLeastOneSuccessfulSearch: boolean;
}

export interface IGlobalSearchOpenState {
    isOpen: boolean;
    showAllFilters: boolean;
}

export interface IGlobalSearchError {
    message?: string;
    isAbortedRequest: boolean;
}

export function getSearchErrorMessage(e: unknown) {
    const response: IGlobalSearchError = {
        isAbortedRequest: false,
    };
    if (e instanceof TooManyRequestsError) {
        response.message = "Our servers are experiencing high usage. Please try your search again.";
    } else if (e instanceof ServiceUnavailableError) {
        response.message = "The search service is not currently available. Please try again later.";
    } else if (e instanceof NetworkError) {
        response.message = e.message;
        response.isAbortedRequest = e.domException?.name === "AbortError";
    } else if (e instanceof APIError) {
        response.message = e.errorMessage;
    } else {
        showAPIErrorMessage(e);
        response.message = "An unknown error occurred. Please try your search again.";
    }
    return response;
}

export class GlobalSearchEntity {
    constructor(data: SeachApiData) {
        this.results = data.results;
        this.totalCount = data.totalCount;
        this.debug = data.debug;
    }
    results: GlobalSearchResult[];
    totalCount: number;
    debug: SearchDebugData;
}

export class GlobalSearchResult {
    constructor(data: ResultApiItem) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.jobName = data.jobName;
        this.category = data.category;
        this.builderId = data.builderId;
        this.title = data.title;
    }

    id: number;
    jobId: number | null;
    jobName: string | null;
    category: SearchCategory;
    builderId: number;
    title: string;
    userId?: number;
}

class SearchDebugData {
    constructor(data: DebugApiData) {
        this.elasticRequests = data.elasticRequests;
        this.elasticTime = data.elasticTime;
        this.engineTimeTotal = data.engineTimeTotal;
        this.resultsTotal = data.resultsTotal;
        this.excludedResultsTotal = data.excludedResultsTotal;
        this.engineTime = data.engineTime;
        this.results = data.results;
        this.excludedResults = data.excludedResults;
    }

    elasticRequests: number;
    elasticTime: number;
    engineTimeTotal: number;
    resultsTotal: number;
    excludedResultsTotal: number;
    engineTime: { [key: string]: number };
    results: { [key: string]: number };
    excludedResults: { [key: string]: number };
}

export class GlobalSearchFilters {
    constructor(data: FilterApiData) {
        this.categorySelectItems = mapBTServiceDropdownToSelectItem<IGlobalSearchFilterExtraData>(
            data.categories
        );
        this.jobSelectItems = mapBTServiceDropdownToSelectItem<IGlobalSearchFilterExtraData>(
            data.availableJobs
        );
    }
    jobSelectItems: BTSelectItem<IGlobalSearchFilterExtraData>[];
    categorySelectItems: BTSelectItem<IGlobalSearchFilterExtraData>[];
}

export class IGlobalSearchFilterExtraData {
    order: number;
}

export interface IGlobalSearchFilterValues {
    jobId: number;
    category: number[];
}

export class ValidateRecentlyViewedResults {
    constructor(data: any) {
        this.searchItems = data.searchItems;
    }
    searchItems: GlobalSearchResult[];
}
