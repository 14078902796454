import { InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTForm, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInputNumber } from "commonComponents/btWrappers/BTInputNumber/BTInputNumber";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import {
    ConflictTrackingFormActions,
    IConflictTrackingFormValues,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

interface IConflictTrackingProps {
    name: string;
    values: IConflictTrackingFormValues;
    actionBeingPerformed: ConflictTrackingFormActions;
    modalConfig?: IModalConfiguration;
    onSubmit: (newValues: IConflictTrackingFormValues) => Promise<void>;
    onClose: () => void;
}

class ConflictTrackingInternal extends Component<
    InjectedFormikProps<IConflictTrackingProps, IConflictTrackingFormValues>
> {
    render() {
        const {
            name,
            onClose,
            submitCount,
            actionBeingPerformed,
            handleSubmit,
            handleBlur,
            handleChange,
            values,
            errors,
            setFieldValue,
        } = this.props;

        return (
            <BTModalLayout
                title="Schedule Item Conflicts"
                footerContent={
                    <>
                        <BTButton<ConflictTrackingFormActions>
                            type="primary"
                            htmlType="submit"
                            data-testid="update"
                            onClick={handleSubmit}
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="update"
                        >
                            Update
                        </BTButton>
                        <BTButton<ConflictTrackingFormActions>
                            type="secondary"
                            data-testid="cancel"
                            onClick={onClose}
                        >
                            Cancel
                        </BTButton>
                    </>
                }
                modalConfig={this.props.modalConfig}
            >
                <BTForm onSubmit={handleSubmit}>
                    <BTLayoutContent>
                        <div className="ConflictTracking">
                            <FormikValidationSummary
                                errors={errors}
                                values={values}
                                showAfterSubmit={submitCount}
                                scheme={ConflictTrackingValidators}
                            />

                            <PageSection title={name}>
                                <BTFormItemAutomatic<IConflictTrackingFormValues> id="shouldAlertConflicts">
                                    <BTCheckbox<IConflictTrackingFormValues>
                                        id="shouldAlertConflicts"
                                        data-testid="alertConflicts"
                                        checked={values.shouldAlertConflicts}
                                        onChange={handleChange}
                                    />
                                </BTFormItemAutomatic>

                                <BTFormItemAutomatic<IConflictTrackingFormValues> id="conflictAlertThreshold">
                                    <BTInputNumber<IConflictTrackingFormValues>
                                        id="conflictAlertThreshold"
                                        data-testid="numberOfConflicts"
                                        value={values.conflictAlertThreshold}
                                        min={2}
                                        max={100}
                                        onBlur={handleBlur}
                                        onChange={setFieldValue}
                                        roundToDecimalPlaces={0}
                                    />
                                </BTFormItemAutomatic>
                            </PageSection>
                        </div>
                    </BTLayoutContent>
                </BTForm>
            </BTModalLayout>
        );
    }
}

const ConflictTrackingValidators = yup.object().shape<IConflictTrackingFormValues>({
    shouldAlertConflicts: yup.boolean().label("Track Conflicts"),
    conflictAlertThreshold: yup
        .number()
        .required()
        .min(2, "Must track conflicts for at least 2 items")
        .max(100)
        .label("# Of Schedule Items"),
});

const ScheduleConflictTrackingPresentational = withFormik<
    IConflictTrackingProps,
    IConflictTrackingFormValues
>({
    mapPropsToValues: (props: IConflictTrackingProps) => ({
        ...props.values,
    }),

    validationSchema: () => ConflictTrackingValidators,
    validateOnChange: true,
    validateOnBlur: true,

    handleSubmit: async (values: IConflictTrackingFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
    },
})(ConflictTrackingInternal);

export default ScheduleConflictTrackingPresentational;
