import { LineItemType } from "legacyComponents/LineItemContainer.types";

import { CostTypes, MarkedAs } from "types/enum";

import { MarkupType } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { IProposalFormatFormValues } from "entity/estimate/common/ProposalFormat/ProposalFormat.types";
import { TaxMethod } from "entity/tax/common/tax.types";

export interface IWorksheetPresentationalValues extends IProposalFormatFormValues {
    taxMethod: TaxMethod;
    taxGroupId?: number;
}

export class ProposalLineItemSaveRequest {
    constructor(lineItem: any, lineItemDisplayOrder: number | null = null) {
        this.id = lineItem.id;
        this.pageTypeEnum = lineItem.lineItemType;
        this.costCode = lineItem.costCodeId!;
        this.costCodeItemId = lineItem.costItemId;
        this.title = lineItem.itemTitle;
        this.costTypes = lineItem.costTypes;
        this.markedAs = lineItem.markedAs;
        this.unitCost = lineItem.unitCost;
        this.unitType = lineItem.unit;
        this.quantity = lineItem.quantity;
        this.builderCost = lineItem.builderCost;
        this.markupType = lineItem.markupType;
        this.markupPercent = lineItem.markupPercent;
        this.markupPerUnit = lineItem.markupPerUnit;
        this.markupAmount = lineItem.markupAmount;
        this.ownerPrice = lineItem.ownerPrice;
        this.description = lineItem.description;
        this.internalNotes = lineItem.internalNotes;
        this.includeInCatalog = lineItem.includeItemInCatalog;
        this.costCategoryId = lineItem.costCategoryId;
        this.parentId = lineItem.parentId;
        this.purchaseOrderLineItemId = lineItem.relatedPurchaseOrderLineItemId;
        this.assemblyId = lineItem.assemblyId;
        this.importedFromProposalFormatItemId = lineItem.importedFromProposalFormatItemId;
        this.lineItemDisplayOrder = lineItemDisplayOrder;
        this.taxGroupId = lineItem.taxGroupId;
    }

    id: number;
    pageTypeEnum: LineItemType;
    costCode: number;
    costCodeItemId: number | null;
    title: string | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    unitCost: number;
    unitType: string;
    quantity: number;
    builderCost: number;
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    description: string;
    internalNotes: string | null;
    includeInCatalog?: boolean;
    costCategoryId: number | null;
    parentId?: number;
    purchaseOrderLineItemId?: number;
    assemblyId: number;
    importedFromProposalFormatItemId: number | null;
    lineItemDisplayOrder: number | null;
    taxGroupId: number | null;
}
