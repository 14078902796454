import { InjectedFormikProps, withFormik } from "formik";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTIconCloseOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import {
    GlobalSearchFilters,
    IGlobalSearchFilterValues,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { AllJobs } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

import "./GlobalSearchAllFilters.less";

interface IGlobalSearchAllFiltersProps {
    filters: GlobalSearchFilters | undefined;
    selectedFilters: IGlobalSearchFilterValues;
    onFilterChange: (filters: IGlobalSearchFilterValues) => void;
    onClose: (filters: IGlobalSearchFilterValues | null) => void;
}

export interface ISearchFiltersFormValues {
    jobId: number;
    category: number[];
}

const GlobalSearchAllFiltersInternal: React.FunctionComponent<
    InjectedFormikProps<IGlobalSearchAllFiltersProps, IGlobalSearchFilterValues>
> = ({ setFieldValue, values, onFilterChange, onClose, filters, setFieldTouched, resetForm }) => {
    if (!filters) {
        return null;
    }

    const dropDownWrapper = (menu: React.ReactElement) => {
        return <div className="GlobalSearch">{menu}</div>;
    };

    return (
        <div className="GlobalSearchAllFilters">
            <div className="flex">
                <BTTitle className="Header" level={2}>
                    Filters
                </BTTitle>
                <div className="flex justify-content-end">
                    <BTButton
                        data-testid="close"
                        type="link"
                        onClick={() => onClose(null)}
                        className="FilterCloseButton"
                        icon={<BTIconCloseOutlined />}
                    />
                </div>
            </div>
            <BTForm>
                <BTFormItem label="Job" className="SelectFilter">
                    <BTSelect
                        className="GlobalFilters"
                        id="jobId"
                        treeData={filters.jobSelectItems}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.jobId}
                        data-testid="jobSelect"
                        dropdownRender={dropDownWrapper}
                    />
                </BTFormItem>

                <BTFormItem label="Category" className="SelectFilter">
                    <BTSelect
                        id="category"
                        data-testid="categorySelect"
                        treeData={filters.categorySelectItems}
                        onChange={setFieldValue}
                        value={values.category}
                        onBlur={setFieldTouched}
                        dropdownRender={dropDownWrapper}
                        multiple
                    />
                </BTFormItem>
                <div className="FilterButtons">
                    <BTButton
                        id="btnResetFilters"
                        data-testid="btnResetFilters"
                        className="margin-all-xs"
                        type="secondary"
                        onClick={() => {
                            resetForm();
                            onFilterChange({ category: [], jobId: AllJobs });
                        }}
                    >
                        Reset Filters
                    </BTButton>
                    <BTButton
                        id="btnApplyFilters"
                        data-testid="btnApplyFilters"
                        className="margin-all-xs"
                        type="primary"
                        onClick={() => onClose(values)}
                    >
                        Search
                    </BTButton>
                </div>
            </BTForm>
        </div>
    );
};

const SearchFiltersValidators = yup.object().shape<ISearchFiltersFormValues>({
    jobId: yup.number(),
    category: yup.array(),
});

export const GlobalSearchAllFilters = withFormik<
    IGlobalSearchAllFiltersProps,
    ISearchFiltersFormValues
>({
    mapPropsToValues: (props: IGlobalSearchAllFiltersProps) => ({
        jobId: props.selectedFilters.jobId,
        category: props.selectedFilters.category,
    }),
    enableReinitialize: true,
    handleSubmit: () => {},
    validationSchema: SearchFiltersValidators,
})(GlobalSearchAllFiltersInternal);
