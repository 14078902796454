import { GridEntity } from "commonComponents/utilities/Grid/GridContainer.types";

export type TemplateListFormActions = "newTemplate" | "checkedAction" | undefined;

export enum TemplateListTabs {
    SelectedTemplate = 0,
    MyTemplates = 1,
    RecommendedTemplates = 2,
}

export class TemplateGridEntity extends GridEntity {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(data: any) {
        super(data);
        // TODO: handle permission logic here
    }
}
