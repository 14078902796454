import { DismissButtonFormActions } from "commonComponents/entity/emptyState/common/DismissButton/DismissButton";

interface IEmptyStateEntityCreatable {
    isReadonly: boolean;
    onCallToActionClick: () => void;
    hasListData: boolean;
}

/**
 * Implement this interface on an entity specific implementation of the empty state or filtered state
 */
export interface IEmptyStateEntityProps extends IEmptyStateEntityCreatable {
    /**
     * Has filtered data flag
     */
    hasFilteredData: boolean;
    /**
     * Has timeout state flag
     */
    hasTimeoutState?: boolean;
    /**
     * Disabled flag
     */
    disabled?: boolean;
    /**
     * BT Learning Academy Link
     */
    helpLink?: string;
}

/**
 * Implement this interface on an entity specific banner implementation for initial with data state.
 * These entities will usually have both a emptystate and banner implementation
 */
export interface IEmptyStateEntityBannerProps extends IEmptyStateEntityCreatable {
    /**
     * Is new to entity flag
     */
    isNewToEntity: boolean;
    /**
     * Empty state actions
     */
    actionBeingPerformed: EmptyStateActions;
    /**
     * On dismiss event
     */
    onDismiss?: () => void;
    /**
     * Is dismissed flag
     */
    isDismissed: boolean;
    /**
     * Dismissed flag
     */
    disabled?: boolean;
}

/**
 * Implement this interface on an entity specific banner implementation that can have any data state, empty, filtered or initial
 * These entities have only a banner implementation that covers all states
 */
export interface IEmptyStateBannerOnlyEntityProps
    extends IEmptyStateEntityProps,
        IEmptyStateEntityBannerProps {}

/**
 * Enum for the different empty state scenarios
 * @enum
 */
export enum EmptyStateDataType {
    /** A true empty state. User has no data for the entity  */
    EmptyState,
    /** Data may exist but the user is still new to the entity.
     * New to entity = User has not created entity or has not dismissed a banner message
     */
    InitialWithDataState,
    /** User is not seeing data likely due to filtering */
    FilteredState,
    /** User is not seeing data likely due to a timeout */
    TimeoutState,
}

export type EmptyStateActions = undefined | DismissButtonFormActions;

export class EmptyStateDismissResponse {}

export enum AutoDismissEntities {
    JobPicker = "jobPicker",
}

export interface IAutoDismissEmptyState {
    [key: string]: string;
}

export const emptyStateIconSize = 78;
export const emptyStateBannerIconSize = 56;
