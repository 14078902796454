import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { AuthEmailResponse } from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModal.api.types";

import { LoginResponse } from "entity/login/Login/Login.api.types";

export interface IMultiFactorAuthModalHandler {
    GetAuthInfo(credentialId: number): Promise<AuthEmailResponse>;
    SendAuthenticationEmail(credentialId: number): Promise<EmptyResponseEntity>;
    VerifyAuthCode(credentialId: number, oneTimeCode: string): Promise<LoginResponse>;
}

export class MultiFactorAuthModalHandler implements IMultiFactorAuthModalHandler {
    async GetAuthInfo(credentialId: number): Promise<AuthEmailResponse> {
        return await APIHandler(
            `/api/Login/AuthenticationEmailResponse?credentialId=${credentialId}`,
            { method: "GET", responseType: AuthEmailResponse }
        );
    }

    async SendAuthenticationEmail(credentialId: number): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Login/SendAuthenticationEmail", {
            method: "POST",
            data: { credentialId },
            responseType: EmptyResponseEntity,
        });
    }

    async VerifyAuthCode(credentialId: number, oneTimeCode: string): Promise<LoginResponse> {
        const requestData = {
            authenticationCode: oneTimeCode,
            credentialId: credentialId.toString(),
            deviceOS: "",
            deviceVersion: "",
            BTAppVersionNumber: "",
        };
        return await APIHandler("/api/Login/ConfirmAuthenticationEmail ", {
            method: "POST",
            data: requestData,
            responseType: LoginResponse,
        });
    }
}
