import classNames from "classnames";
import { forwardRef, memo } from "react";

import { displayWithFriendlyDefault } from "utilities/helpers";

import "./ValueDisplay.less";

interface IValueDisplayProps {
    id?: string;
    /** The value to be displayed in readonly mode. If null, undefined, or "", `--` will be displayed to represent an empty value. */
    value: React.ReactNode | undefined | null;
    "data-testid"?: string;
    style?: React.CSSProperties;
    displaySize?: "default" | "small" | "large";
    isTextArea?: boolean;
    className?: string;
    tabIndex?: number;
    onFocus?: (e: React.FocusEvent<any>) => void;
}

export const ValueDisplay = memo(
    forwardRef<HTMLDivElement, IValueDisplayProps>((props, ref) => {
        const { value, style, displaySize, className, isTextArea = false, ...otherProps } = props;

        let displayValue = displayWithFriendlyDefault(value);

        const CssSandboxName = "ValueDisplay";
        const styleClasses = classNames(CssSandboxName, {
            [`${CssSandboxName}-${displaySize}`]: displaySize !== undefined,
            [`${CssSandboxName}-textarea`]: isTextArea,
            [`${className}`]: !!className,
        });
        return (
            <div ref={ref} style={style} className={styleClasses} {...otherProps}>
                {displayValue}
            </div>
        );
    })
);
