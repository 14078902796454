import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IDepositProps } from "./Deposit";

const Deposit = lazyLoadWithRetry(() => import("./Deposit"));

export const RouteDeposit = (
    props: Omit<IDepositProps, "id" | "jobId" | "history" | "location" | "match" | "payOnline">
) => (
    <RouteRelative
        path={routes.deposits.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <BuilderInfoContext.Consumer>
                        {(builderInfo) => (
                            <Deposit
                                {...props}
                                {...routeProps}
                                modalConfig={{
                                    parentRoute: props.modalConfig
                                        ? props.modalConfig.parentRoute
                                        : "",
                                    beforeClose: () => {
                                        props.onClose?.(routeProps);
                                        props.modalConfig?.beforeClose();
                                    },
                                }}
                                id={parseInt(routeProps.match.params.id)}
                                jobId={parseInt(routeProps.match.params.jobId)}
                                builderInfo={builderInfo}
                                payOnline={routeProps.match.params.payOnline === "true"}
                            />
                        )}
                    </BuilderInfoContext.Consumer>
                </BTSuspense>
            );
        }}
    />
);
