import { Sortable, SortableEvent } from "react-sortablejs";

// Ported from https://github.com/SortableJS/Sortable/blob/2addddd67387b6e4b6b5e51806eb698f0a3eee88/plugins/Swap/Swap.js
// And made to work as a target only

let lastTargetEl: any;

export class TargetProps {
    constructor(draggedId?: number, targetId?: number) {
        this.draggedId = draggedId;
        this.targetId = targetId;
    }
    draggedId?: number;
    targetId?: number;
}

function TargetPlugin(): any {
    function Target(this: any) {
        this.defaults = {
            targetClass: "target-highlight",
        };
    }

    Target.prototype = {
        dragStart({ dragEl, rootEl, sortable }: any) {
            lastTargetEl = dragEl;

            setCheckboxOverride(sortable, rootEl);
        },
        dragOverValid({ completed, target, onMove, activeSortable, changed, cancel }: any) {
            if (!activeSortable.options.target) return;
            let el = this.sortable.el,
                options = this.options;
            if (target && target !== el) {
                let prevTargetEl = lastTargetEl;
                if (onMove(target) !== false) {
                    Sortable.utils.toggleClass(target, options.targetClass, true);
                    lastTargetEl = target;
                } else {
                    Sortable.utils.toggleClass(lastTargetEl, options.targetClass, false);
                    lastTargetEl = null;
                }

                if (prevTargetEl && prevTargetEl !== lastTargetEl) {
                    Sortable.utils.toggleClass(prevTargetEl, options.targetClass, false);
                }
            }
            changed();
            completed(true);
            cancel();
        },
        drop({ originalEvent, activeSortable, putSortable, dragEl }: any) {
            let touch =
                originalEvent.changedTouches && originalEvent.changedTouches.length
                    ? originalEvent.changedTouches[0]
                    : originalEvent;
            let target = document.elementFromPoint(touch.clientX, touch.clientY);
            let toSortable = putSortable || this.sortable;
            let options = this.options;

            // If the user drops outside of the target zone then do nothing
            if (toSortable && !toSortable.el.contains(target)) {
                Sortable.utils.toggleClass(lastTargetEl, options.targetClass, false);
                lastTargetEl = null;
            } else {
                lastTargetEl &&
                    Sortable.utils.toggleClass(lastTargetEl, options.targetClass, false);
                if (
                    lastTargetEl &&
                    (options.target || (putSortable && putSortable.options.target))
                ) {
                    if (dragEl !== lastTargetEl) {
                        toSortable.captureAnimationState();
                        if (toSortable !== activeSortable) activeSortable.captureAnimationState();
                        toSortable.animateAll();
                        if (toSortable !== activeSortable) activeSortable.animateAll();
                    }
                }
            }
        },
        nulling() {
            lastTargetEl = null;
        },
    };

    return Object.assign(Target, {
        pluginName: "target",
        eventProperties() {
            return {
                targetItem: lastTargetEl,
            };
        },
    });
}

export interface ITargetEvent extends SortableEvent {
    targetItem: HTMLElement | undefined;
}

// Override existing functionality to save checkbox states since we maintain this ourselves
// If this functionality is needed outside of this plugin it may be pulled out into its own plugin
// Feature was added in https://github.com/SortableJS/Sortable/issues/1052#issuecomment-369613072
function setCheckboxOverride(sortable: any, rootEl: any) {
    if (rootEl) {
        const orgEvent = sortable._nulling;
        sortable._nulling = (root: any) => {
            // Modify all checkboxes so they don't get changed
            const inputs = rootEl.getElementsByTagName("input");
            inputs.forEach((element: any) => {
                if (element.type === "checkbox") {
                    element.setAttribute("data-checked", element.checked);
                }
            });

            // Call the function like normal
            orgEvent(root);

            // Revert checkboxes back
            inputs.forEach((element: any) => {
                if (element.hasAttribute("data-checked")) {
                    element.checked = element.getAttribute("data-checked") === "true";
                }
            });
        };
    }
}

// @ts-ignore
export class Target extends TargetPlugin {}

export interface ITargetOptions {
    /**
     * Enable target mode
     */
    target?: boolean;
    /**
     * Class name for target item (if target mode is enabled)
     */
    targetClass?: string;
}
