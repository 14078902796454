import moment from "moment";

import { IPrintInfoAPIData, PrintInfoAPI } from "commonComponents/print/PrintInfo/PrintInfo.types";

interface IPrintHeaderAPIData {
    logo: string;
    builderInfo: IPrintInfoAPIData;
    builderTime?: string;
}

/** Used to display the print page headers */
export class PrintHeaderAPI {
    constructor(data: IPrintHeaderAPIData) {
        this.builderInfo = new PrintInfoAPI(data.builderInfo);
        this.builderTime = moment(data.builderTime);
        this.logo = data.logo;
    }

    builderInfo: PrintInfoAPI;
    builderTime: moment.Moment | undefined;
    logo: string;
}
