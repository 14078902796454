import moment from "moment";
import { Component } from "react";

import { PaymentEntityTypes, PaymentStatus } from "types/enum";

import { isInPortal } from "utilities/portal/portal";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconExclamation, BTIconPaymentProcessing } from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTable } from "commonComponents/btWrappers/BTTable/BTTable";
import { IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { MerchantPaymentStatusTag } from "commonComponents/entity/merchantPayment/common/MerchantPaymentStatus/MerchantPaymentStatus";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import "./MerchantPaymentList.less";

interface IMerchantPaymentListProps {
    merchantPayments?: MerchantPaymentListData;
    title?: string;
    removeCollapse?: boolean;
}

export class MerchantPaymentList extends Component<IMerchantPaymentListProps> {
    render() {
        const { merchantPayments, title, removeCollapse } = this.props;

        if (!merchantPayments) {
            return null;
        }

        const paymentType = merchantPayments
            ? merchantPayments.paymentType
            : PaymentEntityTypes.NoEntity;
        const isBuilder = isInPortal({ builder: true });
        const isSub = isInPortal({ subs: true });
        const isOwner = isInPortal({ owner: true });
        const isOnExternal = !isBuilder && !isSub && !isOwner;
        const isReceivingPayment =
            (isBuilder && paymentType === PaymentEntityTypes.OwnerInvoice) ||
            (!isBuilder && paymentType === PaymentEntityTypes.POPayment);
        const showFees =
            isReceivingPayment || (merchantPayments && merchantPayments.showFeeAndNetAmount);
        const hasRefundReason = merchantPayments.merchantPayments.some((mp) => mp.refundReason);

        const merchantPaymentListColumns: IBTColumnProps<MerchantPaymentData>[] = [];

        if (isBuilder || isOnExternal) {
            merchantPaymentListColumns.push({
                title: "Created",
                dataIndex: "addedByDate",
                render: (createdDate: moment.Moment) => <DateDisplay value={createdDate} />,
            });
        }

        if (isBuilder) {
            merchantPaymentListColumns.push({
                title: "Last Updated",
                dataIndex: "lastUpdatedDate",
                render: (lastUpdatedDate: moment.Moment) => <DateDisplay value={lastUpdatedDate} />,
            });
        }

        merchantPaymentListColumns.push({
            title: "Payment Method",
            dataIndex: "paymentMethod",
        });

        if (isSub) {
            merchantPaymentListColumns.push({
                title: "Settlement Date",
                dataIndex: "settlementDate",
                render: (settlementDate: moment.Moment | undefined) =>
                    settlementDate ? <DateDisplay value={settlementDate} /> : "",
            });
        }

        if (isBuilder) {
            merchantPaymentListColumns.push({
                title: "Paying Fee",
                dataIndex: "feePayerName",
            });
        }

        merchantPaymentListColumns.push({
            title: !isBuilder ? "Price" : showFees ? "Orig. Payment Amount" : "Payment Amount",
            dataIndex: "amount",
            align: "right",
        });

        if (showFees) {
            merchantPaymentListColumns.push(
                {
                    title: "Fees",
                    dataIndex: "fee",
                    align: "right",
                },
                {
                    title: isReceivingPayment ? "Net Deposit" : "Total Payment Amount",
                    dataIndex: "netAmount",
                    align: "right",
                }
            );
        }

        merchantPaymentListColumns.push({
            title: "Status",
            width: hasRefundReason ? 200 : undefined,
            render: (row: MerchantPaymentData) => {
                if (row.refundReason) {
                    return (
                        <>
                            <div>
                                <MerchantPaymentStatusTag status={row.status} />
                            </div>
                            <div>
                                <span className="text-bold">Reason:</span> {row.refundReason}
                            </div>
                        </>
                    );
                } else {
                    return <MerchantPaymentStatusTag status={row.status} />;
                }
            },
        });

        const merchantPaymentListFooter = (merchantPaymentList: MerchantPaymentListData) => {
            return (
                <>
                    {(isBuilder || isOnExternal) && <div />}
                    {isBuilder && <div />}
                    {(isBuilder || isSub) && <div />}
                    <div className="PurchaseOrder--PaymentsFooter">Total Listed:</div>
                    <div className="PurchaseOrder--PaymentsFooter">
                        {merchantPaymentList.totalAmount}
                    </div>
                    {showFees && <div />}
                    {showFees && (
                        <div className="PurchaseOrder--PaymentsFooter">
                            {merchantPaymentList.totalAmountReceived
                                ? merchantPaymentList.totalAmountReceived
                                : ""}
                        </div>
                    )}
                    <div />
                </>
            );
        };

        const refundIndicator =
            merchantPayments &&
            merchantPayments.merchantPayments.find((m) => m.status === PaymentStatus.Refunded) ? (
                <BTPopover content="This invoice contains a refund" placement="rightTop">
                    <span className="MerchantPaymentList--RefundIndicator">&nbsp;*</span>
                </BTPopover>
            ) : (
                ""
            );

        const table = (
            <BTTable<MerchantPaymentData>
                dataSource={merchantPayments!.merchantPayments}
                rowKey={(row, index) => `${index}`}
                columns={merchantPaymentListColumns}
                pagination={false}
                size="small"
                tableFooter={merchantPaymentListFooter(merchantPayments)}
            />
        );

        if (removeCollapse) {
            return table;
        }

        return (
            <>
                {merchantPayments && (
                    <PageSection
                        title={
                            <BTRow>
                                <BTCol span={12}>
                                    <BTTitle level={2} style={{ margin: 0 }}>
                                        {`${title ?? "Online Payment Details"} (${
                                            merchantPayments.merchantPayments.length
                                        })`}
                                        {refundIndicator}
                                    </BTTitle>
                                </BTCol>
                                <BTCol span={12} style={{ textAlign: "right" }}>
                                    <BTTitle level={3}>
                                        {merchantPayments.lastPaymentHasError && (
                                            <BTIconExclamation data-testid="paymentErrorIcon" />
                                        )}
                                        <span>
                                            <BTIconPaymentProcessing data-testid="merchantPaymentProcessingIcon" />{" "}
                                            Processing Payment
                                        </span>
                                    </BTTitle>
                                </BTCol>
                            </BTRow>
                        }
                        className="MerchantPaymentList"
                        isCollapsible
                    >
                        {table}
                    </PageSection>
                )}
            </>
        );
    }
}

export class MerchantPaymentListData {
    constructor(data: any) {
        this.merchantPayments = data.merchantPayments.map((x: any) => new MerchantPaymentData(x));
        this.showFeeAndNetAmount = data.showFeeAndNetAmount;
        this.lastPaymentHasError = data.lastPaymentHasError;
        this.totalAmount = data.totalAmount;
        this.totalAmountReceived = data.totalAmountReceived;
        this.paymentType = data.paymentType;
    }
    merchantPayments: MerchantPaymentData[];
    showFeeAndNetAmount: boolean;
    lastPaymentHasError: boolean;
    totalAmount: string;
    totalAmountReceived: string | null;
    paymentType: PaymentEntityTypes;
}

export class MerchantPaymentData {
    constructor(data: any) {
        this.addedByDate = moment(data.addedByDate);
        this.amount = data.amount;
        this.fee = data.fee ? data.fee : "-";
        this.feePayerName = data.feePayerName;
        this.lastUpdatedDate = moment(data.lastUpdatedDate);
        this.netAmount = data.netAmount ? data.netAmount : "-";
        this.paymentMethod = data.paymentMethod;
        this.settlementDate = data.settlementDate ? moment(data.settlementDate) : undefined;
        this.status = data.statusValue;
        this.id = data.id;
        this.canIssueRefund = data.canIssueRefund;
        this.formattedAddedByDate = data.formattedAddedByDate;
        this.refundReason = data.refundReason;
    }
    addedByDate: moment.Moment;
    amount: string;
    fee: string | null;
    feePayerName: string;
    lastUpdatedDate: moment.Moment;
    netAmount: string | null;
    paymentMethod: string;
    settlementDate: moment.Moment | undefined;
    status: PaymentStatus;
    canIssueRefund: boolean;
    id: number;
    formattedAddedByDate: string;
    refundReason: string;
}
