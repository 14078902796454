import { Component } from "react";
import { CSSTransition } from "react-transition-group";

import "./HighlightUpdates.less";

/**
 * Props for the main HighlightUpdates component
 */
interface IHighlightUpdatesProps {
    /** If this changes, run the glow animation */
    value: any;
}

interface IHighlightUpdatesState {
    different: boolean;
    blocked: boolean;
}

/** Wrap this component around inputs for glowing onChange animations */
export class HighlightUpdates extends Component<IHighlightUpdatesProps, IHighlightUpdatesState> {
    state = {
        different: false,
        blocked: false,
    };

    private resetAnimation = () => {
        this.setState({ different: false, blocked: false });
    };

    private blockAnimation = () => {
        this.setState({ blocked: true });
    };

    componentDidUpdate = (nextProps: IHighlightUpdatesProps) => {
        // Ignoring below eslint rule @ time of implementation.
        if (this.props.value !== nextProps.value) {
            this.setState({ different: true });
        }
    };

    render() {
        return (
            <CSSTransition
                in={this.state.different && !this.state.blocked}
                timeout={1000}
                classNames="flashTransition"
                onEntered={this.resetAnimation}
                enter
            >
                <div onFocusCapture={this.blockAnimation} onBlur={this.resetAnimation}>
                    {this.props.children}
                </div>
            </CSSTransition>
        );
    }
}
