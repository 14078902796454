import { Drawer, DrawerProps } from "antd";
import { useCallback } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";

export interface IBTDrawerProps
    extends Pick<
        DrawerProps,
        | "className"
        | "placement"
        | "visible"
        | "afterVisibleChange"
        | "forceRender"
        | "width"
        | "closable"
        | "onClose"
        | "mask"
        | "title"
        | "footer"
        | "destroyOnClose"
        | "keyboard"
    > {}

export const BTDrawer: React.FC<IBTDrawerProps & ITrackingProp> = track({
    element: "Drawer",
})((props) => {
    const { children, tracking, visible, afterVisibleChange, ...otherProps } = props;

    const handleVisibleChange = useCallback(() => {
        if (visible) {
            tracking?.trackEvent({
                event: "DrawerOpen",
            });
        } else {
            tracking?.trackEvent({
                event: "DrawerClose",
            });
        }
        afterVisibleChange?.(visible ? visible : false);
    }, [visible, tracking, afterVisibleChange]);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Drawer afterVisibleChange={handleVisibleChange} visible={visible} {...otherProps}>
            {children}
        </Drawer>
    );
});
