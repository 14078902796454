export type ReportFormActions = "Update Filters" | "Download PDF" | "Load More" | undefined;

export interface IReportFormValues {}

export class ReportEntity {
    constructor(data: any) {
        this.title = data.title;
        this.chartType = data.chartType == null ? undefined : data.chartType;
        this.chartData = data.chartData;
        this.decimals = data.decimals;
        this.percentage = data.percentage;
        this.stacked = data.stacked;
        this.overlap = data.overlap;
        this.time = data.time;
        this.diff = data.diff;
        this.totalPercentage = data.totalPercentage;
        this.listResponse = new ReportListResponse(data.listResponse);
        this.rowCount = data.rowCount;
        this.hasTimeout = data.timeoutException !== null;
    }
    title: string;
    chartType?: string;
    chartData: string;
    decimals: number;
    percentage: boolean;
    stacked: boolean;
    overlap: boolean;
    time: boolean;
    diff: boolean;
    totalPercentage: boolean;
    listResponse: ReportListResponse;
    rowCount: number;
    hasTimeout: boolean;
}

class ReportListResponse {
    constructor(data: any) {
        this.type = data.type;
        this.category = data.category;
        this.categoryTitle = data.categoryTitle;
        this.filterType = data.filterType;
        this.title = data.title;
        this.description = data.description;
        this.placeholderUrl = data.placeholderUrl;
    }
    type: ReportTypes;
    category: number;
    categoryTitle: string;
    filterType: number;
    title: string;
    description: string;
    placeholderUrl: string;
}

export class VerifyAuthorizationResponse {}

export class ReportDeleteResponse {}

export class ReportCreateResponse {}

export enum ReportTypes {
    AmountInvoicedVsAmountPaid = 0,
    DailyLogCountPerJob = 1,
    DailyLogCountPerUser = 2,
    EstimatedCostVsActualCost = 3,
    JobCountPerProjectManager = 4,
    LeadActivitiesPerSalesperson = 5,
    LeadCountPerSalesperson = 6,
    LeadStatusPerSource = 7,
    ProjectCompletionPerJob = 8,
    RunningTotalVsContractPrice = 9,
    ToDoCountPerJob = 10,
    ToDoCountPerUser = 11,
    TotalHoursWorkedPerUser = 12,
    AverageAgeOfLeadPerSalesPerson = 13,
    AverageAgeOfLeadPerSource = 14,
    DurationPerJob = 15,
    TotalHoursWorkedByJob = 16,
    CompanyCashflowPerJob = 17,
    TotalCompanyLeadCount = 18,
    WarrantyClaimsPerJob = 19,
    WorkInProgressCash = 20,
    WorkInProgressAccrual = 23,
}
