import { Input, Typography } from "antd";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { InjectedFormikProps, withFormik } from "formik";
import { uniq } from "lodash-es";
import { Component, createRef, useCallback, useMemo } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { btConfirm, btDeleteConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTForm, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import {
    BTIconCloseOutlined,
    BTIconDeleteFilled,
    BTIconEditFilled,
    BTIconEllipsisOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import {
    GridViewColumn,
    GridViewItem,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { GridColumn } from "commonComponents/utilities/Grid/GridContainer.types";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";
import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";
import {
    ISavedViewsAndFiltersCardFormValues,
    SavedViewsAndFiltersCardActions,
} from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard.api.types";

import "./SavedViewsAndFiltersCard.less";

export interface ISavedViewsAndFiltersCardProps {
    data: SavedFilterItem | GridViewItem;
    isExpanded: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onSetAsDefaultClick: () => void;
    onCopyClick: () => void;
    onSaveFilter?: (values: ISavedViewsAndFiltersCardFormValues) => void;
    onUpdateFilter?: (filter: SavedFilterItem, values: ISavedViewsAndFiltersCardFormValues) => void;
    onSaveView?: (
        values: ISavedViewsAndFiltersCardFormValues,
        columns: GridViewColumn[],
        saveAsNew: boolean
    ) => void;
    columnList?: GridColumn<unknown>[];
    columnsSelectedList?: GridColumn<unknown>[];
    actionBeingPerformed: SavedViewsAndFiltersCardActions;
    filterTypeEntity?: FilterEntityType;
    isNew: boolean;
    closeExpandedCard: () => void;
    filterList?: SavedFilterItem[];
    viewList?: GridViewItem[];
}

interface IFunctionalSavedViewsAndFiltersCardProps
    extends InjectedFormikProps<
        ISavedViewsAndFiltersCardProps,
        ISavedViewsAndFiltersCardFormValues
    > {
    entityTypeName: string;
}

class SavedViewsAndFiltersCardInternal extends Component<
    InjectedFormikProps<ISavedViewsAndFiltersCardProps, ISavedViewsAndFiltersCardFormValues>
> {
    componentDidMount() {
        if (this.nameInputRef.current) {
            this.nameInputRef.current.focus();
        }
    }

    private getEntityTypeName() {
        if (this.props.data instanceof SavedFilterItem) {
            return "Filter";
        } else {
            return "View";
        }
    }

    private handleEditClick = () => {
        const { data, onEditClick } = this.props;
        const entityTypeNameLC = this.getEntityTypeName().toLowerCase();

        if (!data.isPrivate) {
            btConfirm({
                title: `Edit saved ${entityTypeNameLC}?`,
                content: `Editing this ${entityTypeNameLC} will change it for everyone in your account.`,
                okText: "Edit",
                cancelText: "Cancel",
                onOk: () => {
                    onEditClick();
                },
            });
        } else {
            onEditClick();
        }
    };

    private handleDeleteClick = () => {
        const { data, onDeleteClick } = this.props;
        const entityTypeName = this.getEntityTypeName();
        let contentMessage = "";
        if (!data.isPrivate) {
            contentMessage = `Deleting this ${entityTypeName.toLowerCase()} will remove it for everyone in your account. `;
        }
        if (data.isDefault) {
            contentMessage += `Your default ${entityTypeName.toLowerCase()} will be set to the Standard ${entityTypeName}.`;
        }
        if (!data.isPrivate || data.isDefault) {
            btDeleteConfirm({
                title: `Delete Saved ${entityTypeName}?`,
                content: contentMessage,
                okText: "Delete",
                cancelText: "Cancel",
                onOk: () => {
                    onDeleteClick();
                },
            });
        } else {
            onDeleteClick();
        }
    };

    private handleCopyClick = () => {
        const { onCopyClick } = this.props;
        onCopyClick();
    };

    private handleCloseExpandedClick = () => {
        const { closeExpandedCard, resetForm } = this.props;
        resetForm();
        closeExpandedCard();
    };

    private handleColumnListUpdated = (treeData: BTSelectItem[]) => {
        const requiredIds = treeData.filter((c) => c.disabled).map((c) => c.value);
        return (field: string, value: any) => {
            const ids = uniq([...value, ...requiredIds]);
            this.props.setFieldValue(field, ids);
        };
    };

    private getColumnSelectData = () => {
        return this.props.columnList
            ? this.props.columnList
                  .sort((a, b) => {
                      if (a.enabled && b.enabled) {
                          return a.order - b.order;
                      } else if (!a.enabled && !b.enabled) {
                          // unselected custom fields should be at the end of the list
                          if (a.isCustomField !== b.isCustomField) {
                              return a.isCustomField && !b.isCustomField ? 1 : -1;
                          }
                          return a.name.localeCompare(b.name);
                      }
                      return a.enabled && !b.enabled ? -1 : 1;
                  })
                  .map(
                      (col) =>
                          new BTSelectItem({
                              name: col.isCustomField ? `*Custom Field - ${col.name}` : col.name,
                              id: col.id,
                              disabled: !col.isRemovableFromGrid,
                          })
                  )
            : [];
    };

    private nameInputRef = createRef<Input>();

    render() {
        const { isExpanded } = this.props;
        const entityTypeName = this.getEntityTypeName();
        const treeData = this.getColumnSelectData();
        return !isExpanded ? (
            <SavedViewsAndFiltersCardCollapsed
                {...this.props}
                onEditClick={this.handleEditClick}
                onDeleteClick={this.handleDeleteClick}
                entityTypeName={entityTypeName}
                onCopyClick={this.handleCopyClick}
            />
        ) : (
            <SavedViewsAndFiltersCardExpanded
                {...this.props}
                entityTypeName={entityTypeName}
                treeData={treeData}
                nameInputRef={this.nameInputRef}
                onDeleteClick={this.handleDeleteClick}
                handleColumnListUpdated={this.handleColumnListUpdated}
                closeExpandedCard={this.handleCloseExpandedClick}
            />
        );
    }
}

const SavedViewsAndFiltersCardCollapsed: React.FunctionComponent<
    IFunctionalSavedViewsAndFiltersCardProps
> = (props) => {
    const { data, onEditClick, onDeleteClick, onSetAsDefaultClick, onCopyClick, entityTypeName } =
        props;
    const { isDefault, isPrivate } = data;
    const isOwner = data instanceof SavedFilterItem ? data.isOwner : data.canDelete && data.canEdit;
    const canEdit = data.canEdit;
    const name = data instanceof SavedFilterItem ? data.name : data.viewName;
    const defaultBubble = (
        <StatusTagDisplay statusText={`Default ${entityTypeName}`} statusType="info" />
    );
    const entityTypeNameLC = entityTypeName.toLowerCase();
    const extraActionsDropdown = (
        <BTMenu>
            <BTMenuItem
                data-testid="setAsDefault"
                onClick={onSetAsDefaultClick}
                disabled={isDefault}
            >
                Set as default {entityTypeNameLC}
            </BTMenuItem>
            <BTMenuItem data-testid="duplicateSaved" onClick={onCopyClick}>
                Duplicate saved {entityTypeNameLC}
            </BTMenuItem>
        </BTMenu>
    );
    return (
        <BTCard className="SavedViewsAndFiltersCard">
            <BTRow className="align-items-center">
                <BTCol flex="auto" className="name-column">
                    {isDefault && <BTRow>{defaultBubble}</BTRow>}
                    <BTRow>
                        <Typography.Text>{name}</Typography.Text>
                    </BTRow>
                    {isOwner && isPrivate && (
                        <BTRow>
                            <Typography.Text type="secondary">Private</Typography.Text>
                        </BTRow>
                    )}
                    {isOwner && !isPrivate && (
                        <BTRow>
                            <Typography.Text type="secondary">Shared</Typography.Text>
                        </BTRow>
                    )}
                </BTCol>
                <BTCol className="flex justify-content-end">
                    <BTRow align="middle">
                        {canEdit && (
                            <>
                                <BTPopover content="Edit">
                                    <BTButton
                                        type="link"
                                        data-testid="edit-button"
                                        onClick={onEditClick}
                                        icon={<BTIconEditFilled />}
                                    />
                                </BTPopover>
                                <BTPopover content="Delete">
                                    <BTButton
                                        type="link"
                                        data-testid="delete-button"
                                        onClick={onDeleteClick}
                                        icon={<BTIconDeleteFilled />}
                                    />
                                </BTPopover>
                            </>
                        )}
                        <BTDropdown overlay={extraActionsDropdown} placement="bottomRight">
                            <BTPopover content="More Actions">
                                <BTButton
                                    type="link"
                                    data-testid=""
                                    onClick={() => {}}
                                    icon={<BTIconEllipsisOutlined />}
                                />
                            </BTPopover>
                        </BTDropdown>
                    </BTRow>
                </BTCol>
            </BTRow>
        </BTCard>
    );
};

const SavedViewsAndFiltersCardExpanded: React.FunctionComponent<
    IFunctionalSavedViewsAndFiltersCardProps & {
        treeData: BTSelectItem<undefined>[];
        nameInputRef: React.RefObject<Input>;
        handleColumnListUpdated: (
            treeData: BTSelectItem[]
        ) => (field: string, value: any, selectedItem?: BTSelectItem) => void;
    }
> = (props) => {
    const {
        data,
        entityTypeName,
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        onDeleteClick,
        actionBeingPerformed,
        treeData,
        nameInputRef,
        handleColumnListUpdated,
        isNew,
        onSaveView,
        onSaveFilter,
        onUpdateFilter,
        validateForm,
        columnList,
        columnsSelectedList,
        closeExpandedCard,
    } = props;
    const cardTitle = (isNew ? "Add New" : "Edit") + ` Saved  ${entityTypeName}`;
    const saveButtonText = isNew ? "Add" : "Save";
    const isOwner = data instanceof SavedFilterItem ? data.isOwner : data.canDelete && data.canEdit;
    const selectedColumns = useMemo(() => {
        if (values.isFilter) {
            return undefined;
        } else {
            // if columnsSelectedList is set, carry over the existing column settings (frozen) to a newly saved view
            const currentIds = columnsSelectedList ? columnsSelectedList?.map((c) => c.id) : [];
            return [
                ...(columnsSelectedList ?? []),
                ...columnList!.filter((c2) => !currentIds?.includes(c2.id)),
            ]
                .filter((col) => values.columns.indexOf(col.id) !== -1)
                .map(({ id, width, isFrozen }) => new GridViewColumn({ id, width, isFrozen }));
        }
    }, [columnList, columnsSelectedList, values.columns, values.isFilter]);

    const customHandleSave = useCallback(async () => {
        const formErrors = await validateForm(values);
        if (Object.keys(formErrors).length === 0) {
            if (!values.isFilter) {
                onSaveView!(values, selectedColumns!, isNew);
            } else {
                if (isNew) {
                    onSaveFilter!(values);
                } else {
                    onUpdateFilter!(data as SavedFilterItem, values);
                }
            }
        } else {
            Object.keys(formErrors).forEach((key) => setFieldTouched(key, true));
        }
    }, [
        data,
        isNew,
        onSaveFilter,
        onSaveView,
        onUpdateFilter,
        selectedColumns,
        setFieldTouched,
        validateForm,
        values,
    ]);

    const handleChangeIsShared = useCallback(
        (e: CheckboxChangeEvent) => {
            setFieldValue("isPrivate", !e.target.checked);
        },
        [setFieldValue]
    );

    return (
        <BTCard className="SavedViewsAndFiltersCard-expanded">
            <BTRow>
                <BTCol flex="auto">
                    <BTTitle level={3}>{cardTitle}</BTTitle>
                </BTCol>
                <BTCol className="flex justify-content-end">
                    <BTButton
                        data-testid="close"
                        type="link"
                        onClick={closeExpandedCard}
                        icon={<BTIconCloseOutlined />}
                    />
                </BTCol>
            </BTRow>
            <BTForm>
                <BTFormItemAutomatic<ISavedViewsAndFiltersCardFormValues>
                    id={values.isFilter ? "filterName" : "viewName"}
                    label={values.isFilter ? "Filter Name" : "Label"}
                >
                    <BTInput
                        id={values.isFilter ? "filterName" : "viewName"}
                        data-testid="name"
                        value={values.isFilter ? values.filterName : values.viewName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        fieldRef={nameInputRef}
                        autoFocus
                    />
                </BTFormItemAutomatic>
                {!values.isFilter && (
                    <BTFormItemAutomatic<ISavedViewsAndFiltersCardFormValues> id="columns">
                        <BTSelect<ISavedViewsAndFiltersCardFormValues>
                            id="columns"
                            data-testid="columns"
                            treeData={treeData}
                            multiple
                            value={values.columns}
                            onChange={handleColumnListUpdated(treeData)}
                            onBlur={setFieldTouched}
                        />
                    </BTFormItemAutomatic>
                )}
                <BTFormItemAutomatic<ISavedViewsAndFiltersCardFormValues> id="isPrivate">
                    <BTCheckbox
                        id="isPrivate"
                        data-testid="isShared"
                        checked={!values.isPrivate}
                        onChange={handleChangeIsShared}
                        disabled={!isOwner}
                    >
                        Share this {values.isFilter ? "filter" : "view"}
                    </BTCheckbox>
                </BTFormItemAutomatic>
                <BTFormItemAutomatic<ISavedViewsAndFiltersCardFormValues> id="isDefault">
                    <BTCheckbox
                        id="isDefault"
                        data-testid="isDefault"
                        checked={values.isDefault}
                        onChange={handleChange}
                    >
                        Set as default
                    </BTCheckbox>
                </BTFormItemAutomatic>
                <BTRow justify="end">
                    {!isNew && (
                        <BTButton
                            type="secondary"
                            data-testid="expanded-delete"
                            loadingAction="delete"
                            actionBeingPerformed={actionBeingPerformed}
                            onClick={onDeleteClick}
                            className="deleteButton"
                        >
                            Delete
                        </BTButton>
                    )}
                    <BTButton
                        data-testid="expanded-save"
                        type="primary"
                        loadingAction="save"
                        actionBeingPerformed={actionBeingPerformed}
                        onClick={async () => {
                            await customHandleSave();
                        }}
                        className="saveButton"
                    >
                        {saveButtonText}
                    </BTButton>
                </BTRow>
            </BTForm>
        </BTCard>
    );
};

const SavedViewsAndFiltersCardValidators = (props: ISavedViewsAndFiltersCardProps) => {
    return yup.object().shape<ISavedViewsAndFiltersCardFormValues>({
        isDefault: yup.bool(),
        isPrivate: yup.bool(),
        isFilter: yup.bool(),
        viewName: yup.string().when("isFilter", {
            is: false,
            then: yup
                .string()
                .max(25)
                .required()
                .transform((str) => str.toLowerCase())
                .notOneOf(
                    props
                        .viewList!.filter((v) => v.viewId !== (props.data as GridViewItem).viewId)
                        .map((v) => v.viewName.toLowerCase()),
                    "View name must be unique"
                ),
        }),
        filterName: yup.string().when("isFilter", {
            is: true,
            then: yup
                .string()
                .max(25)
                .required()
                .transform((str) => str.toLowerCase())
                .notOneOf(
                    props
                        .filterList!.filter((f) => f.id !== (props.data as SavedFilterItem).id)
                        .map((f) => f.name.toLowerCase()),
                    "Filter name must be unique"
                ),
        }),
        columns: yup
            .array<string>()
            .label("Columns")
            .when("isFilter", {
                is: false,
                then: yup
                    .array<string>()
                    .label("Columns")
                    .min(1, "Column field must have at least 1 item"),
            }),
        filterType: yup.number(),
        selectedFilter: yup.number(),
        value: yup.string(),
        savedView: yup.number(),
    });
};

export const SavedViewsAndFiltersCard = withFormik<
    ISavedViewsAndFiltersCardProps,
    ISavedViewsAndFiltersCardFormValues
>({
    mapPropsToValues: (
        props: ISavedViewsAndFiltersCardProps
    ): ISavedViewsAndFiltersCardFormValues => {
        const isFilter = props.data instanceof SavedFilterItem;
        return {
            isFilter: isFilter,
            viewName: isFilter ? "" : (props.data as GridViewItem).viewName,
            filterName: isFilter ? (props.data as SavedFilterItem).name : "",
            isDefault: props.data.isDefault,
            isPrivate: props.data.isPrivate,
            value: isFilter ? (props.data as SavedFilterItem).value : undefined,
            columns: props.columnList
                ? props.columnList.filter((col) => col.enabled).map((col) => col.id)
                : [],
            filterType: isFilter ? props.filterTypeEntity! : 0,
            selectedFilter: 0,
            savedView: isFilter ? 0 : (props.data as GridViewItem).viewId,
        };
    },
    validationSchema: SavedViewsAndFiltersCardValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: async () => {},
})(SavedViewsAndFiltersCardInternal);
