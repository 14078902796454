import { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router";

import { BTLoginTypes } from "types/enum";

import { track } from "utilities/analytics/analytics";
import { getGlobalValue } from "utilities/globalValueUtils";
import {
    getAllBuildersSelected,
    getSelectedJobId,
    getSelectedJobIds,
} from "utilities/jobPicker/jobPicker";
import { isInPortal } from "utilities/portal/portal";
import { routesWebforms } from "utilities/routesWebforms";

import { HotkeyDisplaySetup } from "commonComponents/helpers/HotkeyDisplaySetup/HotkeyDisplaySetup";
import {
    IJobPickerHandler,
    JobPickerHandler,
} from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";

import { SystemFilterType } from "entity/filters/Filter/Filter.api.types";

export interface IEntityListWrapperState {
    builderId: number;
    jobIds: number[];
    jobName: string;
    userId: number;
    isTemplateMode: boolean;
    loginType: BTLoginTypes;
    jobPickerReady: boolean;
    systemFilter?: SystemFilterType;
    isRouteJobIdDifferent?: boolean;
    allJobsSelected: boolean;
    allBuildersSelected: boolean;
}

interface IEntityListWrapperRouteProps {
    jobId: string;
}

interface IEntityListWrapperProps {
    render: (state: IEntityListWrapperState) => ReactNode;
    isJobPickerRequired: boolean; // Set variable to true if your entity requires the job picker
    reactRouterProps?: RouteComponentProps<IEntityListWrapperRouteProps>;
    jobPickerHandler?: IJobPickerHandler;
}

/* This should only be used on Webforms list pages */

const getJobIds = (hasJobPicker: boolean): number[] => {
    if (!hasJobPicker) {
        return [];
    }

    return getSelectedJobIds();
};

const isAllJobsSelected = (hasJobPicker: boolean): boolean => {
    if (!hasJobPicker) {
        return false;
    }
    return getSelectedJobId() === JobIdTypes.AllJobs;
};

export default class EntityListWrapper extends Component<
    IEntityListWrapperProps,
    IEntityListWrapperState
> {
    static defaultProps = {
        isJobPickerRequired: true,
        jobPickerHandler: new JobPickerHandler(),
    };

    state: Readonly<IEntityListWrapperState> = {
        builderId: Number(getGlobalValue("getBuilderId")),
        userId: Number(getGlobalValue("globalUserId")),
        jobIds: getJobIds(this.props.isJobPickerRequired),
        jobName: "",
        isTemplateMode: window.btJScriptGlobals.isTemplateMode,
        allBuildersSelected: false,
        loginType: getGlobalValue("loginTypeInt")!,
        jobPickerReady: false,
        systemFilter: parseSystemFilterQS(),
        isRouteJobIdDifferent: undefined,
        allJobsSelected: isAllJobsSelected(this.props.isJobPickerRequired),
    };

    async componentDidMount() {
        // eslint-disable-next-line no-restricted-syntax
        if (typeof (window as any).JobPickerPubSubKeys !== "undefined") {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).ko.postbox.subscribe(
                // eslint-disable-next-line no-restricted-syntax
                (window as any).JobPickerPubSubKeys.JobPickerLoadComplete,
                (job: any) => {
                    const newJobIds = getJobIds(this.props.isJobPickerRequired);
                    const jobId = this.calculateJobId(newJobIds);
                    const routeJobId = this.props.reactRouterProps
                        ? parseInt(this.props.reactRouterProps!.match.params.jobId)
                        : NaN;

                    this.setState((prevState) => {
                        return {
                            jobIds: newJobIds,
                            jobName: job.selectedJobName,
                            jobPickerReady: this.props.reactRouterProps
                                ? prevState.jobPickerReady
                                : true,
                            allBuildersSelected: getAllBuildersSelected(),
                            allJobsSelected: isAllJobsSelected(this.props.isJobPickerRequired),
                            isRouteJobIdDifferent: this.props.reactRouterProps
                                ? jobId !== routeJobId
                                : undefined,
                        };
                    });
                }
            );

            // eslint-disable-next-line no-restricted-syntax
            (window as any).ko.postbox.subscribe(
                // eslint-disable-next-line no-restricted-syntax
                (window as any).JobPickerPubSubKeys.JobClicked,
                (job: any) => {
                    const jobName: string = job.jobName;
                    const builderId: number = job.builderId;
                    const newJobIds = getJobIds(this.props.isJobPickerRequired);
                    const jobId = this.calculateJobId(newJobIds);
                    const routeJobId = this.props.reactRouterProps
                        ? parseInt(this.props.reactRouterProps!.match.params.jobId)
                        : NaN;

                    this.setState({
                        jobIds: newJobIds,
                        jobName,
                        builderId,
                        isRouteJobIdDifferent: this.props.reactRouterProps
                            ? jobId !== routeJobId
                            : undefined,
                        allJobsSelected: isAllJobsSelected(this.props.isJobPickerRequired),
                        allBuildersSelected: getAllBuildersSelected(),
                    });
                }
            );

            // eslint-disable-next-line no-restricted-syntax
            (window as any).ko.postbox.subscribe(
                // eslint-disable-next-line no-restricted-syntax
                (window as any).JobPickerPubSubKeys.JobClickPrePostback,
                (args: any) => {
                    args.shouldPostback = false;
                }
            );

            // eslint-disable-next-line no-restricted-syntax
            (window as any).ko.postbox.subscribe(
                // eslint-disable-next-line no-restricted-syntax
                (window as any).JobPickerPubSubKeys.JobPickerLoadComplete,
                async () => {
                    const { isJobPickerRequired, reactRouterProps } = this.props;
                    if (reactRouterProps) {
                        const routeJobId = parseInt(reactRouterProps.match.params.jobId);
                        const jobId = this.calculateJobId(getJobIds(isJobPickerRequired));

                        if (
                            routeJobId !== undefined &&
                            !isNaN(routeJobId) &&
                            routeJobId !== jobId
                        ) {
                            // If the route's jobId isn't the current job, we need to make it the current job
                            // eslint-disable-next-line no-restricted-syntax
                            void (window as any).JobPickerBridge.setSelectedJobId(routeJobId, true);
                        }
                    }
                    this.setState({ jobPickerReady: true });
                }
            );
        }
    }

    shouldComponentUpdate = (
        nextProps: IEntityListWrapperProps,
        nextState: IEntityListWrapperState
    ) => {
        if (this.props.reactRouterProps && nextProps.reactRouterProps) {
            const routeJobId = parseInt(this.props.reactRouterProps.match.params.jobId);
            const nextRouteId = parseInt(nextProps.reactRouterProps.match.params.jobId);
            const jobId = this.calculateJobId(getJobIds(this.props.isJobPickerRequired));

            if (nextRouteId !== undefined && !isNaN(nextRouteId)) {
                const isOwnerPortal = isInPortal({ owner: true });
                // When the jobId in the route has just changed, we need to set the current job to be the new routeJobId
                // We don't want to render the child media list since we don't want to give it the now outdated jobId
                if (nextRouteId !== routeJobId && nextRouteId !== jobId && !isOwnerPortal) {
                    // eslint-disable-next-line no-restricted-syntax
                    void (window as any).JobPickerBridge.setSelectedJobId(nextRouteId, true);
                    return false;
                }
                // After the JobPickerLoadComplete event fires, if the routeJobId and jobId don't match, we need to wait for the new selected job.
                // We don't want to render the child media list since we don't want to give it the now outdated jobId
                if (
                    nextState.jobPickerReady !== this.state.jobPickerReady &&
                    routeJobId !== undefined &&
                    !isNaN(routeJobId) &&
                    routeJobId !== jobId &&
                    routeJobId !== JobIdTypes.NoJobs
                ) {
                    return false;
                }
            }
        }

        return true;
    };

    private calculateJobId = (jobIds: number[]) => {
        if (this.props.reactRouterProps) {
            // Use the same logic as the media lists to calculate the jobId
            const jobId = jobIds.length === 1 ? jobIds[0] : JobIdTypes.AllJobs;
            return jobId;
        }
        return NaN;
    };

    render() {
        if (
            isInPortal({ owner: true }) ||
            this.state.jobPickerReady ||
            !this.props.isJobPickerRequired!
        ) {
            return (
                <TrackedWrapper jobIds={this.state.jobIds}>
                    {this.props.render(this.state)}
                    <HotkeyDisplaySetup />
                </TrackedWrapper>
            );
        } else {
            return null;
        }
    }
}

function parseSystemFilterQS() {
    let systemFilter: SystemFilterType | undefined;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("filter")) {
        systemFilter = Number(queryParams.get("filter"));
        queryParams.delete("filter");
        // eslint-disable-next-line deprecate/member-expression
        routesWebforms.replaceState(null, null, "?" + queryParams.toString());
    }
    return systemFilter;
}

export const TrackedWrapper: React.FunctionComponent<{ jobIds?: number[] }> = track((props) => ({
    jobId: (props.jobIds?.length ?? 0) === 1 ? props.jobIds[0] : 0,
}))((props) => <>{props.children}</>);
