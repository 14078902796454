import { Tag } from "antd";
import { Component } from "react";

import { CostTypes } from "types/enum";

import { friendlyDefaultDisplayValue } from "utilities/helpers";
import { isNullOrUndefined } from "utilities/object/object";

interface ICostTypeListProps {
    costTypes?: CostTypes[];
}

export class CostTypeList extends Component<ICostTypeListProps> {
    render() {
        const { costTypes } = this.props;

        if (!isNullOrUndefined(costTypes) && costTypes.length > 0) {
            return (
                <>
                    {costTypes.map((x, index) => (
                        <Tag key={index}>{CostTypes[x]}</Tag>
                    ))}
                </>
            );
        }
        return friendlyDefaultDisplayValue;
    }
}
