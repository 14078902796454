import { QueryClientProvider } from "@tanstack/react-query";
import NetworkErrorPage from "layouts/errors/NetworkErrorPage";
import ServerError from "layouts/errors/ServerError";
import { ErrorInfo, PureComponent } from "react";

import { queryClient } from "helpers/queryClient";

import { NetworkError } from "utilities/apiHandler";
import { reportError } from "utilities/errorHelpers";
import { externalPages } from "utilities/externalPages";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

interface IServerErrorBoundarProps {
    children: React.ReactNode;
}

enum ErrorTypes {
    None = 0,
    ServerError = 1,
    NetworkError = 2,
    Unknown = 3,
}

interface IServerErrorBoundaryState {
    currentError: ErrorTypes;
}

export class ServerErrorBoundary extends PureComponent<
    IServerErrorBoundarProps,
    IServerErrorBoundaryState
> {
    state: Readonly<IServerErrorBoundaryState> = {
        currentError: ErrorTypes.None,
    };

    private handleRefresh = () => {
        window.location.reload();
    };

    static getDerivedStateFromError(error: unknown): IServerErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        if (!(error instanceof Error)) {
            return { currentError: ErrorTypes.Unknown };
        }

        if (error instanceof NetworkError) {
            return { currentError: ErrorTypes.NetworkError };
        }

        return { currentError: ErrorTypes.ServerError };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (error && error instanceof NetworkError) {
            return;
        }

        if (error !== undefined) {
            reportError(error, errorInfo, "error");
        } else {
            // If no error was passed to the error boundary, but the error boundary
            // was triggered anyways, we want to still report it with a generic
            // exception
            reportError(
                new Error("Critical page failure: An unknown error has occurred"),
                errorInfo,
                "error"
            );
        }
    }

    render() {
        switch (this.state.currentError) {
            case ErrorTypes.ServerError:
            case ErrorTypes.Unknown:
                return (
                    <ServerError>
                        <BTRow responsiveMode="viewport" justify="center">
                            <BTCol sm={16} xs={24}>
                                <BTRow gutter={32} justify="center" align="middle">
                                    <BTButton
                                        data-testid="refreshPage"
                                        type="link"
                                        onClick={this.handleRefresh}
                                    >
                                        Refresh Page
                                    </BTButton>
                                    <BTCol>
                                        <BTExternalLink
                                            data-testid="buildertrendHomepage"
                                            href={externalPages.BuildertrendCom}
                                        >
                                            Buildertrend Homepage
                                        </BTExternalLink>
                                    </BTCol>
                                </BTRow>
                            </BTCol>
                        </BTRow>
                    </ServerError>
                );
            case ErrorTypes.NetworkError:
                return (
                    <QueryClientProvider client={queryClient}>
                        <NetworkErrorPage />
                    </QueryClientProvider>
                );
            default:
                return this.props.children;
        }
    }
}
