import memoizeOne from "memoize-one";
import queryString from "query-string";

import { OpenedFrom } from "types/enum";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { PortalInfoContext } from "commonComponents/utilities/InlineOwnerPreview/PortalInfoContext";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IChangeOrderProps } from "entity/changeOrder/ChangeOrder/ChangeOrder";
import { InlinePreviewChangeOrderHandler } from "entity/changeOrder/ChangeOrder/ChangeOrder.api.handler";
import { ChangeOrderEntity } from "entity/changeOrder/ChangeOrder/ChangeOrder.api.types";

const ChangeOrder = lazyLoadWithRetry(() => import("./ChangeOrder"));

const PreviewChangeOrderHandler = memoizeOne((changeOrder: ChangeOrderEntity) => {
    return new InlinePreviewChangeOrderHandler(changeOrder);
});

export const RouteChangeOrder = (
    props: Omit<IChangeOrderProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.changeOrder.changeOrderDetails}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            const urlParams = routeProps.match.params;

            const coId = parseInt(urlParams.id);
            const jobId = parseInt(urlParams.jobId);
            let openedFrom = props.openedFrom ? props.openedFrom : OpenedFrom.Nothing;
            if (qsValues.fromBid) {
                openedFrom = OpenedFrom.Bid;
            }
            if (qsValues.fromRFI) {
                openedFrom = OpenedFrom.Rfi;
            }

            return (
                <BTSuspense>
                    <PortalInfoContext.Consumer>
                        {(portalInfo) => {
                            let handler;
                            if (portalInfo.currentPortal !== portalInfo.rootPortal) {
                                handler = PreviewChangeOrderHandler(props.changeOrder!);
                            }
                            return (
                                <ChangeOrder
                                    {...props}
                                    {...routeProps}
                                    id={coId}
                                    jobId={jobId}
                                    openedFrom={openedFrom}
                                    handler={handler}
                                />
                            );
                        }}
                    </PortalInfoContext.Consumer>
                </BTSuspense>
            );
        }}
    />
);
