import { ComponentProps } from "react";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

const OwnerSetup = lazyLoadWithRetry(() => import("entity/OwnerSetup/OwnerSetup"));

export const RouteOwnerSetup = (
    props: Omit<ComponentProps<typeof OwnerSetup>, "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.owner.setup}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const jobId = parseInt(routeValues.jobId);

            return (
                <BTSuspense>
                    <OwnerSetup {...props} {...routeProps} jobId={jobId} />
                </BTSuspense>
            );
        }}
    />
);
