import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IBudgetSettingsProps } from "entity/budget/budgetSettings/budgetSettings";

const IntegrationsSettings = lazyLoadWithRetry(() => import("./IntegrationsSettings"));
export const RouteIntegrationsSettings = (
    props: Omit<IBudgetSettingsProps, "id" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.marketplace.integrationSettings}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            const integrationType = parseInt(routeProps.match.params.type);
            const forceLogin: boolean = qsValues.forceLogin;
            const showError: boolean = qsValues.showError;
            return (
                <BTSuspense>
                    <IntegrationsSettings
                        {...routeProps}
                        {...props}
                        forceLogin={forceLogin}
                        showError={showError}
                        integrationType={integrationType}
                    />
                </BTSuspense>
            );
        }}
    />
);
