import classNames from "classnames";
import { forwardRef } from "react";
import { SizeMe, SizeMeProps } from "react-sizeme";

import "./Sticky.less";

import { useStickyHeight } from "./StickyContext";
import { StickyDirection } from "./StickyContext.type";

interface IStickyProps {
    /**
     * The direction that this container will stick when scrolled to this edge of the scroll area
     * @default "top"
     */
    direction?: StickyDirection;
    /**
     * This drives the level at which the sticky container will stick in relation to other sticky containers.
     * The lower the number, the closer to the edge the container will stick.
     *
     * - | top -      0 |
     * - | top -    100 |
     * - | top -    200 |
     * - |              |
     * - | bottom - 200 |
     * - | bottom - 100 |
     * - | bottom -   0 |
     */
    level: number;
    isSticky?: boolean;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    /**
     * @Default "scroll"
     */
    resizeDetectorStrategy?: "scroll" | "object";
}

const StickyInternal = forwardRef<HTMLDivElement, IStickyProps & SizeMeProps>(
    (
        { direction = "top", size, level, isSticky = true, className, style, onScroll, children },
        divRef
    ) => {
        const height = useStickyHeight(size.height && isSticky ? size.height : 0, direction, level);
        return (
            <div
                className={classNames({ Sticky__Component: isSticky }, className)}
                style={{ ...style, [direction]: height }}
                onScroll={onScroll}
                ref={divRef}
            >
                {children}
            </div>
        );
    }
);

export const Sticky = forwardRef<HTMLDivElement, IStickyProps>((props, divRef) => (
    <SizeMe
        monitorHeight
        monitorWidth={false}
        noPlaceholder
        resizeDetectorStrategy={props.resizeDetectorStrategy ?? "scroll"}
    >
        {({ size }) => <StickyInternal {...props} size={size} ref={divRef} />}
    </SizeMe>
));
