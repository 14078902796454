import { Route } from "react-router";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";

export const ExternalRoute = ({ path, url }: { path: string; url: string }) => (
    <Route
        path={path}
        component={() => {
            window.location.assign(url);
            return <BTSuspense />;
        }}
    />
);
