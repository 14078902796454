import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IReconsentProps } from "entity/marketplace/Reconsent/Reconsent";
import { ReconsentHandler } from "entity/marketplace/Reconsent/Reconsent.api.handler";

const Reconsent = lazyLoadWithRetry(() => import("./Reconsent"));
export const RouteReconsent = (
    props: Omit<IReconsentProps, "id" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.marketplace.reconsent}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Reconsent
                        {...routeProps}
                        {...props}
                        modalConfig={{
                            parentRoute: props.modalConfig ? props.modalConfig.parentRoute : "",
                            beforeClose: () => {
                                props.modalConfig?.beforeClose();
                            },
                        }}
                        handler={new ReconsentHandler()}
                    />
                </BTSuspense>
            );
        }}
    />
);
