import queryString from "query-string";
import { match, RouteComponentProps, StaticContext } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IScheduleProps } from "entity/schedule/Schedule/Schedule";
import {
    IScheduleCreationData,
    ScheduleEntityDefaultParams,
    ScheduleTabNames,
} from "entity/schedule/Schedule/Schedule.api.types";

const Schedule = lazyLoadWithRetry(() => import("./Schedule"));

export interface IScheduleUrlProps {
    id: string;
    jobId: string;
}

interface IRouteScheduleProps
    extends Omit<
        IScheduleProps,
        | "id"
        | "jobId"
        | "initialTab"
        | "linkedPredId"
        | "phaseId"
        | "title"
        | "isAllDay"
        | "startDate"
        | "endDate"
        | "assignedIds"
        | "color"
        | "history"
        | "location"
        | "match"
    > {
    prefilledFormValues?: IScheduleCreationData;
    onClose?: (routeProps: RouteComponentProps<IScheduleUrlProps, StaticContext, unknown>) => void;
}

export function getScheduleRouteProps(
    id: number,
    jobId: number,
    routeProps: RouteComponentProps
): RouteComponentProps<IScheduleUrlProps, StaticContext, unknown> {
    const scheduleMatch: match<IScheduleUrlProps> = {
        ...routeProps.match,
        params: { id: id.toString(), jobId: jobId.toString() },
    };
    return {
        ...routeProps,
        match: scheduleMatch,
    };
}

export const RouteSchedule = (props: IRouteScheduleProps) => (
    <RouteRelative
        path={routes.schedule.details}
        render={(routeProps: RouteComponentProps<IScheduleUrlProps, StaticContext, unknown>) => {
            const routeValues = routeProps.match.params;
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            const id = parseInt(routeValues.id);
            const jobId = parseInt(routeValues.jobId);
            const initialTab = qsValues.initialTab
                ? (parseInt(qsValues.initialTab) as ScheduleTabNames)
                : undefined;
            const linkedPredId = qsValues.linkedPredId
                ? (parseInt(qsValues.linkedPredId) as number)
                : undefined;
            const phaseId = qsValues.phaseId ? (parseInt(qsValues.phaseId) as number) : undefined;

            return (
                <BTSuspense>
                    <Schedule
                        {...props}
                        {...routeProps}
                        id={id}
                        jobId={jobId}
                        initialTab={initialTab}
                        linkedPredId={linkedPredId}
                        phaseId={phaseId}
                        entityDefaultParams={new ScheduleEntityDefaultParams(qsValues)}
                        modalConfig={{
                            parentRoute: props.modalConfig ? props.modalConfig.parentRoute : "",
                            beforeClose: () => {
                                props.onClose?.(routeProps);
                                props.modalConfig?.beforeClose();
                            },
                        }}
                    />
                </BTSuspense>
            );
        }}
    />
);
