// eslint-disable-next-line no-restricted-imports
import { QueryClient } from "@tanstack/react-query";

/**
 * Exported so that it can be cleared in between tests
 */
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            staleTime: 0,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

/**
 * Use this instead of the default queryClient when you want to simulate error states.
 */
export const queryClientError = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: Infinity,
            queryFn: () => Promise.reject(new Error("A query rejected by queryClientError.")),
        },
    },
});

/**
 * Use this instead of the default queryClient when you want to simulate loading states.
 */
export const queryClientLoading = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            queryFn: () => new Promise(() => {}),
        },
    },
});
