import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IPdfSignatureRequestProps } from "entity/pdfSignature/PdfSignatureRequest";

const PdfSignatureRequest = lazyLoadWithRetry(() => import("./PdfSignatureRequest"));

interface IRoutePdfSignatureRequestProps
    extends Omit<
        IPdfSignatureRequestProps,
        "id" | "jobId" | "documentInstanceId" | "history" | "match" | "location"
    > {}
export const RoutePdfSignatureRequest = (props: IRoutePdfSignatureRequestProps) => (
    <RouteRelative
        path={routes.signatureRequest.standaloneDetails}
        render={(routeProps) => {
            const { id, jobId, docInstanceId } = routeProps.match.params;
            return (
                <BTSuspense>
                    <PdfSignatureRequest
                        id={parseInt(id)}
                        jobId={parseInt(jobId)}
                        documentInstanceId={parseInt(docInstanceId)}
                        {...props}
                        {...routeProps}
                    />
                </BTSuspense>
            );
        }}
    />
);
