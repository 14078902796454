import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { BTLoginTypes } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import {
    LinkedAccountingEntityResponse,
    UnlinkAccountingResponse,
    UpdateAccountingResponse,
} from "commonComponents/entity/accounting/LinkedAccountingEntity/LinkedAccountingEntity.api.types";

import { RecursiveUpdateTypes } from "entity/costCatalog/CostCatalog.types";
import {
    AddToJobResponse,
    AdjustPercentMarkupFormValues,
    IAddToJobRequest,
    IJobPermissionRequest,
    JobDeleteResponse,
    JobEntity,
    JobPermissionResponse,
    JobPermissionWizardResponse,
    JobRunningTotal,
    JobSaveRequest,
    JobSaveResponse,
    LinkableJobListResponse,
    UpdateSubPermissionResponse,
    UpdateUserPermissionResponse,
    UserListResponse,
    UsernameAndPasswordValidationResponse,
} from "entity/job/Job.api.types";

export interface IJobHandler {
    get(id: number): Promise<JobEntity>;
    getDefault(): Promise<JobEntity>;
    getUsersList(jobId: number, userType: BTLoginTypes): Promise<UserListResponse>;
    create(newValues: JobSaveRequest): Promise<JobSaveResponse>;
    update(id: number, updateRequest: JobSaveRequest): Promise<JobSaveResponse>;
    delete(id: number, deleteTimeCardItems: boolean): Promise<JobDeleteResponse>;
    addToJob(request: IAddToJobRequest): Promise<AddToJobResponse>;
    getJobPermissions(request: IJobPermissionRequest): Promise<JobPermissionResponse>;
    updateUserPermissions(
        jobIds: number[],
        permissions: number[],
        subIds?: number[],
        internalUserIds?: number[],
        skipGlobalDocs?: boolean
    ): Promise<UpdateUserPermissionResponse>;
    updateSubPermissions(
        jobIds: number[],
        permissions: number[],
        subsToInvite?: number[],
        subIds?: number[]
    ): Promise<UpdateSubPermissionResponse>;
    updateAccountingJobsite(jobId: number): Promise<UpdateAccountingResponse>;
    unlinkJobsiteFromAccounting(jobId: number): Promise<UnlinkAccountingResponse>;
    validateUserNameAndPassword(
        jobId: number,
        username: string,
        password: string
    ): Promise<UsernameAndPasswordValidationResponse>;
    getAccountingInfo(id: number): Promise<LinkedAccountingEntityResponse>;
    getJobPermissonsWizard(jobId: number): Promise<JobPermissionWizardResponse>;
    sendMassSubInvitationRequest(
        subsToInvite: number[],
        subInviteMessage: string
    ): Promise<EmptyResponseEntity>;
    setUpdateTypesForJob(
        jobId: number,
        newUpdateType: RecursiveUpdateTypes
    ): Promise<EmptyResponseEntity>;
    getLinkableJobs(jobId: number): Promise<LinkableJobListResponse>;
    updateDefaultMarkup: (
        jobId: number,
        values: AdjustPercentMarkupFormValues
    ) => Promise<EmptyResponseEntity>;
}

export class JobHandler implements IJobHandler {
    async get(id: number): Promise<JobEntity> {
        const response = await APIHandler(`/api/jobsites/${id}`, {
            method: "GET",
            responseType: JobEntity,
        });
        response.id = id;
        response.jobId = id;
        return response;
    }

    async getDefault(): Promise<JobEntity> {
        const response = await APIHandler(`/api/jobsites/DefaultInfo`, {
            method: "GET",
            responseType: JobEntity,
        });
        response.id = 0;
        response.jobId = 0;
        return response;
    }

    async getJobRunningTotal(jobId: number): Promise<JobRunningTotal> {
        const response = await APIHandler(`/api/jobsites/${jobId}/JobRunningTotal`, {
            method: "GET",
            responseType: JobRunningTotal,
        });
        return response;
    }

    async getUsersList(jobId: number, userType: BTLoginTypes): Promise<UserListResponse> {
        return await APIHandler(`/api/Users/UsersList?jobsiteId=${jobId}&userType=${userType}`, {
            method: "GET",
            responseType: UserListResponse,
        });
    }

    async create(addRequest: JobSaveRequest): Promise<JobSaveResponse> {
        return await APIHandler(`/api/jobsites/Add`, {
            method: "PUT",
            data: addRequest,
            responseType: JobSaveResponse,
        });
    }

    async update(id: number, updateRequest: JobSaveRequest): Promise<JobSaveResponse> {
        return await APIHandler(`/api/jobsites/${id}`, {
            method: "PUT",
            data: updateRequest,
            responseType: JobSaveResponse,
        });
    }

    async delete(id: number, deleteTimeCardItems: boolean): Promise<JobDeleteResponse> {
        return await APIHandler(`/api/jobsites/${id}`, {
            method: "DELETE",
            data: { deleteTimeCardItems: deleteTimeCardItems },
            responseType: JobDeleteResponse,
        });
    }

    async addToJob(request: IAddToJobRequest): Promise<AddToJobResponse> {
        return await APIHandler(`/api/jobsites/${request.jobId}/Users/`, {
            method: "PUT",
            data: request,
            responseType: AddToJobResponse,
        });
    }

    async getJobPermissions(request: IJobPermissionRequest): Promise<JobPermissionResponse> {
        let headers = {};
        if (request.subIds) {
            headers["X-AddedSubIds"] = request.subIds;
        }
        if (request.internalUserIds) {
            headers["X-AddedInternalUserIds"] = request.internalUserIds;
        }
        return await APIHandler("/api/jobsites/JobPermissions/", {
            method: "POST",
            data: request,
            responseType: JobPermissionResponse,
            headers: headers,
        });
    }

    async getJobPermissonsWizard(jobId: number): Promise<JobPermissionWizardResponse> {
        return await APIHandler(`/api/jobsites/GetJobsitePermissionsWizard/`, {
            method: "GET",
            data: { jobId: jobId },
            responseType: JobPermissionWizardResponse,
        });
    }

    async updateUserPermissions(
        jobIds: number[],
        permissions: number[],
        subIds?: number[],
        internalUserIds?: number[],
        skipGlobalDocs?: boolean
    ): Promise<UpdateUserPermissionResponse> {
        return await APIHandler("/api/jobsites/UpdateUserPermissions", {
            method: "PUT",
            data: {
                jobIds: jobIds,
                permissions: permissions,
                subs: subIds,
                users: internalUserIds,
                skipGlobalDocs,
            },
            responseType: UpdateUserPermissionResponse,
        });
    }

    async updateSubPermissions(
        jobIds: number[],
        permissions: number[],
        subIds?: number[]
    ): Promise<UpdateSubPermissionResponse> {
        return await APIHandler("/api/jobsites/UpdateSubPermissions", {
            method: "PUT",
            data: { jobIds: jobIds, permissions: permissions, subs: subIds },
            responseType: UpdateUserPermissionResponse,
        });
    }

    async updateAccountingJobsite(jobId: number): Promise<UpdateAccountingResponse> {
        return await APIHandler(`/api/jobsites/UpdateAccountingJob/`, {
            method: "PUT",
            data: { jobId: jobId },
            responseType: UpdateAccountingResponse,
        });
    }

    async unlinkJobsiteFromAccounting(jobId: number): Promise<UnlinkAccountingResponse> {
        return await APIHandler(`/api/jobsites/UnlinkJobsiteFromAccounting/`, {
            method: "PUT",
            data: { jobId: jobId },
            responseType: UnlinkAccountingResponse,
        });
    }

    async validateUserNameAndPassword(
        jobId: number,
        username: string,
        password: string
    ): Promise<UsernameAndPasswordValidationResponse> {
        return await APIHandler(`/api/jobsites/ValidateUserNameAndPassword/`, {
            method: "GET",
            data: { jobId: jobId, username: username, password: password },
            responseType: UsernameAndPasswordValidationResponse,
        });
    }

    async getAccountingInfo(id: number): Promise<LinkedAccountingEntityResponse> {
        return await APIHandler(
            `/api/Accounting/${id}/LinkedEntityInfo?loginType=${BTLoginTypes.OWNER}`,
            { method: "GET", responseType: LinkedAccountingEntityResponse }
        );
    }

    async sendMassSubInvitationRequest(subsToInvite: number[], subInviteMessage: string) {
        return await APIHandler(`/api/Jobsites/SendMassSubInvitationRequest/`, {
            method: "PUT",
            data: { subsToInvite: subsToInvite, subInviteMessage: subInviteMessage },
            responseType: EmptyResponseEntity,
        });
    }

    async setUpdateTypesForJob(jobId: number, newUpdateType: RecursiveUpdateTypes) {
        return await APIHandler(
            `/api/CostCodeItems/SetUpdateTypesForJob?jobId=${jobId}&newUpdateType=${newUpdateType}`,
            { method: "PUT", responseType: EmptyResponseEntity }
        );
    }

    async getLinkableJobs(jobId: number): Promise<LinkableJobListResponse> {
        return await APIHandler(`/api/jobsites/GetLinkedJobsListItems`, {
            method: "GET",
            data: { jobId: jobId },
            responseType: LinkableJobListResponse,
        });
    }

    async updateDefaultMarkup(jobId: number, values: AdjustPercentMarkupFormValues) {
        return await APIHandler(`/api/jobsites/${jobId}/UpdateMarkupSettings`, {
            method: "PUT",
            data: {
                defaultMargin: values.defaultMargin ?? null,
                defaultMarkup: values.defaultMarkup ?? null,
                markupMarginPercentType: values.markupMarginPercentType,
                costTypesMarkup: values.costTypesMarkup ?? [],
            },
            responseType: EmptyResponseEntity,
        });
    }
}
