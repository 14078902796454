import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import classNames from "classnames";
import { Component, memo, useContext } from "react";

import {
    NumberFormatValues,
    default as ReactNumberFormat,
} from "@buildertrend/react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { round } from "utilities/math/math";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { CurrencyDisplay } from "commonComponents/financial/CurrencyDisplay/CurrencyDisplay";
import {
    IInputDebouncingContext,
    useDebouncedOnChange,
} from "commonComponents/utilities/Input/InputDebouncingContext";
import { ReadOnlyShouldTruncate } from "commonComponents/utilities/TextTruncate/TextTruncate.type";
import {
    isReadOnly,
    shouldTruncate,
} from "commonComponents/utilities/TextTruncate/TextTruncate.utility";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./Currency.less";

interface ICurrencyProps<FormValues> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;

    /** pass formik setFieldValue */
    onChange: (
        field: string,
        value: number | undefined,
        shouldValidate?: boolean,
        values?: NumberFormatValues
    ) => void;

    /** pass formik setFieldTouched */
    onBlur: (field: string, touched: boolean) => void;

    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;

    value: number | undefined | null;

    /** @default "default" */
    size?: "large" | "default" | "small";

    /** @default true */
    allowNegative?: boolean;

    /** @default 2 */
    decimalScale?: number;

    /** @default false */
    disabled?: boolean;

    /** @default 0 */
    defaultValue?: number;

    /** @default 1000000000 (one billion) */
    max?: number;

    /** @default -1000000000 (negative one billion) */
    min?: number;

    style?: React.CSSProperties;

    className?: string;

    /** @default false */
    readOnly?: ReadOnlyShouldTruncate;

    /** Currency will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    /** @default undefined */
    placeholder?: string;

    /** @default undefined */
    suffix?: string;

    /** @default undefined */
    showPlaceholder?: boolean;

    /** ref for the input */
    fieldRef?: React.RefObject<Input>;

    autoFocus?: boolean;

    /**
     * Sets the debounce context and limits. Should only be set in edge cases.
     */
    debouncingContext?: IInputDebouncingContext;
}

interface ICustomInputProps {
    defaultClassName: string;
    prefixOverride: string;
    suffixOverride: string;
    "data-testid": string;
    fieldRef?: React.RefObject<Input>;
}

export class CustomInput extends Component<ICustomInputProps & InputProps> {
    render() {
        const {
            prefixOverride,
            suffixOverride,
            className,
            disabled,
            defaultClassName,
            "data-testid": dataTestId,
            fieldRef,
            ...rest
        } = this.props;
        const classNameDefault = disabled ? `${defaultClassName}-disabled` : defaultClassName;

        return (
            // eslint-disable-next-line react/forbid-elements
            <Input
                className={classNames(classNameDefault, className)}
                disabled={disabled}
                addonBefore={prefixOverride}
                addonAfter={suffixOverride}
                data-testid={dataTestId}
                ref={fieldRef}
                {...rest}
            />
        );
    }
}

/**
 * Displays a formatted currency as an input, use whenever you want to have currency editable
 */
const CurrencyInternal = track((props) => ({
    element: "Currency Input",
    uniqueId: props["data-testid"],
}))(function <FormValues = undefined>({
    id,
    style,
    suffix,
    placeholder,
    showPlaceholder,
    autoFocus,
    disabled = false,
    onChange,
    onBlur,
    onFocus = () => {},
    value,
    defaultValue = 0,
    max = 1_000_000_000,
    min = -1_000_000_000,
    allowNegative = true,
    decimalScale = 2,
    "data-testid": testid,
    readOnly = false,
    fieldRef,
    tracking,
    debouncingContext = { useDebouncing: true, inputDelay: 150 },
    className,
    ...otherProps
}: ICurrencyProps<FormValues> & ITrackingProp) {
    const [debouncedValue, debouncedChange] = useDebouncedOnChange<typeof value, FormValues>(
        value,
        onChange,
        debouncingContext
    );
    const builderInfo = useContext(BuilderInfoContext);

    const currencyLocale =
        otherProps.forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo.locale.currencyLocale;

    function handleIsAllowed(values: NumberFormatValues) {
        // enforce min/max of 1/-1 billion
        return !(values.floatValue >= max! || values.floatValue <= min!);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, values?: NumberFormatValues) {
        if (values) {
            debouncedChange(id, values.floatValue, true, values);
        }
    }

    function handleBlur() {
        onBlur(id as string, true);
        tracking?.trackEvent({ event: "InputBlur" });
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
        event.target.select();
        onFocus(event);
        tracking?.trackEvent({ event: "InputFocus" });
    }

    let roundedValue: string | number | null | undefined;
    if (debouncedValue === null) {
        roundedValue = null;
    } else {
        roundedValue =
            debouncedValue !== undefined &&
            typeof debouncedValue === "number" &&
            !isNaN(debouncedValue)
                ? round(debouncedValue, decimalScale)
                : undefined;
    }

    const customInputParams = {
        prefixOverride: currencyLocale.currencySymbol.trim(),
        style: style,
        defaultClassName: `top-zero CurrencyInput`,
        "data-testid": testid,
        fieldRef: fieldRef,
    };

    if (isReadOnly(readOnly)) {
        const displayValue = (
            <CurrencyDisplay
                className={classNames("top-zero", className)}
                decimalScale={decimalScale}
                value={roundedValue || 0}
                data-testid={testid}
                suffix={suffix}
                shouldTruncate={shouldTruncate(readOnly)}
                forceUSCulture={otherProps.forceUSCulture}
            />
        );

        return <ValueDisplay value={displayValue} />;
    }

    return (
        <ReactNumberFormat
            className={className}
            placeholder={placeholder}
            id={id as string}
            defaultValue={showPlaceholder ? undefined : defaultValue}
            // ReactNumberFormat's value property is incorrectly typed and can actually be null.
            // It is necessary to support null values for clearing the input programmatically.
            // https://github.com/s-yadav/react-number-format/pull/347
            value={roundedValue as any}
            displayType="input"
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            isAllowed={handleIsAllowed}
            thousandSeparator={currencyLocale.groupSeparator}
            decimalSeparator={currencyLocale.decimalSeparator}
            fixedDecimalScale={true}
            thousandsGroupStyle={currencyLocale.thousandsGroupStyle}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            customInput={CustomInput}
            autoFocus={autoFocus}
            allowedDecimalSeparators={[currencyLocale.decimalSeparator]}
            {...customInputParams}
        />
    );
});

export const Currency = memo(CurrencyInternal) as typeof CurrencyInternal;
