import { RouteComponentProps } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

const ContactUs = lazyLoadWithRetry(() => import("../ContactUs/ContactUs"));

export interface IContactSupportProps extends RouteComponentProps {
    modalConfig?: IModalConfiguration;
}

export const RouteContactUs = (
    props: Omit<IContactSupportProps, "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.contact.contactUs}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <ContactUs {...props} {...routeProps} />
                </BTSuspense>
            );
        }}
    />
);
