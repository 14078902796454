import Button, { ButtonProps } from "antd/lib/button";
import classNames from "classnames";
import { PureComponent, ReactNode } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { getLoadingAction } from "utilities/form/form";

import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";

import "./BTButton.less";

export const btButtonTypes = [
    "secondary",
    "primary",
    "tertiary",
    "link",
    "ghost",
    "dashed",
    "destruction",
    "new",
    "marketing-primary",
] as const;

export type BTButtonType = (typeof btButtonTypes)[number];

const btButtonTypeToAntDict = {
    secondary: "default",
    new: "success",
    tertiary: "text",
    destruction: "danger",
};

export interface IBTButtonPropsBase
    extends Omit<ButtonProps, "onClick" | "type" | "icon" | "disabled" | "title"> {
    /**
     * @example
     * "save" | "delete" | "print" | "notify"
     */
    "data-testid": string;

    /**
     * Applies disabled styles to the button, note: a disabled attribute will not be added to the
     * button to make sure bubbleup events still function
     * @default false
     */
    disabled?: boolean;

    /**
     * @default "default"
     */
    type?: BTButtonType;

    onClick?:
        | ((event: React.FormEvent<HTMLFormElement>) => void)
        | ((event: React.MouseEvent<any, MouseEvent>) => void);

    icon?: ReactNode;

    /**
     * Reduce padding for use of buttons outside of button groups. Should only be used with tertirary type buttons in practice.
     * @default false
     */
    isolated: boolean;

    /**
     * Apply an underline to the button text. Should only be used with isolated tertiary buttons in practice.
     * @default false
     */
    underline: boolean;

    /**
     * Apply to remove box-shadow on focus/click
     */
    noShadow?: boolean;

    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
}

interface IBTButtonPropsLoading<ActionType> extends IBTButtonPropsBase {
    /**
     * @example
     * <BTButton actionBeingPerformed={actionBeingPerformed} loadingAction="save" />
     */
    actionBeingPerformed: ActionType;

    /**
     * @example
     * <BTButton actionBeingPerformed={actionBeingPerformed} loadingAction="save" />
     */
    loadingAction: ActionType;
}

export type IBTButtonProps<ActionType> = IBTButtonPropsBase | IBTButtonPropsLoading<ActionType>;

@track((props) => ({ element: "Button", uniqueId: props["data-testid"] }))
export class BTButton<ActionType> extends PureComponent<
    IBTButtonProps<ActionType> & ITrackingProp
> {
    static defaultProps = {
        isolated: false,
        underline: false,
        noShadow: false,
        disabled: false,
    };

    private handleClick = (event: any) => {
        const { onClick, disabled } = this.props;

        if (disabled) {
            event.preventDefault();
            return;
        }

        if (onClick) {
            onClick(event);
        }

        this.props.tracking?.trackEvent({ event: "ButtonClick" });
    };

    private handleDoubleClick = (event: any) => {
        const { onDoubleClick, disabled } = this.props;

        if (disabled) {
            event.preventDefault();
            return;
        }

        if (onDoubleClick) {
            onDoubleClick(event);
        }
    };

    render() {
        const {
            children,
            icon,
            type,
            actionBeingPerformed,
            loadingAction,
            onClick,
            className,
            isolated,
            underline,
            noShadow,
            disabled,
            tabIndex,
            size,
            hotkey,
            ...otherProps
        } = this.props as IBTButtonPropsLoading<ActionType>;
        // note: actionBeingPerformed and loadingAction can be undefined

        // add loading props if they were passed
        let loadingProps: ButtonProps = {};
        if (
            "actionBeingPerformed" in this.props &&
            actionBeingPerformed !== undefined &&
            loadingAction !== undefined
        ) {
            loadingProps = getLoadingAction(actionBeingPerformed, loadingAction);
        }

        let translatedAntButtonType = type;
        if (type && btButtonTypeToAntDict[type]) {
            translatedAntButtonType = btButtonTypeToAntDict[type];
        }
        const button = (
            // eslint-disable-next-line react/forbid-elements
            <Button
                {...otherProps}
                {...loadingProps}
                onClick={this.handleClick as any}
                onDoubleClick={this.handleDoubleClick as any}
                type={translatedAntButtonType as any}
                icon={undefined} // TODO antd 4.0 allows a ReactNode to be passed in here
                className={classNames(className, "BTButton", {
                    "ant-btn-icon-only": children === undefined && icon !== undefined,
                    isolated: isolated,
                    "isolated-underlined": isolated && underline,
                    NoShadow: noShadow,
                    disabled: disabled,
                    "ant-btn-default": type === undefined,
                    AutoSizing: size === undefined,
                })}
                tabIndex={disabled ? -1 : tabIndex}
                aria-disabled={disabled}
            >
                {!otherProps.loading && icon}
                {children}
            </Button>
        );
        const disableHotkey = loadingProps.disabled || !!loadingProps.loading || disabled;
        if (hotkey) {
            return (
                <Hotkey
                    {...getHotkeyProps(hotkey)}
                    disabled={disableHotkey}
                    onCommand={() => {
                        if (!disableHotkey) {
                            this.handleClick({
                                preventDefault: () => {},
                            });
                        }
                    }}
                >
                    {button}
                </Hotkey>
            );
        } else {
            return button;
        }
    }
}
