import { BTLoginTypes } from "types/enum";

import { IPasswordResetHandler } from "entity/passwordReset/PasswordReset/PasswordReset.api.handler";

export interface IPasswordResetResponse {
    userName: string;
    builderId: number;
    loginType: BTLoginTypes;
}

export class PasswordResetResponse implements IPasswordResetResponse {
    constructor(data: IPasswordResetResponse) {
        this.userName = data.userName;
        this.builderId = data.builderId;
        this.loginType = data.loginType;
    }

    userName: string;
    builderId: number;
    loginType: BTLoginTypes;
}

export interface IPasswordResetRequest {
    pwd: string;
    cpwd: string;
    jwt: string;
    id: number;
}

export interface IJwtValidationRequest {
    id: number;
    jwt: string;
}

export type PasswordResetFormActions = undefined | "submit" | "validating";

export interface IPasswordResetProps {
    handler?: IPasswordResetHandler;
    jwt: string;
    globalUserId: number;
}
