import { message } from "antd";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { TabPermissions } from "types/enum";

import { APIError, showAPIErrorMessage } from "utilities/apiHandler";
import { getSelectedItems, setLoadingAction } from "utilities/form/form";
import { routes } from "utilities/routes";

import { btConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { showScheduleConflictAlert } from "commonComponents/entity/scheduleConflicts/ScheduleConflictAlert/ScheduleConflictAlert";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { withJobPrompt } from "commonComponents/helpers/JobPrompt/JobPrompt";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { openBackgroundProcessStatusPopup } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup";
import { ProcessStatusGroupType } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";

import {
    IJobFromTemplateHandler,
    JobFromTemplateHandler,
} from "entity/job/JobFromTemplate/JobFromTemplate.api.handler";
import { IScheduleHandler, ScheduleHandler } from "entity/schedule/Schedule/Schedule.api.handler";
import { SchedulePostSaveSteps } from "entity/schedule/Schedule/Schedule.api.types";
import {
    ITemplateImportHandler,
    TemplateImportHandler,
} from "entity/templateImport/TemplateImport/TemplateImport.api.handler";
import {
    CopyOptions,
    ITemplateImportFormValues,
    TemplateImportCountsResponse,
    TemplateImportFormActions,
    TemplateImportResponse,
} from "entity/templateImport/TemplateImport/TemplateImport.api.types";
import { ISchedulePostSaveState } from "entity/templateImport/TemplateImport/TemplateImport.types";
import { fireTemplateImportConflictsPrompt } from "entity/templateImport/TemplateImport/TemplateImportConflicts";
import { TemplateImportPresentational } from "entity/templateImport/TemplateImport/TemplateImportPresentational";

export interface ITemplateImportProps extends RouteComponentProps {
    jobId: number;
    tabId: TabPermissions;
    onSubmit?: () => void;
    modalConfig?: IModalConfiguration;
    handler?: ITemplateImportHandler;
    templateHandler?: IJobFromTemplateHandler;
    scheduleHandler?: IScheduleHandler;
    postImportData?: TemplateImportResponse;
    externalJobId?: string;
}

interface ITemplateImportState {
    templateId: number;
    templateList?: BTSelectItem[];
    counts?: TemplateImportCountsResponse;
    actionBeingPerformed: TemplateImportFormActions;
    canSendNotifications?: boolean;
    hasExistingEstimates?: boolean;
    canImportEstimates: boolean;
    hasBidsAddPerm: boolean;
    hasPOsAddPerm: boolean;
    hasEstimatesAddPerm: boolean;
}

function formValuesToCopyOptions(values: ITemplateImportFormValues): CopyOptions[] {
    const options: CopyOptions[] = [];

    // We only need to check selections, POs, Bills, estimates, and bid packages
    if (values.selections) {
        options.push(CopyOptions.Selections);
    }
    if (values.purchaseOrders) {
        options.push(CopyOptions.PurchaseOrders);
    }
    if (values.bills) {
        options.push(CopyOptions.Bills);
    }
    if (values.estimates) {
        options.push(CopyOptions.Estimates);
    }
    if (values.bidPackages) {
        options.push(CopyOptions.BidPackages);
    }

    return options;
}

class TemplateImportInternal extends Component<
    ITemplateImportProps,
    ITemplateImportState & ISchedulePostSaveState
> {
    static defaultProps = {
        handler: new TemplateImportHandler(),
        templateHandler: new JobFromTemplateHandler(),
        scheduleHandler: new ScheduleHandler(),
    };
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    state: Readonly<ITemplateImportState & ISchedulePostSaveState> = {
        templateId: -1,
        actionBeingPerformed: undefined,
        postSaveStep: SchedulePostSaveSteps.Hidden,
        subsToAdd: [],
        usersToAdd: [],
        subIdsToAddPermissions: [],
        userIdsToAddPermissions: [],
        scheduleIdsToCheck: [],
        schedulesToNotify: [],
        canImportEstimates: true,
        hasBidsAddPerm: true,
        hasPOsAddPerm: true,
        hasEstimatesAddPerm: true,
    };

    async componentDidMount() {
        if (this.props.postImportData) {
            this.setState({ templateList: [] });
            await setLoadingAction<TemplateImportFormActions>(this, {
                actionBeingPerformed: "importStarted",
                callback: async () => {
                    this.postImport(this.props.postImportData!, false);
                },
            });
        } else {
            try {
                const resp = await this.props.templateHandler!.getSetup(
                    undefined,
                    this.props.jobId,
                    this.props.externalJobId
                );

                if (this.props.externalJobId) {
                    await setLoadingAction<TemplateImportFormActions>(this, {
                        actionBeingPerformed: "templateChanged",
                        callback: async () => {
                            try {
                                let counts = await this.props.handler!.getExternalTemplateCounts(
                                    this.props.externalJobId!,
                                    this.props.jobId
                                );
                                const templates = resp.templates.filter(
                                    (t) => parseInt(t.id) !== -1
                                );
                                const selectedId = getSelectedItems(templates)[0].id;
                                this.setState({
                                    counts: counts,
                                    templateId: parseInt(selectedId) ?? -1,
                                });
                            } catch (e) {
                                showAPIErrorMessage(e);
                            }
                        },
                    });
                }
                this.setState({
                    templateList: resp.templates,
                    hasExistingEstimates: resp.hasExistingEstimates,
                    canImportEstimates: resp.canImportEstimates,
                    hasBidsAddPerm: resp.hasBidsAddPerm,
                    hasPOsAddPerm: resp.hasPOsAddPerm,
                    hasEstimatesAddPerm: resp.hasEstimatesAddPerm,
                });
            } catch (e) {
                // Close the modal on initial error
                if (this.props.modalConfig) {
                    showAPIErrorMessage(e);
                } else if (e instanceof APIError) {
                    // todo: clean up with webforms import links
                    // eslint-disable-next-line no-restricted-syntax
                    (window as any).top.btToastMessages.error(e.response.message);
                }
                this.finishImport();
            }
        }
    }

    private handleChangeTemplate = async (selectedId: number) => {
        await setLoadingAction<TemplateImportFormActions>(this, {
            actionBeingPerformed: "templateChanged",
            callback: async () => {
                try {
                    let resp;
                    if (selectedId !== -1) {
                        resp = await this.props.handler!.getCounts(selectedId, this.props.jobId);
                    }
                    this.setState({ counts: resp, templateId: selectedId });
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private handleStartImport = async (values: ITemplateImportFormValues) => {
        let promptVisible = false;
        await setLoadingAction<TemplateImportFormActions>(this, {
            actionBeingPerformed: "importStarted",
            callback: async () => {
                try {
                    const promptResponse = await fireTemplateImportConflictsPrompt({
                        jobId: values.templateId,
                        copyOptions: formValuesToCopyOptions(values),
                        isExistingJob: true,
                        lineItemType: "Template",
                        onContinue: () => {
                            void this.startImport(values);
                        },
                    });
                    promptVisible = promptResponse.showPrompt;
                    if (promptVisible) {
                        btConfirm(promptResponse.props!);
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
        if (!promptVisible) {
            void this.startImport(values);
        }
    };

    private startImport = async (values: ITemplateImportFormValues) => {
        await setLoadingAction<TemplateImportFormActions>(this, {
            actionBeingPerformed: "importStarted",
            callback: async () => {
                const { jobId, tabId, handler } = this.props;
                try {
                    const resp = await handler!.startImport(jobId, tabId, values);
                    this.postImport(resp, true);
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private postImport = (resp: TemplateImportResponse, showSuccessMessage: boolean) => {
        if (resp.backgroundJobId) {
            void message.success("Import Started");
            openBackgroundProcessStatusPopup(ProcessStatusGroupType.JobImportNew);
            this.finishImport();
            return;
        }

        if (showSuccessMessage) {
            void message.success("Import complete");
        }

        // Skip conflicts that don't involve the items just brought in.
        const skipConflicts =
            !resp.canViewConflicts ||
            resp.conflictsResponse.count === 0 ||
            resp.scheduleIdsAdded.length === 0 ||
            !resp.conflictsResponse.conflicts.some((u) =>
                u.conflicts.some((s) => resp.scheduleIdsAdded.includes(s.scheduleId))
            );

        if (skipConflicts || resp.isCalendarOffline) {
            this.showPromptToAddUsers(resp);
        } else {
            showScheduleConflictAlert(
                resp.conflictsResponse.conflicts,
                () => this.showPromptToAddUsers(resp),
                () => this.showConflicts(resp)
            );
        }
    };

    private showConflicts = (resp: TemplateImportResponse) => {
        this.setState({
            postSaveStep: SchedulePostSaveSteps.Conflicts,
            conflictData: resp.conflictsResponse,
            subsToAdd: resp.subsToAdd,
            usersToAdd: resp.usersToAdd,
            scheduleIdsToCheck: resp.scheduleIdsAdded,
            canSendNotifications: resp.canSendNotifications,
        });
    };

    private handleConflictsSkipped = () => {
        if (this.state.subsToAdd.length + this.state.usersToAdd.length > 0) {
            this.setState({ postSaveStep: SchedulePostSaveSteps.AddUsersToJob });
        } else {
            void this.showNotificationPrompt();
        }
    };

    private showPromptToAddUsers = (resp: TemplateImportResponse) => {
        if (resp.canAddUsers && resp.subsToAdd.length + resp.usersToAdd.length > 0) {
            this.setState({
                postSaveStep: SchedulePostSaveSteps.AddUsersToJob,
                subsToAdd: resp.subsToAdd,
                usersToAdd: resp.usersToAdd,
                scheduleIdsToCheck: resp.scheduleIdsAdded,
                canSendNotifications: resp.canSendNotifications,
            });
        } else {
            void this.showNotificationPrompt(resp.scheduleIdsAdded, resp.canSendNotifications);
        }
    };

    private handleUsersAddedToJob = (subIds: number[], userIds: number[]) => {
        if (subIds.length + userIds.length > 0) {
            this.setState({
                postSaveStep: SchedulePostSaveSteps.Permissions,
                subIdsToAddPermissions: subIds,
                userIdsToAddPermissions: userIds,
            });
        } else {
            void this.showNotificationPrompt();
        }
    };

    private showNotificationPrompt = async (
        scheduleIdsAdded?: number[],
        canSendNotifications?: boolean
    ) => {
        try {
            if (!(canSendNotifications || this.state.canSendNotifications)) {
                this.finishImport();
                return;
            }
            const resp = await this.props.scheduleHandler!.getPendingNotifications(
                this.props.jobId
            );
            const idsToCheck = scheduleIdsAdded || this.state.scheduleIdsToCheck;
            const schedules = resp.schedules.filter((s) => idsToCheck.includes(Number(s.id)));
            if (schedules.length > 0) {
                this.setState({
                    postSaveStep: SchedulePostSaveSteps.Notifications,
                    schedulesToNotify: schedules,
                });
            } else {
                this.finishImport();
            }
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private finishImport = () => {
        this.setState({
            postSaveStep: SchedulePostSaveSteps.Hidden,
        });

        if (this.props.onSubmit) {
            this.props.onSubmit();
        } else if (this.props.modalConfig) {
            this.props.modalConfig.beforeClose();
        }
    };

    render() {
        const isPostImport = this.props.postImportData !== undefined;

        if (!this.state.templateList) {
            return <BTLoading />;
        }

        const component = (
            <TemplateImportPresentational
                {...this.props}
                {...this.state}
                templateList={this.state.templateList}
                onChangeTemplate={this.handleChangeTemplate}
                onStartImport={this.handleStartImport}
                onConflictsSkipped={this.handleConflictsSkipped}
                onUsersAddedToJob={this.handleUsersAddedToJob}
                onUserPermissionsAdded={this.showNotificationPrompt}
                onNotificationsSent={this.finishImport}
                isPostImport={isPostImport}
                canChangeTemplate={!this.props.externalJobId}
            />
        );

        if (this.props.modalConfig && !isPostImport) {
            return (
                <BTModal
                    data-testid="btModalTemplateImportData"
                    visible
                    width="800px"
                    title="Import Data From Template"
                    beforeClose={this.props.modalConfig.beforeClose}
                    removeBodyPadding
                    useModalLayout
                >
                    {component}
                </BTModal>
            );
        }

        return component;
    }
}

const TemplateImportWithJobPicker = withJobPrompt(TemplateImportInternal, {
    routeTransform: (props) =>
        props.match.url +
        routes.templateImport.getDetailsLink(props.jobId, props.tabId) +
        props.location.search,
});

export const TemplateImport = withErrorBoundary(TemplateImportWithJobPicker)(
    "Could not load Template Import"
);
export default TemplateImport;
