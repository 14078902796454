import { List } from "antd";
import { ListProps } from "antd/lib/list";
import classNames from "classnames";
import { Component } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VList } from "react-virtualized";

import "./BTListVirtual.less";

interface IVirtualListProps<T> extends Omit<ListProps<T>, "renderItem"> {
    defaultRowHeight: number;
    width: number;
    renderItem: (item: T, index: number, style: React.CSSProperties) => React.ReactNode;
    idKey: keyof T;
}

interface IFooterRow {
    footerReservedWord: boolean; // Adding this as a prop that nobody else should use for type-checking to render the footer row
}

/**
 * @deprecated use `react-virtualized` instead (basically, we're no longer using this wrapper, but
 * using the library directly). It may help to look how BTListVirtual works and copy the internal
 * logic for your use case.
 */
export class BTListVirtual<T> extends Component<IVirtualListProps<T>> {
    private instanceOfIFooterRow(object: any): object is IFooterRow {
        return "footerReservedWord" in object;
    }

    private keyMapper = (rowIndex: number) => {
        if (!this.props.dataSource) {
            return null;
        }
        if (rowIndex >= this.props.dataSource.length) {
            // Index is greater than the main data length, so this must be the footer row since that
            return "footer";
        }
        return this.props.dataSource[rowIndex][this.props.idKey];
    };

    private cache = new CellMeasurerCache({
        defaultHeight: this.props.defaultRowHeight,
        fixedWidth: true,
        keyMapper: this.keyMapper,
    });

    private getVirtualList = () => {
        const { dataSource, footer, defaultRowHeight, width, renderItem } = this.props;

        const ds = dataSource as (T | IFooterRow)[] | undefined;
        const dataSourceWithFooter = footer && ds ? ds.concat({ footerReservedWord: true }) : ds;

        return (
            <AutoSizer disableWidth>
                {(sizerProps) => (
                    <VList
                        overscanRowCount={30}
                        height={sizerProps.height}
                        rowHeight={(x) => {
                            return this.cache.getHeight(x.index, 0);
                        }}
                        estimatedRowSize={defaultRowHeight}
                        rowCount={(dataSourceWithFooter && dataSourceWithFooter.length) || 0}
                        width={width}
                        rowRenderer={(props) => {
                            if (!dataSourceWithFooter) {
                                return (
                                    <CellMeasurer
                                        cache={this.cache}
                                        rowIndex={props.index}
                                        key={props.key}
                                        parent={props.parent}
                                    >
                                        {" "}
                                        <></>
                                    </CellMeasurer>
                                );
                            }
                            const item = dataSourceWithFooter[props.index];
                            if (
                                this.instanceOfIFooterRow(item) &&
                                item.footerReservedWord === true
                            ) {
                                return (
                                    <CellMeasurer
                                        cache={this.cache}
                                        rowIndex={props.index}
                                        key={props.key}
                                        parent={props.parent}
                                    >
                                        <div style={props.style}>{footer}</div>
                                    </CellMeasurer>
                                );
                            } else {
                                return (
                                    <CellMeasurer
                                        cache={this.cache}
                                        rowIndex={props.index}
                                        key={props.key}
                                        parent={props.parent}
                                    >
                                        {renderItem(item as T, props.index, props.style)}
                                    </CellMeasurer>
                                );
                            }
                        }}
                    />
                )}
            </AutoSizer>
        );
    };

    render() {
        const { dataSource } = this.props;
        const className = classNames("BTListVirtual", this.props.className);
        return (
            <List className={className}>
                {dataSource && dataSource.length > 0 && this.getVirtualList()}
            </List>
        );
    }
}
