import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { IInternalUserSettingsProps } from "entity/internalUser/InternalUserRoleSettings/InternalUserRoleList/InternalUserRoleList";

const InternalUserRoleList = lazyLoadWithRetry(
    () =>
        import(
            "entity/internalUser/InternalUserRoleSettings/InternalUserRoleList/InternalUserRoleList"
        )
);

export const RouteInternalUserRolesList = (
    props: Omit<
        IInternalUserSettingsProps,
        "showCustomFields" | "fromInternalUserId" | "currentTab" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.internalUsers.internalUserSettings}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const showCustomFields = routeValues.showCustomFields === "true";
            const fromInternalUserId = parseInt(routeValues.fromInternalUserId);
            const currentTab: InternalUserTabKeys = routeValues.currentTab;

            return (
                <BTSuspense>
                    <InternalUserRoleList
                        {...props}
                        {...routeProps}
                        showCustomFields={showCustomFields}
                        fromInternalUserId={fromInternalUserId}
                        currentTab={currentTab}
                    />
                </BTSuspense>
            );
        }}
    />
);
