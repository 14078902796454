import { createContext } from "react";

export interface IFullscreenContext {
    /**
     * The element that is flagged as full screen
     */
    fullscreenElement?: HTMLElement;
    setFullscreen: (element: HTMLElement | null | undefined) => void;
    isInFullscreen: () => boolean;
}

export const FullscreenContext = createContext<IFullscreenContext | undefined>(undefined);
