import memoizeOne from "memoize-one";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { PortalInfoContext } from "commonComponents/utilities/InlineOwnerPreview/PortalInfoContext";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IOwnerInvoiceProps } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice";
import { InlinePreviewOwnerInvoiceHandler } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice.api.handler";
import { OwnerInvoiceEntity } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice.api.types";

const OwnerInvoice = lazyLoadWithRetry(() => import("./OwnerInvoice"));

const PreviewOwnerInvoiceHandler = memoizeOne((ownerInvoice: OwnerInvoiceEntity) => {
    return new InlinePreviewOwnerInvoiceHandler(ownerInvoice);
});

export const RouteOwnerInvoice = (
    props: Omit<
        IOwnerInvoiceProps,
        "id" | "jobId" | "history" | "location" | "match" | "isPayOnline"
    >
) => (
    <RouteRelative
        path={routes.ownerInvoice.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <BuilderInfoContext.Consumer>
                        {(builderInfo) => (
                            <PortalInfoContext.Consumer>
                                {(portalInfo) => {
                                    let handler;
                                    if (portalInfo.currentPortal !== portalInfo.rootPortal) {
                                        handler = PreviewOwnerInvoiceHandler(props.ownerInvoice!);
                                    }
                                    return (
                                        <OwnerInvoice
                                            {...props}
                                            {...routeProps}
                                            modalConfig={{
                                                parentRoute: props.modalConfig
                                                    ? props.modalConfig.parentRoute
                                                    : "",
                                                beforeClose: () => {
                                                    props.onClose?.(routeProps);
                                                    props.modalConfig?.beforeClose();
                                                },
                                            }}
                                            id={parseInt(routeProps.match.params.id)}
                                            jobId={parseInt(routeProps.match.params.jobId)}
                                            isPayOnline={
                                                routeProps.match.params.payOnline === "true"
                                            }
                                            builderInfo={builderInfo}
                                            handler={handler}
                                        />
                                    );
                                }}
                            </PortalInfoContext.Consumer>
                        )}
                    </BuilderInfoContext.Consumer>
                </BTSuspense>
            );
        }}
    />
);
