import { GoogleMap, StandaloneSearchBox } from "@react-google-maps/api";
import { Button, Input } from "antd";
import { Component, createRef } from "react";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTIconFullscreenExitOutlined,
    BTIconWarningFilled,
} from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import CustomMark from "commonComponents/entity/map/common/CustomMark";
import LoadMapApi from "commonComponents/entity/map/common/mapApiContext/LoadMapApi";
import { IMapPosition, IMark } from "commonComponents/entity/map/Map.types";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import "./MapInput.less";

interface IMapInputProps {
    mapContainerStyle?: React.CSSProperties;
    center: IMapPosition;
    zoomLevel: number;
    readOnly: boolean;
    mark?: IMark;
    entityName?: string;
    onMarkSet: (newPosition: IMapPosition) => void;
    onMarkClear: () => void;
}

interface IMapInputState {
    mapContext?: google.maps.Map;
    searchContext?: google.maps.places.SearchBox;
    showInfoWindow: boolean;
    isPinLocked: boolean;
    showOverlay: boolean;
}

export class MapInput extends Component<IMapInputProps, IMapInputState> {
    static defaultProps = {
        mapContainerStyle: { height: 500 },
        readOnly: false,
        onMarkSet: () => {},
        onMarkClear: () => {},
    };

    state: Readonly<IMapInputState> = {
        mapContext: undefined,
        searchContext: undefined,
        showInfoWindow: false,
        isPinLocked: this.props.mark !== undefined,
        showOverlay: false,
    };

    private overlayDiv = createRef<HTMLDivElement>();
    private searchDiv = createRef<HTMLDivElement>();
    private helperDiv = createRef<HTMLDivElement>();

    private renderOverlay = (map: google.maps.Map) => {
        if (map) {
            this.setState({ mapContext: map });
        }
        if (map && this.overlayDiv.current) {
            map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(this.overlayDiv.current);
        }
        if (map && this.searchDiv.current) {
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.searchDiv.current);
        }
        if (map && this.helperDiv.current) {
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.helperDiv.current);
        }
        this.setState({ showOverlay: true });
    };

    private onBoundsChanged = () => {
        const { mapContext, searchContext } = this.state;
        if (mapContext && searchContext) {
            const bounds = mapContext.getBounds();
            if (bounds) {
                // bias search results to map window
                searchContext.setBounds(bounds);
            }
        }
    };

    private onPlaceSelected = () => {
        const { mapContext, searchContext } = this.state;
        if (mapContext && searchContext) {
            const places = searchContext.getPlaces();
            if (places?.length === 0) {
                return;
            }

            // Change map bounds
            let bounds = new google.maps.LatLngBounds();
            const zoomLevel = mapContext.getZoom();
            places!.forEach((p) => {
                if (p.geometry?.location) {
                    bounds.extend(p.geometry.location);
                }
            });
            mapContext.fitBounds(bounds);
            mapContext.setZoom(zoomLevel ?? 0);
        }
    };

    private recenterMap = () => {
        const { mark, center } = this.props;
        const { mapContext } = this.state;
        if (mapContext) {
            if (mark) {
                mapContext.panTo(mark.position);
            } else {
                mapContext.panTo(center);
            }
        }
    };

    render() {
        const { mapContainerStyle, center, zoomLevel, readOnly, mark, onMarkSet, onMarkClear } =
            this.props;
        const { isPinLocked, showOverlay } = this.state;

        return (
            <div className="MapInput">
                <LoadMapApi
                    loadingElement={
                        <div style={{ ...mapContainerStyle, position: "relative" }}>
                            <BTLoading displayMode="absolute" />
                        </div>
                    }
                    render={() => (
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={zoomLevel}
                            center={center}
                            onClick={
                                !isPinLocked
                                    ? (e: google.maps.MapMouseEvent) => {
                                          onMarkSet({
                                              lat: e.latLng!.lat(),
                                              lng: e.latLng!.lng(),
                                          });
                                      }
                                    : () => {}
                            }
                            onLoad={this.renderOverlay}
                            onBoundsChanged={this.onBoundsChanged}
                            options={{ streetViewControl: false, gestureHandling: "greedy" }}
                        >
                            {mark && (
                                <CustomMark
                                    key={mark.id}
                                    mark={mark}
                                    showInfoWindow={this.state.showInfoWindow}
                                    onMouseOver={this.onMarkMouseOver}
                                    onCloseClick={this.onInfoWindowCloseClick}
                                />
                            )}
                            <div ref={this.searchDiv}>
                                {showOverlay && !readOnly && (
                                    <div className="SearchDiv">
                                        <StandaloneSearchBox
                                            onLoad={(ref) => {
                                                this.setState({ searchContext: ref });
                                            }}
                                            onPlacesChanged={this.onPlaceSelected}
                                        >
                                            {/* eslint-disable-next-line react/forbid-elements */}
                                            <Input
                                                type="text"
                                                size="large"
                                                placeholder="Search Google Maps"
                                                height={44}
                                            />
                                        </StandaloneSearchBox>
                                    </div>
                                )}
                            </div>
                            <div ref={this.helperDiv}>
                                {showOverlay &&
                                    !readOnly &&
                                    !this.state.isPinLocked &&
                                    this.props.mark === undefined && (
                                        <div className="HelperDiv">
                                            <BTAlert
                                                type="warning"
                                                icon={<BTIconWarningFilled />}
                                                closable
                                                banner
                                                message="Click anywhere on map to place pin"
                                                data-testid="placePinOnMap"
                                            />
                                        </div>
                                    )}
                            </div>
                            <div ref={this.overlayDiv}>
                                {showOverlay && !readOnly && (
                                    <Button.Group className="OverlayButtons">
                                        <BTPopover content="Re-center map">
                                            <BTButton
                                                data-testid="center-map"
                                                className="IconButton"
                                                onClick={this.recenterMap}
                                                icon={<BTIconFullscreenExitOutlined rotate={45} />}
                                            />
                                        </BTPopover>
                                        {this.props.mark !== undefined && (
                                            <BTButton
                                                data-testid="clear-pin"
                                                onClick={() => {
                                                    this.setState({ isPinLocked: false });
                                                    onMarkClear();
                                                }}
                                            >
                                                Clear Pin
                                            </BTButton>
                                        )}
                                        {!this.state.isPinLocked && (
                                            <BTButton
                                                data-testid="lock-pin"
                                                onClick={() => this.setState({ isPinLocked: true })}
                                                disabled={mark === undefined}
                                            >
                                                Lock Pin
                                            </BTButton>
                                        )}
                                    </Button.Group>
                                )}
                            </div>
                        </GoogleMap>
                    )}
                />
            </div>
        );
    }

    private onMarkMouseOver = () => {
        this.setState({ showInfoWindow: true });
    };

    private onInfoWindowCloseClick = () => {
        this.setState({ showInfoWindow: false });
    };
}
