import { CheckCircleOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { SuccessColor } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconCheckCircleOutlinedSuccess: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return (
        <CheckCircleOutlined
            {...props}
            type="check-circle"
            style={{ ...props.style, color: SuccessColor }}
        />
    );
};
