import { Component } from "react";

import { externalPages } from "utilities/externalPages";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

interface IAcceptTermsAndPoliciesProps {
    onDismiss: () => void;
    onAccept: () => void;
}

export class AcceptTermsAndPoliciesPresentational extends Component<IAcceptTermsAndPoliciesProps> {
    render() {
        return (
            <BTModal
                data-testid="btModalAcceptTermsAndPolicies"
                visible
                centered
                closable={false}
                width="500px"
                beforeClose={this.props.onDismiss}
                title="New Terms of Use and Privacy Policy"
            >
                <BTRow>
                    <BTCol>
                        {"We've recently made some updates to our "}
                        <BTExternalLink
                            href={externalPages.BuildertrendTermsOfUse}
                            target="_blank"
                            isUnderline={true}
                        >
                            terms of use
                        </BTExternalLink>
                        {" and "}
                        <BTExternalLink
                            href={externalPages.BuildertrendPrivacyPolicy}
                            target="_blank"
                            isUnderline={true}
                        >
                            privacy policy
                        </BTExternalLink>
                    </BTCol>
                </BTRow>
                <BTRow className="margin-top-md" justify="space-between">
                    <BTCol />
                    <BTCol>
                        <BTButton
                            id="accept"
                            data-testid="accept"
                            type="primary"
                            actionBeingPerformed="accept"
                            onClick={this.props.onAccept}
                        >
                            OK
                        </BTButton>
                    </BTCol>
                </BTRow>
            </BTModal>
        );
    }
}
