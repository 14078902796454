/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */

/**
 * 0 = -- All Users, Owners, Subs/Vendors, Leads --

1 = Internal Users

2 = Owners

3 = Subs/Vendors

4 = Leads

5 = BTAdmin

7 = CONTACT


 */
export type BTLoginTypes = (typeof BTLoginTypes)[keyof typeof BTLoginTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BTLoginTypes = {
    ALL: 0,
    BUILDER: 1,
    OWNER: 2,
    SUBS: 3,
    LEAD: 4,
    BTADMIN: 5,
    CONTACT: 7,
} as const;
