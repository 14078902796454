import { useMemo } from "react";

import {
    IBTIconDeprecatedProps,
    IBTIconProps,
    IBTIconPropsInternal,
    IconSize,
} from "commonComponents/btWrappers/BTIcon/BTIcon.types";

/**
 * Converts the provide t-shirt IconSize to a value in pixels
 * @param size The size prop to convert
 * @default "auto"
 */
// TODO: remove export and make this private
export const getSizeInPixels = (size: IconSize | undefined): number | undefined => {
    if (typeof size === "number") {
        return size;
    }

    let componentSize = size;
    if (componentSize === undefined) {
        componentSize = "auto";
    }

    switch (componentSize) {
        case "small":
            return 10;

        case "auto":
            /* font size is based off the context of the component. (eg: <Button size="large" icon={<BTIcon type="cloud" />} /> would use a larger icon) */
            return undefined;

        case "large":
            return 24;

        case "extraLarge":
            return 32;

        default:
            throw new Error(`BTIcon - not a valid size ${size}`);
    }
};

export function useBaseIcon(
    props: IBTIconProps,
    iconName?: string
): [IBTIconPropsInternal & IBTIconDeprecatedProps] {
    const internalProps = useMemo<IBTIconPropsInternal>(() => {
        const result = {
            ...props,
            "data-icon-name": iconName ?? "BTIcon",
        };
        const fontSize = getSizeInPixels(props.size);
        if (fontSize !== undefined || props.style) {
            result.style = {
                ...props.style,
                fontSize: getSizeInPixels(props.size),
            };
        }
        return result;
    }, [props, iconName]);
    return [internalProps];
}

export function useAntIcon(
    props: IBTIconProps,
    iconName?: string
): [IBTIconPropsInternal & IBTIconDeprecatedProps] {
    return useBaseIcon(props, iconName);
}
