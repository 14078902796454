export enum SurveyListContainerTabs {
    Individual = 1,
    Grouped = 2,
    Questions = 3,
}

export type SurveyListFormActions = "newSurvey" | "print" | "import" | "checkedAction" | undefined;

export interface ISurveyListParams {
    tab?: number;
}
