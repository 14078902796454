import { Component } from "react";

import { IPortalType, isInPortal, PortalType } from "utilities/portal/portal";

import { PortalInfoContext } from "commonComponents/utilities/InlineOwnerPreview/PortalInfoContext";

export interface IShowOnPortalProps {
    /** @default false - defaults to hidden */
    builder: boolean;

    /** @default false - defaults to hidden */
    subs: boolean;

    /** @default false - defaults to hidden */
    owner: boolean;

    /** @default false - defaults to hidden */
    btadmin: boolean;

    children?: never;

    render: (portalType: IPortalType) => React.ReactNode;
}

/**
 * Shows or hides the content of this component on specific portals
 * @see <HideOnPortal>, isInPortal()
 * @example
 * <ShowOnPortal owner render={() => <>Shown only to owners</>} />
 * @example
 * <ShowOnPortal builder subs render={() => <>Shown to builder/subs</>}>
 */
export class ShowOnPortal extends Component<IShowOnPortalProps> {
    static defaultProps = {
        builder: false,
        subs: false,
        owner: false,
        btadmin: false,
    };

    render() {
        return (
            <PortalInfoContext.Consumer>
                {(context) => {
                    if (context.currentPortal === context.rootPortal) {
                        if (isInPortal(this.props)) {
                            return this.props.render({
                                isBuilder: isInPortal({ builder: true }),
                                isSubs: isInPortal({ subs: true }),
                                isOwner: isInPortal({ owner: true }),
                                isBtadmin: isInPortal({ btadmin: true }),
                            });
                        }
                        return null;
                    } else {
                        if (
                            (this.props.owner && context.currentPortal === PortalType.OWNER) ||
                            (this.props.builder && context.currentPortal === PortalType.BUILDER) ||
                            (this.props.subs && context.currentPortal === PortalType.SUBS) ||
                            (this.props.btadmin && context.currentPortal === PortalType.BTADMIN)
                        ) {
                            return this.props.render({
                                isBuilder: context.currentPortal === PortalType.BUILDER,
                                isSubs: context.currentPortal === PortalType.SUBS,
                                isOwner: context.currentPortal === PortalType.OWNER,
                                isBtadmin: context.currentPortal === PortalType.BTADMIN,
                            });
                        }
                        return null;
                    }
                }}
            </PortalInfoContext.Consumer>
        );
    }
}
