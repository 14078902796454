import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { INotificationUnfollowProps } from "entity/notification/NotificationUnfollow/NotificationUnfollow";

const NotificationUnfollow = lazyLoadWithRetry(() => import("./NotificationUnfollow"));

export const RouteNotificationUnfollow = (
    props: Omit<
        INotificationUnfollowProps,
        "notificationId" | "builderId" | "history" | "match" | "location"
    >
) => (
    <RouteRelative
        path={routes.notifications.preferences}
        render={(routeProps) => {
            const notificationId = parseInt(routeProps.match.params.notificationId);
            const builderId = parseInt(routeProps.match.params.builderId);

            return (
                <BTSuspense>
                    <NotificationUnfollow
                        {...props}
                        {...routeProps}
                        notificationId={notificationId}
                        builderId={builderId}
                    />
                </BTSuspense>
            );
        }}
    />
);
