import { Row, RowProps } from "antd";
import classnames from "classnames";
import { Component, forwardRef } from "react";
import { ContainerQuery } from "react-container-query";
import { Query } from "react-container-query/lib/interfaces";
import { ScreenMin } from "styles/antTheme/Variables";

import "./BTRow.less";

const query: Query = {
    "BTRow-xs": {
        minWidth: ScreenMin.xs,
    },
    "BTRow-sm": {
        minWidth: ScreenMin.sm,
    },
    "BTRow-md": {
        minWidth: ScreenMin.md,
    },
    "BTRow-lg": {
        minWidth: ScreenMin.lg,
    },
    "BTRow-xl": {
        minWidth: ScreenMin.xl,
    },
    "BTRow-xxl": {
        minWidth: ScreenMin.xxl,
    },
};

type ResponsiveMode = "viewport" | "container";

// These props are based off AntDesign's v4 Row (https://github.com/ant-design/ant-design/blob/master/components/grid/row.tsx)
// Do not change this without consulting architecture first
export interface IBTRowProps extends Omit<RowProps, "type"> {
    /**
     * Specify whether to use the parent container, or the viewport for the responsive width. Only use the viewport if the item is not opening in a modal.
     * Added for performance purposes with very very large lists where calculating width happens for each item added, not needed in standard cases. \
     * @default container
     */
    responsiveMode?: ResponsiveMode;
}

interface IBTRowInternalProps extends IBTRowProps {
    innerRef?:
        | ((instance: HTMLDivElement | null) => void)
        | React.MutableRefObject<HTMLDivElement | null>
        | null;
}

class BTRowInternal extends Component<IBTRowInternalProps> {
    static defaultProps = {
        responsiveMode: "container",
    };

    render() {
        const { className, responsiveMode, innerRef, ...otherProps } = this.props;
        if (this.props.responsiveMode === "viewport") {
            // eslint-disable-next-line react/forbid-elements
            return <Row {...otherProps} ref={innerRef} className={className} />;
        }

        return (
            <ContainerQuery query={query}>
                {(params) => {
                    return (
                        // eslint-disable-next-line react/forbid-elements
                        <Row
                            ref={innerRef}
                            className={classnames(params, className)}
                            {...otherProps}
                        />
                    );
                }}
            </ContainerQuery>
        );
    }
}

export const BTRow = forwardRef<HTMLDivElement, IBTRowProps>((props, ref) => (
    <BTRowInternal innerRef={ref} {...props} />
));
