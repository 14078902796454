// eslint-disable-next-line no-restricted-imports
import { SortDescriptor } from "@progress/kendo-data-query";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { DirectionTypes, DirectionTypeStrings } from "types/enum";

import { ListEntityType } from "utilities/list/list.types";

import { IGridSettingsHandler } from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.handler";
import { GridColumn } from "commonComponents/utilities/Grid/GridContainer.types";

export type GridSettingsActions = undefined | "apply" | "saveAsNew" | "update" | "delete";

export const savedViewWidth = 200;

export interface IGridSettingsFormValues {
    isDefault: boolean;
    isPrivate: boolean;
    viewName: string;
    columns: (string | number)[];
    savedView: number;
}

export interface IHasSavedViews {
    /**
     * List entity type
     */
    entityType: ListEntityType;
    /**
     * Grid settings handler
     */
    gridSettingsHandler?: IGridSettingsHandler;
}

export interface IHasSavedViewsState {
    selectedGridView?: GridViewItem;
    gridViews?: BTSelectItem<GridViewItem>[];
}

export class GridViewsResponse {
    constructor(data: any) {
        this.defaultItem = new GridViewItem(data.defaultItem);

        if (data.items.length > 0 && data.items[0] instanceof BTSelectItem) {
            this.items = data.items;
        } else {
            this.items = data.items.map(
                (view: any) =>
                    new BTSelectItem({
                        name: view.viewName,
                        id: view.viewId,
                        value: view.viewId,
                        extraData: new GridViewItem(view),
                    })
            );
        }

        if (data.allGridColumns) {
            this.allGridColumns = data.allGridColumns.map(
                (col: GridColumn<unknown>) => new GridColumn(col)
            );
        } else {
            this.allGridColumns = [];
        }
    }

    defaultItem: GridViewItem;
    items: BTSelectItem<GridViewItem>[];
    allGridColumns: GridColumn<unknown>[];
}

export class GridViewItem {
    constructor(data: any) {
        this.viewId = data.viewId;
        this.viewName = data.viewName;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.isDefault = data.isDefault;
        this.isSystemDefault = data.isSystemDefault;
        this.isPrivate = data.isPrivate;
        this.canPrivate = data.canPrivate;
        this.columns = data.columns.map((col: any) => new GridViewColumn(col));
        this.displayColumns = data.columns.map((col: any) => new GridViewColumn(col));
        this.pageSize = data.defaultPageSize;
        this.sort = [
            {
                field: data.sortColumnId,
                dir:
                    data.sortDirection !== DirectionTypes.DEFAULT
                        ? DirectionTypeStrings.get(data.sortDirection)
                        : undefined,
            },
        ];
        this.isCustom = data.isCustom;
        this.shouldResetColumnConfigs = false;
    }

    viewId: number;
    viewName: string;
    canEdit: boolean;
    canDelete: boolean;
    isPrivate: boolean;
    canPrivate: boolean;
    isDefault: boolean;
    isSystemDefault: boolean;
    columns: GridViewColumn[];
    displayColumns: GridViewColumn[];
    pageSize: number;
    sort: SortDescriptor[];
    isCustom: boolean;
    shouldResetColumnConfigs?: boolean;
}

export class GridViewColumn {
    constructor(data: any) {
        this.id = data.id;
        this.width = data.width;
        this.isFrozen = data.isFrozen;
    }
    id: string;
    width: number;
    isFrozen: boolean;
}

export class GridViewSavedResponse {
    constructor(data: any) {
        this.view = new GridViewItem(data.view);
    }
    view: GridViewItem;
}

export interface IGridViewSaveRequest {
    viewName: string;
    columns: GridViewColumn[];
    isPrivate: boolean;
    isDefault: boolean;
    sortDirection?: "asc" | "desc";
    sortColumnId?: string;
    pageSize: number;
    gridType: ListEntityType;
    columnListIds: string[];
}

export class GridViewDeleteResponse {}

export class SetDefaultViewResponse {}
