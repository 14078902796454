declare type SectionColor = "green" | "yellow" | "blue" | "dark-blue" | "red" | "teal" | "";
interface IPercentageSectionData {
    color: SectionColor;
    label: string;
    labelClassName?: string;
    value?: number | undefined;
    percentage: number;
    striped?: boolean | undefined;
    isOutstanding?: boolean | undefined;
    showValueAsLabel?: boolean;
    hasBoldLabel?: boolean;
}

export class PercentageSection {
    constructor(data: IPercentageSectionData) {
        this.color = data.color;
        this.label = data.label;
        this.labelClassName = data.labelClassName ?? "";
        this.value = data.value;
        this.percentage = data.percentage;
        this.striped = data.striped ?? false;
        this.isOutstanding = data.isOutstanding ?? false;
        this.showValueAsLabel = data.showValueAsLabel ?? false;
        this.hasBoldLabel = data.hasBoldLabel ?? false;
    }

    color: SectionColor;
    label: string;
    labelClassName?: string;
    value: number | undefined;
    percentage: number;
    striped: boolean;
    isOutstanding: boolean;
    showValueAsLabel?: boolean;
    hasBoldLabel?: boolean;
}
