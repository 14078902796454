import moment from "moment";

export enum RfiStatus {
    NotReleased = 1,
    Released = 2,
    Completed = 3,
    Reopened = 4,
}

export class DiscussionRfiEntity {
    constructor(data: any) {
        this.assignedTo = data.assignedTo;
        this.dateStatus = data.dateStatus;
        this.formattedStatus = data.formattedStatus;
        this.id = data.id;
        this.previewText = data.previewText;
        this.question = data.question;
        this.responseCount = parseInt(data.responseCount);
        if (isNaN(this.responseCount)) {
            this.responseCount = 0; // Services return "--" if unreleased RFI and there are none. Don't want to break mobile, so replacing it with 0 here.
        }
        this.statusIcon = data.statusIcon;
        this.statusIconText = data.statusIconText;
        this.statusText = data.statusText;
        this.title = data.title;
        this.status = data.status;
        this.dueDate = moment(data.dueDateRaw);
        this.statusLastUpdated = data.statusLastUpdated
            ? moment(data.statusLastUpdated)
            : undefined;
    }

    assignedTo: string;
    dateStatus: string;
    formattedStatus: string;
    id: number;
    previewText: string;
    question: string;
    responseCount: number;
    statusIcon: string;
    statusIconText: string;
    statusText: string;
    title: string;
    status: RfiStatus;
    dueDate: moment.Moment;
    statusLastUpdated?: moment.Moment;
}
