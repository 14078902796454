import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISelectionProps } from "entity/selection/Selection/Selection";

const Selection = lazyLoadWithRetry(() => import("./Selection"));

export const RouteSelection = (
    props: Omit<ISelectionProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.selection.details}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search);
            let lockingChoiceId: number | undefined;
            if (qsValues.lockingChoiceId) {
                lockingChoiceId = parseInt(qsValues.lockingChoiceId);
            }

            return (
                <BTSuspense>
                    <Selection
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                        lockingSelectionChoiceId={lockingChoiceId}
                    />
                </BTSuspense>
            );
        }}
    />
);
