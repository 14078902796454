import { AnalyticsEvent } from "utilities/analytics/analytics";
import { isNullOrUndefined } from "utilities/object/object";
import { isNullOrWhitespace } from "utilities/string/string";

// Returns the name part OR '?' if not present
const getEventPart = (partName: string) => {
    return !isNullOrWhitespace(partName) ? partName : "?";
};

// Returns whether we should send the event to Appcues/Qualtrics
export function isEventWhitelisted(translatedEventName: string, whitelist: string[]) {
    return whitelist.indexOf(translatedEventName) >= 0;
}

// Translates a frontend event into what it will be called in Appcues/Qualtrics
export function translateEventName(eventParams: any) {
    let translatedEventName = getEventPart(eventParams.payload.event);
    translatedEventName += `|${getEventPart(eventParams.payload.properties.page)}`;

    // NOTE: If you change any existing translated event logic, make sure to also update the
    //       whitelist in 'isEventWhitelisted()' to reflect your changes.
    switch (eventParams.payload.event as AnalyticsEvent) {
        // Add special cases for specific events here
        default:
            translatedEventName += `|${getEventPart(eventParams.payload.properties.component)}`;
            translatedEventName += `|${getEventPart(eventParams.payload.properties.uniqueId)}`;
            break;
    }

    return translatedEventName;
}

export const translateRouteParams = (routeParams?: any) => {
    // if it's not defined, just return a new object so downstream doesn't blow up
    if (isNullOrUndefined(routeParams)) {
        return {};
    }
    const { jobsiteId, id, ...rest } = routeParams;
    const translatedParams = { ...rest };
    if (jobsiteId !== undefined) {
        // remap jobsiteId => jobId
        translatedParams["jobId"] = jobsiteId;
    }

    if (id !== undefined) {
        // remap id => entityId
        translatedParams["entityId"] = id;
    }

    return translatedParams;
};
