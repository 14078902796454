import Icon from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import SVG from "images/Close.svg?react";

import "./BTIconCloseOutlined.less";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

interface IBTIconCloseOutlinedProps extends IBTIconProps {
    isOnDark?: boolean;
    /**
     * Pass false to use this icon without baked in colors. Default is true
     */
    isDefaultColoring?: boolean;
}

export const BTIconCloseOutlined: FunctionComponent<IBTIconCloseOutlinedProps> = (baseProps) => {
    const { isOnDark, isDefaultColoring = true, ...iconProps } = baseProps;
    const [props] = useBaseIcon(iconProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon
            {...props}
            component={SVG}
            className={classNames(
                { BTIconCloseOutlined: isDefaultColoring, isOnDark },
                props.className
            )}
        />
    );
};
