import { Empty } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import Table, { TableProps } from "antd/lib/table";
import {
    CompareFn,
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
    TableRowSelection,
} from "antd/lib/table/interface";
import classNames from "classnames";
import { isEqual } from "lodash-es";
import { SummaryCellProps } from "rc-table/lib/Footer/Cell";
import { FooterRowProps } from "rc-table/lib/Footer/Row";
import {
    CellType,
    GetComponentProps,
    RenderedCell,
    RenderExpandIcon,
    RenderExpandIconProps,
    RowClassName,
} from "rc-table/lib/interface";
import { Component, createRef, isValidElement, useRef } from "react";
import { Resizable } from "react-resizable";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { isNullOrUndefined } from "utilities/object/object";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCaretRightOutlined } from "commonComponents/btWrappers/BTIcon";
import {
    BTColumnsType,
    BufferColumnKey,
    IBTColumnGroupType,
    IBTColumnType,
} from "commonComponents/btWrappers/BTTable/BTTable.types";
import { useStickyTotalSize } from "commonComponents/utilities/StickyContext";
import { StickyContext } from "commonComponents/utilities/StickyContext/StickyContext";

import "./BTTable.less";

export interface ISummaryCell extends SummaryCellProps {
    key: string;
}

interface ISummaryRow extends FooterRowProps {
    cells: ISummaryCell[];
    className?: string;
    key: string;
}

export interface IStickyFooter {
    rows: ISummaryRow[];
}

export interface IBTTableProps<T> extends Omit<TableProps<T>, "sticky" | "columns"> {
    tableFooter?: JSX.Element | { children?: any; props: IBTColumnProps<T> }[];
    footerClass?: string;
    striped?: boolean;
    emptyText?: string;
    emptyState?: boolean;
    collapseBorders?: boolean;
    backgroundInherit?: boolean;

    /**
     * Enables sticky header on the table header.
     *
     * The table header will stick to the bottom of the
     * current sticky layer stack in StickyHeaderContext.
     *
     * @default false
     */
    sticky?: boolean | { topLevel?: number; bottomLevel?: number };
    columns?: BTColumnsType<T>;
    onColumnResize?: (index: number, width: number) => void;
    stickyFooter?: IStickyFooter;
}

export interface IBTColumnProps<T> extends IBTColumnType<T> {
    "data-testid"?: string;
}

interface IBTTableInternalProps<T> extends IBTTableProps<T> {
    offsetHeader?: number;
    offsetScroll?: number;
}

interface IResizableColumnHeaderProps
    extends React.DetailedHTMLProps<
        React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
        HTMLTableHeaderCellElement
    > {
    width?: number;
    onResizeStop?: (newWidth: number) => void;
    isResizable: boolean;
    tableContainerRef: React.RefObject<HTMLDivElement>;
    columnIndex: number;
    lastColumn: boolean;
    minWidth?: number;
}

const ResizableHeader: React.FunctionComponent<IResizableColumnHeaderProps> = (props) => {
    const startPosRef = useRef<number>(0);
    const handleRef = useRef<HTMLButtonElement>(null);
    const thRef = useRef<HTMLTableHeaderCellElement>(null);

    const {
        isResizable,
        width,
        onResizeStop,
        tableContainerRef,
        lastColumn,
        minWidth,
        columnIndex: _columnIndex, // toss columnIndex
        ...rest
    } = props;

    const initialWidth = width ?? 0;
    if (!isResizable) {
        return <th {...rest} />;
    }

    const handleResizeStart = (e: React.SyntheticEvent<Element, Event>) => {
        if (e.type === "mousedown" || e.type === "touchstart") {
            if (e.type === "mousedown") {
                startPosRef.current = (e.nativeEvent as MouseEvent).x;
            } else {
                startPosRef.current =
                    (e as unknown as TouchEvent).touches.item(0)?.clientX ?? startPosRef.current;
            }
            if (handleRef?.current) {
                const tblHolder = tableContainerRef.current!.querySelector(
                    ".ant-table-sticky-holder"
                ) as HTMLDivElement;
                handleRef.current.style.position = "fixed";
                handleRef.current.style.height = tableContainerRef.current
                    ? `${tableContainerRef.current!.clientHeight}px`
                    : "100%";
                handleRef.current.style.left = `${startPosRef.current}px`;
                handleRef.current.style.top = `${tblHolder.getBoundingClientRect().y}px`;
                handleRef.current.style.opacity = "1";
                handleRef.current.style.width = "4px";
                tblHolder && (tblHolder.style.overflowX = "clip");
            }
        }
    };

    const handleResizeStop = (e: React.SyntheticEvent<Element, Event>) => {
        if (
            isResizable &&
            (e.type === "mouseup" || e.type === "touchend" || e.type === "touchcancel") &&
            onResizeStop
        ) {
            let releasePos: number;
            switch (e.type) {
                case "mouseup": {
                    releasePos = (e as unknown as MouseEvent).x;
                    break;
                }
                case "touchend": {
                    releasePos =
                        (e as unknown as TouchEvent).changedTouches.item(0)?.clientX ??
                        startPosRef.current;
                    break;
                }
                default: {
                    releasePos = startPosRef.current;
                    break;
                }
            }
            const widthChange = releasePos - startPosRef.current;
            const newWidth = Math.max(thRef.current!.clientWidth + widthChange, minWidth ?? 0);
            if (handleRef?.current) {
                handleRef.current.style.position = "absolute";
                handleRef.current.style.opacity = "";
                handleRef.current.style.height = "100%";
                handleRef.current.style.right = `0px`;
                handleRef.current.style.left = "";
                handleRef.current.style.top = "";
                handleRef.current.style.width = "";
                const tblHolder = tableContainerRef.current!.querySelector(
                    ".ant-table-sticky-holder"
                ) as HTMLDivElement;
                tblHolder && (tblHolder.style.overflow = "hidden");
                if (newWidth <= tblHolder.clientWidth) {
                    // Columns have been resized to fit within table container.
                    // Need to remove any fixed column box-shadows that may still be displayed.
                    const tblDiv = tableContainerRef.current!.querySelector(
                        ".ant-table"
                    ) as HTMLDivElement;
                    tblDiv.classList.remove("ant-table-ping-right");
                    tblDiv.classList.remove("ant-table-ping-left");
                }
            }
            onResizeStop(newWidth);
        }
    };

    const handleResize = (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        if (handleRef?.current) {
            let curPos: number;
            switch (e.type) {
                case "mousemove": {
                    curPos = (e as unknown as MouseEvent).x;
                    break;
                }
                case "touchmove": {
                    curPos =
                        (e as unknown as TouchEvent).changedTouches.item(0)?.clientX ??
                        startPosRef.current;
                    break;
                }
                default: {
                    curPos = startPosRef.current;
                    break;
                }
            }
            const widthChange = curPos - startPosRef.current;
            const newWidth = thRef.current!.clientWidth + widthChange;
            if (minWidth && newWidth < minWidth) {
                const widthDiff = minWidth - newWidth;
                curPos += widthDiff;
            }
            handleRef.current.style.left = `${curPos}px`;
        }
    };

    return (
        <Resizable
            width={initialWidth}
            height={0}
            handle={
                <span>
                    {
                        // eslint-disable-next-line react/forbid-elements
                        <button
                            className={classNames("BTTable--resizable-header-handle", {
                                "BTTable--resizable-header-handle-last": lastColumn,
                            })}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                            ref={handleRef}
                        />
                    }
                </span>
            }
            draggableOpts={{ enableUserSelectHack: false }}
            onResizeStart={handleResizeStart}
            onResizeStop={handleResizeStop}
            onResize={handleResize}
        >
            <th {...rest} ref={thRef} />
        </Resizable>
    );
};

@track((props) => ({
    component: "Table",
    uniqueId: props["data-testid"],
}))
class BTTableInternal<T extends {}> extends Component<IBTTableInternalProps<T> & ITrackingProp> {
    static defaultProps = {
        sticky: false,
    };

    tableContainerRef = createRef<HTMLDivElement>();
    static contextType = StickyContext;
    context!: React.ContextType<typeof StickyContext>;

    private setStickyHeaderColumnWidths = () => {
        const maxColWidths = this.getColumnWidthsByLargestCell(
            this.getStickyHeaderTableColumnWidths(),
            this.getStickyContentTableColumnWidths()
        );

        this.setStickyHeaderTableColumnWidths(maxColWidths);
        this.setStickyContentTableColumnWidths(maxColWidths);
    };

    private getStickyHeaderTableColumnWidths = () => {
        let headerColWidths: number[] = [];
        const nestedCols = [
            ...(this.tableContainerRef.current?.querySelectorAll(
                ".ant-table-header table tr .ant-table-cell.NestedColumn"
            ) ?? []),
        ];
        let nestedIndex = 0;
        let index = 0;
        this.tableContainerRef.current
            ?.querySelectorAll(".ant-table-header table tr .ant-table-cell:not(.NestedColumn)")
            .forEach((element) => {
                if (element.classList.contains("GroupedColumn")) {
                    headerColWidths[index + nestedIndex] = (
                        nestedCols[nestedIndex] as HTMLTableElement
                    ).offsetWidth;
                    nestedIndex++;
                    headerColWidths[index + nestedIndex] = (
                        nestedCols[nestedIndex] as HTMLTableElement
                    ).offsetWidth;
                    nestedIndex++;
                } else {
                    headerColWidths[index + nestedIndex] = (
                        element as HTMLTableElement
                    ).offsetWidth;
                    index++;
                }
            });
        return headerColWidths;
    };

    private getStickyContentTableColumnWidths = () => {
        let contentColWidths: number[] = [];
        (this.tableContainerRef.current?.querySelector(".ant-table-body table") as HTMLDivElement)
            .querySelectorAll("tr .ant-table-cell")
            .forEach((element, index) => {
                contentColWidths[index] = (element as HTMLTableDataCellElement).offsetWidth;
            });
        return contentColWidths;
    };

    private getColumnWidthsByLargestCell = (
        headerColumnWidths: number[],
        contentColumnWidths: number[]
    ) => {
        return headerColumnWidths.map((item, index) => Math.max(item, contentColumnWidths[index]));
    };

    private setStickyHeaderTableColumnWidths = (maxColWidths: number[]) => {
        let index = 0;
        const nestedColMaxWidths: number[] = [];
        this.tableContainerRef.current
            ?.querySelectorAll(".ant-table-header table tr .ant-table-cell:not(.NestedColumn)")
            .forEach((element) => {
                if (element.classList.contains("GroupedColumn")) {
                    nestedColMaxWidths.push(maxColWidths[index]);
                    index++;
                    nestedColMaxWidths.push(maxColWidths[index]);
                    index++;
                } else {
                    (element as HTMLTableElement).style.minWidth = maxColWidths[index] + "px";
                    index++;
                }
            });

        this.tableContainerRef.current
            ?.querySelectorAll(".ant-table-header table tr .ant-table-cell.NestedColumn")
            .forEach((element, index) => {
                (element as HTMLTableElement).style.minWidth = nestedColMaxWidths[index] + "px";
            });
    };

    private setStickyContentTableColumnWidths = (maxColWidths: number[]) => {
        (this.tableContainerRef.current?.querySelector(".ant-table-body table") as HTMLDivElement)
            .querySelectorAll("tr .ant-table-cell")
            .forEach((element, index) => {
                (element as HTMLTableCellElement).style.minWidth = maxColWidths[index] + "px";
            });
    };

    private resizeStickyHeaderColumnWidths = () => {
        (this.tableContainerRef.current?.querySelector(".ant-table-header table") as HTMLDivElement)
            .querySelectorAll("tr .ant-table-cell")
            .forEach((element) => {
                (element as HTMLTableElement).style.minWidth = "";
            });
        (this.tableContainerRef.current?.querySelector(".ant-table-body table") as HTMLDivElement)
            .querySelectorAll("tr .ant-table-cell")
            .forEach((element) => {
                (element as HTMLTableCellElement).style.minWidth = "";
            });
        this.setStickyHeaderColumnWidths();
    };

    private removeCheckBoxFromFooterRowWithRowSelection = (
        rowSelection: TableRowSelection<T>
    ): TableRowSelection<T> => {
        const { getCheckboxProps, ...rest } = rowSelection;

        return {
            getCheckboxProps: (record: T) => ({
                // footer row should be empty object
                style: this.isEmptyObjectRepresentingFooter(record)
                    ? { display: "none" }
                    : getCheckboxProps
                    ? (
                          getCheckboxProps(record) as Partial<
                              Omit<CheckboxProps, "checked" | "defaultChecked">
                          >
                      ).style
                    : undefined,
            }),
            ...rest,
        };
    };

    private getRowClassName = (
        record: T,
        index: number,
        dataSource?: readonly T[],
        footerClass?: string,
        striped?: boolean,
        rowClassName?: string | RowClassName<T>
    ) => {
        let className = striped && index % 2 ? "BTTable--alternateBackground" : "";

        if (dataSource && dataSource.length === index && footerClass) {
            className = `${className} ${footerClass}`;
        }

        // if a rowClassName was provided as a prop to BTTable, append that class name to the resulting class name.
        if (rowClassName) {
            const label =
                typeof rowClassName === "function" ? rowClassName(record, index, 0) : rowClassName;
            className = `${className} ${label}`;
        }

        return className;
    };

    private getTableClassNames = () => {
        const { collapseBorders, backgroundInherit, className, columns } = this.props;

        return classNames(className, {
            "BTTable--collapsedBorders": collapseBorders,
            "BTTable--backgroundInherit": backgroundInherit,
            "BTTable--resizable": columns?.some((c) => c.resizeable),
        });
    };

    private isEmptyObjectRepresentingFooter = (obj: T) => {
        // Object.keys(obj).length check tells us if the object has any props. If it's an empty object, then
        // it is the footer that our code adds to the ant d Table (it is really a row)
        return Object.keys(obj).length === 0;
    };

    private getExpandIcon: RenderExpandIcon<T> = (props: RenderExpandIconProps<T>) => {
        const { expandIcon, expandable } = this.props;
        const { expanded, onExpand, record, expandable: isRowExpandable } = props;

        if (!isRowExpandable) {
            return undefined;
        }

        if (expandIcon) {
            return expandIcon(props);
        }

        if (expandable?.expandIcon) {
            return expandable.expandIcon(props);
        }

        return (
            <BTButton
                type="link"
                isolated
                data-testid="expandRowButton"
                className="expandRowButton"
                onClick={(e: React.MouseEvent<HTMLElement>) => onExpand(record, e)}
                icon={<BTIconCaretRightOutlined rotateRight={expanded} />}
            />
        );
    };

    private handleOnChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<T> | SorterResult<T>[],
        extra: TableCurrentDataSource<T>
    ) => {
        const { onChange, tracking } = this.props;
        if (extra.action === "sort") {
            const mainSorting = Array.isArray(sorter) ? sorter[0] : sorter;
            tracking?.trackEvent({
                event: "SortChange",
                extraInfo: {
                    direction: mainSorting.order === "ascend" ? "Ascending" : "Descending",
                    column: mainSorting.columnKey,
                },
            });
        }
        if (onChange) {
            onChange(pagination, filters, sorter, extra);
        }
    };

    tableResizeObserver: ResizeObserver | undefined =
        window.ResizeObserver && new ResizeObserver(this.resizeStickyHeaderColumnWidths);

    componentDidMount() {
        if (
            this.props.sticky &&
            this.props.tableLayout === "auto" &&
            this.tableResizeObserver &&
            this.tableContainerRef.current &&
            !this.props.columns?.some((c) => c.resizeable)
        ) {
            this.tableResizeObserver.observe(this.tableContainerRef.current);
        }
        this.overrideStickyFooterBottom();
    }

    componentDidUpdate(prevProps: Readonly<IBTTableInternalProps<T> & ITrackingProp>): void {
        if (!isEqual(this.props.offsetScroll, prevProps.offsetScroll)) {
            this.overrideStickyFooterBottom();
        }
    }

    private overrideStickyFooterBottom = () => {
        const ref = this.tableContainerRef.current;
        if (this.props.stickyFooter && ref) {
            const footer = ref.querySelector(
                ".ant-table-summary.ant-table-sticky-holder"
            ) as HTMLDivElement;
            footer.style.setProperty("--footer-offset-height", `${this.props.offsetScroll}px`);
        }
    };

    componentWillUnmount() {
        if (this.tableResizeObserver) {
            this.tableResizeObserver.disconnect();
        }
    }

    private handleColumnResize = (index: number, newWidth: number) => {
        const { onColumnResize } = this.props;
        onColumnResize && onColumnResize(index, newWidth);
    };

    private isTableFooterRow = (index?: number) => {
        const { tableFooter, dataSource } = this.props;
        return tableFooter && dataSource && index === dataSource.length;
    };

    private isRenderedCell = (
        renderResult: React.ReactNode | RenderedCell<T>
    ): renderResult is RenderedCell<T> => {
        const renderedCell: RenderedCell<T> | undefined =
            !!renderResult &&
            typeof renderResult === "object" &&
            !isValidElement(renderResult) &&
            "props" in renderResult
                ? renderResult
                : undefined;

        return renderedCell !== undefined;
    };

    /**
     * Here we're using the RenderedCell pattern in order to be able to specify the
     * colSpan on the table cell by copying the colSpan from the onCell call over.
     * Two notes on this.
     *
     * 1. The normal way of setting the colSpan on the table cell is to use the onCell
     *    property on the column definition. However, our version of antd still has special
     *    handling for colSpan, and it won't get through. This issue is resolved here.
     *    https://github.com/react-component/table/commit/a246380c4a226da42103fd08cc91d94b08391b30#diff-b050cdcb547c6b5f4ff6cb88ef99b0fbf5509a75a91b248cb285f438a4eb65bdR151-R152
     *
     * 2. While this RenderedCell pattern works for us as a workaround for now, it is
     *    deprecated in the most current version of antd due to performance issues. Once
     *    we have upgraded to a version where the issue above is fixed, we should clean
     *    up this workaround so that we no longer use it. This workaround is designed
     *    to allow us to use onCell for the colSpan immediately so that we can clean
     *    this up without making any changes to consumers of BTTable.
     *    https://github.com/react-component/table/commit/75ee0064e54a4b3215694505870c9d6c817e9e4a#diff-b050cdcb547c6b5f4ff6cb88ef99b0fbf5509a75a91b248cb285f438a4eb65bdR140
     */
    private spliceColSpanIntoRenderIfNeeded = (
        text: unknown,
        record: T,
        index: number,
        oldRender:
            | ((value: unknown, record: T, index: number) => React.ReactNode | RenderedCell<T>)
            | undefined,
        oldOnCell: GetComponentProps<T> | undefined
    ) => {
        // if the old render output is using the RenderedCell pattern, we need to decompose
        // it in order to correctly splice in our modifications
        let oldRenderedElement: React.ReactNode;
        let oldRenderedCellProps: CellType<T> | undefined;
        if (oldRender) {
            const oldRenderResult = oldRender(text, record, index);
            if (this.isRenderedCell(oldRenderResult)) {
                oldRenderedElement = oldRenderResult.children;
                oldRenderedCellProps = oldRenderResult.props;
            } else {
                oldRenderedElement = oldRenderResult;
            }
        } else {
            // asserting that `text` is a ReactNode here even though it is NOT always true in order
            // to not break existing usages of BTTable at the time of this change
            oldRenderedElement = text as React.ReactNode;
        }

        const onCellProps: React.TdHTMLAttributes<HTMLTableCellElement> | undefined = oldOnCell?.(
            record,
            index
        );
        if (oldRenderedCellProps || onCellProps?.colSpan !== undefined) {
            return {
                children: oldRenderedElement,
                props: { colSpan: onCellProps?.colSpan, ...oldRenderedCellProps },
            };
        }

        return oldRenderedElement;
    };

    /**
     * This CompareFn<T> is a hack to prevent an empty row from being displayed during an asc sort.
     * It is assumed that the antd sort is a stable sort. If it were to change in the future, this could
     * potentially break.
     */
    private colSorterForTableWithFooter = (sorter: CompareFn<T>) => (a: T, b: T) => {
        return this.isEmptyObjectRepresentingFooter(a) || this.isEmptyObjectRepresentingFooter(b)
            ? 0
            : sorter(a, b);
    };

    private generateColumnForDisplay = (
        col: IBTColumnGroupType<T> | IBTColumnType<T>,
        colIndex: number,
        tableFooterChildrenToRender: {
            children?: any;
            props: IBTColumnProps<T>;
        }[]
    ) => {
        const { tableFooter } = this.props;
        let colValue = { ...col };

        const oldOnHeaderCell = colValue.onHeaderCell;
        colValue.onHeaderCell = (column) => {
            const onHeaderCell = oldOnHeaderCell && oldOnHeaderCell(column);
            return {
                ...onHeaderCell,
                width: colValue.width,
                minWidth: colValue.minWidth,
                onResizeStop: (width: number) => this.handleColumnResize(colIndex, width),
                isResizable: colValue.resizeable ?? false,
                tableContainerRef: this.tableContainerRef,
                columnIndex: colIndex,
                lastColumn: colIndex + 1 === this.props.columns?.length,
            } as React.HTMLAttributes<HTMLElement>;
        };

        const oldOnCell = colValue.onCell;
        colValue.onCell = (data, index) => {
            if (colValue["data-testid"] && !this.isTableFooterRow(index)) {
                return {
                    "data-testid": colValue["data-testid"],
                    ...oldOnCell?.(data, index),
                };
            } else {
                return { ...oldOnCell?.(data, index) };
            }
        };

        const oldRender = colValue.render;
        colValue.render = (text, record, index) => {
            if (this.isTableFooterRow(index)) {
                if (tableFooterChildrenToRender.length - 1 >= colIndex) {
                    return tableFooterChildrenToRender[colIndex];
                } else {
                    return null;
                }
            }

            return this.spliceColSpanIntoRenderIfNeeded(text, record, index, oldRender, oldOnCell);
        };

        if (tableFooter && typeof colValue.sorter === "function") {
            colValue.sorter = this.colSorterForTableWithFooter(colValue.sorter);
        }

        return colValue;
    };

    private getSummaryForStickyFooter = (data: readonly T[]) => {
        const { stickyFooter, summary } = this.props;
        if (summary) {
            return summary(data);
        } else if (stickyFooter) {
            const { rows } = stickyFooter;

            return (
                <Table.Summary fixed>
                    {rows.map((row) => {
                        const { cells, className, key } = row;
                        return (
                            <Table.Summary.Row key={`stickyFooterRow${key}`} className={className}>
                                {cells.map((cell: ISummaryCell) => {
                                    const { children, key, ...cellProps } = cell;
                                    return (
                                        <Table.Summary.Cell
                                            key={`stickyFooterCell${key}`}
                                            {...cellProps}
                                        >
                                            {children}
                                        </Table.Summary.Cell>
                                    );
                                })}
                            </Table.Summary.Row>
                        );
                    })}
                </Table.Summary>
            );
        } else {
            return;
        }
    };

    render = () => {
        const {
            tableFooter,
            columns,
            dataSource,
            striped,
            footerClass,
            emptyText,
            emptyState,
            rowSelection,
            rowClassName,
            sticky,
            offsetHeader,
            offsetScroll,
            components,
            stickyFooter,
            onColumnResize: _onColumnResize, // toss onColumnResize, included elsewhere
            ...otherProps
        } = this.props;

        if (columns?.some((c) => c.resizeable) && !columns.some((c) => c.key === BufferColumnKey)) {
            throw new Error("BTTables with Resizable columns require a BufferColumn");
        }

        // getClassName depends on the value of dataSource.length before an empty T is added to dataSource for the footer
        const newRowClassName = (record: T, index: number) =>
            this.getRowClassName(record, index, dataSource, footerClass, striped, rowClassName);

        const dataSourceCopy = dataSource ? [...dataSource] : undefined;
        if (tableFooter && dataSourceCopy && dataSourceCopy.length > 0) {
            dataSourceCopy.push({} as T);
        }

        // Have to find the children to actually render. If you do {false && <element>} then it gets put into the array as "false". Filter those down
        let tableFooterChildrenToRender: { children?: any; props: IBTColumnProps<T> }[];

        if (tableFooter) {
            if (Array.isArray(tableFooter)) {
                tableFooterChildrenToRender = tableFooter.filter((x) => x);
            } else {
                if (tableFooter.props.children) {
                    tableFooterChildrenToRender = tableFooter.props.children.filter((x: any) => x);
                } else {
                    tableFooterChildrenToRender = [];
                }
            }
        } else {
            tableFooterChildrenToRender = [];
        }

        const tableClassNames = this.getTableClassNames();

        const columnsCopy = columns?.map((col, colIndex) => {
            return this.generateColumnForDisplay(col, colIndex, tableFooterChildrenToRender);
        });

        let rowSelectionProp = rowSelection;
        if (tableFooter && rowSelectionProp) {
            rowSelectionProp = this.removeCheckBoxFromFooterRowWithRowSelection(rowSelectionProp);
        }
        const componentsWithHeaders = {
            header: { cell: ResizableHeader },
            ...components,
        };

        const useLocaleEmptyState = !isNullOrUndefined(otherProps.locale?.emptyText);
        const footerHeight = 55 * (stickyFooter?.rows.length ?? 0);
        return (
            <>
                {(useLocaleEmptyState || (dataSourceCopy && dataSourceCopy.length > 0)) && (
                    <div ref={this.tableContainerRef} className="BTTable-Outer">
                        {
                            // eslint-disable-next-line react/forbid-elements
                            <Table
                                {...otherProps}
                                className={classNames("BTTable", tableClassNames, {
                                    StickyHeaderAuto:
                                        sticky &&
                                        !columnsCopy?.some((c) => c.resizeable) &&
                                        otherProps.tableLayout === "auto",
                                    StickyFooterOffsetHeight: stickyFooter !== undefined,
                                })}
                                rowClassName={newRowClassName}
                                dataSource={dataSourceCopy}
                                columns={columnsCopy}
                                rowSelection={rowSelectionProp}
                                expandIcon={this.getExpandIcon}
                                showSorterTooltip={false}
                                sticky={
                                    sticky
                                        ? {
                                              offsetHeader,
                                              offsetScroll: (offsetScroll ?? 0) + footerHeight,
                                          }
                                        : undefined
                                }
                                components={componentsWithHeaders}
                                onChange={this.handleOnChange}
                                summary={this.getSummaryForStickyFooter}
                            />
                        }
                    </div>
                )}

                {!useLocaleEmptyState &&
                    emptyState !== false &&
                    (!dataSourceCopy || dataSourceCopy.length === 0) && (
                        <Empty
                            description={emptyText ? emptyText : "No Data"}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
            </>
        );
    };
}

export const BTTable = <T extends {}>(
    props: IBTTableProps<T>
): ReturnType<React.FunctionComponent<IBTTableProps<T>>> => {
    const { sticky } = props;

    const topLevel = typeof sticky !== "boolean" ? sticky?.topLevel : undefined;
    const bottomLevel = typeof sticky !== "boolean" ? sticky?.bottomLevel : undefined;

    const stickyHeaderTotalHeight = useStickyTotalSize("top", topLevel);
    const stickyFooterTotalHeight = useStickyTotalSize("bottom", bottomLevel);

    return (
        <BTTableInternal
            {...props}
            offsetHeader={stickyHeaderTotalHeight}
            offsetScroll={stickyFooterTotalHeight}
        />
    );
};
