import { PureComponent } from "react";
import { LinkProps } from "react-router-dom";

import { LinkType } from "types/enum";

import { AbstractLink } from "commonComponents/utilities/AbstractLink/AbstractLink";

interface ILegacyLinkProps extends Omit<LinkProps, "to"> {
    shouldOpenNewTab?: boolean;
    to: string;
    isUnderline?: boolean;
}

/**
 * Link that renders as a <a href /> if the to prop includes ".aspx".
 * Otherwise renders as a <BTLink />.
 * @deprecated
 */
export default class BTLegacyLink extends PureComponent<ILegacyLinkProps> {
    static defaultProps = {
        isUnderline: true,
    };

    private calculateLinkTypeFromPath = (path: string): LinkType => {
        return path.includes(".aspx") ? LinkType.InternalWebForms : LinkType.InternalReact;
    };

    render() {
        const { to, shouldOpenNewTab, ...otherProps } = this.props;
        const linkType = this.calculateLinkTypeFromPath(to);
        return (
            <AbstractLink
                url={to}
                linkType={linkType}
                shouldOpenNewTab={shouldOpenNewTab}
                {...otherProps}
            />
        );
    }
}
