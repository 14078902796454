import { DatePicker } from "antd";
import { MonthPickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { PureComponent, useContext } from "react";

import { DateLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { track } from "utilities/analytics/analytics";
import { getClosestModal } from "utilities/helpers";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

interface IBTMonthPickerProps<FormValues>
    extends Omit<MonthPickerProps, "onChange" | "id" | "allowClear" | "placeholder"> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;
    readOnly?: boolean;

    /** pass formik setFieldValue */
    onChange: (field: KeyOfOrString<FormValues>, value: moment.Moment | undefined) => void;
}

interface IInjectedMonthPickerProps {
    datePickerLocale: DateLocale;
}

@track((props) => ({ element: "Month Picker", uniqueId: props["data-testid"] }))
class BTMonthPickerInternal<FormValues = undefined> extends PureComponent<
    IBTMonthPickerProps<FormValues> & IInjectedMonthPickerProps
> {
    private onValueChange = (date: moment.Moment | null) => {
        const { onChange, id } = this.props;
        const dateNullOrUndefined = date !== null ? date : undefined;

        onChange(id, dateNullOrUndefined);
    };

    render() {
        const {
            id,
            value,
            onChange,
            readOnly,
            "data-testid": testid,
            datePickerLocale,
            ...otherProps
        } = this.props;

        if (readOnly) {
            return (
                <ValueDisplay
                    id={id as string}
                    data-testid={testid}
                    value={value && value.format(datePickerLocale.monthFormat)}
                />
            );
        }
        return (
            // eslint-disable-next-line react/forbid-elements
            <DatePicker.MonthPicker
                value={value}
                onChange={this.onValueChange}
                getPopupContainer={getClosestModal}
                allowClear={false}
                placeholder=""
                format={datePickerLocale.monthFormat}
                {...otherProps}
            />
        );
    }
}

export function BTMonthPicker<FormValues = undefined>(props: IBTMonthPickerProps<FormValues>) {
    const builderInfo = useContext(BuilderInfoContext);
    return (
        <BTMonthPickerInternal<FormValues>
            datePickerLocale={builderInfo?.locale.dateLocale ?? DateLocale.default}
            {...props}
        />
    );
}
