import { APIHandler } from "utilities/apiHandler";

import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";

import {
    ISavedFilterFormValues,
    SavedFilterCreateResponse,
    SavedFilterDeleteResponse,
    SavedFilterSetUserDefaultResponse,
    SavedFilterUpdateResponse,
} from "./SavedFilter.api.types";

export interface ISavedFilterHandler {
    create(
        newValues: ISavedFilterFormValues,
        jobID?: number | null
    ): Promise<SavedFilterCreateResponse>;
    update(
        id: number,
        formData: ISavedFilterFormValues,
        jobID?: number | null
    ): Promise<SavedFilterUpdateResponse>;
    delete(id: number): Promise<SavedFilterDeleteResponse>;
    setUserDefault(id: number, type: FilterEntityType): Promise<SavedFilterSetUserDefaultResponse>;
}

export class SavedFilterHandler implements ISavedFilterHandler {
    async create(
        newValues: ISavedFilterFormValues,
        jobID?: number
    ): Promise<SavedFilterCreateResponse> {
        const data = {
            filterType: newValues.filterType,
            isDefault: newValues.isDefault,
            isPrivate: newValues.isPrivate,
            name: newValues.filterName,
            value: newValues.value,
            jobID: jobID,
            useSession: jobID === undefined,
        };
        return await APIHandler(`/api/Filters/Saved`, {
            method: "POST",
            data: data,
            responseType: SavedFilterCreateResponse,
        });
    }

    async update(
        id: number,
        formData: ISavedFilterFormValues,
        jobID?: number
    ): Promise<SavedFilterUpdateResponse> {
        const data = {
            filterType: formData.filterType,
            isDefault: formData.isDefault,
            isPrivate: formData.isPrivate,
            name: formData.filterName,
            value: formData.value,
            jobIDs: jobID,
            useSession: jobID === undefined,
        };
        return await APIHandler(`/api/Filters/${id}/Saved`, {
            method: "PUT",
            data: data,
            responseType: SavedFilterUpdateResponse,
        });
    }

    async delete(id: number): Promise<SavedFilterDeleteResponse> {
        return await APIHandler(`/api/Filters/${id}/Saved`, {
            method: "DELETE",
            responseType: SavedFilterDeleteResponse,
        });
    }

    async setUserDefault(
        id: number,
        type: FilterEntityType
    ): Promise<SavedFilterSetUserDefaultResponse> {
        return await APIHandler(`/api/Filters/${id}/SetUserDefault?resultFilterType=${type}`, {
            method: "PUT",
            responseType: SavedFilterSetUserDefaultResponse,
        });
    }
}
