import classNames from "classnames";
import { useEffect } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { BTBadge } from "commonComponents/btWrappers/BTBadge/BTBadge";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { GlobalSearchResult } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { getInfoForSearchResponse } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchUtils";
import { getLabelForSearchCategory } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

import "./GlobalSearchResults.less";

interface IGlobalSearchResultsProps {
    results?: BTSelectItem<GlobalSearchResult>[];
    isSearching: boolean;
    selectedResultIndex: number;
    onResultClick: (item: GlobalSearchResult) => void;
    resultText: string;
    showBadge?: boolean;
}
export const GlobalSearchResults: React.FunctionComponent<IGlobalSearchResultsProps> = ({
    results,
    isSearching,
    selectedResultIndex,
    onResultClick,
    resultText,
    showBadge,
}) => {
    useEffect(() => {
        const selectedResult = document.querySelector(".SearchResult.Active");
        if (selectedResult) {
            selectedResult.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [selectedResultIndex]);

    if (!results || results.length === 0 || isSearching) {
        return null;
    }

    return (
        <>
            <div className="GlobalSearchResults">
                <BTRow gutter={8} className="Header">
                    <BTCol>
                        <span>{resultText}</span>
                    </BTCol>
                    {showBadge && (
                        <BTCol className="Badge">
                            <BTBadge
                                overflowCount={15}
                                count={results.length}
                                statusType="info"
                                title=""
                            />
                        </BTCol>
                    )}
                </BTRow>

                {results.map((r, i) => {
                    if (r.extraData) {
                        const info = getInfoForSearchResponse(r.extraData);
                        const catLabel = getLabelForSearchCategory(r.extraData.category);
                        const classes = classNames("SearchResult", {
                            Active: i === selectedResultIndex,
                        });
                        return (
                            <div
                                className={classes}
                                key={r.id}
                                onClick={() => onResultClick(r.extraData!)}
                            >
                                <div className="SearchIcon">{info.icon}</div>{" "}
                                <div className="SearchResultCard">
                                    <BTRow align="middle" justify="space-between">
                                        <BTCol>
                                            <span>{r.value}</span>
                                        </BTCol>
                                        <div className="SearchSecondaryText">
                                            {r.extraData.jobName && (
                                                <span>
                                                    {r.extraData.jobName}
                                                    {" • "}
                                                </span>
                                            )}
                                            <span>{catLabel}</span>
                                        </div>
                                    </BTRow>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </>
    );
};
