import { AppDefaultInfo, BuilderInfo, EnvironmentInfo, UserInfo } from "helpers/AppProvider.types";

import { BTLoginTypes } from "types/enum";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTLink, BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import {
    MainNavigationResponse,
    NavMenuItemResponse,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";
import {
    IMenuTabInfo,
    IMenuTabInfoLinkUrlInfo,
    MenuOnClickTypes,
} from "commonComponents/utilities/MainNavigation/MainNavigation.types";

interface IMainNavLinkProps {
    info: IMenuTabInfo;
    data: MainNavigationResponse;
    navMenuItem: NavMenuItemResponse;
    jobId: number;
    isTemplateMode: boolean;
    loginType: BTLoginTypes;
    "data-testid"?: string;
    "aria-label"?: string;
    onContentClick: (info: IMenuTabInfo, navMenuItem: NavMenuItemResponse) => void;
    builderInfo?: BuilderInfo;
    userInfo: UserInfo;
    appDefaultInfo?: AppDefaultInfo;
    environmentInfo?: EnvironmentInfo;
}

const mainLinkClass = "MainNavMenuItemAction";

export const MainNavLink: React.FunctionComponent<IMainNavLinkProps> = ({
    info,
    data,
    navMenuItem,
    jobId,
    isTemplateMode,
    loginType,
    onContentClick,
    builderInfo,
    appDefaultInfo,
    environmentInfo,
    children,
    userInfo,
    ...props
}) => {
    let linkUrl: string;
    const onClickType = info.onClickType?.(builderInfo, userInfo) ?? MenuOnClickTypes.Anchor;
    const metaProps = { "data-testid": props["data-testid"], "aria-label": props["aria-label"] };
    const linkUrlInfo: IMenuTabInfoLinkUrlInfo = {
        loginType: loginType,
        data: data,
        jobId: jobId,
        isTemplateMode: isTemplateMode,
        builderInfo: builderInfo,
        userInfo: userInfo,
        appDefaultInfo: appDefaultInfo,
        environmentInfo: environmentInfo,
    };
    switch (onClickType) {
        case MenuOnClickTypes.Anchor:
            linkUrl = info.getLinkUrl ? info.getLinkUrl(linkUrlInfo) : "#";
            return (
                <a
                    href={linkUrl}
                    className={mainLinkClass}
                    onClick={() => onContentClick(info, navMenuItem)}
                    {...metaProps}
                >
                    {children}
                </a>
            );

        case MenuOnClickTypes.BTLinkRelative:
            linkUrl = info.getLinkUrl ? info.getLinkUrl(linkUrlInfo) : "/";
            return (
                <BTLinkRelative
                    to={linkUrl}
                    className={mainLinkClass}
                    onClick={() => onContentClick(info, navMenuItem)}
                    {...metaProps}
                >
                    {children}
                </BTLinkRelative>
            );

        case MenuOnClickTypes.BTLink:
            linkUrl = info.getLinkUrl ? info.getLinkUrl(linkUrlInfo) : "/";
            return (
                <BTLink
                    to={linkUrl}
                    className={mainLinkClass}
                    onClick={() => onContentClick(info, navMenuItem)}
                    {...metaProps}
                >
                    {children}
                </BTLink>
            );

        case MenuOnClickTypes.External:
            return (
                <BTExternalLink
                    href={info.getLinkUrl ? info.getLinkUrl(linkUrlInfo) : "#"}
                    className={mainLinkClass}
                    onClick={() => onContentClick(info, navMenuItem)}
                    {...metaProps}
                >
                    {children}
                </BTExternalLink>
            );

        case MenuOnClickTypes.NoLink:
            return (
                <div
                    className={mainLinkClass}
                    onClick={() => onContentClick(info, navMenuItem)}
                    {...metaProps}
                >
                    {children}
                </div>
            );
    }
};
