import { IOnboardingContactProvided } from "utilities/onboarding/onboarding.types";
import {
    AppcuesConstants,
    IAppcuesFormResponse,
    IAppcuesFormSubmittedEvent,
} from "utilities/thirdPartyIntegrations/appcues/appcues.types";

/**
 * Extracts the value from the field with ID of {@link fieldId} from the provided {@link formData} from Appcues
 * @param formData {@link IAppcuesFormResponse} from an Appcues Form submission
 * @param fieldId Unique ID of the target Appcues form field
 * @returns String value of the target field
 */
export function extractFieldValueByFieldId(
    formData: IAppcuesFormResponse,
    fieldId: string
): string {
    let value = "";

    try {
        if (!formData || !formData.response) {
            return value;
        }
        const targetField = formData.response.find((field) => field.fieldId === fieldId);
        value = targetField?.value ? targetField?.value : "";
    } catch (e) {
        // Swallow and return ""
    }

    return value;
}

/**
 * Extracts the form data from the 'Identify Your BT Champion' Appcues flow 'form-submitted' event.
 * @param actionData {@link IAppcuesFormSubmittedEvent} directly from Appcues 'form-submitted' event
 */
export function ExtractOnboardingContactDataFromBTChampionFormV2(
    actionData: IAppcuesFormSubmittedEvent
) {
    let onboardingContactProvided: IOnboardingContactProvided | undefined = undefined;
    try {
        const name = extractFieldValueByFieldId(
            actionData.interaction,
            AppcuesConstants.Flow_IdentifyBTChampion_V2.FieldId_Name
        );
        const email = extractFieldValueByFieldId(
            actionData.interaction,
            AppcuesConstants.Flow_IdentifyBTChampion_V2.FieldId_Email
        );
        const phone = extractFieldValueByFieldId(
            actionData.interaction,
            AppcuesConstants.Flow_IdentifyBTChampion_V2.FieldId_Phone
        );

        onboardingContactProvided = {
            name: name,
            email: email,
            phone: phone,
        };
    } catch (error) {
        reportError(error);
    }

    return onboardingContactProvided;
}
