import { APIHandler } from "utilities/apiHandler";

import {
    ClearClientObservableBackgroundJobStatusesResponse,
    ClientObservableBackgroundJobStatuses,
    JobImportActionRequiredResponse,
    ProcessStatusGroupType,
} from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";

export interface IClientObservableBackgroundJobStatusPopupHandler {
    getClientObservableBackgroundJobStatuses: () => Promise<ClientObservableBackgroundJobStatuses>;
    clearClientObservableBackgroundJobStatuses: (
        processType: ProcessStatusGroupType
    ) => Promise<ClearClientObservableBackgroundJobStatusesResponse>;
    getActionRequired: (index: number, jobId?: string) => Promise<JobImportActionRequiredResponse>;
}

export class ClientObservableBackgroundJobStatusPopupHandler {
    async getClientObservableBackgroundJobStatuses(): Promise<ClientObservableBackgroundJobStatuses> {
        return await APIHandler("/api/ClientObservableBackgroundJobs", {
            method: "GET",
            responseType: ClientObservableBackgroundJobStatuses,
        });
    }

    async clearClientObservableBackgroundJobStatuses(
        processType: ProcessStatusGroupType
    ): Promise<ClearClientObservableBackgroundJobStatusesResponse> {
        return await APIHandler("/api/ClientObservableBackgroundJobs/ClearJobs", {
            method: "DELETE",
            data: { processType },
            responseType: ClearClientObservableBackgroundJobStatusesResponse,
        });
    }

    async getActionRequired(
        index: number,
        jobId?: string
    ): Promise<JobImportActionRequiredResponse> {
        return await APIHandler("/api/Templates/ActionRequired", {
            method: "GET",
            data: { index, jobId },
            responseType: JobImportActionRequiredResponse,
        });
    }
}
