import {
    ILineItemResponse,
    LineItem,
    RelatedStackItem,
} from "legacyComponents/LineItemContainer.types";
import { LineItemType } from "legacyComponents/LineItemContainer.types";

import { CostTypes, MarkedAs } from "types/enum";

import yup from "utilities/form/yup";
import { calculateBuilderCost } from "utilities/lineItem/lineItemHelper";
import { isNullOrUndefined } from "utilities/object/object";

import { ILineItemRow } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    baseLineItemSchemaDefinition,
    markupLineItemSchemaDefinition,
    taxLineItemSchemaDefinition,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.schemas";
import {
    IBaseLineItem,
    IMarkupLineItem,
    ITaxLineItem,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import { MarkupType } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { IOwnerInvoiceFormValues } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice.api.types";

export interface IOwnerInvoiceLineItem extends IMarkupLineItem, ITaxLineItem, ILineItemRow {
    // stored in database with markup fields, but they can all be calculated from ownerPrice
    // unitPrice comes from markupPerUnit in database
    unitCost: number;
    quantity: number;
    ownerPrice: number;
    internalNotes: string;
    costItemId: number | null;
    costCodeTitle: string | null | undefined;
    lineItemType: LineItemType;
    costTypes: CostTypes[];
    markedAs: MarkedAs;
    unitPrice: number;
    relatedChangeOrderLineItemId?: number | null;
    relatedSelectionChoiceLineItemId?: number | null;
    relatedGeneralItemId?: number | null;
    relatedBidLineItemId?: number | null;
    relatedBillLineItemId?: number | null;
    relatedTimeCardItemId?: number | null;
    relatedTimeCardLineItemId?: number | null;
    relatedAccountingCostId?: number | null;
    relatedStackLinks?: Record<number, RelatedStackItem[]> | null;
    isStackedLineItem: boolean;
    allowanceLineItemId?: number | null;
    allowanceId?: number | null;
    calculatedAmount?: number;
    /**
     * Field to indicate what has been invoiced from a related entity, but not saved yet
     */
    newlyInvoicedAmount?: number;
}

export class OwnerInvoiceLineItem implements IOwnerInvoiceLineItem {
    constructor(data: any) {
        this._isInEdit = data._isInEdit;
        this.autoFocusedField = data.autoFocusedField;
        if (data.builderCost) {
            this.builderCost = data.builderCost;
        } else {
            this.builderCost = calculateBuilderCost(data.unitCost!, data.quantity);
        }
        this.costCodeId = data.costCode ?? data.costCodeId;
        this.costItemId = data.costCodeItemId ?? data.costItemId;
        this.costTypes = data.costTypes ?? [];
        this.markedAs = data.markedAs;
        this.description = data.description;
        this.id = data.lineItemId ?? data.id;
        this.internalNotes = data.internalNotes;
        this.isEditable = true;
        this.isExpanded = data.isExpanded ?? false;
        this.itemTitle = data.itemTitle ?? data.title;
        this.markupAmount = data.markupAmount ?? data.markupPrice;
        this.markupPerUnit = data.markupPerUnit;
        this.markupPercent = data.markupPercent ?? data.markupPercentage;
        this.ownerPrice = data.ownerPrice;
        this.quantity = data.quantity ?? 0;
        this.unit = data.unitType ?? data.unit;
        this.unitCost = data.unitCost;
        this.unitPrice = data.unitPrice;
        this.calculatedAmount = data.calculatedAmount;
        this.markupType = data.markupType ?? data.markupColumn;

        this.costCodeTitle = data.costCodeTitle;

        this.lineItemType = LineItemType.OwnerInvoice;
        this.relatedChangeOrderLineItemId = data.relatedChangeOrderLineItemId;
        this.relatedSelectionChoiceLineItemId = data.selectionChoiceLineItemId;
        this.relatedGeneralItemId = data.relatedGeneralItemId;
        this.relatedBidLineItemId = data.relatedBidLineItemId;
        this.relatedBillLineItemId = data.billLineItemId;
        this.relatedAccountingCostId = data.relatedAccountingCostId;
        this.taxGroupId = data.taxGroupId;
        this.totalWithTax = data.totalWithTax;
        this.allowanceLineItemId = data.allowanceLineItemId;
        this.allowanceId = data.allowanceId;
        this.relatedStackLinks = data.relatedStackLinks;
        this.isStackedLineItem =
            !isNullOrUndefined(this.relatedStackLinks) &&
            (Object.keys(this.relatedStackLinks).length > 1 || // two or more related time cards
                Object.values(this.relatedStackLinks).reduce((acc, arr) => acc + arr.length, 0) >
                    1); // two or more related time line items
    }

    _isInEdit?: boolean | undefined;
    autoFocusedField?: string | undefined;
    builderCost: number;
    costCodeId: number | null;
    costCodeTitle: string | null | undefined;
    costItemId: number | null;
    costTypes: CostTypes[];
    markedAs: MarkedAs;
    description: string;
    id: number;
    internalNotes: string;
    isDiscontinued?: boolean | undefined;
    isEditable: boolean;
    isExpanded?: boolean | undefined;
    isSelected?: boolean | undefined;
    itemTitle: string | null;
    lineItemType: LineItemType;
    markupAmount: number;
    markupPerUnit: number;
    markupPercent: number;
    markupType: MarkupType;
    ownerPrice: number;
    parentId?: number | undefined;
    quantity: number;
    relatedGeneralItemId?: number | null | undefined;
    unit: string;
    unitCost: number;
    unitPrice: number;
    varianceCodeId?: number | null | undefined;
    varianceCodeTitle?: string | undefined;
    relatedChangeOrderLineItemId?: number | null;
    relatedSelectionChoiceLineItemId?: number | null;
    relatedBidLineItemId?: number | null;
    relatedBillLineItemId?: number | null | undefined;
    relatedTimeCardItemId?: number | null;
    relatedTimeCardLineItemId?: number | null;
    relatedAccountingCostId?: number | null;
    relatedStackLinks?: Record<number, RelatedStackItem[]>;
    isStackedLineItem: boolean;
    /**
     * Field to indicate what has been invoiced from a related entity, but not saved yet
     */
    newlyInvoicedAmount?: number;
    // ITaxLineItem
    taxGroupId?: number;
    totalWithTax?: number;
    allowanceLineItemId?: number;
    allowanceId?: number;
    calculatedAmount?: number;
}

const baseLineItemOwnerInvoiceSchemaType = {
    ...baseLineItemSchemaDefinition,
    ...markupLineItemSchemaDefinition,
    ...taxLineItemSchemaDefinition,
};
type BaseLineItemOwnerInvoiceSchemaType = typeof baseLineItemOwnerInvoiceSchemaType;
export function getLineItemOwnerInvoiceSchema(
    getExtendedSchema: (
        baseSchema: BaseLineItemOwnerInvoiceSchemaType
    ) => BaseLineItemOwnerInvoiceSchemaType = (s) => s
) {
    return yup.object<IBaseLineItem>(getExtendedSchema(baseLineItemOwnerInvoiceSchemaType));
}

export const transformOwnerInvoiceLineItemsToLineItems = (newValues: IOwnerInvoiceFormValues) => {
    return newValues.ownerInvoiceLineItems.map(
        (x: IOwnerInvoiceLineItem) =>
            new LineItem(
                {
                    id: x.id > 0 ? x.id : 0,
                    costCodeId: x.costCodeId,
                    costCode: x.costCodeId!,
                    costCodeItemId: x.costItemId,
                    catalogItemId: x.costItemId,
                    costTypeId: 0,
                    unitCost: x.unitCost,
                    quantity: x.quantity,
                    unitType: x.unit,
                    builderCost: x.builderCost,
                    markupColumn: x.markupType as number,
                    markupPercentage: x.markupPercent,
                    markupPerUnit: x.markupPerUnit,
                    markupPrice: x.markupAmount,
                    ownerPrice: x.ownerPrice,
                    title: x.itemTitle,
                    description: x.description,
                    internalNotes: x.internalNotes,
                    costTypes: x.costTypes,
                    markedAs: x.markedAs,
                    relatedGeneralItemId: x.relatedGeneralItemId,
                    relatedChangeOrderLineItemId: x.relatedChangeOrderLineItemId,
                    relatedBidLineItemId: x.relatedBidLineItemId,
                    relatedStackLinks: x.relatedStackLinks,
                    selectionChoiceLineItemId: x.relatedSelectionChoiceLineItemId,
                    billLineItemId: x.relatedBillLineItemId,
                    relatedAccountingCostId: x.relatedAccountingCostId,
                    taxGroupId: x.taxGroupId,
                    totalWithTax: x.totalWithTax,
                    allowanceLineItemId: x.allowanceLineItemId,
                    allowanceId: x.allowanceId,
                    calculatedAmount: x.calculatedAmount,
                } as ILineItemResponse,
                "",
                LineItemType.OwnerInvoice
            )
    );
};

export enum OwnerInvoiceLineItemContainerColumns {
    TitleAndCostCode = 1,
    CostTypes = 2,
    UnitCost = 3,
    UnitPrice = 4,
    Quantity = 5,
    Unit = 6,
    Markup = 7,
    MarkupAmount = 8,
    OwnerPrice = 9,
    Tax = 10,
    DeleteAction = 11,
    MarkedAs = 12,
}
