import {
    BTLocalStorage,
    BTSessionStorage,
    getJobPickerState,
    LocalStorageKeys,
} from "types/btStorage";
import { JobStatusTypes } from "types/enum";

import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { IJobPickerHandler } from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import { JobPickerFilterTypes } from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import { getJobPickerStorageKey } from "commonComponents/utilities/JobPicker/JobPickerWrapper";
import { StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";

// note: also set in webforms
// value from @screen-lg less var, this value should match a responsive screen size (xs, sm, md, lg, etc)
export const autoCollapseJobpickerWidth = 992;

export function isResponsiveAutoCollapsed() {
    return window.innerWidth <= autoCollapseJobpickerWidth;
}

export function isUserPreferenceCollapsed() {
    return BTLocalStorage.get("bt-string-jobPickerPreference") === "closed";
}

export function getCollapsed() {
    return isUserPreferenceCollapsed() || isResponsiveAutoCollapsed();
}

export function selectJobsAndSetJobMode(jobIds: number[], allJobsSelected: boolean) {
    const jobPickerState = getJobPickerState();
    const storageValue = {
        ...jobPickerState,
        selectedJobIds: jobIds,
        isAllJobsSelected: allJobsSelected,
        isTemplateMode: false,
    };
    BTLocalStorage.set("bt-object-dangerousJobPickerState", storageValue);
    BTSessionStorage.set("bt-object-dangerousJobPickerState", storageValue);
}

// Saving and selecting a job, this will update local storage so that the job is selected when the user navigates back to the summary page
export function updateJobPickerLocalStorage(jobStatus: JobStatusTypes, builderId: number) {
    const jobPickerState = getJobPickerState();
    const filters = JSON.parse(jobPickerState.filters);
    if (filters[JobPickerFilterTypes.Other] === JobStatusFilterTypes.OpenOrClosed.toString()) {
        return;
    }
    if (jobStatus === JobStatusTypes.Closed) {
        filters[JobPickerFilterTypes.Other] = JobStatusFilterTypes.Closed.toString();
    } else if (jobStatus === JobStatusTypes.Presale) {
        filters[JobPickerFilterTypes.Other] =
            JobStatusFilterTypes.Open.toString() + "," + JobStatusFilterTypes.Presale.toString();
    } else {
        filters[JobPickerFilterTypes.Other] = JobStatusFilterTypes.Open.toString();
    }

    const filterString = JSON.stringify(filters);
    const storageValue = {
        ...jobPickerState,
        filters: filterString,
    };
    BTLocalStorage.set("bt-object-dangerousJobPickerState", storageValue);
    BTSessionStorage.set("bt-object-dangerousJobPickerState", storageValue);
    // eslint-disable-next-line no-restricted-globals
    localStorage.setItem(
        getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, false, builderId),
        filterString
    );
}

export const selectJob = async (
    builderId: number,
    jobId: number,
    templatesOnly = false,
    persistFilters = false,
    jobPickerHandler?: IJobPickerHandler
) => {
    await jobPickerHandler?.set({ jobId: jobId, builderId: builderId }, [], persistFilters, {
        keywordFilter: "", // TODO: get value from BTSearch for keyword
        otherFilter: templatesOnly ? "Templates" : JobStatusFilterTypes.Open.toString(),
        leadStatusFilter: "",
        selectedBuilderFilter: builderId,
    }).response;
};

export function getStatusTypeForJob(status?: JobStatusTypes): StatusTypes {
    switch (status) {
        case JobStatusTypes.Closed:
            return "";
        case JobStatusTypes.Presale:
            return "pending";
        case JobStatusTypes.Open:
            return "info";
        default:
            return "";
    }
}

export function getStatusTextForJob(status?: JobStatusTypes): string {
    switch (status) {
        case JobStatusTypes.Closed:
            return "Closed";
        case JobStatusTypes.Presale:
            return "Presale";
        case JobStatusTypes.Open:
            return "Open";
        default:
            return "";
    }
}

export function getStatusTextForJobFilter(status?: JobStatusFilterTypes): string {
    switch (status) {
        case JobStatusFilterTypes.Closed:
            return "Closed";
        case JobStatusFilterTypes.Presale:
            return "Presale";
        case JobStatusFilterTypes.Open:
            return "Open";
        case JobStatusFilterTypes.OpenOrClosed:
            return "Open and Closed";
        default:
            return "";
    }
}
