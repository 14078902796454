import { createContext, useContext, useEffect } from "react";

import {
    IStickyLayer,
    MainNavigationStickyHeaderHeight,
    StickyDirection,
} from "./StickyContext.type";
import {
    StickyLayerAction,
    StickyLayerAddAction,
    StickyLayerRemoveAction,
} from "./StickyLayer.reducer";

export interface IStickyContext {
    top: IStickyLayer[];
    bottom: IStickyLayer[];
    dispatchLayerChangeAction: (action: StickyLayerAction) => void;
}

export const StickyContext = createContext<IStickyContext>({
    top: [],
    bottom: [],
    dispatchLayerChangeAction: () => {},
});

function getTotalStickySize(layers: IStickyLayer[], direction: StickyDirection, level?: number) {
    let filteredLayers = layers;
    if (level !== undefined) {
        if (direction === "top") {
            filteredLayers = layers.filter((layer) => layer.level < level);
        } else {
            filteredLayers = layers.filter((layer) => layer.level > level);
        }
    }

    return filteredLayers.reduce((accumulator, currentLayer) => accumulator + currentLayer.size, 0);
}

/**
 * Adds a component's height at the provided level as a layer to
 * the context when the component mounts. This layer is removed
 * when the component unmounts.
 *
 * Lower level elements should appear above higher level elements.
 * For example, the main navigation bar is at level 0 and a list
 * page action bar is at level 100. That will allow the list page
 * action bar to stick under the main navigation bar.
 *
 * @param size Height of the element to add.
 * @param level Level of the element to add.
 */
export function useStickyHeight(size: number, direction: StickyDirection, level: number) {
    const { top, bottom, dispatchLayerChangeAction } = useContext(StickyContext);

    useEffect(() => {
        const layer: IStickyLayer = { size, level };

        dispatchLayerChangeAction(new StickyLayerAddAction(layer, direction));

        return () => {
            dispatchLayerChangeAction(new StickyLayerRemoveAction(layer, direction));
        };
    }, [dispatchLayerChangeAction, size, level, direction]);

    const isTop = direction === "top";
    return getTotalStickySize(isTop ? top : bottom, direction, level);
}

/**
 * Returns the total height of all components above a component
 * at given level or the total height currently in context.
 *
 * @param level The stopping level for the total height calculation.
 */
export function useStickyTotalSize(direction: StickyDirection, level?: number) {
    const { top, bottom } = useContext(StickyContext);

    const isTop = direction === "top";
    return getTotalStickySize(isTop ? top : bottom, direction, level);
}

/**
 * Sets the sticky header value to the main navigation's height.
 * @deprecated
 */
export function useStickyHeaderWithMainNav() {
    return useStickyHeight(MainNavigationStickyHeaderHeight, "top", 0);
}
