import moment from "moment";

import {
    BTSelectItem,
    mapBTServiceDropdownToSelectItem,
    ServiceValidation,
} from "types/apiResponse/apiResponse";
import { AccountingIntegrationType } from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";

import { JobUsingTaxGroup } from "entity/tax/TaxGroup/TaxGroup.api.types";

import type { default as TaxRateAPIResponseExample } from "./TaxRate.api.json";

export const NoTaxId = -1;
export const ImportOptionId = -2;
export const NewTaxAgencyId = 0;
export const NewTaxRateId = 0;
export const MaxTaxAgencyNameLength = 100;
export const MaxTaxRateNameLength = 100;

export type TaxRateFormActions = undefined | "save" | "delete";

export interface ITaxRateFormValues {
    taxRates: ITaxRateFormValue[];
    isActive?: boolean;
    taxAgencyOptions: BTSelectItem<any>[];
}

export interface ITaxRateFormValue {
    taxRateId: number | null;
    taxRateName: string;
    taxRatePercent: number;
    taxAgencyId?: number | null;
    taxAgencyName?: string;
    isCompoundRate: boolean;
}

export class TaxRateEntity {
    constructor(data: typeof TaxRateAPIResponseExample) {
        this.builderId = data.builderId;
        this.taxRateId = data.taxRateId;
        this.taxRateName = data.taxRateName;
        this.taxRatePercent = data.taxRatePercent;
        this.taxAgencies =
            data.taxAgencies && mapBTServiceDropdownToSelectItem(data.taxAgencies)[0].children;
        this.taxGroupId = data.taxGroupId;
        this.taxGroupMembershipId = data.taxGroupMembershipId;
        this.isCompoundRate = data.isCompoundRate;
        this.deactivatedDate = moment(data.deactivatedDate);
        this.isApplied = data.isApplied;
        this.isActive = data.isActive;
        this.taxAgency = data.taxAgency && new TaxAgencyEntity(data.taxAgency);
        this.accountingType = data.accountingType;
        this.taxRateInUseJobs =
            data.taxRateInUseJobs && data.taxRateInUseJobs.map((x) => new JobUsingTaxGroup(x));
    }

    builderId: number;
    taxRateId: number;
    taxRateName: string;
    taxRatePercent: number;
    taxAgency?: TaxAgencyEntity;
    taxAgencies?: BTSelectItem<unknown>[];
    taxGroupId?: number | null;
    taxGroupMembershipId?: number | null;
    isCompoundRate: boolean;
    deactivatedDate?: moment.Moment;
    isApplied: boolean;
    isActive: boolean;
    accountingType?: AccountingIntegrationType;
    taxRateInUseJobs: JobUsingTaxGroup[];
}

export class TaxAgencyEntity {
    constructor(data: any) {
        this.builderId = data.builderId;
        this.taxAgencyName = data.taxAgencyName;
        if (!data.taxAgencyName && data.taxAgencyId === 0) {
            this.taxAgencyId = null;
        } else {
            this.taxAgencyId = data.taxAgencyId;
        }
    }

    builderId?: number;
    taxAgencyId: number | null;
    taxAgencyName: string;
}

export class TaxRateDeleteResponse implements ITaxRateDeleteResponse {
    constructor(data: ITaxRateDeleteResponse) {
        this.requestedIds = data.requestedIds;
        this.deletedIds = data.deletedIds;
    }
    requestedIds: number[];
    deletedIds: number[];
}

export interface ITaxRateDeleteResponse {
    requestedIds: number[];
    deletedIds: number[];
}

export class TaxRateCreateResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
        this.taxRates = data.taxRates;
    }
    taxRates: TaxRateEntity[];
    failedFields?: ServiceValidation[];
}

export interface ITaxRateUpdateRequest {
    taxRateId: number;
    taxRateName: string;
    taxRatePercent: number;
    taxAgency: TaxAgencyEntity;
    isActive: boolean;
}

export function transformTaxAgencyFromFormValue(
    formValue: ITaxRateFormValue,
    taxAgencyOptions: BTSelectItem<unknown>[]
) {
    const selectedOption = taxAgencyOptions.find(
        (option) => parseInt(option.id) === formValue.taxAgencyId
    );
    const taxAgencyId = selectedOption?.id;

    return new TaxAgencyEntity({
        taxAgencyId:
            !isNullOrUndefined(taxAgencyId) && parseInt(taxAgencyId) <= NewTaxAgencyId
                ? NewTaxAgencyId
                : taxAgencyId,
        taxAgencyName: selectedOption?.title,
    });
}
export class TaxRateUpdateResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
    }

    failedFields?: ServiceValidation[];
}

export interface ITaxRateCreateRequest {
    taxRates: {
        taxRateName: string;
        taxRatePercent: number;
        taxAgency: TaxAgencyEntity;
    }[];
}
