import { ButtonSize } from "antd/lib/button";
import { throttle } from "lodash-es";
import { Component, createRef, ReactNode } from "react";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";

import "./ReadMore.less";

interface IReadMoreProps {
    numberOfLines: number;
    lineHeight: number;
    showingMore: boolean;
    onShowingMoreToggle: (showingMore: boolean) => void;
    moreText: ReactNode;
    lessText: ReactNode;
    "data-testid"?: string;
    size: ButtonSize;
}

interface IReadMoreState {
    isOverflown: boolean;
    shouldShowLessButton: boolean;
}

export default class ReadMorePresentational extends Component<IReadMoreProps, IReadMoreState> {
    static defaultProps = {
        lineHeight: 1.5,
        moreText: "Show More",
        lessText: "Show Less",
        size: "default",
    };

    state: Readonly<IReadMoreState> = {
        isOverflown: false,
        shouldShowLessButton: false,
    };

    private textRef = createRef<HTMLDivElement>();

    handleTextResizeChange = throttle(
        (
            [textEl]: ResizeObserverEntry[],
            { showingMore, numberOfLines, lineHeight }: IReadMoreProps
        ) => {
            this.setState((prevState) => {
                const isOverflown = textEl.target.scrollHeight > textEl.target.clientHeight;

                // `fontSize` is needed because maxHeight is set in `em`;  This is calculated to a `px` value.
                const fontSize = parseFloat(window.getComputedStyle(textEl.target).fontSize) || 1;
                const maxHeight = numberOfLines * lineHeight * fontSize;
                const clientHeight = textEl.target.clientHeight;
                const shouldShowLessButton = showingMore && clientHeight > maxHeight;

                const isOverflownChanged = prevState.isOverflown !== isOverflown;
                const shouldShowLessButtonChanged =
                    prevState.shouldShowLessButton !== shouldShowLessButton;

                const result = {} as IReadMoreState;
                if (isOverflownChanged) {
                    result.isOverflown = isOverflown;
                }
                if (shouldShowLessButtonChanged) {
                    result.shouldShowLessButton = shouldShowLessButton;
                }
                if (!isOverflownChanged && !shouldShowLessButtonChanged) {
                    return null;
                }

                return result;
            });
        },
        200
    );

    // This is currently supported in all of our supported browsers.
    textResizeObserver: ResizeObserver | undefined =
        window.ResizeObserver &&
        new ResizeObserver((entries) => {
            this.handleTextResizeChange(entries, this.props);
        });

    componentDidMount() {
        if (this.textRef.current) {
            // If no browser support fallback to this.
            if (!this.textResizeObserver) {
                this.setState({
                    isOverflown:
                        this.textRef.current.scrollHeight > this.textRef.current.clientHeight,
                });
            } else {
                this.textResizeObserver.observe(this.textRef.current);
            }
        }
    }

    componentWillUnmount() {
        if (this.textResizeObserver && this.textRef.current) {
            this.textResizeObserver.disconnect();
        }
    }

    private handleShowMoreClick = (e: React.MouseEvent) => {
        this.props.onShowingMoreToggle(!this.props.showingMore);
        e.stopPropagation();
    };

    render() {
        const { isOverflown, shouldShowLessButton } = this.state;
        const {
            showingMore,
            children,
            numberOfLines,
            lineHeight,
            moreText,
            lessText,
            "data-testid": dataTestId,
            size,
        } = this.props;
        return (
            <>
                <div className="readMorePrint">{children}</div>
                <div className="readMoreContainer" data-testid={dataTestId}>
                    <div
                        className="readMore"
                        ref={this.textRef}
                        style={{
                            maxHeight: showingMore ? undefined : `${numberOfLines * lineHeight!}em`,
                            overflow: showingMore ? "visible" : "hidden",
                        }}
                    >
                        <div className="readMore-trimmedText">{children}</div>
                    </div>
                    {(isOverflown || shouldShowLessButton) && (
                        <div className="readMore-link">
                            <BTButton
                                data-testid={showingMore ? "showLess" : "showMore"}
                                type="link"
                                size={size}
                                isolated={true}
                                onClick={this.handleShowMoreClick}
                                data-read-more-button
                            >
                                {showingMore ? lessText : moreText}
                            </BTButton>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
