import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IOwnerSurveyProps } from "./OwnerSurvey";

const OwnerSurvey = lazyLoadWithRetry(() => import("./OwnerSurvey"));

export const RouteOwnerSurvey: React.FunctionComponent<
    Omit<IOwnerSurveyProps, "history" | "location" | "match" | "id">
> = (props) => (
    <RouteRelative
        path={routes.survey.ownerSurvey}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <OwnerSurvey
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                    />
                </BTSuspense>
            );
        }}
    />
);
