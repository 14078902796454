import classNames from "classnames";
import { useCallback } from "react";

import "./BTArrowButton.less";

interface IBTArrowButtonProps {
    onClick: () => void;
    orientation: "left" | "right" | "up" | "down";
    className?: string;
}

export const BTArrowButton: React.FunctionComponent<IBTArrowButtonProps> = (props) => {
    const { onClick: parentOnClick } = props;
    const onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            parentOnClick();
        },
        [parentOnClick]
    );
    const { orientation, ...otherProps } = props;

    return (
        <div
            {...otherProps}
            onClick={onClick}
            className={classNames("BTArrowButton", props.orientation, props.className)}
        >
            <div className="BTArrowButtonIcon" />
        </div>
    );
};
