/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { useQuery } from "@tanstack/react-query";
import type {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type {
    ActionItemCountsContract,
    BuilderCardInfoContract,
    CommunicationCountsContract,
    ContactUsContract,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>InternalUser</code>
</div>
**Stability Level**: `stable`
 */
export const summaryJobContactInfoByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ContactUsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/contact-info`, method: "GET", signal },
        options
    );
};

export const getSummaryJobContactInfoByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/contact-info`] as const;
};

export const getSummaryJobContactInfoByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getSummaryJobContactInfoByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>> = ({
        signal,
    }) => summaryJobContactInfoByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobContactInfoByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>
>;
export type SummaryJobContactInfoByJobIdGetV2QueryError = ErrorType<unknown>;

export const useSummaryJobContactInfoByJobIdGetV2 = <
    TData = Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getSummaryJobContactInfoByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>InternalUser</code>
</div>
**Stability Level**: `stable`
 */
export const summaryJobBuilderContactInfoByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<BuilderCardInfoContract>(
        { url: `/apix/v2/Summary/job/${jobId}/builder-contact-info`, method: "GET", signal },
        options
    );
};

export const getSummaryJobBuilderContactInfoByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/builder-contact-info`] as const;
};

export const getSummaryJobBuilderContactInfoByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobBuilderContactInfoByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>
    > = ({ signal }) => summaryJobBuilderContactInfoByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobBuilderContactInfoByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>
>;
export type SummaryJobBuilderContactInfoByJobIdGetV2QueryError = ErrorType<unknown>;

export const useSummaryJobBuilderContactInfoByJobIdGetV2 = <
    TData = Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getSummaryJobBuilderContactInfoByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>InternalUser</code>
</div>
**Stability Level**: `stable`
 */
export const summaryJobActionItemsCountByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ActionItemCountsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/action-items/count`, method: "GET", signal },
        options
    );
};

export const getSummaryJobActionItemsCountByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/action-items/count`] as const;
};

export const getSummaryJobActionItemsCountByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobActionItemsCountByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>
    > = ({ signal }) => summaryJobActionItemsCountByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobActionItemsCountByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>
>;
export type SummaryJobActionItemsCountByJobIdGetV2QueryError = ErrorType<unknown>;

export const useSummaryJobActionItemsCountByJobIdGetV2 = <
    TData = Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getSummaryJobActionItemsCountByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>InternalUser</code>
</div>
**Stability Level**: `stable`
 */
export const summaryJobCommunicationsCountByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<CommunicationCountsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/communications/count`, method: "GET", signal },
        options
    );
};

export const getSummaryJobCommunicationsCountByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/communications/count`] as const;
};

export const getSummaryJobCommunicationsCountByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobCommunicationsCountByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>
    > = ({ signal }) => summaryJobCommunicationsCountByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobCommunicationsCountByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>
>;
export type SummaryJobCommunicationsCountByJobIdGetV2QueryError = ErrorType<unknown>;

export const useSummaryJobCommunicationsCountByJobIdGetV2 = <
    TData = Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getSummaryJobCommunicationsCountByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
