import { Component } from "react";
import { Helmet } from "react-helmet";

interface IPageTitleProps {
    title: string;
}

export class PageTitle extends Component<IPageTitleProps> {
    render() {
        return (
            <Helmet>
                <title>{this.props.title}</title>
            </Helmet>
        );
    }
}
