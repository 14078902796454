/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";

import { apixHandler } from "../utilities/api/apix-handler";
import type { BodyType, ErrorType } from "../utilities/api/apix-handler";
import type {
    AdminBuildersSearchGetV2Params,
    BuilderDropdownResponseV2,
    ExternalBuilderResponse,
    ExternalBuildersRequest,
    ForbiddenResponse,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <div>
<b>Authorization:</b> <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalUser</code>
</div>
**Stability Level**: `stable`
 * @summary Get information about the Builder the account is currently associated with.
 */
export const buildersGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalBuilderResponse>(
        { url: `/apix/v2/Builders`, method: "GET", signal },
        options
    );
};

export const getBuildersGetV2QueryKey = () => {
    return [`/apix/v2/Builders`] as const;
};

export const getBuildersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof buildersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof buildersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getBuildersGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buildersGetV2>>> = ({ signal }) =>
        buildersGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof buildersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type BuildersGetV2QueryResult = NonNullable<Awaited<ReturnType<typeof buildersGetV2>>>;
export type BuildersGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the Builder the account is currently associated with.
 */
export const useBuildersGetV2 = <
    TData = Awaited<ReturnType<typeof buildersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof buildersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getBuildersGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalService</code>
</div>
**Stability Level**: `stable`
 */
export const buildersPostV2 = (
    externalBuildersRequest: BodyType<ExternalBuildersRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<ExternalBuilderResponse[]>(
        {
            url: `/apix/v2/Builders`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: externalBuildersRequest,
        },
        options
    );
};

export const getBuildersPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof buildersPostV2>>,
        TError,
        { data: BodyType<ExternalBuildersRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof buildersPostV2>>,
    TError,
    { data: BodyType<ExternalBuildersRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof buildersPostV2>>,
        { data: BodyType<ExternalBuildersRequest> }
    > = (props) => {
        const { data } = props ?? {};

        return buildersPostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type BuildersPostV2MutationResult = NonNullable<Awaited<ReturnType<typeof buildersPostV2>>>;
export type BuildersPostV2MutationBody = BodyType<ExternalBuildersRequest>;
export type BuildersPostV2MutationError = ErrorType<unknown>;

export const useBuildersPostV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof buildersPostV2>>,
        TError,
        { data: BodyType<ExternalBuildersRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof buildersPostV2>>,
    TError,
    { data: BodyType<ExternalBuildersRequest> },
    TContext
> => {
    const mutationOptions = getBuildersPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalService</code>
</div>
**Stability Level**: `stable`
 * @summary Get information about a specified builder.
 */
export const buildersByBuilderIdGetV2 = (
    builderId: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalBuilderResponse>(
        { url: `/apix/v2/Builders/${builderId}`, method: "GET", signal },
        options
    );
};

export const getBuildersByBuilderIdGetV2QueryKey = (builderId: string) => {
    return [`/apix/v2/Builders/${builderId}`] as const;
};

export const getBuildersByBuilderIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
    TError = ErrorType<void>
>(
    builderId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getBuildersByBuilderIdGetV2QueryKey(builderId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>> = ({
        signal,
    }) => buildersByBuilderIdGetV2(builderId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!builderId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type BuildersByBuilderIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>
>;
export type BuildersByBuilderIdGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about a specified builder.
 */
export const useBuildersByBuilderIdGetV2 = <
    TData = Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
    TError = ErrorType<void>
>(
    builderId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getBuildersByBuilderIdGetV2QueryOptions(builderId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>AdminUser</code>
</div>
**Stability Level**: `stable`
 * @summary Gets the list of builders for a builder select component
 */
export const adminBuildersSearchGetV2 = (
    params?: AdminBuildersSearchGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<BuilderDropdownResponseV2>(
        { url: `/apix/v2/admin/Builders/search`, method: "GET", params, signal },
        options
    );
};

export const getAdminBuildersSearchGetV2QueryKey = (params?: AdminBuildersSearchGetV2Params) => {
    return [`/apix/v2/admin/Builders/search`, ...(params ? [params] : [])] as const;
};

export const getAdminBuildersSearchGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    params?: AdminBuildersSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminBuildersSearchGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminBuildersSearchGetV2>>> = ({
        signal,
    }) => adminBuildersSearchGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminBuildersSearchGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminBuildersSearchGetV2>>
>;
export type AdminBuildersSearchGetV2QueryError = ErrorType<ForbiddenResponse>;

/**
 * @summary Gets the list of builders for a builder select component
 */
export const useAdminBuildersSearchGetV2 = <
    TData = Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    params?: AdminBuildersSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getAdminBuildersSearchGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
