import { useEffect, useMemo, useReducer } from "react";

import { useHasValueChanged } from "utilities/useHasValueChanged";

import { IStickyContext, StickyContext } from "./StickyContext";
import { IStickyLayer } from "./StickyContext.type";
import { stickyLayerReducer, StickyLayerReplaceAction } from "./StickyLayer.reducer";

export const StickyProvider: React.FunctionComponent<{
    initialTopLayer?: IStickyLayer;
    initialBottomLayer?: IStickyLayer;
}> = ({ children, initialTopLayer, initialBottomLayer }) => {
    const [reducerState, dispatchLayerChangeAction] = useReducer(stickyLayerReducer, {
        top: initialTopLayer ? [initialTopLayer] : [],
        bottom: initialBottomLayer ? [initialBottomLayer] : [],
    });

    const providerValue: IStickyContext = useMemo(
        () => ({ ...reducerState, dispatchLayerChangeAction }),
        [reducerState]
    );

    const [hasInitialTopLayerChanged, oldInitialTopLayer] = useHasValueChanged(initialTopLayer);

    useEffect(() => {
        if (hasInitialTopLayerChanged) {
            dispatchLayerChangeAction(
                new StickyLayerReplaceAction(
                    {
                        oldLayer: oldInitialTopLayer,
                        newLayer: initialTopLayer,
                    },
                    "top"
                )
            );
        }
    }, [hasInitialTopLayerChanged, oldInitialTopLayer, initialTopLayer]);

    const [hasInitialBottomLayerChanged, oldInitialBottomLayer] =
        useHasValueChanged(initialBottomLayer);
    useEffect(() => {
        if (hasInitialBottomLayerChanged) {
            dispatchLayerChangeAction(
                new StickyLayerReplaceAction(
                    {
                        oldLayer: oldInitialBottomLayer,
                        newLayer: initialBottomLayer,
                    },
                    "bottom"
                )
            );
        }
    }, [hasInitialBottomLayerChanged, oldInitialBottomLayer, initialBottomLayer]);

    return <StickyContext.Provider value={providerValue}>{children}</StickyContext.Provider>;
};
