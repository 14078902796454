import classNames from "classnames";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";

import "./EditorControlButton.less";

export interface IEditorControlButtonProps<ActionType extends string | undefined = string> {
    "data-testid": string;
    isToggled: boolean;
    friendlyName: string;
    icon: React.ReactNode;
    onClick?: () => void;
    dropdown?: boolean;
    disabled?: boolean;
    loadingAction?: ActionType;
    actionBeingPreformed?: ActionType;
}

export function EditorControlButton<ActionType extends string | undefined = string>({
    "data-testid": dataTestId,
    isToggled,
    disabled,
    onClick,
    dropdown,
    icon,
    loadingAction,
    actionBeingPreformed,
    friendlyName,
}: IEditorControlButtonProps<ActionType>) {
    const buttonType = isToggled ? "primary" : "tertiary";

    return (
        // eslint-disable-next-line react/forbid-dom-props
        <span title={friendlyName}>
            <BTButton
                data-testid={dataTestId}
                onClick={onClick}
                type={buttonType}
                icon={actionBeingPreformed ? undefined : icon}
                disabled={disabled}
                aria-pressed={isToggled}
                className={classNames("EditorButton", { EditorDropdownButton: dropdown })}
                loadingAction={loadingAction}
                actionBeingPerformed={actionBeingPreformed}
            >
                {dropdown && <BTIconCaretSmallDown />}
            </BTButton>
        </span>
    );
}
