import { isNullOrWhitespace } from "utilities/string/string";

import {
    Channel,
    ChannelCreatedEvent,
    ChannelHistoryElement,
    ChannelHistoryElementType,
    ChannelMessageEvent,
    ChannelParticipant,
    ChannelParticipantStatus,
    ChannelReadEvent,
    ChannelType,
    ChatEvent,
    ChatEventType,
    IChannel,
    IDraftChannelState,
    UserAddedEvent,
} from "entity/chat/common/chat.types";

export function isDraftChannel(channel: IChannel): channel is IDraftChannelState {
    return channel.channelType === ChannelType.Draft;
}

export function isPublishedChannel(channel: IChannel): channel is Channel {
    return channel.channelType !== ChannelType.Draft;
}

export function isChannelMessageElement(
    event: ChannelHistoryElement | ChatEvent
): event is ChannelMessageEvent {
    return (
        (event as ChannelHistoryElement).channelHistoryElementType ===
        ChannelHistoryElementType.Message
    );
}

export function isChannelReadEvent(event: ChatEvent): event is ChannelReadEvent {
    return event.type === ChatEventType.ChannelRead;
}

export function isChannelCreatedEvent(event: ChatEvent): event is ChannelCreatedEvent {
    return event.type === ChatEventType.ChannelCreated;
}

export function isUserAddedEvent(event: ChatEvent): event is UserAddedEvent {
    return event.type === ChatEventType.UserAdded;
}

export function isEventRenderable(event: ChatEvent): event is ChatEvent & ChannelHistoryElement {
    return (event as ChannelHistoryElement).channelHistoryElementType !== undefined;
}

export function getChannelIdFromChatEvent(event: ChatEvent) {
    switch (event.type) {
        case ChatEventType.ChannelCreated:
            return event.channel.channelId;
        case ChatEventType.Message:
            return event.message.channelId;
        case ChatEventType.ChannelRead:
        case ChatEventType.UserAdded:
        case ChatEventType.UserRemoved:
            return event.channelId;
        default:
            const neverShouldOccur: never = event;
            throw new Error(`Unhandled event type: ${(neverShouldOccur as ChatEvent).type}`);
    }
}

export function getChannelName(
    userIds: Set<number> | null | undefined,
    channel: Channel | IDraftChannelState,
    maxUsersShown: number
): string {
    if (isDraftChannel(channel) || !isNullOrWhitespace(channel.channelName)) {
        return channel.channelName;
    }

    if (channel.participants.length === 0) {
        return "No Participants";
    }

    // messaging yourself
    if (channel.participants.length === 1 && userIds?.has(channel.participants[0].userId)) {
        return getChannelParticipantDisplayName(userIds, channel.participants[0]);
    }

    // generate a name based on the other users in the channel
    const participantsWithoutUser = channel.participants.filter((p) => !userIds?.has(p.userId));
    const limitedNamesList = participantsWithoutUser
        .map((p) => getChannelParticipantDisplayName(userIds, p).split(" ")[0])
        .sort()
        .slice(0, maxUsersShown);

    const participantsWithoutUserLength = participantsWithoutUser.length;
    if (participantsWithoutUserLength > maxUsersShown) {
        // add a + x at the end
        return (
            limitedNamesList.join(", ") +
            (participantsWithoutUserLength > maxUsersShown
                ? ", + " + (participantsWithoutUserLength - maxUsersShown)
                : "")
        );
    } else {
        // show each user, with an and before the last
        if (participantsWithoutUserLength < 2) {
            // direct message
            return getChannelParticipantDisplayName(userIds, participantsWithoutUser[0]);
        } else {
            const last = limitedNamesList.pop();
            const oxfordComma = participantsWithoutUserLength > 2 ? "," : "";
            return limitedNamesList.join(", ") + oxfordComma + " and " + last;
        }
    }
}

export function getChannelParticipantDisplayName(
    currentUserIds: Set<number> | null | undefined,
    participant: ChannelParticipant | undefined
): string {
    if (!participant) {
        return "";
    }

    if (currentUserIds?.has(participant.userId)) {
        return participant.name + " (You)";
    }

    return getNameWithStatus(participant.name, participant.status);
}

export function getNameWithStatus(name: string, status?: ChannelParticipantStatus) {
    switch (status) {
        case ChannelParticipantStatus.Inactive:
            return name + " (Inactive)";
        case ChannelParticipantStatus.Deleted:
            return "Deleted User";
        case ChannelParticipantStatus.Active:
        default:
            return name;
    }
}
