const isBrowser: boolean = typeof document !== "undefined";

interface IInjectScriptArg {
    url: string;
    id: string;
}

export const injectScript = async ({ url, id }: IInjectScriptArg): Promise<any> => {
    if (!isBrowser) {
        return Promise.reject(new Error("document is undefined"));
    }

    return new Promise(function injectScriptCallback(resolve, reject) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.async = true;
        script.onload = function onload(): void {
            resolve(id);
        };
        script.onerror = function onerror(err): void {
            reject(err);
        };

        document.head.appendChild(script);
    }).catch((err) => {
        throw err;
    });
};
