import { cloneDeep } from "lodash-es";

import { EntityIdJobIdResponse } from "types/apiResponse/apiResponse";
import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler, PreviewActionAPIError } from "utilities/apiHandler";

import {
    CommentPermissions,
    DiscussionCommentEntity,
} from "commonComponents/entity/comment/CommentContainer/CommentContainer.api.types";

import {
    ChangeOrderEntity,
    ChangeOrderLineItem,
    ChangeOrderPresentingScreen,
    ChangeOrderResetStatusResponse,
    ChangeOrderSaveRequest,
    ChangeOrderVarianceImportResponse,
    IChangeOrderResetStatusRequest,
    IUpdateBidStatusFromChangeOrderRequest,
} from "./ChangeOrder.api.types";

export interface IChangeOrderHandler {
    get(coId: number, isFromBid?: boolean, isFromRfi?: boolean): Promise<ChangeOrderEntity>;
    getDefault(jobId: number): Promise<ChangeOrderEntity>;
    create(newValues: ChangeOrderSaveRequest, jobsiteId: number): Promise<EntityIdJobIdResponse>;
    resetStatus(
        coId: number,
        request: IChangeOrderResetStatusRequest
    ): Promise<ChangeOrderResetStatusResponse>;
    update(id: number, formData: ChangeOrderSaveRequest): Promise<EntityIdJobIdResponse>;
    delete(id: number): Promise<EmptyResponseEntity>;
    approveBids(request: IUpdateBidStatusFromChangeOrderRequest): Promise<EmptyResponseEntity>;
    declineBids(request: IUpdateBidStatusFromChangeOrderRequest): Promise<EmptyResponseEntity>;
    varianceImport(coId: number): Promise<ChangeOrderVarianceImportResponse>;
}

export class ChangeOrderHandler implements IChangeOrderHandler {
    async get(coId: number, isFromBid?: boolean, isFromRfi?: boolean): Promise<ChangeOrderEntity> {
        const presentingScreen =
            isFromBid && !isFromRfi
                ? ChangeOrderPresentingScreen.FromBidRequest
                : isFromRfi && !isFromBid
                ? ChangeOrderPresentingScreen.FromRFI
                : ChangeOrderPresentingScreen.None;
        return await APIHandler(`/api/ChangeOrders/${coId}/changeOrder`, {
            method: "GET",
            data: { presentingScreen },
            responseType: ChangeOrderEntity,
        });
    }

    async getDefault(jobId: number): Promise<ChangeOrderEntity> {
        return await APIHandler(`/api/ChangeOrders/Defaults`, {
            method: "GET",
            data: { jobId },
            responseType: ChangeOrderEntity,
        });
    }

    async create(
        requestData: ChangeOrderSaveRequest,
        jobsiteId: number
    ): Promise<EntityIdJobIdResponse> {
        const { formData, ...restData } = requestData;
        return await APIHandler(`/api/ChangeOrders/Update`, {
            method: "POST",
            data: { ...formData, ...restData, jobsiteId },
            responseType: EntityIdJobIdResponse,
        });
    }

    /**
     * This method is for setting the CO status to a new status. It can be used for any status, we're not really "resetting" anything here.
     *
     * @param coId Change Order Id
     * @param request New Status Request object
     */
    async resetStatus(
        coId: number,
        request: IChangeOrderResetStatusRequest
    ): Promise<ChangeOrderResetStatusResponse> {
        return await APIHandler(`/api/ChangeOrders/${coId}/ResetStatus`, {
            method: "POST",
            data: request,
            responseType: ChangeOrderResetStatusResponse,
        });
    }

    async update(
        coId: number,
        requestData: ChangeOrderSaveRequest
    ): Promise<EntityIdJobIdResponse> {
        const { formData, ...restData } = requestData;
        return await APIHandler(`/api/ChangeOrders/${coId}/Update`, {
            method: "PUT",
            data: { ...formData, ...restData },
            responseType: EntityIdJobIdResponse,
        });
    }

    async delete(coId: number): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/ChangeOrders/${coId}`, {
            method: "DELETE",
            responseType: EmptyResponseEntity,
        });
    }

    async approveBids(
        request: IUpdateBidStatusFromChangeOrderRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/bids/ApproveBidsFromChangeOrder`, {
            method: "PUT",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }

    async declineBids(
        request: IUpdateBidStatusFromChangeOrderRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/bids/DeclineBidsFromChangeOrder`, {
            method: "PUT",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }

    async varianceImport(coId: number): Promise<ChangeOrderVarianceImportResponse> {
        return await APIHandler(`/api/ChangeOrders/${coId}/VarianceImport`, {
            method: "GET",
            responseType: ChangeOrderVarianceImportResponse,
        });
    }
}

export class InlinePreviewChangeOrderHandler implements IChangeOrderHandler {
    private changeOrder: ChangeOrderEntity | undefined;

    constructor(changeOrder?: ChangeOrderEntity) {
        this.changeOrder = cloneDeep(changeOrder);
        this.changeOrder!.discussions.comments.comments = changeOrder!.discussions.comments.comments
            .map((x: DiscussionCommentEntity) => {
                return x.toOwnerPreviewComment();
            })
            .filter((comment) => {
                return comment.showOwner === true;
            });
        this.changeOrder!.discussions.addNewCommentPermissions = new CommentPermissions({
            canDelete: false,
            canShowOwner: false,
            canShowSubs: true,
            defaultShowSubs: false,
            defaultShowOwner: false,
        });
        if (this.changeOrder?.attachedFiles) {
            this.changeOrder!.attachedFiles.files = this.changeOrder!.attachedFiles?.files.filter(
                (file) => {
                    return file.viewingPermissions?.showOwner === true;
                }
            );
        }
        if (this.changeOrder?.attachedFilesPostApproval) {
            this.changeOrder!.attachedFilesPostApproval.attachedFiles.files =
                this.changeOrder!.attachedFilesPostApproval?.attachedFiles.files.filter((file) => {
                    return file.viewingPermissions?.showOwner === true;
                });
        }
        if (this.changeOrder?.attachedFilesPreApproval) {
            this.changeOrder!.attachedFilesPreApproval.attachedFiles.files =
                this.changeOrder!.attachedFilesPreApproval?.attachedFiles.files.filter((file) => {
                    return file.viewingPermissions?.showOwner === true;
                });
        }
        this.changeOrder!.changeOrderLineItems = this.changeOrder!.changeOrderLineItems.map(
            (x: ChangeOrderLineItem) =>
                new ChangeOrderLineItem({
                    ...x,
                    unitCost: Math.round((x.ownerPrice / x.quantity) * 10000) / 10000,
                })
        );
    }

    async get(): Promise<ChangeOrderEntity> {
        return this.changeOrder!;
    }

    async getDefault(): Promise<ChangeOrderEntity> {
        return this.changeOrder!;
    }

    async create(): Promise<EntityIdJobIdResponse> {
        throw new PreviewActionAPIError();
    }

    async resetStatus(): Promise<ChangeOrderResetStatusResponse> {
        throw new PreviewActionAPIError();
    }

    async update(): Promise<EntityIdJobIdResponse> {
        throw new PreviewActionAPIError();
    }

    async delete(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async approveBids(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async declineBids(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async varianceImport(): Promise<ChangeOrderVarianceImportResponse> {
        throw new PreviewActionAPIError();
    }
}
