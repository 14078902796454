import type { default as MediaListJson } from "entity/media/common/MediaList.api.json";

type MediaListEntityApiData = typeof MediaListJson;
type BTViewingPermissionsApiData = MediaListEntityApiData["files"][number]["viewPermissions"];
type BTFilePermissionsApiData = MediaListEntityApiData["files"][number]["permissions"];

export class BTViewingPermissions {
    constructor(data: BTViewingPermissionsApiData) {
        this.showSubs = data.showSubs;
        this.showAllSubs = data.showAllSubs;
        this.showOwner = data.showOwner;
        this.mainFile = data.mainFile;
    }

    showSubs: boolean;
    showAllSubs: boolean;
    showOwner: boolean;
    mainFile: boolean;
}

export class BTFilePermissions {
    constructor(data: BTFilePermissionsApiData) {
        this.canAddRfis = data.canAddRfis;
        this.canAnnotate = data.canAnnotate;
        this.canComment = data.canComment;
        this.canCopy = data.canCopy;
        this.canDelete = data.canDelete;
        this.canDrawOnline = data.canDrawOnline;
        this.canEdit = data.canEdit;
        this.canEditOnline = data.canEditOnline;
        this.canMove = data.canMove;
        this.canRequestSignatures = data.canRequestSignatures;
        this.canSetAsOwnerFeature = data.canSetAsOwnerFeature;
        this.canSetViewingPermissions = data.canSetViewingPermissions;
        this.canSign = data.canSign;
        this.canViewRfis = data.canViewRfis;
        this.canEditDailyLogs = data.canEditDailyLogs;
        this.canEditToDos = data.canEditToDos;
        this.canEditScheduleItems = data.canEditScheduleItems;
        this.canEditChangeOrders = data.canEditChangeOrders;
        this.canEditWarranties = data.canEditWarranties;
        this.canEditSelections = data.canEditSelections;
        this.canEditBids = data.canEditBids;
    }

    canAddRfis: boolean;
    canAnnotate: boolean;
    canComment: boolean;
    canCopy: boolean;
    canDelete: boolean;
    canDrawOnline: boolean;
    canEdit: boolean;
    canEditOnline: boolean;
    canMove: boolean;
    canRequestSignatures: boolean;
    canSetAsOwnerFeature: boolean;
    canSetViewingPermissions: boolean;
    canSign: boolean;
    canViewRfis: boolean;
    canEditDailyLogs: boolean;
    canEditToDos: boolean;
    canEditScheduleItems: boolean;
    canEditChangeOrders: boolean;
    canEditWarranties: boolean;
    canEditSelections: boolean;
    canEditBids?: boolean;
}
