import { JSONContent } from "@tiptap/react";
import moment from "moment";
import { Key } from "react";
// eslint-disable-next-line no-restricted-imports
import * as yup from "yup";

import {
    commonPasswords,
    securePasswordMaxLength,
    securePasswordMinLength,
    usernameMaxLength,
    usernameMinLength,
} from "types/CredentialConstants";

import { charactersOverLimitValidator, charactersUnderLimitValidator } from "utilities/form/form";
import { isNullOrUndefined } from "utilities/object/object";
import { IIsInPortalProps, isInPortal } from "utilities/portal/portal";
import { isNullOrWhitespace } from "utilities/string/string";

import { IEntityLinkAttributes } from "commonComponents/btWrappers/editor/core/extensions/custom/entityLink/EntityLinkExtension";
import { IImageAttributes } from "commonComponents/btWrappers/editor/core/extensions/custom/image/ImageExtension";
import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

export const EditorValidationErrorMessages: yup.IEditorValidationErrorMessages = {
    imagesNotUploaded: "Some images are still uploading",
    maxContentSize: "Content size exceeds the maximum limit",
    requireContent: "Content must not be empty",
    entityLinkNotLoaded: "Some links are still loading",
    unspecifiedError: "Editor content not valid",
};

/**
 * Use yup locale to set default error message
 */
yup.setLocale({
    mixed: {
        required: "Required",
        // notType: "Looks like that's the wrong type",
    },
    string: {
        required: "Required",
        min: charactersUnderLimitValidator as any,
        max: charactersOverLimitValidator as any,
        notType: "Must be a string",
    },
    number: {
        required: "Required",
        min: "Min value ${min}",
        max: "Max value ${max}",
        notType: "Must be a number",
    },
    boolean: {
        required: "Required",
    },
    date: {
        required: "Required",
    },
    array: {
        required: "Required",
    },
    object: {
        required: "Required",
    },
} as any); // as any is needed because yup.d.ts LocaleObject definition is missing notType

/**
 * Validates a moment date is valid
 */
yup.addMethod(
    yup.mixed,
    "validMomentDate",
    function (
        type: "date" | "time" | "year" | "month" | "week",
        message: string = "Selected ${type} must be valid"
    ) {
        const messageWithType = message.replace(/(\$\{type\})/g, type);
        return this.test("validMomentDate", messageWithType, function (value) {
            const { path, createError } = this;

            if (value === undefined || value === null) {
                return true;
            }

            if (!moment.isMoment(value) || !value.isValid()) {
                return createError({ path: path, message: messageWithType });
            }

            return true;
        });
    }
);

yup.addMethod(
    yup.mixed,
    "minMomentDate",
    function (
        date: moment.Moment,
        message: string = "Date must be on or after ${date}",
        dateFormat: string = "ll"
    ) {
        const messageWithDate = message.replace(/(\$\{date\})/g, date.format(dateFormat));
        return this.test("minMomentDate", messageWithDate, function (value) {
            const { path, createError } = this;
            if (value === undefined || value === null) {
                return true;
            }
            if (date.isAfter(moment(value), "day")) {
                return createError({ path: path, message: messageWithDate });
            }
            return true;
        });
    }
);

yup.addMethod(
    yup.mixed,
    "maxMomentDate",
    function (
        date: moment.Moment,
        message: string = "Date must be on or before ${date}",
        dateFormat: string = "ll"
    ) {
        const messageWithDate = message.replace(/(\$\{date\})/g, date.format(dateFormat));
        return this.test("maxMomentDate", messageWithDate, function (value) {
            const { path, createError } = this;
            if (value === undefined || value === null) {
                return true;
            }
            if (date.isBefore(moment(value), "day")) {
                return createError({ path: path, message: messageWithDate });
            }
            return true;
        });
    }
);

/**
 * TODO Remove when yup is updated to > v0.29.1. Also remove in yup types file
 */
yup.addMethod(yup.mixed, "defined", function (message: string = "Required") {
    return this.test("defined", message, (value) => value !== undefined);
});

/**
 * Run a test on a specific portal
 */
yup.addMethod(
    yup.number,
    "testOnPortal",
    function (
        portals: IIsInPortalProps,
        test: (value: number) => boolean,
        message: string = "Required"
    ) {
        return this.test("testOnPortal", message, function (value) {
            if (isInPortal(portals)) {
                return test.call(this, value);
            }

            return true;
        });
    }
);

/**
 * Verifies a dropdown isn't its default value (-1 by default)
 */
yup.addMethod(
    yup.number,
    "requiredDropdown",
    function (defaultValue: number = -1, message: string = "Required") {
        return this.required(message).test("requiredDropdown", message, function (value) {
            return value !== defaultValue;
        });
    }
);

/**
 * Verifies a dropdown isn't its default value (-1 or undefined)
 */
yup.addMethod(yup.array, "requiredDropdown", function (message: string = "Required") {
    return this.required(message).test("requiredDropdown", message, function (value) {
        return value !== -1 && value !== undefined;
    });
});

/**
 * Verifies a dropdown isn't its default value ("-1" by default)
 */
yup.addMethod(
    yup.string,
    "requiredDropdown",
    function (defaultValue: string = "-1", message: string = "Required") {
        return this.required(message).test("requiredDropdown", message, function (value) {
            return value !== defaultValue && value !== undefined;
        });
    }
);

/**
 * Override of the required string validator in order to prevent whitespace from passing validation
 */
yup.addMethod(yup.string, "required", function (message: string = "Required") {
    return this.test("required", message, function (value: string | undefined | null) {
        return value !== undefined && value !== null && value !== "" && value.trim().length > 0;
    });
});

/**
 * Verifies a string is a valid 6 character color hex code
 */
yup.addMethod(
    yup.string,
    "colorHexCode",
    function (includeHash: boolean, message: string = "Please enter a valid hex code") {
        const regex = includeHash ? /^#[0-9A-Fa-f]{6}$/ : /^[0-9A-Fa-f]{6}$/;
        return this.test("colorHexCode", message, function (value) {
            return regex.test(value);
        });
    }
);

/**
 * Verifies a string is a valid username
 */
yup.addMethod(yup.string, "username", function () {
    const usernameCharsRegex =
        /^[""$&(*,.0-9:<>@A-Z\\^`a-z|~¢¤¦¨ª¬®°²´¶¸º¼¾ÀÂÄÆÈÊÌÎÐÒÔÖØÚÜÞàâäæèêìîðòôöøúüþŒŠŸžˆ–‘‚”†•‰›™!#%')+\-/;=?[\]_{}¡£¥§©«­¯±³µ·¹»½¿ÁÃÅÇÉËÍÏÑÓÕ×ÙÛÝßáãåçéëíïñóõ÷ùûýÿœšŽƒ˜—’“„‡…‹€]*$/;

    return this.test("username", "Invalid username", function (value: string) {
        if (value === undefined || value === null) {
            return true;
        }

        const { path, createError } = this;
        if (value.length < usernameMinLength) {
            return createError({
                path: path,
                message: `Minimum length ${usernameMinLength} characters`,
            });
        } else if (value.length > usernameMaxLength) {
            return createError({
                path: path,
                message: `Maximum length ${usernameMaxLength} characters`,
            });
        } else if (!usernameCharsRegex.test(value)) {
            return createError({ path: path, message: "Username contains an invalid character" });
        }
        return true;
    });
});

/**
 * Verifies a string is a valid secure password
 */
yup.addMethod(yup.string, "securePassword", function () {
    return this.test("secure password", "Invalid password", function (value: string) {
        return validatePassword(
            this.path,
            this.createError,
            securePasswordMinLength,
            securePasswordMaxLength,
            value
        );
    });
});

function validatePassword(
    path: any,
    createError: any,
    specifiedPasswordMinLength: number,
    specifiedPasswordMaxLength: number,
    value: string
) {
    if (value === undefined || value === null) {
        return true;
    }

    if (value.length < specifiedPasswordMinLength) {
        return createError({
            path: path,
            message: `Minimum length ${specifiedPasswordMinLength} characters`,
        });
    } else if (value.length > specifiedPasswordMaxLength) {
        return createError({
            path: path,
            message: `Maximum length ${specifiedPasswordMaxLength} characters`,
        });
    } else if (value.toLowerCase().includes("password")) {
        return createError({ path: path, message: "Cannot contain the word 'password'" });
    }

    const lowerCaseRegex = new RegExp("[a-z]");
    const upperCaseRegex = new RegExp("[A-Z]");
    const numberRegex = new RegExp("[0-9]");
    const rightBracketRegex = new RegExp("\u005D");
    const specialCharRegex = new RegExp("[ !#$%&'()*+,-./:;<=>?@[^_`|~\"\\{}]");

    let criteriaMet = 0;

    criteriaMet += lowerCaseRegex.test(value) ? 1 : 0;
    criteriaMet += upperCaseRegex.test(value) ? 1 : 0;
    criteriaMet += numberRegex.test(value) ? 1 : 0;
    criteriaMet += specialCharRegex.test(value) || rightBracketRegex.test(value) ? 1 : 0;

    if (criteriaMet < 3) {
        return createError({
            path: path,
            message:
                "Password must contain at least 3 of the following: Lower case character, Upper case character, Number, Special character (eg. !@#$%^&*)",
        });
    }

    if (commonPasswords.some((cp) => cp.toLowerCase() === value.toLowerCase())) {
        return createError({
            path: path,
            message: "This password is not secure. Please choose a stronger password.",
        });
    }

    return true;
}

yup.addMethod(yup.string, "multipleEmails", function () {
    return validateMultipleEmails(this as yup.StringSchema);
});

function validateMultipleEmails(schema: yup.StringSchema) {
    return schema.test("Multiple emails test", "Must be a valid address", function (value: string) {
        return (
            !value || value.split(";").every((email) => schema.email().isValidSync(email.trim()))
        );
    });
}

yup.addMethod(
    yup.object,
    "map",
    function (
        mappers: Record<
            Key,
            yup.Schema<unknown> | ((schema: yup.Schema<unknown>) => yup.Schema<unknown>)
        >
    ) {
        const oldFields = Object.fromEntries(
            Object.keys(this.describe().fields).map((key) => [key, null] as const)
        );

        return this.concat(
            yup.object().shape(
                Object.fromEntries(
                    Object.entries({ ...oldFields, ...mappers }).map(([key, value]) => {
                        return [
                            key,
                            typeof value === "function"
                                ? value(yup.reach(this, key))
                                : value ?? yup.reach(this, key),
                        ];
                    })
                )
            )
        );
    }
);

export default yup;

/**
 * Returns the SchemaDescription for the field at the given path in the schema
 * @param schema Schema for the form
 * @param path Path to a given field in the form
 * @param value All form values for schema.  Only needed for lazy loaded schemas
 */
export function getFieldSchema<FormValues>(
    schema: yup.Schema<FormValues>,
    path: string,
    value?: FormValues
): yup.SchemaDescription {
    let result = yup.reach(schema, path, value);
    return result.describe();
}

/**
 * Returns true if a given field is required in the yup schema, else false
 * @param schema Schema for the form
 * @param fieldName Path to a given field in the form
 * @param value All form values for schema.  Only needed for lazy loaded schemas
 */
export function isFieldRequired<FormValues>(
    schema: any,
    fieldName: string,
    value?: FormValues
): boolean {
    return (
        getFieldSchema(schema, fieldName, value).tests.find(
            (validator: any) => validator.name === "required"
        ) !== undefined
    );
}

const base64ImagesRegex = /^data:image/;

/**
 * Images get uploaded as base64 encoded data strings. However, if we were to
 * upload this to the server as is, it would often exceed your standard content
 * limits. Images/documents use a different storage quota though, so it doesn't
 * make sense to include the data from images as part of the content limit.
 *
 * This function transforms data blobs with 1000 character dummy strings to simulate
 * the worst case size of the file URL that the base64 image will become post
 * save/upload
 **/
function transformJsonNodeForContentSizing(
    value: JSONContent,
    validationParameters: yup.IEditorContentValidationParameters
) {
    if (value.type !== "image") {
        return value;
    }

    const attrs: IImageAttributes = value.attrs as IImageAttributes;
    const isBase64Image = base64ImagesRegex.test(attrs.src);
    if (validationParameters.hasBase64Images && isBase64Image) {
        return "~".repeat(1000);
    }

    return value;
}

function validateImageNodesAreValid(
    value: JSONContent,
    validationParameters: yup.IEditorContentValidationParameters,
    errorMessages: typeof EditorValidationErrorMessages
): null | string {
    const attrs: IImageAttributes = value.attrs as IImageAttributes;

    const isBase64Image = base64ImagesRegex.test(attrs.src);
    const isUploaded = attrs.src.startsWith("https:") && !!attrs.uploadMetadata;
    if (!validationParameters.hasBase64Images && isBase64Image) {
        return errorMessages.unspecifiedError;
    }

    if (validationParameters.hasUploadedImages && !isUploaded) {
        return errorMessages.imagesNotUploaded;
    }

    return null;
}

function validateEntityLinkNodesAreValid(
    value: JSONContent,
    errorMessages: typeof EditorValidationErrorMessages
): null | string {
    const attrs: IEntityLinkAttributes = value.attrs as IEntityLinkAttributes;

    if (!attrs.externalId) {
        return errorMessages.entityLinkNotLoaded;
    }

    return null;
}

function validateJsonNode(
    value: JSONContent,
    validationParameters: yup.IEditorContentValidationParameters,
    errorMessages: yup.IEditorValidationErrorMessages
): string | null {
    if (value.type === "image") {
        return validateImageNodesAreValid(value, validationParameters, errorMessages);
    }
    if (value.type === "entityLink") {
        return validateEntityLinkNodesAreValid(value, errorMessages);
    }
    return null;
}

/**
 * Should be relatively stable, but this is a bit hacky. We can't pull names using extension.name from Tiptap
 * because that would require importing TipTap in this file, which will import tiptap EVERYWHERE. For
 * now, we have to resort to hard coding these extension types and "contentful" behaviors. Long term
 * though, we may be able to do this upon module import of useBtEditor.ts to allow us to use the
 * actual extension names at least.
 */
function isContentfulNode(node: JSONContent) {
    switch (node.type) {
        case "text":
            return !!node.text && node.text.length > 0;
        case "image":
        case "emoji":
        case "entityLink":
            return true;
        default:
            return false;
    }
}

yup.addMethod(
    yup.mixed,
    "editorContentValid",
    function (
        validationParameters: yup.IEditorContentValidationParameters,
        messages?: Partial<yup.IEditorValidationErrorMessages>
    ) {
        const errorMessages = { ...EditorValidationErrorMessages, ...messages };

        return this.test(
            "editorContentValid",
            errorMessages.unspecifiedError,
            function (value: EditorContent) {
                // Ignore check if there is no value
                if (isNullOrUndefined(value)) {
                    return true;
                }

                const { maxContentSize = 1e6, requireContent = false } = validationParameters;

                if (maxContentSize > 1e6) {
                    // This means the content is around 1 MB in size, which is much more than it should ever be
                    throw new Error("Unreasonable maximum size has been selected");
                }

                let contentSizeString: string;
                let hasContentfulNode = false;
                let currentError: string | null = null;
                if (typeof value === "string") {
                    contentSizeString = value;
                } else {
                    contentSizeString = JSON.stringify(value.content, (_, value: unknown) => {
                        if (typeof value === "object" && value && "type" in value) {
                            const jsonNode = value as JSONContent;
                            currentError =
                                currentError ||
                                validateJsonNode(jsonNode, validationParameters, errorMessages);

                            hasContentfulNode = hasContentfulNode || isContentfulNode(jsonNode);

                            return transformJsonNodeForContentSizing(
                                jsonNode,
                                validationParameters
                            );
                        }

                        return value;
                    });
                }

                const contentSize = contentSizeString.length;

                if (contentSize > maxContentSize) {
                    currentError = currentError || errorMessages.maxContentSize;
                }

                if (!hasContentfulNode && requireContent) {
                    currentError = currentError || errorMessages.requireContent;
                }

                if (currentError) {
                    return this.createError({ path: this.path, message: currentError });
                }

                return true;
            }
        );
    }
);

yup.addMethod(
    yup.string,
    "maxLengthExcludingBase64Images",
    function (maxContentSize: number = 200000, message: string = "Max Content Size Exceeded") {
        return this.test("maxLengthExcludingBase64Images", message, function (value: string) {
            return (
                isNullOrWhitespace(value) ||
                value.trim().length - embeddedImagesHtmlLength(value ?? "") <= maxContentSize
            );
        });
    }
);

export const embeddedImagesHtmlLength = (inputText: string) => {
    if (inputText.trim().length === 0) {
        return 0;
    }
    // Regular expression to match all occurrences of '<img src="data:image" />'
    const regex = /<img[^>]*src="data:image[^>]*>/g;
    // Array to store matches
    const matches = [];
    let match;
    // Extract all matches
    while ((match = regex.exec(inputText)) !== null) {
        matches.push(match[0]);
    }
    // Calculate total length of photos
    const totalLengthOfPhotos = matches.reduce(function (total, photo) {
        return total + photo.length;
    }, 0);
    return totalLengthOfPhotos;
};
