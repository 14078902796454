import { Component } from "react";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconNotifications } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import {
    EmptyStateDataType,
    emptyStateIconSize,
    IEmptyStateEntityProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { EmptyState, getEmptyStateDataType } from "commonComponents/entity/emptyState/EmptyState";
import { FilteredEmptyState } from "commonComponents/entity/emptyState/FilteredEmptyState";

interface INotificationPanelEmptyStateProps extends IEmptyStateEntityProps {}

export class NotificationPanelEmptyState extends Component<INotificationPanelEmptyStateProps> {
    private getEmptyStateContent = () => {
        return (
            <BTRow>
                <BTCol xs={24} style={{ lineHeight: "20px" }}>
                    {getNotificationEmptyStateDescription()}
                </BTCol>
            </BTRow>
        );
    };

    render() {
        const dataType = getEmptyStateDataType(this.props);
        if (dataType === null) {
            return null;
        }

        if (dataType === EmptyStateDataType.FilteredState) {
            return <FilteredEmptyState entityTitlePlural="Notifications" />;
        }

        return (
            <EmptyState
                title={getNotificationPanelEmptyStateTitle()}
                image={
                    <BTIconNotifications
                        size={emptyStateIconSize}
                        className="EmptyStateIcon"
                        data-testid="EmptyStateIcon"
                        isOnWhite
                    />
                }
            >
                {this.getEmptyStateContent()}
            </EmptyState>
        );
    }
}

export function getNotificationPanelEmptyStateTitle() {
    return "Be notified of your assignments and other activity";
}

export function getNotificationEmptyStateDescription() {
    return "Here's where you will be notified of your assignments, new messages and other activity you follow.";
}
