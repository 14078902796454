import { LocationDescriptor } from "history";
import { useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { routes } from "utilities/routes";

import { BTButton, IBTButtonProps } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";

interface ILinkButtonPropsOptional {
    replace?: boolean;
    isolated?: boolean;
    underline?: boolean;
    noShadow?: boolean;

    to: string;
    dontUseMatchUrl?: boolean;
    /**
     * If true will treat `to` as an external link.
     * @default false
     */
    isExternal?: boolean;

    /**
     * If true code will adjust passed link url and open in a new tab.
     * @default false
     */
    showInNewTab?: boolean;
}

type ILinkButtonProps<ActionType> = Omit<
    IBTButtonProps<ActionType>,
    "isolated" | "underline" | "noShadow" | "target" | "rel"
> &
    ILinkButtonPropsOptional;

const noop = () => {};

/**
 * function to use with showInNewTab prop.
 * showInNewTab prop is going to assume you are opening a /app specific page.
 * Some of the urls passed in to links pass the routes.appBase already by either directly appending it
 * or by the getBaseRoute() method. We have difference on how that approach works based on a
 * combination of in iframe usage, in SPA usage, and in combination with Link component usage.
 * The newly added prop leverages this function to get it right regardless  of the other combination.
 * Please contact Architecture if you find you need to adjust this for additional use case.
 * TODO: (SPA cleanup) Re-evaluate usage of function when fully SPA.
 */
const formatRouteForNewTab = (to: string) => {
    if (
        to?.toString().toLowerCase().startsWith("/") &&
        !to?.toString().toLowerCase().startsWith("/app")
    ) {
        return `${routes.appBase}${to}`;
    }
    return to;
};

/**
 * LinkButton utilizes the route in the `to` property to navigate to the
 * provided route when clicked. When `isExternal` is true it will handle
 * navigation to an external link.
 */
export function LinkButton<ActionType>(
    props: React.PropsWithChildren<ILinkButtonProps<ActionType>>
): ReturnType<React.FunctionComponent<ILinkButtonProps<ActionType>>> {
    const {
        replace,
        to,
        showInNewTab,
        isExternal = false,
        dontUseMatchUrl,
        ...buttonProps
    } = props;
    const { onClick = noop } = buttonProps;

    const history = useHistory();
    const match = useRouteMatch();

    // click event ported from https://github.com/remix-run/react-router/blob/b529499efcb906c814b9a3a68e2b4292a15b09c8/packages/react-router-dom/modules/Link.js#L47
    const handleButtonClick = useCallback(
        (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>) => {
            try {
                onClick(event as any);
            } catch (ex) {
                event.preventDefault();
                throw ex;
            }

            if (isExternal) {
                return;
            }

            const navigate = () => {
                if (showInNewTab) {
                    window.open(formatRouteForNewTab(to), "_blank");
                    return;
                }

                const toInternal = dontUseMatchUrl ? to : `${match.url}${to}`;
                const method: (location: LocationDescriptor) => void = replace
                    ? history.replace
                    : history.push;

                method(toInternal);
            };

            // onClick prevented default
            if (!event.defaultPrevented) {
                event.preventDefault();
                navigate();
            }
        },
        [
            history.push,
            history.replace,
            isExternal,
            showInNewTab,
            match.url,
            onClick,
            replace,
            to,
            dontUseMatchUrl,
        ]
    );

    if (isExternal) {
        const handleClick = onClick as (event: React.MouseEvent<any, MouseEvent>) => void;
        return (
            <BTExternalLink
                href={to}
                data-testid={props["data-testid"]}
                isolated={props.isolated}
                disabled={props.disabled}
                onClick={handleClick}
                buttonType={props.type ?? "secondary"}
                className={props.className}
                hotkey={props.hotkey}
                icon={props.icon}
            >
                {props.children}
            </BTExternalLink>
        );
    }

    return <BTButton<ActionType> {...buttonProps} onClick={handleButtonClick} />;
}
