import {
    DocumentFileType,
    DocumentTemplate,
} from "entity/document/DocumentCreate/DocumentCreate.api.types";

export const rootLevelFolderId = 0;
export const attachedDocsFolderId = -1;
export const attachedPhotosFolderId = -4;
export const attachedVideosFolderId = -5;
export const ownerDocumentsFolderId = -3;
export const documentInstanceTypeMultiplier = -1000;
export const globalDocsFolderId = -99;
export const trashFolderId = -999;
export const maxAllowedFileSizeMB = 512;
export const uploadTrackingButtonSourceText = "Button";
export const uploadTrackingDragAndDropSourceText = "Drag & Drop";
export const contextMenuTrackingText = "Right Click Context Menu";
export const checkedActionTrackingText = "Checked Actions Menu";
export const mediaActionTrackingText = "Media Actions";
export const maxDaysInTrash = 14;

export class QueryStringParamNames {
    public static readonly DocumentInstanceId = "documentInstanceID";
    public static readonly IsOwner = "isOwner";
    public static readonly Encryption = "encryption";
}

export class DocumentConstants {
    public static readonly BlankWordDoc: DocumentTemplate = {
        fileType: DocumentFileType.Word,
        title: "Blank Word Document",
        documentId: 0,
        documentInstanceId: 0,
    };
    public static readonly BlankExcelDoc: DocumentTemplate = {
        fileType: DocumentFileType.Excel,
        title: "Blank Excel Spreadsheet",
        documentId: 0,
        documentInstanceId: 0,
    };

    public static readonly NewDocumentId: number = 0;

    public static readonly NewAnnotationId = -999;

    public static readonly NewSignatureRequestId = 0;
}
