import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IRfiProps } from "entity/rfi/Rfi/Rfi";

const Rfi = lazyLoadWithRetry(() => import("./Rfi"));

export const RouteRfi = (
    props: Omit<IRfiProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.rfi.details}
        render={(routeProps) => {
            const id = parseInt(routeProps.match.params.id);
            const jobId = parseInt(routeProps.match.params.jobId);

            return (
                <BTSuspense>
                    <Rfi id={id} jobId={jobId} {...props} {...routeProps} />
                </BTSuspense>
            );
        }}
    />
);
