import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import {
    ActivationResponse,
    IUserActivationRequest,
} from "commonComponents/entity/credentials/Credentials/Credentials.api.types";

import { LoginResponse, UserProfileResponse } from "entity/login/Login/Login.api.types";

import {
    IUserActivationLinkRequest,
    IUserActivationPageLoginRequest,
    UserActivationPageEntity,
} from "./UserActivationPage.api.types";

export interface IUserActivationPageHandler {
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    get: (shareToken: string) => Promise<UserActivationPageEntity>;
    createNewUser: (request: IUserActivationRequest) => Promise<EmptyResponseEntity>;
    linkExisting: (request: IUserActivationLinkRequest) => Promise<EmptyResponseEntity>;
    login: (request: IUserActivationPageLoginRequest) => Promise<LoginResponse>;
    profile: (userId: number) => Promise<UserProfileResponse>;
}

export class UserActivationPageHandler implements IUserActivationPageHandler {
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    async get(shareToken: string): Promise<UserActivationPageEntity> {
        return await APIHandler(`/apix/v2/Users/activation/${shareToken}`, {
            method: "GET",
            responseType: UserActivationPageEntity,
        });
    }

    async createNewUser(request: IUserActivationRequest): Promise<ActivationResponse> {
        return await APIHandler("/api/Users/CreateNewUser", {
            method: "POST",
            data: request,
            responseType: ActivationResponse,
        });
    }

    async linkExisting(request: IUserActivationLinkRequest): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Users/LinkExisting", {
            method: "PUT",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }

    async login(request: IUserActivationPageLoginRequest): Promise<LoginResponse> {
        return await APIHandler("/api/Login/AjaxLogin", {
            method: "POST",
            data: request,
            responseType: LoginResponse,
        });
    }

    async profile(userId: number): Promise<UserProfileResponse> {
        return await APIHandler("/api/Users/Profile", {
            method: "GET",
            data: { userId },
            responseType: UserProfileResponse,
        });
    }
}
