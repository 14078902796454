import { Button, Input } from "antd";
import { ButtonType } from "antd/lib/button";
import { SearchProps } from "antd/lib/input/Search";
import { ReactNode, useEffect, useRef } from "react";

import { track } from "utilities/analytics/analytics";

import "./BTSearch.less";

const { Search } = Input;

interface IBTSearchProps extends Omit<SearchProps, "enterButton" | "placeholder"> {
    enterButton?: string | ReactNode;
    enterButtonType?: ButtonType;
    autoFocus?: boolean;
    /** ref for the input */
    fieldRef?: React.RefObject<any>; // todo: ant designs type of this ref is wrong. Looks like its fixed in antd v4 - we can type this once we upgrade
    "data-testid"?: string;
    placeholder?: string;
}

export const BTSearch = track((props) => ({
    element: "Search Input",
    uniqueId: props["data-testid"],
}))(function ({
    enterButton,
    enterButtonType = "default",
    fieldRef,
    autoFocus,
    placeholder,
    ...otherProps
}: IBTSearchProps) {
    const refInternal = useRef<Input>(null);

    useEffect(() => {
        if (autoFocus && (fieldRef?.current || refInternal?.current)) {
            (fieldRef?.current || refInternal?.current)?.focus();
        }
    }, [autoFocus, fieldRef, refInternal]);

    return (
        <Search
            ref={fieldRef ?? refInternal}
            placeholder={placeholder ?? ""}
            className="BTSearch"
            enterButton={
                enterButton ? (
                    // eslint-disable-next-line react/forbid-elements
                    <Button type={enterButtonType}>{enterButton}</Button>
                ) : (
                    false
                )
            }
            {...otherProps}
        />
    );
});

export default BTSearch;
