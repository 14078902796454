import classNames from "classnames";
import { Component } from "react";

import { BTIconLoadingOutlined } from "commonComponents/btWrappers/BTIcon";

import "./BTLoading.less";

interface IBTLoadingProps {
    /**
     * By default delays the loading spinner from appearing for 1 second (but we still disable multiple clicks)
     * The delay is useful for short running tasks so a loading spinner isn't shown then immediately hidden
     * Only set this to true when you know the task will be very long running on all devices
     * @default false
     */
    instant?: boolean;

    /**
     * Sets the way the loading spinner is displayed
     * "fixed" - the spinner is fixed to the page so it follows the user when scrolling
     * "absolute" - the spinner is centered and contained within its nearest containing relative element and moves with it (use style={{ position: relative }})
     * "modal" - a special mode meant for BTModal, the spinner is fixed to the page and the background is extended to cover the visible part of the modal
     * @default "fixed"
     */
    displayMode?: "fixed" | "absolute" | "modal";

    loadingMessages?: string[];
    loadingMessageDelay?: number;
    className?: string;

    /**
     * If true, the loading spinner will have a gray background
     * @default true
     */
    showLoadingBackground?: boolean;
    "data-testid"?: string;
}

interface IBTLoadingState {
    show: boolean;
    loadingMessageIndex: number;
}

export class BTLoading extends Component<IBTLoadingProps, IBTLoadingState> {
    static defaultProps = {
        instant: false,
        displayMode: "fixed",
        loadingMessages: [],
        loadingMessageDelay: 0,
        showLoadingBackground: true,
    };

    state: Readonly<IBTLoadingState> = {
        show: false,
        loadingMessageIndex: -1,
    };

    private delayedLoading: number;
    private loadingMessageTimer: number;

    componentDidMount = () => {
        if (this.props.instant === false) {
            this.delayedLoading = setTimeout(() => {
                this.setState({ show: true });
            }, 300);
        } else {
            this.setState({ show: true });
        }

        if (this.props.loadingMessages!.length > 0) {
            this.fireLoadingTimer();
        }
    };

    componentWillUnmount = () => {
        if (this.delayedLoading) {
            clearTimeout(this.delayedLoading);
        }

        if (this.loadingMessageTimer) {
            clearTimeout(this.loadingMessageTimer);
        }
    };

    private fireLoadingTimer = () => {
        this.loadingMessageTimer = setTimeout(() => {
            let newIndex = this.state.loadingMessageIndex + 1;
            if (newIndex > this.props.loadingMessages!.length - 1) {
                newIndex = 0;
            }
            this.setState({ loadingMessageIndex: newIndex });
            this.fireLoadingTimer();
        }, this.props.loadingMessageDelay!);
    };

    render() {
        const loadingBackgroundClass = { loadingBackground: this.props.showLoadingBackground };

        if (!this.state.show) {
            return (
                <div className="BTLoading" data-testid="BTLoading">
                    <div className={classNames(loadingBackgroundClass, "transparent")} />
                </div>
            );
        }

        // add additional css class for fixed or modal mode
        let additionalClass = "";
        if (this.props.displayMode === "fixed") {
            additionalClass = "BTLoadingFixed";
        } else if (this.props.displayMode === "modal") {
            additionalClass = "BTLoadingModal";
        }

        return (
            <div
                data-testid={this.props["data-testid"]}
                className={classNames("BTLoading", additionalClass, this.props.className)}
            >
                <div className={classNames(loadingBackgroundClass)} />
                <div className="loadingIconPanel">
                    <BTIconLoadingOutlined className="loadingIcon" size="extraLarge" />
                    {this.state.loadingMessageIndex > -1 && (
                        <span className="loadingMessage">
                            {this.props.loadingMessages![this.state.loadingMessageIndex]}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
