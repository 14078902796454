import { AutoComplete } from "antd";
import classNames from "classnames";
import { useCallback, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { debounce_input_delay, max_debounce_delay } from "types/constants";

import { ITrackingProp, track } from "utilities/analytics/analytics";

import { BTIconCloseOutlined } from "commonComponents/btWrappers/BTIcon";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";

import "./BTAutoComplete.less";

export interface IBTAutoCompleteProps<T> {
    options: BTSelectItem<T>[] | undefined;
    placeholder?: React.ReactNode;
    dropdownRender?: (menu: React.ReactElement, isSearching: boolean) => React.ReactElement;
    notFoundContent?: (isSearching: boolean) => React.ReactNode;
    prefixIcon?: React.ReactNode;
    suffixIcon?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    inputDelay?: number;
    maxDelay?: number;
    clearResultsOnSearchStart?: boolean;
    allowClear?: boolean;
    "data-testid": string;
    open?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
    hotkeyDisabled?: boolean;
    clearIcon?: React.ReactNode;

    onSearch: (searchPhrase: string) => Promise<void>;
    onKeywordChange?: (searchPhrase: string) => void;
    onClear?: () => void;
    onPrefixIconClick?: () => void;
    onFocus?: () => void;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    onClick?: React.MouseEventHandler<HTMLElement>;

    /**
     * Always shows the clear icon instead of just on hover.
     * Requires allowClear to also be true and text to be entered.
     * @default false
     **/
    clearButtonVisibleWithText?: boolean;
}

export const BTAutoComplete = track((props) => ({
    element: "Auto Complete",
    uniqueId: props["data-testid"],
}))(function <T>({
    onSearch,
    placeholder,
    dropdownRender,
    options,
    notFoundContent,
    style,
    prefixIcon,
    suffixIcon,
    className,
    inputDelay,
    maxDelay,
    allowClear,
    clearButtonVisibleWithText,
    "data-testid": dataTestId,
    onClear,
    open,
    onBlur,
    onFocus,
    onClick,
    onPrefixIconClick,
    onKeywordChange,
    hotkey,
    hotkeyDisabled,
    clearIcon,
    ...otherProps
}: IBTAutoCompleteProps<T> & ITrackingProp) {
    const combinedClassName = classNames(
        {
            PrefixIcon: prefixIcon !== undefined,
            SuffixIcon: suffixIcon !== undefined,
            ClearButtonVisibleWithText: clearButtonVisibleWithText,
        },
        "BTAutoComplete"
    );

    const [isSearching, setIsSearching] = useState(false);
    const [inputText, setInputText] = useState<string>();

    const inputRef = useRef<HTMLInputElement>(null);

    function onFocusWrapper() {
        if (onFocus) {
            onFocus();
        }
        otherProps.tracking?.trackEvent({ event: "InputFocus" });
    }

    const debounceOptions = maxDelay ? { maxWait: maxDelay ?? max_debounce_delay } : undefined;
    const handleSearch = useDebouncedCallback(
        useCallback(
            async (value: string) => {
                setIsSearching(true);
                try {
                    await onSearch(value);
                } finally {
                    setIsSearching(false);
                }
            },
            [onSearch]
        ),
        inputDelay ?? debounce_input_delay,
        debounceOptions
    );

    const triggerFocus = () => {
        inputRef?.current?.focus();
        onFocusWrapper();
    };

    const autoComplete = (
        <div className={combinedClassName}>
            {prefixIcon ? (
                <div
                    onClick={() => {
                        if (onPrefixIconClick) {
                            inputRef?.current?.focus();
                            onPrefixIconClick();
                        }
                    }}
                    className="PrefixIconWrapper"
                >
                    {prefixIcon}
                </div>
            ) : null}
            <AutoComplete
                ref={inputRef}
                data-testid={dataTestId}
                onSearch={(value) => {
                    setInputText(value);

                    if (onKeywordChange) {
                        onKeywordChange(value);
                    }

                    return handleSearch.callback(value);
                }}
                placeholder={placeholder}
                dropdownRender={(menu) =>
                    dropdownRender ? dropdownRender(menu, isSearching) : menu
                }
                options={isSearching ? [] : options}
                notFoundContent={notFoundContent ? notFoundContent(isSearching) : null}
                style={style}
                allowClear={allowClear}
                clearIcon={clearIcon ?? <BTIconCloseOutlined size={14} />}
                onClear={async () => {
                    inputRef?.current?.focus();
                    if (onClear) {
                        onClear();
                    }
                }}
                open={open}
                onBlur={onBlur}
                onFocus={onFocusWrapper}
                onClick={onClick}
                className={className}
            />
            {suffixIcon && !inputText ? (
                <div
                    onClick={() => {
                        triggerFocus();
                    }}
                    className="SuffixIconWrapper"
                >
                    {suffixIcon}
                </div>
            ) : null}
        </div>
    );

    if (!hotkey) {
        return autoComplete;
    }

    return (
        <Hotkey
            disabled={hotkeyDisabled}
            {...getHotkeyProps(hotkey)}
            onCommand={() => triggerFocus()}
        >
            {autoComplete}
        </Hotkey>
    );
});
