import moment, { Moment } from "moment";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";

import { alphabetize, getSelectedValue } from "utilities/form/form";

import {
    AccountingValidationData,
    IHasAccountingValidation,
} from "commonComponents/accounting/AccountingValidation/AccountingValidation.types";
import { ICostCodeSelectorExtraData } from "commonComponents/entity/costCode/CostCodeSelector/CostCodeSelector";
import { AccountingEntityTypes } from "commonComponents/financial/Common/Accounting.types";

import { ShiftEditedModes } from "entity/timeClock/common/ShiftEditedModes";

export enum ShiftEntityApprovalStatusType {
    PendingApproval = 0,
    Approved = 1,
}

export enum OverrideType {
    clockIn = "ClockIn",
    clockOut = "ClockOut",
}

export enum LocationType {
    clockIn = 1,
    clockOut = 2,
}

export enum PinType {
    default = 1,
    outOfBounds = 2,
}

export enum GustoSyncStatus {
    NotSynced = 1,
    Synced = 2,
    ManuallySynced = 3,
}

export enum BreakValidationErrors {
    BreakInFuture = "Breaks cannot be added in the future",
    StartAfterEnd = "Break start must be before break end",
    StartOutOfBounds = "Break start must be within the shift times",
    EndOutOfBounds = "Break end must be within the shift times",
    OverlappingBreaks = "Breaks cannot be overlapping",
}

export enum AccountingErrorTypes {
    ShiftNotSent = 1,
    ShiftNotSentAndPayrollNeedsAction = 2,
    ShiftMayHaveBeenDeleted = 3,
}

export type ShiftFormActions =
    | undefined
    | "approve"
    | "reset"
    | "save"
    | "saveAndClose"
    | "delete"
    | "recalculate"
    | "gustoSync"
    | "selectAllEntity";

export interface IShiftFormValues {
    jobsite: number;
    timeIn?: moment.Moment;
    timeOut: moment.Moment | null;
    shiftType: number;
    approvalStatus: ShiftEntityApprovalStatusType;
    minutesReg: number;
    minutesOT: number;
    minutes2OT: number;
    minuteDistributionType: ShiftEditedModes;
    users: number[];
    userMinuteData: any[];
    tags: number[];
    notes: string;
    lineItems: ITimeCardLineItem[];
    payrollItems?: number[];
    breaks: IShiftBreak[];
    totalBreakTime: number;
    breaksToDelete: number[];
    builderTimeZone: string;
}

export class ShiftEntity implements IBaseEntity, IHasAccountingValidation {
    constructor(data: any) {
        this.failedFields = data.failedFields;
        if (!this.failedFields) {
            this.id = data.timeCardId;
            this.timeIn = data.timeIn.value !== null ? moment(data.timeIn.value) : undefined;
            this.timeOut = data.timeOut.value !== null ? moment(data.timeOut.value) : undefined;
            this.jobsites = data.jobsite.value.map(
                (p: any) => new BTSelectItem<IShiftEntityJobsiteExtraData>(p)
            );
            this.jobId = getSelectedValue(this.jobsites);
            this.users = data.users.options.map(
                (p: any) => new BTSelectItem<IShiftEntityUsersExtraData>(p)
            );
            this.flatRateCostCodeId = data.flatRateCostCodeId;
            this.costCodes = data.costCodeList.value.map((p: any) => {
                if (p.extraData) {
                    p.extraData.canEdit =
                        p.id !== this.flatRateCostCodeId && p.id !== UserDefaultCostCode;
                }
                return new BTSelectItem<IShiftEntityCostCodesExtraData>(p);
            });
            this.lineItems = data.lineItems
                ? data.lineItems.value.map((x: any) => new TimeCardLineItem(x))
                : null;
            this.useDefaultUserRates = data.useDefaultUserRates;
            this.defaultUserRate = data.defaultUserRate;
            this.userIds = data.users.value;
            this.canAdd = data.canAdd;
            this.hasEditPermission = data.hasEditPermission; // this relates directly to the Update permission from the user's role
            this.hasAdminPermission = data.hasAdminPermission;
            this.canEdit = data.canEdit; // this relates to Update permissions if it's the user's own shift, or TimeClock_AdjustOtherUsers if it's another user's shift
            this.canDelete = data.canDelete;
            this.canApprove = data.canApprove;
            this.canViewCost = data.canViewCost;
            this.approvalStatus =
                data.approvalStatus !== undefined
                    ? new ShiftEntityApprovalStatus(data.approvalStatus)
                    : null;
            this.minutesReg = data.minutesReg.value;
            this.minutesOT = data.minutesOT.value;
            this.minutes2OT = data.minutes2OT.value;
            this.minuteDistributionType = data.minuteDistributionType;
            this.showOvertime = data.showOvertime;
            this.showDoubleOvertime = data.showDoubleOvertime;

            if (data.shiftType.value !== undefined) {
                this.shiftType = data.shiftType.value.map((val: any) => {
                    return new BTSelectItem(val);
                });
            }
            this.timeInModified =
                data.timeInModified !== null ? new ModifiedData(data.timeInModified) : null;
            this.timeOutModified =
                data.timeOutModified !== null ? new ModifiedData(data.timeOutModified) : null;
            this.detailedHoursModified =
                data.minutesModified !== null ? new ModifiedData(data.minutesModified) : null;
            this.location =
                data.location.value !== null
                    ? data.location.value.map((value: any) => new Location(value))
                    : null;
            this.builderTimeZone = data.builderTimeZone;
            this.tags =
                data.tags !== undefined
                    ? alphabetize(data.tags.value.map((tag: any) => new BTSelectItem(tag)))
                    : [];
            this.notes = data.notes.value;

            this.breaks = data.breaks
                ? data.breaks.map((shiftBreak: any) => new ShiftBreak(shiftBreak))
                : [];
            this.totalBreakTime = 0;
            this.breaks.forEach((shiftBreak: ShiftBreak) => {
                if (shiftBreak.breakStart !== undefined && shiftBreak.breakEnd !== undefined) {
                    this.totalBreakTime += shiftBreak.breakEnd.diff(
                        shiftBreak.breakStart,
                        "minutes"
                    );
                }
            });

            this.payrollItemsList =
                data.payrollItemsList !== undefined
                    ? data.payrollItemsList.value.map(
                          (payrollItem: any) => new BTSelectItem(payrollItem)
                      )
                    : [];
            this.sendToAccountingOnApproval = data.sendToAccountingOnApproval; // TODO: Need to run accounting validation on Save and Approve if this is true
            this.defaultPayrollItem = data.defaultPayrollItem; // TODO: This is used as the default payroll item only when splitting line items on Cost tab
            this.accountingValidation = {
                entityId: this.id,
                entityType: AccountingEntityTypes.TimeCard,
                canSendToAccounting: data.createInvoiceChkbox || data.createInvoiceButton,
                shouldValidateOnLoad: true,
                accountingIntegrationName: data.accountingTitle,
                checkPayrollItem: data.checkPayrollItem,
                serviceItemsOnly: data.serviceItemsOnly,
            };

            if (this.id === 0) {
                const userDefault = this.costCodes.find(
                    (code) => parseInt(code.id) === UserDefaultCostCode
                );
                if (userDefault && data.canAdd && this.userIds.length > 0) {
                    const flattenedUserItems = this.users.flatMap((group) => group.children!);
                    const user = flattenedUserItems.find(
                        (user) => parseInt(user.id) === this.userIds[0]
                    )!;

                    let flatRateCostCode, userCostCode;
                    // Using this kind of loop because we only want to return the Flat Rate Cost Code if the Default Cost Code is not in the list
                    for (const code of this.costCodes) {
                        const codeId = parseInt(code.id);
                        if (codeId === user.extraData!.defaultCostCodeId) {
                            userCostCode = code;
                        } else if (codeId === this.flatRateCostCodeId) {
                            flatRateCostCode = code;
                        }
                    }
                    userCostCode = userCostCode ? userCostCode : flatRateCostCode;
                    userDefault.title = `User Default Cost Code (${userCostCode!.title})`;
                    userDefault.extraData = userCostCode!.extraData;
                }
            }

            this.jobsiteRadius = data.jobsiteRadius ? data.jobsiteRadius : null;
            this.currentUserId = data.currentUserId ? data.currentUserId : null;
            this.userId = data.userId !== null ? data.userId : null;
            this.hasAdjustOtherUserShiftPermission = data.hasAdjustOtherUserShiftPermission;

            this.canEditShift = this.getCanEditShift();
            this.displayHoursInDecimal = data.displayHoursInDecimal;
            this.gustoSyncStatus = data.gustoSyncStatus;
            this.hasGustoPermissions = data.hasGustoPermissions;
            this.hasCostCodesFeature = data.hasCostCodesFeature;

            this.createdOn = data.createdOn ? moment(data.createdOn) : null;
            this.createdByUserId = data.createdByUserId;
            this.createdByName = data.createdByName ?? undefined;

            this.modifiedOn = moment(data.modifiedOn);
            this.modifiedByUserId = data.modifiedByUserId;
            this.modifiedByName = data.modifiedByName ?? undefined;
        }
    }

    id: number;
    builderId: number;
    jobId: number;
    timeIn?: moment.Moment;
    timeOut?: moment.Moment;
    jobsites: BTSelectItem<IShiftEntityJobsiteExtraData>[];
    users: BTSelectItem<IShiftEntityUsersExtraData>[];
    lineItems: TimeCardLineItem[];
    costCodes: BTSelectItem<IShiftEntityCostCodesExtraData>[];
    useDefaultUserRates: boolean;
    defaultUserRate?: number;
    flatRateCostCodeId: number;
    userIds: number[];
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canApprove: boolean;
    canViewCost: boolean;
    approvalStatus: ShiftEntityApprovalStatus | null;
    minutesReg: number;
    minutesOT: number;
    minutes2OT: number;
    minuteDistributionType: ShiftEditedModes;
    timeInModified: ModifiedData | null;
    timeOutModified: ModifiedData | null;
    detailedHoursModified: ModifiedData | null;
    location: Location[] | null;
    builderTimeZone: string;

    tags: BTSelectItem[];
    notes: string;
    hasEditPermission: boolean;
    hasAdminPermission: boolean;
    hasAdjustOtherUserShiftPermission: boolean;
    shiftType: BTSelectItem[];
    showOvertime: boolean;
    showDoubleOvertime: boolean;

    payrollItemsList: BTSelectItem[];
    sendToAccountingOnApproval: boolean;
    defaultPayrollItem: string;
    accountingPayrollItemTitle: string;
    accountingValidation: AccountingValidationData;

    breaks: ShiftBreak[];
    totalBreakTime: number;
    jobsiteRadius: IJobsiteRadius;
    currentUserId: number;
    userId: number;
    canEditShift: boolean;
    failedFields?: { key: string; message: string[] }[];

    getCanEditShift = () => {
        const isNew = this.id === 0;
        const isApproved =
            !isNew &&
            this.approvalStatus !== null &&
            this.approvalStatus.value === ShiftEntityApprovalStatusType.Approved;
        const currentUserIsAssignedUser =
            !isNew && this.users.length === 1 && this.currentUserId === this.userId;
        const hasEditPermission = currentUserIsAssignedUser
            ? this.hasEditPermission || (isNew && this.canAdd)
            : this.hasAdjustOtherUserShiftPermission;

        return (!isApproved && hasEditPermission) || isNew;
    };
    displayHoursInDecimal: boolean;
    gustoSyncStatus: GustoSyncStatus | null;
    hasGustoPermissions: boolean;
    hasCostCodesFeature: boolean;

    createdOn: moment.Moment | null;
    createdByUserId: number | null;
    createdByName?: string;
    modifiedOn: moment.Moment;
    modifiedByUserId: number | null;
    modifiedByName?: string;
}

export interface IJobsiteRadius {
    radius: number;
    latitude: number;
    longitude: number;
}

export interface IShiftEntityJobsiteExtraData {
    isLocationRequired: boolean;
}

export interface IShiftEntityUsersExtraData {
    defaultUserLaborCost: number;
    defaultCostCodeId: number;
    isLocationRequired: boolean;
    userId: number;
}

export interface IShiftEntityCostCodesExtraData extends ICostCodeSelectorExtraData {
    defaultCostCodeRate: number;
    inactivationDate: Moment;
}

export class ShiftEntityApprovalStatus {
    constructor(data: any) {
        this.name = data.name;
        this.value = data.value;
        this.message = data.message;
    }
    name: string;
    value: ShiftEntityApprovalStatusType;
    message: string;
}

export class Location {
    constructor(data: any) {
        this.title = data.title;
        this.dateString = data.dateString;
        this.color = data.color;
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.pinType = data.pinType;
        this.outOfBoundsDistanceText = data.outOfBoundsDistanceText;
        this.jobName = data.jobName;
        this.locationType = data.locationType;
    }
    title: string;
    dateString: string;
    color: string;
    longitude: number;
    latitude: number;
    pinType: number;
    locationType: LocationType;
    outOfBoundsDistanceText: string;
    jobName: string;
}

export class ModifiedData {
    constructor(data: any) {
        this.modifiedBy = data.modifiedBy;
        this.modifiedDate = moment(data.modifiedDate);
        this.modified = data.modified;
    }
    modifiedBy: string;
    modifiedDate: Moment;
    modified: boolean;
}

export class UserMinuteData {
    minutesReg: number;
    minutesOT: number;
    minutes2OT: number;
}

export class ShiftBreak {
    constructor(data: any) {
        this.timeCardBreakId = data.timeCardBreakId;
        this.breakStart = data.breakStart ? moment(data.breakStart.value) : undefined;
        this.breakEnd = data.breakEnd ? moment(data.breakEnd.value) : undefined;
    }

    timeCardBreakId?: number;
    breakStart?: Moment;
    breakEnd?: Moment;
}

export interface IShiftBreak {
    timeCardBreakId?: number;
    breakStart?: Moment;
    breakEnd?: Moment;
}

export class ShiftDeleteResponse {}

export class CurrentShiftsResponse {
    constructor(data: any) {
        if (data !== null) {
            this.currentShifts = data.currentClockInData.map((c: any) => new CurrentShift(c));
        } else {
            this.currentShifts = null;
        }
    }
    currentShifts: CurrentShift[] | null;
}

export class CurrentShift {
    constructor(data: any) {
        this.id = data.timeCardItemId;
        this.jobName = data.jobName;
        this.isSelf = data.isSelf;
        this.userName = data.userName;
    }
    id: number;
    jobName: string;
    isSelf: boolean;
    userName: string;
}

export class ShiftCostCodesResponse {
    constructor(data: any) {
        this.costCodes = data.costCode.value.map(
            (p: any) => new BTSelectItem<IShiftEntityCostCodesExtraData>(p)
        );
    }
    costCodes: BTSelectItem<IShiftEntityCostCodesExtraData>[];
}

export class ShiftRecalculateResponse {
    constructor(data: any) {
        this.minutesReg = data.minutesReg;
        this.minutesOT = data.minutesOT;
        this.minutes2OT = data.minutes2OT;
        this.totalMinutes = data.minutesTotal;
    }
    minutesReg: number;
    minutesOT: number;
    minutes2OT: number;
    totalMinutes: number;
}

export class ShiftUsersResponse {
    constructor(data: any) {
        this.users = data.users.options.map(
            (p: any) => new BTSelectItem<IShiftEntityUsersExtraData>(p)
        );
    }
    users: BTSelectItem<IShiftEntityUsersExtraData>[];
}

export class GeofenceOverrideResponse {
    constructor(data: any) {
        this.location = data.location;
    }
    location: Location[];
}

export class ShiftAccountingResponse {
    constructor(data: any) {
        this.canSendAnyToAccounting = data.canSendAnyToAccounting;
        this.payrollNeedsAction = data.payrollNeedsAction;
    }
    canSendAnyToAccounting: boolean;
    payrollNeedsAction: boolean;
}

export class DetailedHoursField {
    constructor(fieldName: string, fieldValue: number) {
        this.fieldName = fieldName;
        this.fieldValue = fieldValue;
    }

    fieldName: string;
    fieldValue: number;
}

export class TimeCardLineItem {
    constructor(data?: any) {
        if (data) {
            this.id = data.id;
            this.costCodeId = data.costCodeId;
            this.payrollItemId = data.payrollItemId;
            this.minutes = data.minutes;
            this.rate = data.rate ? data.rate.value : null;
        }
    }

    id: number;
    costCodeId: number;
    isCostCodeActive: boolean;
    payrollItemId?: string;
    minutes: number;
    rate: number | null;
}

export interface ITimeCardLineItem {
    id: number;
    costCodeId: number;
    isCostCodeActive: boolean;
    payrollItemId?: string;
    minutes: number;
    rate: number | null;
}

export const UserDefaultCostCode = -3;

export enum ShiftDetailsTab {
    General = 1,
    DetailedHours = 2,
    Breaks = 3,
    Cost = 4,
    Map = 5,
}
