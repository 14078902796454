import classNames from "classnames";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { BuilderInfo } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { BTLocalStorage } from "types/btStorage";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { selectJobs } from "utilities/jobPicker/jobPicker";

import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { IJobsitesRequired } from "commonComponents/helpers/JobsitesRequired/JobsitesRequired";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import ContactInfoButton from "commonComponents/utilities/HeaderInfo/ContactInfoButton/ContactInfoButton";
import { IHeaderInfoResponse } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import {
    IJobPickerHandler,
    JobPickerHandler,
} from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import {
    IMainNavigationHandler,
    MainNavigationHandler,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.handler";

import "./OwnerHeaderInfo.less";

interface IOwnerHeaderInfoProps extends RouteComponentProps, IJobsitesRequired {
    className?: string;
    handler?: IMainNavigationHandler;
    jobPickerHandler?: IJobPickerHandler;
    builderId: number;
    jobId: number;
    loadNewJob: boolean;
    setSelectedJobId: (jobId: number, orgId?: number) => Promise<void>;
}

interface IOwnerHeaderInfoInternalProps extends IOwnerHeaderInfoProps {
    isSpa: boolean | null;
    builderInfo: BuilderInfo;
}

interface IOwnerHeaderInfoState {
    data: IHeaderInfoResponse;
    jobChanging: boolean;
}

class OwnerHeaderInfoInternal extends Component<
    IOwnerHeaderInfoInternalProps,
    IOwnerHeaderInfoState
> {
    private mounted = true;
    static defaultProps = {
        handler: new MainNavigationHandler(),
        jobPickerHandler: new JobPickerHandler(),
    };

    componentWillUnmount = async () => {
        this.mounted = false;
    };

    componentDidMount = async () => {
        // Load the info we have in local storage
        const headerInfo = BTLocalStorage.get("bt-object-headerInfo");
        if (headerInfo && headerInfo.builderId === this.props.builderId) {
            this.setState({ data: headerInfo });
        }

        // If we just picked a new job on owner portal, we want to prevent any
        // useless API calls from going out before page refresh
        if (!this.props.loadNewJob) {
            return;
        }

        try {
            const resp = await this.props.handler!.getHeader({
                selectedJobIds: [this.props.jobId],
            });
            if (this.mounted) {
                this.setState({ data: resp });

                // Store the API response in local storage
                BTLocalStorage.set("bt-object-headerInfo", resp);
            }
        } catch (e: any) {
            if (
                (e as DOMException) &&
                e.code === e.NETWORK_ERR &&
                e.message.includes("NetworkError when attempting to fetch resource.")
            ) {
                // Ignoring error per arch request. FF bug -- https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
            } else {
                showAPIErrorMessage(e);
            }
        }
    };

    // This is needed for webforms, and will need to be removed when in SPA
    private onJobChanged = async (id: number) => {
        if (this.props.isSpa) {
            // move this logic up to the list page layout
            await this.props.setSelectedJobId(id);
        } else {
            try {
                this.setState({ jobChanging: true });
                selectJobs([id], false);
                await this.props.jobPickerHandler!.switchOwner(id);
                // eslint-disable-next-line no-restricted-syntax
                const loc = (window as any).location;

                // refresh page without QS (or post values)
                const url = loc.href.split("?")[0];
                // eslint-disable-next-line no-restricted-syntax
                (window as any).location = url;
            } catch (e) {
                showAPIErrorMessage(e);
                this.setState({ jobChanging: false });
            }
        }
    };

    render() {
        if (!this.state || !this.state.data) {
            return <></>;
        }

        const { data } = this.state;
        const hasLinkedJobs = data.linkedJobs.jobs.length > 1;

        return (
            <div className={classNames("OwnerHeader", this.props.className)}>
                <div className="HeaderInfo">
                    {data.logoPath && (
                        <div
                            className="BuilderLogo"
                            style={{ backgroundImage: `url(${data.logoPath})` }}
                        />
                    )}
                    <div className="OwnerJobPicker">
                        {hasLinkedJobs && (
                            <div className="OwnerJobPickerFormItem">
                                <div>
                                    <strong>Job</strong>
                                </div>
                                <BTSelect
                                    id="ownerJobPicker"
                                    data-testid="ownerJobPicker"
                                    value={this.props.jobId}
                                    treeData={data.linkedJobs.jobs.map(
                                        (j) =>
                                            new BTSelectItem({
                                                name: j.jobName,
                                                id: j.id,
                                                selected: j.id === this.props.jobId,
                                            })
                                    )}
                                    onChange={(_field, value, _selectedItem) =>
                                        this.onJobChanged(value)
                                    }
                                    disabled={data.linkedJobs.disabled}
                                    onBlur={() => {}}
                                />
                            </div>
                        )}
                    </div>
                    {this.state.jobChanging && <BTLoading />}
                    {data.builderName !== null && (
                        <div className={hasLinkedJobs ? "margin-left-lg" : "HeaderContactUs"}>
                            <ContactInfoButton
                                jobId={this.props.jobId}
                                history={this.props.history}
                                match={this.props.match}
                                location={this.props.location}
                                parentUrl={this.props.match.url}
                            >
                                Contact Us
                            </ContactInfoButton>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const OwnerHeaderInfo = (props: IOwnerHeaderInfoProps) => {
    const builderInfo = useRequiredContext(BuilderInfoContext);
    return (
        <SpaInfoContext.Consumer>
            {(spaInfo) => (
                <OwnerHeaderInfoInternal
                    {...props}
                    isSpa={spaInfo.isSpa}
                    builderInfo={builderInfo}
                />
            )}
        </SpaInfoContext.Consumer>
    );
};

export default OwnerHeaderInfo;
