import qs from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IInternalUserProps } from "entity/internalUser/InternalUserDetails/InternalUser";

const InternalUser = lazyLoadWithRetry(() => import("./InternalUser"));

export const RouteInternalUser = (
    props: Omit<IInternalUserProps, "id" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.internalUsers.internalUserDetails}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            const id = parseInt(routeValues.id);
            const qsValues: any = qs.parse(routeProps.location.search);

            return (
                <BTSuspense>
                    <InternalUser
                        {...props}
                        {...routeProps}
                        id={id}
                        initialTab={qsValues.initialTab}
                    />
                </BTSuspense>
            );
        }}
    />
);
