import newHorizontalBlackGrey from "images/Logo/Horizontal/bt-logo-horizontal-black-grey.svg";
import newHorizontalDarkBlueBlue from "images/Logo/Horizontal/bt-logo-horizontal-dark-blue-blue.svg";
import newHorizontalOneColor from "images/Logo/Horizontal/bt-logo-horizontal-one-color.svg";
import newHorizontalWhiteBlue from "images/Logo/Horizontal/bt-logo-horizontal-white-blue.svg";
import newHorizontalWhite from "images/Logo/Horizontal/bt-logo-horizontal-white.svg";
import newMinimalBlackGrey from "images/Logo/Minimal/bt-logo-minimal-black-grey.svg";
import newMinimalDarkBlueBlue from "images/Logo/Minimal/bt-logo-minimal-dark-blue-blue.svg";
import newMinimalOneColor from "images/Logo/Minimal/bt-logo-minimal-one-color.svg";
import newMinimalWhiteBlue from "images/Logo/Minimal/bt-logo-minimal-white-blue.svg";
import newMinimalWhite from "images/Logo/Minimal/bt-logo-minimal-white.svg";
import newVerticalBlackGrey from "images/Logo/Vertical/bt-logo-vertical-black-grey.svg";
import newVerticalDarkBlueBlue from "images/Logo/Vertical/bt-logo-vertical-dark-blue-blue.svg";
import newVerticalOneColor from "images/Logo/Vertical/bt-logo-vertical-one-color.svg";
import newVerticalWhiteBlue from "images/Logo/Vertical/bt-logo-vertical-white-blue.svg";
import newVerticalWhite from "images/Logo/Vertical/bt-logo-vertical-white.svg";

export const btLogoLayouts = ["vertical", "horizontal", "minimal"] as const;

export const btLogoColors = [
    "dark-blue-blue",
    "white",
    "white-blue",
    "black",
    "black-grey",
] as const;

export const newBTLogoColors = [
    "black-grey",
    "dark-blue-blue",
    "one-color",
    "white",
    "white-blue",
] as const;

export const btLogoSizes = ["xs", "sm", "md", "lg", "xl"] as const;

export const btLogoSizeMap: {
    [key in (typeof btLogoLayouts)[number]]: { [key in (typeof btLogoSizes)[number]]: number };
} = {
    horizontal: { xs: 100, sm: 200, md: 300, lg: 400, xl: 500 },
    vertical: { xs: 100, sm: 200, md: 300, lg: 400, xl: 500 },
    minimal: { xs: 24, sm: 36, md: 56, lg: 72, xl: 144 },
};

export const newBTLogoTypeUrlMap: {
    [key in (typeof btLogoLayouts)[number]]: { [key in (typeof newBTLogoColors)[number]]: string };
} = {
    horizontal: {
        "black-grey": newHorizontalBlackGrey,
        "dark-blue-blue": newHorizontalDarkBlueBlue,
        "one-color": newHorizontalOneColor,
        white: newHorizontalWhite,
        "white-blue": newHorizontalWhiteBlue,
    },
    vertical: {
        "black-grey": newVerticalBlackGrey,
        "dark-blue-blue": newVerticalDarkBlueBlue,
        "one-color": newVerticalOneColor,
        white: newVerticalWhite,
        "white-blue": newVerticalWhiteBlue,
    },
    minimal: {
        "black-grey": newMinimalBlackGrey,
        "dark-blue-blue": newMinimalDarkBlueBlue,
        "one-color": newMinimalOneColor,
        white: newMinimalWhite,
        "white-blue": newMinimalWhiteBlue,
    },
};

export interface IBTLogoProps {
    /**
     * The type of logo to display
     * @default "horizontal"
     */
    layout?: (typeof btLogoLayouts)[number];
    /**
     * The preset size to use for the logo
     * @default "md"
     */
    size?: (typeof btLogoSizes)[number];
    /**
     * The color of the logo
     * @default "dark-blue-blue"
     */
    color?: (typeof btLogoColors)[number] | (typeof newBTLogoColors)[number];
    /**
     * Any classes to apply to the image element
     */
    className?: string;
}

export const BTLogo: React.FC<IBTLogoProps> = ({
    layout = "horizontal",
    size = "md",
    color = "dark-blue-blue",
    className,
}) => {
    return (
        <img
            alt="Buildertrend Logo"
            src={newBTLogoTypeUrlMap[layout][color]}
            width={btLogoSizeMap[layout][size]}
            className={className}
        />
    );
};
