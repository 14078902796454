import { APIHandler } from "utilities/apiHandler";

import { FromTemplateEntity } from "entity/template/common/FromTemplate.api.types";

export interface IFromTemplateHandler {
    getDetails(id: number): Promise<FromTemplateEntity>;
}

export class FromTemplateHandler implements IFromTemplateHandler {
    async getDetails(id: number) {
        return await APIHandler(`/api/Templates/${id}/Details`, {
            method: "GET",
            responseType: FromTemplateEntity,
        });
    }
}
