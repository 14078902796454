import moment from "moment";

import { IAppProviderHandler } from "helpers/AppProvider.api.handler";
import {
    AppDefaultInfo,
    BrandingInfo,
    BuilderFlags,
    BuilderInfo,
    CurrencyLocale,
    DateLocale,
    GlobalInfo,
    IntegrationBuilderProfile,
    IntegrationUserProfile,
    SupportContactInfo,
    TimeLocale,
} from "helpers/AppProvider.types";

import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";
import { isNullOrUndefined } from "utilities/object/object";

export class AppProviderBTJScriptGlobalsHandler implements IAppProviderHandler {
    isLegacy: true;

    getSync(): GlobalInfo {
        const isBuilderInSession = !isNullOrUndefined(window.btJScriptGlobals.getBuilderId);

        let builderInfo: BuilderInfo | null = null;
        let supportContactInfo: SupportContactInfo | null = null;
        let brandingInfo: BrandingInfo | null = null;

        if (isBuilderInSession) {
            builderInfo = {
                encryptedBuilderId: window.btJScriptGlobals.encryptedBuilderId,
                builderId: parseInt(window.btJScriptGlobals.getBuilderId),
                name: window.btJScriptGlobals.builderName,
                isDemoAccount: window.btJScriptGlobals.isDemoAccount,
                scheduleDefaultTabForCurrentPortal:
                    window.btJScriptGlobals.builderInformation.scheduleDefaultTab,
                selectionDefaultBuilderView: 0, // not supported in non-SPA
                activityCalendarDefaultView:
                    window.btJScriptGlobals.builderInformation.activityCalendarDefaultView,
                weekStartDay: window.btJScriptGlobals.getBuilderWeekStartDay,
                locale: {
                    currencyLocale: new CurrencyLocale({
                        currencySymbol: window.btJScriptGlobals.getBuilderCurrencySymbol,
                        decimalSeparator: window.btJScriptGlobals.getBuilderNumberDecimalSeparator,
                        groupSeparator: window.btJScriptGlobals.getBuilderNumberGroupSeparator,
                    }),
                    dateLocale: new DateLocale(
                        window.btJScriptGlobals.builderShortDateFormatForMoment
                    ),
                    timeLocale: new TimeLocale({
                        format: window.btJScriptGlobals.builderTimeFormatForMoment,
                        is24HourClock: window.btJScriptGlobals.uses24HourClock,
                        timeZoneName: window.btJScriptGlobals.builderTimeZoneName,
                    }),
                },
                flags: new BuilderFlags(window.btJScriptGlobals.btAdminFlags),
                taxesOptOut: window.btJScriptGlobals.taxesOptOut,
                isInTaxesSupportedRegion: window.btJScriptGlobals.isInTaxesSupportedRegion,
                setupDate: moment(window.btJScriptGlobals.setupDate),
                showAccountingBannerAd: window.btJScriptGlobals.showAccountingBannerAd,
                canUseExternalTemplates: window.btJScriptGlobals.canUseExternalTemplates,
                redirectToSetupExperience: window.btJScriptGlobals.redirectToSetupExperience,
            };

            supportContactInfo = {
                email: window.btJScriptGlobals.supportContactInfo.email,
                header: window.btJScriptGlobals.supportContactInfo.header,
                phoneNumber: window.btJScriptGlobals.supportContactInfo.phoneNumber,
                timezone: window.btJScriptGlobals.supportContactInfo.timezone,
                workDays: window.btJScriptGlobals.supportContactInfo.workDays,
                workHours: window.btJScriptGlobals.supportContactInfo.workHours,
            };
            brandingInfo = {
                primaryColor: `#${window.btJScriptGlobals.builderHeaderColor}`,
            };
        }
        return {
            environmentInfo: {
                isProduction: window.btJScriptGlobals.isProduction,
                name: window.btJScriptGlobals.environmentName,
                color: window.btJScriptGlobals.environmentColor,
                supportMobileEphemeralLogin:
                    window.btJScriptGlobals?.supportMobileEphemeralLogin ?? false,
                auth0Url: window.btJScriptGlobals.auth0Url,
                quartzPath: "",
            },
            appDefaultInfo: new AppDefaultInfo(window.btJScriptGlobals.appDefaultFlags),
            thirdPartyInfo: {
                googleMapsApiKey: window.btJScriptGlobals.googleMapsApiKey,
                sentryCurrentEnvironmentReleaseId: window.btJScriptGlobals.sentryRelease,
                firebaseConfig: window.btJScriptGlobals.firebaseConfiguration ?? null,
                sardineClientId: window.btJScriptGlobals.sardineClientId ?? null,
                sardineSessionKey: window.btJScriptGlobals.sardineSessionKey ?? null,
            },
            userInfo: {
                loginType: window.btJScriptGlobals.loginTypeInt,
                timeoutMinutes: window.btJScriptGlobals.timeoutMinutes,
                isImpersonatingBuilder: window.btJScriptGlobals.isImpersonatingBuilder,
                userName: window.btJScriptGlobals.userIdDisplay,
                fullName: window.btJScriptGlobals.userDisplayName,
                email: window.btJScriptGlobals.userEmail,
                firstName: window.btJScriptGlobals.userFirstName,
                isAdmin: window.btJScriptGlobals.isAdmin,
                globalUserId:
                    window.btJScriptGlobals.globalUserId === 0
                        ? null
                        : window.btJScriptGlobals.globalUserId,
                credentialLoginType: window.btJScriptGlobals.credentialLoginType,
                isBuilderImpersonatingOwner: window.btJScriptGlobals.isBuilderImpersonatingOwner,
                orgLinks: [],
                systemAlerts: window.btJScriptGlobals.systemAlerts,
                intercomSettings: window.btJScriptGlobals.intercomSettings,
                roleInfo: window.btJScriptGlobals.roleInfo,
                shouldRedirectToSetupExperience:
                    window.btJScriptGlobals.shouldRedirectToSetupExperience,
                hasAcceptedTermsAndPolicy: window.btJScriptGlobals.hasAcceptedTermsAndPolicy,
            },
            integrationUserProfile: window.btJScriptGlobals.integrationUserProfile
                ? new IntegrationUserProfile(window.btJScriptGlobals.integrationUserProfile)
                : null,
            builderInfo,
            brandingInfo,
            integrationBuilderProfile: window.btJScriptGlobals.integrationBuilderProfile
                ? new IntegrationBuilderProfile(window.btJScriptGlobals.integrationBuilderProfile)
                : null,
            supportContactInfo,
        };
    }

    async get(): Promise<GlobalInfo> {
        return Promise.resolve(this.getSync());
    }

    async authLog(): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Login/LogAuth`, {
            method: "POST",
            responseType: EmptyResponseEntity,
        });
    }
}
