import classNames from "classnames";
import { Moment } from "moment";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router";

import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";

import { BTSelectItem, IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { BTLocalStorage } from "types/btStorage";
import { MenuItemName } from "types/enum";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { getBaseRoute } from "utilities/routes";

import { BTAutoComplete } from "commonComponents/btWrappers/BTAutoComplete/BTAutoComplete";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCloseOutlined, BTIconSearch } from "commonComponents/btWrappers/BTIcon";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import { HotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.utility";
import {
    GlobalSearchHandler,
    IGlobalSearchHandler,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.api.handler";
import {
    getSearchErrorMessage,
    GlobalSearchEntity,
    GlobalSearchFilters,
    GlobalSearchResult,
    IGlobalSearchError,
    IGlobalSearchFilterValues,
    IGlobalSearchOpenState,
    IGlobalSearchState,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { GlobalSearchDropdown } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchDropdown/GlobalSearchDropdown";
import {
    getInfoForSearchResponse,
    GetRecentlyViewedFromLocalStorage,
    ResetPageForJobNotInJobPicker,
    SetRecentlyViewedLocalStorage,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchUtils";
import {
    AllJobs,
    SearchCategory,
} from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";
import { LinkType } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.utilities";
import { SearchBarRoutes } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBarRoutes";

import "./GlobalSearch.less";

interface IGlobalSearchProps {
    builderIdUserIdLookup: Record<number, number> | undefined;
    handler?: IGlobalSearchHandler;
    searchRequestedPage?: MenuItemName;
    handleHideSearchInput?: () => void;
}

const defaultSearchHandler = new GlobalSearchHandler();
export const GlobalSearch: React.FunctionComponent<IGlobalSearchProps & ITrackingProp> = track({
    component: "Global Search",
})(
    ({
        builderIdUserIdLookup,
        handler = defaultSearchHandler,
        searchRequestedPage = MenuItemName.None,
        tracking,
        handleHideSearchInput,
    }) => {
        // State
        const searchRequest = useRef<IAPIHandlerResult<GlobalSearchEntity> | undefined>(undefined);
        const [searchState, setSearchState] = useState<IGlobalSearchState>({
            selectedResultIndex: 0,
            isSearching: false,
            searchValue: "",
            performedAtLeastOneSuccessfulSearch: false,
        });
        const [filters, setFilters] = useState<GlobalSearchFilters | undefined>(undefined);
        const [selectedFilters, setSelectedFilters] = useState<IGlobalSearchFilterValues>({
            jobId: AllJobs,
            category: [],
        });
        const [openState, setOpenState] = useState<IGlobalSearchOpenState>({
            isOpen: false,
            showAllFilters: false,
        });

        // Ref
        const lastRecentlyViewedRefresh = useRef<Moment | null>(null);
        const searchTimestamp = useRef(0);
        const globalSearchOverlayRef = useRef<HTMLDivElement>(null);

        const history = useHistory();
        const match = useRouteMatch();
        const spaContext = useContext(SpaInfoContext);

        let searchOverlaytiming: number | null = null;

        const closeDropdown = useCallback(() => {
            if (searchOverlaytiming) {
                clearTimeout(searchOverlaytiming);
            }
            setOpenState({
                isOpen: false,
                showAllFilters: false,
            });
        }, [searchOverlaytiming]);

        const handleSearchOpen = () => {
            setOpenState({
                isOpen: true,
                showAllFilters: openState.showAllFilters,
            });
        };

        const handleSearchClose = useCallback(() => {
            closeDropdown();
            if (handleHideSearchInput !== undefined) {
                handleHideSearchInput();
            }
        }, [closeDropdown, handleHideSearchInput]);

        const openDropdown = async () => {
            if (!openState.isOpen) {
                if (
                    !lastRecentlyViewedRefresh.current ||
                    lastRecentlyViewedRefresh.current.diff(moment(), "minutes") > 5
                ) {
                    await refreshRecentlyViewed();
                }

                searchOverlaytiming = setTimeout(handleSearchOpen, 250);
            }
        };

        useEffect(() => {
            if (!openState.isOpen) {
                window.removeEventListener("resize", closeDropdown);
            } else {
                window.addEventListener("resize", closeDropdown);
            }

            return () => {
                window.removeEventListener("resize", closeDropdown);
            };
        }, [openState.isOpen, closeDropdown]);

        // Computed State
        const userIds = useMemo(
            () => (builderIdUserIdLookup ? Object.values(builderIdUserIdLookup) : []),
            [builderIdUserIdLookup]
        );
        const selectedResultIndex = searchState.selectedResultIndex ?? -1;

        // Functions
        const handleNavdown = () => {
            if (!searchState || !searchState.results) return;
            if (searchState.selectedResultIndex < searchState.results.length - 1) {
                const newIndex = searchState.selectedResultIndex + 1;
                setSearchState({
                    ...searchState,
                    selectedResultIndex: newIndex,
                });
            }
        };

        const handleNavUp = () => {
            if (!searchState || !searchState.results) return;
            if (searchState.selectedResultIndex > 0) {
                const newIndex = searchState.selectedResultIndex - 1;
                setSearchState({
                    ...searchState,
                    selectedResultIndex: newIndex,
                });
            }
        };

        const handleMouseDown = useCallback(
            (e: MouseEvent) => {
                if (
                    e.target instanceof Element &&
                    !e.target?.matches(".GlobalSearch *") &&
                    !e.target?.matches(".GlobalSearch")
                ) {
                    closeDropdown();
                }
            },
            [closeDropdown]
        );

        const refreshRecentlyViewed = useCallback(async () => {
            lastRecentlyViewedRefresh.current = moment();
            const hasNoSearchTerm = searchState.searchValue === "";
            const recentResults = GetRecentlyViewedFromLocalStorage(userIds);
            if (recentResults.length === 0) {
                setSearchState({
                    ...searchState,
                    recentlyViewed: undefined,
                });
                return;
            }

            try {
                const resp = await handler.validateSearchItems(recentResults);
                const items = resp.searchItems.map(
                    (r) =>
                        new BTSelectItem<GlobalSearchResult>({
                            id: r.id,
                            value: r.title,
                            extraData: r,
                        })
                );
                if (hasNoSearchTerm) {
                    setSearchState({
                        ...searchState,
                        recentlyViewed: items,
                        results: items,
                    });
                } else {
                    setSearchState({
                        ...searchState,
                        recentlyViewed: items,
                    });
                }
            } catch (e) {
                showAPIErrorMessage(e);
            }
        }, [handler, searchState, userIds]);

        useEffect(() => {
            BTLocalStorage.addChangeListener(
                "bt-objectArray-globalSearchRecentlyViewed",
                refreshRecentlyViewed
            );
            return () =>
                BTLocalStorage.removeChangeListener(
                    "bt-objectArray-globalSearchRecentlyViewed",
                    refreshRecentlyViewed
                );
        });

        const handleResultClick = async (item: GlobalSearchResult | undefined) => {
            if (!item || searchState.isSearching || searchState.error) {
                return;
            }
            const info = getInfoForSearchResponse(item);
            closeDropdown();

            if (info.link && searchState.results) {
                setSearchState({
                    ...searchState,
                    selectedResultIndex: 0,
                });

                tracking?.trackEvent({
                    event: "LinkClick",
                    element: "Link",
                    uniqueId: SearchCategory[item.category],
                });
                await ResetPageForJobNotInJobPicker(info, item.jobId);
                if (info.linkType === LinkType.WebformsLink) {
                    window.location.assign(info.link);
                } else if (info.linkType === LinkType.NewTabLink) {
                    window.open(info.link);
                } else if (info.linkType === LinkType.AutoSPARouting) {
                    if (spaContext.isSpa) {
                        history.push(info.link);
                    } else {
                        window.location.assign(getBaseRoute() + info.link);
                    }
                } else {
                    history.push(match.url + info.link);
                }
                if (builderIdUserIdLookup) {
                    const userId = builderIdUserIdLookup[item.builderId];
                    SetRecentlyViewedLocalStorage(userIds, userId, item);
                }
            }
        };

        const handleKeywordChange = () => {
            setSearchState({
                ...searchState,
                lastKeywordChange: moment(),
            });
        };

        const handleOnSearch = useCallback(
            async (searchValue: string, filterValues: IGlobalSearchFilterValues) => {
                searchRequest.current?.cancel();

                if (!openState.isOpen) {
                    setOpenState({ ...openState, isOpen: true });
                }

                if (searchValue !== "") {
                    setSearchState({
                        ...searchState,
                        searchValue,
                        isSearching: true,
                    });
                    const categories =
                        filterValues.category.length > 0 &&
                        filterValues.category.length !== filters?.categorySelectItems.length
                            ? filterValues.category
                            : undefined;
                    searchTimestamp.current = performance.now();

                    searchRequest.current = handler.get({
                        search: searchValue,
                        categories: categories,
                        jobIds: filterValues.jobId !== AllJobs ? [filterValues.jobId] : undefined,
                        limit: 16,
                        requestedPage: searchRequestedPage,
                    });

                    try {
                        const entity = await searchRequest.current.response;
                        searchRequest.current = undefined;

                        let items: BTSelectItem<GlobalSearchResult>[] = [];
                        if (entity) {
                            items = entity.results.map(
                                (r) =>
                                    new BTSelectItem<GlobalSearchResult>({
                                        id: r.id,
                                        value: r.title,
                                        extraData: r,
                                    })
                            );
                        }
                        setSearchState({
                            searchValue,
                            results: items,
                            error: undefined,
                            selectedResultIndex: 0,
                            isSearching: false,
                            recentlyViewed: searchState.recentlyViewed,
                            performedAtLeastOneSuccessfulSearch: true,
                        });
                        const durationMs = performance.now() - searchTimestamp.current;

                        tracking?.trackEvent({
                            event: "Search",
                            extraInfo: {
                                duration: `${durationMs} ms`,
                                query: searchValue,
                                ...(entity?.debug ?? {}),
                            },
                        });
                    } catch (e) {
                        let error: IGlobalSearchError | undefined = getSearchErrorMessage(e);
                        if (!error.isAbortedRequest) {
                            setSearchState({
                                searchValue: searchValue,
                                results: undefined,
                                error: error,
                                selectedResultIndex: 0,
                                isSearching: false,
                                recentlyViewed: searchState.recentlyViewed,
                                performedAtLeastOneSuccessfulSearch:
                                    searchState.performedAtLeastOneSuccessfulSearch,
                            });
                        }
                    }
                } else {
                    setSearchState({
                        searchValue,
                        results: searchState.recentlyViewed,
                        error: undefined,
                        selectedResultIndex: 0,
                        isSearching: false,
                        recentlyViewed: searchState.recentlyViewed,
                        performedAtLeastOneSuccessfulSearch:
                            searchState.performedAtLeastOneSuccessfulSearch,
                    });
                }
            },
            [
                filters?.categorySelectItems.length,
                handler,
                openState,
                searchRequestedPage,
                searchState,
                tracking,
            ]
        );

        // Use Effects
        useEffect(() => {
            if (!openState.isOpen) {
                document.removeEventListener("mousedown", handleMouseDown);
            } else {
                document.addEventListener("mousedown", handleMouseDown);
            }

            return () => {
                document.removeEventListener("mousedown", handleMouseDown);
            };
        }, [handleMouseDown, openState]);

        useEffect(() => {
            async function getFilters() {
                try {
                    const filters = await handler.getFilters(searchRequestedPage);
                    setFilters(filters);
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            }
            if (filters === undefined && openState.isOpen) {
                void getFilters();
            }
        }, [filters, handler, openState.isOpen, searchRequestedPage]);

        const globalSearchDropdown = () => (
            <GlobalSearchDropdown
                isSearching={searchState.isSearching ?? false}
                searchState={searchState}
                selectedResultIndex={selectedResultIndex}
                filters={filters}
                onFilterChange={async (filterValues) => {
                    setSelectedFilters(filterValues);
                    await handleOnSearch(searchState.searchValue ?? "", filterValues);
                }}
                selectedFilters={selectedFilters}
                onResultClick={handleResultClick}
                showAllFilters={openState.showAllFilters}
                onShowAllFilters={(value) =>
                    setOpenState({
                        isOpen: true,
                        showAllFilters: value,
                    })
                }
            />
        );

        return (
            <>
                <div
                    className={classNames("GlobalSearch", {
                        "GlobalSearch-open": openState.isOpen,
                    })}
                    ref={globalSearchOverlayRef}
                >
                    <Hotkey
                        {...HotkeyCommands["enter"]}
                        onCommand={async () => {
                            const lastKeywordChange = searchState.lastKeywordChange
                                ? moment
                                      .duration(moment().diff(searchState.lastKeywordChange))
                                      .asSeconds()
                                : 999;
                            const searchResult = searchState.results?.[selectedResultIndex];
                            if (lastKeywordChange > 1 && searchResult) {
                                await handleResultClick(searchResult.extraData!);
                            }
                        }}
                        disabled={!openState.isOpen}
                        preventDefaultWhenDisabled={false}
                    >
                        <Hotkey
                            {...HotkeyCommands["navDown"]}
                            onCommand={handleNavdown}
                            disabled={!openState.isOpen || searchState.results?.length === 0}
                        >
                            <Hotkey
                                {...HotkeyCommands["navUp"]}
                                onCommand={handleNavUp}
                                disabled={!openState.isOpen || searchState.results?.length === 0}
                            >
                                <BTAutoComplete
                                    onSearch={(searchPhrase) =>
                                        handleOnSearch(searchPhrase, selectedFilters)
                                    }
                                    placeholder="Search"
                                    data-testid="globalSearchInput"
                                    options={searchState.results}
                                    notFoundContent={globalSearchDropdown}
                                    className="SearchInput"
                                    prefixIcon={<BTIconSearch size={16} />}
                                    allowClear
                                    clearButtonVisibleWithText
                                    onClear={() => {
                                        searchRequest.current?.cancel();
                                        setSearchState({
                                            ...searchState,
                                            searchValue: "",
                                            isSearching: false,
                                            results: searchState.recentlyViewed,
                                            selectedResultIndex: 0,
                                        });
                                    }}
                                    dropdownRender={globalSearchDropdown}
                                    open={openState.isOpen}
                                    onFocus={openDropdown}
                                    onPrefixIconClick={openDropdown}
                                    onClick={openDropdown}
                                    onKeywordChange={handleKeywordChange}
                                    hotkey="search"
                                    hotkeyDisabled={openState.isOpen}
                                    clearIcon={
                                        <BTIconCloseOutlined className="GlobalSearch" size={14} />
                                    }
                                />
                            </Hotkey>
                        </Hotkey>
                    </Hotkey>
                    <BTButton
                        className="GlobalSearch-search-close MainNavDropdown MainNavDropdown-icon-only MainNavDropdown-search-close"
                        data-testid="search-close-main-nav-button"
                        aria-label="Search"
                        onClick={handleSearchClose}
                        noShadow
                    >
                        <BTIconCloseOutlined className="MainNavDropdown-search-close--icon" />
                    </BTButton>
                </div>
                <Route render={(routeProps) => <SearchBarRoutes {...routeProps} />} />
            </>
        );
    }
);
