import Icon from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import SVG from "images/Notifications.svg?react";

import "./BTIconNotifications.less";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

interface IBTIconNotificationsProps extends IBTIconProps {
    isOnWhite?: boolean;
}

export const BTIconNotifications: FunctionComponent<IBTIconNotificationsProps> = (baseProps) => {
    const { isOnWhite, ...iconProps } = baseProps;
    const [props] = useBaseIcon(iconProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon
            {...props}
            component={SVG}
            className={classNames("BTIconNotifications", props.className, {
                isOnWhite,
                isOnDark: !isOnWhite,
            })}
        />
    );
};
