import { CheckAllState } from "types/enum";

import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BufferColumnKey, IBTColumnType } from "commonComponents/btWrappers/BTTable/BTTable.types";

export function getCheckAllState(rows: any[], propertyName: KeyOfOrString) {
    if (rows.some((row) => row[propertyName])) {
        if (rows.every((row) => row[propertyName] || row[propertyName] === null)) {
            return CheckAllState.AllChecked;
        }
        return CheckAllState.Indeterminate;
    }
    return CheckAllState.None;
}

export function getBufferColumn<T>(): IBTColumnType<T> {
    // Column used for tables with resizable columns
    // Required in order to fill space in tables when combind widths of columns is less than table width
    // Use getBufferColumn<T> below to match the generic with your table
    const bufferColumn: Pick<IBTColumnType<unknown>, "key" | "fixed"> = {
        fixed: "right",
        key: BufferColumnKey,
    };
    return bufferColumn;
}
