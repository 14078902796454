export interface IStickyLayer {
    size: number;
    level: number;
}

export type StickyDirection = "top" | "bottom";

/**
 * The main navigation header height is set in #reactMainNavigation in
 * master.css, so we will have to manually set this here for now.
 *
 * If the value in master.css is ever updated this value also needs to be updated.
 */
export const MainNavigationStickyHeaderHeight = 48;
