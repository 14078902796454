import { Loader } from "appcues-loader";

import { EnvironmentInfo } from "helpers/AppProvider.types";

import { AccountingIntegrationType, BTLoginTypes } from "types/enum";

import { reportError } from "utilities/errorHelpers";
import { onboardingActionCompleted } from "utilities/onboarding/onboarding";
import { OnboardingAction } from "utilities/onboarding/onboarding.types";
import { ExtractOnboardingContactDataFromBTChampionFormV2 } from "utilities/thirdPartyIntegrations/appcues/appcues.helpers";
import {
    AppcuesConstants,
    IAppcuesFormSubmittedEvent,
    IAppcuesGroupAttributes,
    IAppcuesOptions,
    IAppcuesUserAttributes,
} from "utilities/thirdPartyIntegrations/appcues/appcues.types";
import { isInIframe } from "utilities/url/url";

/**
 * Identifies the current User & Builder with Appcues
 * @param context {@link IAppcuesOptions} context
 */
const identify = async (context: IAppcuesOptions) => {
    try {
        identifyUser(context);
        // Note: This isn't anywhere in Appcues documentation, but it seems that when
        //       we async load their script, we need to await this user Promise (which seems to be loading the currently cached user)
        //       before calling identify() and group()
        await window.Appcues?.user();
        identifyBuilder(context);

        bindListeners(context);

        // lets Appcues state know we have successfully initalized the user in session
        // identify also calls Page(), but there is a race condition between identifying the user in this context and the cached user
        window.Appcues?.page();
    } catch (error) {
        reportError(error);
    }
};

/**
 * Identifies the current User with Appcues
 * @param userInfo Current {@link UserInfo}
 */
const identifyUser = (context: IAppcuesOptions) => {
    const { userInfo, integrationUserProfile } = context;

    const userAttributes: IAppcuesUserAttributes = {
        userId: integrationUserProfile.userId,
        firstName: integrationUserProfile.firstName,
        fullName: integrationUserProfile.fullName,
        userName: integrationUserProfile.username,
        isAdmin: integrationUserProfile.isAdmin,
        isBtChampion: integrationUserProfile.isBtChampion,
        isInIframe: isInIframe(),
        userType: BTLoginTypes[userInfo.loginType],
        userCreatedAt: integrationUserProfile.userCreatedAt
            ? integrationUserProfile.userCreatedAt.unix()
            : null,
        userRole: integrationUserProfile.userRole,
        hasActiveJobs: integrationUserProfile.hasActiveJobs,
        canAddDailyLogs: integrationUserProfile.canAddDailyLogs,
        canAddToDos: integrationUserProfile.canAddToDos,
        canAddScheduleItems: integrationUserProfile.canAddScheduleItems,
        canAddInternalUsers: integrationUserProfile.canAddInternalUsers,
    };

    // Identify user
    window.Appcues?.identify(getAppcuesUserId(context), userAttributes);
};

const getAppcuesGroupId = (context: IAppcuesOptions) => {
    const { builderInfo, environmentInfo } = context;

    return `${builderInfo.builderId}${getAppcuesEnvironmentName(environmentInfo)}`;
};

const getAppcuesUserId = (context: IAppcuesOptions) => {
    const { userInfo, environmentInfo } = context;

    return `${userInfo.globalUserId}${getAppcuesEnvironmentName(environmentInfo)}`;
};

const getAppcuesEnvironmentName = (environmentInfo: EnvironmentInfo) => {
    if (environmentInfo.name === "Production") {
        return "";
    }

    if (environmentInfo.name.includes("Development")) {
        return "_Dev";
    }

    if (environmentInfo.name.includes("Ephemeral")) {
        return "_Eph";
    }

    return `_${environmentInfo.name}`;
};

const getGroupAttributes = (context: IAppcuesOptions) => {
    const { builderInfo, integrationBuilderProfile } = context;

    const groupAttributes: IAppcuesGroupAttributes = {
        builderId: integrationBuilderProfile.builderId,
        builderName: integrationBuilderProfile.builderName,
        isDemoAccount: builderInfo.isDemoAccount,
        accountSetupDate: integrationBuilderProfile.accountSetupDate.unix(),
        isConnectedToAccounting: integrationBuilderProfile.isConnectedToAccounting,
        accountingIntegrationType:
            AccountingIntegrationType[integrationBuilderProfile.accountingIntegrationType],
        hasOnboardingContact: integrationBuilderProfile.hasOnboardingContact,
        subscriptionPlan: integrationBuilderProfile.subscriptionPlan,
        isPaymentProcessingLinked: integrationBuilderProfile.isPaymentProcessingLinked,
        hasCustomCostCodes: integrationBuilderProfile.hasCustomCostCodes,
        activeInternalUserCount: integrationBuilderProfile.activeInternalUserCount,
    };

    return groupAttributes;
};

/**
 * Identifies the current Builder with Appcues
 * @param context {@link IAppcuesOptions} context
 */
const identifyBuilder = (context: IAppcuesOptions) => {
    const groupAttributes = getGroupAttributes(context);
    const appcuesGroupId = getAppcuesGroupId(context);

    // Identify account ('group' in Appcues)
    window.Appcues?.group(appcuesGroupId, groupAttributes);
};

/**
 * Binds listeners to various Appcues events
 */
const bindListeners = (context: IAppcuesOptions) => {
    const appcuesGroupId = getAppcuesGroupId(context);

    if (isInIframe()) {
        // Force hide Checklists inside iframes
        window.Appcues?.on("all", () => {
            window.Appcues?.clearShow();
        });
    }

    window.Appcues?.on("form_submitted", (event: IAppcuesFormSubmittedEvent) => {
        // BT Champion Flow - V2
        if (event.flowId === AppcuesConstants.Flow_IdentifyBTChampion_V2.FlowId) {
            const onboardingContactProvided =
                ExtractOnboardingContactDataFromBTChampionFormV2(event);

            // Submit the onboarding action
            onboardingActionCompleted(
                OnboardingAction.OnboardingContactProvided,
                onboardingContactProvided
            );

            // Update Group attribute to indicate BT Champion info was provided.
            window.Appcues?.group(appcuesGroupId, { btChampionProvided: true });
        }
    });
};

/**
 * Triggers/shows the Appcues Flow of the matching provided flowId.
 * @param flowId Appcues Flow ID to show
 */
export const ShowAppcuesFlow = (flowId: string) => {
    window.Appcues?.show(flowId);
};

let initStarted: boolean = false;

/**
 * Appcues wrapper instance. Be sure to call `initialize()` to init Appcues
 * @param context {@link IAppcuesOptions} context
 * @returns Wrapper instance for Appcues integration
 */
export function AppcuesIntegration(context: IAppcuesOptions) {
    return {
        name: "appcues",
        config: context,
        /**
         * Loads 3rd-party script and initializes Appcues
         */
        initialize: () => {
            if (!!window.Appcues || initStarted) {
                // Already initialized
                return;
            }
            initStarted = true;

            window.AppcuesSettings = {
                enableURLDetection: true,
            };

            new Loader(context.appcuesAccountId)
                .load()
                .then(async () => {
                    await identify(context);

                    window.Appcues.refresh = async () => identify(context);
                })
                .catch(() => {
                    // Swallow error
                });
        },
    };
}
