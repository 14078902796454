export const Black = "#000";
export const White = "#FFF";

export const BlackTransparancy = "#000000a6";

// greys
export const Grey0 = "#FFFFFF";
export const Grey5 = "#F7F9FC";
export const Grey10 = "#F1F4FA";
export const Grey15 = "#E2E7F0";
export const Grey20 = "#D3DBE2";
export const Grey25 = "#C7D0D9";
export const Grey30 = "#ACB8C3";
export const Grey40 = "#8F9BA8";
export const Grey50 = "#7B8493";
export const Grey60 = "#666D7C";
export const Grey70 = "#4E555F";
export const Grey80 = "#383C45";
export const Grey90 = "#202227";
export const Grey100 = "#0E0F10";

// Legacy greys are only used for buttons, they will be removed once we've migrated away from webforms
export const LegacyGrey1 = "#CCCCCC";
export const LegacyGrey2 = "#626262";
export const LegacyGrey3 = "#A8A8A8"; // used for icons on builder setup

// blues
export const Blue5 = "#FAFDFF";
export const Blue10 = "#E6F6FF";
export const Blue15 = "#CEEDFF";
export const Blue20 = "#BCE4FF";
export const Blue25 = "#A8DAFF";
export const Blue30 = "#80C4FF";
export const Blue40 = "#4DABFF";
export const Blue50 = "#278CFF";
export const Blue60 = "#0763FB";
export const Blue70 = "#004FD6";
export const Blue80 = "#002F77";
export const Blue90 = "#001A43";
export const Blue100 = "#000831";

// Legacy blues are only used for buttons, they will be removed once we've migrated away from webforms
export const LegacyBlue1 = "#6097b5";
export const LegacyBlue2 = "#3f7796";

// reds
export const Red5 = "#FFF8F7";
export const Red10 = "#FFEEEA";
export const Red15 = "#FDDDD5";
export const Red20 = "#FFCDC1";
export const Red25 = "#FFB9A8";
export const Red30 = "#FF9886";
export const Red40 = "#FD756A";
export const Red50 = "#EF5963";
export const Red60 = "#D33A5A";
export const Red70 = "#B5254C";
export const Red80 = "#8B1439";
export const Red90 = "#61002F";
export const Red100 = "#260314";

// Legacy reds are only used for buttons, they will be removed once we've migrated away from webforms
export const LegacyRed1 = "#d9534f";
export const LegacyRed2 = "#c9302c";

// greens
export const Green5 = "#F5FFFB";
export const Green10 = "#DDFDEF";
export const Green15 = "#C4FAE2";
export const Green20 = "#AFF2D7";
export const Green25 = "#94EBC8";
export const Green30 = "#69DDAB";
export const Green40 = "#31CE8D";
export const Green50 = "#1FBA7A";
export const Green60 = "#06A260";
export const Green70 = "#057E4B";
export const Green80 = "#005C35";
export const Green90 = "#01321D";
export const Green100 = "#001A0E";

export const CustomGreen1 = "#52c41a"; // This is only for a two tone icon that looks bad with all our current greens

// yellows
export const Yellow5 = "#FFFBEF";
export const Yellow10 = "#FDF3D3";
export const Yellow15 = "#FBE9B1";
export const Yellow20 = "#F8DC83";
export const Yellow25 = "#EFCF61";
export const Yellow30 = "#E2BA2B";
export const Yellow40 = "#C99A0D";
export const Yellow50 = "#B78200";
export const Yellow60 = "#9F6600";
export const Yellow70 = "#854D00";
export const Yellow80 = "#653200";
export const Yellow90 = "#3D1A00";
export const Yellow100 = "#1E0A01";

export const CustomYellow1 = "#FF6600";
export const CustomYellow2 = "#FFEA09";

// oranges
export const Orange5 = "#FFFAF4";
export const Orange10 = "#FFEFDE";
export const Orange15 = "#FFE2BE";
export const Orange20 = "#FFD49A";
export const Orange25 = "#FFC26F";
export const Orange30 = "#FFA44A";
export const Orange40 = "#F48432";
export const Orange50 = "#E56B03";
export const Orange60 = "#CB4F00";
export const Orange70 = "#AF3700";
export const Orange80 = "#842301";
export const Orange90 = "#511000";
export const Orange100 = "#290500";

// purples
export const Purple5 = "#FCF8FF";
export const Purple10 = "#F6EDFF";
export const Purple15 = "#ECDDFF";
export const Purple20 = "#E4CEFD";
export const Purple25 = "#DABFFD";
export const Purple30 = "#C5A0FB";
export const Purple40 = "#AA7CF3";
export const Purple50 = "#9967EF";
export const Purple60 = "#834BE7";
export const Purple70 = "#6831CE";
export const Purple80 = "#4A2593";
export const Purple90 = "#2C145C";
export const Purple100 = "#150A2E";

// teals
export const Teal5 = "#f3feff";
export const Teal10 = "#d8ffff";
export const Teal15 = "#b0ffff";
export const Teal20 = "#88ffff";
export const Teal25 = "#64fcfc";
export const Teal30 = "#28f0f0";
export const Teal40 = "#00d8d8";
export const Teal50 = "#00bcc4";
export const Teal60 = "#0597a3";
export const Teal70 = "#007589";
export const Teal80 = "#00546b";
export const Teal90 = "#002b42";
export const Teal100 = "#001524";

// other
export const black = Grey10;

export const PrimaryColor = Blue70;
export const InfoColor = Blue60;
export const SuccessColor = Green60;
export const ErrorColor = Red60;
export const WarningColor = Yellow60;

// ColorPicker Constants
export class ColorPickerConstants {
    static readonly Maroon = "#442121";
    static readonly Merlot = "#572A2A";
    static readonly TuscanRed = "#8C4343";
    static readonly Rose = "#AD5252";
    static readonly Victoria = "#C78888";
    static readonly Brown = "#542C10";
    static readonly Coffee = "#6C3815";
    static readonly Amber = "#AD5A21";
    static readonly Peach = "#D67029";
    static readonly Cream = "#E39D6C";
    static readonly Forest = "#353F26";
    static readonly Olive = "#435130";
    static readonly Green = "#6C824D";
    static readonly Mint = "#84A05E";
    static readonly Cucumber = "#ABBE91";
    static readonly Plum = "#2D263E";
    static readonly Purple = "#3A3150";
    static readonly Lavender = "#5C4E81";
    static readonly Iris = "#72609F";
    static readonly Violet = "#9E92BD";
    static readonly Navy = "#213444";
    static readonly Levi = "#2A4257";
    static readonly Ocean = "#436A8C";
    static readonly Ice = "#5283AD";
    static readonly Sky = "#88AAC7";
    static readonly Graphite = "#323232";
    static readonly Gunmetal = "#404040";
    static readonly Silver = "#676767";
    static readonly Gray = "#7F7F7F";
    static readonly FullMoon = "#A7A7A7";
    static readonly Black = "#1D1D1D";
    static readonly AlarmRed = "#DD2222";
    static readonly AlarmPink = "#ED2591";
    static readonly AlarmBlue = "#2222DD";
    static readonly AlarmGreen = "#008000";
    static readonly AlarmPurple = "#6F116F";
    static readonly AlarmOrange = "#FF9600";
    static readonly AlarmAqua = "#2CD1D2";
    static readonly AlarmLime = "#9FC62A";
    static readonly AlarmGold = "#DDC817";
}

export class RfiAvatarColors {
    static readonly ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
}

export class ReportAndDataVizColors {
    static readonly DataViz1 = "#0763FB";
    static readonly DataViz2 = "#0597A3";

    static readonly Categorical1 = "#0763FB";
    static readonly Categorical2 = "#0597A3";
    static readonly Categorical3 = "#1FBA7A";
    static readonly Categorical4 = "#EF5963";
    static readonly Categorical5 = "#834BE7";
    static readonly Categorical6 = "#B140A1";

    static readonly DivergentPositive = "#1FBA7A";
    static readonly DivergentNegative = "#EF5963";
    static readonly DivergentNeutral = "#202227";
    static readonly DivergentNeutralHeader = "#FFFFFF";
}

export class ADACompliantColors {
    static readonly ADAPurple7 = "#866AD2";

    static readonly ADAGreen7 = "#388235";

    static readonly ADABlue8 = "#0076CC";
    static readonly ADABlue9 = "#005694";
    static readonly ADABlue10 = "#003861";

    static readonly ADAYellow8 = "#9C693A";
    static readonly ADAYellow9 = "#6A4D2F";
    static readonly ADAYellow10 = "#3D2D1F";
}

// Want the icon colors to match the standard colors for the file types
// e.g. Microsoft's blue for Word files
export class DocumentIcons {
    static readonly Excel = "#2F8540";
    static readonly Folder = "#E9BC1F";
    static readonly FolderGlobal = "#0099CC";
    static readonly Generic = "#C6C6C5";
    static readonly Pdf = "#EC2031";
    static readonly PostScript = "#CD6628";
    static readonly PowerPoint = "#DF8326";
    static readonly Txt = "#B1B4B5";
    static readonly Word = "#24589A";
    static readonly Zip = "#797979";
}
