import { createContext } from "react";

export interface IBTSelectCustomItemContext {
    /**
     * Hack used to detect when the current item is being rendered in the input vs. option popover
     * true = the current item is being rendered in the input
     * false = the current item is being rendered in the option popover
     *
     * @note ant design <Select> lacks support for custom JSX within the select input (it only supports strings)
     * @todo remove when ant design adds support
     */
    isSelectedValueRender: boolean;
}

export const BTSelectCustomItemContext = createContext<IBTSelectCustomItemContext | undefined>({
    isSelectedValueRender: false,
});
