import { MessagePayload } from "@firebase/messaging";

import {
    IBTChatNotificationEvent,
    IBTNotificationEvent,
    INotificationData,
    MessagePayloadType,
    NotificationDataFactory,
} from "helpers/pushNotification.types";

import { NotificationActionType } from "types/enum";

import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

import { ISendChatRequest } from "entity/chat/common/chat.types";

/* Modules referenced by the service worker cannot contain references to the rest of the React app
   due to how the service worker is bundled. If they do, we'll receive a build error.
*/

export const parseNotificationFromFirebaseMessagePayload = (
    data: MessagePayload["data"],
    logger: Console = console
): INotificationData | undefined => {
    try {
        if (!data) {
            logger.warn(`Firebase message payload was missing a data property.`);
            return;
        }
        // The data payload is a Dictionary<string,string>, so let's convert it back to a proper object
        const parsedPayload: Record<string, unknown> = {
            ...data,
        };

        for (const key in parsedPayload) {
            try {
                parsedPayload[key] = JSON.parse(data[key]);
            } catch (e) {
                // It's not JSON, ignore
            }
        }
        return NotificationDataFactory.create(parsedPayload);
    } catch (e) {
        logger.error(e);
        return;
    }
};

export class NotificationClickHandlerFactory {
    private readonly logger: Console;

    constructor(logger: Console) {
        this.logger = logger;
    }

    private chatGuard(
        event: IBTNotificationEvent<MessagePayloadType>
    ): event is IBTChatNotificationEvent {
        return event.notification.data.actions[0].at === NotificationActionType.Channel_Details;
    }

    create(event: IBTNotificationEvent<MessagePayloadType>): Promise<void> {
        if (this.chatGuard(event)) {
            return this.chat(event);
        } else {
            return this.default(event);
        }
    }

    /** We generate urls for onClick event from data object. */
    private async default(event: IBTNotificationEvent<MessagePayloadType>) {
        event.notification.close();
        this.logger.log("Notification clicked");
        const data = event.notification.data;
        try {
            // Always taking the last action. Right now all use just 1 action
            let action = data.actions[data.actions.length - 1];
            if (!action) {
                this.logger.log("No action to perform for notification");
                return;
            }

            if (!this.isServiceWorker(event.currentTarget)) {
                this.logger.error("Not called from a service worker");
                return;
            }

            const sw = event.currentTarget;

            if (!sw.clients.openWindow) {
                this.logger.warn(
                    "Cannot open notification in a new window. Skipping notification action"
                );
            }

            const redirectLink = new URL(
                "/emailLinkClicked.aspx",
                `${sw.location.protocol}//${sw.location.hostname}`
            );
            redirectLink.searchParams.set("p", "true");
            redirectLink.searchParams.set("elt", action.at.toString());
            redirectLink.searchParams.set("key1", action.e.toString());
            redirectLink.searchParams.set("u", data.userId.toString());

            if (data.orgId) {
                redirectLink.searchParams.set("orgId", data.orgId.toString());
            }
            if (data.notificationId) {
                redirectLink.searchParams.set("notificationId", data.notificationId.toString());
            }

            if (action.o) {
                Object.entries(action.o).forEach(([key, value]) => {
                    redirectLink.searchParams.set(key, String(value));
                });
            }

            const clientList = await sw.clients.matchAll({ type: "window" });
            if (!clientList.length) {
                redirectLink.searchParams.set("needsSignIn", "true");
            }
            await sw.clients.openWindow(redirectLink.toString());
        } catch (e) {
            this.logger.error(e);
        }
    }

    private async chat(event: IBTChatNotificationEvent) {
        event.notification.close();
        this.logger.log("Notification clicked");
        const { data } = event.notification;
        if (event.reply) {
            return this.sendChatMessage(
                event.reply,
                event.notification.data.threadId,
                data.actions[0].o.portalType
            );
        }

        try {
            if (!this.isServiceWorker(event.currentTarget)) {
                this.logger.error("Not called from a service worker");
                return;
            }

            const sw = event.currentTarget;
            let redirectLink: URL;

            if (Array.isArray(data?.actions)) {
                const chatUrl = data.actions[0].o.chatUrl;
                redirectLink = new URL(chatUrl);
            } else {
                redirectLink = new URL(
                    `app/Chat`,
                    `${sw.location.protocol}//${sw.location.hostname}`
                );
            }

            const btTabs = await sw.clients.matchAll({ type: "window", includeUncontrolled: true });
            let btChatTab: null | WindowClient = null;
            for (const tab of btTabs) {
                const tabUrl = new URL(tab.url);
                if (tabUrl.pathname.toLowerCase().includes("/chat")) {
                    btChatTab = tab;
                    break;
                }
            }

            if (btChatTab) {
                await btChatTab.focus();
                await btChatTab.navigate(redirectLink.toString());
            } else {
                await sw.clients.openWindow(redirectLink.toString());
            }
        } catch (e) {
            this.logger.error(e);
        }
    }

    private async sendChatMessage(reply: string, channelId: string, portalType: number) {
        const url = `/api/Channels/${channelId}/Messages`;
        const editorContent = EditorContent.FromPlainText(reply);

        const chatPayload: ISendChatRequest = {
            content: editorContent,
        };

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(chatPayload),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Portaltype: portalType.toString(),
            },
        });

        // if necessary, the data can be instantiated into a ChannelMessageEvent
        await response.json();
    }

    private isServiceWorker(currentTarget: any): currentTarget is ServiceWorkerGlobalScope {
        return currentTarget instanceof ServiceWorkerGlobalScope;
    }
}
