import { Component } from "react";

import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { TextWrap } from "commonComponents/utilities/TextWrap/TextWrap";

export class RecentItemsTooltipConfig {
    constructor(data: any) {
        this.entityName = data.entityName;
        this.recentItems = data.recentItems.map((r: any) => new RecentItem(r));
    }
    entityName: string;
    recentItems: RecentItem[];
}

export class RecentItem {
    constructor(data: any) {
        this.customId = data.customId;
        this.title = data.title;
    }
    customId: string;
    title: string;
}

interface IRecentItemsTooltip {
    config: RecentItemsTooltipConfig;
}

export class RecentItemsTooltip extends Component<IRecentItemsTooltip> {
    private formatItem = (r: RecentItem) => {
        return `${r.customId}${r.title && r.title !== "" ? ` - ${r.title}` : ""}`;
    };

    render() {
        const { config } = this.props;
        return (
            <BTPopover
                content={
                    <TextWrap>
                        <div style={{ marginBottom: 8 }}>
                            {config.entityName} numbers are auto assigned based on your previous
                            numbers used per job.
                        </div>
                        {config.recentItems && config.recentItems.length > 0 && (
                            <>
                                <div style={{ marginBottom: 8 }}>Recently Added:</div>
                                {config.recentItems.map((recentItem) => (
                                    <div key={recentItem.customId}>
                                        {this.formatItem(recentItem)}
                                    </div>
                                ))}
                            </>
                        )}
                    </TextWrap>
                }
                placement="right"
            />
        );
    }
}
