import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import { UserInfo } from "helpers/AppProvider.types";

import { BTLoginTypes } from "types/enum";

import { parseParams } from "utilities/url/url";

import { marketplaceAdminDialog } from "entity/marketplace/common/MarketplaceAdminDialog";
import { marketplaceNonBuilderDialog } from "entity/marketplace/common/MarketplaceNonBuilderDialog";

interface IModalLauncherProps extends RouteComponentProps {
    search: string;
    userInfo?: UserInfo;
}

const ModalLauncher: React.FC<IModalLauncherProps> = ({ search, userInfo }) => {
    const [qs] = useState(() =>
        parseParams<{ openModalUrl: string | string[]; checkAccess: boolean }>(search, {
            parseBooleans: true,
        })
    );

    const removeQsParams = useCallback(() => {
        delete qs.openModalUrl;
        delete qs.checkAccess;
        let newQs = queryString.stringify(qs);

        if (newQs !== "") {
            newQs = "?" + newQs;
        }

        history.pushState(null, document.title, location.pathname + newQs);
    }, [qs]);

    const openModal = useCallback(
        (openModalUrl: string | string[]) => {
            // this is in-case there's more than one modal in the url
            let encodedUrl = Array.isArray(openModalUrl)
                ? openModalUrl[openModalUrl.length - 1]
                : openModalUrl;

            let url = decodeURIComponent(encodedUrl);
            const isSettings =
                url.includes("app/PayrollSettings") ||
                url.includes("app/IntegrationsSettings") ||
                url.includes("app/TheHomeDepotSettings") ||
                url.includes("app/TakeoffSettings");

            if (url.length > 0 && url.startsWith("/app/")) {
                removeQsParams();

                // eslint-disable-next-line no-restricted-syntax
                (window as any).btMaster.btDialogs.fireDialog({
                    dBoxMethod: "iframe",
                    src: url,
                    height: "600px",
                    width: "1200px",
                    cssClass: isSettings ? "NewModalHeader" : "",
                });
            }
        },
        [removeQsParams]
    );

    const showNoAccessModal = useCallback(
        (userInfo: UserInfo) => {
            removeQsParams();
            userInfo.loginType === BTLoginTypes.BUILDER
                ? marketplaceAdminDialog()
                : marketplaceNonBuilderDialog();
        },
        [removeQsParams]
    );

    useEffect(() => {
        const { openModalUrl, checkAccess } = qs;

        if (checkAccess && userInfo && !userInfo.isAdmin) {
            showNoAccessModal(userInfo);
        } else if (openModalUrl) {
            openModal(openModalUrl);
        }
    }, [qs, userInfo, showNoAccessModal, openModal]);

    return null;
};

export default ModalLauncher;
