import { getClosestReactModal } from "utilities/helpers";

/**
 * Scrolls to a specific element on the page
 */
export const scrollToElement = function (element: HTMLElement | null, center?: boolean) {
    if (element) {
        const closestReactModal = getClosestReactModal(element, "body");
        const elementBoundingRect = element.getBoundingClientRect();

        let context: HTMLElement | (Window & typeof globalThis);
        let heightToScroll: number;
        let viewHeight: number;
        if (closestReactModal) {
            context = closestReactModal;
            heightToScroll =
                elementBoundingRect.top - context.children[0].getBoundingClientRect().top;
            viewHeight = context.offsetHeight;
        } else {
            context = window;
            heightToScroll = elementBoundingRect.top + context.pageYOffset;
            viewHeight = context.innerHeight;
        }

        if (center && viewHeight > elementBoundingRect.height) {
            heightToScroll = heightToScroll - (viewHeight - elementBoundingRect.height) / 2;
        }

        const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
            context.scrollTo({ top: heightToScroll, behavior: "smooth" });
        } else {
            context.scrollTo(0, heightToScroll);
        }
    }
};

/**
 * Scrolls to a specific element on the page using an ID
 */
export const scrollToElementById = function (id: string, center?: boolean) {
    const element = document.getElementById(id);
    scrollToElement(element, center);
};

export class ScrollPos {
    PosX: number;
    PosY: number;
}

export const saveScrollPosition = () => {
    // eslint-disable-next-line no-restricted-syntax
    const win = window as any;
    const pos = new ScrollPos();
    pos.PosX = win.scrollX;
    pos.PosY = win.scrollY;
    return pos;
};

export const scrollToPosition = (pos: ScrollPos) => {
    // eslint-disable-next-line no-restricted-syntax
    const win = window as any;
    win.scrollTo(pos.PosX, pos.PosY);
};
