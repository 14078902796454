import {
    BTLocalStorage,
    BTSessionStorage,
    getJobPickerState,
    LocalStorageKeys,
} from "types/btStorage";

import { formattedPlural } from "utilities/string/string";

import {
    IApiJobBuilderRequest,
    JobPickerFilterTypes,
    JobPickerJob,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobIdTypes,
    JobPickerSelectModes,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import { getStatusTextForJobFilter } from "commonComponents/utilities/JobPicker/JobPicker.utils";
import { getJobPickerStorageKey } from "commonComponents/utilities/JobPicker/JobPickerWrapper";

// TODO: highly recommanded that keyword searching be done on the serverside through the API
// API should be adjusted to support this, if it has not already, along with pagination instead of trying to handle this on the client
//
export function filterJobsList(
    jobsList: JobPickerJob[],
    filterText: string,
    templatesOnly: boolean
): JobPickerJob[] {
    const filter = filterText.trim().toLocaleLowerCase();
    const filteredJobsList =
        filter === ""
            ? jobsList
            : jobsList.filter(
                  (x) =>
                      x.jobId === JobIdTypes.AllJobs ||
                      x.jobId === JobIdTypes.GlobalDocs ||
                      x.jobName.toLocaleLowerCase().includes(filter) ||
                      x.jobInfo?.ownerName?.toLocaleLowerCase()?.includes(filter)
              );

    // Check for special case with TimeClock with no jobs
    if (jobsList.length === 2 && jobsList.filter((x) => !isSpecialJob(x.jobId)).length === 0) {
        return jobsList.filter((x) => x.jobId !== JobIdTypes.AllJobs).map((x) => x);
    }

    // Set all listed count after omitting all listed, none, and special jobs
    const allListedJob = filteredJobsList.filter((x) => !isSpecialJob(x.jobId));
    const builderId = allListedJob.length > 0 ? allListedJob[0].builderId : undefined;
    setAllListedJobCount(allListedJob.length, filteredJobsList, templatesOnly, builderId);

    return filteredJobsList;
}

function setAllListedJobCount(
    count: number,
    jobsList: JobPickerJob[],
    templatesOnly: boolean,
    builderId?: number
) {
    let statusText = "";
    try {
        const selectedJobStatuses: string[] = (
            JSON.parse(
                // eslint-disable-next-line no-restricted-globals
                localStorage.getItem(
                    getJobPickerStorageKey(
                        LocalStorageKeys.CurrentJobPickerFilter,
                        false,
                        builderId
                    )
                ) ?? "{}"
            )[JobPickerFilterTypes.Other] ?? ""
        ).split(",");
        if (selectedJobStatuses.length === 1 || selectedJobStatuses.length === 2) {
            statusText =
                selectedJobStatuses
                    .map((id) => getStatusTextForJobFilter(Number(id)))
                    .join(" and ") + " ";
        }
    } catch (e) {
        statusText = "";
    }
    const allListedJob = jobsList.find((x) => x.jobId === JobIdTypes.AllJobs);
    if (allListedJob !== undefined) {
        allListedJob.jobName = templatesOnly
            ? formattedPlural({
                  value: count,
                  one: "View 1 Template",
                  other: `All ${count} Listed Templates`,
              })
            : formattedPlural({
                  value: count,
                  one: `View 1 ${statusText}Job`,
                  other: `All ${count} ${statusText}Jobs`,
              });
    }
}

export function isSpecialJob(jobId: number) {
    return [
        JobIdTypes.AllJobs,
        JobIdTypes.GlobalDocs,
        JobIdTypes.GlobalJob,
        JobIdTypes.NoJobs,
    ].includes(jobId);
}

export function getSelectedJobIdsForJobPicker(
    id: number,
    jobs: JobPickerJob[] | IApiJobBuilderRequest[],
    selectMode?: JobPickerSelectModes
) {
    if (
        id === JobIdTypes.NoJobs ||
        (id === JobIdTypes.AllJobs && selectMode === JobPickerSelectModes.Single)
    ) {
        return null;
    }
    if (id === JobIdTypes.AllJobs) {
        return jobs
            .filter((x) => x.jobId !== JobIdTypes.AllJobs && x.jobId !== JobIdTypes.NoJobs)
            .map((x) => x.jobId);
    }
    return id;
}

export function getSelectedJobIds(): number[] {
    const jobPickerState = getJobPickerState();
    return jobPickerState.selectedJobIds.map((x) => Number(x));
}

export function getAllBuildersSelected(): boolean {
    const jobPickerState = getJobPickerState();
    return jobPickerState.allBuildersSelected ?? false;
}

export function getSelectedJobId(): number {
    const jobPickerState = getJobPickerState();
    const selectedJobIds = jobPickerState.selectedJobIds;
    const isAllJobsSelected =
        jobPickerState.selectedJobIds.length > 1 && jobPickerState.isAllJobsSelected;

    // Fallback to session values if localstorage is not set.
    if (!selectedJobIds || selectedJobIds.length === 0) {
        const jobIds: string | number =
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper?.getSelectedJobIdList() || "";
        // If the job picker hasn't initialized, it will return an empty string
        if (jobIds === null || jobIds === "" || jobIds === JobIdTypes.NoJobs) {
            return JobIdTypes.NoJobs;
        }
        // If a single job is selected, it will return that jobId as a number
        else if (typeof jobIds === "number") {
            return jobIds;
        } else {
            // Otherwise, it'll be a comma separated list of jobs
            const jobList = jobIds.split(",").map((j) => Number(j));
            if (jobList.includes(JobIdTypes.AllJobs) || jobList.length > 1) {
                return JobIdTypes.AllJobs;
            }
            return jobList[0];
        }
    }

    if (isAllJobsSelected || selectedJobIds?.length > 1) {
        return JobIdTypes.AllJobs;
    } else if (selectedJobIds?.length === 1) {
        return selectedJobIds[0];
    }
    return JobIdTypes.NoJobs;
}

export function selectJobs(jobIds: number[], allJobsSelected: boolean) {
    const jobPickerState = getJobPickerState();
    const storageValue = {
        ...jobPickerState,
        selectedJobIds: jobIds,
        isAllJobsSelected: allJobsSelected,
    };
    BTLocalStorage.set("bt-object-dangerousJobPickerState", storageValue);
    BTSessionStorage.set("bt-object-dangerousJobPickerState", storageValue);
}

export function setTemplateMode() {
    const jobPickerState = getJobPickerState();
    const storageValue = {
        ...jobPickerState,
        selectedJobIds: [],
        isAllJobsSelected: false,
        isTemplateMode: true,
    };
    BTLocalStorage.set("bt-object-dangerousJobPickerState", storageValue);
    BTSessionStorage.set("bt-object-dangerousJobPickerState", storageValue);
}
