import { PureComponent } from "react";
import { Blue7 } from "styles/antTheme/Colors";

import "./ColorSwatch.less";

interface IColorSwatchProps {
    className: string;
    color: string;
    size: number;
    selected?: boolean;
    onClick: (color: string) => void;
    pointer?: boolean;
}

export class ColorSwatch extends PureComponent<IColorSwatchProps> {
    static defaultProps = {
        className: "",
        selected: false,
        onClick: () => {},
        pointer: false,
        size: 28,
    };

    private handleSwatchClick = () => {
        const { onClick, color } = this.props;
        onClick(color);
    };

    render() {
        const { color, selected, pointer, className } = this.props;
        const borderColor = selected ? Blue7 : "transparent";
        const cursor = pointer ? "pointer" : "default";

        return (
            <div className={`ColorSwatch-Container ${className}`}>
                <span
                    className="ColorSwatch-Outline"
                    style={{
                        width: `${this.props.size + 8}px`,
                        height: `${this.props.size + 8}px`,
                        borderColor: borderColor,
                    }}
                >
                    <span
                        className="ColorSwatch-Swatch"
                        style={{
                            background: color,
                            width: this.props.size,
                            height: this.props.size,
                            cursor: cursor,
                        }}
                        onClick={this.handleSwatchClick}
                    />
                </span>
            </div>
        );
    }
}
