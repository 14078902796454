import { message } from "antd";
import { Component } from "react";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";

import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    IScheduleConflictHandler,
    ScheduleConflictHandler,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.handler";
import {
    ConflictTrackingFormActions,
    IConflictTrackingFormValues,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import ScheduleConflictTrackingPresentational from "commonComponents/entity/scheduleConflicts/ScheduleConflictTracking/ScheduleConflictTrackingPresentational";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

interface IConflictTrackingProps {
    name: string;
    globalUserId: number;
    values: IConflictTrackingFormValues;
    onClose: (userId?: number, updatedNumberOfConflicts?: number) => void;
    handler?: IScheduleConflictHandler;
}

interface IConflictTrackingState {
    actionBeingPerformed: ConflictTrackingFormActions;
}

class ConflictTracking extends Component<IConflictTrackingProps, IConflictTrackingState> {
    static defaultProps = {
        handler: new ScheduleConflictHandler(),
    };

    state: Readonly<IConflictTrackingState> = {
        actionBeingPerformed: undefined,
    };

    private handleUpdate = async (newValues: IConflictTrackingFormValues) => {
        await setLoadingAction<ConflictTrackingFormActions>(this, {
            actionBeingPerformed: "update",
            callback: async () => {
                try {
                    const resp = await this.props.handler!.setConflictThreshold(
                        newValues,
                        this.props.globalUserId
                    );
                    void message.success("Conflict Tracking Updated");
                    this.props.onClose(resp.globalUserId, resp.conflictAlertThreshold);
                } catch (error) {
                    showAPIErrorMessage(error);
                }
            },
        });
    };

    render() {
        const { name, values, onClose } = this.props;
        const component = (
            <ScheduleConflictTrackingPresentational
                name={name}
                values={values}
                actionBeingPerformed={this.state.actionBeingPerformed}
                onSubmit={this.handleUpdate}
                onClose={() => onClose()}
                modalConfig={{ parentRoute: "", beforeClose: onClose }}
            />
        );

        return (
            <BTModal
                data-testid="btModalScheduleConflictTracking"
                beforeClose={() => onClose()}
                visible
                useModalLayout
                removeBodyPadding
                title="Schedule Item Conflicts"
                setPageTitle={false}
                width="400px"
            >
                {component}
            </BTModal>
        );
    }
}

const ScheduleConflictTracking = withErrorBoundary(ConflictTracking)(
    "Could not load Conflict Tracking modal"
);
export default ScheduleConflictTracking;
