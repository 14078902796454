import moment from "moment";
import { ReactNode } from "react";

import { DirectoryType, MediaType } from "types/enum";

import {
    APIError,
    NetworkError,
    ServiceUnavailableError,
    showAPIErrorMessage,
    TooManyRequestsError,
} from "utilities/apiHandler";
import { isInPortal } from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";

import {
    BTIconAllowances,
    BTIconBidPackages,
    BTIconBillsPurchaseOrders,
    BTIconBookOpen,
    BTIconChangeOrders,
    BTIconContacts,
    BTIconDailyLogs,
    BTIconDocuments,
    BTIconFolderOutlined,
    BTIconInternalUsers,
    BTIconJobs,
    BTIconLeadProposals,
    BTIconLeads,
    BTIconMessages,
    BTIconOwnerInvoices,
    BTIconPhotos,
    BTIconPurchaseOrders,
    BTIconRFIs,
    BTIconSchedules,
    BTIconSelections,
    BTIconSubs,
    BTIconTodos,
    BTIconVideos,
    BTIconWarranties,
} from "commonComponents/btWrappers/BTIcon";
import AddressDisplay from "commonComponents/entity/address/AddressDisplay";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import {
    getLabelForSearchCategory,
    IHighlightedField,
    ISearchBarResult,
    ISearchBarResultResponse,
    SearchCategory,
    SearchExpirationMinutes,
} from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

export enum LinkType {
    NewTabLink = 0,
    WebformsLink = 1,
    RelativeLink = 2,
    AutoSPARouting = 3, // replace with RelativeLink once we are fully SPA
}

export function handleSearchError(e: any) {
    if (e instanceof TooManyRequestsError) {
        return "Servers are experiencing high usage. Please try your search again.";
    } else if (e instanceof ServiceUnavailableError) {
        return "The search service is not currently available.";
    } else if (e instanceof NetworkError) {
        return;
    } else if (e instanceof APIError) {
        return e.errorMessage;
    } else {
        showAPIErrorMessage(e);
        return;
    }
}

export function getSearchBarResultsWithHighlights(
    data: ISearchBarResultResponse[] | undefined,
    highlightsEnabled: boolean = true,
    disableLinks: boolean
) {
    const results: ISearchBarResult[] = [];
    const isOwner: boolean = isInPortal({ owner: true });
    const isSub: boolean = isInPortal({ subs: true });
    if (data) {
        data.forEach((r) => {
            const builder = new ResultBuilder(r);
            builder.hasHighlights(highlightsEnabled && Boolean(r.highlights));
            builder.categoryLabel(getLabelForSearchCategory(r.category));
            switch (r.category) {
                case SearchCategory.ChangeOrders:
                case SearchCategory.Schedules:
                case SearchCategory.Selections:
                case SearchCategory.Warranties:
                    results.push(
                        builder
                            .primary("title")
                            .secondary("description")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.BidPackages:
                    builder.primary("title").secondary("description").tertiary("jobName");
                    if (isSub) {
                        builder.categoryLabel("Bids");
                    }
                    results.push(builder.getResult());
                    break;
                case SearchCategory.OwnerInvoices:
                    results.push(
                        builder
                            .primary("title")
                            .primaryLabel("Untitled")
                            .secondary("description")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.PurchaseOrders:
                    results.push(
                        builder
                            .primary("title")
                            .primaryLabel("Untitled")
                            .secondary("scopeOfWork", "description")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.Bills:
                    results.push(
                        builder
                            .primary("title")
                            .primaryLabel("Untitled")
                            .secondary("comments")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.DailyLogs:
                    results.push(
                        builder
                            .primary("date")
                            .secondary("logNotes", "notes")
                            .tertiary("jobName")
                            .renderField("date", (data) => ({
                                content: (
                                    <>
                                        <DateDisplay value={moment(data["date"])} />
                                        {data["title"] && (
                                            <>
                                                {" - "} {data["title"]}
                                            </>
                                        )}
                                    </>
                                ),
                                hasHighlight: false,
                            }))
                            .getResult()
                    );
                    break;
                case SearchCategory.RFIs:
                    results.push(
                        builder
                            .primary("title")
                            .primaryLabel("Untitled")
                            .secondary("question")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.Todo:
                    results.push(
                        builder
                            .primary("title")
                            .primaryLabel("Untitled")
                            .secondary("notes")
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.Allowances:
                    results.push(
                        builder.primary("title").secondary("notes").tertiary("jobName").getResult()
                    );
                    break;
                case SearchCategory.Messages:
                    builder.primary("sentBy");
                    if (!r["isDraft"]) {
                        builder.secondary("subject").renderField("subject", (data) => ({
                            content: (
                                <div>
                                    {r["subject"]} -
                                    <DateDisplay value={moment(data["sentDate"])} />
                                </div>
                            ),
                            hasHighlight: false,
                        }));
                    } else {
                        builder.secondary("subject");
                    }
                    if (!isOwner) {
                        builder.tertiary("jobName");
                    }
                    results.push(builder.getResult());
                    break;
                case SearchCategory.Leads:
                    results.push(
                        builder.primary("title", "opportunityTitle").secondary("notes").getResult()
                    );
                    break;
                case SearchCategory.LeadProposals:
                    results.push(
                        builder
                            .primary("proposalTitle", "title")
                            .secondary("opportunityTitle")
                            .getResult()
                    );
                    break;
                case SearchCategory.Contact:
                    results.push(builder.primary("displayName").getResult());
                    break;
                case SearchCategory.InternalUsers:
                    results.push(
                        builder
                            .primary("fullName")
                            .renderField("fullName", createFullNameField)
                            .getResult()
                    );
                    break;
                case SearchCategory.Subs:
                    results.push(
                        builder
                            .primary("company", "name")
                            .secondaryLabel(
                                r["divisions"].length === 0 ? undefined : r["divisions"].join(",")
                            )
                            .getResult()
                    );
                    break;
                case SearchCategory.Documents:
                case SearchCategory.Photos:
                case SearchCategory.Videos:
                case SearchCategory.DocumentFolders:
                case SearchCategory.VideoFolders:
                case SearchCategory.PhotoFolders:
                    results.push(
                        builder
                            .primary("title")
                            .secondary("lastModifiedDate")
                            .renderField("lastModifiedDate", (data) => ({
                                content: <DateDisplay value={moment(data["lastModifiedDate"])} />,
                                hasHighlight: false,
                            }))
                            .tertiary("jobName")
                            .getResult()
                    );
                    break;
                case SearchCategory.Jobs:
                    results.push(
                        builder
                            .primary("jobName")
                            .secondary("ownerName")
                            .tertiary("jobAddress")
                            .renderField("jobAddress", (data) => ({
                                content: (
                                    <AddressDisplay
                                        address={data["jobAddress"]}
                                        emptyState={<></>}
                                        singleLineFormat
                                    />
                                ),
                                hasHighlight: false,
                            }))
                            .getResult()
                    );
                    break;
                case SearchCategory.CostItems:
                    results.push(builder.primary("title").tertiary("costCodeTitle").getResult());
                    break;
                default:
                    throw new Error(`Search result card not implemented for enum ${r.category}`);
            }
        });
    }

    results.forEach((r) => Object.assign(r, getInfoForSearchResponse(r.data)));
    if (disableLinks) {
        results.forEach((r) => (r.link = undefined));
    }

    return results;
}

type ResultFieldTransform = (
    data: ISearchBarResultResponse,
    highlightsEnabled: boolean,
    value: IHighlightedField
) => IHighlightedField;

class ResultBuilder {
    private primaryFields: string[] = [];
    private secondaryFields: string[] = [];
    private tertiaryFields: string[] = [];

    private staticPrimaryLabel?: string = undefined;
    private staticSecondaryLabel?: string = undefined;
    private staticCategoryLabel?: string = undefined;

    private transforms: Record<string, ResultFieldTransform> = {};
    private highlightsEnabled: boolean = false;

    private data: ISearchBarResultResponse;

    constructor(data: ISearchBarResultResponse) {
        this.data = data;
        this.data.expires = moment().add(SearchExpirationMinutes, "minutes");
    }

    getResult: () => ISearchBarResult = () => {
        return {
            primaryField: getHighlightedField(
                this.data,
                this.primaryFields,
                this.transforms,
                this.highlightsEnabled,
                this.staticPrimaryLabel
            ),
            secondaryField: getHighlightedField(
                this.data,
                this.secondaryFields,
                this.transforms,
                this.highlightsEnabled,
                this.staticSecondaryLabel
            ),
            tertiaryField: getHighlightedField(
                this.data,
                this.tertiaryFields,
                this.transforms,
                this.highlightsEnabled
            ),
            categoryField: getHighlightedField(
                this.data,
                [],
                this.transforms,
                this.highlightsEnabled,
                this.staticCategoryLabel
            ),
            data: this.data,
            linkType: LinkType.RelativeLink,
        };
    };

    primary = (...fields: string[]) => this.builderMethod(this.primaryFields.push(...fields));
    secondary = (...fields: string[]) => this.builderMethod(this.secondaryFields.push(...fields));
    tertiary = (...fields: string[]) => this.builderMethod(this.tertiaryFields.push(...fields));
    categoryLabel = (label: string) => this.builderMethod((this.staticCategoryLabel = label));
    primaryLabel = (label: string) => this.builderMethod((this.staticPrimaryLabel = label));
    secondaryLabel = (label: string) => this.builderMethod((this.staticSecondaryLabel = label));
    hasHighlights = (val: boolean) => this.builderMethod((this.highlightsEnabled = val));
    renderField = (
        field: string,
        transform: (
            data: ISearchBarResultResponse,
            highlightsEnabled: boolean,
            value: IHighlightedField
        ) => IHighlightedField
    ) => this.builderMethod((this.transforms[field] = transform));

    private builderMethod = (_: any) => this;
}

function getHighlightedField(
    r: ISearchBarResultResponse,
    fields: string[],
    transforms: Record<string, ResultFieldTransform>,
    highlightsEnabled: boolean,
    staticLabel?: string
): IHighlightedField | undefined {
    const fieldsWithHighlights = highlightsEnabled
        ? fields.filter((f) => r.highlights[f] !== undefined)
        : [];
    const populatedFields = fields.filter((f) => Boolean(r[f]));
    const fieldsFromTransforms = fields.filter((f) => Boolean(transforms[f]));
    let transformToUse: ResultFieldTransform | undefined;
    let result: IHighlightedField | undefined;
    if (fieldsWithHighlights.length > 0) {
        transformToUse = transforms[fieldsWithHighlights[0]];
        result = {
            content: r.highlights[fieldsWithHighlights[0]].highlights.join(""),
            hasHighlight: true,
        };
    } else if (populatedFields.length > 0) {
        transformToUse = transforms[populatedFields[0]];
        result = {
            content: r[populatedFields[0]],
            hasHighlight: false,
        };
    } else if (fieldsFromTransforms.length > 0) {
        transformToUse = transforms[fieldsFromTransforms[0]];
        result = { content: null, hasHighlight: false };
    }
    if (result && transformToUse) {
        return transformToUse(r, highlightsEnabled, result);
    }
    // if both a field and label are specified, the label is applied when the field is empty
    if (!result && staticLabel) {
        return {
            content: staticLabel,
            hasHighlight: false,
        };
    }
    return result;
}

// Combine the user's first and last name from the service to fit our model
function createFullNameField(
    r: ISearchBarResultResponse,
    highlightsEnabled: boolean
): IHighlightedField {
    const first = r["firstName"];
    const last = r["lastName"];
    const firstHighlight = r.highlights["firstName"];
    const lastHighlight = r.highlights["lastName"];

    if (highlightsEnabled && (firstHighlight || lastHighlight)) {
        return {
            content: `${firstHighlight?.highlights.join("") ?? first ?? ""}${
                lastHighlight ?? last ? " " : ""
            }${lastHighlight?.highlights.join("") ?? last ?? ""}`,
            hasHighlight: true,
        };
    } else {
        return {
            content: `${first ?? ""}${last ? " " : ""}${last ?? ""}`,
            hasHighlight: false,
        };
    }
}

interface ICategoryExtraInfo {
    icon: ReactNode;
    linkType?: LinkType;
    link?: string;
}

function getInfoForSearchResponse(data: ISearchBarResultResponse): ICategoryExtraInfo {
    const isOwner: boolean = isInPortal({ owner: true });
    const isBuilder: boolean = isInPortal({ builder: true });
    switch (data.category) {
        case SearchCategory.Todo:
            return {
                icon: <BTIconTodos size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.toDo.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Contact:
            return {
                icon: <BTIconContacts size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.contact.getDetailsLink(data.id, false)),
            };
        case SearchCategory.ChangeOrders:
            return {
                icon: <BTIconChangeOrders size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.changeOrder.getChangeOrderDetailsLink(data.id, data.jobId!)
                ),
            };
        case SearchCategory.PurchaseOrders:
            const purchaseOrderIcon = <BTIconPurchaseOrders size={20} />;
            const purchaseOrderLink = getSearchLink(
                routes.purchaseOrder.getDetailsLink(data.id, data.jobId!)
            );
            if (isOwner) {
                return {
                    icon: purchaseOrderIcon,
                    linkType: LinkType.NewTabLink,
                    link: getBaseRoute() + routes.purchaseOrder.getPrintLink([data.id]),
                };
            }
            return {
                icon: purchaseOrderIcon,
                linkType: LinkType.RelativeLink,
                link: purchaseOrderLink,
            };
        case SearchCategory.Bills:
            return {
                icon: <BTIconBillsPurchaseOrders size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.bill.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Subs:
            return {
                icon: <BTIconSubs size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.sub.getDetailsLink(data.id)),
            };
        case SearchCategory.InternalUsers:
            return {
                icon: <BTIconInternalUsers size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.internalUsers.getInternalUserDetailsLink(data.id)),
            };
        case SearchCategory.DailyLogs:
            return {
                icon: <BTIconDailyLogs size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.dailyLog.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.OwnerInvoices:
            return {
                icon: <BTIconOwnerInvoices size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.ownerInvoice.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Messages:
            return {
                icon: <BTIconMessages size={20} />,
                linkType: LinkType.RelativeLink,
                link: data["isDraft"]
                    ? getSearchLink(routes.messages.getComposeLink(data.id, data.jobId!))
                    : getSearchLink(routes.messages.getMessageDetailsLink(data.id)),
            };
        case SearchCategory.Schedules:
            return {
                icon: <BTIconSchedules size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.schedule.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Warranties:
            return {
                icon: <BTIconWarranties size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.warranty.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.BidPackages:
            const url = isBuilder
                ? routes.bidPackage.getDetailsLink(data.id, data.jobId!)
                : routes.bid.getDetailsLink(data["bidId"], data.jobId!);
            return {
                icon: <BTIconBidPackages size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(url),
            };
        case SearchCategory.Leads:
            return {
                icon: <BTIconLeads size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.lead.getDetailsLink(data.id)),
            };
        case SearchCategory.LeadProposals:
            return {
                icon: <BTIconLeadProposals size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.leadProposals.getDetailsLink(data.id, data["leadId"], false)
                ),
            };
        case SearchCategory.Selections:
            return {
                icon: <BTIconSelections size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.selection.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.RFIs:
            return {
                icon: <BTIconRFIs size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.rfi.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Allowances:
            return {
                icon: <BTIconAllowances size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.allowance.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Documents:
            return {
                icon: <BTIconDocuments size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Document)
                ),
            };
        case SearchCategory.Photos:
            return {
                icon: <BTIconPhotos size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Photo)
                ),
            };
        case SearchCategory.Videos:
            return {
                icon: <BTIconVideos size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Video)
                ),
            };
        case SearchCategory.Jobs:
            return {
                icon: <BTIconJobs size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.job.getDetailsLink(data.id)),
            };
        case SearchCategory.Templates:
            return {
                icon: <BTIconJobs size={20} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.template.getTemplateDetailsLink(data.id)),
            };
        case SearchCategory.DocumentFolders:
            return {
                icon: <BTIconFolderOutlined size={20} />,
                linkType: LinkType.AutoSPARouting,
                link:
                    getBaseRoute() +
                    routes.document.getListLink(
                        DirectoryType.Standard,
                        data.id,
                        data.jobId ?? undefined
                    ),
            };
        case SearchCategory.PhotoFolders:
            return {
                icon: <BTIconFolderOutlined size={20} />,
                linkType: LinkType.AutoSPARouting,
                link:
                    getBaseRoute() +
                    routes.photo.getListLink(
                        DirectoryType.Standard,
                        data.id,
                        data.jobId ?? undefined
                    ),
            };
        case SearchCategory.VideoFolders:
            return {
                icon: <BTIconFolderOutlined size={20} />,
                linkType: LinkType.AutoSPARouting,
                link:
                    getBaseRoute() +
                    routes.video.getListLink(
                        DirectoryType.Standard,
                        data.id,
                        data.jobId ?? undefined
                    ),
            };
        case SearchCategory.CostItems:
            return {
                icon: <BTIconBookOpen size={20} />,
            };
        default:
            throw new Error(`Search result card not implemented for enum ${data.category}`);
    }
}

function getSearchLink(url: string) {
    return `/SearchResult${url}`;
}
