import { Popover, Space } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { useCallback, useContext } from "react";
// eslint-disable-next-line no-restricted-imports
import { useTracking } from "react-tracking";

import { FocusContext } from "commonComponents/utilities/Focus/FocusContext";
import { IHotkey } from "commonComponents/utilities/Hotkey/hotkey.types";
import { useHotkey } from "commonComponents/utilities/Hotkey/useHotkey";

import "./Hotkey.less";

export interface IHotkeyDisplayProps {
    rawCommand: string;
}

interface IHotkeyProps extends IHotkey {
    onCommand: () => void;
    disabled?: boolean;
    triggerBehavior?: "down" | "up" | "press";
    popoverPlacement?: TooltipPlacement;
    preventDefaultWhenDisabled?: boolean;
}

export function HotkeyDisplay({ rawCommand }: IHotkeyDisplayProps) {
    const isMac = navigator.userAgent.includes("Mac");
    const commands = [
        ...new Set(
            rawCommand.split(/, /).map((command) => {
                if (isMac) {
                    return command.replaceAll("ctrl", "cmd");
                } else {
                    return command.replaceAll("cmd", "ctrl");
                }
            })
        ),
    ];

    return (
        <Space split="or" size={4}>
            {commands.map((command) => (
                <Space key={command} split="+" size={1}>
                    {command.split("+").map((key) => (
                        <kbd key={key} className="command">
                            {key}
                        </kbd>
                    ))}
                </Space>
            ))}
        </Space>
    );
}

export function Hotkey({
    command,
    friendlyName,
    supportedTags,
    onCommand,
    disabled,
    triggerBehavior = "press",
    children,
    popoverPlacement = "top",
    preventDefaultWhenDisabled = true,
}: React.PropsWithChildren<IHotkeyProps>) {
    const { trackEvent } = useTracking();

    const onCommandWithTracking = useCallback(() => {
        trackEvent({
            event: "HotKey",
            hotkey: friendlyName,
        });
        onCommand();
    }, [friendlyName, onCommand, trackEvent]);

    useHotkey({
        command,
        onCommand: onCommandWithTracking,
        supportedTags,
        triggerBehavior,
        disabled,
        preventDefaultWhenDisabled,
    });
    const focusContext = useContext(FocusContext);

    if (!children) {
        return null;
    }

    const isPopoverVisible =
        !disabled && focusContext && focusContext.isHelpMenuEnabled && focusContext.isCurrentFocus;

    return (
        // eslint-disable-next-line react/forbid-elements
        <Popover
            content={
                <span className="HotkeyPopoverContent">
                    <HotkeyDisplay rawCommand={command} />
                </span>
            }
            overlayClassName="HotkeyPopoverOverlay"
            visible={isPopoverVisible ?? false}
            placement={popoverPlacement}
        >
            {children}
        </Popover>
    );
}
