import { createContext } from "react";
import { Blue9 } from "styles/antTheme/Colors";

import { BrandingInfo } from "helpers/AppProvider.types";

/** The default branding info to be used when a builder is not in session */
export const defaultBrandingInfo: BrandingInfo = {
    primaryColor: Blue9,
};

/**
 * Context containing branding info, including the current builder in session's
 * primary color. A default will be provided if a builder is not in session
 */
export const BrandingInfoContext = createContext<BrandingInfo>(defaultBrandingInfo);
