import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTIconCheckCircleOutlinedSuccess,
    BTIconCloseCircleOutlinedError,
    BTIconExclamationCircleOutlinedWarning,
    BTIconLoadingOutlined,
} from "commonComponents/btWrappers/BTIcon";

import "./ImportStatus.less";

const StatusIcon: React.FunctionComponent<{ status: string }> = ({ status }) => {
    switch (status) {
        case "loading":
            return <BTIconLoadingOutlined size={82} />;
        case "check-circle-success":
            return <BTIconCheckCircleOutlinedSuccess size={82} />;
        case "warning-warning":
            return <BTIconExclamationCircleOutlinedWarning size={82} />;
        case "close-circle-error":
            return <BTIconCloseCircleOutlinedError size={82} />;
    }
    return <> </>;
};

export interface IImportStatusProps {
    status: "loading" | "check-circle-success" | "warning-warning" | "close-circle-error";
    primaryLabel: React.ReactNode;
    secondaryLabel?: React.ReactNode;
    cancelled: boolean;
    okButtonText?: string;
    onCancel?: () => void;
    onOk?: () => void | Promise<void>;
    customFooter?: JSX.Element;
}

export const ImportStatus: React.FunctionComponent<IImportStatusProps> = (props) => {
    const loading = props.status === "loading";
    const buttonClick = (loading && props.onCancel) || (!loading && props.onOk);

    const okButtonText = props.okButtonText ?? "Ok";

    return (
        <div className="ImportStatus">
            <StatusIcon status={props.status} />
            <div className="PrimaryLabel">{props.primaryLabel}</div>
            {props.secondaryLabel && <div className="margin-top-lg">{props.secondaryLabel}</div>}
            {props.children}
            {buttonClick && !props.customFooter && (
                <BTButton
                    className="margin-top-lg"
                    data-testid="okCancel"
                    onClick={buttonClick}
                    type={loading ? "secondary" : "primary"}
                    loading={props.cancelled}
                    disabled={props.cancelled}
                >
                    {loading ? "Cancel" : okButtonText}
                </BTButton>
            )}
            {props.customFooter}
        </div>
    );
};

export interface IImportStatusListProps {
    status: "loading" | "check-circle-success" | "warning-warning" | "close-circle-error";
    primaryLabel: React.ReactNode;
    secondaryLabel?: React.ReactNode;
    cancelled: boolean;
    okButtonText?: string;
    onCancel?: () => void;
    onOk?: () => void;
    customFooter?: JSX.Element;
    errorList?: string[];
    errorListLabel?: string;
}

export const ImportStatusList: React.FunctionComponent<IImportStatusListProps> = (props) => {
    const {
        status,
        primaryLabel,
        secondaryLabel,
        cancelled,
        okButtonText,
        onCancel,
        onOk,
        customFooter,
        errorList,
        errorListLabel,
    } = props;

    const errorItems = errorList?.map((item, index) => <li key={index}>{item}</li>);

    return (
        <ImportStatus
            status={status}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            okButtonText={okButtonText}
            onOk={onOk}
            onCancel={onCancel}
            cancelled={cancelled}
            customFooter={customFooter}
        >
            <>
                {errorItems && (
                    <>
                        <br />
                        <strong>{errorListLabel}</strong>
                        <ul>{errorItems}</ul>
                    </>
                )}
            </>
        </ImportStatus>
    );
};
