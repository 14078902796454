import {
    AttachedFiles,
    AttachedFilesRequest,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import {
    IHasLineItems,
    ILineItemContainerData,
    ILineItemData,
    ILineItemResponse,
    LineItem,
    LineItemType,
    PriceTypes,
} from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import {
    BTSelectItem,
    IBaseEntity,
    IWithServiceValidationErrors,
    mapBTServiceDropdownToSelectItem,
    ServiceValidation,
} from "types/apiResponse/apiResponse";
import { CostTypes, DocumentInstanceType, MarkedAs } from "types/enum";
import { AccountingIntegrationType } from "types/enum";

import { calculateBuilderCost } from "utilities/lineItem/lineItemHelper";

import {
    AccountingValidationData,
    IHasAccountingValidation,
} from "commonComponents/accounting/AccountingValidation/AccountingValidation.types";
import {
    DiscussionsEntity,
    IHasDiscussionContainer,
} from "commonComponents/entity/discussion/DiscussionContainer/DiscussionContainer.api.types";
import { RecentItemsTooltipConfig } from "commonComponents/entity/recentItemsTooltip/RecentItemsTooltip";
import { AccountingEntityTypes } from "commonComponents/financial/Common/Accounting.types";
import { ILineItemRow } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    ICostLineItem,
    ITaxLineItem,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { TaxMethod } from "entity/tax/common/tax.types";
import { TaxRateBreakdownInfo } from "entity/tax/common/TaxRateBreakdown/TaxRateBreakdown.api.types";
import { IExternalProductLink, VendorTypes } from "entity/vendor/common/vendor.types";

export type CreditMemoFormActions =
    | undefined
    | "save"
    | "saveAndRelease"
    | "saveAndClose"
    | "unrelease"
    | "applyToInvoice"
    | "print"
    | "delete"
    | "addOwnerEmail"
    | "skipAddingOwnerEmail"
    | "saveToAccounting";

export interface ICreditMemoFormValues {
    containerIsValid: boolean;
    title: string;
    referenceId: string;
    description: string;
    amount: number;
    amountApplied: number;
    amountRemaining: number;
    notifyOwner: boolean;
    priceType: PriceTypes;
    showLineItemsToOwner: boolean;
    attachedFiles: AttachedFilesRequest;
    costCodeIds: number[];
    sendToAccounting: boolean | undefined;
    creditMemoLineItems: CreditMemoLineItem[];
    ownerEmail: string;
    applyInvoiceId: number | undefined;
    taxMethod: TaxMethod;
    taxGroupId?: number;
}

export class CreditMemoSaveRequest {
    constructor(
        data: ICreditMemoFormValues,
        id: number,
        jobId: number,
        builderId: number,
        status: CreditMemoStatusTypes
    ) {
        this.id = id;
        this.referenceId = data.referenceId;
        this.title = data.title;
        this.description = data.description;
        this.amountApplied = data.amountApplied;
        this.builderId = builderId;
        this.jobId = jobId;
        this.status = status;
        this.amount = data.amount;
        this.useLineItems = data.priceType === PriceTypes.LineItems;
        this.taxMethod = data.taxMethod;
        this.lineItems = transformCreditMemoLineItemsToLineItems(
            data.creditMemoLineItems,
            this.useLineItems ? undefined : this.amount,
            this.taxMethod
        );
        this.showLineItemsToOwner = data.showLineItemsToOwner;
        this.attachedFiles = data.attachedFiles;
        this.sendToAccounting = data.sendToAccounting ?? false;
        this.notifyOwner = data.notifyOwner;
        this.ownerEmail = data.ownerEmail;
        this.taxGroupId = data.taxGroupId;
    }
    id: number;
    referenceId: string;
    title: string;
    description: string;
    amountApplied: number;
    amount: number;
    allowanceId?: number;
    status: CreditMemoStatusTypes;
    dateOfLastAction: string;
    userOfLastAction: string;

    documentInstanceType: DocumentInstanceType = DocumentInstanceType.CreditMemo;
    name: string = "Credit Memo";
    jobId?: number | undefined;
    builderId: number;
    lineItems: LineItem[];
    showLineItemsToOwner: boolean;
    useLineItems: boolean;

    discussions: DiscussionsEntity;
    attachedFiles: AttachedFilesRequest;
    sendToAccounting: boolean;
    notifyOwner: boolean;
    ownerEmail: string;
    taxMethod: TaxMethod;
    taxGroupId?: number;
}

export class CreditMemoEntity
    implements
        IBaseEntity,
        IHasAttachedFiles,
        IHasDiscussionContainer,
        IHasLineItems,
        IHasAccountingValidation
{
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.jobName = data.jobName;
        this.instanceKey = uuidv4();
        this.referenceId = data.referenceId;
        this.title = data.title;
        this.description = data.description ?? "";
        this.amountApplied = data.amountApplied;
        this.amount = data.amount;
        this.amountRemaining = data.amountRemaining;
        this.status = data.status;

        this.createdByDetails = data.createdByDetails;
        this.createdOn = moment(data.createdOn);
        this.createdBy = data.createdBy;
        this.createdById = data.createdById;
        this.lastUpdatedByDate = moment(data.lastUpdatedByDate);
        this.lastUpdatedById = data.lastUpdatedById;
        this.lastUpdatedByName = data.lastUpdatedByName;

        this.appliedByDetails = data.appliedByDetails;
        this.appliedToInvoices = data.appliedToInvoices
            ? data.appliedToInvoices.map((p: any) => new CreditMemoAppliedInvoice(p))
            : null;
        this.showAccounting = data.showAccounting;
        this.integrationName = data.integrationName;
        this.lineItems = data.lineItems;

        this.lineItemContainerData = {
            pageType: "credit-memos",
            pageTypeEnum: LineItemType.CreditMemo,
            customUnitCostHeader: "Unit Amount",
            customBuilderCostHeader: "Amount",
            hideFlatFeeOwnerPrice: true,
            priceType:
                data.useLineItems && (this.id > 0 || this.amount === 0)
                    ? PriceTypes.LineItems
                    : PriceTypes.FlatFee,
            showToOwner: data.showLineItemsToOwner,
            canEditCostCodes: data.canEditCostCodes,
        };

        this.showLineItemsToOwner = data.showLineItemsToOwner;
        this.useLineItems = data.useLineItems;
        this.canEdit = data.canEdit;
        this.canEditPrice = data.canEditPrice;
        this.canOwnerViewInvoicesTab = data.canOwnerViewInvoicesTab;
        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        if (data.discussions) {
            this.discussions = new DiscussionsEntity(data.discussions);
        }

        this.accountingValidation = {
            entityId: this.id,
            entityType: AccountingEntityTypes.CreditMemo,
            showValidation: this.amount !== 0,
            showAccountsReceivable: data.hasAccountsReceivable,
            canGetLatestStatus: data.canGetLatestStatus,
            canResetAccounting: data.canResetAccounting,
            canSendToAccounting: data.canEdit,
            accountingIntegrationName: data.integrationName,
            shouldValidateOnLoad: true,
        };

        this.shouldSendToAccounting = data.shouldAutomaticallySendToAccounting;
        this.accountingResetWarningText = data.resetWarningText;
        this.canSyncWithAccounting = data.canSyncWithAccounting;
        this.isLinkedToAccounting = data.isLinkedToAccounting;

        this.shouldPromptNotifyOwner = data.shouldPromptNotifyOwner;
        this.shouldRequestOwnerEmail = data.shouldRequestOwnerEmail;
        this.isOwnerActive = data.isOwnerActive;
        this.canApplyToOwnerInvoices = data.canApplyToInvoices;
        this.accountingSectionTitle = `${data.integrationName} Status`;
        this.customIdPrefix = data.customCreditMemoIdPrefix;
        this.recentItemsTooltip = data.recentItems
            ? new RecentItemsTooltipConfig(data.recentItems)
            : undefined;

        this.creditMemoLineItems = data.lineItems?.value.map(
            (x: ILineItemResponse) => new CreditMemoLineItem(x) ?? []
        );

        const applicableInvoices = data.applicableInvoices?.map((i: any) => new BTSelectItem(i));
        this.applicableInvoices =
            applicableInvoices && applicableInvoices.length > 0
                ? [new BTSelectItem({ id: -1, name: "Please Select One" })].concat(
                      applicableInvoices
                  )
                : [];

        this.taxMethods = data.taxMethods && mapBTServiceDropdownToSelectItem(data.taxMethods);
        this.taxGroupId = data.taxGroupId;
        this.taxRateBreakdown = data.taxRateBreakdown;

        this.hasCostCodesFeature = data.hasCostCodesFeature;
    }

    id: number;
    jobId: number | undefined;
    builderId: number;
    jobName: string;
    instanceKey: string;

    referenceId: string;
    title: string;
    description: string;
    amountApplied: number;
    amountRemaining: number;
    amount: number;
    status: CreditMemoStatusTypes;

    createdByDetails?: string;
    createdOn: moment.Moment;
    createdBy: string;
    createdById: number;
    lastUpdatedByDate: moment.Moment;
    lastUpdatedByName: string;
    lastUpdatedById: number;

    appliedByDetails?: string;

    applicableInvoices: BTSelectItem[];
    appliedToInvoices: CreditMemoAppliedInvoice[] | null;

    documentInstanceType: DocumentInstanceType = DocumentInstanceType.CreditMemo;
    name: string = "Credit Memo";
    attachedFiles: AttachedFiles;
    showLineItemsToOwner: boolean;
    useLineItems: boolean;
    canEdit: boolean;
    canEditPrice: boolean;
    canOwnerViewInvoicesTab: boolean;

    discussions: DiscussionsEntity;

    accountingType: AccountingIntegrationType;
    showAccounting: boolean;
    integrationName: string;
    accountingValidation: AccountingValidationData;
    shouldSendToAccounting: boolean | undefined;
    accountingResetWarningText: string;
    accountingSectionTitle: string;
    canSyncWithAccounting: boolean;
    isLinkedToAccounting: boolean;

    // IHasLineItems
    lineItems: ILineItemData;
    lineItemContainerData: ILineItemContainerData;

    creditMemoLineItems: CreditMemoLineItem[];
    shouldPromptNotifyOwner: boolean;
    shouldRequestOwnerEmail: boolean;
    isOwnerActive: boolean;
    canApplyToOwnerInvoices: boolean;
    recentItemsTooltip?: RecentItemsTooltipConfig;
    customIdPrefix?: string;

    taxMethods: BTSelectItem[] | undefined;
    taxGroupId?: number;
    taxRateBreakdown?: TaxRateBreakdownInfo;

    hasCostCodesFeature: boolean;
}

export class CreditMemoSaveResponse implements IWithServiceValidationErrors {
    constructor(data: any) {
        this.creditMemoId = data.creditMemoId;
        this.jobId = data.jobId;
        this.formMessage = data.formMessage;
        this.failedFields = data.failedFields;
        this.accountingErrorMessage = data.accountingErrorMessage;
        this.isErrorAlert = data.isErrorAlert;
    }
    jobId: number;
    creditMemoId: number;
    formMessage: string;
    failedFields: ServiceValidation[];
    accountingErrorMessage?: string;
    isErrorAlert: boolean;
}

export class CreditMemoAppliedInvoice {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.title = data.title;
        this.key = data.key;
    }

    id?: number;
    jobId?: number;
    title: string;
    key: string;
}

export class CreditMemoApplyResponse {}

export class CreditMemoAccountingResponse implements IWithServiceValidationErrors {
    constructor(data: any) {
        this.failedFields = data.failedFields;
        this.formMessage = data.formMessage;
        this.isErrorAlert = data.isErrorAlert;
        this.accountingErrorMessage = data.accountingErrorMessage;
    }
    formMessage: string;
    failedFields: ServiceValidation[];
    isErrorAlert: boolean;
    accountingErrorMessage: string;
}

export class CreditMemoDeleteResponse {}

export enum CreditMemoStatusTypes {
    Unreleased = 0,
    Released = 1,
    PartiallyApplied = 2,
    Applied = 3,
}

export enum CreditMemoAccountingStatusTypes {
    NotSynced = 0,
    PendingSync = 1,
    Synced = 2,
    Applied = 3,
}

export enum CreditMemoLineItemColumns {
    TitleAndCostCode = 1,
    CostTypes = 2,
    UnitCost = 3,
    Quantity = 4,
    Tax = 5,
    BuilderCost = 6,
    Delete = 7,
}

export interface ICreditMemoLineItem extends ITaxLineItem, ICostLineItem, ILineItemRow {
    quantity: number;
}

export class CreditMemoLineItem implements ICreditMemoLineItem {
    constructor(data: any) {
        this.quantity = data.quantity;
        this.itemTitle = data.title;
        this.costCodeId = data.costCodeId;
        this.lineItemType = LineItemType.CreditMemo;
        this.costItemId = data.costCodeItemId;
        this.id = data.lineItemId ?? data.id;
        this.unitCost = data.unitCost;
        this.builderCost = calculateBuilderCost(this.unitCost, this.quantity);
        this.costTypes = data.costTypes ?? [];
        this.markedAs = data.markedAs;
        this.description = data.description;
        this.isEditable = true;
        this._isInEdit = data._isInEdit;
        this.isExpanded = data.isExpanded ?? false;
        this.taxGroupId = data.taxGroupId;
        this.totalWithTax = data.totalWithTax;
    }
    unitCost: number;
    builderCost: number;
    internalNotes: string | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    parentId?: number | undefined;
    relatedGeneralItemId?: number | null | undefined;
    vendorProductId?: number | undefined;
    vendorType?: VendorTypes | undefined;
    isDiscontinued?: boolean | undefined;
    productLink?: IExternalProductLink | undefined;
    varianceCodeId?: number | null | undefined;
    varianceCodeTitle?: string | undefined;
    unit: string;
    description: string;
    _isInEdit?: boolean | undefined;
    isSelected?: boolean | undefined;
    isExpanded?: boolean | undefined;
    isEditable: boolean;
    autoFocusedField?: string | undefined;
    quantity: number;
    itemTitle: string | null;
    costCodeId: number | null;
    lineItemType: LineItemType;
    costItemId: number | null;
    id: number;
    taxGroupId?: number | undefined;
    totalWithTax?: number | undefined;
}

function transformCreditMemoLineItemsToLineItems(
    creditMemoLineItems: CreditMemoLineItem[],
    amount?: number,
    taxMethod?: TaxMethod
) {
    return creditMemoLineItems.map(
        (li: ICreditMemoLineItem) =>
            new LineItem(
                {
                    id: li.id > 0 ? li.id : 0,
                    costCodeId: li.costCodeId,
                    costCode: li.costCodeId!,
                    unitCost: li.unitCost,
                    quantity: li.quantity,
                    title: li.itemTitle,
                    description: li.description,
                    costTypes: li.costTypes,
                    builderCost: amount ?? calculateBuilderCost(li.unitCost, li.quantity),
                    taxGroupId: taxMethod !== TaxMethod.None ? li.taxGroupId : null,
                    totalWithTax: li.totalWithTax,
                    markedAs: li.markedAs,
                } as ILineItemResponse,
                "",
                LineItemType.CreditMemo
            )
    );
}
