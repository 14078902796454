import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconExclamationCircleOutlined,
    BTIconMagnifyingGlassEnrichedDark,
    BTIconMagnifyingGlassX,
} from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { emptyStateIconSize } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { EmptyState } from "commonComponents/entity/emptyState/EmptyState";
import {
    IGlobalSearchError,
    IGlobalSearchState,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";

import "./GlobalSearchEmptyState.less";

interface IGlobalSearchEmptyStateProps {
    searchState: IGlobalSearchState;
}

const NewState = () => {
    return (
        <EmptyState
            className="GlobalSearchEmptyState"
            image={
                <BTIconMagnifyingGlassEnrichedDark
                    size={emptyStateIconSize}
                    className="EmptyStateIcon"
                    data-testid="EmptyStateIcon"
                />
            }
        >
            <BTRow className="margin-top-md">
                <BTCol>
                    <BTTitle level={2}>Find anything in Buildertrend</BTTitle>
                </BTCol>
                <BTCol>Search across all open jobs or find something specific with filters.</BTCol>
            </BTRow>
        </EmptyState>
    );
};

const NoResults = () => {
    return (
        <EmptyState
            className="GlobalSearchEmptyState"
            image={
                <BTIconMagnifyingGlassX
                    size={emptyStateIconSize}
                    className="EmptyStateIcon"
                    data-testid="EmptyStateIcon"
                />
            }
        >
            <BTRow>
                <BTCol>We didn't find anything matching your search.</BTCol>
            </BTRow>
        </EmptyState>
    );
};

interface IErrorState {
    error: IGlobalSearchError;
}
const ErrorState: React.FunctionComponent<IErrorState> = ({ error }) => {
    return (
        <EmptyState
            className="GlobalSearchEmptyState"
            image={
                <BTIconExclamationCircleOutlined
                    size={emptyStateIconSize}
                    className="EmptyStateIcon"
                    data-testid="EmptyStateIcon"
                />
            }
        >
            <BTRow>
                <BTCol className="padding-top-md ErrorStateMessage">{error.message}</BTCol>
            </BTRow>
        </EmptyState>
    );
};

export const GlobalSearchEmptyState: React.FunctionComponent<IGlobalSearchEmptyStateProps> = ({
    searchState,
}) => {
    return (
        <>
            {searchState.searchValue === "" && <NewState />}
            {searchState.results && searchState.results.length === 0 && <NoResults />}
            {searchState.error && <ErrorState error={searchState.error} />}
        </>
    );
};
