import { createContext } from "react";

import { SupportContactInfo } from "helpers/AppProvider.types";

/**
 * Context containing the support information for the builder currently
 * in session.
 *
 * The context consumer value will be null if no builder is in session
 * or undefined if the context consumer is not within a parent
 */
export const SupportContactInfoContext = createContext<SupportContactInfo | null | undefined>(
    undefined
);
