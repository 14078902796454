import { Moment } from "moment";

export interface IJobAccess {
    hasAccess: boolean;
    hasNotificationAccess?: boolean | null;
    jobId: number;
    jobStatus: JobStatusFilterTypes;
    jobName: string;
    groupNames?: string[];
    dateCreated: Moment;
}

export class JobAccessEntity {
    constructor(data: any) {
        this.jobs = data.jobs;
    }
    jobs: IJobAccess[];
}

export enum JobStatusFilterTypes {
    NoFilter = 0,
    Open = 1,
    Closed = 2,
    OpenOrClosed = 3,
    Deleted = 4,
    Presale = 5,
    Templates = 8,
}
