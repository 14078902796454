import { createContext, useContext, useEffect } from "react";

import { BuilderFlags, BuilderInfo, CurrencyLocale } from "helpers/AppProvider.types";
import { IGlobalValues } from "helpers/GlobalValues";

import { isNullOrUndefined } from "utilities/object/object";

/**
 * Context containing builder info, including certain builder configurations, the
 * builder's locale, and the builder id.
 *
 * The context consumer value will be null if no builder is in session
 * or undefined if the context consumer is not within a parent
 */
export const BuilderInfoContext = createContext<BuilderInfo | null | undefined>(undefined);

/**
 * HACK: Hopefully we can get rid of this when we remove knockout. This needs to be it's own component
 * per https://github.com/facebook/react/issues/15281 and cannot be within the builder info provider.
 * React calls the use effect hooks of children components before the parent's use effect hooks. Therefore,
 * in order to enable "nested" overriding behavior, this BuilderInfoGlobalOverride component is rendered
 * as a *sibling* component rather than an ancestor (see the provider below for what I mean)
 */
const BuilderInfoGlobalOverride: React.FC<IBuilderInfoProviderProps> = ({ value }) => {
    // While we are still servicing knockout components, we need to make sure btJscriptsGlobal and
    // BTNumeric are populated with the right locale settings
    const currencyLocale = value?.locale.currencyLocale ?? CurrencyLocale.default;
    useEffect(() => {
        let oldBtJScriptGlobalCurrencyLocale:
            | Pick<
                  IGlobalValues,
                  | "getBuilderNegativeSign"
                  | "getBuilderNumberDecimalDigits"
                  | "getBuilderNumberDecimalSeparator"
                  | "getBuilderNumberGroupSeparator"
                  | "getBuilderNumberGroupSizes"
                  | "getBuilderNumberNegativePattern"
                  | "getBuilderCurrencySymbol"
              >
            | undefined = undefined;

        // This is a valid use case to bypass the SPA proxy for now, because we need to directly modify btJScriptGlobals to support legacy components
        const btJScriptGlobals: IGlobalValues =
            window.btJScriptGlobals.spaProxyBypass ?? window.btJScriptGlobals;
        if (btJScriptGlobals) {
            oldBtJScriptGlobalCurrencyLocale = {
                getBuilderNegativeSign: btJScriptGlobals.getBuilderNegativeSign,
                getBuilderNumberDecimalDigits: btJScriptGlobals.getBuilderNumberDecimalDigits,
                getBuilderNumberDecimalSeparator: btJScriptGlobals.getBuilderNumberDecimalSeparator,
                getBuilderNumberGroupSeparator: btJScriptGlobals.getBuilderNumberGroupSeparator,
                getBuilderNumberGroupSizes: btJScriptGlobals.getBuilderNumberGroupSizes,
                getBuilderNumberNegativePattern: btJScriptGlobals.getBuilderNumberNegativePattern,
                getBuilderCurrencySymbol: btJScriptGlobals.getBuilderCurrencySymbol,
            };

            btJScriptGlobals.getBuilderNumberDecimalSeparator = currencyLocale.decimalSeparator;
            btJScriptGlobals.getBuilderNumberGroupSeparator = currencyLocale.groupSeparator;
            btJScriptGlobals.getBuilderCurrencySymbol = currencyLocale.currencySymbol;

            // eslint-disable-next-line no-restricted-syntax
            const btNumeric = (window as any).btNumeric;
            if (!isNullOrUndefined(btNumeric) && typeof btNumeric["Init"] === "function") {
                btNumeric.Init(
                    btJScriptGlobals.getBuilderNegativeSign,
                    btJScriptGlobals.getBuilderNumberDecimalDigits,
                    currencyLocale.decimalSeparator,
                    currencyLocale.groupSeparator,
                    btJScriptGlobals.getBuilderNumberGroupSizes,
                    btJScriptGlobals.getBuilderNumberNegativePattern,
                    currencyLocale.currencySymbol
                );
            }
        }

        return () => {
            // On unmount, or when currencyLocale changes, restore the original values
            if (oldBtJScriptGlobalCurrencyLocale) {
                btJScriptGlobals.getBuilderNumberDecimalSeparator =
                    oldBtJScriptGlobalCurrencyLocale.getBuilderNumberDecimalSeparator;
                btJScriptGlobals.getBuilderNumberGroupSeparator =
                    oldBtJScriptGlobalCurrencyLocale.getBuilderNumberGroupSeparator;
                btJScriptGlobals.getBuilderCurrencySymbol =
                    oldBtJScriptGlobalCurrencyLocale.getBuilderCurrencySymbol;

                // eslint-disable-next-line no-restricted-syntax
                const btNumeric = (window as any).btNumeric;
                if (!isNullOrUndefined(btNumeric) && typeof btNumeric["Init"] === "function") {
                    btNumeric.Init(
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNegativeSign,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNumberDecimalDigits,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNumberDecimalSeparator,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNumberGroupSeparator,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNumberGroupSizes,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderNumberNegativePattern,
                        oldBtJScriptGlobalCurrencyLocale.getBuilderCurrencySymbol
                    );
                }
            }
        };
    }, [currencyLocale]);

    return null;
};
export interface IBuilderInfoProviderProps {
    value: BuilderInfo | null;
}

export const BuilderInfoProvider: React.FC<IBuilderInfoProviderProps> = ({ value, children }) => {
    return (
        // eslint-disable-next-line react/forbid-elements
        <BuilderInfoContext.Provider value={value}>
            <BuilderInfoGlobalOverride value={value} />
            {children}
        </BuilderInfoContext.Provider>
    );
};

export interface IWhenBuilderFlagProps {
    /**
     * The builder flag key to render the "then" prop if true, or "otherwise" if false. You can pass
     * multiple flag conditions which are AND'D together.
     * @example
     * // Single condition
     * <WhenBuilderFlag
     *     isEnabled="myFlag"
     *     then={<>I display when myFlag is true</>}
     *  />
     * // Multiple conditions
     * <WhenBuilderFlag
     *     isEnabled={{ flag1: true, flag2: false }}
     *     then={<>I display when flag1 is true and flag2 is false</>}
     * />
     */
    isEnabled: keyof BuilderFlags | Partial<{ [key in keyof BuilderFlags]: boolean }>;
    /**
     * The react element or render function which will be outputed when the flag condition matches.
     * Will not render anything if not provided. **Avoid using the render function
     * when possible as this may be removed soon**
     */
    then?: React.ReactNode | (() => React.ReactNode);
    /**
     * The react element or render function which will be outputed when the flag condition **DOES
     * NOT** match. Will not render anything if not provided. **Avoid using the render function
     * when possible as this may be removed soon**
     */
    otherwise?: React.ReactNode | (() => React.ReactNode);
    /**
     * The default to render when no builder context is present
     * If not set, then this component will throw an error without context
     */
    noBuilderInfoContextDefault?: "then" | "otherwise";
}

/**
 * Utility that renders components depending on the flag value
 * @example
 * <WhenBuilderFlag
 *   isEnabled="myFlag"
 *   then={<>My flag is enabled</>}
 *   otherwise={<>My flag is disabled</>}
 * />
 */
export const WhenBuilderFlag: React.FC<IWhenBuilderFlagProps> = ({
    isEnabled: flags,
    then,
    otherwise,
    noBuilderInfoContextDefault,
}) => {
    const builderContext = useContext(BuilderInfoContext);

    const renderThen = () => {
        if (typeof then === "function") {
            return then();
        } else {
            return then;
        }
    };

    const renderOtherwise = () => {
        if (typeof otherwise === "function") {
            return otherwise();
        } else {
            return otherwise;
        }
    };

    if (!builderContext) {
        if (noBuilderInfoContextDefault === "then" && then) {
            return renderThen();
        } else if (noBuilderInfoContextDefault === "otherwise" && otherwise) {
            return renderOtherwise();
        } else {
            throw new Error(
                "Attempted to enter builder flag check but the builder info is not in context. Did you " +
                    "forget to add an AppProvider or BuilderInfoContext wrapper? Is the builder in session?"
            );
        }
    } else {
        let areAllBuilderFlagsInCorrectState: boolean;
        if (typeof flags === "object") {
            areAllBuilderFlagsInCorrectState = Array.from(Object.entries(flags)).every(
                ([flagKey, desiredFlagValue]) => builderContext.flags[flagKey] === desiredFlagValue
            );
        } else {
            areAllBuilderFlagsInCorrectState = builderContext.flags[flags];
        }

        if (areAllBuilderFlagsInCorrectState) {
            if (then) {
                return renderThen();
            }
        } else {
            if (otherwise) {
                return renderOtherwise();
            }
        }
    }

    return null;
};
