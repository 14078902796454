import { PureComponent } from "react";
import { Grey8 } from "styles/antTheme/Colors";

/**
 * Use for less important text
 * @example <>Normal 14px font... <NoteText>This text will be smaller (12px)</NoteText></>
 */
export class NoteText extends PureComponent<{ className?: string }> {
    render() {
        return (
            <span className={this.props.className} style={{ fontSize: "12px", color: Grey8 }}>
                {this.props.children}
            </span>
        );
    }
}
