import { Route, RouteComponentProps } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";

export interface IRouteRebateListProps extends RouteComponentProps {}

const RebateList = lazyLoadWithRetry(() => import("entity/rebate/RebateList/RebateList"));

export const RouteRebateList = (
    props: Omit<IRouteRebateListProps, "history" | "match" | "location">
) => (
    <Route
        path={routes.rebates.list}
        render={(routeProps) => {
            const tab = routeProps.match.params.tab;

            return (
                <BTSuspense>
                    <RebateList {...props} {...routeProps} selectedTab={tab} />
                </BTSuspense>
            );
        }}
    />
);
