import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { PaymentEntityTypes, ProposalStatus } from "types/enum";

import { APIHandler, IBlobResponse } from "utilities/apiHandler";

import { IPaymentMethodFormValues } from "commonComponents/financial/PaymentMethodDetails/PaymentMethodDetails.api.types";
import {
    IDisplayPreferenceSaveRequest,
    ProposalDisplayColumnTypes,
} from "commonComponents/utilities/LineItemDisplaySettings/LineItemDisplaySettings.api.types";

import {
    BulkAssignToAssemblySaveResponse,
    IBulkAssignToAssemblySaveRequest,
} from "entity/assembly/AssignToAssembly/AssignToAssembly.api.types";
import { IReleaseToOwnerFormValues } from "entity/estimate/Proposal/ReleaseToOwner/ReleaseToOwner.types";
import { IResetStatusPromptFormValues } from "entity/estimate/Proposal/ResetStatusPrompt/ResetStatusPrompt.types";
import { ITemplateImportForLeadProposalsImportRequest } from "entity/leadProposal/LeadProposal/TemplateImportForLeadProposal/TemplateImportForLeadProposals.api.types";

import {
    CreateCostItemFromLineItemRequest,
    CreateCostItemFromLineItemResponse,
    ILeadProposalApproveDeclineRequest,
    ILeadProposalExternalRequest,
    ILeadProposalFormValues,
    ILeadProposalPaymentRequestFormValues,
    LeadProposalCreateResponse,
    LeadProposalDeleteResponse,
    LeadProposalEntity,
    LeadProposalPaymentRequestResponse,
    LeadProposalSaveRequest,
    LeadProposalTemplatesResponse,
    LeadProposalUnreleaseRequest,
    ProposalPaymentDueTypes,
} from "./LeadProposal.api.types";

export interface ILeadProposalHandler {
    get(id: number, checkoutId?: number): Promise<LeadProposalEntity>;
    createDefault(leadId: number): Promise<LeadProposalCreateResponse>;
    update(
        id: number,
        formData: ILeadProposalFormValues,
        builderId: number
    ): Promise<EmptyResponseEntity>;
    release(
        id: number,
        releaseToOwnerValues: IReleaseToOwnerFormValues
    ): Promise<EmptyResponseEntity>;
    unreleaseOrReset(
        id: number,
        status: ProposalStatus,
        values?: IResetStatusPromptFormValues,
        updateCostItems?: boolean
    ): Promise<EmptyResponseEntity>;
    approveDeclineLeadProposal(
        id: number,
        signatureFile: ILeadProposalApproveDeclineRequest
    ): Promise<EmptyResponseEntity>;
    delete(id: number): Promise<LeadProposalDeleteResponse>;
    notify(id: number): Promise<EmptyResponseEntity>;
    getProposalTemplates(): Promise<LeadProposalTemplatesResponse>;
    getProposalsFromLeads(): Promise<LeadProposalTemplatesResponse>;
    saveLineItemDisplayPreference(
        proposalId: number,
        preferenceSaveRequest: IDisplayPreferenceSaveRequest<ProposalDisplayColumnTypes>
    ): Promise<EmptyResponseEntity>;
    download(proposalId: number): Promise<IBlobResponse>;
    importProposalTemplate(
        proposalId: number,
        values: ITemplateImportForLeadProposalsImportRequest
    ): Promise<EmptyResponseEntity>;
    getPaymentRequestInfo(proposalId: number): Promise<LeadProposalPaymentRequestResponse>;
    updatePaymentRequestInfo(
        proposalId: number,
        formValues?: ILeadProposalPaymentRequestFormValues,
        removePaymentRequest?: boolean
    ): Promise<EmptyResponseEntity>;
    getExternalProposal(
        encodedProposalId: string | undefined,
        isBuilder: boolean,
        isForPdfGeneration: boolean,
        checkoutId?: number,
        shareToken?: string
    ): Promise<LeadProposalEntity>;
    approveDeclineExternalLeadProposal(
        id: string,
        request: ILeadProposalExternalRequest
    ): Promise<LeadProposalEntity>;
    createDefaultTemplate(): Promise<LeadProposalCreateResponse>;
    submitPayment(encodedId: string, values: IPaymentMethodFormValues): Promise<LeadProposalEntity>;
    createCostItemFromLineItem(
        costItemCreateRequest: CreateCostItemFromLineItemRequest
    ): Promise<CreateCostItemFromLineItemResponse>;
    skipPayment(leadProproposalIdposalId: number): Promise<EmptyResponseEntity>;
    assignToAssembly: (
        request: IBulkAssignToAssemblySaveRequest
    ) => Promise<BulkAssignToAssemblySaveResponse>;
}

export class LeadProposalHandler implements ILeadProposalHandler {
    getProposalTemplates(): Promise<LeadProposalTemplatesResponse> {
        return APIHandler("/api/LeadProposal/ProposalTemplates", {
            method: "GET",
            responseType: LeadProposalTemplatesResponse,
        });
    }

    async getProposalsFromLeads(): Promise<LeadProposalTemplatesResponse> {
        return APIHandler("/api/Leads/LeadsWithProposals", {
            method: "GET",
            responseType: LeadProposalTemplatesResponse,
        });
    }

    async get(id: number, checkoutId?: number): Promise<LeadProposalEntity> {
        const qsParam = checkoutId && checkoutId > 0 ? `?checkoutId=${checkoutId}` : "";
        return await APIHandler(`/api/LeadProposal/${id}${qsParam}`, {
            method: "GET",
            responseType: LeadProposalEntity,
        });
    }

    async createDefault(leadId: number): Promise<LeadProposalCreateResponse> {
        return await APIHandler(`/api/Leads/Proposal?id=${leadId}`, {
            method: "POST",
            responseType: LeadProposalCreateResponse,
        });
    }

    async update(
        id: number,
        formData: ILeadProposalFormValues,
        builderId: number
    ): Promise<EmptyResponseEntity> {
        const requestData = new LeadProposalSaveRequest(formData, builderId);
        return await APIHandler(`/api/LeadProposal/${id}`, {
            method: "PUT",
            data: requestData,
            responseType: EmptyResponseEntity,
        });
    }

    async release(
        id: number,
        releaseToOwnerValues: IReleaseToOwnerFormValues
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/LeadProposal/${id}/Review`, {
            method: "PUT",
            data: {
                leadEmail: releaseToOwnerValues.ownerEmail,
                additionalText: releaseToOwnerValues.emailDescription,
                sendBuilderACopy: releaseToOwnerValues.sendMeACopy,
                status: ProposalStatus.Pending,
            },
            responseType: EmptyResponseEntity,
        });
    }

    async unreleaseOrReset(
        id: number,
        status: ProposalStatus,
        values?: IResetStatusPromptFormValues,
        updateCostItems?: boolean
    ): Promise<EmptyResponseEntity> {
        const requestData = new LeadProposalUnreleaseRequest(status, values, updateCostItems);
        return await APIHandler(`/api/LeadProposal/${id}/Review`, {
            method: "PUT",
            data: requestData,
            responseType: EmptyResponseEntity,
        });
    }

    async approveDeclineLeadProposal(
        id: number,
        requestData: ILeadProposalApproveDeclineRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/LeadProposal/${id}/Review`, {
            method: "PUT",
            data: requestData,
            responseType: EmptyResponseEntity,
        });
    }

    async delete(id: number): Promise<LeadProposalDeleteResponse> {
        return await APIHandler(`/api/LeadProposal/${id}`, {
            method: "DELETE",
            responseType: LeadProposalDeleteResponse,
        });
    }

    async notify(id: number): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/Notify`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
        });
    }

    async saveLineItemDisplayPreference(
        proposalId: number,
        preferenceSaveRequest: IDisplayPreferenceSaveRequest<ProposalDisplayColumnTypes>
    ) {
        return await APIHandler(`/api/Proposals/${proposalId}/SaveLineItemDisplayPreference`, {
            method: "PUT",
            data: preferenceSaveRequest,
            responseType: EmptyResponseEntity,
        });
    }

    async download(proposalId: number): Promise<IBlobResponse> {
        return await APIHandler(`/api/LeadProposal/${proposalId}/ExportToExcel`, {
            method: "GET",
            responseType: "blob",
        });
    }

    async importProposalTemplate(
        proposalId: number,
        values: ITemplateImportForLeadProposalsImportRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/LeadProposal/${proposalId}/ImportProposalTemplate`, {
            method: "PUT",
            data: values,
            responseType: EmptyResponseEntity,
        });
    }

    async getPaymentRequestInfo(proposalId: number): Promise<LeadProposalPaymentRequestResponse> {
        return await APIHandler(`/api/LeadProposal/${proposalId}/Payment`, {
            method: "GET",
            responseType: LeadProposalPaymentRequestResponse,
        });
    }

    async updatePaymentRequestInfo(
        proposalId: number,
        formValues?: ILeadProposalPaymentRequestFormValues,
        removePaymentRequest?: boolean
    ): Promise<EmptyResponseEntity> {
        let requestData = {
            ...formValues,
            requireOnlinePayment: !removePaymentRequest,
            paymentDueDays:
                formValues?.paymentDueType === ProposalPaymentDueTypes.RequiredToApprove
                    ? undefined
                    : formValues?.paymentDueDays,
        };
        return await APIHandler(`/api/LeadProposal/${proposalId}/Payment`, {
            method: "PUT",
            data: requestData,
            responseType: EmptyResponseEntity,
        });
    }

    async getExternalProposal(
        encodedProposalId: string | undefined,
        isBuilder: boolean,
        isForPdfGeneration: boolean,
        checkoutId?: number,
        shareToken?: string
    ): Promise<LeadProposalEntity> {
        return await APIHandler(`/api/LeadProposal/External`, {
            method: "GET",
            data: {
                encodedId: encodedProposalId,
                isBuilder,
                isForPdfGeneration,
                checkoutId,
                shareToken,
            },
            responseType: LeadProposalEntity,
        });
    }

    async approveDeclineExternalLeadProposal(
        id: string,
        request: ILeadProposalExternalRequest
    ): Promise<LeadProposalEntity> {
        return await APIHandler(`/api/LeadProposal/ExternalApprove?encodedId=${id}`, {
            method: "PUT",
            data: request,
            responseType: LeadProposalEntity,
        });
    }

    async createDefaultTemplate(): Promise<LeadProposalCreateResponse> {
        return await APIHandler(`/api/LeadProposal/DefaultProposalTemplate`, {
            method: "GET",
            responseType: LeadProposalCreateResponse,
        });
    }

    async submitPayment(
        encodedId: string,
        values: IPaymentMethodFormValues
    ): Promise<LeadProposalEntity> {
        return await APIHandler(`/api/LeadProposal/ExternalPayOnline?encodedId=${encodedId}`, {
            method: "PUT",
            data: values,
            responseType: LeadProposalEntity,
        });
    }

    async createCostItemFromLineItem(
        costItemCreateRequest: CreateCostItemFromLineItemRequest
    ): Promise<CreateCostItemFromLineItemResponse> {
        return await APIHandler("/api/LineItems/CreateCostItemFromLineItem", {
            method: "POST",
            data: costItemCreateRequest,
            responseType: CreateCostItemFromLineItemResponse,
        });
    }

    async skipPayment(proposalId: number): Promise<EmptyResponseEntity> {
        return await APIHandler(
            `/api/OnlinePayments/SkipPayment?id=${proposalId}&type=${PaymentEntityTypes.LeadProposal}`,
            {
                method: "PUT",
                responseType: EmptyResponseEntity,
            }
        );
    }

    async assignToAssembly(
        request: IBulkAssignToAssemblySaveRequest
    ): Promise<BulkAssignToAssemblySaveResponse> {
        return await APIHandler(`/api/LineItems/AssignToAssembly`, {
            method: "PUT",
            data: request,
            responseType: BulkAssignToAssemblySaveResponse,
        });
    }
}
