import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISurveyProps } from "entity/survey/Survey/Survey";

const Survey = lazyLoadWithRetry(() => import("./Survey"));

export const RouteSurvey = (
    props: Omit<ISurveyProps, "id" | "jobId" | "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.survey.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Survey
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
