import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { Component } from "react";

import { divide, multiply, round, subtract } from "utilities/math/math";

import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { CurrencyDisplay } from "commonComponents/financial/CurrencyDisplay/CurrencyDisplay";
import { PercentageDisplay } from "commonComponents/financial/PercentageDisplay/PercentageDisplay";
import { PercentageSection } from "commonComponents/utilities/PercentageDisplayBar/PercentageDisplayBar.types";

import "./PercentageDisplayBar.less";

export interface IPercentageDisplayBarProps {
    /* Note: All sections must provide a "value" if the display bar isn't in readonly mode */
    percentageSections: PercentageSection[];
    totalValue: number | undefined;
    outstandingLabel: string;
    readonly: boolean;
    placement?: TooltipPlacement;
    hidePercentage: boolean;
    hideTooltip: boolean;
    showFooter?: boolean;
    shouldPreventTextOverlap?: boolean;
    shouldIncludePopoverTotal?: boolean;
    className?: string;
}

interface IPercentageSectionTooltipProps {
    section: PercentageSection;
    shouldPreventTextOverlap?: boolean;
    percentageValue: number;
    readonly: boolean;
}

const totalPercentage = 100;

function PercentageSectionTooltip({
    section,
    shouldPreventTextOverlap,
    percentageValue,
    readonly,
}: IPercentageSectionTooltipProps) {
    if (shouldPreventTextOverlap) {
        return (
            <div className="percentageDisplayBar-item" style={{ verticalAlign: "middle" }}>
                <div className="percentageDisplayBar-section">
                    <div
                        className={classNames("percentageDisplayBar--popover-percent-box", {
                            [section.color]: true,
                            striped: section.striped,
                            outstanding: section.isOutstanding,
                        })}
                    />
                    <PercentageDisplay
                        data-testid="percentageDisplayBar-popover-value"
                        value={percentageValue}
                        decimalScale={0}
                    />
                </div>

                <div className="percentageDisplayBar--popover-label condensed">{section.label}</div>
            </div>
        );
    } else {
        return (
            <div className="percentageDisplayBar-item">
                <div
                    className={classNames("percentageDisplayBar--popover-percent-box", {
                        [section.color]: true,
                        striped: section.striped,
                        outstanding: section.isOutstanding,
                    })}
                >
                    <PercentageDisplay
                        data-testid="percentageDisplayBar-popover-value"
                        value={percentageValue}
                        decimalScale={0}
                    />
                </div>
                <div className="percentageDisplayBar--popover-label">
                    <div>{section.label}</div>
                    {!readonly && section.value !== undefined && (
                        <div>
                            <CurrencyDisplay value={section.value} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export class PercentageDisplayBar extends Component<IPercentageDisplayBarProps> {
    static defaultProps = {
        outstandingLabel: "Outstanding",
        readonly: false,
        totalValue: totalPercentage,
        placement: "rightTop",
        hidePercentage: false,
        hideTooltip: false,
        showFooter: false,
        shouldIncludePopoverTotal: true,
    };

    private isFullyOutstanding(section: PercentageSection): boolean {
        if (!section.isOutstanding) {
            return false;
        }
        if (this.props.readonly) {
            return section.percentage === 100;
        }
        // || returns 0 if section.value is any falsey value, it is sometimes NaN
        return (
            this.props.totalValue === 0 ||
            Number(multiply(divide(section.value || 0, this.props.totalValue!), 100)) === 100
        );
    }

    private getSectionDisplayPercentage(section: PercentageSection): number {
        if (this.isFullyOutstanding(section)) {
            return 100;
        }
        if (this.props.readonly) {
            return section.percentage;
        } else {
            let percentage =
                this.props.totalValue === 0
                    ? 0
                    : Number(multiply(divide(section.value || 0, this.props.totalValue!), 100));

            // Rounding issue (outstanding) - outstanding needs to account for rounding issues on the other percent display values
            if (section.isOutstanding) {
                let outstandingPercentage =
                    totalPercentage -
                    this.props.percentageSections.reduce(
                        (a, b) => a + this.getSectionDisplayPercentage(b),
                        0
                    );
                if (this.props.readonly) {
                    return round(outstandingPercentage);
                }
                return outstandingPercentage;
            }

            // Rounding issue - less than 1% remaining
            if (percentage > 99 && percentage < 100) {
                return 99;
            }
            if (percentage > 0 && percentage < 1) {
                return 1;
            }

            return percentage;
        }
    }

    private getSectionWidth(section: PercentageSection): number {
        if (this.isFullyOutstanding(section)) {
            return totalPercentage;
        }
        return this.getSectionDisplayPercentage(section);
    }

    private getSectionText(section: PercentageSection): React.ReactNode {
        if (section.percentage === 0) {
            return null;
        }
        if (section.showValueAsLabel && section.value !== undefined) {
            return (
                <CurrencyDisplay
                    value={section.value}
                    className={classNames({
                        boldCurrencyLabel: section.hasBoldLabel,
                        right: section.striped,
                    })}
                />
            );
        }
        if (this.props.hidePercentage) {
            return " ";
        }
        const minDisplayPercent = 20;
        const maxDisplayPercent = 100;
        let sectionDisplaySpace = this.getSectionWidth(section);
        return sectionDisplaySpace >= minDisplayPercent && sectionDisplaySpace < maxDisplayPercent
            ? round(this.getSectionDisplayPercentage(section)) + "%"
            : "";
    }

    // Outstanding helper functions
    private getOutstandingValue(): number {
        return (
            this.props.totalValue! - this.props.percentageSections.reduce((a, b) => a + b.value!, 0)
        );
    }

    private getOutstandingPercentage(): number {
        let percentAmount = subtract(
            totalPercentage,
            this.props.percentageSections.reduce(
                (a, b) => a + this.getSectionDisplayPercentage(b),
                0
            )
        ).toNumber();
        return percentAmount >= 0 ? percentAmount : 1;
    }

    render() {
        let outstandingSection = new PercentageSection({
            color: "",
            label: this.props.outstandingLabel,
            isOutstanding: true,
            value: this.props.readonly ? undefined : this.getOutstandingValue(),
            percentage: this.getOutstandingPercentage(),
            striped: false,
        });

        const popoverContent = (
            <div className="percentageDisplayBar--popover">
                {this.props.percentageSections
                    .concat(outstandingSection)
                    .filter((section) => {
                        return (
                            this.isFullyOutstanding(section) ||
                            (this.props.readonly ? section.percentage > 0 : section.value !== 0)
                        );
                    })
                    .map((section) => (
                        <PercentageSectionTooltip
                            key={"tooltip-" + section.label}
                            section={section}
                            shouldPreventTextOverlap={this.props.shouldPreventTextOverlap}
                            percentageValue={this.getSectionDisplayPercentage(section)}
                            readonly={this.props.readonly}
                        />
                    ))}
                {this.props.shouldIncludePopoverTotal && this.props.totalValue !== undefined && (
                    <div className="percentageDisplayBar-item percentageDisplayBar-total">
                        <div>Total</div>
                        <div style={{ textAlign: "right" }}>
                            <CurrencyDisplay value={this.props.totalValue} />
                        </div>
                    </div>
                )}
            </div>
        );

        const sectionContent = this.props.percentageSections.map((section) => {
            if (this.props.shouldPreventTextOverlap) {
                return (
                    <span
                        key={"section-content-" + section.label}
                        className={`percentageDisplayBar--section ${section.color} ${
                            section.striped ? "striped" : ""
                        }`}
                        style={{ width: this.getSectionWidth(section) + "%" }}
                        data-testid="percentageDisplayBar--section"
                    />
                );
            } else {
                return (
                    <span
                        key={"section-content-" + section.label}
                        className={`percentageDisplayBar--section ${section.color} ${
                            section.striped ? "striped" : ""
                        }`}
                        style={{ width: this.getSectionWidth(section) + "%" }}
                        data-testid="percentageDisplayBar--section"
                    >
                        {this.getSectionText(section)}
                    </span>
                );
            }
        });

        const progressContent = (
            <div className="percentageDisplayBar--meter">
                {sectionContent}
                {outstandingSection.percentage > 0 && (
                    <span
                        className="percentageDisplayBar--outstanding"
                        data-testid="percentageDisplayBar--outstanding"
                    >
                        {this.getSectionText(outstandingSection)}
                    </span>
                )}
            </div>
        );

        const progressFooter = (
            <div className="percentageDisplayBar--footer">
                {this.props.percentageSections.map((section) => {
                    const className = classNames("footer--section", section.labelClassName);
                    return (
                        <span key={"footer-" + section.label} className={className}>
                            {section.label}
                        </span>
                    );
                })}
            </div>
        );

        const sectionValueLabels = (
            <div className="percentageDisplayBar--footer">
                {this.props.percentageSections.map((section) => (
                    <span key={"section-value-" + section.label} className="footer--section">
                        {this.getSectionText(section)}
                    </span>
                ))}
            </div>
        );

        const percentageDisplayBarClass = classNames("percentageDisplayBar", this.props.className);
        return (
            <div className={percentageDisplayBarClass}>
                {this.props.shouldPreventTextOverlap && this.props.showFooter && progressFooter}
                {this.props.hideTooltip && progressContent}
                {!this.props.hideTooltip && (
                    <BTPopover
                        className="padding-left-zero"
                        content={popoverContent}
                        placement={this.props.placement}
                        autoAdjustOverflow={true}
                        arrowPointAtCenter={false}
                    >
                        {progressContent}
                    </BTPopover>
                )}
                {!this.props.shouldPreventTextOverlap && this.props.showFooter && progressFooter}
                {this.props.shouldPreventTextOverlap && sectionValueLabels}
            </div>
        );
    }
}
