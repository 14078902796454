import { Skeleton } from "antd";
import { RouteComponentProps } from "react-router";

import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes } from "types/enum";

import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconMailFilled, BTIconPhoneFilled } from "commonComponents/btWrappers/BTIcon";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTLink, BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTTable } from "commonComponents/btWrappers/BTTable/BTTable";
import { IOwnerProjectManager } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { OwnerProjectManagerResponse, useSummaryJobContactInfoByJobIdGetV2 } from "handlers";

import "./ContactInfoButton.less";

interface IContactInfoButtonProps extends RouteComponentProps {
    jobId: number;
    parentUrl: string;
}

const ContactInfoButton: React.FunctionComponent<IContactInfoButtonProps> = (props) => (
    <>
        <BTLinkRelative to={routes.contact.getContactInfoDisplayLink()}>
            <BTButton data-testid="contactInfo">{props.children}</BTButton>
        </BTLinkRelative>
        <RouteRelative
            path={routes.contact.contactInfoDisplay}
            render={() => <ContactInfoDisplayModal {...props} />}
        />
    </>
);

export const ContactInfoDisplayModal: React.FunctionComponent<IContactInfoButtonProps> = (
    props
) => {
    const userInfo = useRequiredContext(UserInfoContext);
    // The generated handlers don't let you send 0 as a parameter for jobId because it's a falsy value.
    // 0 represents all jobs selected.
    // Overriding the enabled property like this fixes this problem.
    const contactUsRequest = useSummaryJobContactInfoByJobIdGetV2(props.jobId, {
        query: { enabled: props.jobId !== undefined },
    });

    return (
        <BTModal
            data-testid="btModalContactInfoDisplay"
            centered
            visible
            width="650px"
            removeBodyPadding
            beforeClose={() => props.history.replace(props.parentUrl)}
            className="ContactInfoDisplay"
            useModalLayout
        >
            <BTModalLayout
                title="Contact Information"
                modalConfig={{
                    beforeClose: () => props.history.replace(props.parentUrl),
                    parentRoute: props.parentUrl,
                }}
            >
                {contactUsRequest.isLoading && <Skeleton className="Skeleton" />}
                {contactUsRequest.error && <div>Error loading contact information</div>}
                {contactUsRequest.data && (
                    <BTLayoutContent>
                        <PageSection title={contactUsRequest.data.data.builderName ?? ""}>
                            <div className="ContactInfoHeader">
                                <div>
                                    {contactUsRequest.data.data.phone && (
                                        <div>
                                            Phone:{" "}
                                            <PhoneLink phone={contactUsRequest.data.data.phone} />
                                        </div>
                                    )}
                                    {contactUsRequest.data.data.fax && (
                                        <div>
                                            Fax:{" "}
                                            <PhoneLink phone={contactUsRequest.data.data.fax} />
                                        </div>
                                    )}
                                </div>
                                {contactUsRequest.data.data.formattedAddress && (
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: contactUsRequest.data.data.formattedAddress,
                                        }}
                                    />
                                )}
                            </div>
                        </PageSection>
                        {contactUsRequest.data.data.projectManagers &&
                            contactUsRequest.data.data.projectManagers.length > 0 && (
                                <PageSection
                                    className="ProjectManagersList"
                                    title="Project Managers"
                                    removeBodyPadding
                                >
                                    <BTTable<OwnerProjectManagerResponse>
                                        showHeader={false}
                                        striped
                                        size="small"
                                        pagination={false}
                                        dataSource={contactUsRequest.data.data.projectManagers}
                                        columns={[
                                            {
                                                render: (pm) => (
                                                    <ProjectManagerRow
                                                        pm={pm}
                                                        loginType={userInfo.loginType}
                                                        key={`pm-${pm.userId}`}
                                                        jobId={props.jobId}
                                                    />
                                                ),
                                            },
                                        ]}
                                    />
                                </PageSection>
                            )}
                    </BTLayoutContent>
                )}
            </BTModalLayout>
        </BTModal>
    );
};

const ProjectManagerRow: React.FunctionComponent<{
    pm: IOwnerProjectManager;
    loginType: BTLoginTypes;
    jobId: number;
}> = (props) => (
    <div className="ProjectManagerInfo margin-horizontal-sm">
        <span>
            <BTLink
                useAutoSPARouting
                to={
                    routes.messages.getListLink() +
                    routes.messages.getComposeLink(0, props.jobId, {
                        defaultRecipientId: props.pm.userId,
                    })
                }
            >
                <BTIconMailFilled />
            </BTLink>{" "}
            {props.pm.name}
        </span>
        <span>{props.pm.phone && <PhoneLink phone={props.pm.phone} />}</span>
    </div>
);

export const PhoneLink: React.FunctionComponent<{
    phone?: string;
}> = (props) => {
    let phoneLink = (
        <BTButton
            className="ContactButton"
            data-testid="emptyPhone"
            icon={<BTIconPhoneFilled />}
            type="link"
            disabled={!props.phone}
            isolated
            noShadow
        >
            {props.phone ? props.phone : "(---) --- - ----"}
        </BTButton>
    );
    if (props.phone) {
        phoneLink = (
            <a onClick={(e) => e.stopPropagation()} href={`tel:${props.phone}`}>
                {phoneLink}
            </a>
        );
    }
    return <>{phoneLink}</>;
};

export default ContactInfoButton;
