import { btInfo } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";

export const marketplaceNonBuilderDialog = () => {
    btInfo({
        content: (
            <>
                <BTTitle level={3}>
                    Looks like your company doesn't have your own Buildertrend account.
                </BTTitle>
                <BTTitle level={3}>
                    <BTExternalLink href="https://www.buildertrend.com">Learn more</BTExternalLink>{" "}
                    to find out how Buildertrend can help your business.
                </BTTitle>
            </>
        ),
        onOk() {},
    });
};
