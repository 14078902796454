import * as Sentry from "@sentry/react";
import { FormikErrors } from "formik";
import { isEmpty } from "lodash-es";
import { ErrorInfo } from "react";

import { getGlobalValue } from "utilities/globalValueUtils";

interface IInternalErrorNotes {
    internalNotes: string;
}

function isSentryEnabled() {
    return (
        getGlobalValue("appDefaultFlags") !== undefined &&
        getGlobalValue("appDefaultFlags").sentryReactDSN !== undefined
    );
}

/**
 * Report an error to our client side error reporting system
 *
 * @param error - The error to report
 * @param extraInfo - Object containing extra information to aid in debugging
 * @param level - The Sentry.IO severity level of the event
 */
export function reportError(
    error: unknown,
    extraInfo?: ErrorInfo | IInternalErrorNotes,
    level?: Sentry.SeverityLevel
) {
    if (isSentryEnabled()) {
        Sentry.withScope((scope) => {
            if (extraInfo) {
                let contextRecord = { key: "extraInfo", value: extraInfo };

                scope.setContext("extraInfo", contextRecord);
            }
            if (level !== undefined) {
                scope.setLevel(level);
            }
            Sentry.captureException(error, scope);
        });
    }
}

/**
 * Send a message to our client side error reporting system. Generally used for additional debugging information.
 *
 * @param message - The message to send
 */
export function reportMessage(message: string) {
    if (isSentryEnabled()) {
        Sentry.captureMessage(message);
    }
}

/**
 * Add custom breadcrumbs to any error report that is sent to our client side error reporting system
 *
 * @param breadcrumb - The breadcrumb to add to any error report breadcrumb "trails"
 */
export function reportBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    if (isSentryEnabled()) {
        Sentry.addBreadcrumb(breadcrumb);
    }
}

export function showFailedFields<T>(e: any, setErrors: (errors: FormikErrors<T>) => void) {
    if (e.response.data && e.response.data.failedFields) {
        const errors = {};
        Array.from(e.response.data.failedFields).forEach((f: any) => {
            errors[f.key] = f.message[0];
        });
        if (!isEmpty(errors) && setErrors) {
            setErrors(errors);
            return true;
        }
    }
    return false;
}
