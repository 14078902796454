import moment from "moment";

import { BuilderInfo } from "helpers/AppProvider.types";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { routes } from "utilities/routes";

import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

import { default as ChannelListExampleJson } from "entity/chat/common/channelList.example.json";
import { getNameWithStatus } from "entity/chat/common/chat.utilities";
import { default as EventsExampleJson } from "entity/chat/common/events.example.json";
import { default as MessageableUsersExampleJson } from "entity/chat/common/messageableUser.example.json";
import { ChatWebviewInterface } from "entity/chat/webview/chatWebview.types";

import { default as AppProviderAPI } from "../../../helpers/AppProvider.api.json";

export enum ChannelType {
    // Frontend only
    Draft = -1,

    ToDos = 0,
    Schedules = 1,
    All = 2,
    Selections = 3,
    ChangeOrders = 4,
    PurchaseOrders = 5,
    Warranty = 6,
    Bids = 7,
    DailyLogs = 8,
    OwnerPayments = 9,
    Photos = 10,
    Documents = 11,
    Videos = 12,
    LegacyBills = 13,
    PhotoDocs = 14,
    BidPackages = 15,
    CreditMemo = 16,
    Receipts = 17,
    Bills = 18,
    Jobsite = 19,
    Deposits = 20,
    DirectMessaging = 21,
    GroupMessaging = 22,
}

export enum ChatEventType {
    Message = 0,
    ChannelCreated = 1,
    UserAdded = 2,
    UserRemoved = 3,
    ChannelRead = 4,
}

export enum ChannelHistoryElementType {
    Message = 0,
    UserAdded = 1,
    UserRemoved = 2,
}

export enum ChannelParticipantStatus {
    Active = 0,
    Inactive = 1,
    Deleted = 2,
}

export const ChannelRowHeightInPixels = 64;

interface ISetDraftSelectedParticipants {
    type: "setDraftSelectedParticipants";
    payload: BTSelectItem<MessageableUsersExtraData>[];
}

interface ICreateDraftChannel {
    type: "createDraftChannelIfNotExists";
    payload?: {
        participant: {
            id: number;
            name: string;
        };
    };
}

interface IChannelsLoaded {
    type: "channelsLoaded";
    payload: Channel[];
}

interface ISetChannelCursor {
    type: "setChannelCursor";
    payload: string | null;
}

interface ISetIsLoadingChannels {
    type: "setIsLoadingChannels";
    payload: boolean;
}

interface ISetDraftIsLoadingRelatedChannel {
    type: "setDraftIsLoadingRelatedChannel";
    payload: boolean;
}

interface ISetDraftRelatedChannel {
    type: "setDraftRelatedChannel";
    payload: Channel | null;
}

interface ISetIsLoadingEventsUp {
    type: "setIsLoadingEventsUp";
    payload: boolean;
}

interface ISetIsLoadingEventsDown {
    type: "setIsLoadingEventsDown";
    payload: boolean;
}

interface ICurrentChannelSelectionChanged {
    type: "currentChannelSelectionChanged";
    payload: {
        selection: string | typeof routes.chat.createChannelUrlParam;
        /**
         * When selecting a channel, record the last read date of the channel before selecting the
         * channel. This will be used to "freeze" the last read divider to the time when you first navigate to the channel.
         * This may be null if the last read dividder should not be shown, for example if there is
         * no messages after the last read divider since you clicked on the channel or if
         * you never read the channel yet.
         */
        lastReadSinceChannelSelectedDateUtc: moment.Moment;
    };
}

export interface IHistoricalChatEventsLoaded {
    type: "historicalChatEventsLoaded";
    payload: {
        events: ChatEvent[];
        currentUserIds: Set<number>;
        /**
         * The cursor for loading "older" data (data when scrolling up in chat)
         * Undefined means don't set the current upCursor value in state
         */
        upCursor?: string | null;
        /**
         * The cursor for loading "newer" data (data when scrolling down in chat)
         * Undefined means don't set the current downCursor value in state. Usually is null unless chat
         * was loaded from the middle of the chat history.
         */
        downCursor?: string | null;
    };
}

interface IDeleteDraftChannel {
    type: "deleteDraftChannel";
    payload?: never;
}

interface ISetChannelIsRead {
    type: "setChannelIsRead";
    payload: string;
}

interface ISetIsPolling {
    type: "setIsPolling";
    payload: boolean;
}

interface IChannelEventsPolled {
    type: "channelEventsPolled";
    payload: {
        events: ChatEvent[];
        currentUserIds: Set<number>;
        lastPolledDateUtc: moment.Moment;
    };
}

interface IPollingErrorStateChanged {
    type: "pollingErrorStateChanged";
    payload: unknown | null;
}

interface IChannelLoadingCriticallyFailed {
    type: "channelLoadingCriticallyFailed";
    payload: Error | null;
}

interface IChannelHistoryLoadingCriticallyFailed {
    type: "channelHistoryLoadingCriticallyFailed";
    payload: Error | null;
}

interface ISetIsLoadingSelectedChannel {
    type: "setIsLoadingSelectedChannel";
    payload: boolean;
}

interface ISelectedChannelLoadingCriticallyFailed {
    type: "selectedChannelLoadingCriticallyFailed";
    payload: Error | null;
}

interface IChannelNameUpdated {
    type: "channelNameUpdated";
    payload: {
        channelId: string;
        channelName: string;
    };
}

interface ILocallyOriginatedChannelEventsOccured {
    type: "locallyOriginatedChannelEventsOccured";
    payload: {
        events: ChatEvent[];
        currentUserIds: Set<number>;
    };
}

interface IHardScrollToBottomRequested {
    type: "hardScrollToBottomRequested";
    payload?: never;
}

interface IClearSelectedChannelState {
    type: "clearSelectedChannelState";
    payload?: never;
}

export type ChatAction =
    | IChannelsLoaded
    | ISetChannelCursor
    | ISetIsLoadingChannels
    | ICreateDraftChannel
    | ISetDraftSelectedParticipants
    | ISetDraftIsLoadingRelatedChannel
    | ISetDraftRelatedChannel
    | ISetIsLoadingEventsUp
    | ISetIsLoadingEventsDown
    | IHistoricalChatEventsLoaded
    | ICurrentChannelSelectionChanged
    | IDeleteDraftChannel
    | ISetChannelIsRead
    | ISetIsPolling
    | IChannelEventsPolled
    | IPollingErrorStateChanged
    | IChannelLoadingCriticallyFailed
    | IChannelHistoryLoadingCriticallyFailed
    | ISetIsLoadingSelectedChannel
    | ISelectedChannelLoadingCriticallyFailed
    | IChannelNameUpdated
    | ILocallyOriginatedChannelEventsOccured
    | IHardScrollToBottomRequested
    | IClearSelectedChannelState;

export class ChannelParticipant {
    constructor(data: (typeof ChannelListExampleJson)["data"][number]["participants"][number]) {
        this.name = data.name;
        this.userId = data.userId;
        this.status = data.status;
        this.role = data.role;
    }

    name: string;
    userId: number;
    status: ChannelParticipantStatus;
    role: string;
}

export interface IChannel {
    channelId: string;
    channelName: string;
    channelType: ChannelType;
    latestMessageDateUtc: moment.Moment;
}

export interface IDraftChannelState extends IChannel {
    channelId: typeof routes.chat.createChannelUrlParam;
    /**
     * Drafts may have a channel associated with them if you are creating a chat with someone that
     * you already have a conversation started with
     */
    relatedChannelId: string | null;
    isLoadingRelatedChannel: boolean;
    channelType: ChannelType.Draft;
    channelName: string;
    selectedParticipants: BTSelectItem<MessageableUsersExtraData>[];
}

export interface IChatState {
    channels: (Channel | IDraftChannelState)[];
    currentChannelHistory: IChannelHistory | null;
    hasInitiallyLoadedChannels: boolean;
    nextChannelCursor: string | null;
    isLoadingChannels: boolean;
    channelLoadingCriticalError: Error | null;
    isLoadingSelectedChannel: boolean;
    loadingSelectedChannelCriticalError: Error | null;

    // Polling state values
    lastPolledDateUtc: moment.Moment;
    isPolling: boolean;
    pollingError: unknown | null;
}

type ChannelId = string;
export type IChatDraftEditorContent = Record<ChannelId, EditorContent>;

export interface IChannelHistory {
    channelId: string;
    channelHistoryElements: ChannelHistoryElement[];
    virtuosoFirstItemIndex: number;
    hasInitiallyLoadedChannelEvents: boolean;
    upCursor: string | null;
    downCursor: string | null;
    isLoadingEventsUp: boolean;
    isLoadingEventsDown: boolean;
    channelHistoryLoadingCriticalError: Error | null;
    lastReadSinceChannelSelectedUtc: moment.Moment;
    dateTimeSinceChannelSelectedUtc: moment.Moment;
}

interface IChatEventBase {
    type: ChatEventType;
    eventDateTimeUtc: moment.Moment;
    eventId: string;
}

export class ChannelMessageEvent implements IChatEventBase, IChannelHistoryElement {
    constructor(data: any) {
        this.type = ChatEventType.Message;
        this.eventDateTimeUtc = moment.utc(data.eventDateTimeUtc);
        this.message = new ChannelMessage(data.message);
        this.eventId = data.eventId;

        this.channelHistoryElementType = ChannelHistoryElementType.Message;
        this.channelHistoryElementId = this.eventId;
        this.channelHistoryElementDateUtc = this.eventDateTimeUtc.clone();
    }

    type: ChatEventType.Message;
    eventDateTimeUtc: moment.Moment;
    message: ChannelMessage;
    eventId: string;

    channelHistoryElementType: ChannelHistoryElementType.Message;
    channelHistoryElementId: string;
    channelHistoryElementDateUtc: moment.Moment;
}

export class ChannelCreatedEvent implements IChatEventBase {
    constructor(data: any) {
        this.type = ChatEventType.ChannelCreated;
        this.eventDateTimeUtc = moment.utc(data.eventDateTimeUtc);
        this.channel = new Channel(data.channel);
        this.eventId = data.eventId;
    }

    type: ChatEventType.ChannelCreated;
    eventDateTimeUtc: moment.Moment;
    channel: Channel;
    eventId: string;
}

export class ChannelReadEvent implements IChatEventBase {
    constructor(data: any) {
        this.type = ChatEventType.ChannelRead;
        this.eventId = data.eventId;
        this.eventDateTimeUtc = moment.utc(data.eventDateTimeUtc);
        this.channelId = data.channelId;
        this.userId = data.userId;
    }
    type: ChatEventType.ChannelRead;
    eventId: string;
    eventDateTimeUtc: moment.Moment;
    channelId: string;
    userId: string;
}

// This interface represents every element in the chat thread array that can be rendered
interface IChannelHistoryElement {
    channelHistoryElementType: ChannelHistoryElementType;
    channelHistoryElementId: string;
    channelHistoryElementDateUtc: moment.Moment;
}

export class UserAddedEvent implements IChatEventBase, IChannelHistoryElement {
    constructor(data: any) {
        this.type = ChatEventType.UserAdded;
        this.eventDateTimeUtc = moment.utc(data.eventDateTimeUtc);
        this.eventId = data.eventId;
        this.userName = getNameWithStatus(data.userName, data.status);
        this.userId = data.userId;
        this.channelId = data.channelId;
        this.status = data.status;
        this.role = data.role;

        this.channelHistoryElementType = ChannelHistoryElementType.UserAdded;
        this.channelHistoryElementId = this.eventId;
        this.channelHistoryElementDateUtc = this.eventDateTimeUtc.clone();
    }

    userName: string;
    userId: number;
    type: ChatEventType.UserAdded;
    eventDateTimeUtc: moment.Moment;
    eventId: string;
    channelId: string;
    status: ChannelParticipantStatus;
    role: string;

    channelHistoryElementType: ChannelHistoryElementType.UserAdded;
    channelHistoryElementId: string;
    channelHistoryElementDateUtc: moment.Moment;
}

export class UserRemovedEvent implements IChatEventBase, IChannelHistoryElement {
    constructor(data: any) {
        this.type = ChatEventType.UserRemoved;
        this.eventDateTimeUtc = moment.utc(data.eventDateTimeUtc);
        this.eventId = data.eventId;
        this.userName = getNameWithStatus(data.userName, data.status);
        this.userId = data.userId;
        this.channelId = data.channelId;
        this.status = data.status;

        this.channelHistoryElementType = ChannelHistoryElementType.UserRemoved;
        this.channelHistoryElementId = this.eventId;
        this.channelHistoryElementDateUtc = this.eventDateTimeUtc.clone();
    }

    userName: string;
    userId: number;
    type: ChatEventType.UserRemoved;
    eventDateTimeUtc: moment.Moment;
    eventId: string;
    channelId: string;
    status: ChannelParticipantStatus;

    channelHistoryElementType: ChannelHistoryElementType.UserRemoved;
    channelHistoryElementId: string;
    channelHistoryElementDateUtc: moment.Moment;
}

// When adding new channel events (ex. User added, user removed, etc., add to this union type using `|` )
export type ChatEvent =
    | ChannelMessageEvent
    | ChannelCreatedEvent
    | ChannelReadEvent
    | UserAddedEvent
    | UserRemovedEvent;
export type ChannelHistoryElement = ChannelMessageEvent | UserAddedEvent | UserRemovedEvent;

interface IChannelUrlParams {
    channelId: typeof routes.chat.createChannelUrlParam | string | undefined;
    participantId: undefined;
    participantName: undefined;
}

interface IDraftWithParticipantUrlParams {
    channelId: typeof routes.chat.createChannelUrlParam | string | undefined;
    participantId: string;
    participantName: string;
}

export type ChannelUrlParams = IChannelUrlParams | IDraftWithParticipantUrlParams;

export interface IChannelListRequest {
    channelTypes: ChannelType[];
    limit: number;
    participantIds?: number[];
    nextCursor?: string | null;
    exactMatchesOnly?: boolean;
    excludeTitledChannels?: boolean;
    includeReadOnly?: boolean;
}

export interface IChannelEventListRequest {
    nextCursor?: string | null;
    previousCursor?: string | null;
    limit: number;
    afterDateUtc?: moment.Moment;
    dir?: "asc" | "desc";
}

export interface IVirtuosoContext {
    currentChannelHistory: IChannelHistory;
}

export class ChannelReadResponse {}

export class ChannelEventListResponse {
    constructor(data: typeof EventsExampleJson) {
        this.nextCursor = data.nextCursor;
        this.previousCursor = data.previousCursor;
        this.data = data.data.map(getChatEventResponse);
    }

    data: ChatEvent[];
    nextCursor: string | null;
    previousCursor: string | null;
}

export class ChannelListResponse {
    constructor(data: typeof ChannelListExampleJson) {
        this.data = data.data.map((datum) => new Channel(datum));
        this.nextCursor = data.nextCursor;
    }

    data: Channel[];
    nextCursor: string | null;
}

export interface IMessageableUsersRequest {
    query: string | undefined;
    limit: number;
    nextCursor?: string | null;
    userIdsToExclude?: number[];
}

export class MessageableUsersResponse {
    constructor(data: typeof MessageableUsersExampleJson) {
        this.data = data.data.map(
            (datum: (typeof MessageableUsersExampleJson)["data"][0]) => new BTSelectItem(datum)
        );
        this.nextCursor = data.nextCursor;
    }

    data: BTSelectItem<MessageableUsersExtraData>[];
    nextCursor: string | null;
}

export class MessageableUsersExtraData {
    constructor(data: (typeof MessageableUsersExampleJson)["data"][0]["extraData"]) {
        this.role = data.role;
    }
    role: string;
}

export class ISendChatRequest {
    content: EditorContent;
}

export class ICreateChannelRequest {
    participants: number[];
    initialMessage: EditorContent;
}

export class IAddUsersRequest {
    userIds: number[];
}

export class Channel implements IChannel {
    constructor(data: (typeof ChannelListExampleJson)["data"][number]) {
        this.channelId = data.channelId;
        this.channelName = data.channelName;
        this.channelType = data.channelType;
        this.participants = data.participants.map(
            (participant) => new ChannelParticipant(participant)
        );
        this.chatPreview = new ChannelMessageEvent(data.chatPreview);
        this.lastReadDateUtc = moment.utc(data.lastReadDateUtc);
        this.latestMessageDateUtc = moment.utc(data.latestMessageDateUtc);
        this.builderInfo = new BuilderInfo(data.builderInfo as typeof AppProviderAPI.builderInfo);
    }

    channelId: string;
    channelName: string;
    participants: ChannelParticipant[];
    channelType: ChannelType;
    chatPreview: ChannelMessageEvent;
    latestMessageDateUtc: moment.Moment;
    lastReadDateUtc: moment.Moment;
    builderInfo: BuilderInfo;
}

export interface IChannelMediaUploadResponse {
    mediaId: string;
}

export class ChannelMessage {
    constructor(data: any) {
        this.id = data.id;
        this.externalId = data.externalId;
        this.channelId = data.channelId;
        this.authorId = data.authorId;
        this.authorName = getNameWithStatus(data.authorName, data.authorStatus);
        this.authorStatus = data.authorStatus;
        this.authorRole = data.authorRole;
        this.content = new EditorContent(data.content);
        this.plainTextContent = data.plainTextContent;
        this.dateSentUtc = moment.utc(data.dateSentUtc);
    }

    id: number;
    externalId: string;
    channelId: string;
    authorId: number;
    authorName: string;
    authorStatus: ChannelParticipantStatus;
    authorRole: string;
    content: EditorContent;
    plainTextContent: string;
    dateSentUtc: moment.Moment;
}

const getChatEventResponse = (event: (typeof EventsExampleJson)["data"][number]) => {
    switch (event.type) {
        case ChatEventType.Message:
            return new ChannelMessageEvent(event);
        case ChatEventType.ChannelCreated:
            return new ChannelCreatedEvent(event);
        case ChatEventType.ChannelRead:
            return new ChannelReadEvent(event);
        case ChatEventType.UserAdded:
            return new UserAddedEvent(event);
        case ChatEventType.UserRemoved:
            return new UserRemovedEvent(event);
        default:
            throw new Error("Unknown chat event received from the server");
    }
};

export interface IChannelPatchRequest {
    channelName?: string;
    participants?: number[];
    channelType?: ChannelType;
}

/**
 * Event transforms contain all of the data needed to transform chat state for a given event. This is because
 * not all of the data needed to transform chat state is contained in the event itself. For example, when a
 * message is added, we need to know the channel that the message was added to, which may or may not be loaded
 * in state yet. With transforms, we can keep API calls in the component layer and keep the dispatch logic
 * more "pure"
 */
export type ChatStateTransform = (state: Readonly<IChatState>) => Readonly<IChatState>;

/**
 * These represent settings required to initialize chat in a webview context
 */
export interface IChatWebviewInitializationSettings {
    isImageUploadSupportedByNative: boolean;
}

export interface IChatWebviewConfig {
    chatInitializationSettings: IChatWebviewInitializationSettings;
    interface: ChatWebviewInterface;
    platform: "ios" | "android";
    platformVersion: string;
}

export class ChannelEventCursorResponse {
    constructor(data: {
        nextCursorInclusive: string | null;
        previousCursorExclusive: string | null;
    }) {
        this.nextCursorInclusive = data.nextCursorInclusive;
        this.previousCursorExclusive = data.previousCursorExclusive;
    }

    nextCursorInclusive: string | null;
    previousCursorExclusive: string | null;
}
