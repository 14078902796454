import {
    GetNotificationPreferencesRequest,
    GetNotificationPreferencesResponse,
    IPreferenceToggleChange,
    IPushNotificationHandler,
    PushNotificationPreferences,
    RegisterBrowserResponse,
    SetNotificationPreferencesRequest,
    ToggleSinglePreferenceResponse,
} from "helpers/pushNotification.types";

import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

export class PushNotificationHandler implements IPushNotificationHandler {
    async registerBrowser(firebaseToken: string): Promise<RegisterBrowserResponse> {
        return await APIHandler(`/api/PushNotifications/registerBrowser`, {
            method: "POST",
            data: {
                token: firebaseToken,
            },
            responseType: RegisterBrowserResponse,
        });
    }

    async allowPushNotifications(shouldAllow: boolean): Promise<PushNotificationPreferences> {
        return await APIHandler(`/api/PushNotifications/toggle?on=${shouldAllow}`, {
            method: "PUT",
            responseType: PushNotificationPreferences,
        });
    }

    async toggleSinglePreference(
        preference: IPreferenceToggleChange,
        shouldTurnOn: boolean
    ): Promise<ToggleSinglePreferenceResponse> {
        return await APIHandler(`/api/PushNotifications/togglePreferences?on=${shouldTurnOn}`, {
            method: "PUT",
            data: {
                ...preference,
            },
            responseType: ToggleSinglePreferenceResponse,
        });
    }

    async getTogglePreferences(): Promise<PushNotificationPreferences> {
        return await APIHandler(`/api/PushNotifications/toggle`, {
            method: "GET",
            responseType: PushNotificationPreferences,
        });
    }

    async getPreferences(
        request: GetNotificationPreferencesRequest
    ): Promise<GetNotificationPreferencesResponse> {
        return await APIHandler(`/apix/v2.0/NotificationPreferences`, {
            method: "POST",
            responseType: GetNotificationPreferencesResponse,
            data: {
                ...request,
            },
        });
    }

    async setPreferences(request: SetNotificationPreferencesRequest): Promise<EmptyResponseEntity> {
        return await APIHandler(`/apix/v2.0/NotificationPreferences`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
            data: {
                ...request,
            },
        });
    }
}

export class FakePushNotificationHandler implements IPushNotificationHandler {
    async registerBrowser(): Promise<RegisterBrowserResponse> {
        return new RegisterBrowserResponse();
    }

    async allowPushNotifications(): Promise<PushNotificationPreferences> {
        return new PushNotificationPreferences();
    }

    async toggleSinglePreference(): Promise<ToggleSinglePreferenceResponse> {
        return new ToggleSinglePreferenceResponse();
    }

    async getTogglePreferences(): Promise<PushNotificationPreferences> {
        const data = new PushNotificationPreferences();
        data.hasPushNotificationsOn = true;
        return data;
    }

    async getPreferences(): Promise<GetNotificationPreferencesResponse> {
        return new GetNotificationPreferencesResponse();
    }

    async setPreferences(): Promise<EmptyResponseEntity> {
        return new EmptyResponseEntity();
    }
}

export const defaultPushNotificationHandler = new PushNotificationHandler();
