import { useContext } from "react";

/**
 * Alternative hook for retrieving context in places where it is required that the value
 * not be null or undefined. If null or undefined is found, an error will be thrown to the nearest
 * error boundary. Typically this means something has gone wrong and there is no reasonable
 * fallback.
 */
export function useRequiredContext<T>(context: React.Context<T>) {
    const contents = useContext(context);
    if (contents !== null && contents !== undefined) {
        // Can infer properly with TS 4.8
        // https://devblogs.microsoft.com/typescript/announcing-typescript-4-8/#improved-intersection-reduction-union-compatibility-and-narrowing
        return contents as NonNullable<T>;
    }
    throw new Error("context is required but was found to be null or undefined");
}
