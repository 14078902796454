import {
    AttachedFiles,
    AttachedFilesRequest,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { BTLoginTypes, DocumentInstanceType, LinkedScheduleType } from "types/enum";

import { getWorkdayExceptionRecurrences, IWorkdayExceptionRecurrences } from "utilities/date/date";
import { alphabetize } from "utilities/form/form";

import {
    DiscussionsEntity,
    IHasDiscussionContainer,
} from "commonComponents/entity/discussion/DiscussionContainer/DiscussionContainer.api.types";
import { RelatedToDos } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { UserWithConflicts } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";

import { default as ScheduleItemBuilderExample } from "./Schedule.builder.api.json";
import { default as ScheduleItemOwnerExample } from "./Schedule.owner.api.json";
import { default as ScheduleItemSubExample } from "./Schedule.sub.api.json";

export type ScheduleEntityBuilderType = typeof ScheduleItemBuilderExample;
export type ScheduleEntitySubType = typeof ScheduleItemSubExample;
export type ScheduleEntityOwnerType = typeof ScheduleItemOwnerExample;

export type ScheduleEntityApiType =
    | ScheduleEntityBuilderType
    | ScheduleEntitySubType
    | ScheduleEntityOwnerType;

export type ScheduleFormActions =
    | undefined
    | "save"
    | "saveAndNew"
    | "saveAndClose"
    | "saveAndToDo"
    | "newToDo"
    | "delete"
    | "copy"
    | "manuallyNotify"
    | "manualConfirm"
    | "manualDecline"
    | "subConfirm"
    | "subDecline"
    | "checkAssigneeJobAccess"
    | "giveAssigneeJobAccess"
    | "reloadAssignedTo"
    | "openRelatedItem";

export enum ScheduleConfirmationImages {
    BuilderApproved = "/images/Common/status/BuilderApprove.png",
    SubApproved = "/images/Common/status/SubApprove.png",
    Declined = "/images/Common/status/declinedItem.png",
}

export enum ScheduleConfirmationTypes {
    NotConfirmed = 0,
    BuilderConfirmed = 2,
    BuilderDeclined = 3,
    SubConfirmedViaEmail = 10,
    SubConfirmedViaApplication = 11,
    SubConfirmedViaLink = 12,
    SubConfirmedViaText = 13,
    SubDeclinedViaLink = 14,
    SubDeclinedViaApplication = 15,
    NoConfirmationRequested = 999,
}

export const confirmedStatuses = [
    ScheduleConfirmationTypes.BuilderConfirmed,
    ScheduleConfirmationTypes.SubConfirmedViaApplication,
    ScheduleConfirmationTypes.SubConfirmedViaEmail,
    ScheduleConfirmationTypes.SubConfirmedViaLink,
    ScheduleConfirmationTypes.SubConfirmedViaText,
];

export const declinedStatuses = [
    ScheduleConfirmationTypes.BuilderDeclined,
    ScheduleConfirmationTypes.SubDeclinedViaApplication,
    ScheduleConfirmationTypes.SubDeclinedViaLink,
];

export const maxYearsInTheFuture = 30;

export class ScheduleUsersToNotify {
    constructor(
        addedUsers: BTSelectItem<IAssignedToData>[] = [],
        removedUsers: BTSelectItem<IAssignedToData>[] = [],
        currentUsers: BTSelectItem<IAssignedToData>[] = []
    ) {
        this.addedUsers = addedUsers;
        this.removedUsers = removedUsers;
        this.currentUsers = currentUsers;
    }
    addedUsers: BTSelectItem<IAssignedToData>[];
    removedUsers: BTSelectItem<IAssignedToData>[];
    currentUsers: BTSelectItem<IAssignedToData>[];
}

export interface IAssignedToData {
    hasJobAccess?: boolean;
    archived?: boolean;
    loginType: BTLoginTypes;
    canNotify: boolean;
    isCurrentUser?: boolean;
}

export interface IScheduleFormValues {
    title: string;
    isComplete: boolean;
    percentComplete: number;
    color: string;

    startDate?: moment.Moment | null;
    startTime?: moment.Moment | null;
    endDate?: moment.Moment | null;
    endTime?: moment.Moment | null;
    duration: number;
    isHourly: boolean;

    assignedTo: number[];
    notifyAddedUsers: boolean;
    addedUsersToNotify: number[];
    notifyRemovedUsers: boolean;
    removedUsersToNotify: number[];
    notifyCurrentUsers: boolean;
    currentUsersToNotify: number[];
    requireConfirmation: boolean;
    notifyAffectedUsers: boolean;
    notifyAffectedUsersDaysOut: number;
    shiftReason: number;
    shiftComments: string;
    notificationDetails: string;
    scheduleNotificationConfirmationSubmitCount: number;
    reminder: number;

    predecessors: IPredecessorFormValue[];
    workdayExceptions: IWorkdayExceptionRecurrences;

    allNotes?: string;
    internalNotes?: string;
    subNotes?: string;
    ownerNotes?: string;

    phaseId: number;
    tagIds: number[];

    showOnGantt: boolean;
    showOwner?: boolean;
    subPermissions: number[];

    checkedLinkIds: number[];
    shiftFilter: number;
    shiftId: number;
    shiftReasonId: number;
    comments: string;
    shouldCopyToIndirectShifts: boolean;

    attachedFiles: AttachedFilesRequest;
    attachedFilesConfiguration: AttachedFiles;

    // todo: handle generic related items
    relatedBidPackageId?: number;
    relatedChangeOrderId?: number;
}

export type IScheduleCreationData = Partial<IScheduleFormValues>;

export interface IUpdateReasonModal {
    shiftId: number;
    shiftReasonId: number;
    comments: string;
    isVisible: boolean;
}

export class ScheduleEntity implements IHasAttachedFiles, IHasDiscussionContainer {
    constructor(data: any) {
        // todo: the goal here would be to fully update this to be strongly typed from the expected API call json, and eventually remove the any type
        let builderSubData = data as ScheduleEntityBuilderType | ScheduleEntitySubType;

        if (builderSubData) {
            this.assignedToOptions =
                builderSubData.assignedTo?.value
                    ?.filter((g) => g.options.length > 0)
                    ?.map(
                        (a) => new BTSelectItem<IAssignedToData>({ ...a, id: `group_${a.type}` })
                    ) || [];
        }

        this.id = data.id;
        this.builderId = data.builderId;
        this.instanceKey = uuidv4();
        this.builderName = data.builderName;
        if (data.jobInfo) {
            this.jobId = data.jobInfo.id;
            this.jobName = data.jobInfo.name;
        } else {
            this.jobId = data.jobId;
            this.jobName = data.jobName.value;
        }
        this.isTemplate = data.isTemplate;

        if (data.createdBy) {
            this.createdDate = moment(data.createdDate);
            this.createdBy = data.createdBy.value;
            this.createdName = data.createdName;
            this.createdById = data.createdById;
        } else {
            this.createdBy = "";
        }
        this.isScheduleOnline = !data.isOffline;
        this.workdayExceptions = getWorkdayExceptionRecurrences(
            data.workDayExceptions,
            data.workDays[this.jobId]
        );

        this.permissions = new SchedulePermissions(data);

        this.title = data.title.value;
        if (data.completed) {
            this.isComplete = data.completed.value;
        } else {
            this.isComplete = false;
        }
        this.percentComplete = data.percentComplete;

        this.canMarkComplete = data.canMarkComplete;

        if (data.completedByName) {
            this.completedByName = data.completedByName;
        }
        if (data.completedByDate) {
            this.completedByDate = moment(data.completedByDate);
        }
        if (data.color) {
            const selectedColor =
                data.color.value.find((color: any) => color.selected) ?? data.color.value[0];
            if (selectedColor.extraData?.hex) {
                this.color = selectedColor.extraData.hex;
            }
        }

        if (data.startDate.value) {
            this.startDate = moment(data.startDate.value);
        }

        if (data.endDate.value) {
            this.endDate = moment(data.endDate.value);
        }

        if (data.startTime.value) {
            this.startTime = moment(data.startTime.value);
        }

        if (data.endTime.value) {
            this.endTime = moment(data.endTime.value);
        }

        this.duration = data.duration.value;
        this.isHourly = data.isHourly.value;

        if (data.reminder) {
            this.reminderOptions = data.reminder.value.map((r: any) => new BTSelectItem(r));
        } else {
            this.reminderOptions = [];
        }

        this.isUserReminded = data.isUserReminded || false;

        if (data.isUserRemindedDate) {
            this.isUserRemindedDate = moment(data.isUserRemindedDate);
        }

        if (data.allNotes) {
            this.allNotes = data.allNotes.value;
        }

        if (data.internalNotes) {
            this.internalNotes = data.internalNotes.value;
        }

        if (data.subNotes) {
            this.subNotes = data.subNotes.value;
        }

        if (data.ownerNotes) {
            this.ownerNotes = data.ownerNotes.value;
        }

        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        this.documentInstanceType = DocumentInstanceType.ScheduleItems;
        this.name = "Schedule Item Details";

        if (data.discussions) {
            this.discussions = new DiscussionsEntity(data.discussions);
        }

        if (data.predecessors) {
            this.predecessors = data.predecessors.map((item: any) => new Predecessor(item));
        } else {
            this.predecessors = [];
        }

        if (data.predecessorList) {
            this.predecessorList = data.predecessorList.map((item: any) => new BTSelectItem(item));
            this.predecessorTypeList = PredecessorTypesListData.map(
                (item: any) => new BTSelectItem(item)
            );
        } else {
            this.predecessorList = [];
            this.predecessorTypeList = [];
        }

        if (data.linkedItems) {
            this.linkedItems = data.linkedItems.map((item: any) => new ScheduleLinkedItem(item));
        } else {
            this.linkedItems = [];
        }

        if (data.shiftList) {
            this.shiftList = data.shiftList.map((item: any) => new Shift(item));
        } else {
            this.shiftList = [];
        }

        this.showConfirmDecline = data.showConfirmDecline;
        if (data.confirmStatus) {
            this.confirmStatus = new ConfirmStatus(data.confirmStatus);
        }

        if (data.confirmations) {
            this.confirmations = data.confirmations.value.map(
                (item: any) => new Confirmation(item)
            );
        } else {
            this.confirmations = [];
        }

        this.hasPhasePermissions = data.hasPhasePermissions;
        if (data.phase) {
            this.phases = data.phase.value.map((phase: any) => new BTSelectItem(phase));
        } else {
            this.phases = [];
        }

        if (data.tags) {
            this.tags = alphabetize(data.tags.value.map((tag: any) => new BTSelectItem(tag)));
        } else {
            this.tags = [];
        }

        if (data.showOnGantt) {
            this.showOnGantt = data.showOnGantt.value;
        } else {
            this.showOnGantt = false;
        }

        // todo : maybe remove this prop? it seems like front end validation might prevent this from populating on the back end?
        if (data.scheduleFallingUnderMovedWDE) {
            this.scheduleFallingUnderMovedWDE = data.scheduleFallingUnderMovedWDE.value;
        } else {
            this.scheduleFallingUnderMovedWDE = false;
        }

        if (data.showOwner) {
            this.showOwner = data.showOwner.value;
            this.showOwnerTimeframe = data.showOwnerTimeframe;
        }

        if (data.subPermissions) {
            this.subPermissions = data.subPermissions.value.map(
                (sub: any) => new BTSelectItem(sub)
            );
        } else {
            this.subPermissions = [];
        }

        if (data.subPermissionsHelpLink) {
            this.subPermissionsHelpLink = data.subPermissionsHelpLink;
        } else {
            this.subPermissionsHelpLink = "";
        }

        if (data.baselineDetails) {
            this.baselineDetails = new BaselineDetails(data.baselineDetails);
        }

        if (data.notify) {
            this.notifyOptions = new NotifyOptions(data.notify);
        }

        if (data.conflicts) {
            this.conflicts = data.conflicts.map((item: any) => new UserWithConflicts(item));
        } else {
            this.conflicts = [];
        }

        if (data.userViewingPermissions) {
            this.userViewingPermissions = new UserViewingPermissions(data.userViewingPermissions);
        }

        this.canEditJobsites = data.canEditJobsites;
        this.relatedToDos = data.relatedToDos ? new RelatedToDos(data.relatedToDos) : undefined;
        this.relatedTodosEnabled = data.relatedTodosEnabled;
    }

    id: number;
    builderId: number;
    instanceKey: string;
    builderName: string;
    jobId: number;
    jobName: string;
    isTemplate: boolean;

    createdDate: moment.Moment;
    createdBy: string;
    createdName: string;
    createdById: number;

    isScheduleOnline: boolean;
    workdayExceptions: IWorkdayExceptionRecurrences;
    permissions: SchedulePermissions;
    canEditJobsites: boolean;

    title: string;
    isComplete: boolean;
    percentComplete: number;
    completedByName?: string;
    completedByDate?: moment.Moment;
    canMarkComplete: boolean;
    color: string;

    startDate?: moment.Moment;
    startTime?: moment.Moment;
    endDate?: moment.Moment;
    endTime?: moment.Moment;
    duration: number;
    isHourly: boolean;

    assignedToOptions: BTSelectItem<IAssignedToData>[];
    reminderOptions: BTSelectItem[];
    isUserReminded: boolean;
    isUserRemindedDate?: moment.Moment;

    predecessors: Predecessor[];
    predecessorList: BTSelectItem[];
    predecessorTypeList: BTSelectItem[];

    linkedItems: ScheduleLinkedItem[];
    shiftList: Shift[];

    allNotes?: string;
    internalNotes?: string;
    subNotes?: string;
    ownerNotes?: string;

    attachedFiles: AttachedFiles;
    documentInstanceType: DocumentInstanceType;
    name: string;

    discussions: DiscussionsEntity;
    showConfirmDecline: boolean;
    confirmStatus?: ConfirmStatus;
    confirmations: Confirmation[];

    hasPhasePermissions: boolean;
    phases: BTSelectItem[];
    tags: BTSelectItem[];

    showOnGantt: boolean;
    showOwner?: boolean;
    showOwnerTimeframe?: string;
    subPermissions: BTSelectItem[];
    subPermissionsHelpLink: string;
    scheduleFallingUnderMovedWDE?: boolean;

    baselineDetails?: BaselineDetails;
    notifyOptions?: NotifyOptions;

    conflicts: UserWithConflicts[];
    userViewingPermissions: UserViewingPermissions;
    relatedToDos?: RelatedToDos;
    relatedTodosEnabled: boolean;
}

export class Confirmation {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.confirmed = data.confirmed;
        this.confirmText = data.confirmText;
        this.confirmDate = data.confirmDate !== null ? moment(data.confirmDate) : null;
        this.confirmationComment = data.confirmationComment;
        this.confirmStatus = data.confirmStatus;
        this.confirmStatusImage = data.confirmStatusImage;
        this.canConfirm = data.canConfirm;
        this.canDecline = data.canDecline;
        this.confirmedUserType = data.confirmedUserType;
    }

    id: number;
    name: string;
    confirmed: boolean;
    confirmText: string;
    confirmDate: moment.Moment | null;
    confirmationComment: string;
    confirmStatus: ScheduleConfirmationTypes;
    confirmStatusImage: string;
    canConfirm: boolean;
    canDecline: boolean;
    confirmedUserType: number;
}

export class SchedulePermissions {
    constructor(data?: ScheduleEntityApiType) {
        if (!data) {
            return;
        }
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
    }

    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

export interface IPredecessorFormValue {
    scheduleItem: number | undefined;
    linkedType: number;
    lag: number;
}

export class Predecessor {
    constructor(data: any) {
        this.scheduleItem = Number(data.scheduleItem.value[0].id);
        this.linkedType = Number(data.linkedType.value[0].selected ? 1 : 2);
        this.lag = data.lag.value;
        this.dateTimeRange = data.dateTimeRange;
    }

    scheduleItem: number | undefined;
    linkedType: number;
    lag: number;
    dateTimeRange: string;
}

export class ScheduleLinkedItem {
    constructor(data: any) {
        this.id = Number(data.id);
        this.jobId = data.jobId;
        this.title = data.title;
        this.type = data.type;
        this.typeEnum = data.typeEnum;
        this.lagDays = data.lagDays;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.canBreakLink = data.canBreakLink;
        this.canOpenItem = data.canOpenItem;
        this.canMarkComplete = (data.extraData && data.extraData.canMarkComplete) || false;

        // Clean this up with the POs conversion
        if (data.typeEnum === LinkedScheduleType.PurchaseOrderPayments && data.action) {
            this.relatedId = data.action.o.poId;
        }

        // Clean this up with the signature request conversion
        if (data.typeEnum === LinkedScheduleType.SignatureRequests && data.action) {
            this.relatedId = data.action.o.docInstanceId;
        }
    }

    id: number;
    jobId: number;
    relatedId?: number;
    title: string;
    type: string;
    typeEnum: LinkedScheduleType;
    lagDays: string;
    startDate: string;
    endDate: string;
    canBreakLink: boolean;
    canOpenItem: boolean;
    canMarkComplete: boolean;
}

export class ConfirmStatus {
    constructor(data: any) {
        this.confirmStatus = data.confirmStatus;
        this.confirmStatusText = data.confirmStatusText;
        this.confirmationComment = data.confirmationComment;
    }

    confirmStatus: ScheduleConfirmationTypes;
    confirmStatusText: string;
    confirmationComment: string;
}

export class BaselineDetails {
    constructor(data: any) {
        this.baselineSet = data.baselineSet;
        this.baselineDate = moment(data.baselineDate);
        this.baselineStartDate = moment(data.baselineStartDate);
        this.baselineEndDate = moment(data.baselineEndDate);
        this.baselineDuration = Number(data.baselineDuration);
        this.currentStartDate = moment(data.currentStartDate);
        this.currentEndDate = moment(data.currentEndDate);
        this.currentDuration = Number(data.currentDuration);
        this.totalVariance = Number(data.totalVariance);
    }

    baselineSet: boolean;
    baselineDate: moment.Moment;
    baselineStartDate: moment.Moment;
    baselineEndDate: moment.Moment;
    baselineDuration: number;
    currentStartDate: moment.Moment;
    currentEndDate: moment.Moment;
    currentDuration: number;
    totalVariance: number;
}

export class NotifyOptions {
    constructor(data: any) {
        this.canAddShiftReasons = data.canAddShiftReasons;
        this.notifyDays = data.notifyDays.value;
        this.notifyLinked = data.notifyLinked.value;
        this.recordShift = data.recordShift.value;
        this.shiftComments = data.shiftComments.value;
        this.shiftReasons = data.shiftReason.value.map((r: any) => new BTSelectItem(r));
        this.defaultShiftReasons = data.defaultShiftReasons || [];
    }
    canAddShiftReasons: boolean;
    notifyDays: number;
    notifyLinked: boolean;
    recordShift: boolean;
    shiftComments: string;
    shiftReasons: BTSelectItem[];
    defaultShiftReasons: number[];
}

export interface IScheduleDeleteRequest {
    id: number;
    removedUsersToNotify: number[];
    notifyAffectedUsersDaysOut: number;
}

export class ScheduleUpdateResponse {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.usersNotOnJob = new UsersNotOnJobResponse(data.usersNotOnJob);
    }
    id: number;
    jobId: number;
    usersNotOnJob: UsersNotOnJobResponse;
}

class UsersNotOnJobResponse {
    constructor(data: any) {
        if (data) {
            this.logins = data.logins.map((item: any) => new BTSelectItem(item));
            this.subs = data.subs.map((item: any) => new BTSelectItem(item));
        } else {
            this.logins = [];
            this.subs = [];
        }
    }
    logins: BTSelectItem[];
    subs: BTSelectItem[];
}

export class ScheduleSubConfirmResponse {}

export enum ScheduleItemDateType {
    StartDate = 1,
    Duration = 2,
    EndDate = 3,
    DragItemToNewStartDate = 4,
    Predecessor = 5,
    Lag = 6,
    PredecessorType = 7,
    None = -999,
}

interface IRecalculateErrorsPathNode {
    title: string;
    scheduleId: number;
}

interface IRecalculateErrors {
    message: string;
    invalidPath: IRecalculateErrorsPathNode[];
}

export class RecalculateScheduleRequest {
    jobId: number;
    scheduleId: number;
    predecessors: RecalculatePredecessor[];
    startDate: moment.Moment;
    endDate: moment.Moment;
    duration: number;
    itemChangeType: ScheduleItemDateType;
    hasPredecessors?: boolean = true;
}

export class RecalculateScheduleResponse extends RecalculateScheduleRequest {
    constructor(data?: any) {
        super();
        if (!data) {
            return;
        }
        this.jobId = data.jobId;
        this.scheduleId = data.scheduleId;
        this.predecessors = data.predecessors.map((p: any) => new RecalculatePredecessor(p));
        this.startDate = moment(data.startDate);
        this.endDate = moment(data.endDate);
        this.duration = data.duration;
        this.itemChangeType = data.itemChangeType;
        this.applyChanges = data.applyChanges;
        if (data.errors) {
            this.errors = data.errors;
        }
    }
    errors?: IRecalculateErrors;
    applyChanges: boolean;
}

export class RecalculatePredecessor {
    constructor(data: any) {
        this.predecessorId = data.predecessorId;
        this.type = data.type;
        this.lag = data.lag;
        this.isChanged = data.isChanged;
    }

    predecessorId: number;
    type: number;
    lag: number;
    isChanged: boolean;
}

export interface IBreakLinkRequest {
    scheduleId: number;
    PredecessorId: number;
}

export class LinkedItemsResponse {
    constructor(data: any) {
        this.linkedItems = (data.linkedItems ? data.linkedItems : data).map(
            (item: any) => new ScheduleLinkedItem(item)
        );
    }
    linkedItems: ScheduleLinkedItem[];
}

export class SendReminderResponse {
    constructor(data: any) {
        this.notifications = data.notificationList.map((n: any) => new ReminderNotification(n));
    }
    notifications: ReminderNotification[];
}

export class ReminderNotification {
    constructor(data: any) {
        this.message = data.message;
        if (data.list) {
            this.list = data.list;
        }
    }
    list?: string[];
    message: string;
}

export const PredecessorTypesListData = [
    {
        id: 1,
        name: "Finish-to-Start (FS)",
        secondaryName: null,
        selected: true,
        extraData: null,
    },
    {
        id: 2,
        name: "Start-to-Start (SS)",
        secondaryName: null,
        selected: false,
        extraData: null,
    },
];

export enum ShiftFilter {
    All = 0,
    Direct = 1,
    Indirect = 2,
}

export class Shift {
    constructor(data: any) {
        this.shiftId = data.shiftId;
        this.user = data.user;
        this.comments = data.comments;
        this.reason = data.reason;
        this.reasonId = data.reasonId;
        this.sourceTitle = data.sourceTitle;
        this.workDaySlippage = data.workDaySlippage;
        this.shiftStatus = data.shiftStatus;
        this.shiftType = data.shiftType;
        this.prevStartDateLocal = moment(data.prevStartDateLocal);
        this.newStartDateLocal = moment(data.newStartDateLocal);
        this.prevEndDateLocal = moment(data.prevEndDateLocal);
        this.newEndDateLocal = moment(data.newEndDateLocal);
        this.isDirect = data.isDirect;
    }

    shiftId: number;
    user: string;
    comments: string;
    reason: string;
    reasonId: number;
    sourceTitle: string;
    workDaySlippage: string;
    shiftStatus: string;
    shiftType: string;
    prevStartDateLocal: moment.Moment;
    newStartDateLocal: moment.Moment;
    prevEndDateLocal: moment.Moment;
    newEndDateLocal: moment.Moment;
    isDirect: boolean;
}

export class ScheduleEntityDefaultParams {
    constructor(data: any) {
        this.title = data.title;
        this.displayColor = data.displayColor;
        this.assignedUserIds = data.assignedUserIds;
        this.startDate = data.startDate ? moment(data.startDate) : null;
        this.endDate = data.endDate ? moment(data.endDate) : null;
        this.isAllDay = data.isAllDay;
    }

    title: string;
    displayColor: string;
    assignedUserIds: string;
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    isAllDay: boolean;
}

export class UpdateShiftReasonResponse {
    constructor(data: any) {
        this.updatedShifts = data.updatedShifts.map((item: any) => new UpdatedShiftInfo(item));
    }

    updatedShifts: UpdatedShiftInfo[];
}

class UpdatedShiftInfo {
    constructor(data: any) {
        this.shiftId = data.shiftId;
        this.comments = data.comments;
        this.reason = data.reason;
    }

    shiftId: number;
    comments: string;
    reason: number;
}

export class PendingNotificationsResponse {
    constructor(data: any) {
        this.users = [];
        this.schedules = [];
        if (data && data.schedules) {
            const hasAllUsers = data.schedules.some((s: any) => s.user === "All Assigned Users");
            data.schedules.forEach((s: any) => {
                if (!hasAllUsers) {
                    s.assigneeIds.forEach((a: any) => {
                        if (this.users.findIndex((u) => u.id === a.id) === -1) {
                            this.users.push(
                                new BTSelectItem({ id: a, name: s.user, selected: false })
                            );
                        }
                    });
                }

                this.schedules.push(
                    new BTSelectItem({
                        id: s.id,
                        name: s.title,
                        selected: false,
                    })
                );
            });

            if (hasAllUsers) {
                this.users.push(
                    new BTSelectItem({
                        id: "0",
                        name: "All Assigned Users",
                        selected: false,
                    })
                );
            }
        }
    }
    users: BTSelectItem[];
    schedules: BTSelectItem[];
}

export class ManuallyConfirmDeclineResponse {
    constructor(data: any) {
        if (data.confirmations) {
            this.confirmations = data.confirmations.value.map(
                (item: any) => new Confirmation(item)
            );
        } else {
            this.confirmations = [];
        }
    }
    confirmations: Confirmation[];
}

export class UsersWithoutJobAccessResponse {
    constructor(data: any) {
        this.internalUsers = data.internalUsers.map((item: any) => new BTSelectItem(item));
        this.subs = data.subs.map((item: any) => new BTSelectItem(item));
        this.canEditJobs = data.canEditJobs;
    }
    internalUsers: BTSelectItem[];
    subs: BTSelectItem[];
    canEditJobs: boolean;
}

export class IgnoreConflictsResponse {}

export enum SchedulePostSaveSteps {
    Conflicts = 1,
    AddUsersToJob = 2,
    Permissions = 3,
    Notifications = 4,
    Hidden = 5,

    // Used on the list pages
    OnlineAlert = 6,
}

export enum ScheduleTabNames {
    PredecessorsAndLinks = 1,
    PhasesAndTags = 2,
    Viewing = 3,
    Notes = 4,
    Shifts = 5,
    Files = 6,
    Conflicts = 7,
    Messaging = 8,
    Confirmations = 9,
}

export class UserViewingPermissions {
    constructor(data: any) {
        this.canViewUsers = data.canViewUsers;
        this.canViewSubs = data.canViewSubs;
        this.canViewOwner = data.canViewOwner;
        this.canAddUsers = data.canAddUsers;
        this.canAddSubs = data.canAddSubs;
    }
    canViewUsers: boolean;
    canViewSubs: boolean;
    canViewOwner: boolean;
    canAddUsers: boolean;
    canAddSubs: boolean;
}
