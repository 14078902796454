import { IntegrationEnums } from "entity/marketplace/MarketplaceDirectory/MarketplaceDirectory.api.types";

export class ReconsentDetailsResponse {
    constructor(data: { integrationType: IntegrationEnums; tokenExpirationDate: Date }) {
        this.integrationType = data.integrationType;
        this.tokenExpirationDate = data.tokenExpirationDate;
    }
    integrationType: IntegrationEnums;
    tokenExpirationDate: Date;
}
