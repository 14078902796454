import { type MediaType, MobileColors, type PerformingUserType } from "types/enum";

import { BTSelectGroupHeaderId } from "commonComponents/btWrappers/BTSelect/BTSelect";

import { StringBTServiceListItem } from "handlers";

/**
 * This file stores common api requests/responses shared between multiple API endpoints
 */

/** stores the standard properties shared between entities */
export interface IBaseEntity {
    id: number;
    /** undefined when a lead */
    jobId: number | undefined;
    builderId: number;
}

export interface IBaseEntityPermissions extends IBaseEntity {
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

// derived util functions for entity permissions
export function isNew(entity: IBaseEntity) {
    return entity.id === 0;
}
export function showSave(entity: IBaseEntityPermissions) {
    return (entity.canEdit && !isNew(entity)) || (entity.canAdd && isNew(entity));
}
export function showSaveAndNew(entity: IBaseEntityPermissions) {
    return entity.canAdd && (entity.canEdit || isNew(entity));
}
export function showDelete(entity: IBaseEntityPermissions) {
    return entity.canDelete && !isNew(entity);
}

export interface IIsSelectable {
    isSelected: boolean;
}

/* Do not use this as the responseType in your apiHandler calls. If you only need success and message, use EmptyResponseEntity */
export class BaseResponse {
    constructor(apiResponse: any) {
        this.success = apiResponse.success;
        this.message = apiResponse.message;
        this.needsToRelogin = apiResponse.needsToRelogin;
        this.data = apiResponse.data;
        this.forcedUpgrade = apiResponse.forcedUpgrade;
    }

    success: boolean;
    message: string;
    needsToRelogin: boolean;
    data: any;
    forcedUpgrade: any;
}

export class ExceptionResponse extends BaseResponse {
    ExceptionMessage?: string;
    ExceptionType?: string;
    StackTrace?: string;
    Message?: string;
}

/** @todo replace with actual hex values */
export function getHexValue(mobileColor: MobileColors) {
    switch (mobileColor) {
        case MobileColors.None:
            return "";
        case MobileColors.Red:
            return "red";
        case MobileColors.Green:
            return "green";
        case MobileColors.Black:
            return "black";
        case MobileColors.Yellow:
            return "yellow";
        case MobileColors.Gray:
            return "gray";
        case MobileColors.Orange:
            return "orange";
        default:
            throw `Not a supported color type ${mobileColor}`;
    }
}

export class CultureInfo {
    constructor(data: any) {
        this.currencyIdentifier = data.currencyIdentifier;
        this.currencySeparator = data.currencySeparator;
        this.currencyThousandsSeparator = data.currencyThousandsSeparator;
    }

    currencyIdentifier: string;
    currencySeparator: string;
    currencyThousandsSeparator: string;

    /** @todo put time stuff in this call too! */
    timestuff: any;
}

export interface IDropDownItem {
    id: number;
    name: string;
    extraData?: { [key: string]: any };
}

export interface IValidator {
    errorMessage: string;
    value: any;
    type: "maxLength" | "money" | "required" | "readonly";
}

export function mapBTServiceDropdownToSelectItem<ExtraDataType>(
    data: any
): BTSelectItem<ExtraDataType>[] {
    const values: number[] = typeof data.value === "number" ? [data.value] : data.value; // array of selected ids
    const opts = data.options; // opt groups
    const mapperFunction = (opt: any) =>
        new BTSelectItem<ExtraDataType>(opt, (child: BTSelectItem<any>) => {
            child.selected = values.includes(child.value as number);
        });

    if (opts && opts.length > 0) {
        if (opts.length > 1 || opts[0].name) {
            return opts.map(mapperFunction);
        } else if (opts.length > 0 && opts[0].options) {
            // if there is only one option group do not display it
            return opts[0].options.map(mapperFunction);
        }
    }

    return [new BTSelectItem(data)];
}

declare type SelectMapCallback = (selectObject: BTSelectItem<any>) => void;

/** The expected format for ant design TreeSelect */
export class BTSelectItem<ExtraDataType = undefined> {
    /**
     * @param data
     * @param customMapper This optional callback function will be called when creating each select - use to set custom values
     * @example new BTSelectItem({ name: "DropDown Name", id: 123 })
     * @example new BTSelectItem({ name: "DropDown Name", id: 123, value: { look: "its an object", very: "cool" } })
     * @example mapBTServiceDropdownToSelectItem<MyExtraDataType>(data.myKey)
     *          --- use when api response object is coming from BTServiceDropdown storing value at the top level
     * @example
     * type MyExtraDataType = { expiredCert: boolean };
     * new BTSelectItem<MyExtraDataType>({ name: "DropDown Name", id: 123, value: { look: "its an object", very: "cool" } })
     */
    constructor(data: any, customMapper?: SelectMapCallback, type?: number) {
        // todo make data an interface or type

        if (data.id === undefined) {
            data.id = BTSelectItem.randomUniqueId();
        }

        this.title = data.title || data.name;
        this.value = data.value || data.id;
        this.id = data.id;
        this.type = type || data.type;
        this.key = data.key || data.id;
        this.disabled = data.extraData?.disabled ?? data.Disabled ?? data.disabled ?? false;

        const childrenKey = data.options ?? data.children;
        this.children = childrenKey?.map(
            (c: any) => new BTSelectItem<ExtraDataType>(c, customMapper, data.type)
        );

        this.icon = data.icon;
        this.className = data.className;
        this.selected = data.selected ?? false;
        this.extraData = data.extraData;

        customMapper?.(this);
    }

    static randomUniqueId(): string {
        // currently the api doesn't return id's for select groups.
        // The following adds a unique id to the missing item's
        return `${BTSelectGroupHeaderId}-${Math.floor(Math.random() * 100000000000 + 1)}`;
    }

    title: string;
    value: any;
    id: string;
    type?: number;
    key: string;
    children?: BTSelectItem<ExtraDataType>[];
    disabled: boolean;

    icon?: React.ReactNode;

    /** WARNING - only works on single-selects */
    className?: string;

    selected: boolean;
    extraData?: ExtraDataType;
}

export function mapStringBTServiceListItemsToBTSelectItems<ExtraDataType = undefined>(
    items: StringBTServiceListItem[] | null | undefined
): BTSelectItem<ExtraDataType>[] {
    if (!items) {
        return [];
    }

    return items.map(
        (item) =>
            new BTSelectItem<ExtraDataType>({
                title: item.name ?? "",
                value: item.id,
                id: item.id,
                key: item.key,
                disabled: item.disabled ?? false,
                extraData: {
                    disabled: item.disabled,
                    ...(item.extraData as unknown as ExtraDataType),
                },
                selected: item.selected ?? false,
                className: "",
            })
    );
}

export class FolderSelectItem extends BTSelectItem {
    constructor(data: any) {
        const subFolders = data.subFolders
            ? data.subFolders.map((f: any) => new FolderSelectItem(f))
            : [];
        super({
            id: data.id,
            name: data.title,
            children: subFolders,
        });
        this.folderId = data.id;
        this.title = data.title;
        this.mediaType = data.mediaType;
        this.subFolders = subFolders;
    }
    folderId: number;
    title: string;
    subFolders: FolderSelectItem[];
    mediaType: MediaType;
}

export class BTRadioOption {
    constructor(data: any) {
        this.id = data.id;
        this.label = data.label || data.name;
        this.secondaryName = data.secondaryName;
        this.isDisabled = data.isDisabled || false;
    }
    id: number;
    label: string;
    secondaryName: string;
    isDisabled: boolean;
}

export interface IWithServiceValidationErrors {
    formMessage: string;
    failedFields: ServiceValidation[];
}

export class ServiceValidation {
    key: string;
    message: string[];
}

export enum BTServiceDropdownTypes {
    SINGLESELECT = 0,
    MULTISELECT = 1,
}

export class BTServiceDropdownGroup<T = unknown> {
    constructor(data: any) {
        this.options = data.options
            ? data.options.map((x: any) => new BTSelectItem<T>(x))
            : undefined;
    }
    name?: string;
    options: BTSelectItem<T>[];
    type?: number;
}

export class BTServiceDropdown<T = unknown> {
    constructor(data: any) {
        this.options = data.options
            ? data.options.map((x: any) => new BTServiceDropdownGroup<T>(x))
            : undefined;
    }

    options?: BTServiceDropdownGroup<T>[];
    type: BTServiceDropdownTypes;
    value?: number[];
    title?: string;
    validators?: IValidator[];
    message?: string;
}

export class BTEntityAction<ExtraDataType = undefined> {
    constructor(data: any) {
        this.id = data.id;
        this.buttonName = data.buttonName;
        this.confirmMessage = data.message;
        this.signatureRequired = data.signatureRequired;
        this.extraData = data.extraData;
    }

    id: number;
    buttonName: string;
    confirmMessage: string;
    signatureRequired: boolean;
    extraData?: ExtraDataType;
}

export class BTEntityActionConfirmationExtraData {
    constructor(extraData: any) {
        this.confirmTitle = extraData.confirmTitle;
        this.okButton = extraData.okButton;
        this.confirmMessage = extraData.confirmMessage;
    }

    confirmTitle: string;
    okButton: string;
    confirmMessage: string;
}

export class JobInfo {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.builderId = data.builderId;
        this.jobStatus = data.jobStatus;
        this.ownerName = data.ownerName;
        this.portal = data.portal;
        this.userId = data.userId;
        this.canView = data.canView;
    }

    id: number;
    name: string;
    builderId: number;
    jobStatus: number;
    ownerName?: string;
    portal?: any;
    userId: number;
    canView: boolean;
}

export class RelatedRFIs {
    queryParamName: string;
    canAdd: boolean;
    canView: boolean;
    values: RFIEntity[];

    constructor(data: any) {
        this.queryParamName = data.queryParamName;
        this.canAdd = data.canAdd;
        this.canView = data.canView;
        this.values = data.values.map((value: any) => new RFIEntity(value));
    }
}

class RFIEntity {
    id: number;
    title: string;
    responseCount: string;
    previewText: string;
    formattedStatus: string;
    dateStatus: string;
    statusIcon: string;
    statusColor: string;
    statusIconText: string;
    assignedTo: string;
    statusText: string;
    jobName: string;
    question: string;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.responseCount = data.responseCount;
        this.previewText = data.previewText;
        this.formattedStatus = data.formattedStatus;
        this.dateStatus = data.dateStatus;
        this.statusIcon = data.statusIcon;
        this.statusColor = data.statusColor;
        this.statusIconText = data.statusIconText;
        this.assignedTo = data.assignedTo;
        this.statusText = data.statusText;
        this.jobName = data.jobName;
        this.question = data.question;
    }
}

export class EntityIdResponse {
    constructor(data: any) {
        this.id = data.id;
    }
    id: number;
}

export class EntityIdJobIdResponse {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
    }

    id: number;
    jobId: number;
}

export class AssignedUserExtraData {
    userType: PerformingUserType;
    simpleTitle: string;
    hasJobAccess: boolean;
    expiredCert: boolean;
    showEmailPrompt: boolean;
    isActive: boolean;
    emailAddresses: string;
    holdPaymentsMessage: string;
    canPayOnline: boolean;
}

export class AuthInfo {
    constructor(data: any) {
        this.authToken = data.authToken;
    }
    authToken: string;
}

// TODO: remove when all api requests are converted to the cancellable version
export enum APIHandlerVersion {
    none,
    cancellable,
}

export interface IAPIHandlerResult<T> {
    response: Promise<T | undefined>;
    unhandledResponse: Promise<T>;
    cancel: () => void;
}

export function isCanceledAPIRequestError(e: any) {
    return e instanceof DOMException && e.code === e.ABORT_ERR;
}

export interface IBTApiCopyOptions {
    id: number;
    header: string;
    name: string;
    formattedName: string;
    count?: number;
    nestedOptions?: IBTApiCopyOptions[];
    showFields?: string[];
    type: string; // CustomFieldDataType
    enabled: boolean;
    value?: boolean | BTSelectItem[];
}
