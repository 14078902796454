import { Component } from "react";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { routesWebforms } from "utilities/routesWebforms";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTModalHeader } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { DependentEntity } from "commonComponents/helpers/DependentEntitiesPrompt/DependentEntitiesPrompt.api.types";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import "./DependentEntitiesPrompt.less";

interface IDependentEntitiesPromptProps {
    dependencies: DependentEntity[];
    fullScreen?: boolean;

    /** Only pass this when rendering the prompt as a root page, i.e. in an iframe */
    pageTitle?: string;
}

export class DependentEntitiesPrompt extends Component<IDependentEntitiesPromptProps> {
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    render() {
        const { dependencies, fullScreen } = this.props;
        // Show loading spinner until entity is loaded
        if (!dependencies) {
            return <BTLoading />;
        }
        const height = this.props.pageTitle ? "calc(100vh - 72px)" : "100vh";
        const rowStyle = fullScreen ? { height } : undefined;
        const columnWidths = fullScreen ? { xxl: 4, xl: 6, lg: 8, sm: 12, xs: 22 } : { xs: 24 };

        return (
            <>
                {this.props.pageTitle && (
                    <BTModalHeader
                        title={this.props.pageTitle}
                        modalConfig={{
                            parentRoute: "",
                            // eslint-disable-next-line deprecate/member-expression
                            beforeClose: () => routesWebforms.closeModal(),
                        }}
                    />
                )}

                <BTRow
                    justify="center"
                    align="middle"
                    style={rowStyle}
                    className="DependentEntitiesPromptModalRow"
                >
                    <BTCol {...columnWidths}>
                        <BTForm>
                            {dependencies.map((entity) => {
                                return (
                                    <BTFormItem
                                        label={`Please Select a ${entity.name}`}
                                        key={entity.name}
                                        validateStatus="success"
                                        help=""
                                    >
                                        <BTSelect
                                            id={`entitySelect${entity.name}`}
                                            data-testid={`entitySelect${entity.name}`}
                                            treeData={entity.entityList}
                                            onChange={(field: string, value: any) =>
                                                entity.onEntitySelected(value as number)
                                            }
                                            value={
                                                entity.entityList.filter(
                                                    (entity) => entity.selected
                                                ).length > 0
                                                    ? entity.entityList.filter(
                                                          (entity) => entity.selected
                                                      )[0].value
                                                    : undefined
                                            }
                                            onBlur={() => {}}
                                        />
                                    </BTFormItem>
                                );
                            })}
                        </BTForm>
                    </BTCol>
                </BTRow>
            </>
        );
    }
}
