import { BdsHotkeyDisplay, registerHotkey } from "@buildertrend/components";

import { getLoginTypeFromCurrentPortal } from "utilities/portal/portal";
import { routesWebforms } from "utilities/routesWebforms";

import { HotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.utility";

export function HotkeyDisplaySetup() {
    const hotkeyCommand = HotkeyCommands.helpCenter.command;
    const hotkeyFriendlyName = HotkeyCommands.helpCenter.friendlyName;
    const hotkeyCallback = () => {
        window.open(
            routesWebforms.Help.GetHelpCenterLink(getLoginTypeFromCurrentPortal()),
            "_blank"
        );
    };

    registerHotkey(hotkeyCommand, hotkeyFriendlyName, hotkeyCallback, undefined, true);
    return <BdsHotkeyDisplay />;
}
