import { BTLoginTypes, LoginStatus } from "types/enum";

import type MultiFactorAuthModalGetResponse from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModalGetResponse.api.json";
import type MultiFactorAuthModalPostResponse from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModalPostResponse.api.json";

export type MultiFactorAuthModalFormActions = "submit" | "resendEmail" | "otherAction" | undefined;

export interface IMultiFactorAuthModalFormValues {
    oneTimeCode: string;
}

export interface IMFASupportInfo {
    phone: string;
    daysOfOperation: string;
    hoursOfOperation: string;
    offsetDescription: string;
    readMoreLink: string;
}

export class EmailVerificationResponse {
    constructor(data: typeof MultiFactorAuthModalPostResponse) {
        this.loginStatus = data.loginStatus;
        this.loginType = data.loginType;
    }

    loginStatus: LoginStatus;
    loginType: BTLoginTypes;
}

export class AuthEmailResponse {
    constructor(data: typeof MultiFactorAuthModalGetResponse) {
        this.firstEmail = data.firstEmail;
        this.emailCount = data.emailCount;
        this.isFirstMultifactorAuth = data.isFirstMultifactorAuth;
        this.supportInfo = data.supportInfo;
    }

    firstEmail: string;
    emailCount: number;
    isFirstMultifactorAuth: boolean;
    supportInfo: IMFASupportInfo;
}
