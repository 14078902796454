import { Component } from "react";

import { track } from "utilities/analytics/analytics";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import DismissButton from "commonComponents/entity/emptyState/common/DismissButton/DismissButton";
import {
    EmptyStateActions,
    EmptyStateDataType,
    IEmptyStateBannerOnlyEntityProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    getEmptyStateDataType,
    IEmptyStateProps,
} from "commonComponents/entity/emptyState/EmptyState";

import "./EmptyStateBanner.less";

export interface IEmptyStateBannerProps extends IEmptyStateProps {
    actionBeingPerformed?: EmptyStateActions;
    isDismissable?: boolean;
    onDismiss?: () => void;
    bordered?: boolean;
}

@track((props) => ({ component: "Empty State Banner", uniqueId: props.title }))
export class EmptyStateBanner extends Component<IEmptyStateBannerProps> {
    render() {
        const { title, children, image, onDismiss, isDismissable, bordered, className } =
            this.props;
        return (
            <BTCard className={`EmptyStateBanner ${className}`} bordered={bordered}>
                <div className="BannerContent">
                    {image && <div className="BannerImage">{image}</div>}
                    <div>
                        <BTTitle level={3}>{title}</BTTitle>
                        <div className="BannerBody">{children}</div>
                        {isDismissable && (
                            <DismissButton
                                onDismiss={onDismiss}
                                actionBeingPerformed={this.props.actionBeingPerformed}
                            />
                        )}
                    </div>
                </div>
            </BTCard>
        );
    }
}

export function getEmptyStateBannerOnlyEntityDataType(props: IEmptyStateBannerOnlyEntityProps) {
    if (props.hasListData) {
        if (props.isNewToEntity) {
            return EmptyStateDataType.InitialWithDataState;
        }
    } else {
        return getEmptyStateDataType(props);
    }

    return null;
}

interface IEmptyStateCallToActionButton {
    onCallToActionClick: () => void;
    disabled?: boolean;
}
export const EmptyStateCallToActionButton = (
    props: React.PropsWithChildren<IEmptyStateCallToActionButton>
) => (
    <BTButton
        disabled={props.disabled}
        data-testid="emptyStateBannerNew"
        className="padding-horizontal-xs"
        loadingAction="new"
        actionBeingPerformed={undefined}
        type="link"
        onClick={props.onCallToActionClick}
    >
        <strong>{props.children}</strong>
    </BTButton>
);
