import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { NotificationActionType } from "types/enum";

export interface IPreferenceToggleChange {
    prefIds: number[];
    builderIds: number[];
}

export interface IPushNotificationHandler {
    registerBrowser: (token: string) => Promise<RegisterBrowserResponse>;
    toggleSinglePreference: (
        preferences: IPreferenceToggleChange,
        shouldTurnOn: boolean
    ) => Promise<ToggleSinglePreferenceResponse>;
    getTogglePreferences: () => Promise<PushNotificationPreferences>;
    allowPushNotifications: (shouldAllow: boolean) => Promise<PushNotificationPreferences>;
    getPreferences(
        request: GetNotificationPreferencesRequest
    ): Promise<GetNotificationPreferencesResponse>;
    setPreferences(request: SetNotificationPreferencesRequest): Promise<EmptyResponseEntity>;
}

export class RegisterBrowserResponse {}

export class ToggleSinglePreferenceResponse {
    constructor(other?: IPushNotificationPreferences) {
        if (other) {
            this.options = other.options;
        }
    }

    options: IPushNotificationPreferenceCategory[];
}

export interface IPushNotificationPreferenceValue {
    id: number;
    name: string;
    isOn: boolean;
}

export interface IPushNotificationPreferenceCategory {
    name: string;
    showMassUpdates: boolean;
    values: IPushNotificationPreferenceValue[];
}

export interface IPushNotificationPreferences {
    hasPushNotificationsOn: boolean;
    options: IPushNotificationPreferenceCategory[];
}

export class PushNotificationPreferences implements IPushNotificationPreferences {
    constructor(other?: IPushNotificationPreferences) {
        if (other) {
            this.hasPushNotificationsOn = other.hasPushNotificationsOn;
            this.options = other.options;
        }
    }

    hasPushNotificationsOn: boolean;
    options: IPushNotificationPreferenceCategory[];
}

export interface IOrgLinkNotificationTypePreferences {
    userNotificationTypePreferenceId: number;
    notificationType: number;
    isEmailEnabled: boolean;
    isPushEnabled: boolean;
    isTextEnabled: boolean;
    isAllEnabled: boolean;
}

export class OrgLinkNotificationPreferences {
    userNotificationPreferenceId: number;
    builderId: number;
    globalUserId: number;
    preferences: IOrgLinkNotificationTypePreferences[];
}

export interface IClusteredIndexKey {
    entityId: number;
    builderId: number;
}

export class GetNotificationPreferencesRequest {
    users: IClusteredIndexKey[];
    notificationType: number;
}

export interface IGetNotificationPreferencesResponse {
    preferences: OrgLinkNotificationPreferences[];
}

export class GetNotificationPreferencesResponse implements IGetNotificationPreferencesResponse {
    constructor(other?: IGetNotificationPreferencesResponse) {
        if (other) {
            this.preferences = other.preferences;
        }
    }

    preferences: OrgLinkNotificationPreferences[];
}

export class SetNotificationPreferencesRequest {
    preferences: OrgLinkNotificationPreferences[];
}

export enum MessagePayloadType {
    Launch = 0,
    /** Trigger to reloads/sync some data */
    SendToSync = 1,
    /** Delete the notification from the device (probably because it was read/seen elsewhere) */
    Delete = 2,
    Chat = 4,
}

interface ICreateDesktopParams<PT extends MessagePayloadType> {
    createDesktopNotificationParams: (
        isSessionNotAvailable: boolean
    ) => IBTDesktopNotificationParams<PT>;
}

abstract class NotificationDataBase<PT extends MessagePayloadType>
    implements ICreateDesktopParams<PT>
{
    uniqueId: number;
    threadId: string;
    jobId: number;
    orgId: number;
    builderLogoUrl: string;
    message: string;
    userId: number;
    title: string;
    notificationId: number;
    payloadType: PT;
    actions: IOtherDataMap[PT];

    constructor(data: IMessagePayloadData<PT>) {
        this.uniqueId = data.n;
        this.threadId = data.threadId;
        this.jobId = data.j;
        this.orgId = data.orgId;
        this.builderLogoUrl = data.b;
        this.message = data.m;
        this.userId = data.u;
        this.title = data.t;
        this.notificationId = data.notificationId;
        this.payloadType = data.pt;
        this.actions = data.a;
    }

    createDesktopNotificationParams(isSessionAvailable: boolean): IBTDesktopNotificationParams<PT> {
        const {
            actions,
            threadId,
            orgId,
            notificationId,
            userId,
            title,
            builderLogoUrl,
            message,
            payloadType,
        } = this;

        return {
            title: isSessionAvailable ? title : "You have a new message from Buildertrend.",
            options: {
                icon: builderLogoUrl,
                body: isSessionAvailable ? message : "Log in to view details.",
                data: {
                    actions,
                    threadId,
                    orgId,
                    notificationId,
                    payloadType,
                    userId,
                },
                actions: [],
            },
        };
    }
}

type NotChat = Exclude<MessagePayloadType, MessagePayloadType.Chat>;
export type INotificationData = IBasicNotificationData | IChatNotificationData;

export interface IBasicNotificationData extends NotificationDataBase<NotChat> {
    uniqueId: number;
    threadId: string;
    jobId: number;
    orgId: number;
    builderLogoUrl: string;
    message: string;
    userId: number;
    payloadType: NotChat;
    title: string;
    notificationId: number;
    actions: IBasicActionPayload[];
}

class BasicNotificationData
    extends NotificationDataBase<NotChat>
    implements IBasicNotificationData {}

export interface IChatNotificationData extends NotificationDataBase<MessagePayloadType.Chat> {
    uniqueId: number;
    threadId: string;
    jobId: number;
    orgId: number;
    builderLogoUrl: string;
    message: string;
    userId: number;
    payloadType: MessagePayloadType.Chat;
    title: string;
    notificationId: number;
    actions: IChatActionPayload[];
    getChannelUrl(): URL | undefined;
}

class ChatNotificationData
    extends NotificationDataBase<MessagePayloadType.Chat>
    implements IChatNotificationData
{
    getChannelUrl() {
        if (Array.isArray(this.actions) && this.actions.length > 0) {
            const chatUrl = this.actions[0].o.chatUrl;
            return new URL(chatUrl);
        }
        return undefined;
    }

    createDesktopNotificationParams(
        isSessionAvailable: boolean
    ): IBTDesktopNotificationParams<MessagePayloadType.Chat> {
        const {
            actions,
            threadId,
            orgId,
            notificationId,
            userId,
            title,
            builderLogoUrl,
            message,
            payloadType,
        } = this;

        const actionsAreAvailable = Notification && "actions" in Notification.prototype;

        return {
            title: isSessionAvailable ? title : "You have a new message from Buildertrend.",
            options: {
                icon: builderLogoUrl,
                body: isSessionAvailable ? message : "Log in to view details.",
                data: {
                    actions,
                    threadId,
                    orgId,
                    notificationId,
                    payloadType,
                    userId,
                },
                // only allow replies if there is an active session
                actions:
                    isSessionAvailable && actionsAreAvailable
                        ? [
                              {
                                  action: "reply",
                                  type: "text",
                                  title: "Reply",
                              },
                              {
                                  action: "go-to-chat",
                                  type: "text",
                                  title: "Go to chat",
                              },
                          ]
                        : [],
            },
        };
    }
}

export const NotificationDataFactory = {
    create(data: Record<string, unknown>): INotificationData {
        if (!jsonHasExpectedProperties(data)) {
            throw new Error("Invalid notification payload");
        }

        if (isChatPayloadType(data)) {
            return new ChatNotificationData(data);
        } else if (isNotChatPayloadType(data)) {
            return new BasicNotificationData(data);
        } else {
            throw new Error("Invalid notification payload");
        }
    },
};

function isNotChatPayloadType(
    record: IMessagePayloadData<MessagePayloadType>
): record is IBasicMessagePayloadData {
    switch (record.pt) {
        case MessagePayloadType.Launch:
        case MessagePayloadType.SendToSync:
        case MessagePayloadType.Delete:
            return true;
        default:
            return false;
    }
}

function isChatPayloadType(
    record: IMessagePayloadData<MessagePayloadType>
): record is IChatMessagePayloadData {
    return record.pt === MessagePayloadType.Chat;
}

function jsonHasExpectedProperties(
    unverifiedRecord: Record<string, unknown>
): unverifiedRecord is IMessagePayloadData<MessagePayloadType> {
    // Check for the existence of all required fields
    if (
        typeof unverifiedRecord.n !== "number" ||
        typeof unverifiedRecord.threadId !== "string" ||
        typeof unverifiedRecord.j !== "number" ||
        typeof unverifiedRecord.orgId !== "number" ||
        typeof unverifiedRecord.b !== "string" ||
        typeof unverifiedRecord.u !== "number" ||
        typeof unverifiedRecord.t !== "string" ||
        typeof unverifiedRecord.notificationId !== "number" ||
        typeof unverifiedRecord.pt !== "number" ||
        !Array.isArray(unverifiedRecord.a)
    ) {
        throw new Error("Invalid notification payload");
    }
    return true;
}

/** associated MessagePayloadType with Actions */
type IOtherDataMap = {
    [K in MessagePayloadType]: K extends MessagePayloadType.Launch
        ? IBasicActionPayload[]
        : K extends MessagePayloadType.SendToSync
        ? IBasicActionPayload[]
        : K extends MessagePayloadType.Delete
        ? IBasicActionPayload[]
        : K extends MessagePayloadType.Chat
        ? IChatActionPayload[]
        : never;
};

export interface IMessagePayloadData<PT extends MessagePayloadType> {
    [key: string]: unknown;
    /** UniqueID */
    n: number;
    /** ThreadId */
    threadId: string;
    /** JobId */
    j: number;
    /** OrgId */
    orgId: number;
    /** BuilderLogoURL */
    b: string;
    /** Message */
    m: string;
    /** UserId */
    u: number;
    /** Title */
    t: string;
    /** NotificationID */
    notificationId: number;
    /** PayloadType */
    pt: PT;
    /** Actions */
    a: IOtherDataMap[PT];
}

/** Catchall for non-chat types. */
/** These are for types coming in from firebase from .NET. */
export type IBasicMessagePayloadData = IMessagePayloadData<NotChat>;
export type IChatMessagePayloadData = IMessagePayloadData<MessagePayloadType.Chat>;
interface IChatActionOtherData {
    senderName: string;
    chatUrl: string;
    portalType: number;
}

interface IDefaultActionOtherData {
    [key: string]: unknown;
}

export type IChatActionPayload = IActionPayload<NotificationActionType.Channel_Details>;
type IBasicActionPayload = IActionPayload<NotChatNotificationActionType>;

interface ISpecificNotificationActionTypeToActionPayloadMap {
    [NotificationActionType.Channel_Details]: IChatActionOtherData;
}

type IDefaultNotificationActionTypeToActionPayloadMap = {
    [key in Exclude<
        NotificationActionType,
        keyof ISpecificNotificationActionTypeToActionPayloadMap
    >]: IDefaultActionOtherData;
};

type INotificationActionTypeToActionPayloadMap = ISpecificNotificationActionTypeToActionPayloadMap &
    IDefaultNotificationActionTypeToActionPayloadMap;

type NotChatNotificationActionType = Exclude<
    NotificationActionType,
    NotificationActionType.Channel_Details
>;

/** This is the action type inside of the "a" key in the firebase payload from .NET */
interface IActionPayload<NAT extends NotificationActionType = NotificationActionType> {
    e: number;
    externalId: string;
    at: NAT;
    o: INotificationActionTypeToActionPayloadMap[NAT];
}

export interface IBTDesktopNotificationOptions<PT extends MessagePayloadType>
    extends Omit<NotificationOptions, "data" | "actions"> {
    data: {
        actions: IActionPayload[];
        threadId: string;
        orgId: number;
        notificationId: number;
        payloadType: PT;
        userId: number;
    };
    actions: (NotificationAction & {
        type: "button" | "text";
    })[];
}

/** These are all of the props needed to produce a desktop notification. */
export interface IBTDesktopNotificationParams<PT extends MessagePayloadType> {
    title: string;
    options: IBTDesktopNotificationOptions<PT>;
}

/** Provides better definition of the "data" payload we're returning from Firebase. */
export interface IBTNotificationEvent<PT extends MessagePayloadType> extends NotificationEvent {
    notification: Omit<Notification, "data"> & {
        data: {
            actions: IActionPayload[];
            threadId: string;
            orgId: number;
            notificationId: number;
            payloadType: PT;
            userId: number;
        };
    };
}

export interface IBTChatNotificationEvent extends IBTNotificationEvent<MessagePayloadType.Chat> {
    action: BTDesktopNotificationActions;
    notification: Omit<Notification, "data"> & {
        data: IBTDesktopNotificationOptions<MessagePayloadType.Chat>["data"] & {
            actions: IChatActionPayload[];
        };
    };
    reply: string;
    ["go-to-chat"]: string;
}

export type BTDesktopNotificationActions = "reply" | "go-to-chat" | "";
