import { Radio } from "antd";
import { InjectedFormikProps } from "formik";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTConfirm,
    BTConfirmContent,
    BTConfirmFooter,
    BTConfirmHeader,
} from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTRadioGroup } from "commonComponents/btWrappers/BTRadioGroup/BTRadioGroup";
import { ILineItemsToInvoiceFormValues } from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoice.api.types";

export interface IStackLineItemsConfirmProps {
    showConfirmModal: boolean;
    onContinue: () => Promise<void>;
    onClose: () => void;
}

export const StackLineItemsConfirm: React.FunctionComponent<
    InjectedFormikProps<IStackLineItemsConfirmProps, ILineItemsToInvoiceFormValues>
> = ({ showConfirmModal, values, onClose, onContinue, handleChange }) => {
    return (
        <BTConfirm visible={showConfirmModal} title="Create Line Item Stacks?">
            <BTConfirmHeader>
                Creating line item stacks consolidates line items that share the same cost code.
            </BTConfirmHeader>
            <BTForm>
                <BTConfirmContent>
                    <BTFormItem>
                        <BTRadioGroup
                            name="stackLineItems"
                            value={values.stackLineItems}
                            onChange={handleChange}
                        >
                            <Radio value={true} data-testid="createStacks">
                                Create Stacks
                            </Radio>
                            <Radio value={false} data-testid="keepIndividualLines">
                                Keep individual line items
                            </Radio>
                        </BTRadioGroup>
                    </BTFormItem>
                </BTConfirmContent>
            </BTForm>
            <BTConfirmFooter className="margin-bottom-md">
                <BTButton
                    id="cancel"
                    data-testid="cancel"
                    type="secondary"
                    onClick={onClose}
                    className="margin-right-xs"
                >
                    Cancel
                </BTButton>
                <BTButton
                    id="continueAddLineItemsToInvoice"
                    data-testid="continueAddLineItemsToInvoice"
                    type="primary"
                    onClick={onContinue}
                >
                    Continue
                </BTButton>
            </BTConfirmFooter>
        </BTConfirm>
    );
};
