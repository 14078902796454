import moment, { Moment } from "moment";
import { Key } from "react";
import { RouteComponentProps } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { BuilderInfo } from "helpers/AppProvider.types";

import { BTSelectItem, IBaseEntity, ServiceValidation } from "types/apiResponse/apiResponse";
import { EmailConstants } from "types/constants";
import { Countries } from "types/countries";
import {
    ActivationStatus,
    CustomFieldAssociatedType,
    JobStatusTypes,
    LeadStatus,
} from "types/enum";

import { isNullOrWhitespace } from "utilities/string/string";

import { IBTAlertProps } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { GetAddressServiceObject } from "commonComponents/entity/address/Address/Address";
import {
    AddressEntity,
    AddressFormValues,
    AddressServiceObject,
    IHasAddress,
} from "commonComponents/entity/address/Address/Address.api.types";
import { CellEmailLookupEntity } from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookup.api.types";
import {
    ICellPhoneInputFormValues,
    SmsOptInStatus,
    VerificationMessageType,
} from "commonComponents/entity/cellPhone/CellPhoneInput/CellPhoneInput.types";
import { Contact } from "commonComponents/entity/contact/ContactSearch/ContactSearch.api.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import { Email } from "commonComponents/entity/email/InputEmail/InputEmail.types";
import { IEmailEntryFormValues } from "commonComponents/entity/emailFormEntry/EmailFormEntry";

import { IContactHandler } from "entity/contact/Contact/Contact.api.handler";
import type { default as ContactUsageJson } from "entity/contact/Contact/ContactUsage.api.json";
import { IMergeContactModalHandler } from "entity/contact/MergeContactModal/MergeContactModal.api.handler";

type ContactUsageAPIData = typeof ContactUsageJson;
type DeleteConflictAPIData = ContactUsageAPIData["conflicts"][number];
type AccessConflictAPIData = ContactUsageAPIData["accessConflictCounts"][number];

export type TableType = "lead" | "job";

export interface IContactProps extends RouteComponentProps {
    /**
     * Id
     */
    id: number;
    /**
     * Is from selector flag
     */
    isFromSelector: boolean;
    /**
     * Address form values
     */
    jobAddress?: AddressFormValues;
    /**
     * Accessed from owner setup flag
     */
    accessedFromOwnerSetup?: boolean;
    /**
     * Contact handler
     */
    handler?: IContactHandler;
    /**
     * Modal configuration
     */
    modalConfig?: IModalConfiguration;
    /**
     * On save event
     */
    onSave?: (id: number) => void;
    /**
     * On delete event
     */
    onDelete?: (id: number) => void;
    /**
     * Merge contact handler
     */
    mergeContactModalHandler?: IMergeContactModalHandler;
    builderInfo?: BuilderInfo;
}

export class ContactEntity implements IBaseEntity, IHasAddress, IHasCustomFieldContainer {
    constructor(data: any) {
        this.key = uuidv4();

        this.id = data.id;
        this.jobId = undefined;
        this.builderId = data.builderId;
        this.orgLinkId = data.orgLinkId;
        this.createdBy = data.createdBy;
        this.createdById = data.createdById;
        this.createdDate = moment(data.createdDate);
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.canSendLeadEmail = data.canSendLeadEmail;
        this.canEditProfilePicture = data.canEditProfilePicture;
        this.isBuilderImpersonatingOwner = data.isBuilderImpersonatingOwner;
        this.lastUpdatedByDate = moment(data.lastUpdatedByDate);
        this.lastUpdatedById = data.lastUpdatedById;
        this.lastUpdatedByName = data.lastUpdatedByName;

        this.firstName = data.firstName && data.firstName.value;
        this.lastName = data.lastName && data.lastName.value;
        this.displayName = data.displayName && data.displayName.value;
        this.phonePrimary = data.phonePrimary.value;
        this.phoneCell = data.phoneCell.value;
        this.primaryEmail = data.primaryEmail.value.emailAddress
            ? new Email(data.primaryEmail.value)
            : null;
        this.additionalEmails = data.additionalEmails.value.map((email: any) => new Email(email));
        this.maxAdditionalEmailsCount =
            data.additionalEmails.validators.find((v: any) => v.type === "maxCount")?.value ?? 0;
        this.address = data.address && new AddressEntity(data.address);
        this.cellEmail = data.cellEmail && new CellEmail(data.cellEmail.value);
        this.leads = data.leads && data.leads.map((lead: any) => new LeadRow(lead));
        this.leadsListMessage = data.leadsListMessage;
        this.jobs = data.jobs && data.jobs.map((job: any) => new JobRow(job));
        this.jobsListMessage = data.jobsListMessage;
        this.profilePicture = data.profilePicture ? data.profilePicture.value : undefined;
        this.activationStatus = data.activationStatus;
        this.activationSent = data.activationSent ? moment(data.activationSent) : undefined;
        this.activationConfirmed = data.activationConfirmed
            ? moment(data.activationConfirmed)
            : undefined;
        this.hasPrimaryEmail = !isNullOrWhitespace(this.primaryEmail?.emailAddress);
        this.hasJobAccess = data.hasJobAccess;

        this.customFieldAssociatedType = data.customFieldAssociatedType;
        this.customFields = data.customFields;
        this.customFieldOptions = data.customFieldOptions;
        this.customFieldsCanConfigure = data.customFieldsCanConfigure;

        this.cellEmailLookupEntity =
            data.cellEmailLookupLoadData && new CellEmailLookupEntity(data.cellEmailLookupLoadData);
        this.contactSelector = data.contactSelector
            ? data.contactSelector.value.map((item: any) => new BTSelectItem(item))
            : undefined;
        this.sendInvite = data.sendInvite;
        this.smsOptInStatus = data.smsOptInStatus;
        this.countryCode = data.countryCode;
    }

    /**
     * Set to a random unique key on entity creation by default
     */
    key: Key;

    id: number;
    jobId: undefined;
    builderId: number;
    orgLinkId?: number;
    createdBy: string;
    createdById: number;
    createdDate: moment.Moment;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canSendLeadEmail: boolean;
    canEditProfilePicture?: boolean;
    isBuilderImpersonatingOwner?: boolean;
    lastUpdatedByDate: moment.Moment;
    lastUpdatedById: number | null;
    lastUpdatedByName: string;

    firstName?: string;
    lastName?: string;
    displayName: string;
    phonePrimary: string;
    phoneCell: string;
    primaryEmail: Email | null;
    additionalEmails: Email[];
    maxAdditionalEmailsCount: number;
    address: AddressEntity;
    cellEmail: CellEmail;
    leads: LeadRow[];
    leadsListMessage: string;
    jobs: JobRow[];
    jobsListMessage: string;
    profilePicture?: string;
    activationStatus: ActivationStatus;
    activationSent?: Moment;
    activationConfirmed?: Moment;
    hasPrimaryEmail: boolean;
    hasJobAccess: boolean;

    customFieldAssociatedType: CustomFieldAssociatedType;
    customFields: ICustomFieldResponse[];
    customFieldOptions: ICustomFieldOptionsList;
    customFieldsCanConfigure: boolean;

    cellEmailLookupEntity: CellEmailLookupEntity;
    contactSelector: BTSelectItem[];
    sendInvite: boolean;
    smsOptInStatus: SmsOptInStatus;
    countryCode: Countries;
}

class CellEmail {
    constructor(data: any) {
        this.number = data.number;
        this.provider = data.provider;
        this.fullCellEmail = data.fullCellEmail;
    }

    number: string;
    provider: string;
    fullCellEmail: string;
}

export class LeadRow {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.extraData = new LeadExtraData(data.extraData);
    }

    id: number;
    name: string;
    extraData: LeadExtraData;
}

class LeadExtraData {
    constructor(data: any) {
        this.status = data.status;
        this.createdByDate = moment(data.createdByDate);
        this.soldDate = data.soldDate ? moment(data.soldDate) : undefined;
        this.salesperson = data.salesperson;
    }

    status: LeadStatus;
    createdByDate: moment.Moment;
    soldDate?: moment.Moment;
    salesperson: string;
}

export class JobRow {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.extraData = new JobExtraData(data.extraData);
    }

    id: number;
    name: string;
    extraData: JobExtraData;
}

class JobExtraData {
    constructor(data: any) {
        this.address = new AddressEntity(data.address);
        this.projectManagers = data.projectManagers;
    }

    address: AddressEntity;
    projectManagers: string[];
}

export type ContactFormAction =
    | undefined
    | "save"
    | "saveAndNew"
    | "saveAndClose"
    | "sendInvite"
    | "cancelInvite"
    | "saveAndSendInvite"
    | "saveAndCancelInvite"
    | "other";

export interface IContactFormValues extends IEmailEntryFormValues, ICellPhoneInputFormValues {
    firstName?: string;
    lastName?: string;
    displayName: string;
    phonePrimary: string;
    cellEmail: string;
    address: AddressFormValues;
    customFields: ICustomFieldFormValues[];
    profilePicture?: string;
    sendInvite: boolean;
    cancelInvite: boolean;
    isDisabled?: boolean;
}

export interface IContactSaveRequest {
    firstName?: string;
    lastName?: string;
    displayName: string;
    phonePrimary: string;
    phoneCell: string;
    // todo: replace with primaryEmail/additionalEmails properties once backend supports it
    emailAddress: Email[];
    cellEmail: string;
    address: AddressServiceObject;
    customFields: ICustomFieldFormValues[];
    profilePicture?: string;
    sendInvite: boolean;
    verificationRequest: VerificationMessageType;
}

export class ContactSaveRequest implements IContactSaveRequest {
    constructor(entity: IHasAddress, values: IContactFormValues) {
        this.firstName = values.firstName;
        this.lastName = values.lastName;
        this.displayName = values.displayName;
        this.phonePrimary = values.phonePrimary;
        this.phoneCell = values.phoneCell;
        this.emailAddress = values.primaryEmail
            ? [values.primaryEmail, ...values.additionalEmails]
            : [];
        this.cellEmail = values.cellEmail;
        this.customFields = values.customFields;
        this.profilePicture = values.profilePicture;
        this.address = GetAddressServiceObject(entity, values.address);
        this.sendInvite = values.sendInvite;
        this.cancelInvite = values.cancelInvite;
        this.verificationRequest = values.verificationRequest;
        this.isDisabled = values.isDisabled;
    }

    firstName?: string;
    lastName?: string;
    displayName: string;
    phonePrimary: string;
    phoneCell: string;
    emailAddress: Email[];
    cellEmail: string;
    address: AddressServiceObject;
    customFields: ICustomFieldFormValues[];
    profilePicture?: string;
    sendInvite: boolean;
    cancelInvite: boolean;
    verificationRequest: VerificationMessageType;
    isDisabled?: boolean;
}

export class ContactInsertResponse {
    constructor(data: any) {
        this.id = data.id;
        this.failedFields = data.failedFields;
    }

    id: number;
    failedFields?: ServiceValidation[];
}

export enum ContactType {
    Owner = 0,
    LeadOpportunity = 1,
    NotUsed = 2,
}

export enum ContactAccessEntityTypes {
    None = 0,
    Jobsite = 1,
    Todo = 2,
    OwnerInvoice = 3,
}

export class UsageItem {
    constructor(data: DeleteConflictAPIData, uniqueId: number) {
        this.uniqueId = uniqueId;
        this.contactType = data.contactType;
        this.title = data.title;
        this.jobStatus = data.jobStatus;
    }

    uniqueId: number;
    contactType: ContactType;
    title: string;
    jobStatus: JobStatusTypes;
}

export class AccessItemCount {
    constructor(data: AccessConflictAPIData, uniqueId: number) {
        this.count = data.count;
        this.entityType = data.entityType;
        this.description = data.description;
        this.uniqueId = uniqueId;
    }

    uniqueId: number;
    count: number;
    entityType: ContactAccessEntityTypes;
    description: string;
}

export class ContactUsageResponse {
    constructor(data: ContactUsageAPIData) {
        this.conflicts = data.conflicts.map((item, index) => new UsageItem(item, index));
        if (data.accessConflictCounts) {
            this.accessConflictCounts = data.accessConflictCounts.map(
                (item, index) => new AccessItemCount(item, index)
            );
        }
    }

    conflicts: UsageItem[];
    accessConflictCounts: AccessItemCount[];
}

export interface IContactAlert extends Omit<IBTAlertProps, "data-testid"> {
    isVisible: boolean;
    emailValidationInvalidSupport?: string;
    duplicatedContactIds?: number[];
}

export class SaveResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
    }
    failedFields?: ServiceValidation[];
}

// Contact Detail Cards

export interface IContactDetailCardInfo {
    id: number;
    orgLinkId?: number;
    displayName: string;
    primaryEmail?: string;
    phonePrimary?: string;
    activationStatus: ActivationStatus;
    activationSent?: Moment;
    activationConfirmed?: Moment;
    canAdd: boolean;
    canDelete: boolean;
    canEdit: boolean;
    hasPrimaryEmail: boolean;
    hasJobAccess: boolean;
}

// currently contacts can populate from search and from the entity
// this could be refactored in the future but requires more api work
// for now just making sure it's explicit without blended definitions and unexpected data collision
export class ContactSearchCardInfo implements IContactDetailCardInfo {
    constructor(data: Contact, canAdd: boolean, canDelete: boolean, canEdit: boolean) {
        this.id = data.contactID;
        this.orgLinkId = data.orgLinkId;
        this.displayName = data.displayName;
        this.primaryEmail = data.Email || EmailConstants.NoEmailPlaceholder;
        this.phonePrimary = data.phonePrimary || "(---) --- - ----";
        this.activationStatus = data.activationStatusInfo.activationStatus;
        this.activationSent = data.activationStatusInfo.activationSent;
        this.activationConfirmed = data.activationStatusInfo.activationConfirmed;
        this.hasPrimaryEmail = data.hasPrimaryEmail;
        this.hasJobAccess = data.hasJobAccess;

        this.canAdd = canAdd;
        this.canDelete = canDelete;
        this.canEdit = canEdit;
    }
    id: number;
    displayName: string;
    primaryEmail?: string;
    phonePrimary?: string;
    activationStatus: ActivationStatus;
    activationSent?: Moment;
    activationConfirmed?: Moment;
    canAdd: boolean;
    canDelete: boolean;
    canEdit: boolean;
    hasPrimaryEmail: boolean;
    hasJobAccess: boolean;
    orgLinkId?: number;
}

export class ContactEntityInfo implements IContactDetailCardInfo {
    constructor(data: ContactEntity) {
        this.id = data.id;
        this.displayName = data.displayName;
        this.primaryEmail = data.primaryEmail?.emailAddress || EmailConstants.NoEmailPlaceholder;
        this.phonePrimary = data.phonePrimary || "(---) --- - ----";
        this.activationStatus = data.activationStatus;
        this.activationSent = data.activationSent ? moment(data.activationSent) : undefined;
        this.activationConfirmed = data.activationConfirmed
            ? moment(data.activationConfirmed)
            : undefined;
        this.canAdd = data.canAdd;
        this.canDelete = data.canDelete;
        this.canEdit = data.canEdit;
        this.hasPrimaryEmail = data.hasPrimaryEmail;
        this.hasJobAccess = data.hasJobAccess;
        this.orgLinkId = data.orgLinkId;
    }
    id: number;
    orgLinkId?: number;
    displayName: string;
    primaryEmail?: string;
    phonePrimary?: string;
    activationStatus: ActivationStatus;
    activationSent?: Moment;
    activationConfirmed?: Moment;
    canAdd: boolean;
    canDelete: boolean;
    canEdit: boolean;
    hasPrimaryEmail: boolean;
    hasJobAccess: boolean;
}
