import classNames from "classnames";
import { FC, ReactNode } from "react";
import { useInView } from "react-intersection-observer";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { isInIframe } from "utilities/url/url";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCloseOutlined } from "commonComponents/btWrappers/BTIcon";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import {
    EntityShareButton,
    ISharingConfig,
} from "commonComponents/entity/sharing/EntityShareButton/EntityShareButton";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";

import "./BTModalLayout.less";

interface IBTModalLayoutProps {
    title?: ReactNode;
    modalHeaderClassName?: string;
    subtitle?: ReactNode;
    sharing?: ISharingConfig;
    footerContent?: ReactNode;
    modalConfig?: IModalConfiguration;
    className?: string;
    closable?: boolean;
    headerLogoAlign?: "center" | "left";
}

export const BTModalLayout: FC<IBTModalLayoutProps & ITrackingProp> = track({})((props) => {
    const { ref: topRef, inView: topInView } = useInView({ threshold: 1 });
    const { ref: bottomRef, inView: bottomInView } = useInView({ threshold: 1 });

    const content = (
        <div className={classNames("BTModalLayout", props.className)}>
            {props.title && (
                <BTModalHeader
                    {...props}
                    className={classNames({ Unstuck: !topInView }, props.modalHeaderClassName)}
                />
            )}
            <div className="ModalContentContainer">
                <div ref={topRef} />
                {props.children}
                <div ref={bottomRef} />
            </div>
            {props.footerContent && (
                <div
                    className={classNames("BTModalFooter", {
                        Unstuck: !bottomInView,
                    })}
                    data-testid="modalFooter"
                >
                    {props.footerContent}
                </div>
            )}
            {props.closable !== false && props.modalConfig?.beforeClose && (
                <div
                    className="BTModalMask"
                    onClick={() => {
                        props.modalConfig?.beforeClose();
                        props.tracking?.trackEvent({
                            event: "ModalClose",
                            extraInfo: {
                                isFromClickOut: true,
                            },
                        });
                    }}
                />
            )}
        </div>
    );
    return isInIframe() ? <FocusProvider>{content}</FocusProvider> : content;
});

export const BTModalHeader: FC<IBTModalLayoutProps> = ({
    className,
    closable = true,
    modalConfig,
    sharing,
    subtitle,
    headerLogoAlign = "center",
    title,
}) => {
    return (
        <div className={classNames("BTModalHeader", className)}>
            <div
                className={classNames("ModalHeaderContainer flex-grow-1", {
                    "ModalHeaderContainer--left": headerLogoAlign === "left",
                })}
            >
                <div className="ModalHeaderContent">
                    {subtitle && <div className="ModalHeaderJobName">{subtitle}</div>}
                    {title && (
                        <span className="ShareableTitle">
                            <BTTitle level={2} bold className="ModalHeaderTitle">
                                {title}
                            </BTTitle>
                            <EntityShareButton sharing={sharing} />
                        </span>
                    )}
                </div>
            </div>

            {closable && (
                <BTButton
                    type="tertiary"
                    isolated
                    data-testid="close"
                    hotkey={{ hotkey: "close", popoverPlacement: "bottom" }}
                    onClick={modalConfig?.beforeClose}
                    className="ModalHeaderClose"
                    icon={
                        <BTIconCloseOutlined
                            title="Close"
                            size={20}
                            className="ModalHeaderCloseIcon"
                        />
                    }
                />
            )}
        </div>
    );
};
