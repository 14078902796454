import classNames from "classnames";
import { PropsWithChildren, ReactNode, useCallback } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";

import { BTButton, BTButtonType } from "commonComponents/btWrappers/BTButton/BTButton";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";

import "../BTLink/BTLink.less";

const newTabRel = "noopener noreferrer";

interface IExternalLinkButtonProps {
    "data-testid": string;
    /**
     * If provided with a button type, this will use button styling instead of standard link styling
     */
    buttonType: BTButtonType;
    isUnderline?: never;
}

interface IExternalLinkAnchorProps {
    "data-testid"?: string;
    isUnderline?: boolean;
    buttonType?: undefined;
}

interface IBTExternalLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "type"> {
    disabled?: boolean;
    /**
     * Reduce padding for use of buttons outside of button groups.
     * This is only applied if buttonType is defined, and is recommended
     * only to be used with buttontype="link"
     * @default false
     */
    isolated?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
    icon?: ReactNode;
}

function BTExternalLinkInternal({
    children,
    className = "",
    isUnderline = false,
    onClick,
    tracking,
    rel,
    "data-testid": dataTestId,
    buttonType,
    href,
    disabled,
    isolated,
    hotkey,
    icon,
    ...otherProps
}: PropsWithChildren<
    IBTExternalLinkProps & (IExternalLinkButtonProps | IExternalLinkAnchorProps) & ITrackingProp
>) {
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                onClick(event);
            }
            tracking?.trackEvent({ event: "LinkClick" });
        },
        [onClick, tracking]
    );

    let modifiedRel: string;
    if (rel) {
        modifiedRel = `${rel} ${newTabRel}`;
    } else {
        modifiedRel = newTabRel;
    }
    const linkClassNames = classNames("BTLink", {
        [className!]: className !== undefined,
        underline: isUnderline,
        disabled: disabled,
    });
    const component = buttonType ? (
        <BTButton
            data-testid={dataTestId!}
            type={buttonType}
            href={href}
            target="_blank"
            rel={modifiedRel}
            disabled={disabled}
            className={className}
            isolated={isolated}
            onClick={handleClick}
            icon={icon}
        >
            {children}
        </BTButton>
    ) : (
        // we do ensure this element always has rel="noopener noreferrer" in the lines above, but eslint doesn't recognize that, so disabling eslint for this line
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            data-testid={dataTestId}
            className={linkClassNames}
            href={href}
            {...otherProps}
            target="_blank"
            rel={modifiedRel}
            onClick={handleClick}
        >
            {children}
        </a>
    );

    if (!hotkey) {
        return component;
    }

    return (
        <Hotkey
            {...getHotkeyProps(hotkey)}
            disabled={disabled}
            onCommand={() => {
                handleClick({} as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
                if (href) {
                    window.open(href, "_blank", modifiedRel);
                }
            }}
        >
            {component}
        </Hotkey>
    );
}

/**
 * Wrapper over anchor tag that automatically adds target="_blank" and adds noopener/noreferrer to rel
 */
export const BTExternalLink = track((props) => ({
    element: "Link",
    uniqueId: props["data-testid"],
}))(BTExternalLinkInternal);
