import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

export interface IAuthLogHandler {
    authLog(): Promise<EmptyResponseEntity>;
}

export class AuthLogHandler implements IAuthLogHandler {
    async authLog(): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Login/LogAuth`, {
            method: "POST",
            responseType: EmptyResponseEntity,
        });
    }
}
