import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { memo } from "react";

import { ShowCharactersRemainingThreshold } from "types/constants";

import { track } from "utilities/analytics/analytics";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { useDebouncedOnChange } from "commonComponents/utilities/Input/InputDebouncingContext";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

type OmittedTextAreaProps = "id" | "onChange" | "onBlur" | "placeholder" | "autosize";
export interface IBTTextAreaProps<FormValues> extends Omit<TextAreaProps, OmittedTextAreaProps> {
    id: KeyOfOrString<FormValues> & string;
    "data-testid": string;
    readOnly?: boolean;

    value: string | undefined;

    /** The max number of characters to allow in the textarea */
    maxLength?: number;

    /** The minimum number of rows to display */
    minRows?: number;

    /** The maximum number of rows to display */
    maxRows?: number;

    /** pass formik setFieldValue */
    onChange: (field: KeyOfOrString<FormValues>, value: string) => void;

    /** pass formik setFieldTouched */
    onBlur: (id: KeyOfOrString<FormValues>, touched: boolean, value: string | undefined) => void;

    /** ref for the input */
    fieldRef?: React.RefObject<any>;
}

/**
 * @example <BTTextArea<IFormValues> id="XX" data-testid="XX" value={values.XX} onChange={handleChange} onBlur={handleBlur} />
 * @example // max length example using yup validation
 * myText: yup.string().label("My Text").max(4000)
 */
const BTTextAreaInternal = track((props) => ({
    element: "Text Area",
    uniqueId: props["data-testid"],
}))(function <FormValues = undefined>({
    id,
    disabled,
    className,
    onChange,
    onBlur,
    maxLength,
    value,
    "data-testid": testid,
    readOnly,
    fieldRef,
    minRows = 2,
    maxRows = 12,
    ...otherProps
}: IBTTextAreaProps<FormValues>) {
    const [debouncedValue, debouncedChange] = useDebouncedOnChange<typeof value, FormValues>(
        value ?? "",
        (field: KeyOfOrString<FormValues>, value: string) => {
            // fire blur event on change so min/max length error appears right away
            onBlur(field, true, value);
            onChange(field, value);
        }
    );

    function onChangeWrapper(event: React.ChangeEvent<HTMLTextAreaElement>) {
        debouncedChange(id, event.target.value);
    }

    function onBlurWrapper() {
        onBlur(id, true, debouncedValue);
    }

    let charactersRemaining: JSX.Element | undefined;

    const valueLength = debouncedValue ? debouncedValue.length : 0;
    if (
        maxLength !== undefined &&
        disabled !== true &&
        valueLength <= maxLength &&
        valueLength / maxLength >= ShowCharactersRemainingThreshold
    ) {
        // Note: characters remaining past the max length limit should be handled by a max length validator on your form values
        const charCount = maxLength - valueLength;
        charactersRemaining = (
            <span data-testid="charsRemaining">
                {charCount} {charCount === 1 ? "character" : "characters"} remaining
            </span>
        );
    }

    if (readOnly) {
        return (
            <ValueDisplay
                id={id}
                data-testid={testid}
                value={debouncedValue}
                className={className}
                isTextArea={true}
            />
        );
    }

    // note: maxLength is NOT spread on the TextArea by design. We want don't want to prevent the user from typing beyond the limit
    return (
        <>
            {/* eslint-disable-next-line react/forbid-elements */}
            <Input.TextArea
                id={id}
                name={id}
                data-testid={testid}
                value={debouncedValue}
                onChange={onChangeWrapper}
                onBlur={onBlurWrapper}
                autoSize={{
                    minRows: minRows,
                    maxRows: maxRows,
                }}
                ref={fieldRef}
                disabled={disabled}
                className={className}
                {...otherProps}
            />
            {charactersRemaining}
        </>
    );
});

export const BTTextArea = memo(BTTextAreaInternal) as typeof BTTextAreaInternal;
