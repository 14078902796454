import { List } from "antd";
import classNames from "classnames";
import { useState } from "react";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconTakeoffs } from "commonComponents/btWrappers/BTIcon";
import { BTListVirtual } from "commonComponents/btWrappers/BTListVirtual/BTListVirtual";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { ColorSwatch } from "commonComponents/entity/colorPicker/ColorSwatch/ColorSwatch";
import { getAccountingLabelText } from "commonComponents/financial/Common/accountingUtils";
import {
    IJobPickerJobProps,
    IJobPickerProps,
} from "commonComponents/utilities/JobPicker/JobPicker";
import {
    AccountSwitcherItem,
    JobPickerJob,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobIdTypes,
    JobPickerDisplayModes,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import { JobPickerEmptyStateBanner } from "commonComponents/utilities/JobPicker/JobPickerEmptyState/JobPickerEmptyStateBanner";
import { JobInfoContainer } from "commonComponents/utilities/JobPicker/Sections/JobInfo";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

interface IJobPickerJobsList extends IJobPickerProps {
    getJobsList: () => { count: number; jobs: JobPickerJob[] };
    onShowMoreThanOneHundred: () => void;
    clearKeywordSearch: () => void;
    keywordSearch: string;
    isConnectedToAccounting: boolean;
}

const JobIcon: React.FunctionComponent<IJobPickerJobProps> = (props) => {
    const { currentJob } = props;

    let jobIcon: string | null = null;
    let jobHoverText: string | undefined = undefined;
    let content: React.ReactNode;

    if (currentJob.jobId === JobIdTypes.GlobalJob) {
        jobIcon = "/images/TimeClock/TimeClockIcon.png";
        jobHoverText =
            "General allows you to clock in/out without assigning that time to a specific job.";
    } else if (currentJob.jobId === JobIdTypes.GlobalDocs) {
        jobIcon = "/images/Documents/folderGlobalx21.png";
        if (props.displayMode === JobPickerDisplayModes.Documents) {
            jobHoverText = "Global Documents Folder";
        }
        if (props.displayMode === JobPickerDisplayModes.Videos) {
            jobHoverText = "Global Videos Folder";
        }
        if (props.displayMode === JobPickerDisplayModes.Photos) {
            jobHoverText = "Global Photos Folder"; // This is not currently a thing, but could become a thing at some point in the future, so adding it now
        }
    } else if (currentJob.accountingEnabledImageSrc) {
        jobIcon = currentJob.accountingEnabledImageSrc;
        jobHoverText = currentJob.accountingEnabledImageSrcHoverMsg || undefined;
        const label = getAccountingLabelText(currentJob.accountingType);
        content = (
            <StatusTagDisplay
                className="margin-right-xs"
                statusType={jobHoverText === "Pending Sync" ? "pending" : "success"}
                statusText={label}
            />
        );
    } else if (currentJob.customJobIconSrc) {
        jobIcon = currentJob.customJobIconSrc;
    } else if (currentJob.hasTakeoff) {
        content = <BTIconTakeoffs data-testid={`job-takeoff-icon-${currentJob.jobId}`} />;
    }

    if (Boolean(jobIcon || content)) {
        const inner = (
            <div>{content ?? <img className="JobIcon" src={jobIcon!} alt={jobHoverText} />}</div>
        );
        if (jobHoverText) {
            return <BTPopover content={jobHoverText}>{inner}</BTPopover>;
        } else {
            return inner;
        }
    }
    return null;
};

const JobPickerListItem: React.FunctionComponent<IJobPickerJobProps> = (props) => {
    const { currentJob, selectedJobIds, isTemplateMode } = props;
    const scheduleMode = props.displayMode === JobPickerDisplayModes.Calendar;

    const [isPopoverVisible, setPopoverVisible] = useState(false);

    let currentlySelectedId: number;
    if (typeof selectedJobIds === "number") {
        currentlySelectedId = selectedJobIds;
    } else if (selectedJobIds === null) {
        // None selected
        currentlySelectedId = JobIdTypes.NoJobs;
    } else {
        currentlySelectedId = JobIdTypes.AllJobs;
    }

    let content = (
        <>
            <div
                className="flex align-items-center ItemRow"
                // Needed to ensure popover hover works within virtual scroll container
                onMouseEnter={() => setPopoverVisible(true)}
                onMouseLeave={() => setPopoverVisible(false)}
            >
                {scheduleMode && (
                    <ColorSwatch
                        className="JobItemColor"
                        size={14}
                        color={currentJob.jobInfo.color}
                    />
                )}
                <div className="ItemRowJobName flex-grow-1">{currentJob.jobName}</div>
                <JobIcon {...props} />
            </div>
            <BuilderLabel id={currentJob.builderId} accounts={props.availableAccounts} />
        </>
    );

    if (currentJob.jobId > 0 && !isTemplateMode) {
        content = (
            <BTPopover
                visible={isPopoverVisible}
                content={
                    <JobInfoContainer currentJob={currentJob} isTemplateMode={isTemplateMode} />
                }
                placement="right"
            >
                {content}
            </BTPopover>
        );
    }

    return (
        <List.Item
            className={classNames("JobListItem", {
                selected: currentJob.jobId === currentlySelectedId,
                AllSelected: currentlySelectedId === JobIdTypes.AllJobs,
                AllJobs: currentJob.jobId === JobIdTypes.AllJobs,
                ScheduleMode: scheduleMode,
            })}
            style={props.style}
            onClick={async () => await props.onJobPickerJobChanged(currentJob, props.keywordSearch)}
        >
            {content}
        </List.Item>
    );
};

const BuilderLabel: React.FunctionComponent<{ id: number; accounts?: AccountSwitcherItem[] }> = (
    props
) => {
    if (!props.accounts || props.accounts.filter((b) => b.selected).length <= 1) {
        return null;
    }
    const name = props.accounts.find((b) => b.builderId === props.id)?.builderName;
    if (!name) {
        return null;
    }
    return <div className="flex-grow-1 JobBuilderLabel">{name}</div>;
};

export const JobPickerJobsList: React.FunctionComponent<IJobPickerJobsList> = (props) => {
    const { listMetadata } = props;
    const jobsListAndCount = props.getJobsList();
    const jobsList = jobsListAndCount.jobs;
    if (jobsList.filter((x) => x.jobId !== JobIdTypes.AllJobs).length === 0) {
        // If only job is "All Listed", then display empty
        const isEmptyDueToKeywordSearch =
            props.jobs.filter((x) => x.jobId !== JobIdTypes.AllJobs).length > 0;
        return (
            <div id="JobPickerJobListEmptyState">
                <JobPickerEmptyStateBanner
                    isDismissed={listMetadata.emptyStatesMetadata.isNewToEntity}
                    actionBeingPerformed={undefined}
                    isNewToEntity={false}
                    hasListData={false} // The keyword filter emptied this out, so we won't listen to the service response since it's a client-side filter
                    hasFilteredData={true} // If there were jobs before, it has filtered data
                    isReadonly={listMetadata.emptyStatesMetadata.isReadOnly}
                    onCallToActionClick={props.clearKeywordSearch}
                    canClearSearch={isEmptyDueToKeywordSearch}
                    isBanner={false}
                    mode={props.templatesOnly ? "Template" : "Job"}
                    isConnectedToAccounting={props.isConnectedToAccounting}
                />
            </div>
        );
    }
    let footer: React.ReactNode | undefined = undefined;
    if (jobsListAndCount.count !== jobsListAndCount.jobs.length) {
        // Need to add the show more button
        footer = (
            <div className="padding-all-sm">
                Over 100 Jobs Listed
                <BTButton
                    data-testid="ShowAll"
                    type="link"
                    onClick={props.onShowMoreThanOneHundred}
                >
                    View More
                </BTButton>
            </div>
        );
    } else if (props.isListLimited) {
        footer = <div className="padding-all-sm">Update Filters or Search for Additional Jobs</div>;
    }

    return (
        <BTListVirtual<JobPickerJob>
            dataSource={jobsListAndCount.jobs}
            renderItem={(job, index, style) => (
                <JobPickerListItem {...props} style={style} currentJob={job} />
            )}
            footer={footer}
            className="JobList"
            width={props.width}
            idKey="jobId"
            defaultRowHeight={34}
        />
    );
};
