import { isEventWhitelisted, translateEventName } from "utilities/analytics/analyticsUtils";
import { isInIframe } from "utilities/url/url";

const whitelist: string[] = ["ButtonClick|Buildertrend|?|updateAccount"];

export function appcuesPlugin(userConfig = {}) {
    return {
        name: "appcues",
        config: userConfig,
        track: (params: any) => {
            try {
                const translatedEventName = translateEventName(params);

                if (isEventWhitelisted(translatedEventName, whitelist)) {
                    window.Appcues?.track(translatedEventName, params.payload.properties);

                    if (isInIframe()) {
                        // We're in an iframe, so inform the parent to refresh Appcues
                        window.parent.Appcues?.refresh();
                    }
                }
            } catch (e) {
                // Eat the error
            }
        },
        loaded: () => {
            // return boolean so analytics knows when it can send data to third party
            return !!window.Appcues;
        },
    };
}
