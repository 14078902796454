import Icon from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import SVG from "images/People.svg?react";

import "./BTIconUsers.less";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

interface IBTIconUsersProps extends IBTIconProps {
    isOnDark?: boolean;
}

export const BTIconUsers: FunctionComponent<IBTIconUsersProps> = (baseProps) => {
    const { isOnDark, ...iconProps } = baseProps;
    const [props] = useBaseIcon(iconProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon
            {...props}
            component={SVG}
            className={classNames("BTIconUsers", props.className, { isOnDark })}
            data-testid="BTIcon-Users"
        />
    );
};
