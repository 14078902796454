import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { LeadStatus } from "types/enum";

import { APIHandler } from "utilities/apiHandler";
import { combineDateTime } from "utilities/date/date";

import { GetAddressServiceObject } from "commonComponents/entity/address/Address/Address";
import { BulkActionResponse } from "commonComponents/entity/bulkAction/BulkAction/BulkAction";

import {
    ActivityImportEntity,
    IActivityImportFormValues,
} from "entity/lead/Common/LeadActivityImport/LeadActivityImport.api.types";
import {
    ActivityCopyResponse,
    DefaultProposalResponse,
    FutureLeadActivitiesCountResponse,
    FutureLeadActivityIdsResponse,
    IActivityCopyFormValues,
    IImportProposalToLeadRequest,
    ILeadFormValues,
    IMassActivityReassignRequest,
    IMassUpdateActivityColorRequest,
    LeadActivityListRequest,
    LeadActivityListResponse,
    LeadEntity,
    LeadProposalListResponse,
    LeadProposalTemplateCopyResponse,
    LeadUpdateResponse,
    UpdateStatusSetupResponse,
    WhatToCopyResponse,
} from "entity/lead/Lead/Lead.api.types";
import { ILeadProposalTemplateCopyFormValues } from "entity/lead/Lead/tabs/LeadProposals/LeadProposalTemplateCopy";
import { TemplateImportConflictsResponse } from "entity/templateImport/TemplateImport/TemplateImport.api.types";

export interface ILeadHandler {
    get(id: number): Promise<LeadEntity>;
    getDefault(): Promise<LeadEntity>;
    create(entity: LeadEntity, values: ILeadFormValues): Promise<LeadUpdateResponse>;
    update(entity: LeadEntity, values: ILeadFormValues): Promise<LeadUpdateResponse>;
    delete(id: number, deleteContact?: boolean): Promise<EmptyResponseEntity>;
    createDefaultProposal(leadId: number): Promise<DefaultProposalResponse>;
    reassignLeads(leads: number[], salesperson: number[]): Promise<BulkActionResponse>;
    reassignFutureActivities(leads: number[], salesperson: number): Promise<EmptyResponseEntity>;
    deleteFutureActivities(leads: number[]): Promise<EmptyResponseEntity>;
    getFutureActivityIds(leadId: number): Promise<FutureLeadActivityIdsResponse>;
    getFutureActivitiesCount(leadIds: number[]): Promise<FutureLeadActivitiesCountResponse>;
    importProposalToLead(values: IImportProposalToLeadRequest): Promise<BulkActionResponse>;
    copyProposalToTemplate(
        proposalId: number,
        data: ILeadProposalTemplateCopyFormValues
    ): Promise<LeadProposalTemplateCopyResponse>;
    getWhatToCopy(proposalId: number): Promise<WhatToCopyResponse>;
    getActivitiesForLead(listRequest: LeadActivityListRequest): Promise<LeadActivityListResponse>;
    deleteActivities(activityIds: number[]): Promise<BulkActionResponse>;
    reassignActivities(activityIds: number[], salespersonId: number): Promise<BulkActionResponse>;
    changeActivityColors(activityIds: number[], color: string): Promise<EmptyResponseEntity>;
    getActivityImportData(leadIds: number[]): Promise<ActivityImportEntity>;
    importActivities(
        userId: number,
        leadIds: number[],
        values: IActivityImportFormValues
    ): Promise<BulkActionResponse>;
    copyActivitiesToTemplate(
        leadId: number,
        values: IActivityCopyFormValues
    ): Promise<ActivityCopyResponse>;
    getProposalsListForLead(leadId: number): Promise<LeadProposalListResponse>;
    getUpdateStatusSetup(leadIds: number[]): Promise<UpdateStatusSetupResponse>;
    updateLeadStatus(
        leadIds: number[],
        status: LeadStatus,
        soldDate?: moment.Moment
    ): Promise<BulkActionResponse>;
    getImportConflicts(
        proposalId: number,
        leadIds: number[]
    ): Promise<TemplateImportConflictsResponse>;
}

export class LeadHandler implements ILeadHandler {
    get(id: number): Promise<LeadEntity> {
        return APIHandler("/api/Leads", {
            method: "GET",
            data: { id },
            responseType: LeadEntity,
        });
    }
    getDefault(): Promise<LeadEntity> {
        return APIHandler("/api/Leads/Defaults", {
            method: "GET",
            responseType: LeadEntity,
        });
    }
    create(entity: LeadEntity, values: ILeadFormValues): Promise<LeadUpdateResponse> {
        return APIHandler("/api/Leads", {
            method: "POST",
            data: getDataForUpdate(entity, values),
            responseType: LeadUpdateResponse,
        });
    }
    update(entity: LeadEntity, values: ILeadFormValues): Promise<LeadUpdateResponse> {
        return APIHandler(`/api/Leads?id=${entity.id}`, {
            method: "PUT",
            data: getDataForUpdate(entity, values),
            responseType: LeadUpdateResponse,
        });
    }
    delete(id: number, deleteContact?: boolean): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/Leads?id=${id}${deleteContact ? "&deleteContact=true" : ""}`, {
            method: "DELETE",
            responseType: EmptyResponseEntity,
        });
    }
    createDefaultProposal(leadId: number): Promise<DefaultProposalResponse> {
        return APIHandler(`/api/Leads/Proposal?id=${leadId}`, {
            method: "POST",
            responseType: DefaultProposalResponse,
        });
    }
    reassignLeads(leads: number[], salesperson: number[]): Promise<BulkActionResponse> {
        return APIHandler("/api/Leads/BulkUpdateLeads", {
            method: "PUT",
            data: { salesperson, leadIds: leads },
            responseType: BulkActionResponse,
        });
    }
    reassignFutureActivities(leads: number[], salesperson: number): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/LeadActivities/ReassignFutureActivities`, {
            method: "POST",
            data: { leads, salesperson },
            responseType: EmptyResponseEntity,
        });
    }
    deleteFutureActivities(leads: number[]): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/Leads/BulkDeleteFutureActivities`, {
            method: "DELETE",
            data: { leads },
            responseType: EmptyResponseEntity,
        });
    }
    copyProposalToTemplate(
        proposalId: number,
        data: ILeadProposalTemplateCopyFormValues
    ): Promise<LeadProposalTemplateCopyResponse> {
        return APIHandler(`/api/LeadProposal/${proposalId}/CopyToProposalTemplate`, {
            method: "POST",
            responseType: LeadProposalTemplateCopyResponse,
            data,
        });
    }
    importProposalToLead(values: IImportProposalToLeadRequest): Promise<BulkActionResponse> {
        return APIHandler("/api/Leads/ImportProposal", {
            method: "POST",
            data: values,
            responseType: BulkActionResponse,
        });
    }
    getWhatToCopy(proposalId: number): Promise<WhatToCopyResponse> {
        return APIHandler(`/api/Leads/WhatToCopy?proposalId=${proposalId}`, {
            method: "GET",
            responseType: WhatToCopyResponse,
        });
    }
    getActivitiesForLead(listRequest: LeadActivityListRequest): Promise<LeadActivityListResponse> {
        return APIHandler(`/api/LeadActivities/GridForLead`, {
            method: "POST",
            data: listRequest,
            responseType: LeadActivityListResponse,
        });
    }
    deleteActivities(activityIds: number[]): Promise<BulkActionResponse> {
        return APIHandler("/api/LeadActivities/BulkDeleteActivities", {
            method: "DELETE",
            data: { ids: activityIds },
            responseType: BulkActionResponse,
        });
    }
    reassignActivities(activityIds: number[], salespersonId: number): Promise<BulkActionResponse> {
        const request: IMassActivityReassignRequest = {
            ids: activityIds,
            salesperson: salespersonId,
        };
        return APIHandler("/api/LeadActivities/BulkReassignActivities", {
            method: "POST",
            data: request,
            responseType: BulkActionResponse,
        });
    }
    changeActivityColors(activityIds: number[], color: string): Promise<EmptyResponseEntity> {
        const request: IMassUpdateActivityColorRequest = {
            ids: activityIds,
            color,
        };
        return APIHandler("/api/LeadActivities/BulkUpdateColor", {
            method: "POST",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }
    getActivityImportData(leadIds: number[]): Promise<ActivityImportEntity> {
        return APIHandler("/api/Leads/ActivityImportSetup", {
            method: "POST",
            data: { leadIds },
            responseType: ActivityImportEntity,
        });
    }
    importActivities(
        userId: number,
        leadIds: number[],
        values: IActivityImportFormValues
    ): Promise<BulkActionResponse> {
        const emailsToUpdate = values.updatedEmails.filter((c) => c.email.length > 0);
        const startDateTime = combineDateTime(values.startDate);
        return APIHandler("/api/Leads/ImportActivity", {
            method: "POST",
            data: {
                leadIds,
                template: values.templateId,
                reassignToMe: userId === values.salespersonId,
                startDateTime: startDateTime!.format("YYYY-MM-DDTHH:mm:ss"),
                hasTimeValue: !startDateTime!.clone().startOf("d").isSame(startDateTime!, "second"),
                newAssignee: values.salespersonId,
                updatedEmails: {
                    contactIds: emailsToUpdate.map((c) => c.id),
                    emails: emailsToUpdate.map((c) => c.email),
                    count: emailsToUpdate.length,
                },
            },
            responseType: BulkActionResponse,
        });
    }
    copyActivitiesToTemplate(
        leadId: number,
        values: IActivityCopyFormValues
    ): Promise<ActivityCopyResponse> {
        return APIHandler("/api/LeadActivities/CopyToTemplate", {
            method: "POST",
            data: {
                leadId,
                newTemplateName: values.templateName,
                copyAddresses: values.copyAddresses,
            },
            responseType: ActivityCopyResponse,
        });
    }

    getProposalsListForLead(leadId: number): Promise<LeadProposalListResponse> {
        return APIHandler(`/api/LeadProposal/ListForLead?id=${leadId}`, {
            method: "GET",
            responseType: LeadProposalListResponse,
        });
    }

    getFutureActivityIds(leadId: number): Promise<FutureLeadActivityIdsResponse> {
        return APIHandler(`/api/LeadActivities/GetFutureActivityIds?id=${leadId}`, {
            method: "GET",
            responseType: FutureLeadActivityIdsResponse,
        });
    }

    getFutureActivitiesCount(leadIds: number[]): Promise<FutureLeadActivitiesCountResponse> {
        return APIHandler("/api/LeadActivities/GetFutureActivitiesCount", {
            method: "GET",
            data: { leadIds },
            responseType: FutureLeadActivitiesCountResponse,
        });
    }

    getUpdateStatusSetup(leadIds: number[]): Promise<UpdateStatusSetupResponse> {
        return APIHandler("/api/Leads/UpdateStatusSetup", {
            method: "POST",
            data: { leadIds },
            responseType: UpdateStatusSetupResponse,
        });
    }

    updateLeadStatus(
        leadIds: number[],
        status: LeadStatus,
        soldDate?: moment.Moment
    ): Promise<BulkActionResponse> {
        return APIHandler("/api/Leads/UpdateStatus", {
            method: "POST",
            data: { leadIds, status, soldDate },
            responseType: BulkActionResponse,
        });
    }
    getImportConflicts(
        proposalId: number,
        leadIds: number[],
        jobId?: number
    ): Promise<TemplateImportConflictsResponse> {
        return APIHandler("/api/LeadProposal/GetImportConflicts", {
            method: "POST",
            data: { leadIds, proposalId, jobId },
            responseType: TemplateImportConflictsResponse,
        });
    }
}

function getDataForUpdate(entity: LeadEntity, values: ILeadFormValues) {
    const { contactSelector, soldDate, address, projectedSalesDate, ...otherValues } = values;
    return {
        general: {
            ...otherValues,
            contact: {
                contactSelector,
            },
            soldDate: [LeadStatus.Open, LeadStatus.NoOpportunity].includes(values.status)
                ? null
                : soldDate && soldDate.clone().startOf("day"),
            projectedSalesDate: projectedSalesDate && projectedSalesDate.clone().startOf("day"),
            address: GetAddressServiceObject(entity, address),
            location: address.location,
        },
    };
}
