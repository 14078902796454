import { DependencyList, useEffect, useState } from "react";
import { useIsFirstRender } from "usehooks-ts";

import { showAPIErrorMessage } from "utilities/apiHandler";

export function useLoadEffect(
    loadFn: (handleLoadException: (e: unknown) => void) => void,
    deps?: DependencyList
) {
    const isFirstRender = useIsFirstRender();
    const [, setState] = useState();

    const handleException = (e: unknown) => {
        if (isFirstRender) {
            setState(() => {
                throw e;
            });
        } else {
            showAPIErrorMessage(e);
        }
    };
    useEffect(() => {
        loadFn(handleException);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
