import { externalPages } from "utilities/externalPages";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import appleAppStore from "images/apple-app-store.svg";
import googleAppStore from "images/google-app-store.svg";

import "./AppStore.less";

interface IAppStoreInternalProps {
    arrange?: "horizontal" | "vertical";
}

function AppStoreInternal({ arrange = "horizontal" }: Readonly<IAppStoreInternalProps>) {
    return (
        <div className="AppStore">
            <BTRow
                justify="center"
                gutter={8}
                className={arrange === "vertical" ? "flex-column align-items-center" : ""}
            >
                <BTCol>
                    <a
                        href={externalPages.AppleStoreBTApp}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <BTButton
                            type="link"
                            isolated
                            data-testid="appleAppStore"
                            className="AppStore-Button"
                        >
                            <img alt="" src={appleAppStore} className="AppStore-Image" />
                        </BTButton>
                    </a>
                </BTCol>
                <BTCol>
                    <a
                        href={externalPages.GooglePlayStoreBTApp}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <BTButton
                            type="link"
                            isolated
                            data-testid="googleAppStore"
                            className="AppStore-Button"
                        >
                            <img alt="" src={googleAppStore} className="AppStore-Image" />
                        </BTButton>
                    </a>
                </BTCol>
            </BTRow>
        </div>
    );
}

export const AppStore = withErrorBoundary(AppStoreInternal)("Could not load App Store");
export default AppStore;
