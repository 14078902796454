import { PureComponent } from "react";
import { Route } from "react-router-dom";

import "./PrintPageLayout.less";

interface IPrintPageLayoutProps {
    component: React.ComponentType<any>;
    path?: string;
    exact?: boolean;
    width?: number;
}

export class PrintPageLayout extends PureComponent<IPrintPageLayoutProps> {
    render() {
        const { component: Component, width, ...rest } = this.props;

        const printPageStyles = `
            body {
                background: white;
            }
            body form {
                background: white !important;
            }
        `;

        return (
            <>
                <style>{printPageStyles}</style>
                <div className="PrintPageLayout" style={{ minWidth: width ?? 700, margin: "auto" }}>
                    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
                </div>
            </>
        );
    }
}
