import { Collapse } from "antd";
import classNames from "classnames";
import { Component } from "react";

import { BTCollapse } from "commonComponents/btWrappers/BTCollapse/BTCollapse";
import { BTIconCaretRightOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { IPageSectionProps } from "commonComponents/utilities/PageSection/PageSection";

import "./PageSectionCollapsible.less";

const Panel = Collapse.Panel;

interface IPageSectionCollapsibleProps
    extends Omit<IPageSectionProps, "extra" | "isCollapsible" | "isStickyHeader"> {
    isExpanded?: boolean;
}

export class PageSectionCollapsible extends Component<IPageSectionCollapsibleProps> {
    static defaultProps = {
        className: "",
        removeBodyPadding: false,
    };

    render() {
        const { className, title, children, removeBodyPadding, isExpanded, ...rest } = this.props;

        const pageSectionCollapsibleClass = classNames("PageSectionCollapsible", className, {
            removeBodyPadding: removeBodyPadding,
        });

        return (
            <BTCollapse
                data-testid="pageSectionCollapsible"
                bordered={false}
                expandIcon={({ isActive }) => <BTIconCaretRightOutlined rotateRight={isActive} />}
                className={pageSectionCollapsibleClass}
                defaultActiveKey={isExpanded ? "pageSectionContent" : undefined}
                {...rest}
            >
                <Panel
                    key="pageSectionContent"
                    header={
                        <BTTitle level={2} className="pageSectionTitle">
                            {title}
                        </BTTitle>
                    }
                >
                    {children}
                </Panel>
            </BTCollapse>
        );
    }
}
