import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ITemplateFromTemplateProps } from "./TemplateFromTemplate";

const TemplateFromTemplate = lazyLoadWithRetry(() => import("./TemplateFromTemplate"));

export const RouteTemplateFromTemplate = (
    props: Omit<ITemplateFromTemplateProps, "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.template.templateFromTemplate}
        render={(routeProps) => {
            const jobId = routeProps.match.params.externalJobId;
            return (
                <BTSuspense>
                    <TemplateFromTemplate externalJobId={jobId} {...props} {...routeProps} />
                </BTSuspense>
            );
        }}
    />
);
