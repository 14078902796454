import { message } from "antd";
import { Component } from "react";

import { FilterValueContext } from "helpers/globalContext/FilterValueContext";

import { getFilterEntityType, ListEntityType } from "utilities/list/list.types";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconFunnelX } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { emptyStateIconSize } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { EmptyState } from "commonComponents/entity/emptyState/EmptyState";

import {
    FilterEntity,
    FilterFormActions,
    getSelectedFilter,
    IFilterFormValues,
    SelectedFilterItem,
    StandardFilterId,
} from "entity/filters/Filter/Filter.api.types";
import { applySerializeBehavior, prepareFiltersForSubmission } from "entity/filters/filters.utils";

export interface IFilteredEmptyState {
    entityTitlePlural: string;
    onClearFilters?: (
        updatedFilterValues: IFilterFormValues,
        actionBeingPerformed: FilterFormActions
    ) => Promise<void>;
    entityType?: ListEntityType;
    filterEntity?: FilterEntity;
}

export class FilteredEmptyState extends Component<IFilteredEmptyState> {
    static contextType = FilterValueContext;
    filterValueContext: React.ContextType<typeof FilterValueContext>;
    private handleClearFilters = async () => {
        const { filterEntity, onClearFilters, entityType } = this.props;
        if (onClearFilters && filterEntity && entityType) {
            const clearedItems = filterEntity.items.map((e) => new SelectedFilterItem(e));
            const savedFilter = getSelectedFilter(filterEntity, StandardFilterId);
            const values: IFilterFormValues = {
                items: clearedItems,
                savedFilter,
            };
            prepareFiltersForSubmission(values, filterEntity);

            const transformedValues = applySerializeBehavior(filterEntity, values);
            this.filterValueContext?.setAppliedFilter({
                items: values.items,
                type: getFilterEntityType(entityType),
            });
            void message.success("All filters cleared");

            await onClearFilters(transformedValues, "update");
        }
    };

    render() {
        const { entityTitlePlural, onClearFilters } = this.props;

        return (
            <EmptyState
                title="No results"
                image={
                    <BTIconFunnelX
                        size={emptyStateIconSize}
                        className="EmptyStateIcon"
                        data-testid="EmptyStateIcon"
                    />
                }
            >
                <BTRow>
                    <span>
                        Adjust or clear your filters to find the {entityTitlePlural} you're looking
                        for.
                    </span>
                    {!!onClearFilters && (
                        <BTButton
                            className="margin-top-sm"
                            data-testid="emptyState-clearAllFilters"
                            type="new"
                            onClick={this.handleClearFilters}
                        >
                            Clear Filters
                        </BTButton>
                    )}
                </BTRow>
            </EmptyState>
        );
    }
}
