import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { NotificationPanelResponse } from "entity/notification/NotificationPanel/NotificationPanel.api.types";

export interface INotificationPanelHandler {
    get(
        firstRow: number,
        lastRow: number,
        previousId: number,
        isRead?: boolean
    ): Promise<NotificationPanelResponse>;
    updateStatus(
        notificationId: number,
        builderId: number,
        markRead: boolean
    ): Promise<EmptyResponseEntity>;
    markAllRead(globalUserId: number, builderId: number): Promise<EmptyResponseEntity>;
}

export class NotificationPanelHandler implements INotificationPanelHandler {
    get(
        firstRow: number,
        lastRow: number,
        previousId: number,
        isRead?: boolean
    ): Promise<NotificationPanelResponse> {
        return APIHandler(`/api/Notifications/Summary`, {
            method: "GET",
            data: {
                infiniteScrollData: JSON.stringify({
                    firstRow,
                    lastRow,
                    previousId,
                }),
                isRead,
            },
            responseType: NotificationPanelResponse,
        });
    }

    updateStatus(
        notificationId: number,
        builderId: number,
        markRead: boolean
    ): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/Notifications/UpdateNotificationStatus`, {
            method: "PUT",
            data: {
                notificationId,
                builderId,
                markRead,
            },
            responseType: EmptyResponseEntity,
        });
    }

    markAllRead(globalUserId: number, builderId: number): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/Notifications/MarkAllRead`, {
            method: "PUT",
            data: { globalUserId, builderId },
            responseType: EmptyResponseEntity,
        });
    }
}
