import { IUserPermissions } from "utilities/list/list.types";

import type { default as SelectionConfigJson } from "entity/selection/SelectionListContainer/SelectionConfigJson.api.json";

export enum SelectionListContainerTabs {
    Default = -1,
    Agenda = 0,
    CardView = 1,
    List = 2,
    Hierarchy = 3,
    Category = 4,
    Location = 5,
    Allowances = 6,
}

type SelectionConfigApiData = typeof SelectionConfigJson;
export class SelectionConfig {
    constructor(data: SelectionConfigApiData) {
        this.defaultBuilderTab = data.defaultBuilderTab;
        this.defaultOwnerTab = data.defaultOwnerTab;
        this.jobIncludesAllowances = data.jobIncludesAllowances;
        this.ownerCanViewLockedSelections = data.ownerCanViewLockedSelections;
        this.selectionPermissions = data.selectionPermissions;
        this.shouldExpandCategoryLocationGroups = data.shouldExpandCategoryLocationGroups;
    }
    defaultBuilderTab: SelectionListContainerTabs;
    defaultOwnerTab: SelectionListContainerTabs;
    jobIncludesAllowances: boolean;
    ownerCanViewLockedSelections: boolean;
    selectionPermissions: IUserPermissions;
    shouldExpandCategoryLocationGroups: boolean;
}
