import moment from "moment";
import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ILeadActivityProps } from "entity/lead/LeadActivity/LeadActivity";

const LeadActivity = lazyLoadWithRetry(() => import("./LeadActivity"));
export const RouteLeadActivity = (
    props: Omit<
        ILeadActivityProps,
        "id" | "leadId" | "openActivity" | "activityDate" | "history" | "match" | "location"
    >
) => (
    <RouteRelative
        path={routes.leadActivity.details}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search);
            const activityDate: moment.Moment | undefined = qsValues.activityDate
                ? moment(qsValues.activityDate as string)
                : undefined;
            return (
                <BTSuspense>
                    <LeadActivity
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        leadId={parseInt(routeProps.match.params.leadId)}
                        openActivity={parseInt(routeProps.match.params.openActivity)}
                        activityDate={activityDate}
                    />
                </BTSuspense>
            );
        }}
    />
);
