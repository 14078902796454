import { removeAt, replaceAt } from "utilities/array/array";

import { IStickyLayer, StickyDirection } from "./StickyContext.type";

export class StickyLayerAddAction {
    constructor(layer: IStickyLayer, direction: StickyDirection) {
        this.layer = layer;
        this.direction = direction;
    }

    public layer: IStickyLayer;
    public direction: StickyDirection;
    public readonly type = "add";
}

export class StickyLayerRemoveAction {
    constructor(layer: IStickyLayer, direction: StickyDirection) {
        this.layer = layer;
        this.direction = direction;
    }

    public layer: IStickyLayer;
    public direction: StickyDirection;
    public readonly type = "remove";
}

export class StickyLayerReplaceAction {
    constructor(
        layers: {
            oldLayer: IStickyLayer | undefined;
            newLayer: IStickyLayer | undefined;
        },
        direction: StickyDirection
    ) {
        this.layers = layers;
        this.direction = direction;
    }

    public layers: {
        oldLayer: IStickyLayer | undefined;
        newLayer: IStickyLayer | undefined;
    };
    public direction: StickyDirection;
    public readonly type = "replace";
}

export type StickyLayerAction =
    | StickyLayerAddAction
    | StickyLayerRemoveAction
    | StickyLayerReplaceAction;

interface IStickyLayerReducerState {
    top: IStickyLayer[];
    bottom: IStickyLayer[];
}

export function stickyLayerReducer(
    state: IStickyLayerReducerState,
    action: StickyLayerAction
): IStickyLayerReducerState {
    switch (action.type) {
        case "add":
            if (action.layer.size) {
                return {
                    ...state,
                    [action.direction]: [...state[action.direction], action.layer],
                };
            }
            return state;
        case "remove":
            return {
                ...state,
                [action.direction]: [
                    ...state[action.direction].filter((layer) => layer !== action.layer),
                ],
            };
        case "replace":
            const hasNewLayerWithHeight = !!action.layers.newLayer?.size;

            if (!action.layers.oldLayer && !hasNewLayerWithHeight) {
                return state;
            }

            let newLayerState = [...state[action.direction]];
            if (action.layers.oldLayer) {
                const oldLayerIndex = newLayerState.indexOf(action.layers.oldLayer);

                newLayerState = hasNewLayerWithHeight
                    ? replaceAt(newLayerState, action.layers.newLayer!, oldLayerIndex)
                    : removeAt(newLayerState, oldLayerIndex);
            } else {
                newLayerState.push(action.layers.newLayer!);
            }

            return {
                ...state,
                [action.direction]: newLayerState,
            };
        default:
            return state;
    }
}
