import moment from "moment";

import { IBaseEntity } from "types/apiResponse/apiResponse";
import { PriorityLevel } from "types/enum";

import { CustomFieldPrintItem } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import { DiscussionPrintAPI } from "commonComponents/entity/discussion/DiscussionPrintContainer/DiscussionPrintContainer.types";
import { PrintHeaderAPI } from "commonComponents/print/PrintHeader/PrintHeader.types";

export class ToDoPrintDisplayOptions {
    constructor(data: any) {
        this.completedDetails = data.completedDetails;
        this.customFields = data.customFields;
        this.discussions = data.discussions;
        this.showAllImages = data.showAllImages;
    }

    completedDetails: boolean;
    customFields?: boolean;
    discussions?: boolean;
    showAllImages: boolean;
}

export class ToDoPrintEntity {
    constructor(data: any) {
        this.printDisplayOptions = new ToDoPrintDisplayOptions(data.printDisplayOptions);
        this.showCustomFieldsCheckbox = data.showCustomFieldsCheckbox;
        this.showDiscussionsCheckbox = data.showDiscussionsCheckbox;
        this.toDos = data.toDosPrints.map((toDo: any) => new ToDoForPrint(toDo));
    }

    printDisplayOptions: ToDoPrintDisplayOptions;
    showCustomFieldsCheckbox: boolean;
    showDiscussionsCheckbox: boolean;
    toDos: ToDoForPrint[];
}

export class ToDoForPrint implements IBaseEntity {
    constructor(data: any) {
        this.id = data.toDoId;
        this.jobId = data.jobsiteId;
        this.builderId = data.builderId;

        this.addedByDate = moment(data.addedByDate);
        this.addedByName = data.addedByName;
        this.assignedUsers = data.assignedUsers.map(
            (assignedUser: any) => new AssignedUser(assignedUser)
        );
        this.assignedUsersOwnerInfo = data.assignedUsersOwnerInfo;
        this.assignedUsersTracker = data.assignedUsersTracker;

        let checklistItems = data.checklistItems.map((item: any) => new ChecklistEntity(item));
        this.completedChecklistItems = checklistItems.filter(
            (item: ChecklistEntity) => item.completedByDate
        );
        this.incompleteChecklistItems = checklistItems.filter(
            (item: ChecklistEntity) => !item.completedByDate
        );

        this.completedByName = data.completedByName;
        this.customFields = data.customFields.map(
            (customField: any) => new CustomFieldPrintItem(customField)
        );
        this.description = data.notes;
        this.discussions = data.discussions.map(
            (discussion: any) => new DiscussionPrintAPI(discussion)
        );
        this.fLJobsiteName = data.flJobsiteName;
        this.isPastDue = data.isPastDue;
        this.isTimeSelected = data.isTimeSelected;
        this.jobsite = new Jobsite(data.jobsite);
        this.localCompletedDate = data.localCompletedDate
            ? moment(data.localCompletedDate)
            : data.localCompletedDate;
        this.localDueDateTime = data.localDueDateTime
            ? moment(data.localDueDateTime)
            : data.localDueDateTime;
        this.printHeader = new PrintHeaderAPI(data.printHeader);
        this.priorityAsEnum = data.priorityAsEnum;
        this.tagList = data.tagList;
        this.title = data.title;
        this.imageUrls = data.imageUrls;
        this.attachmentCount = data.attachmentCount;
    }

    id: number;
    jobId: number;
    builderId: number;

    addedByDate: moment.Moment;
    addedByName: string;
    assignedUsers: AssignedUser[];
    assignedUsersOwnerInfo: string;
    assignedUsersTracker: number[];
    completedChecklistItems: ChecklistEntity[];
    completedByName: string;
    customFields: CustomFieldPrintItem[];
    description: string;
    discussions: DiscussionPrintAPI[];
    fLJobsiteName: string;
    incompleteChecklistItems: ChecklistEntity[];
    isPastDue: boolean;
    isTimeSelected: boolean;
    jobsite: Jobsite;
    localCompletedDate?: moment.Moment;
    localDueDateTime?: moment.Moment;
    printHeader: PrintHeaderAPI;
    priorityAsEnum: PriorityLevel;
    tagList: string;
    title: string;
    imageUrls: string[];
    attachmentCount: number;
}

export class AssignedUser {
    constructor(data: any) {
        this.userId = data.userId;
        this.userName = data.userName;
    }

    userId: number;
    userName: string;
}

export class ChecklistEntity {
    constructor(data: any) {
        this.assignedToName = data.assignedToName;
        this.completedByDate = data.completedByDate
            ? moment(data.completedByDate)
            : data.completedByDate;
        this.completedByName = data.completedByName;
        this.description = data.description;
        this.imageUrls = data.imageUrls;
    }

    assignedToName: string;
    completedByDate?: moment.Moment;
    completedByName: string;
    description: string;
    imageUrls: string[];
}

class Jobsite {
    constructor(data: any) {
        this.jobAddress = data.jobAddress;
        this.jobContactName = data.jobContactName;
        this.jobsiteId = data.jobsiteId;
    }

    jobAddress: string;
    jobContactName: string;
    jobsiteId: number;
}
