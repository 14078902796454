import { Decimal, Numeric } from "decimal.js-light";

/**
 * Performs addition while avoiding issues arising from floating point error.
 * To get the raw value, simply call `.toNumber()` on the result
 * @param a
 * @param b
 *
 * @example
 * add(0.3, 0.2) => 0.5
 */
export const add = (a: Numeric, b: Numeric) => {
    const decA = new Decimal(a);
    const decB = new Decimal(b);
    return decA.add(decB);
};

/**
 * Performs subtraction while avoiding issues arising from floating point error.
 * To get the raw value, simply call `.toNumber()` on the result
 * @param a
 * @param b
 *
 * @example
 * subtract(0.3, 0.2) => 0.1
 */
export const subtract = (a: Numeric, b: Numeric) => {
    const decA = new Decimal(a);
    const decB = new Decimal(b);
    return decA.sub(decB);
};

/**
 * Performs multiplication while avoiding issues arising from floating point error
 * To get the raw value, simply call `.toNumber()` on the result
 * @param a
 * @param b
 *
 * @example
 * multiply(0.3, 0.2) => 0.06
 */
export const multiply = (a: Numeric, b: Numeric) => {
    const decA = new Decimal(a);
    const decB = new Decimal(b);
    return decA.mul(decB);
};

/**
 * Performs division while avoiding issues arising from floating point error
 * To get the raw value, simply call `.toNumber()` on the result
 * @param a dividend
 * @param b divisor
 *
 * @example
 * divide(0.3, 0.2) => 1.5
 */
export const divide = (a: Numeric, b: Numeric) => {
    const decA = new Decimal(a);
    const decB = new Decimal(b);
    return decA.div(decB);
};

/**
 * Returns a supplied numeric expression rounded to the nearest number of decimals.
 * Helps avoid floating point rounding errors if the number of decimals supplied are < the precision error location.
 *
 * @param value Value to round
 * @param decimals Number of decimals to round to
 *
 * @example
 * round(0.1 + 0.2,  1) => 0.3
 * round(0.1 + 0.2, 10) => 0.3
 * round(0.1 + 0.2, 17) => 0.30000000000000004
 * See unit tests for more examples
 */
export const round = (value: Numeric, decimals: number = 0) => {
    return new Decimal(value).toDecimalPlaces(decimals).toNumber();
};

/**
 * Preforms a floating-point-error safe way of summing numeric values
 * To get the raw value, simply call `.toNumber()` on the result
 * @param values An array of numeric values
 *
 * @example
 * sum([1, 2, 3]) => 6
 */
export const sum = (values: Numeric[]) => {
    return values.reduce(
        (accumulator: Decimal, value: Numeric) => add(accumulator, new Decimal(value)),
        new Decimal(0)
    );
};

/**
 * Preforms a floating-point-error safe way of summing numeric values of a property in a list of objects
 * @param items An array of objects
 * @param key Property name on obect of value to sum
 *j
 * @example
 * sumListValue([{itemId: 1, cost: 10.50, price: 15.75 }, {itemId: 1, cost: 20, price: 30 }], "price") => 45.75
 */
export function sumListValue<T>(items: T[], key: keyof T): number {
    return items
        .reduce((accumulator: Decimal, item: T) => {
            const numberValue = Number(item[key]);
            if (isNaN(numberValue)) {
                throw new Error(`Value of ${key.toString()} is not a number.`);
            }
            return add(accumulator, new Decimal(numberValue));
        }, new Decimal(0))
        .toNumber();
}

/**
 * Preforms a floating-point-error safe way of summing numeric values
 * If all are null, return null, otherwise, sum the non-null values
 * @param values An array of numeric values
 *
 * @example
 * sumNullable([1, 2, 3, null]) => 6
 * sumNullable([null, null]) => null
 */
export function sumNullable(values: (Numeric | null)[]): number | null {
    const nonNulls = values.filter((v) => v !== null);
    if (nonNulls.length > 0) {
        return nonNulls
            .reduce(
                (accumulator: Decimal, value: Numeric | null) =>
                    add(accumulator, new Decimal(value!)),
                new Decimal(0)
            )
            .toNumber();
    }
    return null;
}

/**
 * Preforms a floating-point-error safe way of subtracting numeric values
 * If all are null, return null, otherwise, subtract the non-null values in order
 * @param values An array of numeric values
 *
 * @example
 * subtractNullable(10, [null, 2, 3]) => 5
 * subtractNullable(null, [null, 2, 3]) => -5
 * subtractNullable(null, [null, null, null]) => null
 */
export function subtractNullable(
    startingValue: Numeric | null,
    values: (Numeric | null)[]
): number | null {
    const nonNulls = values.filter((v) => v !== null);
    if (startingValue !== null || nonNulls.length > 0) {
        return nonNulls
            .reduce(
                (accumulator: Decimal, value: Numeric | null) =>
                    subtract(accumulator, new Decimal(value!)),
                new Decimal(startingValue ?? 0)
            )
            .toNumber();
    }
    return null;
}
