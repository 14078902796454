import { ColorPickerConstants } from "styles/buildertrendTheme/Colors";

import { BTSelectItem } from "types/apiResponse/apiResponse";

export const colors: BTSelectItem[] = [
    new BTSelectItem({ id: 1, title: "Maroon", value: ColorPickerConstants.Maroon }),
    new BTSelectItem({ id: 2, title: "Merlot", value: ColorPickerConstants.Merlot }),
    new BTSelectItem({ id: 3, title: "Tuscan Red", value: ColorPickerConstants.TuscanRed }),
    new BTSelectItem({ id: 4, title: "Rose", value: ColorPickerConstants.Rose }),
    new BTSelectItem({ id: 5, title: "Victoria", value: ColorPickerConstants.Victoria }),
    new BTSelectItem({ id: 6, title: "Brown", value: ColorPickerConstants.Brown }),
    new BTSelectItem({ id: 7, title: "Coffee", value: ColorPickerConstants.Coffee }),
    new BTSelectItem({ id: 8, title: "Amber", value: ColorPickerConstants.Amber }),
    new BTSelectItem({ id: 9, title: "Peach", value: ColorPickerConstants.Peach }),
    new BTSelectItem({ id: 10, title: "Cream", value: ColorPickerConstants.Cream }),
    new BTSelectItem({ id: 11, title: "Forest", value: ColorPickerConstants.Forest }),
    new BTSelectItem({ id: 12, title: "Olive", value: ColorPickerConstants.Olive }),
    new BTSelectItem({ id: 13, title: "Green", value: ColorPickerConstants.Green }),
    new BTSelectItem({ id: 14, title: "Mint", value: ColorPickerConstants.Mint }),
    new BTSelectItem({ id: 15, title: "Cucumber", value: ColorPickerConstants.Cucumber }),
    new BTSelectItem({ id: 16, title: "Plum", value: ColorPickerConstants.Plum }),
    new BTSelectItem({ id: 17, title: "Purple", value: ColorPickerConstants.Purple }),
    new BTSelectItem({ id: 18, title: "Lavender", value: ColorPickerConstants.Lavender }),
    new BTSelectItem({ id: 19, title: "Iris", value: ColorPickerConstants.Iris }),
    new BTSelectItem({ id: 20, title: "Violet", value: ColorPickerConstants.Violet }),
    new BTSelectItem({ id: 21, title: "Navy", value: ColorPickerConstants.Navy }),
    new BTSelectItem({ id: 22, title: "Levi", value: ColorPickerConstants.Levi }),
    new BTSelectItem({ id: 23, title: "Ocean", value: ColorPickerConstants.Ocean }),
    new BTSelectItem({ id: 24, title: "Ice", value: ColorPickerConstants.Ice }),
    new BTSelectItem({ id: 25, title: "Sky", value: ColorPickerConstants.Sky }),
    new BTSelectItem({ id: 26, title: "Graphite", value: ColorPickerConstants.Graphite }),
    new BTSelectItem({ id: 27, title: "Gunmetal", value: ColorPickerConstants.Gunmetal }),
    new BTSelectItem({ id: 28, title: "Silver", value: ColorPickerConstants.Silver }),
    new BTSelectItem({ id: 29, title: "Gray", value: ColorPickerConstants.Gray }),
    new BTSelectItem({ id: 30, title: "Full Moon", value: ColorPickerConstants.FullMoon }),
    new BTSelectItem({ id: 31, title: "Black", value: ColorPickerConstants.Black }),
    new BTSelectItem({ id: 32, title: "Alarm Red", value: ColorPickerConstants.AlarmRed }),
    new BTSelectItem({ id: 33, title: "Alarm Pink", value: ColorPickerConstants.AlarmPink }),
    new BTSelectItem({ id: 34, title: "Alarm Blue", value: ColorPickerConstants.AlarmBlue }),
    new BTSelectItem({ id: 35, title: "Alarm Green", value: ColorPickerConstants.AlarmGreen }),
    new BTSelectItem({ id: 36, title: "Alarm Purple", value: ColorPickerConstants.AlarmPurple }),
    new BTSelectItem({ id: 37, title: "Alarm Orange", value: ColorPickerConstants.AlarmOrange }),
    new BTSelectItem({ id: 38, title: "Alarm Aqua", value: ColorPickerConstants.AlarmAqua }),
    new BTSelectItem({ id: 39, title: "Alarm Lime", value: ColorPickerConstants.AlarmLime }),
    new BTSelectItem({ id: 40, title: "Alarm Gold", value: ColorPickerConstants.AlarmGold }),
];

export const getColorIdFromHex = (color: string): number => {
    const option = colors.find((item) => item.value === color);

    if (option) {
        // normal BTSelectItem's can have string or number id's. Color pickers will only have numbers
        return option.id as unknown as number;
    } else {
        return 25;
    }
};
