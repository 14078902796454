import { BTLoginTypes } from "types/enum";

import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

interface IRoutePDFSignatureSignProps {
    beforeClose: () => void;
}

export const PDFSignatureSignRoute = (props: IRoutePDFSignatureSignProps) => (
    <RouteRelative
        path={routes.signatureRequest.sign}
        render={(routeProps) => {
            const documentInstanceId = parseInt(routeProps.match.params.documentInstanceId);
            const builderId = parseInt(routeProps.match.params.builderId);
            const loginType: BTLoginTypes = parseInt(routeProps.match.params.loginType);

            return (
                <BTSuspense>
                    <BTModal
                        data-testid="btModalPDFSignatureSigning"
                        visible
                        title="Sign document"
                        iframeHeight="800px"
                        width="925px"
                        url={routesWebforms.SignatureRequest.GetSignUrl(
                            documentInstanceId,
                            loginType,
                            false,
                            true,
                            builderId
                        )}
                        beforeClose={() => props.beforeClose()}
                    />
                </BTSuspense>
            );
        }}
    />
);
