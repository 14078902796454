import { APIHandler } from "utilities/apiHandler";

import {
    AddUsersToJobResponse,
    IJobViewingPermissionWizardFormValues,
} from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard.api.types";

export interface IJobViewingPermissionWizardHandler {
    addUsersToJob(formData: IJobViewingPermissionWizardFormValues): Promise<AddUsersToJobResponse>;
}

export class JobViewingPermissionWizardHandler implements IJobViewingPermissionWizardHandler {
    async addUsersToJob(
        formData: IJobViewingPermissionWizardFormValues
    ): Promise<AddUsersToJobResponse> {
        return await APIHandler(`/api/Jobsites/AddUsersToJob/`, {
            method: "PUT",
            data: formData,
            responseType: AddUsersToJobResponse,
        });
    }
}
