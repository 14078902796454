import { createContext } from "react";

export enum MapApiStatus {
    NotLoaded = 0,
    Loaded = 1,
    Error = 2,
    Unavailable = 3,
}

export interface IMapApiContext {
    /**
     * Indicates status of Map API
     */
    status: () => Promise<MapApiStatus>;
}

export const MapApiContext = createContext<IMapApiContext>({
    status: async () => MapApiStatus.NotLoaded,
});
