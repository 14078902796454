import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { Moment } from "moment";

import {
    BTEntityAction,
    BTSelectItem,
    IBaseEntity,
    mapBTServiceDropdownToSelectItem,
    ServiceValidation,
} from "types/apiResponse/apiResponse";
import { ActivationStatus, CustomFieldAssociatedType, LeadStatus } from "types/enum";

import { alphabetize, getSelectedValue, getSelectedValues } from "utilities/form/form";

import {
    IEntityExtraData,
    LinkingAction,
} from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { AccountingEntity } from "commonComponents/entity/accounting/LinkedAccountingEntity/LinkedAccountingEntity.api.types";
import { GetAddressServiceObject } from "commonComponents/entity/address/Address/Address";
import {
    AddressEntity,
    AddressFormValues,
    AddressServiceObject,
    IHasAddress,
    LocationEntity,
} from "commonComponents/entity/address/Address/Address.api.types";
import { getColorIdFromHex } from "commonComponents/entity/colorPicker/ColorPicker/ColorPicker.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    CustomFieldPrintItem,
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import {
    ViewingAccessEntity,
    ViewingAccessFormValues,
} from "commonComponents/entity/job/ViewingAccess/ViewingAccess.types";
import { IMapPosition, PinTypes } from "commonComponents/entity/map/Map.types";
import {
    INotificationPreference,
    INotificationPreferenceData,
    INotificationPreferenceRow,
} from "commonComponents/entity/notificationPreferences/NotificationPreferencesTable.api.types";
import { UserWithConflicts } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";
import {
    IOnlinePaymentLimitsFormValues,
    OnlinePaymentLimitsEntity,
} from "commonComponents/financial/OnlinePaymentLimits/OnlinePaymentLimits.api.types";
import { PrintHeaderAPI } from "commonComponents/print/PrintHeader/PrintHeader.types";
import { PrintInfoAPI } from "commonComponents/print/PrintInfo/PrintInfo.types";

import { BudgetColumn } from "entity/budget/Budget/BudgetContainer/BudgetContainer.api.types";
import { BudgetColumn as JobCostingBudgetColumn } from "entity/budget/JobCostingBudget/BudgetContainer/JobCostingBudgetContainer.api.types";
import { RiskInsuranceStatus } from "entity/builderRiskInsurance/BuilderRiskInsuranceStatus/BuilderRiskInsuranceStatus.api.types";
import { ContactEntity } from "entity/contact/Contact/Contact.api.types";
import { AdjustPercentTypes } from "entity/estimate/AdjustPercentMarkup/AdjustPercentMarkup.api.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

import { TaxGroupServiceListItemExtraData } from "../../commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";

export enum JobDetailTab {
    Job = "1",
    Owner = "2",
    InternalUsers = "3",
    SubsVendors = "4",
    Options = "5",
    Accounting = "6",
    BRI = "7",
}

export const JobDetailTabNames = {
    [JobDetailTab.Job]: "Job",
    [JobDetailTab.Owner]: "Owners",
    [JobDetailTab.InternalUsers]: "Internal Users",
    [JobDetailTab.SubsVendors]: "Subs/Vendors",
    [JobDetailTab.Options]: "Options",
    [JobDetailTab.Accounting]: "Accounting",
    [JobDetailTab.BRI]: "Builder's Risk Insurance",
};

export interface IJobFormValues {
    jobInfo: JobInfoFormValues;
    ownerInfo: OwnerInfoFormValues;
    viewingAccess?: ViewingAccessFormValues;
    accountingEntityLinkingType: JobsiteLinkingTypes;
    createNewAccountingEntityOption: LinkingAction;
    includeOtherAccountingCostsInBudget: boolean;
    customFields: ICustomFieldFormValues[];
    notificationPreferences: INotificationPreferenceRow[];
}

export class JobInfoFormValues {
    constructor(data: JobInfoEntity) {
        this.jobName = data.jobName;
        this.builderName = data.builderName;
        this.groupedProjectType = getSelectedValue(data.groupedProjectTypes);
        this.contractType = getSelectedValue(data.contractTypes);
        this.jobStatus = getSelectedValue(data.jobStatus);
        this.projectManagers = getSelectedValues(data.projectManagers);
        this.jobGroups = getSelectedValues(data.jobGroups);
        this.jobsitePrefix = data.jobsitePrefix;
        this.lotInfo = data.lotInfo;
        this.permitNumber = data.permitNumber;
        this.contractPrice = data.contractPrice;
        this.address = new AddressFormValues(data);
        this.workDays = getSelectedValues(data.workDays);
        this.totalArea = data.totalArea;
        this.notifyProjectManager = false;
        this.internalNotes = data.internalNotes;
        this.subNotes = data.subNotes;
        this.projectedStartDate = data.projectedStartDate;
        this.projectedCompletionDate = data.projectedCompletionDate;
        this.actualStartDate = data.actualStartDate;
        this.actualCompletionDate = data.actualCompletionDate;
        this.syncActualStartEnd = data.syncActualStartEnd;
        this.enabled = data.enabled;

        const selectedColorItem = data.jobColor.filter((item) => item.selected)[0];
        this.jobColor =
            selectedColorItem && selectedColorItem.extraData ? selectedColorItem.extraData.hex : "";

        this.individualPOLimit = new POLimit(data.individualPOLimit).value;
        this.totalPOLimit = new POLimit(data.totalPOLimit).value;
        this.enableGeofencing = data.enableGeofencing;
        this.includeTimeClockForBudget = data.includeTimeClockForBudget;
        this.includeTimeClockForCosts = data.includeTimeClockForCosts;
        this.includeAllowances = data.includeAllowances;
        this.updateRunningTotalForSelection = data.updateRunningTotalForSelection;
        this.isTemplate = data.isTemplate;
        this.templateName = data.templateName;
        this.remainingDaysToDelete = data.remainingDaysToDelete ?? undefined;
        this.defaultTaxGroupId = data.defaultTaxGroupId ?? NoTaxId;
        this.contractType = getSelectedValue(data.contractTypes);
        if (data.additionalInfo?.financialSettings) {
            this.adjustMarkup = new AdjustPercentMarkupFormValues(
                data.additionalInfo.financialSettings
            );
        }
    }

    jobName: string;
    builderName: string;
    groupedProjectType: number;
    jobStatus: number;
    projectManagers: number[];
    jobGroups: number[];
    jobsitePrefix: string;
    lotInfo: string;
    permitNumber: string;
    contractPrice: number;
    workDays: number[];
    totalArea: number | null;
    notifyProjectManager: boolean;
    internalNotes: string | null;
    subNotes: string | null;
    projectedStartDate: moment.Moment | null;
    projectedCompletionDate: moment.Moment | null;
    actualStartDate: moment.Moment | null;
    actualCompletionDate: moment.Moment | null;
    syncActualStartEnd: boolean;
    enabled: boolean;
    individualPOLimit: number | undefined | null;
    totalPOLimit: number | undefined | null;
    enableGeofencing: boolean;
    includeTimeClockForBudget: boolean;
    includeTimeClockForCosts: boolean;
    includeAllowances: boolean;
    updateRunningTotalForSelection: boolean;
    isTemplate: boolean;
    templateName: string;
    jobColor: string;
    address: AddressFormValues;
    remainingDaysToDelete?: number;
    defaultTaxGroupId: number;
    contractType?: number;
    adjustMarkup?: AdjustPercentMarkupFormValues;
}

export class AdjustPercentMarkupFormValues {
    constructor(data: JobFinancialSettings) {
        this.defaultMarkup = data.defaultMarkup;
        this.defaultMargin = data.defaultMargin;
        this.markupMarginPercentType = data.markupMarginPercentType;
        this.costTypesMarkup = data.costTypesMarkup ?? [];
    }
    defaultMarkup?: number;
    defaultMargin?: number;
    markupMarginPercentType: number;
    costTypesMarkup: ICostTypesMarkup[];
}

export enum AccessMethod {
    None = 0,
    Email = 1,
    Manual = 2,
}

export enum JobStatus {
    Closed = 0,
    Open = 1,
    Presale = 3,
}

export class OwnerInfoFormValues {
    constructor(data: OwnerInfoEntity) {
        this.contact = new ContactFormValues(data);
        this.contacts = data.contacts;
        this.showJobPrice = data.showJobPrice;
        this.showBudget = data.showBudget;
        this.showPOsAndBills = data.showPOsAndBills;
        this.addWarrantyClaims = data.addWarrantyClaims;
        this.limitOwnerCalendar = getSelectedValue(data.limitOwnerCalendar);
        this.canSubmitCORequest = data.canSubmitCORequest;
        this.peekLockedSelections = data.peekLockedSelections;
        this.seePaymentsTab = data.seePaymentsTab;
        this.showCalendar = data.showCalendar;
        this.showCalendarPhases = data.showCalendarPhases;
        this.showScheduleItems = data.showScheduleItems;
        this.addedWarrantyClaimsEndDate =
            data.addedWarrantyClaimsEndDate !== undefined ? data.addedWarrantyClaimsEndDate : null;
        this.existingLinkedJobs = getSelectedValues(data.linkedJobs);
        this.newLinkedJobs = [];
        this.activationStatus = data.activationStatus;
        this.loginEnabled = data.loginEnabled;
        this.accessMethod = data.accessMethod;
        this.userName = data.userName;
        this.paymentOptions = { ...data.paymentOptionsSetup };
        this.showProjectManagerPhoneNumbersToOwner = data.showProjectManagerPhoneNumbersToOwner;
        this.budgetDisplayPreference = getSelectedValues(
            data.budgetDisplayPreference.filter((x) => !x.extraData?.disabled)
        );
        this.jobCostingBudgetDisplayPreference = getSelectedValues(
            data.jobCostingBudgetDisplayPreference.filter((x) => !x.extraData?.disabled)
        );
        this.showJobCostingBudgetToOwner = data.showJobCostingBudgetToOwner;
    }
    contact: ContactFormValues;
    contacts: ContactEntity[];
    showJobPrice: boolean;
    showBudget: boolean;
    showPOsAndBills: boolean;
    addWarrantyClaims: boolean;
    limitOwnerCalendar: number;
    canSubmitCORequest: boolean;
    peekLockedSelections: boolean;
    seePaymentsTab: boolean;
    showCalendar: boolean;
    showCalendarPhases: boolean;
    showScheduleItems: boolean;
    addedWarrantyClaimsEndDate: moment.Moment | null;
    existingLinkedJobs: number[];
    newLinkedJobs: number[];
    activationStatus: number;
    loginEnabled: boolean;
    accessMethod: number;
    resetOwnerPassword: boolean;
    userName?: string;
    password: string;
    resendEmailInvite: boolean;
    paymentOptions: IOnlinePaymentLimitsFormValues;
    showProjectManagerPhoneNumbersToOwner: boolean;
    budgetDisplayPreference: number[];
    jobCostingBudgetDisplayPreference: number[];
    showJobCostingBudgetToOwner: boolean;
}

export class OwnerInfoSaveRequest {
    constructor(
        entity: OwnerInfoEntity,
        values: OwnerInfoFormValues,
        notificationPreferences: INotificationPreferenceRow[]
    ) {
        this.contact = values.contact;
        this.contacts = values.contacts?.map((contact) => contact.id) ?? [];
        this.showJobPrice = values.showJobPrice;
        this.showBudget = values.showBudget;
        this.showPOsAndBills = values.showPOsAndBills;
        this.addWarrantyClaims = values.addWarrantyClaims;
        this.limitOwnerCalendar = values.limitOwnerCalendar;
        this.canSubmitCORequest = values.canSubmitCORequest;
        this.peekLockedSelections = values.peekLockedSelections;
        this.seePaymentsTab = values.seePaymentsTab;
        this.showCalendar = values.showCalendar;
        this.showPhases = values.showCalendarPhases;
        this.showScheduleItems = values.showScheduleItems;
        this.addedWarrantyClaimsEndDate = values.addedWarrantyClaimsEndDate;
        this.linkedJobs = values.existingLinkedJobs.concat(values.newLinkedJobs);
        this.allowCreditCards = values.paymentOptions.allowCreditCards;
        this.minCreditCard = values.paymentOptions.minCreditCard;
        this.maxCreditCard = values.paymentOptions.maxCreditCard;
        this.allowChecks = values.paymentOptions.allowChecks;
        this.minCheck = values.paymentOptions.minCheck;
        this.maxCheck = values.paymentOptions.maxCheck;
        this.allowPartialPayments = values.paymentOptions.allowPartialPayments;
        this.activationStatus = values.activationStatus;
        this.loginEnabled = values.loginEnabled;
        this.accessMethod = values.accessMethod;
        this.resetOwnerPassword = values.resetOwnerPassword;
        this.userName = values.userName;
        this.password = values.password;
        this.resendEmailInvite = values.resendEmailInvite;
        this.showProjectManagerPhoneNumbersToOwner = values.showProjectManagerPhoneNumbersToOwner;
        this.notificationPreferences = notificationPreferences
            .filter((np) => !np.isCategoryRow && !np.isGroupRow)
            .map((row) => {
                const pref: INotificationPreference = {
                    emailEnabled: row.emailEnabled!,
                    textEnabled: row.textEnabled!,
                    pushEnabled: row.pushEnabled,
                    allUsersEnabled: row.allUsersEnabled,
                    name: row.name,
                    type: row.type!,
                };
                return pref;
            });
        this.budgetDisplayPreference = values.budgetDisplayPreference;
        this.jobCostingBudgetDisplayPreference = values.jobCostingBudgetDisplayPreference;
        this.showJobCostingBudgetToOwner = values.showJobCostingBudgetToOwner;
    }
    contact: ContactFormValues | null;
    contacts: number[];
    showJobPrice: boolean;
    showBudget: boolean;
    showPOsAndBills: boolean;
    addWarrantyClaims: boolean;
    limitOwnerCalendar: number;
    canSubmitCORequest: boolean;
    peekLockedSelections: boolean;
    seePaymentsTab: boolean;
    showCalendar: boolean;
    showPhases: boolean;
    showScheduleItems: boolean;
    addedWarrantyClaimsEndDate: moment.Moment | null;
    linkedJobs: number[];
    allowPartialPayments: boolean;
    allowCreditCards: boolean;
    minCreditCard: number;
    maxCreditCard: number | null;
    allowChecks: boolean;
    minCheck: number;
    maxCheck: number | null;
    activationStatus: number;
    loginEnabled: boolean;
    accessMethod: number;
    resetOwnerPassword: boolean;
    userName?: string;
    password: string;
    resendEmailInvite: boolean;
    notificationPreferences: INotificationPreference[];
    showProjectManagerPhoneNumbersToOwner: boolean;
    budgetDisplayPreference: number[];
    jobCostingBudgetDisplayPreference: number[];
    showJobCostingBudgetToOwner: boolean;
}

export class JobViewingAccessSaveRequest {
    constructor(values: ViewingAccessFormValues) {
        this.users = values.users
            .filter((user) => {
                return user.hasAccess;
            })
            .map((user) => user.accessorId);
        this.subs = values.subs
            .filter((sub) => {
                return sub.hasAccess;
            })
            .map((sub) => sub.accessorId);
        this.userNotifications = values.users
            .filter((user) => user.hasNotificationAccess)
            .map((user) => user.accessorId);
    }
    users: number[];
    subs: number[];
    userNotifications: number[];
}

export class ContactFormValues {
    constructor(data: OwnerInfoEntity) {
        this.contactSelector = getSelectedValue(data.contactSelector);
        this.profilePicture = data.profilePicture;
    }
    profilePicture?: string;
    contactSelector: number;
}

export class JobEntity implements IBaseEntity, IHasCustomFieldContainer {
    constructor(data: any) {
        this.builderId = data.builderId;
        this.globalUserId = data.globalUserId;
        this.hasAdminPermissions = data.hasAdminPermissions;
        this.taxGroups = data.taxGroups
            ? mapBTServiceDropdownToSelectItem<TaxGroupServiceListItemExtraData>(data.taxGroups)[0]
                  ?.children ?? []
            : [];
        this.jobInfo = new JobInfoEntity(data.jobInfo, data.lead);
        this.ownerInfo = new OwnerInfoEntity(data);
        if (data.contact) {
            this.contact = new ContactEntity(data.contact);
        }
        if (data.viewingAccess) {
            this.viewingAccess = new ViewingAccessEntity(data.viewingAccess);
            this.viewingAccessSubPendingPaymentsMap = new Map(
                data.viewingAccess.subs.value.map((s: any) => [
                    s.id,
                    s.extraData?.pendingPaymentsCount ?? 0,
                ])
            );
        }
        if (data.jobId) {
            this.jobId = data.jobId;
        }

        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.canViewPrice = data.canViewPrice;
        this.canViewSchedule = data.canViewSchedule;
        this.canViewTimeClock = data.canViewTimeClock;
        this.canViewSelections = data.canViewSelections;
        this.canViewWarranty = data.canViewWarranty;
        this.isMerchantSetup = data.isMerchantSetup;
        this.canRemoveAllowances = data.canRemoveAllowances;
        this.canViewAccounting = data.canViewAccounting;
        this.showBudgetOptions = data.showBudgetOptions;
        this.canSaveSelect = data.canSaveSelect;
        this.canAddSubs = data.canAddSubs;
        this.canAddInternalUsers = data.canAddInternalUsers;
        this.allowJobsUnderCustomer = data.allowJobsUnderCustomer;
        this.linkingOptions =
            data.linkingOptions &&
            data.linkingOptions.map((item: any) => new BTSelectItem<IEntityExtraData>(item));
        this.canViewPurchaseOrders = data.canViewPurchaseOrders;
        this.canViewChangeOrders = data.canViewChangeOrders;
        this.canViewPayments = data.canViewPayments;
        this.canViewRiskInsurance = data.canViewRiskInsurance;
        this.riskInsuranceStatus = data.riskInsuranceStatus;
        this.customFields = data.jobInfo.customFields;
        this.customFieldAssociatedType = CustomFieldAssociatedType.JobsJobInfo;
        this.customFieldOptions = data.customFieldOptions;
        this.customFieldsCanConfigure = true;
        this.hasTimeClockItems = data.hasTimeClockItems;
        this.hasAllowances = data.hasAllowances;
        this.hasPurchaseOrderPriceViewing = data.hasPurchaseOrderPriceViewing;
        this.createdJobCountForBuilder = data.createdJobCountForBuilder;
        if (data.ownerInfo) {
            this.printOwnerInfo = new PrintInfoAPI(data.ownerInfo);
        }
        if (data.printHeader) {
            this.printHeader = new PrintHeaderAPI(data.printHeader);
        }
        if (data.customFieldsPrint) {
            this.customFieldsPrint = data.customFieldsPrint.map(
                (x: any) => new CustomFieldPrintItem(x)
            );
        }
        this.hasBudgetAccess = data.hasBudgetAccess;
        this.isBuilderConnectedToAccounting = data.isBuilderConnectedToAccounting;
    }

    id: number;
    builderId: number;
    globalUserId: number;
    jobId: number;
    jobInfo: JobInfoEntity;
    ownerInfo: OwnerInfoEntity;
    contact?: ContactEntity;
    viewingAccess?: ViewingAccessEntity;
    /**
     * Precomputed map which contains the number of pending payments (value) associated
     * with a sub (key)
     */
    viewingAccessSubPendingPaymentsMap?: Map<number, number>;
    jobPermissionWizardEntity?: JobPermissionWizardEntity;

    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canViewPrice: boolean;
    canViewSchedule: boolean;
    canViewTimeClock: boolean;
    canViewSelections: boolean;
    canViewWarranty: boolean;
    canRemoveAllowances: boolean;
    canViewAccounting: boolean;
    isMerchantSetup: boolean;
    showBudgetOptions: boolean;
    canSaveSelect: boolean;
    canAddSubs: boolean;
    canAddInternalUsers: boolean;
    canViewPurchaseOrders: boolean;
    canViewChangeOrders: boolean;
    canViewPayments: boolean;
    allowJobsUnderCustomer: boolean;
    linkingOptions: BTSelectItem<IEntityExtraData>[];
    canViewRiskInsurance: boolean;
    customFields: ICustomFieldResponse[];
    customFieldAssociatedType: CustomFieldAssociatedType;
    customFieldOptions: ICustomFieldOptionsList;
    customFieldsCanConfigure: boolean;

    hasTimeClockItems: boolean;
    hasAllowances: boolean;
    riskInsuranceStatus: RiskInsuranceStatus;
    accountingEntity: AccountingEntity | null;
    hasPurchaseOrderPriceViewing: boolean;
    createdJobCountForBuilder?: number;
    printOwnerInfo?: PrintInfoAPI;
    printHeader?: PrintHeaderAPI;
    customFieldsPrint: CustomFieldPrintItem[];
    taxGroups: BTSelectItem<TaxGroupServiceListItemExtraData>[];
    hasAdminPermissions: boolean;
    hasBudgetAccess: boolean;
    isBuilderConnectedToAccounting: boolean;
}

export class JobInfoEntity implements IHasAddress {
    constructor(data: any, leadInfo: any) {
        this.jobName = data.jobName.value;
        this.groupedProjectTypes = [];
        if (data.groupedProjectType) {
            /**
             * groupedProjectTypes can have a mix of grouped & ungrouped items, where ungrouped items appear in a nameless group.
             * Here, if a group has no name, we pull its children out in to the root of the list, so that they appear as ungrouped BTSelectItems.
             */
            data.groupedProjectType.value.forEach((group: any) => {
                if (!group.name) {
                    group.options.forEach((child: any) => {
                        this.groupedProjectTypes.push(new BTSelectItem(child));
                    });
                } else {
                    this.groupedProjectTypes.push(new BTSelectItem(group));
                }
            });
        }

        this.contractTypes = data.contractType.value.map((item: any) => new BTSelectItem(item));
        this.projectManagers = data.projectManagers.value.map(
            (item: any) => new BTSelectItem(item)
        );
        this.jobStatus = data.jobStatus.value.map((item: any) => new BTSelectItem(item));
        this.jobsitePrefix = data.jobsitePrefix ? data.jobsitePrefix.value : "";
        this.lotInfo = data.lotInfo.value;
        this.permitNumber = data.permitNumber.value;
        this.dateOpened =
            data.dateOpened && data.dateOpened.value ? moment(data.dateOpened.value) : null;
        this.openedByName = data.openedByName.value;
        this.openedById = data.openedById;
        if (leadInfo) {
            this.relatedLeadId = leadInfo.leadId;
            this.leadStatus = leadInfo.leadStatus;
            this.leadTitle = leadInfo.leadTitle;
        }
        this.contractPrice = data.contractPrice ? data.contractPrice.value : undefined;
        this.jobGroups = data.jobGroups
            ? alphabetize(data.jobGroups.value.map((item: any) => new BTSelectItem(item)))
            : [];
        this.address = new AddressEntity(data.address);
        this.workDays = data.workDays
            ? data.workDays.value.list.map((item: any) => new BTSelectItem(item))
            : [];
        if (data.totalArea) {
            this.totalArea = data.totalArea.value;
            this.totalAreaLabel = data.totalArea.title;
        }
        this.internalNotes = data.internalNotes ? data.internalNotes.value : "";
        this.subNotes = data.subNotes.value;
        this.projectedStartDate =
            data.projectedStartDate && data.projectedStartDate.value
                ? moment(data.projectedStartDate.value)
                : null;
        this.projectedCompletionDate =
            data.projectedCompletionDate && data.projectedCompletionDate.value
                ? moment(data.projectedCompletionDate.value)
                : null;
        this.actualStartDate =
            data.actualStartDate && data.actualStartDate.value
                ? moment(data.actualStartDate.value)
                : null;
        this.actualCompletionDate =
            data.actualCompletionDate && data.actualCompletionDate.value
                ? moment(data.actualCompletionDate.value)
                : null;
        this.syncActualStartEnd = data.syncActualStartEnd ?? false;
        this.scheduleStart = data.scheduleStart ? moment(data.scheduleStart) : undefined;
        this.scheduleEnd = data.scheduleEnd ? moment(data.scheduleEnd) : undefined;
        this.enabled = data.workDays ? data.workDays.value.enabled : false;
        this.jobColor = data.jobColor
            ? data.jobColor.value.map((item: any) => new BTSelectItem<JobColorExtraData>(item))
            : [];
        this.hasPurchaseOrderPriceViewing = data.hasPurchaseOrderPriceViewing ?? false;
        if (data.individualPOLimit) {
            this.individualPOLimit = new POLimit(data.individualPOLimit);
        }
        if (data.totalPOLimit) {
            this.totalPOLimit = new POLimit(data.totalPOLimit);
        }
        this.enableGeofencing = data.enableGeofencing?.value;
        this.includeTimeClockForBudget = data.includeTimeClockForBudget
            ? data.includeTimeClockForBudget.value
            : undefined;
        this.includeTimeClockForCosts = data.includeTimeClockForCosts
            ? data.includeTimeClockForCosts.value
            : undefined;
        this.includeAllowances = data.includeAllowances ? data.includeAllowances.value : undefined;
        this.updateRunningTotalForSelection = data.updateRunningTotalForSelection
            ? data.updateRunningTotalForSelection.value
            : undefined;
        this.isTemplate = data.isTemplate ? data.isTemplate.value : undefined;
        this.templateName = data.templateName ? data.templateName : undefined;
        this.builderLocation = data.builderLocation
            ? { lat: data.builderLocation.latitude, lng: data.builderLocation.longitude }
            : null;
        this.builderName = data.builderName && data.builderName.value;
        this.remainingDaysToDelete = data.remainingDaysToDelete ?? undefined;
        this.costItemResetMessage = data.costItemResetMessage ?? null;
        this.updateJobsitePricingWithCreditMemos = data.updateJobsitePricingWithCreditMemos;
        this.defaultTaxGroupId = data.defaultTaxGroupId ?? NoTaxId;
        if (data.additionalInfo) {
            this.additionalInfo = new JobInformation(data.additionalInfo);
        }
        if (data.isConnectedToTakeoffs !== undefined) {
            this.isConnectedToTakeoffs = data.isConnectedToTakeoffs ?? undefined;
        }
    }

    jobName: string;
    builderName: string;
    groupedProjectTypes: BTSelectItem[];
    contractTypes: BTSelectItem[];
    jobStatus: BTSelectItem[];
    projectManagers: BTSelectItem[];
    jobsitePrefix: string;
    lotInfo: string;
    permitNumber: string;
    dateOpened: moment.Moment | null;
    openedByName: string;
    openedById: number;
    relatedLeadId?: number;
    leadStatus?: LeadStatus;
    leadTitle?: string;
    contractPrice: number;
    address: AddressEntity;
    workDays: BTSelectItem[];
    totalArea: number | null;
    totalAreaLabel: string;
    jobGroups: BTSelectItem[];
    internalNotes: string;
    subNotes: string;
    projectedStartDate: moment.Moment | null;
    projectedCompletionDate: moment.Moment | null;
    actualStartDate: moment.Moment | null;
    actualCompletionDate: moment.Moment | null;
    syncActualStartEnd: boolean;
    scheduleStart?: moment.Moment;
    scheduleEnd?: moment.Moment;
    enabled: boolean;
    jobColor: BTSelectItem<JobColorExtraData>[];
    hasPurchaseOrderPriceViewing: boolean;
    individualPOLimit: POLimit;
    totalPOLimit: POLimit;
    enableGeofencing: boolean;
    includeTimeClockForBudget: boolean;
    includeTimeClockForCosts: boolean;
    includeAllowances: boolean;
    updateRunningTotalForSelection: boolean;
    isTemplate: boolean;
    templateName: string;
    showBudgetOptions: boolean;
    builderLocation: IMapPosition | null;
    remainingDaysToDelete?: number;
    costItemResetMessage: string | null;
    updateJobsitePricingWithCreditMemos: boolean;
    defaultTaxGroupId: number;
    additionalInfo?: JobInformation;
    isConnectedToTakeoffs: boolean;
}

class JobInformation {
    constructor(data: any) {
        this.financialSettings = data.financialSettings;
    }
    financialSettings: JobFinancialSettings;
}

class JobFinancialSettings {
    constructor(data: any) {
        this.hasSingleSelectCostTypes = data.hasSingleSelectCostTypes;
        this.markupMarginPercentType = data.markupMarginPercentType;
        this.defaultMargin = data.defaultMargin;
        this.defaultMarkup = data.defaultMarkup;
        this.costTypesMarkup = data.costTypesMarkup;
    }
    hasSingleSelectCostTypes: boolean;
    markupMarginPercentType: AdjustPercentTypes;
    defaultMarkup?: number;
    defaultMargin?: number;
    costTypesMarkup?: ICostTypesMarkup[];
}

export class JobInfoSaveRequest {
    constructor(
        values: JobInfoFormValues,
        entity: JobInfoEntity,
        customFields: ICustomFieldFormValues[]
    ) {
        this.jobName = values.jobName;
        this.groupedProjectType = values.groupedProjectType;
        this.jobStatus = values.jobStatus;
        this.projectManagers = values.projectManagers;
        this.jobGroups = values.jobGroups;
        this.jobsitePrefix = values.jobsitePrefix;
        this.lotInfo = values.lotInfo;
        this.permitNumber = values.permitNumber;
        this.contractPrice = values.contractPrice || 0;
        this.totalArea = values.totalArea;
        this.notifyProjectManager = values.notifyProjectManager;
        this.internalNotes = values.internalNotes;
        this.subNotes = values.subNotes;
        this.projectedStartDate = values.projectedStartDate;
        this.projectedCompletionDate = values.projectedCompletionDate;
        this.actualStartDate = values.actualStartDate;
        this.actualCompletionDate = values.actualCompletionDate;
        this.syncActualStartEnd = values.syncActualStartEnd;
        this.enabled = values.enabled;
        this.jobColor = getColorIdFromHex(values.jobColor);
        this.address = GetAddressServiceObject(entity, values.address);
        this.workDays = values.workDays;
        this.individualPOLimit =
            values.individualPOLimit !== undefined ? values.individualPOLimit : null;
        this.totalPOLimit = values.totalPOLimit !== undefined ? values.totalPOLimit : null;
        this.enableGeofencing = values.enableGeofencing;
        this.includeTimeClockForBudget = values.includeTimeClockForBudget;
        this.includeTimeClockForCosts = values.includeTimeClockForCosts;
        this.includeAllowances = values.includeAllowances;
        this.removeAllowancesWasConfirmed = false;
        this.updateRunningTotalForSelection = values.updateRunningTotalForSelection;
        this.isTemplate = values.isTemplate;
        this.templateName = values.templateName;
        this.location = values.address.location!;
        this.customFields = customFields;
        this.defaultTaxGroupId = values.defaultTaxGroupId;
        this.contractType = values.contractType;
        this.markupMarginPercentType = values.adjustMarkup?.markupMarginPercentType ?? 0;
        this.costTypesMarkup = values.adjustMarkup?.costTypesMarkup;
        this.defaultMarkup = values.adjustMarkup?.defaultMarkup;
        this.defaultMargin = values.adjustMarkup?.defaultMargin;
    }

    jobName: string;
    groupedProjectType: number;
    jobStatus: number;
    projectManagers: number[];
    jobGroups: number[];
    jobsitePrefix: string;
    lotInfo: string;
    permitNumber: string;
    contractPrice: number;
    workDays: number[];
    totalArea: number | null;
    notifyProjectManager: boolean;
    internalNotes: string | null;
    subNotes: string | null;
    projectedStartDate: moment.Moment | null;
    projectedCompletionDate: moment.Moment | null;
    actualStartDate: moment.Moment | null;
    syncActualStartEnd: boolean;
    actualCompletionDate: moment.Moment | null;
    enabled: boolean;
    individualPOLimit: number | undefined | null;
    totalPOLimit: number | undefined | null;
    enableGeofencing: boolean;
    includeTimeClockForBudget: boolean;
    includeTimeClockForCosts: boolean;
    includeAllowances: boolean;
    removeAllowancesWasConfirmed: boolean;
    updateRunningTotalForSelection: boolean;
    isTemplate: boolean;
    templateName: string;
    jobColor: number;
    address: AddressServiceObject;
    location: LocationEntity;
    customFields: ICustomFieldFormValues[];
    shouldHardUpdateCostItems?: boolean;
    defaultTaxGroupId: number;
    contractType?: number;
    markupMarginPercentType: number;
    defaultMarkup?: number;
    defaultMargin?: number;
    costTypesMarkup?: ICostTypesMarkup[];
}

export interface ICostTypesMarkup {
    costType: number;
    markup?: number;
    margin?: number;
}

export class JobSaveRequest {
    constructor(values: IJobFormValues, entity: JobEntity) {
        this.jobInfo = new JobInfoSaveRequest(values.jobInfo, entity.jobInfo, values.customFields);
        this.ownerInfo = new OwnerInfoSaveRequest(
            entity.ownerInfo,
            values.ownerInfo,
            values.notificationPreferences
        );
        this.viewingAccess = values.viewingAccess
            ? new JobViewingAccessSaveRequest(values.viewingAccess)
            : null;
        this.accountingJobsiteLinkingType = values.accountingEntityLinkingType;
        this.createNewAccountingJobOption = values.createNewAccountingEntityOption;
        this.includeOtherAccountingCostsInBudget = values.includeOtherAccountingCostsInBudget;
        this.accountingValues = new AccountingValues(
            values.accountingEntityLinkingType,
            values.createNewAccountingEntityOption
        );
    }

    jobInfo: JobInfoSaveRequest;
    ownerInfo: OwnerInfoSaveRequest;
    viewingAccess: JobViewingAccessSaveRequest | null;
    accountingJobsiteLinkingType: JobsiteLinkingTypes;
    createNewAccountingJobOption: LinkingAction;
    includeOtherAccountingCostsInBudget: boolean;
    accountingValues: AccountingValues;
}

export class AccountingValues {
    constructor(
        public accountingEntityLinkingType: JobsiteLinkingTypes,
        public createNewAccountingJobOption: LinkingAction
    ) {}
}

export class JobRunningTotal {
    constructor(data: any) {
        this.hasJobsitePermission = data.hasJobsitePermission;
        this.hasRunningTotalPermission = data.hasRunningTotalPermission;
        this.jobRunningTotal = data.jobRunningTotal;
        this.pricePerUnitArea = data.pricePerUnitArea;
        this.areaUnit = data.areaUnit;
    }

    hasJobsitePermission: boolean;
    hasRunningTotalPermission: boolean;
    jobRunningTotal: number | null;
    pricePerUnitArea: number | null;
    areaUnit: string | null;
}

export class OwnerInfoEntity {
    constructor(data: any) {
        this.contactSelector = data.contact?.contactSelector.value.map(
            (item: any) => new BTSelectItem(item)
        );
        this.contacts =
            data.ownerInfo.contacts?.map((contact: ContactEntity) => new ContactEntity(contact)) ??
            [];
        this.showJobPrice = data.ownerInfo.showJobPrice?.value;
        this.showBudget = data.ownerInfo.showBudget?.value;
        this.readonlyBudget = data.ownerInfo.showBudget?.validators[0].value;
        this.showPOsAndBills = data.ownerInfo.showPOsAndBills?.value;
        this.readonlyPOsAndBills = data.ownerInfo.showPOsAndBills?.validators[0].value;
        this.addWarrantyClaims = data.ownerInfo.addWarrantyClaims?.value;
        this.limitOwnerCalendar = data.ownerInfo.limitOwnerCalendar?.value.map(
            (item: any) => new BTSelectItem(item)
        );
        this.canSubmitCORequest = data.ownerInfo.canSubmitCORequest?.value;
        this.peekLockedSelections = data.ownerInfo.peekLockedSelections?.value;
        this.seePaymentsTab = data.ownerInfo.seePaymentsTab?.value;
        this.showCalendar = data.ownerInfo.showCalendar?.rightSelected;
        this.showCalendarPhases = data.ownerInfo.showPhases;
        this.showScheduleItems = data.ownerInfo.showScheduleItems;
        this.addedWarrantyClaimsEndDate = data.ownerInfo.addedWarrantyClaimsEndDate?.value
            ? moment(data.ownerInfo.addedWarrantyClaimsEndDate.value)
            : undefined;
        this.firstName = data.contact?.firstName ? data.contact.firstName.value : undefined;
        this.lastName = data.contact?.lastName ? data.contact.lastName.value : undefined;
        this.emails = data.ownerInfo.emails?.value.length
            ? data.ownerInfo.emails.value?.map((email: any) => email.emailAddress)
            : undefined;
        this.displayName = data.contact?.displayName ? data.contact.displayName.value : undefined;
        this.allowLoginAsOwner = data.ownerInfo.allowLoginAsOwner;
        this.linkedJobs =
            data.ownerInfo.linkedJobs?.map(
                (item: any) => new BTSelectItem<ILinkedJobDropdownExtraData>(item)
            ) ?? [];
        this.allowCreditCard = data.ownerInfo.allowCreditCards;
        this.allowChecks = data.ownerInfo.allowChecks;
        this.canAcceptChecks = data.ownerInfo.canAcceptChecks;
        this.areMerchantSettingsVisible = data.ownerInfo.areMerchantSettingsVisible;
        this.activationDate = moment(data.ownerInfo.activationDateRaw);
        this.loginEnabled = data.ownerInfo.loginEnabled ? data.ownerInfo.loginEnabled.value : false;
        this.accessMethod = data.ownerInfo.accessMethod
            ? data.ownerInfo.accessMethod.value.find((method: any) => method.selected === true)!.id
            : AccessMethod.None;
        this.userName = data.ownerInfo.userName ? data.ownerInfo.userName.value : undefined;
        this.activationStatus = data.ownerInfo.activationStatus;
        this.notificationPreferences = data.ownerInfo.notificationPreferences;
        this.showNotificationPreferences = data.ownerInfo.showNotificationPreferences?.value;
        this.profilePicture =
            data.contact && data.contact.profilePicture !== null
                ? data.contact.profilePicture.value
                : undefined;
        this.paymentOptionsSetup = new OnlinePaymentLimitsEntity(data.ownerInfo);
        this.showProjectManagerPhoneNumbersToOwner =
            data.ownerInfo.showProjectManagerPhoneNumbersToOwner?.value;
        this.budgetDisplayPreference =
            data.ownerInfo.budgetDisplayPreference?.map(
                (item: BTSelectItem) => new BTSelectItem(item)
            ) ?? [];
        this.jobCostingBudgetDisplayPreference =
            data.ownerInfo.jobCostingBudgetDisplayPreference?.map(
                (item: BTSelectItem) => new BTSelectItem(item)
            ) ?? [];
        this.showJobCostingBudgetToOwner = data.ownerInfo.showJobCostingBudgetToOwner?.value;
    }
    firstName?: string;
    lastName?: string;
    emails?: string[];
    displayName: string;
    contactSelector: BTSelectItem[];
    contacts: ContactEntity[];
    showJobPrice: boolean;
    showBudget: boolean;
    showPOsAndBills: boolean;
    readonlyBudget: boolean;
    readonlyPOsAndBills: boolean;
    addWarrantyClaims: boolean;
    limitOwnerCalendar: BTSelectItem[];
    canSubmitCORequest: boolean;
    peekLockedSelections: boolean;
    seePaymentsTab: boolean;
    showCalendar: boolean;
    showCalendarPhases: boolean;
    showScheduleItems: boolean;
    addedWarrantyClaimsEndDate: moment.Moment | undefined;
    allowLoginAsOwner: boolean;
    linkedJobs: BTSelectItem<ILinkedJobDropdownExtraData>[];
    allowCreditCard: boolean;
    allowChecks: boolean;
    canAcceptChecks: boolean;
    areMerchantSettingsVisible: boolean;
    activationStatus: ActivationStatus;
    activationDate: Moment;
    loginEnabled: boolean;
    accessMethod: AccessMethod;
    userName?: string;
    notificationPreferences: INotificationPreferenceData;
    showNotificationPreferences: boolean;
    profilePicture?: string;
    paymentOptionsSetup: OnlinePaymentLimitsEntity;
    showProjectManagerPhoneNumbersToOwner: boolean;
    budgetDisplayPreference: BTSelectItem<IBudgetDisplayPreferenceExtraData>[];
    jobCostingBudgetDisplayPreference: BTSelectItem<IBudgetDisplayPreferenceExtraData>[];
    showJobCostingBudgetToOwner: boolean;
}

export class JobPermissionWizardEntity {
    constructor(data: any) {
        this.autoAddedSubs = data.autoAddedSubs;
        this.subs = data.subs;
        this.users = data.users;
        this.conflicts = data.conflicts;
        this.calendarOnline = data.calendarOnline;
        this.canSetBaseline = data.canSetBaseline;
        this.skipAddUsers = data.skipAddUsers || false;
        this.baselineSet = data.baselineSet;
    }
    autoAddedSubs: number[];
    subs: number[];
    users: number[];
    conflicts: UserWithConflicts[];
    calendarOnline: boolean;
    canSetBaseline: boolean;
    skipAddUsers: boolean;
    baselineSet: boolean;
}

export interface ILinkedJobDropdownExtraData {
    jobName: string;
    ownerName: string;
    disabled: boolean;
    linkedIds: number[];
    status: ActivationStatus;
}

export interface IBudgetDisplayPreferenceExtraData {
    disabled: boolean;
}

export class ValueWithMinMax {
    constructor(data: any) {
        if (!data) {
            return;
        }

        this.max = data.max !== null ? data.max : undefined;
        this.min = data.min !== null ? data.min : undefined;
    }

    max: number;
    min: number;
}

export class POLimit extends ValueWithMinMax {
    constructor(data: any) {
        super(data);
        if (!data) {
            return;
        }

        this.value = data.value;
        this.placeholder = data.placeholder !== null ? data.placeholder : undefined;
    }

    value: number | null;
    placeholder: string;
}

export class JobDeleteResponse {}

export class JobSaveResponse {
    constructor(data: any) {
        this.id = data.id;
        this.failedFields = data.failedFields;
        this.addedInactiveSubs = data.addedInactiveSubs
            ? data.addedInactiveSubs.map((sub: any) => new BTSelectItem<SubsToInviteExtraData>(sub))
            : [];
        this.subInviteMessage = data.subInviteMessage ? data.subInviteMessage.value : undefined;
    }
    id: number;
    failedFields?: ServiceValidation[];
    addedInactiveSubs?: BTSelectItem<SubsToInviteExtraData>[];
    subInviteMessage?: string;
}

export interface IAddToJobRequest {
    jobId: number;
    subs: number[];
    logins: number[];
}

export class AddToJobResponse {
    subs: number[];
}

export interface IJobPermissionRequest {
    subIds?: number[];
    internalUserIds?: number[];
    jobIds: number[];
}

export class JobPermissionResponse {
    constructor(data: any) {
        this.headerMessage = data.headerMessage;
        this.headerDescription = data.headerDescription;
        this.permissions = data.permissions;
        this.skip = data.skip;
        this.titleText = data.titleText;
        this.subTitleText = data.subTitleText;
    }
    headerMessage: string;
    headerDescription: string;
    permissions: JobPermission[];
    skip: BTEntityAction;
    titleText: string;
    subTitleText: string;
}

export class JobPermissionWizardResponse {
    constructor(data: any) {
        this.autoAddedSubs = data.autoAddedSubs;
        this.subs = data.subs;
        this.users = data.users;
        this.conflicts = data.conflicts;
        this.calendarOnline = data.calendarOnline;
        this.canSetBaseline = data.canSetBaseline;
        this.skipAddUsers = data.skipAddUsers || false;
        this.baselineSet = data.baselineSet;
    }
    autoAddedSubs: number[];
    subs: number[];
    users: number[];
    conflicts: UserWithConflicts[];
    calendarOnline: boolean;
    canSetBaseline: boolean;
    skipAddUsers: boolean;
    baselineSet: boolean;
}

export class UpdateUserPermissionResponse {}
export class UpdateSubPermissionResponse {}

export class UsernameAndPasswordValidationResponse {
    constructor(data?: any) {
        this.usernameMessage = data.usernameMessage;
        this.passwordMessage = data.passwordMessage;
        this.hasErrors = data.hasErrors;
    }
    usernameMessage: string;
    passwordMessage: string;
    hasErrors: boolean;
}

export class UserListResponse {
    constructor(data?: any) {
        this.users = data.groupedUserList
            ? data.groupedUserList.value[0].options.map((item: any) => new BTSelectItem(item))
            : undefined;
    }
    users?: BTSelectItem[];
}

export class LinkableJobListResponse {
    constructor(data: any) {
        this.linkableJobs =
            data.map((item: any) => new BTSelectItem<ILinkedJobDropdownExtraData>(item)) ?? [];
    }
    linkableJobs: BTSelectItem<ILinkedJobDropdownExtraData>[];
}

export class JobPermission {
    value: boolean;
    header: boolean;
    id: number;
    name: string;
    formattedName: string;
    count: number;
    selected: boolean;
    showFields: boolean;
    type: string;
    enabled: boolean;
    extraData: object;
    nestedOptions: JobPermission[];
}

export class SubsToInviteExtraData {
    inviteStatus: string;
    email: string;
}
export class JobColorExtraData {
    hex: string;
}

export enum MappingStatus {
    Mapped = 1,
    NotMapped = 2,
    MappedManually = 3,
}

export const getPinTypeFromMappingStatus = (mappingStatus: MappingStatus | null) => {
    if (mappingStatus === MappingStatus.Mapped) {
        return PinTypes.Default;
    } else if (mappingStatus === MappingStatus.MappedManually) {
        return PinTypes.Edited;
    } else {
        return PinTypes.Inactive;
    }
};

export class ReassignConfirmationResponse {
    constructor(data: any) {
        this.dataRows = data.data.map((row: any) => {
            return {
                sourceType: row.sourceType,
                title: row.title,
                startDate: row["Start Date"],
                endDate: row["End Date"],
                deadLine: row.Deadline,
            };
        });

        this.pageNumber = data.page;
        this.pageSize = data.pageSize;
        this.total = data.records;
    }

    dataRows: ReassignItemRow[];
    pageNumber: number;
    pageSize: number;
    total: number;
}
export class MassReassignResponse {}

export class TransferItemDetailsResponse {
    constructor(data: any) {
        this.hasJobsiteAccess = data.hasJobsiteAccess;
        this.needsJobsiteAccess = data.needsJobsiteAccess;
        this.showNotifyCheckbox = data.showNotifyCheckbox;
    }
    hasJobsiteAccess: boolean;
    needsJobsiteAccess: boolean;
    showNotifyCheckbox: boolean;
}

export class TransferItemDetailsEntity {
    constructor(data: TransferItemDetailsResponse) {
        let grantViewingAccess = false;
        let grantViewingAccessVisible = true;
        let forceJobsiteAccessVisible = false;

        if (data.hasJobsiteAccess) {
            grantViewingAccessVisible = false;
        } else {
            if (data.needsJobsiteAccess) {
                grantViewingAccessVisible = false;
                forceJobsiteAccessVisible = true;
                grantViewingAccess = true;
            } else {
                grantViewingAccessVisible = true;
                grantViewingAccess = true;
            }
        }
        this.notify = data.showNotifyCheckbox;
        this.notifyVisible = data.showNotifyCheckbox;
        this.forceJobsiteAccessVisible = forceJobsiteAccessVisible;
        this.grantViewingAccessVisible = grantViewingAccessVisible;
        this.grantViewingAccess = grantViewingAccess;
    }

    notify: boolean;
    notifyVisible: boolean;
    forceJobsiteAccessVisible: boolean;
    grantViewingAccessVisible: boolean;
    grantViewingAccess: boolean;
}

export class ReassignItemRow {
    sourceType: string;
    title: string;
    startDate: string;
    endDate: string;
    deadLine: string;
}

export interface IReassignConfirmationRequest {
    firstRow: number;
    lastRow: number;
    previousId: number;
    pageNumber: number;
    pageSize: number;
}

export interface IBudgetPrefOptionTabProps {
    onBudgetPrefOptionChange: (
        e: CheckboxChangeEvent,
        budgetColumns: BudgetColumn[],
        jobCostingBudgetColumns: JobCostingBudgetColumn[]
    ) => void;
}
