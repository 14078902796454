import moment from "moment";

import { AccountingIntegrationType } from "types/enum";

export enum AccountingEntityTypes {
    TimeCard = 0,
    Bill = 1,
    ChangeOrder = 2,
    OwnerInvoice = 3,
    PurchaseOrderPayment = 4,
    CreditMemo = 5,
    VendorCredit = 6,
    Check = 7,
    Expense = 8,
    CreditCardCredit = 9,
    Deposit = 10,
}

export enum JobsiteLinkingTypes {
    Customer = 1,
    Job = 2,
}

export enum AccountingConnectionStatus {
    NotConnected = 0,
    PendingSync = 1,
    Connected = 2,
}

export enum SentToAccountingStatus {
    None = -1,
    NotSent = 0,
    Sent = 1,
    Pending = 2,
}

export enum TaxOptions {
    IgnoreTax = 0,
    TaxInclusive = 1,
    TaxExclusive = 2,
}

export const IntegrationTypeNames = new Map<AccountingIntegrationType, string>([
    [AccountingIntegrationType.None, "N/A"],
    [AccountingIntegrationType.QuickBooksDesktop, "QuickBooks"],
    [AccountingIntegrationType.QuickBooksOnline, "QuickBooks"],
    [AccountingIntegrationType.Xero, "Xero"],
]);

export const IntegrationPrimaryEntityName = new Map<AccountingIntegrationType, string>([
    [AccountingIntegrationType.None, "N/A"],
    [AccountingIntegrationType.QuickBooksDesktop, "Customer"],
    [AccountingIntegrationType.QuickBooksOnline, "Customer"],
    [AccountingIntegrationType.Xero, "Contact"],
]);

export const IntegrationSecondaryEntityName = new Map<AccountingIntegrationType, string>([
    [AccountingIntegrationType.None, ""],
    [AccountingIntegrationType.QuickBooksDesktop, "Customer & Job"],
    [AccountingIntegrationType.QuickBooksOnline, "Customer & Job"],
    [AccountingIntegrationType.Xero, ""],
]);

export const AccountingConnectionStatusText = new Map<AccountingConnectionStatus, string>([
    [AccountingConnectionStatus.NotConnected, "Not Connected"],
    [AccountingConnectionStatus.PendingSync, "Pending Sync"],
    [AccountingConnectionStatus.Connected, "Connected"],
]);

export const getJobEntityNames = (type: AccountingIntegrationType) => {
    let entityNames = `${IntegrationPrimaryEntityName.get(type)}`;
    if (
        IntegrationSecondaryEntityName.get(type) &&
        IntegrationSecondaryEntityName.get(type) !== ""
    ) {
        entityNames = `${entityNames} or ${IntegrationSecondaryEntityName.get(type)}`;
    }
    return entityNames;
};

export class AccountingValidationResponse {
    constructor(data: any) {
        this.formMessage = data.formMessage;
        this.isAccountsPayableAccount = data.isAccountsPayableAccount;
        this.isJobsiteLinked = data.isJobsiteLinked;
        this.isVendorLinked = data.isVendorLinked;
        this.allCostCodesValid = data.allCostCodesValid;
        this.accountValidationErrorMsg = data.accountValidationErrorMsg;
        this.accountingValidationErrorAlertMessage = data.accountingValidationErrorAlertMessage;
    }
    formMessage: string;
    isAccountsPayableAccount: boolean;
    isJobsiteLinked: boolean;
    isVendorLinked: boolean;
    allCostCodesValid: boolean;
    accountValidationErrorMsg?: string;
    accountingValidationErrorAlertMessage?: string;
}

export class EntityAccountingStatus {
    constructor(data: any) {
        this.accountingTitle = data.accountingTitle;
        this.accountingImage = data.accountingImage;
        this.sendToAccountingText = data.sendToAccountingText;
        this.syncStatus = data.syncStatus;
        this.syncStatusTooltipMessage = data.syncStatusTooltipMessage;
        this.lastSync = data.lastSync;
        this.canSendToAccounting = data.canSendToAccounting;
        this.accountsPayableTerm = data.accountsPayableTerm;
        this.accountsReceivableTerm = data.accountsReceivableTerm;
        this.flatFeeTerm = data.flatFeeTerm;
        this.creditMemoStatus = data.creditMemoStatus;
        this.invoiceStatus = data.invoiceStatus;
        this.showExpanded = data.creditMemoStatus || data.invoiceStatus || data.depositStatus;
        this.totalAmount = data.totalAmount;
        this.amountPaid = data.amountPaid;
        this.paidDate = data.paidDateValue ? moment(data.paidDateValue) : undefined;
        this.showButton = data.showButton;
        this.showCheckbox = data.showCheckbox;
        this.supportsJobsitenumberegrationTypes = data.supportsJobsitenumberegrationTypes;
        this.paidDateValue = data.paidDateValue;
        this.outstandingBalanceText = data.outstandingBalanceText;
        this.totalAmountText = data.totalAmountText;
        this.totalTaxText = data.totalTaxText;
        this.helpMessageText = data.helpMessageText;
        this.linkedByName = data.linkedByName;
        this.syncButtonText = data.syncButtonText;
        this.hasExternalAccountingLink = data.hasExternalAccountingLink;
        this.externalAccountingLinkText = data.externalAccountingLinkText;
        this.externalAccountingLink = data.externalAccountingLink;
        this.showDisabledInputs = data.showDisabledInputs;
        this.depositStatus = data.depositStatus;
        this.canReset = data.canReset;
        this.resetButtonText = data.resetButtonText;
        this.resetPopoverText = data.resetPopoverText;
    }

    accountingTitle: string;
    accountingImage: string;
    sendToAccountingText: string;
    syncStatus: SentToAccountingStatus;
    syncStatusTooltipMessage?: string;
    lastSync: string;
    canSendToAccounting: boolean;
    accountsPayableTerm: string;
    accountsReceivableTerm: string;
    flatFeeTerm: string;
    creditMemoStatus: string;
    invoiceStatus: string;
    totalAmount: number;
    amountPaid?: number;
    paidDate?: moment.Moment;
    showButton: boolean;
    showCheckbox: boolean;
    supportsJobsitenumberegrationTypes: boolean;
    paidDateValue?: moment.Moment;
    outstandingBalanceText: string;
    totalAmountText: string;
    totalTaxText: string;
    helpMessageText: string;
    showExpanded: boolean;
    linkedByName: string;
    syncButtonText: string;
    hasExternalAccountingLink: boolean;
    externalAccountingLinkText: string;
    externalAccountingLink: string;
    showDisabledInputs?: boolean;
    depositStatus: string;
    canReset?: boolean;
    resetButtonText?: string;
    resetPopoverText?: string;
}

export class TaxValidationAlert {
    constructor(data: any) {
        this.confirmationMessage = data.confirmationMessage;
        this.confirmButtonText = data.confirmButtonText;
        this.cancelButtonText = data.cancelButtonText;
    }

    confirmationMessage: string;
    confirmButtonText: string;
    cancelButtonText: string;
}

export class TaxValidationAlertResponse {
    constructor(data: any) {
        this.taxValidationAlert = data.taxValidationAlert
            ? new TaxValidationAlert(data.taxValidationAlert)
            : undefined;
    }

    taxValidationAlert?: TaxValidationAlert;
}
