/** Enums of all possible Onboarding-related actions/events */
export enum OnboardingAction {
    OnboardingContactProvided = 1,
    InternalUserCreated = 2,
    InternalUsersMassAdded = 3,
    ScheduleItemAdded = 4,
    ToDoAdded = 5,
    DailyLogAdded = 6,
}

/** Interface for Onboarding plugins */
export interface IOnboardingPlugin {
    actionCompleted?: (action: OnboardingAction, actionData: any) => void;
}

/**
 * Provided Onboarding Contact info
 */
export interface IOnboardingContactProvided {
    name: string;
    email: string;
    phone: string;
}
