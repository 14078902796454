import { InfoCircleOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { ErrorColor } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconInfoCircleErrorOutlined: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return (
        <InfoCircleOutlined
            {...props}
            type="exclamation-circle"
            style={{ ...props.style, color: ErrorColor }}
        />
    );
};
