import Checkbox, { CheckboxChangeEvent, CheckboxProps } from "antd/lib/checkbox";
import classNames from "classnames";
import { PureComponent } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import {
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";

import "./BTCheckbox.less";

export interface IBTCheckboxProps<FormValues> extends Omit<CheckboxProps, "id"> {
    id: KeyOfOrString<FormValues>;
    "data-testid": string;
    rounded?: boolean;
    readOnly?: boolean;
    hotkey?: THotkeyCommands | IHotkeyWithPopoverConfig;
    onHotkeyPressed?: (e: CheckboxChangeEvent) => void;
}

@track((props) => ({ element: "Checkbox", uniqueId: props["data-testid"] }))
export class BTCheckbox<FormValues> extends PureComponent<
    IBTCheckboxProps<FormValues> & ITrackingProp
> {
    private handleChange = (e: CheckboxChangeEvent) => {
        const { onChange, disabled } = this.props;
        if (disabled) {
            return;
        }

        if (onChange) {
            onChange(e);
        }
        this.props.tracking?.trackEvent({
            event: "ValueChange",
            extraInfo: {
                checked: e.target.checked,
            },
        });
    };

    // Use this if you want to pass a different function in from the normal checkbox
    private handleHotkeyPressed = (e: CheckboxChangeEvent) => {
        const { onHotkeyPressed, disabled } = this.props;
        if (disabled) {
            return;
        }

        if (onHotkeyPressed) {
            onHotkeyPressed(e);
        }
        this.props.tracking?.trackEvent({
            event: "ValueChange",
            extraInfo: {
                checked: e.target.checked,
            },
        });
    };

    render() {
        const {
            id,
            children,
            className,
            rounded,
            readOnly,
            disabled,
            tabIndex,
            hotkey,
            onHotkeyPressed,
            ...otherProps
        } = this.props;
        const checkClass = classNames(className, {
            rounded: rounded,
            readOnly: readOnly,
            disabled: disabled,
        });

        const checkbox = (
            // eslint-disable-next-line @buildertrend/enterprise-extras/no-deprecated-element
            <Checkbox
                {...otherProps}
                className={checkClass}
                id={id && id.toString()}
                onChange={this.handleChange}
                tabIndex={disabled ? -1 : tabIndex}
                aria-disabled={disabled}
            >
                {children}
            </Checkbox>
        );

        if (!hotkey) {
            return checkbox;
        }
        const checkboxChangeEvent: CheckboxChangeEvent = {
            target: {
                checked: true,
            },
            nativeEvent: new MouseEvent("submit"),
            stopPropagation: () => {},
            preventDefault: () => {},
        };
        return (
            <Hotkey
                {...getHotkeyProps(hotkey)}
                disabled={disabled}
                onCommand={() => {
                    if (onHotkeyPressed) {
                        this.handleHotkeyPressed(checkboxChangeEvent);
                    } else {
                        this.handleChange(checkboxChangeEvent);
                    }
                }}
            >
                {checkbox}
            </Hotkey>
        );
    }
}
