import { Component } from "react";

import { BTIconDocuments } from "commonComponents/btWrappers/BTIcon";
import {
    emptyStateIconSize,
    IEmptyStateEntityBannerProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    EmptyStateBanner,
    EmptyStateCallToActionButton,
} from "commonComponents/entity/emptyState/EmptyStateBanner";

interface ISavedViewEmptyStateBannerProps extends IEmptyStateEntityBannerProps {}

export class SavedViewsEmptyStateBanner extends Component<ISavedViewEmptyStateBannerProps> {
    private getSavedViewEmptyStateDescription() {
        return "Saved views let you configure what information is shown on this page.";
    }

    private getSavedViewEmptyStateTitle() {
        return "You have no saved views";
    }

    private getInitialWithDataState = () => {
        const { onCallToActionClick } = this.props;

        return (
            <>
                {this.getSavedViewEmptyStateDescription()}
                <EmptyStateCallToActionButton onCallToActionClick={onCallToActionClick}>
                    Add a new saved view
                </EmptyStateCallToActionButton>
            </>
        );
    };

    render() {
        const { actionBeingPerformed, isDismissed, hasListData } = this.props;

        if (isDismissed || hasListData) {
            return null;
        }

        return (
            <EmptyStateBanner
                title={this.getSavedViewEmptyStateTitle()}
                actionBeingPerformed={actionBeingPerformed}
                image={
                    <BTIconDocuments
                        size={emptyStateIconSize}
                        className="EmptyStateIcon"
                        data-testid="EmptyStateIcon"
                    />
                }
                isDismissable={false}
            >
                {this.getInitialWithDataState()}
            </EmptyStateBanner>
        );
    }
}
