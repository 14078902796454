import classNames from "classnames";

import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { IMenuItemClickRequest } from "commonComponents/utilities/MainNavigation/MainNavigation.types";

interface IQuickAddLinkProps {
    info: any;
    routeInfo: IMenuItemClickRequest;
    isFlyout: boolean;
    className?: string;
    onClick?: (key: string, isExternalLink?: boolean) => void;
}

export const QuickAddLink: React.FunctionComponent<IQuickAddLinkProps> = ({
    info,
    routeInfo,
    isFlyout,
    className,
    children,
    onClick,
}) => {
    // Render link to open from the nav react app
    return (
        <BTLinkRelative
            title={"New " + info.entity}
            to={info.quickAddReactRoute!(routeInfo)}
            onClick={() => onClick?.("New " + info.entity)}
            className={classNames(className, { Flyout: isFlyout })}
            isUnderline={false}
        >
            {children}
        </BTLinkRelative>
    );
};
