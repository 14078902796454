import { IGlobalValues } from "helpers/GlobalValues";

/**
 * Given a string representing the key value of a BT Admin flag, returns a boolean indicating whether that flag is enabled or disabled.
 * When BT Admin flag dictionary is undefined (e.g. when in Storybook), always returns true
 * @param flag key value of the BT Admin flag to check
 * @deprecated this should not be used on SPA components
 */
export function getGlobalValue<Key extends keyof IGlobalValues>(key: Key): IGlobalValues[Key] {
    return (globalThis as unknown as Window).btJScriptGlobals[key];
}
