import moment from "moment";
import { CSSProperties, PureComponent } from "react";

import { TimeLocale } from "helpers/AppProvider.types";

import { friendlyDefaultDisplayValue } from "utilities/helpers";

import {
    ITimezoneDetailsProps,
    withTimezoneDetails,
} from "commonComponents/helpers/TimeZone/TimeZoneDetails";

export interface IDateDisplayProps {
    value: moment.Moment;

    /** Shows the date (Month, Day, Year)
     * @default true
     */
    showDate?: boolean;

    /** Shows the time, will appear after date
     * @default false
     */
    showTime?: boolean;

    /** Shows the day of week (Mon, Tue, Wed...) at the beginning of the date
     * @default false
     */
    showDayOfWeek?: boolean;

    "data-testid"?: string;

    /** Use ONLY when static dates cannot be used at Storybook story level.
     *  Snapshots for the component will be ignored in Chromatic, if included.
     */
    "data-chromatic"?: "ignore";

    className?: string;
}

export interface IDateDisplayFormattingProps
    extends Pick<IDateDisplayProps, "showDate" | "showTime" | "showDayOfWeek"> {}

/**
 * Displays a formatted date as text, use whenever you want to show date for display only
 */
class DateDisplayInternal extends PureComponent<IDateDisplayProps & ITimezoneDetailsProps> {
    static defaultProps = {
        showDate: true,
        showTime: false,
        showDayOfWeek: false,
    };

    render() {
        const {
            value,
            showDate,
            showDayOfWeek,
            showTime,
            builderTimeLocale,
            className,
            "data-testid": dataTestId,
            "data-chromatic": dataChromatic,
        } = this.props;

        const dateStyles: CSSProperties = {
            whiteSpace: "nowrap",
        };

        if (value === null || value === undefined) {
            throw `<DateDisplay> was passed a value that was set to "${value}"`;
        }

        return (
            <span
                style={dateStyles}
                data-testid={dataTestId}
                data-chromatic={dataChromatic}
                className={className}
            >
                {formatDate(value, builderTimeLocale, {
                    showDate,
                    showTime,
                    showDayOfWeek,
                })}
            </span>
        );
    }
}

export const DateDisplay = withTimezoneDetails(DateDisplayInternal);

export function getDateDisplayOrRecentLabel(
    value?: moment.Moment | null,
    showTime: boolean = true
) {
    if (!value) {
        return friendlyDefaultDisplayValue;
    }
    const timeDisplay = showTime ? (
        <>
            {" - "}
            <DateDisplay showDate={false} showTime value={value} />
        </>
    ) : null;
    if (moment().isSame(value, "d")) {
        return (
            <>
                Today
                {timeDisplay}
            </>
        );
    } else if (moment().subtract(1, "d").isSame(value, "d")) {
        return (
            <>
                Yesterday
                {timeDisplay}
            </>
        );
    } else if (moment().add(1, "d").isSame(value, "d")) {
        return (
            <>
                Tomorrow
                {timeDisplay}
            </>
        );
    }
    return <DateDisplay value={value} />;
}

/**
 * Format the date as a string
 * @param value
 * @param builderTimeLocale pass the builders current time locale. This is stored in react context, grab with withTimezoneDetails
 * @param showDate Shows the date (Month, Day, Year)
 * @param showTime Shows the time, will appear after date
 * @param showDayOfWeek Shows the day of week (Mon, Tue, Wed...) at the beginning of the date
 */
export function formatDate(
    value: moment.Moment,
    builderTimeLocale: TimeLocale,
    options: IDateDisplayFormattingProps
): string {
    if (value === null || value === undefined) {
        throw `formatDate() was passed a value that was set to "${value}"`;
    }

    let valAsDate: Date;
    if (value instanceof Date) {
        valAsDate = value;
    } else {
        // convert moment.js object to Date
        valAsDate = value.toDate();
    }

    let date: string = "MMM D, YYYY";
    let time: string = "";
    let format: string = "";
    let prefix: string = "";
    let spacer: string = "";

    const dayOfWeek = "ddd";

    // 24 hour format uses 24hour:minute - 12 hour format uses 12hour:minute AM/PM
    if (builderTimeLocale.is24HourClock) {
        time = "HH:mm";
    } else {
        time = "h:mm A";
    }

    if (options.showDate) {
        if (options.showDayOfWeek) {
            format = date;
        } else {
            format = prefix + date;
        }
    }

    if (options.showTime) {
        if (format === "") {
            spacer = "";
        } else {
            spacer = ", ";
        }

        format = format + spacer + time;
    }

    if (options.showDayOfWeek) {
        if (format === "") {
            spacer = "";
        } else {
            spacer = ", ";
        }

        format = prefix + dayOfWeek + spacer + format;
    }

    return moment(valAsDate).format(format);
}
