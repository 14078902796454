import {
    IHotkey,
    IHotkeyWithPopoverConfig,
    THotkeyCommands,
} from "commonComponents/utilities/Hotkey/hotkey.types";

export const HotkeyCommands: Record<THotkeyCommands, IHotkey> = {
    save: { friendlyName: "Save", command: "ctrl+s, cmd+s" },
    print: { friendlyName: "Print", command: "ctrl+p, cmd+p" },
    saveAndClose: {
        friendlyName: "Save And Close",
        command: "ctrl+enter, cmd+enter",
    },
    new: { friendlyName: "New", command: "ctrl+i, cmd+i" },
    delete: { friendlyName: "Delete", command: "ctrl+d, cmd+d" },
    checkAll: {
        friendlyName: "Check All",
        command: "ctrl+a, cmd+a",
        supportedTags: [],
    },
    helpCenter: { friendlyName: "Help Center", command: "f1" },
    search: { friendlyName: "Search", command: "ctrl+space, cmd+space" },
    enter: { friendlyName: "Enter", command: "enter" },
    close: { friendlyName: "Close", command: "esc" },
    showHotkeys: { friendlyName: "Show Hotkeys", command: "ctrl+/, cmd+/" },
    navRight: {
        command: "right",
        friendlyName: "Navigate Right",
        supportedTags: [],
    },
    navLeft: {
        command: "left",
        friendlyName: "Navigate Left",
        supportedTags: [],
    },
    navUp: { command: "up", friendlyName: "Navigate Up" },
    navDown: { command: "down", friendlyName: "Navigate Down" },
    sendChat: {
        command: "enter",
        friendlyName: "Send Chat",
        supportedTags: [],
    },
};

export function getHotkeyProps(hotkeyCommand: THotkeyCommands | IHotkeyWithPopoverConfig) {
    if (typeof hotkeyCommand === "string") {
        return { ...HotkeyCommands[hotkeyCommand] };
    } else {
        const { hotkey, ...rest } = hotkeyCommand;
        return { ...HotkeyCommands[hotkey], ...rest };
    }
}
