import { Divider } from "antd";
import { FormikHandlers, InjectedFormikProps, withFormik } from "formik";
import moment from "moment";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { TabPermissions } from "types/enum";
import { SplitDateTime } from "types/splitDateTime";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTDatePicker } from "commonComponents/btWrappers/BTDatePicker/BTDatePicker";
import { BTForm, BTFormItem, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTTimePicker } from "commonComponents/btWrappers/BTTimePicker/BTTimePicker";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import JobViewingPermissionWizard from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard";
import { OpenedFromEntity } from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard.api.types";
import PermissionWizard from "commonComponents/entity/permissionWizard/PermissionWizard/PermissionWizard";
import { ScheduleConflictViews } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import ScheduleConflictReport from "commonComponents/entity/scheduleConflicts/ScheduleConflictReport/ScheduleConflictReport";
import ScheduleNotificationPrompt from "commonComponents/entity/scheduleNotifications/ScheduleNotificationPrompt";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

import { SchedulePostSaveSteps } from "entity/schedule/Schedule/Schedule.api.types";
import {
    ITemplateImportFormValues,
    TemplateImportCountsResponse,
    TemplateImportFormActions,
} from "entity/templateImport/TemplateImport/TemplateImport.api.types";
import { ISchedulePostSaveProps } from "entity/templateImport/TemplateImport/TemplateImport.types";

import "./TemplateImport.less";

export interface ITemplateImportProps extends RouteComponentProps {
    jobId: number;
    tabId: TabPermissions;
    templateId: number;
    templateList: BTSelectItem[];
    counts?: TemplateImportCountsResponse;
    hasExistingEstimates?: boolean;
    canImportEstimates: boolean;
    hasBidsAddPerm: boolean;
    hasPOsAddPerm: boolean;
    hasEstimatesAddPerm: boolean;
    actionBeingPerformed: TemplateImportFormActions;
    isPostImport: boolean;
    onChangeTemplate: (selectedId: number) => void;
    onStartImport: (values: ITemplateImportFormValues) => Promise<void>;
    modalConfig?: IModalConfiguration;
    canChangeTemplate?: boolean;
}

export class TemplateImportInternal extends Component<
    InjectedFormikProps<ITemplateImportProps & ISchedulePostSaveProps, ITemplateImportFormValues>
> {
    static defaultProps = {
        canChangeTemplate: true,
    };

    private handleCustomSubmit = async (
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>
    ): Promise<void> => {
        event.preventDefault();

        const { onStartImport, setSubmitting, handleSubmit, values } = this.props;
        handleSubmit(event);

        setSubmitting(true);
        const formErrors = await this.props.validateForm(this.props.values);
        if (Object.keys(formErrors).length === 0) {
            await onStartImport(values);
        }
        setSubmitting(false);
    };

    private handlePurchaseOrdersClicked = (values: ITemplateImportFormValues) => {
        values.linkedBills = !values.purchaseOrders;
    };

    render() {
        const {
            templateId,
            templateList,
            counts,
            hasExistingEstimates,
            onChangeTemplate,
            values,
            handleChange,
            actionBeingPerformed,
            setFieldValue,
            postSaveStep,
            canImportEstimates,
            hasBidsAddPerm,
            hasPOsAddPerm,
            hasEstimatesAddPerm,
            isPostImport,
        } = this.props;

        const loading = actionBeingPerformed !== undefined;

        return (
            <>
                {!isPostImport && (
                    <BTModalLayout
                        title="Import Data From Template"
                        footerContent={
                            <BTButton<TemplateImportFormActions>
                                id="save"
                                data-testid="save"
                                type="primary"
                                onClick={this.handleCustomSubmit}
                                actionBeingPerformed={actionBeingPerformed}
                                loadingAction="importStarted"
                            >
                                Import
                            </BTButton>
                        }
                        modalConfig={this.props.modalConfig}
                    >
                        <BTLayoutContent>
                            <FormikValidationSummary
                                errors={this.props.errors}
                                values={values}
                                showAfterSubmit={this.props.submitCount}
                                scheme={TemplateImportValidators}
                            />
                            <PageSection title="Import Options" className="TemplateImport">
                                <BTForm>
                                    <BTTitle bold level={3}>
                                        Source
                                    </BTTitle>
                                    <BTRow>
                                        <BTCol xs={24} sm={12}>
                                            <BTFormItemAutomatic
                                                id="templateId"
                                                required
                                                className="TemplateSelectFormItem"
                                            >
                                                <BTSelect
                                                    id="templateId"
                                                    data-testid="templateId"
                                                    value={templateId}
                                                    treeData={templateList}
                                                    onChange={(field, value) =>
                                                        onChangeTemplate(value)
                                                    }
                                                    onBlur={() => {}}
                                                    disabled={loading}
                                                    readOnly={!this.props.canChangeTemplate}
                                                />
                                            </BTFormItemAutomatic>
                                        </BTCol>
                                    </BTRow>

                                    <div className="ImportDescription">
                                        <BTTitle bold level={3}>
                                            Items to Copy
                                        </BTTitle>
                                    </div>

                                    <BTFormItem label="Project Management">
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="schedule"
                                            data-testid="schedule"
                                            checked={values.schedule}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.scheduleItems)}
                                        >
                                            Schedule {counts && <>({counts.scheduleItems})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {values.schedule && (
                                        <div className="TemplateImportDatePicker">
                                            <BTFormItemAutomatic<ITemplateImportFormValues>
                                                id="scheduleStartDate"
                                                required
                                            >
                                                <BTDatePicker<ITemplateImportFormValues>
                                                    id="scheduleStartDate"
                                                    data-testid="scheduleStartDate"
                                                    value={values.scheduleStartDate!}
                                                    onChange={setFieldValue}
                                                    disabled={loading}
                                                />
                                            </BTFormItemAutomatic>
                                        </div>
                                    )}
                                    <BTFormItem>
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="toDos"
                                            data-testid="toDos"
                                            checked={values.toDos}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.toDos)}
                                        >
                                            To-Do's {counts && <>({counts.toDos})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    <BTFormItem>
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="selections"
                                            data-testid="selections"
                                            checked={values.selections}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.selections)}
                                        >
                                            Selections {counts && <>({counts.selections})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {!values.schedule && values.selections && (
                                        <div className="TemplateImportDatePicker">
                                            <BTFormItemAutomatic<ITemplateImportFormValues> id="selectionsDeadline">
                                                <BTDatePicker<ITemplateImportFormValues>
                                                    id="selectionsDeadline"
                                                    data-testid="selectionsDeadline"
                                                    value={values.selectionsDeadline!}
                                                    onChange={setFieldValue}
                                                    disabled={loading}
                                                />
                                            </BTFormItemAutomatic>
                                        </div>
                                    )}
                                    {values.selections && (
                                        <div className="margin-left-lg">
                                            <BTFormItem>
                                                <BTCheckbox<ITemplateImportFormValues>
                                                    id="selectionsResetApproved"
                                                    data-testid="selectionsResetApproved"
                                                    checked={values.selectionsResetApproved}
                                                    onChange={handleChange}
                                                    disabled={
                                                        loading ||
                                                        (counts &&
                                                            !counts.selectionsApprovedChoices)
                                                    }
                                                >
                                                    Reset approved choices to pending{" "}
                                                    {counts && (
                                                        <>({counts.selectionsApprovedChoices})</>
                                                    )}
                                                </BTCheckbox>
                                            </BTFormItem>
                                            <div className="margin-left-lg">
                                                <BTFormItem>
                                                    <BTCheckbox<ITemplateImportFormValues>
                                                        id="selectionsApplyPending"
                                                        data-testid="selectionsApplyPending"
                                                        checked={values.selectionsApplyPending}
                                                        onChange={handleChange}
                                                        disabled={
                                                            loading ||
                                                            (counts &&
                                                                !counts.selectionsLineItemUpdates)
                                                        }
                                                    >
                                                        Apply any pending line item updates{" "}
                                                        {counts && (
                                                            <>
                                                                ({counts.selectionsLineItemUpdates})
                                                            </>
                                                        )}
                                                    </BTCheckbox>
                                                </BTFormItem>
                                            </div>
                                        </div>
                                    )}
                                    <BTFormItem>
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="specifications"
                                            data-testid="specifications"
                                            checked={values.specifications}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.specifications)}
                                        >
                                            Specifications{" "}
                                            {counts && <>({counts.specifications})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    <Divider />

                                    <BTFormItem label="Files">
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="documentFolders"
                                            data-testid="documentFolders"
                                            checked={values.documentFolders}
                                            onChange={handleChange}
                                            disabled={
                                                loading || (counts && !counts.documentFolders)
                                            }
                                        >
                                            Document Folders{" "}
                                            {counts && <>({counts.documentFolders})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {values.documentFolders && (
                                        <div className="margin-left-lg">
                                            <BTFormItem>
                                                <BTCheckbox<ITemplateImportFormValues>
                                                    id="documentFiles"
                                                    data-testid="documentFiles"
                                                    checked={values.documentFiles}
                                                    onChange={handleChange}
                                                    disabled={
                                                        loading || (counts && !counts.documentFiles)
                                                    }
                                                >
                                                    Include files{" "}
                                                    {counts && <>({counts.documentFiles})</>}
                                                </BTCheckbox>
                                            </BTFormItem>
                                        </div>
                                    )}
                                    <BTFormItem>
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="photoFolders"
                                            data-testid="photoFolders"
                                            checked={values.photoFolders}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.photoFolders)}
                                        >
                                            Photo Folders {counts && <>({counts.photoFolders})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    <BTFormItem>
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="videoFolders"
                                            data-testid="videoFolders"
                                            checked={values.videoFolders}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.videoFolders)}
                                        >
                                            Video Folders {counts && <>({counts.videoFolders})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {values.videoFolders && (
                                        <div className="margin-left-lg">
                                            <BTFormItem>
                                                <BTCheckbox<ITemplateImportFormValues>
                                                    id="videoFiles"
                                                    data-testid="videoFiles"
                                                    checked={values.videoFiles}
                                                    onChange={handleChange}
                                                    disabled={
                                                        loading || (counts && !counts.videoFiles)
                                                    }
                                                >
                                                    Include files{" "}
                                                    {counts && <>({counts.videoFiles})</>}
                                                </BTCheckbox>
                                            </BTFormItem>
                                        </div>
                                    )}

                                    <Divider />

                                    <BTFormItem label="Messaging">
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="surveys"
                                            data-testid="surveys"
                                            checked={values.surveys}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.surveys)}
                                        >
                                            Surveys {counts && <>({counts.surveys})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {!values.schedule && values.surveys && (
                                        <div className="TemplateImportDatePicker">
                                            <BTFormItemAutomatic<ITemplateImportFormValues> id="surveyReleaseDate">
                                                <BTDatePicker<ITemplateImportFormValues>
                                                    id="surveyReleaseDate"
                                                    data-testid="surveyReleaseDate"
                                                    value={values.surveyReleaseDate!}
                                                    onChange={setFieldValue}
                                                    disabled={loading}
                                                />
                                            </BTFormItemAutomatic>
                                        </div>
                                    )}

                                    <Divider />

                                    <BTFormItem label="Financial">
                                        <BTCheckbox<ITemplateImportFormValues>
                                            id="ownerInvoices"
                                            data-testid="ownerInvoices"
                                            checked={values.ownerInvoices}
                                            onChange={handleChange}
                                            disabled={loading || (counts && !counts.ownerInvoices)}
                                        >
                                            Owner Invoices {counts && <>({counts.ownerInvoices})</>}
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {!values.schedule && values.ownerInvoices && (
                                        <div className="TemplateImportDatePicker">
                                            <BTFormItemAutomatic id="ownerInvoiceDeadline.date">
                                                <BTDatePicker
                                                    id="ownerInvoiceDeadline.date"
                                                    data-testid="ownerInvoiceDeadline"
                                                    value={values.ownerInvoiceDeadline?.date!}
                                                    onChange={setFieldValue}
                                                    disabledDate={pastDateValidator}
                                                    disabled={loading}
                                                />
                                            </BTFormItemAutomatic>
                                            <BTFormItemAutomatic id="ownerInvoiceDeadline.time">
                                                <BTTimePicker
                                                    id="ownerInvoiceDeadline.time"
                                                    data-testid="ownerInvoiceDeadline"
                                                    onChange={setFieldValue}
                                                    value={values.ownerInvoiceDeadline?.time!}
                                                    disabled={loading}
                                                />
                                            </BTFormItemAutomatic>
                                        </div>
                                    )}
                                    {hasBidsAddPerm && (
                                        <>
                                            <BTFormItem>
                                                <BTCheckbox<ITemplateImportFormValues>
                                                    id="bidPackages"
                                                    data-testid="bidPackages"
                                                    checked={values.bidPackages}
                                                    onChange={handleChange}
                                                    disabled={
                                                        loading || (counts && !counts.bidPackages)
                                                    }
                                                >
                                                    Bid Packages{" "}
                                                    {counts && <>({counts.bidPackages})</>}
                                                </BTCheckbox>
                                            </BTFormItem>
                                            {!values.schedule && values.bidPackages && (
                                                <div className="TemplateImportDatePicker">
                                                    <BTFormItemAutomatic id="bidPackageDeadline.date">
                                                        <BTDatePicker
                                                            id="bidPackageDeadline.date"
                                                            data-testid="bidPackageDeadline"
                                                            value={values.bidPackageDeadline?.date!}
                                                            onChange={setFieldValue}
                                                            disabledDate={pastDateValidator}
                                                            disabled={loading}
                                                        />
                                                    </BTFormItemAutomatic>
                                                    <BTFormItemAutomatic id="bidPackageDeadline.time">
                                                        <BTTimePicker
                                                            id="bidPackageDeadline.time"
                                                            data-testid="bidPackageDeadline"
                                                            onChange={setFieldValue}
                                                            value={values.bidPackageDeadline?.time!}
                                                            disabled={loading}
                                                        />
                                                    </BTFormItemAutomatic>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {hasEstimatesAddPerm && (
                                        <EstimateCopyOptions
                                            values={values}
                                            counts={counts}
                                            canImportEstimates={canImportEstimates}
                                            hasExistingEstimates={hasExistingEstimates}
                                            loading={loading}
                                            onChange={handleChange}
                                        />
                                    )}
                                    {hasPOsAddPerm && (
                                        <>
                                            <BTFormItem data-testid="includePurchaseOrders">
                                                <BTCheckbox<ITemplateImportFormValues>
                                                    id="purchaseOrders"
                                                    data-testid="purchaseOrders"
                                                    checked={values.purchaseOrders}
                                                    onChange={handleChange}
                                                    disabled={
                                                        loading ||
                                                        (counts && !counts.purchaseOrders)
                                                    }
                                                    onClick={() =>
                                                        this.handlePurchaseOrdersClicked(values)
                                                    }
                                                >
                                                    Purchase Orders
                                                    {counts && <>({counts.purchaseOrders})</>}
                                                </BTCheckbox>
                                                {values.purchaseOrders && (
                                                    <div className="margin-left-lg">
                                                        <BTFormItem>
                                                            <BTCheckbox<ITemplateImportFormValues>
                                                                id="linkedBills"
                                                                data-testid="linkedBills"
                                                                checked={values.linkedBills}
                                                                onChange={handleChange}
                                                                disabled={
                                                                    loading ||
                                                                    (counts && !counts.linkedBills)
                                                                }
                                                            >
                                                                Linked Bills{" "}
                                                                {counts && (
                                                                    <>({counts.linkedBills})</>
                                                                )}
                                                            </BTCheckbox>
                                                        </BTFormItem>
                                                    </div>
                                                )}
                                            </BTFormItem>

                                            {!values.schedule && values.purchaseOrders && (
                                                <div className="TemplateImportDatePicker">
                                                    <BTFormItemAutomatic<ITemplateImportFormValues> id="purchaseOrdersCompletion">
                                                        <BTDatePicker<ITemplateImportFormValues>
                                                            id="purchaseOrdersCompletion"
                                                            data-testid="purchaseOrdersCompletion"
                                                            value={values.purchaseOrdersCompletion!}
                                                            onChange={setFieldValue}
                                                            disabled={loading}
                                                        />
                                                    </BTFormItemAutomatic>
                                                </div>
                                            )}
                                            {values.purchaseOrders && (
                                                <div className="margin-left-lg">
                                                    <BTFormItem>
                                                        <BTCheckbox<ITemplateImportFormValues>
                                                            id="purchaseOrdersFiles"
                                                            data-testid="purchaseOrdersFiles"
                                                            checked={values.purchaseOrdersFiles}
                                                            onChange={handleChange}
                                                            disabled={
                                                                loading ||
                                                                (counts &&
                                                                    !counts.purchaseOrdersFiles)
                                                            }
                                                        >
                                                            Include PO attachments{" "}
                                                            {counts && (
                                                                <>({counts.purchaseOrdersFiles})</>
                                                            )}
                                                        </BTCheckbox>
                                                    </BTFormItem>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {hasPOsAddPerm && (
                                        <BTFormItem>
                                            <BTCheckbox<ITemplateImportFormValues>
                                                id="bills"
                                                data-testid="bills"
                                                checked={values.bills}
                                                onChange={handleChange}
                                                disabled={loading || (counts && !counts.bills)}
                                            >
                                                Bills {counts && <>({counts.bills})</>}
                                            </BTCheckbox>
                                        </BTFormItem>
                                    )}
                                </BTForm>
                            </PageSection>
                        </BTLayoutContent>
                    </BTModalLayout>
                )}
                {postSaveStep === SchedulePostSaveSteps.Conflicts && (
                    <ScheduleConflictReport
                        startingView={ScheduleConflictViews.Job}
                        startingId={this.props.jobId}
                        startingData={this.props.conflictData!}
                        handleContinue={this.props.onConflictsSkipped}
                        modalConfig={{
                            parentRoute: this.props.match.url,
                            beforeClose: this.props.onConflictsSkipped,
                        }}
                        {...this.props}
                    />
                )}
                {postSaveStep === SchedulePostSaveSteps.AddUsersToJob && (
                    <JobViewingPermissionWizard
                        jobId={this.props.jobId}
                        subs={this.props.subsToAdd}
                        internalUsers={this.props.usersToAdd}
                        openedFromEntity={OpenedFromEntity.TemplateImport}
                        canSelectUsers={true}
                        modalConfig={{
                            parentRoute: this.props.match.url,
                            beforeClose: () => this.props.onUsersAddedToJob([], []),
                        }}
                        onClose={() => this.props.onUsersAddedToJob([], [])}
                        afterSubmit={this.props.onUsersAddedToJob}
                        match={this.props.match}
                        history={this.props.history}
                        location={this.props.location}
                    />
                )}
                {postSaveStep === SchedulePostSaveSteps.Permissions && (
                    <PermissionWizard
                        jobIds={[this.props.jobId]}
                        onComplete={this.props.onUserPermissionsAdded}
                        onCancel={this.props.onUserPermissionsAdded}
                        subIds={this.props.subIdsToAddPermissions}
                        internalUserIds={this.props.userIdsToAddPermissions}
                        modalConfig={{
                            parentRoute: this.props.match.url,
                            beforeClose: this.props.onUserPermissionsAdded,
                        }}
                    />
                )}
                {postSaveStep === SchedulePostSaveSteps.Notifications && (
                    <ScheduleNotificationPrompt
                        schedules={this.props.schedulesToNotify}
                        onSend={this.props.onNotificationsSent}
                        onClose={this.props.onNotificationsSent}
                    />
                )}
            </>
        );
    }
}

const pastDateValidator = (current: moment.Moment | null) => {
    return current !== null && current.isBefore(moment().startOf("d"));
};

const scheduleDateValidator = yup.mixed().label("New Start Date").validMomentDate("date");

const bidDeadlineValidator = yup.mixed().label("Date");
const invoiceDeadlineValidator = yup.mixed().label("Date");

const TemplateImportValidators = yup.object().shape<ITemplateImportFormValues>({
    templateId: yup.number().label("Source Template").requiredDropdown(),
    schedule: yup.boolean(),
    toDos: yup.boolean(),
    selections: yup.boolean(),
    selectionsResetApproved: yup.boolean(),
    selectionsApplyPending: yup.boolean(),
    specifications: yup.boolean(),
    documentFolders: yup.boolean(),
    documentFiles: yup.boolean(),
    photoFolders: yup.boolean(),
    videoFolders: yup.boolean(),
    videoFiles: yup.boolean(),
    surveys: yup.boolean(),
    bidPackages: yup.boolean(),
    estimates: yup.boolean(),
    estimatesRemoveExisting: yup.boolean(),
    estimatesKeepIntroClosingText: yup.boolean(),
    bills: yup.boolean(),
    linkedBills: yup.boolean(),
    purchaseOrders: yup.boolean(),
    purchaseOrdersFiles: yup.boolean(),
    ownerInvoices: yup.boolean(),
    scheduleStartDate: scheduleDateValidator.when("schedule", {
        is: true,
        then: scheduleDateValidator.required(),
    }),
    selectionsDeadline: yup.mixed().label("New Deadline").validMomentDate("date"),
    surveyReleaseDate: yup.mixed().label("New Release Date").validMomentDate("date"),
    bidPackageDeadline: yup.object().shape<SplitDateTime>({
        date: bidDeadlineValidator.when("time", {
            is: (time) => time,
            then: bidDeadlineValidator.required("Deadline Date required."),
        }),
        time: yup.mixed().label("Time"),
    }),
    purchaseOrdersCompletion: yup
        .mixed()
        .label("Estimated Completion for Purchase Orders")
        .validMomentDate("date"),
    ownerInvoiceDeadline: yup.object().shape<SplitDateTime>({
        date: invoiceDeadlineValidator.when("time", {
            is: (time) => time,
            then: invoiceDeadlineValidator.required("Invoice Date required."),
        }),
        time: yup.mixed().label("Time"),
    }),
});

export const TemplateImportPresentational = withFormik<
    ITemplateImportProps & ISchedulePostSaveProps,
    ITemplateImportFormValues
>({
    mapPropsToValues: (props: ITemplateImportProps) => {
        const getInitialValue = (tab: TabPermissions, count: number | undefined) => {
            return props.tabId === tab && count !== 0;
        };

        return {
            templateId: props.templateId,
            schedule: getInitialValue(TabPermissions.Calendar, props.counts?.scheduleItems),
            toDos: getInitialValue(TabPermissions.ToDos, props.counts?.toDos),
            selections: getInitialValue(TabPermissions.Selections, props.counts?.selections),
            selectionsResetApproved: false,
            selectionsApplyPending: false,
            specifications: getInitialValue(
                TabPermissions.Specifications,
                props.counts?.specifications
            ),
            documentFolders: getInitialValue(
                TabPermissions.Documents,
                props.counts?.documentFolders
            ),
            documentFiles: false,
            photoFolders: getInitialValue(TabPermissions.Photos, props.counts?.photoFolders),
            videoFolders: getInitialValue(TabPermissions.Videos, props.counts?.videoFolders),
            videoFiles: false,
            surveys: getInitialValue(TabPermissions.Surveys, props.counts?.surveys),
            bidPackages: getInitialValue(TabPermissions.Bidding, props.counts?.bidPackages),
            estimates:
                props.tabId === TabPermissions.EstimateGeneralItems && props.canImportEstimates,
            estimatesRemoveExisting: false,
            estimatesKeepIntroClosingText: false,
            bills: getInitialValue(TabPermissions.PurchaseOrders, props.counts?.bills),
            linkedBills: getInitialValue(TabPermissions.PurchaseOrders, props.counts?.linkedBills),
            purchaseOrders: getInitialValue(
                TabPermissions.PurchaseOrders,
                props.counts?.purchaseOrders
            ),
            purchaseOrdersFiles: false,
            ownerInvoices: getInitialValue(
                TabPermissions.OwnerInvoices,
                props.counts?.ownerInvoices
            ),
            scheduleStartDate: undefined,
            selectionsDeadline: undefined,
            surveyReleaseDate: undefined,
            bidPackageDeadline: new SplitDateTime(),
            purchaseOrdersCompletion: undefined,
            ownerInvoiceDeadline: new SplitDateTime(),
        };
    },
    validationSchema: () => TemplateImportValidators,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: () => {},
})(TemplateImportInternal);

interface IEstimateCopyOptionsProps {
    values: ITemplateImportFormValues;
    counts?: TemplateImportCountsResponse;
    canImportEstimates: boolean;
    hasExistingEstimates?: boolean;
    loading: boolean;
    onChange: FormikHandlers["handleChange"];
}

const EstimateCopyOptions: React.FC<IEstimateCopyOptionsProps> = ({
    values,
    counts,
    canImportEstimates,
    hasExistingEstimates,
    loading,
    onChange,
}) => {
    return (
        <>
            <BTFormItem>
                <BTCheckbox<ITemplateImportFormValues>
                    id="estimates"
                    data-testid="estimates"
                    checked={values.estimates}
                    onChange={onChange}
                    disabled={
                        loading || !canImportEstimates // if the proposal is released, we will not allow them to import estimates
                    }
                >
                    Estimates{counts && ` (${counts.estimates})`}
                </BTCheckbox>
            </BTFormItem>
            {values.estimates && (
                <>
                    {hasExistingEstimates && (
                        <div className="margin-left-lg">
                            <BTFormItem>
                                <BTCheckbox<ITemplateImportFormValues>
                                    id="estimatesRemoveExisting"
                                    data-testid="estimatesRemoveExisting"
                                    checked={values.estimatesRemoveExisting}
                                    onChange={onChange}
                                    disabled={loading}
                                >
                                    <div>Remove existing estimate items</div>
                                    <div>
                                        Any existing items tied to Bids, Selections, or Allowances
                                        will not be removed.
                                    </div>
                                </BTCheckbox>
                            </BTFormItem>
                        </div>
                    )}
                    <div className="margin-left-lg">
                        <BTFormItem>
                            <BTCheckbox<ITemplateImportFormValues>
                                id="estimatesKeepIntroClosingText"
                                data-testid="estimatesKeepIntroClosingText"
                                checked={values.estimatesKeepIntroClosingText}
                                onChange={onChange}
                                disabled={loading}
                            >
                                <div>
                                    Keep Introductory and Closing Text in Job Proposal Details. Text
                                    from this template will be added to the existing text.
                                </div>
                            </BTCheckbox>
                        </BTFormItem>
                    </div>
                </>
            )}
        </>
    );
};
