import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import moment from "moment";

import { GlobalInfo } from "helpers/AppProvider.types";

import { APIHandler } from "utilities/apiHandler";

export interface IAppProviderHandler {
    get(encryptedBuilderId?: string): Promise<GlobalInfo>;
    isLegacy: boolean;
}

export class AppProviderHandler implements IAppProviderHandler {
    isLegacy = false;

    async get(encryptedBuilderId?: string): Promise<GlobalInfo> {
        const globalInfo = await APIHandler(`/api/AccountInfo/GlobalInfo`, {
            method: "GET",
            data: { encryptedBuilderId },
            responseType: GlobalInfo,
        });
        // Deprecate btJScriptGlobals for SPA pages
        if (window.btJScriptGlobals && !window.btJScriptGlobals.spaProxyBypass) {
            const btJScriptGlobalsOrig = window.btJScriptGlobals;
            const btJScriptGlobalsProxy: ProxyHandler<object> = {
                get: (target, prop) => {
                    // DO NOT USE THIS PROPERTY WITHOUT TALKING TO ARCH
                    if (prop === "spaProxyBypass") {
                        return target;
                    } else {
                        console.warn(
                            `An attempt was made to access btJScriptGlobals from a SPA enabled page [key="${String(
                                prop
                            )}"]. Refactor to pull from context or an API endpoint instead.`
                        );
                        return target[prop];
                    }
                },
            };

            // Add the spa proxy bypass property
            // DO NOT USE THIS PROPERTY WITHOUT TALKING TO ARCH
            btJScriptGlobalsOrig.spaProxyBypass = btJScriptGlobalsOrig;

            const proxy = new Proxy(btJScriptGlobalsOrig, btJScriptGlobalsProxy);
            window.btJScriptGlobals = proxy;
        }

        return globalInfo;
    }
}

export const defaultAppProviderHandler = new AppProviderHandler();

// Hooks

/** Queries */

export type BTQueryOptions<
    TQueryFnData = unknown,
    TError = unknown,
    TQueryKey extends unknown[] = [],
    TData = TQueryFnData
> = Partial<
    Pick<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
        | "onSuccess"
        | "onError"
        | "onSettled"
        | "initialData"
        | "staleTime"
        | "cacheTime"
        | "retry"
        | "retryDelay"
        | "enabled"
        | "refetchOnWindowFocus"
    >
>;

export const globalInfoQueryKey = "globalInfo";
export const useGetGlobalInfoQuery = (
    handler: IAppProviderHandler,
    options?: BTQueryOptions<GlobalInfo, Error, (string | boolean)[]>,
    encryptedBuilderId?: string
) => {
    return useQuery(
        [
            globalInfoQueryKey,
            handler.isLegacy, // Use different query between API handler vs btJScriptGlobals handler
        ],
        async () => {
            return await handler.get(encryptedBuilderId);
        },
        {
            retry: 3,
            cacheTime: moment.duration(1, "day").asMilliseconds(),
            staleTime: moment.duration(1, "day").asMilliseconds(),
            ...options,
        }
    );
};
