import { IOnboardingPlugin, OnboardingAction } from "utilities/onboarding/onboarding.types";

/**
 * Class for Appcues onboarding action event logic
 */
export class AppcuesOnboarding implements IOnboardingPlugin {
    actionCompleted(action: OnboardingAction, actionData: any) {
        switch (action as OnboardingAction) {
            case OnboardingAction.InternalUserCreated:
                window.Appcues?.track("Onboarding_InternalUserCreated", actionData);
                break;
            case OnboardingAction.InternalUsersMassAdded:
                window.Appcues?.track("Onboarding_InternalUsersMassAdded", actionData);
                break;
            case OnboardingAction.ScheduleItemAdded:
                window.Appcues?.track("Onboarding_ScheduleItemAdded", actionData);
                break;
            case OnboardingAction.ToDoAdded:
                window.Appcues?.track("Onboarding_ToDoAdded", actionData);
                break;
            case OnboardingAction.DailyLogAdded:
                window.Appcues?.track("Onboarding_DailyLogAdded", actionData);
                break;
        }
    }
}
