import { useCallback, useContext } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { IBTSelectProps } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTSelectCustomItemContext } from "commonComponents/btWrappers/BTSelect/BTSelectCustomItemContext";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";

export function useFilterSelectCustomItemRender<FormValues = unknown>(): IBTSelectProps<
    FormValues,
    SavedFilterItem
>["customItemRender"] {
    return useCallback((item: BTSelectItem<SavedFilterItem>) => {
        return <FilterSelectCustomItem item={item} />;
    }, []);
}

interface IFilterSelectCustomItem {
    item: BTSelectItem<SavedFilterItem>;
}

export const FilterSelectCustomItem: React.FunctionComponent<IFilterSelectCustomItem> = ({
    item,
}) => {
    const selectContext = useContext(BTSelectCustomItemContext);
    const isSelectValueRender = selectContext?.isSelectedValueRender ?? false;

    return (
        <div className="flex justify-content-between align-items-center">
            {item.title}
            {!isSelectValueRender && item.extraData?.isDefault && (
                <StatusTagDisplay
                    className="margin-left-xss"
                    statusText="Default"
                    statusType="info"
                />
            )}
        </div>
    );
};
