import { APIHandler } from "utilities/apiHandler";

import {
    IConflictTrackingFormValues,
    ScheduleConflictResponse,
    SetConflictThresholdResponse,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";

export interface IScheduleConflictHandler {
    getAllConflicts(showPastItems: boolean): Promise<ScheduleConflictResponse>;
    getConflictsByUser(userId: number, showPastItems: boolean): Promise<ScheduleConflictResponse>;
    getConflictsByJob(jobId: number, showPastItems: boolean): Promise<ScheduleConflictResponse>;
    getConflictsBySchedule(id: number, showPastItems: boolean): Promise<ScheduleConflictResponse>;
    setConflictThreshold(
        data: IConflictTrackingFormValues,
        globalUserId: number,
        jobsiteId?: number
    ): Promise<SetConflictThresholdResponse>;
}

export class ScheduleConflictHandler {
    async getAllConflicts(showPastItems: boolean): Promise<ScheduleConflictResponse> {
        return await APIHandler(`/api/Calendar/AllConflicts`, {
            method: "GET",
            data: { showPastItems },
            responseType: ScheduleConflictResponse,
        });
    }
    async getConflictsByUser(
        userId: number,
        showPastItems: boolean
    ): Promise<ScheduleConflictResponse> {
        return await APIHandler(`/api/Calendar/ConflictsByUser`, {
            method: "GET",
            data: { userId, showPastItems },
            responseType: ScheduleConflictResponse,
        });
    }
    async getConflictsByJob(
        jobId: number,
        showPastItems: boolean
    ): Promise<ScheduleConflictResponse> {
        return await APIHandler(`/api/Calendar/ConflictsByJob`, {
            method: "GET",
            data: { jobId, showPastItems },
            responseType: ScheduleConflictResponse,
        });
    }
    async getConflictsBySchedule(
        id: number,
        showPastItems: boolean
    ): Promise<ScheduleConflictResponse> {
        return await APIHandler(`/api/Calendar/Conflicts`, {
            method: "GET",
            data: { id, showPastItems },
            responseType: ScheduleConflictResponse,
        });
    }
    async setConflictThreshold(
        data: IConflictTrackingFormValues,
        globalUserId: number
    ): Promise<SetConflictThresholdResponse> {
        return await APIHandler("/api/Calendar/ConflictTrackingAndCount", {
            method: "PUT",
            data: { ...data, globalUserId },
            responseType: SetConflictThresholdResponse,
        });
    }
}
