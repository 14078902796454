import moment from "moment";

function isNullorUndefined(value: any) {
    return value === undefined || value === null;
}

/**
 * Sorts ant design table based on date - note this is not a stable sorting method
 * @param a
 * @param b
 * @param sortOrder
 * @example sorter={(a: WarrantyServiceItem, b: WarrantyServiceItem, sortOrder?: SortOrder) => dateSorter(a.scheduledFor, b.scheduledFor, sortOrder)}
 */
export const dateSorter = function (
    a: undefined | null | moment.Moment,
    b: undefined | null | moment.Moment
): number {
    // handle null/undefined runtime values
    if (isNullorUndefined(a) && isNullorUndefined(b)) {
        return 0;
    } else if (isNullorUndefined(a)) {
        return -1;
    }
    if (isNullorUndefined(b)) {
        return 1;
    }

    // check for runtime errors - verify both a and b are moment objects. This happens when the dev doesn't call moment("apiResponseHere...")
    if (!(a instanceof moment) || !(b instanceof moment)) {
        throw `dateSorter should be passed two dates, it was passed dateSorter(${typeof a}, ${typeof b})`;
    }

    return a.diff(b);
};

export const lexicographicSorter = function (
    a: undefined | null | string,
    b: undefined | null | string
): number {
    // handle null/undefined runtime values
    if (isNullorUndefined(a) && isNullorUndefined(b)) {
        return 0;
    } else if (isNullorUndefined(a)) {
        return -1;
    }
    if (isNullorUndefined(b)) {
        return 1;
    }

    // Comparing lowercase values in order to mimic the sort method in gridsort.js
    const wordA = a!.toLowerCase();
    const wordB = b!.toLowerCase();

    return wordA.localeCompare(wordB);
};

export const numberSorter = function (
    a: undefined | null | number,
    b: undefined | null | number
): number {
    // handle null/undefined runtime values
    if (isNullorUndefined(a) && isNullorUndefined(b)) {
        return 0;
    } else if (isNullorUndefined(a)) {
        return -1;
    }
    if (isNullorUndefined(b)) {
        return 1;
    }

    return a! - b!;
};
