import Decimal, { Numeric } from "decimal.js-light";

import { BuilderInfo } from "helpers/AppProvider.types";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { add, divide, multiply, subtract } from "utilities/math/math";
import { lexicographicSorter, numberSorter } from "utilities/sort/sort";

import { TaxGroupServiceListItemExtraData } from "commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";
import {
    ICostLineItem,
    IMarkupLineItem,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import {
    CompoundCalculationType,
    IntermediateRoundingScale,
    ITaxGroupBreakdownItemRequest,
    ITaxRateBreakdownItemRequest,
    TaxMethod,
} from "entity/tax/common/tax.types";
import {
    TaxRateBreakdownInfo,
    TaxRateBreakdownItem,
} from "entity/tax/common/TaxRateBreakdown/TaxRateBreakdown.api.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

const rawTaxAmountPrecision = 4;

/**
 * calculates the amount that tax will be applied to based on tax method
 * @param amount total price tax is being applied to
 * @param taxPercent total effective tax rate of the group/rate
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns amount that tax is being applied to
 */
export function calculateApplicableAmount(
    amount: Numeric,
    taxPercent: Numeric,
    taxMethod: TaxMethod
) {
    switch (taxMethod) {
        case TaxMethod.Exclusive:
            return new Decimal(amount).toDecimalPlaces(IntermediateRoundingScale);
        case TaxMethod.Inclusive:
            return divide(amount, add(1, taxPercent)).toDecimalPlaces(IntermediateRoundingScale);
        default:
            return new Decimal(0);
    }
}

/**
 * calculates tax amount being applied to a price (either from a tax rate or a tax group)
 * @param amount total price tax is being applied to
 * @param taxPercent total effective tax rate of the group/rate
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns tax amount being applied
 */
export function calculateTaxFromRate(amount: Numeric, taxPercent: Numeric, taxMethod: TaxMethod) {
    const applicableAmount = calculateApplicableAmount(amount, taxPercent, taxMethod);
    const taxAmount = multiply(applicableAmount, taxPercent);
    return taxAmount.toDecimalPlaces(IntermediateRoundingScale);
}

/**
 * calculates tax on an amount when applying a tax rate
 * @param amount total price tax is being applied to
 * @param taxPercent total effective tax rate of the group
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns tax amount being applied from a rate
 */
export function applyTaxRate(amount: Numeric, taxPercent: Numeric, taxMethod: TaxMethod) {
    return calculateTaxFromRate(amount, divide(taxPercent, 100), taxMethod).toDecimalPlaces(
        rawTaxAmountPrecision
    );
}

/**
 * calculates tax on an amount when applying a tax group
 * @param amount total price tax is being applied to
 * @param taxPercent total effective tax rate of the group/rate
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns tax amount being applied from a group
 */
export function applyTaxGroup(amount: Numeric, taxPercent: Numeric, taxMethod: TaxMethod) {
    return calculateTaxFromRate(amount, divide(taxPercent, 100), taxMethod).toDecimalPlaces(
        rawTaxAmountPrecision
    );
}

/**
 * calculates total amount including tax on an amount
 * @param amount total price tax is being applied to
 * @param taxPercent total effective tax rate of the group/rate
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns total amount including taxes
 */
export function calculateTotalWithTax(amount: Numeric, taxPercent: Numeric, taxMethod: TaxMethod) {
    if (taxMethod !== TaxMethod.Exclusive) {
        return new Decimal(amount).toDecimalPlaces(rawTaxAmountPrecision).toNumber();
    } else {
        return add(amount, applyTaxGroup(amount, taxPercent, taxMethod))
            .toDecimalPlaces(rawTaxAmountPrecision)
            .toNumber();
    }
}

/**
 * creates singular item used in the TaxRateBreakdown component for dynamic updates
 * @param applicableAmount total price tax is being applied to (already taking into account tax method)
 * @param taxRate tax rate info parsed from BTSelect items in tax rate dropdown
 * @returns TaxRateBreakdownItem for updated TaxRateBreakdown
 */
export function getBreakdownItem(applicableAmount: Numeric, taxRate: ITaxRateBreakdownItemRequest) {
    return new TaxRateBreakdownItem({
        applicableAmount: new Decimal(applicableAmount)
            .toDecimalPlaces(rawTaxAmountPrecision)
            .toNumber(),
        taxAmount: applyTaxRate(
            applicableAmount,
            new Decimal(taxRate.taxRatePercent),
            TaxMethod.Exclusive
        ).toNumber(),
        id: taxRate.taxRateId,
        isActive: taxRate.isActive,
        name: taxRate.taxRateName,
        percentage: taxRate.taxRatePercent,
    });
}

/**
 * creates list of items used in the TaxRateBreakdown component for dynamic updates
 * represents a tax group
 * @param taxGroup tax group info parsed from BTSelect items in tax rate dropdown
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns list of TaxRateBreakdownItemRequest to use in updated breakdown
 */
export function getBreakdownItems(taxGroup: ITaxGroupBreakdownItemRequest, taxMethod: TaxMethod) {
    const groupTaxAmount = applyTaxGroup(
        new Decimal(taxGroup.amount),
        new Decimal(taxGroup.totalEffectiveRate).toDecimalPlaces(IntermediateRoundingScale),
        taxMethod
    );

    if (taxMethod === TaxMethod.None) {
        return [] as TaxRateBreakdownItem[];
    }

    const applicableAmount = calculateApplicableAmount(
        new Decimal(taxGroup.amount),
        divide(taxGroup.totalEffectiveRate, 100).toDecimalPlaces(IntermediateRoundingScale),
        taxMethod
    );

    const compoundRates = taxGroup.taxRates.filter((r) => r.isCompoundRate);
    const baseRates = taxGroup.taxRates.filter((r) => !r.isCompoundRate);
    const items = baseRates.map((r) => getBreakdownItem(applicableAmount, r));

    let totalTax = new Decimal(
        items.reduce((total, item) => add(total, item.taxAmount).toNumber(), 0)
    ).toDecimalPlaces(rawTaxAmountPrecision);

    if (compoundRates.length > 0) {
        let compoundApplicableAmount = totalTax;
        if (taxGroup.compoundCalculationType === CompoundCalculationType.NetPlusTax) {
            compoundApplicableAmount = add(compoundApplicableAmount, applicableAmount);
        }

        const compoundItem = getBreakdownItem(compoundApplicableAmount, compoundRates[0]);
        totalTax = add(totalTax, compoundItem.taxAmount);
        items.push(compoundItem);
    }

    if (groupTaxAmount !== totalTax && items.length) {
        const taxDifference = subtract(groupTaxAmount.toNumber(), totalTax.toNumber());
        const reorderedItems = items.sort((a, b) => numberSorter(a.id, b.id));
        reorderedItems[0].taxAmount = add(reorderedItems[0].taxAmount, taxDifference)
            .toDecimalPlaces(rawTaxAmountPrecision)
            .toNumber();
        return reorderedItems;
    }
    return items;
}

/**
 * generates correctly mapped request object to turn into breakdown objects given entity data
 * only used in flat fee mode when a tax group (other than No Tax) is applied
 * @param flatFeeTaxGroupId tax group id for the entity in flat fee mode
 * @param amount price to apply tax to
 * @param taxGroups tax group data from BTSelect dropdown to parse
 * @returns single TaxGroupBreakdownItemRequest with group/amount data
 */
export function getBreakdownGroupRequestForFlatFee(
    flatFeeTaxGroupId: any,
    amount: number,
    taxGroups: BTSelectItem<TaxGroupServiceListItemExtraData>[]
): ITaxGroupBreakdownItemRequest {
    const selectedTaxGroup = taxGroups?.filter(
        (g) => parseInt(g.id) === parseInt(flatFeeTaxGroupId)
    )[0];

    if (selectedTaxGroup) {
        return {
            amount: amount,
            title: selectedTaxGroup!.title,
            compoundCalculationType: selectedTaxGroup!.extraData!.compoundCalculationType,
            totalEffectiveRate: selectedTaxGroup!.extraData!.totalEffectiveRate,
            taxRates: selectedTaxGroup!.extraData!.taxRates.map((r) => ({
                taxRateId: r.taxRateId,
                taxRateName: r.taxRateName,
                taxRatePercent: r.taxRatePercent,
                isActive: r.isActive,
                isCompoundRate: r.isCompoundRate ?? false,
            })),
        };
    }
    return { title: "", totalEffectiveRate: 0, amount: 0, taxRates: [] };
}

/**
 * generates correctly mapped request objects to turn into breakdown objects given entity data
 * @param lineItems updated line items from the entity (contains new taxGroupId)
 * @param taxGroups tax group data from BTSelect dropdown to parse
 * @param applyToOwnerPrice switch for grabbing price from builderCost/ownerPrice based on entity
 * @returns TaxGroupBreakdownItemRequest with tax group/amount data
 */
export function getBreakdownGroupRequests(
    lineItems: BreakdownGroupRequestLineItem[],
    taxGroups: BTSelectItem<TaxGroupServiceListItemExtraData>[],
    applyToOwnerPrice: boolean
) {
    let requests: ITaxGroupBreakdownItemRequest[] = [];
    lineItems.forEach((li: BreakdownGroupRequestLineItem) => {
        if (li.taxGroupId !== NoTaxId) {
            const selectedTaxGroup = taxGroups?.filter((g) => parseInt(g.id) === li.taxGroupId)[0];
            if (selectedTaxGroup) {
                const amount = applyToOwnerPrice ? li.ownerPrice! : li.builderCost!;
                const data: ITaxGroupBreakdownItemRequest = {
                    amount: amount,
                    title: selectedTaxGroup!.title,
                    compoundCalculationType: selectedTaxGroup!.extraData?.compoundCalculationType,
                    totalEffectiveRate: selectedTaxGroup!.extraData?.totalEffectiveRate!,
                    taxRates:
                        selectedTaxGroup!.extraData?.taxRates?.map((rate) => ({
                            ...rate,
                            isCompoundRate: rate.isCompoundRate ?? false,
                        })) ?? [],
                };
                requests.push(data);
            }
        }
    });
    return requests;
}

export class BreakdownGroupRequestLineItem {
    constructor(data: any) {
        this.taxGroupId = data.taxGroupId;
        this.ownerPrice = data.ownerPrice;
        this.builderCost = data.builderCost;
    }

    taxGroupId: number;
    ownerPrice?: number;
    builderCost?: number;
}

/**
 * gets tax breakdown given an array of TaxGroupBreakdownItemRequest
 * @param taxBreakdownGroups tax group and amount info to show on price breakdown
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @returns TaxRateBreakdownInfo with updated tax information
 */
export function getTaxRateBreakdown(
    taxBreakdownGroups: ITaxGroupBreakdownItemRequest[],
    taxMethod: TaxMethod
) {
    let rateBreakdowns: TaxRateBreakdownItem[] = [];
    let totalTax = new Decimal(0);

    // create full list of tax rate breakdown objects
    taxBreakdownGroups.forEach((g) => {
        rateBreakdowns = rateBreakdowns.concat(getBreakdownItems(g, taxMethod));
    });

    let rateDict = {};
    rateBreakdowns.forEach((r) => {
        // total the tax for the breakdown
        totalTax = add(totalTax, r.taxAmount);

        // in the case of duplicate rate ids, we need to sum them to display in the same line
        if (rateDict[r.id] === undefined) {
            rateDict[r.id] = r;
        } else {
            rateDict[r.id].applicableAmount = add(
                rateDict[r.id].applicableAmount,
                r.applicableAmount
            ).toNumber();
            rateDict[r.id].taxAmount = add(rateDict[r.id].taxAmount, r.taxAmount).toNumber();
        }
    });

    // sort all of the values by name, then by percentage
    const dictValues = Object.values(rateDict) as TaxRateBreakdownItem[];
    const sortedItems = dictValues.sort(
        (a, b) => lexicographicSorter(a.name, b.name) || numberSorter(a.percentage, b.percentage)
    );

    // TODO: We need to update this method to include tax override when doing client side live calculations.
    const breakdownInfo = new TaxRateBreakdownInfo({
        taxRateBreakdownItems: sortedItems,
        totalTax: totalTax.toNumber(),
        taxOverride: 0,
    });

    return breakdownInfo;
}

/**
 * gets tax breakdown for the price breakdown component based on line item and tax groups information
 * @param lineItems updated line items from the entity (contains new taxGroupId)
 * @param isFlatFee if the price type has been set to flat fee on the entity
 * @param applyToOwnerPrice switch for grabbing price from builderCost/ownerPrice based on entity
 * @param taxGroups tax group data from BTSelect dropdown to parse
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @param flatFeeTaxGroupId tax group id for the entity in flat fee mode
 * @param flatFeePrice price to apply tax to
 * @returns TaxRateBreakdownInfo with selected tax groups
 */
export function getTaxRateBreakdownForPriceBreakdown<T extends ICostLineItem>(
    lineItems: T[],
    isFlatFee: boolean,
    applyToOwnerPrice: boolean,
    taxGroups?: BTSelectItem<TaxGroupServiceListItemExtraData>[],
    taxMethod?: TaxMethod,
    flatFeeTaxGroupId?: number,
    flatFeePrice?: number
) {
    if (taxGroups !== undefined && taxGroups.length > 0) {
        if (isFlatFee) {
            return getTaxRateBreakdown(
                [
                    getBreakdownGroupRequestForFlatFee(
                        flatFeeTaxGroupId!,
                        flatFeePrice!,
                        taxGroups!
                    )!,
                ],
                taxMethod!
            );
        } else {
            const breakdownGroupRequestLineItems = lineItems.map(
                (li: T) => new BreakdownGroupRequestLineItem(li)
            );
            return getTaxRateBreakdown(
                getBreakdownGroupRequests(
                    breakdownGroupRequestLineItems,
                    taxGroups!,
                    applyToOwnerPrice
                )!,
                taxMethod!
            );
        }
    } else {
        return new TaxRateBreakdownInfo({ taxRateBreakdownItems: [], totalTax: 0, taxOverride: 0 });
    }
}

/**
 * gets tax breakdown for the price breakdown component based on line item and tax groups information
 * @param lineItems updated line items from the entity (contains new taxGroupId)
 * @param isFlatFee if the price type has been set to flat fee on the entity
 * @param applyToOwnerPrice switch for grabbing price from builderCost/ownerPrice based on entity
 * @param taxGroups tax group data from BTSelect dropdown to parse
 * @param taxMethod type of tax being applied, (None, Exclusive or Inclusive)
 * @param flatFeeTaxGroupId tax group id for the entity in flat fee mode
 * @param flatFeePrice price to apply tax to
 * @returns TaxRateBreakdownInfo with selected tax groups
 */
export function getTotalTaxesOnEntity(
    lineItems: IMarkupLineItem[],
    isFlatFee: boolean,
    applyToOwnerPrice: boolean,
    taxGroups?: BTSelectItem<TaxGroupServiceListItemExtraData>[],
    taxMethod?: TaxMethod,
    flatFeeTaxGroupId?: number,
    flatFeePrice?: number
) {
    if (taxGroups !== undefined && taxGroups.length > 0) {
        if (isFlatFee) {
            const a = getBreakdownGroupRequestForFlatFee(
                flatFeeTaxGroupId!,
                flatFeePrice!,
                taxGroups!
            );

            const b = getBreakdownItems(a, taxMethod!);
            return b.reduce((a, b) => a + b.taxAmount, 0);
        } else {
            const breakdownGroupRequestLineItems = lineItems!.map(
                (li: IMarkupLineItem) => new BreakdownGroupRequestLineItem(li)
            );
            const a = getBreakdownGroupRequests(
                breakdownGroupRequestLineItems,
                taxGroups!,
                applyToOwnerPrice
            );
            let rateBreakdowns: TaxRateBreakdownItem[] = [];
            a.forEach((g) => {
                rateBreakdowns = rateBreakdowns.concat(getBreakdownItems(g, taxMethod!));
            });

            return rateBreakdowns.reduce((a, b) => a + b.taxAmount, 0);
        }
    } else {
        return 0;
    }
}

/**
 * checks if taxFlag is turned on and also checks taxOptOut and if US builder
 * @param builderInfo builder Info
 * @param mp flag to check
 */
export function isBuilderTaxPrefActive(
    builderInfo: BuilderInfo | undefined | null,
    mp: boolean | undefined
) {
    return !builderInfo?.taxesOptOut && builderInfo?.isInTaxesSupportedRegion && mp;
}
