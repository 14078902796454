import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ILeadProps } from "entity/lead/Lead/Lead";

const Lead = lazyLoadWithRetry(() => import("./Lead"));

export const RouteLead = (props: Omit<ILeadProps, "leadId" | "history" | "match" | "location">) => (
    <RouteRelative
        path={routes.lead.details}
        render={(routeProps) => {
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            return (
                <BTSuspense>
                    <Lead
                        {...props}
                        {...routeProps}
                        leadId={parseInt(routeProps.match.params.id) ?? -1}
                        checkoutId={qsValues.checkout_id}
                    />
                </BTSuspense>
            );
        }}
    />
);
