import { IEntityRecord } from "helpers/routing/EntityLinkRouter/EntityLinkRouter.api.types";

import { routes } from "utilities/routes";

export function getShareUrl(record: IEntityRecord) {
    const shareUrl = location.href
        // clear out any query params
        .replace(location.search, "")
        // clear out any hash
        .replace(location.hash, "")
        // and generate the new link url
        .replace(
            location.pathname,
            `${routes.appBase}${routes.entityLink.getLink(record.externalId)}`
        );

    return shareUrl;
}
