import { Collapse } from "antd";
import { Component } from "react";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTCollapse } from "commonComponents/btWrappers/BTCollapse/BTCollapse";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import {
    ScheduleConflict,
    ScheduleConflictViews,
    UserWithConflicts,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import ScheduleConflictTracking from "commonComponents/entity/scheduleConflicts/ScheduleConflictTracking/ScheduleConflictTracking";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";

import "./ScheduleConflictTable.less";

interface IScheduleConflictTableProps {
    conflicts: UserWithConflicts[];
    activeId?: number;
    loading?: boolean;
    onViewItemsClick?: (scheduleId: number) => void;
    onGroupHeaderClick?: (userId: number) => void;
    onJobNameClick?: (jobId: number) => void;
    onSetConflictThreshold: (userId: number, updatedNumberOfConflicts: number) => void;
    renderConflictTitle: (record: ScheduleConflict) => JSX.Element;
    view?: ScheduleConflictViews;
}

interface IScheduleConflictTableState {
    conflictTrackingId: number;
}

export default class ScheduleConflictTable extends Component<
    IScheduleConflictTableProps,
    IScheduleConflictTableState
> {
    state: Readonly<IScheduleConflictTableState> = {
        conflictTrackingId: -1,
    };

    private handleCloseTracking = (userId?: number, updatedNumberOfConflicts?: number) => {
        // Hide tracking modal
        this.setState({ conflictTrackingId: -1 });
        // Update parent with any info from submission
        if (userId !== undefined && updatedNumberOfConflicts !== undefined) {
            this.props.onSetConflictThreshold(userId, updatedNumberOfConflicts);
        }
    };

    private handleDisplayModal = (conflictTrackingId: number) => {
        this.setState({ conflictTrackingId });
    };

    private renderGroupHeader = (conflictGroup: UserWithConflicts) => {
        if (this.props.onGroupHeaderClick) {
            return (
                <a
                    className="ConflictLink"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.onGroupHeaderClick!(conflictGroup.globalUserId);
                    }}
                >
                    {conflictGroup.name}
                </a>
            );
        }
        return conflictGroup.name;
    };

    private renderConflictThreshold = (conflictGroup: UserWithConflicts) => {
        return (
            <p>
                Alerting when (
                <a onClick={() => this.handleDisplayModal(conflictGroup.globalUserId)}>
                    {conflictGroup.itemCount} items
                </a>
                ) or more conflict
            </p>
        );
    };

    private renderJobName = (record: ScheduleConflict) => {
        if (this.props.onJobNameClick) {
            return (
                <a
                    // eslint-disable-next-line react/forbid-dom-props
                    title={record.jobName}
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.onJobNameClick!(record.jobsiteID);
                    }}
                >
                    {record.jobName}
                </a>
            );
        }
        // eslint-disable-next-line react/forbid-dom-props
        return <span title={record.jobName}>{record.jobName}</span>;
    };

    private renderConflictCount = (record: ScheduleConflict) => {
        const conflictCount = `${record.relatedConflicts} Conflict${
            record.relatedConflicts > 1 ? "s" : ""
        }`;
        if (this.props.activeId === undefined) {
            // Show all conflict counts and view items links
            return <span>{conflictCount}</span>;
        }
        return record.relatedConflicts === 0 || record.scheduleId !== this.props.activeId
            ? "-"
            : conflictCount;
    };

    private renderViewDetails = (record: ScheduleConflict) => {
        return (
            <a
                className="ConflictLink"
                onClick={(e) => {
                    e.preventDefault();
                    if (this.props.onViewItemsClick) {
                        this.props.onViewItemsClick(record.scheduleId);
                    }
                }}
            >
                View Items
            </a>
        );
    };

    private getKey = (conflict: UserWithConflicts) => {
        return `${conflict.name}_${conflict.globalUserId}`;
    };

    private getDefaultExpandedKeyByView = () => {
        // For the user view auto expand the first row by default
        if (
            this.props.view &&
            this.props.view === ScheduleConflictViews.User &&
            this.props.conflicts.length > 0
        ) {
            return this.getKey(this.props.conflicts[0]);
        }
        return undefined;
    };

    render() {
        const Panel = Collapse.Panel;
        const { conflicts } = this.props;
        const matchingConflict = conflicts.find(
            (c) => this.state.conflictTrackingId === c.globalUserId
        );
        const renderViewBtn = this.props.activeId === undefined;

        return (
            conflicts && (
                <BTCollapse
                    data-testid="scheduleConflict"
                    className="ScheduleConflictTable"
                    defaultActiveKey={this.getDefaultExpandedKeyByView()}
                >
                    {conflicts.map((conflict) => (
                        <Panel
                            id={this.getKey(conflict)}
                            key={this.getKey(conflict)}
                            header={this.renderGroupHeader(conflict)}
                        >
                            {this.renderConflictThreshold(conflict)}
                            <BTRow className="ConflictHeader">
                                <BTCol xs={6}>Title</BTCol>
                                <BTCol xs={6}>Job</BTCol>
                                <BTCol xs={3} className="hidden-md-down">
                                    Start Date
                                </BTCol>
                                <BTCol xs={3} className="hidden-md-down">
                                    End Date
                                </BTCol>
                                <BTCol xs={6} className="hidden-md-down" />
                                <BTCol xs={12} className="hidden-md-up">
                                    <div>Start Date</div>
                                    <div>End Date</div>
                                </BTCol>
                            </BTRow>
                            {conflict.conflicts.map((record) => (
                                <BTRow
                                    className={
                                        record.scheduleId === this.props.activeId
                                            ? "ActiveConflict"
                                            : undefined
                                    }
                                    key={`conflict_${conflict.globalUserId}_${record.scheduleId}`}
                                >
                                    <BTCol
                                        xs={6}
                                        className={
                                            record.scheduleId !== this.props.activeId
                                                ? "ConflictLink"
                                                : "ConflictLinkInactive"
                                        }
                                    >
                                        {this.props.renderConflictTitle(record)}
                                    </BTCol>
                                    <BTCol
                                        xs={6}
                                        className={
                                            this.props.onJobNameClick
                                                ? "ConflictLink"
                                                : "ConflictLinkInactive"
                                        }
                                    >
                                        {this.renderJobName(record)}
                                    </BTCol>
                                    <BTCol xs={3} className="hidden-md-down">
                                        <DateDisplay value={record.startDate} />
                                    </BTCol>
                                    <BTCol xs={3} className="hidden-md-down">
                                        <DateDisplay value={record.endDate} />
                                    </BTCol>
                                    <BTCol
                                        xs={renderViewBtn ? 3 : 6}
                                        className="hidden-md-down text-right"
                                    >
                                        {this.renderConflictCount(record)}
                                    </BTCol>
                                    {renderViewBtn && (
                                        <BTCol xs={3} className="hidden-md-down">
                                            {this.renderViewDetails(record)}
                                        </BTCol>
                                    )}
                                    <BTCol xs={6} className="hidden-md-up">
                                        <div>
                                            <DateDisplay value={record.startDate} />
                                        </div>
                                        <div>
                                            <DateDisplay value={record.endDate} />
                                        </div>
                                    </BTCol>
                                    <BTCol xs={6} className="hidden-md-up">
                                        <div>{this.renderConflictCount(record)}</div>
                                        {renderViewBtn && (
                                            <div>{this.renderViewDetails(record)}</div>
                                        )}
                                    </BTCol>
                                </BTRow>
                            ))}
                        </Panel>
                    ))}
                    {matchingConflict && (
                        <ScheduleConflictTracking
                            name={matchingConflict.name}
                            globalUserId={matchingConflict.globalUserId}
                            values={{
                                shouldAlertConflicts: true,
                                conflictAlertThreshold: matchingConflict.itemCount,
                            }}
                            onClose={this.handleCloseTracking}
                        />
                    )}
                </BTCollapse>
            )
        );
    }
}
