import { initializeApp } from "firebase/app";
import {
    getMessaging,
    getToken,
    MessagePayload,
    Messaging,
    NextFn,
    onMessage,
} from "firebase/messaging";

import { FirebaseConfig } from "helpers/AppProvider.types";
import { parseNotificationFromFirebaseMessagePayload } from "helpers/pushNotification.background.utilities";
import { IPushNotificationHandler, MessagePayloadType } from "helpers/pushNotification.types";
import { showChatMessageNotification } from "helpers/pushNotification.utilities.display";

import { reportError } from "utilities/errorHelpers";
import { routes } from "utilities/routes";

import { ChatHandler, IChatHandler } from "entity/chat/Chat.api.handler";

export class ForegroundPushNotificiationManager {
    // This should be moved to an app default value in the future so we can change it
    private static VAPID_KEY =
        "BJXzC7TKkWTcAfAI9r5ye4o7wVuOUUO7m4EDfJRy4VrnGrAmAHcNSi1RO0kLVntQvt1jaiLD2p6IwzKqedWZCIQ";
    /**
     * If state is in "errored" state, disable all further push notification operations until a page
     * reload. Otherwise, "active" state is the default state
     */
    private _state: "errored" | "active" | "uninitialized" | "updatingRegistration";
    private swRegistration: ServiceWorkerRegistration;
    private pushNotificationHandler: IPushNotificationHandler;
    private messagingInstance: Messaging | null = null;
    private currentUserId: number | null = null;
    private chatHandler: IChatHandler;

    constructor(
        swRegistration: ServiceWorkerRegistration,
        pushNotificationHandler: IPushNotificationHandler
    ) {
        this.swRegistration = swRegistration;
        this.pushNotificationHandler = pushNotificationHandler;
        this._state = "uninitialized";
        this.chatHandler = new ChatHandler();
    }

    private handleMessage: NextFn<MessagePayload> = async (messagePayload) => {
        console.log("Received notification in foreground");
        if (!messagePayload.data || typeof messagePayload.data === "undefined") return;
        const { data } = messagePayload;
        const notificationData = parseNotificationFromFirebaseMessagePayload(data);
        if (!notificationData) return;

        // foreground notifications are forwarded to the service worker so that it can
        // determine if the user has more than one tab open and if the user is currently
        // viewing the chat tab
        if (
            this.swRegistration &&
            document.visibilityState === "visible" &&
            !this.userIsViewingChat(notificationData.payloadType)
        ) {
            if (notificationData.payloadType === MessagePayloadType.Chat) {
                // show antd notification
                // use existing wrapper if there is one
                showChatMessageNotification(notificationData, this.chatHandler);
            } else {
                const { title, options } = notificationData.createDesktopNotificationParams(true);
                await this.swRegistration.showNotification(title, options);
            }
        }
    };

    private userIsViewingChat = (payloadType: MessagePayloadType) => {
        const chatRouteUrl = routes.chat.getListLink().toLowerCase();
        return (
            payloadType === MessagePayloadType.Chat &&
            window.location.pathname.toLowerCase().includes(chatRouteUrl)
        );
    };

    private getFirebaseClientToken = async () => {
        if (!this.messagingInstance) {
            if (this._state === "active") {
                console.error(
                    "Messaging instance was not initialized. Notifications will not work"
                );
                reportError(
                    new Error(
                        "Messaging instance was not initialized prior to fetching firebase client id"
                    )
                );
            }
            this._state = "errored";
            return;
        }

        try {
            return await getToken(this.messagingInstance, {
                vapidKey: ForegroundPushNotificiationManager.VAPID_KEY,
                serviceWorkerRegistration: this.swRegistration,
            });
        } catch (e) {
            console.warn(
                "Failed to get firebase registration token; likely because the user rejected push notifications. Push notifications may not be enabled.",
                e
            );
            this._state = "errored";
            return null;
        }
    };

    private tieFirebaseTokenToUserSession = async (token: string) => {
        try {
            await this.pushNotificationHandler.registerBrowser(token);
            this._state = "active";
        } catch (e) {
            console.error("Failed to register firebase token to buildertrend");
            reportError(e);
            this._state = "errored";
        }
    };

    public initialize = (firebaseConfig: FirebaseConfig) => {
        if (this._state === "uninitialized") {
            try {
                this._state = "active";
                const firebaseApp = initializeApp(firebaseConfig);
                const messaging = getMessaging(firebaseApp);
                onMessage(messaging, this.handleMessage);
                this.messagingInstance = messaging;
            } catch (e) {
                this._state = "errored";
                console.error("Failed to initialize firebase app", e);
                reportError(e);
            }
        }
    };

    /**
     * Enables foreground push notifications for the current user.
     *
     * @param userId - passed so that we can track if the user has changed or not. If it has changed,
     * we need to tie the current browser's firebase token to the current user in session. If it hasn't
     * changed, this method does nothing.
     */
    public enablePushNotificationsForCurrentUser = async (userId: number) => {
        const userHasChangedSinceLastRegistration = userId !== this.currentUserId;
        if (!userHasChangedSinceLastRegistration || this._state !== "active") {
            return;
        }

        if (userHasChangedSinceLastRegistration && this._state === "active") {
            this._state = "updatingRegistration";
            const token = await this.getFirebaseClientToken();
            if (token) {
                await this.tieFirebaseTokenToUserSession(token);
                this.currentUserId = userId;
            }
        }
    };

    public get state() {
        return this._state;
    }
}

/**
 * Not ideal, but we need to manage this globally to prevent double notifications
 * for the multiple React instances that exist in our non-SPA pages. Post SPA this could
 * exist within the React lifecycle w/state management as some "PushNotificationProvider" or something.
 */
export class RootForegroundNotificiationController {
    private managerInstance: ForegroundPushNotificiationManager | null = null;

    public getInstance = (
        registration: ServiceWorkerRegistration,
        firebaseConfig: FirebaseConfig,
        pushNotificationHandler: IPushNotificationHandler
    ): Omit<ForegroundPushNotificiationManager, "initialize"> => {
        if (!this.managerInstance) {
            this.managerInstance = new ForegroundPushNotificiationManager(
                registration,
                pushNotificationHandler
            );
            this.managerInstance.initialize(firebaseConfig);
        }

        return this.managerInstance;
    };
}
