export enum TaxMethod {
    // For US builders this value is being used as "Non-Taxable"
    None = 0,
    // For US builders this value is being used as "Taxable"
    Exclusive = 1,
    Inclusive = 2,
}

export enum CompoundCalculationType {
    TaxOnly = 0,
    NetPlusTax = 1,
}

export const IntermediateRoundingScale = 10;

export interface ITaxGroupBreakdownItemRequest {
    title: string;
    compoundCalculationType?: CompoundCalculationType;
    totalEffectiveRate: number;
    taxRates: ITaxRateBreakdownItemRequest[];

    amount: number;
}

export interface ITaxRateBreakdownItemRequest {
    taxRateId: number;
    taxRateName: string;
    taxRatePercent: number;
    isActive: boolean;
    isCompoundRate: boolean;
}

export enum TaxesSetupActiveTab {
    TaxRate = "1",
    TaxGroup = "2",
}

export const CostCodeTaxableKey = "taxable";
