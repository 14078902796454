// eslint-disable-next-line no-restricted-imports
import { ComponentType, lazy } from "react";

export function lazyLoadWithRetry<T extends ComponentType<any>>(
    factory: () => Promise<{ default: T }>
) {
    return lazy(
        () =>
            new Promise<{ default: T }>((resolve, reject) => {
                factory()
                    .then(resolve)
                    .catch(() => {
                        // retry after some time
                        void setTimeout(() => {
                            factory().then(resolve, reject);
                        }, 1500);
                    });
            })
    );
}
