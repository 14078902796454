import { DatePicker, DatePickerProps } from "antd";
import classNames from "classnames";
import memoizeOne from "memoize-one";
import moment, { Moment } from "moment";
import "moment-recur-ts";
import { createRef, PureComponent, useContext } from "react";

import { DateLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { addCloseOnScroll, getClosestModal, removeCloseOnScroll } from "utilities/helpers";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./BTDatePicker.less";

interface IBTDatePickerProps<FormValues>
    extends Omit<
        DatePickerProps,
        "onChange" | "id" | "allowClear" | "open" | "onOpenChange" | "ref"
    > {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;
    readOnly?: boolean;

    showTime?: boolean;

    showToday?: boolean;

    allowClear?: boolean;

    /**
     * With this flag the date picker is rendered as a dropdown - removing the clear icon, adding the chevron, and changing the date format
     * @default false
     */
    isDropDown?: boolean;

    /** pass formik setFieldValue */
    onChange: (field: KeyOfOrString<FormValues>, value: moment.Moment | undefined) => void;

    /** pass formik setFieldTouched
     * onBlur={() => setFieldTouched("fieldName", true)}
     */
    onBlur?: any | DatePickerProps["onBlur"];
}

interface IBTDatePickerInternalProps {
    builderDateLocale: DateLocale;
}

interface IBTDatePickerInternalState {
    open: boolean;
}

@track((props) => ({ element: "Date Picker", uniqueId: props["data-testid"] }))
class BTDatePickerInternal<FormValues> extends PureComponent<
    IBTDatePickerProps<FormValues> & ITrackingProp & IBTDatePickerInternalProps,
    IBTDatePickerInternalState
> {
    static defaultProps = {
        allowClear: true,
    };
    state: IBTDatePickerInternalState = {
        open: false,
    };

    fieldRef = createRef<HTMLDivElement>();

    private handleValueChange = (date: moment.Moment | null) => {
        const { onChange, id, tracking, value } = this.props;
        const dateNullOrUndefined = date !== null ? date : undefined;

        onChange(id, dateNullOrUndefined);
        tracking?.trackEvent({
            event: "ValueChange",
            extraInfo: {
                date: date?.toString(),
                previousDate: value?.toString(),
            },
        });
        this.currentStringValueForMouseOutIssue = undefined;
    };

    // Do not use!  This is needed until this issue is resolved https://github.com/ant-design/ant-design/issues/21189
    private currentStringValueForMouseOutIssue?: string;
    private handleKeyUp = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        this.currentStringValueForMouseOutIssue = target.value;
    };

    private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { builderDateLocale, onBlur } = this.props;
        if (onBlur) {
            onBlur(e);
        }
        if (this.currentStringValueForMouseOutIssue) {
            let value = this.currentStringValueForMouseOutIssue
                ? moment(
                      this.currentStringValueForMouseOutIssue,
                      builderDateLocale.dateInputFormats
                  )
                : null;
            if (value == null || (value.isValid() && !value.isSame(this.props.value))) {
                this.handleValueChange(value);
            }
        }
    };

    private handleOpenChange = (open: boolean) => {
        this.setState({
            open: open,
        });

        // bind scroll event
        if (this.fieldRef.current) {
            if (open) {
                addCloseOnScroll(this.fieldRef.current, this.handleScroll);
            } else {
                removeCloseOnScroll(this.fieldRef.current, this.handleScroll);
            }
        }
    };

    private handleScroll = () => {
        // close on scroll
        this.handleOpenChange(false);
    };

    private handlePanelRender = (originPanel: React.ReactNode) => {
        return <div data-testid="datePanel">{originPanel}</div>;
    };

    private getDateFormat = (
        picker: IBTDatePickerProps<FormValues>["picker"],
        dateInputFormats: string[] | undefined
    ) => {
        const customDayFormat = "ddd, MMM DD, yyy";
        const customMonthFormat = ["MMMM, YYYY", "MM/YYYY", "MMMM YYYY"];
        const weekFormat = "MMM DD";
        const customWeekStartEndFormat = (value: Moment) =>
            `${moment(value).startOf("week").format(weekFormat)} - ${moment(value)
                .endOf("week")
                .format(weekFormat + ", YYYY")}`;
        if (picker === "week" || picker === "month") {
            return picker === "week" ? [customWeekStartEndFormat] : customMonthFormat;
        }
        return [customDayFormat, ...(dateInputFormats || [])];
    };

    private memoGetDateFormat = memoizeOne(this.getDateFormat);

    componentWillUnmount = () => {
        this.fieldRef.current
            ?.querySelector("input")
            ?.removeEventListener("keyup", this.handleKeyUp);
        this.handleOpenChange(false);
    };

    componentDidMount = () => {
        this.fieldRef.current?.querySelector("input")?.addEventListener("keyup", this.handleKeyUp);
    };

    render() {
        const {
            id,
            value,
            onChange,
            onBlur,
            readOnly,
            "data-testid": testid,
            builderDateLocale,
            picker,
            isDropDown,
            ...otherProps
        } = this.props;

        if (readOnly) {
            return (
                <ValueDisplay
                    id={id as string}
                    data-testid={testid}
                    value={value && <DateDisplay value={value} showTime={!!this.props.showTime} />}
                    className={otherProps.className}
                />
            );
        }

        const format: string[] | ((value: Moment) => string)[] | undefined = isDropDown
            ? this.memoGetDateFormat(picker, builderDateLocale.dateInputFormats)
            : builderDateLocale.dateInputFormats;
        const suffixIconValue = isDropDown ? <BTIconCaretSmallDown size={20} /> : undefined;

        return (
            <div className="BTDatePicker" ref={this.fieldRef}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <DatePicker
                    id={id as string}
                    data-testid={testid}
                    value={value}
                    onChange={this.handleValueChange}
                    onBlur={this.handleBlur}
                    open={this.state.open}
                    onOpenChange={this.handleOpenChange}
                    onFocus={() => this.handleOpenChange(true)}
                    getPopupContainer={getClosestModal}
                    format={format}
                    defaultValue={undefined}
                    picker={picker}
                    placeholder={otherProps.placeholder ?? ""}
                    allowClear={otherProps.allowClear}
                    defaultPickerValue={this.props.defaultPickerValue}
                    panelRender={this.handlePanelRender}
                    className={classNames(otherProps.className, {
                        isDropdown: isDropDown,
                    })}
                    suffixIcon={suffixIconValue}
                    {...otherProps}
                />
            </div>
        );
    }
}

export function BTDatePicker<FormValues = undefined>(props: IBTDatePickerProps<FormValues>) {
    const builderInfo = useContext(BuilderInfoContext);
    return (
        <BTDatePickerInternal<FormValues>
            builderDateLocale={builderInfo?.locale.dateLocale ?? DateLocale.default}
            {...props}
        />
    );
}
