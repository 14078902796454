import { BTContentRoot } from "types/constants";

import { isNullOrUndefined } from "utilities/object/object";

/**
 *
 * @param bool
 * @example hideTableColumn(this.booleanKey);
 */
export function isVisible(bool: boolean) {
    return bool ? "" : "hidden";
}

export function addCloseOnScroll(
    triggerNode: Element,
    onScrollCallback: (event: Event) => void,
    useDocument?: boolean
): void {
    const closestModal = getClosestScrollContainer(triggerNode);
    const closestGrid = getClosestReactGrid(triggerNode);

    if (closestModal) {
        // scroll event is NOT fired on document body - it's fired on the window
        // in this case the flyout is on a list page, not within a modal. So we have no need of closing the flyout on scroll
        closestModal.addEventListener("scroll", onScrollCallback, { passive: true });
    }

    if (closestGrid) {
        closestGrid.addEventListener("scroll", onScrollCallback, { passive: true });
    }

    if (useDocument) {
        document.addEventListener("scroll", onScrollCallback, { passive: true });
    }
}

export function removeCloseOnScroll(
    triggerNode: Element,
    onScrollCallback: (event: Event) => void,
    useDocument?: boolean
): void {
    const bodyEl = document.body;
    const closestModal = getClosestScrollContainer(triggerNode) ?? bodyEl;
    const closestGrid = getClosestReactGrid(triggerNode) ?? bodyEl;

    if (closestModal !== bodyEl) {
        // scroll event is NOT fired on document body - it's fired on the window
        // in this case the flyout is on a list page, not within a modal. So we have no need of closing the flyout on scroll
        closestModal.removeEventListener("scroll", onScrollCallback);
    }

    if (closestGrid !== bodyEl) {
        closestGrid.removeEventListener("scroll", onScrollCallback);
    }

    /**
     * This was a dead conditional test because closestModal and closestGrid always had a value so this would never pass and attached events are never getting removed.
     * Change to check if they are equal to document.body to match the rest of the tests and actually have the event removed correctly.
     */
    if (useDocument) {
        document.removeEventListener("scroll", onScrollCallback);
    }
}

function getClosestScrollContainer(triggerNode: Element): HTMLElement | undefined {
    const closestHeader = triggerNode.closest(".ant-layout-header");
    if (closestHeader !== null) {
        return closestHeader as HTMLElement;
    }

    // Need to account for both closestBtModalLayout and closestModal variations
    const closestBtModalLayout = triggerNode.closest(".ModalContentContainer");
    if (closestBtModalLayout !== null) {
        return closestBtModalLayout as HTMLElement;
    }

    return getClosestReactModal(triggerNode, "body");
}

/**
 * Grabs our API key for Google Recaptcha
 * @param
 * @example getGoogleRecaptchaKey();
 */
export function getGoogleRecaptchaKey() {
    return "6LdJSGAUAAAAAKG80vMH9dDYpF7ijlTztUEnILUo";
}

/**
 * Use this helper function when you want a control to appear over the top of another control (ex: tooltip, date/time pickers, etc)
 *
 * @param triggerNode
 * @param section the content of the "modal" is it's root wrapper. The "body" contians the scrollbar.
 */
export function getClosestModal(
    triggerNode?: Element | null,
    section: "content" | "body" = "content"
): HTMLElement {
    const closestReactModal = getClosestReactModal(triggerNode, section);
    return closestReactModal ?? document.body;
}

export function getClosestReactGrid(triggerNode?: Element): HTMLElement | undefined {
    // get the closest modal/drawer/popover for flyouts so the form a correct zindex context
    if (triggerNode) {
        const closestKendoGrid = triggerNode.closest(".k-grid-content.k-virtual-content");
        if (closestKendoGrid !== null) {
            return closestKendoGrid as HTMLElement;
        }

        const closestAntGrid = triggerNode.closest(".ant-table-body");
        if (closestAntGrid !== null) {
            return closestAntGrid as HTMLElement;
        }
    }

    return undefined;
}

export function getClosestReactModal(
    triggerNode?: Element | null,
    section: "content" | "body" = "content"
): HTMLElement | undefined {
    // get the closest modal/drawer/popover for flyouts so the form a correct zindex context
    if (triggerNode) {
        const closestHeader = triggerNode.closest(".ant-layout-header");
        if (closestHeader !== null) {
            return closestHeader as HTMLElement;
        }

        const closestModal = triggerNode.closest(`.ant-modal-${section}`);
        if (closestModal !== null) {
            return closestModal as HTMLElement;
        }

        const closestDrawer = triggerNode.closest(`.ant-drawer-${section}`);
        if (closestDrawer !== null) {
            return closestDrawer as HTMLElement;
        }

        const closestPopover = triggerNode.closest(`.ant-popover-${section}`);
        if (closestPopover !== null) {
            return closestPopover as HTMLElement;
        }

        const closestFullscreenElement = triggerNode.closest(`.Fullscreen`);
        if (closestFullscreenElement !== null) {
            return closestFullscreenElement as HTMLElement;
        }

        // If no ant design parents are found, look for our custom "magic" class to serve as our content root
        const closestZIndexContentRoot = triggerNode.closest(`.${BTContentRoot}`);
        if (closestZIndexContentRoot !== null) {
            return closestZIndexContentRoot as HTMLElement;
        }
    }

    return undefined;
}

/**
 * Use this helper function when you want a control to disappear or become sticky while scrolling on a page
 *
 * @param triggerNode
 * @param section the content of the "modal" is it's root wrapper. The "body" contians the scrollbar.
 */
export function getClosestModalOrGrid(
    triggerNode?: Element,
    section: "content" | "body" = "content"
): HTMLElement {
    const closestReactModal = getClosestReactModalOrGrid(triggerNode, section);
    return closestReactModal ?? document.body;
}

/**
 * Returns the closest Grid if defined. Other wise returns the closes Modal. Otherwise returns undefined.
 * @param triggerNode
 * @param section
 */
export function getClosestReactModalOrGrid(
    triggerNode?: Element,
    section: "content" | "body" = "content"
): HTMLElement | undefined {
    // get the closest modal/drawer/popover for flyouts so the form a correct zindex context
    if (!triggerNode) {
        return undefined;
    }

    return (
        getClosestReactGrid(triggerNode) ?? getClosestReactModal(triggerNode, section) ?? undefined
    );
}

export function isWindows(): boolean {
    return navigator.userAgent.indexOf("Win") !== -1;
}

export function boolToText(expr: boolean | undefined, textTrue: string, textFalse: string): string {
    if (isNullOrUndefined(expr)) {
        return "";
    }
    return expr ? textTrue : textFalse;
}

/**
 * **PREFER USING `displayWithFriendlyDefault` INSTEAD IF POSSIBLE**
 *
 * Friendly default display value to display when an element is considered empty
 */
export const friendlyDefaultDisplayValue = "--";
/**
 * Displays a friendly default display value ("--") when the passed ReactNode is null, undefined, or
 * whitespace
 * @param obj
 */
export function displayWithFriendlyDefault(obj: React.ReactNode) {
    return isNullOrUndefined(obj) || (typeof obj === "string" && obj.trim().length === 0)
        ? friendlyDefaultDisplayValue
        : obj;
}
