import { AttachedFiles } from "legacyComponents/FileUploadContainer.types";
import { Moment } from "moment";
import moment from "moment";
import { RouteComponentProps } from "react-router";

import { CostTypes } from "types/enum";

import { calculateBuilderCost, recalculateMarkupFields } from "utilities/lineItem/lineItemHelper";
import { IHasEmptyStateBannerProps } from "utilities/list/list.types";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { DismissButtonFormActions } from "commonComponents/entity/emptyState/common/DismissButton/DismissButton";
import { IGridSettingsHandler } from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.handler";
import {
    ICostLineItem,
    MarkupType,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { ICostCatalogHandler } from "entity/costCatalog/CostCatalog.api.handler";
import { default as CostGroupsForImportExample } from "entity/costCatalog/CostGroupImport.api.json";
import { CostItemListFormActions } from "entity/costCatalog/CostItemList/CostItemList.api.types";
import { ICostItemLineItem } from "entity/CostGroup/CostGroup.api.types";
import { ICostItemEntity } from "entity/costItem/CostItem/CostItem.api.types";
import { IFilterHandler } from "entity/filters/Filter/Filter.api.handler";
import { IExternalProductLink, VendorTypes } from "entity/vendor/common/vendor.types";

import { default as CostItemsImportAPIResponseExample } from "./CostItemImport.api.json";
import { ICostItemListHandler } from "./CostItemList/CostItemList.api.handler";

export type CostCatalogFormActions = DismissButtonFormActions | undefined;

export enum CostCatalogViewType {
    Setup = 1,
    LineItemContainer = 2,
    CostGroupEditor = 3,
    // VendorCatalog = 4,
    Proposal = 5,
    FormatTab = 6,
    EstimateList = 7,
    Rebates = 8,
}

export enum RecursiveUpdateTypes {
    DoNotUpdate = 0,
    SoftUpdate = 1,
    Update = 2,
}

export interface ICostCatalogProps extends RouteComponentProps, IHasEmptyStateBannerProps {
    viewType?: CostCatalogViewType;
    defaultTab?: CostCatalogTabs;
    costCatalogHandler?: ICostCatalogHandler;
    onAddCostItemLineItems?: (addFromCatalogRequest: IAddFromCatalogRequest) => Promise<void>;
    addToEntityDisplayName?: string;
    modalConfig?: IModalConfiguration;
    inactivationCutoffDate?: Moment;
    onUpdateParentLineItems?: (
        isBreakingCostItemLinks?: boolean | null,
        costItem?: ICostItemEntity
    ) => void;
    jobIds?: number[];
    listHandler?: ICostItemListHandler;
    filterHandler?: IFilterHandler;
    gridSettingsHandler?: IGridSettingsHandler;
    lineItemToReplace?: ICostLineItem;
    lockCostCode?: boolean;
    hasSingleSelectCostTypes?: boolean;
}

export class CostCatalogSettings {
    constructor(data: any) {
        this.canAddCostGroups = data.canAddCostGroups;
        this.canAddCostItems = data.canAddCostItems;
        this.canAddFromCatalog = data.canAddFromCatalog;
        this.hasCostItemImport = data.hasCostItemImport;
        this.showCostGroups = data.showCostGroups;
        this.showCostItems = data.showCostItems;
        this.vendorCatalogInformation =
            data.vendorCatalogInformation &&
            new VendorCatalogInformation(data.vendorCatalogInformation);
        this.hasCostCodesFeature = data.hasCostCodesFeature;
    }
    canAddCostGroups: boolean;
    canAddCostItems: boolean;
    canAddFromCatalog: boolean;
    hasCostItemImport: boolean;
    showCostGroups: boolean;
    showCostItems: boolean;
    vendorCatalogInformation: VendorCatalogInformation | null;
    hasCostCodesFeature: boolean;
}

export class VendorCatalogInformation {
    constructor(data: any) {
        this.homeDepotCatalogInformation =
            data.homeDepotCatalogInformation &&
            new HomeDepotCatalogInformation(data.homeDepotCatalogInformation);
        this.isNewToHomeDepotCatalogSync = data.isNewToHomeDepotCatalogSync;
        this.isHomeDepotCompatibleBuilderCountry = data.isHomeDepotCompatibleBuilderCountry;
    }

    homeDepotCatalogInformation: HomeDepotCatalogInformation | null;
    isNewToHomeDepotCatalogSync: boolean;
    isHomeDepotCompatibleBuilderCountry: boolean;
}

export class HomeDepotCatalogInformation {
    constructor(data: any) {
        this.isConnectedToHomeDepot = data.isConnectedToHomeDepot;
        this.lastHomeDepotSyncDate = data.lastHomeDepotSyncDate
            ? moment(data.lastHomeDepotSyncDate)
            : null;
        this.homeDepotStoreName = data.homeDepotStoreName;
        this.newHomeDepotProductCount = data.newHomeDepotProductCount;
    }

    isConnectedToHomeDepot: boolean;
    lastHomeDepotSyncDate: Moment | null;
    homeDepotStoreName: string;
    newHomeDepotProductCount: number;
}

export interface ICostCatalogState {
    settings: CostCatalogSettings;
    currentCostItemImportType: VendorTypes | null;
    actionBeingPerformed: CostCatalogFormActions;
    isHomeDepotUnconnectedBannerDismissed: boolean;
    // These props are passed to each tab to inform them when the component updates if they should refresh data based on changes in the other tab.
    shouldRefreshCostItems: boolean;
    shouldRefreshCostGroups: boolean;
}

export enum CostCatalogTabs {
    CostItems = "costItems",
    CostGroups = "costGroups",
}

export interface IAddFromCatalogRequest {
    lineItems: ICostItemLineItem[];
    formatParentId?: number;
    costGroups?: IAddCostGroup[];
    includeDescription?: boolean;
    includeFiles?: boolean;
}

export interface IAddCostGroup {
    costItemCount: number;
    costGroupId: number;
    description: string;
    title: string;
    files: AttachedFiles;
}
export interface ICostCatalogTitleHeaderProps extends RouteComponentProps {
    currentTab: CostCatalogTabs;
    settings: CostCatalogSettings;
    viewType: CostCatalogViewType;
    areItemsChecked: boolean;
    onAddGridLineItems?: () => Promise<void>;
    addToEntityDisplayName?: string;
    actionBeingPerformed?: CostItemListFormActions;
    filter?: React.ReactNode;
    exportButton?: React.ReactNode;
    checkedActions?: React.ReactNode;
}

export interface ICostItemTitleHeaderProps extends ICostCatalogTitleHeaderProps {
    onImportCostItems: (vendorType: VendorTypes) => void;
    checkedActions?: React.ReactNode;
    lineItemToReplace?: ICostLineItem;
}

export interface ICostGroupTitleHeaderProps extends ICostCatalogTitleHeaderProps {
    onCopyToCostGroup: () => Promise<void>;
    onAddNewCostGroup: () => void;
}

export class PendingUpdatesResponse {
    constructor(data: any) {
        this.pendingCostItemUpdates = data.pendingCostItemUpdates;
        this.hasPendingUpdates = data.hasPendingUpdates;
        this.costItemUpdateMessage = data.costItemUpdateMessage;
    }

    pendingCostItemUpdates: number;
    hasPendingUpdates: boolean;
    costItemUpdateMessage: string | null;
}

export class LineItemsBySelectionResponse {
    constructor(data: any) {
        this.lineItemIds = data.lineItemIds;
    }

    lineItemIds: number[];
}

export class CostItemsForImportResponse {
    constructor(data: typeof CostItemsImportAPIResponseExample) {
        this.lineItems = data.costItems.map((ci) => {
            const lineItem = {
                costCodeId: ci.CostCodeId,
                description: ci.Description,
                costItemId: ci.Id,
                markupPerUnit: ci.MarkupPerUnit ?? 0,
                markupPercent: ci.MarkupPercentage ?? 0,
                internalNotes: ci.Notes,
                ownerPrice: ci.OwnerPrice ?? 0,
                itemTitle: ci.ProductTitle,
                quantity: ci.Quantity,
                quantityIsLinked: ci.QuantityIsLinked,
                unit: ci.UnitType,
                unitCost: ci.unitCost,
                markupAmount: ci.MarkupPrice ?? 0,
                markupType: ci.MarkupColumn ?? MarkupType.percent,
                markupIsLinked: ci.MarkupColumn !== undefined && ci.MarkupColumn !== null,
                id: 0,
                builderCost: calculateBuilderCost(ci.unitCost, ci.Quantity),
                costTypes: ci.CostTypes,
                markedAs: ci.MarkedAs,
                costCodeTitle: "",
                costGroupQuantity: 1,
                costCategoryId: ci.CostCategoryId,
                vendorProductId: ci.VendorProductId ?? undefined,
                vendorType: ci.VendorType as VendorTypes,
                isDiscontinued: ci.IsDiscontinued,
                productLink: ci.ProductLink
                    ? ({
                          label: ci.ProductLink.label,
                          value: ci.ProductLink.value,
                          helpText: ci.ProductLink.helpText,
                      } as IExternalProductLink)
                    : undefined,
            };

            return recalculateMarkupFields(lineItem);
        });
        this.includeFiles = data.includeFiles;
    }

    lineItems: ICostItemLineItem[];
    includeFiles: boolean;
}

export class CostGroupsForImportResponse {
    constructor(data: typeof CostGroupsForImportExample) {
        const lineItems: ICopyCostGroupLineItem[] = data.costItems.map((ci) => ({
            builderCost: calculateBuilderCost(ci.unitCost, ci.Quantity),
            costCodeId: ci.CostCodeId,
            costItemId: ci.Id,
            costTypes: ci.CostTypes,
            markedAs: ci.MarkedAs,
            description: ci.Description,
            id: 0,
            internalNotes: ci.Notes,
            itemTitle: ci.ProductTitle,
            costCodeTitle: ci.CostCodeTitle,
            markupAmount: ci.MarkupPrice ?? 0,
            costGroupQuantity: 0,
            markupPerUnit: ci.MarkupPerUnit ?? 0,
            markupPercent: ci.MarkupPercentage ?? 0,
            markupType: ci.MarkupColumn ?? MarkupType.percent,
            markupIsLinked: ci.MarkupColumn !== undefined && ci.MarkupColumn !== null,
            ownerPrice: ci.OwnerPrice ?? 0,
            quantityIsLinked: ci.QuantityIsLinked,
            unitCost: ci.unitCost,
            quantity: ci.Quantity,
            unit: ci.UnitType,
            parentId: ci.ParentId,
            isSelected: true,
            proposalFormatItemId: ci.ProposalFormatItemId,
            costCategoryId: ci.CostCategoryId,
            vendorProductId: ci.VendorProductId,
            vendorType: ci.VendorType as VendorTypes,
            isDiscontinued: ci.IsDiscontinued,
            productLink: ci.ProductLink
                ? ({
                      label: ci.ProductLink.label,
                      value: ci.ProductLink.value,
                      helpText: ci.ProductLink.helpText,
                  } as IExternalProductLink)
                : undefined,
        }));
        this.costGroups = data.costGroups.map((cg) => {
            return {
                title: cg.title,
                id: cg.id,
                description: cg.description,
                lineItems: lineItems.filter((ci) => ci.parentId === cg.id),
                files: new AttachedFiles(cg.files),
            };
        });
        this.inactiveItems = data.inactiveItems;
        this.builderFlatRateId = data.builderFlatRateId;
    }

    costGroups: ICostGroupForImport[];
    inactiveItems: IInactiveLineItem[];
    builderFlatRateId: number;
}

export interface ICostGroupForImport {
    title: string;
    id: number;
    description: string;
    files: AttachedFiles;
    lineItems?: ICopyCostGroupLineItem[];
}

export interface ICopyCostGroupLineItem extends ICostItemLineItem {
    isSelected: boolean;
    proposalFormatItemId: number;
    costTypes: CostTypes[] | null;
}

export interface IInactiveLineItem {
    id: number;
    title: string;
    count: number;
}
