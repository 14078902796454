/* eslint-disable deprecate/member-expression */
import { ButtonProps, Modal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import classNames from "classnames";
import { Component, ReactNode } from "react";

import { AppProvider } from "helpers/AppProvider";

import { dispatchTracking, track } from "utilities/analytics/analytics";
import { formattedPlural, IFormattedPluralProps } from "utilities/string/string";

import {
    BTIconCheckCircleSuccess,
    BTIconCloseCircleError,
    BTIconInfoCircleFilled,
    BTIconWarningWarning,
} from "commonComponents/btWrappers/BTIcon";
import { BTModal, IBTModalProps } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";

import "./BTConfirm.less";

interface IBTModalFuncProps extends Omit<ModalFuncProps, "okButtonProps | cancelButtonProps"> {
    okButtonProps?: ButtonProps & { "data-testid"?: string };
    cancelButtonProps?: ButtonProps & { "data-testid"?: string };
}
const defaultModalProps: IBTModalFuncProps = {
    zIndex: 5000,
    centered: true,
    autoFocusButton: "cancel",
    icon: <></>,
    okButtonProps: {
        "data-testid": "confirmPrompt",
    } as any,
    cancelButtonProps: {
        "data-testid": "cancelPrompt",
    },
};

/**
 * Returns identifier that should be used in analytics tracking.
 * NOTE: We have limited info around these prompts, so this attempts to use what
 * relevant data we have in order to supply some context around the event.
 * @param props
 * @returns
 */
const getPromptTitleForTracking = (props: IBTModalFuncProps) => {
    if (typeof props.title === "string") {
        return props.title;
    } else if (typeof props.content === "string") {
        return props.content;
    } else {
        return "Confirmation";
    }
};

/**
 * Handles onOk and OnCancel callbacks and closing of the prompt if necessary based on antd
 * documentation here (see onOk & onCancel): https://ant.design/components/modal
 * @param callback onOk or onCancel callback supplied by the consumer
 * @param close close param supplied by antd
 */
const handleOkCancelCallback = async (
    callback: ((...args: any[]) => any) | undefined,
    close: any
) => {
    // AntD allows the callback functions to control whether the prompt is closed
    // automatically, so mimicking that behavior via shouldNotClose.
    const shouldNotClose = await callback?.(close);

    // Manually close the prompt here if the supplied callback doesn't handle it (i.e. doesn't accept the param)
    // and didn't specify to NOT close
    if (shouldNotClose !== true && !callback?.length && typeof close === "function") {
        close();
    } else {
        return shouldNotClose;
    }
};

/** Wraps antd Modal.confirm
 * NOTE: If onOk isn't wrapped in a function, additional props are passed.
 * @example
    btConfirm({
        title: "Are you sure you want to do this?",
        content: "Doing this may have unintended consequences",
        okText: "Yes",
        cancelText: "No",
        okType: "success",
        onOk: async () => {
            try {
                this.props.handler.doSomething(this.props.XXXXXXXXXXXXXXXXXX.id);
            }
            catch (e) {
                showAPIErrorMessage(e);
            }
        }
    });
*/
export const btConfirm = function (props: IBTModalFuncProps) {
    const promptTitle = getPromptTitleForTracking(props);
    const trackingData = {
        component: "Confirmation",
        uniqueId: promptTitle,
        extraInfo: {
            promptType: "btConfirm",
            promptTitle: promptTitle,
        },
    };

    props.title = wrapElementInAppProvider(props.title);
    props.content = wrapBodyContent(props.content);
    const combinedProps = { ...defaultModalProps, ...props };
    combinedProps.className = classNames(combinedProps.className, "BTConfirm");
    combinedProps.modalRender = (node) => {
        return wrapModalRenderInFocusProvider(node, props.modalRender);
    };
    combinedProps.onCancel = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.cancelButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onCancel, close);
    };
    combinedProps.onOk = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.okButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onOk, close);
    };
    combinedProps.afterClose = () => {
        dispatchTracking({
            ...trackingData,
            event: "ModalClose",
            uniqueId: promptTitle,
            element: "Confirmation",
        });
        props.afterClose?.();
    };

    dispatchTracking({
        ...trackingData,
        event: "ModalOpen",
        uniqueId: promptTitle,
        element: "Confirmation",
    });
    Modal.confirm(combinedProps);
};

// Ignoring rule as we need to use a type with a literal in an intersection.
type IEntityDeleteProps = Omit<IBTModalFuncProps, "okText" | "title" | "content"> & {
    entityName: string | IFormattedPluralProps<string>;
};

interface IEntityMoveToTrashProps extends IEntityDeleteProps {
    additionalContent?: string;
}

type BTDeleteConfirmProps = IBTModalFuncProps | IEntityDeleteProps;

function isEntityDelete(
    props: IBTModalFuncProps | IEntityDeleteProps
): props is IEntityDeleteProps {
    return (props as IEntityDeleteProps).entityName !== undefined;
}

const btDeleteConfirmBase = function (props: BTDeleteConfirmProps) {
    let entityNameToDisplay;
    if (isEntityDelete(props)) {
        entityNameToDisplay =
            typeof props.entityName !== "string"
                ? formattedPlural(props.entityName)
                : props.entityName;
    }
    const promptTitle = entityNameToDisplay
        ? `Delete ${entityNameToDisplay}`
        : getPromptTitleForTracking(props);
    const trackingData = {
        component: "Confirmation",
        uniqueId: promptTitle,
        extraInfo: {
            promptType: "btDeleteConfirm",
            promptTitle: promptTitle,
        },
    };

    const deleteDefaultProps: IBTModalFuncProps = {
        autoFocusButton: "cancel",
        okType: "danger",
        okText: "Delete",
    };

    if (isEntityDelete(props)) {
        deleteDefaultProps.okText = `Delete`;
        deleteDefaultProps.okType = "danger";
        deleteDefaultProps.title = `Delete ${entityNameToDisplay}?`;
        deleteDefaultProps.content = `Are you sure you want to permanently delete ${
            typeof props.entityName !== "string"
                ? props.entityName.value > 1
                    ? "these"
                    : "this"
                : "this"
        } ${entityNameToDisplay}?`;
    }

    const deleteCombinedProps = { ...defaultModalProps, ...deleteDefaultProps };
    const combinedProps = { ...deleteCombinedProps, ...props };

    combinedProps.title = wrapElementInAppProvider(combinedProps.title);
    combinedProps.content = wrapBodyContent(combinedProps.content);
    combinedProps.className = classNames(combinedProps.className, "BTConfirm");
    combinedProps.modalRender = (node) => {
        return wrapModalRenderInFocusProvider(node, props.modalRender);
    };

    combinedProps.onCancel = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.cancelButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onCancel, close);
    };
    combinedProps.onOk = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.okButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onOk, close);
    };
    combinedProps.afterClose = () => {
        dispatchTracking({
            ...trackingData,
            event: "ModalClose",
            uniqueId: promptTitle,
            element: "Confirmation",
        });
        props.afterClose?.();
    };

    dispatchTracking({
        ...trackingData,
        event: "ModalOpen",
        uniqueId: promptTitle,
        element: "Confirmation",
    });
    Modal.confirm(combinedProps);
};

/**
 *
 * @param itemName
 * @param props
 * @example
 * btDeleteConfirm({
 *   entityName: "Change Order",
 *   onOk: async () => {
 *      // delete api call here...
 *   }
 * })
 */
export const btDeleteConfirm = function (props: BTDeleteConfirmProps) {
    btDeleteConfirmBase(props);
};

export const btMoveToTrashConfirm = function (props: IEntityMoveToTrashProps) {
    const entityNameToDisplay =
        typeof props.entityName !== "string" ? formattedPlural(props.entityName) : props.entityName;
    let content = `Are you sure you want to move ${
        typeof props.entityName !== "string"
            ? formattedPlural({ value: props.entityName.value, one: "this", other: "these" })
            : "this"
    } ${entityNameToDisplay} to Trash?`;

    if (props.additionalContent) {
        content = `${content} ${props.additionalContent}`;
    }

    const moveToTrashProps = {
        ...props,
        okText: `Move to Trash`,
        title: "Move to Trash?",
        content,
    };
    btDeleteConfirmBase(moveToTrashProps);
};

export const btDeleteForeverConfirm = function (props: IEntityDeleteProps) {
    const entityNameToDisplay =
        typeof props.entityName !== "string" ? formattedPlural(props.entityName) : props.entityName;
    const content = `${
        typeof props.entityName !== "string"
            ? formattedPlural({ value: props.entityName.value, one: "This", other: "These" })
            : "This"
    } ${entityNameToDisplay} will be deleted forever and cannot be recovered.`;

    const moveToTrashProps = {
        ...props,
        okText: `Delete Forever`,
        title: "Delete Forever?",
        content,
    };
    btDeleteConfirmBase(moveToTrashProps);
};

/** Wraps antd Modal.info */
export const btInfo = function (props: IBTModalFuncProps) {
    const promptTitle = getPromptTitleForTracking(props);
    const trackingData = {
        component: "Confirmation",
        uniqueId: promptTitle,
        extraInfo: {
            promptType: "btInfo",
            promptTitle: promptTitle,
        },
    };

    // Per UX, we still want to show the icon on this modal. bt-109213.
    const { ...rest } = defaultModalProps;
    props.title = wrapElementInAppProvider(props.title);
    props.content = wrapBodyContent(props.content);
    const combinedProps = { ...rest, ...props };
    combinedProps.className = classNames(combinedProps.className, "BTConfirm");
    combinedProps.modalRender = (node) => {
        return wrapModalRenderInFocusProvider(node, props.modalRender);
    };

    combinedProps.onCancel = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.cancelButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onCancel, close);
    };
    combinedProps.onOk = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.okButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onOk, close);
    };
    combinedProps.afterClose = () => {
        dispatchTracking({
            ...trackingData,
            event: "ModalClose",
            uniqueId: promptTitle,
            element: "Confirmation",
        });
        props.afterClose?.();
    };

    dispatchTracking({
        ...trackingData,
        event: "ModalOpen",
        uniqueId: promptTitle,
        element: "Confirmation",
    });
    Modal.info(combinedProps);
};

/** Wraps antd Modal.error */
export const btError = function (props: IBTModalFuncProps) {
    const promptTitle = getPromptTitleForTracking(props);
    const trackingData = {
        component: "Confirmation",
        uniqueId: promptTitle,
        extraInfo: {
            promptType: "btError",
            promptTitle: promptTitle,
        },
    };

    // Per UX, we still want to show the icon on this modal. bt-109213.
    const { ...rest } = defaultModalProps;
    props.title = wrapElementInAppProvider(props.title);
    props.content = wrapBodyContent(props.content);
    const combinedProps = { ...rest, ...props };
    combinedProps.className = classNames(combinedProps.className, "BTConfirm");
    combinedProps.modalRender = (node) => {
        return wrapModalRenderInFocusProvider(node, props.modalRender);
    };

    combinedProps.onCancel = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.cancelButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onCancel, close);
    };
    combinedProps.onOk = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.okButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onOk, close);
    };
    combinedProps.afterClose = () => {
        dispatchTracking({
            ...trackingData,
            event: "ModalClose",
            uniqueId: promptTitle,
            element: "Confirmation",
        });
        props.afterClose?.();
    };

    dispatchTracking({
        ...trackingData,
        event: "ModalOpen",
        uniqueId: promptTitle,
        element: "Confirmation",
    });
    Modal.error(combinedProps);
};

/** Wraps antd Modal.warning */
export const btWarning = function (props: IBTModalFuncProps) {
    const promptTitle = getPromptTitleForTracking(props);
    const trackingData = {
        component: "Confirmation",
        uniqueId: promptTitle,
        extraInfo: {
            promptType: "btWarning",
            promptTitle: promptTitle,
        },
    };

    props.title = wrapElementInAppProvider(props.title);
    props.content = wrapBodyContent(props.content);
    const combinedProps = { ...defaultModalProps, ...props };
    combinedProps.className = classNames(combinedProps.className, "BTConfirm");
    combinedProps.modalRender = (node) => {
        return wrapModalRenderInFocusProvider(node, props.modalRender);
    };

    combinedProps.onCancel = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.cancelButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onCancel, close);
    };
    combinedProps.onOk = async (close) => {
        dispatchTracking({
            ...trackingData,
            event: "ButtonClick",
            uniqueId: combinedProps.okButtonProps?.["data-testid"],
            element: "Button",
        });

        return await handleOkCancelCallback(props.onOk, close);
    };
    combinedProps.afterClose = () => {
        dispatchTracking({
            ...trackingData,
            event: "ModalClose",
            uniqueId: promptTitle,
            element: "Confirmation",
        });
        props.afterClose?.();
    };

    dispatchTracking({
        ...trackingData,
        event: "ModalOpen",
        uniqueId: promptTitle,
        element: "Confirmation",
    });
    Modal.warning(combinedProps);
};

// Wrapping content in app provider for when we alert currencies
const wrapElementInAppProvider = function (element?: React.ReactNode) {
    if (element) {
        return <AppProvider isRoot={false}>{element}</AppProvider>;
    }
    return undefined;
};

// Wrapping modal body content in app provider and in a container div to set modal extents
const wrapBodyContent = function (bodyContent?: React.ReactNode) {
    if (bodyContent) {
        return (
            <div className="BTConfirm-BodyContainer">{wrapElementInAppProvider(bodyContent)}</div>
        );
    }
    return undefined;
};

const wrapModalRenderInFocusProvider = function (
    node: ReactNode,
    modalRender?: (node: ReactNode) => ReactNode
) {
    return <FocusProvider>{modalRender ? modalRender(node) : node}</FocusProvider>;
};

export type ModalReturnTypes = "ok" | "cancel";

const promisify = (modalFunc: (props: IBTModalFuncProps) => void) => {
    const asyncModalFunc = async (props: Omit<IBTModalFuncProps, "onOk" | "onCancel">) => {
        return new Promise<ModalReturnTypes>((resolve) => {
            modalFunc({
                ...props,
                onOk: () => resolve("ok"),
                onCancel: () => resolve("cancel"),
            });
        });
    };

    return asyncModalFunc;
};

export const btConfirmAsync = promisify(btConfirm);
export const btInfoAsync = promisify(btInfo);
export const btWarningAsync = promisify(btWarning);
export const btErrorAsync = promisify(btError);

// The following logic is necessary because of issues w/ Omit in conjunction with union types
// (can't do Omit<IBTModalFuncProps | IEntityDeleteProps, "onOk" | "onCancel"> in promisify)
export const btDeleteConfirmAsync = async (props: BTDeleteConfirmProps) => {
    if (props.onOk || props.onCancel) {
        throw "onOk / onCancel are not valid props. Instead, use the promise result to determine control flow";
    }

    return new Promise<ModalReturnTypes>((resolve) => {
        btDeleteConfirm({
            ...props,
            onOk: () => resolve("ok"),
            onCancel: () => resolve("cancel"),
        });
    });
};

type ExcludeFromWrapper = "centered" | "width" | "closable" | "beforeClose" | "setPageTitle";
export type BTConfirmIconType = "info" | "warning" | "error" | "success";
export interface IBTConfirmProps extends Omit<IBTModalProps<unknown>, ExcludeFromWrapper> {
    icon?: BTConfirmIconType;
    fitToContent?: boolean;
    closable?: boolean;
    beforeClose?: () => void;
    /**
     * Removes the modal's header. This will also make the modal NOT closeable and also NOT show the modal's title.
     * @default false
     */
    removeHeader?: boolean;
}

export const BTConfirmDefaultProps: Readonly<Partial<IBTConfirmProps>> = {
    afterClose: (): void => {},
    footer: null,
    removeBodyPadding: false,
    isLoading: false,
    overflow: "auto",
    closable: false,
    beforeClose: (): void => {},
    removeHeader: false,
};

/**
 * Temporary wrapper to disguise a BTModal as a confirm.
 */
@track((props) => ({ component: "Confirmation", uniqueId: props.title, extraInfo: "BTConfirm" }))
export class BTConfirm extends Component<IBTConfirmProps> {
    static defaultProps = BTConfirmDefaultProps;

    render() {
        const { icon, title, children, className, removeHeader, ...otherProps } = this.props;

        let iconNode: React.ReactNode;
        switch (icon) {
            case "error":
                iconNode = <BTIconCloseCircleError />;
                break;

            case "info":
                iconNode = <BTIconInfoCircleFilled type="info" />;
                break;

            case "success":
                iconNode = <BTIconCheckCircleSuccess />;
                break;

            case "warning":
                iconNode = <BTIconWarningWarning />;
                break;
        }

        return (
            <BTModal
                data-testid="btModalConfirm"
                {...otherProps}
                setPageTitle={false}
                centered
                className={classNames("BTConfirm", className)}
                width={this.props.fitToContent ? "auto" : "416px"}
                closable={this.props.closable}
                beforeClose={this.props.beforeClose!}
                removeHeader={removeHeader || !this.props.closable}
            >
                {title && (
                    <div className="BTConfirmTitle">
                        {iconNode}
                        <BTTitle level={3}>{title}</BTTitle>
                    </div>
                )}
                {children}
            </BTModal>
        );
    }
}

export class BTConfirmHeader extends Component<{ className?: string }> {
    render = () => divWithClass("BTConfirmHeader", this.props.className, this.props.children);
}

export class BTConfirmContent extends Component<{ className?: string }> {
    render = () => divWithClass("BTConfirmContent", this.props.className, this.props.children);
}

export class BTConfirmFooter extends Component<{ className?: string }> {
    render = () => divWithClass("BTConfirmFooter", this.props.className, this.props.children);
}

function divWithClass(mainClassName: string, otherClassName?: string, children?: React.ReactNode) {
    return <div className={classNames(mainClassName, otherClassName)}>{children}</div>;
}
