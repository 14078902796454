export enum ChangeOrderStatuses {
    UNRELEASED = 0,
    PENDING = 1,
    DECLINED = 2,
    APPROVED = 3,
    MANUAL = 4,
    MANUAL_DECLINE = 5,
    ALL = 6, // This may only be used for filtering on the backend
    EXPIRED = 7,
    ALL_FOR_OWNER = 8, // / This may only be used for filtering on the backend
    OWNER_REQUESTED = 9,
}

export const undecidedStatuses = [
    ChangeOrderStatuses.UNRELEASED,
    ChangeOrderStatuses.PENDING,
    ChangeOrderStatuses.OWNER_REQUESTED,
    ChangeOrderStatuses.EXPIRED,
];
export const unreleaseableStatuses = [
    ChangeOrderStatuses.PENDING,
    ChangeOrderStatuses.DECLINED,
    ChangeOrderStatuses.MANUAL,
    ChangeOrderStatuses.MANUAL_DECLINE,
    ChangeOrderStatuses.EXPIRED,
];
export const releaseableStatuses = [
    ChangeOrderStatuses.UNRELEASED,
    ChangeOrderStatuses.OWNER_REQUESTED,
];
export const decidedStatuses = [
    ChangeOrderStatuses.APPROVED,
    ChangeOrderStatuses.DECLINED,
    ChangeOrderStatuses.MANUAL,
    ChangeOrderStatuses.MANUAL_DECLINE,
];
export const deleteStatuses = [
    ChangeOrderStatuses.PENDING,
    ChangeOrderStatuses.UNRELEASED,
    ChangeOrderStatuses.OWNER_REQUESTED,
    ChangeOrderStatuses.MANUAL,
    ChangeOrderStatuses.MANUAL_DECLINE,
];

export enum ResetOptionTypes {
    Merge = 0,
    Delete = 1,
}
