import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormikErrors, FormikTouched } from "formik";
import { Component } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { getAllValues } from "utilities/form/form";
import yup from "utilities/form/yup";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTSelectUser } from "commonComponents/btWrappers/BTSelectUser/BTSelectUser";

export interface INotificationAssignedToProps<FormValuesType extends object, ExtraDataType> {
    shouldNotifyFieldName: KeyOfOrString<FormValuesType> & string;
    shouldNotifyValue: boolean;
    usersToNotifyFieldName: KeyOfOrString<FormValuesType> & string;
    usersToNotifyValue: number[];
    users: BTSelectItem<ExtraDataType>[];
    assigneeType: string;
    assigneeTitle?: string;
    errors: FormikErrors<FormValuesType>;
    handleChange: (e: CheckboxChangeEvent) => void;
    schema: yup.ObjectSchema<yup.Shape<object, FormValuesType>>;
    setFieldTouched: (field: string, isTouched?: boolean) => void;
    setFieldValue: (field: string, value: any) => void;
    touched: FormikTouched<FormValuesType>;
}

export class NotificationAssignedTo<FormValuesType extends object, ExtraDataType> extends Component<
    INotificationAssignedToProps<FormValuesType, ExtraDataType>
> {
    private onChangeAssignedTo = (e: CheckboxChangeEvent) => {
        const { setFieldValue, usersToNotifyFieldName, users, handleChange } = this.props;
        if (e.target.checked) {
            // When the user checks the box, manually select all items in the dropdown
            setFieldValue(usersToNotifyFieldName, getAllValues(users));
        } else {
            // When the user unchecks the box, manually clear all selected items
            setFieldValue(usersToNotifyFieldName, []);
        }
        handleChange(e);
    };

    render() {
        const {
            shouldNotifyFieldName,
            shouldNotifyValue,
            usersToNotifyFieldName,
            usersToNotifyValue,
            users,
            assigneeType,
            setFieldTouched,
            setFieldValue,
        } = this.props;

        const assigneeTitle = this.props.assigneeTitle ?? "Assignees";

        return (
            <>
                <BTFormItemAutomatic<FormValuesType> id={shouldNotifyFieldName} label="">
                    <BTCheckbox<FormValuesType>
                        id={shouldNotifyFieldName}
                        data-testid={shouldNotifyFieldName}
                        checked={shouldNotifyValue}
                        onChange={this.onChangeAssignedTo}
                    >
                        Notify {assigneeType} {assigneeTitle}
                    </BTCheckbox>
                </BTFormItemAutomatic>
                {shouldNotifyValue && users.length > 0 && (
                    <div className="AssigneeDropdown">
                        <BTFormItemAutomatic<FormValuesType> id={usersToNotifyFieldName} label="">
                            <BTSelectUser<FormValuesType, ExtraDataType>
                                id={usersToNotifyFieldName}
                                data-testid={usersToNotifyFieldName}
                                value={usersToNotifyValue}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                treeData={users}
                                multiple={true}
                            />
                        </BTFormItemAutomatic>
                    </div>
                )}
            </>
        );
    }
}
