import { Typography } from "antd";
import { Component } from "react";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { IInfoWindowContentProps } from "commonComponents/entity/map/Map.types";
import { GetIconPathForPinType } from "commonComponents/entity/map/mapUtilities";

export class InfoWindowContent extends Component<IInfoWindowContentProps> {
    render() {
        const showButton = this.props.buttonText && this.props.buttonCallback;

        return (
            <div className="padding-all-xs">
                <BTRow gutter={8} align="middle">
                    {this.props.pinType && (
                        <BTCol>
                            <img
                                src={GetIconPathForPinType(this.props.pinType)}
                                alt={this.props.pinType.toString()}
                                height={32}
                                width={32}
                            />
                        </BTCol>
                    )}
                    <BTCol>
                        {this.props.header && (
                            <BTRow gutter={8}>
                                <Typography.Text>{this.props.header}</Typography.Text>
                            </BTRow>
                        )}
                        {this.props.body && (
                            <BTRow gutter={8}>
                                <Typography.Text>{this.props.body}</Typography.Text>
                            </BTRow>
                        )}
                        {this.props.footer && (
                            <BTRow gutter={8}>
                                <Typography.Text>{this.props.footer}</Typography.Text>
                            </BTRow>
                        )}
                        {showButton && (
                            <BTRow gutter={8}>
                                <BTButton
                                    type="link"
                                    size="small"
                                    className="ml20"
                                    data-testid="override"
                                    onClick={this.props.buttonCallback}
                                >
                                    {this.props.buttonText}
                                </BTButton>
                            </BTRow>
                        )}
                    </BTCol>
                </BTRow>
            </div>
        );
    }
}
