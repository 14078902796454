import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IBidPackageProps } from "entity/bidPackage/BidPackage/BidPackage";

const BidPackage = lazyLoadWithRetry(() => import("./BidPackage"));

export const RouteBidPackage = (
    props: Omit<IBidPackageProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.bidPackage.details}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search, {
                parseBooleans: true,
            });
            const bidPackageId = parseInt(routeValues.id);
            const jobId = parseInt(routeValues.jobId);
            const isFromRfi = qsValues.fromRFI;
            const isTemplateMode = qsValues.isTemplateMode;
            const initialTab = qsValues.initialTab;

            return (
                <BTSuspense>
                    <BidPackage
                        {...props}
                        {...routeProps}
                        id={bidPackageId}
                        jobId={jobId}
                        isFromRfi={isFromRfi}
                        isTemplateMode={isTemplateMode}
                        initialTab={initialTab}
                    />
                </BTSuspense>
            );
        }}
    />
);
