import moment from "moment";

import { IBaseEntity } from "types/apiResponse/apiResponse";
import { DocumentInstanceType, FileStatus, MediaType } from "types/enum";
import { BTFilePermissions, BTViewingPermissions } from "types/filePermissions";

import { isInPortal } from "utilities/portal/portal";

import { FilesFromExternalUrlsResponse } from "entity/document/Document/Document.api.types";
import { BTServiceDocumentItem } from "entity/media/common/mediaTypes";

import AttachedFilesResponse from "./FileUploadContainer.api.json";

type FileViewOptionsData = (typeof AttachedFilesResponse)["newFileOptions"];

/** implement this interface on your entity to have attachments */
export interface IHasAttachedFiles extends IBaseEntity {
    documentInstanceType: DocumentInstanceType;
    name: string;
    leadId?: number;

    /**
     * Required to load the inital list of files
     * Generally this data is passed down from the API with the key "attachedFiles"
     * In legacy webforms this param is called "fileViewerVM"
     */
    attachedFiles: AttachedFiles;

    /**
     * Used for bulk file uploads.
     * This will replace the typical id prop
     */
    bulkUploadEntityIds?: number[];
}

export class AttachedFiles {
    constructor(data: any) {
        this.title = data.title;
        this.viewingPermissions = new ViewingPermissions(data.viewingPermissions);

        this.newFileOptions = new FileViewOptions(data.newFileOptions);

        this.files = data.files.map((file: any) => new BTFileSystem(file));
    }

    title: string;
    viewingPermissions: ViewingPermissions;
    newFileOptions: any;
    files: BTFileSystem[];
}

export class FileViewOptions {
    constructor(data: FileViewOptionsData) {
        this.title = data.title;
        this.loginType = data.loginType;
        this.fileOptionKey = data.fileOptionKey;
        this.defaultValue = data.defaultValue;
        this.canEdit = data.canEdit;
        this.title = data.title;
        this.visible = data.visible;
        this.options = data.options;
    }

    title: string | null;
    loginType: number;
    fileOptionKey: number;
    defaultValue: boolean;
    canEdit: boolean;
    visible: boolean;
    options: FileViewOptions[] | null;
}

export class BTServiceFileUpload {
    constructor(data: any) {
        this.ext = data.ext;
        this.fileName = data.fileName;
        this.hasChanged = data.hasChanged;
        this.imagePath = data.imagePath;
        this.tempId = data.tempId;
        this.url = data.url;
        this.fileSize = data.fileSize;
    }

    ext: string;
    fileName: string;
    hasChanged: true;
    imagePath: string;
    tempId: number;
    url: string;
    fileSize: number;
}

export function MapBTServiceDocumentItemToFileUpload(data: BTServiceDocumentItem<any>) {
    return new BTServiceFileUpload({
        ext: data.ext,
        fileName: data.fileName,
        hasChanged: false,
        imagePath: "",
        tempId: 0,
        url: data.url,
    });
}

export function MapFileUploadToBTServiceDocumentItem(data: BTServiceFileUpload) {
    return new BTServiceDocumentItem({
        tempId: data.tempId,
        ext: data.ext,
        title: data.fileName,
        url: data.url,
        fileSize: data.fileSize,
    });
}

/*
    When we need to attach existing docs, they come through the KO wrapper in the format below, which
    we pass through to the POST. Use this when you have BTFiles you need to attach from the React side.
*/
export function MapBTFileToFileUpload(data: BTFileSystem, entityType: DocumentInstanceType) {
    const perms = { ...data.viewingPermissions };
    return {
        id: data.id,
        uniqueId: data.id,
        ext: data.extension,
        fileSize: data.fileSize,
        fileName: data.title,
        hasChanged: true,
        isOriginalResolution: data.isOriginalResolution,
        entityType,
        mediaType: data.mediaType,
        newFileOptions: {
            builder: getNewFileOption("Builder"),
            subs: getNewFileOption("Subs"),
            owner: getNewFileOption("Owner"),
        },
        notifications: perms,
        viewingPermissions: perms,
        permissions: { ...data.permissions },
    } as any;
}

function MapBTFileSystemToAttachedFilesConfig(data: BTFileSystem, canAnnotate: boolean) {
    return {
        ...data,
        hasChanged: true,
        isOriginalResolution: true,
        permissions: {
            ...data.permissions,
            canEditOnline: false,
            canDrawOnline: false,
            canAnnotate: canAnnotate, // technically this should be true if the user is a builder
        },
        isOnNewEntity: true,
    } as BTFileSystem;
}

function getNewFileOption(title: string) {
    const perms = {
        canEdit: false,
        defaultValue: false,
        visible: false,
    };
    return {
        title,
        notify: perms,
        show: perms,
    };
}

/** Used by create endpoints for adding new files to an entity */
export class AttachedFilesRequest {
    constructor(data?: any) {
        if (!data) {
            this.removeDocs = [];
            this.attachDocs = [];
            this.updateDocs = [];
        } else {
            this.removeDocs = data.removeDocs;
            this.attachDocs = data.attachDocs;
            this.updateDocs = data.updateDocs;
        }
    }

    removeDocs: BTServiceFileUpload[];
    attachDocs: BTServiceFileUpload[];
    updateDocs: BTServiceFileUpload[];
}

export class BrowseExistingDocs {
    constructor(data: any) {
        this.canBrowsePhotos = data.canBrowsePhotos;
        this.canBrowseDocuments = data.canBrowseDocuments;
        this.canBrowseVideos = data.canBrowseVideos;
    }

    canBrowsePhotos: boolean;
    canBrowseDocuments: boolean;
    canBrowseVideos: boolean;
}

export class ViewingPermissions {
    constructor(data: any) {
        this.allAllowedExtensions = data.allAllowedExtensions;
        this.allowFullSizeImages = data.allowFullSizeImages;
        this.allowedDocumentExtensions = data.allowedDocumentExtensions;
        this.allowedPhotoExtensions = data.allowedPhotoExtensions;
        if (data.allowedVideoExtensions) {
            this.allowedVideoExtensions = data.allowedVideoExtensions;
        }
        this.browseExistingDocs = new BrowseExistingDocs(data.browseExistingDocs);
        this.canAddFiles = data.canAddFiles;
        this.documentListType = data.documentListType;
        this.isFileReplaceMode = data.isFileReplaceMode;
        this.jobsiteID = data.jobsiteID;
        this.leadId = data.leadId;
        this.maxFileSize = data.maxFileSize;
        this.maxFiles = data.maxFiles;
        this.minFileSize = data.minFileSize;
        this.videoMaxSizeMB = data.videoMaxSizeMB;
        this.videoDurationLimit = data.videoDurationLimit;
        this.ignoreBlacklist = data.ignoreBlacklist;
    }

    allAllowedExtensions: string[];
    allowFullSizeImages: boolean;
    allowedDocumentExtensions: string[];
    allowedPhotoExtensions: string[];
    allowedVideoExtensions?: string[];
    browseExistingDocs: BrowseExistingDocs;
    canAddFiles: boolean;
    documentListType: number;
    isFileReplaceMode: boolean;
    jobsiteID: number | null;
    leadId: number | null;
    maxFileSize: number;
    maxFiles: number;
    minFileSize: number;
    videoMaxSizeMB: number | null;
    videoDurationLimit: number | null;
    ignoreBlacklist: boolean;
}

/** Represents a file, response from tempFile upload, combinedFileViewer, etc */
export class BTFileSystem {
    constructor(data: any) {
        this.id = data.id;
        this.documentInstanceId = data.documentInstanceId;
        this.builderId = data.builderId;
        this.jobsiteId = data.jobsiteId;
        this.entityId = data.entityId;
        this.isOnNewEntity = data.isOnNewEntity;
        this.title = data.title || data.fileName;
        this.fileName = this.title;
        this.extension = data.extension || data.ext;
        this.ext = this.extension;
        this.isPhoto = data.isPhoto;
        this.mediaType = data.mediaType;
        this.folderType = data.folderType;
        this.permissions = data.permissions ? new BTFilePermissions(data.permissions) : undefined;
        this.viewingPermissions = data.viewingPermissions
            ? new BTViewingPermissions(data.viewingPermissions)
            : undefined;
        this.commentCount = data.commentCount;
        this.subFolderDocumentCount = data.subFolderDocumentCount;
        this.subFolderCount = data.subFolderCount;
        this.fileSize = data.fileSize;
        this.fileSizeFormatted = data.fileSizeFormatted;
        this.thumbnail = data.thumbnail;
        this.docPath = data.docPath;
        this.annotatedDocPath = data.annotatedDocPath;
        this.downloadDocPath = data.downloadDocPath;
        this.downloadAnnotatedDocPath = data.downloadAnnotatedDocPath;
        this.previewDocPath = data.previewDocPath;
        this.previewAnnotatedDocPath = data.previewAnnotatedDocPath;
        this.videoId = data.videoId;
        this.docPathWithBranding = data.docPathWithBranding;
        this.annotatedDocPathWithBranding = data.annotatedDocPathWithBranding;
        this.addedBy = data.addedBy;
        this.dateTaken = data.dateTaken ? moment(data.dateTaken) : undefined;

        this.lastModifiedOn = data.lastModifiedOn ? moment(data.lastModifiedOn) : null;
        // todo switch to moment
        this.dateAttached = data.dateAttached;

        this.hasAnnotations = data.hasAnnotations;
        this.annotationGroupId = data.annotationGroupId;
        this.hasAnnotationLinks = data.hasAnnotationLinks;
        this.annotationLinkCount = data.annotationLinkCount;
        this.annotationCount = data.annotationCount;
        this.isOriginalResolution = data.isOriginalResolution;
        this.is360Media = data.is360Media;
        this.duration = data.duration;
        this.mainFileId = data.mainFileId;
        this.isTempFile = !!data.isTempFile;
        if (data.isTempFile) {
            this.tempId = data.id;
        }
        this.status = data.status === undefined ? FileStatus.Pending : data.status;
        this.fileStatus =
            this.mediaType === MediaType.Video && data.isTempFile
                ? FileStatus.Pending
                : data.status;
        this.getNative = data.nativeFile;
        this.nativeFile = data.nativeFile;

        this.existingBTDoc = data.existingBTDoc;
        this.uniqueId = data.uniqueId;
    }

    id: number;
    documentInstanceId: string;
    builderId: number;
    jobsiteId: number | null;
    entityId: number;
    title: string;
    extension: string;
    isPhoto: boolean;
    mediaType: MediaType;
    folderType: number;
    permissions?: BTFilePermissions;
    viewingPermissions?: BTViewingPermissions;
    commentCount: number;
    subFolderDocumentCount: number;
    subFolderCount: number;
    fileSize: number;
    fileSizeFormatted: string;
    thumbnail: string;
    docPath: string;
    annotatedDocPath: string;
    downloadDocPath: string;
    downloadAnnotatedDocPath: string;
    previewDocPath: string;
    previewAnnotatedDocPath: string;
    videoId: number | null;
    docPathWithBranding: string;
    annotatedDocPathWithBranding: string;
    addedBy: string;
    lastModifiedOn: moment.Moment | null;
    dateAttached: moment.Moment;
    hasAnnotations: boolean;
    annotationGroupId: number | null;
    hasAnnotationLinks: boolean;
    annotationLinkCount: number;
    annotationCount: number;
    isOriginalResolution: boolean;
    is360Media: boolean;
    duration: string;
    mainFileId?: number;
    tempId: number;
    status: number;
    fileStatus: FileStatus;
    dateTaken?: moment.Moment;

    // Properties used to support old CFV (knockout) until we can fully retire it
    /** Obsolete property to support old CFV. Don't set tempId if present */
    existingBTDoc?: boolean;
    /** Obsolete property to support old CFV. Required to link existing files to entity */
    uniqueId?: number;
    /** Obsolete property to support old CFV. */
    isTempFile: boolean;
    /** Obsolete property to support old CFV. */
    isOnNewEntity: boolean;
    /** Obsolete property to support old CFV. Use 'title' instead */
    fileName: string;
    /** Obsolete property to support old CFV. Use 'extension' instead. */
    ext: string;
    /** Obsolete property to support old CFV. Used to display video thumbnails on unsaved entities */
    getNative: File;
    /** Function type version of this property to support old CFV and obsolete. Consider forcing 'File' type when removing CFV */
    nativeFile?: File | (() => File | undefined);
}

export interface IAttachedDocFormValues {
    attachedFiles: AttachedFilesRequest;
    attachedFilesConfiguration: AttachedFiles;
}

export function createNewDocUploadsFromURLs(
    filesResponse: FilesFromExternalUrlsResponse,
    targetValues: IAttachedDocFormValues,
    docInstanceType: DocumentInstanceType
): IAttachedDocFormValues {
    // Return object - pass into setFieldValues
    const { attachedFiles, attachedFilesConfiguration } = targetValues;
    const returnValues = {} as IAttachedDocFormValues;

    const filesResponseAsFileUpload = filesResponse.fileSystemList.map((file) => {
        file.viewingPermissions!.showSubs = true;
        file.viewingPermissions!.showOwner = true;
        let asBTFileUpload = MapBTFileToFileUpload(file, docInstanceType);
        asBTFileUpload.tempId = file.tempId;
        return asBTFileUpload;
    });

    // for existing entities - needs to be a FILEUPLOAD object
    returnValues.attachedFiles = {
        ...attachedFiles,
        attachDocs: [...attachedFiles.attachDocs, ...filesResponseAsFileUpload],
    };
    // Map BTFileSystem to object needed for attachedFilesConfiguration
    const mappedFilesResponse = filesResponse.fileSystemList.map((file) => {
        return MapBTFileSystemToAttachedFilesConfig(file, isInPortal({ builder: true }));
    });

    // for new entities - needs to be a BTFILESYSTEM object
    returnValues.attachedFilesConfiguration = {
        ...attachedFilesConfiguration,
        files: [...mappedFilesResponse, ...attachedFilesConfiguration.files],
    };

    return returnValues;
}
