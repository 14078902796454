import { Popover, PopoverProps } from "antd";
import classNames from "classnames";
import { createRef, PureComponent } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { addCloseOnScroll, getClosestModal, removeCloseOnScroll } from "utilities/helpers";
import { isNullOrUndefined } from "utilities/object/object";

import { BTIconInfoCircleFilled } from "commonComponents/btWrappers/BTIcon";

import "./BTPopover.less";

export type TBTPopoverTrigger = "hover" | "focus" | "click" | "contextMenu";

export interface IBTPopoverProps extends PopoverProps {
    /**
     * Use "inline" to align to text
     * @default inline
     */
    display?: "inline" | "inline-block";

    /**
     * OBSOLETE: use widthtype instead and set to unrestricted
     * Need to also remove Constrained style in BTPopover.less
     * Set to true if element must span wider than default popover content
     * @default false
     */
    unconstrainedWidth?: boolean;

    /**
     * Set width type for popover
     * @default narrow
     */
    widthType?: "narrow" | "wide" | "unrestricted";

    /**
     * Remove automatic margin from popover component. Spacing will also be removed if
     * popover children elements are provided
     */
    removeSpacing?: boolean;

    /**
     * Use "click" to only show content on click instead of hover.
     * @default hover
     */
    trigger?: TBTPopoverTrigger | TBTPopoverTrigger[];

    /**
     * Adds word-break: break-word to the inner content
     * @default true
     */
    breakWord: boolean;

    /**
     * no longer renders the popover but still renders the children as normal
     * @default false
     */
    disabled: boolean;

    /**
     * Add the scroll event
     * @default true
     */
    shouldCloseOnScroll?: boolean;

    /**
     * Added data-testid prop which can be sent from all references as it is required for automation testing
     */
    "data-testid"?: string;
}

interface IBTPopoverState {
    visible: boolean;
}

/**
 * Returns identifier that should be used in analytics tracking.
 * NOTE: We have limited info around these popovers, so this attempts to use what
 * relevant data we have in order to supply some context around the event.
 * @param props
 * @returns string for tracking uniqueId
 */
const getUniqueIdForTracking = (props: IBTPopoverProps) => {
    if (props["data-testid"]) {
        return props["data-testid"];
    } else if (typeof props.title === "string") {
        return props.title;
    } else if (typeof props.content === "string") {
        return props.content;
    } else {
        return "Popover";
    }
};

/**
 * Wraps ant design Popover with defaults
 * If no child content is passed a info icon will be used
 */
@track((props) => ({ component: "Popover", uniqueId: getUniqueIdForTracking(props) }))
export class BTPopover extends PureComponent<IBTPopoverProps & ITrackingProp, IBTPopoverState> {
    static defaultProps = {
        display: "inline",
        trigger: "hover",
        breakWord: true,
        disabled: false,
        unconstrainedWidth: false,
        shouldCloseOnScroll: true,
        widthType: "narrow",
    };

    state: IBTPopoverState = {
        visible: false,
    };

    refPopover = createRef<{ getRootDomNode: () => HTMLElement }>();

    static getDerivedStateFromProps(
        nextProps: IBTPopoverProps,
        state: IBTPopoverState
    ): IBTPopoverState | null {
        // close the popover when disabling. Fixes case when popover becomes disabled while visible
        if (nextProps.disabled && state.visible) {
            return { visible: false };
        }

        // update state.visible if we are getting props.visible passed in
        if (nextProps.visible !== undefined && state.visible !== nextProps.visible) {
            return { visible: nextProps.visible };
        }

        return null;
    }

    private onVisibleChange = (isVisible: boolean) => {
        this.setState({
            visible: isVisible,
        });

        /**
         * Get the trigger element for the popover
         */
        const triggerEl = this.refPopover.current?.getRootDomNode();

        /**
         * Remove the scroll event when conditions are met
         */
        if (!isVisible && triggerEl?.closest) {
            removeCloseOnScroll(triggerEl, this.handleScroll, true);
        }

        if (!this.props.disabled) {
            this.props.onVisibleChange?.(isVisible);

            // only track open
            if (isVisible) {
                this.props.tracking?.trackEvent({ event: "PopoverOpen" });
            }
        }
    };

    componentDidUpdate(prevProps: IBTPopoverProps, prevState: IBTPopoverState) {
        /**
         * Add the scroll event if we have a visible state change
         */
        if (prevState.visible !== this.state.visible && this.state.visible) {
            /**
             * Get the root trigger element that the popover is attached to
             */
            const triggerEl = this.refPopover.current?.getRootDomNode();

            if (triggerEl?.closest && this.props.shouldCloseOnScroll) {
                addCloseOnScroll(triggerEl, this.handleScroll, true);
            }
        }
    }

    private handleScroll = () => {
        // close on scroll
        this.onVisibleChange(false);
        /**
         * If trigger method is set to focus then grab that activeElement and if we have one then blur it
         * so that we can close the menu safely.
         */
        if (this.props.trigger === "focus") {
            (document.activeElement as HTMLElement)?.blur();
        }
    };

    render() {
        const {
            placement,
            className,
            overlayClassName,
            children,
            display,
            removeSpacing,
            trigger,
            disabled,
            ...otherProps
        } = this.props;

        let innerContent = children;
        if (!innerContent && otherProps.content) {
            innerContent = <BTIconInfoCircleFilled className="PopoverIcon" style={{ display }} />;
        }
        if (isNullOrUndefined(innerContent)) {
            return null;
        }

        const popoverClassNames = classNames("BTPopover", {
            [className!]: className !== undefined,
            ClickTrigger:
                trigger === "click" || (Array.isArray(trigger) && trigger.includes("click")),
            PopoverSpacing: !removeSpacing || children,
        });

        let widthType = this.props.widthType;
        if (this.props.unconstrainedWidth) {
            widthType = "unrestricted";
        }

        const overlayClassNames = classNames("BTPopoverOverlay", {
            [overlayClassName!]: overlayClassName !== undefined,
            PopoverBreak: this.props.breakWord,
            narrow: widthType === "narrow",
            wide: widthType === "wide",
        });

        return (
            <>
                {/* eslint-disable-next-line react/forbid-elements */}
                <Popover
                    ref={this.refPopover}
                    getPopupContainer={getClosestModal}
                    autoAdjustOverflow
                    className={popoverClassNames}
                    overlayClassName={overlayClassNames}
                    placement={placement}
                    trigger={trigger}
                    visible={this.state.visible && !disabled}
                    {...otherProps}
                    onVisibleChange={this.onVisibleChange}
                >
                    {innerContent}
                </Popover>
            </>
        );
    }
}

/**
 * Hack to close active popovers by tiggering a touchstart & mousedown event on the page
 * @see https://github.com/ant-design/ant-design/issues/28464
 */
export function hack_closePopovers(element: HTMLElement = document.body) {
    let touchStartEvent = new Event("touchstart", {
        bubbles: true,
    });

    let mouseDownEvent = new Event("mousedown", {
        bubbles: true,
    });

    element.dispatchEvent(touchStartEvent);
    element.dispatchEvent(mouseDownEvent);
}
