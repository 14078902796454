import { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { AssignedUserExtraData, BTSelectItem } from "types/apiResponse/apiResponse";
import { CommonConstants } from "types/constants";
import { PerformingUserType } from "types/enum";

import { getByValue } from "utilities/form/form";
import { routes } from "utilities/routes";

import { btConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { useSelectCreatable } from "commonComponents/btWrappers/BTSelectCreatable/BTSelectCreatable.utilities";
import {
    BTSelectUser,
    IBTSelectUserProps,
} from "commonComponents/btWrappers/BTSelectUser/BTSelectUser";
import PermissionWizard from "commonComponents/entity/permissionWizard/PermissionWizard/PermissionWizard";

import { AddToJobResponse } from "entity/job/Job.api.types";
import { RouteSubVendor } from "entity/sub/SubVendor/RouteSubVendor";
import { SubTabKeys } from "entity/sub/SubVendor/SubVendor.api.types";

interface IGlobalUserSelectorState {
    showPermissionWizard: boolean;
    selectedSubId?: number;
    selectedInternalUserId?: number;
    creatableUserName?: string;
}

export interface IGlobalUserSelectorProps<FormValues>
    extends Omit<
        RouteComponentProps & IBTSelectUserProps<FormValues, AssignedUserExtraData>,
        "onChange"
    > {
    onChange: (
        field: string,
        value: number,
        selectedUser?: BTSelectItem<AssignedUserExtraData>,
        shouldUpdateState?: boolean
    ) => void;
    canAddToJobsite?: (type: PerformingUserType) => boolean;
    addToJobsite?: (
        field: BTSelectItem<AssignedUserExtraData>,
        type: PerformingUserType
    ) => Promise<AddToJobResponse>;
    jobId?: number;
    fromBills?: boolean;
    canCreateUser?: boolean;
    afterUserCreate?: () => Promise<void>;
}

export class GlobalUserSelector<FormValues> extends PureComponent<
    IGlobalUserSelectorProps<FormValues>,
    IGlobalUserSelectorState
> {
    constructor(props: IGlobalUserSelectorProps<FormValues>) {
        super(props);
        this.state = {
            showPermissionWizard: false,
        };
    }
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    private setPermissionWizardVisibility = (
        showWizard: boolean,
        performingUserType?: PerformingUserType,
        performingUserId?: number
    ) => {
        let selectedSubId = undefined;
        let selectedInternalUserId = undefined;
        if (showWizard) {
            switch (performingUserType) {
                case PerformingUserType.InternalUser:
                    selectedInternalUserId = performingUserId;
                    break;
                case PerformingUserType.Subcontractor:
                    selectedSubId = performingUserId;
                    break;
            }
        }
        this.setState({
            showPermissionWizard: showWizard,
            selectedSubId,
            selectedInternalUserId,
        });
    };

    private handleAddToJob(
        field: string,
        value: number,
        selectedUser: BTSelectItem<AssignedUserExtraData>,
        fromBills: boolean | undefined
    ) {
        const performingUserId = Number(selectedUser!.id);
        let performingUserType = selectedUser!.extraData!.userType!;

        btConfirm({
            title: fromBills ? "Add assignee to job?" : "Assignee must have job access",
            content: `Would you like to add ${selectedUser.extraData!.simpleTitle} to this job?`,
            okText: "Add to Job",
            cancelText: fromBills ? "Do Not Add to Job" : "Cancel",
            closable: true,
            onOk: async () => {
                await this.props.addToJobsite!(selectedUser!, selectedUser!.type!).then(() => {
                    selectedUser!.extraData!.hasJobAccess = true;
                    selectedUser = GlobalUserSelectMapping(selectedUser!, performingUserId);
                    this.props.onChange(field, performingUserId, selectedUser, false);
                    this.setPermissionWizardVisibility(true, performingUserType, performingUserId);
                });
            },
            onCancel: (e) => {
                if (e && e.triggerCancel) {
                    return;
                }
                this.props.onChange(field, performingUserId, selectedUser, false);
            },
        });
    }

    private onUserCreate = async (userId: number) => {
        if (this.props.afterUserCreate) {
            // after creation refresh data to get the new sub
            await this.props.afterUserCreate();
        }
        const { treeData } = this.props;
        const selectedUser = getByValue(treeData, userId);

        if (typeof this.props.id === "string" && selectedUser) {
            this.onUserChange(this.props.id, selectedUser?.value);
        }
    };

    private onUserChange = (field: string, value: number) => {
        const { treeData, fromBills } = this.props;
        const selectedUser = getByValue(treeData, value);
        const showAddToJobModal =
            selectedUser?.extraData &&
            !selectedUser?.extraData?.hasJobAccess &&
            this.props.canAddToJobsite &&
            this.props.canAddToJobsite(selectedUser!.extraData!.userType!);

        if (showAddToJobModal) {
            this.handleAddToJob(field, value, selectedUser!, fromBills);
        } else {
            const performingUserId = Number(selectedUser!.id);
            this.props.onChange(field, performingUserId, selectedUser, false);
        }
    };

    /** TODO Routing in this component was added to simplify the solution for ado-175938
     * need to convert this component to a functional component to better follow best practices
     */
    private onSubCreateClick = (
        field: string,
        value: string | number | undefined,
        subName?: string
    ) => {
        const { history, match } = this.props;
        if (subName) {
            this.setState({ creatableUserName: subName });
            history.push(match.url + routes.sub.getDetailsLink(0, SubTabKeys.AdditionalInfo));
        }
    };

    render() {
        let subIds =
            this.state.selectedSubId !== undefined ? [this.state.selectedSubId] : undefined;
        let internalUserIds =
            this.state.selectedInternalUserId !== undefined
                ? [this.state.selectedInternalUserId]
                : undefined;
        return (
            <>
                <RouteSubVendor
                    modalConfig={{
                        parentRoute: this.props.match.url,
                        beforeClose: () => {
                            this.props.history.push(this.props.match.url);
                        },
                    }}
                    afterClose={this.onUserCreate}
                    creatableSubName={this.state.creatableUserName}
                />
                <GlobalUserSelectorCreatable<FormValues>
                    {...this.props}
                    onChange={this.onUserChange}
                    onUserCreate={this.onSubCreateClick}
                />

                {this.state.showPermissionWizard && this.props.jobId !== undefined && (
                    <PermissionWizard
                        jobIds={[this.props.jobId]}
                        onComplete={() => {
                            this.setPermissionWizardVisibility(false);
                        }}
                        onCancel={() => {
                            this.setPermissionWizardVisibility(false);
                        }}
                        subIds={subIds}
                        internalUserIds={internalUserIds}
                        modalConfig={{
                            parentRoute: "",
                            beforeClose: () => this.setPermissionWizardVisibility(false),
                        }}
                        fromBills={this.props.fromBills}
                    />
                )}
            </>
        );
    }
}

interface IGlobalUserSelectorCreatableProps<FormValues>
    extends IGlobalUserSelectorProps<FormValues> {
    onUserCreate: (
        field: string,
        value: string | number | undefined,
        search: string | undefined
    ) => void;
}

function GlobalUserSelectorCreatable<FormValues extends unknown>(
    props: IGlobalUserSelectorCreatableProps<FormValues>
) {
    const selectableProps = useSelectCreatable({
        treeData: props.treeData,
        onChange: props.onChange,
        maxLength: 50,
        createableEntityName: "Sub/Vendor",
        onOptionCreate: props.onUserCreate,
        canCreateEntity: props.canCreateUser ? props.canCreateUser : false,
    });

    return <BTSelectUser<FormValues, AssignedUserExtraData> {...props} {...selectableProps} />;
}

export const GlobalUserSelectMapping = (
    user: BTSelectItem<AssignedUserExtraData>,
    selectedId?: number
) =>
    new BTSelectItem<AssignedUserExtraData>(user, (user) => {
        const isGroupParent = user.children !== undefined;

        if (!isGroupParent) {
            let alteredTitle = false;
            if (!user.extraData.hasJobAccess) {
                user.title += " (no job access)";
                alteredTitle = true;
            }
            if (user.extraData.expiredCert) {
                user.title += " (expired cert)";
                alteredTitle = true;
            }
            if (!alteredTitle && user.extraData.simpleTitle) {
                user.title = user.extraData.simpleTitle;
            }

            user.selected = Number(user.id) === selectedId;
        }
    });

// returns the sub based on id
export const getSub = (
    dropdownValues: BTSelectItem<AssignedUserExtraData>[],
    subId: number
): BTSelectItem<AssignedUserExtraData> | undefined => {
    return dropdownValues
        .find((group) => group.title === CommonConstants.Sub.plural)!
        .children!.find((user) => subId === Number(user.id));
};
