export enum LeadTabTypes {
    ListView = "ListView",
    ActivityView = "ActivityView",
    ActivityCalendar = "ActivityCalendar",
    ActivityTemplates = "ActivityTemplates",
    Proposals = "Proposals",
    ProposalTemplates = "ProposalTemplates",
    Map = "Map",
}

export function getLeadTabTypeFromUrl(pathname: string): LeadTabTypes {
    const urlParts = pathname.split("/");
    const tab = urlParts[2];
    if (tab === "opportunities") {
        return LeadTabTypes.ListView;
    } else if (tab === "map") {
        return LeadTabTypes.Map;
    } else if (tab === "activities") {
        return LeadTabTypes.ActivityView;
    } else if (tab === "calendar") {
        return LeadTabTypes.ActivityCalendar;
    } else if (tab === "activityTemplates") {
        return LeadTabTypes.ActivityTemplates;
    } else if (tab === "proposals") {
        return LeadTabTypes.Proposals;
    } else if (tab === "proposalTemplates") {
        return LeadTabTypes.ProposalTemplates;
    }
    return LeadTabTypes.ListView;
}
