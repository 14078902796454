import { TwitterSquareFilled } from "@ant-design/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

interface IBTIconTwitterSquareFilledProps extends IBTIconProps {
    isOnDark?: boolean;
}
export const BTIconTwitterSquareFilled: FunctionComponent<IBTIconTwitterSquareFilledProps> = (
    baseProps
) => {
    const { isOnDark, ...iconProps } = baseProps;
    const [props] = useAntIcon(iconProps);

    return (
        <TwitterSquareFilled
            {...props}
            className={classNames("BTIconTwitterSquareFilled", props.className, { isOnDark })}
        />
    );
};
