import moment from "moment";

import { isNullOrWhitespace } from "utilities/string/string";

import {
    AddressEntity,
    AddressFieldTypes,
    AddressFormValues,
    EntityMappingStatus,
    LocationEntity,
} from "commonComponents/entity/address/Address/Address.api.types";
import { IMapPosition, PinTypes } from "commonComponents/entity/map/Map.types";

export function GetIconPathForPinType(pinType: PinTypes) {
    switch (pinType) {
        case PinTypes.Default:
            return "/images/FontIcons/map-marker-default.svg";
        case PinTypes.Inactive:
            return "/images/FontIcons/map-marker-inactive.svg";
        case PinTypes.Edited:
            return "/images/FontIcons/map-marker-edited.svg";
        case PinTypes.Locked:
            return "/images/FontIcons/map-marker-locked.svg";
        case PinTypes.Warning:
            return "/images/FontIcons/map-marker-warning.svg";
        case PinTypes.CurrentLocation:
            return "/images/FontIcons/map-marker-current-location.svg";
        case PinTypes.None:
            return "";
    }
}

export function GetTitleForPinType(pinType: PinTypes) {
    switch (pinType) {
        case PinTypes.None:
            return "";
        case PinTypes.Default:
            return "Mapped";
        case PinTypes.Inactive:
            return "Inactive";
        case PinTypes.Edited:
            return "Manually Mapped";
        case PinTypes.Locked:
            return "Locked";
        case PinTypes.Warning:
            return "Warning";
        case PinTypes.CurrentLocation:
            return "Current Location";
    }
}

export function getMapPositionFromDevice(): Promise<IMapPosition | null> {
    return new Promise<IMapPosition | null>((resolve) => {
        if (typeof navigator !== "undefined" && typeof navigator.geolocation !== "undefined") {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    } as IMapPosition);
                },
                () => {
                    resolve(null);
                }
            );
        } else {
            resolve(null);
        }
    });
}

export function entityIsMappable(address: AddressFormValues, entity: AddressEntity) {
    let isMappable = !isNullOrWhitespace(address.street) && !isNullOrWhitespace(address.zip);

    if (entity.fields.some((field) => field.addressFieldType === AddressFieldTypes.City)) {
        isMappable = isMappable && !isNullOrWhitespace(address.city);
    }
    if (entity.fields.some((field) => field.addressFieldType === AddressFieldTypes.State)) {
        isMappable = isMappable && !isNullOrWhitespace(address.state);
    }
    if (entity.fields.some((field) => field.addressFieldType === AddressFieldTypes.Suburb)) {
        isMappable = isMappable && !isNullOrWhitespace(address.suburb);
    }
    return isMappable;
}

// returns an array of locations from an array of addresses
export async function getMapPositions(
    addresses: string[],
    cancellationToken: MapAllCancellationToken
) {
    let locations: LocationEntity[] = [];

    for (const address of addresses) {
        let position = await tryGetMapPosition(address, cancellationToken);

        locations.push(position);
    }

    return locations;
}

// gets mapped position with a retry on failure
async function tryGetMapPosition(
    address: string,
    cancellationToken: MapAllCancellationToken
): Promise<LocationEntity> {
    const maxRetries = 5;
    let retryCount = 0;

    const request: google.maps.GeocoderRequest = {
        address,
    };

    while (retryCount < maxRetries) {
        const position = await getMapPosition(request, getMapLocationEntity);
        if (position?.mappingStatus === EntityMappingStatus.Mapped || retryCount >= maxRetries) {
            return position;
        } else {
            retryCount++;
            // this accounts for googles rate limit, should rarely retry more than once
            await sleep(1000 * retryCount, cancellationToken);
        }
    }

    return new LocationEntity({
        mapPosition: null,
        mappingStatus: EntityMappingStatus.NotMapped,
    });
}

export function getMapPositionFromZipCode(
    zipCode: string,
    country?: string
): Promise<LocationEntity> {
    const request: google.maps.GeocoderRequest = {
        componentRestrictions: {
            postalCode: zipCode,
            country,
        },
    };
    return getMapPosition(request, getMapLocationEntity);
}

export function getZipCodeFromLatLng(lat: number, lng: number): Promise<string | undefined> {
    const request: google.maps.GeocoderRequest = {
        location: { lat, lng },
    };
    return getMapPosition(request, getZipCode);
}

async function getMapPosition<T>(
    request: google.maps.GeocoderRequest,
    formatResults: (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => T
) {
    const geocoder = new google.maps.Geocoder();
    return new Promise<T>((resolve) => {
        void geocoder.geocode(request, (results, status) => {
            const newLocation = formatResults(results ?? [], status);
            resolve(newLocation);
        });
    });
}

function getMapLocationEntity(
    results: google.maps.GeocoderResult[],
    status: google.maps.GeocoderStatus
) {
    let newMapLocation: IMapPosition | null;
    if (status === "OK") {
        const location: google.maps.LatLng = results[0].geometry.location;
        newMapLocation = { lat: location.lat(), lng: location.lng() };
    } else {
        newMapLocation = null;
    }
    const newLocation = new LocationEntity({
        mapPosition: newMapLocation,
        mappingStatus: newMapLocation ? EntityMappingStatus.Mapped : EntityMappingStatus.NotMapped,
    });
    return newLocation;
}

function getZipCode(results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
    if (status === "OK") {
        const streetAddressObject = results.find((r) => r.types.includes("street_address"));
        const postalCodeObject = streetAddressObject?.address_components.find((ac) =>
            ac.types.includes("postal_code")
        );
        return postalCodeObject?.long_name;
    } else {
        return undefined;
    }
}

export async function getMapPositionFromDeviceWithFallback(fallbackPosition?: IMapPosition | null) {
    let mapPosition: IMapPosition = { lat: 0, lng: 0 };

    const browserLocation = await getMapPositionFromDevice();
    if (browserLocation != null) {
        mapPosition = browserLocation;
    } else {
        mapPosition = fallbackPosition
            ? { lat: fallbackPosition.lat, lng: fallbackPosition.lng }
            : mapPosition;
    }

    return mapPosition;
}

// works similar to thread.sleep in c#
export async function sleep(milliseconds: number, cancellationToken: MapAllCancellationToken) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(resolve, milliseconds);
        cancellationToken.cancel = () => {
            reject();
            clearTimeout(timeout);
        };
    });
}

// sleeps until a specific time
export async function sleepUntil(time: moment.Moment, cancellationToken: MapAllCancellationToken) {
    const curTime = moment();

    // sleep the difference
    if (curTime < time) {
        await sleep(time.diff(curTime, "milliseconds"), cancellationToken);
    }
}

export class MapAllCancellationToken {
    cancel: () => void;
}
