import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { LoginResponse } from "entity/login/Login/Login.api.types";
import {
    IJwtValidationRequest,
    IPasswordResetRequest,
    IPasswordResetResponse,
    PasswordResetResponse,
} from "entity/passwordReset/PasswordReset/PasswordReset.api.types";
import { IUserActivationPageLoginRequest } from "entity/UserActivationPage/UserActivationPage.api.types";

export interface IPasswordResetHandler {
    updatePassword: (
        updatePasswordRequest: IPasswordResetRequest
    ) => Promise<IPasswordResetResponse>;
    validateJwt(jwtValidationRequest: IJwtValidationRequest): Promise<EmptyResponseEntity>;
    login(request: IUserActivationPageLoginRequest): Promise<LoginResponse>;
}

export class PasswordResetHandler implements IPasswordResetHandler {
    async updatePassword(
        updatePasswordRequest: IPasswordResetRequest
    ): Promise<IPasswordResetResponse> {
        return await APIHandler(`/api/Password/UpdatePassword`, {
            method: "PUT",
            data: updatePasswordRequest,
            responseType: PasswordResetResponse,
        });
    }

    async validateJwt(jwtValidationRequest: IJwtValidationRequest): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Password/ValidateJWT`, {
            method: "POST",
            data: jwtValidationRequest,
            responseType: EmptyResponseEntity,
        });
    }

    async login(request: IUserActivationPageLoginRequest): Promise<LoginResponse> {
        return await APIHandler("/api/Login/AjaxLogin", {
            method: "POST",
            data: request,
            responseType: LoginResponse,
        });
    }
}
