import { useContext } from "react";

import { IAppContextValue } from "helpers/AppProvider";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLocalStorage } from "types/btStorage";

import { isInPortal } from "utilities/portal/portal";
import { AppcuesIntegration } from "utilities/thirdPartyIntegrations/appcues/appcues";
import { IAppcuesOptions } from "utilities/thirdPartyIntegrations/appcues/appcues.types";
import { ChameleonIntegration } from "utilities/thirdPartyIntegrations/chameleon/chameleon";
import { IChameleonOptions } from "utilities/thirdPartyIntegrations/chameleon/chameleon.types";
import { QualtricsIntegration } from "utilities/thirdPartyIntegrations/qualtrics/qualtrics";
import { IQualtricsOptions } from "utilities/thirdPartyIntegrations/qualtrics/qualtrics.types";

interface IThirdPartyIntegrationsProps {
    appContext: IAppContextValue;
}

export const ThirdPartyIntegrationsInternal = function (props: IThirdPartyIntegrationsProps) {
    const appDefaults = useContext(AppDefaultInfoContext);
    const builderInfo = useContext(BuilderInfoContext);
    const userInfo = useContext(UserInfoContext);
    const envInfo = useContext(EnvironmentInfoContext);

    // Don't initialize in btadmin
    if (!isInPortal({ btadmin: true })) {
        initAppcues();
        initQualtrics();
        initChameleon();
    }

    /** Initializes Appcues */
    function initAppcues() {
        const appcuesEnabled = appDefaults?.enableAppcues;
        const appcuesAccountId = appDefaults?.appcuesAccountId;
        const disableAnalytics = BTLocalStorage.get("bt-boolean-disableAllAnalytics");

        // Only init Appcues when we have a User/Builder to track/target
        if (
            !builderInfo ||
            !userInfo?.globalUserId ||
            !props.appContext._globalInfo.integrationBuilderProfile ||
            !props.appContext._globalInfo.integrationUserProfile
        ) {
            return;
        }

        // Only init if Appcues is enabled
        if (!appcuesEnabled || !appcuesAccountId || disableAnalytics) {
            return;
        }

        // Only init if builder is not set to be redirected to the setup experience
        if (builderInfo.redirectToSetupExperience) {
            return;
        }

        let appcuesContext: IAppcuesOptions = {
            appcuesAccountId: appcuesAccountId,
            userInfo: userInfo,
            builderInfo: builderInfo,
            integrationBuilderProfile: props.appContext._globalInfo.integrationBuilderProfile,
            integrationUserProfile: props.appContext._globalInfo.integrationUserProfile,
            environmentInfo: props.appContext._globalInfo.environmentInfo,
        };

        let Appcues = AppcuesIntegration(appcuesContext);
        Appcues.initialize();
    }

    /** Initializes Qualtrics */
    function initQualtrics() {
        const enableQualtrics = appDefaults?.enableQualtrics;
        const qualtricsProjectId = appDefaults?.qualtricsProjectId;
        const qualtricsInterceptUrl = appDefaults?.qualtricsInterceptorUrl;
        const disableAnalytics = BTLocalStorage.get("bt-boolean-disableAllAnalytics");

        // Only init Appcues when we have a User to track/target
        if (!builderInfo || !props.appContext._globalInfo.integrationUserProfile?.userId) {
            return;
        }

        // Only init if Qualtrics is enabled
        if (!enableQualtrics || !qualtricsProjectId || !qualtricsInterceptUrl || disableAnalytics) {
            return;
        }

        let qualtricsContext: IQualtricsOptions = {
            qualtricsProjectId: qualtricsProjectId,
            qualtricsInterceptUrl: qualtricsInterceptUrl,
            integrationUserProfile: props.appContext._globalInfo.integrationUserProfile,
        };

        const qualtrics = QualtricsIntegration(qualtricsContext);
        qualtrics.initialize();
    }

    /** Initializes Chameleon */
    function initChameleon() {
        // Only init Chameleon when we have a User/Builder to track/target
        if (
            !builderInfo ||
            !userInfo?.globalUserId ||
            !props.appContext._globalInfo.integrationBuilderProfile ||
            !props.appContext._globalInfo.integrationUserProfile ||
            builderInfo.redirectToSetupExperience
        ) {
            return;
        }

        const chameleonEnabled = appDefaults?.enableChameleon;
        const chameleonToken = props.appContext._globalInfo.appDefaultInfo.chameleonToken;
        const disableAnalytics = BTLocalStorage.get("bt-boolean-disableAllAnalytics");

        // Only init if Chameleon enabled and we're not disabling analytics
        if (!chameleonEnabled || !chameleonToken || disableAnalytics) {
            return;
        }

        let chameleonContext: IChameleonOptions = {
            chameleonToken: chameleonToken,
            userInfo: userInfo,
            builderInfo: builderInfo,
            integrationBuilderProfile: props.appContext._globalInfo.integrationBuilderProfile,
            integrationUserProfile: props.appContext._globalInfo.integrationUserProfile,
            environmentInfo: envInfo!,
        };

        const chameleon = ChameleonIntegration(chameleonContext);
        chameleon.initialize();
    }

    return null;
};

export const ThirdPartyIntegrations: React.FC<IThirdPartyIntegrationsProps> = ({ appContext }) => {
    return <ThirdPartyIntegrationsInternal appContext={appContext} />;
};
