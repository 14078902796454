import { BTTreeCheckboxNode } from "commonComponents/btWrappers/BTTreeCheckbox/BTTreeCheckbox.types";

export class TemplateCopyPickerItem extends BTTreeCheckboxNode {
    constructor(data: any) {
        super(data);
        this.nestedOptions = data.nestedOptions.map(
            (item: any) => new TemplateCopyPickerItem(item)
        );
        this.column = data.webColumn;
        this.count = data.count;
    }

    nestedOptions: TemplateCopyPickerItem[];
    column: WebColumn;
    count?: number;
}

export enum WebColumn {
    Left = 0,
    Right = 1,
}
