import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ILeadProposalProps } from "entity/leadProposal/LeadProposal/LeadProposal";

const LeadProposal = lazyLoadWithRetry(() => import("./LeadProposal"));

export const RouteLeadProposal = (
    props: Omit<
        ILeadProposalProps,
        "id" | "leadId" | "history" | "location" | "match" | "appDefaultInfo"
    >
) => (
    <RouteRelative
        path={routes.leadProposals.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <LeadProposal
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.proposalId)}
                        leadId={parseInt(routeProps.match.params.leadId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
