// This optional code is used to register a service worker.
// register() is not called by default.
import { IServiceWorkerConfig, ServiceWorkerQueryParams } from "service-worker.types";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const publicUrl: string | undefined = getBaseUrl();
function getBaseUrl() {
    let publicUrl: string | undefined = import.meta.env.BASE_URL ?? "";
    return !publicUrl?.endsWith("/") ? publicUrl : publicUrl.slice(0, -1);
}

export async function register(config: IServiceWorkerConfig) {
    if (!("serviceWorker" in navigator)) {
        console.log(
            "Device is not setup to use service workers. Notifications and performance may be affected"
        );
        return;
    }

    const swUrl = getServiceWorkerUrl(config);

    try {
        const registration = await navigator.serviceWorker.register(swUrl, {
            scope: `${window.location.protocol}//${window.location.hostname}`,
            type: import.meta.env.PROD ? "classic" : "module",
        });
        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker === null) {
                return;
            }
            installingWorker.onstatechange = () => {
                if (installingWorker.state === "installed" && navigator.serviceWorker.controller) {
                    console.log(
                        "A newer version of the service worker is detected. Replacing the current instance."
                    );

                    // Replaces the current instance of the service worker with a new one
                    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
                }
            };
        };
        return registration;
    } catch (e) {
        console.error("Error during service worker registration:", e);
    }
    return null;
}

export async function get(
    config: IServiceWorkerConfig
): Promise<ServiceWorkerRegistration | null | undefined> {
    if (!("serviceWorker" in navigator)) {
        return undefined;
    }

    const swUrl = getServiceWorkerUrl(config);
    return await navigator.serviceWorker.getRegistration(swUrl);
}

export async function unregister() {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.ready;
            await registration.unregister();
        } catch (e) {
            console.error(e);
        }
    }
}

function getServiceWorkerUrl(config: IServiceWorkerConfig) {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    const swUrl = new URL(
        import.meta.env.PROD ? `${publicUrl}/service-worker.js` : `${publicUrl}/service-worker`,
        baseUrl
    );
    swUrl.searchParams.set(ServiceWorkerQueryParams.config, JSON.stringify(config));
    return swUrl;
}
