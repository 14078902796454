import { message } from "antd";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isDate } from "lodash-es";
import qs from "query-string";

import { defaultErrorMessage } from "utilities/apiHandler";
import { getCurrentPortalType } from "utilities/portal/portal";

const axiosInstance = Axios.create({
    // Apply default config to all apix requests
    headers: {
        PortalType: getCurrentPortalType().toString(),
    },
});

axiosInstance.interceptors.request.use((originalRequest) => {
    // need to pre-serialize dates in the query string parameters before we hit
    // the query-string.stringify method which calls toString() on dates instead of toISOString()
    serializeDates(originalRequest.params);
    return originalRequest;
});

axiosInstance.interceptors.response.use((originalResponse) => {
    deserializeDates(originalResponse.data);
    return originalResponse;
});

/**
 * DO NOT import this method manually, it is used by Orval to override the default axios instance.
 * Instead, import the generated code from "handlers" if you want to use apix.
 */
export const apixHandler = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig
): Promise<AxiosResponse<T, any>> => {
    const source = Axios.CancelToken.source();
    const promise = axiosInstance({
        ...config,
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: "none" });
        },
        ...options,
        cancelToken: source.token,
    });

    // @ts-ignore
    promise.cancel = () => {
        source.cancel("Query was cancelled");
    };

    return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}
/**
 * This method recursively searches through the body object and converts any date strings to Date objects.
 * @param body The data being read from the request
 * @returns the data with any date strings converted to Date objects
 */
export function deserializeDates(body: unknown) {
    if (body === null || body === undefined || typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
            body[key] = new Date(value);
        } else if (typeof value === "object") {
            deserializeDates(value);
        }
    }
}

/**
 * This method recursively searches through the body object and converts any date Objects to its iso string.
 * @param body The data being read from the request
 * @returns the data with any Date objects converted to date strings
 */
export function serializeDates(body: unknown) {
    if (body === null || body === undefined || typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isDate(value)) {
            body[key] = (value as Date).toISOString();
        } else if (typeof value === "object") {
            serializeDates(value);
        }
    }
}

/**
 * Displays the error message from the server, if the server does not return an error message the default will be used
 * @param customMessage by default the message returned from the api will be shown, if the api failed but has no message "An error has occurred. Please try again." is shown
 */
export function showApixErrorMessage<T>(e: ErrorType<T>, customMessage?: string) {
    if (customMessage) {
        void message.error(customMessage, 5);
        return;
    }

    let messageToDisplay: string = defaultErrorMessage;
    if (hasMessage(e.response?.data)) {
        messageToDisplay = e.response?.data?.message ?? defaultErrorMessage;
    }

    void message.error(messageToDisplay, 5);
}

function hasMessage(error: unknown): error is IHasMessage {
    return (error as IHasMessage)?.message !== undefined;
}

interface IHasMessage {
    message: string;
}
