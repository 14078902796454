import {
    LinkType,
    MarketplaceAction,
    MarketplaceOfferingStatus,
    MarketplaceOfferingType,
    ProServices,
} from "types/enum";

import { default as ParagonUserDataReponseJson } from "./ParagonUserDataResponse.json";

export class MarketplaceOfferingsResponse {
    constructor(data: any) {
        this.sections = data.sections.map((s: any) => new Section(s));
    }

    sections: Section[];

    getOffering(type: MarketplaceOfferingType) {
        const section = this.sections.filter((s) => s.offerings.some((o) => o.type === type))[0];
        const offering = section.offerings.filter((o) => o.type === type)[0];
        return offering;
    }

    tryGetOffering(type: MarketplaceOfferingType) {
        const section = this.sections.filter((s) => s.offerings.some((o) => o.type === type))[0];
        if (section) {
            const offering = section.offerings.filter((o) => o.type === type)[0];
            return offering;
        }
        return null;
    }
}

export class ParagonUserTokenResponse {
    constructor(data: any) {
        this.token = data.token;
        this.metadata = new ParagonUserMetadata(data.metadata);
    }

    token: string;
    metadata: ParagonUserMetadata;
}

export interface IIncomingUser {
    userId: string;
    integrations: { [integrationType: string]: IIntegrationData };
}

export interface IIntegrationData {
    enabled: boolean;
    integrationType: IntegrationTypeEnums;
    configuredWorkflows: { [workflowId: string]: IWorkflowData };
}

export interface IWorkflowData {
    enabled: boolean;
}

export class AuthenticatedConnectUser implements IIncomingUser {
    constructor(data: IIncomingUser) {
        this.userId = data.userId;
        this.integrations = {};
        for (let [integrationName, integrationData] of Object.entries(data.integrations)) {
            this.integrations[integrationName] = new Integration(integrationName, integrationData);
        }
    }
    userId: string;
    integrations: { [integrationType: string]: Integration };
}

export class Integration implements IIntegrationData {
    constructor(integrationName: string, data: IIntegrationData) {
        this.integrationType = getIntegrationTypeFromName(integrationName);
        this.enabled = data.enabled;
        this.configuredWorkflows = {};
        if (data.configuredWorkflows) {
            for (let [workflowId, workflowData] of Object.entries(data.configuredWorkflows)) {
                this.configuredWorkflows[workflowId] = new ConfiguredWorkflow(workflowData);
            }
        }
    }

    enabled: boolean;
    integrationType: IntegrationTypeEnums;
    configuredWorkflows: { [workflowId: string]: ConfiguredWorkflow };
}

export class ConfiguredWorkflow implements IWorkflowData {
    constructor(data: IWorkflowData) {
        this.enabled = data.enabled;
    }

    enabled: boolean;
}

export class ParagonUserMetadata {
    constructor(data: any) {
        this.externalUserId = data.externalUserId;
        this.name = data.name;
        this.email = data.email;
    }
    name: string;
    email: string;
    externalUserId: string;
}

export class Section {
    constructor(data: any) {
        this.name = data.name;
        this.description = data.description;
        this.offerings = data.offerings.map((o: any) => new Offering(o));
    }

    name: string;
    description: string;
    offerings: Offering[];
}

export class Offering {
    constructor(data: any) {
        this.title = data.title;
        this.description = data.description;
        this.status = data.status;
        this.link = data.link && new MarketplaceLink(data.link);
        this.foregroundImage = data.foregroundImage;
        this.backgroundImage = data.backgroundImage;
        this.type = data.type;
        this.learnMoreLink = data.learnMoreLink && new MarketplaceLink(data.learnMoreLink);
        this.connectedText = data.connectedText;
        this.disclaimer = data.disclaimer;
    }

    title: string;
    description: string;
    status: MarketplaceOfferingStatus;
    connectedText: string | null;
    link: MarketplaceLink | null;
    foregroundImage: string | null;
    backgroundImage: string | null;
    type: MarketplaceOfferingType;
    learnMoreLink: MarketplaceLink;
    disclaimer: string | null;
}

export class MarketplaceLink {
    constructor(data: any) {
        this.linkLabel = data.linkLabel;
        this.linkUrl = data.linkUrl;
        this.linkAction = data.linkAction;
        this.linkType = data.linkType;
        this.proServiceType = data.proServiceType;
    }

    linkUrl: string;
    linkLabel: string | null;
    linkAction: MarketplaceAction;
    linkType: LinkType;
    proServiceType?: ProServices | null;
}

export enum IntegrationTypeEnums {
    None = 0,
    Crm = 1,
}

export enum IntegrationEnums {
    None = 0,
    HubSpot = 1,
    Salesforce = 2,
    Pipedrive = 3,
}

export class MarketplaceScopes {
    public static readonly BuildertrendApiExternal = "btapi:external";
    public static readonly BuildertrendExternalCrmWrite = "btapi:external:crm:write";
    public static readonly OfflineAccess = "offline_access";
}

export const IntegrationDescription: { [key in IntegrationEnums]: string } = {
    [IntegrationEnums.None]: "None",
    [IntegrationEnums.HubSpot]: "HubSpot",
    [IntegrationEnums.Salesforce]: "Salesforce",
    [IntegrationEnums.Pipedrive]: "Pipedrive",
};

export function getIntegrationEnum(type: string): IntegrationEnums | undefined {
    const typeMap: { [key: string]: IntegrationEnums } = {
        hubspot: IntegrationEnums.HubSpot,
        salesforce: IntegrationEnums.Salesforce,
        pipedrive: IntegrationEnums.Pipedrive,
    };
    return typeMap[type];
}

export function getFriendlyIntegrationDescription(type: IntegrationEnums): string {
    return IntegrationDescription[type];
}

export function getFormattedIntegrationDescription(type: IntegrationEnums): string {
    return IntegrationDescription[type].toLowerCase().replace(/\s+/g, "");
}

export function getIntegrationTypeFromName(type: string) {
    switch (type) {
        case "hubspot":
        case "salesforce":
        case "pipedrive":
            return IntegrationTypeEnums.Crm;
        default:
            return IntegrationTypeEnums.None;
    }
}

export function getIntegrationTypeFromEnum(type: IntegrationEnums) {
    switch (type) {
        case IntegrationEnums.HubSpot:
        case IntegrationEnums.Salesforce:
        case IntegrationEnums.Pipedrive:
            return IntegrationTypeEnums.Crm;
        default:
            return IntegrationTypeEnums.None;
    }
}

export class ParagonUserDataReponse {
    constructor(data: typeof ParagonUserDataReponseJson) {
        this.user = new AuthenticatedConnectUser(data.user);
        this.orgLinkExternalID = data.orgLinkExternalID;
    }
    user: AuthenticatedConnectUser;
    orgLinkExternalID: string | undefined;
}
