import moment from "moment";
import { Moment } from "moment";

import { EntityTypes, NotificationActionType, NotificationTypes } from "types/enum";

export type NotificationPanelFormActions = undefined | "markAllRead";

export class NotificationPanelResponse {
    constructor(data: any) {
        this.notificationItems = data.notificationSummaryItems?.map(
            (item: NotificationItem) => new NotificationItem(item)
        );
        this.infiniteScrollData = new InfiniteScrollResponse(data.infiniteScrollData);
    }

    notificationItems: NotificationItem[];
    infiniteScrollData: InfiniteScrollResponse;
}

export class NotificationItem {
    constructor(data: any) {
        this.id = data.id;
        this.messageData = new MessageData(data.messageData);
        this.notificationType = data.notificationType;
        this.dateSent = moment(data.dateSent);
        this.isRead = data.isRead;
        this.canUnfollow = data.canUnfollow;
        this.actionType = data.actionType;
    }

    id: number;
    messageData: MessageData;
    notificationType: NotificationTypes;
    dateSent: Moment;
    isRead: boolean;
    canUnfollow: boolean;
    actionType: NotificationActionType;
}

export class MessageData {
    constructor(data: any) {
        this.entityId = data.entityId;
        this.entityType = data.entityType;
        this.recipientLoginType = data.recipientLoginType;
        this.title = data.title;
        this.builderId = data.builderId;
        this.userId = data.userId;
        this.jobId = data.jobId;
        this.body = data.body;
        this.extraData = data.extraData;
    }

    entityId?: number;
    entityType: EntityTypes;
    recipientLoginType: number;
    title: string;
    builderId: number;
    userId: number;
    jobId?: number;
    body: string;
    extraData: any;
}

export class InfiniteScrollResponse {
    constructor(data: any) {
        this.hasLoadedAll = data.hasLoadedAll;
        this.infiniteScrollStatus = data.infiniteScrollStatus;
    }

    hasLoadedAll: boolean;
    infiniteScrollStatus: number;
}
