import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IContactProps } from "entity/contact/Contact/Contact.api.types";

const Contact = lazyLoadWithRetry(() => import("./Contact"));

export const RouteContact = (
    props: Omit<IContactProps, "id" | "isFromSelector" | "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.contact.details}
        render={(routeProps) => {
            const id = parseInt(routeProps.match.params.id);
            const isFromSelector =
                routeProps.match.params.isFromSelector?.toLowerCase() === "true" || false;

            return (
                <BTSuspense>
                    <BuilderInfoContext.Consumer>
                        {(builderInfo) => (
                            <Contact
                                {...props}
                                {...routeProps}
                                id={id}
                                isFromSelector={isFromSelector}
                                builderInfo={builderInfo!}
                            />
                        )}
                    </BuilderInfoContext.Consumer>
                </BTSuspense>
            );
        }}
    />
);
