import { BTSelectItem } from "types/apiResponse/apiResponse";
import { AccountingIntegrationType } from "types/enum";

import {
    IEntityExtraData,
    LinkingAction,
} from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";

import { JobLinkingTypes } from "entity/accounting/LinkJobToAccounting/LinkJobToAccounting.api.types";

export interface IAccountingEntity {
    id: number;
    displayName: string;
    entityTerm: string;
    primaryAccountingTerm: string;
    secondaryAccountingTerm: string | null;
    jobLinkType: JobLinkingTypes | null;
    accountingIntegrationLogoImgSrc: string;
    isEntityInPendingSync: boolean;
    isEntityLinkedToAccounting: boolean;
    accountingIntegrationType: AccountingIntegrationType;
    linkingOptions: BTSelectItem<IEntityExtraData>[];
    createNewAccountingEntityOption: LinkingAction;
}

export class AccountingEntity implements IAccountingEntity {
    constructor(id: number, data: LinkedAccountingEntityResponse) {
        this.id = id;
        this.displayName = data.accountingLinkedUser.displayName;
        this.entityTerm = data.accountingLinkedUser.entityTerm;
        this.primaryAccountingTerm = data.accountingLinkedUser.primaryAccountingTerm;
        this.secondaryAccountingTerm = data.accountingLinkedUser.secondaryAccountingTerm;
        this.jobLinkType = data.accountingLinkedUser.jobLinkType;
        this.accountingIntegrationLogoImgSrc =
            data.accountingLinkedUser.linkedToAccountingSystemImage;
        this.isEntityInPendingSync = data.accountingLinkedUser.isEmployeePending;
        this.isEntityLinkedToAccounting = data.accountingLinkedUser.isUserLinked;
        this.accountingIntegrationType = data.accountingLinkedUser.accountingIntegrationType;
        this.createNewAccountingEntityOption = data.createNewAccountingEntityOption;
        this.linkingOptions = data.linkingOptions;
        this.otherAccountingCostToolTipMsg =
            data.accountingLinkedUser.otherAccountingCostToolTipMsg;
        this.showOtherAccountingCosts = data.accountingLinkedUser.showOtherAccountingCosts;
        this.includeOtherAccountingCostsInBudget =
            data.accountingLinkedUser.includeOtherAccountingCostsInBudget;
        this.allowJobsUnderCustomer = data.accountingLinkedUser.allowJobsUnderCustomer;
    }

    id: number;
    displayName: string;
    entityTerm: string;
    primaryAccountingTerm: string;
    secondaryAccountingTerm: string | null;
    jobLinkType: JobLinkingTypes | null;
    accountingIntegrationLogoImgSrc: string;
    isEntityInPendingSync: boolean;
    isEntityLinkedToAccounting: boolean;
    accountingIntegrationType: AccountingIntegrationType;
    linkingOptions: BTSelectItem<IEntityExtraData>[];
    createNewAccountingEntityOption: LinkingAction;
    otherAccountingCostToolTipMsg: string;
    showOtherAccountingCosts: boolean;
    includeOtherAccountingCostsInBudget: boolean;
    allowJobsUnderCustomer: boolean;
}

export class UpdateAccountingResponse {
    constructor(data?: any) {
        if (!data) {
            return;
        }
        this.accountingMessage = data.accountingMessage;
        this.accountingSuccess = data.accountingSuccess;
    }
    accountingMessage: string;
    accountingSuccess: boolean;
}

export class UnlinkAccountingResponse {
    constructor(data?: any) {
        if (!data) {
            return;
        }
        this.hasInvoicedReceivables = data.hasInvoicedReceivables;
    }
    hasInvoicedReceivables: boolean;
}

export class AccountingValues {
    constructor(data: any) {
        this.accountingEntityLinkingType = data.accountingEntityLinkingType;
        this.createNewAccountingEntityOption = data.createNewAccountingEntityOption;
    }

    accountingEntityLinkingType: JobsiteLinkingTypes;
    createNewAccountingEntityOption: LinkingAction;
}

export class LinkedAccountingEntityResponse {
    constructor(data: any) {
        this.accountingLinkedUser = new AccountingUserViewModel(data.accountingLinkedUser);
        this.canViewAccountingTab = data.canViewAccountingTab;
        this.createNewAccountingEntityOption = data.createNewAccountingEntityOption;
        this.linkingOptions =
            data.linkingOptions === null
                ? []
                : data.linkingOptions.map((d: any) => new BTSelectItem(d));
    }

    accountingLinkedUser: AccountingUserViewModel;
    canViewAccountingTab: boolean;
    linkingOptions: BTSelectItem<IEntityExtraData>[];
    createNewAccountingEntityOption: LinkingAction;
}

class AccountingUserViewModel {
    constructor(data: any) {
        this.displayName = data.displayName;
        this.accountingIntegrationType = data.accountingIntegrationType;
        this.accountingIntegrationTypeName = data.accountingIntegrationTypeName;
        this.accountingNameForLogin = data.accountingNameForLogin;
        this.canViewAccountingTab = data.canViewAccountingTab;
        this.isAccountConnected = data.isAccountConnected;
        this.isUserLinked = data.isUserLinked;
        this.isEmployeePending = data.isEmployeePending;
        this.linkedToAccountingSystemImage = data.linkedToAccountingSystemImage;
        this.syncSubToVendorImgSrc = data.syncSubToVendorImgSrc;
        this.builderTrendLinkedToAccountingSystemPending =
            data.builderTrendLinkedToAccountingSystemPending;
        this.builderTrendLinkedToAccountingSystem = data.builderTrendLinkedToAccountingSystem;
        if (data.payrollItems) {
            this.payrollItems = data.payrollItems.map((d: any) => new BTSelectItem(d));
        }
        this.defaultPayrollItem = data.defaultPayrollItem;
        this.primaryAccountingTerm = data.primaryAccountingTerm;
        this.secondaryAccountingTerm = data.secondaryAccountingTerm;
        this.entityTerm = data.entityTerm;
        this.otherAccountingCostToolTipMsg = data.otherAccountingCostToolTipMsg;
        this.showOtherAccountingCosts = data.showOtherAccountingCosts;
        this.includeOtherAccountingCostsInBudget = data.includeOtherAccountingCostsInBudget;
        // Job linking only
        this.allowJobsUnderCustomer = data.allowJobsUnderCustomer;
        this.jobLinkType = data.jobLinkType;
    }

    accountingIntegrationType: AccountingIntegrationType;
    accountingIntegrationTypeName: string;
    accountingNameForLogin: string;
    canViewAccountingTab: boolean;
    isAccountConnected: boolean;
    isUserLinked: boolean;
    isEmployeePending: boolean;
    linkedToAccountingSystemImage: string;
    syncSubToVendorImgSrc: string;
    builderTrendLinkedToAccountingSystemPending: string;
    builderTrendLinkedToAccountingSystem: string;
    payrollItems?: BTSelectItem[];
    defaultPayrollItem: string;
    primaryAccountingTerm: string;
    secondaryAccountingTerm: string | null;
    jobLinkType: JobLinkingTypes | null;
    displayName: string;
    entityTerm: string;
    otherAccountingCostToolTipMsg: string;
    showOtherAccountingCosts: boolean;
    includeOtherAccountingCostsInBudget: boolean;
    allowJobsUnderCustomer: boolean;
}
