// eslint-disable-next-line no-restricted-imports
import { SortDescriptor } from "@progress/kendo-data-query";
import { message } from "antd";
import { useCallback, useState } from "react";

import { useActionBeingPerformed } from "utilities/form/form";
import { isInPortal } from "utilities/portal/portal";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconPlusCircleFilled } from "commonComponents/btWrappers/BTIcon";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    GridViewColumn,
    GridViewItem,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { GridColumn } from "commonComponents/utilities/Grid/GridContainer.types";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";
import {
    ISavedFilterHandler,
    SavedFilterHandler,
} from "entity/filters/SavedFilter/SavedFilter.api.handler";
import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";
import { SavedViewsAndFiltersCard } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard";
import {
    getFeatureLabel,
    ISavedViewsAndFiltersCardFormValues,
    SavedViewsAndFiltersCardActions,
    SavedViewsAndFiltersSettingsState,
} from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard.api.types";
import {
    ISavedViewsAndFiltersExtraMappingProps,
    MapFiltersCards,
    MapSavedViewsCards,
} from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersSettings/SavedViewsAndFiltersMapping";
import { UpdateCurrentViewCard } from "entity/filters/SavedViewsAndFiltersList/UpdateCurrentViewCard/UpdateCurrentViewCard";

export interface ISavedViewsAndFiltersSettingsProps {
    dataType: "view" | "filter";
    gridSettingsState: SavedViewsAndFiltersSettingsState;
    setGridSettingsState: (state: SavedViewsAndFiltersSettingsState) => void;
    // View Settings props
    onApplyView?: (view: GridViewItem) => void;
    onSaveView?: (
        values: ISavedViewsAndFiltersCardFormValues,
        columns: GridViewColumn[],
        saveAsNew: boolean
    ) => Promise<void>;
    onDeleteView?: (view: GridViewItem) => Promise<void>;
    onSetDefaultView?: (view: GridViewItem) => Promise<void>;
    columnsList?: GridColumn<unknown>[];
    columnsSelectedList?: GridColumn<unknown>[];
    selectedGridView?: GridViewItem;
    viewData?: GridViewItem[];
    // the current sorting, may be different than saved version if user has applied a sort to grid
    sortingData?: SortDescriptor[];

    // Filter Settings props
    onSaveFilter?: (id: number, values: ISavedViewsAndFiltersCardFormValues) => {};
    onUpdateFilter?: (id: number, values: ISavedViewsAndFiltersCardFormValues) => {};
    onDeleteFilter?: (id: number) => Promise<void>;
    onSetDefaultFilter?: (newDefault: SavedFilterItem) => Promise<void>;
    filterTypeEntity?: FilterEntityType;
    filterData?: SavedFilterItem[];
    filterHandler?: ISavedFilterHandler;
    currentFilterValue?: string;
    jobIDs?: number[];
    beforeClose: () => void;
}

const defaultFilterHandler = new SavedFilterHandler();
export const SavedViewsAndFiltersSettings: React.FunctionComponent<
    ISavedViewsAndFiltersSettingsProps
> = ({ filterHandler = defaultFilterHandler, ...props }) => {
    const {
        dataType,
        onApplyView,
        columnsList,
        selectedGridView,
        onSaveView,
        onSaveFilter,
        currentFilterValue,
        filterTypeEntity,
        onUpdateFilter,
        onDeleteFilter,
        onDeleteView,
        viewData,
        filterData,
        gridSettingsState,
        setGridSettingsState,
        columnsSelectedList,
        onSetDefaultFilter,
        onSetDefaultView,
        sortingData,
        beforeClose,
    } = props;
    const [actionBeingPerformed, setActionBeingPerformed] =
        useActionBeingPerformed<SavedViewsAndFiltersCardActions>();

    const [editingId, setEditingId] = useState<number | undefined>(undefined);
    const [copyingId, setCopyingId] = useState<number | undefined>(undefined);
    const toggleAddingNew = useCallback(() => {
        setEditingId(undefined);
        setCopyingId(undefined);
        if (gridSettingsState === "open" || gridSettingsState === "editing") {
            setGridSettingsState("addingNew");
        } else {
            setGridSettingsState("open");
        }
    }, [gridSettingsState, setGridSettingsState]);
    let featureName = getFeatureLabel.get(filterTypeEntity!) ?? "current";

    const isFilter = dataType === "filter";
    const isBuilderOrAdmin: boolean = isInPortal({ builder: true, btadmin: true });
    const pageTitle = isFilter ? "Manage Saved Filters" : "Manage Saved Views";
    const pageDescription = isFilter
        ? `Manage your saved filters and select your default filter for the ${featureName} page.`
        : `Manage your grid views and select your default view for the ${featureName} page.`;
    const ownedHeader = isFilter ? "Your Saved Filters" : "Your Saved Views";
    const sharedHeader = isFilter ? "Shared with you" : "Shared Saved Views";
    const sharedDescription = isFilter
        ? "These filters have been created by other internal users on your account"
        : "These views have been created by other internal users on your account";
    const AddNewButtonText = isFilter ? "Add new saved filter" : "Add New Saved View";
    const newView = isFilter
        ? undefined
        : new GridViewItem({
              viewId: 0,
              viewName:
                  copyingId === undefined
                      ? ""
                      : `Copy of ${viewData?.find((v) => v.viewId === copyingId)?.viewName}`,
              canEdit: true,
              canDelete: true,
              isDefault: false,
              isSystemDefault: false,
              isPrivate: false,
              canPrivate: true,
              columns: [],
              pageSize: selectedGridView!.pageSize,
              sort: selectedGridView!.sort,
          });
    const newFilter = isFilter
        ? new SavedFilterItem({
              id: 0,
              name:
                  copyingId === undefined
                      ? ""
                      : `Copy of ${filterData?.find((f) => f.id === copyingId)?.name}`,
              isDefault: false,
              isPrivate: false,
              value:
                  copyingId === undefined
                      ? currentFilterValue
                      : filterData?.find((f) => f.id === copyingId)?.value,
              nameAndUpdatedBy: "",
              isMobileDefault: false,
              isOwner: true,
          })
        : undefined;
    const handleSaveFilter = useCallback(
        async (newValues: ISavedViewsAndFiltersCardFormValues) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "save",
                callback: async () => {
                    if (isFilter) {
                        try {
                            const nonSpecialJobs = props.jobIDs?.filter((j) => j > 0);
                            const jobID =
                                nonSpecialJobs?.length === 1 ? nonSpecialJobs[0] : undefined;
                            const createResponse = await filterHandler.create(newValues, jobID);
                            onSaveFilter!(createResponse.id, newValues);
                            setGridSettingsState("open");
                            await message.success(`${newValues.filterName} added`);
                        } catch {
                            await message.error("We were unable to add your filter");
                        }
                    }
                },
            });
        },
        [
            setActionBeingPerformed,
            isFilter,
            filterHandler,
            props.jobIDs,
            onSaveFilter,
            setGridSettingsState,
        ]
    );

    const handleUpdateFilter = useCallback(
        async (filter: SavedFilterItem, newValues: ISavedViewsAndFiltersCardFormValues) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "save",
                callback: async () => {
                    if (isFilter) {
                        try {
                            const nonSpecialJobs = props.jobIDs?.filter((j) => j > 0);
                            const jobID =
                                nonSpecialJobs?.length === 1 ? nonSpecialJobs[0] : undefined;
                            await filterHandler.update(filter.id, newValues, jobID);
                            onUpdateFilter!(filter.id, newValues);
                            setEditingId(undefined);
                            await message.success("Your changes were saved.");
                        } catch {
                            await message.error("We were unable to save your changes.");
                        }
                    }
                },
            });
        },
        [setActionBeingPerformed, isFilter, filterHandler, props.jobIDs, onUpdateFilter]
    );

    const handleSaveView = useCallback(
        async (
            values: ISavedViewsAndFiltersCardFormValues,
            columns: GridViewColumn[],
            saveAsNew: boolean
        ) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "save",
                callback: async () => {
                    await onSaveView!(values, columns, saveAsNew);
                    setGridSettingsState("open");
                    setEditingId(undefined);
                    await message.success(
                        saveAsNew ? `${values.viewName} added` : "Your changes were saved."
                    );
                },
            });
        },
        [onSaveView, setActionBeingPerformed, setGridSettingsState]
    );

    const handleDeleteFilter = useCallback(
        async (id: number) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "delete",
                callback: async () => {
                    await onDeleteFilter!(id);
                },
            });
        },
        [onDeleteFilter, setActionBeingPerformed]
    );

    const handleDeleteView = useCallback(
        async (view: GridViewItem) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "delete",
                callback: async () => {
                    await onDeleteView!(view);
                },
            });
        },
        [onDeleteView, setActionBeingPerformed]
    );

    const handleSetDefaultFilter = useCallback(
        async (newDefault: SavedFilterItem) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "setDefault",
                callback: async () => {
                    await onSetDefaultFilter!(newDefault);
                },
            });
        },
        [onSetDefaultFilter, setActionBeingPerformed]
    );

    const handleSetDefaultView = useCallback(
        async (newDefault: GridViewItem) => {
            await setActionBeingPerformed({
                actionBeingPerformed: "setDefault",
                callback: async () => {
                    await onSetDefaultView!(newDefault);
                },
            });
        },
        [onSetDefaultView, setActionBeingPerformed]
    );

    const handleEmptyStateAddNew = useCallback(() => {
        toggleAddingNew();
    }, [toggleAddingNew]);

    const columnsListUnselected = columnsList?.map((column) => ({
        ...column,
        enabled: !column.isRemovableFromGrid,
    }));

    const handleEditClick = useCallback(
        (id?: number) => {
            setEditingId(id);
            setGridSettingsState("editing");
            setCopyingId(undefined);
        },
        [setGridSettingsState]
    );

    const handleCopyClick = useCallback(
        (id?: number) => {
            setEditingId(undefined);
            setGridSettingsState("copying");
            setCopyingId(id);
        },
        [setGridSettingsState]
    );

    const getViewColumnListById = useCallback(() => {
        const selectedView = viewData?.find((v) => v.viewId === copyingId)!;
        const columnIds = selectedView.columns.map((c) => c.id);
        return columnsList?.map((column) => ({
            ...column,
            enabled: columnIds.indexOf(column.id) !== -1,
        }));
    }, [columnsList, copyingId, viewData]);

    const getAddNewColumnList = useCallback(() => {
        if (gridSettingsState === "copying") {
            return getViewColumnListById();
        } else if (gridSettingsState === "addingNew") {
            return columnsListUnselected;
        } else {
            return columnsList;
        }
    }, [columnsList, columnsListUnselected, getViewColumnListById, gridSettingsState]);

    const isAddingNew =
        gridSettingsState === "addingNew" ||
        gridSettingsState === "addingCurrent" ||
        gridSettingsState === "copying";

    const commonProps: ISavedViewsAndFiltersExtraMappingProps = {
        emptyStateCallToAction: handleEmptyStateAddNew,
        isAddingNew: isAddingNew,
        filterToOwner: true,
        editingId: editingId,
        onEditClick: handleEditClick,
        onCopyClick: handleCopyClick,
        actionBeingPerformed: actionBeingPerformed,
        onUpdateFilterFull: handleUpdateFilter,
    };

    return (
        <BTModalLayout
            title={`Manage Saved ${isFilter ? "Filters" : "Views"}`}
            modalConfig={{ parentRoute: "", beforeClose: beforeClose }}
        >
            <BTLayoutContent>
                <PageSection title={pageTitle} className="SavedViewsAndFilterSettings">
                    {isBuilderOrAdmin && pageDescription}
                    {!isFilter && (
                        <UpdateCurrentViewCard
                            columnsList={columnsList!}
                            onApplyView={onApplyView!}
                            selectedGridView={selectedGridView!}
                            sortingData={sortingData}
                            columnsSelectedList={columnsSelectedList}
                        />
                    )}
                    {actionBeingPerformed !== undefined && (
                        <BTLoading displayMode="absolute" instant />
                    )}
                    {isBuilderOrAdmin && (
                        <>
                            <BTRow>
                                <BTCol flex="auto" className="flex">
                                    <BTTitle className="margin-top-sm" level={3}>
                                        {ownedHeader}
                                    </BTTitle>
                                </BTCol>
                                {!isAddingNew && (
                                    <BTCol className="flex justify-content-end">
                                        <BTButton
                                            type="link"
                                            data-testid="add-new"
                                            className="margin-top-sm"
                                            icon={<BTIconPlusCircleFilled />}
                                            onClick={toggleAddingNew}
                                        >
                                            {AddNewButtonText}
                                        </BTButton>
                                    </BTCol>
                                )}
                            </BTRow>
                            {isAddingNew && !isFilter && (
                                <SavedViewsAndFiltersCard
                                    data={newView!}
                                    isExpanded={true}
                                    onEditClick={() => {}}
                                    onDeleteClick={() => {}}
                                    onCopyClick={() => {}}
                                    onSetAsDefaultClick={() => {}}
                                    onSaveView={handleSaveView}
                                    actionBeingPerformed={actionBeingPerformed}
                                    columnList={getAddNewColumnList()}
                                    columnsSelectedList={columnsSelectedList}
                                    isNew={true}
                                    closeExpandedCard={toggleAddingNew}
                                    viewList={viewData}
                                    filterList={[]}
                                />
                            )}
                            {isAddingNew && isFilter && (
                                <SavedViewsAndFiltersCard
                                    data={newFilter!}
                                    isExpanded={true}
                                    onEditClick={() => {}}
                                    onDeleteClick={() => {}}
                                    onCopyClick={() => {}}
                                    onSetAsDefaultClick={() => {}}
                                    onSaveFilter={handleSaveFilter}
                                    filterTypeEntity={filterTypeEntity}
                                    actionBeingPerformed={actionBeingPerformed}
                                    isNew={true}
                                    closeExpandedCard={toggleAddingNew}
                                    filterList={filterData}
                                    viewList={[]}
                                />
                            )}
                            {isFilter ? (
                                <MapFiltersCards
                                    {...props}
                                    {...commonProps}
                                    onUpdateFilterFull={handleUpdateFilter}
                                    onDeleteFilter={handleDeleteFilter}
                                    onSetDefaultFilter={handleSetDefaultFilter}
                                />
                            ) : (
                                <MapSavedViewsCards
                                    {...props}
                                    {...commonProps}
                                    onSaveView={handleSaveView}
                                    onDeleteView={handleDeleteView}
                                    onSetDefaultView={handleSetDefaultView}
                                />
                            )}
                            <BTTitle className="margin-top-sm margin-bottom-zero" level={3}>
                                {sharedHeader}
                            </BTTitle>
                            <div className="flex align-items-center margin-bottom-xs">
                                {sharedDescription}
                            </div>
                            {isFilter ? (
                                <MapFiltersCards
                                    {...props}
                                    {...commonProps}
                                    filterToOwner={false}
                                    onUpdateFilterFull={handleUpdateFilter}
                                    onDeleteFilter={handleDeleteFilter}
                                    onSetDefaultFilter={handleSetDefaultFilter}
                                />
                            ) : (
                                <MapSavedViewsCards
                                    {...props}
                                    {...commonProps}
                                    filterToOwner={false}
                                    onSaveView={handleSaveView}
                                    onDeleteView={handleDeleteView}
                                    onSetDefaultView={handleSetDefaultView}
                                />
                            )}
                        </>
                    )}
                </PageSection>
            </BTLayoutContent>
        </BTModalLayout>
    );
};
