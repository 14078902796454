import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import {
    CaptchaResponse,
    GenerateEphemeralUrlResponse,
    GenerateOAuthCodeResponse,
    ILoginFormValues,
    ILoginRequest,
    ImpersonationInfo,
    LoginNameAndIdForLeadResponse,
    LoginResponse,
    SwitchLinkedLoginResponse,
} from "entity/login/Login/Login.api.types";

export interface ILoginHandler {
    login(data: ILoginRequest): Promise<LoginResponse>;
    logout(): Promise<EmptyResponseEntity>;
    switchLinkedLogin(loginId: number, loginType?: number): Promise<SwitchLinkedLoginResponse>;
    getCaptchaResponse(): Promise<CaptchaResponse>;
    getLoginNameAndIdForLead(builderId: number): Promise<LoginNameAndIdForLeadResponse>;
    forgotUsername(formData: ILoginFormValues): Promise<EmptyResponseEntity>;
    forgotPassword(formData: ILoginFormValues): Promise<EmptyResponseEntity>;
    impersonationCode(targetCredentialId: number): Promise<ImpersonationInfo>;
    generateOauthCode(): Promise<GenerateOAuthCodeResponse>;
    orgSelect(oAuthCode: string, orgLinkIds: number[]): Promise<EmptyResponseEntity>;
    generateEphemeralUrl(
        ephEnv: string,
        redirectUri: string,
        state: string
    ): Promise<GenerateEphemeralUrlResponse>;
}

export class LoginHandler implements ILoginHandler {
    async login(data: ILoginRequest): Promise<LoginResponse> {
        return await APIHandler("/api/Login/AjaxLogin", {
            method: "POST",
            headers: { PortalType: undefined },
            data: data,
            responseType: LoginResponse,
        });
    }

    async logout(): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Logout", {
            method: "POST",
            responseType: EmptyResponseEntity,
        });
    }

    /**
     *
     * @param loginId Id of the login you're trying to switch to
     * @param loginType the current login type - used for authenticating BEFORE switching the login.
     * Only use this in cases where the portal type is set to undefined incorrectly.
     */
    async switchLinkedLogin(
        loginId: number,
        loginType?: number
    ): Promise<SwitchLinkedLoginResponse> {
        let headers = undefined;
        if (loginType) {
            headers = { PortalType: loginType };
        }

        return await APIHandler("/api/Login/SwitchLinkedLogin", {
            method: "POST",
            data: { loginId },
            headers,
            responseType: SwitchLinkedLoginResponse,
        });
    }

    async orgSelect(oAuthCode: string, orgLinkIds: number[]): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Login/OrgSelect", {
            method: "POST",
            headers: {
                PortalType: null,
            },
            data: { oAuthCode, orgLinkIds },
            responseType: EmptyResponseEntity,
        });
    }

    async getCaptchaResponse(): Promise<CaptchaResponse> {
        return await APIHandler("/api/Login/CaptchaResponse", {
            method: "GET",
            responseType: CaptchaResponse,
        });
    }

    async getLoginNameAndIdForLead(builderId: number): Promise<LoginNameAndIdForLeadResponse> {
        return await APIHandler("/api/Login/GetLoginNameAndIdForLead/", {
            method: "GET",
            data: { id: builderId },
            responseType: LoginNameAndIdForLeadResponse,
        });
    }

    async forgotUsername(formData: ILoginFormValues): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Login/ForgotUsername`, {
            method: "POST",
            data: { email: formData.emailReset },
            responseType: EmptyResponseEntity,
        });
    }

    async forgotPassword(formData: ILoginFormValues): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Login/ForgotPassword`, {
            method: "POST",
            data: { username: formData.usernameReset },
            responseType: EmptyResponseEntity,
        });
    }

    async generateOauthCode(): Promise<GenerateOAuthCodeResponse> {
        return await APIHandler("/api/Login/GenerateOauthCode", {
            method: "POST",
            responseType: GenerateOAuthCodeResponse,
        });
    }

    async impersonationCode(targetCredentialId: number): Promise<ImpersonationInfo> {
        return await APIHandler(`/api/Login/ImpersonationCode`, {
            method: "GET",
            data: { targetCredentialId: targetCredentialId },
            responseType: ImpersonationInfo,
        });
    }

    async generateEphemeralUrl(
        ephEnv: string,
        redirectUri: string,
        state: string
    ): Promise<GenerateEphemeralUrlResponse> {
        return await APIHandler(`/api/Login/GenerateEphemeralUrl`, {
            method: "GET",
            data: { ephEnv, redirectUri, state },
            responseType: GenerateEphemeralUrlResponse,
        });
    }
}
