import { Component } from "react";
import ReactDOM from "react-dom";

import { isUnitTest } from "utilities/environment/environment";

/**
 * This wraps a value in an observable, but only if it is defined
 */
export const getOptionalObservable = <T extends {}>(
    prop: T | undefined | null
): KnockoutObservable<T> | undefined => {
    if (prop === null || prop === undefined) {
        return undefined;
    }
    const knockoutOnWindow: KnockoutStatic = window["ko"];
    return knockoutOnWindow.observable(prop);
};

/**
 * This wraps a value in an observable
 */
export const getObservable = <T extends any>(prop?: T): KnockoutObservable<T | undefined> => {
    const knockoutOnWindow: KnockoutStatic = window["ko"];
    return knockoutOnWindow.observable(prop);
};

/**
 * This wraps an array value in an observable array
 */
export const getObservableArray = <T extends any>(prop: T[] = []): KnockoutObservableArray<T> => {
    const knockoutOnWindow: KnockoutStatic = window["ko"];
    return knockoutOnWindow.observableArray(prop);
};

/**
 * Unwraps the supplied object if necessary.
 *
 * NOTE: Obsolete. Meant to assist with CFV+React. Remove this with CFV.
 * @param param Object to unwrap
 * @returns The unwrapped object
 */
export function unwrap<T>(param: T | undefined | (() => T | undefined)): T | undefined {
    if (typeof param === "function") {
        return (param as () => T | undefined)();
    }
    return param;
}

abstract class BaseKnockoutWrapper<Props, State, VmType> extends Component<Props, State> {
    /**
     * Function to return the VM that will be bound to the knockout component.
     * This is where we will setup our observables. It should only ever be called once inside of this abstract class.
     * If there is data that needs to be pulled out of this component back to the parent entity, this is also where to setup knockout subscriptions to update the form values
     */
    abstract getViewModel(): VmType;
    /**
     * This is what you will use to update observables as needed when props change
     * @param vm VM bound to the component
     * @param prevProps previous props to use and check to see what updated
     */
    abstract updateViewModel(vm: VmType, prevProps: Props): void;
    abstract componentName: string;
    private waitForComponentInterval?: number = undefined;

    private componentHasLoaded = () => {
        const knockoutOnWindow: KnockoutStatic = window["ko"];
        return knockoutOnWindow.components.isRegistered(this.componentName);
    };

    private windowShouldHaveKnockout = () => {
        return !isUnitTest() && !window.noKnockoutOnWindow;
    };

    private waitForComponent = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (this.componentHasLoaded()) {
                resolve();
            } else {
                const maxCheckCount = 300;
                let checkCount = 0;
                // check for component 10 times/second, fail after 30 seconds
                this.waitForComponentInterval = setInterval(() => {
                    if (checkCount >= maxCheckCount) {
                        reject(
                            new Error(
                                `Failed to load knockout component, ${this.componentName}.  If this is a list page add the knockout bundle in the asp:Content area like so: <%: BundleConfig.IncludeWebPackBundle(BundleConfig.BundleNames.Component.{bundleName}) %>`
                            )
                        );
                    }
                    if (this.componentHasLoaded()) {
                        resolve();
                    }
                    checkCount += 1;
                }, 100);
            }
        });
    };

    componentDidUpdate = (prevProps: Props) => {
        this.updateViewModel(this.__koModel, prevProps);
    };

    componentWillUnmount = () => {
        if (!this.windowShouldHaveKnockout()) {
            return;
        }

        if (this.waitForComponentInterval) {
            clearInterval(this.waitForComponentInterval);
        }
        const knockoutOnWindow: KnockoutStatic = window["ko"];
        // eslint-disable-next-line react/no-find-dom-node
        knockoutOnWindow.cleanNode(ReactDOM.findDOMNode(this)!);
    };

    __koModel: VmType;

    componentDidMount = async () => {
        try {
            if (!this.windowShouldHaveKnockout()) {
                return;
            }
            const knockoutOnWindow: KnockoutStatic = window["ko"];

            this.__koModel = this.getViewModel();

            await this.waitForComponent();
            // eslint-disable-next-line react/no-find-dom-node
            knockoutOnWindow.cleanNode(ReactDOM.findDOMNode(this)!);
            // Bind the __koModel view model to the components mounted DOMnode
            // eslint-disable-next-line react/no-find-dom-node
            knockoutOnWindow.applyBindings(this.__koModel, ReactDOM.findDOMNode(this));
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    };

    render = () => {
        if (!this.windowShouldHaveKnockout()) {
            return <div>&lt;Knockout Wrapper for {this.componentName}&gt;</div>;
        }

        return <div data-bind={`component: { name: "${this.componentName}", params: $data }`} />;
    };
}

export default BaseKnockoutWrapper;
