import { BTLoginTypes } from "types/enum";

export type LinkAccountFormActions = undefined | "linkAccount";

export interface ILinkAccountFormValues {
    username: string;
    password: string;
    recaptchaToken: string | null;
}

export interface ILinkAccountRequest {
    username: string;
    password?: string;
    shareToken: string;
    isSignedIn?: boolean;
}

export interface ILoginRequest {
    username: string;
    password: string;
}

export interface ILinkAccountEntity {
    logoSrc: string;
    name: string;
    builderName: string;
    loginType: BTLoginTypes;
    suggestedUsername: string;
    isActivated: boolean;
    hasActiveSession: boolean;
    username: string;
    hasAlreadyLinked: boolean;
}

export class LinkAccountEntity implements ILinkAccountEntity {
    constructor(data: ILinkAccountEntity) {
        this.logoSrc = data.logoSrc;
        this.name = data.name;
        this.builderName = data.builderName;
        this.loginType = data.loginType;
        this.suggestedUsername = data.suggestedUsername;
        this.isActivated = data.isActivated;
        this.hasActiveSession = data.hasActiveSession;
        this.username = data.username;
        this.hasAlreadyLinked = data.hasAlreadyLinked;
    }
    logoSrc: string;
    name: string;
    builderName: string;
    loginType: BTLoginTypes;
    suggestedUsername: string;
    isActivated: boolean;
    hasActiveSession: boolean;
    username: string;
    hasAlreadyLinked: boolean;
}

export interface IShareTokenLoginType {
    loginType: BTLoginTypes;
}

export interface IFinalizeActivationRequest {
    shareToken: string;
    emailAddress: string;
}
