import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISubVendorProps } from "entity/sub/SubVendor/SubVendor";

const SubVendor = lazyLoadWithRetry(() => import("./SubVendor"));

export const RouteSubVendor: React.FunctionComponent<
    Omit<ISubVendorProps, "history" | "match" | "location" | "id">
> = (props) => (
    <RouteRelative
        path={routes.sub.details}
        render={(routeProps) => {
            const urlParams = routeProps.match.params;
            const defaultTab = urlParams.defaultTab ? urlParams.defaultTab : undefined;
            return (
                <BTSuspense>
                    <SubVendor
                        {...props}
                        {...routeProps}
                        id={parseInt(urlParams.id)}
                        defaultTab={defaultTab}
                    />
                </BTSuspense>
            );
        }}
    />
);
