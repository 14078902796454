import { useContext } from "react";

import { TimeLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

export interface ITimezoneDetailsProps {
    builderTimeLocale: TimeLocale;
}

export function withTimezoneDetails<T>(Component: React.ComponentType<T & ITimezoneDetailsProps>) {
    const WithTimezoneDetails: React.FunctionComponent<T> = (props) => {
        const builderInfo = useContext(BuilderInfoContext);
        const timeLocale = builderInfo?.locale.timeLocale ?? TimeLocale.default;
        return <Component builderTimeLocale={timeLocale} {...props} />;
    };

    WithTimezoneDetails.displayName = Component.name
        ? `WithTimezoneDetails(${Component.name})`
        : "WithTimezoneDetails";

    return WithTimezoneDetails;
}
