import {
    APIHandlerVersion,
    BTServiceDropdown,
    IAPIHandlerResult,
} from "types/apiResponse/apiResponse";
import { BuilderFilterTypes, ClosedAccountTypes } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

export interface IBuilderSearchExtraData {
    daysSinceLastLogin: string;
    externalId: string;
}

export class BuilderSearchResponseType extends BTServiceDropdown<IBuilderSearchExtraData> {}

export interface IBuilderSearchHandler {
    searchForBuilders(
        value: string,
        accountStatus?: ClosedAccountTypes,
        accountType?: BuilderFilterTypes
    ): IAPIHandlerResult<BuilderSearchResponseType>;
}

export class BuilderSearchHandler implements IBuilderSearchHandler {
    searchForBuilders(
        value: string,
        accountStatus?: ClosedAccountTypes,
        accountType?: BuilderFilterTypes
    ): IAPIHandlerResult<BuilderSearchResponseType> {
        return APIHandler(`/api/Builder/GetBuildersForDropdown`, {
            method: "GET",
            responseType: BuilderSearchResponseType,
            data: {
                searchOptions: {
                    SearchQuery: value,
                    accountStatus,
                    accountType,
                },
            },
            version: APIHandlerVersion.cancellable,
        });
    }
}
