import moment from "moment";
import { v4 as uuid } from "uuid";

import { APIHandlerVersion, IAPIHandlerResult } from "types/apiResponse/apiResponse";

import { APIHandler } from "utilities/apiHandler";
import { uploadFile } from "utilities/file/file";
import { PortalType } from "utilities/portal/portal";

import {
    currentSchemaVersion,
    EditorContent,
} from "commonComponents/btWrappers/editor/editor.types";

import { ChatSearchResponse } from "entity/chat/ChatSearch/ChatSearch.types";
import { default as ChannelListExampleJson } from "entity/chat/common/channelList.example.json";
import {
    Channel,
    ChannelCreatedEvent,
    ChannelEventCursorResponse,
    ChannelEventListResponse,
    ChannelHistoryElementType,
    ChannelListResponse,
    ChannelMessageEvent,
    ChannelParticipantStatus,
    ChannelReadResponse,
    ChatEvent,
    ChatEventType,
    IChannelEventListRequest,
    IChannelListRequest,
    IChannelMediaUploadResponse,
    IChannelPatchRequest,
    ICreateChannelRequest,
    IMessageableUsersRequest,
    ISendChatRequest,
    MessageableUsersResponse,
} from "entity/chat/common/chat.types";
import { default as EventListExampleJson } from "entity/chat/common/events.example.json";
import { default as MessageableUserExampleJson } from "entity/chat/common/messageableUser.example.json";

export interface IChatHandler {
    getChannels: (request: IChannelListRequest) => IAPIHandlerResult<ChannelListResponse>;
    getMessageableUsers: (
        request: IMessageableUsersRequest
    ) => IAPIHandlerResult<MessageableUsersResponse>;
    getMessageableUsersNotInChannel: (
        channelId: string,
        request: IMessageableUsersRequest
    ) => IAPIHandlerResult<MessageableUsersResponse>;
    getChannelEventsByChannelId: (
        channelId: string,
        request: IChannelEventListRequest
    ) => Promise<ChannelEventListResponse>;
    getChannelEvents: (request: IChannelEventListRequest) => Promise<ChannelEventListResponse>;
    sendChat: (channelId: string, request: ISendChatRequest) => Promise<ChannelMessageEvent>;
    createChannel: (request: ICreateChannelRequest) => Promise<ChannelCreatedEvent>;
    readChannel: (channelId: string) => Promise<ChannelReadResponse>;
    getChannel: (channelId: string) => Promise<Channel>;
    patchChannel: (channelId: string, patchRequest: IChannelPatchRequest) => Promise<Channel>;
    uploadMedia: (
        orgId: number,
        file: Blob,
        portalType: PortalType,
        onProgress?: (percent: number) => void
    ) => Promise<IChannelMediaUploadResponse>;
    getCursorForMessageId: (messageId: string) => Promise<ChannelEventCursorResponse>;
    search: (
        query: string,
        limit: number,
        nextCursor?: string,
        abortSignal?: AbortSignal
    ) => Promise<ChatSearchResponse>;
}

export class ChatHandler implements IChatHandler {
    readChannel(channelId: string): Promise<ChannelReadResponse> {
        return APIHandler(`/api/Channels/${channelId}/read`, {
            method: "POST",
            responseType: ChannelReadResponse,
        });
    }
    getChannels(request: IChannelListRequest): IAPIHandlerResult<ChannelListResponse> {
        return APIHandler("/api/Channels", {
            method: "GET",
            responseType: ChannelListResponse,
            data: request,
            version: APIHandlerVersion.cancellable,
            throwExceptionOnAbort: false,
        });
    }

    getMessageableUsers(
        request: IMessageableUsersRequest
    ): IAPIHandlerResult<MessageableUsersResponse> {
        return APIHandler("/api/Channels/MessageableUsers", {
            method: "GET",
            responseType: MessageableUsersResponse,
            data: request,
            version: APIHandlerVersion.cancellable,
            throwExceptionOnAbort: false,
        });
    }

    getMessageableUsersNotInChannel(
        channelId: string,
        request: IMessageableUsersRequest
    ): IAPIHandlerResult<MessageableUsersResponse> {
        return APIHandler(`/api/Channels/${channelId}/MessageableUsers`, {
            method: "GET",
            responseType: MessageableUsersResponse,
            data: request,
            version: APIHandlerVersion.cancellable,
            throwExceptionOnAbort: false,
        });
    }

    getChannelEventsByChannelId(
        channelId: string,
        request: IChannelEventListRequest
    ): Promise<ChannelEventListResponse> {
        return APIHandler(`/api/Channels/${channelId}/Events`, {
            method: "GET",
            responseType: ChannelEventListResponse,
            data: request,
        });
    }

    getChannelEvents(request: IChannelEventListRequest): Promise<ChannelEventListResponse> {
        return APIHandler(`/api/Channels/Events`, {
            method: "GET",
            responseType: ChannelEventListResponse,
            data: request,
        });
    }

    getChannel(channelId: string): Promise<Channel> {
        return APIHandler(`/api/Channels/${channelId}`, {
            method: "GET",
            responseType: Channel,
        });
    }

    sendChat(channelId: string, request: ISendChatRequest): Promise<ChannelMessageEvent> {
        return APIHandler(`/api/Channels/${channelId}/Messages`, {
            method: "POST",
            responseType: ChannelMessageEvent,
            data: request,
        });
    }

    createChannel(request: ICreateChannelRequest): Promise<ChannelCreatedEvent> {
        return APIHandler(`/api/Channels`, {
            method: "POST",
            responseType: ChannelCreatedEvent,
            data: request,
        });
    }

    patchChannel(channelId: string, patchRequest: IChannelPatchRequest): Promise<Channel> {
        return APIHandler(`/apix/v2.0/Channels/${channelId}`, {
            method: "PATCH",
            responseType: Channel,
            data: patchRequest,
        });
    }

    async uploadMedia(
        orgId: number,
        file: Blob,
        portalType: PortalType,
        onProgress?: (progressPercent: number) => void
    ): Promise<IChannelMediaUploadResponse> {
        const response = await uploadFile<IChannelMediaUploadResponse>(
            file,
            `/apix/v2.0/channels/org/${orgId}/media`,
            { onProgressUpdate: onProgress, headers: { PortalType: String(portalType) } }
        );
        return response;
    }

    getCursorForMessageId(messageId: string): Promise<ChannelEventCursorResponse> {
        return APIHandler(`/apix/v2.0/Channels/Events/Messages/${messageId}/Cursor`, {
            method: "GET",
            responseType: ChannelEventCursorResponse,
        });
    }

    async search(
        query: string,
        limit: number,
        nextCursor?: string,
        abortSignal?: AbortSignal
    ): Promise<ChatSearchResponse> {
        const { cancel, unhandledResponse } = APIHandler(`/apix/v2.0/channels/search`, {
            method: "GET",
            responseType: ChatSearchResponse,
            throwExceptionOnAbort: false,
            version: APIHandlerVersion.cancellable,
            data: { query, limit, nextCursor },
        });
        abortSignal?.addEventListener("abort", cancel);
        return unhandledResponse;
    }
}

export const defaultChatHandler = new ChatHandler();

export class FakeChatHandler implements IChatHandler {
    public cancelOverride = () => {};

    getChannels = (): IAPIHandlerResult<ChannelListResponse> => {
        return {
            response: Promise.resolve(new ChannelListResponse(ChannelListExampleJson)),
            cancel: this.cancelOverride,
            unhandledResponse: Promise.resolve(new ChannelListResponse(ChannelListExampleJson)),
        };
    };

    getChannelEventsByChannelId = async (channelId: string) => {
        const events = new ChannelEventListResponse(EventListExampleJson);
        events.data.forEach((event) => {
            switch (event.type) {
                case ChatEventType.ChannelCreated:
                    event.channel.channelId = channelId;
                    break;
                case ChatEventType.Message:
                    event.message.channelId = channelId;
                    break;
                case ChatEventType.UserAdded:
                    event.channelId = channelId;
                    break;
                case ChatEventType.UserRemoved:
                    event.channelId = channelId;
                    break;
                case ChatEventType.ChannelRead:
                    event.channelId = channelId;
                    break;
                default:
                    // The below line throws compiler error if new event types are added
                    const neverShouldOccur: never = event;
                    throw new Error(`Unexpected event type: ${neverShouldOccur}`);
            }
        });
        return events;
    };

    getMessageableUsers = (
        _request: IMessageableUsersRequest
    ): IAPIHandlerResult<MessageableUsersResponse> => {
        return {
            cancel: this.cancelOverride,
            response: Promise.resolve(new MessageableUsersResponse(MessageableUserExampleJson)),
            unhandledResponse: Promise.resolve(
                new MessageableUsersResponse(MessageableUserExampleJson)
            ),
        };
    };

    private nextEventsToBePolled: ChatEvent[] = [];
    public addMockEventsToBePolled = (nextEventsToBePolled: ChatEvent[]) => {
        this.nextEventsToBePolled = nextEventsToBePolled;
    };

    getMessageableUsersNotInChannel = (
        _channelId: string,
        _request: IMessageableUsersRequest
    ): IAPIHandlerResult<MessageableUsersResponse> => {
        return {
            cancel: this.cancelOverride,
            response: Promise.resolve(new MessageableUsersResponse(MessageableUserExampleJson)),
            unhandledResponse: Promise.resolve(
                new MessageableUsersResponse(MessageableUserExampleJson)
            ),
        };
    };

    getChannelEvents = async () => {
        const returnEvents = this.nextEventsToBePolled;
        this.nextEventsToBePolled = [];
        return {
            data: returnEvents,
            nextCursor: null,
            previousCursor: null,
        };
    };

    sendChat = async (): Promise<ChannelMessageEvent> => {
        const date = moment.utc();
        const eventId = uuid();
        return {
            eventDateTimeUtc: date,
            eventId: uuid(),
            type: ChatEventType.Message,
            message: {
                authorId: 1,
                authorName: "Test",
                authorStatus: ChannelParticipantStatus.Active,
                authorRole: "Full Admin",
                channelId: uuid(),
                content: new EditorContent({
                    content: null,
                    schemaVersion: currentSchemaVersion,
                }),
                dateSentUtc: date,
                id: 12345,
                plainTextContent: "",
                externalId: "message-12345",
            },
            channelHistoryElementDateUtc: date,
            channelHistoryElementId: eventId,
            channelHistoryElementType: ChannelHistoryElementType.Message,
        };
    };

    createChannel = async () => {
        throw new Error("Method not implemented");
    };

    readChannel = async (): Promise<ChannelReadResponse> => {
        return {};
    };

    getChannel = async (channelId: string) => {
        const channel = new Channel({ ...ChannelListExampleJson.data[0] });
        channel.channelId = channelId;
        channel.chatPreview.message.channelId = channelId;
        return channel;
    };

    patchChannel = async (channelId: string, patch: IChannelPatchRequest) => {
        const updatedChannel = new Channel({ ...ChannelListExampleJson.data[0], channelId });
        if (patch.channelName) {
            updatedChannel.channelName = patch.channelName;
        }
        if (patch.channelType || patch.participants) {
            return Promise.reject(new Error("Unsupported mock patch"));
        }

        return Promise.resolve(updatedChannel);
    };

    uploadMedia = async () => {
        throw new Error("Method not implemented");
    };

    getCursorForMessageId = async () => {
        throw new Error("Method not implemented");
    };

    search = async () => {
        throw new Error("Method not implemented");
    };
}
