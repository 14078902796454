import Cookies from "js-cookie";
import moment from "moment";

import { BTLocalStorage } from "types/btStorage";

import { IntercomSettings } from "commonComponents/utilities/IntercomChat/IntercomTypes";

// Remove this file and instead use react-use-intercom NPM package and the useIntercom() hook once everything is converted to SPA.
// for now sticking with this

// eslint-disable-next-line no-restricted-syntax
const win = window as any;
let timer: number | undefined = undefined;
export function initializeChat(settings: IntercomSettings) {
    // Remove with SPA, intercom should never render in an iframe
    if (win.top !== win.self) {
        return;
    }
    const showChatCookie = BTLocalStorage.get("bt-object-intercomShowChat");
    win.intercomSettings = settings; // can we use state?
    if (showChatCookie && showChatCookie.expires > moment().valueOf()) {
        if (showChatCookie.value) {
            showChat();
        } else {
            setupChat(undefined);
        }
    } else {
        // always hide the launcher
        setupChat(undefined, true);
    }
}

function addCloseHover(element: Element, newButton: HTMLButtonElement) {
    element.addEventListener("mouseover", () => {
        newButton.style.display = "inline-block";
    });
    element.addEventListener("mouseout", () => {
        newButton.style.display = "none";
    });
}

function addCloseElement() {
    clearTimeout(timer);
    timer = setTimeout(() => {
        let button: Element | undefined = undefined;
        const intercomButtons = Array.from(document.getElementsByClassName("intercom-launcher"));
        const intercomPostLoadButtons = Array.from(
            document.getElementsByClassName("intercom-launcher-frame")
        );
        if (intercomButtons.length > 0) {
            button = intercomButtons[0];
        } else if (intercomPostLoadButtons.length > 0) {
            button = intercomPostLoadButtons[0].parentElement!;
        }

        const existingButton = document.getElementById("btnCloseIntercom");

        if (button && !existingButton) {
            const newButton = document.createElement("button");
            newButton.id = "btnCloseIntercom";
            newButton.addEventListener("click", (e) => {
                hideChat();
                e.preventDefault();
            });
            button.appendChild(newButton);
            addCloseHover(button, newButton);
        }
    }, 3000);
}

export function setupChat(setupCommand?: string, hideLauncher?: boolean) {
    const intercomSettings = win.intercomSettings;
    const intercomRedirectCookieName = "intercom.redirect";

    if (window.location.pathname.toLowerCase().includes("webview/")) {
        return;
    }

    // START INTERCOM CODE
    const ic = win.Intercom;
    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", intercomSettings);
    } else {
        const doc = document;
        const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
        };
        const q: any[] = [];
        i.c = function (args: any) {
            q.push(args);
        };
        i.q = q;
        win.Intercom = i;
        const load = function () {
            if (intercomSettings === undefined) {
                return;
            }
            const s = doc.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + intercomSettings.app_id;
            const x = doc.getElementsByTagName("script")[0];
            x.parentNode!.insertBefore(s, x);
            s.onload = function () {
                win.Intercom("boot", intercomSettings);
                if (setupCommand) {
                    win.Intercom(setupCommand);
                }

                if (setupCommand === "shutdown") {
                    return;
                }

                win.Intercom("update", {
                    hide_default_launcher: hideLauncher,
                });

                // eslint-disable-next-line deprecate/member-expression
                const redirectCookie = Cookies.get(intercomRedirectCookieName);

                if (redirectCookie) {
                    // eslint-disable-next-line deprecate/member-expression
                    Cookies.remove(intercomRedirectCookieName, { path: "/" });
                    // HACK: We need to make sure we give the Intercom boot process enough time to finish before redirecting,
                    //   but there doesn't seem to be a hook to let us know when that is. So we just use a timeout.
                    void setTimeout(() => {
                        location.assign(redirectCookie);
                    }, 3000);
                }

                if (!hideLauncher) {
                    addCloseElement();
                }
            };

            win.Intercom("onHide", function () {
                const existingValue = BTLocalStorage.get("bt-object-intercomShowChat");
                if (existingValue.expires !== -1) {
                    BTLocalStorage.set("bt-object-intercomShowChat", {
                        value: false,
                        expires: moment().add(1, "days").valueOf(),
                    });
                    addCloseElement();
                }
            });

            win.Intercom("onUnreadCountChange", function (unreadCount: number) {
                if (unreadCount > 0) {
                    addCloseElement();
                    win.Intercom("update", {
                        hide_default_launcher: false,
                    });
                }
            });

            win.Intercom("onShow", function () {
                BTLocalStorage.set("bt-object-intercomShowChat", {
                    value: true,
                    expires: moment().add(1, "days").valueOf(),
                });
                addCloseElement();
            });
        };
        if (document.readyState === "complete") {
            load();
        } else if (win.attachEvent) {
            win.attachEvent("onload", load);
        } else {
            win.addEventListener("load", load, false);
        }
    }
    // END INTERCOM CODE
}

export function updateIntercomUserData(userData?: any) {
    const data = Object.assign({ last_request_at: new Date().getTime() / 1000 }, userData);
    win.Intercom("update", data);
}

export function showChat() {
    if (!win.Intercom) {
        setupChat("show");
    } else {
        win.Intercom("onShow", function () {
            (
                document.querySelector('[name="intercom-messenger-frame"]') as
                    | HTMLElement
                    | undefined
            )?.focus();
        });
        win.Intercom("show");
        win.Intercom("update", {
            hide_default_launcher: false,
        });
    }
    addCloseElement();
}

export function hideChat() {
    if (win.Intercom) {
        BTLocalStorage.set("bt-object-intercomShowChat", { value: false, expires: -1 });
        win.Intercom("hide");
        win.Intercom("update", {
            hide_default_launcher: true,
        });
    }
}

// Should only be used to completely shut down intercom
// which should only be done if a user is logged out
export function shutdownChat() {
    if (!win.Intercom) {
        setupChat("shutdown");
    } else {
        win.Intercom("shutdown");
    }
}
