import Overflow from "rc-overflow";

import "./BTOverflow.less";

/**
 * Give this component a list of JSX.Element's and it will automatically convert them to a dropdown menu on small devices
 * @example
 * <BTOverflow
 *  renderRest={(omittedItems) => { }}
 *  data={[
 *    <>item 1</>,
 *    <>item 2</>,
 *    <>item 3</>,
 *  ]}
 *  suffix={<div>Always show this</div>}
 * />
 */
export interface IBTOverflowProps<ItemType> {
    className?: string;
    style?: React.CSSProperties;
    data: ItemType[];
    itemKey?: React.Key | ((item: ItemType) => React.Key);
    renderItem?: (item: ItemType) => React.ReactNode;

    /** @default responsive */
    maxCount?: number | "responsive" | "invalidate";
    renderRest: (omittedItems: ItemType[]) => React.ReactNode;
    suffix?: React.ReactNode;

    itemWidth?: number;
    "data-testid"?: string;
}

export const BTOverflow = function <ItemType = undefined>({
    className,
    style,
    data,
    itemKey,
    renderItem,
    maxCount = "responsive",
    renderRest,
    suffix,
    itemWidth,
    "data-testid": dataTestId,
}: IBTOverflowProps<ItemType>) {
    return (
        <Overflow
            className={className}
            style={style}
            data={data}
            itemKey={itemKey}
            renderItem={renderItem}
            maxCount={maxCount}
            renderRest={renderRest}
            suffix={suffix}
            itemWidth={itemWidth}
            data-testid={dataTestId}
        />
    );
};
