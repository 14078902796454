import { Collapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import { FC, memo } from "react";

import { BTLocalStorage } from "types/btStorage";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { isNullOrWhitespace } from "utilities/string/string";

export interface IBTCollapseProps extends Omit<CollapseProps, "onChange" | "panel"> {
    "data-testid": string;
    onChange?: (key: string | string[], isActive: boolean) => void;
    sessionStorageKey?: string;
}

export interface IBTCollapsePanelProps {
    isActive?: boolean;
}

const hasActivePanel = (key?: (string | number)[] | string | number) => {
    if (key === undefined) {
        return false;
    }
    if (Array.isArray(key)) {
        return key.length > 0;
    }
    if (typeof key === "string") {
        return !isNullOrWhitespace(key);
    }
    return !!key;
};

export const BTCollapse: FC<IBTCollapseProps & ITrackingProp> = track((props) => ({
    component: "Collapse",
    uniqueId: props["data-testid"],
}))(
    memo(
        ({
            onChange,
            sessionStorageKey,
            children,
            "data-testid": testid,
            tracking,
            defaultActiveKey,
            ...otherProps
        }) => {
            const handleChange = (key: string | string[]) => {
                onChange?.(key, hasActivePanel(key));
                tracking?.trackEvent({
                    event: "CollapseToggled",
                    extraInfo: {
                        key: key,
                        hasActivePanel: hasActivePanel(key),
                    },
                });
                if (sessionStorageKey) {
                    const values = BTLocalStorage.get("bt-object-btCollapsePrefs") || {};
                    values[sessionStorageKey] = key;
                    BTLocalStorage.set("bt-object-btCollapsePrefs", values);
                }
            };

            const collapsePrefs = BTLocalStorage.get("bt-object-btCollapsePrefs");
            const defaultKey =
                sessionStorageKey && collapsePrefs[sessionStorageKey] !== undefined
                    ? collapsePrefs[sessionStorageKey]
                    : defaultActiveKey;

            return (
                // eslint-disable-next-line react/forbid-elements
                <Collapse
                    onChange={handleChange}
                    defaultActiveKey={defaultKey}
                    data-testid={testid}
                    {...otherProps}
                >
                    {children}
                </Collapse>
            );
        }
    )
);

export enum BTCollapseSessionStorageKeys {
    jobFromTemplate = "jobFromTemplate",
}
