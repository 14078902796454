import { useMemo } from "react";

import { getUniqueId } from "utilities/number/number";

export function getRandomId() {
    const hash = getUniqueId("string");
    return `uid-${hash}`;
}

export function useRandomId(): string {
    return useMemo(() => getRandomId(), []);
}
