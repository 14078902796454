import { APIHandler } from "utilities/apiHandler";

import { ExistingJobListResponse } from "./JobPrompt.types";

export interface IJobPromptHandler {
    get(existingFilter?: any): Promise<ExistingJobListResponse>;
}

export class JobPromptHandler implements IJobPromptHandler {
    async get(existingFilter?: any): Promise<ExistingJobListResponse> {
        const request = {
            existingFilter: undefined,
        };

        if (existingFilter) {
            request.existingFilter = existingFilter;
        }

        return await APIHandler("/api/jobpicker/GetExistingJobList", {
            method: "GET",
            data: request,
            responseType: ExistingJobListResponse,
        });
    }
}
