import { BTSelectItem } from "types/apiResponse/apiResponse";

export class Job extends BTSelectItem {
    constructor(data: any) {
        super({ id: data.jobID, name: data.jobName });
        this.builderID = data.builderID;
        this.jobID = data.jobID;
        this.jobName = data.jobName;
        this.NewJobName = data.NewJobName;
    }

    builderID: number;
    jobID: number;
    jobName: string;
    NewJobName: string;
}

export class ExistingJobListResponse {
    constructor(data: any) {
        this.jobs = data.jobs.map((j: any) => new Job(j));
        this.footerData = data.footerData;
        this.records = data.records;
        this.infiniteScrollStatus = data.infiniteScrollStatus;
        this.isLoaded = data.isLoaded;
        this.isTemplateMode = data.isTemplateMode;
    }

    jobs: Job[];
    footerData: any | null;
    records: number;
    infiniteScrollStatus: number;
    isLoaded: boolean;
    isTemplateMode: boolean;
}
