import moment from "moment";
import { Component } from "react";

import { BTLocalStorage } from "types/btStorage";

import { GetReadOnlyEmptyStateAccessMessage } from "utilities/emptyState/emptyStateUtility";
import { isInPortal } from "utilities/portal/portal";
import { routes } from "utilities/routes";

import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import {
    EmptyStateDataType,
    IEmptyStateBannerOnlyEntityProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { AutoDismissEntities } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    EmptyStateBanner,
    EmptyStateCallToActionButton,
    getEmptyStateBannerOnlyEntityDataType,
} from "commonComponents/entity/emptyState/EmptyStateBanner";

interface IJobPickerEmptyStateBannerProps extends IEmptyStateBannerOnlyEntityProps {
    canClearSearch: boolean;
    isBanner: boolean;
    mode: "Job" | "Template";
    isConnectedToAccounting: boolean;
}

export class JobPickerEmptyStateBanner extends Component<IJobPickerEmptyStateBannerProps> {
    private getIsDismissed = () => {
        const { isDismissed, isNewToEntity } = this.props;
        return isDismissed || !isNewToEntity;
    };

    componentDidMount = () => {
        // If the user has not already dismissed or is not new to the entity then check
        // local storage. If 14 days has passed then auto dismiss the banner
        if (!this.getIsDismissed()) {
            const autoDismissedDate = BTLocalStorage.get("bt-object-autoDismissEmptyState");
            if (autoDismissedDate[AutoDismissEntities.JobPicker]) {
                if (
                    moment().diff(
                        moment(autoDismissedDate[AutoDismissEntities.JobPicker]),
                        "day"
                    ) >= 14
                ) {
                    this.props.onDismiss!();
                    delete autoDismissedDate[AutoDismissEntities.JobPicker];
                    BTLocalStorage.set("bt-object-autoDismissEmptyState", autoDismissedDate);
                }
            } else {
                autoDismissedDate[AutoDismissEntities.JobPicker] = new Date().toISOString();
                BTLocalStorage.set("bt-object-autoDismissEmptyState", autoDismissedDate);
            }
        }
    };

    private getEmptyStateContent = () => {
        const { isReadonly, onCallToActionClick, mode } = this.props;

        if (isReadonly) {
            const addOrAnyVerbiage = isInPortal({ subs: true }) ? "any" : "add";
            return mode === "Job"
                ? GetReadOnlyEmptyStateAccessMessage("jobs", addOrAnyVerbiage)
                : GetReadOnlyEmptyStateAccessMessage("templates", addOrAnyVerbiage);
        }

        if (mode === "Job") {
            return <div>Add any jobs that you would like to start working on.</div>;
        }

        return (
            <>
                Streamline your process to more efficiently manage jobs with templates.
                <BTLinkRelative
                    to={"/JobPickerActions" + routes.template.getTemplateDetailsLink(0)}
                >
                    <EmptyStateCallToActionButton onCallToActionClick={onCallToActionClick}>
                        Create a {mode}
                    </EmptyStateCallToActionButton>
                </BTLinkRelative>
            </>
        );
    };

    private getJobEmptyStateTitle = () => {
        const { isReadonly, mode } = this.props;
        if (mode !== "Job") {
            return "Save time with templates";
        } else if (!isReadonly) {
            return "Get started here!";
        } else {
            return "Track job progress with Buildertrend";
        }
    };

    render() {
        const { actionBeingPerformed, onDismiss } = this.props;

        const dataType = getEmptyStateBannerOnlyEntityDataType(this.props);
        const isInitialDataState = dataType === EmptyStateDataType.InitialWithDataState;
        const hasFilteredDataAndIsBanner = this.props.hasFilteredData && this.props.isBanner;
        if (
            dataType === null ||
            ((isInitialDataState || hasFilteredDataAndIsBanner) && this.getIsDismissed())
        ) {
            return null;
        }

        if (
            this.props.isBanner &&
            dataType !== EmptyStateDataType.EmptyState &&
            isInPortal({ subs: true })
        ) {
            return null;
        }

        if (dataType === EmptyStateDataType.FilteredState && !this.props.isBanner) {
            if (this.props.canClearSearch) {
                return (
                    <EmptyStateBanner
                        title="Your search returned no results."
                        actionBeingPerformed={undefined}
                        isDismissable={false}
                        bordered={false}
                    >
                        Adjust your search to find what you're looking for.
                        {this.props.canClearSearch && (
                            <EmptyStateCallToActionButton
                                onCallToActionClick={this.props.onCallToActionClick}
                            >
                                Clear Search
                            </EmptyStateCallToActionButton>
                        )}
                    </EmptyStateBanner>
                );
            }

            return (
                <EmptyStateBanner
                    title="No results"
                    actionBeingPerformed={undefined}
                    isDismissable={false}
                    bordered={false}
                >
                    Adjust or clear your filters to find the {this.props.mode.toLocaleLowerCase()}s
                    you're looking for.
                </EmptyStateBanner>
            );
        }

        return (
            <EmptyStateBanner
                title={this.getJobEmptyStateTitle()}
                actionBeingPerformed={actionBeingPerformed}
                onDismiss={onDismiss}
                isDismissable={isInitialDataState || this.props.hasFilteredData}
            >
                {this.getEmptyStateContent()}
            </EmptyStateBanner>
        );
    }
}
