import { unwrap } from "legacyComponents/BaseKnockoutWrapper";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { APIError } from "utilities/apiHandler";

export enum FileSizeUnit {
    Byte = "B",
    Kilobyte = "KB",
    Megabyte = "MB",
    Gigabyte = "GB",
    Terabyte = "TB",
}

export function convertFileSizeToBytes(size: number, unit: FileSizeUnit) {
    switch (unit) {
        case FileSizeUnit.Byte:
            return size;
        case FileSizeUnit.Kilobyte:
            return size * 1024;
        case FileSizeUnit.Megabyte:
            return size * 1024 ** 2;
        case FileSizeUnit.Gigabyte:
            return size * 1024 ** 3;
        case FileSizeUnit.Terabyte:
            return size * 1024 ** 4;
    }
}

/**
 * @param fileName a file name with the last "." and extension following it
 * @returns
 * the extension of the file (without the period)
 * @example
 * getFileExtension("myFile.png")
 */
export function getFileExtension(fileName: string) {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

/**
 *
 * @param file The file to validate
 * @param supportedFileExtensions An array of string of supported file types to allow. Each file type
 * should be prefixed with a "`.`"
 * @example
 * isSupportedFileExtension(myFile, [".png", ".jpg", ".jpeg", ".bmp"])
 */
export function isSupportedFileExtension(fileName: string, supportedFileExtensions: string[]) {
    // File name extension grabber that supports "hidden" files as well
    // https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
    const setOfSupportedExtensions = new Set(
        supportedFileExtensions.map((supportedFileExt) => {
            const isolatedSupportedExt = supportedFileExt.startsWith(".")
                ? supportedFileExt.substring(1)
                : supportedFileExt;
            return isolatedSupportedExt.toLocaleLowerCase();
        })
    );

    if (setOfSupportedExtensions.has("*")) {
        return true;
    }

    const fileExtension = getFileExtension(fileName);
    return setOfSupportedExtensions.has(fileExtension.toLocaleLowerCase());
}

/**
 * Throws an exception if the given base64 encoded image string was not a valid/renderable image.
 */
export async function validateBase64Image(imageBase64: string, timeoutInMilliseconds = 10000) {
    const image = new Image();
    if (!image) {
        return Promise.reject(
            new Error("Could not attempt validation as an image object could not be created.")
        );
    }

    image.src = imageBase64;
    return new Promise<void>((resolve, reject) => {
        const timeoutHandle = setTimeout(() => {
            reject(
                new Error("Could not determine image validity because the operation timed out.")
            );
        }, timeoutInMilliseconds);

        image.onload = () => {
            clearTimeout(timeoutHandle);
            resolve();
        };

        image.onerror = () => {
            clearTimeout(timeoutHandle);
            reject(
                new Error(
                    "Image could not be rendered. Either the image is corrupt, its formating is not suppported by the current browser, or there was an issue with the base64 encoding."
                )
            );
        };
    });
}

export async function convertImageBlobToBase64String(blob: Blob, timeoutInMilliseconds = 10000) {
    return new Promise<string>((resolve, reject) => {
        const timeoutHandle = setTimeout(() => {
            reject(
                new Error(
                    "An error occurred while converting the image to a base64 encoding because the opperation timed out"
                )
            );
        }, timeoutInMilliseconds);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (event) {
            clearTimeout(timeoutHandle);
            if (event.target?.result && typeof event.target.result === "string") {
                try {
                    resolve(event.target.result);
                } catch (e) {
                    reject(
                        new Error(
                            "An error occurred while converting the image to a base64 encoding"
                        )
                    );
                }
            } else {
                reject(
                    new Error(
                        "An error occurred while converting the image to a base64 encoding: " +
                            "The file reader did not return a valid result"
                    )
                );
            }
        };
        reader.onerror = function (event) {
            clearTimeout(timeoutHandle);
            reject(event.target?.error ?? event);
        };
    });
}

/**
 * Loads a blob from a URL using the fetch() api
 * @param url Url of the blob to load
 * @param timeoutInMilliseconds Timeout in milliseconds for loading the blob
 */
export async function getBlobFromUrl(url: string, timeoutInMilliseconds = 15000) {
    const controller = new AbortController();
    const abortControllerTimeout = setTimeout(() => controller.abort(), timeoutInMilliseconds);

    let data;
    try {
        data = await fetch(url, { signal: controller.signal });
    } finally {
        clearTimeout(abortControllerTimeout);
    }

    return await data.blob();
}

/**
 * Loads an image from a URL using the fetch() api and then converts the image data to a base64 string
 * @param url Url of the image to load
 * @param timeoutInMilliseconds Timeout in milliseconds for loading the image and converting it to base64 encoding.
 * Note that the total time might be `2 * timeoutInMilliseconds` in the worst case as two operations
 * use the same timeout.
 * @returns
 */
export async function getBase64ImageFromUrl(url: string, timeoutInMilliseconds = 15000) {
    return await convertImageBlobToBase64String(
        await getBlobFromUrl(url, timeoutInMilliseconds),
        timeoutInMilliseconds
    );
}

interface IGetLocalBlobUrlFromExternalResourceOptions {
    onProgressUpdate?: (progress: number) => void;
    timeoutInMilliseconds?: number;
    headers?: Record<string, string>;
}

export async function getLocalBlobUrlFromExternalResource(
    url: string,
    options: IGetLocalBlobUrlFromExternalResourceOptions = {}
) {
    const { onProgressUpdate, headers, timeoutInMilliseconds = 15000 } = options;
    return new Promise<string>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);

        xhr.responseType = "blob";

        if (headers) {
            for (const headerName in headers) {
                xhr.setRequestHeader(headerName, headers[headerName]);
            }
        }

        xhr.onload = function () {
            if (this.status === 200) {
                const blob = new Blob([this.response], { type: this.response.type });
                const blobUrl = URL.createObjectURL(blob);
                resolve(blobUrl);
            } else {
                reject(new APIError(this.response, undefined, url));
            }
        };

        xhr.onerror = function () {
            reject(new Error("Failed to download resource"));
        };

        xhr.ontimeout = function () {
            reject(new Error("Request timed out"));
        };

        xhr.onprogress = onProgressUpdate
            ? function (event) {
                  if (event.lengthComputable) {
                      const percentComplete = (event.loaded / event.total) * 100;
                      onProgressUpdate(percentComplete);
                  }
              }
            : null;

        xhr.timeout = timeoutInMilliseconds;
        xhr.send();
    });
}

interface IUploadFileOptions {
    onProgressUpdate?: (progress: number) => void;
    headers?: Record<string, string>;
}

export async function uploadFile<UploadResponseType = void>(
    file: Blob,
    uploadUrl: string,
    options: IUploadFileOptions = {}
) {
    const { onProgressUpdate, headers } = options;
    return new Promise<UploadResponseType>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", uploadUrl, true);

        xhr.responseType = "json";

        if (headers) {
            for (const headerName in headers) {
                xhr.setRequestHeader(headerName, headers[headerName]);
            }
        }

        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(this.response);
            } else {
                reject(new APIError(this.response, undefined, uploadUrl));
            }
        };

        xhr.onerror = function () {
            reject(new Error("Failed to upload file"));
        };

        xhr.ontimeout = function () {
            reject(new Error("Request timed out"));
        };

        xhr.upload.onprogress = onProgressUpdate
            ? function (event) {
                  if (event.lengthComputable && onProgressUpdate) {
                      const percentComplete = (event.loaded / event.total) * 100;
                      onProgressUpdate(percentComplete);
                  }
              }
            : null;
        xhr.send(file);
    });
}

export function getVideoUrl(file: BTFileSystem) {
    const nativeFile = unwrap(file.nativeFile);
    if (file.isOnNewEntity && nativeFile) {
        return URL.createObjectURL(nativeFile);
    } else {
        return file.docPath;
    }
}
