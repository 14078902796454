export class BTTreeCheckboxNode {
    constructor(data: any) {
        this.id = data.id;
        this.formattedName = data.formattedName;
        this.checked = data.selected;
        this.nestedOptions = data.nestedOptions.map((item: any) => new BTTreeCheckboxNode(item));
        this.disabled = !data.enabled;
    }

    id: number | string;
    formattedName: string;
    checked: boolean;
    nestedOptions: BTTreeCheckboxNode[];
    disabled: boolean;
}
