import { Component, createElement, ErrorInfo, forwardRef, ReactNode } from "react";
import "react-dom";

import { ForbiddenReason } from "types/enum";

import {
    APIError,
    handleNoJobAccessError,
    isMissingEntityException,
    isNotAuthorizedResponse,
    missingEntityExceptionAPIResponse,
    NoAccessError,
    UpgradeRequiredError,
} from "utilities/apiHandler";
import { reportError } from "utilities/errorHelpers";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTConfirm,
    BTConfirmFooter,
    BTConfirmHeader,
} from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";

interface IErrorBoundaryProps {
    /** @default "An error occurred" */
    errorMessage?: string;
    modalConfig?: IModalConfiguration;
}

interface IErrorBoundaryState {
    hasError: boolean;
    showAlertModal: boolean;
    error?: Error;
}

// todo maybe we should change this to not production? That way stack info is shown in test/staging/etc instead of just our locals
function reactIsInDevelopmentMode() {
    return "_self" in createElement("div");
}

/**
 * Display a error message when a component throws an exception instead of showing a blank white page
 * Wrap entities/components that you want to be safe.
 * @see withErrorBoundary
 * @see https://reactjs.org/docs/error-boundaries.html
 * @example:
 * <ErrorBoundary>
 *  <ComponentThatMightThrowException />
 * </ErrorBoundary>
 */
export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
    static defaultProps = {
        errorMessage: "An error occurred",
    };

    state: Readonly<IErrorBoundaryState> = {
        hasError: false,
        showAlertModal: false,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            showAlertModal: true,
        });

        if (error !== undefined) {
            if (!(error instanceof APIError)) {
                // Important API errors are already reported in apiHandler.ts.
                // This is for reporting any OTHER errors that have been thrown but have
                // not been captured
                reportError(error, errorInfo);
            }
        } else {
            // If no error was passed to the error boundary, but the error boundary
            // was triggered anyways, we want to still report it with a generic
            // exception
            reportError(new Error("An unknown error has occurred"), errorInfo);
        }
    }

    private stackTrace = (): JSX.Element => {
        if (reactIsInDevelopmentMode()) {
            let errorText = "";

            if (this.state.error !== undefined) {
                if (this.state.error instanceof APIError) {
                    errorText = this.state.error.errorMessage;
                } else {
                    errorText = this.state.error!.toString();
                }

                if (this.state.error.stack) {
                    errorText = this.state.error.stack;
                }
            }

            // add more stack info when in debug mode
            return (
                <>
                    <br />
                    <hr />
                    <div>Note: See your console for actual file paths & line numbers</div>
                    {this.state.error && (
                        <>
                            <b>InnerException: </b>
                            <div style={{ whiteSpace: "pre-wrap" }}>{errorText}</div>
                        </>
                    )}
                </>
            );
        }

        return <></>;
    };

    private handleConfirmDismissed = () => {
        this.setState({ showAlertModal: false });
        this.props.modalConfig?.beforeClose();
    };

    render() {
        const { hasError, error, showAlertModal } = this.state;
        const { errorMessage, children, modalConfig } = this.props;

        if (hasError) {
            const stackTrace = this.stackTrace();
            let apiErrorMessage: JSX.Element = <></>;

            // API error, pull the error message for the API response
            if (error !== undefined) {
                const errorsToShowMessageFor = [UpgradeRequiredError];

                if (error instanceof APIError) {
                    const isNotAuthorized = isNotAuthorizedResponse(error);
                    const isMissingEntity = isMissingEntityException(error);
                    if (isNotAuthorized || isMissingEntity) {
                        // API responded with "Not authorized - Please login", instead of displaying a error boundary show not logged in error before redirect
                        let title = errorMessage ?? "";
                        let message = "";

                        if (isNotAuthorized) {
                            title = "Login Required";
                            message =
                                "You must be logged in to see this page, redirecting to login page...";
                        } else if (isMissingEntity) {
                            message = missingEntityExceptionAPIResponse;
                        }

                        if (modalConfig) {
                            return (
                                <BTConfirm visible={showAlertModal} icon="info" title={title}>
                                    <BTConfirmHeader>{message}</BTConfirmHeader>
                                    <BTConfirmFooter className="margin-bottom-md">
                                        <BTButton
                                            id="dismissErrorModal"
                                            data-testid="dismissErrorModal"
                                            type="primary"
                                            onClick={this.handleConfirmDismissed}
                                        >
                                            Ok
                                        </BTButton>
                                    </BTConfirmFooter>
                                </BTConfirm>
                            );
                        }

                        return (
                            <BTAlert
                                showIcon={true}
                                title={title}
                                message={message}
                                type="info"
                                style={{ margin: 5 }}
                                data-testid="apiError"
                            />
                        );
                    }

                    apiErrorMessage = (
                        <>
                            <br />
                            {error.errorMessage}
                        </>
                    );
                } else if (error instanceof NoAccessError) {
                    if (error.reason === ForbiddenReason.NotConnected) {
                        handleNoJobAccessError();
                    } else {
                        apiErrorMessage = (
                            <>
                                <br />
                                {error.message}
                            </>
                        );
                    }
                } else if (
                    error.message &&
                    error.message.length > 0 &&
                    errorsToShowMessageFor.some((e) => error instanceof e)
                ) {
                    apiErrorMessage = (
                        <>
                            <br />
                            {error.message}
                        </>
                    );
                }
            }

            if (modalConfig) {
                return (
                    <BTConfirm visible={showAlertModal} icon="error" title={errorMessage}>
                        <BTConfirmHeader>
                            {apiErrorMessage}
                            {stackTrace}
                        </BTConfirmHeader>
                        <BTConfirmFooter className="margin-bottom-md">
                            <BTButton
                                id="dismissErrorModal"
                                data-testid="dismissErrorModal"
                                type="primary"
                                onClick={this.handleConfirmDismissed}
                            >
                                Ok
                            </BTButton>
                        </BTConfirmFooter>
                    </BTConfirm>
                );
            }

            let btAlertAction: ReactNode;
            let btAlertMessage: ReactNode;
            let btAlertTitle: string;
            const isLoadChunkError = error?.name === "ChunkLoadError";

            if (isLoadChunkError) {
                btAlertAction = (
                    <BTButton
                        data-testid="reloadButton"
                        type="primary"
                        onClick={() => window.location.reload()}
                    >
                        Reload
                    </BTButton>
                );
                btAlertMessage =
                    "Please check your internet connection and try reloading the page.";
                btAlertTitle = "Fail to load page";
            } else {
                btAlertAction = <></>;
                btAlertMessage = (
                    <>
                        {errorMessage}
                        {apiErrorMessage}
                        {stackTrace}
                    </>
                );
                btAlertTitle = "Error";
            }

            return (
                <BTAlert
                    action={btAlertAction}
                    showIcon={true}
                    title={btAlertTitle}
                    message={btAlertMessage}
                    type="error"
                    style={{ margin: 5 }}
                    data-testid="errorBoundaryDefaultAlert"
                />
            );
        }
        return children;
    }
}

/**
 * Wraps a component with a ErrorBoundary, this will display an error message if the components or it's children throw an exception
 * @example:
 * export MySafeComponent = withErrorBoundary(ComponentThatMightThrowException)();
 * export MySafeComponent = withErrorBoundary(ComponentThatMightThrowException)("Custom error message");
 */
export const withErrorBoundary = <P extends object>(
    Component: React.ComponentType<P & IErrorBoundaryProps>
) => {
    return function (errorMessage?: string) {
        const Wrapped = (props: P & IErrorBoundaryProps) => {
            return (
                <ErrorBoundary errorMessage={errorMessage} modalConfig={props.modalConfig}>
                    <Component {...props} />
                </ErrorBoundary>
            );
        };

        // Format for display in DevTools
        Wrapped.displayName = Component.name
            ? `WithErrorBoundary(${Component.name})`
            : "WithErrorBoundary";

        return Wrapped;
    };
};

export const withErrorBoundaryForwardRef = <P extends object>(
    Component: React.ComponentType<P & IErrorBoundaryProps>
) => {
    return function (errorMessage?: string) {
        const Wrapped = forwardRef((props: P & IErrorBoundaryProps, ref) => {
            return (
                <ErrorBoundary errorMessage={errorMessage} modalConfig={props.modalConfig}>
                    <Component {...props} ref={ref} />
                </ErrorBoundary>
            );
        });

        // Format for display in DevTools
        Wrapped.displayName = Component.name
            ? `WithErrorBoundary(${Component.name})`
            : "WithErrorBoundary";

        return Wrapped;
    };
};
