import moment from "moment";

import { IDateRangeFormValues } from "commonComponents/entity/filters/DateRange/DateRange";

import { ConsolidatedProofOfPurchaseStatus } from "handlers";

export enum RebateListTabKeys {
    Overview = "overview",
    SupplierPrograms = "supplierPrograms",
    HowItWorks = "howItWorks",
}

export type RebateListDateRange = "All" | "YTD" | "LastQuarter" | "LastWeek";

// rename when cleaning up displayRebatesAtProofOfPurchaseLevel
export type RebateListSortOrderTypes = "Ascending" | "Descending";

export interface IRebateListPaginationParameters {
    limit?: number;
    nextCursor: string | null;
    previousCursor: string | null;
    sortColumn?: string;
    sortOrder?: RebateListSortOrderTypes;
}

export interface IProofOfPurchaseListFilterParameters {
    uploadedDate?: {
        formValues?: IDateRangeFormValues;
        after?: moment.Moment;
        before?: moment.Moment;
    };
    supplierIds?: number[];
    statuses?: ConsolidatedProofOfPurchaseStatus[];
}

// rename when cleaning up displayRebatesAtProofOfPurchaseLevel
export const RebateListDefaultPageSize = 10;

// rename when cleaning up displayRebatesAtProofOfPurchaseLevel
export const RebateListPageSizes = [RebateListDefaultPageSize, 20, 50, 100];

export interface IRebateListUrlParameters {
    supplierId?: string;
    tab?: string;
    termId?: string;
}
