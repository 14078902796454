import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISuccessPlanListProps } from "entity/customerTrainingPlan/SuccessPlanList/SuccessPlanList";

const SuccessPlanList = lazyLoadWithRetry(() => import("./SuccessPlanList"));

export const RouteSuccessPlanList = (
    props: Omit<ISuccessPlanListProps, "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.trainingPlan.list}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <SuccessPlanList {...props} {...routeProps} />
                </BTSuspense>
            );
        }}
    />
);
