/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { useQuery } from "@tanstack/react-query";
import type {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type {
    ForbiddenResponse,
    MediaFolderInfoResponse,
    MediaFoldersByFolderIdGetV2Params,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>InternalUser</code>
</div>
**Stability Level**: `stable`
 */
export const mediaFoldersByFolderIdGetV2 = (
    folderId: number,
    params?: MediaFoldersByFolderIdGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<MediaFolderInfoResponse>(
        { url: `/apix/v2/MediaFolders/${folderId}`, method: "GET", params, signal },
        options
    );
};

export const getMediaFoldersByFolderIdGetV2QueryKey = (
    folderId: number,
    params?: MediaFoldersByFolderIdGetV2Params
) => {
    return [`/apix/v2/MediaFolders/${folderId}`, ...(params ? [params] : [])] as const;
};

export const getMediaFoldersByFolderIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    folderId: number,
    params?: MediaFoldersByFolderIdGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getMediaFoldersByFolderIdGetV2QueryKey(folderId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>> = ({
        signal,
    }) => mediaFoldersByFolderIdGetV2(folderId, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!folderId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type MediaFoldersByFolderIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>
>;
export type MediaFoldersByFolderIdGetV2QueryError = ErrorType<ForbiddenResponse>;

export const useMediaFoldersByFolderIdGetV2 = <
    TData = Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    folderId: number,
    params?: MediaFoldersByFolderIdGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof mediaFoldersByFolderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getMediaFoldersByFolderIdGetV2QueryOptions(folderId, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
