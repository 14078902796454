import moment from "moment";

import {
    ActivityCalendarView,
    BuilderSelectionViews,
    OwnerCalendarDefaultViews,
    OwnerSelectionViews,
} from "types/enum";

import type { default as HasUnreadResponseJson } from "commonComponents/utilities/MainNavigation/HasUnreadResponse.api.json";
import { NavHeaderTabs } from "commonComponents/utilities/MainNavigation/MainNavigation.types";
import type { default as MainNavigationJson } from "commonComponents/utilities/MainNavigation/MainNavigationBuilder.api.json";
import type { default as OwnerMainNavigationJson } from "commonComponents/utilities/MainNavigation/MainNavigationOwner.api.json";
import type { default as SubMainNavigationJson } from "commonComponents/utilities/MainNavigation/MainNavigationSub.api.json";
import type { default as UnreadNotificationCountData } from "commonComponents/utilities/MainNavigation/UnreadNotificationCount.api.json";

import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";

type MainNavigationApiData =
    | typeof MainNavigationJson
    | typeof SubMainNavigationJson
    | typeof OwnerMainNavigationJson;
type DefaultViewsApiData = MainNavigationApiData["defaultViews"];
type HeaderStyleApiData = MainNavigationApiData["headerStyleInfo"];
type TabsApiData = MainNavigationApiData["tabs"];
type NavHeaderNoRemindersApiData = TabsApiData[keyof TabsApiData];
type NavMenuItemApiData =
    | TabsApiData["projectManagement"]["changeOrders"]
    | TabsApiData["projectManagement"]["todos"];

interface IRemindersTabData {
    title: string;
    reminders: NavMenuItemApiData;
}

interface INotificationTabData {
    title: string;
    notifications: NavMenuItemApiData;
}

export interface IRiskInsurance {
    jobId: number;
    preapprovalId: number;
}

type NavHeaderData = NavHeaderNoRemindersApiData | IRemindersTabData | INotificationTabData;

export class MainNavigationResponse {
    constructor(data: MainNavigationApiData) {
        const tabs = data.tabs;
        this.sales = new NavHeaderResponse(tabs.sales);
        this.job = new NavHeaderResponse(tabs.job);
        this.projectManagement = new NavHeaderResponse(tabs.projectManagement);
        this.files = new NavHeaderResponse(tabs.files);
        this.messaging = new NavHeaderResponse(tabs.messaging);
        this.financial = new NavHeaderResponse(tabs.financial);
        this.reports = new NavHeaderResponse(tabs.reports);

        this.users = new NavHeaderResponse(tabs.users);
        this.users.title = "Users";

        this.notifications = new NavHeaderResponse({
            title: "Notifications",
            notifications: {
                title: "Notifications",
                visible: true,
                canQuickAdd: false,
                isQuickAddPreference: false,
                quickAddCounts: 0,
                requiredPackage: null,
            },
        });

        this.help = new NavHeaderResponse(tabs.help);
        this.help.title = "Help";

        this.setup = new NavHeaderResponse(tabs.setup);
        this.setup.title = "Settings";

        this.canQuickAddCreditMemos = data.canQuickAddCreditMemos;
        this.defaultViews = new DefaultViews(data.defaultViews);
        this.headerStyleInfo = new HeaderStyleInfo(data.headerStyleInfo);
        this.builderId = data.builderId;
        this.isSearchEnabled = data.isSearchEnabled;
        this.builderIdUserIdLookup = data.builderIdUserIdLookup;
        this.multipleBuildersSelected = data.multipleBuildersSelected;
        this.isChatEnabled = data.isChatEnabled;
    }

    canQuickAddCreditMemos: boolean;
    defaultViews: DefaultViews;
    headerStyleInfo: HeaderStyleInfo;
    builderId: number;
    isSearchEnabled: boolean;
    builderIdUserIdLookup: Record<number, number>;
    multipleBuildersSelected: boolean;
    sales: NavHeaderTabs["sales"];
    job: NavHeaderTabs["job"];
    projectManagement: NavHeaderTabs["projectManagement"];
    files: NavHeaderTabs["files"];
    messaging: NavHeaderTabs["messaging"];
    financial: NavHeaderTabs["financial"];
    reports: NavHeaderTabs["reports"];
    users: NavHeaderTabs["users"];
    notifications: NavHeaderTabs["notifications"];
    help: NavHeaderTabs["help"];
    setup: NavHeaderTabs["setup"];
    isChatEnabled: boolean;

    toServiceObject = () => {
        const {
            canQuickAddCreditMemos,
            headerStyleInfo,
            defaultViews,
            builderId,
            isSearchEnabled,
            ...tabs
        } = this;
        return {
            canQuickAddCreditMemos,
            headerStyleInfo,
            defaultViews,
            builderId,
            isSearchEnabled,
            tabs,
        };
    };
}

export class NavHeaderResponse {
    constructor(data: NavHeaderData) {
        this.title = data.title;
        Object.keys(data)
            .filter((k) => k !== "title")
            .forEach((k) => {
                this[k] = new NavMenuItemResponse(data[k]);
            });
    }
    title: string | null;
}

export class NavMenuItemResponse {
    constructor(data: NavMenuItemApiData) {
        this.title = data.title || "";
        this.visible = data.visible || false;
        this.canQuickAdd = data.canQuickAdd || false;
        this.isQuickAddPreference = data.isQuickAddPreference || false;
        this.quickAddCounts = data.quickAddCounts;
        this.requiredPackage = data.requiredPackage || null;
    }
    title: string;
    visible: boolean;
    canQuickAdd: boolean;
    isQuickAddPreference: boolean;
    quickAddCounts: number;
    requiredPackage: string | null;
}

export class DefaultViews {
    constructor(data: DefaultViewsApiData) {
        this.builderCalendarDefaultView = data.builderCalendarDefaultView;
        this.ownerCalendarDefaultView = data.ownerCalendarDefaultView;
        this.builderSelectionDefaultView = data.builderSelectionDefaultView;
        this.ownerSelectionDefaultView = data.ownerSelectionDefaultView;
        this.activityCalendarDefaultView = data.activityCalendarDefaultView;
    }

    builderCalendarDefaultView: ScheduleListContainerTabs;
    ownerCalendarDefaultView: OwnerCalendarDefaultViews;
    builderSelectionDefaultView: BuilderSelectionViews;
    ownerSelectionDefaultView: OwnerSelectionViews;
    activityCalendarDefaultView: ActivityCalendarView;
}

export class HeaderStyleInfo {
    constructor(data: HeaderStyleApiData) {
        this.color = data.color;
        this.darken = data.darken;
    }
    color: string;
    darken: boolean;
}

export class UnreadNotificationCountResponse {
    constructor(data: typeof UnreadNotificationCountData) {
        this.unreadCount = data.unreadCount;
    }
    unreadCount: number;
}

export class HasUnreadResponse {
    constructor(data: typeof HasUnreadResponseJson) {
        this.hasUnread = data.hasUnread;
        this.lastCheckedDateUtc = moment(data.lastCheckedDateUtc);
    }
    hasUnread: boolean;
    lastCheckedDateUtc: moment.Moment;
}
