import { Skeleton } from "antd";

import { BTLoginTypes } from "types/enum";

import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

import BidRequestUserActivation from "images/bidRequest.svg?react";
import CheckCircle from "images/check-circle.svg?react";
import CommunicationActivation from "images/communication.svg?react";
import EmailMarketingUserActivation from "images/emailMarketing.svg?react";
import EstimatesProposalsUserActivation from "images/estimatesProposals.svg?react";
import LeadsCRMUserActivation from "images/leadsCRM.svg?react";
import SatisfactionActivation from "images/satisfaction.svg?react";
import SubActivationLogin from "images/sub-activation-login.svg?react";
import UserFriendlyActivation from "images/userFriendly.svg?react";
import WebBasedActivation from "images/webBased.svg?react";

import "./UserActivationPageAdvertisements.less";

interface IUserActivationPageAdvertisementsProps {
    loginType: BTLoginTypes;
}

const subContent = [
    "Easily manage your Bids, PO's, and Schedule for this builder in one place.",
    "Quickly get updates on the go with our Mobile App.",
    "Call in anytime for unlimited support -- our team is available when you need them.",
];

const buildCardData = (loginType: BTLoginTypes) => {
    switch (loginType) {
        case BTLoginTypes.OWNER:
        case BTLoginTypes.CONTACT: {
            return [
                {
                    image: (
                        <UserFriendlyActivation data-testid="userFriendlyImage" className="Image" />
                    ),
                    title: "User Friendly",
                    subTitle: "Log in to easily view your jobs and track progress.",
                },
                {
                    image: <WebBasedActivation data-testid="webBasedImage" className="Image" />,
                    title: "Web Based",
                    subTitle:
                        "Log in from your desktop, tablet, or mobile device to always stay connected.",
                },
                {
                    image: (
                        <CommunicationActivation
                            data-testid="communicationImage"
                            className="Image"
                        />
                    ),
                    title: "Open Lines of Communication",
                    subTitle:
                        "Improve communication with real-time messaging and automatic reminders.",
                },
                {
                    image: (
                        <SatisfactionActivation data-testid="satisfactionImage" className="Image" />
                    ),
                    title: "Maximum Customer Satisfaction",
                    subTitle:
                        "Never miss an update with customized smart phone and email notifications.",
                },
            ];
        }
        case BTLoginTypes.BUILDER: {
            return [
                {
                    image: (
                        <BidRequestUserActivation data-testid="bidRequestImage" className="Image" />
                    ),
                    title: "Bid Request",
                    subTitle: "Create, compare, send and store bids in one convenient location.",
                },
                {
                    image: (
                        <EstimatesProposalsUserActivation
                            data-testid="estimatesProposalsImage"
                            className="Image"
                        />
                    ),
                    title: "Estimates & Proposals",
                    subTitle:
                        "Our entire system makes it a breeze to build estimates, deliver customized proposals, and ultimately land more jobs.",
                },
                {
                    image: <LeadsCRMUserActivation data-testid="leadsCrmImage" className="Image" />,
                    title: "Leads / CRM",
                    subTitle:
                        "Buildertrend offers one convenient place to create, capture, and close all your prospects.",
                },
                {
                    image: (
                        <EmailMarketingUserActivation
                            data-testid="emailMarketingImage"
                            className="Image"
                        />
                    ),
                    title: "Email Marketing",
                    subTitle:
                        "Communicate with professional quality emails. The moment a lead responds, you'll receive a push notification to your mobile device.",
                },
            ];
        }
        default: {
            return null;
        }
    }
};

const AdvertisementCard = (image: JSX.Element, title: string, subTitle: string): JSX.Element => {
    return (
        <BTCol className="Advertisement">
            <BTCard className="Card">
                <div className="ImageContainer">{image}</div>
                <p className="Heading">{title}</p>
                <p className="Detail">{subTitle}</p>
            </BTCard>
        </BTCol>
    );
};

const UserActivationPageAdvertisements: React.FunctionComponent<
    IUserActivationPageAdvertisementsProps
> = ({ loginType }) => {
    const cardData = buildCardData(loginType);
    return (
        <>
            {(loginType === BTLoginTypes.OWNER ||
                loginType === BTLoginTypes.BUILDER ||
                loginType === BTLoginTypes.CONTACT) &&
                cardData && (
                    <>
                        <BTRow justify="center" responsiveMode="viewport">
                            {AdvertisementCard(
                                cardData[0].image,
                                cardData[0].title,
                                cardData[0].subTitle
                            )}
                            {AdvertisementCard(
                                cardData[1].image,
                                cardData[1].title,
                                cardData[1].subTitle
                            )}
                        </BTRow>

                        <BTRow justify="center" responsiveMode="viewport">
                            {AdvertisementCard(
                                cardData[2].image,
                                cardData[2].title,
                                cardData[2].subTitle
                            )}
                            {AdvertisementCard(
                                cardData[3].image,
                                cardData[3].title,
                                cardData[3].subTitle
                            )}
                        </BTRow>
                    </>
                )}

            {loginType === BTLoginTypes.SUBS && (
                <BTRow justify="center" className="SubAdvertisement">
                    <BTCol>
                        <SubActivationLogin data-testid="subActivationImage" className="Image" />
                        <p className="Heading">
                            Activate your FREE account to accomplish more together.
                        </p>

                        <div>
                            {subContent.map((prompt) => (
                                <BTRow className="ListItem" key={prompt}>
                                    <BTCol span={2}>
                                        <CheckCircle className="CircleCheck" />
                                    </BTCol>
                                    <BTCol span={22} className="Prompt">
                                        {prompt}
                                    </BTCol>
                                </BTRow>
                            ))}
                        </div>
                    </BTCol>
                </BTRow>
            )}
        </>
    );
};

export const UserActivationPageAdvertisementsSkeleton = () => {
    return (
        <BTRow align="middle">
            <BTCol className="UserActivationPageAdvertisements--Marketing">
                {/* TODO placeholder until the right component is worked on */}
                <Skeleton active />
            </BTCol>
        </BTRow>
    );
};
export { UserActivationPageAdvertisements };
