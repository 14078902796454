import { ColumnProps } from "antd/lib/table";
import { LineItemType, MarkupColumnTypes } from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { CostTypes, MarkedAs } from "types/enum";

import { divide, multiply, round, subtract } from "utilities/math/math";

import {
    IDateRangeEntity,
    IDateRangeFormValues,
} from "commonComponents/entity/filters/DateRange/DateRange";
import { RelatedInvoice } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { PercentageSection } from "commonComponents/utilities/PercentageDisplayBar/PercentageDisplayBar.types";

import { recalculateAndSetMarkupFieldsForLineItemForInvoice } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice.utility";
import { TaxMethod } from "entity/tax/common/tax.types";

export type LineItemsToInvoiceFormActions = undefined | "save" | "newInvoice";

export interface ILineItemsToInvoiceFormValues {
    lineItems: LineItemForInvoice[];
    adjustInvoicePercent: number;
    includeDescriptions: boolean;
    showLineItemsToOwner: boolean;
    includeAttachments: boolean;
    dateRange?: IDateRangeFormValues | null;
    stackLineItems: boolean;
    supportsLineItemStacking: boolean;
}

export class LineItemsToInvoiceEntity {
    constructor(data: any) {
        this.mappingWizardTitle = data.mappingWizardTitle;
        this.pageSectionTitle = data.pageSectionTitle;
        this.isCostPlusWorkflow = data.isCostPlus;
        this.lineItems = data.lineItems.map(
            (li: any) => new LineItemForInvoice(li, this.isCostPlusWorkflow)
        );
        this.shouldDisplayParentRows = data.shouldDisplayParentRows;
        this.shouldDisplayGrandparentRows = data.shouldDisplayGrandparentRows;
        this.showLineItemsToOwner = data.showLineItemsToOwner;
        this.taxMethod = data.taxMethod;
        this.invalidTaxMethodsSelected = data.invalidTaxMethodsSelected;
        if (data.dateRangeOptions) {
            this.dateRangeOptions = {
                dateRange: [null, null] as RangeValue<moment.Moment>,
                options: data.dateRangeOptions.map((x: any) => new BTSelectItem(x)),
            };
        }
        this.hideDescriptionAndNotesCheckbox = data.hideDescriptionAndNotesCheckbox;
        this.supportsLineItemStacking = data.supportsLineItemStacking;
        this.supportsAttachments = data.supportsAttachments;
    }
    mappingWizardTitle: string;
    pageSectionTitle: string;
    isCostPlusWorkflow: boolean;
    lineItems: LineItemForInvoice[];
    shouldDisplayParentRows: boolean;
    shouldDisplayGrandparentRows: boolean;
    showLineItemsToOwner: boolean;
    taxMethod: TaxMethod;
    invalidTaxMethodsSelected: boolean;
    /**
     * Set to allow date range filtering on the invoicing modal. dateToFilter property must be set on LineItemForInvoice object
     */
    dateRangeOptions?: IDateRangeEntity;
    hideDescriptionAndNotesCheckbox: boolean;
    /**
     * If true, will create line item stacks for the line items' relationship
     * Currently only supported for time clock line items
     */
    supportsLineItemStacking: boolean;
    supportsAttachments: boolean;
}

export enum TableDataType {
    LineItem = 0,
    Parent = 1,
    GrandParent = 2,
}

export class LineItemForInvoice {
    constructor(data: any, isCostPlusWorkflow: boolean) {
        let total = data.ownerPrice;
        this.lineItemId = data.id;
        this.title = data.title;
        this.costCode = data.costCode;
        this.costCodeTitle = data.costCodeTitle;
        this.ownerPrice = data.ownerPrice;
        this.builderCost = data.builderCost;
        this.amountInvoiced = data.amountInvoiced;
        this.amountRemaining = total - this.amountInvoiced;
        this.percentInvoiced =
            total === 0 ? 100 : multiply(divide(this.amountInvoiced, total), 100).toNumber();
        this.percentRemaining = total === 0 ? 0 : subtract(100, this.percentInvoiced).toNumber();
        this.shouldApplyRemaining = this.percentInvoiced > 99 && this.percentInvoiced < 100;
        this.invoicedAmount = isCostPlusWorkflow ? this.builderCost : 0.0;
        this.invoicedPercent = 0.0;
        this.grandparent = data.grandparentEntity
            ? new LineItemAncestorEntity(data.grandparentEntity, TableDataType.GrandParent)
            : undefined;
        this.parent = data.parentEntity
            ? new LineItemAncestorEntity(data.parentEntity, TableDataType.Parent)
            : undefined;
        this.type = TableDataType.LineItem;
        this.isDeleted = data.isDeleted;
        this.unitType = data.unitType;
        this.quantity = data.quantity;
        this.unitCost = data.unitCost;
        this.markupAmount = data.markupAmount;
        this.markupPerUnit = data.markupPerUnit;
        this.markupPercent = data.markupPercent;
        this.markupType = data.markupType;
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.lineItemType = data.lineItemType;
        this.invoiceOverview = [];
        this.costTypes = data.costTypes ?? [];
        if (
            this.ownerPrice !== undefined &&
            this.amountInvoiced !== undefined &&
            this.amountInvoiced !== 0
        ) {
            this.invoiceOverview.push(
                new PercentageSection({
                    color: "green",
                    value: this.amountInvoiced,
                    percentage: this.amountInvoiced / total,
                    label: "Invoiced",
                    striped: false,
                    isOutstanding: false,
                })
            );
        }
        this.markedAs = data.markedAs ?? MarkedAs.None;
        this.isSelected = false;
        this.isFullyInvoicedButNotSaved = false;
        this.taxGroupId = data.taxGroupId;
        this.totalWithTax = data.totalWithTax;
        this.isCostPlusWorkflow = isCostPlusWorkflow;
        this.dateToFilter = data.dateToFilter && moment(data.dateToFilter);
    }

    lineItemId: number;
    title: string;
    costCode: number;
    costCodeTitle: string;
    ownerPrice: number;
    builderCost: number;
    amountInvoiced: number;
    amountRemaining: number;
    percentInvoiced: number;
    percentRemaining: number;
    shouldApplyRemaining: boolean;
    invoiceOverview: PercentageSection[];
    invoicedAmount?: number;
    invoicedPercent?: number;
    grandparent?: LineItemAncestorEntity;
    parent?: LineItemAncestorEntity;
    type: TableDataType;
    isDeleted: boolean;
    unitType: string;
    quantity: number;
    unitCost: number;
    markupAmount: number;
    markupPerUnit: number;
    markupPercent: number;
    markupType: MarkupColumnTypes;
    description: string;
    internalNotes: string;
    lineItemType: LineItemType;
    costTypes: CostTypes[];
    markedAs: MarkedAs;
    isSelected: boolean;
    isFullyInvoicedButNotSaved: boolean;
    taxGroupId?: number;
    totalWithTax?: number;
    isCostPlusWorkflow: boolean;
    /**
     * Optionally set to allow date filtering on line items. requires dateRangeEntity on the LineItemsToInvoiceEntity parent
     */
    dateToFilter?: moment.Moment;
}

export class LineItemAncestorEntity {
    constructor(data: any, type: TableDataType) {
        this.id = data.id ?? data.idString;
        this.title = data.title;
        this.type = type;
        this.costTypes = data.costTypes ?? [];
        this.totalAmount = data.totalAmount;
        this.parentId = data.parentId;
    }

    // parent id can be a string, such as Accounting integration entities
    id: number | string;
    title: string;
    type: TableDataType;
    costTypes?: CostTypes[];
    totalAmount?: number;
    /**
     * Used to determine relationship between parent and grandparent rows
     */
    parentId: number | null;
}

export interface ILineItemsToInvoiceMappingRequest {
    jobId: number;
    idsAndTypes: ILineItemIdsAndTypes[];
    referringType: LineItemType;
}

export interface IAddLineItemsToInvoiceMappingRequest {
    jobId: number;
    lineItemType: LineItemType;
    invoiceId?: number;
    entityIds?: number[];
}

export interface IAddInvoiceAttachmentMappingRequest {
    jobId: number;
    entityIds?: number[];
}

export enum PaymentStatusType {
    Unreleased = 1,
    PendingReleased = 2,
    PartiallyPaid = 3,
    Paid = 4,
    Void = 5,
}

export interface ILineItemsToInvoiceCreateRequest {
    title: string;
    description: string;
    amount: number;
    amountPaid: number;
    isDeadlineLinked: boolean;
    deadLine: string;
    deadlineLinked: null;
    deadLineBeforeOrAfter: null;
    deadLineOffset: null;
    deadlineTime: null;
    paidDate: string;
    ownerEmail: string;
    status: PaymentStatusType; // todo add enum for this
    createInvoiceChkbox: boolean;
    notifyOwner: boolean;
    customFields: [];
    attachedFiles: {
        removeDocs: [];
        updateDocs: [];
        attachDocs: [];
    };
    showLineItemsToOwner: boolean;
    lineItems: {
        updated: LineItemForInvoiceRequest[];
        removed: [];
    };
    invoicedFromEntity: boolean;
    isCostPlus: boolean;
    taxMethod: TaxMethod;
    includeRelatedEntityAttachments: boolean;
    sourceLineItemType: LineItemType | null;
}

export class LineItemForInvoiceRequest {
    constructor(li: LineItemForInvoice, includeDescription: boolean, isCostPlusWorkflow: boolean) {
        this.id = 0;
        this.title = li.title;
        this.unitType = li.unitType;
        this.ownerPrice = li.invoicedAmount!;
        this.excludeFromNewExpected = false;
        this.catalogItemId = null;
        this.costCatalogUpdateType = null;
        this.isTBD = false;
        this.costCode = li.costCode;
        this.costTypes = li.costTypes ?? [];
        this.markedAs = li.markedAs ?? MarkedAs.None;
        this.taxGroupId = li.taxGroupId;
        this.markupType = MarkupColumnTypes.Percentage;
        this.pageTypeEnum = li.lineItemType;
        this.parentId = li.parent?.id;
        switch (li.lineItemType) {
            case LineItemType.EstimateLineItem:
                this.estimateId = li.lineItemId;
                break;
            case LineItemType.SelectionChoice:
                this.selectionChoiceLineItemId = li.lineItemId;
                break;
            case LineItemType.PurchaseOrderPayment:
            case LineItemType.LegacyBill:
            case LineItemType.Bill:
                this.billLineItemId = li.lineItemId;
                break;
            case LineItemType.ChangeOrder:
                this.relatedChangeOrderLineItemId = li.lineItemId;
                break;
            case LineItemType.Bid:
                this.relatedBidLineItemId = li.lineItemId;
                break;
            case LineItemType.Allowance:
                this.allowanceId = Number(li.parent?.id); // will always be a number for allowances
                this.allowanceLineItemId = li.lineItemId;
        }

        if (includeDescription) {
            this.description = li.description;
            this.internalNotes = li.internalNotes;
        }

        if (isCostPlusWorkflow) {
            recalculateAndSetMarkupFieldsForLineItemForInvoice(li);

            this.quantity = 1;
            this.builderCost = li.builderCost;
            this.markupAmount = li.markupAmount;
            this.markupPercent = li.markupPercent;
            this.markupPerUnit = li.markupPerUnit;
            this.unitCost = li.builderCost;
        } else {
            this.markupType = li.markupType;
            let percentage = divide(li.invoicedPercent!, 100);
            let unroundedQuantity = multiply(li.quantity, percentage).toNumber();
            this.quantity = round(unroundedQuantity, 4);
            this.unitCost = li.unitCost;
            this.builderCost = round(multiply(li.builderCost, percentage), 2);

            this.markupAmount = subtract(this.ownerPrice, this.builderCost).toNumber();
            this.markupPercent = li.markupPercent;
            this.markupPerUnit = round(divide(this.markupAmount, unroundedQuantity), 2);
            this.totalWithTax =
                li.totalWithTax === undefined
                    ? this.ownerPrice
                    : round(multiply(li.totalWithTax, percentage), 4);
        }

        /* In order to support fully invoicing line items which cannot be invoiced due to 1/10,000 quantity precision,
            we will send over invoices that do not fit this calculation with a quantity of 1 and owner price of the invoice amount */
        if (li.shouldApplyRemaining) {
            this.quantity = 1;
            this.unitCost = li.invoicedAmount!;
            this.builderCost = li.invoicedAmount!;
        }
    }

    id: number;
    costCode: number;
    unitCost: number;
    quantity: number;
    markupType: MarkupColumnTypes;
    markupAmount: number;
    markupPercent: number;
    markupPerUnit: number;
    unitType: string;
    ownerPrice: number;
    title: string;
    builderCost: number;
    excludeFromNewExpected: boolean;
    catalogItemId: number | null;
    costCatalogUpdateType: number | null;
    isTBD: boolean;
    description: string;
    internalNotes: string;
    estimateId?: number;
    selectionChoiceLineItemId?: number;
    purchaseOrderPaymentLineItemId?: number;
    billLineItemId?: number;
    relatedChangeOrderLineItemId?: number;
    relatedBidLineItemId?: number;
    allowanceId?: number;
    allowanceLineItemId?: number;
    costTypes: CostTypes[];
    markedAs: MarkedAs;
    pageTypeEnum: LineItemType;
    parentId: number | string | undefined;

    totalWithTax?: number;
    taxGroupId?: number;
}

export class LineItemsToInvoiceCreateResponse {
    constructor(data: any) {
        this.id = data.id;
    }
    id: number;
}

/* InvoiceListFromLineItem */
export interface IRelatedInvoicesForLineItemRequest {
    lineItemId: number;
    jobId: number;
    lineItemType: LineItemType;
}

export class RelatedInvoicesForLineItemEntity {
    constructor(data: any) {
        this.lineItemTotal = data.lineItemTotal;
        this.totalInvoiced = data.totalInvoiced;
        this.relatedInvoices = data.payments.map((p: any) => new RelatedInvoice(p));
        this.canCreateOwnerPayment = data.canCreateOwnerPayment;
    }
    lineItemTotal: number;
    totalInvoiced: number;
    relatedInvoices: RelatedInvoice[];
    canCreateOwnerPayment: boolean;
}

export interface ILineItemIdsAndTypes {
    id: number;
    parentId?: number;
    lineItemType: LineItemType;
}

export interface ICheckedActionInvoiceData {
    idsAndTypes: ILineItemIdsAndTypes[];
    jobId: number;
    builderId: number;
}

export class LineItemIdsToInvoiceResponse {
    constructor(data: any) {
        if (data.billLineItemIds) {
            this.lineItemIds = data.billLineItemIds.map((e: any) => Number(e));
        } else {
            this.lineItemIds = data.map((e: any) => Number(e));
        }
    }
    lineItemIds: number[];
}

export class LineItemsToInvoiceButtonConfirmation {
    constructor(title: string, okText: string, content: JSX.Element) {
        this.title = title;
        this.okText = okText;
        this.content = content;
    }

    title: string;
    okText: string;
    content: JSX.Element;
}

export const mapResponseToInvoiceData = (
    resp: LineItemIdsToInvoiceResponse,
    lineItemType: LineItemType,
    builderId: number,
    jobId: number
): ICheckedActionInvoiceData => ({
    idsAndTypes: resp.lineItemIds.map((i) => ({ id: i, lineItemType })),
    builderId,
    jobId,
});

export interface ILineItemsToInvoiceColumns<RowType> extends IBTColumnProps<RowType> {
    getFooterValue?: () => any;
}

export interface IBTColumnProps<T> extends ColumnProps<T> {
    "data-testid"?: string;
}
