import { GridViewItem } from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";

import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";
import { SavedViewsAndFiltersCard } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard";
import {
    ISavedViewsAndFiltersCardFormValues,
    SavedViewsAndFiltersCardActions,
} from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard.api.types";
import { FiltersEmptyStateBanner } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersEmptyState/FiltersEmptyStateBanner";
import { SavedViewsEmptyStateBanner } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersEmptyState/SavedViewsEmptyStateBanner";
import { ISavedViewsAndFiltersSettingsProps } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersSettings/SavedViewsAndFiltersSettings";

export interface ISavedViewsAndFiltersExtraMappingProps {
    filterToOwner: boolean;
    emptyStateCallToAction: () => void;
    isAddingNew: boolean;
    editingId: number | undefined;
    onEditClick: (entityId?: number) => void;
    actionBeingPerformed: SavedViewsAndFiltersCardActions;
    onUpdateFilterFull: (
        filter: SavedFilterItem,
        newValues: ISavedViewsAndFiltersCardFormValues
    ) => Promise<void>;
    onCopyClick: (entityId?: number) => void;
}

export const MapSavedViewsCards: React.FunctionComponent<
    ISavedViewsAndFiltersSettingsProps & ISavedViewsAndFiltersExtraMappingProps
> = (props) => {
    const {
        viewData,
        actionBeingPerformed,
        filterToOwner,
        onSaveView,
        emptyStateCallToAction,
        isAddingNew,
        editingId,
        onEditClick,
        columnsList,
        onDeleteView,
        onCopyClick,
        onSetDefaultView,
    } = props;
    // make sure there is viewData, then filter to shared or private
    const sortedViews = viewData
        ? filterToOwner
            ? viewData.filter((view) => view.canPrivate)
            : viewData.filter((view) => !view.canPrivate)
        : [];

    const getViewColumnList = (item: GridViewItem) => {
        const columnIds = item.columns.map((c) => c.id);
        return columnsList?.map((column) => ({
            ...column,
            enabled: columnIds.indexOf(column.id) !== -1,
        }));
    };

    const filteredViewsCards =
        sortedViews.length > 0
            ? sortedViews.map((f, index) => (
                  <div key={index}>
                      <SavedViewsAndFiltersCard
                          data={f}
                          isExpanded={f.viewId === editingId}
                          onEditClick={() => {
                              onEditClick(f.viewId);
                          }}
                          columnList={getViewColumnList(f)}
                          onCopyClick={() => {
                              onCopyClick(f.viewId);
                          }}
                          onDeleteClick={() => onDeleteView!(f)}
                          onSaveView={onSaveView}
                          onSetAsDefaultClick={() => onSetDefaultView!(f)}
                          actionBeingPerformed={actionBeingPerformed}
                          isNew={false}
                          closeExpandedCard={() => onEditClick(undefined)}
                          viewList={viewData}
                          filterList={[]}
                      />
                  </div>
              ))
            : [];
    const emptyState =
        filterToOwner && !isAddingNew && sortedViews.length < 1 ? (
            <SavedViewsEmptyStateBanner
                isDismissed={false}
                isNewToEntity={true}
                isReadonly={false}
                onCallToActionClick={emptyStateCallToAction}
                actionBeingPerformed={undefined}
                hasListData={false}
            />
        ) : (
            <></>
        );
    return filteredViewsCards.length > 0 ? <>{filteredViewsCards}</> : emptyState;
};

export const MapFiltersCards: React.FunctionComponent<
    ISavedViewsAndFiltersSettingsProps & ISavedViewsAndFiltersExtraMappingProps
> = (props) => {
    const {
        filterData,
        actionBeingPerformed,
        filterToOwner,
        emptyStateCallToAction,
        isAddingNew,
        editingId,
        onEditClick,
        onDeleteFilter,
        onUpdateFilterFull,
        onCopyClick,
        onSetDefaultFilter,
    } = props;
    // make sure there is viewData, then filter to shared or private
    const sortedFilters = filterData
        ? filterToOwner
            ? filterData.filter((filter) => filter.isOwner)
            : filterData.filter((filter) => !filter.isOwner)
        : [];
    const sortedFilterCards =
        sortedFilters.length > 0
            ? sortedFilters.map((f, index) => (
                  <div key={index}>
                      <SavedViewsAndFiltersCard
                          data={f}
                          isExpanded={f.id === editingId}
                          onEditClick={() => onEditClick(f.id)}
                          onCopyClick={() => {
                              onCopyClick(f.id);
                          }}
                          onDeleteClick={() => onDeleteFilter!(f.id)}
                          onUpdateFilter={onUpdateFilterFull}
                          onSetAsDefaultClick={() => onSetDefaultFilter!(f)}
                          actionBeingPerformed={actionBeingPerformed}
                          filterTypeEntity={props.filterTypeEntity}
                          isNew={false}
                          closeExpandedCard={() => onEditClick(undefined)}
                          filterList={filterData!}
                          viewList={[]}
                      />
                  </div>
              ))
            : [];
    const emptyState =
        filterToOwner && !isAddingNew && sortedFilters.length < 1 ? (
            <FiltersEmptyStateBanner
                isDismissed={false}
                isNewToEntity={true}
                isReadonly={false}
                onCallToActionClick={emptyStateCallToAction}
                actionBeingPerformed={undefined}
                hasListData={false}
            />
        ) : (
            <></>
        );
    return sortedFilterCards.length > 0 ? <>{sortedFilterCards}</> : emptyState;
};
