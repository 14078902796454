import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISelectionChoiceProps } from "entity/selectionChoice/SelectionChoice/SelectionChoice";

const SelectionChoice = lazyLoadWithRetry(() => import("./SelectionChoice"));

export const RouteSelectionChoice = (
    props: Omit<ISelectionChoiceProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.selectionChoice.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <SelectionChoice
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.choiceId)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                        selectionId={
                            routeProps.match.params.selectionId
                                ? parseInt(routeProps.match.params.selectionId)
                                : undefined
                        }
                    />
                </BTSuspense>
            );
        }}
    />
);
