import moment from "moment";
import { ReactNode } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { BTLocalStorage } from "types/btStorage";
import { DirectoryType, MediaType } from "types/enum";

import { isInPortal } from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";

import {
    BTIconAllowances,
    BTIconBidPackages,
    BTIconBillsPurchaseOrders,
    BTIconChangeOrders,
    BTIconContacts,
    BTIconDailyLogs,
    BTIconDocuments,
    BTIconFolderOutlined,
    BTIconInternalUsers,
    BTIconJobs,
    BTIconLeadProposals,
    BTIconLeads,
    BTIconMessages,
    BTIconOwnerInvoices,
    BTIconPhotos,
    BTIconPurchaseOrders,
    BTIconRFIs,
    BTIconSchedules,
    BTIconSelections,
    BTIconSubs,
    BTIconTodos,
    BTIconVideos,
    BTIconWarranties,
} from "commonComponents/btWrappers/BTIcon";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";
import {
    GlobalSearchFilters,
    GlobalSearchResult,
    IGlobalSearchFilterExtraData,
    IGlobalSearchFilterValues,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import {
    ISearchBarResultResponse,
    SearchCategory,
} from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";
import { LinkType } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.utilities";

export function loadRecentSearchCache(userIds?: number[]): ISearchBarResultResponse[] {
    return BTLocalStorage.get("bt-objectArray-searchBarRecentResults")
        .filter((r) => userIds === undefined || userIds.includes(r.userId))
        .map((r) => ({
            ...r,
            expires: moment(r.expires),
        }));
}

interface ICategoryExtraInfo {
    icon: ReactNode;
    linkType: LinkType;
    link?: string;
}

const IconSize: number = 35;

export function getInfoForSearchResponse(data: GlobalSearchResult): ICategoryExtraInfo {
    const isOwner: boolean = isInPortal({ owner: true });
    const isBuilder: boolean = isInPortal({ builder: true });
    switch (data.category) {
        case SearchCategory.Todo:
            return {
                icon: <BTIconTodos size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.toDo.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Contact:
            return {
                icon: <BTIconContacts size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.contact.getDetailsLink(data.id, false)),
            };
        case SearchCategory.ChangeOrders:
            return {
                icon: <BTIconChangeOrders size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.changeOrder.getChangeOrderDetailsLink(data.id, data.jobId!)
                ),
            };
        case SearchCategory.PurchaseOrders:
            const purchaseOrderIcon = <BTIconPurchaseOrders size={IconSize} />;
            const purchaseOrderLink = getSearchLink(
                routes.purchaseOrder.getDetailsLink(data.id, data.jobId!)
            );
            if (isOwner) {
                return {
                    icon: purchaseOrderIcon,
                    linkType: LinkType.NewTabLink,
                    link: getBaseRoute() + routes.purchaseOrder.getPrintLink([data.id]),
                };
            }
            return {
                icon: purchaseOrderIcon,
                linkType: LinkType.RelativeLink,
                link: purchaseOrderLink,
            };
        case SearchCategory.Bills:
            return {
                icon: <BTIconBillsPurchaseOrders size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.bill.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Subs:
            return {
                icon: <BTIconSubs size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.sub.getDetailsLink(data.id)),
            };
        case SearchCategory.InternalUsers:
            return {
                icon: <BTIconInternalUsers size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.internalUsers.getInternalUserDetailsLink(data.id)),
            };
        case SearchCategory.DailyLogs:
            return {
                icon: <BTIconDailyLogs size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.dailyLog.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.OwnerInvoices:
            return {
                icon: <BTIconOwnerInvoices size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.ownerInvoice.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Messages:
            return {
                icon: <BTIconMessages size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: data["isDraft"]
                    ? getSearchLink(routes.messages.getComposeLink(data.id, data.jobId!))
                    : getSearchLink(routes.messages.getMessageDetailsLink(data.id)),
            };
        case SearchCategory.Schedules:
            return {
                icon: <BTIconSchedules size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.schedule.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Warranties:
            return {
                icon: <BTIconWarranties size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.warranty.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.BidPackages:
            const url = isBuilder
                ? routes.bidPackage.getDetailsLink(data.id, data.jobId!)
                : routes.bid.getDetailsLink(data["bidId"], data.jobId!);
            return {
                icon: <BTIconBidPackages size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(url),
            };
        case SearchCategory.Leads:
            return {
                icon: <BTIconLeads size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.lead.getDetailsLink(data.id)),
            };
        case SearchCategory.LeadProposals:
            return {
                icon: <BTIconLeadProposals size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.leadProposals.getDetailsLink(data.id, data["leadId"], false)
                ),
            };
        case SearchCategory.Selections:
            return {
                icon: <BTIconSelections size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.selection.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.RFIs:
            return {
                icon: <BTIconRFIs size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.rfi.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Allowances:
            return {
                icon: <BTIconAllowances size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.allowance.getDetailsLink(data.id, data.jobId!)),
            };
        case SearchCategory.Documents:
            return {
                icon: <BTIconDocuments size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Document)
                ),
            };
        case SearchCategory.Photos:
            return {
                icon: <BTIconPhotos size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Photo)
                ),
            };
        case SearchCategory.Videos:
            return {
                icon: <BTIconVideos size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(
                    routes.media.getMediaPropertiesLink(data.id, data.jobId!, MediaType.Video)
                ),
            };
        case SearchCategory.Jobs:
            return {
                icon: <BTIconJobs size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.job.getDetailsLink(data.id)),
            };
        case SearchCategory.Templates:
            return {
                icon: <BTIconJobs size={IconSize} />,
                linkType: LinkType.RelativeLink,
                link: getSearchLink(routes.template.getTemplateDetailsLink(data.id)),
            };
        case SearchCategory.DocumentFolders:
            return {
                icon: <BTIconFolderOutlined size={IconSize} />,
                linkType: LinkType.AutoSPARouting,
                link: routes.document.getListLink(
                    data.jobId === JobIdTypes.GlobalDocs
                        ? DirectoryType.GlobalDocs
                        : DirectoryType.Standard,
                    data.id,
                    data.jobId ?? undefined
                ),
            };
        case SearchCategory.PhotoFolders:
            return {
                icon: <BTIconFolderOutlined size={IconSize} />,
                linkType: LinkType.AutoSPARouting,
                link: routes.photo.getListLink(
                    DirectoryType.Standard,
                    data.id,
                    data.jobId ?? undefined
                ),
            };
        case SearchCategory.VideoFolders:
            return {
                icon: <BTIconFolderOutlined size={IconSize} />,
                linkType: LinkType.AutoSPARouting,
                link: routes.video.getListLink(
                    DirectoryType.Standard,
                    data.id,
                    data.jobId ?? undefined
                ),
            };
        default:
            throw new Error(`Not implemented for enum ${data.category}`);
    }
}

function getSearchLink(url: string) {
    return `/SearchResult${url}`;
}

// Method sets the correct job in the job picker if it does not exist
// Replace once we are fully SPA
export async function ResetPageForJobNotInJobPicker(
    item: ICategoryExtraInfo,
    jobId: number | null
) {
    if (
        jobId &&
        item.linkType === LinkType.WebformsLink &&
        !(await window.JobPickerBridge.isJobInJobPicker(jobId))
    ) {
        BTLocalStorage.set("bt-boolean-redirectedToJobNotInJobPicker", true);
        // Reload the page if the url is correct, but the job isn't in the job picker since pages rely on the url changing to trigger updates
        if (item.link && item.link === window.location.pathname + window.location.hash) {
            window.location.reload();
        }
    }
}

export function GetRecentlyViewedFromLocalStorage(userIds?: number[]) {
    const recentResults = BTLocalStorage.get("bt-objectArray-globalSearchRecentlyViewed");
    return recentResults.filter(
        (r) => userIds === undefined || (r.userId && userIds.includes(r.userId))
    );
}

export function SetRecentlyViewedLocalStorage(
    allUserIds: number[],
    userId: number,
    item: GlobalSearchResult
) {
    item.userId = userId;
    const recentlyViewed = GetRecentlyViewedFromLocalStorage(allUserIds);
    const existingIndex = recentlyViewed.findIndex(
        (x) => x.id === item.id && x.category === item.category
    );
    if (existingIndex > -1) {
        recentlyViewed.splice(existingIndex, 1);
    }
    recentlyViewed.unshift(item);
    const updatedItems = recentlyViewed.slice(0, 7);
    BTLocalStorage.set("bt-objectArray-globalSearchRecentlyViewed", updatedItems);
}

export function RemoveFromRecentlyViewedLocalStorage(category: number, ...ids: number[]) {
    const recentResults = GetRecentlyViewedFromLocalStorage();
    BTLocalStorage.set(
        "bt-objectArray-globalSearchRecentlyViewed",
        recentResults.filter((r) => r.category !== category || !ids.includes(r.id))
    );
}

export function GetQuickFilterCategories(
    filters: GlobalSearchFilters,
    selectedFilters: IGlobalSearchFilterValues,
    showSuggestedFilters: boolean
) {
    let defaultCategories: BTSelectItem<IGlobalSearchFilterExtraData>[] = [];

    if (showSuggestedFilters) {
        defaultCategories = filters.categorySelectItems
            .filter((f) => f.extraData && f.extraData.order !== undefined)
            .sort((a, b) => {
                if (a.extraData && b.extraData) {
                    return a.extraData.order - b.extraData.order;
                } else {
                    return 0;
                }
            })
            .slice(0, 3);
    }

    const otherCategories = filters.categorySelectItems
        .filter(
            (f) =>
                selectedFilters.category.includes(Number(f.id)) &&
                !defaultCategories.some((d) => d.id === f.id)
        )
        .sort((a, b) => {
            const containsA = selectedFilters.category.includes(Number(a.id)) ? 1 : 0;
            const containsB = selectedFilters.category.includes(Number(b.id)) ? 1 : 0;
            return containsA - containsB;
        });

    return [...defaultCategories, ...otherCategories];
}
