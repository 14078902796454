import { createContext } from "react";

import { getCurrentPortalType, PortalType } from "utilities/portal/portal";

interface IPortalInfo {
    rootPortal: PortalType;
    currentPortal: PortalType;
}

export const PortalInfoContext = createContext<IPortalInfo>({
    rootPortal: getCurrentPortalType(),
    currentPortal: getCurrentPortalType(),
});
