import { RouteComponentProps } from "react-router";

import { WhenBuilderFlag } from "helpers/globalContext/BuilderInfoContext";

import { isInPortal } from "utilities/portal/portal";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { RouteJob } from "commonComponents/entity/job/RouteJob/RouteJob";
import { RouteMediaProperties } from "commonComponents/entity/media/MediaProperties/RouteMediaProperties";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { RouteBid } from "entity/bid/Bid/BidRoute";
import { RouteBidPackage } from "entity/bidPackage/BidPackage/BidPackageRoute";
import { RouteBill } from "entity/bill/Bill/RouteBill";
import { RouteChangeOrder } from "entity/changeOrder/ChangeOrder/ChangeOrderRoute";
import { RouteCreditMemo } from "entity/creditMemo/CreditMemo/RouteCreditMemo";
import { RouteDailyLog } from "entity/dailyLog/DailyLog/DailyLogRoute";
import { RouteDeposit } from "entity/deposit/Deposit/RouteDeposit";
import { PDFSignatureDetailsRoute } from "entity/document/PDFSignature/PDFSignatureDetailsRoute";
import { PDFSignatureSignRoute } from "entity/document/PDFSignature/PDFSignatureSignRoute";
import { RouteProposal } from "entity/estimate/Proposal/ProposalRoute";
import { RouteInternalUser } from "entity/internalUser/InternalUserDetails/RouteInternalUser";
import { RouteIntegrationsSettings } from "entity/lead/IntegrationsSettings/RouteIntegrationsSettings";
import { RouteLead } from "entity/lead/Lead/LeadRoute";
import { RouteLeadActivity } from "entity/lead/LeadActivity/LeadActivityRoute";
import { RouteLeadProposal } from "entity/leadProposal/LeadProposal/LeadProposalRoute";
import { RouteLienWaiver } from "entity/lienWaiver/LienWaiver/LienWaiverRoute";
import { RouteReconsent } from "entity/marketplace/Reconsent/RouteReconsent";
import { RouteMessages } from "entity/message/Message/RouteMessage";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { RouteNotificationUnfollow } from "entity/notification/NotificationUnfollow/RouteNotificationUnfollow";
import { RouteOwnerInvoice } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoiceRoute";
import { RouteOwnerSetup } from "entity/OwnerSetup/RouteOwnerSetup";
import { RoutePdfSignatureRequest } from "entity/pdfSignature/RoutePdfSignatureRequest";
import { RoutePhotoPreview } from "entity/photo/PhotoPreview/RoutePhotoPreview";
import { RoutePurchaseOrder } from "entity/purchaseOrder/PurchaseOrder/PurchaseOrderRoute";
import { RouteRebateList } from "entity/rebate/RebateList/RouteRebateList";
import { RouteReceipt } from "entity/receipt/Receipt/RouteReceipt";
import { RouteRfi } from "entity/rfi/Rfi/RfiRoute";
import { RouteSchedule } from "entity/schedule/Schedule/RouteSchedule";
import { RouteSelection } from "entity/selection/Selection/SelectionRoute";
import { RouteSelectionChoice } from "entity/selectionChoice/SelectionChoice/SelectionChoiceRoute";
import { RouteSubSetup } from "entity/sub/SubSetup/RouteSubSetup";
import { RouteSubVendor } from "entity/sub/SubVendor/RouteSubVendor";
import { RouteOwnerSurvey } from "entity/survey/OwnerSurvey/RouteOwnerSurvey";
import { RouteSurvey } from "entity/survey/Survey/SurveyRoute";
import { RouteClockInClockOut } from "entity/timeClock/ClockInClockOut/RouteClockInClockOut";
import { RouteShift } from "entity/timeClock/Shift/RouteShift";
import { RouteToDo } from "entity/toDo/details/ToDoDetails/RouteToDo";
import { RouteWarranty } from "entity/warranty/Warranty/RouteWarranty";

export const NotificationPanelRoutes: React.FunctionComponent<RouteComponentProps> = (props) => {
    const modalConfig: IModalConfiguration = {
        beforeClose: () => props.history.replace(props.match.url),
        parentRoute: props.match.url + "/NotificationPanel",
    };
    const isBuilder: boolean = isInPortal({ builder: true });
    const isOwner: boolean = isInPortal({ owner: true });
    return (
        <RouteRelative
            path="/NotificationPanel"
            render={() => (
                <>
                    <RouteChangeOrder modalConfig={modalConfig} />
                    <RouteDailyLog modalConfig={modalConfig} />
                    <RouteBid modalConfig={modalConfig} />
                    <RouteCreditMemo modalConfig={modalConfig} />
                    <RouteBidPackage modalConfig={modalConfig} />
                    <RouteMediaProperties modalConfig={modalConfig} />
                    <RoutePhotoPreview modalConfig={modalConfig} />
                    <RouteProposal modalConfig={modalConfig} isTemplateMode={false} />
                    <RouteInternalUser modalConfig={modalConfig} />
                    <RouteSubSetup modalConfig={modalConfig} />
                    <RouteOwnerSetup modalConfig={modalConfig} />
                    <RouteNotificationUnfollow modalConfig={modalConfig} />
                    <RouteJob
                        parentRoute={modalConfig.parentRoute}
                        history={props.history}
                        beforeClose={async () => {
                            modalConfig.beforeClose();
                        }}
                    />
                    <RouteLeadProposal modalConfig={modalConfig} />
                    <RouteLeadActivity modalConfig={modalConfig} />
                    <RouteLead modalConfig={modalConfig} />
                    <RouteBill modalConfig={modalConfig} />
                    <RouteOwnerInvoice modalConfig={modalConfig} />
                    <RoutePurchaseOrder modalConfig={modalConfig} />
                    <RouteSelection modalConfig={modalConfig} />
                    <RouteSelectionChoice modalConfig={modalConfig} />
                    <RouteSchedule modalConfig={modalConfig} />
                    <RouteShift modalConfig={modalConfig} />
                    <RouteClockInClockOut modalConfig={modalConfig} />
                    <RouteSubVendor modalConfig={modalConfig} />
                    {isBuilder && <RouteSurvey modalConfig={modalConfig} />}
                    {isOwner && <RouteOwnerSurvey modalConfig={modalConfig} />}
                    <RouteToDo modalConfig={modalConfig} />
                    <RouteRfi modalConfig={modalConfig} />
                    <RouteWarranty modalConfig={modalConfig} />
                    <RouteReceipt modalConfig={modalConfig} />
                    <PDFSignatureSignRoute beforeClose={() => modalConfig.beforeClose()} />
                    <WhenBuilderFlag
                        isEnabled="signatureRequestModalUpdate"
                        then={<RoutePdfSignatureRequest modalConfig={modalConfig} />}
                        otherwise={
                            <PDFSignatureDetailsRoute
                                beforeClose={() => modalConfig.beforeClose()}
                            />
                        }
                    />

                    <RouteMessages fromReact modalConfig={modalConfig} />
                    <RouteMessageCompose isDraft modalConfig={modalConfig} />
                    <RouteLienWaiver modalConfig={modalConfig} />
                    <RouteDeposit modalConfig={modalConfig} />
                    <RouteIntegrationsSettings modalConfig={modalConfig} />
                    <RouteReconsent modalConfig={modalConfig} />
                    <RouteRebateList />
                </>
            )}
        />
    );
};
