import { ActivationStatus } from "types/enum";

import yup from "utilities/form/yup";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { ISearchBarResult } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

import { IContactAlert } from "entity/contact/Contact/Contact.api.types";
import { IMergeContactsRequest } from "entity/contact/MergeContactModal/MergeContactModal.api.types";
import { DepositFormActions } from "entity/deposit/Deposit/Deposit.api.types";
import { OwnerInvoiceFormActions } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoice.api.types";

export interface IContactEmailAndInviteFormValues {
    contactId?: number;
    contactSearch?: string;
    emailAddress?: string;
    inviteToBT?: boolean;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    activationStatus?: ActivationStatus;
}

export type ContactEmailAndInviteFormActions = undefined | "addOwnerEmail" | "skipAddingOwnerEmail";

export interface IContactEmailAndInvitePresentationalProps {
    userId?: number;
    data?: ContactEmailAndInviteEntity;
    contactAlert?: IContactAlert;
    actionBeingPerformed: OwnerInvoiceFormActions | DepositFormActions;
    modalConfig: IModalConfiguration | undefined;
    onSendEmail: (
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>,
        newValues?: IContactEmailAndInviteFormValues
    ) => Promise<void>;
    onSkipEmail: (
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>,
        newValues?: IContactEmailAndInviteFormValues
    ) => Promise<void>;
    onResultItemClick?: (result: ISearchBarResult) => Promise<void>;
    onCreateContactClick: () => void;
    isEditMode: boolean;
    onShowMergeContactsModal: (isVisible: boolean) => Promise<void>;
    onMergeContacts: (mergeConactRequest: IMergeContactsRequest) => Promise<boolean>;
    isMergeContactModalVisible: boolean;
    showAlert?: boolean;
    contacts?: IContactOrgLink[];
    entityName: string;
}

export class ContactEmailAndInviteEntity implements IContactEmailAndInviteEntity {
    constructor(data: IContactEmailAndInviteEntity) {
        this.contactId = data.contactId;
        this.isActive = data.isActive;
        this.displayName = data.displayName;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.emailAddress = data.emailAddress;
        this.hasEmail = data.hasEmail;
        this.activationStatus = data.activationStatus;
    }

    contactId?: number;
    isActive?: boolean;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    hasEmail?: boolean;
    emailAddress?: string;
    activationStatus?: ActivationStatus;
}

interface IContactEmailAndInviteEntity {
    contactId?: number;
    isActive?: boolean;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    hasEmail?: boolean;
    hasContact?: boolean;
    activationStatus?: ActivationStatus;
}

export class ContactOrgLinkResponse {
    constructor(data: any) {
        this.payeeList = data.userOrgLink?.map((x: IContactOrgLink) => new ContactOrgLink(x) ?? []);
    }

    payeeList: IContactOrgLink[];
}

export class ContactOrgLink implements IContactOrgLink {
    constructor(data: IContactOrgLink) {
        this.orgLinkId = data.orgLinkId;
        this.contactId = data.contactId;
        this.activationStatus = data.activationStatus;
        this.displayName = data.displayName;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
    }

    orgLinkId?: number;
    contactId?: number | undefined;
    activationStatus: ActivationStatus;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface IContactOrgLink {
    orgLinkId?: number;
    contactId?: number | undefined;
    activationStatus: ActivationStatus;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const ContactEmailAndInviteValidators = yup
    .object()
    .shape<IContactEmailAndInviteFormValues>({
        contactSearch: yup.string().label("Email or Name"),
        emailAddress: yup.string().email().label("Email Address"),
        inviteToBT: yup.boolean().label("Invite to Buildertrend"),
        displayName: yup.string().label("Name"),
        lastName: yup.string().label("Last Name"),
        firstName: yup.string().label("First Name"),
        contactId: yup.number().label("Contact Id"),
        activationStatus: yup.number().label("Activation Status"),
    });
