import { BTDrawer } from "commonComponents/btWrappers/BTDrawer/BTDrawer";
import { IMainNavRolledMenuItemsProps } from "commonComponents/utilities/MainNavigation/MainNavRolledMenuItems/MainNavRolledMenuItems.type";

export const MainNavRolledMenuItems: React.FC<
    IMainNavRolledMenuItemsProps & React.HTMLAttributes<HTMLDivElement>
> = ({ isRollupMenuOpen = false, handleCloseRollupMenu, rolledUpItems }) => {
    const renderAsDrawer = () => {
        return (
            <BTDrawer
                title=""
                placement="right"
                onClose={handleCloseRollupMenu}
                visible={isRollupMenuOpen && rolledUpItems.length > 0}
                destroyOnClose={true}
                className="MainNavRolledMenuItems"
            >
                {rolledUpItems.map((item, index) => {
                    const keystring = `itemKey${index}`;
                    return (
                        <div key={keystring} style={{ marginBottom: 4 }}>
                            {item()}
                        </div>
                    );
                })}
            </BTDrawer>
        );
    };

    return <>{renderAsDrawer()}</>;
};
