import { Component } from "react";

import { BTIconDocuments } from "commonComponents/btWrappers/BTIcon";
import {
    emptyStateIconSize,
    IEmptyStateEntityBannerProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    EmptyStateBanner,
    EmptyStateCallToActionButton,
} from "commonComponents/entity/emptyState/EmptyStateBanner";

interface IFilterEmptyStateBannerProps extends IEmptyStateEntityBannerProps {}

export class FiltersEmptyStateBanner extends Component<IFilterEmptyStateBannerProps> {
    private getFilterEmptyStateDescription() {
        return "Saved filters let you configure what information is shown on this page.";
    }

    private getFilterEmptyStateTitle() {
        return "You have no saved filters";
    }

    private getInitialWithDataState = () => {
        const { onCallToActionClick } = this.props;

        return (
            <>
                {this.getFilterEmptyStateDescription()}
                <EmptyStateCallToActionButton onCallToActionClick={onCallToActionClick}>
                    Add a new saved filter
                </EmptyStateCallToActionButton>
            </>
        );
    };

    render() {
        const { actionBeingPerformed, isDismissed, hasListData } = this.props;

        if (isDismissed || hasListData) {
            return null;
        }

        return (
            <EmptyStateBanner
                title={this.getFilterEmptyStateTitle()}
                actionBeingPerformed={actionBeingPerformed}
                image={
                    <BTIconDocuments
                        size={emptyStateIconSize}
                        className="EmptyStateIcon"
                        data-testid="EmptyStateIcon"
                    />
                }
                isDismissable={false}
            >
                {this.getInitialWithDataState()}
            </EmptyStateBanner>
        );
    }
}
