/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */

/**
 * 1 = OpenOnly

2 = ClosedOnly

3 = OpenOrClosed

4 = OpenOrDataRetrieval

5 = Canceled


 */
export type ClosedAccountTypes = (typeof ClosedAccountTypes)[keyof typeof ClosedAccountTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClosedAccountTypes = {
    OpenOnly: 1,
    ClosedOnly: 2,
    OpenOrClosed: 3,
    OpenOrDataRetrieval: 4,
    Canceled: 5,
} as const;
