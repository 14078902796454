import moment from "moment";
import { PureComponent } from "react";

import { getLocalNow } from "entity/timeClock/Shift/ShiftUtilities";

export interface IDateDisplayRelativeProps {
    value: moment.Moment;
    "data-testid"?: string;
}

export class DateDisplayRelative extends PureComponent<IDateDisplayRelativeProps> {
    private format(value: moment.Moment): string {
        const currentTime = getLocalNow();
        const differenceInMins = moment.duration(currentTime.diff(value)).asMinutes();
        const differenceInHours = moment.duration(currentTime.diff(value)).asHours();
        const differenceInDays = moment.duration(currentTime.diff(value)).asDays();
        const differenceInWeeks = moment.duration(currentTime.diff(value)).asWeeks();
        const differenceInMonths = moment.duration(currentTime.diff(value)).asMonths();
        const differenceInYears = moment.duration(currentTime.diff(value)).asYears();
        if (differenceInMins < 1) {
            return "Just Now";
        } else if (differenceInMins < 60) {
            return `${differenceInMins.toFixed(0)}m ago`;
        } else if (differenceInHours < 24) {
            return `${differenceInHours.toFixed(0)}h ago`;
        } else if (differenceInDays < 7) {
            return `${differenceInDays.toFixed(0)}d ago`;
        } else if (differenceInDays < 30) {
            return `${differenceInWeeks.toFixed(0)}w ago`;
        } else if (differenceInDays < 366) {
            return `${differenceInMonths.toFixed(0)}mo ago`;
        } else {
            return `${differenceInYears.toFixed(0)}y ago`;
        }
    }
    render() {
        return <span data-testid={this.props["data-testid"]}>{this.format(this.props.value)}</span>;
    }
}
