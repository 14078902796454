import { ModalFuncProps } from "antd";
import classNames from "classnames";

import { IInactiveLineItem } from "entity/costCatalog/CostCatalog.types";
import { LeadHandler } from "entity/lead/Lead/Lead.api.handler";
import { TemplateImportHandler } from "entity/templateImport/TemplateImport/TemplateImport.api.handler";
import {
    ITemplateImportConflictsRequest,
    TemplateImportConflictsResponse,
} from "entity/templateImport/TemplateImport/TemplateImport.api.types";

import "./TemplateImportConflicts.less";

interface IImportConflictsPromptRequestParams {
    onContinue: () => void;
    onCancel?: () => void;
    lineItemType: "Template" | "Proposal" | "Job" | "Catalog";
}

interface ITemplateImportConflictPromptRequestParams
    extends ITemplateImportConflictsRequest,
        IImportConflictsPromptRequestParams {
    templateImportHandler?: TemplateImportHandler;
}

interface IProposalImportConflictPromptRequestParams extends IImportConflictsPromptRequestParams {
    leadHandler?: LeadHandler;
    proposalId: number;
    leadIds: number[];
    jobId?: number;
}

interface ICatalogImportConflictPromptRequestParams extends IImportConflictsPromptRequestParams {
    inactiveCostCodes: IInactiveLineItem[];
    multipleCostTypesCount: number;
    hasMultipleMarkedAs: boolean;
}

export interface IImportConflictPromptResponse {
    showPrompt: boolean;
    props?: ModalFuncProps;
}

export async function fireProposalImportConflictsPrompt(
    params: IProposalImportConflictPromptRequestParams
): Promise<IImportConflictPromptResponse> {
    const leadHandler = params.leadHandler ?? new LeadHandler();
    const resp = await leadHandler.getImportConflicts(
        params.proposalId,
        params.leadIds,
        params.jobId
    );
    return renderComponent(params, resp);
}

export async function fireTemplateImportConflictsPrompt(
    params: ITemplateImportConflictPromptRequestParams
): Promise<IImportConflictPromptResponse> {
    const templateImportHandler = params.templateImportHandler ?? new TemplateImportHandler();
    const resp = await templateImportHandler.getImportConflicts(params);
    return renderComponent(params, resp);
}

export async function fireCatalogImportConflictsPrompt(
    params: ICatalogImportConflictPromptRequestParams
): Promise<IImportConflictPromptResponse> {
    const resp = {
        inactiveCostCodes: params.inactiveCostCodes,
        multipleCostTypesCount: params.multipleCostTypesCount,
        hasMultipleMarkedAs: params.hasMultipleMarkedAs,
    };
    return renderComponent(params, resp);
}

function getTitle(
    inactiveCostCodes: boolean,
    multipleCostTypes: boolean,
    multipleMarkedAs: boolean
): string {
    let title = "";
    if (inactiveCostCodes) {
        title += "Inactive Cost Codes";
        if (multipleCostTypes && multipleMarkedAs) {
            title += ", Multiple Cost Types, & Multiple Marked As Options";
        } else if (multipleCostTypes) {
            title += " & Multiple Cost Types";
        } else if (multipleMarkedAs) {
            title += " & Multiple Marked As Options";
        }
    } else if (multipleCostTypes) {
        title += "Multiple Cost Types";
        if (multipleMarkedAs) {
            title += " & Multiple Marked As Options";
        }
    } else if (multipleMarkedAs) {
        title += "Multiple Marked As Options";
    }
    return title;
}

function getHeader(
    inactiveCostCodes: boolean,
    multipleCostTypes: boolean,
    multipleMarkedAs: boolean
): string {
    let header = "Some of the items you are trying to import ";
    if (inactiveCostCodes) {
        header += "are associated with inactive Cost Codes";
        if (multipleCostTypes && multipleMarkedAs) {
            header += ", have multiple Cost Types, and/or have multiple Marked As options selected";
        } else if (multipleCostTypes) {
            header += " and/or have multiple Cost Types";
        } else if (multipleMarkedAs) {
            header += " and/or have multiple Marked As options selected";
        }
    } else if (multipleCostTypes) {
        header += "have multiple Cost Types";
        if (multipleMarkedAs) {
            header += " and/or have multiple Marked As options selected";
        }
    } else if (multipleMarkedAs) {
        header += "have multiple Marked As options selected";
    }
    return header + ". ";
}

function getFooter(
    inactiveCostCodes: boolean,
    multipleCostTypes: boolean,
    multipleMarkedAs: boolean,
    lineItemType: "Template" | "Proposal" | "Job" | "Catalog"
): string {
    let footer = `Update your ${lineItemType} line items to have `;
    if (inactiveCostCodes) {
        footer += "active Cost Codes";
        if (multipleCostTypes && multipleMarkedAs) {
            footer += ", a single Cost Type selected, and a single Mark As option selected";
        } else if (multipleCostTypes) {
            footer += " and a single Cost Type selected";
        } else if (multipleMarkedAs) {
            footer += " and a single Marked As option selected";
        }
    } else if (multipleCostTypes) {
        footer += "a single Cost Type selected";
        if (multipleMarkedAs) {
            footer += " and a single Marked As option selected";
        }
    } else if (multipleMarkedAs) {
        footer += "a single Marked As option selected";
    }
    return footer + " to prevent this issue in future.";
}

function renderComponent(
    params: IImportConflictsPromptRequestParams,
    resp: TemplateImportConflictsResponse
) {
    const hasInactiveCostCodes = resp.inactiveCostCodes.length > 0;
    const hasMultipleCostTypes = resp.multipleCostTypesCount > 0;
    const hasMultipleMarkedAs = resp.hasMultipleMarkedAs;
    if (hasInactiveCostCodes || hasMultipleCostTypes || hasMultipleMarkedAs) {
        const title = getTitle(hasInactiveCostCodes, hasMultipleCostTypes, hasMultipleMarkedAs);
        return Promise.resolve({
            showPrompt: true,
            props: {
                title: title,
                width: 450,
                content: (
                    <>
                        {getHeader(hasInactiveCostCodes, hasMultipleCostTypes, hasMultipleMarkedAs)}
                        {hasInactiveCostCodes && (
                            <div
                                className={classNames({
                                    ImportConflictNewLine:
                                        hasMultipleCostTypes || hasMultipleMarkedAs,
                                    ImportConflictInline:
                                        !hasMultipleCostTypes && !hasMultipleMarkedAs,
                                })}
                            >
                                Inactive Cost Codes will be replaced with the Buildertrend Flat Rate
                                Cost Code.
                                <ul className="margin-top-lg">
                                    {resp.inactiveCostCodes.map((item) => (
                                        <li key={item.id}>
                                            {item.title} ({item.count})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {hasMultipleCostTypes && (
                            <div
                                className={classNames({
                                    ImportConflictNewLine:
                                        hasInactiveCostCodes || hasMultipleMarkedAs,
                                    ImportConflictInline:
                                        !hasInactiveCostCodes && !hasMultipleMarkedAs,
                                })}
                            >
                                Line Items now only support one Cost Type. Line items imported with
                                multiple Cost Types will have Cost Type input cleared{" "}
                                {params.lineItemType === "Catalog" && <>and linkage broken </>}
                                upon import.
                                <ul className="margin-top-lg">
                                    <li>
                                        Line items with multiple Cost Types (
                                        {resp.multipleCostTypesCount})
                                    </li>
                                </ul>
                            </div>
                        )}
                        {hasMultipleMarkedAs && (
                            <div
                                className={classNames({
                                    ImportConflictNewLine:
                                        hasInactiveCostCodes || hasMultipleCostTypes,
                                    ImportConflictInline:
                                        !hasInactiveCostCodes && !hasMultipleCostTypes,
                                })}
                            >
                                Line items now only support one Mark As option. Line items imported
                                with multiple Marked As options will have the Mark As input cleared{" "}
                                {params.lineItemType === "Catalog" && <>and linkage broken </>}
                                upon import.
                            </div>
                        )}
                        <div className="ImportConflictNewLine">
                            {getFooter(
                                hasInactiveCostCodes,
                                hasMultipleCostTypes,
                                hasMultipleMarkedAs,
                                params.lineItemType
                            )}
                        </div>
                    </>
                ),
                okText: "Import With Updates",
                onOk: params.onContinue,
                onCancel: params.onCancel,
            },
        });
    }

    return Promise.resolve({ showPrompt: false });
}
