import qs, { ParseOptions } from "query-string";

import { except } from "utilities/array/array";

/**
 * Detects if the passed domain is http://localhost https://localhost ony any port
 * @param domain make sure the domain is the full domain name
 */
export function isLocalhostDomain(domain: string) {
    const localhostDomainRegex = /(https?:\/\/localhost(?::\d+)?)/;
    const isLocalhost = localhostDomainRegex.test(domain);

    return isLocalhost;
}

/**
 * Detects if the passed domain is *.buildertrend.net
 * @param domain make sure the domain is the full domain name
 */
export function isBuildertrendDomain(domain: string) {
    return domain.endsWith("buildertrend.net");
}

/**
 * Detects if the passed domain is *.buildertrendsystems.com
 * @param domain make sure the domain is the full domain name
 */
export function isPaymentProcessingServiceDomain(domain: string) {
    return domain.endsWith("buildertrendsystems.com") || domain.endsWith("ngrok.io");
}

export function getOAuthUrl(redirectUri: string, oAuthCode: string, state: string) {
    const parsed = qs.parseUrl(redirectUri);
    const result = qs.stringifyUrl({
        url: parsed.url,
        query: {
            ...parsed.query,
            // merge in the code and state with any existing qs params
            code: oAuthCode,
            state,
        },
    });
    return result;
}

export const removeQueryStringParams = (queryString: string, excludedKeys: string[] = []) => {
    const qsParams = new URLSearchParams(queryString);
    const deleteParams: string[] = [];
    const exceptions = ["filter", ...excludedKeys];

    qsParams.forEach((val, key) => deleteParams.push(key));
    except(deleteParams, exceptions).forEach((val) => qsParams.delete(val));

    return qsParams.toString();
};

/**
 * @returns Boolean indicating if we are inside an iframe
 */
export const isInIframe = (): boolean => {
    return window.location !== window.parent.location;
};

export function parseParams<T>(query: string, options?: ParseOptions) {
    return qs.parse(query, options) as Partial<T>;
}
