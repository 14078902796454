import { useRef } from "react";

/**
 *  Evaluates the provided value with a shallow comparison on the value from the previous render.
 *
 *  @returns [isValueChanged: boolean, previousValue: T]
 */
export const useHasValueChanged = <T>(value: T) => {
    const previousContent = useRef<T>(value);

    const previousValue = previousContent.current;
    const isValueChanged = value !== previousValue;
    previousContent.current = value;

    return [isValueChanged, previousValue] as const;
};
