import { PureComponent } from "react";

import { btConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTIconWarningFilled } from "commonComponents/btWrappers/BTIcon";
import { UserWithConflicts } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";

import "./ScheduleConflictAlert.less";

interface IScheduleConflictAlertProps {
    conflicts: UserWithConflicts[];
}

export class ScheduleConflictAlert extends PureComponent<IScheduleConflictAlertProps> {
    render() {
        return (
            <div className="ScheduleConflictAlert">
                <ul>
                    {this.props.conflicts.map((c) => (
                        <li key={c.name}>
                            <strong>{c.name}</strong>
                            <span>
                                is performing on {c.conflicts.length} items during these work days.
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export const showScheduleConflictAlert = (
    conflicts: UserWithConflicts[],
    onContinue: () => void,
    onViewDetails: () => void
) => {
    btConfirm({
        title: "Conflict Detected",
        onOk: onContinue,
        okText: "Continue",
        onCancel: onViewDetails,
        cancelText: "View Details",
        cancelButtonProps: {
            type: "default",
        },
        icon: <BTIconWarningFilled />,
        width: "max-content",
        content: <ScheduleConflictAlert conflicts={conflicts} />,
    });
};
