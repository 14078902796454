import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { BTLocalStorage } from "types/btStorage";

import { reportError, reportMessage } from "utilities/errorHelpers";

import { formatBytes } from "commonComponents/utilities/ByteDisplay/ByteDisplay.utilities";

interface ISawmillOptions {
    tenantId: string;
    ingestionEndpoint: string;
    batchThreshold: number;
    maxCachedEvents: number;
    maxPayloadSizeBytes: number;
}

interface ISawmillRequest {
    tenantId: string;
    idempotencyKey?: string;
    events: ISawmillEvent[];
}

export interface ISawmillEvent {
    client: string;
    clientOS: string;
    userId: string;
    eventName: string;
    timestamp: string;
    body: any;
}

export function sawmillPlugin(userConfig: ISawmillOptions) {
    return {
        name: "sawmill",
        config: { ...userConfig },
        track: async ({ payload, config }: { payload: any; config: ISawmillOptions }) => {
            const newEvent = {
                client: "Browser",
                clientOS: window.navigator.userAgent || "unknown",
                userId: payload.userId,
                eventName: payload.event,
                timestamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                body: payload.properties,
            };

            // ado-178532 we had massive event sizes put on this key which made it unsafe
            // Remove after some time
            BTLocalStorage.remove("bt-objectArray-sawmillEvents");

            await navigator.locks.request("sawmillEvents", { steal: false }, async () => {
                const cachedEvents = BTLocalStorage.get("bt-objectArray-sawmillEventsV2");

                const events = [...cachedEvents, newEvent];

                const eventsPayloadSize = JSON.stringify(events).length;
                if (eventsPayloadSize > config.maxPayloadSizeBytes) {
                    // We should never try and send or store more than the max payload size
                    // If the events get this large, drop them. We probably have a bug or major outage
                    BTLocalStorage.set("bt-objectArray-sawmillEventsV2", []);
                    if (config.maxPayloadSizeBytes > 0) {
                        reportMessage(
                            `Sawmill events reached ${formatBytes(
                                eventsPayloadSize
                            )}. Max payload size of ${formatBytes(
                                config.maxPayloadSizeBytes
                            )} reached. Dropping all Sawmill events.`
                        );
                    }
                    return;
                }

                if (events.length >= config.batchThreshold) {
                    const idempotencyKey = uuidv4();
                    try {
                        const response = await sendSawmillRequest(idempotencyKey, config, events);
                        if (response.ok) {
                            BTLocalStorage.set("bt-objectArray-sawmillEventsV2", []);
                            return;
                        }
                    } catch (error) {
                        // waiting on https://github.com/getsentry/sentry-javascript/issues/4146 for more debug info
                        reportError(
                            error,
                            {
                                internalNotes:
                                    "Failed to post Sawmill events. The queue will be tried again on the next event. Idempotency key: " +
                                    idempotencyKey,
                            },
                            "warning"
                        );
                    }
                }

                BTLocalStorage.set(
                    "bt-objectArray-sawmillEventsV2",
                    events.slice(-config.maxCachedEvents)
                );
            });
        },
    };
}

function sendSawmillRequest(
    idempotencyKey: string,
    config: ISawmillOptions,
    events: ISawmillEvent[]
) {
    const request = {
        tenantId: config.tenantId,
        idempotencyKey: idempotencyKey,
        events,
    } as ISawmillRequest;

    return fetch(config.ingestionEndpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });
}
