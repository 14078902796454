import {
    IEntityRecord,
    IEntityRecordInsert,
} from "helpers/routing/EntityLinkRouter/EntityLinkRouter.api.types";
import { default as EntityLinkRouterExampleJson } from "helpers/routing/EntityLinkRouter/entityLinkRouter.example.json";

import { APIHandler, responseAs } from "utilities/apiHandler";
import { PortalType } from "utilities/portal/portal";

export interface IEntityLinkRouterHandler {
    get(externalId: string, portalType: PortalType): Promise<IEntityRecord>;
    insert(insertData: IEntityRecordInsert): Promise<IEntityRecord>;
}

export class EntityLinkRouterHandler implements IEntityLinkRouterHandler {
    async get(externalId: string, portalType: PortalType): Promise<IEntityRecord> {
        return await APIHandler(`/api/EntityRecord/`, {
            method: "GET",
            data: {
                externalId,
            },
            headers: {
                PortalType: portalType,
            },
            responseType: (data) => responseAs<IEntityRecord>(data),
        });
    }

    async insert(insertData: IEntityRecordInsert): Promise<IEntityRecord> {
        return await APIHandler(`/api/EntityRecord/`, {
            method: "POST",
            data: insertData,
            responseType: (data) => responseAs<IEntityRecord>(data),
        });
    }
}

export class FakeEntityLinkRouterHandler implements IEntityLinkRouterHandler {
    async get(_externalId: string, _portalType: PortalType): Promise<IEntityRecord> {
        return responseAs<IEntityRecord>(EntityLinkRouterExampleJson);
    }
    async insert(_insertData: IEntityRecordInsert): Promise<IEntityRecord> {
        return responseAs<IEntityRecord>(EntityLinkRouterExampleJson);
    }
}

export const defaultEntityLinkRouterHandler = new EntityLinkRouterHandler();
