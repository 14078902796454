import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import classNames from "classnames";
import { createRef, CSSProperties, FunctionComponent, PureComponent, useContext } from "react";
import { useLocation, useRouteMatch } from "react-router";

import { ITrackingProp, track, useTracking } from "utilities/analytics/analytics";
import { setQualtricsExtraParameters } from "utilities/analytics/customPlugins/qualtricsPlugin";
import { IDialogEventMessage } from "utilities/routesWebforms";
import { parsePixelLength } from "utilities/string/string";
import { isBuildertrendDomain, isLocalhostDomain } from "utilities/url/url";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCloseOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import {
    FullscreenContext,
    IFullscreenContext,
} from "commonComponents/utilities/FullscreenContext/FullscreenContext";
import { PageTitle } from "commonComponents/utilities/PageTitle/PageTitle";
import { StickyProvider } from "commonComponents/utilities/StickyContext";

import "./BTModal.less";

/**
 * An interface to pass to entities for them to open themselves in modals
 */
export interface IModalConfiguration {
    parentRoute: string;
    beforeClose: () => void;
}

export interface IOptionalModalConfiguration extends Object {
    modalConfig?: IModalConfiguration;
}

export interface IBTModalProps<CloseCallbackData>
    extends Omit<
        ModalProps,
        "width" | "title" | "visible" | "afterClose" | "bodyStyle" | "getContainer"
    > {
    /** Sets the modal and page title. To opt-out of the page title use setPageTitle={false} */
    title?: string;

    /** @default true */
    setPageTitle?: boolean;

    visible: boolean;

    /* pass a url to display within an iframe, don't pass when you want inner content */
    url?: string;

    /**
     * Specifies the max width of the modal. Modals can be a smaller width when on smaller width monitors & mobile.
     * Modals will have a horizontal scroll if they go beyond this width
     */
    width: string;

    /**
     * Shows a loading spinner over the entire modal content
     * @default false
     */
    isLoading?: boolean;

    /**
     * Only specify when using iframe modals (url prop)
     */
    iframeHeight?: string | number;

    /**
     * should padding be shown removed from within the modal's body?
     * @default false (when a url (iframe) is used this defaults to true)
     */
    removeBodyPadding?: boolean;

    /**
     * DO NOT USE
     */
    alwaysOnTop?: boolean;

    /**
     * Style for modal's mask element
     */
    maskStyle?: CSSProperties;

    /* This method is called when the close button is clicked, your method should set the visible state to false */
    beforeClose: (data?: CloseCallbackData) => void;

    /* This method is called after a modal is closed (after beforeClose) */
    afterClose?: () => void;

    /**
     * Sets the overflow behavior of the modal, controlling how content is
     * rendered when it reaches the maximum height or width
     * @default "auto"
     */
    overflow?: "auto" | "clip" | "hidden" | "scroll" | "visible";

    /**
     * Prevents ant modal animation for the modal. Used in edge cases when you need the modal to be rendered immediately
     */
    disableAnimation?: boolean;

    actionBeingPerformed?: any | undefined;

    /**
     * Added data-testid prop which can be sent from all references as it is required for automation testing
     */
    "data-testid"?: string;

    /**
     * Only set this to `true` when using BTModalLayout in a presentational component!
     * If this is not set to `true`, the modal title will be set by the `title` prop as usual.
     */
    useModalLayout?: boolean;

    /**
     * Removes the modal's header. This will also make the modal NOT closeable and also NOT show the modal's title.
     * @default false
     */
    removeHeader?: boolean;
    children?: React.ReactNode;
}

type BTModalInternalProps<CloseCallbackData> = IBTModalProps<CloseCallbackData> &
    ITrackingProp &
    ModalProps;

interface IBTModalState {
    iframeWidth: number;
    iframeHeight: number;
    originalProps?: {
        url?: string;
        width: string;
        iframeHeight?: string | number;
    };
    isClosing: boolean;
}

function parseCSSLengthToPixels(length: string | number) {
    return typeof length === "number" ? length : parsePixelLength(length);
}

const defaultLength = 100;

@track((props) => ({
    modal: props.title,
}))
class BTModalInternal<CloseCallbackData> extends PureComponent<
    BTModalInternalProps<CloseCallbackData>,
    IBTModalState
> {
    static defaultProps = {
        afterClose: (): void => {},
        /* default the footer to hidden */
        footer: null,
        removeBodyPadding: false,
        isLoading: false,
        overflow: "auto",
        setPageTitle: true,
        useModalLayout: false,
        removeHeader: false,
    };

    // todo remove once react conversion is completed
    // if we haven't completed the migration by React v17+ we need to migrated to a controlled component or ignore width/height prop changes on the same modal
    static getDerivedStateFromProps(
        nextProps: IBTModalProps<any>,
        state: IBTModalState
    ): IBTModalState | null {
        if (
            !state.originalProps ||
            nextProps.url !== state.originalProps.url ||
            nextProps.width !== state.originalProps.width ||
            nextProps.iframeHeight !== state.originalProps.iframeHeight
        ) {
            return {
                iframeWidth: parseCSSLengthToPixels(nextProps.width) || defaultLength,
                iframeHeight:
                    (nextProps.iframeHeight && parseCSSLengthToPixels(nextProps.iframeHeight)) ||
                    defaultLength,
                originalProps: {
                    width: nextProps.width,
                    iframeHeight: nextProps.iframeHeight,
                    url: nextProps.url,
                },
                isClosing: false,
            };
        }
        return null;
    }

    // todo remove once react conversion is completed
    state: Readonly<IBTModalState> = {
        iframeWidth: defaultLength,
        iframeHeight: defaultLength,
        isClosing: false,
    };

    private iframeElement = createRef<HTMLIFrameElement>();

    /**
     * This method allows for webform pages to communicate with react, all communication should be done via this method.
     * @example
     * // From within webforms: - this will trigger the beforeClose() method
     * webformsDialogHandler.closeModal();
     */
    private dialogMessageHandler = (event: MessageEvent) => {
        const isLocalhost = isLocalhostDomain(event.origin);
        const isBuildertrend = isBuildertrendDomain(event.origin);

        if (!isLocalhost && !isBuildertrend) {
            // unkown domain, ignore the message
            return;
        }

        if (this.props.url === undefined || this.iframeElement.current === null) {
            // this modal is not using a iframe
            return;
        }

        if (this.iframeElement.current.contentWindow !== event.source) {
            // the iframe that sent the event does not match the modal receiving it
            // likely due to an extra "parent." existing where it isn't needed
            return;
        }

        // todo remove once react conversion is completed. Once we're on a single page app we can setState to control this instead
        try {
            const eventMessage = JSON.parse(event.data) as IDialogEventMessage;
            switch (eventMessage.type) {
                case "closeModal":
                case "closeModalAndReload":
                    this.props.beforeClose(eventMessage.data);
                    break;

                case "setModalSize":
                    // Update the width/height of the modal (if the width/height are null keep the dimension the same)
                    // For now we're not going to allow either dimension to shrink to try and keep things simple
                    const { iframeWidth, iframeHeight } = this.state;
                    const eventWidth = parseCSSLengthToPixels(eventMessage.data.width);
                    const eventHeight = parseCSSLengthToPixels(eventMessage.data.height);

                    this.setState({
                        iframeWidth:
                            eventWidth && eventWidth > iframeWidth ? eventWidth : iframeWidth,
                        iframeHeight:
                            eventHeight && eventHeight > iframeHeight ? eventHeight : iframeHeight,
                    });
                    break;
                case "checkQualtrics":
                    setQualtricsExtraParameters(eventMessage.data.surveyName);
                    break;
                default:
                // unknown message
            }
        } catch (e) {
            // unexpected message format, just ignore
            return;
        }
    };

    private beforeCloseCallback = () => {
        const { beforeClose } = this.props;
        beforeClose(undefined);
    };

    private handleCancel = () => {
        const { actionBeingPerformed } = this.props;

        // If there is an action being performed then wait until it is finished. Will also close after 10 seconds as a fallback
        if (!this.state.isClosing) {
            if (actionBeingPerformed) {
                this.setState({ isClosing: true });
            } else {
                this.beforeCloseCallback();
                return true;
            }
        }
        return false;
    };

    @track((props) => (props.visible ? { event: "ModalOpen" } : undefined))
    componentDidMount() {
        window.addEventListener("message", this.dialogMessageHandler);
    }

    componentDidUpdate(prevProps: IBTModalProps<any>) {
        if (
            this.state.isClosing &&
            prevProps.actionBeingPerformed !== undefined &&
            this.props.actionBeingPerformed === undefined
        ) {
            this.beforeCloseCallback();
        }

        if (prevProps.visible && !this.props.visible) {
            this.props.tracking?.trackEvent({
                event: "ModalClose",
            });
        }
        if (!prevProps.visible && this.props.visible) {
            this.props.tracking?.trackEvent({
                event: "ModalOpen",
            });
        }
    }

    @track((props) => (props.visible ? { event: "ModalClose" } : undefined))
    componentWillUnmount() {
        window.removeEventListener("message", this.dialogMessageHandler);
    }

    render() {
        const {
            title,
            footer,
            visible,
            afterClose,
            url,
            overflow,
            alwaysOnTop,
            maskStyle,
            setPageTitle,
            useModalLayout,
            removeHeader,
            ...antModalProps
        } = this.props;

        const showOldHeader = !useModalLayout;

        let footerProp = footer;
        let bodyStyle: React.CSSProperties = { overflow: overflow };
        let dialogContent;

        if (url === undefined) {
            // if it's not an iframe just render the passed in children
            dialogContent = this.props.children;

            /* remove modal padding (this is done automatically for iframes) */
            if (this.props.removeBodyPadding) {
                bodyStyle.padding = 0;
            }
        } else {
            dialogContent = (
                <div style={{ fontSize: 0 }}>
                    <iframe
                        className="legacyIframeModal"
                        ref={this.iframeElement}
                        // DEPRECATED - Avoid using title in future code
                        // eslint-disable-next-line react/forbid-dom-props
                        title={title}
                        src={url}
                        style={{ width: this.state.iframeWidth, height: this.state.iframeHeight }}
                    />
                </div>
            );

            // remove default modal padding when adding an iframe
            bodyStyle.padding = 0;
        }

        if (this.props.okText !== undefined) {
            // show the footer if okText is changed
            footerProp = undefined;
        }

        let newTitle: React.ReactNode = title;
        if (removeHeader) {
            newTitle = undefined;
        } else if (this.props.closable === false && !title) {
            newTitle = <>&nbsp;</>;
        }

        const isLoadingOrClosing = this.props.isLoading || this.state.isClosing;
        const closeIcon = (
            <BTButton
                data-testid="closeIframeButton"
                type="tertiary"
                hotkey="close"
                icon={
                    <BTIconCloseOutlined
                        className="ModalHeaderCloseIcon"
                        title="Close"
                        isOnDark={false}
                        size={20}
                    />
                }
            />
        );

        return (
            <StickyProvider>
                <FocusProvider disabled={!visible}>
                    {
                        // eslint-disable-next-line react/forbid-elements
                        <Modal
                            {...antModalProps}
                            closable={
                                !removeHeader &&
                                showOldHeader &&
                                !this.state.isClosing &&
                                this.props.closable
                            }
                            title={
                                showOldHeader && !removeHeader ? (
                                    <BTTitle bold level={2}>
                                        {newTitle}
                                    </BTTitle>
                                ) : undefined
                            }
                            className={classNames("react", antModalProps.className, {
                                newModalHeader: useModalLayout,
                            })}
                            visible={visible}
                            bodyStyle={bodyStyle}
                            footer={footerProp} // hide the footer
                            onCancel={this.handleCancel}
                            afterClose={afterClose}
                            destroyOnClose={true}
                            keyboard={showOldHeader && !removeHeader}
                            maskClosable={false}
                            maskStyle={maskStyle}
                            zIndex={alwaysOnTop ? 10001 : 100}
                            wrapClassName={classNames(
                                "buildertrend-custom-modal",
                                "buildertrend-custom-modal-no-header",
                                {
                                    "scroll-locked": isLoadingOrClosing, // prevent scrolling while loading
                                    "buildertrend-custom-modal-no-animation":
                                        this.props.disableAnimation,
                                }
                            )}
                            closeIcon={showOldHeader && !removeHeader ? closeIcon : undefined}
                            getContainer={this.props.getContainer}
                        >
                            {setPageTitle && title && (
                                <PageTitle title={`${title} - Buildertrend`} />
                            )}
                            {dialogContent}
                            {isLoadingOrClosing && <BTLoading displayMode="modal" />}
                        </Modal>
                    }
                </FocusProvider>
            </StickyProvider>
        );
    }
}

export function BTModal<CloseCallbackData>(
    props: IBTModalProps<CloseCallbackData>
): ReturnType<FunctionComponent<IBTModalProps<CloseCallbackData>>> {
    const { params } = useRouteMatch();
    const { pathname } = useLocation();
    const fullscreenContext = useContext<IFullscreenContext | undefined>(FullscreenContext);
    const { Track } = useTracking({
        route: pathname,
        routeParams: params,
    });
    return (
        <Track>
            <BTModalInternal {...props} getContainer={fullscreenContext?.fullscreenElement} />
        </Track>
    );
}
