import { Component, createRef, RefObject } from "react";

import { track } from "utilities/analytics/analytics";

import {
    BTButton,
    BTButtonType,
    IBTButtonProps,
} from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown, IBTDropdownProps } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";

declare type OverlayFunc = () => React.ReactElement;

interface IBTDropdownActionsProps<ActionType> extends Omit<IBTDropdownProps, "overlay"> {
    "data-testid": string;

    type?: BTButtonType;
    isolated?: boolean;
    actionBeingPerformed?: ActionType;
    loadingAction?: ActionType;
    buttonClassName?: string;
    buttonProps?: Partial<IBTButtonProps<ActionType>>;
    /** Optional replacement for passing in overlay. If used and only one BTDropdownMenuItem is passed in, the dropdown will be rendered as a button instead */
    menuItems?: BTDropdownMenuItem[];
    /** If only one menu item exists, text to prefix on the button */
    singleActionPrefix?: string;
    /** If menuItems are not specified, overlay must be passed in */
    overlay?: React.ReactElement | OverlayFunc;
    /** Allows custom message for popover, if needed */
    popoverMessage?: string;
    /**
     * Should the caret be shown after the dropdown
     * @default true
     */
    showCaret?: boolean;
    accessibilityHack?: boolean;
}

export class BTDropdownMenuItem {
    "data-testid": string;
    onClick: () => Promise<void> | void;
    disabled?: boolean;
    content: JSX.Element | string;
}

@track((props) => ({ component: "Dropdown Actions", uniqueId: props["data-testid"] }))
export class BTDropdownActions<ActionType = any> extends Component<
    IBTDropdownActionsProps<ActionType>
> {
    static defaultProps = {
        showCaret: true,
    };
    divRef: RefObject<HTMLDivElement>;
    constructor(props: IBTDropdownActionsProps<ActionType>) {
        super(props);
        this.divRef = createRef<HTMLDivElement>();
    }

    render = () => {
        const {
            children,
            type,
            isolated,
            actionBeingPerformed,
            loadingAction,
            buttonClassName,
            "data-testid": dataTestId,
            buttonProps,
            overlay,
            menuItems,
            singleActionPrefix,
            popoverMessage,
            showCaret,
            ...otherProps
        } = this.props;

        let menuItemOverlay = null;
        if (menuItems && menuItems.length === 1) {
            const menuItem = menuItems[0];
            return (
                <BTButton
                    data-testid={menuItem["data-testid"]}
                    onClick={menuItem.onClick}
                    className={otherProps.className}
                    {...(buttonProps || {})}
                    disabled={menuItem.disabled}
                    type={type}
                    isolated={isolated}
                    loadingAction={loadingAction}
                    actionBeingPerformed={actionBeingPerformed}
                >
                    {singleActionPrefix} {menuItem.content}
                </BTButton>
            );
        } else if (menuItems) {
            menuItemOverlay = (
                <BTMenu>
                    {menuItems.map((x) => (
                        <BTMenuItem
                            key={x["data-testid"]}
                            onClick={x.onClick}
                            data-testid={x["data-testid"]}
                            disabled={x.disabled}
                        >
                            {x.content}
                        </BTMenuItem>
                    ))}
                </BTMenu>
            );
        }

        let buttonLabel: React.ReactNode = undefined;
        if (children || showCaret) {
            buttonLabel = (
                <>
                    {children}
                    {showCaret && <BTIconCaretSmallDown />}
                </>
            );
        }

        const dropdownButton = (
            <>
                <BTDropdown
                    {...otherProps}
                    overlay={menuItemOverlay ?? overlay!}
                    getPopupContainer={
                        this.props.accessibilityHack
                            ? (_) => this.divRef.current ?? document.body
                            : undefined
                    }
                >
                    <BTButton
                        className={buttonClassName}
                        actionBeingPerformed={actionBeingPerformed}
                        type={type}
                        isolated={isolated}
                        data-testid={dataTestId}
                        loadingAction={loadingAction}
                        {...(buttonProps || {})}
                    >
                        {buttonLabel}
                    </BTButton>
                </BTDropdown>
                {this.props.accessibilityHack && <div ref={this.divRef} />}
            </>
        );

        const popover = (
            <BTPopover content={popoverMessage}>
                <div style={{ display: "inline-block" }}>{dropdownButton}</div>
            </BTPopover>
        );

        if (this.props.disabled && popoverMessage) {
            return popover;
        }

        return dropdownButton;
    };
}
