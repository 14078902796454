import moment, { Moment } from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";

export type RoleSurveyFormActions = undefined | "save";

export const OtherRoleValue = -1;

export interface IRoleSurveyFormValues {
    roles: string | number;
    otherDescription: string;
}

export class RoleSurveyEntity {
    constructor(data: any) {
        this.jobDescription = new JobDescription(data);
        this.lastUpdatedDate = moment(data.lastUpdatedDate);
        this.headerText = data.headerText;
        this.subText = data.subText;
        this.otherJobDescription = data.otherJobDescription;
    }
    jobDescription: JobDescription;
    lastUpdatedDate?: Moment;
    headerText: string;
    subText: string;
    otherJobDescription: string;
}

export class RoleSurveySubmitResponse {
    constructor(data: any) {
        this.lastUpdatedDate = moment(data.lastUpdatedDate);
    }
    lastUpdatedDate: Moment;
}

class JobDescription {
    constructor(data: any) {
        this.options = data.jobDescription.options.map((item: any) => {
            return new BTSelectItem(item);
        });
        this.title = data.jobDescription.title;
        this.value = data.jobDescription.value;
    }
    options: BTSelectItem[];
    title: string;
    value?: number;
}
