import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISpecificationProps } from "entity/specification/Specification";

const Specification = lazyLoadWithRetry(() => import("./Specification"));

export const RouteSpecification = (
    props: Omit<ISpecificationProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.specification.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Specification
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
