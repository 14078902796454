import { APIHandlerVersion, IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { BTLoginTypes } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import { getColorIdFromHex } from "commonComponents/entity/colorPicker/ColorPicker/ColorPicker.types";

import {
    IBreakLinkRequest,
    IgnoreConflictsResponse,
    IScheduleDeleteRequest,
    IScheduleFormValues,
    LinkedItemsResponse,
    ManuallyConfirmDeclineResponse,
    PendingNotificationsResponse,
    RecalculateScheduleRequest,
    RecalculateScheduleResponse,
    ScheduleEntity,
    ScheduleEntityDefaultParams,
    ScheduleSubConfirmResponse,
    ScheduleUpdateResponse,
    SendReminderResponse,
    Shift,
    UpdateShiftReasonResponse,
    UsersWithoutJobAccessResponse,
} from "./Schedule.api.types";

export interface IScheduleHandler {
    get(id: number): Promise<ScheduleEntity>;
    getDefault(
        jobId: number,
        entityDefaultParams?: ScheduleEntityDefaultParams
    ): Promise<ScheduleEntity>;
    recalculate(data: RecalculateScheduleRequest): Promise<RecalculateScheduleResponse | undefined>;
    create(jobId: number, newValues: IScheduleFormValues): Promise<ScheduleUpdateResponse>;
    update(id: number, formData: IScheduleFormValues): Promise<ScheduleUpdateResponse>;
    delete(request: IScheduleDeleteRequest): Promise<EmptyResponseEntity>;
    confirmScheduleItem(id: number, confirmed: boolean): Promise<ScheduleSubConfirmResponse>;
    getLinks(id: number): Promise<LinkedItemsResponse>;
    breakLinks(scheduleId: number, linkIds: IBreakLinkRequest[]): Promise<LinkedItemsResponse>;
    sendReminder(id: number, formData: IScheduleFormValues): Promise<SendReminderResponse>;
    getShifts(id: number, filterValue: number): Promise<Shift[]>;
    updateShiftReason(
        shiftId: number,
        reasonId: number,
        comments: string,
        shouldCopyToIndirectShifts: boolean
    ): Promise<UpdateShiftReasonResponse>;
    getPendingNotifications(
        jobId: number,
        scheduleIds?: number[],
        onlyChanged?: boolean
    ): Promise<PendingNotificationsResponse>;
    ignoreConflicts(id: number, jobId: number): Promise<IgnoreConflictsResponse>;
    confirmDeclineManually(
        id: number,
        userId: number,
        userType: BTLoginTypes,
        confirmed: boolean
    ): Promise<ManuallyConfirmDeclineResponse>;
    getUsersWithoutJobAccess(
        id: number,
        jobId: number,
        assignedUsers: number[]
    ): Promise<UsersWithoutJobAccessResponse>;
}

export class ScheduleHandler implements IScheduleHandler {
    private static recalculateRequest: IAPIHandlerResult<RecalculateScheduleResponse> | undefined;

    async get(id: number): Promise<ScheduleEntity> {
        return await APIHandler(`/api/calendar/${id}`, {
            method: "GET",
            responseType: ScheduleEntity,
        });
    }

    async getDefault(
        jobId: number,
        entityDefaultParams?: ScheduleEntityDefaultParams
    ): Promise<ScheduleEntity> {
        let defaultOptions;
        if (entityDefaultParams) {
            defaultOptions = {
                ...entityDefaultParams,
                startDate: entityDefaultParams.startDate && entityDefaultParams.startDate,
                endDate: entityDefaultParams.endDate && entityDefaultParams.endDate,
            };
        }

        const data = {
            jobId,
            ...defaultOptions,
        };

        return await APIHandler(`/api/calendar/DefaultInfo`, {
            method: "GET",
            data,
            responseType: ScheduleEntity,
        });
    }

    async recalculate(
        data: RecalculateScheduleRequest
    ): Promise<RecalculateScheduleResponse | undefined> {
        if (ScheduleHandler.recalculateRequest) {
            ScheduleHandler.recalculateRequest.cancel();
        }
        ScheduleHandler.recalculateRequest = APIHandler(`/api/calendar/Recalculate`, {
            method: "POST",
            data,
            responseType: RecalculateScheduleResponse,
            version: APIHandlerVersion.cancellable,
            throwExceptionOnAbort: false,
        });
        const resp = await ScheduleHandler.recalculateRequest.response;
        if (resp) {
            ScheduleHandler.recalculateRequest = undefined;
        }
        return resp;
    }

    async create(jobId: number, newValues: IScheduleFormValues): Promise<ScheduleUpdateResponse> {
        return await APIHandler(`/api/calendar?jobId=${jobId}`, {
            method: "POST",
            data: mapFormDataForUpdate(newValues),
            responseType: ScheduleUpdateResponse,
        });
    }

    async update(id: number, formData: IScheduleFormValues): Promise<ScheduleUpdateResponse> {
        return await APIHandler(`/api/calendar/${id}`, {
            method: "PUT",
            data: mapFormDataForUpdate(formData),
            responseType: ScheduleUpdateResponse,
        });
    }

    async delete(request: IScheduleDeleteRequest): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/calendar/ScheduleItem", {
            data: request,
            method: "DELETE",
            responseType: EmptyResponseEntity,
        });
    }

    async confirmScheduleItem(
        id: number,
        confirmed: boolean,
        subId: number = -1
    ): Promise<ScheduleSubConfirmResponse> {
        return await APIHandler(`/api/calendar/${id}/confirmed`, {
            method: "POST",
            data: { confirmed, subId },
            responseType: ScheduleSubConfirmResponse,
        });
    }

    async getLinks(id: number): Promise<LinkedItemsResponse> {
        return await APIHandler(`/api/calendar/${id}/LinkList`, {
            method: "GET",
            responseType: LinkedItemsResponse,
        });
    }

    async breakLinks(id: number, linkIds: IBreakLinkRequest[]): Promise<LinkedItemsResponse> {
        return await APIHandler(`/api/calendar/BreakLinks`, {
            method: "POST",
            data: { scheduleId: id, links: linkIds },
            responseType: LinkedItemsResponse,
        });
    }

    async sendReminder(id: number, formData: IScheduleFormValues): Promise<SendReminderResponse> {
        const data = {
            notifyAssignees: formData.currentUsersToNotify,
            requireConfirmation: formData.requireConfirmation,
            comments: formData.notificationDetails,
        };
        return await APIHandler(`/api/calendar/${id}/Reminder`, {
            method: "POST",
            data,
            responseType: SendReminderResponse,
        });
    }

    async getShifts(id: number, filterValue: number): Promise<Shift[]> {
        let response = await APIHandler(`/api/calendar/${id}?shiftFilter=${filterValue}`, {
            method: "GET",
            responseType: ScheduleEntity,
        });
        return response.shiftList;
    }

    async updateShiftReason(
        shiftId: number,
        reasonId: number,
        comments: string,
        shouldCopyToIndirectShifts: boolean
    ): Promise<UpdateShiftReasonResponse> {
        const data = {
            shiftId,
            reason: reasonId,
            shiftComments: comments,
            shouldUpdateIndirectShifts: shouldCopyToIndirectShifts,
        };
        return await APIHandler(`/api/calendar/updateShiftReason`, {
            method: "PUT",
            responseType: UpdateShiftReasonResponse,
            data,
        });
    }

    async getPendingNotifications(
        jobId: number,
        scheduleIds?: number[],
        onlyChanged?: boolean
    ): Promise<PendingNotificationsResponse> {
        return await APIHandler(`/api/calendar/PendingNotifications`, {
            method: "GET",
            responseType: PendingNotificationsResponse,
            data: { jobId, scheduleIds, onlyChanged },
        });
    }

    async ignoreConflicts(id: number, jobId: number): Promise<IgnoreConflictsResponse> {
        return await APIHandler("/api/Calendar/HideConflicts", {
            method: "PUT",
            data: { scheduleItemId: id, jobId },
            responseType: IgnoreConflictsResponse,
        });
    }

    async confirmDeclineManually(
        id: number,
        userId: number,
        userType: BTLoginTypes,
        confirmed: boolean
    ): Promise<ManuallyConfirmDeclineResponse> {
        const isBuilder = userType === BTLoginTypes.BUILDER;
        const data = isBuilder ? { userId, confirmed } : { subId: userId, confirmed };
        const endpoint = isBuilder ? "BuilderConfirmed" : "Confirmed";
        return await APIHandler(`/api/calendar/${endpoint}?id=${id}`, {
            method: "POST",
            data,
            responseType: ManuallyConfirmDeclineResponse,
        });
    }

    async getUsersWithoutJobAccess(
        id: number,
        jobId: number,
        assignedUsers: number[]
    ): Promise<UsersWithoutJobAccessResponse> {
        return await APIHandler(`/api/Calendar/UsersWithoutJobAccess`, {
            method: "POST",
            data: {
                scheduleId: id,
                jobId,
                assignedUsers,
            },
            responseType: UsersWithoutJobAccessResponse,
        });
    }
}

const mapFormDataForUpdate = (values: IScheduleFormValues) => {
    return {
        ...values,
        predecessors: values.predecessors.filter((p) => p.scheduleItem),
        workdayExceptions: undefined,
        completed: values.isComplete,
        color: getColorIdFromHex(values.color),
        phase: values.phaseId,
        tags: values.tagIds,
        notify: {
            notifyNewAssignees: values.notifyAddedUsers ? values.addedUsersToNotify : [],
            notifyCurrentAssignees: values.notifyCurrentUsers ? values.currentUsersToNotify : [],
            notifyRemovedAssignees: values.notifyRemovedUsers ? values.removedUsersToNotify : [],
            notifyLinked: values.notifyAffectedUsers,
            notifyDays: values.notifyAffectedUsersDaysOut,
        },
    };
};
