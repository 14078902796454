import { btInfo } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";

export const marketplaceAdminDialog = () => {
    btInfo({
        title: "Contact Your Administrator",
        content: (
            <>
                <BTTitle level={3}>
                    Only users with "Full Admin" permissions can add this feature.
                </BTTitle>
                <BTTitle level={3}>Contact your admin to add this feature.</BTTitle>
            </>
        ),
        onOk() {},
    });
};
