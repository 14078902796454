import { ColumnGroupType, ColumnType } from "antd/lib/table";

export type BTColumnsType<T> = (IBTColumnGroupType<T> | IBTColumnType<T>)[];

export interface IBTColumnType<T> extends ColumnType<T> {
    resizeable?: boolean;
    minWidth?: number;
}

export interface IBTColumnGroupType<T> extends Omit<ColumnGroupType<T>, "children"> {
    children: BTColumnsType<T>;
    resizeable?: boolean;
    minWidth?: number;
}

export const BufferColumnKey = -1;
