import classNames from "classnames";
import { useContext, useEffect } from "react";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { queryClient } from "helpers/queryClient";

import { BTLocalStorage } from "types/btStorage";
import { MenuItemName } from "types/enum";

import { routes } from "utilities/routes";

import { BTBadge } from "commonComponents/btWrappers/BTBadge/BTBadge";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTIconCloseOutlined,
    BTIconHelp,
    BTIconLiveChat,
    BTIconNotifications,
    BTIconSearch,
    BTIconUsers,
} from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTUser } from "commonComponents/btWrappers/BTUser/BTUser";
import { MainNavDropdown } from "commonComponents/utilities/MainNavigation/common/MainNavDropdown";
import { GlobalSearch } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch";
import { IMainNavActionItemsProps } from "commonComponents/utilities/MainNavigation/MainNavActionItems/MainNavActionItems.types";
import {
    ChatReadQueryKey,
    useGetChatQuery,
    useGetNotificationCountQuery,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.handler";
import { menuItemClass } from "commonComponents/utilities/MainNavigation/MainNavigation.types";
import { ExperimentPersonalizedQuickAdd } from "commonComponents/utilities/MainNavigation/MainNavQuickAddExperiment";

import { NotificationPanel } from "entity/notification/NotificationPanel/NotificationPanel";
import { NotificationPanelHandler } from "entity/notification/NotificationPanel/NotificationPanel.api.handler";

export const MainNavActionItems: React.FC<
    IMainNavActionItemsProps & React.HTMLAttributes<HTMLDivElement>
> = ({
    className,
    userId,
    navData,
    isTemplateMode,
    selectedMenuItem,
    subMenuProps,
    handler,
    userInfoContext,
    handleShowSearchInput,
    handleHideSearchInput,
    ...props
}) => {
    const userInfo = useRequiredContext(UserInfoContext);
    const appDefaultInfo = useContext(AppDefaultInfoContext);
    const { headerStyleInfo, isSearchEnabled } = navData;
    const allowSearch = isSearchEnabled && !isTemplateMode;

    const { data: unreadCount } = useGetNotificationCountQuery(
        handler,
        appDefaultInfo?.unreadNotificationCountCacheTimeout ?? 300
    );

    const getNotificationMenuItem = () => {
        return (
            <div className={menuItemClass}>
                <NotificationPanel
                    userId={userId}
                    unreadCount={unreadCount}
                    notificationHandler={new NotificationPanelHandler()}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                />
            </div>
        );
    };

    const isReadQueryEnabled =
        !userInfo.isBuilderImpersonatingOwner &&
        (navData?.isChatEnabled ?? false) === true &&
        selectedMenuItem !== MenuItemName.Chat;

    const {
        data: hasUnreadChatData,
        isFetching: isFetchingChatReadData,
        refetch: refetchChatReadData,
    } = useGetChatQuery(handler, appDefaultInfo?.chatMainNavIndicatorPollingRateInSeconds ?? 60, {
        enabled: isReadQueryEnabled,
    });

    const hasUnreadChat = isReadQueryEnabled && hasUnreadChatData?.hasUnread;
    const hasUnreadNotification = (unreadCount ?? 0) > 0;

    useEffect(() => {
        (async () => {
            const hasUnreadData = BTLocalStorage.get("bt-boolean-hasUnreadChat");
            if (!isFetchingChatReadData && hasUnreadData === null) {
                await queryClient.invalidateQueries([ChatReadQueryKey]);
            }
        })();
    }, [isFetchingChatReadData, refetchChatReadData]);
    return (
        <div className={classNames("MainNavActionItems", className)} {...props}>
            <div className="MainNavDropdownsRow--actions-wrap">
                <div
                    key="globalSearch"
                    className={classNames(
                        "menu-item-no-hover",
                        "MenuItem-SearchBar",
                        "GlobalSearch-SearchBar"
                    )}
                >
                    <div
                        className={classNames("MenuItem-SearchBar--search-wrap")}
                        style={{
                            backgroundColor: headerStyleInfo.color,
                        }}
                    >
                        {allowSearch && (
                            <GlobalSearch
                                searchRequestedPage={selectedMenuItem}
                                builderIdUserIdLookup={navData.builderIdUserIdLookup}
                                handleHideSearchInput={handleHideSearchInput}
                            />
                        )}
                        <BTButton
                            className="MainNavDropdown MainNavDropdown-icon-only MainNavDropdown-search-close"
                            data-testid="search-close-main-nav-button"
                            aria-label="Search"
                            onClick={handleHideSearchInput}
                            noShadow
                        >
                            <BTIconCloseOutlined className="MainNavDropdown-search-close--icon" />
                        </BTButton>
                    </div>
                    {allowSearch && (
                        <BTButton
                            className="MainNavDropdown MainNavDropdown-icon-only MainNavDropdown-search-action"
                            data-testid="search-main-nav-button"
                            aria-label="Search"
                            onClick={handleShowSearchInput}
                            noShadow
                        >
                            <BTIconSearch className="MainNavDropdown-search-action--icon" />
                        </BTButton>
                    )}
                </div>
                <ExperimentPersonalizedQuickAdd {...subMenuProps} />

                <MainNavDropdown
                    {...subMenuProps}
                    intercomTarget="Notification Icon"
                    itemKey="notifications"
                    data-testid="notifications-main-nav-button"
                    aria-label="Notifications Menu"
                    destroyPopupOnHide={true}
                    customMenuContent={getNotificationMenuItem()}
                    className="MainNavDropdown-icon-only"
                >
                    <MainNavBadge dot={hasUnreadNotification}>
                        <BTIconNotifications />
                    </MainNavBadge>
                </MainNavDropdown>

                {navData.isChatEnabled && (
                    <BTLink useAutoSPARouting to={routes.chat.getListLink()}>
                        <BTButton
                            className="MainNavDropdown MainNavDropdown-icon-only"
                            data-testid="chat-main-nav-button"
                            aria-label="Chat Button"
                            noShadow
                        >
                            <MainNavBadge dot={hasUnreadChat}>
                                <BTIconLiveChat />
                            </MainNavBadge>
                        </BTButton>
                    </BTLink>
                )}

                <MainNavDropdown
                    {...subMenuProps}
                    itemKey="users"
                    data-testid="users-main-nav-button"
                    aria-label="Users Menu"
                    className="MainNavDropdown-icon-only"
                >
                    <BTIconUsers isOnDark />
                </MainNavDropdown>

                <MainNavDropdown
                    {...subMenuProps}
                    itemKey="help"
                    data-testid="help-main-nav-button"
                    aria-label="Help Menu"
                    className="MainNavDropdown-icon-only"
                >
                    <BTIconHelp />
                </MainNavDropdown>

                <MainNavDropdown
                    {...subMenuProps}
                    itemKey="setup"
                    data-testid="settings-main-nav-button"
                    aria-label="Settings Menu"
                    className="MainNavDropdown-icon-only"
                >
                    <BTUser
                        size="small"
                        displayName={userInfoContext?.fullName || ""}
                        globalUserId={userInfoContext?.globalUserId || -1}
                        contactCard="none"
                    />
                </MainNavDropdown>
            </div>
        </div>
    );
};

const MainNavBadge: React.FunctionComponent<{ count?: number; dot?: boolean }> = (props) => {
    return (
        <BTBadge count={props.count} dot={props.dot} offset={[-5, 0]}>
            {props.children}
        </BTBadge>
    );
};
