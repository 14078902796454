import { ComponentType, useEffect, useMemo, useState } from "react";
import { Route, RouteComponentProps } from "react-router-dom";

import { AppProvider } from "helpers/AppProvider";
import { AppProviderHandler, defaultAppProviderHandler } from "helpers/AppProvider.api.handler";
import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";

import { track } from "utilities/analytics/analytics";

import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";

interface IWebviewPageLayoutProps<P extends { [K in keyof P]?: string } = {}> {
    component: ComponentType<RouteComponentProps<P>>;
    path: string;
    exact?: boolean;
    appProviderHandler?: AppProviderHandler;
}

const WebviewPageLayout = <P extends {}>({
    component: Component,
    appProviderHandler = defaultAppProviderHandler,
    ...rest
}: IWebviewPageLayoutProps<P>) => {
    const [origNavHeight, setOrigNavHeight] = useState<string>("");

    useEffect(() => {
        const currentNavHeight = document.documentElement.style.getPropertyValue(
            "--main-navigation-height"
        );
        setOrigNavHeight(currentNavHeight);
        document.documentElement.style.setProperty("--main-navigation-height", "0px");

        return () => {
            document.documentElement.style.setProperty("--main-navigation-height", origNavHeight);
        };
    }, [origNavHeight]);

    const spaInfoValue = useMemo(() => ({ isSpa: true }), []);

    return (
        <AppProvider handler={appProviderHandler}>
            <FocusProvider shareFocusWithParent>
                <SpaInfoContext.Provider value={spaInfoValue}>
                    <Route
                        {...rest}
                        render={(matchProps) => {
                            if (matchProps.match.params.jobId !== undefined) {
                                return (
                                    <TrackedComponent
                                        jobId={parseInt(matchProps.match.params.jobId)}
                                        component={Component}
                                        {...matchProps}
                                    />
                                );
                            }
                            return <Component {...matchProps} />;
                        }}
                    />
                </SpaInfoContext.Provider>
            </FocusProvider>
        </AppProvider>
    );
};

interface ITrackedComponentProps<P extends { [K in keyof P]?: string } = {}>
    extends RouteComponentProps<P> {
    jobId: number;
    component: ComponentType<RouteComponentProps<P>>;
}

const TrackedComponent = <P extends {}>({
    component: Component,
    jobId,
    ...otherProps
}: ITrackedComponentProps<P>) => {
    useEffect(() => {
        track({ jobId });
    }, [jobId]);

    return <Component {...otherProps} />;
};

export default WebviewPageLayout;
