import classNames from "classnames";
import moment from "moment";
import { ComponentProps, useCallback, useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps } from "react-router";

import { BdsBtLogo, BdsButton, BdsIcon } from "@buildertrend/components";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { queryClient } from "helpers/queryClient";

import { BTLocalStorage, LocalStorageKeys } from "types/btStorage";
import { BTLoginTypes, MenuItemName } from "types/enum";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCaretSmallDown, BTIconList } from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { RouteJob } from "commonComponents/entity/job/RouteJob/RouteJob";
import { BTOverflow } from "commonComponents/utilities/BTOverflow/BTOverflow";
import { ClientObservableBackgroundJobStatusPopup } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup";
import { MainNavDropdown } from "commonComponents/utilities/MainNavigation/common/MainNavDropdown";
import { MainNavActionItems } from "commonComponents/utilities/MainNavigation/MainNavActionItems/MainNavActionItems";
import {
    ChatReadQueryKey,
    IMainNavigationHandler,
    MainNavigationHandler,
    NotificationCountQueryKey,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.handler";
import {
    HeaderStyleInfo,
    IRiskInsurance,
    MainNavigationResponse,
    NavMenuItemResponse,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";
import {
    IBTSubMenuItemProps,
    IMenuTabInfo,
    MenuInfo,
    MenuOnClickTypes,
} from "commonComponents/utilities/MainNavigation/MainNavigation.types";
import { MainNavRolledMenuItems } from "commonComponents/utilities/MainNavigation/MainNavRolledMenuItems/MainNavRolledMenuItems";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";
import { MainNavigationStickyHeaderHeight } from "commonComponents/utilities/StickyContext";

import { RouteBidPackage } from "entity/bidPackage/BidPackage/BidPackageRoute";
import { RouteBill } from "entity/bill/Bill/RouteBill";
import { RouteChangeOrder } from "entity/changeOrder/ChangeOrder/ChangeOrderRoute";
import { RouteContact } from "entity/contact/Contact/RouteContact";
import { RouteContactUs } from "entity/ContactUs/RouteContactUs";
import { RouteSuccessPlanList } from "entity/customerTrainingPlan/SuccessPlanList/RouteSuccessPlanList";
import { RouteDailyLog } from "entity/dailyLog/DailyLog/DailyLogRoute";
import { RouteInternalUser } from "entity/internalUser/InternalUserDetails/RouteInternalUser";
import { RouteInternalUserRoleDetails } from "entity/internalUser/InternalUserRoleDetails/RouteInternalUserRoleDetails";
import { RouteInternalUserRolesList } from "entity/internalUser/InternalUserRoleSettings/InternalUserRoleList/RouteInternalUserRolesList";
import { JobDetailTab } from "entity/job/Job.api.types";
import { RouteJobPriceSummary } from "entity/job/JobPriceSummary/RouteJobPriceSummary";
import { RouteLead } from "entity/lead/Lead/LeadRoute";
import { RouteLeadActivity } from "entity/lead/LeadActivity/LeadActivityRoute";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { NotificationPanelRoutes } from "entity/notification/NotificationPanel/NotificationPanelRoutes";
import { RouteOwnerInvoice } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoiceRoute";
import { RouteOwnerSetup } from "entity/OwnerSetup/RouteOwnerSetup";
import { RoutePurchaseOrder } from "entity/purchaseOrder/PurchaseOrder/PurchaseOrderRoute";
import { RouteUploadReceipt } from "entity/receipt/ReceiptUpload/RouteUploadReceipt";
import { RouteRfi } from "entity/rfi/Rfi/RfiRoute";
import { RouteSchedule } from "entity/schedule/Schedule/RouteSchedule";
import { RouteSelection } from "entity/selection/Selection/RouteSelection";
import { RouteSpecification } from "entity/specification/SpecificationRoute";
import { RouteSubSetup } from "entity/sub/SubSetup/RouteSubSetup";
import { RouteSubVendor } from "entity/sub/SubVendor/RouteSubVendor";
import { RouteSubscriptionManager } from "entity/SubscriptionManager/SubscriptionManagerRoute";
import { RouteTemplate } from "entity/template/Template/RouteTemplate";
import { RouteTemplateFromTemplate } from "entity/template/TemplateFromTemplate/RouteTemplateFromTemplate";
import { RouteShift } from "entity/timeClock/Shift/RouteShift";
import { RouteToDo } from "entity/toDo/details/ToDoDetails/RouteToDo";
import { RouteWarranty } from "entity/warranty/Warranty/RouteWarranty";

import "./MainNavigation.less";

interface IMainNavigationProps extends RouteComponentProps, ITrackingProp {
    className?: string;
    handler?: IMainNavigationHandler;
    menuInfo?: MenuInfo;
    builderId: number;
    userId: number;
    jobId: number;
    selectedMenuItem?: MenuItemName;
    fromReact: boolean;
    isTemplateMode: boolean;
    loginType: BTLoginTypes;
    riskInsurance?: IRiskInsurance;
}

const defaultMainNavigationHandler = new MainNavigationHandler();
const defaultMenuInfo = new MenuInfo();

const getMenuItems = (
    subMenuProps: Omit<IBTSubMenuItemProps, "itemKey">,
    isTemplateMode: boolean
) => {
    return [
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="sales"
                data-testid="sales-main-nav-button"
                aria-label="Sales Menu"
            >
                {subMenuProps.mainNavResponse.sales.title} <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="job"
                data-testid="job-main-nav-button"
                aria-label="Job Menu"
            >
                {isTemplateMode ? "Templates" : subMenuProps.mainNavResponse.job.title}{" "}
                <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="projectManagement"
                data-testid="project-management-main-nav-button"
                aria-label="Project Management Menu"
            >
                {subMenuProps.mainNavResponse.projectManagement.title} <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="files"
                data-testid="files-main-nav-button"
                aria-label="Files Menu"
            >
                {subMenuProps.mainNavResponse.files.title} <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="messaging"
                data-testid="messaging-main-nav-button"
                aria-label="Messaging Menu"
            >
                {subMenuProps.mainNavResponse.messaging.title} <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="financial"
                data-testid="financial-main-nav-button"
                aria-label="Financial Menu"
            >
                {subMenuProps.mainNavResponse.financial.title} <MainNavCaret />
            </MainNavDropdown>
        ),
        () => (
            <MainNavDropdown
                {...subMenuProps}
                itemKey="reports"
                data-testid="reports-main-nav-button"
                aria-label="Reports Button"
            />
        ),
    ];
};

const HomeOwnerPreviewBanner = () => {
    return (
        <BTAlert
            type="warning"
            showIcon
            icon={<BdsIcon iconName="ExclamationCircle" size="lg" />}
            data-testid="homeownerPreviewAlert"
            className="HomeownerPreviewAlert"
            message="You're currently previewing the homeowner view"
            action={
                <BdsButton
                    testid="exitHomeownerPreview"
                    className="ExitHomeownerPreviewButton"
                    displayType="secondary"
                    onClick={() => {
                        window.close();
                    }}
                    text="Exit homeowner preview"
                />
            }
        />
    );
};

const getHomeButton = (loginType: BTLoginTypes) => {
    let homeButton;

    if (loginType === BTLoginTypes.OWNER || loginType === BTLoginTypes.CONTACT) {
        const homeRoute = routes.ownerSummary.getLink();

        const linkProps: ComponentProps<typeof BTLink> = {
            to: homeRoute,
            useAutoSPARouting: true,
        };

        homeButton = (
            <BTLink {...linkProps}>
                <BTButton
                    className="MainNavDropdown MainNavDropdown-icon-only"
                    data-testid="home-main-nav-button"
                    aria-label="Home Button"
                    noShadow
                    isolated
                    underline={false}
                >
                    <BdsBtLogo autoSize />
                </BTButton>
            </BTLink>
        );
    }
    return homeButton;
};

interface IMenuItemsProps extends IMainNavigationProps {
    setMainNavigationRef: (node: HTMLDivElement) => void;
    headerStyleInfo: HeaderStyleInfo;
    isSmallWidth: boolean;
    data: MainNavigationResponse;
}

const MainNavigationInternal = (props: IMenuItemsProps) => {
    const {
        setMainNavigationRef,
        headerStyleInfo,
        isSmallWidth,
        data,
        loginType,
        selectedMenuItem,
        jobId,
        isTemplateMode,
        builderId,
        userId,
        fromReact,
        tracking,
        handler = defaultMainNavigationHandler,
        menuInfo = defaultMenuInfo,
    } = props;

    const appDefaultInfo = useContext(AppDefaultInfoContext);

    const [showSearchInput, setShowSearchInput] = useState(false);
    const [menuOverflowOpen, setMenuOverflowOpen] = useState(false);

    const envInfo = useContext(EnvironmentInfoContext);
    const builderInfo = useRequiredContext(BuilderInfoContext);
    const userInfo = useRequiredContext(UserInfoContext);

    const handleShowSearchInput = useCallback(() => {
        setShowSearchInput(true);
    }, []);

    const handleHideSearchInput = useCallback(() => {
        setShowSearchInput(false);
    }, []);
    const handleShowDrawer = useCallback(() => {
        setMenuOverflowOpen(true);
    }, []);
    const handleOnClose = useCallback(() => {
        setMenuOverflowOpen(false);
    }, []);

    const handleItemClick = useCallback(
        (key: string, isExternalLink?: boolean) => {
            tracking?.trackEvent({
                event: "MenuItemClick",
                element: "Navigation Menu Item",
                uniqueId: key,
                extraInfo: isExternalLink ? { menuType: "External Link" } : undefined,
            });
        },
        [tracking]
    );

    const handleContentClick = useCallback(
        (info: IMenuTabInfo, navMenuItem: NavMenuItemResponse) => {
            if (info.onClick) {
                info.onClick(
                    { userId, builderId, jobId, fromReact, isTemplateMode, builderInfo },
                    data,
                    {
                        location: props.location,
                        match: props.match,
                        history: props.history,
                    }
                );
            }
            handleItemClick(
                navMenuItem.title,
                info.onClickType?.(builderInfo, userInfo) === MenuOnClickTypes.External
            );
        },
        [
            handleItemClick,
            builderInfo,
            userInfo,
            userId,
            builderId,
            jobId,
            fromReact,
            isTemplateMode,
            data,
            props.location,
            props.match,
            props.history,
        ]
    );

    const subMenuProps: Omit<IBTSubMenuItemProps, "itemKey"> = {
        data: data,
        menuInfo: menuInfo,
        selectedMenuItem: selectedMenuItem!,
        jobId,
        isTemplateMode,
        loginType,
        onContentClick: handleContentClick,
        routeInfo: {
            userId,
            builderId,
            jobId,
            fromReact,
            isTemplateMode,
        },
        mainNavResponse: data,
        builderInfo,
        userInfo,
        appDefaultInfo,
        envInfo,
        onItemClick: handleItemClick,
    };

    const menuItems = getMenuItems(subMenuProps, isTemplateMode);

    const menuActionItem = () => {
        return (
            <MainNavActionItems
                navData={data}
                isTemplateMode={isTemplateMode}
                userId={userId}
                handler={handler}
                subMenuProps={subMenuProps}
                handleShowSearchInput={handleShowSearchInput}
                handleHideSearchInput={handleHideSearchInput}
                userInfoContext={userInfo}
                history={props.history}
                location={props.location}
                match={props.match}
                selectedMenuItem={selectedMenuItem}
            />
        );
    };

    const handleDialogClose = useCallback(() => {
        props.history.replace(props.match.url);
    }, [props.match, props.history]);

    /**
     * @deprecated this will cause the full page to reload. Instead you should make the specific calls needed to reload stale data
     */
    const handleDialogCloseRefreshPage = useCallback(async () => {
        handleDialogClose();
        // eslint-disable-next-line deprecate/member-expression
        routesWebforms.refreshPage();
    }, [handleDialogClose]);

    const legacyModalConfigReloadPage = {
        parentRoute: props.match.url,
        beforeClose: handleDialogCloseRefreshPage,
    };

    const modalConfigReloadPageQuickAction = {
        parentRoute: props.match.url + "/QuickAction",
        beforeClose: handleDialogCloseRefreshPage,
    };

    const modalConfigReloadPage = {
        parentRoute: props.match.url,
        beforeClose: handleDialogCloseRefreshPage,
    };

    const modalConfigNoReloadPage = {
        parentRoute: props.match.url + "/QuickAction",
        beforeClose: handleDialogClose,
    };

    return (
        <>
            {builderInfo.flags.homeownerImpersonationBanner &&
                userInfo.isBuilderImpersonatingOwner && <HomeOwnerPreviewBanner />}
            {envInfo && !envInfo.isProduction && envInfo.name !== "Production" && (
                <div style={{ background: envInfo.color }} className="environment-title">
                    {envInfo.name}
                </div>
            )}
            <div
                ref={setMainNavigationRef}
                style={{
                    backgroundColor: headerStyleInfo.color,
                }}
                className={classNames("MainNavDropdownsRow", props.className, {
                    darken: headerStyleInfo.darken,
                    lighten: !headerStyleInfo.darken,
                    "MainNavDropdownsRow-show-search-input": showSearchInput,
                    OwnerPreview:
                        userInfo.isBuilderImpersonatingOwner &&
                        builderInfo.flags.homeownerImpersonationBanner,
                })}
            >
                {!isSmallWidth && (
                    <>
                        {getHomeButton(loginType)}
                        <BTOverflow
                            style={{
                                width: "100%",
                            }}
                            renderItem={(item) => item()}
                            renderRest={(rolledUpItems) => (
                                <>
                                    <BTButton
                                        data-testid="responsive-menu-overflow"
                                        onClick={handleShowDrawer}
                                        className="MainNavDropdown"
                                    >
                                        More <MainNavCaret />
                                    </BTButton>

                                    <MainNavRolledMenuItems
                                        handleCloseRollupMenu={handleOnClose}
                                        isRollupMenuOpen={menuOverflowOpen}
                                        rolledUpItems={rolledUpItems}
                                    />
                                </>
                            )}
                            data={menuItems}
                            suffix={menuActionItem()}
                        />
                    </>
                )}
                {isSmallWidth && (
                    <>
                        <BTRow responsiveMode="viewport" wrap={false}>
                            <BTButton
                                data-testid="responsive-menu-overflow"
                                onClick={handleShowDrawer}
                                className="MainNavDropdown MainNavDropdown-icon-only"
                                style={{
                                    display: "block",
                                }}
                            >
                                <BTIconList />
                            </BTButton>
                            {getHomeButton(loginType)}
                        </BTRow>
                        <MainNavRolledMenuItems
                            handleCloseRollupMenu={handleOnClose}
                            isRollupMenuOpen={menuOverflowOpen}
                            rolledUpItems={menuItems}
                        />
                        {menuActionItem()}
                    </>
                )}
            </div>
            <Route render={(routeProps) => <NotificationPanelRoutes {...routeProps} />} />
            <RouteRelative path="/QuickAction">
                {/* Routes for Quick Add Actions */}
                <RouteBidPackage modalConfig={modalConfigReloadPageQuickAction} />
                <RouteBill modalConfig={modalConfigReloadPageQuickAction} />
                <RouteChangeOrder modalConfig={modalConfigReloadPageQuickAction} />
                <RouteContact modalConfig={modalConfigReloadPageQuickAction} />
                <RouteDailyLog modalConfig={modalConfigReloadPageQuickAction} />
                <RouteInternalUser modalConfig={modalConfigReloadPageQuickAction} />
                <RouteInternalUserRolesList modalConfig={modalConfigReloadPageQuickAction} />
                <RouteInternalUserRoleDetails modalConfig={modalConfigReloadPageQuickAction} />
                <RouteJob
                    parentRoute={props.match.url + "/QuickAction"}
                    history={props.history}
                    beforeClose={handleDialogCloseRefreshPage}
                />
                <RouteLead modalConfig={modalConfigReloadPageQuickAction} />
                <RouteLeadActivity modalConfig={modalConfigReloadPageQuickAction} />
                <RouteMessageCompose modalConfig={modalConfigReloadPageQuickAction} />
                <RouteOwnerInvoice modalConfig={modalConfigReloadPageQuickAction} />
                <RoutePurchaseOrder modalConfig={modalConfigReloadPageQuickAction} />
                <RouteRfi modalConfig={modalConfigReloadPageQuickAction} />
                <RouteSchedule modalConfig={modalConfigReloadPageQuickAction} />
                <RouteSelection modalConfig={modalConfigReloadPageQuickAction} />
                <RouteSpecification modalConfig={modalConfigReloadPageQuickAction} />
                <RouteShift modalConfig={modalConfigReloadPageQuickAction} />
                <RouteSubVendor modalConfig={modalConfigReloadPageQuickAction} />
                <RouteTemplate modalConfig={modalConfigReloadPageQuickAction} />
                <RouteTemplateFromTemplate modalConfig={modalConfigReloadPageQuickAction} />
                <RouteToDo modalConfig={modalConfigReloadPageQuickAction} />
                <RouteUploadReceipt modalConfig={modalConfigReloadPageQuickAction} />
                <RouteWarranty modalConfig={modalConfigReloadPageQuickAction} />
            </RouteRelative>
            {/* OwnerSummary handles the routing for price summary for owners */}
            <ShowOnPortal
                builder
                subs
                render={() => {
                    return (
                        <RouteJobPriceSummary
                            modalConfig={{
                                parentRoute: props.match.url,
                                beforeClose: handleDialogClose,
                            }}
                        />
                    );
                }}
            />
            <RouteContactUs
                modalConfig={{
                    parentRoute: props.match.url,
                    beforeClose: handleDialogClose,
                }}
            />

            <RouteJob
                parentRoute="" /* RouteJob should be using RouteRelative*/
                history={props.history}
                beforeClose={handleDialogCloseRefreshPage}
            />

            {/* These routes are used for opening user detail pages so they do not conflict with the SPA version of page */}
            <RouteRelative path="/QuickSettings">
                <RouteInternalUser modalConfig={legacyModalConfigReloadPage} />
            </RouteRelative>

            {/* These two can be accessed through the quick actions OR
                the my user preferences menu item, and need routes for both */}
            <RouteInternalUserRolesList modalConfig={modalConfigReloadPage} />
            <RouteInternalUserRoleDetails modalConfig={modalConfigReloadPage} />

            <RouteSubSetup modalConfig={legacyModalConfigReloadPage} />
            <RouteOwnerSetup modalConfig={legacyModalConfigReloadPage} />

            <RouteSuccessPlanList modalConfig={modalConfigNoReloadPage} />

            <RouteSubscriptionManager modalConfig={modalConfigReloadPage} />

            <ShowOnPortal
                builder
                render={() => (
                    <div className="ProcessStatusPopupContainer">
                        <ClientObservableBackgroundJobStatusPopup
                            history={props.history}
                            location={props.location}
                            match={props.match}
                        />
                    </div>
                )}
            />
        </>
    );
};

export const MainNavigation = track({ component: "Main Navigation" })(
    ({
        builderId,
        userId,
        jobId,
        selectedMenuItem,
        fromReact,
        isTemplateMode,
        loginType,
        riskInsurance,
        handler = defaultMainNavigationHandler,
        menuInfo = defaultMenuInfo,
        tracking,
        ...props
    }: IMainNavigationProps) => {
        const [data, setData] = useState<MainNavigationResponse | null>(null);
        const [isSmallWidth, setIsSmallWidth] = useState(false);
        const [navHeight, setNavHeight] = useState(MainNavigationStickyHeaderHeight);

        const [mainNavigationPopoverContainerRef, setMainNavigationRef] = useResizeObserver(() => {
            if (mainNavigationPopoverContainerRef.current) {
                setNavHeight(mainNavigationPopoverContainerRef.current.clientHeight);
                setIsSmallWidth(mainNavigationPopoverContainerRef.current.clientWidth <= 576);
            }
        });

        const elNavigationHeight = mainNavigationPopoverContainerRef.current?.clientHeight;

        // Set sticky context of main nav height as a base with state variable so it can be dynamically updated
        useStickyHeight(navHeight, "top", 0);

        const loadData = useCallback(async () => {
            try {
                // Fire off the API call to get the fresh one
                const resp = await handler.get();
                setData(resp);

                // Store the API response in local storage
                // eslint-disable-next-line no-restricted-globals
                localStorage.setItem(
                    LocalStorageKeys.MainNavigationData,
                    JSON.stringify(resp.toServiceObject())
                );
            } catch (e) {
                showAPIErrorMessage(e);
            }
            const count = BTLocalStorage.get("bt-number-unreadNotificationCount");
            if (count === null) {
                await queryClient.invalidateQueries([NotificationCountQueryKey]);
            }
        }, [handler]);

        useEffect(() => {
            // clear query data once they enter the chat page
            if (selectedMenuItem === MenuItemName.Chat) {
                queryClient.setQueryData([ChatReadQueryKey], {
                    hasUnread: false,
                    lastCheckedDateUtc: moment.utc(),
                });
                BTLocalStorage.set("bt-boolean-hasUnreadChat", false);
            }
        }, [selectedMenuItem]);

        useEffect(() => {
            // Observe nav height and adjust css variable and sticky context state usage
            // this is groundwork for a more responsive ui
            if (elNavigationHeight) {
                // If we have grabbed the height of the navigation update the css var and the nav height state
                // so that sticky context is updated as well
                document.documentElement.style.setProperty(
                    "--main-navigation-height",
                    `${elNavigationHeight}px`
                );
                setNavHeight(elNavigationHeight);
            }
        }, [elNavigationHeight]);

        useEffect(() => {
            (async () => {
                try {
                    // Load the main menu we have in local storage
                    // eslint-disable-next-line no-restricted-globals
                    const mainMenuString = localStorage.getItem(
                        LocalStorageKeys.MainNavigationData
                    );
                    if (mainMenuString) {
                        const data = new MainNavigationResponse(JSON.parse(mainMenuString));
                        if (data.builderId === builderId) {
                            // Skip cache if the builderId has changed
                            setData(data);
                        } else {
                            // Retrieve new unread count if builderId has changed
                            await queryClient.invalidateQueries([NotificationCountQueryKey]);
                        }
                    }
                } catch (e) {
                    // Fail deserialization error silently --- corrects after API call
                }
                if (riskInsurance) {
                    props.history.replace(
                        routes.job.getDetailsPath(
                            riskInsurance?.jobId,
                            false,
                            riskInsurance?.preapprovalId,
                            JobDetailTab.BRI
                        )
                    );
                }

                await loadData();
            })();
        }, [builderId, loadData, props.history, riskInsurance]);

        if (!data) {
            return null;
        }

        return (
            <>
                <MainNavigationInternal
                    setMainNavigationRef={setMainNavigationRef}
                    headerStyleInfo={data.headerStyleInfo}
                    data={data}
                    isSmallWidth={isSmallWidth}
                    builderId={builderId}
                    fromReact={fromReact}
                    jobId={jobId}
                    userId={userId}
                    isTemplateMode={isTemplateMode}
                    loginType={loginType}
                    handler={handler}
                    menuInfo={menuInfo}
                    {...props}
                />
            </>
        );
    }
);

const MainNavCaret: React.FunctionComponent = () => (
    <BTIconCaretSmallDown className="nav-bar-caret-down" />
);
