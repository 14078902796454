import { Typography } from "antd";
import { BaseType } from "antd/lib/typography/Base";
import classNames from "classnames";
import { PureComponent } from "react";

import "./BTTitle.less";

export interface IBTTitleProps {
    /**
     * Controls the level of importants of the title (1=most important, 4=least important)
     * @default 3
     * @note 1 = Rarely used, huge call to action text, mostly for marketing pages
     * @note 2 = Used for <PageSection> Title's
     * @note 3 = Used within <PageSection> content
     * @note 4 = Used within <p> and <Typography.Text> to help separate a paragraph of text
     */
    level?: 1 | 2 | 3 | 4;

    /**
     * Font weight of the title
     */
    bold?: boolean;

    ["data-testid"]?: string;

    /**
     * Stlying for the title (ex: error is red, success is green)
     */
    type?: "disabled" | "success" | "error" | "primary";

    style?: React.CSSProperties;

    className?: string;

    ellipsis?: boolean;
}

/**
 * Displays a title
 * note: when this component is within PrintPageLayout the font size/lineheight of headers is reduced
 */
export class BTTitle extends PureComponent<IBTTitleProps> {
    static defaultProps = {
        level: 3,
        bold: false,
        className: "",
        ellipsis: false,
    };

    render() {
        const { level, type, bold, style, ellipsis, className, children } = this.props;

        const titleType: undefined | BaseType = type === "error" ? "danger" : undefined;
        const disabled: boolean = type === "disabled";

        return (
            // eslint-disable-next-line react/forbid-elements
            <Typography.Title
                level={level}
                disabled={disabled}
                type={titleType}
                className={classNames(className, {
                    "ant-typography-success": type === "success",
                    "bt-title-primary": type === "primary",
                    "ant-typography-bold": bold,
                })}
                style={style}
                ellipsis={ellipsis}
                data-testid={this.props["data-testid"]}
            >
                {children}
            </Typography.Title>
        );
    }
}
