import queryString from "query-string";

import { ReplyType } from "types/enum";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IMessageComposeProps } from "entity/message/MessageCompose/MessageCompose";

const MessageCompose = lazyLoadWithRetry(() => import("./MessageCompose"));

type RouteMessageComposeProps = Omit<
    IMessageComposeProps,
    | "messageId"
    | "modalTitle"
    | "jobId"
    | "history"
    | "location"
    | "match"
    | "replyType"
    | "replyId"
    | "defaultRecipientId"
    | "defaultSubject"
    | "docInstanceIds"
>;

export const RouteMessageCompose = (props: RouteMessageComposeProps) => (
    <RouteRelative
        path={routes.messages.compose}
        render={(routeProps) => {
            const urlParams = routeProps.match.params;
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(routeProps.location.search);

            const attachOwnerProfile = qsValues.attachOwnerProfile;
            const messageId = parseInt(urlParams.messageId);
            const jobId = parseInt(urlParams.jobId);
            const replyType = qsValues.replyType
                ? (parseInt(qsValues.replyType) as ReplyType)
                : undefined;
            const replyId = qsValues.replyId ? parseInt(qsValues.replyId) : undefined;
            const defaultRecipientId = qsValues.defaultRecipientId
                ? parseInt(qsValues.defaultRecipientId)
                : 0;
            const defaultSubject = qsValues.defaultSubject;
            const docInstanceIds =
                qsValues.docInstanceIds?.split(",").map((str: string) => Number(str)) || [];

            let modalTitle = undefined;
            if (props.modalConfig && replyType) {
                modalTitle =
                    replyType === ReplyType.Forward ? "Forward a message" : "Reply to a message";
            }

            return (
                <BTSuspense>
                    <MessageCompose
                        {...props}
                        {...routeProps}
                        modalTitle={modalTitle}
                        messageId={messageId}
                        jobId={jobId}
                        replyId={replyId}
                        replyType={replyType}
                        defaultSubject={defaultSubject}
                        defaultRecipientId={defaultRecipientId}
                        docInstanceIds={docInstanceIds}
                        attachOwnerProfile={attachOwnerProfile}
                    />
                </BTSuspense>
            );
        }}
    />
);
