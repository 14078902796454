import { OnboardingHandler } from "utilities/onboarding/onboarding.api.handler";
import { UpdateOnboardingContactInfoRequest } from "utilities/onboarding/onboarding.api.types";
import {
    IOnboardingContactProvided,
    IOnboardingPlugin,
    OnboardingAction,
} from "utilities/onboarding/onboarding.types";

/**
 * Class for native BTNet onboarding action event logic
 */
export class BTOnboarding implements IOnboardingPlugin {
    actionCompleted(action: OnboardingAction, actionData: any) {
        switch (action as OnboardingAction) {
            case OnboardingAction.OnboardingContactProvided:
                this.ProcessOnboardingContactProvided(actionData);
                break;
        }
    }

    /**
     * Sends the provided actionData info to update the Onboarding Contact info in BTAdmin
     * @param actionData {@link IOnboardingContactProvided} Provided info for the Onboarding Contact
     */
    private ProcessOnboardingContactProvided = (actionData: IOnboardingContactProvided) => {
        if (!actionData) {
            return;
        }

        const requestData = new UpdateOnboardingContactInfoRequest(
            actionData.name,
            actionData.email,
            actionData.phone
        );

        const apiHandler = new OnboardingHandler();
        apiHandler.UpdateOnboardingContactInformation(requestData).catch((e) => {
            reportError(e);
        });
    };
}
