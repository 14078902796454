import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { debounce_input_delay, max_debounce_delay } from "types/constants";

import { isUnitTest } from "utilities/environment/environment";
import { KeyOfOrString } from "utilities/type/PropsOfType";

export interface IInputDebouncingContext {
    useDebouncing: boolean;
    inputDelay?: number;
    maxDelay?: number;
}

/**
 * This hook debounces the onChange callback. Used for storing a cached local state for a component.
 * This can substantially increase performance by only needing a rerender on the current component every change.
 * The parent component will be rerendered every @see {max_debounce_delay} milliseconds
 * @param value the components outside value (from props)
 * @param onChange callback to debounce, the wrapped/debounced version will be returned as debouncedChange. Use this in place of calls to onChange
 * @example
 * const [ debouncedValue, debouncedChange ] = useDebouncedOnChange(value, onChange);
 * <BTInput
 *     id={id}
 *     value={debouncedValue} // this will update every rerender
 *     onChange={(event) => debouncedChange(id, event.target.value)} // the call to this will be debounced
 * />
 */
export function useDebouncedOnChange<T, FormValues>(
    value: T,
    onChange: (id: any, value: any, ...restProps: any[]) => void,
    debouncingContext?: IInputDebouncingContext
) {
    const [debouncedValue, setDebouncedValue] = useState<T | undefined | null>(null);
    // Debounce callback
    const debounced = useDebouncedCallback(
        (id, value, ...rest) => {
            onChange(id, value, rest);
            setDebouncedValue(null);
        },
        (debouncingContext && debouncingContext.inputDelay) || debounce_input_delay,
        { maxWait: (debouncingContext && debouncingContext.maxDelay) ?? max_debounce_delay }
    );

    const debouncedChange = useCallback(
        function (
            id: KeyOfOrString<FormValues> | (KeyOfOrString<FormValues> & string),
            value: any,
            ...restProps: any[]
        ) {
            // do not debounce for unit tests or if context is set to not debounce
            if (isUnitTest() || (debouncingContext && !debouncingContext.useDebouncing)) {
                onChange(id, value, ...restProps);
            } else {
                setDebouncedValue(value);
                debounced.callback(id, value, ...restProps);
            }
        },
        [debounced, onChange, debouncingContext]
    );

    useEffect(() => {
        // Check if debounced is currently pending
        // If it is, call flush() to manually execute the callback
        // Necessary for when a component will potentially unmount before the debounce finishes (ex: Line Item Containers)
        return () => {
            if (debounced.pending()) {
                debounced.flush();
            }
        };
    }, [debounced]);

    if (debouncedValue === undefined) {
        // if debounced is undefined, it means the user actively is clearing
        // the associated field. It can't fall through to the normal return because that
        // would restore the value the user is trying to clear, so we need to explicitly
        // return only the debounced value (undefined).
        return [debouncedValue, debouncedChange] as const;
    }
    // if debouncedValue has a value, use that, otherwise fall back to
    // the last committed value
    return [debouncedValue ?? value, debouncedChange] as const;
}
