import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IBidProps } from "entity/bid/Bid/Bid";

const Bid = lazyLoadWithRetry(() => import("./Bid"));

export const RouteBid = (
    props: Omit<IBidProps, "id" | "jobId" | "history" | "location" | "match" | "builderId">
) => (
    <RouteRelative
        path={routes.bid.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Bid
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                        builderId={parseInt(routeProps.match.params.builderId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
