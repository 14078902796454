import {
    AttachedFiles,
    AttachedFilesRequest,
    BTFileSystem,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import moment, { Moment } from "moment";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";
import { DocumentInstanceType, ProposalGroupTypes, ProposalStatus } from "types/enum";

import yup from "utilities/form/yup";

import { AddressEntity } from "commonComponents/entity/address/Address/Address.api.types";
import {
    isLineItemGroupRow,
    isLineItemRow,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import {
    FormatItem,
    FormatItemList,
    isProposalCategory,
    isUnassignedCategory,
    LeadProposalLineItem,
    OptionMode,
    ProposalCategory,
    ProposalSubCategory,
    UnassignedCostGroupId,
    WorksheetLineItem,
} from "entity/estimate/common/estimate.common.types";
import { CostCodeExtraData } from "entity/estimate/Estimate/Estimate.api.types";
import { getLineItemEstimatesSchema } from "entity/estimate/EstimateLineItemContainer/EstimateLineItemDetails/EstimateLineItemDetails.api.types";
import { ProposalPrintOptions } from "entity/estimate/ProposalPrint/ProposalPrint.api.types";
import { TaxGroupEntity } from "entity/tax/TaxGroup/TaxGroup.api.types";
import { TaxRateEntity } from "entity/tax/TaxRate/TaxRate.api.types";

export const getFormatItemListFromApiCategories = (categoriesFromApi: ProposalCategory[]) => {
    let formatItems: FormatItemList = categoriesFromApi;

    const unassignedCategory = categoriesFromApi.find((c) => isUnassignedCategory(c));

    // Flatten/remove the unassigned category if it's the one and only category group
    if (unassignedCategory && categoriesFromApi.length === 1) {
        formatItems = unassignedCategory.items;
    }

    // Also filter out the unassigned category if it's empty
    if (unassignedCategory && unassignedCategory.items.length === 0) {
        formatItems = categoriesFromApi.filter((c) => c !== unassignedCategory);
    }

    return formatItems;
};

export enum ProposalTypes {
    JobProposal = 0,
    LeadProposal = 1,
    LeadProposalTemplate = 2,
}

export enum ProposalEntityNames {
    JobProposal = "Job Proposal",
}

export type ProposalFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "delete"
    | "releaseToOwner"
    | "unrelease"
    | "approve"
    | "decline"
    | "resend"
    | "print"
    | "reset"
    | "moveItems"
    | "recategorizeFormat";

export interface IProposalFormValues {
    title: string;
    approvalDeadline: moment.Moment | null;
    introductionText: string;
    closingText: string;
    attachments: AttachedFilesRequest;
    displayToOwner: boolean;
    columnsToDisplay: ProposalColumnTypes[];
    headerLayout: ProposalHeaderLayouts;
    bodyLayout: ProposalBodyLayouts;
    showOwnerContactInfo: boolean;
    showAddress: boolean;
    showPrintoutInfo: boolean;
    printoutType: ProposalPrintoutType;
    formatItems: FormatItemList;
    deletedCategories: number[];
    newCategoryId: number;
    files: BTFileSystem[]; // This files field is for holding files as they update without triggering a re-load to allow for new attachments on the proposal to show.
    requiredSignatureUsers: number[];
    requiredSignatureId?: number;
}

export interface IProposalSignatureObject {
    signaturePath?: string;
    signedById: number;
    signedByDate?: Moment;
    signedByName: string;
    approvalType: ProposalSignatureStatus;
    proposalRequestOwnerName: string;
    proposalRequestOrgLinkId: number;
    reason: string;
    isRequired: number;
}

export class ProposalSignatureObject implements IProposalSignatureObject {
    constructor(data: IProposalSignatureObject) {
        this.signaturePath = data.signaturePath;
        this.signedById = data.signedById;
        this.signedByDate = data.signedByDate ? moment(data.signedByDate) : undefined;
        this.signedByName = data.signedByName;
        this.approvalType = data.approvalType;
        this.proposalRequestOwnerName = data.proposalRequestOwnerName || "Owner";
        this.proposalRequestOrgLinkId = data.proposalRequestOrgLinkId;
        this.reason = data.reason;
        this.isRequired = data.isRequired;
    }

    signaturePath?: string;
    signedById: number;
    signedByDate?: Moment;
    signedByName: string;
    approvalType: ProposalSignatureStatus;
    proposalRequestOwnerName: string;
    proposalRequestOrgLinkId: number;
    reason: string;
    isRequired: number;
}

export enum ProposalSignatureStatus {
    Pending = 0,
    Approved = 1,
    Declined = 2,
    ManuallyApproved = 3,
    ManuallyDeclined = 4,
    UnreleasedApproved = 5,
    UnreleasedDeclined = 6,
}

export class ProposalEntity implements IProposalPreviewEntity {
    constructor(data: any) {
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.id = data.id;
        this.canEdit = data.canEdit;

        this.title = data.title;
        this.customerInfo = new CustomerInfo(data.jobInfo);
        this.status = new ProposalStatusObject(data.status);
        this.signatures = data.signatures
            ? data.signatures.map(
                  (signature: IProposalSignatureObject) => new ProposalSignatureObject(signature)
              )
            : [];

        this.canSign = data.canSign;
        this.currentOwnerName = data.currentOwnerName;
        this.isCurrentSigningOwnerRequired = data.isCurrentSigningOwnerRequired;

        this.approvalDeadline = data.approvalDeadline ? moment(data.approvalDeadline) : null;
        this.introductionText = data.introductionText;
        this.defaultIntroductionText = data.defaultIntroductionText;
        this.closingText = data.closingText;
        this.defaultClosingText = data.defaultClosingText;
        this.displayToOwner = data.displayToOwner;
        this.formatOptions = data.formatOptions;
        this.proposalPrintOptions = new ProposalPrintOptions(data.proposalPrintOptions);
        this.categories = data.categories.map((category: any) => {
            return new ProposalCategory(category, this.builderId, this.jobId, undefined);
        });
        this.columnsToDisplay = mapBTServiceDropdownToSelectItem(data.columnsToDisplay);
        this.attachedFiles = new AttachedFiles(data.attachments);
        this.name = "Job Proposal";
        this.documentInstanceType = DocumentInstanceType.Estimate;
        this.costCodes = data.costCodes.map(
            (code: any) =>
                new BTSelectItem<CostCodeExtraData>({
                    id: code.id,
                    name: code.name,
                    extraData: { costCategoryId: code.costCategoryId },
                })
        );
        this.builderInfo = new BuilderInfo(data.builderInfo);
        this.builderAddress = new AddressEntity(data.builderAddress);
        this.customerAddress = data.jobAddress ? new AddressEntity(data.jobAddress) : null;
        this.contactAddress = data.contactAddress ? new AddressEntity(data.contactAddress) : null;
        this.showContactAddress = data.showContactAddress;
        this.notificationText = data.notificationText;
        this.costItemUpdateMessage = data.costItemUpdateMessage;
        this.pdfFailureMessage = data.pdfFailureMessage;
        this.proposalType = ProposalTypes.JobProposal;
        this.disclaimer = data.disclaimer;
        this.hasOtherPendingProposal = undefined;
        this.isManuallyLocked = data.isManuallyLocked;
        this.currentDate = data.currentDate;
        this.groupType = data.proposalFormatGroupType;
        this.projectConnectedToTakeoffs = data.projectConnectedToTakeoffs;
        this.taxGroup = data.taxGroup;
        this.taxRates = this.taxGroup?.taxRates;

        this.signatureUsers = data.signatureUsers
            ? data.signatureUsers.map((item: BTSelectItem) => new BTSelectItem(item))
            : [];
        this.templateIdentifier = data.templateIdentifier;
    }

    id: number;
    jobId: number;
    builderId: number;
    canEdit: boolean;

    title: string;
    customerInfo: CustomerInfo;
    customerAddress: AddressEntity | null;
    contactAddress: AddressEntity | null;
    showContactAddress: boolean;
    builderInfo: BuilderInfo;
    builderAddress: AddressEntity;
    status: ProposalStatusObject;
    signatures: IProposalSignatureObject[];
    canSign: boolean;
    currentOwnerName: string;
    isCurrentSigningOwnerRequired: boolean;

    approvalDeadline: moment.Moment | null;
    defaultIntroductionText: string;
    introductionText: string;
    defaultClosingText: string;
    closingText: string;
    displayToOwner: boolean;
    formatOptions: IProposalFormatOptions;
    proposalPrintOptions: ProposalPrintOptions;
    categories: ProposalCategory[];
    columnsToDisplay: BTSelectItem[];
    attachedFiles: AttachedFiles;
    documentInstanceType: DocumentInstanceType;
    name: string;
    costCodes: BTSelectItem<CostCodeExtraData>[];
    notificationText: string;
    costItemUpdateMessage: string | null;
    pdfFailureMessage: string | null;
    proposalType: ProposalTypes;
    groupType: ProposalGroupTypes;
    disclaimer: string;
    hasOtherPendingProposal: boolean | undefined;
    isManuallyLocked: boolean;
    currentDate: string;
    projectConnectedToTakeoffs: boolean;
    taxGroup: TaxGroupEntity | null;
    taxRates?: TaxRateEntity[];
    signatureUsers: BTSelectItem[];
    templateIdentifier: string | null;
}

export class CustomerInfo {
    constructor(data: any) {
        this.ownerEmail = data.ownerEmail;
        this.name = data.name;
        this.entityHasContact = data.entityHasContact;
        this.ownerName = data.ownerName;
        this.ownerPhone = data.ownerPhone;
        this.ownerCell = data.ownerCell;
    }
    ownerEmail: string | null;
    name: string | null;
    entityHasContact: boolean;
    ownerPhone: string | null;
    ownerCell: string | null;
    ownerName: string | null;
}

export class BuilderInfo {
    constructor(data: any) {
        this.logo = data.logo;
        this.companyName = data.companyName;
        this.phone = data.phone;
        this.fax = data.fax;
        this.includeNameOnPrintouts = data.includeNameOnPrintouts;
        this.shouldShowAddressOnPrintouts = data.shouldShowAddressOnPrintouts;
        this.additionalFields = data.additionalFields;
    }
    logo: string;
    companyName: string;
    phone: string;
    fax: string;
    includeNameOnPrintouts: boolean;
    shouldShowAddressOnPrintouts: boolean;
    additionalFields: string[];
}

export class ProposalStatusObject {
    constructor(data: any) {
        this.status = data.status;
        this.user = data.statusText;
        this.date = data.date ? moment(data.date) : null;
        this.signature = data.signature;
        this.isExpired = data.isExpired;
        this.reason = data.reason;
    }

    status: ProposalStatus;
    user: string;
    date: moment.Moment | null;
    signature?: string;
    isExpired: boolean;
    reason: string | null;
}

export class ProposalDeleteResponse {}

export interface IUnreleasedProposalLineItemSaveRequest {
    /** deprecated: remove once server is reading from proposalFormatItemId */
    formatId: number;
    proposalFormatItemId: number;
}

export interface IUnreleasedProposalGroupSaveRequest {
    /** deprecated: remove once server is reading from proposalFormatItemId */
    id: number;
    proposalFormatItemId: number;
    isExpanded: boolean;
    /** only included for the unassigned group */
    lineItems?: IUnreleasedProposalLineItemSaveRequest[];
}

export interface IUnreleasedProposalSaveRequest {
    title: string;
    approvalDeadline: moment.Moment | null;
    introductionText: string;
    closingText: string;
    columnsToDisplay: number[];
    /** both the root groups and subgroups */
    categories: IUnreleasedProposalGroupSaveRequest[];
    formatOptions: IProposalFormatOptions;
    /** remove from this request once we have a separate endpoint for released proposals */
    displayToOwner: boolean;
    requiredSignatureUsers: number[];
    requiredSignatureId?: number;
}

export interface IProposalFormatOptions {
    header: ProposalHeaderLayouts;
    body: ProposalBodyLayouts;
    showOwnerContactInfo: boolean;
    showAddress: boolean;
    showPrintoutInfo: boolean;
    printoutType: ProposalPrintoutType;
}

export enum ProposalHeaderLayouts {
    Centered = 1,
    Split = 2,
}

export enum ProposalBodyLayouts {
    Column = 1,
    MultiLine = 2,
    None = 3,
}

export enum ProposalPrintoutType {
    Line = 1,
    Stacked = 2,
}

export interface IProposalDisplayOptions {
    showCustomerContactInfo: boolean;
    showAddress: boolean;
    showPrintoutInfo: boolean;
    printoutType: ProposalPrintoutType;
}

export const ProposalFormatItemValidationSchema = (
    groupEntityName: string,
    subgroupEntityName: string
) =>
    yup.lazy<FormatItem>((item) => {
        if (isProposalCategory(item)) {
            return ProposalCategoryValidationSchema(groupEntityName, subgroupEntityName);
        } else {
            return proposalFormatLineItemValidationSchema;
        }
    });

const ProposalSubFormatItemValidationSchema = (subgroupEntityName: string) =>
    yup.lazy<FormatItem>((item) => {
        if (isProposalCategory(item)) {
            return ProposalSubCategoryValidationSchema(subgroupEntityName);
        } else {
            return proposalFormatLineItemValidationSchema;
        }
    });

const proposalFormatLineItemValidationSchema = yup.lazy(() => {
    return getLineItemEstimatesSchema((schema) => {
        return { ...schema, unitPrice: yup.number().label("Unit Price").nullable() };
    });
});

const ProposalSubCategoryValidationSchema = (subgroupEntityName: string) =>
    yup.object<ProposalSubCategory>({
        id: yup.number(),
        title: yup.string().required().max(200).label(`${subgroupEntityName} Title`),
        description: yup.string().nullable().max(2000).label(`${subgroupEntityName} Description`),
        attachedFiles: yup.mixed(),
        items: yup
            .array<WorksheetLineItem | LeadProposalLineItem>()
            .of(proposalFormatLineItemValidationSchema),
        parentId: yup.number(),
        optionMode: yup.number(),
        // we don't really care about these ones
        aggregates: yup.mixed(),
        attachments: yup.mixed(),
        builderId: yup.mixed(),
        costCategoryId: yup.mixed(),
        displayOrder: yup.mixed(),
        documentInstanceType: yup.mixed(),
        field: yup.mixed(),
        isExpanded: yup.mixed(),
        isSelected: yup.mixed(),
        jobId: yup.mixed(),
        leadId: yup.mixed(),
        name: yup.mixed(),
        value: yup.mixed(),
        openAddAttachFiles: yup.boolean(),
        openCreateDocument: yup.boolean(),
        mountFileComponent: yup.boolean(),
        files: yup.mixed(),
        viewAllFiles: yup.boolean(),
    });

export const ProposalCategoryValidationSchema = (
    groupEntityName: string,
    subgroupEntityName: string
) =>
    yup.object<ProposalCategory>({
        id: yup.number(),
        parentId: yup.number().nullable(),
        title: yup.string().required().max(200).label(`${groupEntityName} Title`),
        description: yup.string().nullable().max(2000).label(`${groupEntityName} Description`),
        attachedFiles: yup.mixed(),
        items: yup.array().of(ProposalSubFormatItemValidationSchema(subgroupEntityName)),
        optionMode: yup.number(),
        // we don't really care about these ones
        aggregates: yup.mixed(),
        attachments: yup.mixed(),
        builderId: yup.mixed(),
        costCategoryId: yup.mixed(),
        displayOrder: yup.mixed(),
        documentInstanceType: yup.mixed(),
        field: yup.mixed(),
        isExpanded: yup.mixed(),
        isSelected: yup.mixed(),
        jobId: yup.mixed(),
        leadId: yup.mixed(),
        name: yup.mixed(),
        value: yup.mixed(),
        openAddAttachFiles: yup.boolean(),
        openCreateDocument: yup.boolean(),
        mountFileComponent: yup.boolean(),
        files: yup.mixed(),
        viewAllFiles: yup.boolean(),
    });

export const getUnassignedCategory = (builderId: number) => {
    const unassignedGroup = getDefaultProposalCategory(undefined, builderId, UnassignedCostGroupId);
    unassignedGroup.title = "Unassigned";
    return unassignedGroup;
};

export const getDefaultProposalCategory = (
    jobId: number | undefined,
    builderId: number,
    newCategoryId: number
): ProposalCategory => {
    let newCategory: ProposalCategory = {
        items: [],
        id: newCategoryId,
        _isInEdit: false,
        displayOrder: 0,
        openCreateDocument: false,
        builderId: builderId,
        openAddAttachFiles: false,
        mountFileComponent: false,
        field: "",
        value: "",
        description: null,
        attachedFiles: undefined as any, // we need to setup being able to upload files, so we will yoink the info from the proposal
        title: "",
        isSelected: false,
        isExpanded: true,
        attachments: new AttachedFilesRequest(),
        costCategoryId: null,
        documentInstanceType: DocumentInstanceType.ProposalFormatItem,
        name: "Category",
        aggregates: "",
        jobId: jobId,
        leadId: 0,
        files: [],
        viewAllFiles: false,
        autoFocusedField: "title",
        optionMode: OptionMode.Required,
    };

    return newCategory;
};

export const getDefaultProposalSubCategory = (
    jobId: number | undefined,
    builderId: number,
    newCategoryId: number,
    parentId: number
): ProposalSubCategory => {
    let newSubCategory = getDefaultProposalCategory(
        jobId,
        builderId,
        newCategoryId
    ) as ProposalSubCategory;

    newSubCategory.parentId = parentId;
    newSubCategory.name = "Sub Category";

    return newSubCategory;
};

class ProposalCategorySaveRequest {
    constructor(category: ProposalCategory, setLineItems: boolean, displayOrder: number) {
        // for new categories, they will have a negative number id.
        this.id = category.id;
        this.costCategoryId = category.costCategoryId;
        this.title = category.title;
        this.description = category.description;
        if (setLineItems) {
            this.lineItems = category.items
                .filter((item) => isLineItemRow(item))
                .map((item, i) => new ProposalLineItemSaveRequest(item as WorksheetLineItem, i));
        } else {
            this.lineItems = [];
        }
        this.attachments = category.attachments;
        this.displayOrder = displayOrder;
        this.isExpanded = category.isExpanded;
        this.optionMode = category.optionMode;
        this.parentId = category.parentId;
    }

    id: number | null;
    costCategoryId: number | null;
    title: string;
    description: string | null;
    lineItems: ProposalLineItemSaveRequest[];
    attachments: AttachedFilesRequest;
    displayOrder: number;
    isExpanded: boolean;
    optionMode?: number;
    // If parentId is defined, its a sub category
    parentId?: number;
}

class ProposalSubCategorySaveRequest {
    constructor(subCategory: ProposalSubCategory, displayOrder: number) {
        this.id = subCategory.id;
        this.formatId = subCategory.id;
        this.costCategoryFormatItemId = subCategory.parentId;
        this.displayOrder = displayOrder;
    }

    id: number;
    formatId: number;
    costCategoryFormatItemId: number | null;
    displayOrder: number;
    isExpanded: boolean;
}

class ProposalLineItemSaveRequest {
    constructor(lineItem: WorksheetLineItem | LeadProposalLineItem, displayOrder: number) {
        this.id = lineItem.id;
        this.formatId = lineItem.formatId!;
        this.costCategoryFormatItemId = lineItem.costCategoryFormatId;
        this.displayOrder = displayOrder;
    }

    id: number;
    formatId: number;
    costCategoryFormatItemId: number | null;
    displayOrder: number;
    isExpanded: boolean;
}

export class ProposalFormatGroupSaveRequest {
    constructor(proposalId: number, category: ProposalCategory) {
        this.proposalId = proposalId;
        this.formatGroup = new ProposalCategorySaveRequest(category, false, category.displayOrder);
    }
    proposalId: number;
    formatGroup: ProposalCategorySaveRequest;
}

export class ProposalFormatGroupBatchUpdateRequest {
    constructor(proposalId: number, categories: ProposalCategory[], setLineItems: boolean = false) {
        this.proposalId = proposalId;
        this.formatGroups = categories.map(
            (c) => new ProposalCategorySaveRequest(c, setLineItems, c.displayOrder)
        );
    }
    proposalId: number;
    formatGroups: ProposalCategorySaveRequest[];
}

export class ProposalFormatLineItemRequest {
    constructor(
        proposalId: number,
        categories: ProposalCategory[],
        worksheetLines?: WorksheetLineItem[]
    ) {
        this.proposalId = proposalId;
        if (worksheetLines) {
            this.lineItems = worksheetLines.map(
                (l) => new ProposalLineItemSaveRequest(l, l.displayOrder ?? 0)
            );
        } else {
            this.lineItems = categories.flatMap((c) => this.getLineItems(c));
        }
    }
    proposalId: number;
    lineItems: ProposalLineItemSaveRequest[];

    private getLineItems(category: ProposalCategory): ProposalLineItemSaveRequest[] {
        const requests: ProposalLineItemSaveRequest[] = [];
        category.items.forEach((item, i) => {
            if (isLineItemRow(item)) {
                const lineItemRequest = new ProposalLineItemSaveRequest(item, i);
                lineItemRequest.costCategoryFormatItemId = category.id;
                lineItemRequest.isExpanded = category.isExpanded;
                requests.push(lineItemRequest);
            }

            if (isLineItemGroupRow(item)) {
                const lineItemRequest = new ProposalSubCategorySaveRequest(item, i);
                lineItemRequest.costCategoryFormatItemId = category.id;
                lineItemRequest.isExpanded = category.isExpanded;
                requests.push(lineItemRequest);

                if (item.items.length > 0) {
                    const items = this.getLineItems(item);
                    requests.push(...items);
                }
            }
        });

        return requests;
    }
}

export interface IApproveDeclineProposalRequest {
    approving: boolean;
    signatureFileId: number;
    saveRequest: IUnreleasedProposalSaveRequest | null;
    approvalComments: string | undefined;
    shareToken?: string;
}

export enum ProposalApprovalType {
    Approve,
    Decline,
}

/**
 * Represents the information that can be displayed on the proposal sent to the owner
 */
export enum ProposalColumnTypes {
    CostCode = 1,
    Description = 2,
    QuantityAndUnit = 3,
    UnitCost = 4,
    BuilderCost = 5,
    Markup = 6,
    MarkupAmount = 7,
    UnitPrice = 8,
    OwnerPrice = 9,
    TotalOwnerPrice = 10,
    CategoryOwnerPrice = 11,
    TotalCost = 13,
    TotalMarkup = 14,
    ItemTitle = 16,
    CostType = 17,
    TotalTax = 18,
    TaxableStatus = 19,
    TaxBreakdown = 20,
    TotalWithTax = 21,
    MarkedAs = 22,
    TotalApprovedOptions = 23,
}

export interface ISetPreviewLayoutOptionsRequest {
    previewContentLayout?: ProposalBodyLayouts;
    previewHeaderLayout?: ProposalHeaderLayouts;
}

export interface ISetShowAdditionalPrintInfoRequest {
    showContactName?: boolean;
    showAddress?: boolean;
}

export const enum RecategorizeType {
    ClearAll = 0,
    KeepFormatting = 1,
    PullFormattingFromLegacyGroups = 2,
    SortAlphanumerically = 3,
    ReformatByCostCode = 4,
    PullFormattingFromTakeoffAssemblies = 5,
}

export interface IRecategorizeProposalFormatRequest {
    /**
     * Deprecated, group type should be determined by the RecategorizeType if needed.
     * This property should be removed with cost code grouping on the proposal worksheet, as it's still needed for that one scenario.
     */
    newGroupType?: ProposalGroupTypes;
    recategorizeType: RecategorizeType;
}

export const setOfLineItemSpecificColumns: ReadonlySet<ProposalColumnTypes> = new Set([
    ProposalColumnTypes.CostCode,
    ProposalColumnTypes.Description,
    ProposalColumnTypes.QuantityAndUnit,
    ProposalColumnTypes.UnitCost,
    ProposalColumnTypes.BuilderCost,
    ProposalColumnTypes.Markup,
    ProposalColumnTypes.MarkupAmount,
    ProposalColumnTypes.UnitPrice,
    ProposalColumnTypes.OwnerPrice,
    ProposalColumnTypes.ItemTitle,
    ProposalColumnTypes.CostType,
]);

export const hasLineItemSpecificColumn = (lineItemColumnTypes: ProposalColumnTypes[]) => {
    return lineItemColumnTypes.some((colType) => setOfLineItemSpecificColumns.has(colType));
};

export interface IProposalPreviewFormValues {
    headerLayout: ProposalHeaderLayouts;
    bodyLayout: ProposalBodyLayouts;
    showOwnerContactInfo: boolean;
    showAddress: boolean;
    showPrintoutInfo: boolean;
    printoutType: ProposalPrintoutType;
    columnsToDisplay: number[];
    formatItems: FormatItemList;
    introductionText: string;
    closingText: string;
    files: BTFileSystem[];
    title: string;
    approvalDeadline: moment.Moment | null;
}

export interface IProposalPreviewEntity extends IHasAttachedFiles {
    columnsToDisplay: BTSelectItem[];
    status: ProposalStatusObject;
    builderInfo: BuilderInfo;
    customerInfo: CustomerInfo | null;
    builderAddress: AddressEntity;
    customerAddress: AddressEntity | null;
    contactAddress: AddressEntity | null;
    showContactAddress: boolean;
    canEdit: boolean;
    proposalType: ProposalTypes;
    disclaimer: string;
    approvalDeadline: moment.Moment | null;
    currentDate: string;
    groupType?: ProposalGroupTypes;
    projectConnectedToTakeoffs: boolean;
    taxRates?: TaxRateEntity[];
    signatures?: IProposalSignatureObject[];
}
