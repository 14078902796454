import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { showAPIErrorMessage } from "utilities/apiHandler";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    IJobViewingPermissionWizardFormValues,
    OpenedFromEntity,
} from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard.api.types";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import {
    IJobViewingPermissionWizardHandler,
    JobViewingPermissionWizardHandler,
} from "./JobViewingPermissionWizard.api.handler";
import { JobViewingPermissionWizardPresentational } from "./JobViewingPermissionWizardPresentational";

export interface IJobViewingPermissionWizardProps extends RouteComponentProps {
    jobId: number;
    autoAddedSubs?: BTSelectItem[];
    autoAddedInternalUsers?: BTSelectItem[];
    subs: BTSelectItem[];
    internalUsers: BTSelectItem[];
    openedFromEntity: OpenedFromEntity;
    canSelectUsers: boolean;
    handler?: IJobViewingPermissionWizardHandler;
    modalConfig?: IModalConfiguration;
    onClose: () => void;
    afterSubmit: (subIds: number[], userIds: number[]) => void;
}

class JobViewingPermissionWizardInternal extends Component<IJobViewingPermissionWizardProps> {
    static defaultProps = {
        handler: new JobViewingPermissionWizardHandler(),
    };

    private onSubmit = async (formValues: IJobViewingPermissionWizardFormValues) => {
        const { handler, afterSubmit } = this.props;
        try {
            const resp = await handler!.addUsersToJob(formValues);
            if ((formValues.autoAddedSubIds?.length ?? 0) > 0) {
                resp.subIdsAdded = resp.subIdsAdded.concat(formValues.autoAddedSubIds!);
            }
            afterSubmit(resp.subIdsAdded, resp.internalUserIdsAdded);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    render() {
        const { modalConfig } = this.props;

        const jobViewingPermissionWizard = (
            <JobViewingPermissionWizardPresentational
                {...this.props}
                onSubmit={this.onSubmit}
                modalConfig={modalConfig}
            />
        );

        if (modalConfig) {
            return (
                <BTModal
                    data-testid="btModalGlobalUserJobViewPermission"
                    title="Job Viewing Permission Wizard"
                    width="700px"
                    beforeClose={modalConfig.beforeClose}
                    removeBodyPadding
                    visible
                    useModalLayout
                >
                    {jobViewingPermissionWizard}
                </BTModal>
            );
        } else {
            return jobViewingPermissionWizard;
        }
    }
}

export const JobViewingPermissionWizard = withErrorBoundary(JobViewingPermissionWizardInternal)(
    "Could not load Job Viewing Permission Wizard"
);
export default JobViewingPermissionWizard;
