import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ISelectionProps } from "entity/selection/Selection/Selection";

const Selection = lazyLoadWithRetry(() => import("./Selection"));

export const RouteSelection = (
    props: Omit<ISelectionProps, "id" | "jobId" | "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.selection.details}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <Selection
                        {...props}
                        {...routeProps}
                        id={parseInt(routeProps.match.params.id)}
                        jobId={parseInt(routeProps.match.params.jobId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
