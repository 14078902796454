import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { IAcceptTermsAndPoliciesFormValues } from "commonComponents/utilities/AcceptTermsAndPolicies/acceptTermsAndPolicies/AcceptTermsAndPolicies.api.types";

export interface IAcceptTermsAndPoliciesHandler {
    acceptTermsAndPolicies(values: IAcceptTermsAndPoliciesFormValues): Promise<EmptyResponseEntity>;
}

export class AcceptTermsAndPoliciesHandler implements IAcceptTermsAndPoliciesHandler {
    async acceptTermsAndPolicies(
        values: IAcceptTermsAndPoliciesFormValues
    ): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Users/AcceptTermsAndPolicies", {
            method: "PUT",
            data: values.acceptDocuments,
            responseType: EmptyResponseEntity,
        });
    }
}
