import Icon from "@ant-design/icons";
import { FunctionComponent } from "react";

import SVG from "images/BTLearningAcademy.svg?react";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

export const BTIconLearningAcademy: FunctionComponent<IBTIconProps> = (baseProps) => {
    const { ...iconProps } = baseProps;
    const [props] = useBaseIcon(iconProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon {...props} component={SVG} />
    );
};
