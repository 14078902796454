export interface IPrintInfoAPIData extends Partial<PrintInfoAPI> {}

/** Displays builder/owner/jobsite information on print pages */
export class PrintInfoAPI {
    constructor(data: IPrintInfoAPIData) {
        this.address = data.address;
        this.cell = data.cell;
        this.email = data.email;
        this.fax = data.fax;
        this.name = data.name ?? "";
        this.phone = data.phone;
        this.companyAdditionalFields = data.companyAdditionalFields;
    }

    address?: string;
    cell?: string;
    email?: string;
    fax?: string;
    name: string;
    phone?: string;
    companyAdditionalFields?: string[];
}
