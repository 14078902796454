import { createContext, useContext, useState } from "react";

type StateContext = Readonly<[number, React.Dispatch<React.SetStateAction<number>>]>;
export const FilterCountContext = createContext<StateContext | undefined>(undefined);

export const FilterCountProvider: React.FC = (props) => {
    const state = useState(0);
    return (
        <FilterCountContext.Provider value={state}>{props.children}</FilterCountContext.Provider>
    );
};

export const FilterCount: React.FC<{ count: number; setFilterCount?: (count: number) => void }> = (
    props
) => {
    const contextVal = useContext(FilterCountContext);
    if (contextVal) {
        const [count, setCount] = contextVal;
        if (props.count !== count) {
            requestAnimationFrame(() => {
                setCount(props.count);
                props.setFilterCount?.(props.count);
            });
        }
    }
    return null;
};

export const FilterCountDisplay: React.FC = () => {
    const contextVal = useContext(FilterCountContext);
    if (contextVal && contextVal[0] > 0) {
        return <> ({contextVal[0]})</>;
    }
    return null;
};
