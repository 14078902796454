import { round } from "utilities/math/math";

export const formatBytes = (numBytes: number) => {
    const conversionFactor = 1024;
    const numKBytes = numBytes / conversionFactor;
    if (numKBytes < conversionFactor) {
        return {
            value: roundAndFormatNumber(numKBytes, 0),
            label: "KB",
        };
    }

    const numMBytes = numKBytes / conversionFactor;
    if (numMBytes < conversionFactor) {
        return {
            value: roundAndFormatNumber(numMBytes, 1),
            label: "MB",
        };
    }

    const numGBytes = numMBytes / conversionFactor;
    return {
        value: roundAndFormatNumber(numGBytes, 1),
        label: "GB",
    };
};

const roundAndFormatNumber = (value: number, decimalPlaces: number) => {
    const roundFactor = 10 ** decimalPlaces;
    return (round(value * roundFactor) / roundFactor).toLocaleString();
};
