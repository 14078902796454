import { isNullOrUndefined } from "utilities/object/object";

export class SharingSettingsAPI {
    constructor(data: any) {
        this.internalUserPreference = data.internalUserPreference
            ? new ShareNotifyAPI(data.internalUserPreference)
            : null;
        this.subVendorPreference = data.subVendorPreference
            ? new ShareNotifyAPI(data.subVendorPreference)
            : null;
        this.ownerPreference = data.ownerPreference
            ? new ShareNotifyAPI(data.ownerPreference)
            : null;
        this.privateOption = data.privateOption ? new PrivateOptionAPI(data.privateOption) : null;
        this.showShare = !isNullOrUndefined(data.showShare) ? data.showShare : true;
    }
    internalUserPreference: ShareNotifyAPI | null;
    subVendorPreference: ShareNotifyAPI | null;
    ownerPreference: ShareNotifyAPI | null;
    privateOption: PrivateOptionAPI | null;
    showShare: boolean;
}

export class ShareNotifyAPI {
    constructor(data: any) {
        this.canShare = data.canShare;
        this.canNotify = data.canNotify;
        this.share = data.share;
        this.notify = data.notify;
        this.canUpdate = !isNullOrUndefined(data.canUpdate) ? data.canUpdate : true;
    }
    canShare: boolean;
    canNotify: boolean;
    share: boolean;
    notify: boolean;
    canUpdate: boolean;
}

export class PrivateOptionAPI {
    constructor(data: any) {
        this.isPrivate = data.isPrivate;
        this.isPrivateEnabled = data.isPrivateEnabled;
    }

    isPrivate: boolean;
    isPrivateEnabled: boolean;
}
