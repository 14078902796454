import { WarningFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconWarningFilled: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <WarningFilled {...props} type="warning" />;
};
