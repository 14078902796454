import { Component } from "react";

import "./RequiredFieldIndicator.less";

export class RequiredFieldIndicator extends Component {
    render() {
        return (
            <span className="requiredField" data-testid="requiredFieldIndicator">
                *
            </span>
        );
    }
}
