import { Badge, BadgeProps } from "antd";
import classNames from "classnames";
import { PureComponent } from "react";

import "./BTBadge.less";

interface IBTBadgeProps extends Pick<BadgeProps, "count" | "className" | "offset" | "dot"> {
    statusType: StatusTypes;
    overflowCount?: number;
    title?: string;
}

export type StatusTypes = "info" | "alert";

export class BTBadge extends PureComponent<IBTBadgeProps> {
    static defaultProps = {
        statusType: "alert",
    };

    render() {
        const { children, className, count, offset, statusType, overflowCount, title, dot } =
            this.props;
        return (
            // eslint-disable-next-line react/forbid-elements
            <Badge
                offset={offset}
                count={count}
                className={classNames("BTBadge", className, statusType)}
                overflowCount={overflowCount}
                title={title}
                dot={dot}
            >
                {children}
            </Badge>
        );
    }
}
