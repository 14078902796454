import { ButtonSize } from "antd/lib/button";
import { Component } from "react";

import ReadMorePresentational from "commonComponents/utilities/ReadMore/ReadMorePresentational";

import "./ReadMore.less";

interface IReadMoreProps {
    numberOfLines: number;
    lineHeight?: number;
    "data-testid"?: string;
    size: ButtonSize;
}

interface IReadMoreState {
    showingMore: boolean;
}

export default class ReadMore extends Component<IReadMoreProps, IReadMoreState> {
    static defaultProps = {
        size: "default",
    };

    state: Readonly<IReadMoreState> = {
        showingMore: false,
    };

    private toggleShowMore = (showingMore: boolean) => {
        this.setState({ showingMore });
    };

    render = () => {
        const { showingMore } = this.state;
        return (
            <ReadMorePresentational
                showingMore={showingMore}
                onShowingMoreToggle={this.toggleShowMore}
                {...this.props}
            />
        );
    };
}
