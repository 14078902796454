import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import classNames from "classnames";
import { PureComponent } from "react";

import { track } from "utilities/analytics/analytics";

import "./BTCard.less";

export interface IBTCardProps extends CardProps {
    /**
     * Prop for if the title portion of the card
     * is a parent to the contents
     *
     * Darkens the title portion slightly to show this
     */
    isParentChild?: boolean;
    /**
     * Ants Default Design for a cover is to set negative margins which would overlap the border
     * of the card making it look like the cover does not have border.
     *
     * Use this to override that so the card cover can have a border
     */
    overrideAntCardCoverMargins?: boolean;
}

@track({ component: "Card" })
export class BTCard extends PureComponent<IBTCardProps> {
    static defaultProps = {
        isParentChild: false,
        overrideAntCardCoverMargins: false,
    };

    render() {
        const { className, isParentChild, overrideAntCardCoverMargins, ...rest } = this.props;
        return (
            // eslint-disable-next-line react/forbid-elements
            <Card
                className={classNames("BTCard", className, {
                    BTParentChildCard: isParentChild,
                    BTCardCoverMarginOverride: overrideAntCardCoverMargins,
                })}
                {...rest}
            />
        );
    }
}
