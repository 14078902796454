import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ILienWaiverProps } from "entity/lienWaiver/LienWaiver/LienWaiver";

const LienWaiver = lazyLoadWithRetry(() => import("./LienWaiver"));
export const RouteLienWaiver = (
    props: Omit<ILienWaiverProps, "history" | "location" | "match" | "id" | "jobId">
) => (
    <RouteRelative
        path={routes.lienWaiver.details}
        render={(routePath) => (
            <BTSuspense>
                <LienWaiver
                    id={routePath.match.params.id}
                    jobId={routePath.match.params.jobId}
                    modalConfig={props.modalConfig}
                    {...routePath}
                />
            </BTSuspense>
        )}
    />
);
