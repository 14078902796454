/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";

import { apixHandler } from "../utilities/api/apix-handler";
import type { BodyType, ErrorType } from "../utilities/api/apix-handler";
import type {
    AdminUsersOrgLinkUsersGetV2Params,
    AdminUsersUsernameSearchGetV2Params,
    AuthStoreForUsernameResponse,
    BadRequestResponse,
    ExternalCompanyInformationResponse,
    ExternalUserIdentityContract,
    ExternalUserResponse,
    ForbiddenResponse,
    GetUserActivationLoginTypeResponse,
    GetUserActivationResponse,
    MassInviteUsersForBuilderRequest,
    MassInviteUsersOnBuilderResponse,
    OrgLinkUsersResponse,
    UpdateAccountToAuth0Request,
    UpdateAccountToAuth0Response,
    UsernameLookupContract,
    UsersAuthStoreForUsernameGetV2Params,
    UsersGetExternalCompanyInformationGetV2Params,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * <div>
<b>Authorization:</b> <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalUser</code>
</div>
**Stability Level**: `stable`
 * @summary Get information about the requested User.
 */
export const usersGetV2 = (options?: SecondParameter<typeof apixHandler>, signal?: AbortSignal) => {
    return apixHandler<ExternalUserResponse>(
        { url: `/apix/v2/Users`, method: "GET", signal },
        options
    );
};

export const getUsersGetV2QueryKey = () => {
    return [`/apix/v2/Users`] as const;
};

export const getUsersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersGetV2>>> = ({ signal }) =>
        usersGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersGetV2QueryResult = NonNullable<Awaited<ReturnType<typeof usersGetV2>>>;
export type UsersGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the requested User.
 */
export const useUsersGetV2 = <
    TData = Awaited<ReturnType<typeof usersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>LegacyBearer</code> | <code>passthrough</code>
</div>
<div>
<b>Policy:</b> <code>IdentityProviders</code>
</div>
**Stability Level**: `stable`
 * @summary Get identity information about the requested User.
 */
export const usersIdentityGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalUserIdentityContract>(
        { url: `/apix/v2/Users/identity`, method: "GET", signal },
        options
    );
};

export const getUsersIdentityGetV2QueryKey = () => {
    return [`/apix/v2/Users/identity`] as const;
};

export const getUsersIdentityGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersIdentityGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersIdentityGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersIdentityGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersIdentityGetV2>>> = ({ signal }) =>
        usersIdentityGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersIdentityGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersIdentityGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersIdentityGetV2>>
>;
export type UsersIdentityGetV2QueryError = ErrorType<void>;

/**
 * @summary Get identity information about the requested User.
 */
export const useUsersIdentityGetV2 = <
    TData = Awaited<ReturnType<typeof usersIdentityGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersIdentityGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersIdentityGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalService</code>
</div>
**Stability Level**: `stable`
 * @summary Get information about the specified user.
 */
export const usersByUserIdGetV2 = (
    userId: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalUserResponse>(
        { url: `/apix/v2/Users/${userId}`, method: "GET", signal },
        options
    );
};

export const getUsersByUserIdGetV2QueryKey = (userId: string) => {
    return [`/apix/v2/Users/${userId}`] as const;
};

export const getUsersByUserIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersByUserIdGetV2>>,
    TError = ErrorType<void>
>(
    userId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof usersByUserIdGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersByUserIdGetV2QueryKey(userId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersByUserIdGetV2>>> = ({ signal }) =>
        usersByUserIdGetV2(userId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersByUserIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersByUserIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersByUserIdGetV2>>
>;
export type UsersByUserIdGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the specified user.
 */
export const useUsersByUserIdGetV2 = <
    TData = Awaited<ReturnType<typeof usersByUserIdGetV2>>,
    TError = ErrorType<void>
>(
    userId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof usersByUserIdGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersByUserIdGetV2QueryOptions(userId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>UserCredentialsWrite</code>
</div>
**Stability Level**: `stable`
 */
export const usersUpdateAccountToAuth0PostV2 = (
    updateAccountToAuth0Request: BodyType<UpdateAccountToAuth0Request>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<UpdateAccountToAuth0Response>(
        {
            url: `/apix/v2/Users/UpdateAccountToAuth0`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: updateAccountToAuth0Request,
        },
        options
    );
};

export const getUsersUpdateAccountToAuth0PostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        TError,
        { data: BodyType<UpdateAccountToAuth0Request> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
    TError,
    { data: BodyType<UpdateAccountToAuth0Request> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        { data: BodyType<UpdateAccountToAuth0Request> }
    > = (props) => {
        const { data } = props ?? {};

        return usersUpdateAccountToAuth0PostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UsersUpdateAccountToAuth0PostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>
>;
export type UsersUpdateAccountToAuth0PostV2MutationBody = BodyType<UpdateAccountToAuth0Request>;
export type UsersUpdateAccountToAuth0PostV2MutationError = ErrorType<unknown>;

export const useUsersUpdateAccountToAuth0PostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        TError,
        { data: BodyType<UpdateAccountToAuth0Request> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
    TError,
    { data: BodyType<UpdateAccountToAuth0Request> },
    TContext
> => {
    const mutationOptions = getUsersUpdateAccountToAuth0PostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>UserCredentialsWrite</code>
</div>
**Stability Level**: `stable`
 */
export const usersAuthStoreForUsernameGetV2 = (
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<AuthStoreForUsernameResponse>(
        { url: `/apix/v2/Users/AuthStoreForUsername`, method: "GET", params, signal },
        options
    );
};

export const getUsersAuthStoreForUsernameGetV2QueryKey = (
    params?: UsersAuthStoreForUsernameGetV2Params
) => {
    return [`/apix/v2/Users/AuthStoreForUsername`, ...(params ? [params] : [])] as const;
};

export const getUsersAuthStoreForUsernameGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersAuthStoreForUsernameGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>> = ({
        signal,
    }) => usersAuthStoreForUsernameGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersAuthStoreForUsernameGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>
>;
export type UsersAuthStoreForUsernameGetV2QueryError = ErrorType<unknown>;

export const useUsersAuthStoreForUsernameGetV2 = <
    TData = Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersAuthStoreForUsernameGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>machine-to-machine-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>ExternalService</code>
</div>
**Stability Level**: `stable`
 * @summary Gets a list of ExternalUserResponse objects given a users external id
 */
export const usersGetExternalCompanyInformationGetV2 = (
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalCompanyInformationResponse>(
        { url: `/apix/v2/Users/GetExternalCompanyInformation`, method: "GET", params, signal },
        options
    );
};

export const getUsersGetExternalCompanyInformationGetV2QueryKey = (
    params?: UsersGetExternalCompanyInformationGetV2Params
) => {
    return [`/apix/v2/Users/GetExternalCompanyInformation`, ...(params ? [params] : [])] as const;
};

export const getUsersGetExternalCompanyInformationGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getUsersGetExternalCompanyInformationGetV2QueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>
    > = ({ signal }) => usersGetExternalCompanyInformationGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersGetExternalCompanyInformationGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>
>;
export type UsersGetExternalCompanyInformationGetV2QueryError = ErrorType<unknown>;

/**
 * @summary Gets a list of ExternalUserResponse objects given a users external id
 */
export const useUsersGetExternalCompanyInformationGetV2 = <
    TData = Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersGetExternalCompanyInformationGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * **Stability Level**: `stable`
 */
export const usersExternalActivationLoginTypeByShareTokenGetV2 = (
    shareToken: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<GetUserActivationLoginTypeResponse>(
        {
            url: `/apix/v2/Users/external-activation/${shareToken}/login-type`,
            method: "GET",
            signal,
        },
        options
    );
};

export const getUsersExternalActivationLoginTypeByShareTokenGetV2QueryKey = (
    shareToken: string
) => {
    return [`/apix/v2/Users/external-activation/${shareToken}/login-type`] as const;
};

export const getUsersExternalActivationLoginTypeByShareTokenGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getUsersExternalActivationLoginTypeByShareTokenGetV2QueryKey(shareToken);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>
    > = ({ signal }) =>
        usersExternalActivationLoginTypeByShareTokenGetV2(shareToken, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!shareToken, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersExternalActivationLoginTypeByShareTokenGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>
>;
export type UsersExternalActivationLoginTypeByShareTokenGetV2QueryError = ErrorType<unknown>;

export const useUsersExternalActivationLoginTypeByShareTokenGetV2 = <
    TData = Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersExternalActivationLoginTypeByShareTokenGetV2QueryOptions(
        shareToken,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * **Stability Level**: `stable`
 */
export const usersActivationByShareTokenGetV2 = (
    shareToken: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<GetUserActivationResponse>(
        { url: `/apix/v2/Users/activation/${shareToken}`, method: "GET", signal },
        options
    );
};

export const getUsersActivationByShareTokenGetV2QueryKey = (shareToken: string) => {
    return [`/apix/v2/Users/activation/${shareToken}`] as const;
};

export const getUsersActivationByShareTokenGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getUsersActivationByShareTokenGetV2QueryKey(shareToken);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>> = ({
        signal,
    }) => usersActivationByShareTokenGetV2(shareToken, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!shareToken, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersActivationByShareTokenGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>
>;
export type UsersActivationByShareTokenGetV2QueryError = ErrorType<unknown>;

export const useUsersActivationByShareTokenGetV2 = <
    TData = Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getUsersActivationByShareTokenGetV2QueryOptions(shareToken, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>AdminUser</code>
</div>
**Stability Level**: `stable`
 */
export const adminUsersUsernameSearchGetV2 = (
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<UsernameLookupContract>(
        { url: `/apix/v2/admin/Users/username-search`, method: "GET", params, signal },
        options
    );
};

export const getAdminUsersUsernameSearchGetV2QueryKey = (
    params?: AdminUsersUsernameSearchGetV2Params
) => {
    return [`/apix/v2/admin/Users/username-search`, ...(params ? [params] : [])] as const;
};

export const getAdminUsersUsernameSearchGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminUsersUsernameSearchGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>> = ({
        signal,
    }) => adminUsersUsernameSearchGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminUsersUsernameSearchGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>
>;
export type AdminUsersUsernameSearchGetV2QueryError = ErrorType<unknown>;

export const useAdminUsersUsernameSearchGetV2 = <
    TData = Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getAdminUsersUsernameSearchGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>AdminUser</code>
</div>
**Stability Level**: `stable`
 */
export const adminUsersOrgLinkUsersGetV2 = (
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<OrgLinkUsersResponse>(
        { url: `/apix/v2/admin/Users/OrgLinkUsers`, method: "GET", params, signal },
        options
    );
};

export const getAdminUsersOrgLinkUsersGetV2QueryKey = (
    params?: AdminUsersOrgLinkUsersGetV2Params
) => {
    return [`/apix/v2/admin/Users/OrgLinkUsers`, ...(params ? [params] : [])] as const;
};

export const getAdminUsersOrgLinkUsersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminUsersOrgLinkUsersGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>> = ({
        signal,
    }) => adminUsersOrgLinkUsersGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminUsersOrgLinkUsersGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>
>;
export type AdminUsersOrgLinkUsersGetV2QueryError = ErrorType<unknown>;

export const useAdminUsersOrgLinkUsersGetV2 = <
    TData = Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getAdminUsersOrgLinkUsersGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * <div>
<b>Authorization:</b> <code>session-based</code> | <code>user-bearer</code>
</div>
<div>
<b>Authentication:</b> <code>Session</code> | <code>LegacyBearer</code> | <code>auth0</code>
</div>
<div>
<b>Policy:</b> <code>AdminUser</code>
</div>
**Stability Level**: `stable`
 */
export const adminUsersInvitesByOrganizationIdPostV2 = (
    organizationId: string,
    massInviteUsersForBuilderRequest: BodyType<MassInviteUsersForBuilderRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<MassInviteUsersOnBuilderResponse>(
        {
            url: `/apix/v2/admin/Users/invites/${organizationId}`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: massInviteUsersForBuilderRequest,
        },
        options
    );
};

export const getAdminUsersInvitesByOrganizationIdPostV2MutationOptions = <
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        TError,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
    TError,
    { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return adminUsersInvitesByOrganizationIdPostV2(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AdminUsersInvitesByOrganizationIdPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>
>;
export type AdminUsersInvitesByOrganizationIdPostV2MutationBody =
    BodyType<MassInviteUsersForBuilderRequest>;
export type AdminUsersInvitesByOrganizationIdPostV2MutationError = ErrorType<
    BadRequestResponse | ForbiddenResponse
>;

export const useAdminUsersInvitesByOrganizationIdPostV2 = <
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        TError,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
    TError,
    { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
    TContext
> => {
    const mutationOptions = getAdminUsersInvitesByOrganizationIdPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
