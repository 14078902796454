import classNames from "classnames";
import { Component } from "react";

import "./Status.less";

export type StatusTypes = "success" | "danger" | "pending" | "emergency" | "info" | "";

interface IStatusDisplayProps {
    statusType: StatusTypes;
    details?: React.ReactNode;
    className?: string;
    "data-testid"?: string;
    /**
     * Sets the way the status containing div is displayed
     * @default "absolute"
     */
    displayMode?: "absolute" | "relative";
    actionButtons?: React.ReactNode;
}

export class StatusDisplay extends Component<IStatusDisplayProps> {
    static defaultProps = {
        displayMode: "absolute",
    };

    render = () => {
        // add additional css class for fixed or modal mode
        const displayClass = this.props.displayMode === "absolute" ? "status-absolute" : null;

        return (
            <div
                className={classNames("status-text", this.props.className, displayClass)}
                data-testid={this.props["data-testid"]}
            >
                <span className="status-text status-details">{this.props.details}</span>
                {this.props.actionButtons}
                <div className={`status-text status-tag ${this.props.statusType}`}>
                    {this.props.children}
                </div>
            </div>
        );
    };
}
