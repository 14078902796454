import { BuilderFlags, BuilderInfo, UserInfo } from "helpers/AppProvider.types";

export class AnalyticsTraits {
    constructor(userInfo: UserInfo, builderInfo: BuilderInfo) {
        this.userTraits = userInfo;
        this.builderTraits = new AnalyticsBuilderTraits(builderInfo);
    }

    userTraits: UserInfo;
    builderTraits: AnalyticsBuilderTraits;
}

export class AnalyticsBuilderTraits {
    constructor(data: BuilderInfo) {
        this.builderId = data.builderId;
        this.builderName = data.name;
        this.isDemoAccount = data.isDemoAccount;
        this.flags = data.flags;
    }

    builderId: number;
    builderName: string;
    isDemoAccount: boolean;
    flags: BuilderFlags;
}
