import { Input } from "antd";

import { UserInfo } from "helpers/AppProvider.types";

import { BTLocalStorage } from "types/btStorage";
import { BTLoginTypes, LoginStatus } from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";
import { getPortalForLoginType, PortalType } from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";
import { getOAuthUrl } from "utilities/url/url";

import { clearJobPickerKeys } from "commonComponents/utilities/JobPicker/JobPickerWrapper";

import {
    ILoginResponse,
    IRedirectPageParams,
    LoginPageRedirect,
    LoginResponse,
    OAuthType,
} from "entity/login/Login/Login.api.types";
import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";

export function getCanRedirectForExistingLogin(
    reason: string | undefined,
    oauthRedirectType: OAuthType | undefined,
    userInfo: UserInfo | undefined,
    promptLogin: boolean | undefined
) {
    return (
        reason === undefined && // do not redirect when the user is presented with a reason
        !(oauthRedirectType && promptLogin) && // do not redirect during oauth flow where prompt=login
        userInfo !== undefined && // require a user
        userInfo != null &&
        userInfo.globalUserId !== null &&
        (userInfo.loginType === BTLoginTypes.BUILDER || // only these 5 login types are supported
            userInfo.loginType === BTLoginTypes.SUBS ||
            userInfo.loginType === BTLoginTypes.OWNER ||
            userInfo.loginType === BTLoginTypes.CONTACT)
    );
}

export function handleAutoFill<TValues>(
    usernameKey: keyof TValues,
    usernameValue: string,
    usernameRef: React.RefObject<Input>,
    setFieldValue: (key: keyof TValues, value: any) => void
) {
    if (usernameRef.current && usernameRef.current.state.value !== usernameValue) {
        setFieldValue(usernameKey, usernameRef.current!.state.value);
    }
}

export const getLastPageRequested = (loginType: BTLoginTypes, loginPostRedirect: string) => {
    const decodedPostLoginUrl = unescape(loginPostRedirect);
    let redirectUrl = "/".concat(decodedPostLoginUrl);
    redirectUrl = redirectUrl.replace("//", "/");
    if (redirectUrl.includes("summaryGrid.aspx") && loginType !== BTLoginTypes.BUILDER) {
        if (loginType === BTLoginTypes.SUBS) {
            redirectUrl = redirectUrl.replace("summaryGrid.aspx", "subSummary.aspx");
        } else if (loginType === BTLoginTypes.OWNER) {
            redirectUrl = redirectUrl.replace("summaryGrid.aspx", "reactOwnerSummary.aspx");
        }
    }
    if (isReactPortal(redirectUrl)) {
        redirectUrl = getReactUrlWithPortal(redirectUrl, loginType);
    }
    return redirectUrl;
};

const isReactPortal = (redirectUrl: string) => {
    return (
        redirectUrl.startsWith("/app") &&
        !redirectUrl.startsWith("/app/owner") &&
        !redirectUrl.startsWith("/app/subs")
    );
};

const getReactUrlWithPortal = (baseUrl: string, loginType: BTLoginTypes) => {
    let portalAddon;
    switch (loginType) {
        case BTLoginTypes.OWNER:
            portalAddon = "/owner";
            break;
        case BTLoginTypes.SUBS:
            portalAddon = "/subs";
            break;
        default:
            portalAddon = "";
            break;
    }
    return baseUrl.replace("/app", `/app${portalAddon}`);
};

export const handleRedirectPage = (params: IRedirectPageParams) => {
    const response = params.loginResponse;
    const redirectPage = getRedirectPage(response, params.isOAuthRequest, params.loginPostRedirect);

    // Clears localStorage of previous Job Picker keys
    if (response.builderId) {
        clearJobPickerKeys(response.builderId);
    }

    if (!(response instanceof LoginResponse) && redirectPage !== LoginPageRedirect.OAuth) {
        return;
    }

    if (redirectPage === LoginPageRedirect.OAuth) {
        const oauthRedirectState = BTLocalStorage.get("bt-object-oauthRedirectState");
        BTLocalStorage.remove("bt-object-oauthRedirectState");
        const redirectURI = params.oauthRedirectUri ?? oauthRedirectState?.redirectURI;
        const stateParam = params.oauthState ?? oauthRedirectState?.state;
        if (redirectURI && stateParam && response.oAuthCode) {
            window.location.assign(getOAuthUrl(redirectURI, response.oAuthCode, stateParam));
        }
    } else if (response instanceof LoginResponse) {
        switch (redirectPage) {
            case LoginPageRedirect.FrozenSubOwner:
                window.location.assign(routesWebforms.Login.GetFrozenSubUrl());
                break;
            case LoginPageRedirect.FrozenBuilder:
                window.location.assign(
                    routesWebforms.Login.GetFrozenBuilderUrl(
                        response.loginStatus === LoginStatus.ClosedBuilder,
                        response.isUserAdmin,
                        response.builderName,
                        response.encryptedRewinBuilderId,
                        response.contactSupport
                    )
                );
                break;
            case LoginPageRedirect.SubNoJobAccess:
                window.location.assign(getBaseRoute() + routes.bid.getListLink());
                break;
            case LoginPageRedirect.Summary:
                window.location.assign(
                    routesWebforms.Summary.GetSummaryGridUrl(response.loginType, {
                        userJustLoggedIn: true,
                        firstLogin: response.loginStatus === LoginStatus.SuggestPasswordReset,
                    })
                );
                break;
            case LoginPageRedirect.CalendarPageOwner:
                window.location.assign(
                    getBaseRoute() + routes.schedule.getListLink(ScheduleListContainerTabs.Default)
                );
                break;
            case LoginPageRedirect.PostLoginRedirect:
                const redirectUrl = getLastPageRequested(
                    response.loginType,
                    params.loginPostRedirect!
                );
                window.location.assign(redirectUrl);
                break;
            case LoginPageRedirect.MFA:
                if (response.credId > 0) {
                    params.setLoginStateForMFA!(response.credId);
                }
                break;
            case LoginPageRedirect.RequireEmail:
                if (response.requireEmailCredId) {
                    params.setLoginStateForRequireEmail!(response.requireEmailCredId);
                }
                break;
            case LoginPageRedirect.DataPrivacy:
                window.location.assign(
                    routesWebforms.Misc.GetDataPrivacyUrl(response.dataPrivacyJWT!)
                );
                break;
            case LoginPageRedirect.SubscriptionManagement:
                let redirectRoute = `app/${routes.subscriptionManager.getSettingsLink(
                    false,
                    false
                )}`;
                redirectRoute = redirectRoute.replace("//", "/");
                window.location.assign(redirectRoute);
                break;
            case LoginPageRedirect.NotConnected:
                window.location.assign(`app${routes.notConnected.getLink(true)}`);
                break;
            default:
                // If we don't redirect, we need to reset the loading spinner
                params.resetLoadingSpinner!();
                break;
        }
    }
};

const getRedirectPage = (
    response: ILoginResponse,
    isOAuthRequest?: boolean,
    loginPostRedirect?: string
): LoginPageRedirect => {
    const portalType = getPortalForLoginType(response.loginType);
    if (isOAuthRequest && response.oAuthCode) {
        return LoginPageRedirect.OAuth;
    }

    if (response instanceof LoginResponse) {
        if (!isNullOrUndefined(response.dataPrivacyJWT) && loginPostRedirect) {
            if (response.dataPrivacyJWT) {
                return LoginPageRedirect.DataPrivacy;
            } else {
                return LoginPageRedirect.NoRedirect;
            }
        }

        if (
            (portalType === PortalType.SUBS || portalType === PortalType.OWNER) &&
            (response.loginStatus === LoginStatus.ClosedBuilder ||
                response.loginStatus === LoginStatus.FrozenBuilder)
        ) {
            return LoginPageRedirect.FrozenSubOwner;
        }

        if (
            portalType === PortalType.BUILDER &&
            (response.loginStatus === LoginStatus.ClosedBuilder ||
                response.loginStatus === LoginStatus.FrozenBuilder)
        ) {
            return LoginPageRedirect.FrozenBuilder;
        }

        if (
            portalType === PortalType.BUILDER &&
            response.loginStatus === LoginStatus.NonBtOfferingBuilder
        ) {
            return LoginPageRedirect.SubscriptionManagement;
        }

        if (response.loginStatus === LoginStatus.RequiresMFA) {
            return LoginPageRedirect.MFA;
        }
        if (response.loginStatus === LoginStatus.RequireEmail) {
            return LoginPageRedirect.RequireEmail;
        }

        if (loginPostRedirect) {
            if (
                portalType === PortalType.SUBS &&
                response.loginStatus === LoginStatus.SuggestPasswordReset
            ) {
                return LoginPageRedirect.Summary;
            }
            if (response.loginStatus !== LoginStatus.Failed) {
                return LoginPageRedirect.PostLoginRedirect;
            } else {
                return LoginPageRedirect.NoRedirect;
            }
        }

        if (response.loginStatus === LoginStatus.NotConnected) {
            return LoginPageRedirect.NotConnected;
        }

        if (response.isSubNoJobAccess && response.subBidCount > 0) {
            return LoginPageRedirect.SubNoJobAccess;
        }

        if (
            (portalType === PortalType.BUILDER ||
                portalType === PortalType.SUBS ||
                portalType === PortalType.OWNER) &&
            response.loginStatus !== LoginStatus.Failed
        ) {
            return LoginPageRedirect.Summary;
        }
    }

    return LoginPageRedirect.NoRedirect;
};
