import { Route, RouteProps, useRouteMatch } from "react-router-dom";

/**
 * Use this route whenever adding a nested route to your page
 * @param props Whatever props you would normally pass to a Route
 */
export const RouteRelative: React.FunctionComponent<RouteProps> = (props) => {
    const { path, ...rest } = props;
    const match = useRouteMatch();
    return <Route {...rest} path={`${match.url}${path}`} />;
};
