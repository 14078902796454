import { Component } from "react";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";

export interface IValidationProps {
    errorCount: number;
}

/**
 * Displays validation errors in a consistent manner.
 *
 * **NOTE:** If you are using Formik, use FormikValidationSummary instead which generates the
 * ValidationSummary automatically based off of the Yup schema and Formik's generated error
 * object prop.
 */
export class ValidationSummary extends Component<IValidationProps> {
    render() {
        const { errorCount, children } = this.props;

        if (errorCount === 0 || !children) {
            return null;
        }

        return (
            <BTAlert
                title={
                    <>
                        Please correct the following{" "}
                        <BTFormattedPlural value={errorCount} one="field" other="fields" />:
                    </>
                }
                message={children}
                showIcon={true}
                type="error"
                data-testid="requiredCorrections"
            />
        );
    }
}
