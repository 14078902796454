import { useEffect, useMemo, useState } from "react";
import { Key } from "ts-key-enum";

import { FocusContext, IFocusContext } from "commonComponents/utilities/Focus/FocusContext";
import {
    IRootFocusListener,
    RootFocusObserver,
} from "commonComponents/utilities/Focus/RootFocusObserver";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import { THotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.types";
import { HotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.utility";

export interface IFocusProviderProps {
    shareFocusWithParent?: boolean;
    disabled?: boolean;
    /**
        List of global hot keys to remove. 
        It can be removed once spa conversion is complete 
    */
    hotkeysToRemove?: THotkeyCommands[];
}

export function FocusProvider({
    shareFocusWithParent = false,
    disabled,
    children,
}: React.PropsWithChildren<IFocusProviderProps>) {
    if (!window.rootFocusObserver) {
        window.rootFocusObserver = new RootFocusObserver();
    }
    const [focusLayer, setFocusLayer] = useState(
        shareFocusWithParent ? window.rootFocusObserver.currentFocusLayer : -1
    );
    const [currentGlobalFocusLayer, setCurrentGlobalFocusLayer] = useState(
        window.rootFocusObserver.currentFocusLayer
    );
    const [isHelpMenuEnabled, setIsHelpMenuEnabled] = useState(false);

    useEffect(() => {
        if (isHelpMenuEnabled) {
            const overlayKeyUp = (e: KeyboardEvent) => {
                if (e.key === "/" || e.key === Key.Control || e.key === Key.Meta) {
                    setIsHelpMenuEnabled(false);
                }
            };
            window.addEventListener("keyup", overlayKeyUp);
            return () => {
                window.removeEventListener("keyup", overlayKeyUp);
            };
        }
        return () => {};
    }, [isHelpMenuEnabled]);

    useEffect(() => {
        const ref: IRootFocusListener = {
            onFocusLayerChange: (currentFocusLayer) => {
                setCurrentGlobalFocusLayer(currentFocusLayer);
            },
        };

        window.rootFocusObserver.registerListener(ref);

        return () => {
            window.rootFocusObserver.unregisterListener(ref);
        };
    }, []);

    // Steal focus from parent/root contexts if !shareFocusWithParent by adding a new global focus layer
    useEffect(() => {
        if (!shareFocusWithParent && !disabled) {
            setFocusLayer(window.rootFocusObserver.addFocusLayer());

            return () => {
                window.rootFocusObserver.subtractFocusLayer();
                setFocusLayer(-1);
            };
        }

        return () => {};
    }, [shareFocusWithParent, disabled]);

    const context = useMemo<IFocusContext>(() => {
        return {
            isCurrentFocus: !disabled && currentGlobalFocusLayer === focusLayer,
            isHelpMenuEnabled: isHelpMenuEnabled,
        };
    }, [isHelpMenuEnabled, focusLayer, disabled, currentGlobalFocusLayer]);

    return (
        <FocusContext.Provider value={context}>
            <Hotkey
                onCommand={() => setIsHelpMenuEnabled(true)}
                triggerBehavior="down"
                {...HotkeyCommands.showHotkeys}
            />
            {children}
        </FocusContext.Provider>
    );
}
