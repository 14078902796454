import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { BTQueryOptions } from "helpers/AppProvider.api.handler";

import { BTLocalStorage } from "types/btStorage";

import { APIHandler } from "utilities/apiHandler";

import {
    getHeaderInfoFromResponse,
    IHeaderInfoRequest,
    IHeaderInfoResponse,
} from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import {
    HasUnreadResponse,
    MainNavigationResponse,
    UnreadNotificationCountResponse,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";

export interface IMainNavigationHandler {
    get: () => Promise<MainNavigationResponse>;
    getHeader: (request: IHeaderInfoRequest) => Promise<IHeaderInfoResponse>;
    getInitialNotificationCount: () => Promise<UnreadNotificationCountResponse>;
    getHasUnreadChat: () => Promise<HasUnreadResponse>;
}

export class MainNavigationHandler implements IMainNavigationHandler {
    async get(): Promise<MainNavigationResponse> {
        return await APIHandler(`/api/menu/`, {
            method: "GET",
            responseType: MainNavigationResponse,
        });
    }

    async getHeader(request: IHeaderInfoRequest): Promise<IHeaderInfoResponse> {
        return await APIHandler(`/api/menu/HeaderInfo`, {
            method: "GET",
            data: request,
            responseType: getHeaderInfoFromResponse,
        });
    }

    async getInitialNotificationCount(): Promise<UnreadNotificationCountResponse> {
        return await APIHandler(`/api/Notifications/UnreadCount`, {
            method: "GET",
            responseType: UnreadNotificationCountResponse,
        });
    }

    async getHasUnreadChat(): Promise<HasUnreadResponse> {
        return await APIHandler(`/api/channels/read`, {
            method: "GET",
            responseType: HasUnreadResponse,
        });
    }
}

export const NotificationCountQueryKey = "notificationCount";
export const useGetNotificationCountQuery = (
    handler: IMainNavigationHandler,
    unreadCountCacheTime: number,
    options?: BTQueryOptions<number, Error, string[]>
) => {
    return useQuery(
        [NotificationCountQueryKey],
        async () => {
            const response = await handler.getInitialNotificationCount();
            BTLocalStorage.set("bt-number-unreadNotificationCount", response.unreadCount);
            return response.unreadCount;
        },
        {
            retry: 3,
            // interval to check if count is stale and get new unread count if needed
            refetchInterval: moment.duration(unreadCountCacheTime, "seconds").asMilliseconds(),
            // time that the query lasts in cache after being unmounted
            cacheTime: moment.duration(5, "minutes").asMilliseconds(),
            // once the stale time is reached the new unread count will be retrieved on the next refresh
            staleTime: moment.duration(unreadCountCacheTime, "seconds").asMilliseconds(),
            refetchOnWindowFocus: true,
            initialData: BTLocalStorage.get("bt-number-unreadNotificationCount") ?? 0,
            ...options,
        }
    );
};

export const ChatReadQueryKey = "chatRead";
export const useGetChatQuery = (
    handler: IMainNavigationHandler,
    readPollingRateInSeconds: number,
    options?: BTQueryOptions<HasUnreadResponse, Error, string[]>
) => {
    return useQuery(
        [ChatReadQueryKey],
        async () => {
            const response = await handler.getHasUnreadChat();
            BTLocalStorage.set("bt-boolean-hasUnreadChat", response.hasUnread);
            return response;
        },
        {
            retry: 3,
            cacheTime: moment.duration(24, "hours").asMilliseconds(),
            refetchInterval: moment.duration(readPollingRateInSeconds, "seconds").asMilliseconds(),
            staleTime: moment.duration(readPollingRateInSeconds, "seconds").asMilliseconds(),
            initialData: {
                hasUnread: BTLocalStorage.get("bt-boolean-hasUnreadChat") ?? false,
                lastCheckedDateUtc: moment.utc(),
            },
            ...options,
        }
    );
};
