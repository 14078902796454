import { Component, Suspense } from "react";

import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

export class BTSuspense extends Component {
    render() {
        return (
            // Ant V3 sends through events that we are no longer handling in the form submit
            // We need to prevent webforms from refreshing due to an unhandled submit event
            // Should be removed with upgrade to V4
            <div
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="BTSuspense"
            >
                {/* eslint-disable-next-line react/forbid-elements */}
                <Suspense fallback={<BTLoading />}>{this.props.children}</Suspense>
            </div>
        );
    }
}
