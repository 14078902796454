import { IMapTabRow } from "commonComponents/entity/map/MapTab/MapTab.api.types";
import { GridEntity } from "commonComponents/utilities/Grid/GridContainer.types";

export type JobListFormActions = "newJob" | undefined;

export enum JobListTabs {
    List = 0,
    Map = 1,
}

export interface IGeoLocation {
    latitude?: number;
    longitude?: number;
}

export class JobGridEntity extends GridEntity<{}, IMapTabRow> {
    constructor(data: any) {
        super(data);
        // TODO: handle permission logic here
        this.createdJobCountForBuilder = data.createdJobCountForBuilder;
        if (data.data) {
            this.data = this.data.map((x: any) => {
                const { ...otherRows } = x;

                return Object.assign({
                    address: x.jobAddress,
                    nameLink: x.jobNameLink,
                    displayName: x.ownerDisplayName,
                    ...otherRows,
                });
            });
        }
    }

    createdJobCountForBuilder?: number;
}
