export class ScheduleNotificationResponse {}

export interface IScheduleNotificationRequest {
    comments: string;
    schedules: IScheduleNotificationRequestItem[];
    hasChanged: boolean;
}

export interface IScheduleNotificationRequestItem {
    scheduleId: number;
    userIds: number[];
    requireConfirmation: boolean;
}
