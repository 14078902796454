import { IHash } from "types/IHash";

/**
 * Gets a random integer between `min` and `max` (inclusive)
 * @param min minimum random value returned by this function
 * @param max maximum random value returned by this function
 *
 * @returns a random value between `min` and `max` (inclusive)
 */
export function getRandomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns the mathematical accuracy, significant digits after the decimal,
 * of a number
 *
 * https://stackoverflow.com/a/27865285
 *
 * @param n number whose accuracy will be returned
 *
 * @returns the mathematical accuracy of `n`, or 0 for `undefined` or infinite values
 */
export function getAccuracy(n: number | undefined) {
    if (n === undefined || !isFinite(n)) {
        return 0;
    }
    let e = 1,
        p = 0;
    while (Math.round(n * e) / e !== n) {
        e *= 10;
        p++;
    }
    return p;
}

const used: IHash<1> = {};
function getKey(n: number, asString: boolean): string | number {
    return asString ? n.toString(32) : n;
}
/**
 * return a unique id to be used and match the type.
 * Some data structures prefer a string, base32 strings are shorter.
 * numberic option for those that need numeric keys.
 * makes sure that every id is unique.
 * @param type 'string' | 'number'
 */
export function getUniqueId(type: "string" | "number"): string | number {
    const isString = type === "string";
    let value: number = Date.now();
    let key = getKey(value, isString);
    while (used[key]) {
        value += 1;
        key = getKey(value, isString);
    }
    used[key] = 1;
    return key;
}

export function minMax(value: number, min: number, max: number): number {
    return value < min ? min : value > max ? max : value;
}

export function parseIntWithDefault<AltReturnType>(
    str: string | undefined | null,
    defaultValue: number | AltReturnType
) {
    if (typeof str !== "string") {
        return defaultValue;
    }

    const asNum = Number(str);
    if (isNaN(asNum)) {
        return defaultValue;
    }

    return asNum;
}
