import { IValidator } from "types/apiResponse/apiResponse";

export const getReadOnly = function (validatorResponse: IValidator[]): any {
    return getValidatorValue(validatorResponse, "readonly", false);
};

export const getValidatorValue = function (
    validatorResponse: IValidator[],
    type: string,
    defaultValue: any
): any {
    const validator = getValidator(validatorResponse, type);

    if (validator === undefined) {
        return defaultValue;
    }

    return validator.value;
};

export const getValidator = function (validatorResponse: IValidator[], type: string): IValidator {
    return validatorResponse.filter((val: IValidator) => val.type === type)[0];
};
