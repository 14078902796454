import queryString from "query-string";
import { Component, ReactNode } from "react";

import { AppProvider } from "helpers/AppProvider";
import { AppDefaultInfo, BuilderInfo, UserInfo } from "helpers/AppProvider.types";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLocalStorage } from "types/btStorage";
import { BTLoginTypes, MenuItemName } from "types/enum";

import { getGlobalValue } from "utilities/globalValueUtils";
import { getSelectedJobId } from "utilities/jobPicker/jobPicker";
import { getRandomInteger } from "utilities/number/number";

import { IRiskInsurance } from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";
import { HeaderModals } from "commonComponents/utilities/MainNavigation/MainNavigation.types";

export const menuItemClass = "MainNavMenuItem";
export const quickAddClass = "MainNavMenuItemQuickAdd MenuLink";

interface IMainNavigationWrapperState {
    builderId: number;
    userId: number;
    allSubEmails: string;
    jobId: number;
    encryptedBuilderId: string;
    headerKey: number;
    isTemplateMode: boolean;
    username: string;
    ownerFirstLogin: boolean;
    showDataScienceSurvey: boolean;
    userDisplayName: string;
    showSubResetEmailPrompt: boolean;
    isFirstLogin: boolean;
    userJustLoggedIn: boolean;
    loginType: BTLoginTypes;
    selectedMenuItem?: MenuItemName;
    loadNewJob: boolean;
    showRoles: boolean;
    showPasswordReset: boolean;
    showTermsAndPrivacy: boolean;
    showAccountSetup: boolean;
    sessionTimeoutMinutes: number;
    headersToShow: HeaderModals[];
    riskInsurance?: IRiskInsurance;
}

interface IMainNavigationWrapperProps {
    render: (
        state: IMainNavigationWrapperState,
        setSelectedJobId: (jobId: number, orgId?: number) => Promise<void>,
        closeHeaderModalState: () => void
    ) => ReactNode;
}

interface IMainNavigationWrapperInternalProps extends IMainNavigationWrapperProps {
    builderInfo: BuilderInfo | null | undefined;
    userInfo: UserInfo | undefined;
    appDefaultInfo: AppDefaultInfo | undefined;
}

/**
 * Used on webforms list pages only
 */
class MainNavigationWrapperInternal extends Component<
    IMainNavigationWrapperInternalProps,
    IMainNavigationWrapperState
> {
    componentDidMount = async () => {
        const { builderInfo, userInfo, appDefaultInfo } = this.props;

        this.setState({
            ...getApplicationState(builderInfo!, userInfo!, appDefaultInfo!),
            loadNewJob: true,
        });

        BTLocalStorage.addChangeListener(
            "bt-object-dangerousJobPickerState",
            this.listenForJobChange
        );
    };

    componentWillUnmount() {
        BTLocalStorage.removeChangeListener(
            "bt-object-dangerousJobPickerState",
            this.listenForJobChange
        );
    }

    private listenForJobChange = () => {
        const jobId = getSelectedJobId();
        this.setState({ jobId, headerKey: this.generateHeaderKey() });
    };

    /**
     * Helper method to generate a new `itemKey` for <HeaderInfo>. It generates a random number to force header
     * to update every time job or job filter is changed
     */
    private generateHeaderKey = () => {
        return getRandomInteger(1, 100000);
    };

    private onSetSelectedJobId = async (id: number) => {
        this.setState({ jobId: id, headerKey: this.generateHeaderKey(), loadNewJob: false });
    };

    private closeHeaderModalState = () => {
        this.setState(getHeaderModalState([...this.state.headersToShow]));
    };

    render() {
        if (this.state != null) {
            return this.props.render(
                this.state,
                this.onSetSelectedJobId,
                this.closeHeaderModalState
            );
        }
        return null;
    }
}

function getHeaderModalState(headersToShow: HeaderModals[]) {
    const nextHeaderModal = headersToShow.length === 0 ? "none" : headersToShow.pop()!;
    return {
        headersToShow,
        showDataScienceSurvey: nextHeaderModal === "showDataScienceSurvey",
        showRoles: nextHeaderModal === "showRoles",
        showPasswordReset: nextHeaderModal === "showPasswordReset",
        showTermsAndPrivacy: nextHeaderModal === "showTermsAndPrivacy",
    };
}

function getApplicationState(
    builderInfo: BuilderInfo,
    userInfo: UserInfo,
    appDefaultInfo: AppDefaultInfo
) {
    const builderId = builderInfo.builderId;
    const userId = userInfo.globalUserId!;
    let jobId = getSelectedJobId();
    let jobCount =
        BTLocalStorage.get("bt-object-dangerousJobPickerState")?.selectedJobIds.length ?? 0;
    jobId = jobId === -1 ? 0 : jobId;

    const encryptedBuilderId = builderInfo.encryptedBuilderId!;
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(window.location.search);

    const isFirstLogin = qsValues.isFirstLogin === "true";
    const userJustLoggedIn = qsValues.UserJustLoggedIn === "true";

    const showRoles = getGlobalValue("showRoleGatheringSurvey") ?? false;
    const showDataScienceSurvey =
        (getGlobalValue("showQuestionModal") ?? false) && qsValues.showQuestionModal !== "false";
    const isRiskInsurancePayment = !!qsValues.isRiskInsurancePayment;
    const riskInsurance = isRiskInsurancePayment
        ? { jobId: qsValues.jobId, preapprovalId: qsValues.preapproval_id }
        : undefined;
    const selectedMenuItem: MenuItemName | undefined = window.btJScriptGlobals.selectedMenuItem // todo - comes from master pages
        ? Number(window.btJScriptGlobals.selectedMenuItem)
        : undefined;
    const isTemplateMode = window.btJScriptGlobals.isTemplateMode; // todo - this should probably be part of routes instead
    const username = userInfo.userName!;
    const userDisplayName = userInfo.fullName!;
    const allSubEmails = getGlobalValue("allSubEmails") ?? "";
    const showSubResetEmailPrompt = getGlobalValue("showSubResetEmailPrompt") ?? false;
    const loginType = userInfo.loginType;
    const ownerFirstLogin = getGlobalValue("ownerFirstLogin") ?? false;
    const showPasswordReset = isFirstLogin || (ownerFirstLogin && userJustLoggedIn);
    const sessionTimeoutMinutesOverride = appDefaultInfo.sessionTimeoutMinutes;
    const sessionTimeoutMinutes =
        sessionTimeoutMinutesOverride > 0 ? sessionTimeoutMinutesOverride : userInfo.timeoutMinutes;
    const isBuilderImpersonatingOwner = userInfo.isBuilderImpersonatingOwner;
    const hasAcceptedTermsAndPolicy = getGlobalValue("hasAcceptedTermsAndPolicy") ?? false;
    const isImpersonatingBuilder = userInfo.isImpersonatingBuilder;
    const notImpersonatingUser = !isImpersonatingBuilder && !isBuilderImpersonatingOwner;
    const showTermsAndPrivacy = notImpersonatingUser && !hasAcceptedTermsAndPolicy;

    const headersToShow: HeaderModals[] = [];
    if (showDataScienceSurvey) headersToShow.push("showDataScienceSurvey");
    if (showRoles) headersToShow.push("showRoles");
    if (showPasswordReset) headersToShow.push("showPasswordReset");
    if (showTermsAndPrivacy) headersToShow.push("showTermsAndPrivacy");

    const headerState = getHeaderModalState(headersToShow);

    return {
        builderId,
        userId,
        allSubEmails,
        jobId,
        encryptedBuilderId,
        headerKey: jobId + jobCount,
        isTemplateMode,
        username,
        ownerFirstLogin,
        userDisplayName,
        showSubResetEmailPrompt,
        isFirstLogin,
        userJustLoggedIn,
        selectedMenuItem,
        loginType,
        sessionTimeoutMinutes,
        riskInsurance,
        ...headerState,
    };
}

export const MainNavigationWrapper = (props: IMainNavigationWrapperProps) => {
    return (
        <AppProvider deferIntercomLoad>
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <UserInfoContext.Consumer>
                        {(userInfo) => (
                            <AppDefaultInfoContext.Consumer>
                                {(appDefaultInfo) => (
                                    <MainNavigationWrapperInternal
                                        {...props}
                                        builderInfo={builderInfo}
                                        userInfo={userInfo}
                                        appDefaultInfo={appDefaultInfo}
                                    />
                                )}
                            </AppDefaultInfoContext.Consumer>
                        )}
                    </UserInfoContext.Consumer>
                )}
            </BuilderInfoContext.Consumer>
        </AppProvider>
    );
};
