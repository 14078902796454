export enum PromptState {
    New = "New",
    Shown = "Shown",
    Dismissed = "Dismissed",
    Failed = "Failed",
}

interface INotificationPermissionPromptStateNew {
    state: PromptState.New;
}

interface INotificationPermissionPromptStateShown {
    state: PromptState.Shown;
}

interface INotificationPermissionPromptStateDismissed {
    state: PromptState.Dismissed;
    expiresOn?: number;
}

interface INotificationPermissionPromptStateFailed {
    state: PromptState.Failed;
}

export type NotificationPermissionPromptState =
    | INotificationPermissionPromptStateNew
    | INotificationPermissionPromptStateShown
    | INotificationPermissionPromptStateDismissed
    | INotificationPermissionPromptStateFailed;
