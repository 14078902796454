import { message, Spin } from "antd";

import { BTSpin } from "commonComponents/btWrappers/BTSpin/BTSpin";

/* Place messages just below the details sticky header - note: we need to revisit this when we get to list pages */
message.config({
    duration: 2,
    maxCount: 3,
});

Spin.setDefaultIndicator(<BTSpin />);
