export enum PinTypes {
    None = 0,
    Default = 1,
    Inactive = 2,
    Edited = 3,
    Locked = 4,
    Warning = 5,
    CurrentLocation = 6,
}

export interface IMark {
    id: string;
    position: IMapPosition;
    pinType: PinTypes;
    /**
     * Radius in meters. We always store this in the db in meters, regardless of the builder's geofence preference.
     * No special effort should be required to convert between meters/km/miles
     */
    radius?: number;
    /**
     * color should be a valid hex color
     * @example #ff6600
     */
    radiusColor?: string;
    animate?: boolean;
    /**
     * Please use <InfoWindowContent> to format your content correctly
     */
    infoWindowContent?: JSX.Element;
    /**
     * Relative size of the map markers. Default is 32px by 32px
     */
    size?: { height: number; width: number };
    /**
     * Displays as a normal html title. For a full popup / popover, use infoWindowContent
     */
    title?: string;
}

export class Mark implements IMark {
    constructor(data: IMark) {
        this.id = data.id;
        this.position = data.position;
        this.pinType = data.pinType;
        this.radius = data.radius;
        this.radiusColor = data.radiusColor;
        this.animate = data.animate;
        this.infoWindowContent = data.infoWindowContent;
        this.size = data.size;
        this.title = data.title;
    }
    id: string;
    position: IMapPosition;
    pinType: PinTypes;
    radius?: number | undefined;
    radiusColor?: string | undefined;
    animate?: boolean | undefined;
    infoWindowContent?: JSX.Element | undefined;
    size?: { height: number; width: number } | undefined;
    title?: string;
}

export interface IMapPosition {
    lat: number;
    lng: number;
}

export interface IInfoWindowContentProps {
    header?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    buttonText?: string;
    buttonCallback?: () => void;
    pinType?: PinTypes;
}
