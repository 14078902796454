import moment from "moment";

import { AssignedUserExtraData, BTSelectItem } from "types/apiResponse/apiResponse";
import { CostType, CostTypes, POApprovalStatus, POMappingEntityTypes } from "types/enum";
import { StatusObject } from "types/statusObject";

import { getByValue } from "utilities/form/form";

import { GlobalUserSelectMapping } from "commonComponents/entity/globalUserSelector/GlobalUserSelector/GlobalUserSelector";
import { IVarianceCodeExtraData } from "commonComponents/entity/variance/Variance/Variance.api.types";
import { DefaultViews } from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";

export enum PoMappingSteps {
    LineItemAssignment,
    DetailConfiguration,
    CompletionList,
}

export interface IPoLineItemIdTypeMapping {
    id: number;
    lineItemType: POMappingEntityTypes;
}

export type POMappingFormActions = undefined | "finish" | "cancel" | "next" | "previous";

export const NotAVarianceValue = -2;

export interface IPoMappingCreationRequest {
    mappingItems: LineItemPoAssignedUserMapping[];
    mappingIdsAndTitles: AssignedUserPoIdTitleMapping[];
    descriptions: Record<number, string>;
    varianceCodeId: number;
    createSeparatePOsForEachLineItem: boolean;
    updatePendingUpdates: boolean;
    entityType: POMappingEntityTypes;
    relatedChangeOrder: number | null;
    proposed: boolean;
    jobId: number;
}

export class LineItemPoAssignedUserMapping {
    constructor(data: any) {
        this.lineItemId = data.lineItemId;
        this.assignedUserId = data.assignedUserId;
        this.builderCost = data.builderCost;
        this.quantity = data.quantity;
        this.unitType = data.unitType;
        this.unitCost = data.unitCost;
        this.title = data.title;
        this.description = data.description;
        this.lineDescription = data.lineDescription;
        this.costCodeTitle = data.costCodeTitle;
        this.includeDescriptions = data.includeDescriptions;
        this.includeFilesAndPhotos = data.includeFilesAndPhotos;
        this.lineItemParentId = data.lineItemParentId;
        this.lineItemGrandParentId = data.lineItemGrandParentId;
        this.lineItemParentTitle = data.lineItemParentTitle;
        this.lineItemParentDescription = data.lineItemParentDescription;
        this.lineItemGrandParentDisplayTitle = data.lineItemGrandParentDisplayTitle;
        this.internalNotes = data.internalNotes;
        this.titleForMapping = data.titleForMapping;
        this.assignToId = data.assignToId;
        this.entityType = data.entityType;
        this.costTypes = data.costTypes;
    }
    lineItemId: number;
    assignedUserId: number;
    builderCost: number;
    quantity: number;
    unitType: string;
    unitCost: number;
    title: string;
    description: string;
    lineDescription: string;
    costCodeTitle: string;
    includeDescriptions: boolean;
    includeFilesAndPhotos: boolean;
    lineItemParentId: number;
    lineItemParentTitle: string;
    lineItemParentDescription: string;
    lineItemGrandParentId: number;
    lineItemGrandParentDisplayTitle: string;
    internalNotes: string;
    titleForMapping: string;
    assignToId: number;
    entityType: POMappingEntityTypes;
    costTypes: CostType[] | null;
}

export class AssignedUserPoIdTitleMapping {
    assignedUserId: number;
    poId: string;
    title: string;
    lineItemId: number | undefined;
    estimatedCompletionDate: string;
}

export class PoMappingCompletionList {
    constructor(data: any) {
        this.purchaseOrders = data.purchaseOrders.map((po: any) => new CompletedPoMapping(po));
        this.toExistingPo = data.toExistingPo;
    }
    purchaseOrders: CompletedPoMapping[];
    toExistingPo: boolean;
}

export class CompletedPoMapping {
    constructor(data: any) {
        this.poId = data.poId;
        this.poName = data.poName;
        this.title = data.title;
        this.assignedTo = data.assignedTo;
        this.total = data.total;
        this.statusObj = new StatusObject(data.statusObj);
        this.jobId = data.jobId;
        this.subApprovalStatus = data.subApprovalStatus;
    }
    poId: number;
    poName: string;
    title: string;
    assignedTo: string;
    total: number;
    statusObj: StatusObject;
    jobId: number;
    subApprovalStatus: POApprovalStatus;
}

export interface IPoUserMappingRequest {
    toExistingPo: boolean;
    jobId: number;
    createSeparatePosForEachLineItem: boolean;
    updateLinkToCostItemPendingUpdates: boolean;
    proposed: boolean;
}

export interface IPoMappingRequest {
    idTitleMapping: IPoLineItemIdTypeMapping[];
    jobId: number;
    toExistingPo: boolean;
    proposed: boolean;
}

export class PoMappingResponse {
    constructor(data: any) {
        this.entities = data.entities.map((entity: any) => new PoMappingEntity(entity));
        this.purchaseOrders = data.purchaseOrders.map((x: any) => new POListForMapping(x));
        this.performingUsers = data.performingUsers
            ? data.performingUsers.options.map((user: any) =>
                  GlobalUserSelectMapping(user, data.performingUsers.value[0])
              )
            : null;
        this.varianceCodes = data.varianceCodes
            ? data.varianceCodes.map((code: any) => new BTSelectItem(code))
            : null;
        this.canEditCostCodes = data.canEditCostCodes;
        this.createSeparatePo = false;
        this.jobPrefix = data.jobPrefix;
        this.shouldCreateSeperatePOsWithWizard = data.shouldCreateSeperatePOsWithWizard;
        this.usedPONumbers = new Set<string>(data.usedPONumbers);
        this.defaultViews = new DefaultViews(data.defaultViews);
        this.individualPoLimit = data.individualPoLimit;
        this.individualPoLimitFormatted = data.individualPoLimitFormatted;
        this.jobPoLimit = data.jobPoLimit;
        this.jobPoLimitFormatted = data.jobPoLimitFormatted;
        this.currentPoTotalOnJob = data.currentPoTotalOnJob;
        this.hasNoPriceLimitPermission = data.hasNoPriceLimitPermission;
        this.hasCostCodesFeature = data.hasCostCodesFeature;
    }

    entities: PoMappingEntity[];
    purchaseOrders: POListForMapping[];
    performingUsers: BTSelectItem<AssignedUserExtraData>[] | null;
    varianceCodes: BTSelectItem<IVarianceCodeExtraData>[] | null;
    canEditCostCodes: boolean;
    createSeparatePo: boolean;
    jobPrefix: string;
    shouldCreateSeperatePOsWithWizard: boolean;
    usedPONumbers: Set<string>;
    defaultViews: DefaultViews;
    individualPoLimit: number | null;
    individualPoLimitFormatted: string | null;
    jobPoLimit: number | null;
    jobPoLimitFormatted: string | null;
    currentPoTotalOnJob: number;
    hasNoPriceLimitPermission: boolean;
    hasCostCodesFeature: boolean;
}

export class PoMappingEntity {
    constructor(data: any) {
        this.id = data.id;
        this.childId = data.childId;
        this.title = data.title;
        this.description = data.description;
        this.displayTitle = data.displayTitle;
        this.internalNotes = data.internalNotes;
        if (data.lineItems) {
            this.lineItems = data.lineItems.map((item: any) => new PoMappingLineItem(item));
        }
        this.subId = data.subId;
        this.subName = data.subName;
        this.subHasAccessToJob = data.subHasAccessToJob;
        this.hasCostItemUpdates = data.hasCostItemUpdates;
        this.vendors = data.vendors;
        this.entityType = data.entityType;
    }
    id: number;
    /** ChildId is exclusive to selections and selection choices.
     * Whereas the Id is used to store the selection id, the ChildId is used to store the selection choice id. */
    childId: number;
    title: string;
    description: string;
    displayTitle: string;
    internalNotes: string;
    lineItems: PoMappingLineItem[];
    subId: number;
    subName: string;
    subHasAccessToJob: boolean;
    hasCostItemUpdates: boolean;
    vendors: number[];
    entityType: POMappingEntityTypes;
}

export class PoMappingLineItem {
    constructor(data: any) {
        this.lineItemId = data.lineItemId;
        this.unitCost = data.unitCost;
        this.quantity = data.quantity;
        this.description = data.description;
        this.builderCost = data.builderCost;
        this.costCodeTitle = data.costCodeTitle;
        this.unitType = data.unitType;
        this.title = data.title;
        this.internalNotes = data.internalNotes;
        this.includeDescription = data.includeDescription;
        this.includeFiles = data.includeFiles;
        this.parentEntityDescription = data.parentEntityDescription;
        this.parentEntityId = data.parentEntityId;
        this.parentEntityTitle = data.parentEntityTitle;
        this.grandparentEntityId = data.grandparentEntityId;
        this.grandparentEntityTitle = data.grandparentEntityTitle;
        this.grandparentEntityDisplayTitle = data.grandparentEntityDisplayTitle;
        this.entityType = data.entityType;
        this.vendorProductId = data.vendorProductId;
        this.costTypes = data.costTypes ?? [];
    }
    lineItemId: number;
    unitCost: number;
    quantity: number;
    description: string;
    builderCost: number;
    costCodeTitle: string;
    unitType: string;
    title: string;
    internalNotes: string;
    includeDescription: boolean;
    includeFiles: boolean;
    parentEntityDescription: string;
    parentEntityId: number;
    parentEntityTitle: string;
    grandparentEntityId: number;
    grandparentEntityTitle: string;
    grandparentEntityDisplayTitle: string;
    entityType: POMappingEntityTypes;
    vendorProductId: number | null;
    costTypes: CostTypes[];
}

export class PoForMappingExtraData {
    lineItemTotal: number;
}

class POListForMapping {
    constructor(data: any) {
        this.assignedToSubId = data.assignedToSubId;
        this.isDefault = data.isDefault;
        this.poGroupedList = data.poGroupedList.options.map(
            (x: any) => new BTSelectItem<PoForMappingExtraData>(x)
        );
    }
    assignedToSubId: number | null;
    isDefault: boolean;
    poGroupedList: BTSelectItem<PoForMappingExtraData>[];
}

export interface IPoMappingLineItemAssignmentFormValues {
    data: (EntityAssignment | LineItemAssignment)[];
    varianceCode: number;
    createSeparatePo: boolean;
    shouldUpdatePendingUpdates: boolean;
    entities: PoMappingEntity[];
}

export class EntityAssignment {
    constructor(data: any) {
        this.title = data.title;
        this.displayTitle = data.displayTitle;
        this.id = data.id;
        this.childId = data.childId;
        this.description = data.description;

        /** Get the selection choice title:
         * - Only bids and selections have parentEntityId
         * - Of those two, only selections have a parentEntityTitle (the selection choice title)
         */
        const lineItem = data.lineItems[0];
        if (lineItem.parentEntityId && lineItem.parentEntityTitle) {
            this.childTitle = lineItem.parentEntityTitle;
        }
    }
    id: number;
    description: string;
    title: string;
    displayTitle: string;
    /** For selections, childTitle and childId will be assigned to a selection choice title and id,
     * otherwise for all other entities it will be assigned to empty string (title) or undefined (id).*/
    childTitle?: string;
    childId?: number;
    isChecked: boolean = true;
}

export class LineItemAssignment {
    constructor(
        data: any,
        entity: PoMappingEntity,
        isToExistingPo: boolean,
        performingUsers: BTSelectItem<AssignedUserExtraData>[] | null
    ) {
        this.id = data.lineItemId;
        this.unitCost = data.unitCost;
        this.quantity = data.quantity;
        this.description = data.description;
        this.builderCost = data.builderCost;
        this.costCodeTitle = data.costCodeTitle;
        this.unitType = data.unitType;
        this.title = data.title;
        this.internalNotes = data.internalNotes;
        this.entityType = entity.entityType;
        this.vendorProductId = data.vendorProductId;
        this.costTypes = data.costTypes ?? [];

        /* If the parent entity is a Selection Choice or a Bid Request... */
        if (data.parentEntityId > 0) {
            /* then set the parent id to the selection choice or bid request id */
            this.parentEntityId = data.parentEntityId;
            this.parentEntityTitle = data.parentEntityTitle;
            this.parentEntityDescription = entity.description;
            /* and set the grandparent id to the selection or bid package id */
            this.grandparentEntityId = entity.id;
            this.grandparentEntityTitle = entity.title;
            this.grandparentEntityDisplayTitle = entity.displayTitle;
        } else {
            /* Otherwise the direct parent will be the change order or estimate */
            this.parentEntityId = entity.id;
            this.parentEntityTitle = entity.title;
        }

        /* If subId > 0, we are working with a bid request */
        if (entity.subId > 0) {
            this.parentEntityTitle = entity.title;
            if (isToExistingPo) {
                this.subId = entity.subId;
                this.performingUserName = entity.subName;
                this.assignedUserHasJobAccess = entity.subHasAccessToJob;
            } else {
                this.assignToId = entity.subId;
                this.performingUserName = entity.subName;

                /* Search for the user assigned to this bid request and see if they have job access */
                const performingUser = getByValue(performingUsers!, entity.subId);
                if (performingUser) {
                    this.assignedUserHasJobAccess = performingUser.extraData!.hasJobAccess;
                }
            }
        }
    }

    id: number;
    unitCost: number;
    quantity: number;
    description: string;
    builderCost: number;
    costCodeTitle: string;
    unitType: string;
    costTypes: CostTypes[];
    includeDescription: boolean = true;
    includeFiles: boolean = true;
    isChecked: boolean = true;

    entityType: POMappingEntityTypes;

    // Default all dropdown values to "Unassigned", where an "Unassigned"
    // user is treated as a user that has jobsite access
    assignToId: number = -1;
    subId: number = -1; // only relevant for bid requests, it is the subId
    // of the bid request that the line item came from
    assignedUserHasJobAccess: boolean = true;
    performingUserName: string;

    /** The parent entity is either a change order id or estimate id.
     * In the case of selections, it will be the seection choice id.
     * In the case of bids, it will be the bid request id.
     */
    parentEntityId: number;
    parentEntityTitle: string;
    /** Used for selections: holds the selection choice description */
    parentEntityDescription: string;

    /** The grandparent entity is only relevant for bids and selections.
     * For selections this will be the selection id (as opposed to the selection choice id).
     * For bids this will be the bid package id (as opposed to the bid request id).
     */
    grandparentEntityId: number;
    grandparentEntityTitle: string;
    /** Used for selections: the grandparent entity display title is the selection title + location information.
     * It is pulled directly from its associated EntityAssignment object.
     */
    grandparentEntityDisplayTitle: string;

    title: string;
    internalNotes: string;
    vendorProductId: number | null;
}

export class PoMappingDetailConfigurationFormValues {
    constructor(data: any) {
        this.varianceCode = data.varianceCode;
        this.purchaseOrders = data.purchaseOrders;
        this.createSeparatePosForEachLineItem = data.createSeparatePosForEachLineItem;
        this.shouldUpdatePendingUpdates = data.shouldUpdatePendingUpdates;
    }
    varianceCode: number;
    shouldUpdatePendingUpdates: boolean;
    createSeparatePosForEachLineItem: boolean;
    purchaseOrders: PoMappingDetailPurchaseOrder[];
}

export class PoMappingDetailPurchaseOrder {
    constructor(data: any) {
        this.entityId = data.entityId;
        this.poTitle = data.poTitle;
        this.poNumber = data.poNumber;
        this.displayTitle = data.displayTitle;
        this.description = data.description;
        this.performingUserTitle = data.performingUserTitle;
        this.performingUserId = data.performingUserId;
        this.estimatedCompletion = data.estimatedCompletion;
        this.lineItems = data.lineItems;
    }
    entityId: number;
    poTitle: string;
    poNumber: string;
    displayTitle: string;
    description: string;
    performingUserTitle: string;
    performingUserId: number;
    estimatedCompletion: moment.Moment;
    lineItems: PoMappingLineItem[];
}
