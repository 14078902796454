import { Divider } from "antd";

import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    GlobalSearchFilters,
    GlobalSearchResult,
    IGlobalSearchFilterValues,
    IGlobalSearchState,
} from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { GlobalSearchEmptyState } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchEmptyState/GlobalSearchEmptyState";
import { GlobalSearchAllFilters } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchFilters/GlobalSearchAllFilters";
import { GlobalSearchQuickFilters } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchFilters/GlobalSearchQuickFilters";
import { GlobalSearchResults } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchResults/GlobalSearchResults";

interface IGlobalSearchDropdownProps {
    searchState: IGlobalSearchState;
    isSearching: boolean;
    selectedResultIndex: number;
    filters: GlobalSearchFilters | undefined;
    selectedFilters: IGlobalSearchFilterValues;
    onFilterChange: (filters: IGlobalSearchFilterValues) => void;
    onResultClick: (item: GlobalSearchResult) => void;
    showAllFilters: boolean;
    onShowAllFilters: (value: boolean) => void;
}

export const GlobalSearchDropdown: React.FunctionComponent<IGlobalSearchDropdownProps> = ({
    searchState,
    isSearching,
    selectedResultIndex,
    filters,
    selectedFilters,
    onFilterChange,
    onResultClick,
    showAllFilters,
    onShowAllFilters,
}) => {
    const showLoadingSpinner =
        isSearching || (searchState.error && searchState.error.isAbortedRequest);

    const showEmptyState =
        !showLoadingSpinner && (!searchState.results || searchState.results.length === 0);

    const hasResults = !showEmptyState;

    const loadingMessages = [
        "Hang tight! We're scouring the depths of our database to find the best results for you.",
        "Searching high and low for the information you need. Just a few more moments and we'll present you with the most relevant results.",
        "Our search engine is hard at work, sifting through a vast amount of data to bring you the most accurate results. It won't be much longer now.",
        "Our search algorithm is working diligently to serve up the best possible results for your query.",
    ];

    if (showAllFilters) {
        return (
            <div className="GlobalSearch">
                <GlobalSearchAllFilters
                    filters={filters}
                    selectedFilters={selectedFilters}
                    onFilterChange={onFilterChange}
                    onClose={(filters) => {
                        if (filters) {
                            onFilterChange(filters);
                        }
                        onShowAllFilters(false);
                    }}
                />
            </div>
        );
    }

    return (
        <div className="GlobalSearch">
            <GlobalSearchQuickFilters
                filters={filters}
                selectedFilters={selectedFilters}
                onFilterChange={onFilterChange}
                onShowAllFilters={() => onShowAllFilters(true)}
                showSuggestedFilters={searchState.performedAtLeastOneSuccessfulSearch}
            />
            <Divider style={{ margin: 0 }} />
            <>
                {showLoadingSpinner && (
                    <div style={{ height: "480px" }}>
                        <BTLoading
                            displayMode="absolute"
                            loadingMessages={loadingMessages}
                            loadingMessageDelay={4000}
                            showLoadingBackground={false}
                        />
                    </div>
                )}
                {showEmptyState && <GlobalSearchEmptyState searchState={searchState} />}
                {hasResults && (
                    <GlobalSearchResults
                        isSearching={isSearching}
                        results={searchState.results}
                        selectedResultIndex={selectedResultIndex}
                        onResultClick={onResultClick}
                        resultText={searchState.searchValue === "" ? "Recently Viewed" : "Results"}
                        showBadge={searchState.searchValue !== ""}
                    />
                )}
            </>
        </div>
    );
};
