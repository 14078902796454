import { Component } from "react";
import { Route, RouteComponentProps } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTDropdownActions } from "commonComponents/btWrappers/BTDropdownActions/BTDropdownActions";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTIconWarningWarning } from "commonComponents/btWrappers/BTIcon";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import {
    IViewSwitcherInfo,
    ScheduleConflict,
    ScheduleConflictResponse,
    ScheduleConflictViews,
} from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import ScheduleConflictTable from "commonComponents/entity/scheduleConflicts/ScheduleConflictTable";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { IScheduleUrlProps } from "entity/schedule/Schedule/RouteSchedule";

const Schedule = lazyLoadWithRetry(() => import("entity/schedule/Schedule/Schedule"));

interface IScheduleConflictReportPresentationalProps extends RouteComponentProps {
    data: ScheduleConflictResponse;
    view: ScheduleConflictViews;
    startingView: ScheduleConflictViews;
    showPastDates: boolean;
    id?: number;
    loading?: boolean;
    jobsForDropdown?: IViewSwitcherInfo[];
    usersForDropdown?: IViewSwitcherInfo[];
    modalConfig?: IModalConfiguration;
    onContinue?: () => void;
    onSetConflictThreshold: (userId: number, updatedNumberOfConflicts: number) => void;
    onShowPastDatesChanged: (newValue: boolean) => void;
    onScheduleItemChanged: () => void;
    onViewSwitched: (newView: ScheduleConflictViews, newId?: number) => void;
}

export default class ScheduleConflictReportPresentational extends Component<IScheduleConflictReportPresentationalProps> {
    private getTitle() {
        if (this.props.data.count === 0) {
            return "No Conflicts Found";
        }
        const userWithConflicts = this.props.data.conflicts[0];
        switch (this.props.view) {
            case ScheduleConflictViews.All:
                return "Schedule Conflicts for All Jobs";
            case ScheduleConflictViews.User:
                return `Schedule Conflicts for User: '${userWithConflicts.name}'`;
            case ScheduleConflictViews.Job:
                return `Schedule Conflicts for Job: '${userWithConflicts.conflicts[0].jobName}'`;
            case ScheduleConflictViews.Schedule:
                const scheduleConflict = userWithConflicts.conflicts.find(
                    (c) => c.scheduleId === this.props.id
                );
                return `Conflicts with Schedule Item: '${scheduleConflict!.title}'`;
        }
    }

    private renderConflictTitle = (record: ScheduleConflict) => {
        return (
            <BTLink
                title={record.title}
                to={this.props.match.url + `/Schedule/${record.scheduleId}/${record.jobsiteID}`}
            >
                {record.title}
            </BTLink>
        );
    };

    private getRouteProps = (routeProps: RouteComponentProps<IScheduleUrlProps>) => ({
        id: Number(routeProps.match.params.id),
        jobId: Number(routeProps.match.params.jobId),
        modalConfig: {
            parentRoute: this.props.match.url,
            beforeClose: () => {
                this.props.history.replace(
                    this.props.match.url +
                        `/ScheduleConflictReport/${this.props.view}/${this.props.id}`
                );
                this.props.onScheduleItemChanged();
            },
        },
        ...routeProps,
    });

    private renderViewSwitcher() {
        const { startingView, onViewSwitched, jobsForDropdown, usersForDropdown } = this.props;
        const showJob =
            (startingView === ScheduleConflictViews.User ||
                startingView === ScheduleConflictViews.Schedule) &&
            jobsForDropdown !== undefined &&
            jobsForDropdown.length > 0;
        const showSchedule =
            startingView === ScheduleConflictViews.Schedule &&
            usersForDropdown !== undefined &&
            usersForDropdown.length > 0;

        if (startingView === ScheduleConflictViews.All) {
            return <></>;
        }

        const dropdownsItems = (
            <BTMenu>
                <BTMenuItem
                    data-testid="allConflicts"
                    onClick={() => onViewSwitched(ScheduleConflictViews.All)}
                >
                    All Schedule Conflicts
                </BTMenuItem>
                {showJob &&
                    jobsForDropdown?.map((info) => (
                        <BTMenuItem
                            data-testid={`job_${info.id}`}
                            key={`job_${info.id}`}
                            onClick={() => onViewSwitched(ScheduleConflictViews.Job, info.id)}
                        >
                            Job: {info.name}
                        </BTMenuItem>
                    ))}
                {showSchedule &&
                    usersForDropdown?.map((info) => (
                        <BTMenuItem
                            data-testid={`user_${info.id}`}
                            key={`user_${info.id}`}
                            onClick={() => onViewSwitched(ScheduleConflictViews.User, info.id)}
                        >
                            {info.name}
                        </BTMenuItem>
                    ))}
            </BTMenu>
        );

        return (
            <BTDropdownActions overlay={dropdownsItems} type="primary" data-testid="switchView">
                Switch View
            </BTDropdownActions>
        );
    }

    render() {
        const title = this.getTitle();

        return (
            <>
                <BTModalLayout
                    title={title}
                    footerContent={
                        <>
                            {this.props.onContinue !== undefined && (
                                <BTButton
                                    id="continue"
                                    data-testid="continue"
                                    type="primary"
                                    onClick={this.props.onContinue}
                                    disabled={this.props.loading}
                                >
                                    Continue
                                </BTButton>
                            )}
                            {this.renderViewSwitcher()}
                        </>
                    }
                    modalConfig={this.props.modalConfig}
                >
                    <BTForm>
                        <BTLayoutContent>
                            <PageSection
                                title={
                                    <div className="ConflictSectionTitle">
                                        <BTIconWarningWarning size={28} />
                                        <BTTitle level={2} className="margin-left-sm">
                                            {/* eslint-disable-next-line react/forbid-dom-props */}
                                            <span title={title}>{title}</span>
                                        </BTTitle>
                                    </div>
                                }
                            >
                                <BTFormItem>
                                    <BTCheckbox
                                        id="showPastDates"
                                        data-testid="showPastDates"
                                        checked={this.props.showPastDates}
                                        onChange={(e) =>
                                            this.props.onShowPastDatesChanged(e.target.checked)
                                        }
                                        disabled={this.props.loading}
                                    >
                                        Show Past Dates
                                    </BTCheckbox>
                                </BTFormItem>
                                <ScheduleConflictTable
                                    conflicts={this.props.data.conflicts}
                                    renderConflictTitle={this.renderConflictTitle}
                                    onSetConflictThreshold={this.props.onSetConflictThreshold}
                                    onViewItemsClick={(id) =>
                                        this.props.onViewSwitched(
                                            ScheduleConflictViews.Schedule,
                                            id
                                        )
                                    }
                                    onGroupHeaderClick={(id) =>
                                        this.props.onViewSwitched(ScheduleConflictViews.User, id)
                                    }
                                    onJobNameClick={(id) =>
                                        this.props.onViewSwitched(ScheduleConflictViews.Job, id)
                                    }
                                    loading={this.props.loading}
                                    activeId={
                                        this.props.view === ScheduleConflictViews.Schedule
                                            ? this.props.id
                                            : undefined
                                    }
                                    view={this.props.view}
                                />
                            </PageSection>
                        </BTLayoutContent>
                    </BTForm>
                </BTModalLayout>
                <Route
                    path={this.props.match.url + "/Schedule/:id/:jobId"}
                    render={(routeProps) => (
                        <BTSuspense>
                            <Schedule {...this.getRouteProps(routeProps)} />
                        </BTSuspense>
                    )}
                />
            </>
        );
    }
}
