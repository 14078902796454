import {
    ICostCode,
    ILineItemResponse,
    LineItemType,
    PriceTypes,
} from "legacyComponents/LineItemContainer.types";
import { cloneDeep } from "lodash-es";

import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler, PreviewActionAPIError } from "utilities/apiHandler";
import { getOwnerPriceRawTotal } from "utilities/lineItem/lineItemHelper";

import {
    CommentPermissions,
    DiscussionCommentEntity,
} from "commonComponents/entity/comment/CommentContainer/CommentContainer.api.types";
import { IAddInvoiceAttachmentMappingRequest } from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoice.api.types";
import { TaxValidationAlertResponse } from "commonComponents/financial/Common/Accounting.types";

import { FilesFromExternalUrlsResponse } from "entity/document/Document/Document.api.types";
import { InvoiceStatus } from "entity/ownerInvoice/common/OwnerInvoiceStatus/OwnerInvoiceStatus";
import { OwnerInvoiceLineItem } from "entity/ownerInvoice/OwnerInvoiceLineItemContainer/OwnerInvoiceLineItemContainer.types";

import {
    IOwnerInvoiceFormValues,
    ISaveOwnerInvoiceOwnerPreviewDisplayPreferencesRequest,
    OwnerInvoiceAccountingResponse,
    OwnerInvoiceEntity,
    OwnerInvoiceLinkResponse,
    OwnerInvoiceRelatedEntityIDsForAttachments,
    OwnerInvoiceSaveResponse,
    UnstackLineItemRequest,
    UnstackLineItemResponse,
} from "./OwnerInvoice.api.types";

export interface IOwnerInvoiceHandler {
    get(
        id: number,
        relatedParentIDsForAttachments: OwnerInvoiceRelatedEntityIDsForAttachments | null
    ): Promise<OwnerInvoiceEntity>;
    getDefault(
        jobId: number,
        relatedParentIDsForAttachments: OwnerInvoiceRelatedEntityIDsForAttachments | null
    ): Promise<OwnerInvoiceEntity>;
    getOwnerInvoiceLink(id: number, builder: number): Promise<OwnerInvoiceLinkResponse>;
    create(
        newValues: IOwnerInvoiceFormValues,
        jobId: number,
        newStatus?: InvoiceStatus,
        setOwnerEmail?: boolean
    ): Promise<OwnerInvoiceSaveResponse>;
    update(
        id: number,
        formData: IOwnerInvoiceFormValues,
        newStatus?: InvoiceStatus,
        setOwnerEmail?: boolean
    ): Promise<OwnerInvoiceSaveResponse>;
    delete(id: number): Promise<EmptyResponseEntity>;
    resetToPending(id: number, notifyOwner: boolean): Promise<EmptyResponseEntity>;
    notify(id: number, ownerEmail?: string): Promise<EmptyResponseEntity>;
    removeFromAccounting(id: number): Promise<EmptyResponseEntity>;
    checkSalesTax(builderId: number, costCodeIds: number[]): Promise<TaxValidationAlertResponse>;
    sendToAccounting(
        id: number,
        formData: IOwnerInvoiceFormValues
    ): Promise<OwnerInvoiceAccountingResponse>;
    resetOnlinePaymentAccountingSync(merchantPaymentId: number): Promise<EmptyResponseEntity>;
    saveOwnerPreviewDisplayPreferences(
        id: number,
        preferences: ISaveOwnerInvoiceOwnerPreviewDisplayPreferencesRequest
    ): Promise<TaxValidationAlertResponse>;
    getUnstackedLineItems(
        lineItemIds: number[],
        newLineItemId: number,
        lineItemType: LineItemType,
        markupPercent?: number
    ): Promise<UnstackLineItemResponse>;
    getEntityAttachmentsToInvoice(
        jobId: number,
        entityIds?: number[]
    ): Promise<FilesFromExternalUrlsResponse>;
}

export class OwnerInvoiceHandler implements IOwnerInvoiceHandler {
    generateEditRequest = (
        formData: IOwnerInvoiceFormValues,
        newStatus?: InvoiceStatus,
        setOwnerEmail?: boolean
    ) => {
        const useLineItems = formData.priceType === PriceTypes.LineItems;
        let amount;
        if (useLineItems) {
            amount = getOwnerPriceRawTotal(formData.lineItems);
        } else {
            // flat fee just uses builder cost field
            amount = formData.amount;
        }
        return {
            ...formData,
            useLineItems: useLineItems,
            lineItems: {
                updated: formData.lineItems,
            },
            status: newStatus,
            amount: amount,
            ownerEmail: setOwnerEmail ? formData.ownerEmail : undefined,
            invoicedFromEntity: false,
        };
    };

    async getEntityAttachmentsToInvoice(
        jobId: number,
        entityIds?: number[]
    ): Promise<FilesFromExternalUrlsResponse> {
        const request: IAddInvoiceAttachmentMappingRequest = {
            jobId,
            entityIds,
        };
        return await APIHandler(`/api/LineItems/EntityAttachmentsToInvoice`, {
            method: "GET",
            data: request,
            responseType: FilesFromExternalUrlsResponse,
        });
    }

    async get(
        id: number,
        relatedParentIDsForAttachments: OwnerInvoiceRelatedEntityIDsForAttachments | null
    ): Promise<OwnerInvoiceEntity> {
        return await APIHandler(`/api/Payments/${id}`, {
            data: {
                relatedParentIDsForAttachments,
            },
            method: "GET",
            responseType: OwnerInvoiceEntity,
        });
    }

    async getOwnerInvoiceLink(id: number, builder: number): Promise<OwnerInvoiceLinkResponse> {
        const data = { invoiceId: id, builderId: builder };

        return await APIHandler(`/apix/v2/Payments/fetchshortpaymentcode`, {
            method: "POST",
            data: data,
            responseType: OwnerInvoiceLinkResponse,
        });
    }

    async getDefault(
        jobId: number,
        relatedParentIDsForAttachments: OwnerInvoiceRelatedEntityIDsForAttachments | null
    ): Promise<OwnerInvoiceEntity> {
        return await APIHandler(`/api/Payments/Defaults`, {
            method: "GET",
            data: {
                jobId,
                relatedParentIDsForAttachments,
            },
            responseType: OwnerInvoiceEntity,
        });
    }

    async create(
        formData: IOwnerInvoiceFormValues,
        jobId: number,
        newStatus?: InvoiceStatus,
        setOwnerEmail?: boolean
    ): Promise<OwnerInvoiceSaveResponse> {
        let url = "/api/Payments";
        if (jobId) {
            url += `?jobId=${jobId}`;
        }

        return await APIHandler(url, {
            method: "POST",
            data: this.generateEditRequest(formData, newStatus, setOwnerEmail),
            responseType: OwnerInvoiceSaveResponse,
        });
    }

    async update(
        id: number,
        formData: IOwnerInvoiceFormValues,
        newStatus?: InvoiceStatus,
        setOwnerEmail?: boolean
    ): Promise<OwnerInvoiceSaveResponse> {
        return await APIHandler(`/api/Payments/${id}`, {
            method: "PUT",
            data: this.generateEditRequest(formData, newStatus, setOwnerEmail),
            responseType: OwnerInvoiceSaveResponse,
        });
    }

    async delete(id: number): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Payments/${id}`, {
            method: "DELETE",
            responseType: EmptyResponseEntity,
        });
    }

    async resetToPending(id: number, notifyOwner: boolean): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Payments/${id}/ResetToPending?notifyOwner=${notifyOwner}`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
        });
    }

    async notify(id: number, ownerEmail?: string): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Payments/${id}/Notify`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
            data: {
                ownerEmail,
            },
        });
    }

    /**
     * @description Removes invoice from accounting
     * @param id BTCustomerInvoices id
     */
    async removeFromAccounting(id: number): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Payments/${id}/ResetInvoice`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
        });
    }

    async checkSalesTax(
        builderId: number,
        costCodeIds: number[]
    ): Promise<TaxValidationAlertResponse> {
        const requestData = {
            builderId: builderId,
            ccIds: costCodeIds.join(","),
        };
        return await APIHandler("/api/Accounting/ShouldPromptSalesTaxValidation", {
            method: "GET",
            data: requestData,
            responseType: TaxValidationAlertResponse,
        });
    }

    /**
     * @description Create invoice in accounting
     * @param id BTCustomerInvoices id
     */
    async sendToAccounting(
        id: number,
        formData: IOwnerInvoiceFormValues
    ): Promise<OwnerInvoiceAccountingResponse> {
        return await APIHandler(`/api/Payments/${id}/Invoice`, {
            method: "POST",
            data: this.generateEditRequest(formData),
            responseType: OwnerInvoiceAccountingResponse,
        });
    }

    async resetOnlinePaymentAccountingSync(
        merchantPaymentId: number
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(
            `/api/Accounting/${merchantPaymentId}/ResetOnlinePaymentAccountingSync`,
            {
                method: "PUT",
                responseType: EmptyResponseEntity,
            }
        );
    }

    async saveOwnerPreviewDisplayPreferences(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async getUnstackedLineItems(
        lineItemIds: number[],
        newLineItemId: number,
        lineItemType: LineItemType,
        markupPercent?: number
    ): Promise<UnstackLineItemResponse> {
        const request = new UnstackLineItemRequest(
            lineItemIds,
            newLineItemId,
            lineItemType,
            markupPercent
        );
        return await APIHandler(`/api/Payments/GetUnstackedLineItems`, {
            method: "GET",
            data: request,
            responseType: UnstackLineItemResponse,
        });
    }
}

export class InlinePreviewOwnerInvoiceHandler implements IOwnerInvoiceHandler {
    private ownerInvoice: OwnerInvoiceEntity;

    constructor(ownerInvoice: OwnerInvoiceEntity) {
        this.ownerInvoice = cloneDeep(ownerInvoice);
        this.ownerInvoice = {
            ...this.ownerInvoice,
            lineItems: undefined,
            customFields: [...this.ownerInvoice.customFields.filter((x) => x.onOwnerPortal)],
        };
        this.ownerInvoice.discussions.comments.comments = ownerInvoice.discussions.comments.comments
            .map((x: DiscussionCommentEntity) => x.toOwnerPreviewComment())
            .filter((comment) => {
                return comment.showOwner === true;
            });
        this.ownerInvoice.discussions.addNewCommentPermissions = new CommentPermissions({
            canDelete: false,
            canShowOwner: false,
            canShowSubs: false,
            defaultShowSubs: false,
            defaultShowOwner: false,
        });
        if (ownerInvoice.showLineItemsToOwner) {
            this.ownerInvoice.lineItems = ownerInvoice.lineItems;
            this.ownerInvoice.lineItems!.value = ownerInvoice.lineItems!.value.map(
                (x: ILineItemResponse) => {
                    return {
                        ...x,
                        costCodeTitle: ownerInvoice.lineItems!.defaults.costCodeOptions!.value.find(
                            (y: ICostCode) => y.id === x.costCode
                        )?.name,
                    };
                }
            );
            this.ownerInvoice.ownerInvoiceLineItems = ownerInvoice.lineItems!.value.map(
                (x: ILineItemResponse) => new OwnerInvoiceLineItem(x)
            );
        }
        this.ownerInvoice.showPayOnlineBtn = false;
    }

    async saveOwnerPreviewDisplayPreferences(
        id: number,
        preferences: ISaveOwnerInvoiceOwnerPreviewDisplayPreferencesRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(
            `/api/OwnerInvoices/SaveOwnerPreviewDisplayPreferences?ownerInvoiceId=${id}`,
            {
                method: "PUT",
                responseType: EmptyResponseEntity,
                data: preferences,
            }
        );
    }

    generateEditRequest = () => {
        throw new PreviewActionAPIError();
    };

    async get(): Promise<OwnerInvoiceEntity> {
        return this.ownerInvoice;
    }

    async getDefault(): Promise<OwnerInvoiceEntity> {
        return this.ownerInvoice;
    }

    async create(): Promise<OwnerInvoiceSaveResponse> {
        throw new PreviewActionAPIError();
    }

    async update(): Promise<OwnerInvoiceSaveResponse> {
        throw new PreviewActionAPIError();
    }

    async delete(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async resetToPending(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async notify(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async removeFromAccounting(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async checkSalesTax(): Promise<TaxValidationAlertResponse> {
        throw new PreviewActionAPIError();
    }

    async sendToAccounting(): Promise<OwnerInvoiceAccountingResponse> {
        throw new PreviewActionAPIError();
    }

    async resetOnlinePaymentAccountingSync(): Promise<EmptyResponseEntity> {
        throw new PreviewActionAPIError();
    }

    async getUnstackedLineItems(): Promise<UnstackLineItemResponse> {
        throw new PreviewActionAPIError();
    }

    getEntityAttachmentsToInvoice(): Promise<FilesFromExternalUrlsResponse> {
        throw new PreviewActionAPIError();
    }
    getOwnerInvoiceLink(): Promise<OwnerInvoiceLinkResponse> {
        throw new PreviewActionAPIError();
    }
}
