import { default as BTAdminRepAPI } from "./BTAdminUserInfo.api.json";

export class BTAdminUserInfo {
    constructor(rep: BTAdminRep) {
        this.rep = rep;
    }
    rep: BTAdminRep;
}

export class BTAdminRep {
    constructor(data: typeof BTAdminRepAPI) {
        this.repId = data.repID;
        this.fullName = data.fullName;
        this.btAdminPerms = data.btAdminPerms;
    }
    repId: number;
    fullName: string;
    btAdminPerms: number;
}

export enum BTAdminPermissions {
    None = 0,
    TradeShow = 1,
    Sales = 2,
    Billing = 3,
    Support = 4,
    BDR = 5,
    SupportManager = 6,
    Developer = 7,
    SuperUser = 8,
    BDS = 9,
    SalesMarketing = 10,
    HRMarketing = 11,
    RSM = 12,
    RiskInsuranceAgent = 13,
    RiskInsuranceManagement = 14,
    FeatureFlagDashboard = 15,
    BillingUtilities = 16,
    SubscriptionSignUpCodes = 17,
    Hangfire = 18,
    SpecialBuilderAccess = 19,
    InfrastructureBuilderAccess = 20,
    PaymentOperations = 21,
    RebateReceiptReview = 22,
    LendingDashboard = 23,
}
