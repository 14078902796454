import { Loader } from "@googlemaps/js-api-loader";
import { useCallback, useEffect, useMemo, useState } from "react";

import { MapApiContext, MapApiStatus } from "./MapApiContext";

interface IMapApiProviderProps {
    enabled: boolean;
    apiKey: string;
}

const MapApiProvider: React.FC<IMapApiProviderProps> = ({ enabled, apiKey, children }) => {
    const [status, setStatus] = useState<MapApiStatus>(MapApiStatus.NotLoaded);

    const loadMapApi = useCallback(async () => {
        if (status === MapApiStatus.NotLoaded) {
            if (!enabled) {
                setStatus(MapApiStatus.Unavailable);
                return MapApiStatus.Unavailable;
            }
            try {
                const loader = new Loader({
                    apiKey,
                    version: "3.57",
                });

                const status = await Promise.all([
                    loader.importLibrary("maps"),
                    loader.importLibrary("places"),
                ]).then(async () => {
                    setStatus(MapApiStatus.Loaded);
                    return MapApiStatus.Loaded;
                });

                return status;
            } catch {
                setStatus(MapApiStatus.Error);
                return MapApiStatus.Error;
            }
        } else {
            return status;
        }
    }, [status, enabled, apiKey]);

    const getStatus = useCallback(async () => {
        const currentStatus = await loadMapApi();
        return currentStatus;
    }, [loadMapApi]);

    const contextValue = useMemo(
        () => ({
            status: getStatus,
        }),
        [getStatus]
    );

    useEffect(() => {
        void loadMapApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MapApiContext.Provider value={contextValue}>{children}</MapApiContext.Provider>;
};

export default MapApiProvider;
