import moment from "moment";

export class SplitDateTime {
    date: moment.Moment | null | undefined;
    time: moment.Moment | null | undefined;

    constructor(fullDate?: moment.Moment, checkForNoTimeValue = false) {
        if (!fullDate) {
            this.date = undefined;
            this.time = undefined;
            return;
        } else {
            this.date = fullDate;
            this.time = fullDate.clone().set({
                hour: fullDate?.get("hour"),
                minute: fullDate?.get("minute"),
                seconds: fullDate?.get("second"),
            });
        }

        if (checkForNoTimeValue === true) {
            if (this.time?.get("hour") === 23 && this.time?.get("minute") === 59) {
                this.time = null;
            }
        }
    }
}
