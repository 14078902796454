import yup from "utilities/form/yup";

export class ViewingAccessRowEntity {
    constructor(data: any) {
        this.accessorId = data.accessorId;
        this.accessorName = data.accessorName;
        this.businessPhone = data.businessPhone;
        this.cellPhone = data.cellPhone;
        this.hasAccess = data.hasAccess;
        this.hasNotificationAccess = data.hasNotificationAccess;
        this.disableAccess = data.disableAccess;
        this.primaryContact = data.primaryContact;
        this.archived = data.archived;
    }
    accessorId: number;
    accessorName: string;
    businessPhone: number;
    cellPhone: number;
    hasAccess: boolean;
    hasNotificationAccess: boolean;
    disableAccess: boolean;
    primaryContact: string;
    archived: boolean;
}

export class ViewingAccessRowFormValues {
    constructor(row: any) {
        this.accessorId = row.accessorId;
        this.hasAccess = row.hasAccess;
        this.hasNotificationAccess = row.hasNotificationAccess;
        this.accessorName = row.accessorName;
        this.isSelected = false;
        this.disableAccess = row.disableAccess;
        this.archived = row.archived;
    }
    accessorId: number;
    hasAccess: boolean;
    hasNotificationAccess: boolean;
    accessorName: string;
    isSelected: boolean;
    disableAccess: boolean;
    archived: boolean;
}

export class ViewingAccessEntity {
    constructor(data: any) {
        this.subUserViewingAccess = data.subUserViewingAccess.map(
            (sub: any) => new ViewingAccessRowEntity(sub)
        );
        this.internalUserViewingAccess = data.internalUserViewingAccess.map(
            (user: any) => new ViewingAccessRowEntity(user)
        );
    }
    internalUserViewingAccess: ViewingAccessRowEntity[];
    subUserViewingAccess: ViewingAccessRowEntity[];
}

export class ViewingAccessFormValues {
    constructor(data: ViewingAccessEntity) {
        this.subs = data.subUserViewingAccess.map((sub) => new ViewingAccessRowFormValues(sub));
        this.users = data.internalUserViewingAccess.map(
            (user) => new ViewingAccessRowFormValues(user)
        );
    }
    users: ViewingAccessRowFormValues[];
    subs: ViewingAccessRowFormValues[];
}

export function getViewingAccessValidators() {
    return yup
        .object()
        .shape<ViewingAccessFormValues>({
            subs: yup.array().of(yup.mixed()).label("Subs Viewing Access"),
            users: yup.array().of(yup.mixed()).label("Internal Users Viewing Access"),
        })
        .label("Viewing Access");
}
