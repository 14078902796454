import { createContext, useCallback, useReducer, useState } from "react";

import {
    FilterEntity,
    FilterEntityType,
    SelectedFilterItem,
} from "entity/filters/Filter/Filter.api.types";
import {
    ClearFilterState,
    defaultFilterState,
    FilterReducer,
    SetUnsavedAppliedFilter,
    UpdateUnsavedChanges,
} from "entity/filters/Filter/Filter.reducer";
import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";

export interface IFilterValues {
    items: SelectedFilterItem[] | undefined;
    type: FilterEntityType;
}
interface IFilterValueContext {
    appliedFilter: IFilterValues | undefined;
    setAppliedFilter: (filter: IFilterValues | undefined) => void;
    setUnsavedAppliedFilter: (items: SelectedFilterItem[], isChanged: boolean) => void;
    showHasUnsavedChanges: boolean;
    appliedFiltersFromSavedFilter: boolean;
    updateUnsavedChanges: (
        newFilters: SelectedFilterItem[],
        filterEntity: FilterEntity,
        savedFilter: SavedFilterItem
    ) => void;
    appliedFilterItem?: SelectedFilterItem[];
}
export const FilterValueContext = createContext<IFilterValueContext | undefined>(undefined);

export const FilterValueContextProvider: React.FC = (props) => {
    const [appliedFilter, setAppliedFilter] = useState<IFilterValues | undefined>();
    const [state, dispatch] = useReducer(FilterReducer, defaultFilterState);

    // When we introduced the Filter.reducer with the US 140545 we realized that the Empty state and grid state
    // has two different Filter drawer causing an issue setting the state of the Filter.reducer on an unmouted component
    // We had to move the reducer to this filter to set the state of the reducer. We need to account for that scenario when
    // removing this context file.

    const setUnsavedAppliedFilter = useCallback(
        (values: SelectedFilterItem[], isChanged: boolean) =>
            dispatch(new SetUnsavedAppliedFilter(values, isChanged)),
        []
    );
    const updateUnsavedChanges = useCallback(
        (
            newFilters: SelectedFilterItem[],
            filterEntity: FilterEntity,
            savedFilter: SavedFilterItem
        ) => dispatch(new UpdateUnsavedChanges(newFilters, filterEntity, savedFilter)),
        []
    );
    const setAppliedFilterAndDispatch = useCallback(
        (appliedFilter: IFilterValues | undefined) => {
            setAppliedFilter(appliedFilter);
            if (appliedFilter === undefined) {
                dispatch(new ClearFilterState());
            } else {
                setUnsavedAppliedFilter(appliedFilter.items ?? [], true);
            }
        },
        [setUnsavedAppliedFilter]
    );

    const showHasUnsavedChanges = state.showHasUnsavedChanges;
    const appliedFiltersFromSavedFilter = state.appliedFiltersFromSavedFilter;
    const appliedFilterItem = state.appliedFilter;

    return (
        <FilterValueContext.Provider
            value={{
                appliedFilter,
                setAppliedFilter: setAppliedFilterAndDispatch,
                setUnsavedAppliedFilter,
                updateUnsavedChanges,
                appliedFiltersFromSavedFilter,
                showHasUnsavedChanges,
                appliedFilterItem,
            }}
        >
            {props.children}
        </FilterValueContext.Provider>
    );
};
