// eslint-disable-next-line no-restricted-imports
import { SortDescriptor } from "@progress/kendo-data-query";
import { InjectedFormikProps, withFormik } from "formik";
import { isEqual, uniq } from "lodash-es";
import { useCallback, useMemo } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { arrayIsEqualDeep } from "utilities/array/array";
import yup from "utilities/form/yup";
import { isInPortal } from "utilities/portal/portal";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import {
    GridViewColumn,
    GridViewItem,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { GridColumn } from "commonComponents/utilities/Grid/GridContainer.types";

import "./UpdateCurrentViewCard.less";

interface IUpdateCurrentViewProps {
    columnsList: GridColumn<unknown>[];
    onApplyView: (view: GridViewItem) => void;
    selectedGridView: GridViewItem;
    // the current sorting, may be different than saved version if user has applied a sort to grid
    sortingData?: SortDescriptor[];
    columnsSelectedList?: GridColumn<unknown>[];
}

interface IUpdateCurrentViewFormValues {
    columns: (string | number)[];
}

const UpdateCurrentViewCardInternal: React.FunctionComponent<
    InjectedFormikProps<IUpdateCurrentViewProps, IUpdateCurrentViewFormValues>
> = (props) => {
    const {
        values,
        columnsList,
        columnsSelectedList,
        onApplyView,
        setFieldTouched,
        setFieldValue,
        validateForm,
        selectedGridView,
        initialValues,
        sortingData,
    } = props;

    // check if currently selected columns match the grid's currently applied columns
    const hasChanged = !isEqual(
        initialValues.columns.map((v) => v.valueOf()).sort(),
        values.columns.map((v) => v.valueOf()).sort()
    );

    const getColumnSelectData = useCallback((columnsList: GridColumn<unknown>[]) => {
        return columnsList
            .filter((col) => !col.isHidden)
            .sort((a, b) => {
                if (a.enabled && b.enabled) {
                    return a.order - b.order;
                } else if (!a.enabled && !b.enabled) {
                    // unselected custom fields should be at the end of the list
                    if (a.isCustomField !== b.isCustomField) {
                        return a.isCustomField && !b.isCustomField ? 1 : -1;
                    }
                    return a.name.localeCompare(b.name);
                }
                return a.enabled && !b.enabled ? -1 : 1;
            })
            .map(
                (col) =>
                    new BTSelectItem({
                        name: col.isCustomField ? `*Custom Field - ${col.name}` : col.name,
                        id: col.id,
                        disabled: !col.isRemovableFromGrid || col.isAlwaysVisible,
                    })
            );
    }, []);

    const handleColumnListUpdated = useCallback(
        (treeData: BTSelectItem[]) => {
            const requiredIds = treeData.filter((c) => c.disabled).map((c) => c.value);
            return (field: string, value: any) => {
                const ids = uniq([...value, ...requiredIds]);
                setFieldValue(field, ids);
            };
        },
        [setFieldValue]
    );

    const selectedColumns = useMemo(() => {
        return values.columns
            .filter((colId) => columnsList.findIndex((col) => col.id === colId) !== -1)
            .map((colId) => columnsList.find((col) => col.id === colId)!)
            .map(({ id, width, isFrozen }) => new GridViewColumn({ id, width, isFrozen }));
    }, [columnsList, values.columns]);

    const handleApplyView = useCallback(async () => {
        const formErrors = await validateForm(values);
        if (Object.keys(formErrors).length === 0) {
            const currentSort = sortingData ?? selectedGridView.sort;
            const newView = {
                ...selectedGridView,
                sort: currentSort,
                shouldResetColumnConfigs: false,
            };
            newView.columns = selectedColumns;
            if (!arrayIsEqualDeep<GridViewColumn>(newView.columns, selectedGridView.columns)) {
                newView.isCustom = true;
            }
            // if removing the current sort column, sort by the first sortable column in the grid asc
            if (
                newView.sort.length === 0 ||
                (newView.sort.length > 0 &&
                    newView.columns.findIndex((col) => col.id === newView.sort[0].field) === -1)
            ) {
                const sortableColumns = [...columnsSelectedList!, ...columnsList].filter(
                    (gridColumn) =>
                        gridColumn.isSortable &&
                        newView.columns.findIndex((col) => gridColumn.id === col.id) !== -1
                );
                newView.sort =
                    sortableColumns.length > 0
                        ? [
                              {
                                  field: sortableColumns[0].id,
                                  dir: "asc",
                              },
                          ]
                        : [];
            }
            onApplyView(newView);
        }
    }, [
        validateForm,
        values,
        sortingData,
        selectedGridView,
        selectedColumns,
        onApplyView,
        columnsSelectedList,
        columnsList,
    ]);

    const treeData = useMemo(
        () => getColumnSelectData(columnsList),
        [columnsList, getColumnSelectData]
    );
    const isBuilder = isInPortal({ builder: true });
    const cardDescription =
        "Change the current columns displayed on the page." +
        (isBuilder ? " This will not affect your currently selected saved view settings." : "");
    const cardClass =
        (hasChanged ? "UpdateCurrentViewCard-Updated" : "UpdateCurrentViewCard") +
        (isBuilder ? " margin-top-lg" : "");
    return (
        <BTCard className={cardClass} bordered={false}>
            <div>
                <BTTitle level={3}>Update Current View</BTTitle>
            </div>
            <div>{cardDescription}</div>
            <BTRow className="flex-nowrap align-items-center">
                <BTCol flex="auto" className="margin-top-xs">
                    <BTFormItemAutomatic<IUpdateCurrentViewFormValues> id="columns">
                        <BTSelect<IUpdateCurrentViewFormValues>
                            id="columns"
                            data-testid="columns"
                            treeData={treeData}
                            multiple
                            value={values.columns}
                            onChange={handleColumnListUpdated(treeData)}
                            onBlur={setFieldTouched}
                        />
                    </BTFormItemAutomatic>
                </BTCol>
                <BTCol className="ApplyView">
                    <BTButton
                        type="primary"
                        data-testid="apply"
                        loadingAction="apply"
                        onClick={handleApplyView}
                        disabled={!hasChanged}
                    >
                        Apply View
                    </BTButton>
                </BTCol>
            </BTRow>
        </BTCard>
    );
};

const UpdateCurrentViewCardValidators = () =>
    yup.object().shape<IUpdateCurrentViewFormValues>({
        columns: yup.array<string>().label("Columns").min(1),
    });

export const UpdateCurrentViewCard = withFormik<
    IUpdateCurrentViewProps,
    IUpdateCurrentViewFormValues
>({
    mapPropsToValues: (props: IUpdateCurrentViewProps) => ({
        columns: props.columnsList
            ? props.columnsList.filter((col) => col.enabled).map((col) => col.id)
            : [],
    }),
    handleSubmit: () => {},
    validationSchema: UpdateCurrentViewCardValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
})(UpdateCurrentViewCardInternal);
