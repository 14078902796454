import { BTServiceFileUpload } from "legacyComponents/FileUploadContainer.types";
import moment from "moment";

import { IValidator } from "types/apiResponse/apiResponse";
import { CustomFieldAssociatedType, CustomFieldDataType } from "types/enum";

/** implement this interface on your entity to support the custom fields component */
export interface IHasCustomFieldContainer {
    customFieldAssociatedType: CustomFieldAssociatedType;
    customFieldOptions: ICustomFieldOptionsList;
    customFields: ICustomFieldResponse[];

    /**
     * This flag determines if any custom field can be configured.
     * When true the `Settings` button displays next to dropdown/multiselect controls.
     */
    customFieldsCanConfigure: boolean;
}

/** Shared response for customFields, this type is needed by <CustomFields> */
export interface ICustomFieldResponse {
    associatedEntityID: number;
    id: number;
    onOwnerPortal: boolean;
    optionsKey: string;
    title: string;
    toolTipText: string;
    typeId: CustomFieldDataType;
    value: string | null | number | boolean | BTServiceFileUpload | moment.Moment | any[];
    validators: IValidator[];

    // the following properties conditionally appear
    currencyIdentifier?: string;
    currencySeparator?: string;
    currencyThousandsSeparator?: string;
    fileTypes?: string[];
    selectedFile?: ISelectedFile;
    defaultValue?: string;
}

export interface ISelectedFile {
    brandedUrl: string | null;
    /** Ignoring for now, bug in api always returns false, to hide in antd use :showUploadList={{ showRemoveIcon: selectedFile.canDelete }} */
    canDelete: boolean;
    ext: string;
    extraData: any;
    id: number;
    imagePath: string;
    mediaType: number;
    showOwner: boolean;
    showSubs: boolean;
    title: string;
    url: string;
    validators: IValidator[];
}

export interface ICustomFieldOption {
    id: number;
    name: string;
    extraData?: { [key: string]: any };
}

/**
 * @example "57171": [ { id: 74580, name: "1" }, { id: 74581, name: "2" } ]
 */
export interface ICustomFieldOptionsList {
    [key: string]: ICustomFieldOption[];
}

/** Shared response for customFields print, this type is needed by <CustomFieldPrintContainer> */
export class CustomFieldPrintItem {
    constructor(data: any) {
        this.type = data.type;
        this.value = data.value;
        this.label = data.label;
    }

    type: CustomFieldDataType;
    value: string | number | boolean;
    label: string;
}
