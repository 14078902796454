import moment from "moment";

export type LeadToJobFormActions = "save" | "jobPicked" | undefined;

export interface ILeadToJobFormValues {
    jobType?: number;
    jobId?: number;
    jobStatus?: number;
    leadStatus?: number;
    zip?: string;
    deleteActivities: boolean;
    soldDate: moment.Moment | undefined;
    contactSelector: number;
    copyContractPrice: boolean;
    copyJobAddress: boolean;
    copyContact: boolean;
    copyCustomFields: boolean;
    copyFiles: boolean;
    copyEmailAttachments: boolean;
    showOwnerFiles: boolean;
    showSubsFiles: boolean;
    copyPhotos: boolean;
    showOwnerPhotos: boolean;
    showSubsPhotos: boolean;
    copyVideos: boolean;
    showOwnerVideos: boolean;
    showSubsVideos: boolean;
    copyProposalLineItems: boolean;
    replaceExistingEstimates: boolean;
    copyProposalFiles: boolean;
    copyProposalIntroText: boolean;
    proposalId?: number;
    proposalTemplateId?: number;
    linkingEntity: number;
    linkingAction?: number;
    linkingCustomer: string | undefined;
    linkingJob: string | undefined;
    copyToInvoice: boolean;
    applyEstimatesToInvoice: boolean;
    copyProposalPayment: boolean;
    copyPaymentToType?: CopyPaymentToType;
    contractType?: number;
    proposalCopyOption?: ProposalCopyOptions;
}

export enum LeadToJobModes {
    FromScratch = 1,
    FromTemplate = 2,
    ChooseExisting = 3,
}

export enum LeadCopyOptions {
    None = 0,
    CopyFiles = 1,
    FilesShowOwner = 2,
    FilesShowSubs = 4,
    CopyPhotos = 8,
    PhotosShowOwner = 16,
    PhotosShowSubs = 32,
    CopyEstimates = 64,
    CopyCustomFields = 128,
    CopyToInvoice = 256,
    CopyToDeposit = 512,
    SelectProposal = 1024,
    CopyProposalFiles = 2048,
    CopyVideos = 4096,
    VideosShowOwner = 8192,
    VideosShowSubs = 16384,
    CopyIntroAndClosingText = 32768,
    ContractPrice = 65536,
    JobsiteAddress = 131072,
    CustomerContact = 262144,
    IncludeLeadEmailAttachments = 524288,
    ReplaceExistingEstimates = 1048576,
    ApplyEstimatesToInvoice = 2097152,
    CopyProposalPayment = 4194304,
}

export enum CopyPaymentToType {
    Invoice = 0,
    Deposit = 1,
}

export enum ProposalCopyOptions {
    None = 0,
    LeadProposal = 1,
    LeadProposalTemplate = 2,
}
