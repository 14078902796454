import { Typography } from "antd";
import { InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";
import { Grey5 } from "styles/antTheme/Colors";

import { externalPages } from "utilities/externalPages";
import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTForm, BTFormItem, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";
import {
    AuthEmailResponse,
    IMultiFactorAuthModalFormValues,
    MultiFactorAuthModalFormActions,
} from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModal.api.types";

import "styles/common/splitModal.less";

interface IMultiFactorAuthModalProps {
    data: AuthEmailResponse;
    actionBeingPerformed: MultiFactorAuthModalFormActions;
    onSubmit: (values: IMultiFactorAuthModalFormValues) => Promise<void>;
    onResendEmail: () => Promise<void>;
    onSwitchUser: () => void;
}

interface IMultiFactorAuthModalState {
    content: MultiFactorAuthModalContent;
}

enum MultiFactorAuthModalContent {
    OneTimeCode,
    ContactSupport,
}

class MultiFactorAuthEmailString extends Component<IMultiFactorAuthModalProps> {
    render() {
        const { data } = this.props;
        return (
            <>
                <Typography.Text strong>{data.firstEmail}</Typography.Text>
                {data.emailCount > 1 && (
                    <>
                        {" and "}
                        <Typography.Text strong>
                            {data.emailCount - 1}
                            {" other email "}
                            <BTFormattedPlural
                                value={data.emailCount - 1}
                                one="address"
                                other="addresses"
                            />
                        </Typography.Text>
                    </>
                )}
                <Typography.Text strong>,</Typography.Text>
            </>
        );
    }
}

class MultiFactorAuthSupportInfo extends Component<IMultiFactorAuthModalProps> {
    render() {
        const unfamiliarEmailMessage =
            "If the email is not familiar to you, please contact your administrator or Buildertrend Support.";

        const { supportInfo } = this.props.data;

        const divider = <span style={{ color: Grey5 }}>|</span>;

        return (
            <BTCol xs={14} className="ContentColumn">
                <BTTitle level={4} className="Title">
                    "Not your email?"
                </BTTitle>
                <span>{unfamiliarEmailMessage}</span>

                <BTTitle level={4} className="Title">
                    Buildertrend Support
                </BTTitle>
                <span>
                    {supportInfo.phone}
                    <br />
                    {supportInfo.daysOfOperation} {divider} {supportInfo.hoursOfOperation} {divider}{" "}
                    {supportInfo.offsetDescription}
                </span>

                <BTRow gutter={8} className="ActionButtons">
                    <BTFormItem>
                        <BTExternalLink href={externalPages.BuildertrendContactSupport}>
                            <BTButton
                                id="contactSupport"
                                data-testid="contactSupport"
                                type="secondary"
                            >
                                Contact Support
                            </BTButton>
                        </BTExternalLink>

                        <BTButton
                            id="switchUser"
                            data-testid="switchUser"
                            type="link"
                            onClick={this.props.onSwitchUser}
                        >
                            Switch User
                        </BTButton>
                    </BTFormItem>
                </BTRow>
            </BTCol>
        );
    }
}

class MultiFactorAuthFormContent extends Component<
    InjectedFormikProps<
        IMultiFactorAuthModalProps & { showSupportContent: () => void },
        IMultiFactorAuthModalFormValues
    >
> {
    private handleInputChange = (field: string, value: string) => {
        value = value.trim();
        this.props.setFieldValue(field, value);
    };

    render() {
        return (
            <BTCol xs={14} className="ContentColumn">
                <BTTitle level={4} className="Title">
                    One-time code sent.
                </BTTitle>
                <span>
                    We’ve sent a one-time code email to{" "}
                    <MultiFactorAuthEmailString {...this.props} /> enter the code below to login.
                </span>

                <BTFormItemAutomatic<IMultiFactorAuthModalFormValues>
                    id="oneTimeCode"
                    className="padding-top-xs"
                >
                    <BTInput
                        id="oneTimeCode"
                        data-testid="password"
                        value={this.props.values.oneTimeCode}
                        onChange={this.handleInputChange}
                        onBlur={this.props.setFieldTouched}
                    />
                </BTFormItemAutomatic>

                <BTFormItem>
                    <BTButton
                        id="confirm"
                        data-testid="confirm"
                        type="primary"
                        disabled={
                            !this.props.values.oneTimeCode || this.props.values.oneTimeCode === ""
                        }
                        actionBeingPerformed={this.props.actionBeingPerformed}
                        loadingAction="submit"
                        onClick={this.props.handleSubmit}
                    >
                        Confirm
                    </BTButton>
                    <BTButton
                        id="resend"
                        data-testid="resend"
                        type="link"
                        actionBeingPerformed={this.props.actionBeingPerformed}
                        loadingAction="resendEmail"
                        onClick={this.props.onResendEmail}
                    >
                        Re-send Email
                    </BTButton>
                </BTFormItem>

                <BTButton
                    id="notYourEmail"
                    data-testid="notYourEmail"
                    type="link"
                    isolated
                    actionBeingPerformed={this.props.actionBeingPerformed}
                    loadingAction="otherAction"
                    onClick={this.props.showSupportContent}
                >
                    Not your email?
                </BTButton>
            </BTCol>
        );
    }
}

export class MultiFactorAuthModalInternal extends Component<
    InjectedFormikProps<IMultiFactorAuthModalProps, IMultiFactorAuthModalFormValues>,
    IMultiFactorAuthModalState
> {
    state: IMultiFactorAuthModalState = {
        content: MultiFactorAuthModalContent.OneTimeCode,
    };

    private showContactSupportContent = () => {
        this.setState({ content: MultiFactorAuthModalContent.ContactSupport });
    };

    render() {
        const { isFirstMultifactorAuth } = this.props.data;
        const leftPanelMessage = isFirstMultifactorAuth
            ? "We’ve updated security requirements on your account."
            : "It's time to renew your verification.";

        return (
            <BTForm onSubmit={this.props.handleSubmit}>
                <div className="SplitModal">
                    <BTRow>
                        <BTCol xs={10} className="SideBarColumn">
                            <BTRow justify="center" align="middle" className="Sidebar">
                                <BTCol>
                                    <BTRow justify="center">
                                        <BTLogo
                                            layout="minimal"
                                            color="white-blue"
                                            size="md"
                                            className="Logo"
                                        />
                                    </BTRow>
                                    <div className="InfoContainer">
                                        <span className="InfoText">{leftPanelMessage}</span>
                                        {isFirstMultifactorAuth && (
                                            <>
                                                <br />
                                                <br />
                                                <BTExternalLink
                                                    className="InfoText"
                                                    isUnderline
                                                    href={externalPages.XeroMFAHelpDoc}
                                                >
                                                    Read More
                                                </BTExternalLink>
                                            </>
                                        )}
                                    </div>
                                </BTCol>
                            </BTRow>
                        </BTCol>
                        {this.state.content === MultiFactorAuthModalContent.OneTimeCode && (
                            <MultiFactorAuthFormContent
                                {...this.props}
                                showSupportContent={this.showContactSupportContent}
                            />
                        )}
                        {this.state.content === MultiFactorAuthModalContent.ContactSupport && (
                            <MultiFactorAuthSupportInfo {...this.props} />
                        )}
                    </BTRow>
                </div>
            </BTForm>
        );
    }
}

const MultiFactorAuthModalValidators = yup.object().shape<IMultiFactorAuthModalFormValues>({
    oneTimeCode: yup.string().required().label("One-time Code"),
});

export const MultiFactorAuthModalPresentational = withFormik<
    IMultiFactorAuthModalProps,
    IMultiFactorAuthModalFormValues
>({
    mapPropsToValues: () => {
        return {
            oneTimeCode: "",
        };
    },
    validationSchema: () => MultiFactorAuthModalValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: async (values: IMultiFactorAuthModalFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
    },
})(MultiFactorAuthModalInternal);
