import moment from "moment";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";
import { BTLoginTypes, DiscussionsLinkedTypes } from "types/enum";

import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

export type CommentFormActions = undefined | "send" | "sendAndClose";

export interface ICommentFormValues {
    commentJson?: EditorContent;
    linkedType: number;
    notificationUsers: number[];
    showOwner: boolean;
    showSubs: boolean;
    channelId?: number | null;
}

export interface ICommentInfo {
    /** populated when legacy comment */
    discussionText?: string;
    /** populated when new JSON comment format */
    commentJson?: EditorContent;
    linkedType: DiscussionsLinkedTypes;
    notificationUsers: BTSelectItem[];
    showOwner: boolean;
    showSubs: boolean;
    channelId?: number | null;
}

export class CommentPermissions {
    constructor(data: any) {
        this.canDelete = data.canDelete || false; // default to false

        this.canShowOwner = data.canShowOwner;
        this.canShowSubs = data.canShowSubs;

        this.defaultShowSubs = data.defaultShowSubs;
        this.defaultShowOwner = data.defaultShowOwner;
    }

    canDelete: boolean;

    canShowOwner: boolean;
    canShowSubs: boolean;

    defaultShowSubs: boolean;
    defaultShowOwner: boolean;
}

export class DiscussionCommentEntity implements IBaseEntity {
    constructor(data: any) {
        this.id = data.commentID;
        this.jobId = data.jobsiteID;
        this.builderId = data.builderID;

        this.commentIDUpper = data.commentIDUpper;
        this.jobName = data.jobName;
        this.sourceTitle = data.sourceTitle;
        this.sourceType = data.sourceType;
        this.sourceTypeId = data.sourceTypeId;
        this.linkedID = data.linkedID;

        // date is sent down in local time
        this.commentDate = moment(data.commentDate);
        this.commentDateForMobile = data.commentDateForMobile;
        this.commentDateFormatted = data.commentDateFormatted;
        this.commentTimeFormatted = data.commentTimeFormatted;

        this.userFullName = data.userFullName;
        this.userType = data.userType;
        this.addedById = data.addedById;
        this.byCurrentUser = data.byCurrentUser;

        this.commentText = data.commentText ? data.commentText : null;
        this.commentJson = data.commentJson ?? EditorContent.CreateEmpty();
        this.commentIsJson = !!data.commentJson;
        this.commentExists = data.commentsExists;

        // place canAdd, canView, canDelete, canNotifyOwner, canNotifySubs, canShowOwner, canShowSubs, etc into a permissions object
        this.permissions = new CommentPermissions(data);

        this.showOwner = data.showOwner;
        this.showSubs = data.showSubs;

        this.canDelete = data.canDelete;

        this.builderName = data.builderName;
        this.documentLink = data.documentLink;
        this.contextMenu = data.contextMenu;
        this.photo = data.photo;
        this.showCommentInfo = data.showCommentInfo;
        this.parentId = data.parentId;
        this.isUnread = data.isUnread;
    }

    toOwnerPreviewComment: () => DiscussionCommentEntity = () => {
        return {
            ...this,
            byCurrentUser: this.userType === "Owner",
            permissions: {
                ...this.permissions,
                canDelete: false,
            },
        };
    };

    id: number;
    jobId: number;
    builderId: number;

    commentIDUpper: number;
    jobName: string;
    sourceTitle: string;

    /** Entity name, Example: "Warranty" */
    sourceType: string;
    sourceTypeId: number;
    linkedID: number;
    commentDate: moment.Moment;
    commentDateForMobile: string;
    commentDateFormatted: string | null;
    commentTimeFormatted: string | null;
    userFullName: string | null;
    userType: string | BTLoginTypes | null;
    addedById: number;
    byCurrentUser: boolean;

    commentText: string | null;
    commentJson: EditorContent;
    commentIsJson: boolean;
    commentExists: boolean;

    permissions: CommentPermissions;

    showOwner: boolean;
    showSubs: boolean;

    canDelete: boolean;

    builderName: string | null;
    documentLink: string;
    contextMenu: string;
    photo: string | null;
    showCommentInfo: boolean;
    parentId: number | null;
    isUnread: boolean;
}

/** API response when adding a new comment */
export class AddCommentResponse {
    constructor(data: any) {
        this.comment = new DiscussionCommentEntity(data.comment);
        this.channelId = data.channelId;
    }

    comment: DiscussionCommentEntity;
    channelId?: number | null;
}

/** API response when removing a new comment */
export class DeleteCommentResponse {
    // no data is returned from the api, only a status code
}

export interface INotifyPermissionRequest {
    linkedId: number;
    linkedType: DiscussionsLinkedTypes;
    notifyOwner: boolean;
    notifySubs: boolean;
}

/** API request when updating a new comments permissions */
export interface IUpdateCommentPermissionRequest {
    showOwner: boolean;
    showSubs: boolean;
}

/** API response when updating a new comments permissions */
export class UpdateCommentPermissionResponse {
    // no data is returned from the api, only a status code
}

/** API response when reading a comment */
export class ChannelReadResponse {
    // no data is returned from the api, only a status code
}
