import { Result, Typography } from "antd";
import { buildertrendSupportInfo } from "layouts/errors/errorConstants";
import { useEffect, useState } from "react";

import { AppProviderHandler, useGetGlobalInfoQuery } from "helpers/AppProvider.api.handler";

import { NetworkError } from "utilities/apiHandler";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconWifiSlash } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";
import { PhoneNumberDisplay } from "commonComponents/utilities/PhoneNumberDisplay/PhoneNumberDisplay";

import "./NetworkErrorPage.less";

/**
 * How is this this ever possible to be rendered? Well, with Serivce Workers, we can fallback to a cached version of our SPA shell if attempting to connect
 * to a SPA enabled page. However, because we don't intend to support any sort of offline mode for a majority of our pages,
 * we need to display a valid error message on failure.
 *
 * **BEFORE MODIFYING:** Make sure NON of the components used would likely require context values values
 * if possible unless the components gracefully handle sitatuions where these context values are not defined.
 * This page most likely gets displayed *because* the API call that populates many of our context
 * values (builderInfoContext, applicationDefaultContext, userInfoContext, etc.) are failing to begin with.
 */
export const NetworkErrorPage: React.FunctionComponent = ({ children }) => {
    const [isNetworkDown, setIsNetworkDown] = useState(true);
    const { status } = useGetGlobalInfoQuery(new AppProviderHandler(), {
        retry: (_, error) => {
            if (error instanceof NetworkError) {
                setIsNetworkDown(true);
                return true;
            }

            // Even though it's an error, it's not a network error. Set isNetworkDown to true
            // to reload the page and display a server error instead.
            setIsNetworkDown(false);
            return false;
        },
        retryDelay: 2000,
    });

    // Reload the page automatically if the network connection appears to have returned
    useEffect(() => {
        if (!isNetworkDown || status === "success") {
            window.location.reload();
        }
    }, [isNetworkDown, status]);

    return (
        <BTRow
            className="NetworkErrorPage"
            responsiveMode="viewport"
            justify="center"
            align="middle"
        >
            <BTCol span={24}>
                <BTRow responsiveMode="viewport" justify="center">
                    <BTLogo
                        layout="horizontal"
                        color="dark-blue-blue"
                        size="md"
                        className="margin-top-lg"
                    />
                </BTRow>
                <Result
                    status="error"
                    title="Network Error"
                    icon={<BTIconWifiSlash />}
                    subTitle={
                        <>
                            <BTRow
                                responsiveMode="viewport"
                                className="margin-top-md"
                                justify="center"
                            >
                                <BTCol sm={24} md={16}>
                                    <Typography.Paragraph>
                                        Sorry, it looks like you are not connected to the internet
                                        right now. We'll keep trying to load the page when the
                                        network connection is restored. In the meantime, please try
                                        the following troubleshooting steps:
                                    </Typography.Paragraph>
                                </BTCol>
                            </BTRow>
                            <BTRow
                                responsiveMode="viewport"
                                className="margin-top-md"
                                justify="center"
                            >
                                <BTCol sm={16} md={10}>
                                    <Typography.Paragraph>
                                        <ol className="NetworkErrorPage-TroubleshootingSteps">
                                            <li>
                                                If you are using a Wi-Fi connection, try resetting
                                                your router.
                                            </li>
                                            <li>
                                                If you are using a wired connection, check that the
                                                Ethernet cable is securely connected.
                                            </li>
                                            <li>
                                                Check your internet connection by opening another
                                                website.
                                            </li>
                                        </ol>
                                    </Typography.Paragraph>
                                </BTCol>
                            </BTRow>
                            <BTRow
                                responsiveMode="viewport"
                                className="margin-top-md"
                                justify="center"
                            >
                                <BTCol sm={24} md={16}>
                                    <Typography.Paragraph>
                                        If you are connected to the internet and are still seeing
                                        this error, check to make sure no browser extensions or
                                        security policies are blocking your connection to
                                        Buildertrend. Otherwise, our Support team may be able to
                                        assist you by contacting{" "}
                                        <Typography.Text strong>
                                            <PhoneNumberDisplay
                                                phoneNumber={buildertrendSupportInfo.phoneNumber}
                                            />{" "}
                                            extension {buildertrendSupportInfo.extension}
                                        </Typography.Text>
                                        .
                                    </Typography.Paragraph>
                                </BTCol>
                            </BTRow>
                        </>
                    }
                    extra={children}
                />
            </BTCol>
        </BTRow>
    );
};

export default NetworkErrorPage;
