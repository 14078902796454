import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IWarrantyProps } from "entity/warranty/Warranty/Warranty";

const Warranty = lazyLoadWithRetry(() => import("./Warranty"));

export const RouteWarranty = (
    props: Omit<IWarrantyProps, "id" | "jobId" | "history" | "match" | "location">
) => (
    <RouteRelative
        path={routes.warranty.details}
        render={(routeProps) => {
            const id = parseInt(routeProps.match.params.id);
            const jobId = parseInt(routeProps.match.params.jobId);

            return (
                <BTSuspense>
                    <Warranty {...props} {...routeProps} id={id} jobId={jobId} />
                </BTSuspense>
            );
        }}
    />
);
