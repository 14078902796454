import { InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { showAPIErrorMessage } from "utilities/apiHandler";
import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTFormItem, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTTextArea } from "commonComponents/btWrappers/BTTextArea/BTTextArea";
import { NotificationAssignedTo } from "commonComponents/entity/scheduleNotifications/NotificationAssignedTo";
import {
    IScheduleNotificationPromptHandler,
    ScheduleNotificationPromptHandler,
} from "commonComponents/entity/scheduleNotifications/ScheduleNotificationPrompt.api.handler";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

interface IScheduleNotificationPromptProps {
    scheduleId?: number;
    assignedUsers?: BTSelectItem[];
    newAssignedUsers?: Map<number, number[]>;
    /**
     * Passing these in will show a version of the prompt for multiple items
     */
    schedules?: BTSelectItem[];
    handler?: IScheduleNotificationPromptHandler;
    notifyMultiple?: boolean;
    notifyByDefault?: boolean;
    showSkip?: boolean;
    schedulesToNotify?: number[];
    schedulesToConfirm?: number[];

    /**
     * After the API call to send notifications returns successfully, this function is called.
     */
    onSend: () => void;
    /**
     * Calls back after the Skip button is clicked, or after the prompt modal is closed.
     */
    onClose: () => void;

    /**
     * If notifications are sent should the notifications be sent as Updated Schedule or as a Reminder
     */
    hasChanged?: boolean;
}

interface IScheduleNotificationPromptFormValues {
    notifyAssignedUsers: boolean;
    assignedUsersToNotify: number[];
    notificationDetails: string;
    requireConfirmation: boolean;
    notifyMultiple: boolean;
    schedulesToNotify: number[];
    schedulesToConfirm: number[];
}

class ScheduleNotificationPromptInternal extends Component<
    InjectedFormikProps<IScheduleNotificationPromptProps, IScheduleNotificationPromptFormValues>
> {
    static defaultProps = {
        handler: new ScheduleNotificationPromptHandler(),
        showSkip: true,
        hasChanged: true,
    };

    private onSendClick = async () => {
        const {
            values,
            handler,
            onSend,
            scheduleId,
            setSubmitting,
            validateForm,
            hasChanged,
            newAssignedUsers,
        } = this.props;
        try {
            setSubmitting(true);
            if (Object.keys(await validateForm(values)).length === 0) {
                const schedules = [];
                if (scheduleId !== undefined) {
                    schedules.push({
                        scheduleId,
                        userIds: values.assignedUsersToNotify,
                        notifyNewAssignees: false,
                        requireConfirmation: values.requireConfirmation,
                    });
                } else {
                    schedules.push(
                        ...this.props
                            .schedules!.filter((s) =>
                                values.schedulesToNotify!.includes(Number(s.id))
                            )
                            .map((s) => {
                                const userIds = newAssignedUsers
                                    ? newAssignedUsers.has(Number(s.id))
                                        ? newAssignedUsers.get(Number(s.id))!
                                        : []
                                    : [];
                                return {
                                    scheduleId: Number(s.id),
                                    notifyNewAssignees: !!newAssignedUsers,
                                    userIds: userIds,
                                    requireConfirmation:
                                        values.requireConfirmation &&
                                        values.schedulesToConfirm!.includes(Number(s.id)),
                                };
                            })
                    );
                }

                await handler!.sendNotifications({
                    comments: values.notificationDetails,
                    schedules,
                    hasChanged: hasChanged!,
                });

                onSend();
            }
            setSubmitting(false);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private onSkipClick = () => {
        this.props.onClose();
    };

    render() {
        const {
            values,
            assignedUsers,
            handleChange,
            setFieldTouched,
            setFieldValue,
            schedules,
            isSubmitting,
            showSkip,
        } = this.props;
        const noValuesSelected =
            (!values.notifyMultiple || values.schedulesToNotify.length === 0) &&
            (!values.notifyAssignedUsers || values.assignedUsersToNotify.length === 0);

        return (
            <BTModal
                data-testid="btModalScheduleNotificationPrompt"
                visible
                useModalLayout
                width="600px"
                removeBodyPadding
                title="Send Schedule Item Notifications"
                setPageTitle={false}
                beforeClose={this.onSkipClick}
            >
                <BTModalLayout
                    title="Send Schedule Item Notifications"
                    footerContent={
                        <>
                            <BTButton
                                id="sendNotifications"
                                data-testid="sendNotifications"
                                onClick={this.onSendClick}
                                type="primary"
                                loading={isSubmitting}
                                disabled={noValuesSelected}
                            >
                                Send
                            </BTButton>
                            {showSkip && (
                                <BTButton
                                    id="skipNotifications"
                                    data-testid="skipNotifications"
                                    onClick={this.onSkipClick}
                                    disabled={isSubmitting}
                                >
                                    Skip
                                </BTButton>
                            )}
                        </>
                    }
                    modalConfig={{ parentRoute: "", beforeClose: this.onSkipClick }}
                >
                    <BTLayoutContent>
                        <PageSection title="Notifications">
                            {!schedules && (
                                <NotificationAssignedTo<
                                    IScheduleNotificationPromptFormValues,
                                    undefined
                                >
                                    {...this.props}
                                    shouldNotifyFieldName="notifyAssignedUsers"
                                    shouldNotifyValue={values.notifyAssignedUsers}
                                    usersToNotifyFieldName="assignedUsersToNotify"
                                    usersToNotifyValue={values.assignedUsersToNotify}
                                    users={assignedUsers!}
                                    assigneeType="Affected"
                                    assigneeTitle="Users"
                                    schema={NotificationPromptValidators}
                                />
                            )}
                            {schedules && (
                                <>
                                    <BTFormItem label="">
                                        <BTCheckbox
                                            id="notifyMultiple"
                                            data-testid="notifyMultiple"
                                            checked={values.notifyMultiple}
                                            onChange={handleChange}
                                            disabled={this.props.notifyMultiple} // If the prop enables this by default then disallow the ability for users to disable it
                                        >
                                            Send notifications
                                        </BTCheckbox>
                                    </BTFormItem>
                                    {values.notifyMultiple && (
                                        <BTFormItem label="">
                                            <BTSelect
                                                id="schedulesToNotify"
                                                data-testid="schedulesToNotify"
                                                value={values.schedulesToNotify}
                                                onChange={(field, value) => {
                                                    setFieldValue("schedulesToNotify", value);
                                                    const enabledIds =
                                                        values.schedulesToConfirm.filter((id) =>
                                                            value.includes(id)
                                                        );
                                                    setFieldValue("schedulesToConfirm", enabledIds);
                                                }}
                                                onBlur={setFieldTouched}
                                                treeData={schedules}
                                                multiple
                                            />
                                        </BTFormItem>
                                    )}
                                </>
                            )}
                            {(values.notifyAssignedUsers || values.notifyMultiple) && (
                                <BTFormItemAutomatic<IScheduleNotificationPromptFormValues> id="notificationDetails">
                                    <BTTextArea<IScheduleNotificationPromptFormValues>
                                        id="notificationDetails"
                                        data-testid="notificationDetails"
                                        value={values.notificationDetails}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        maxLength={100}
                                    />
                                </BTFormItemAutomatic>
                            )}
                        </PageSection>
                        {(values.notifyAssignedUsers || values.notifyMultiple) && (
                            <PageSection title="Confirmations">
                                <BTFormItemAutomatic<IScheduleNotificationPromptFormValues>
                                    id="requireConfirmation"
                                    label=""
                                >
                                    <BTCheckbox<IScheduleNotificationPromptFormValues>
                                        id="requireConfirmation"
                                        data-testid="requireConfirmation"
                                        checked={values.requireConfirmation}
                                        onChange={handleChange}
                                    >
                                        Request confirmation{" "}
                                        {!values.notifyMultiple && <>from all assignees</>}
                                    </BTCheckbox>
                                </BTFormItemAutomatic>
                                {values.notifyMultiple && values.requireConfirmation && (
                                    <BTFormItem label="">
                                        <BTSelect
                                            id="schedulesToConfirm"
                                            data-testid="schedulesToConfirm"
                                            value={values.schedulesToConfirm}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            treeData={schedules!.map((s) => new BTSelectItem(s))}
                                            disabledIds={schedules!
                                                .map((s) => s.id)
                                                .filter(
                                                    (id) =>
                                                        !values.schedulesToNotify.includes(
                                                            Number(id)
                                                        )
                                                )}
                                            multiple
                                        />
                                    </BTFormItem>
                                )}
                            </PageSection>
                        )}
                    </BTLayoutContent>
                </BTModalLayout>
            </BTModal>
        );
    }
}

const NotificationPromptValidators = yup.object().shape<IScheduleNotificationPromptFormValues>({
    notifyAssignedUsers: yup.boolean().label("Notify Added Assignees"),
    assignedUsersToNotify: yup.array(yup.number()).label("Added Assignees to Notify"),
    notificationDetails: yup.string().label("Notification Details").max(100),
    requireConfirmation: yup.boolean().label("Request Confirmation from all assignees"),
    notifyMultiple: yup.boolean().label("Send Notifications"),
    schedulesToNotify: yup.array(yup.number()).label("Schedules to Notify"),
    schedulesToConfirm: yup.array(yup.number()).label("Schedules to Request Confirmation"),
});

const ScheduleNotificationPrompt = withFormik<
    IScheduleNotificationPromptProps,
    IScheduleNotificationPromptFormValues
>({
    mapPropsToValues: (props: IScheduleNotificationPromptProps) => ({
        notifyAssignedUsers: false,
        assignedUsersToNotify: [],
        notificationDetails: "",
        requireConfirmation: false,
        notifyMultiple: props.notifyByDefault || props.notifyMultiple || false,
        schedulesToConfirm: props.schedulesToConfirm ?? [],
        schedulesToNotify: props.schedulesToNotify ?? [],
    }),
    validationSchema: () => NotificationPromptValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    handleSubmit: () => {},
})(ScheduleNotificationPromptInternal);

export default ScheduleNotificationPrompt;
