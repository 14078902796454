import { WhenBuilderFlag } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IProposalProps } from "entity/estimate/Proposal/Proposal";

const Proposal = lazyLoadWithRetry(() => import("./Proposal"));
const ProposalBuilder = lazyLoadWithRetry(() => import("./ProposalBuilder"));

export const RouteProposal = (
    props: Omit<IProposalProps, "jobId" | "history" | "location" | "match" | "id">
) => (
    <RouteRelative
        path={routes.estimate.proposal}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            return (
                <BTSuspense>
                    <WhenBuilderFlag
                        isEnabled="jobProposalOwnerTemplates"
                        then={
                            <ProposalBuilder
                                {...props}
                                {...routeProps}
                                id={parseInt(routeValues.proposalId)}
                            />
                        }
                        otherwise={
                            <Proposal
                                {...props}
                                {...routeProps}
                                id={parseInt(routeValues.proposalId)}
                                jobId={parseInt(routeValues.jobId)}
                            />
                        }
                    />
                </BTSuspense>
            );
        }}
    />
);
