import { FC, useEffect } from "react";

import { AppDefaultInfo, UserInfo } from "helpers/AppProvider.types";

import { initializeChat } from "utilities/thirdPartyIntegrations/intercom/intercom";

import "./IntercomChat.less";

interface IIntercomChatProps {
    userInfo: UserInfo | undefined;
    appDefaultInfo: AppDefaultInfo;
    deferIntercomLoad?: boolean;
}

export const IntercomChat: FC<IIntercomChatProps> = ({
    userInfo,
    appDefaultInfo,
    deferIntercomLoad,
    ...restProps
}) => {
    useEffect(() => {
        if (
            appDefaultInfo.enableIntercom &&
            userInfo &&
            userInfo.intercomSettings &&
            !userInfo.isImpersonatingBuilder &&
            !deferIntercomLoad
        ) {
            initializeChat(userInfo.intercomSettings);
        }
    }, [userInfo, appDefaultInfo, deferIntercomLoad]);

    return <>{restProps.children}</>;
};
