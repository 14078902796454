import { FirebaseOptions } from "firebase/app";

export const ServiceWorkerQueryParams = {
    config: "config",
} as const;

export interface IServiceWorkerConfig {
    firebase: FirebaseOptions | null;
}

export interface IServiceWorkerPartition {
    name: string;
    initializeWorker: (serviceWorkerConfig: IServiceWorkerConfig, console: Console) => void;
}
