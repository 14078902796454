import { FunctionComponent } from "react";

import { BTLoginTypes } from "types/enum";

import { routes } from "utilities/routes";
import { isNullOrWhitespace } from "utilities/string/string";

import { BTAvatar } from "commonComponents/btWrappers/BTAvatar/BTAvatar";
import {
    getAvatarColor,
    getAvatarColorDark,
} from "commonComponents/btWrappers/BTAvatar/BTAvatar.utils";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconLiveChat,
    BTIconMailOutlined,
    BTIconMobileOutlined,
    BTIconPhoneOutlined,
    BTIconUser,
} from "commonComponents/btWrappers/BTIcon";
import BTLegacyLink from "commonComponents/btWrappers/BTLegacyLink/BTLegacyLink";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { LinkButton } from "commonComponents/btWrappers/LinkButton/LinkButton";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

import { UserProfileResponse } from "entity/login/Login/Login.api.types";

interface IBTUserInfoDisplayProps {
    globalUserId: number;
    displayName: string;
    userProfile?: UserProfileResponse;

    hideAllControls?: boolean;
    showManageUser: boolean;
}

export const BTUserInfoDisplay: FunctionComponent<IBTUserInfoDisplayProps> = (props) => {
    const {
        globalUserId,
        displayName,
        userProfile,
        hideAllControls = false,
        showManageUser,
    } = props;
    const phoneDisabled = isNullOrWhitespace(userProfile?.phone);
    const cellDisabled = isNullOrWhitespace(userProfile?.cellPhone);
    const emailDisabled = isNullOrWhitespace(userProfile?.email);
    const primaryContactDisabled = isNullOrWhitespace(userProfile?.primaryContact);
    const isSub = userProfile?.userType === BTLoginTypes.SUBS;
    const isBuilder = userProfile?.userType === BTLoginTypes.BUILDER;
    const showNewButton = isBuilder;
    const showOldButton = isSub;
    let manageUserLink = "";
    let manageUserPopoverText = "";

    switch (userProfile?.userType) {
        case BTLoginTypes.BUILDER:
            manageUserLink =
                routes.internalUsers.getListLink() +
                routes.internalUsers.getInternalUserDetailsLink(globalUserId);
            manageUserPopoverText = "Manage Internal User";
            break;
        case BTLoginTypes.SUBS:
            manageUserLink = routes.sub.getListLink() + routes.sub.getDetailsLink(globalUserId);
            manageUserPopoverText = "Manage Sub/Vendor";
            break;
    }

    return (
        <div style={{ width: 350, maxHeight: 300 }} className="BTUser-Popover">
            <BTRow gutter={16} wrap={false}>
                <BTCol>
                    <BTAvatar
                        size="large"
                        style={{
                            backgroundColor: getAvatarColor(globalUserId),
                            color: getAvatarColorDark(globalUserId),
                        }}
                        displayName={displayName}
                    />
                </BTCol>
                <BTCol className="text-overflow-auto">
                    <BTTitle level={2} style={{ margin: 0 }} className="text-overflow-auto">
                        {displayName}
                    </BTTitle>

                    {!emailDisabled && (
                        <BTRow>
                            <BTCol>
                                <BTLink
                                    data-testid="bt-user-send-email"
                                    linkType="email"
                                    type="tertiary"
                                    href={`mailto:${userProfile?.email}`}
                                    to=""
                                    isUnderline={false}
                                    className="BTUser-ContactInfo"
                                >
                                    <BTIconMailOutlined className="padding-right-xs BTUser-ContactInfoIcon" />
                                    {userProfile?.email}
                                </BTLink>
                            </BTCol>
                        </BTRow>
                    )}
                    {!primaryContactDisabled && (
                        <BTRow>
                            <BTCol>
                                <BTPopover content="Primary Contact">
                                    <BTIconUser className="padding-right-xs BTUser-ContactInfoIcon" />
                                    {userProfile?.primaryContact}
                                </BTPopover>
                            </BTCol>
                        </BTRow>
                    )}
                </BTCol>
            </BTRow>
            {!hideAllControls && (
                <div>
                    <ShowOnPortal
                        builder
                        render={() => (
                            <>
                                {props.userProfile?.canStartChatConversation && (
                                    <BTPopover content="Direct Message">
                                        <BTLink
                                            useAutoSPARouting={true}
                                            shouldOpenNewTab
                                            to={routes.chat.getDraftChannelPathWithParticipantLink(
                                                globalUserId,
                                                displayName
                                            )}
                                        >
                                            <BTButton
                                                icon={<BTIconLiveChat />}
                                                data-testid="chat-user"
                                                type="tertiary"
                                                disabled={!userProfile?.canStartChatConversation}
                                            />
                                        </BTLink>
                                    </BTPopover>
                                )}
                            </>
                        )}
                    />
                    {/*
                When we are fully SPA for both Internal Users List and Sub/Vendor List, we can
                consolidate the output of the manage user button to the LinkButton component.
                We cannot do this now because the link button is expecting links for SPA.
                It appends /app to the beginning of the link.
                */}
                    {showManageUser && (
                        <ShowOnPortal
                            builder
                            render={() => (
                                <>
                                    {showNewButton && (
                                        <BTPopover content={manageUserPopoverText}>
                                            <LinkButton
                                                icon={<BTIconUser />}
                                                data-testid="manage-user"
                                                type="tertiary"
                                                to={manageUserLink}
                                                showInNewTab
                                            />
                                        </BTPopover>
                                    )}
                                    {showOldButton && (
                                        <BTPopover content={manageUserPopoverText}>
                                            <BTLegacyLink to={manageUserLink} target="_blank">
                                                <BTButton
                                                    icon={<BTIconUser />}
                                                    data-testid="manage-user"
                                                    type="tertiary"
                                                />
                                            </BTLegacyLink>
                                        </BTPopover>
                                    )}
                                </>
                            )}
                        />
                    )}
                    <BTPopover
                        content={`Call ${userProfile?.phone ?? ""}`}
                        disabled={phoneDisabled}
                    >
                        <BTLink
                            data-testid="bt-user-call-phone"
                            linkType="tel"
                            type="tertiary"
                            href={`tel:${userProfile?.phone}`}
                            to=""
                            isUnderline={false}
                            isDisabled={phoneDisabled}
                        >
                            <BTButton
                                icon={<BTIconPhoneOutlined />}
                                data-testid="phone"
                                type="tertiary"
                                disabled={phoneDisabled}
                            />
                        </BTLink>
                    </BTPopover>
                    {!cellDisabled && (
                        <BTPopover
                            content={`Call cell phone ${userProfile?.cellPhone ?? ""}`}
                            disabled={cellDisabled}
                        >
                            <BTLink
                                data-testid="bt-user-call-cell"
                                linkType="tel"
                                type="tertiary"
                                href={`tel:${userProfile?.cellPhone}`}
                                to=""
                                isUnderline={false}
                                isDisabled={cellDisabled}
                            >
                                <BTButton
                                    icon={<BTIconMobileOutlined />}
                                    data-testid="phone"
                                    type="tertiary"
                                    disabled={cellDisabled}
                                />
                            </BTLink>
                        </BTPopover>
                    )}
                </div>
            )}
        </div>
    );
};
