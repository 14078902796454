import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IPhotoPreviewProps } from "./PhotoPreview";

const PhotoPreview = lazyLoadWithRetry(() => import("./PhotoPreview"));
/**
 * @deprecated Deprecated component, use MediaModal instead
 */

export const RoutePhotoPreview: React.FunctionComponent<
    Omit<IPhotoPreviewProps, "id" | "jobId" | "history" | "location" | "match">
> = (props) => (
    <RouteRelative
        path={routes.photo.preview}
        render={(routeProps) => (
            <BTSuspense>
                <PhotoPreview
                    {...props}
                    {...routeProps}
                    id={parseInt(routeProps.match.params.id)}
                    jobId={parseInt(routeProps.match.params.jobId)}
                />
            </BTSuspense>
        )}
    />
);
