import { PureComponent } from "react";

import { BTUser } from "commonComponents/btWrappers/BTUser/BTUser";
import { IGridCellRenderProps } from "commonComponents/utilities/Grid/Grid.types";
import {
    GridColumn,
    GridColumnType,
    IClientGridColumn,
} from "commonComponents/utilities/Grid/GridContainer.types";

export interface IUser {
    id: number;
    displayName: string;
}

export class User {
    id: number;
    displayName: string;
    constructor(data: { id: number; displayName: string }) {
        this.id = data.id;
        this.displayName = data.displayName;
    }
}

type UserCellType = IUser | undefined;

class UserCell extends PureComponent<IGridCellRenderProps<UserCellType, GridColumn<UserCellType>>> {
    render() {
        const { cellValue } = this.props;

        if (!cellValue) {
            return null;
        }

        return (
            <BTUser
                className="padding-left-xs padding-right-xs"
                displayName={cellValue.displayName}
                globalUserId={cellValue.id}
            />
        );
    }
}

export const columnUser: IClientGridColumn<UserCellType> = {
    columnType: GridColumnType.user,
    Cell: UserCell,
};
