import { BTLoginTypes } from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";

export enum UserActivationMethods {
    LinkAccount = 0,
    CreateAccount = 1,
}

export function isValidUserActivationMethod(
    activationMethod: UserActivationMethods | undefined
): boolean {
    if (isNullOrUndefined(activationMethod) || isNaN(activationMethod)) {
        return false;
    }
    return Object.values(UserActivationMethods).includes(activationMethod);
}

export type UserActivationPageFormActions = undefined | "submit" | "addlink" | "login";

export class UserActivationPageEntity {
    constructor(data: any) {
        this.name = data.name;
        this.builderName = data.builderName;
        this.logoSrc = data.logoSrc;
        this.isActivated = data.isActivated;
        this.isMobile = data.isMobile;
        this.loginType = data.loginType;
        this.suggestedUsername = data.suggestedUsername;
        this.canLinkExistingAccount = data.canLinkExistingAccount;
        this.isFromBuilderSignup = data.isFromBuilderSignup;
        this.hasActiveSession = data.hasActiveSession;
        this.username = data.username;
    }

    name: string;
    builderName: string;
    logoSrc: string;
    suggestedUsername: string;
    isActivated: boolean;
    isMobile: boolean;
    loginType: BTLoginTypes;
    canLinkExistingAccount: boolean;
    isFromBuilderSignup: boolean;
    hasActiveSession: boolean;
    username: string;
}

export interface IUserActivationPageFormValues {
    username: string;
    password: string;
    activationMethod?: UserActivationMethods;
}
export interface IUserActivationLinkRequest {
    username: string;
    password: string;
    signInDescription: string;
    shareToken: string;
}

export interface IUserActivationPageLoginRequest {
    username: string;
    password: string;
    signInDescription: string;
}

export enum ExternalEntitiesWithUserActivation {
    BidSubmitted = 1,
    BidDeclined = 2,
    PurchaseOrderApproved = 3,
    PurchaseOrderDeclined = 4,
}
