import { PureComponent } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { NumberRangeOptionItem } from "types/enum";

import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTInputNumber } from "commonComponents/btWrappers/BTInputNumber/BTInputNumber";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { Currency } from "commonComponents/financial/Currency/Currency";

import "./NumberRange.less";

export interface INumberRangeFormValues {
    numberValue: number | undefined;
    selectedItem: NumberRangeOptionItem;
}

export interface INumberRangeEntity {
    value: number | null;
    options: BTSelectItem[];
}

interface INumberRangeProps<FormValues> {
    id: KeyOfOrString<FormValues> & string;

    onBlur: (field: string, touched: boolean) => void;

    onChange: (field: string, value: INumberRangeFormValues) => void;

    entity: INumberRangeEntity;

    value: INumberRangeFormValues;

    title?: string;

    useCurrency?: boolean;
}

export class NumberRange<FormValues> extends PureComponent<INumberRangeProps<FormValues>> {
    static defaultProps = {
        useCurrency: false,
    };

    private handleNumber = (field: string, newNumberValue: number | undefined) => {
        const newFormValue: INumberRangeFormValues = {
            ...this.props.value,
            numberValue: newNumberValue,
        };
        this.props.onChange(field === undefined ? this.props.id : field, newFormValue);
    };

    private handleCurrency = (field: string, value: number | undefined) => {
        this.handleNumber(field, value);
    };

    private handleChange = (field: string, value: any) => {
        const newValue: INumberRangeFormValues = {
            ...this.props.value,
            selectedItem: value,
        };
        this.props.onChange(this.props.id, newValue);
    };

    render() {
        const { id, onBlur, entity, value, title, useCurrency } = this.props;
        const expanded = value.selectedItem !== NumberRangeOptionItem.All;

        return (
            <>
                {title && (
                    <BTRow>
                        <BTCol span={24}>
                            <span>{title}</span>
                        </BTCol>
                    </BTRow>
                )}

                <BTRow gutter={10}>
                    <BTCol span={expanded ? 12 : 24}>
                        <BTSelect
                            id={id}
                            data-testid={id.toString()}
                            onBlur={onBlur}
                            onChange={this.handleChange}
                            treeData={entity.options}
                            value={value.selectedItem}
                        />
                    </BTCol>

                    {expanded && (
                        <BTCol span={12}>
                            {useCurrency && (
                                <Currency
                                    id={`${id}--inputCurrency`}
                                    data-testid={`${id}--inputCurrency`}
                                    onBlur={onBlur}
                                    onChange={this.handleCurrency}
                                    value={value.numberValue}
                                />
                            )}
                            {!useCurrency && (
                                <BTInputNumber
                                    id={id}
                                    data-testid={id}
                                    className="numberRange--inputNumber"
                                    value={value.numberValue}
                                    min={-Infinity}
                                    max={Infinity}
                                    precision={0}
                                    onChange={(field: any, value: any) =>
                                        this.handleNumber(field, value)
                                    }
                                />
                            )}
                        </BTCol>
                    )}
                </BTRow>
            </>
        );
    }
}
