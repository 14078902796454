import { BTSelectItem } from "types/apiResponse/apiResponse";
import { SplitDateTime } from "types/splitDateTime";

export class ActivityImportEntity {
    constructor(data: any) {
        this.userId = data.userId;
        this.templates = data.templates.map((item: any) => new BTSelectItem(item));
        this.salespeople = data.salespeople.map((item: any) => new BTSelectItem(item));
        this.leadsWithoutEmails = data.leadsWithoutEmails.map(
            (l: any) => new LeadWithoutEmailEntity(l)
        );

        this.salespeople.unshift(new BTSelectItem({ id: -1, name: "Current Assigned Users" }));
    }
    userId: number;
    templates: BTSelectItem[];
    salespeople: BTSelectItem[];
    leadsWithoutEmails: LeadWithoutEmailEntity[];
}

class LeadWithoutEmailEntity {
    constructor(data: any) {
        this.contactId = data.contactId;
        this.contactName = data.contactName;
        this.leadIds = data.leadIds;
        this.opportunities = data.opportunities;
    }
    contactId: number;
    contactName: string;
    leadIds: number[];
    opportunities: string[];
}

export interface IActivityImportFormValues {
    templateId?: number;
    startDate: SplitDateTime | null;
    salespersonId?: number;
    updatedEmails: ILeadContactFormValues[];
}

export interface ILeadContactFormValues {
    id: number;
    email: string;
}
