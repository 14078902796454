import { Autocomplete } from "@react-google-maps/api";
import { ErrorMessage, FormikErrors, FormikTouched } from "formik";
import { startCase } from "lodash-es";
import { Component } from "react";
import { InputState } from "react-input-mask";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { Countries } from "types/countries";

import { track } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { getValidateStatusTouched } from "utilities/form/form";
import yup from "utilities/form/yup";
import { isNullOrWhitespace } from "utilities/string/string";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTMaskedInput } from "commonComponents/btWrappers/BTMaskedInput/BTMaskedInput";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import {
    AddressHandler,
    IAddressHandler,
} from "commonComponents/entity/address/Address/Address.api.handler";
import {
    AddressEntity,
    AddressFieldTypes,
    AddressFormValues,
    AddressItem,
    AddressServiceObject,
    EntityMappingStatus,
    getBottomAddressForMapToolTip,
    getPinTypeForMap,
    IAddressCountryChangedRequest,
    IHasAddress,
    LocationEntity,
} from "commonComponents/entity/address/Address/Address.api.types";
import AddressDisplay from "commonComponents/entity/address/AddressDisplay";
import LoadMapApi from "commonComponents/entity/map/common/mapApiContext/LoadMapApi";
import { InfoWindowContent } from "commonComponents/entity/map/InfoWindowContent";
import { IMapPosition, Mark, PinTypes } from "commonComponents/entity/map/Map.types";
import { MapButton } from "commonComponents/entity/map/MapButton/MapButton";
import { entityIsMappable } from "commonComponents/entity/map/mapUtilities";
import { RequiredFieldIndicator } from "commonComponents/utilities/RequiredFieldIndicator/RequiredFieldIndicator";

import "./Address.less";

interface IAddressProps<FormikValuesType> {
    id: string;
    entity: IHasAddress;
    value: AddressFormValues;
    readOnly: boolean;
    errors: FormikErrors<FormikValuesType>;
    touched: FormikTouched<FormikValuesType>;
    onBlur: (id: string, touched: boolean) => void;
    onChange: (id: string, value: any) => void;
    refreshAddress: (id: string, newAddress: AddressEntity) => void;
    onAutoComplete?: (newValues: AddressFormValues) => void;
    disabled?: boolean;
    disableCountryDropdown?: boolean;
    countryDropdownPopover?: JSX.Element;
    enableAutocomplete: boolean;
    enableMapButton: boolean;
    isMapOpenOnLoad: boolean;
    handler?: IAddressHandler;
    entityName?: string;
    mapPinTooltipHeader?: string;
    mapPinTooltipBody?: string;
    mapDefaultStartPosition?: IMapPosition;
    required?: boolean;
    isBuilderConnectedToAccounting?: boolean;
    showFullLengthFields?: boolean;
    supportedCountryCodes?: Countries[];
    shouldEnforceUSZipCodeFormat?: boolean;
}

class AddressState {
    autocomplete: any;
}

@track((props) => ({ component: "Address", uniqueId: props.id }))
class Address<FormikValuesType> extends Component<IAddressProps<FormikValuesType>, AddressState> {
    static defaultProps = {
        enableAutocomplete: false,
        enableMapButton: false,
        isMapOpenOnLoad: false,
        readOnly: false,
        handler: new AddressHandler(),
        showFullLengthFields: false,
    };

    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    componentDidMount() {
        this.setState({ autocomplete: null });
    }

    private beforeMaskedValueChange = (
        newState: InputState,
        oldState: InputState,
        userInput: string
    ) => {
        let { value } = newState;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;

        // Only keep a hyphen if entered by user
        if (value.endsWith("-") && userInput !== "-" && !oldState.value.endsWith("-")) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection,
        };
    };

    private handleChangeInternal = (field: string, fieldValue: string) => {
        const { value } = this.props;
        const newFormValues = { ...value, [field]: fieldValue };
        this.updateLocation(newFormValues, false);
        this.props.onChange(field, fieldValue);
    };

    private handleSelectChange = async (field: keyof AddressFormValues, selectedId: number) => {
        const { value, id } = this.props;

        if (field === "countryId") {
            // Clear out city/suburb--the only two fields that may interchange
            const newFormValues = { ...value, suburb: "", city: "", state: "" };
            this.updateLocation(newFormValues, false);
            this.props.onChange(id, newFormValues);
            await this.changeCountry(selectedId);
        } else {
            const newFormValues = { ...value, [field]: selectedId };
            this.updateLocation(newFormValues, false);
            this.props.onChange(id, newFormValues);
        }
    };

    private updateLocation(value: AddressFormValues, hasAutoCompleteLocation: boolean) {
        if (value.location) {
            value.location.hasAutoCompleteLocation = hasAutoCompleteLocation;
            // Clear location if not manual and not valid
            if (
                !entityIsMappable(value, this.props.entity.address) &&
                !hasAutoCompleteLocation &&
                value.location.mappingStatus !== EntityMappingStatus.MappedManually
            ) {
                value.location.mapPosition = null;
                value.location.mappingStatus = EntityMappingStatus.NotMapped;
            }
        }
    }

    private getStateFromDropdown = (id: number) => {
        const selectedState = this.props.entity.address.stateDropdown
            ? this.props.entity.address.stateDropdown.find((item) => Number(item.id) === id)
            : undefined;
        return typeof selectedState === "undefined" ? "" : selectedState.title;
    };

    private getCountryFromDropdown = (id: number) => {
        const selectedCountry = this.props.entity.address.countryDropdown
            ? this.props.entity.address.countryDropdown.find((item) => Number(item.id) === id)
            : undefined;
        return typeof selectedCountry === "undefined" ||
            typeof selectedCountry.extraData === "undefined"
            ? "US"
            : selectedCountry.extraData.countryCode;
    };

    private changeCountry = async (newCountryId: number, otherValues?: AddressFormValues) => {
        const value = typeof otherValues !== "undefined" ? otherValues : this.props.value;
        const req: IAddressCountryChangedRequest = {
            street: value.street === "" ? null : value.street,
            suburb: value.suburb,
            city: value.city,
            zip: value.zip === "" ? null : value.zip,
            state: value.state === "" ? null : value.state,
            country: null,
            supportedCountryCodes: this.props.supportedCountryCodes,
        };

        if (value.stateId > -1) {
            req.state = this.getStateFromDropdown(value.stateId);
        }
        req.country = this.getCountryFromDropdown(newCountryId);
        try {
            const response = await this.props.handler!.countryChanged(req);
            if (response.stateDropdownId > -2 && response.stateDropdown !== null) {
                // Need to clear the State value or else it will be over the 3 character limit when switching to a dropdown
                response.fields.find((f) => f.addressFieldType === AddressFieldTypes.State)!.value =
                    "";
            }
            return this.props.refreshAddress(this.props.id, response);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private renderAddressItem = (
        item: AddressItem,
        index: number,
        formValues: AddressFormValues,
        entity: AddressEntity,
        showCity: boolean,
        showState: boolean,
        showSuburb: boolean,
        showMapButton: boolean
    ) => {
        const { errors, touched, required, countryDropdownPopover } = this.props;
        const value = formValues[item.id] || "";
        const id = `${this.props.id}.${item.id}`;

        const showStateDD =
            item.addressFieldType === AddressFieldTypes.State && entity.stateDropdown !== null;
        const showCountryDD =
            item.addressFieldType === AddressFieldTypes.Country && entity.countryDropdown !== null;
        const showTextField = !showStateDD && !showCountryDD;

        let span = { xs: 24, sm: 24 };
        if (!this.props.showFullLengthFields) {
            switch (item.addressFieldType) {
                case AddressFieldTypes.City:
                    span = { xs: 24, sm: !showSuburb && !showState ? 16 : 12 };
                    break;
                case AddressFieldTypes.Suburb:
                    span = { xs: 24, sm: showCity ? 6 : 8 };
                    break;
                case AddressFieldTypes.State:
                    span = { xs: 12, sm: showCity ? 6 : 8 };
                    break;
                case AddressFieldTypes.Zip:
                    span = { xs: 12, sm: showCity && (showState || showSuburb) ? 6 : 8 };
                    break;
            }
        }

        let isReadOnly = false,
            isRequired = false;
        item.validators.forEach((v) => {
            if (v.type === "readonly") {
                isReadOnly = v.value;
            } else if (v.type === "required" || required) {
                // v.value is always an empty string when a field is required. If required is a validator on the item then we mark it as required.
                isRequired = true;
            }
        });

        return (
            <BTCol key={index} xs={span.xs} sm={span.sm}>
                <BTFormItem
                    label={
                        <>
                            {item.title}
                            {showCountryDD && item.title === "Country" && countryDropdownPopover}
                            {isRequired && <RequiredFieldIndicator />}
                        </>
                    }
                    validateStatus={getValidateStatusTouched(errors, touched, id)}
                    help={<ErrorMessage name={id} />}
                    className="AddressFormItem"
                >
                    {showStateDD &&
                        this.renderDropdown(formValues.stateId, "stateId", entity.stateDropdown!)}
                    {showCountryDD &&
                        this.renderDropdown(
                            formValues.countryId,
                            "countryId",
                            entity.countryDropdown!
                        )}
                    {showTextField && this.renderTextField(item, id, value.toString(), isReadOnly)}
                    {showMapButton &&
                        item.addressFieldType === AddressFieldTypes.Street &&
                        formValues.location &&
                        this.renderMapButton(formValues.location)}
                </BTFormItem>
            </BTCol>
        );
    };

    private renderTextField = (item: AddressItem, id: string, value: string, disabled: boolean) => {
        let content = (
            <BTInput<undefined>
                id={id}
                placeholder=""
                data-testid={id}
                value={value}
                onChange={this.handleChangeInternal}
                onBlur={this.props.onBlur}
                disabled={disabled || this.props.readOnly || this.props.disabled}
            />
        );

        if (
            item.addressFieldType === AddressFieldTypes.Street &&
            !disabled &&
            this.props.enableAutocomplete
        ) {
            return (
                <LoadMapApi
                    loadingElement={<></>}
                    render={() => (
                        <Autocomplete
                            onLoad={this.autoCompleteLoaded}
                            onPlaceChanged={this.autoCompleteAddressFields}
                        >
                            {content}
                        </Autocomplete>
                    )}
                    renderOnFail={content}
                />
            );
        }

        if (item.addressFieldType === AddressFieldTypes.Zip) {
            if (this.props.shouldEnforceUSZipCodeFormat) {
                content = (
                    <BTMaskedInput<undefined>
                        id={id}
                        data-testid={id}
                        value={value}
                        onChange={this.handleChangeInternal}
                        beforeMaskedValueChange={this.beforeMaskedValueChange}
                        onBlur={this.props.onBlur}
                        mask="XXXXX-XXXX"
                        formatChars={{
                            X: "[0-9-]",
                        }}
                        disabled={disabled || this.props.readOnly || this.props.disabled}
                    />
                );
            }
            if (this.props.isBuilderConnectedToAccounting) {
                return (
                    <>
                        {content}
                        <BTPopover
                            content={
                                <>
                                    Automated sales tax is determined by the job's address. If the
                                    tax rate is different than your jobsite's actual tax rate, reset
                                    the job through <b>Update Customer/Job</b> in the{" "}
                                    <b>Accounting</b> tab.
                                </>
                            }
                        />
                    </>
                );
            }
        }

        return content;
    };

    private renderDropdown = (
        value: number,
        fieldName: keyof AddressFormValues,
        fieldData: BTSelectItem<any>[]
    ) => {
        const id = `${this.props.id}.${fieldName}`;
        const isDisabled =
            this.props.disabled || (fieldName === "countryId" && this.props.disableCountryDropdown);
        return (
            <div style={{ width: "100%" }}>
                <BTSelect
                    id={id}
                    data-testid={id}
                    treeData={fieldData}
                    value={value}
                    onChange={(field, selectedId) => this.handleSelectChange(fieldName, selectedId)}
                    onBlur={this.props.onBlur}
                    readOnly={this.props.readOnly}
                    disabled={isDisabled}
                />
            </div>
        );
    };

    private renderAddressForMapTooltip = () => {
        const { value, entity } = this.props;
        const { street } = value;
        let formattedAddress = <></>;
        if (!isNullOrWhitespace(street)) {
            formattedAddress = <div>{street}</div>;
        }

        const formattedBottomAddress = getBottomAddressForMapToolTip(entity.address);

        if (formattedBottomAddress.length > 0) {
            formattedAddress = (
                <>
                    {formattedAddress}
                    <div>{formattedBottomAddress}</div>
                </>
            );
        }

        return formattedAddress;
    };

    private renderMapButton = (location: LocationEntity) => {
        const {
            isMapOpenOnLoad,
            mapPinTooltipHeader,
            mapPinTooltipBody,
            mapDefaultStartPosition,
            entityName,
        } = this.props;

        const pinType = getPinTypeForMap(location);
        const mark =
            location.mapPosition && pinType !== PinTypes.None
                ? new Mark({
                      id: "addressPin",
                      position: location.mapPosition,
                      pinType: pinType,
                      animate: false,
                      infoWindowContent: (
                          <InfoWindowContent
                              pinType={pinType}
                              header={mapPinTooltipHeader}
                              body={mapPinTooltipBody}
                              footer={this.renderAddressForMapTooltip()}
                          />
                      ),
                  })
                : undefined;

        return (
            <MapButton
                data-testid="addressMapButton"
                type="link"
                className="MapButton"
                mark={mark}
                entityName={entityName}
                isOpenWithMap={isMapOpenOnLoad}
                onMarkSet={this.onMarkSet}
                onMarkClear={() => this.onMarkSet(null)}
                defaultMapStartPosition={mapDefaultStartPosition}
            />
        );
    };

    private onMarkSet = (newPosition: IMapPosition | null) => {
        const { value, id } = this.props;
        const newLocation: LocationEntity = {
            mapPosition: newPosition,
            mappingStatus:
                newPosition !== null
                    ? EntityMappingStatus.MappedManually
                    : EntityMappingStatus.NotMapped,
            hasAutoCompleteLocation: false,
        };
        this.props.onChange(id, { ...value, location: newLocation });
    };

    private autoCompleteLoaded = (data: any) => {
        this.setState({ autocomplete: data });
    };

    private autoCompleteAddressFields = async () => {
        const place = this.state.autocomplete.getPlace();
        let streetNumber = "",
            newStreet = "",
            newCountry = "";
        const newValues = { ...this.props.value };
        place.address_components.forEach((c: any) => {
            const addressType: string = c.types[0];
            if (GetNameForKey(addressType).length > 0) {
                const val: string = c[GetNameForKey(addressType)];
                switch (addressType) {
                    case "street_number":
                        streetNumber = val;
                        break;
                    case "route":
                        newStreet = val;
                        break;
                    case "locality":
                    case "postal_town":
                        newValues.city = val;
                        break;
                    case "administrative_area_level_1":
                        newValues.state = val;
                        break;
                    case "administrative_area_level_2":
                        newValues.suburb = val;
                        break;
                    case "postal_code":
                        newValues.zip = val;
                        break;
                    case "country":
                        newCountry = val;
                }
            }
        });

        // Add location if it exists from places API
        let hasLocation = false;
        if (
            this.props.value.location?.mappingStatus !== EntityMappingStatus.MappedManually &&
            place.geometry &&
            place.geometry.location &&
            place.geometry.location.lat()
        ) {
            newValues.location = {
                mapPosition: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                mappingStatus: EntityMappingStatus.Mapped,
                hasAutoCompleteLocation: true,
            };
            hasLocation = true;
        }

        newValues.street = `${streetNumber}${streetNumber.length > 0 ? " " : ""}${newStreet}`;

        if (newValues.countryId > -1) {
            const newSelectedCountry = this.props.entity.address.countryDropdown
                ? this.props.entity.address.countryDropdown.find(
                      (item) => item.extraData!.countryCode === newCountry
                  )
                : undefined;
            if (
                typeof newSelectedCountry !== "undefined" &&
                newSelectedCountry.value !== newValues.countryId
            ) {
                await this.changeCountry(newSelectedCountry.value, newValues);
                return;
            }
            // We want to ensure that the suburb is null on autofill unless the country supports Suburbs
            if (
                newCountry !== "AU" &&
                newCountry !== "IE" &&
                newCountry !== "NZ" &&
                newCountry !== "GB"
            ) {
                newValues.suburb = null;
            }
        }

        if (newValues.stateId > -2) {
            // Get the new state ID for the provided string
            const newSelectedState = this.props.entity.address.stateDropdown
                ? this.props.entity.address.stateDropdown.find(
                      (item) =>
                          item.title === newValues.state ||
                          item.extraData!.stateAbbr === newValues.state
                  )
                : undefined;
            newValues.stateId =
                typeof newSelectedState === "undefined" ? 0 : newSelectedState.value;
        }

        // If we have location data, trigger onAutoComplete
        if (this.props.onAutoComplete && hasLocation) {
            this.props.onAutoComplete(newValues);
        }

        this.updateLocation(newValues, hasLocation);
        this.props.onChange(this.props.id, newValues);
    };

    render() {
        const {
            value,
            entity,
            enableMapButton,
            mapPinTooltipHeader,
            mapPinTooltipBody,
            mapDefaultStartPosition,
            readOnly,
            entityName,
        } = this.props;
        const addressResponse = entity.address;
        const showCity = addressResponse.fields.some(
            (f) => f.addressFieldType === AddressFieldTypes.City
        );
        const showState = addressResponse.fields.some(
            (f) => f.addressFieldType === AddressFieldTypes.State
        );
        const showSuburb = addressResponse.fields.some(
            (f) => f.addressFieldType === AddressFieldTypes.Suburb
        );
        return (
            <>
                {readOnly ? (
                    <AddressDisplay
                        address={entity.address}
                        className="padding-bottom-lg"
                        showMapIcon
                        entityName={entityName}
                        mapDefaultStartPosition={mapDefaultStartPosition}
                        mapPinTooltipBody={mapPinTooltipBody}
                        mapPinTooltipHeader={mapPinTooltipHeader}
                    />
                ) : (
                    <BTRow gutter={8} className="Address">
                        {addressResponse.fields
                            .sort((a, b) => a.order - b.order)
                            .map((item, index) =>
                                this.renderAddressItem(
                                    item,
                                    index,
                                    value,
                                    entity.address,
                                    showCity,
                                    showState,
                                    showSuburb,
                                    enableMapButton
                                )
                            )}
                    </BTRow>
                )}
            </>
        );
    }
}

export default Address;

export function GetAddressServiceObject(entity: IHasAddress, values: AddressFormValues) {
    let serviceObject: AddressServiceObject = [];
    entity.address.fields.forEach((field) => {
        const value = GetValueForFieldType(field.addressFieldType, entity, values);
        if (value !== null) {
            serviceObject.push({ title: field.title, value });
        }
    });

    return serviceObject;
}

export function GetAddressValidators(
    entity: IHasAddress,
    required?: boolean,
    name?: string,
    excludePOBoxes?: boolean,
    shouldEnforceUSZipCodeFormat?: boolean
) {
    return yup
        .object<AddressFormValues>()
        .shape(
            GetAddressValidatorShape(
                entity,
                required,
                name,
                excludePOBoxes,
                shouldEnforceUSZipCodeFormat
            )
        );
}

export function GetAddressValidatorShape(
    entity: IHasAddress,
    required?: boolean,
    name?: string,
    excludePOBoxes?: boolean,
    shouldEnforceUSZipCodeFormat?: boolean
) {
    const validatorName = name != null ? name : "";
    return {
        street: GetValidatorForId("street", entity, required!, validatorName, excludePOBoxes),
        city: GetValidatorForId("city", entity, required!, validatorName),
        suburb: GetValidatorForId("suburb", entity, required!, validatorName),
        state: GetValidatorForId("state", entity, required!, validatorName),
        zip: GetValidatorForId(
            "zip",
            entity,
            required,
            validatorName,
            excludePOBoxes,
            shouldEnforceUSZipCodeFormat
        ),
        stateId:
            entity.address.stateDropdown && required!
                ? yup
                      .number()
                      .requiredDropdown(-1, "Please select a State/Region/Province")
                      .label(`${startCase(validatorName)} State`)
                      .typeError("Please select a State")
                : yup.number().label("state"),
        countryId:
            entity.address.countryDropdown && required!
                ? yup
                      .number()
                      .requiredDropdown(0, "Please select a Country")
                      .label(`${startCase(validatorName)} Country`)
                      .typeError("Please select a Country")
                : yup.number().label("country"),
    };
}

function GetValidatorForId(
    id: keyof AddressFormValues,
    data: IHasAddress,
    required?: boolean,
    name?: string,
    excludePOBoxes?: boolean,
    shouldEnforceUSZipCodeFormat?: boolean
) {
    const validator = yup.string().label(`${startCase(name!)} ${startCase(id)}`);
    const item = data.address.fields.find((item) => item.id === id) || null;

    if (item === null) {
        return yup
            .string()
            .nullable()
            .label(`${startCase(name!)} ${startCase(id)}`);
    }

    let isRequired = false,
        maxLength = 50;
    item.validators.forEach((v) => {
        if (v.type === "required" || required) {
            isRequired = required! ? true : v.value;
        }
        if (v.type === "maxLength") {
            maxLength = v.value;
        }
    });
    isRequired = id === "state" && data.address.stateDropdown ? false : isRequired;
    if (id === "street" && excludePOBoxes) {
        const poBoxRegex =
            /^(?!.*(?:(.*((p|post)[-.\s]*(o|office)[-.\s]*box[-.\s]*)|.*((p |post)[-.\s]*box[-.\s]*)))).*$/i;
        const poBoxErrorMessage = "PO Boxes are not accepted";
        return isRequired
            ? validator.required().max(maxLength).matches(poBoxRegex, poBoxErrorMessage)
            : validator.max(maxLength).matches(poBoxRegex, poBoxErrorMessage);
    }
    if (id === "zip" && shouldEnforceUSZipCodeFormat) {
        maxLength = 10;
        // Only allow zip codes in the following valid formats: ##### and #####-####
        return isRequired
            ? validator
                  .required()
                  .matches(/^\d{5}(?:-\d{4})?$/, "Enter a valid zip code\n(00000 or 00000-0000)")
            : validator.matches(
                  /^\d{5}(?:-\d{4})?$/,
                  "Enter a valid zip code\n(00000 or 00000-0000)"
              );
    }
    return isRequired ? validator.required().max(maxLength) : validator.max(maxLength);
}

function GetNameForKey(key: string) {
    switch (key) {
        case "route":
        case "locality":
        case "postal_town":
            return "long_name";
        case "country":
        case "street_number":
        case "administrative_area_level_1":
        case "administrative_area_level_2":
        case "postal_code":
            return "short_name";
        default:
            return "";
    }
}

function GetValueForFieldType(
    type: AddressFieldTypes,
    entity: IHasAddress,
    values: AddressFormValues
) {
    switch (type) {
        case AddressFieldTypes.Street:
            return values.street;
        case AddressFieldTypes.Suburb:
            return values.suburb;
        case AddressFieldTypes.City:
            return values.city;
        case AddressFieldTypes.State:
            return entity.address.stateDropdown ? values.stateId : values.state;
        case AddressFieldTypes.Zip:
            return values.zip;
        case AddressFieldTypes.Country:
            return values.countryId;
        default:
            return null;
    }
}
