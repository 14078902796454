import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";
import { DiscussionsLinkedTypes } from "types/enum";

import {
    CommentPermissions,
    DiscussionCommentEntity,
} from "commonComponents/entity/comment/CommentContainer/CommentContainer.api.types";
import { DiscussionRfiEntity } from "commonComponents/entity/rfi/RfiContainer/RfiContainer.api.types";

/** implement this interface on your entity to support the discussions component */
export interface IHasDiscussionContainer extends IBaseEntity {
    discussions: DiscussionsEntity;
    instanceKey: string;
    // note: to get the linked type use linkedDiscussionEntities.commentLinkedType and linkedDiscussionEntities.rfiLinkedType
}

export class LinkedDiscussionEntities {
    constructor(data: any) {
        this.commentLinkedId = data.commentLinkedId;
        this.commentLinkedType = data.commentLinkedType;
        this.rfiLinkedId = data.rfiLinkedId;
        this.rfiLinkedType = data.rfiLinkedType;
    }

    commentLinkedId: number;
    commentLinkedType: DiscussionsLinkedTypes;
    rfiLinkedId: number;
    rfiLinkedType: DiscussionsLinkedTypes;
}

export class RelatedComment {
    constructor(data: any) {
        this.canAdd = data.canAdd;
        this.canView = data.canView;
        this.values = data.values.map((c: any) => new DiscussionCommentEntity(c));
    }

    canAdd: boolean;
    canView: boolean;
    values: DiscussionCommentEntity[];
}

export class RelatedRFI {
    constructor(data: any) {
        this.queryParamName = data.queryParamName;
        this.canAdd = data.canAdd;
        this.canView = data.canView;
        this.values = data.values.map((rfi: any) => new DiscussionRfiEntity(rfi));
    }

    queryParamName: string;
    canAdd: boolean;
    canView: boolean;
    values: DiscussionRfiEntity[];
}

export class DiscussionsEntity {
    constructor(data: any) {
        this.showCommentInfo = data.showCommentInfo;
        this.rfis = data.rfis.map((rfi: any) => new DiscussionRfiEntity(rfi));

        let comments = [];
        let notificationUsers = {};
        this.showDiscussions = data.showDiscussions;
        this.showRfis = data.showRfis;
        this.currentPortalType = data.currentPortalType;
        this.hasAddRFIPermissions = data.hasAddRFIPermissions;
        this.hasAddCommentPermissions = data.hasAddCommentPermissions;
        this.restrictSubAccess = data.restrictSubAccess || false;
        this.rfiButtonOverride = new RfiButtonOverride(data.rfiButtonOverride);
        this.linkedDiscussionEntities = new LinkedDiscussionEntities(data.linkedDiscussionEntities);
        this.jobId = data.jobId;
        this.entityTitle = data.entityTitle;
        this.jobName = data.jobName;
        this.showComments = data.showComments;
        this.channelId = data.channelId;
        this.oldestUnreadCommentId = data.oldestUnreadCommentId;

        // data.comments can be an array or an object (when no comments exist)
        if (data.comments === null) {
            // new entity, comments/rfi's cannot be added until the entitiy is saved
            this.addNewCommentPermissions = null;
            notificationUsers = {
                options: [],
            };
        } else if (Array.isArray(data.comments.comments)) {
            const firstComment = data.comments.comments[0];

            notificationUsers = data.comments.notificationUsers;
            this.addNewCommentPermissions = new CommentPermissions(firstComment);

            if (firstComment.commentsExist) {
                // only pull comments when they're real (not just sending down permissions)
                comments = data.comments.comments.map((c: any) => new DiscussionCommentEntity(c));
            }
        }

        this.comments = new Comments({
            comments: comments,
            notificationUsers: notificationUsers,
        });
    }

    showCommentInfo: boolean;
    rfis: DiscussionRfiEntity[];

    comments: Comments;

    /** null when on a new entity and unsaved */
    addNewCommentPermissions: CommentPermissions | null;

    entityTitle: string;
    jobName: string;
    jobId: number;
    showRfis: boolean;
    showDiscussions: boolean;
    currentPortalType: number;
    hasAddRFIPermissions: boolean;
    hasAddCommentPermissions: boolean;
    restrictSubAccess: boolean;
    rfiButtonOverride: RfiButtonOverride;
    linkedDiscussionEntities: LinkedDiscussionEntities;
    showComments: boolean;
    channelId?: number | null;
    oldestUnreadCommentId: number | null;
}

class Comments {
    constructor(data: any) {
        this.comments = data.comments;

        if (data.notificationUsers.options === undefined) {
            this.notificationUsers = [];
            this.defaultNotifyUsers = [];
        } else {
            this.notificationUsers = data.notificationUsers.options.map(
                (n: any) => new BTSelectItem(n)
            );
            this.defaultNotifyUsers = data.notificationUsers.value;
        }
    }

    comments: DiscussionCommentEntity[];
    notificationUsers: BTSelectItem[];
    defaultNotifyUsers: number[];
}

/** API response when adding a new comment */
export class ParticipantsResponse {
    constructor(data: any) {
        this.owners = data.owners;
        this.subs = data.subs;
        this.internalUsers = data.internalUsers;
    }

    owners: string[];
    subs: string[];
    internalUsers: string[];
}

export class RfiButtonOverride {
    constructor(data: any) {
        this.value = data.value;
        this.message = data.message;
    }

    value: boolean;
    message: string;
}

export class EntitiesResponse {
    constructor(data: any) {
        if (data && data.length > 0) {
            this.entities = data.map(
                (item: any) => new BTSelectItem({ id: item.id, title: item.dropdownTitle })
            );
        } else {
            this.entities = [];
        }
    }
    entities: BTSelectItem[];
}
