import { Component, useContext } from "react";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

import { injectScript } from "utilities/script/injectScript";

import { ParagonContext, ParagonStatus } from "./ParagonContext";

interface IParagonProviderProps {
    enabled: boolean;
    paragonSdkLocation: string;
}

interface IParagonProviderState {
    status: ParagonStatus;
}

class ParagonProviderInternal extends Component<IParagonProviderProps, IParagonProviderState> {
    state: Readonly<IParagonProviderState> = {
        status: ParagonStatus.NotLoaded,
    };

    async componentDidMount() {
        if (!!document.getElementById("ParagonScript")) {
            this.setState({ status: ParagonStatus.Loaded });
            return;
        }

        if (!this.props.enabled) {
            this.setState({ status: ParagonStatus.Unavailable });
            return;
        }

        // Initialize Paragon
        try {
            await this.initializeParagon();
            this.setState({ status: ParagonStatus.Loaded });
        } catch {
            this.setState({ status: ParagonStatus.Error });
        }
    }

    /** Initializes Paragon. Derived from their provided install script. */
    private async initializeParagon() {
        const paragonScriptBaseUrl = this.props.paragonSdkLocation;

        if (paragonScriptBaseUrl) {
            await injectScript({
                id: "ParagonScript",
                url: paragonScriptBaseUrl,
            });
        }
    }

    render() {
        return (
            <ParagonContext.Provider
                value={{
                    status: this.state.status,
                }}
            >
                {this.props.children}
            </ParagonContext.Provider>
        );
    }
}

export const ParagonProvider: React.FunctionComponent = ({ children }) => {
    const appDefaults = useContext(AppDefaultInfoContext);

    const paragonProviderProps: IParagonProviderProps = {
        enabled: appDefaults?.enableParagon ?? false,
        paragonSdkLocation: appDefaults?.marketplaceConfiguration?.paragonSdkLocation ?? "",
    };
    return <ParagonProviderInternal {...paragonProviderProps}>{children}</ParagonProviderInternal>;
};
