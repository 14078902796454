export class JobPickerInfo {
    id: number;
}

export enum JobPickerDisplayModes {
    None = 0,
    Minimal = 1,
    Jobsites = 2,
    Calendar = 3,
    Email = 4,
    ChangeOrder = 5,
    Photos = 6,
    Documents = 7,
    Options = 8,
    Warranty = 9,
    Selections = 10,
    Custom = 11,
    PurchaseOrder = 12,
    Bids = 13,
    ToDos = 14,
    DailyLogs = 15,
    Messages = 16,
    Budget = 17,
    TimeClock = 18,
    Survey = 19,
    Videos = 20,
    OwnerPayments = 21,
    Rfis = 22,
    OnlinePaymentReport = 23,
    Specifications = 24,
    Estimates = 25,
    Bills = 26,
    PurchaseOrders = 27,
    Comments = 28,
    JobPickerRouter = 29,
    NotificationHistory = 30,
}

export enum JobSortOptions {
    Alphabetic = 1,
    CloseDate = 2,
    StartDateProjected = 3,
    CreatedDate = 4,
}

export enum JobPickerSelectModes {
    Single = 1,
    Multiple = 2,
}

export enum JobIdTypes {
    AllJobs = 0,
    NoJobs = -1,
    GlobalDocs = -2,
    GlobalJob = -4,
}

export interface IJobPickerPersistantState {
    keywordSearch: string;
    filters: string;
    templateFilters: string;
    isTemplateMode: boolean;
    selectedJobIds: number[];
    isAllJobsSelected: boolean;
    allBuildersSelected?: boolean;
}
