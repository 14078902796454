import { Input, Select, Space, Tag, TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import classNames from "classnames";
import { createRef, Fragment, PureComponent, ReactElement } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { getAllIds, getByValue, getDisabledIds } from "utilities/form/form";
import { addCloseOnScroll, getClosestModal, removeCloseOnScroll } from "utilities/helpers";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";
import {
    BTSelectCustomItemContext,
    IBTSelectCustomItemContext,
} from "commonComponents/btWrappers/BTSelect/BTSelectCustomItemContext";
import ReadMore from "commonComponents/utilities/ReadMore/ReadMore";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./BTSelect.less";

const { Option, OptGroup } = Select;
const { TreeNode } = TreeSelect;

// note set in less as well
const STANDARD_OPTION_HEIGHT = 32;

export const BTSelectCheckAllItems = "~~~BTSelectCheckAllItems~~~";
export const BTSelectGroupHeaderId = "selectGroupAutoId";

export type BTSelectMaxHeight = "xsmall" | "small" | "middle" | "large";

interface IAntSelectNode {
    value?: string | number;
    title?: React.ReactNode;
    disabled?: boolean;
    "data-searchvalue"?: string;
}

interface IAntTreeSelectNode {
    value?: string | number;
    title?: React.ReactNode;
    disabled?: boolean;
    isLeaf?: boolean;
    disabledCheckbox?: boolean;
    searchValue?: string;
    children?: IAntTreeSelectNode[];
}

export interface IBTSelectProps<FormValues, ExtraDataType> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;
    readOnly?: boolean;

    /** set to true to make this a multi-select, defaults to false */
    multiple?: boolean;

    className?: string;

    /**
     * Note: When using this prop must specify the addon width and the BTSelect width
     * @example <BTSelect style={{ width: "calc(100% - 100px)" }} addonBefore={ <BTButton style={{ width: 100 }}>Click Me!</BTButton }
     */
    addonBefore?: React.ReactElement<any> | React.ReactElement<any>[];
    /**
     * Note: When using this prop must specify the addon width and the BTSelect width
     * @example <BTSelect style={{ width: "calc(100% - 100px)" }} addonAfter={ <BTButton style={{ width: 100 }}>Click Me!</BTButton }
     */
    addonAfter?: React.ReactNode | React.ReactNode[];

    /** Pass setFieldValue or a custom function (if you want to use extra data) -- DO NOT PASS handleChange.
     * When using extra data, it is assumed that the render function is pure and unchanging. If extra data is updated, it may not render again.
     */
    onChange: (field: string, value: any, selectedItem?: BTSelectItem<ExtraDataType>) => void;

    /** Pass setFieldTouched -- DO NOT PASS handleBlur. Additionally, please note that this method will not be triggered when the BTSelect is focused & unfocused by removing nodes via the 'X' icon. */
    onBlur: (field: string, isTouched: boolean) => void;

    treeData?: BTSelectItem<ExtraDataType>[];

    /**
     * Set this if default item has a different value than the default
     * Only needed if single select and in readonly mode
     * @default "-1"
     */
    ungroupedIds?: string[];

    /**
     * Set this if you want to display the default BTSelectItem title if selected in readonly mode
     * @default false
     */
    displayDefaultItemReadonly?: boolean;

    /**
     * Custom placeholder text appears when treeData is empty []
     * @default "There are no items to select"
     */
    noTreeDataPlaceholder?: React.ReactNode;

    /**
     * Custom placeholder text appears when user does not have view permissions
     *
     * This is for BTSelectEditable only
     */
    notVisiblePlaceholder?: React.ReactNode;

    /**
     * Enables disabling values via formik state, rather than off of initial load of tree data
     */
    disabledIds?: (number | string)[];

    /**
     * When using customItemRender, it is assumed that the function passed is pure. Additional renders will not occur unless the select items are changed.
     * Do NOT reference props or state directly in this render function.
     * Deps should be passed via extraData on the select items themselves.
     * @default (item) => item.title
     */
    customItemRender?: (item: BTSelectItem<ExtraDataType>) => React.ReactNode;

    /** Ref for the select */
    fieldRef?: React.RefObject<any>;

    /**
     * max-height of the select
     * xsmall = 1 standard row (32px)
     * small = 2 standard row (64px)
     * middle = 3 standard rows (96px)
     * large = 4 standard rows (128px)
     *
     * Should generally not be used
     * @default middle
     */
    maxHeight?: BTSelectMaxHeight;

    /** Determines whether to hide the Select All item */
    hideSelectAll?: boolean;

    /** Changes multiselect to have single tag displaying "All Selected" when all items are checked
     * @default false
     */
    filterMode?: boolean;

    /**
     * Only works when "multiple" is false.
     * When an item is selected, display a different label by specifying
     * the name of the property that contains the label that you want to display.
     */
    optionLabelProp?: KeyOfOrString<BTSelectItem<ExtraDataType>>;

    /**
     * Function that returns the BTSelectItem value to filter on.
     * @default (item) => item.title
     * @example (item) => item.extraData.myCustomFilterValue
     */
    filterOn?: (item: BTSelectItem<ExtraDataType>) => string;

    /**
     * This controls whether the filtering happens internally in this component or externally.  Set
     * it false when treeData state will be filtered externally by the consuming component.
     * @default true
     */
    shouldFilterOptions?: boolean;

    /**
     * This controls whether the select dropdown items will shrink and expand when longer items
     * scroll into view. Set it to false to make the dropdown width match the select input width.
     * @default true
     */
    dropdownCanExpand?: boolean;

    /**
     * This controls whether the select dropdown will open by default on component mount.
     * @default false
     */
    defaultOpen?: boolean;

    treeSelect?: boolean;

    /**
     * When searching will only match on child nodes
     * @default true
     */
    onlySearchChildNodes?: boolean;

    keepEnabledWhenEmpty?: boolean;

    /**
     * By default, virtual scrolling is enabled, which doesn't support dynamic dropdown width.
     * Setting this prop will opt-out of virtual scrolling to enable dynamic width dropdowns.
     * @default false
     */
    disableVirtualScroll?: boolean;
}

type TreeSelectPropsSubset = Omit<
    TreeSelectProps<any>,
    | "id"
    | "treeData"
    | "onChange"
    | "onBlur"
    | "multiple"
    | "placeholder"
    | "treeCheckable"
    | "open"
    | "onDropdownVisibleChange"
>;

export type BTSelectProps<FormValues, ExtraDataType> = IBTSelectProps<FormValues, ExtraDataType> &
    TreeSelectPropsSubset;

export const btSelectDefaultValue = -1;

type InternalTreeSelectProps = TreeSelectProps<(string | number)[]>;

/** DO NOT USE - These props are only meant for internal wrappers of this component */
interface IBTSelectInternalProps<FormValues, ExtraDataType>
    extends BTSelectProps<FormValues, ExtraDataType> {
    treeCheckable: TreeSelectProps<any>["treeCheckable"];
}

interface IBTSelectInternalState {
    open: boolean;
    searchValue: string;
    controlHeld: boolean;
}

function defaultCustomItemRender(item: BTSelectItem<any>) {
    return item.title;
}

@track((props) => ({
    element: `${props.multiple ? "Multi" : "Single"} Select`,
    uniqueId: props["data-testid"],
}))
export class BTSelectInternal<
    FormValues = undefined,
    ExtraDataType = undefined
> extends PureComponent<
    IBTSelectInternalProps<FormValues, ExtraDataType> & ITrackingProp,
    IBTSelectInternalState
> {
    static defaultProps = {
        multiple: false,
        ungroupedIds: [`${btSelectDefaultValue}`],
        displayDefaultItemReadonly: false,
        noTreeDataPlaceholder: "There are no items to select",
        customItemRender: defaultCustomItemRender,
        treeCheckable: true,
        filterMode: false,
        getPopupContainer: getClosestModal,
        filterOn: (item: BTSelectItem<any>) => item.title,
        shouldFilterOptions: true,
        dropdownCanExpand: true,
        maxHeight: "middle",
        onlySearchChildNodes: true,
        disableVirtualScroll: false,
    };

    state: Readonly<IBTSelectInternalState> = {
        open: false,
        searchValue: "",
        controlHeld: false,
    };

    remountSelect = 0;
    canRemountSelect = false;
    inputGroup = createRef<HTMLDivElement>();
    btSelectCustomItemContextInputValue: IBTSelectCustomItemContext = {
        isSelectedValueRender: true,
    };
    btSelectCustomItemContextDropdownValue: IBTSelectCustomItemContext = {
        isSelectedValueRender: false,
    };

    private controlDownHandler = (e: KeyboardEvent) => {
        if (e.key === "Control") {
            this.setState({ controlHeld: true });
        }
    };

    private controlUpHandler = (e: KeyboardEvent) => {
        if (e.key === "Control") {
            this.setState({ controlHeld: false });
        }
    };

    componentDidMount() {
        if (this.props.defaultOpen) {
            this.handleDropdownVisibleChange(true);
        }
        window.addEventListener("keydown", this.controlDownHandler);
        window.addEventListener("keyup", this.controlUpHandler);
    }

    componentDidUpdate(prevProps: BTSelectProps<FormValues, ExtraDataType>) {
        if (
            this.props.multiple &&
            this.canRemountSelect &&
            prevProps.treeData &&
            this.props.treeData &&
            prevProps.treeData !== this.props.treeData
        ) {
            // If the treeData reference changes, we want to reset the virtual scroll position back to the beginning by remounting the component
            this.remountSelect++;
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.controlDownHandler);
        window.removeEventListener("keyup", this.controlUpHandler);
        this.handleDropdownVisibleChange(false);
    }

    /** Do not use directly */
    private _btSelectItems: {
        btSelectItems: BTSelectItem<ExtraDataType>[];
        rawPropValues: Pick<
            IBTSelectInternalProps<FormValues, ExtraDataType>,
            "treeData" | "multiple" | "disabledIds" | "treeCheckable" | "value" | "hideSelectAll"
        >;
    };
    private get btSelectItems() {
        const { treeData, multiple, disabledIds, treeCheckable, value, hideSelectAll, filterMode } =
            this.props;
        if (
            !this._btSelectItems ||
            this._btSelectItems.rawPropValues.treeData !== treeData ||
            this._btSelectItems.rawPropValues.multiple !== multiple ||
            this._btSelectItems.rawPropValues.disabledIds !== disabledIds ||
            this._btSelectItems.rawPropValues.treeCheckable !== treeCheckable ||
            this._btSelectItems.rawPropValues.value !== value ||
            this._btSelectItems.rawPropValues.hideSelectAll !== hideSelectAll
        ) {
            const actualDisabledIds = disabledIds ?? getDisabledIds(treeData);
            let btSelectItems = this.mapInternalSelectItemsFromTreeData(
                treeData ?? [],
                actualDisabledIds,
                value
            );
            if (multiple) {
                let allOptionText = filterMode ? "Select All" : "Check All";
                if (!treeCheckable) {
                    allOptionText =
                        Array.isArray(value) && value.length > 0 ? "Clear All" : "Select All";
                }
                if (!hideSelectAll) {
                    // Add check all parent
                    btSelectItems = [
                        new BTSelectItem({
                            id: BTSelectCheckAllItems,
                            title: allOptionText,
                            name: allOptionText,
                            value: BTSelectCheckAllItems,
                            children: btSelectItems,
                            className: "BTSelectCheckAll",
                        }),
                    ];
                }
            }

            this._btSelectItems = {
                btSelectItems,
                rawPropValues: { treeData, multiple, disabledIds, treeCheckable, value },
            };
        }
        return this._btSelectItems.btSelectItems;
    }
    _renderedSelectItems: {
        renderedSelectItems: React.ReactNode[];
        deps: { multiple: boolean | undefined; btSelectItems: BTSelectItem<ExtraDataType>[] };
    };
    private get renderedSelectItems() {
        const { multiple } = this.props;
        const btSelectItems = this.btSelectItems;
        if (
            !this._renderedSelectItems ||
            this._renderedSelectItems.deps.multiple !== multiple ||
            this._renderedSelectItems.deps.btSelectItems !== btSelectItems
        ) {
            const renderedSelectItems = multiple
                ? this.btSelectItems.map((item) => this.renderTreeNode(item))
                : this.btSelectItems.map(this.renderGroup);
            this._renderedSelectItems = {
                renderedSelectItems,
                deps: { multiple, btSelectItems },
            };
        }
        return this._renderedSelectItems.renderedSelectItems;
    }

    private handleSearch = (value: string) => {
        const { onSearch } = this.props;
        this.setState({ searchValue: value });
        onSearch?.(value);
    };

    private handleSelectFilterMode(value: (string | number)[], wasNewValueAdded: boolean): any[] {
        const checkAll = this.btSelectItems.find((x) => x.id === BTSelectCheckAllItems);
        let newValues = value;
        if (newValues.includes(BTSelectCheckAllItems)) {
            newValues.pop();
            if (checkAll?.children && wasNewValueAdded) {
                checkAll.children.forEach((child) => {
                    child.children
                        ? child.children.forEach((c) => {
                              newValues.push(c.id);
                          })
                        : newValues.push(child.id);
                });
            }
        } else if (newValues.some((item) => item.toString().includes(BTSelectGroupHeaderId))) {
            const parentIds = newValues.filter((item) =>
                item.toString().includes(BTSelectGroupHeaderId)
            );
            parentIds.forEach((parentId) => {
                const parent = checkAll?.children?.find((child) => child.id === parentId);
                if (parent?.children) {
                    // Remove the header item from the list
                    newValues = newValues.filter((item) => !item.toString().includes(parent.id));
                    // Add children for the found parent
                    parent.children.forEach((child) => {
                        newValues.push(child.id);
                    });
                }
            });
        }
        return newValues;
    }

    private handleSingleSelectChange = (value: any) => {
        const selectedItem = getByValue(this.props.treeData, value);
        this.props.onChange(this.props.id as string, value, selectedItem);
        this.props.tracking?.trackEvent({ event: "ValueChange", value, extraInfo: value });
    };

    private findMatchingItem(
        selectItems: BTSelectItem<ExtraDataType>[],
        selectedValue: string | number
    ): BTSelectItem<ExtraDataType> | undefined {
        for (const item of selectItems) {
            if (item.value === selectedValue) {
                return item;
            } else if (item.children) {
                const match = this.findMatchingItem(item.children, selectedValue);
                if (match) {
                    return match;
                }
            }
        }

        return undefined;
    }

    private handleMultipleSelectChange: InternalTreeSelectProps["onChange"] = (
        value,
        _label,
        { triggerValue, checked, preValue, selected }
    ) => {
        const { searchValue, controlHeld } = this.state;

        if (!controlHeld) {
            this.setState({ searchValue: "" });
        }

        let newValue = value;

        const isFiltered = this.props.shouldFilterOptions && !!searchValue;
        // handles both cases of `treeCheckable`
        const wasNewValueAdded = checked ?? selected ?? false;
        if (isFiltered && wasNewValueAdded && Array.isArray(newValue)) {
            const triggerItem = this.findMatchingItem(this.btSelectItems, triggerValue);

            const completingGroup = newValue.some(
                (item) =>
                    item.toString().includes(BTSelectGroupHeaderId) &&
                    this.findMatchingItem(this.btSelectItems, item)
                        ?.children?.map((child) => child.value)
                        .indexOf(triggerValue) !== -1
            );

            if (triggerItem && !completingGroup) {
                const previouslySelected = newValue.filter((v) =>
                    preValue.some((pv) => pv.value === v)
                );

                newValue = [
                    ...previouslySelected,
                    ...this.filterToOnlyDisplayedChildren(triggerItem, searchValue),
                ];
            }
        }

        if (this.props.filterMode) {
            newValue = this.handleSelectFilterMode(newValue, wasNewValueAdded);
        }

        const selectedItem = getByValue(this.props.treeData, triggerValue);
        this.props.onChange(this.props.id as string, newValue, selectedItem);

        this.props.tracking?.trackEvent({
            event: "ValueChange",
            value: selectedItem?.value,
            extraInfo:
                newValue.length === 0
                    ? undefined
                    : newValue.length === 1
                    ? newValue[0]
                    : `${newValue[0]} (+${newValue.length - 1} more)`,
        });
    };

    private filterToOnlyDisplayedChildren(
        triggerItem: BTSelectItem<ExtraDataType>,
        searchValue: string
    ): (string | number)[] {
        const { children } = triggerItem;
        const isLeaf = !triggerItem.children || triggerItem.children.length === 0;

        if (!isLeaf) {
            return (children ?? []).flatMap((c) =>
                this.filterToOnlyDisplayedChildren(c, searchValue)
            );
        }
        if (
            !triggerItem.disabled &&
            // if we're including parent nodes in the search results then we will also want to
            // be able to select a parent node and all its children. Use case: bidding subs by divisions
            (!this.props.onlySearchChildNodes ||
                this.props.filterOn!(triggerItem).toLowerCase().includes(searchValue.toLowerCase()))
        ) {
            return [triggerItem.value];
        }
        return [];
    }

    // NOTE: when ant adds an 'onClear' prop, we should look to add support for it and potentially replace our usage of onDropdownVisibleChange with it. https://github.com/ant-design/ant-design/issues/21498
    private handleDropdownVisibleChange = (open: boolean) => {
        this.canRemountSelect = false;

        // if the dropdown was open and is now closed, fire the close event listener
        if (open === false && this.state.open) {
            this.props.onBlur(this.props.id as string, true);
        }

        this.setState(
            {
                open,
            },
            () => {
                if (!open) {
                    this.canRemountSelect = true;
                }
            }
        );

        // bind scroll event
        if (this.inputGroup.current) {
            if (open) {
                addCloseOnScroll(this.inputGroup.current, this.handleScroll);
            } else {
                removeCloseOnScroll(this.inputGroup.current, this.handleScroll);
            }
        }
    };

    private handleScroll = () => {
        // close on scroll
        this.handleDropdownVisibleChange(false);
    };

    private getReadonlyDisplayItems = (
        selectItems: BTSelectItem<ExtraDataType>[] | undefined
    ): BTSelectItem<ExtraDataType>[] => {
        const { value, ungroupedIds, displayDefaultItemReadonly } = this.props;
        const arr: BTSelectItem<ExtraDataType>[] = [];

        if (!selectItems) {
            return arr;
        }

        selectItems.forEach((selectItem) => {
            if (Array.isArray(value)) {
                if (value.includes(selectItem.value)) {
                    arr.push(selectItem);
                }
            } else if (
                value === selectItem.value &&
                (!ungroupedIds?.includes(selectItem.id) || displayDefaultItemReadonly)
            ) {
                arr.push(selectItem);
            }

            // go through all select item array children, and grab selected values
            if (selectItem.children) {
                arr.push(...this.getReadonlyDisplayItems(selectItem.children));
            }
        });
        return arr;
    };

    private renderOption = (option: BTSelectItem<ExtraDataType>) => {
        return (
            <Option
                key={option.key}
                value={option.value}
                disabled={option.disabled}
                className={option.className}
                title={option.title}
                label={this.props.customItemRender!(option)}
                data-searchvalue={this.props.filterOn!(option)}
            >
                {this.props.customItemRender!(option)}
            </Option>
        );
    };

    private renderGroup = (treeDataMember: BTSelectItem<ExtraDataType>): React.ReactNode => {
        const children = treeDataMember.children;

        if (isGroupParent(children)) {
            // Casting to string in case it comes in as a number from server
            const isDefaultOption =
                children.length === 1 &&
                this.props.ungroupedIds?.includes(children[0]?.id.toString());
            if (isDefaultOption) {
                return this.renderOption(children[0]);
            }

            return (
                <OptGroup label={treeDataMember.title} key={treeDataMember.key}>
                    {children.map((selectOption: any) => this.renderOption(selectOption))}
                </OptGroup>
            );
        }

        return this.renderOption(treeDataMember);

        function isGroupParent(
            c: BTSelectItem<ExtraDataType>[] | undefined
        ): c is BTSelectItem<ExtraDataType>[] {
            return c !== undefined;
        }
    };

    private renderTreeNode = (option: BTSelectItem<ExtraDataType>) => {
        return (
            <TreeNode
                key={option.key}
                disableCheckbox={option.disabled}
                disabled={option.disabled}
                value={option.value}
                title={this.props.customItemRender!(option)}
                searchValue={this.props.filterOn!(option)}
                isLeaf={!option.children || option.children.length === 0}
                icon={option.icon}
            >
                {option.children && option.children.map(this.renderTreeNode)}
            </TreeNode>
        );
    };

    private getStyle(): React.CSSProperties | undefined {
        const { style } = this.props;
        // Width of the dropdown is always 100%
        if (style && style.width === undefined) {
            style.width = "100%";
        }
        return style || { width: "100%" };
    }

    private getInternalValue({ value, multiple }: BTSelectProps<FormValues, ExtraDataType>) {
        const newValue = Array.isArray(value) ? [...value] : value;

        if (multiple) {
            this.btSelectItems[0]?.children?.forEach((d) => {
                // Add parent if all children are selected & disabled
                if (Array.isArray(newValue) && d.children && d.disabled && d.selected) {
                    newValue.push(d.value);
                }
            });
        }

        return newValue;
    }

    private mapInternalSelectItemsFromTreeData(
        treeData: BTSelectItem<ExtraDataType>[],
        disabledIds: (string | number)[] | undefined,
        value: any
    ) {
        function mapInternalSelectItems(
            item: BTSelectItem<ExtraDataType>,
            disabledIds: (string | number)[] | undefined,
            mappedSelectedItems: { [key: string]: boolean }
        ) {
            const result: BTSelectItem<ExtraDataType> = {
                ...item,
                disabled: disabledIds?.includes(item.id) ?? false,
                selected: mappedSelectedItems[item.value] === true,
            };

            const isParentItem = result.children && result.children.length > 0;
            if (isParentItem) {
                const newChildren: typeof result.children = [];
                let allChildren = { areDisabled: true, areSelected: true };
                result.children?.forEach((c) => {
                    const mappedChild = mapInternalSelectItems(c, disabledIds, mappedSelectedItems);
                    allChildren.areDisabled = allChildren.areDisabled && mappedChild.disabled;
                    allChildren.areSelected = allChildren.areSelected && mappedChild.selected;
                    newChildren.push(mappedChild);
                });

                result.children = newChildren;
                // disable when all children are disabled
                result.disabled = allChildren.areDisabled;
                // select when all children are selected
                result.selected = allChildren.areSelected;
            }

            return result;
        }

        const mappedSelectedItems = {};
        if (Array.isArray(value)) {
            value.forEach((x) => (mappedSelectedItems[x.value] = true));
        }
        return treeData.map((d) => mapInternalSelectItems(d, disabledIds, mappedSelectedItems));
    }

    private handleFilterOptionSingleSelect = (
        value: string,
        { disabled, "data-searchvalue": searchValue }: IAntSelectNode
    ): boolean => this.isNodeMatch({ disabled, searchValue }, value);

    private handleFilterTreeNode = (
        search: string,
        node: IAntTreeSelectNode | undefined
    ): boolean => {
        if (!node) {
            return false;
        }
        if (node.children && node.children.length > 0) {
            const childMatch = node.children.some((childNode) => {
                // check if child has children and recursively check for match
                if (childNode.children && childNode.children.length > 0) {
                    return this.handleFilterTreeNode(search, childNode);
                }
                return this.isNodeMatch(childNode, search);
            });

            // filter out if has children and none match
            if (!childMatch) {
                if (this.props.onlySearchChildNodes) {
                    return false;
                } else {
                    return this.isNodeMatch(node, search);
                }
            }
        } else {
            return this.isNodeMatch(node, search);
        }

        return false;
    };

    private isNodeMatch(
        node: Pick<IAntTreeSelectNode, "disabled" | "searchValue" | "title">,
        search: string
    ) {
        let searchValue = node.searchValue ?? node.title?.toString();

        return (
            (!node.disabled && searchValue?.toLowerCase().includes(search.toLowerCase())) ?? false
        );
    }

    private handleDropdownRender = (menu: ReactElement) => {
        const { dropdownRender, "data-testid": testid } = this.props;
        return (
            <BTSelectCustomItemContext.Provider value={this.btSelectCustomItemContextDropdownValue}>
                <div data-testid={`${testid}-popup`}>
                    {dropdownRender ? dropdownRender(menu) : menu}
                </div>
            </BTSelectCustomItemContext.Provider>
        );
    };

    render() {
        const {
            treeData,
            id,
            onChange,
            onBlur,
            readOnly,
            "data-testid": testid,
            multiple,
            treeSelect,
            value,
            noTreeDataPlaceholder,
            notVisiblePlaceholder,
            disabledIds,
            fieldRef,
            autoFocus,
            treeCheckable,
            keepEnabledWhenEmpty,
            className,
            dropdownClassName,
            maxHeight,
            hideSelectAll,
            addonAfter,
            ungroupedIds,
            displayDefaultItemReadonly,
            customItemRender,
            tagRender,
            filterMode,
            onSearch,
            showSearch,
            allowClear,
            showArrow,
            notFoundContent,
            optionLabelProp,
            getPopupContainer,
            filterOn,
            shouldFilterOptions,
            dropdownCanExpand,
            style: _style,
            onlySearchChildNodes,
            disableVirtualScroll,
            ...restProps
        } = this.props;

        // height of 8.5 tree nodes
        const treePopupHeight = 8.5 * STANDARD_OPTION_HEIGHT;
        const checkable = treeCheckable && !treeSelect;

        /**
         * TODO Remove selectAlignOverride, treeSelectAlignOverride, and dropdownAlignOverride
         * this was removed after ant v4 upgrade but wasn't fixed as anticipated
         * a minor version upgrade should make this unnecessary in the future
         * In the mean time, we need to override and wrap these so that we can use the spread
         * operator to bypass typescript's prop checking
         * @see https://github.com/react-component/select/pull/461
         * @see https://github.com/react-component/select/pull/710
         */
        const dropdownAlignOverride = {
            overflow: {
                adjustX: 1,
                adjustY: 1,
            },
        };
        const selectAlignOverride = {
            dropdownAlign: dropdownAlignOverride,
        };
        const treeSelectAlignOverride = {
            dropdownPopupAlign: dropdownAlignOverride,
        };

        const combinedClassName = classNames(
            {
                "BTSelect-single": !multiple,
                "BTSelect-multi": multiple,
                treeCheckable: multiple && checkable,
                treeNotCheckable: multiple && !checkable,
                maxHeightXSmall: maxHeight === "xsmall",
                maxHeightSmall: maxHeight === "small",
                maxHeightMiddle: maxHeight === "middle",
                maxHeightLarge: maxHeight === "large",
            },
            className,
            "BTSelect"
        );
        const combinedDropdownClassName = classNames(
            {
                "BTSelect-dropdown-single": !multiple,
                "BTSelect-dropdown-multi": multiple,
                dropdownCanExpand: dropdownCanExpand,
                treeCheckable: multiple && checkable,
                treeNotCheckable: multiple && !checkable,
                hideSelectAll: multiple && hideSelectAll,
            },
            dropdownClassName
        );

        const internalValue = this.getInternalValue(this.props);

        const isSelectEmpty = this.props.treeData?.length === 0;
        const allItemsIds =
            multiple || treeSelect
                ? getAllIds(this.btSelectItems).map((id) => id.toString())
                : undefined;

        if (readOnly) {
            const selectedBTSelectItems = this.getReadonlyDisplayItems(this.btSelectItems);
            if (multiple) {
                return (
                    <BTSelectCustomItemContext.Provider
                        value={this.btSelectCustomItemContextInputValue}
                    >
                        <ReadMore numberOfLines={2} lineHeight={2}>
                            <ValueDisplay
                                className="BTSelect"
                                data-testid={testid}
                                value={
                                    selectedBTSelectItems.length > 0 ? (
                                        <>
                                            {selectedBTSelectItems.map((d) => (
                                                <Fragment key={d.value}>
                                                    {customItemRender ===
                                                    defaultCustomItemRender ? (
                                                        <Tag>{customItemRender(d)}</Tag>
                                                    ) : (
                                                        customItemRender!(d)
                                                    )}
                                                </Fragment>
                                            ))}
                                        </>
                                    ) : undefined
                                }
                            />
                        </ReadMore>
                    </BTSelectCustomItemContext.Provider>
                );
            }
            return (
                <BTSelectCustomItemContext.Provider
                    value={this.btSelectCustomItemContextInputValue}
                >
                    <ValueDisplay
                        data-testid={testid}
                        value={
                            selectedBTSelectItems.length > 0 ? (
                                <Space>
                                    {selectedBTSelectItems.map((d) => (
                                        <Fragment key={d.value}>
                                            {customItemRender === defaultCustomItemRender ? (
                                                <Tag>{customItemRender(d)}</Tag>
                                            ) : (
                                                customItemRender!(d)
                                            )}
                                        </Fragment>
                                    ))}
                                </Space>
                            ) : undefined
                        }
                    />
                </BTSelectCustomItemContext.Provider>
            );
        }

        // this is really ugly, but if we pass value={undefined} to ant TreeSelect, it won't use the default
        // because it sees that the key "value" exists on props object, so we need this workaround instead
        const valueObjectForMultiSelect =
            internalValue === undefined ? {} : { value: internalValue };

        let placeholder = isSelectEmpty ? noTreeDataPlaceholder : undefined;
        if (notVisiblePlaceholder) {
            placeholder = notVisiblePlaceholder;
        }

        const isDisabled = restProps.disabled || (isSelectEmpty && !keepEnabledWhenEmpty);
        const showCheckedStrategy = filterMode ? TreeSelect.SHOW_PARENT : TreeSelect.SHOW_CHILD;
        const style = this.getStyle();

        return (
            <BTSelectCustomItemContext.Provider value={this.btSelectCustomItemContextInputValue}>
                <div ref={this.inputGroup}>
                    <Input.Group compact className={combinedClassName}>
                        {this.props.addonBefore ?? ""}
                        {!multiple && !treeSelect && (
                            // eslint-disable-next-line react/forbid-elements
                            <Select
                                id={id as string}
                                showSearch={showSearch === undefined || showSearch} // Default to true even if undefined. Only make false if actually false
                                style={style}
                                optionFilterProp="title"
                                suffixIcon={
                                    <BTIconCaretSmallDown style={{ pointerEvents: "none" }} />
                                }
                                filterOption={
                                    shouldFilterOptions
                                        ? (this.handleFilterOptionSingleSelect as any)
                                        : false
                                }
                                dropdownClassName={combinedDropdownClassName}
                                disabled={isDisabled}
                                onChange={this.handleSingleSelectChange}
                                onDropdownVisibleChange={this.handleDropdownVisibleChange}
                                open={this.state.open}
                                value={internalValue}
                                placeholder={placeholder}
                                getPopupContainer={getPopupContainer}
                                data-testid={testid}
                                size={restProps.size}
                                ref={fieldRef}
                                onSearch={
                                    showSearch === undefined || showSearch
                                        ? this.handleSearch
                                        : undefined
                                }
                                allowClear={allowClear}
                                showArrow={showArrow}
                                notFoundContent={notFoundContent}
                                optionLabelProp={optionLabelProp}
                                dropdownRender={this.handleDropdownRender}
                                tagRender={tagRender}
                                autoFocus={autoFocus}
                                virtual={!disableVirtualScroll}
                                {...selectAlignOverride}
                            >
                                {this.renderedSelectItems}
                            </Select>
                        )}

                        {(multiple || treeSelect) && (
                            <TreeSelect<any>
                                style={style}
                                key={`${testid}${this.remountSelect}`}
                                dropdownClassName={combinedDropdownClassName}
                                id={id as string}
                                multiple={multiple}
                                {...valueObjectForMultiSelect}
                                {...restProps}
                                treeData={!treeSelect ? undefined : treeData}
                                treeDefaultExpandAll={true}
                                treeExpandedKeys={allItemsIds}
                                showCheckedStrategy={showCheckedStrategy}
                                treeCheckable={checkable}
                                disabled={isDisabled}
                                placeholder={placeholder}
                                showSearch
                                suffixIcon={
                                    <BTIconCaretSmallDown style={{ pointerEvents: "none" }} />
                                }
                                onChange={this.handleMultipleSelectChange}
                                onDropdownVisibleChange={this.handleDropdownVisibleChange}
                                open={this.state.open}
                                autoClearSearchValue={false}
                                treeNodeFilterProp="searchValue"
                                treeIcon={treeSelect}
                                listHeight={treePopupHeight}
                                getPopupContainer={getPopupContainer}
                                data-testid={testid}
                                ref={fieldRef}
                                searchValue={this.state.searchValue}
                                onSearch={this.handleSearch}
                                filterTreeNode={
                                    shouldFilterOptions ? this.handleFilterTreeNode : false
                                }
                                allowClear={allowClear}
                                showArrow={showArrow}
                                notFoundContent={notFoundContent}
                                dropdownRender={this.handleDropdownRender}
                                tagRender={tagRender}
                                autoFocus={autoFocus}
                                {...treeSelectAlignOverride}
                            >
                                {this.renderedSelectItems}
                            </TreeSelect>
                        )}

                        {addonAfter ? addonAfter : ""}
                    </Input.Group>
                </div>
            </BTSelectCustomItemContext.Provider>
        );
    }
}

export const BTSelect: new <FormValues = undefined, ExtraDataType = undefined>(
    props: BTSelectProps<FormValues, ExtraDataType>
) => PureComponent<BTSelectProps<FormValues, ExtraDataType>> = BTSelectInternal as any;
