import { useCallback, useRef, useState } from "react";

import { BTSelectItem, IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { BuilderFilterTypes, ClosedAccountTypes } from "types/enum";

import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTSelectLazy } from "commonComponents/btWrappers/BTSelectLazy/BTSelectLazy";
import {
    BuilderSearchHandler,
    BuilderSearchResponseType,
    IBuilderSearchHandler,
} from "commonComponents/entity/builder/BuilderSearch/BuilderSearch.api.handler";

interface IBuilderSearchProps<TFormValues> {
    handler?: IBuilderSearchHandler;
    ["data-testid"]: string;
    id: KeyOfOrString<TFormValues>;
    /** Pass setFieldValue or a custom function (if you want to use extra data) -- DO NOT PASS handleChange */
    onChange: (field: string, value: any, selectedItem?: BTSelectItem<any>) => void;

    /** Pass setFieldTouched -- DO NOT PASS handleBlur. Additionally, please note that this method will not be triggered when the BTSelect is focused & unfocused by removing nodes via the 'X' icon. */
    onBlur: (field: string, isTouched: boolean) => void;

    /**
     * Currently selected item.
     */
    value?: BTSelectItem<any>;

    /**
     * This is the account status that we pass up to the service to filter on closed or open builders
     */
    accountStatus?: ClosedAccountTypes;

    /**
     * Builder type filter we use when choosing which builders to load
     */
    accountType?: BuilderFilterTypes;
    placeholder?: string;
    readOnly?: boolean;
}

const defaultHandler = new BuilderSearchHandler();

export function BuilderSearch<TFormValues>({
    handler = defaultHandler,
    "data-testid": testId,
    id,
    onChange,
    onBlur,
    value,
    accountStatus,
    accountType,
    readOnly,
    ...props
}: IBuilderSearchProps<TFormValues>) {
    const searchRequest = useRef<IAPIHandlerResult<BuilderSearchResponseType> | undefined>(
        undefined
    );

    const [searchData, setSearchData] = useState<BTSelectItem<any>[] | undefined>(undefined);

    const searchCallback = useCallback(
        async (searchValue: string) => {
            searchRequest.current?.cancel();
            if (searchValue !== "") {
                searchRequest.current = handler.searchForBuilders(
                    searchValue,
                    accountStatus,
                    accountType
                );
                const result = await searchRequest.current.response;
                searchRequest.current = undefined;
                const options = result?.options?.[0].options.length
                    ? result.options[0].options.map(
                          (x) =>
                              new BTSelectItem({
                                  id: x.id,
                                  name: `${x.title} ID: ${x.id} - ${x.extraData?.daysSinceLastLogin}`,
                                  extraData: x.extraData,
                              })
                      )
                    : undefined;
                setSearchData(options);
            } else {
                setSearchData(undefined);
            }
        },
        [accountStatus, accountType, handler]
    );

    return (
        <BTSelectLazy<TFormValues, any>
            data-testid={testId}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            onSearch={searchCallback}
            searchData={searchData}
            placeholder={props.placeholder}
            hideSelectAll={true}
            readOnly={readOnly}
        />
    );
}
