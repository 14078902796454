import classNames from "classnames";
import { Component } from "react";
import { ItemInterface, ReactSortable, ReactSortableProps, Sortable } from "react-sortablejs";

import { isNullOrUndefined } from "utilities/object/object";

import { BTIconDragIcon } from "commonComponents/btWrappers/BTIcon";
import {
    ITargetOptions,
    Target,
} from "commonComponents/utilities/DragAndDrop/SortableJsPlugins/Target/Target";

import "./DragAndDrop.less";

interface IDragAndDropProps<T extends ItemInterface>
    extends Omit<ReactSortableProps<T>, "handle" | "dragClass" | "ghostClass">,
        ITargetOptions {
    hideGhostClass?: boolean;
}

interface IDragAndDropHandleProps {
    className?: string;
}

export class DragAndDropHandle extends Component<IDragAndDropHandleProps> {
    render() {
        const { children, className } = this.props;
        const iconNode = isNullOrUndefined(children) ? <BTIconDragIcon /> : children;

        return <span className={classNames("DragAndDropHandle", className)}>{iconNode}</span>;
    }
}

export class DragAndDrop<T extends ItemInterface> extends Component<IDragAndDropProps<T>> {
    static defaultProps = {
        hideGhostClass: false,
    };

    render() {
        const { className, children, hideGhostClass, ...rest } = this.props;
        return (
            <ReactSortable
                {...rest}
                className={classNames("DragAndDrop", "Droppable", className)}
                dragClass="DraggedItem"
                handle=".DragAndDropHandle"
                ghostClass={hideGhostClass ? undefined : "Placeholder"}
            >
                {children}
            </ReactSortable>
        );
    }
}

// Register any SortableJS Plugins
export function registerDragAndDropPlugins() {
    Sortable.mount(new Target());
}
