import { message } from "antd";
import classNames from "classnames";
import { chunk } from "lodash-es";
import { Component } from "react";

import { track } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";

import { BTButton, BTButtonType } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTForm } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import {
    IImportStatusProps,
    ImportStatus,
} from "commonComponents/entity/importStatus/ImportStatus/ImportStatus";

import "./BulkAction.less";

interface IBulkActionProps<StateType> {
    /**
     * Pass in a list of BulkActionStep factories.
     */
    renderSteps: ((step: IBulkActionStep<StateType>) => React.ReactNode)[];
    startingStep?: number;
    fitToContent?: boolean;
    width?: string;
    onCancel: () => void;
    onComplete: () => Promise<void>;
    /**
     * Removes the modal's header. This will also make the modal NOT closeable and also NOT show the modal's title.
     * @default false
     */
    removeHeader?: boolean;
    closable?: boolean;
    className?: string;
}

export interface IBulkActionStep<StateType = {}> {
    loading: (
        params?: Pick<IImportStatusProps, "primaryLabel" | "secondaryLabel" | "onCancel">
    ) => void;
    complete: (
        params?: Pick<
            IImportStatusProps,
            "primaryLabel" | "secondaryLabel" | "onOk" | "customFooter"
        >
    ) => void;
    warning: (params: Pick<IImportStatusProps, "primaryLabel" | "secondaryLabel" | "onOk">) => void;
    failure: (params: Pick<IImportStatusProps, "primaryLabel" | "secondaryLabel" | "onOk">) => void;
    toastComplete: (msg: string) => void;
    error: (e: unknown) => void;
    nextStep: (params?: { nextStep?: number; newState?: StateType }) => void;
    multiStepAction: (params: IMultiStepActionInfo) => Promise<IMultiStepActionResults>;
    state: () => Readonly<StateType | undefined>;
    header: (...headers: (JSX.Element | string | undefined)[]) => IBulkActionStep<StateType>;
    content: (...content: React.ReactNode[]) => IBulkActionStep<StateType>;
    continueButton: (
        onContinueClick?: () => void | Promise<void>,
        label?: React.ReactNode,
        type?: BTButtonType
    ) => IBulkActionStep<StateType>;
    cancelButton: (onClick?: () => void, label?: React.ReactNode) => IBulkActionStep<StateType>;
    onModalClose: (onBeforeClose: () => void) => IBulkActionStep<StateType>;
    render: () => React.ReactElement;
}

type LabelRenderFunc = (
    completedItemCount: number,
    total: number,
    errorMessage?: string
) => React.ReactNode;

interface IMultiStepActionInfo {
    data: any[];
    /**
     * Pass a batchSize if you want to send a grouping of items with each action iteration.
     * i.e. call the api with 25 items. Otherwise it will default to one at a time.
     * @default "1"
     */
    batchSize?: number;
    processBatch: (batch: any[], onCancel: () => void) => Promise<number>;
    label: LabelRenderFunc;
    secondaryLabel?: LabelRenderFunc;
}

interface IMultiStepActionResults {
    cancelled: boolean;
    successCount: number;
    totalCount: number;
    errorMessage?: string;
    complete: (label: LabelRenderFunc, secondaryLabel?: LabelRenderFunc) => void;
}

interface IBulkActionState<StateType> {
    currentStep: number;
    status?: IImportStatusProps;
    data?: StateType;
}

@track({ component: "Bulk Action" })
export class BulkAction<StateType = {}> extends Component<
    IBulkActionProps<StateType>,
    IBulkActionState<StateType>
> {
    static defaultProps = {
        removeHeader: false,
    };

    state: Readonly<IBulkActionState<StateType>> = {
        currentStep: this.props.startingStep || 0,
    };

    private setNextStep = (params?: { nextStep?: number; newState?: StateType }) => {
        const nextStep =
            params && params.nextStep !== undefined ? params.nextStep : this.state.currentStep + 1;
        const data = (params && params.newState) || this.state.data;
        this.setState({ data, currentStep: nextStep, status: undefined });
    };

    private setStatus = (value?: IImportStatusProps) => {
        this.setState({ status: value });
        return true;
    };

    private renderContinue = (
        onClick: () => void = () => this.setNextStep(),
        label: React.ReactNode = "Continue",
        type: BTButtonType = "primary"
    ) => (
        <BTButton data-testid="bulkActionContinue" onClick={onClick} type={type}>
            {label}
        </BTButton>
    );

    private renderCancel = (
        onClick: () => void = () => this.props.onCancel(),
        label: React.ReactNode = "Cancel"
    ) => (
        <BTButton data-testid="bulkActionCancel" onClick={onClick}>
            {label}
        </BTButton>
    );

    private getStep = () => {
        let _headers: (JSX.Element | string)[] = [];
        let _content: React.ReactNode[] = [];
        let _continueButton: React.ReactNode = undefined;
        let _cancelButton: React.ReactNode = undefined;
        let _beforeClose = this.props.onCancel;

        const step: IBulkActionStep<StateType> = {
            loading: (params) =>
                this.setStatus({
                    status: "loading",
                    cancelled: false,
                    ...(params || { primaryLabel: "Loading..." }),
                }),
            error: (e) => {
                showAPIErrorMessage(e);
                this.setNextStep({ nextStep: this.state.currentStep });
            },
            warning: (params) =>
                this.setStatus({ status: "warning-warning", cancelled: false, ...params }),
            failure: (params) =>
                this.setStatus({ status: "close-circle-error", cancelled: false, ...params }),
            complete: (params) =>
                (params &&
                    this.setStatus({
                        status: "check-circle-success",
                        cancelled: false,
                        ...params,
                    })) ||
                this.props.onComplete(),
            toastComplete: (msg) => {
                void message.success(msg);
                void this.props.onComplete();
            },
            nextStep: this.setNextStep,
            multiStepAction: async ({ batchSize = 1, ...params }) => {
                let errorMessage: string | undefined,
                    totalCount = params.data.length,
                    processedCount = 0,
                    successCount = 0,
                    cancelled = false;
                const onCancel = () => {
                    cancelled = true;
                    if (this.state.status) {
                        this.setStatus({ ...this.state.status, cancelled: true });
                    }
                };
                const batches = chunk(params.data, batchSize);
                const batchCount = batches.length;

                for (let i = 0; !cancelled && i < batchCount; i++) {
                    const currentBatch = batches[i];
                    step.loading({
                        primaryLabel: params.label(
                            processedCount + currentBatch.length,
                            totalCount
                        ),
                        secondaryLabel:
                            params.secondaryLabel &&
                            params.secondaryLabel(processedCount + currentBatch.length, totalCount),
                        onCancel: i === batchCount - 1 ? undefined : onCancel,
                    });
                    try {
                        const numSuccessful = await params.processBatch(currentBatch, onCancel);
                        successCount += numSuccessful;
                        processedCount += currentBatch.length;
                    } catch (ex: any) {
                        if (ex.response && ex.response.data) {
                            errorMessage = ex.response.message;
                        } else if (ex.message) {
                            errorMessage = ex.message;
                        }
                    }
                }
                return {
                    cancelled,
                    errorMessage,
                    totalCount,
                    successCount,
                    complete: (label, secondaryLabel) => {
                        (cancelled ? step.warning : step.complete)({
                            primaryLabel: label(successCount, totalCount, errorMessage),
                            secondaryLabel:
                                secondaryLabel &&
                                secondaryLabel(successCount, totalCount, errorMessage),
                            onOk: this.props.onComplete,
                        });
                    },
                };
            },
            state: () => this.state.data,
            continueButton: (onClick, label, type) => {
                _continueButton = this.renderContinue(onClick, label, type);
                return step;
            },
            cancelButton: (onClick, label) => {
                _cancelButton = this.renderCancel(onClick, label);
                return step;
            },
            onModalClose: (onBeforeClose: () => void) => {
                _beforeClose = onBeforeClose;
                return step;
            },
            header: (...headers) => {
                _headers = headers.filter((h) => Boolean(h)).map((h) => h!);
                return step;
            },
            content: (...content) => {
                _content = content.filter((c) => Boolean(c));
                return step;
            },
            render: () => {
                return (
                    <BTModalLayout
                        className={classNames("BulkActionModal", this.props.className)}
                        modalConfig={{
                            beforeClose: _beforeClose,
                            parentRoute: "",
                        }}
                        closable={this.props.closable}
                        title={
                            <>
                                {_content.map((node, index) => (
                                    <div
                                        key={`bulkAction${index}`}
                                        className="BulkActionStepHeader"
                                    >
                                        <span>
                                            {_headers[index]
                                                ? _headers[index]
                                                : _headers[_headers.length - 1]}
                                        </span>
                                    </div>
                                ))}
                            </>
                        }
                        footerContent={
                            <>
                                {_continueButton || this.renderContinue()}
                                {_cancelButton || this.renderCancel()}
                            </>
                        }
                    >
                        <BTForm>
                            <BTLayoutContent>
                                {_content.map((node, index) => (
                                    <div key={`bulkAction${index}`}>{node}</div>
                                ))}
                            </BTLayoutContent>
                        </BTForm>
                    </BTModalLayout>
                );
            },
        };
        return step;
    };

    render() {
        const showCurrentStep =
            this.state.status === undefined &&
            this.props.renderSteps[this.state.currentStep] !== undefined;

        const fitToContentWidth = this.props.fitToContent ? "auto" : "450px";
        return (
            <BTModal
                className="BulkAction"
                visible
                width={this.props.width ?? fitToContentWidth}
                beforeClose={() => {}}
                centered
                removeHeader
                data-testid="bulkActionModal"
            >
                {this.state.status && <ImportStatus {...this.state.status} />}
                {showCurrentStep && this.props.renderSteps[this.state.currentStep](this.getStep())}
            </BTModal>
        );
    }
}

export class BulkActionResponse {
    constructor(data: any) {
        this.errorList = data.errorList;
        this.count = data.count;
        this.successCount = data.SuccessCount || data.successCount;
    }
    errorList: string[];
    count: number;
    successCount: number;
}
