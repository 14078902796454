import calculateSize, { Size } from "calculate-size";
import { fontFamily } from "styles/antTheme/Variables";

/**
 * Splits a string into an array of numbers (number can be float)
 * @param separatedString
 * @param separator defaults to "," - separator to split the string on
 * @example stringToArray("1,2,3,4,5,10.5") will return [1,2,3,4,5,10.5]
 */
export function stringToArray(separatedString?: string, separator: string = ","): number[] {
    if (separatedString === undefined) {
        return [];
    }
    return separatedString.split(separator).map(Number);
}

export interface IFormattedPluralProps<Type> {
    value: number;
    zero?: Type;
    one?: Type;
    other: Type;
}

/**
 * @see BTFormattedPlural for the component version
 * @example
 * formattedPlural({ value: array.length, one: "User", other: "Users"});
 * formattedPlural({ value: array.length, zero: "No users exist", one: "User", other: "Users" });
 */
export function formattedPlural<Type = string>(options: IFormattedPluralProps<Type>) {
    const { value, zero, one, other } = options;

    if (value === 0 && zero) {
        return zero;
    }

    if ((value === 1 || value === -1) && one) {
        return one;
    }

    return other;
}

export function formattedPossesiveProperName(s: string) {
    const formattedProperName = s.charAt(s.length - 1) === "s" ? `${s}'` : `${s}'s`;
    return formattedProperName;
}

/**
 * Attempt to convert a CSS pixel length into a number
 * Will only work if input looks like "100" or "100px"
 * Otherwise return undefined
 * @param length the CSS length to attempt to convert
 */
export function parsePixelLength(length: string): number | undefined {
    const regex = /^(\d+)(px)?$/;
    if (regex.test(length)) {
        return parseInt(length.replace(regex, "$1"));
    }

    // length doesn't match the expected format
    return undefined;
}

/**
 * Function to capitalize the first letter of a string
 * @param s any string
 */
export function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * DO NOT USE
 * Instead use css "white-space: pre-wrap;" note <ValueDisplay> will do this for you
 */
export function newLineToHtmlBr(s: string) {
    return (s + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2");
}

export function isNullOrWhitespace(s?: string | null) {
    return s === undefined || s === null || s.trim().length === 0;
}

/**
 * Checks if a string is empty
 */
export function isWhitespace(s: string) {
    return s.trim().length === 0;
}

/**
 * Truncates text to a maximum size
 * @param message The message to truncate
 * @param maxCharacters The maximum number of characters to show
 * @param truncateCharacter A single character to insert when text is truncated. Defaults to "…"
 * @example truncate("This is some long text", 10) => "This is s…"
 */
export function truncate(
    message: string,
    maxCharacters: number,
    truncateCharacter: string = "…"
): string {
    return message.length > maxCharacters
        ? message.substr(0, maxCharacters - 1) + truncateCharacter
        : message;
}

/**
 * !WARNING this call is slow
 * Calculates the width/height of text
 * This calculation will be cached, but the initial call is VERY slow. You probably shouldn't be using this
 *
 * @param text the text to calculate the width/height of
 * @param fontSize defaults to font size of 14px
 * @param fontWeight
 * @param font defaults to our antd font
 */
export function getTextSize(
    text: string,
    fontSize: string = "14px",
    fontWeight: "bold" | number | undefined = undefined,
    font: string = fontFamily
): Size {
    const size = calculateSize(text, {
        font: font,
        fontSize: fontSize,
        fontWeight: fontWeight as any,
    });

    return size;
}

export function fieldHasMultipleEmails(fieldText: string) {
    return (
        fieldText?.split(";").filter((i) => {
            return i.trim().length > 0;
        })?.length > 1
    );
}

/**
 * Please DO NOT use this unless you absolutely need to check if something is a valid email outside of a yup schema
 */
export function isValidEmailFormat(text: string) {
    // taken from yup 1.0.0 https://github.com/jquense/yup/blob/master/src/string.ts
    const validEmailExpression =
        // eslint-disable-next-line no-control-regex,no-useless-escape
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    return validEmailExpression.test(text);
}

/**
 * Gets initials from a string
 * @example "First middle last" --> "FM"
 * @example "First !! middle last" --> "FM"
 * @example "First (middle last)" --> "FM"
 */
export function getInitials(name: string | null | undefined) {
    if (name === null || name === undefined) {
        return "";
    }

    return name
        .replace(/[!@#$%^&*(){}[\]~`"':;<>,.?/]/g, "")
        .split(" ")
        .map((item) => item[0])
        .join("")
        .slice(0, 2) // limit to 2 initials
        .toUpperCase();
}

/**
 * Taken from is-alphanumeric v1.0.0
 */
export function isAlphaNumeric(str: string) {
    return !/[^0-9a-z\xDF-\xFF]/.test(str.toLowerCase());
}
