export class SignupContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    websiteTestExperience: string;

    constructor(data: any) {
        this.firstName = data.firstName == null ? undefined : data.firstName;
        this.lastName = data.lastName == null ? undefined : data.lastName;
        this.email = data.email == null ? undefined : data.email;
        this.phone = data.phone == null ? undefined : data.phone;
        this.websiteTestExperience =
            data.websiteTestExperience == null ? undefined : data.websiteTestExperience;
    }
}
