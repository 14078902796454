import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCloseOutlined } from "commonComponents/btWrappers/BTIcon";

import "./DismissButton.less";

export type DismissButtonFormActions = "dismiss" | undefined;

interface IDismissButtonProps {
    onDismiss?: () => void;
    actionBeingPerformed?: DismissButtonFormActions;
}

const DismissButton: React.FC<IDismissButtonProps> = ({ onDismiss, actionBeingPerformed }) => {
    return (
        <span className="DismissButton">
            <BTButton<DismissButtonFormActions>
                className="btn-dismiss ant-alert-close-icon"
                data-testid="dismiss-button"
                onClick={onDismiss}
                loadingAction="dismiss"
                actionBeingPerformed={actionBeingPerformed}
                icon={<BTIconCloseOutlined />}
                type="link"
            />
        </span>
    );
};

export default DismissButton;
