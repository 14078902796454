import { Empty } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { debounce_input_delay, max_debounce_delay } from "types/constants";

import { track } from "utilities/analytics/analytics";

import { BTSelect, BTSelectProps } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import "./BTSelectLazy.less";

export interface IBTSelectLazyProps<FormValues, ExtraDataType>
    extends Pick<
        BTSelectProps<FormValues, ExtraDataType>,
        | "id"
        | "onChange"
        | "onBlur"
        | "disabled"
        | "readOnly"
        | "dropdownClassName"
        | "multiple"
        | "style"
        | "customItemRender"
        | "data-testid"
        | "className"
        | "maxHeight"
        | "tagRender"
        | "dropdownRender"
        | "autoFocus"
        | "hideSelectAll"
    > {
    value: BTSelectItem<ExtraDataType>[] | BTSelectItem<ExtraDataType> | undefined | null;
    onSearch: (searchPhrase: string) => Promise<void>;
    searchData: BTSelectItem<ExtraDataType>[] | undefined;
    inputDelay?: number;
    maxDelay?: number;
    placeholder?: string;
}

export const BTSelectLazy = track((props) => ({
    element: "Lazy Select",
    uniqueId: props["data-testid"],
}))(function <FormValues, ExtraDataType>({
    searchData,
    onSearch,
    id,
    onChange,
    onBlur,
    value,
    inputDelay,
    maxDelay,
    multiple = false,
    disabled,
    dropdownClassName,
    readOnly,
    placeholder,
    style,
    customItemRender,
    "data-testid": dataTestId,
    className,
    maxHeight,
    tagRender,
    dropdownRender,
    autoFocus,
    hideSelectAll,
}: IBTSelectLazyProps<FormValues, ExtraDataType>) {
    const [isSearching, setIsSearching] = useState(false);

    const handleSearch = useDebouncedCallback(
        useCallback(
            async (value: string) => {
                setIsSearching(true);
                try {
                    await onSearch(value);
                } finally {
                    setIsSearching(false);
                }
            },
            [onSearch]
        ),
        inputDelay ?? debounce_input_delay,
        { maxWait: maxDelay ?? max_debounce_delay }
    );

    useEffect(() => {
        // Check if debounced is currently pending
        // If it is, call flush() to manually execute the callback
        return () => {
            if (handleSearch.pending()) {
                handleSearch.flush();
            }
        };
    }, [handleSearch]);

    const listItems = useMemo(() => {
        if (!value) {
            return searchData;
        }

        const valueArr = Array.isArray(value) ? value : [value];

        if (!searchData) {
            return valueArr;
        }

        // return unique items between selected values and search results
        // map maintains insertion order
        const combinedMap = new Map([...valueArr, ...searchData].map((x) => [x.id, x]));
        return Array.from(combinedMap.values());
    }, [searchData, value]);

    const handleChange = useCallback(
        async (field: string, value: any, item: BTSelectItem<ExtraDataType> | undefined) => {
            onChange(field, value, item);
            await handleSearch.callback("");
        },
        [handleSearch, onChange]
    );

    const notFoundContent = useMemo(() => {
        if (isSearching) {
            return (
                <BTLoading
                    instant
                    displayMode="absolute"
                    className="BTSelectLazy-Searching"
                    showLoadingBackground={false}
                />
            );
        } else if (searchData) {
            return <Empty description="No results" />;
        } else {
            return "Start typing to view results";
        }
    }, [isSearching, searchData]);

    return (
        <BTSelect<FormValues, ExtraDataType>
            noTreeDataPlaceholder=""
            data-testid={dataTestId}
            id={id}
            className={className}
            onChange={handleChange}
            onBlur={onBlur}
            showSearch
            value={Array.isArray(value) ? value : value?.value}
            onSearch={handleSearch.callback}
            shouldFilterOptions={false}
            treeData={listItems}
            showArrow={false}
            allowClear
            disabled={disabled}
            multiple={multiple}
            keepEnabledWhenEmpty
            notVisiblePlaceholder={placeholder}
            dropdownClassName={dropdownClassName}
            readOnly={readOnly}
            onClear={async () => {
                onChange(String(id), undefined, undefined);
                await handleSearch.callback("");
            }}
            notFoundContent={notFoundContent}
            style={style}
            customItemRender={customItemRender}
            maxHeight={maxHeight}
            tagRender={tagRender}
            dropdownRender={dropdownRender}
            autoFocus={autoFocus}
            hideSelectAll={hideSelectAll}
        />
    );
});
