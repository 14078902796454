import { BTSelectItem } from "types/apiResponse/apiResponse";

import { TemplateCopyPickerItem } from "commonComponents/entity/templateCopyPicker/TemplateCopyPicker/TemplateCopyPicker.api.types";
import {
    IUser,
    User,
} from "commonComponents/utilities/Grid/common/columnImplementations/columnUser";

import { JobPermission } from "entity/job/Job.api.types";

export class FromTemplateEntity {
    constructor(data: any) {
        this.id = data.id;
        this.workDays = data.workDays.value.map((item: any) => new BTSelectItem(item));
        this.whatToCopy = data.whatToCopy.map((item: any) => new TemplateCopyPickerItem(item));
        this.projectManagers = data.projectManagers
            ? data.projectManagers.value
                  .map((item: any) => new BTSelectItem(item))
                  .filter((item: BTSelectItem) => item.selected)
            : undefined;
        this.notifyProjectManager = data.notifyProjectManager;
        this.jobName = data.jobName.value;
        this.isTimeZoneSupported = data.isTimeZoneSupported;

        if (data.newSubs) {
            this.newSubs = data.newSubs.map(
                (item: { id: number; displayName: string }) => new User(item)
            );
        }
        this.permissions = data.permissions;
    }

    id: number;
    workDays: BTSelectItem[];
    whatToCopy: TemplateCopyPickerItem[];
    projectManagers: BTSelectItem[];
    newSubs: IUser[];
    notifyProjectManager: boolean;
    jobName: string;
    isTimeZoneSupported: boolean;
    permissions: JobPermission[];
}
