import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTForm, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

import { FilterEntityType, StandardFilterId } from "entity/filters/Filter/Filter.api.types";

import {
    ISavedFilterFormValues,
    SavedFilterEntity,
    SavedFilterFormActions,
    SavedFilterItem,
} from "./SavedFilter.api.types";

interface ISavedFilterProps {
    actionBeingPerformed: SavedFilterFormActions;
    currentValue: any;
    entity: SavedFilterEntity;
    selectedFilter: number;
    filterTypeEntity: FilterEntityType;
    onSubmit: (values: ISavedFilterFormValues) => Promise<void>;
    onUpdate: (filter: SavedFilterItem, values: ISavedFilterFormValues) => Promise<void>;
    modalConfig?: IModalConfiguration;
}

class SavedFilterInternal extends Component<
    InjectedFormikProps<ISavedFilterProps, ISavedFilterFormValues>
> {
    private shouldDisableUpdateButton = (currentFilter: SavedFilterItem): boolean => {
        const isStandardFilter = currentFilter.id === StandardFilterId;
        const { filterName, isDefault, value } = this.props.values;

        // If the filter is not the standard filter and the user does not have permission to
        // edit this filter.
        if (!isStandardFilter && !currentFilter.canEdit) {
            // If the filter has been modified at all, don't let the user save it unless the only
            // change is setting it as their default.
            return (
                currentFilter.isDefault === isDefault ||
                currentFilter.name !== filterName ||
                currentFilter.value !== value
            );
        }
        return (
            isStandardFilter &&
            (currentFilter.isDefault === this.props.values.isDefault ||
                this.props.values.filterName !== "Standard Filter")
        );
    };

    private isStandardSelectedAndDefault = (currentFilter: SavedFilterItem): boolean => {
        return currentFilter.id === StandardFilterId && currentFilter.isDefault;
    };

    private handleUpdate = async () => {
        this.props.setSubmitting(true);
        const formErrors = await this.props.validateForm();
        if (Object.keys(formErrors).length === 0) {
            await this.props.onUpdate(
                this.props.entity.savedFilters.filter(
                    (filter) => filter.id === this.props.values.selectedFilter
                )[0],
                this.props.values
            );
        } else {
            // run into handleSubmit with known errors to show summary
            this.props.handleSubmit();
        }
        this.props.setSubmitting(false);
    };

    private handleChangeIsShared = (e: CheckboxChangeEvent) => {
        this.props.setFieldValue("isPrivate", !e.target.checked);
    };

    render() {
        const {
            setFieldValue,
            handleChange,
            handleSubmit,
            entity,
            isSubmitting,
            setFieldTouched,
            values,
            errors,
        } = this.props;
        const currentFilter =
            entity.savedFilters.filter((filter) => filter.id === values.selectedFilter)[0] ||
            entity.savedFilters.find((filter) => filter.id === StandardFilterId);
        if (
            currentFilter.id === StandardFilterId &&
            !currentFilter.isDefault &&
            entity.savedFilters.find((x) => x.isDefault) === undefined
        ) {
            currentFilter.isDefault = true;
        }
        const savedFiltersNames = entity.savedFilters.map((savedFilter) =>
            savedFilter.name.toLocaleLowerCase()
        );
        const isUniqueTitle = !savedFiltersNames.includes(
            values.filterName.trim().toLocaleLowerCase()
        );

        values.isPrivate = values.isPrivate && (currentFilter.canEdit || isUniqueTitle);

        return (
            <BTModalLayout
                title="Saved Filters"
                footerContent={
                    <>
                        <BTButton
                            type="primary"
                            htmlType="button"
                            data-testid="update"
                            hotkey="save"
                            onClick={this.handleUpdate}
                            loading={isSubmitting}
                            disabled={this.shouldDisableUpdateButton(currentFilter)}
                        >
                            Save
                        </BTButton>
                        <BTButton
                            data-testid="save"
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            disabled={!isUniqueTitle}
                        >
                            Save as New Filter
                        </BTButton>
                    </>
                }
                modalConfig={this.props.modalConfig}
            >
                <BTForm>
                    <BTLayoutContent>
                        <FormikValidationSummary
                            errors={errors}
                            values={values}
                            showAfterSubmit={this.props.submitCount}
                            scheme={SavedFilterValidators}
                        />

                        <PageSection title="Edit Saved Filters">
                            <BTFormItemAutomatic<ISavedFilterFormValues> id="filterName">
                                <BTInput
                                    id="filterName"
                                    data-testid="filterName"
                                    value={values.filterName}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                />
                            </BTFormItemAutomatic>

                            <BTFormItemAutomatic<ISavedFilterFormValues> id="isDefault" label="">
                                <BTCheckbox
                                    data-testid="isDefault"
                                    id="isDefault"
                                    checked={values.isDefault}
                                    disabled={
                                        this.isStandardSelectedAndDefault(currentFilter) &&
                                        values.filterName === "Standard Filter"
                                    }
                                    onChange={handleChange}
                                >
                                    Set as Default
                                </BTCheckbox>
                            </BTFormItemAutomatic>

                            {(currentFilter.canEdit || isUniqueTitle) && (
                                <BTFormItemAutomatic<ISavedFilterFormValues>
                                    id="isPrivate"
                                    label=""
                                >
                                    <BTCheckbox
                                        data-testid="isShared"
                                        id="isPrivate"
                                        checked={!values.isPrivate}
                                        onChange={this.handleChangeIsShared}
                                    >
                                        Share this filter
                                    </BTCheckbox>
                                </BTFormItemAutomatic>
                            )}
                        </PageSection>
                    </BTLayoutContent>
                </BTForm>
            </BTModalLayout>
        );
    }
}

const SavedFilterValidators = yup.object().shape<ISavedFilterFormValues>({
    filterName: yup.string().max(25).label("Filter Name").required(),
    isDefault: yup.boolean().label("Is Default"),
    isPrivate: yup.boolean().label("Private"),
    selectedFilter: yup.number().label("Saved Filters"),
    value: yup.string().label("value"),
    filterType: yup.number().label("Filter Type"),
});

export const SavedFilterPresentational = withFormik<ISavedFilterProps, ISavedFilterFormValues>({
    // use the default values we specified above
    mapPropsToValues: (props: ISavedFilterProps): ISavedFilterFormValues => {
        const currentFilter =
            props.entity.savedFilters.find((filter) => filter.id === props.selectedFilter) ||
            props.entity.savedFilters.find((filter) => filter.id === StandardFilterId);

        return {
            filterName: currentFilter!.name,
            isDefault: currentFilter!.isDefault,
            isPrivate: currentFilter!.isPrivate,
            selectedFilter: currentFilter!.id,
            value: props.currentValue,
            filterType: props.filterTypeEntity,
        };
    },

    // use the yup validation object we specified above
    validationSchema: () => SavedFilterValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: async (values: ISavedFilterFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
    },
})(SavedFilterInternal);
