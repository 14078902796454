import { APIHandler } from "utilities/apiHandler";

import {
    AddressEntity,
    IAddressCountryChangedRequest,
} from "commonComponents/entity/address/Address/Address.api.types";

export interface IAddressHandler {
    countryChanged(data: IAddressCountryChangedRequest): Promise<AddressEntity>;
}

export class AddressHandler implements IAddressHandler {
    async countryChanged(data: IAddressCountryChangedRequest): Promise<AddressEntity> {
        return await APIHandler(`/api/Signup/GetAddressCountryChanged/`, {
            method: "GET",
            data: data,
            sendUndefinedAsNull: false,
            responseType: AddressEntity,
        });
    }
}
