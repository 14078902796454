import { Component as ReactComponent } from "react";
import { RouteComponentProps } from "react-router";

import { track } from "utilities/analytics/analytics";

import {
    BTModal,
    IModalConfiguration,
    IOptionalModalConfiguration,
} from "commonComponents/btWrappers/BTModal/BTModal";
import { DependentEntitiesPrompt } from "commonComponents/helpers/DependentEntitiesPrompt/DependentEntitiesPrompt";
import { DependentEntity } from "commonComponents/helpers/DependentEntitiesPrompt/DependentEntitiesPrompt.api.types";
import {
    IJobPromptHandler,
    JobPromptHandler,
} from "commonComponents/helpers/JobPrompt/JobPrompt.handler";
import { isValidId } from "commonComponents/utilities/DropDown/DropDown";

interface IWithJobPromptProps<AllComponentProps> {
    /**
     * This callback will be run after a job is selected from the JobPrompt
     * Your function is given the selected jobId and must return the page route using the new jobId
     * @example
     * withJobPrompt(EntityNameInternal)({ routeTransform: (props) => route.entityName.getDetailsLink(props.id, props.jobId) });
     */
    routeTransform: (props: AllComponentProps) => string;
}

interface IInjectedJobPromptProps extends RouteComponentProps {
    jobId: number;
    jobPromptHandler?: IJobPromptHandler;
}

interface IInjectedJobPromptState {
    jobId: number;
    dependencies?: DependentEntity[];
    isTemplateMode: boolean;
}

/** Wraps a component with a JobPrompt, this will prompt the user to select a job if no job is currently selected.
 * Useful when jobId is required by your component but not always available (ex: all jobs selected)
 * Your component will need to have a required prop of jobId: number
 * Make sure your component's Props "extends RouteComponentProps<IYourComponentsURLProps>"
 */
export const withJobPrompt = <P extends IOptionalModalConfiguration>(
    Component: React.ComponentType<P & IInjectedJobPromptProps>,
    jobPromptProps: IWithJobPromptProps<P & IInjectedJobPromptProps>
) => {
    return class extends ReactComponent<P & IInjectedJobPromptProps, IInjectedJobPromptState> {
        static defaultProps = {
            jobPromptHandler: new JobPromptHandler(),
        };

        state: Readonly<IInjectedJobPromptState> = {
            jobId: this.props.jobId,
            dependencies: undefined,
            isTemplateMode: false,
        };

        componentDidMount = async () => {
            try {
                const dependencies = new Array<DependentEntity>();
                let isTemplateMode = false;
                if (!isValidId(this.state.jobId)) {
                    const jobResponse = await this.props.jobPromptHandler!.get();
                    isTemplateMode = jobResponse.isTemplateMode;
                    dependencies.push(
                        new DependentEntity(
                            isTemplateMode ? "Template" : "Job",
                            this.jobSelected,
                            jobResponse.jobs
                        )
                    );
                }
                this.setState({
                    dependencies,
                    isTemplateMode,
                });
            } catch (e) {
                // todo log and alert or something...
            }
        };

        /**
         * @returns The url after including the selected jobid
         * opening EntityName id=0, after selected job id=5
         * before: /app/entityname/123/0
         * after:  /app/entityname/123/5
         */
        private getRouteWithJobId = (selectedJobId: number): string => {
            const allComponentProps: P & IInjectedJobPromptProps = Object.assign({}, this.props);
            allComponentProps.jobId = selectedJobId;

            return jobPromptProps.routeTransform(allComponentProps);

            /*
                todo we can remove routeTransform on every entity if we recreated the route from scratch, but route names might conflict (example :id, :jobId)

                (allComponentProps.match.params as any).jobId = selectedJobId;
                return generatePath(allComponentProps.match.path, allComponentProps.match.params);
            */
        };

        jobSelected = (selectedJobId: number) => {
            const routeWithJobId = this.getRouteWithJobId(selectedJobId);
            this.props.history.replace(routeWithJobId);

            this.setState({
                jobId: selectedJobId,
            });
        };

        render() {
            const { jobId, dependencies, isTemplateMode } = this.state;

            if (!isValidId(jobId)) {
                if (this.props.modalConfig) {
                    return (
                        <PromptInModal
                            isTemplateMode={isTemplateMode}
                            dependencies={dependencies}
                            modalConfig={this.props.modalConfig}
                        />
                    );
                }
                const title = `Select a ${isTemplateMode ? "Template" : "Job"}`;
                return (
                    <DependentEntitiesPrompt
                        fullScreen
                        dependencies={dependencies!}
                        pageTitle={title}
                    />
                );
            }

            return (
                <TrackedJobPrompt jobId={jobId}>
                    <Component {...this.props} jobId={jobId} />
                </TrackedJobPrompt>
            );
        }
    };
};

const TrackedJobPrompt: React.FunctionComponent<{ jobId: number }> = track((props) => ({
    jobId: props.jobId,
}))((props) => <>{props.children}</>);

interface IPromptInModalProps {
    modalConfig: IModalConfiguration;
    isTemplateMode: boolean;
    dependencies?: DependentEntity[];
}

const PromptInModal: React.FunctionComponent<IPromptInModalProps> = (props) => {
    const title = `Select a ${props.isTemplateMode ? "Template" : "Job"}`;
    return (
        <BTModal
            data-testid="btModalJobPrompt"
            title={title}
            setPageTitle={false}
            visible
            centered
            width="400px"
            beforeClose={props.modalConfig.beforeClose}
            overflow="visible"
            removeBodyPadding
        >
            <DependentEntitiesPrompt dependencies={props.dependencies!} />
        </BTModal>
    );
};
