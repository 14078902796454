import type { default as TaxRateBreakdownResponse } from "./TaxRateBreakdownAPIResponse.api.json";

export class TaxRateBreakdownInfo {
    constructor(taxRateBreakdown: typeof TaxRateBreakdownResponse.taxRateBreakdown) {
        this.taxRateBreakdownItems =
            taxRateBreakdown.taxRateBreakdownItems?.map(
                (item: any) => new TaxRateBreakdownItem(item)
            ) ?? [];
        this.totalTax = taxRateBreakdown.totalTax;
        this.taxOverride = taxRateBreakdown.taxOverride ?? 0;
    }

    taxRateBreakdownItems: TaxRateBreakdownItem[];
    totalTax: number;
    taxOverride: number;
}

export class TaxRateBreakdownItem {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.percentage = data.percentage;
        this.isActive = data.isActive;
        this.applicableAmount = data.applicableAmount;
        this.taxAmount = data.taxAmount;
    }

    id: number;
    name: string;
    percentage: number;
    isActive: boolean;
    applicableAmount: number;
    taxAmount: number;
}
