import { APIHandler } from "utilities/apiHandler";

import {
    IRoleSurveyFormValues,
    RoleSurveyEntity,
    RoleSurveySubmitResponse,
} from "./RoleSurvey.api.types";

export interface IRoleSurveyHandler {
    get(): Promise<RoleSurveyEntity>;
    update(formData: IRoleSurveyFormValues): Promise<RoleSurveySubmitResponse>;
}

export class RoleSurveyHandler implements IRoleSurveyHandler {
    async get(): Promise<RoleSurveyEntity> {
        return await APIHandler(`/api/InternalUsers/JobDescription`, {
            method: "GET",
            responseType: RoleSurveyEntity,
        });
    }

    async update(formData: IRoleSurveyFormValues): Promise<RoleSurveySubmitResponse> {
        return await APIHandler(`/api/InternalUsers/JobDescription`, {
            method: "PUT",
            data: {
                jobDescription: formData.roles,
                otherJobDescription: formData.otherDescription,
            },
            responseType: RoleSurveySubmitResponse,
        });
    }
}

export const defaultRoleSurveyHandler = new RoleSurveyHandler();
