import { CaretRightOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

interface IBTIconCaretRightOutlinedProps extends IBTIconProps {
    rotateRight?: boolean;
}

export const BTIconCaretRightOutlined: FunctionComponent<IBTIconCaretRightOutlinedProps> = ({
    rotateRight = false,
    ...baseProps
}) => {
    const [props] = useAntIcon(baseProps);

    return <CaretRightOutlined {...props} rotate={rotateRight ? 90 : 0} />;
};
