import { getAppDefaultFlagValue } from "utilities/appDefaultFlags";
import { AppcuesOnboarding } from "utilities/onboarding/appcuesonboarding/appcuesonboarding";
import { BTOnboarding } from "utilities/onboarding/btonboarding/btonboarding";
import { IOnboardingPlugin, OnboardingAction } from "utilities/onboarding/onboarding.types";

/**
 * @returns Onboarding abstraction
 */
function getOnboarding() {
    let plugins: IOnboardingPlugin[] = [];

    plugins.push(new BTOnboarding());

    // Appcues Onboarding
    const appcuesAccountId = getAppDefaultFlagValue("appcuesAccountId", null);
    const appcuesEnabled = getAppDefaultFlagValue("enableAppcues", false);
    if (appcuesEnabled && appcuesAccountId) {
        plugins.push(new AppcuesOnboarding());
    }

    return {
        plugins,
    };
}

const onboarding = getOnboarding();

/**
 *
 * @param action {@link OnboardingAction}
 * @param actionData Additional event data
 */
export function onboardingActionCompleted(action: OnboardingAction, actionData: any) {
    onboarding.plugins.forEach((plugin) => {
        plugin.actionCompleted && plugin.actionCompleted(action, actionData);
    });
}
