import qs from "query-string";

import { isInPortal } from "utilities/portal/portal";
import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IPurchaseOrderProps } from "entity/purchaseOrder/PurchaseOrder/PurchaseOrder";

const PurchaseOrder = lazyLoadWithRetry(() => import("./PurchaseOrder"));

export const RoutePurchaseOrder = (
    props: Omit<IPurchaseOrderProps, "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.purchaseOrder.details}
        render={(routeProps) => {
            const qsValues: any = qs.parse(routeProps.location.search, { parseBooleans: true });
            const urlParams = routeProps.match.params;

            const id = parseInt(urlParams.id);
            const jobId = parseInt(urlParams.jobId);
            const isFromBid = qsValues.fromBid ?? props.disableBidLinks;
            const varianceChangeOrderId: number | undefined =
                urlParams.varianceChangeOrderId !== undefined
                    ? parseInt(urlParams.varianceChangeOrderId)
                    : undefined;
            const openToScopeOfWork: boolean = routeProps.location.pathname.includes("RFI");
            const isBuilder = isInPortal({ builder: true });

            return (
                <BTSuspense>
                    <PurchaseOrder
                        {...props}
                        {...routeProps}
                        id={id}
                        jobId={jobId}
                        disableBidLinks={isFromBid}
                        varianceChangeOrderId={varianceChangeOrderId}
                        openToScopeOfWork={openToScopeOfWork}
                        loadLinkedBids={isBuilder}
                        modalConfig={{
                            parentRoute: props.modalConfig ? props.modalConfig.parentRoute : "",
                            beforeClose: () => {
                                props.onClose?.(routeProps);
                                props.modalConfig?.beforeClose();
                            },
                        }}
                    />
                </BTSuspense>
            );
        }}
    />
);
