import { ReadOnlyShouldTruncate } from "commonComponents/utilities/TextTruncate/TextTruncate.type";

export const isReadOnly = (value?: ReadOnlyShouldTruncate) => {
    // If the object is specified at all, it's intended to be readonly
    return !!value;
};

export const shouldTruncate = (value?: ReadOnlyShouldTruncate) => {
    return typeof value === "object" && value.shouldTruncate;
};
