import { GridEntity } from "commonComponents/utilities/Grid/GridContainer.types";

import type { default as LeadActivityListJson } from "entity/lead/LeadActivityList/LeadActivityList.api.json";

export type LeadActivityListFormActions =
    | "logCompletedActivity"
    | "scheduleNewActivity"
    | "checkedAction"
    | "selectAllEntity"
    | undefined;

export enum LeadActivityListCheckedActions {
    Reassign = 1,
    BulkDelete,
    ColorChange,
}

export class LeadActivityGridEntity extends GridEntity {
    constructor(data: typeof LeadActivityListJson) {
        super(data);
        this.builderId = data.builderId;
        this.canReassignSalesperson = data.canReassignSalesperson;
    }

    canReassignSalesperson: boolean;
    builderId: number;
}

export enum EntityToOpen {
    Activity = 0,
    LeadAndActivity = 1,
}
