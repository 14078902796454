import { Component } from "react";

import { PaymentStatus } from "types/enum";

import { getMerchantPaymentStatusDisplayString } from "commonComponents/entity/merchantPayment/common/MerchantPaymentStatus/merchantPayments";
import { StatusDisplay, StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

interface IMerchantPaymentStatusProps {
    status: PaymentStatus;
    statusReason?: string | null;
}

class MerchantStatusDisplay {
    constructor(status: PaymentStatus) {
        this.statusText = getMerchantPaymentStatusDisplayString(status);
        switch (status) {
            case PaymentStatus.Unpaid:
                this.statusType = "";
                break;
            case PaymentStatus.Captured:
            case PaymentStatus.ManuallyCaptured:
            case PaymentStatus.PaymentCapturedButNotProperlyRecorded:
                this.statusType = "success";
                break;
            case PaymentStatus.Authorized:
            case PaymentStatus.Reserved:
                this.statusType = "pending";
                break;
            case PaymentStatus.Expired:
            case PaymentStatus.Failed:
            case PaymentStatus.Cancelled:
            case PaymentStatus.Refunded:
            case PaymentStatus.RefundedAndManuallyCaptured:
            case PaymentStatus.ChargedBack:
            case PaymentStatus.Void:
                this.statusType = "danger";
                break;
            default:
                this.statusType = "";
        }
    }

    statusText: string;
    statusType: StatusTypes;
}

export class MerchantPaymentStatusText extends Component<IMerchantPaymentStatusProps> {
    render() {
        const { status } = this.props;
        if (status < 1 || status > 13) {
            return "";
        } else {
            return new MerchantStatusDisplay(status).statusText;
        }
    }
}

export class MerchantPaymentStatus extends Component<IMerchantPaymentStatusProps> {
    render() {
        const { status } = this.props;
        const statusDisplay = new MerchantStatusDisplay(status);
        return (
            <StatusDisplay statusType={statusDisplay.statusType}>
                {statusDisplay.statusText}
            </StatusDisplay>
        );
    }
}

export class MerchantPaymentStatusTag extends Component<IMerchantPaymentStatusProps> {
    render() {
        const { status, statusReason } = this.props;
        const statusDisplay = new MerchantStatusDisplay(status);
        if (status < 1 || status > 13) {
            return <></>;
        } else {
            return (
                <StatusTagDisplay
                    statusText={statusDisplay.statusText}
                    statusType={statusDisplay.statusType}
                    popoverContent={statusReason}
                />
            );
        }
    }
}
