import { WarningFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { WarningColor } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconWarningWarning: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return (
        <WarningFilled {...props} type="warning" style={{ ...props.style, color: WarningColor }} />
    );
};
