import { LinkType } from "types/enum";

import { addPathForCurrentPortal } from "utilities/routes";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { THotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.types";

interface IAbstractLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    url?: string;
    linkType: LinkType;
    addPortalToReactLinks?: boolean;
    shouldOpenNewTab?: boolean;
    isUnderline?: boolean;
    performActionAndRedirect?: boolean;
    hotkey?: THotkeyCommands;
}

export const AbstractLink: React.FunctionComponent<IAbstractLinkProps> = ({
    linkType,
    url,
    isUnderline,
    addPortalToReactLinks = false,
    shouldOpenNewTab,
    performActionAndRedirect = false,
    hotkey,
    ...otherProps
}) => {
    const { onClick } = otherProps;
    if (!url) {
        // if no URL (likely using onClick or similar instead), can't use BTLink. Using anchor tag without href for these cases
        return (
            <BTLink
                to="#"
                role="button"
                tabIndex={0}
                onClick={(e) => {
                    e.preventDefault();
                    if (onClick) {
                        onClick(e);
                    }
                    return false;
                }}
                isUnderline={isUnderline}
                hotkey={hotkey}
                {...otherProps}
            />
        );
    }

    // unless otherwise specified, external pages should open in new tabs, and internal pages should open in same tab
    const computedShouldOpenNewTab = shouldOpenNewTab ?? linkType === LinkType.External;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClick && performActionAndRedirect) {
            onClick(e);
        }
        return false;
    };

    if (linkType === LinkType.InternalReact) {
        return (
            <BTLink
                to={addPortalToReactLinks ? addPathForCurrentPortal(url) : url}
                shouldOpenNewTab={computedShouldOpenNewTab}
                onClick={handleClick}
                isUnderline={isUnderline}
                hotkey={hotkey}
                {...otherProps}
            />
        );
    } else if (computedShouldOpenNewTab) {
        return (
            <BTExternalLink
                href={url}
                onClick={handleClick}
                isUnderline={isUnderline}
                hotkey={hotkey}
                {...otherProps}
            />
        );
    }
    return <a href={url} onClick={handleClick} {...otherProps} />;
};
