import classNames from "classnames";
import queryString from "query-string";
import { forwardRef, useCallback, useMemo, useState } from "react";

import { ImageResizeMode, ObjectFit } from "types/enum";

import { BTLogo, IBTLogoProps } from "commonComponents/utilities/BTLogo/BTLogo";

import "./Thumbnail.less";

export interface IThumbnailProps {
    /** if undefined/null fallback will be immediately rendered */
    src: string | undefined | null;
    width: number;
    height: number;
    alt: string;
    "data-testid"?: string;
    className?: string;
    mode?: ImageResizeMode;
    /** @default ObjectFit.Fill */
    fit?: ObjectFit;
    /** @default false */
    draggable?: boolean;

    style?: React.CSSProperties;

    /**
     * @deprecated Use a clickable element instead, like <Button>, <a>
     * This is dangerous because it breaks accessibility
     * @see eslint-plugin-jsx-a11y no-noninteractive-element-interactions
     */
    onClickDangerous?: React.MouseEventHandler<HTMLImageElement>;

    /** content to render when the image is loading, or not found */
    fallback?: React.ReactNode;

    onLoad?: React.ReactEventHandler<HTMLImageElement>;
    onError?: React.ReactEventHandler<HTMLImageElement>;
}

const getImageSize = (src: string, width: number, height: number, mode?: ImageResizeMode) => {
    if (src.startsWith("data:")) {
        // No need to manipulate data paths
        return src;
    }
    const qsParams = queryString.parseUrl(src);

    // temp disabling until we can fix PDF generation issue https://dev.azure.com/buildertrend/BuildertrendProjects/_workitems/edit/97004
    /*
    if (qsParams.query["width"] || qsParams.query["height"]) {
        // Do NOT remove this exception. Fix the root cause - your API should never return a resized image. That's a client concern.
        throw new Error("Thumbnail URL has width and height already specified. Use props instead.");
    }
    */

    qsParams.query.width = String(width);
    qsParams.query.height = String(height);
    qsParams.query.autorotate = String("true");

    if (mode) {
        qsParams.query.mode = mode;
    }

    return queryString.stringifyUrl(qsParams);
};

function getFallbackLogoSize(size: number): IBTLogoProps["size"] {
    if (size < 75) {
        return "sm";
    }
    if (size < 125) {
        return "md";
    }
    if (size < 175) {
        return "lg";
    }

    return "xl";
}

const emptyDefaultFunc = () => {};

export const Thumbnail = forwardRef<HTMLImageElement, IThumbnailProps>(
    (
        {
            src,
            width,
            height,
            mode,
            fit = ObjectFit.Fill,
            alt,
            onLoad = emptyDefaultFunc,
            onError = emptyDefaultFunc,
            fallback,
            onClickDangerous,
            className,
            draggable = false,
            style,
            "data-testid": dataTestId,
        },
        ref
    ) => {
        const [useFallback, setUseFallback] = useState<boolean>(false);
        const srcEmpty = src === undefined || src === null;

        const photoStyles = useMemo(() => {
            const imageStyle: React.CSSProperties | undefined = {
                maxHeight: `${height}px`,
                maxWidth: `${width}px`,
                objectFit: fit,
                ...style,
            };

            return imageStyle;
        }, [height, width, fit, style]);

        const handleLoad = useCallback(
            (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                onLoad(event);
            },
            [onLoad]
        );

        const handleError = useCallback(
            (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                onError(event);
                setUseFallback(true);
            },
            [onError]
        );

        const imgWidth = mode === ImageResizeMode.Max ? undefined : width;
        const imgHeight = mode === ImageResizeMode.Max ? undefined : height;
        const fallbackSize = Math.min(imgWidth ?? 100, imgHeight ?? 100);
        const fallbackLogoSize = getFallbackLogoSize(fallbackSize);

        return (
            <div
                className={classNames("Thumbnail", "flex align-items-center", {
                    "justify-content-center": !className?.includes("left"),
                })}
            >
                {useFallback || srcEmpty ? (
                    <div
                        style={{
                            width: imgWidth,
                            height: imgHeight,
                        }}
                        className={classNames("Thumbnail--fallback")}
                    >
                        {fallback ? (
                            fallback
                        ) : (
                            <div
                                style={{
                                    width: fallbackSize,
                                    height: fallbackSize,
                                }}
                                className={classNames("Thumbnail--fallback", {
                                    "Thumbnail--fallback-default": !fallback,
                                })}
                            >
                                <BTLogo
                                    layout="minimal"
                                    color="black-grey"
                                    size={fallbackLogoSize}
                                    className="Thumbnail--BTLogo-light"
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <img
                        src={getImageSize(src!, width, height, mode)}
                        width={imgWidth}
                        height={imgHeight}
                        style={photoStyles}
                        alt={alt}
                        data-testid={dataTestId}
                        draggable={draggable}
                        className={className}
                        loading="lazy"
                        onLoad={handleLoad}
                        onError={handleError}
                        onClick={onClickDangerous}
                        ref={ref}
                    />
                )}
            </div>
        );
    }
);

export const thumbnailTestFunctions = {
    getImageSize: getImageSize,
};
