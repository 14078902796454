import { InjectedFormikProps, withFormik } from "formik";

import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTForm, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { RequiredFieldIndicator } from "commonComponents/utilities/RequiredFieldIndicator/RequiredFieldIndicator";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

import { PasswordResetFormActions } from "entity/passwordReset/PasswordReset/PasswordReset.api.types";

import "./PasswordReset.less";

interface IPasswordResetProps {
    handleSubmit: (values: IPasswordResetFormValues) => Promise<void>;
    handleLogin: (values: IPasswordResetFormValues) => Promise<void>;
    redirectToLogin: () => void;
    passwordUpdated: boolean;
    isTokenValid: boolean;
    actionBeingPerformed: PasswordResetFormActions;
}

export interface IPasswordResetFormValues {
    password: string;
    confirmPassword: string;
}

const PasswordResetInternal: React.FunctionComponent<
    InjectedFormikProps<IPasswordResetProps, IPasswordResetFormValues>
> = (props) => {
    const { values, handleSubmit, setFieldValue, setFieldTouched } = props;
    return (
        <BTRow className="PasswordReset" justify="center" align="middle">
            <BTCol xs={24} md={12} className="flex">
                <div className="PasswordResetDiv">
                    <BTRow
                        justify="center"
                        align="middle"
                        className="PasswordReset-LogoLarge margin-top-lg"
                    >
                        <BTCol>
                            <BTLogo layout="vertical" color="white-blue" size="sm" />
                        </BTCol>
                    </BTRow>
                    <BTRow justify="center" align="middle" className="margin-top-lg">
                        <PageSection className="PasswordReset-Form magin-top-lg">
                            {!props.passwordUpdated && props.isTokenValid && (
                                <>
                                    <BTTitle level={2}>Reset your Password</BTTitle>
                                    Create a new password below.
                                    <BTForm onSubmit={handleSubmit}>
                                        <FormikValidationSummary
                                            errors={props.errors}
                                            values={props.values}
                                            scheme={PasswordResetValidationScheme()}
                                        />
                                        <BTFormItemAutomatic<IPasswordResetFormValues>
                                            id="password"
                                            className="margin-top-lg"
                                            label={
                                                <>
                                                    Create a Password
                                                    <RequiredFieldIndicator />{" "}
                                                    <BTPopover content="Password must include at least 12 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)" />
                                                </>
                                            }
                                        >
                                            <BTInput
                                                id="password"
                                                data-testid="password"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.password}
                                                type="password"
                                            />
                                        </BTFormItemAutomatic>

                                        <BTFormItemAutomatic<IPasswordResetFormValues> id="confirmPassword">
                                            <BTInput
                                                id="confirmPassword"
                                                data-testid="confirmPassword"
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                value={values.confirmPassword}
                                                type="password"
                                            />
                                        </BTFormItemAutomatic>

                                        <BTButton<PasswordResetFormActions>
                                            data-testid="loginUpdatePassword"
                                            htmlType="submit"
                                            actionBeingPerformed={props.actionBeingPerformed}
                                            loadingAction="submit"
                                            type="primary"
                                            className="PasswordReset-Form-SubmitButton margin-top-lg"
                                        >
                                            Update Password
                                        </BTButton>
                                    </BTForm>
                                </>
                            )}
                            {!props.isTokenValid && (
                                <>
                                    <BTTitle level={2}>The Link Has Expired</BTTitle>
                                    The link you are trying to use has expired. To reset your
                                    password again go back to the login page.
                                    <BTButton<PasswordResetFormActions>
                                        data-testid="backToLogin"
                                        type="primary"
                                        className="PasswordReset-Form-SubmitButton margin-top-lg"
                                        onClick={props.redirectToLogin}
                                    >
                                        Go to Login
                                    </BTButton>
                                </>
                            )}
                            {props.passwordUpdated && (
                                <>
                                    <BTTitle level={2}>Password Update Successful!</BTTitle>
                                    Your password was successfully updated. Click below to go to
                                    Buildertrend.
                                    <BTButton<PasswordResetFormActions>
                                        data-testid="passwordResetLogin"
                                        type="primary"
                                        className="PasswordReset-Form-SubmitButton margin-top-lg"
                                        onClick={() => props.handleLogin(values)}
                                    >
                                        Go to Buildertrend
                                    </BTButton>
                                </>
                            )}
                        </PageSection>
                    </BTRow>
                </div>
            </BTCol>
        </BTRow>
    );
};

const PasswordResetValidationScheme = () =>
    yup.object().shape<IPasswordResetFormValues>({
        password: yup.string().securePassword().required().label("Create a Password"),
        confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref("password")], "Password and confirmation do not match")
            .label("Re-enter Password"),
    });

export const PasswordResetPresentational = withFormik<
    IPasswordResetProps,
    IPasswordResetFormValues
>({
    mapPropsToValues: () => ({
        password: "",
        confirmPassword: "",
    }),
    handleSubmit: async (values: IPasswordResetFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.handleSubmit(values);
        setSubmitting(false);
    },
    validationSchema: () => PasswordResetValidationScheme(),
    validateOnBlur: true,
    validateOnChange: true,
})(PasswordResetInternal);
