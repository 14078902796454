import { BTSelectItem } from "types/apiResponse/apiResponse";

export class DependentEntity {
    constructor(
        name: string,
        onEntitySelected: (entityId: number) => void,
        entityList: BTSelectItem[]
    ) {
        this.name = name;
        this.onEntitySelected = onEntitySelected;
        this.entityList = entityList;
    }

    name: string;
    onEntitySelected: (entityId: number) => void;
    entityList: BTSelectItem[];
}
