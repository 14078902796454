import { ILineItemResponse, LineItemType } from "legacyComponents/LineItemContainer.types";
import { Moment } from "moment";

import { APIHandler, convertObjectToURL } from "utilities/apiHandler";

import {
    CostCatalogSettings,
    CostGroupsForImportResponse,
    CostItemsForImportResponse,
} from "entity/costCatalog/CostCatalog.types";

export interface ICostCatalogHandler {
    get(viewType: number): Promise<CostCatalogSettings>;

    getCostItemsForImport(ids: number[]): Promise<CostItemsForImportResponse>;

    getCostGroupsForImport(
        ids: number[],
        inactivationCutoffDate?: Moment
    ): Promise<CostGroupsForImportResponse>;

    shouldBreakLinkToCostItem(
        lineItem: ILineItemResponse,
        lineItemType: LineItemType
    ): Promise<Boolean>;
}

export class CostCatalogHandler implements ICostCatalogHandler {
    async get(viewType: number): Promise<CostCatalogSettings> {
        const queryString = convertObjectToURL({ viewType: viewType });
        return await APIHandler(`/api/CostCatalog?${queryString}`, {
            method: "GET",
            responseType: CostCatalogSettings,
        });
    }

    async getCostItemsForImport(ids: number[]) {
        const idsHeader = `[${ids.join(",")}]`;
        return await APIHandler("/api/CostCatalog/CostItemsForImport", {
            method: "GET",
            responseType: CostItemsForImportResponse,
            headers: { costItemIds: idsHeader },
        });
    }

    async getCostGroupsForImport(ids: number[], inactivationCutoffDate?: Moment) {
        const idsHeader = `[${ids.join(",")}]`;
        const date = inactivationCutoffDate
            ? inactivationCutoffDate.format("YYYY-MM-DDTHH:mm:ss")
            : null;
        return await APIHandler("/api/CostCatalog/CostGroupsForImport", {
            method: "GET",
            responseType: CostGroupsForImportResponse,
            headers: { costGroupIds: idsHeader, inactivationCutoffDate: date },
        });
    }

    async shouldBreakLinkToCostItem(lineItem: ILineItemResponse, lineItemType: LineItemType) {
        return await APIHandler("/api/CostCatalog/ShouldBreakLinkToCostItem", {
            method: "POST",
            responseType: Boolean,
            data: { ...lineItem, pageTypeEnum: lineItemType },
        });
    }
}
