import { Input, notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { useState } from "react";

import { IChatNotificationData } from "helpers/pushNotification.types";

import { BTSessionStorage } from "types/btStorage";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCloseOutlined, BTIconPaperPlaneRight } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { EditorControlButton } from "commonComponents/btWrappers/editor/core/EditorControlBar/common/EditorControlButton/EditorControlButton";
import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

import { IChatHandler } from "entity/chat/Chat.api.handler";
import { ISendChatRequest } from "entity/chat/common/chat.types";

import "./pushNotification.utilities.display.css";

import chatNotification from "../audio/chatNotification.mp3";

const defaultTimeout: number = 10;

/** Shows a notification with a chat bubble.  Called when a new chat message comes through,
 * but the user is not actively viewing the chat.
 */
export const showChatMessageNotification = (
    notificationData: IChatNotificationData,
    chatHandler: IChatHandler
) => {
    // if we want new chats from the same user to replace the existing notification,
    // user notificationData.userId instead of notificationData.uniqueId
    const notificationKey = notificationData.uniqueId.toString();

    const handle = setTimeout(() => {
        notification.close(notificationKey);
    }, defaultTimeout * 1000);

    const handleFocus = () => {
        clearTimeout(handle);
    };
    const handleClick = () => {
        try {
            const channelUrl = notificationData.getChannelUrl();
            if (channelUrl) {
                window.open(channelUrl, "_blank");
            }
        } catch (_e) {
            // swallow error
        }
    };
    const handleClose = () => {
        notification.close(notificationKey);
    };

    const args = buildNotificationArgs({
        author: notificationData.title,
        builderLogoUrl: notificationData.builderLogoUrl,
        message: notificationData.message,
        channelId: notificationData.threadId,
        chatHandler: chatHandler,
        notificationKey,
        handleFocus,
        handleClick,
    });

    playAudio(chatNotification);
    setTemporaryTabTitle("🔵 New Chat");

    notification.open({
        ...args,
        closeIcon: <ChatNotificationCloseIcon handleClose={handleClose} />,
    });
};

/**
 * Set a tabs title to a temporary value, then reset it to the original value when the tab is focused.
 * Uses session storage to store the original title to prevent it from being lost on multiple calls to this function.
 * @param title
 */
const setTemporaryTabTitle = (title: string) => {
    const titleInSession = BTSessionStorage.get("bt-string-lastTabTitleBeforeTemporaryTitle");
    const originalTitle = titleInSession ?? document.title;
    BTSessionStorage.set("bt-string-lastTabTitleBeforeTemporaryTitle", originalTitle);

    document.title = title;
    window.addEventListener(
        "focus",
        () => {
            document.title = originalTitle;
            BTSessionStorage.set("bt-string-lastTabTitleBeforeTemporaryTitle", null);
        },
        { once: true }
    );
};

/**
 * Plays audio from a given source. Quietly (hah) fails if the audio cannot be played.
 * @param source
 */
const playAudio = (source: string) => {
    const audio = new Audio(source);
    audio.play().catch(() => {
        // Most browsers don't allow audio to be played before a user first interacts with the page.
        // This only happens if the user loads the page then never interacts with it.
        // https://goo.gl/xX8pDD
        // This can be safely ignored in these rare situations.
    });
};

const ChatNotificationCloseIcon = (props: { handleClose: () => void }) => {
    const { handleClose } = props;
    return (
        <BTButton
            type="tertiary"
            isolated
            data-testid="chat-notification-close"
            hotkey={{ hotkey: "close", popoverPlacement: "bottom" }}
            onClick={handleClose}
            className="ModalHeaderClose"
            icon={<BTIconCloseOutlined title="Close" size={20} className="ModalHeaderCloseIcon" />}
        />
    );
};

type NotificationArgs = ArgsProps & { key: string };

interface INotificationProps {
    author: string;
    message: string;
    channelId: string;
    chatHandler: IChatHandler;
    builderLogoUrl: string;
    notificationKey: string;
    handleFocus: () => void;
    handleClick: () => void;
}

const buildNotificationArgs = (props: INotificationProps): NotificationArgs => {
    const {
        handleClick,
        handleFocus,
        author,
        builderLogoUrl,
        message,
        channelId,
        chatHandler,
        notificationKey,
    } = props;
    return {
        message: <div onClick={handleClick}>{author} Sent a Chat</div>,
        description: (
            <NotificationDescription
                message={message}
                channelId={channelId}
                chatHandler={chatHandler}
                builderLogoUrl={builderLogoUrl}
                notificationKey={notificationKey}
                handleFocus={handleFocus}
                handleClick={handleClick}
            />
        ),
        placement: "topRight",
        className: "chat-notification",
        duration: 0,
        key: notificationKey,
    };
};

const NotificationDescription = (props: Omit<INotificationProps, "author">) => {
    const {
        builderLogoUrl,
        message,
        channelId,
        chatHandler,
        notificationKey,
        handleFocus,
        handleClick,
    } = props;
    const [messageValue, setMessageValue] = useState("");

    const onSendChat = async () => {
        const editorContent = EditorContent.FromPlainText(messageValue);

        const chatPayload: ISendChatRequest = {
            content: editorContent,
        };
        await chatHandler.sendChat(channelId, chatPayload);
        notification.close(notificationKey);
    };
    return (
        <div className="bt-chat-notification-wrapper">
            <BTRow onClick={handleClick}>
                <img src={builderLogoUrl} alt="Builder logo" className="notification-image" />
                <div className="bt-chat-notification-content">{message}</div>
            </BTRow>
            <BTRow
                style={{
                    alignItems: "center",
                }}
            >
                <EditorControlButton
                    data-testid="sendChat"
                    icon={<BTIconPaperPlaneRight />}
                    onClick={onSendChat}
                    friendlyName="Send"
                    isToggled={false}
                    loadingAction="sendChat"
                />
                {/* eslint-disable-next-line react/forbid-elements */}
                <Input
                    style={{
                        width: "auto",
                        flex: 1,
                    }}
                    id="ChatNotificationReply"
                    className="ChatNotificationReply"
                    placeholder="Reply..."
                    data-testid="ChatNotificationReply"
                    value={messageValue}
                    onChange={async (event) => {
                        const value = event.target.value;
                        setMessageValue(value);
                    }}
                    onClick={handleFocus}
                    onFocus={handleFocus}
                    onPressEnter={onSendChat}
                />
            </BTRow>
        </div>
    );
};
