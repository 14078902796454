import { message } from "antd";
import { Component } from "react";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";

import { ISavedFilterHandler, SavedFilterHandler } from "./SavedFilter.api.handler";
import {
    ISavedFilterFormValues,
    SavedFilterEntity,
    SavedFilterFormActions,
    SavedFilterItem,
} from "./SavedFilter.api.types";
import { SavedFilterPresentational } from "./SavedFilterPresentational";

interface ISavedFilterProps {
    selectedFilterId: number;
    currentValue: any;
    entity: SavedFilterEntity;

    /**
     * saved filter handler
     * @default new SavedFilterHandler()
     */
    handler?: ISavedFilterHandler;

    filterTypeEntity: FilterEntityType;
    modalConfig?: IModalConfiguration;
    visible: boolean;
    onFilterAdded: (id: number, values: ISavedFilterFormValues) => void;
    onFilterUpdated: (id: number, values: ISavedFilterFormValues, canEdit: boolean) => void;
    jobIDs?: number[];
}

interface ISavedFilterState {
    actionBeingPerformed: SavedFilterFormActions;
}

class SavedFilterInternal extends Component<ISavedFilterProps, ISavedFilterState> {
    static defaultProps = {
        handler: new SavedFilterHandler(),
    };

    state: Readonly<ISavedFilterState> = {
        actionBeingPerformed: undefined,
    };

    private onSubmit = async (newValues: ISavedFilterFormValues) => {
        const { handler, onFilterAdded, modalConfig, jobIDs } = this.props;
        await setLoadingAction<SavedFilterFormActions>(this, {
            actionBeingPerformed: "save",
            callback: async () => {
                try {
                    const nonSpecialJobs = jobIDs?.filter((j) => j > 0);
                    const jobID = nonSpecialJobs?.length === 1 ? nonSpecialJobs[0] : null;
                    const createResponse = await handler!.create(newValues, jobID);
                    onFilterAdded(createResponse.id, newValues);
                    if (modalConfig) {
                        modalConfig.beforeClose();
                    } else {
                        // eslint-disable-next-line deprecate/member-expression
                        routesWebforms.closeModal();
                    }
                    void message.success("Saved Successfully");
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private onUpdate = async (filter: SavedFilterItem, newValues: ISavedFilterFormValues) => {
        const { handler, onFilterUpdated, modalConfig, jobIDs } = this.props;
        await setLoadingAction<SavedFilterFormActions>(this, {
            actionBeingPerformed: "save",
            callback: async () => {
                try {
                    const nonSpecialJobs = jobIDs?.filter((j) => j > 0);
                    const jobID = nonSpecialJobs?.length === 1 ? nonSpecialJobs[0] : null;
                    await handler!.update(filter.id, newValues, jobID);
                    onFilterUpdated(filter.id, newValues, filter.canEdit);
                    if (modalConfig) {
                        modalConfig.beforeClose();
                    } else {
                        // eslint-disable-next-line deprecate/member-expression
                        routesWebforms.closeModal();
                    }
                    void message.success("Saved Successfully");
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    render() {
        // Show loading spinner until entity is loaded
        if (!this.state || !this.props.entity) {
            return <BTLoading />;
        }

        const component = (
            <SavedFilterPresentational
                actionBeingPerformed={this.state.actionBeingPerformed}
                entity={this.props.entity}
                selectedFilter={this.props.selectedFilterId}
                onSubmit={this.onSubmit}
                onUpdate={this.onUpdate}
                filterTypeEntity={this.props.filterTypeEntity}
                currentValue={this.props.currentValue}
                modalConfig={this.props.modalConfig}
            />
        );

        if (this.props.modalConfig === undefined) {
            return component;
        }

        return (
            <BTModal
                data-testid="btModalFilters"
                beforeClose={this.props.modalConfig.beforeClose}
                visible={this.props.visible}
                title="Saved Filters"
                setPageTitle={false}
                width="500px"
                removeBodyPadding={true}
                useModalLayout
            >
                {component}
            </BTModal>
        );
    }
}

export const SavedFilter = withErrorBoundary(SavedFilterInternal)("Could not load Saved Filter");
export default SavedFilter;
