import { ComponentType } from "react";

import { IBTIconProps } from "commonComponents/btWrappers/BTIcon";

import { InvoiceStatus } from "entity/ownerInvoice/common/OwnerInvoiceStatus/OwnerInvoiceStatus";

export interface IRelatedItemProps {
    type: RelatedItemType;

    /** Link address to the related item to open when clicking the component. */
    to: string;

    /** Setting this to true will make the related item appear like static content. */
    isDisabled?: boolean;

    /** Set this if you don't want this component to combine `match.url + to` for you. */
    fullUrl?: boolean;

    /** Limit the component to one line with content cut off with ellipsis if necessary. Also includes a popover in that case. */
    noWrap?: boolean;

    /** Show the entity type name in front of the title of the entity. */
    showEntityType?: boolean;

    "data-testid"?: string;

    className?: string;

    /** Additional click event handler that will be called before the component click handler logic. */
    onClick?: (event: React.MouseEvent) => void;

    /** Function for how to handle the object when clicking the close icon. If no function is provided, the close button will not appear. */
    onRemove?: () => void;
}

export enum RelatedItemType {
    None = 0,
    ChangeOrder = 1,
    ToDo = 2,
    PurchaseOrder = 3,
    CustomerInvoice = 4,
    Warranty = 5,
    ScheduleItem = 6,
    BidPackage = 7,
    PhotoDoc = 8,
    Video = 9,
    Document = 10,
    Allowance = 11,
    SelectionChoice = 12,
    Bid = 13,
    DailyLog = 14,
    RFI = 15,
    PurchaseOrderPaymentLienWaiver = 16,
    Jobsite = 17,
    Selection = 18,
    Lead = 19,
    CreditMemo = 20,
    Estimate = 21,
    CostGroup = 22,
    UnsentMessage = 23,
    SentMessage = 24,
    BillLienWaiver = 25,
    Bill = 26,
    PurchaseOrderPayment = 27,
    Takeoff = 28,
    Rebate = 29,
    Specification = 30,
    LeadProposal = 31,
    Deposit = 32,
    CostItem = 33,
    OnlinePayment = 34,
}

export interface IRelatedItemResponse {
    id: number;
    type: RelatedItemType;
    title: string;
    status: number | null;
}

// This comes from PoMappingView or PoParentMappingView on the server, not RelatedPOReponse
export class RelatedPurchaseOrder {
    constructor(data: any) {
        this.id = data.POID;
        this.title = data.POTitle;
        this.number = data.PONumber;
    }
    id: number;
    title: string;
    number: string;
}

export class RelatedChangeOrder {
    constructor(data: any) {
        this.id = data.changeOrderId;
        this.title = data.changeOrderTitle;
        this.canUpdate = data.canUpdate;
        this.isLinkedToOtherBid = data.isLinkedToOtherBid;
    }
    id: number;
    title: string;
    canUpdate: boolean;
    isLinkedToOtherBid: boolean;
}

export class CustomInvoiceId {
    constructor(data: any) {
        if (typeof data === "string") {
            this.value = data;
        } else {
            this.value = data.value;
            this.prefix = data.prefix;
        }
    }
    value: string;
    prefix: string;

    toString = () => {
        return this.prefix ? `${this.prefix}-${this.value}` : this.value;
    };
}

export class RelatedInvoice {
    constructor(data: any) {
        this.id = data.id || data.invoiceID;
        this.title = data.title;
        this.lineItemTotal = data.lineItemTotal;
        this.customInvoiceId = new CustomInvoiceId(data.customInvoiceId);
        this.status = data.status;
    }
    id: number;
    title: string;
    lineItemTotal: number;
    customInvoiceId: CustomInvoiceId;
    status: InvoiceStatus;
}

export class ReceiptRelatedItem {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.title = data.title;
        this.type = data.type;
    }

    id: number;
    jobId: number;
    title: string;
    type: RelatedItemType;
}

export class IRelatedItemEntityData {
    name: string;
    namePlural: string;
    RelatedIcon: ComponentType<IBTIconProps>;
}

export class RelatedToDos {
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    queryParamName: string;
    values: RelatedToDoItem[];

    constructor(data: any) {
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.queryParamName = data.queryParamName;
        this.values = data.values ? data.values.map((x: any) => new RelatedToDoItem(x)) : [];
    }
}

class RelatedToDoItem {
    constructor(data: any) {
        this.title = data.title;
        this.toDoId = data.toDoId;
        this.isCompleted = data.isCompleted;
    }
    title: string;
    toDoId: number;
    isCompleted: boolean;
}
