import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";

export type SavedFilterFormActions = undefined | "save" | "update" | "delete";

export interface ISavedFilterFormValues {
    filterType: FilterEntityType;
    filterName: string;
    isDefault: boolean;
    isPrivate: boolean;
    selectedFilter: number;
    value: any;
}

export class SavedFilterEntity {
    constructor(data: any) {
        this.savedFilters = data.savedFilters.map(
            (savedFilter: any) => new SavedFilterItem(savedFilter)
        );
    }

    savedFilters: SavedFilterItem[];
}

export class SavedFilterItem {
    constructor(data: any) {
        this.id = data.id;
        this.isDefault = data.isDefault;
        this.isMobileDefault = data.isMobileDefault;
        this.isOwner = data.isOwner;
        this.canEdit = data.canEdit;
        this.isPrivate = data.isPrivate;
        this.name = data.name;
        this.nameAndUpdatedBy = data.nameAndUpdatedBy;
        this.value = data.value;
    }

    id: number;
    isDefault: boolean;
    isMobileDefault: boolean;
    isOwner: boolean;
    canEdit: boolean;
    isPrivate: boolean;
    name: string;
    nameAndUpdatedBy: string;
    value: any;
}

export class SavedFilterDeleteResponse {}

export class SavedFilterUpdateResponse {}

export class SavedFilterCreateResponse {
    constructor(data: any) {
        this.id = data.id;
    }
    id: number;
}

export class SavedFilterSetUserDefaultResponse {}
