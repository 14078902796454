import { BTSelectItem } from "types/apiResponse/apiResponse";
import { BTLoginTypes, LoginStatus } from "types/enum";

import { IntercomSettings } from "commonComponents/utilities/IntercomChat/IntercomTypes";

import type { default as LoginNameAndIdForLeadJson } from "entity/login/Login/LoginNameAndIdForLead.api.json";

import type { default as ImpersonationCodeJson } from "./LoginImpersonationCode.api.json";

export type LoginFormActions = undefined | "save" | "forgotUsername" | "forgotPassword";

export interface ILoginFormValues {
    emailReset: string;
    username: string;
    password: string;
    usernameReset: string;
    captchaResponse: string | undefined;
    selectedBuilderId?: number;
}

export interface ILoginRequest {
    username: string;
    password: string;
    captchaResponse?: string;
    isOAuthRequest?: boolean;
    isDataPrivacyRequest?: boolean;
    signInDescription: string;
}

export interface ILoginResponse {
    loginType: BTLoginTypes;
    builderId: number | null;
    linkedBuilders: LinkedBuilder[];
    oAuthCode?: string;
}

export interface IRedirectPageParams {
    loginResponse: ILoginResponse;
    isOAuthRequest?: boolean;
    oauthRedirectUri?: string;
    oauthState?: string;
    loginPostRedirect?: string;

    setLoginStateForMFA?: (crednetialId: number) => void;
    setLoginStateForRequireEmail?: (requireEmailCredId: string | null) => void;
    resetLoadingSpinner?: () => void;
}

export interface IOAuthStateValues {
    oauthRedirectType: OAuthType;
    redirectURI: string;
    state: string;
}

export class LoginResponse implements ILoginResponse {
    constructor(data: any) {
        this.loginStatus = data.loginStatus;
        this.loginType = data.loginType;
        this.builderId = data.builderId;
        this.credId = data.credId;
        this.resetPassword = data.resetPassword;
        this.oAuthCode = data.oAuthCode;
        this.isSubNoJobAccess = data.isSubNoJobAccess;
        this.subBidCount = data.subBidCount;
        this.subOpenBidCount = data.subOpenBidCount;
        this.linkedBuilders =
            data.linkedBuilders
                ?.map((l: any) => new LinkedBuilder(l))
                .filter(
                    (item: LinkedBuilder, index: Number, self: LinkedBuilder[]) =>
                        index === self.findIndex((t) => t.builderId === item.builderId)
                ) ?? [];
        this.builderName = data.BuilderName;
        this.isUserAdmin = data.isUserAdmin;
        this.encryptedRewinBuilderId = data.encryptedRewinBuilderId;
        this.dataPrivacyJWT = data.dataPrivacyJWT;
        this.contactSupport = data.contactSupport;
        this.orgLinkIds = data.orgLinkIds;
        this.intercomSettings = data.intercomSettings;
        this.requireEmailCredId = data.requireEmailCredId;
        this.disconnectedAccountHelpUrl = data.disconnectedAccountHelpUrl;
        this.disconnectedAccountMessage = data.loginFailedMessage;
    }

    loginStatus: LoginStatus;
    loginType: BTLoginTypes;
    builderId: number | null;
    credId: number;
    resetPassword: boolean;
    oAuthCode?: string;
    isSubNoJobAccess: boolean;
    subBidCount: number;
    subOpenBidCount: number;
    linkedBuilders: LinkedBuilder[];
    builderName?: string;
    isUserAdmin?: boolean;
    encryptedRewinBuilderId?: string;
    dataPrivacyJWT?: string;
    contactSupport?: boolean;
    orgLinkIds: number[];
    intercomSettings?: IntercomSettings;
    requireEmailCredId: string;
    disconnectedAccountHelpUrl?: string;
    disconnectedAccountMessage?: string;
}

export class UserProfileResponse {
    constructor(data: any) {
        this.name = data.name;
        this.userType = data.userType;
        this.title = data.title;
        this.email = data.email;
        this.phone = data.phone;
        this.cellPhone = data.cellPhone;
        this.primaryContact = data.primaryContact;
        this.canStartChatConversation = data.canStartChatConversation;
    }

    name: string;
    userType: BTLoginTypes;
    title: string | null;
    email: string | null;
    phone: string | null;
    cellPhone: string | null;
    primaryContact: string | null;
    canStartChatConversation: boolean;
}

export class LinkedBuilder {
    constructor(data: any) {
        this.builderId = Number(data.builderId);
        this.loginId = Number(data.loginId);
        this.builderName = data.builderName;
    }

    builderId: number;
    loginId: number;
    builderName: string;
}

export enum LoginPageRedirect {
    OAuth = 0,
    FrozenSubOwner = 1,
    FrozenBuilder = 2,
    NotConnected = 3,
    PostLoginRedirect = 4,
    CalendarPageOwner = 5,
    SubEmailLink = 6,
    SubNoJobAccess = 7,
    Summary = 8,
    MFA = 9,
    DataPrivacy = 10,
    NoRedirect = 11,
    SubscriptionManagement = 12,
    RequireEmail = 13,
}

export enum LoginScreen {
    Login = "login",
    ForgotUser = "forgotUser",
    ForgotPassword = "forgotPassword",
    ConfirmationUser = "confirmationUser",
    ConfirmationPassword = "confirmationPassword",
}

export class CaptchaResponse {
    constructor(data: any) {
        this.showCaptcha = data.showCaptcha;
    }

    showCaptcha: boolean;
}

export enum LoginAdSrc {
    Builder = "https://go.buildertrend.com/l/464372/2021-01-19/93c11w",
    Sub = "https://go.buildertrend.com/l/464372/2021-02-01/94c58y",
    Owner = "https://go.buildertrend.com/l/464372/2021-02-01/94c59k",
}

export enum OAuthType {
    skilljar = 1,
    dataproduct = 2,
    thd = 3,
    cbusa = 4,
    integrationpartner = 5,
    internal = 6,
}

// #region SwitchLinkedLoginResponse types
export class SwitchLinkedLoginResponse {
    constructor(data: any) {
        this.login = new Login(data.login);
        this.jobsites = new Jobsites(data.jobsites);
        this.groupedMenu = data.groupedMenu;
        this.switchBuilderFrozen = data.switchBuilderFrozen;
    }

    login: Login;
    jobsites: Jobsites;
    groupedMenu?: any;
    switchBuilderFrozen: boolean;
}

class Jobsites {
    constructor(data: any) {
        this.builders = data.builders;
        this.jobsiteGroups = data.jobsiteGroups;
        this.jobsite_JobsiteGroups = data.jobsite_JobsiteGroups;
        this.jobsite_ProjectManagers = data.jobsite_ProjectManagers;
        this.projectManagers = data.projectManagers.map((i: any) => new ProjectManager(i));
        this.projectManagersEnabled = data.projectManagersEnabled;
        this.jobsites = data.jobsites.map((i: any) => new Jobsite(i));
        this.savedFilters = data.savedFilters.map((i: any) => new SavedFilter(i));
    }

    builders?: any;
    jobsiteGroups: any[];
    jobsite_JobsiteGroups: any[];
    jobsite_ProjectManagers: any[];
    projectManagers: ProjectManager[];
    projectManagersEnabled: boolean;
    jobsites: Jobsite[];
    savedFilters: SavedFilter[];
}

class SavedFilter {
    constructor(data: any) {
        this.name = data.name;
        this.value = data.value;
        this.id = Number(data.id);
        this.isPrivate = data.isPrivate;
        this.isDefault = data.isDefault;
        this.isMobileDefault = data.isMobileDefault;
        this.isOwner = data.isOwner;
        this.nameAndUpdatedBy = data.nameAndUpdatedBy;
    }
    name: string;
    value: string;
    id: number;
    isPrivate: boolean;
    isDefault: boolean;
    isMobileDefault: boolean;
    isOwner: boolean;
    nameAndUpdatedBy: string;
}

class Jobsite {
    constructor(data: any) {
        this.id = Number(data.id);
        this.name = data.name;
        this.entityHasContact = data.entityHasContact;
        this.builderId = Number(data.builderId);
        this.jobStatus = Number(data.jobStatus);
        this.ownerName = data.ownerName;
        this.portal = data.portal;
        this.userId = Number(data.userId);
        this.canView = data.canView;
        this.isStandaloneTemplate = data.isStandaloneTemplate;
        this.ownerEmail = data.ownerEmail;
        this.ownerPhone = data.ownerPhone;
    }
    id: number;
    name: string;
    entityHasContact: boolean;
    builderId: number;
    jobStatus: number;
    ownerName: string;
    portal: string;
    userId: number;
    canView?: any;
    isStandaloneTemplate: boolean;
    ownerEmail?: any;
    ownerPhone?: any;
}

class ProjectManager {
    constructor(data: any) {
        this.id = Number(data.id);
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }
    id: number;
    firstName: string;
    lastName: string;
}

class Login {
    constructor(data: any) {
        this.assertUpgrade = data.assertUpgrade;
        this.isNotifyBlocking = data.isNotifyBlocking;
        this.inBTOffice = data.inBTOffice;
        this.minVersion = data.minVersion;
        this.appUpdateAvailable = data.appUpdateAvailable;
        this.latestAppVersion = data.latestAppVersion;
        this.builderAppIconUrl = data.BuilderAppIconUrl;
        this.builderName = data.BuilderName;
        this.debugModeUrl = data.DebugModeUrl;
        this.isInDebugMode = data.IsInDebugMode;
        this.token = data.Token;
        this.brandLandingPage = data.brandLandingPage;
        this.loginTypeMobile = data.loginTypeMobile;
        this.sessionId = data.sessionID;
        this.isRegisteredForPush = data.isRegisteredForPush;
        this.temporaryPassword = data.temporaryPassword;
        this.emailAddresses = data.emailAddresses;
        this.users = data.users.map((i: any) => new User(i));
        this.shouldPromptToRate = data.shouldPromptToRate;
        this.termsDialog = data.termsDialog;
        this.loginSuccess = data.loginSuccess;
        this.loginFailedTitle = data.loginFailedTitle;
        this.loginFailedMessage = data.loginFailedMessage;
        this.loginFailedData = data.loginFailedData;
        this.mobileCookies = data.mobileCookies;
        this.showRoleGatheringSurvey = data.showRoleGatheringSurvey;
        this.intercomSettings = new IntercomSettings(data.intercomSettings);
        this.multiFactorAuthResponse = data.multiFactorAuthResponse;
        this.isMultiFactorAuthFailure = data.isMultiFactorAuthFailure;
    }

    assertUpgrade: boolean;
    isNotifyBlocking: boolean;
    inBTOffice: boolean;
    minVersion: string;
    appUpdateAvailable: boolean;
    latestAppVersion: string;
    builderAppIconUrl: string;
    builderName: string;
    debugModeUrl: string;
    isInDebugMode: boolean;
    token: string;
    brandLandingPage: string;
    loginTypeMobile: string;
    sessionId: string;
    isRegisteredForPush: boolean;
    temporaryPassword: boolean;
    emailAddresses?: any;
    users: User[];
    shouldPromptToRate: boolean;
    termsDialog?: any;
    loginSuccess: boolean;
    loginFailedTitle?: any;
    loginFailedMessage?: any;
    loginFailedData?: any;
    mobileCookies: any[];
    showRoleGatheringSurvey: boolean;
    intercomSettings: IntercomSettings;
    multiFactorAuthResponse?: any;
    isMultiFactorAuthFailure: boolean;
}

class User {
    constructor(data: any) {
        this.userId = data.userId;
        this.builderId = data.builderId;
        this.portal = data.portal;
        this.selectedPortal = data.selectedPortal;
        this.mainTitle = data.mainTitle;
        this.subTitle = data.subTitle;
        this.userName = data.userName;
        this.jobName = data.jobName;
    }
    userId: number;
    builderId: number;
    portal: string;
    selectedPortal: boolean;
    mainTitle: string;
    subTitle: string;
    userName: string;
    jobName?: any;
}
// #endregion

export class LoginNameAndIdForLeadResponse {
    constructor(data: typeof LoginNameAndIdForLeadJson) {
        this.logins = data.nameAndIdList.map(
            (login) => new BTSelectItem({ id: login.ID, name: login.Name })
        );
    }

    logins: BTSelectItem[];
}

export class ImpersonationInfo {
    constructor(data: typeof ImpersonationCodeJson) {
        this.authCode = data.authCode;
        this.userName = data.userName;
    }
    authCode: string;
    userName: string;
}

export const signInDescription = "Default Login Service";

export class GenerateOAuthCodeResponse implements ILoginResponse {
    constructor(data: any) {
        this.oAuthCode = data.oAuthCode;
        this.linkedBuilders =
            data.linkedBuilders
                ?.map((l: any) => new LinkedBuilder(l))
                .filter(
                    (item: LinkedBuilder, index: Number, self: LinkedBuilder[]) =>
                        index === self.findIndex((t) => t.builderId === item.builderId)
                ) ?? [];
        this.loginType = data.loginType;
        this.builderId = data.builderId;
    }
    oAuthCode: string;
    linkedBuilders: LinkedBuilder[];
    builderId: number;
    loginType: BTLoginTypes;
}

export class GenerateEphemeralUrlResponse {
    constructor(data: { url: string; key: string }) {
        this.url = data.url;
        this.key = data.key;
    }
    url: string;
    key: string;
}
