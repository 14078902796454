import { isEventWhitelisted, translateEventName } from "utilities/analytics/analyticsUtils";

function isQualtricsReady() {
    // eslint-disable-next-line no-restricted-syntax
    return (window as any).QSI?.EventTracker;
}

const whitelist: string[] = [
    "ButtonClick|Pay bill online - Buildertrend|IndividualOutboundPayment|outboundPayments",
    "ButtonClick|Pay bill online - Buildertrend|MassBillPayment|outboundPayments",
    "ButtonClick|Receipt Upload - Buildertrend|?|save",
    "ButtonClick|Receipt - Buildertrend|?|save",
    "ButtonClick|Receipt - Buildertrend|?|newBill",
    "ButtonClick|Rebate - Buildertrend|?|submit",
    "ButtonClick|Rebate - Buildertrend|?|saveAndClose",
];

export function qualtricsPlugin(userConfig = {}) {
    return {
        name: "qualtrics",
        config: Object.assign({}, userConfig),
        track: (params: any) => {
            try {
                if (!isQualtricsReady()) {
                    return;
                }

                const translatedEventName = translateEventName(params);

                if (isEventWhitelisted(translatedEventName, whitelist)) {
                    // eslint-disable-next-line no-restricted-syntax
                    (window as any).QSI?.EventTracker.track(translatedEventName);
                }
            } catch (e) {
                // Eat the error
            }
        },
    };
}

export type SurveyType = "QBDisconnect";

export function setQualtricsExtraParameters(surveyName: SurveyType): void {
    window.qualtricsExtraParameters.currentSurvey = surveyName;
    window.QSI.API?.unload();
    window.QSI.API?.load();
    window.QSI.API?.run();
}
