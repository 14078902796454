import { message } from "antd";
import { Component } from "react";

import { CommonConstants } from "types/constants";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import PermissionWizardPresentational from "commonComponents/entity/permissionWizard/PermissionWizard/PermissionWizardPresentational";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { IJobHandler, JobHandler } from "entity/job/Job.api.handler";
import { JobPermissionResponse } from "entity/job/Job.api.types";

export type PermissionWizardActions = undefined | "save";

export interface IPermissionWizardProps {
    jobHandler?: IJobHandler;
    subIds?: number[];
    internalUserIds?: number[];
    jobIds: number[];
    onComplete: (internalUserIds: number[], subIds: number[]) => void;
    onCancel: () => void;
    modalConfig?: IModalConfiguration;
    fromBills?: boolean;
}

export interface IPermissionWizardState {
    jobPermissions?: JobPermissionResponse[];
    permissionsToAdd: number[][];
    actionBeingPerformed: string | undefined;
}

class PermissionWizard extends Component<IPermissionWizardProps, IPermissionWizardState> {
    state: Readonly<IPermissionWizardState> = {
        permissionsToAdd: [],
        jobPermissions: undefined,
        actionBeingPerformed: undefined,
    };

    static defaultProps = {
        jobHandler: new JobHandler(),
    };

    private updatePermissions = async () => {
        await setLoadingAction<PermissionWizardActions>(this, {
            actionBeingPerformed: "save",
            callback: async () => {
                try {
                    const requests = [];
                    const internalUserPermissions = this.state.permissionsToAdd[0];
                    const subPermissions =
                        this.state.permissionsToAdd[this.state.jobPermissions!.length - 1];
                    if (
                        this.props.internalUserIds &&
                        this.props.internalUserIds.length &&
                        internalUserPermissions !== undefined
                    ) {
                        requests.push(
                            this.props.jobHandler!.updateUserPermissions(
                                this.props.jobIds,
                                internalUserPermissions,
                                undefined,
                                this.props.internalUserIds
                            )
                        );
                    }
                    if (
                        this.props.subIds &&
                        this.props.subIds.length &&
                        subPermissions !== undefined
                    ) {
                        requests.push(
                            this.props.jobHandler!.updateSubPermissions(
                                this.props.jobIds,
                                subPermissions,
                                this.props.subIds
                            )
                        );
                    }
                    await Promise.all(requests);
                    void message.success("Permissions Updated");
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
        this.props.onComplete(this.props.internalUserIds || [], this.props.subIds || []);
    };

    private doNotUpdatePermissions = async (showToast: boolean) => {
        if (showToast) {
            if (this.props.fromBills) {
                void message.success(
                    `Successfully added ${CommonConstants.Sub.singular.toLowerCase()} to this bill and job`
                );
            } else {
                void message.success("Saved Successfully - Existing items have been hidden");
            }
        }
        this.props.onCancel();
    };

    componentDidMount = async () => {
        try {
            // We share this endpoint with mobile, which expects one at a time
            const requests = [];
            if (this.props.internalUserIds && this.props.internalUserIds.length > 0) {
                requests.push(
                    this.props.jobHandler!.getJobPermissions({
                        jobIds: this.props.jobIds,
                        internalUserIds: this.props.internalUserIds,
                    })
                );
            }
            if (this.props.subIds && this.props.subIds.length > 0) {
                requests.push(
                    this.props.jobHandler!.getJobPermissions({
                        jobIds: this.props.jobIds,
                        subIds: this.props.subIds,
                    })
                );
            }
            const responses = await Promise.all(requests);
            if (responses && responses.flatMap((x) => x.permissions).length > 0) {
                const permissionsToAdd = (this.state.permissionsToAdd || []).slice();
                for (let index = 0; index < responses.length; index++) {
                    responses[index].permissions
                        .concat(responses[index].permissions.flatMap((p) => p.nestedOptions))
                        .forEach((permission) => {
                            if (permission.value === true) {
                                if (!permissionsToAdd[index]) {
                                    permissionsToAdd[index] = [];
                                }
                                permissionsToAdd[index].push(permission.id);
                            }
                        });
                }
                this.setState({
                    jobPermissions: responses,
                    permissionsToAdd,
                });
            } else {
                // If there are no permissions to update run onCancel prop
                if (this.props.fromBills) {
                    await this.doNotUpdatePermissions(true);
                }
                await this.doNotUpdatePermissions(false);
            }
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    };

    private updatePermissionsToAdd = (id: number, checked: boolean, index: number) => {
        const permissionsToAdd = (this.state.permissionsToAdd || []).slice();
        const jobPermissions = this.state.jobPermissions!;
        const flatPermissions = jobPermissions[index].permissions.concat(
            jobPermissions[index].permissions.flatMap((p) => p.nestedOptions)
        );
        if (!permissionsToAdd[index]) {
            permissionsToAdd[index] = [];
        }
        if (checked) {
            permissionsToAdd[index].push(id);
            flatPermissions.find((p) => p.id === id)!.selected = true;
        } else {
            permissionsToAdd[index] = permissionsToAdd[index].filter((x) => x !== id);
            flatPermissions.find((p) => p.id === id)!.selected = false;
        }
        this.setState({
            permissionsToAdd,
            jobPermissions,
        });
    };

    render() {
        if (!this.state || !this.state.jobPermissions) {
            return <BTLoading />;
        }

        const { modalConfig, fromBills } = this.props;
        const permissionWizard = (
            <PermissionWizardPresentational
                jobPermissions={this.state.jobPermissions}
                onCancel={() => this.doNotUpdatePermissions(true)}
                onSubmit={this.updatePermissions}
                onChange={this.updatePermissionsToAdd}
                actionBeingPerformed={this.state.actionBeingPerformed}
                modalConfig={modalConfig}
                fromBills={fromBills}
            />
        );

        if (modalConfig) {
            return (
                <BTModal
                    data-testid="btModalGlobalUserPermissionWizard"
                    visible
                    useModalLayout
                    width="600px"
                    removeBodyPadding={true}
                    beforeClose={modalConfig.beforeClose}
                    title="Permission Wizard"
                    closable={false}
                >
                    {permissionWizard}
                </BTModal>
            );
        }

        return permissionWizard;
    }
}

export default withErrorBoundary(PermissionWizard)("Couldn't load permission wizard.");
