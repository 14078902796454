import "array-flat-polyfill";
import { get } from "lodash-es";
import { useState } from "react";
import { Key } from "ts-key-enum";

import { BTSelectItem } from "types/apiResponse/apiResponse";

/**
 * Splits a string into an array of numbers (number can be float)
 * Consider using the <FormItemAutomatic> component instead
 * @deprecated use getValidateStatusTouched
 * @param field the name of the field
 * @example validateStatus={getValidateStatus(errors.field)}
 */
export function getValidateStatus(field: string | undefined | (string | undefined)[]) {
    if (field === undefined) {
        return "success";
    }

    return "error";
}

/**
 * Similar to getValidateStatus but only shows the error when the field has been touched
 * @param errors pass formik errors
 * @param touched pass formik touched
 * @param fieldName key name of the field, lodash deep names supported (example: "group.field", or "list[3].field")
 */
export function getValidateStatusTouched<E, T>(
    errors: E,
    touched: T,
    fieldName: (keyof E & keyof T) | string
) {
    if (errors === undefined || touched === undefined) {
        return undefined;
    }

    // only show errors when the field has been touched and an error exists
    if (get(errors, fieldName) !== undefined && get(touched, fieldName) !== undefined) {
        return "error";
    }

    return undefined;
}

/**
 * Shows the characters under the min length in the validation error
 * @example
 * yup.string().label("Label Name").min(10, charactersUnderLimitValidator);
 */
export function charactersUnderLimitValidator(params: any): string {
    return `Minimum length ${params.min} ${params.min === 1 ? "character" : "characters"}`;
}

/**
 * Shows the characters over the max limit in the validation error
 * @example
 * yup.string().label("Label Name").max(4000, charactersOverLimitValidator);
 */
export function charactersOverLimitValidator(params: any): string {
    let charactersOverMax = params.value.length - params.max;
    let pluralString = charactersOverMax === 1 ? "character" : "characters";
    return `${charactersOverMax} ${pluralString} over`;
}

/**
 * returns an array of the selected value
 * Consider using <FormItemAutomatic>
 */
export function getSelectedValues<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[]
): number[] | any[] {
    if (dropdownValues === undefined || dropdownValues.length === 0) {
        return [];
    }

    return getSelectedItems(dropdownValues).map((item) => item.value);
}

/**
 * Given an array of dropdown values, returns all leaf/child nodes. Only works when max depth <= 2
 * @param dropdownValues array of dropdown values whose children should be returned
 */
export function getChildren<ExtraDataType>(dropdownValues: BTSelectItem<ExtraDataType>[]) {
    const flattenChildren: BTSelectItem<ExtraDataType>[] = dropdownValues.flatMap((item) =>
        item.children ? item.children : item
    );

    // remove undefined children
    const definedChildren: BTSelectItem<ExtraDataType>[] = flattenChildren.filter(
        (item) => item !== undefined
    );
    return definedChildren;
}

export function getByValue<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[] | undefined,
    value: any
): BTSelectItem<ExtraDataType> | undefined {
    if (dropdownValues === undefined || dropdownValues.length === 0) {
        return undefined;
    }

    const definedChildren = getChildren(dropdownValues);

    return definedChildren.find((item) => item.value === value);
}

/**
 * returns a single selected value as BTSelectItem
 */
export function getSelected<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[]
): BTSelectItem<ExtraDataType> | undefined {
    const selectedValue = getSelectedValues(dropdownValues);

    if (selectedValue.length === 0) {
        return undefined;
    }

    return getByValue(dropdownValues, selectedValue[0]);
}

/**
 * returns a single selected items value (BTSelectItem.value)
 */
export function getSelectedValue<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[]
): number | any {
    const selectedValue = getSelectedValues(dropdownValues);

    if (selectedValue.length === 0) {
        return undefined;
    }

    return selectedValue[0];
}

/**
 * Returns a single selected value or a default value when no item is selected
 * @param defaultValue default value when no value is selected
 */
export function getSelectedOrDefaultValue<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[],
    defaultValue?: BTSelectItem<ExtraDataType> | undefined
): number | any {
    const selectedValue = getSelectedValue(dropdownValues);

    if (selectedValue === undefined) {
        return defaultValue;
    }

    return selectedValue;
}

export function getSelectedItems<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[]
): BTSelectItem<ExtraDataType>[] {
    if (dropdownValues === undefined || dropdownValues.length === 0) {
        return [];
    }

    let selectedItems: BTSelectItem<ExtraDataType>[] = [];
    dropdownValues.forEach((dropdown) => {
        // search children for selected values
        if (dropdown.children) {
            selectedItems = selectedItems.concat(getSelectedItems(dropdown.children));
        }

        if (dropdown.selected) {
            selectedItems.push(dropdown);
        }
    });

    return selectedItems;
}

export function getDisabledIds<ExtraDataType>(data: BTSelectItem<ExtraDataType>[] | undefined) {
    return getAllIds(data, (i) => i.disabled);
}
export function getAllIds<ExtraDataType>(
    data: BTSelectItem<ExtraDataType>[] | undefined,
    includeItem: (item: BTSelectItem<ExtraDataType>) => boolean = () => true
) {
    return !data
        ? []
        : data.reduce<(string | number)[]>((p, c) => {
              if (includeItem(c)) {
                  p.push(c.id);
              }
              if (c.children) {
                  p = [...p, ...getAllIds(c.children, includeItem)];
              }
              return p;
          }, []);
}

/**
 * This is used every time a collection can can be changed separately from it's selected form values.
 * For instance, as in the case of any BTSelectEditable with multiselect and the add/edit/delete ability.
 * This will keep track of the form values separately from the select items in the collection, and ensure
 * that the list remains sorted alphabetically.
 */

export function onMultiselectListUpdated(
    newItems: BTSelectItem[],
    changedItem: BTSelectItem,
    values: number[]
) {
    // Alphabetize
    alphabetize(newItems);

    const newId = Number(changedItem.id);
    const newIds = newItems.map((t) => Number(t.id));

    // Filter deleted tags from form values
    const formValues = values.filter((id: number) => newIds.includes(id));

    // Add new tags to form values
    if (newIds.includes(newId) && !formValues.includes(newId)) {
        formValues.push(newId);
    }

    // Scan through and prevent changes to initial selected values
    newItems.filter((item) => Number(item.id) === newId).forEach((item) => (item.selected = false));

    return { formValues, newItems };
}

function isPerformingAction<ActionType>(action: ActionType, targetAction: ActionType): boolean {
    if (action === undefined) {
        return false;
    }

    return action !== targetAction;
}

/**
 * Conditionally shows a loading icon on the current button, disables the button if another action is in progress
 * @see setLoadingAction
 * @example
 * private onSave = async () => {
 *   setLoadingAction<MyEntityFormActions>(this, "save", async () => {
 *     try {
 *       await this.save(values, setErrors);
 *     }
 *     catch (e) {
 *       showAPIErrorMessage(e);
 *     }
 *   });
 * };
 *
 * // This save button will have a loading spinner when the "save" action is happening, if another action is happening it'll be disabled
 * <BTButton actionBeingPerformed={action} loadingAction="save" onClick={onSave} type="primary">
 * Save
 * </Button>
 * @example
 * // You can manually add checks to the disabled/loading attributes
 * <BTButton loading={getLoadingAction(action, "save").loading} disabled={getLoadingAction(action, "save").disabled && userHasAccessToSave} onClick={onSave} type="primary">
 * Save
 * </Button>
 */
export function getLoadingAction<ActionType>(
    action: ActionType,
    targetAction: ActionType
): { loading: boolean; disabled: boolean } {
    return {
        loading: action === targetAction,
        disabled: isPerformingAction(action, targetAction),
    };
}

export interface ISetLoadingActionOptions<FormActionType> {
    /**
     * the name of the action being performed, actionBeingPerformed will be updated to reflect this value
     */
    actionBeingPerformed: FormActionType;
    /**
     * will skip resetting action state if true. Only set to false if the callback will not navigate or preforms an action which destroys this component.
     * @default true
     */
    callbackNavigates?: boolean;
    /**
     * asynchronous code to run, when the callback in done running the action will be reset removing all loading spinners
     */
    callback: () => Promise<void>;
}

export interface ILoadingContext {
    state: {
        actionBeingPerformed: any;
    };
    setState: any;
}

/**
 * Updates the state of the passed component so a loading icon can be displayed in the UI while an action is occurring (ex: saving, reloading, deleting)
 * This helper sets the state that getLoadingAction helper method uses
 * @param context pass this, the context is your current component. Your component must have "actionBeingPerformed" in your state
 * @param loadingActionOptions options that drive how the action will execute
 * @see getLoadingAction
 * @example
 * await setLoadingAction<MyEntityFormActions>(this, { action: "save", didNavigate: true, callback: async () => {
 *   try {
 *     await this.save(values, setErrors);
 *     void message.success("Thing saved");
 *   }
 *   catch (e) {
 *     showAPIErrorMessage(e);
 *   }
 * });
 */
export async function setLoadingAction<FormActionType>(
    context: ILoadingContext,
    {
        actionBeingPerformed,
        callback,
        callbackNavigates = true,
    }: ISetLoadingActionOptions<FormActionType>
) {
    try {
        context.setState({ actionBeingPerformed });
        await callback();
        if (callbackNavigates) {
            context.setState({ actionBeingPerformed: undefined });
        }
    } catch (e) {
        context.setState({ actionBeingPerformed: undefined });
        throw e;
    }
}

export function useActionBeingPerformed<FormActionType>() {
    const [actionBeingPerformed, setActionBeingPerformed] = useState<FormActionType>();
    return [
        actionBeingPerformed,
        async function ({
            actionBeingPerformed,
            callback,
            callbackNavigates = true,
        }: ISetLoadingActionOptions<FormActionType>) {
            try {
                setActionBeingPerformed(actionBeingPerformed);
                await callback();
                if (callbackNavigates) {
                    setActionBeingPerformed(undefined);
                }
            } catch (e) {
                setActionBeingPerformed(undefined);
                throw e;
            }
        },
    ] as const;
}

export enum SelectItemChangeType {
    Unchanged = 0,
    Added = 1,
    Removed = 2,
}
/**
 * Given the original state of the dropdown values, and the selected items from formik, this will return the
 * changes a user has made to a dropdown. If an item was previously unselected, and it's now selected, it
 * will be considered "added". If an item was previously selected, and it's now unselected, it will be
 * considered "removed". If an item was previously selected, and it's now selected, it will be considered
 * "unchanged" It maintains the original tree data structure, so the result could easily be fed into
 * another BTSelect. It does not modify the "selected" property of any of the BTSelectItems
 */
export function getChangedSelectItems<ExtraDataType>(
    selectItems: BTSelectItem<ExtraDataType>[] | undefined,
    selectedItems: any[],
    changeType: SelectItemChangeType
): BTSelectItem<ExtraDataType>[] {
    if (selectItems === undefined || selectItems.length === 0) {
        return [];
    }

    const changedItems: BTSelectItem<ExtraDataType>[] = [];
    selectItems.forEach((selectItem) => {
        const children = getChangedSelectItems(selectItem.children, selectedItems, changeType);

        const wasSelected = changeType !== SelectItemChangeType.Added;
        const isNowSelected = changeType !== SelectItemChangeType.Removed;

        if (
            (selectItem.selected === wasSelected &&
                selectedItems.includes(selectItem.id) === isNowSelected) ||
            children.length > 0
        ) {
            // Maintain the grouped structure
            changedItems.push({
                ...selectItem,
                children: children.length > 0 ? children : undefined,
            });
        }
    });
    return changedItems;
}

/**
 * Given the original state of the dropdown values, this will filter the dropdown to only include the items that were
 * selected, while maintaining the grouped structure. This is useful for showing confirmations, where the user can
 * select from any of the previously selected items. It does not change the value of "selected", it simply filters by id
 * @example ```getSelectedItemsWithGroups(entity.assignedToOptions, getSelectedValues(entity.assignedToOptions));```
 */
export function getSelectedItemsWithGroups<ExtraDataType>(
    dropdownValues: BTSelectItem<ExtraDataType>[] | undefined,
    selectedIds: any[]
): BTSelectItem<ExtraDataType>[] {
    if (dropdownValues === undefined || dropdownValues.length === 0) {
        return [];
    }

    let selectedItems: BTSelectItem<ExtraDataType>[] = [];
    dropdownValues.forEach((dropdown) => {
        const children = getSelectedItemsWithGroups(dropdown.children, selectedIds);
        if (selectedIds.includes(dropdown.id) || children.length > 0) {
            // Maintain the grouped structure
            selectedItems.push({ ...dropdown, children });
        }
    });

    return selectedItems;
}

/**
 * Given a list of dropdown values, this will get the values of all of the leaf nodes (options without any children). This is
 * useful for manually selecting all items in a dropdown after a user takes a certain action, without relying on the selected
 * property of the original list being updated.
 * @example ``` setFieldValue("selectListFieldName", getAllValues(selectList)) ```
 */
export function getAllValues<ExtraDataType>(selectItems?: BTSelectItem<ExtraDataType>[]): any[] {
    if (selectItems === undefined || selectItems.length === 0) {
        return [];
    }

    let values: any[] = [];
    selectItems.forEach((selectItem) => {
        const childrenValues = getAllValues(selectItem.children);
        if (childrenValues.length > 0) {
            values.push(...childrenValues);
        } else {
            values.push(selectItem.id);
        }
    });
    return values;
}

/**
 * This function alphabetizes a list of BTSelectItems by their title.
 * This is useful in an entity's contructor, where we can't guarantee that the mobile service is returning the list alphabetically, like Webforms did.
 * This is also used every time a sorted collection gets updated, for instance when adding a new item or renaming an existing one.
 */

export function alphabetize(items: BTSelectItem<any>[]): BTSelectItem<any>[] {
    items.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
    return items;
}

/**
 * Use this to capture and prevent bubbling for Enter key presses. Pass to onKeyDown.
 */
export function handleEnterKeyPressed(
    event: KeyboardEvent | React.KeyboardEvent,
    callback: () => void
) {
    if (event.key === Key.Enter && !event.shiftKey && !event.repeat) {
        event.preventDefault();
        callback();
    }
}

export const isItemSelected = (value: any, id?: string | number) => {
    if (id === undefined) {
        return false;
    }
    if (Array.isArray(value)) {
        return value.includes(id);
    }
    return value === id;
};
