import { Radio } from "antd";
import { RadioChangeEvent, RadioGroupProps } from "antd/lib/radio/interface";
import classNames from "classnames";
import { memo } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";

import "commonComponents/btWrappers/BTRadioGroup/BTRadioGroup.less";

interface IBTRadioGroupProps extends RadioGroupProps {
    horizontal?: boolean;
    displayBlock?: boolean;
    hasOptionDescriptions?: boolean;
    "data-testid"?: string;
}

export const BTRadioGroup: React.FC<IBTRadioGroupProps & ITrackingProp> = track({
    element: "Radio Group",
})(
    memo(
        ({
            children,
            horizontal = false,
            displayBlock = false,
            hasOptionDescriptions = false,
            className,
            "data-testid": dataTestId,
            onChange,
            tracking,
            ...otherProps
        }) => {
            const handleChange = (e: RadioChangeEvent) => {
                // only track event if there is uniqueId
                if (tracking && dataTestId) {
                    tracking.trackEvent({
                        uniqueId: dataTestId,
                        event: "ValueChange",
                        value: e.target.value,
                    });
                }
                onChange?.(e);
            };
            return (
                // eslint-disable-next-line react/forbid-elements
                <Radio.Group
                    className={classNames(className, {
                        "BTRadioGroup--Horizontal": horizontal,
                        "BTRadioGroup--DisplayBlock": displayBlock,
                        BTRadioGroup: !hasOptionDescriptions,
                        BTRadioGroupWithLabels: hasOptionDescriptions,
                    })}
                    onChange={handleChange}
                    data-testid={dataTestId}
                    {...otherProps}
                >
                    {children}
                </Radio.Group>
            );
        }
    )
);
