import { CSSProperties, PureComponent } from "react";

import "./TextWrap.less";

interface ITextWrapProps {
    className?: string;
    style?: CSSProperties;
    "data-testid"?: string;
}

export class TextWrap extends PureComponent<ITextWrapProps> {
    render() {
        const { style, className, children, "data-testid": testid } = this.props;
        const classNameWDefault = className !== undefined ? `TextWrap ${className}` : "TextWrap";

        return (
            <span className={classNameWDefault} style={style} data-testid={testid}>
                {children}
            </span>
        );
    }
}
