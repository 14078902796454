import { Avatar } from "antd";
import classNames from "classnames";
import { useCallback, useState } from "react";

import { getInitials, isNullOrWhitespace } from "utilities/string/string";

import { BTAvatarSize } from "commonComponents/btWrappers/BTAvatar/BTAvatar.types";
import { sizeNameToPixels } from "commonComponents/btWrappers/BTAvatar/BTAvatar.utils";
import { BTIconUser } from "commonComponents/btWrappers/BTIcon";
import { Thumbnail } from "commonComponents/utilities/Thumbnail/Thumbnail";

import "./BTAvatar.less";

interface IBTAvatarProps {
    /**
     * @default middle
     */
    size?: BTAvatarSize;

    /** Src of image avatar */
    src?: string | null;

    /** User display name (first and last name), avoid using username if possible
     * @example "John Doe"
     */
    displayName: string;

    className?: string;

    style?: React.CSSProperties;

    icon?: React.ReactNode;
}

const defaultIcon = <BTIconUser />;

/**
 * @deprecated Use BTUser instead
 * @see BTUser
 */
export const BTAvatar: React.FunctionComponent<IBTAvatarProps> = ({
    size = "middle",
    src,
    displayName,
    className,
    style,
    icon = defaultIcon,
}) => {
    const sizeInPixels = sizeNameToPixels(size);

    // if the avatar src fails to load use displayName instead
    const [error, setError] = useState<boolean>(false);
    const srcEmpty = isNullOrWhitespace(src);

    const handleError = useCallback(() => {
        setError(true);
    }, []);

    const displayNameInitials = getInitials(displayName);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Avatar
            size={sizeInPixels}
            gap={sizeInPixels / 4}
            src={
                error || srcEmpty ? undefined : (
                    <Thumbnail
                        src={src}
                        width={sizeInPixels}
                        height={sizeInPixels}
                        alt={displayName}
                        onError={handleError}
                    />
                )
            }
            className={classNames("BTAvatar", `BTAvatarSize-${size}`, className)}
            style={style}
            icon={displayNameInitials.length === 0 ? icon : undefined}
        >
            {displayNameInitials}
        </Avatar>
    );
};
