// eslint-disable-next-line no-restricted-imports
import * as clipboard from "clipboard-polyfill";

import { isNullOrUndefined } from "utilities/object/object";
import { isWhitespace } from "utilities/string/string";

const writeHtml = async (html: string, textPlain?: string) => {
    const dt = new clipboard.DT();
    dt.setData("text/html", html);

    if (!isNullOrUndefined(textPlain) && !isWhitespace(textPlain)) {
        dt.setData("text/plain", textPlain);
    }

    await clipboard.write(dt);
};
// eslint-disable-next-line no-restricted-imports
export * from "clipboard-polyfill";
export { writeHtml };
